import React from 'react';

export default function LabelText({ text, className, style, divStyle }) {
  return (
    <div style={divStyle}>
      <h6 style={style} className={`app-label ${className}`} >
          {text}
      </h6>
    </div>
  );
}
