/*
  created by Rija
  added on 2/1/2021
  @desc meter main page to swtich between different options
*/

import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { ButtonGroup, Button } from 'react-bootstrap';
import { HeadingContainer } from '../../../../neoverv';
import MeterReadings from './MeterReadings';
import MasterData from './MasterData';
import Autoload from '../../../../neoverv/Autoload';
import { withRouter } from 'react-router-dom';
import MeterDataTransfer from './DataTransfer';
import { connect } from 'react-redux';
import { Box, Group, Overlay, Stack } from '@mantine/core';
import { isMobile } from 'react-device-detect';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
const MOBILE_SIZE = 992;

const routes = [
  {
    name: 'reading',
    className: 'tooltips meter-reading',
    route: '/meter/reading',
    label: lang.meter.text_reading,
    heading: lang.newKeys.text_heading_meter_readings,
  },
  {
    name: 'master',
    className: 'tooltips meter-data',
    route: '/meter/master',
    label: lang.meter.text_master_data,
    heading: lang.newKeys.text_heading_metermaster_data,
  },
  {
    name: 'master',
    className: 'tooltips data-transfer',
    route: '/meter/data_transfer',
    label: 'Datentransfer',
    heading: 'Datentransfer',
  },
];

class List extends Component {
  state = {
    active: '/meter/reading',
    isMobile: window.innerWidth <= MOBILE_SIZE
  };

  componentDidMount(){
    window.addEventListener('resize', this.handleResize);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  changePage(route) {
    this.setState({ active: route });
    this.props.history.push(route);
    //Autoload.MainHelper.redirection(route);
  }
  changeHeading() {
    const activeRoute = routes.filter((route) => {
      return window.location.pathname === route.route;
    });
    return activeRoute.length > 0 ? activeRoute[0].heading : routes[0].heading;
  }

  handleResize = () => {
    // TODO: this causes data refetch on the meters reading details screen...
    if (window.innerWidth <= MOBILE_SIZE) {
      this.setState({ isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
  };

  render() {
    const {propertySelected, currentSubscription} = this.props;
    // console.log(window.location.pathname.split('/').pop(), 'abc');
    console.log('propertySelected: ', propertySelected);
    let pathname = this.props.location.pathname;
    console.log(pathname);
    if (pathname === '/meter') {
      pathname = routes[0].route;
    }
    return (
      <div className="meterClass">
        <Box sx={{ height: propertySelected.propertyType !== 'REGULAR' && '100vh', position: 'relative', padding: propertySelected.propertyType !== 'REGULAR' && 10}}>
            <Stack style={{zIndex: 1}}>
              <>
                <HeadingContainer
                  heading={!isMobile && this.changeHeading()}
                  hideHeadingTextWrapper={isMobile}
                  className="Meter-readings"
                  marginBottom={isMobile && 0}
                  headingClassName={window.location.pathname === '/meter/reading' ? routes[0].className : window.location.pathname === '/meter/master' ? routes[1].className : routes[2].className}
                  rightContent={
                    <ButtonGroup>
                      {routes.map((btn, idx) => {
                        return (
                          <Button
                            key={idx}
                            className={`switch-btn ${btn.name}-btn ${btn.route === pathname && 'active'}`}
                            style={{width: '110px', fontWeight: '400'}}
                            // style={{
                            //   color: btn.route === pathname ? '#111147' : '#727293',
                            //   borderBottom: btn.route === pathname ? '3px solid #5555FF' : null,
                            //   fontSize: btn.route === pathname ? '14px' : '14px',
                            //   marginRight: '25px',
                            //   padding: '32px 0px',
                            // }}
                            onClick={() => {
                              this.changePage(btn.route);
                            }}
                          >
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                              <span style={{marginTop: '-1px'}}>
                                {btn.label}
                              </span>
                            </div>
                          </Button>
                        );
                      })}
                    </ButtonGroup>
                  }
                />
                <div>
                  <Route path={this.props.match.url} exact component={MeterReadings} 
                  render={(props)=> <MeterReadings {...props} isMobile={isMobile}/>}/>
                  {/* <Route path={this.props.match.url} exact component={props => <MeterReadings {...props}/>}  />  */}
                  <Route path={this.props.match.url + '/reading'} exact //component={MeterReadings} 
                  render={(props)=> <MeterReadings {...props} isMobile={isMobile}/>}/>
                  <Route path={this.props.match.url + '/master'} exact //component={MasterData} 
                  render={(props)=> <MasterData {...props} isMobile={isMobile}/>}/>
                  <Route path={this.props.match.url + '/data_transfer'} exact //component={MeterDataTransfer} 
                  render={(props)=> <MeterDataTransfer {...props} isMobile={isMobile}/>}/>
                </div>
              </>
            </Stack>
            {propertySelected.propertyType !== 'REGULAR' &&
            <Stack align={'center'} style={{ justifyContent: 'center', zIndex: 50, position: 'absolute', top: 200, left: 0, right: 0, top: 0, bottom: 0, margin: 'auto', width: '100%'}}>
              <Stack spacing={0}>
                {/* <span style={{color: '#0E0E1D', fontSize: '25px', fontWeight: 'bold', textAlign: 'center'}}>Ablesungen nicht verfügbar</span> */}
                <span style={{color: '#5555FF', fontSize: '25px', fontWeight: 'bold', textAlign: 'center'}}>Ablesungen nicht verfügbar</span>
              </Stack>
              <Group>
                <span style={{color: '#615C7E', fontSize: '12px', textAlign: 'center'}}>Ablesungen sind für den Immobilientyp Eigentumswohnung / Gewerbeeinheit nicht verfügbar.</span>
              </Group>
            </Stack>}
            {propertySelected.propertyType !== 'REGULAR' && 
            <Overlay opacity={0} blur={2} zIndex={5} color={'#F7F7FA'}/>}
        </Box>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    propertySelected: state.generalReducer.property,
    currentSubscription: state.generalReducer.currentSubscription,
  };
};

export default withRouter(connect(mapStateToProps)(List));
