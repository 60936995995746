import List from '../../../views/pages/Module/User/List';
import AddTenant from '../../../views/pages/Module/User/AddTenant';
// import InvoiceTemplate from '../../../views/pages/Module/General/profile/InvoiceTemplate';


const pageList = [
  {
    name: 'Add Tenant',
    path: '/user/tenant',
    component: AddTenant,
  },
  // {
  //   name: 'User Invoice',
  //   path: '/user/invoice/:id',
  //   component: InvoiceTemplate,
  // },
  {
    name: 'User List',
    path: '/user/',
    component: List,
  },
 
];

export default pageList;
