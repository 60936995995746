import React, { Component } from 'react';
import { HeadingContainer, TransparentButton, ColorButton, DeleteModal } from '../../../../../neoverv';
import { Row, Col, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import Autoload from '../../../../../neoverv/Autoload';
import {
  updateUserProfile,
  Account_info,
  updateProfilePicture,
  setProfileData,
} from '../../../../../redux/action/General/generalActions';
import Avatar from '../../../../../assets/images/module/general/userAvatar.svg';
import RedCross from '../../../../../assets/images/module/general/RedCross.svg';
import GreenTick from '../../../../../assets/images/module/general/GreenTick.svg';
import { Group, Skeleton } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import ProfilePassword from './ProfilePassword';
import AlertModal from '../../../../../neoverv/components/Module/General/CustomModal/AlertModal';
var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default].general;
var lang2 = Setting[Setting.language_default].newKeys;
const { checkProperty } = Autoload.MainHelper;
const MOBILE_SIZE = 500;

export class PersonalDetails extends Component {
  state = {
    email: '',
    emailErr: false,
    firstName: '',
    firstnameErr: false,
    lastName: '',
    lastnameErr: false,
    address: '',
    addressErr: false,
    city: '',
    cityErr: false,
    zip: null,
    zipErr: false,
    company: '',
    companyErr: false,
    file: '',
    fileToUpload: '',
    deleteAccountModal: false,
    fileUploadErr: { state: false, msg: '' },
    isMobile: window.innerWidth < MOBILE_SIZE,
    skeletonLoading: false,
    emailChangeAlertOpen: false
  };

  componentDidMount() {
    this.setState({skeletonLoading: true});
    this._fetch_UserInfoData();  
    // this.getUserPicture();
    // const { profile } = this.props;
    // // console.log(profile, 'profile');
    // this.setState({
    //   email: checkProperty(profile, 'email'),
    //   name: checkProperty(profile, 'name'),
    //   address: checkProperty(profile, 'address'),
    //   city: checkProperty(profile, 'city'),
    //   zip: checkProperty(profile, 'zip', null),
    //   company: checkProperty(profile, 'company'),
    //   file: checkProperty(profile, 'file'),
    // });
  }
  toggleDeleteAccountModal = () => {
    this.setState({ deleteAccountModal: !this.state.deleteAccountModal });
  };

  _get_UserProfilePic = () => {
    console.log('this.props.profile picture', this.props.profile);
    const userAccountID = this.props.profile.id;
    console.log(userAccountID, 'userAccountID profile');
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.user_api_baseurl + userAccountID + '/picture',
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handle_get_pic',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };
  handle_get_pic = (response) => {
    console.log('user profile picture', response);
    // if (response.status == false) {
    //   Autoload.Redirection.redirection_error();
    // }
    if (response.status == true && response.statuscode == 200) {
      this.setState({ file: response.result ? response.result : '' });
    }
    this.ShowLoader();
  };

  _fetch_UserInfoData() {
    console.log('this.props.profile', this.props.profile);
    const userAccountID = this.props.profile.id;
    console.log(userAccountID, 'userAccountID in user profile');
    Autoload.HttpRequestAxios.HttpRequest(
      // Autoload.Setting.account_api_baseurl + 'b13c75a4-ddaa-4102-c080-08d92bf06bb3',
      //Autoload.Setting.swagger_mocked_api_baseUrl + '/users/1',
      Autoload.Setting.user_api_baseurl + userAccountID,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handle_get_user_list_data_one',
      null,
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }
  handle_get_user_list_data_one(response) {
    console.log('response of profile api one', response.result);
    this.props.Account_info(response.result.accountId);
    const userAccountID = this.props.profile.accountId;
    // api one - company city zipcode street streetNo
    // this.setState({ email: response.result[0].email });
    var data = response.result;
    console.log(data, 'data profile api one ');

    setTimeout(() => {
      Autoload.HttpRequestAxios.HttpRequest(
        // Autoload.Setting.user_api_baseurl + 'b13c75a4-ddaa-4102-c080-08d92bf06bb3',
        Autoload.Setting.account_api_baseurl + userAccountID,
        'GET',
        null,
        null,
        false,
        true,
        false,
        this,
        'handle_get_user_list_data_two',
        { data: data },
        false,
        '',
        {
          'Content-Type': 'application/json',
          Authorization: Autoload.Utils.getAuthToken(),
        }
      );
    }, 500);
  }
  handle_get_user_list_data_two(response) {
    console.log('response of profile api two', response);

    // console.log(response.other.data.result);
    // const fullname =
    //   Autoload.MainHelper.checkProperty(response.other.data, 'firstName') +
    //   ' ' +
    //   Autoload.MainHelper.checkProperty(response.other.data, 'lastName');
    // console.log(fullname, 'fullname');

    this.setState({
      // name: fullname,
      firstName: Autoload.MainHelper.checkProperty(response.other.data, 'firstName'),
      lastName: Autoload.MainHelper.checkProperty(response.other.data, 'lastName'),
      email: Autoload.MainHelper.checkProperty(response.other.data, 'email'),
      accountID: Autoload.MainHelper.checkProperty(response.other.data, 'accountId'),
      address: Autoload.MainHelper.checkProperty(response.result, 'addressStreet'),
      city: Autoload.MainHelper.checkProperty(response.result, 'addressCity'),
      zip: Autoload.MainHelper.checkProperty(response.result, 'addressZipCode'),
      company: Autoload.MainHelper.checkProperty(response.result, 'addressCompany'),
      accountNo: Autoload.MainHelper.checkProperty(response.result, 'accountNo'),
    },()=> this.setState({prevState: this.state}));
    this.props.setProfileData({
      ...this.props.profile,
      firstName: Autoload.MainHelper.checkProperty(response.other.data, 'firstName'),
      lastName: Autoload.MainHelper.checkProperty(response.other.data, 'lastName'),
      email: Autoload.MainHelper.checkProperty(response.other.data, 'email'),
      accountID: Autoload.MainHelper.checkProperty(response.other.data, 'accountId'),
      address: Autoload.MainHelper.checkProperty(response.result, 'addressStreet'),
      city: Autoload.MainHelper.checkProperty(response.result, 'addressCity'),
      zip: Autoload.MainHelper.checkProperty(response.result, 'addressZipCode'),
      company: Autoload.MainHelper.checkProperty(response.result, 'addressCompany'),
      accountNo: Autoload.MainHelper.checkProperty(response.result, 'accountNo'),
    });

    this._get_UserProfilePic();
  }

  _put_userProfileInfo() {
    this.setState({skeletonLoading: true});
    const userAccountID = this.props.profile.id;
    //let btnelement = document.getElementById('save-btn-userProfile-info');
    const userInforData = {
      accountId: userAccountID,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      addressCity: this.state.city,
      addressStreet: this.state.address,
      addressZipCode: this.state.zip,
      addressCompany: this.state.company,
    };
    Autoload.HttpRequestAxios.HttpRequest(
      // Autoload.Setting.swagger_mocked_api_baseUrl + 'accounts/' + userAccountID,
      Autoload.Setting.user_api_baseurl + userAccountID,
      'PUT',
      userInforData,
      null,//btnelement.children[0],
      false,
      true,
      false,
      this,
      'handle_put_userProfileInfo',
      { userInforData },
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      },
      true
    );
  }
  handle_put_userProfileInfo(response) {
    console.log('put user info data one', response);
    let btnelement = document.getElementById('save-btn-userProfile-info');
    // this.props.Account_info(123);
    let profileData = {
      email: response.result.email,
      firstName: response.result.firstName,
      lastName: response.result.lastName,
      phoneNumber: response.result.phoneNumber,
      accountId: response.result.accountId
    };

    if(response.status){
      window.Intercom("update", {
        api_base: "https://api-iam.intercom.io",
        app_id: "vvrs5bx3",
        name: `${profileData.firstName} ${profileData.lastName}`, // Full name
        email: profileData.email, // Email address
        'Company': response.other.userInforData.addressCompany
      });
      window.Intercom('trackEvent', 'profile-updated');
      this.props.setProfileData({ ...this.props.profile, ...profileData });

      showNotification({
        id: 'save_user_info_successful',
        icon: <img src={GreenTick} alt='green-tick'/>,
        message: 'Die Änderungen wurden übernommen.',
      });

      if (this.state.file) {
        if (this.state.fileToUpload) {
          this.putUserPicture(this.props.profile.id, response.other.Profiledata);
        } else {
          // update api run
          this.update_User_Profile(response.other.Profiledata);
        }
      }

      this._fetch_UserInfoData();
    }else{
      console.log('response.message: ', response.message);
      if(response.message === 'EMAIL_EXISTS'){
        showNotification({
          id: 'save_user_info_failed',
          message: 'Es ist ein Fehler aufgetreten. Die Email-Adresse ist nicht verfügbar.',
          icon: <img src={RedCross} alt='red-tick'/>,
        });
        this.setState({email: this.props.profile.email})
      }else{
        showNotification({
          id: 'save_user_info_failed',
          message: 'Die Änderungen wurden nicht gespeichert.',
          icon: <img src={RedCross} alt='red-tick'/>,
        });
      }
    }
    // Autoload.HttpRequestAxios.HttpRequest(
    //   Autoload.Setting.account_api_baseurl + response.result.accountId,
    //   // Autoload.Setting.swagger_mocked_api_baseUrl + 'accounts/' + response.accountId,
    //   'PUT',
    //   response.other.userInforData,
    //   btnelement.children[0],
    //   false,
    //   true,
    //   false,
    //   this,
    //   'handle_put_userProfileInfo_two',
    //   { Profiledata: response.other.userInforData },
    //   false,
    //   '',
    //   {
    //     'Content-Type': 'application/json',
    //     Authorization: Autoload.Utils.getAuthToken(),
    //   }
    // );
    

    this.setState({skeletonLoading: false});
  }
  handle_put_userProfileInfo_two(response) {
    console.log('put api two response', response);
    if (response.status === true && response.statuscode === 401) {
      localStorage.clear();
      window.location.reload();
    }

    if (response.status === true && response.statuscode === 200) {
      console.log('put apis working fine ');
    }
    // ;
    if (this.state.file) {
      if (this.state.fileToUpload) {
        this.putUserPicture(this.props.profile.id, response.other.Profiledata);
      } else {
        // update api run
        this.update_User_Profile(response.other.Profiledata);
      }
    } else {
      this.deleteUserPicture(this.props.profile.id, response.other.Profiledata);
    }
  }

  update_User_Profile(updatedUserData) {
    var Data_to_update = {
      firstName: checkProperty(updatedUserData, 'firstName'),
      lastName: checkProperty(updatedUserData, 'lastName'),
      city: checkProperty(updatedUserData, 'addressCity'),
      address: checkProperty(updatedUserData, 'addressStreet'),
      zip: checkProperty(updatedUserData, 'addressZipCode'),
      company: checkProperty(updatedUserData, 'addressCompany'),
      email: checkProperty(updatedUserData, 'email'),
      id: checkProperty(updatedUserData, 'accountId'),
    };
    this.props.updateUserProfile(Data_to_update);
    console.log('data', Data_to_update);
  }
  putUserPicture(id, updatedUserData) {
    const payload = new FormData();
    payload.append('file', this.state.file ? this.state.fileToUpload : null);
    let btnelement = document.getElementById('save_userProfile_picture');
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.user_api_baseurl + id + '/picture',
      'PUT',
      payload,
      btnelement.children[0],
      false,
      true,
      false,
      this,
      'put_User_picture_Response',
      { updatedUserData: updatedUserData },
      false,
      '',
      { 'Content-Type': 'application/json', Authorization: Autoload.Utils.getAuthToken() }
    );
  }

  put_User_picture_Response(response) {
    console.log('put_User_picture_Response', response);
    if (response.status === false && response.message !== 'Not Found') {
      // Autoload.Redirection.redirection_error();
      Autoload.DataHandlerGeneral.GeneralErrorMessage(
        true,
        lang.newKeys.text_error_not_found
        // + response.message
      );
    }
    if (response.status === true && response.statuscode === 401) {
      localStorage.clear();
      window.location.reload();
    }

    if (response.status === true && response.statuscode === 200) {
      this.props.updateProfilePicture(response.result);
    }
    // ;
    // this.updateProperties(response.other.updated_property_response);
  }
  deleteUserPicture(id, updatedUserData) {
    let btnelement = document.getElementById('delete-user-profile-button_id');
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.user_api_baseurl + id + '/picture',
      'DELETE',
      null,
      btnelement.children[0],
      false,
      true,
      false,
      this,
      'handle_delete_UserProfile_Picture',
      { cancelbtn: btnelement.children[0], updatedUserData: updatedUserData },
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  handle_delete_UserProfile_Picture(response) {
    // ;
    console.log('handle_delete_UserProfile_Picture', response);
    // ;
    if (response.status === false && response.message !== 'Not Found') {
      // Autoload.Redirection.redirection_error();
      Autoload.DataHandlerGeneral.GeneralErrorMessage(
        true,
        lang.newKeys.text_error_not_found
        // + response.message
      );
    }
    this.setState({
      file: null,
    });
    //update  api run
    // this.update_User_Profile(response.other.Profiledata);
  }

  deleteAccount(id) {
    let btnelement = document.getElementById('delete-user-profile');
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.account_api_baseurl + id + '/DeActivate',
      'PUT',
      null,
      btnelement.children[0],
      false,
      true,
      false,
      this,
      'handle_delete_userAccount',
      { cancelbtn: btnelement.children[0] },
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  handle_delete_userAccount(response) {
    console.log('delete user account', response);
    // debugger;
    if (response.status === false && response.message !== 'Not Found') {
      // Autoload.Redirection.redirection_error();
      Autoload.DataHandlerGeneral.GeneralErrorMessage(
        true,
        lang.newKeys.text_error_not_found
        // + response.message
      );
    }
    this.deleteUserPicture(this.props.profile.id);
    window.Intercom("update", {
      api_base: "https://api-iam.intercom.io",
      app_id: "vvrs5bx3",
      'Account Deleted': true
    });
    window.Intercom('trackEvent', 'account-deleted');
    // Autoload.MainHelper.redirection('/login');

    Autoload.Utils.clear_session_data();
    window.location.href = '/login';
  }

  handlePictureUpload = (e) => {
    e.preventDefault();
    if (e.target.files[0] && typeof e.target.files[0] != 'undefined') {
      let fileTypeErr = false;
      let fileDimensionErr = false;
      const file = e.target.files[0];
      // var pattern = /image-*/;
      // console.log('file type', file, file.type)
      if (!file.type.includes('image')) {
        fileTypeErr = true;
      }
      var reader = new FileReader();
      reader.readAsDataURL(file);
      const classContext = this;
      reader.onload = function (e) {
        var image = new Image();
        image.src = e.target.result;
        image.onload = function () {
          var height = this.height;
          var width = this.width;
          // console.log('width', this.width, 'height', this.height)
          if (height < 240 || width < 240) {
            fileDimensionErr = true;
          }
          if (fileTypeErr || fileDimensionErr) {
            const msg = `Invalid file ${fileTypeErr ? 'type' : ''} ${fileTypeErr && fileDimensionErr ? 'and' : ''} ${fileDimensionErr ? 'dimension' : ''
              }`;
            classContext.setState({
              fileUploadErr: { state: true, msg },
            });
          } else {
            classContext.setState({
              file: URL.createObjectURL(file),
              fileToUpload: file,
              fileUploadErr: { state: false, msg: '' },
            });
            classContext.savePersonalChanges();
          }
        };
      };
    }
  };
  // _getProfilePicture() {
  //   // const propertyID = this.props.propertyData.id;
  //   Autoload.HttpRequestAxios.HttpRequest(
  //     Autoload.Setting.swagger_mocked_api_baseUrl + 'users/1/picture',
  //     'GET',
  //     null,
  //     null,
  //     false,
  //     true,
  //     false,
  //     this,
  //     '_getProfilePictureResponse',
  //     null,
  //     false,
  //     '',
  //     {
  //       'Content-Type': 'application/json',
  //       Authorization: Autoload.Utils.getAuthToken(),
  //     }
  //   );
  // }

  _getProfilePictureResponse(response) {
    if (response.status == true && response.statuscode == 200) {
      this.setState({ file: response.result });
    }
  }

  onInputChange = (field, value) => {
    this.setState({ [field]: value });
  };

  handlePictureUpload_general = (e) => {
    const _put_userProfileInfo = ()=> this._put_userProfileInfo();
    e.preventDefault();
    if (e.target.files[0] && typeof e.target.files[0] != 'undefined') {
      let fileTypeErr = false;
      let fileDimensionErr = false;
      const file = e.target.files[0];
      if (!file.type.includes('image')) {
        fileTypeErr = true;
      }
      var reader = new FileReader();
      reader.readAsDataURL(file);
      const classContext = this;
      const props = this.props;
      reader.onload = function (e) {
        var image = new Image();
        image.src = e.target.result;
        image.onload = function () {
          var height = this.height;
          var width = this.width;
          if (height < 240 || width < 240) {
            fileDimensionErr = true;
          }
          if (fileTypeErr || fileDimensionErr) {
            const msg = `Invalid file ${fileTypeErr ? 'type' : ''} ${fileTypeErr && fileDimensionErr ? 'and' : ''} ${fileDimensionErr ? 'dimension' : ''
              }`;
            classContext.setState({ fileUploadErr: { state: true, msg } });
          } else {
            if(file.size > 300000){
              Autoload.Utils.compressImage(file, res=> 
              classContext.setState({
                file: URL.createObjectURL(file),
                fileToUpload: res,
                fileUploadErr: { state: false, msg: '' },
              }, ()=> _put_userProfileInfo()));
            }else{
              classContext.setState({
                file: URL.createObjectURL(file),
                fileToUpload: file,
                fileUploadErr: { state: false, msg: '' },
              }, ()=> _put_userProfileInfo());
            }
            // const payload = new FormData();
            // payload.append('fil', file, file.name);
            // Autoload.HttpRequestAxios.HttpRequest(
            //   Autoload.Setting.property_api_base_url + 'Properties/' + props.propertyData.id + '/Picture',
            //   'PUT',
            //   payload,
            //   null,
            //   false,
            //   true,
            //   false,
            //   classContext,
            //   '_handlePictureUpload',
            //   null,
            //   false,
            //   '',
            //   {
            //     'Content-Type': 'application/json',
            //     Authorization: Autoload.Utils.getAuthToken(),
            //   }
            // );
          }
        };
      };
    }
  };

  validatePersonalData = () => {
    this.setState({
      firstnameErr: false,
      lastnameErr: false,
      companyErr: false,
      addressErr: false,
      emailErr: false,
      cityErr: false,
      zipErr: false,
    });
    let emailErr,
      firstnameErr,
      addressErr,
      zipErr,
      companyErr,
      cityErr,
      lastnameErr,
      fileErr = false;
    //const validName = /^[a-zA-Z]+(?:[\s]+[a-zA-Z]+)*$/;
    const validName = /[^a-zA-Z0-9äöüÄÖÜß]/;
    const validEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!this.state.email || !validEmail.test(this.state.email)) {
      emailErr = true;
    }
    if (!this.state.firstName || !validName.test(this.state.firstName)) {
      firstnameErr = true;
    }
    if (!this.state.lastName || !validName.test(this.state.lastName)) {
      lastnameErr = true;
    }
    if (!this.state.address) {
      addressErr = true;
    }
    if (!this.state.city) {
      cityErr = true;
    }
    if (!this.state.zip || this.state.zip === null) {
      zipErr = true;
    }
    if (this.state.company && !validName.test(this.state.company)) {
      companyErr = true;
    }
    if (
      this.state.fileToUpload !== null &&
      this.state.fileToUpload !== '' &&
      this.state.fileToUpload !== undefined &&
      this.state.fileUploadErr.state
    ) {
      fileErr = true;
    }

    this.setState({ emailErr, firstnameErr, addressErr, zipErr, companyErr, cityErr, lastnameErr });
    if (emailErr || firstnameErr || addressErr || zipErr || companyErr || cityErr || fileErr || lastnameErr) {
      return false;
    }
    this.savePersonalChanges();
    return true;
  };

  // savePersonalChanges = () => {
  //   const { name, email } = this.state;
  //   let data = {
  //     userId: 1,
  //     accountId: this.props.profile.id,
  //     firstName: name.indexOf(' ') > 0 ? name.substr(0, name.indexOf(' ')) : name,
  //     lastName: name.indexOf(' ') > 0 ? name.substr(name.indexOf(' ') + 1) : '',
  //     email: email,
  //   };
  //   const userAccountID = this.props.profile.id;
  //   Autoload.HttpRequestAxios.HttpRequest(
  //     Autoload.Setting.swagger_mocked_api_baseUrl + 'users/' + userAccountID,
  //     'PUT',
  //     null,
  //     null,
  //     false,
  //     true,
  //     false,
  //     this,
  //     'handle_update_profile',
  //     null,
  //     false,
  //     '',
  //     {
  //       Authorization: Autoload.Utils.getAuthToken(),
  //     }
  //   );
  //   // const payload = { ...this.state };
  //   // this.props.updateUserProfile(payload);
  // };
  // handle_update_profile = (response) => {};
  ShowLoader = () => {
    console.log('show loader abc');
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'none';
    // var emptyDiv = document.getElementById('empty-user-account-personal-details');
    // emptyDiv.style.display = 'none';
    // var dataDiv = document.getElementById('response-user-account-personal-details');
    // dataDiv.style.display = 'block';
    this.setState({skeletonLoading: false});  
  };

  render() {
    console.log(this.props.profile, this.state.accountID, 'this.props.profile');
    const {isMobile, skeletonLoading, emailChangeAlertOpen} = this.state;
    return (
      <>
        <div id="empty-user-account-personal-details"></div>
        <div className="account-width personal" id="response-user-account-personal-details" style={{ display: 'block', width: isMobile ? '100%' : '380px' }}>
          <Row style={{marginBottom: '18px', marginTop: '25px'}}>
                <Col xs={12}>
                  <div className="payment-heading-main" style={{fontSize: '16px'}}>
                    {/* {lang.general.text_direct_debit} */}
                    Nutzerprofil
                    {/* {this.state.heading === 'creditcard' ? 'Kreditkarte' : lang.general.text_direct_debit} */}
                  </div>
                </Col>
          </Row>
          {/* <Form> */}
          <Row>
            <Col xs={12}>
              <div className="profile-image-container">
                {skeletonLoading &&
                <Skeleton visible={skeletonLoading} circle mb={'xl'} height={108} width={108} style={{marginRight: '24px', marginBottom: '16px'}}/>}
                <div className="profile-image" style={{height:"108px", minWidth:"108px", display: skeletonLoading ? 'none' : 'block'}}>
                  <img alt="profile" src={this.state.file ? this.state.file : Avatar} height="108px" width="108px" style={{objectFit: 'cover'}} />
                </div>
                <div className="upload-container">
                  <div className="property-input-box">
                    <div id="save_userProfile_picture">
                    {skeletonLoading?
                      <Skeleton visible={skeletonLoading} width={125} height={36} style={{marginBottom: '13px'}}/>
                      :  
                      <label className="upload-btn gray-btn"
                      style={{
                        fontWeight: '500',
                        fontSize: '14px',
                        fontFamily: 'sans-serif',
                        padding: '7px 11px'
                      }} htmlFor="uploadProfileImage_general">
                        {lang.text_upload_photo}
                      </label>}
                      <input
                        id="uploadProfileImage_general"
                        name="uploadProfileImage_general"
                        style={{ display: 'none' }}
                        type="file"
                        accept="image/*"
                        onChange={this.handlePictureUpload_general}
                      />
                    </div>
                    <div id="delete-user-profile-button_id">
                      <button
                        className="delete-propertypicture-button"
                        // onClick={() => this.deletePropertyPicture(this.props.propertyData.id)}
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState({ file: '', fileToUpload: '', fileUploadErr: { state: false, msg: '' } }, ()=> this.deleteUserPicture(this.props.profile.id, null));
                        }}
                      >
                        <Skeleton visible={skeletonLoading}>
                          {lang.text_delete}
                        </Skeleton>
                      </button>
                    </div>
                  </div>
                  <span className="file-desc">{lang.text_upload_photo_ins}</span>
                  <span className="file-err">{this.state.fileUploadErr.state && this.state.fileUploadErr.msg}</span>
                </div>
              </div>
              {/* <div className="profile-image-container">
                <div className="profile-image">
                  <img
                    // style={{objectFit:'contain'}}
                    alt="profile"
                    src={
                      this.state.file
                        ? this.state.file
                        : 'https://cdn.pixabay.com/photo/2018/04/18/18/56/user-3331257__340.png'
                    }
                  />
                </div>
                <div className="upload-container">
                  <label className="upload-btn" htmlFor="uploadProfileImage">
                    {lang.text_upload_photo}
                  </label>
                  <input
                    id="uploadProfileImage"
                    name="uploadProfileImage"
                    style={{ display: 'none' }}
                    type="file"
                    accept="image/*"
                    onChange={this.handlePictureUpload}
                  />
                  <span className="file-desc"> {lang.text_upload_photo_ins} </span>
                  <span className="file-err">{this.state.fileUploadErr.state && this.state.fileUploadErr.msg}</span>
                </div>
              </div> */}
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <Form.Group>
                <Form.Label> {lang2.text_first_name} </Form.Label>
                <Skeleton visible={skeletonLoading}>
                  <Form.Control
                  id="person-name"
                  name="person-name"
                  isInvalid={this.state.firstnameErr}
                  type="text"
                  value={this.state.firstName}
                  onChange={(e) => {
                    this.onInputChange('firstName', e.target.value);
                  }}
                  onBlur={(e)=> {
                    e.preventDefault();
                    e.target.value !== this.state.prevState.firstName && this._put_userProfileInfo();
                  }}
                  />
                </Skeleton>
                <Form.Control.Feedback className="err" type="invalid">
                  {lang.text_invalid_full_name}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={6}>
              <Form.Group>
                <Form.Label> {lang2.text_last_name} </Form.Label>
                <Skeleton visible={skeletonLoading}>
                <Form.Control
                  id="person-name"
                  name="person-name"
                  isInvalid={this.state.lastnameErr}
                  type="text"
                  value={this.state.lastName}
                  onChange={(e) => {
                    this.onInputChange('lastName', e.target.value);
                  }}
                  onBlur={(e)=> {
                    e.preventDefault();
                    e.target.value !== this.state.prevState.lastName && this._put_userProfileInfo();
                  }}
                />
                </Skeleton>
                <Form.Control.Feedback className="err" type="invalid">
                  {lang.text_invalid_full_name}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          {/* <Row>
            <Col xs={12}>
              <Form.Group>
                <Form.Label> {lang.text_company_optional} </Form.Label>
                <Skeleton visible={skeletonLoading}>
                <Form.Control
                  id="person-company"
                  name="person-company"
                  isInvalid={this.state.companyErr}
                  type="text"
                  value={this.state.company}
                  onChange={(e) => {
                    this.onInputChange('company', e.target.value);
                  }}
                />
                </Skeleton>
                <Form.Control.Feedback className="err" type="invalid">
                  {' '}
                  {lang.text_invalid_company_name}{' '}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row> */}
          <Row>
            <Col xs={12}>
              <Form.Group>
                <Form.Label className='tooltips account-user-email-address'> {`${lang.text_email} (Nutzername)`} </Form.Label>
                <Skeleton visible={skeletonLoading}>
                <Form.Control
                  id="person-email"
                  name="person-email"
                  isInvalid={this.state.emailErr}
                  type="text"
                  value={this.state.email}
                  onChange={(e) => {
                    this.onInputChange('email', e.target.value);
                  }}
                  onBlur={(e)=> {
                    e.preventDefault();
                    e.target.value !== this.state.prevState.email && this.setState({emailChangeAlertOpen: true});
                  }}
                />
                </Skeleton>
                <Form.Control.Feedback className="err" type="invalid">
                  {' '}
                  {lang.text_invalid_email}{' '}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          {/* <Row>
            <Col xs={12}>
              <Form.Group>
                <Form.Label> {lang.text_address} </Form.Label>
                <Skeleton visible={skeletonLoading}>
                <Form.Control
                  id="person-address"
                  name="person-address"
                  isInvalid={this.state.addressErr}
                  type="text"
                  value={this.state.address}
                  onChange={(e) => {
                    this.onInputChange('address', e.target.value);
                  }}
                />
                </Skeleton>
                <Form.Control.Feedback className="err" type="invalid">
                  {' '}
                  {lang.text_invalid_address}{' '}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={8}>
              <Form.Group>
                <Form.Label> {lang.text_city} </Form.Label>
                <Skeleton visible={skeletonLoading}>
                <Form.Control
                  id="person-city"
                  name="person-city"
                  isInvalid={this.state.cityErr}
                  type="text"
                  value={this.state.city}
                  onChange={(e) => {
                    this.onInputChange('city', e.target.value);
                  }}
                />
                </Skeleton>
                <Form.Control.Feedback className="err" type="invalid">
                  {' '}
                  {lang.text_invalid_city}{' '}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={4}>
              <Form.Group>
                <Form.Label> {lang.text_zip_code} </Form.Label>
                <Skeleton visible={skeletonLoading}>
                <Form.Control
                  id="person-zip"
                  name="person-zip"
                  isInvalid={this.state.zipErr}
                  type="number"
                  value={this.state.zip}
                  onChange={(e) => {
                    this.onInputChange('zip', e.target.value);
                  }}
                />
                </Skeleton>
                <Form.Control.Feedback className="err" type="invalid">
                  {' '}
                  {lang.text_invalid_zip_code}{' '}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row> */}

          {/* <Row style={{ marginTop: '15px' }}> */}
          {/* <Col xl={4} lg={4} md={3} xs={12}> */}
          {/* <div style={{ display: 'flex', marginTop: '15px', justifyContent: 'space-between' }}>
            <div>
            <Skeleton visible={skeletonLoading}>
              <ColorButton
                onClick={() => this.props.history.push('/dashboard')}
                className="gray-btn user-profile-cancel-btn"
                buttonStyle={{width: '100px'}}
                text={lang.text_cancel}
              />
            </Skeleton>
            </div>
            <div id="save-btn-userProfile-info">
            <Skeleton visible={skeletonLoading}>
              <ColorButton
                onClick={(e) => {
                  e.preventDefault();
                  this._put_userProfileInfo();
                }}
                buttonConfig={{ type: 'submit' }}
                className="purple-btn user-profile-cancel-btn"
                buttonStyle={{width: '100px'}}
                text={lang.text_Save_Changes}
              />
            </Skeleton>
            </div>
          </div> */}
          <Group grow>
            <ProfilePassword/>
          </Group>
          {/* </Col>
          </Row> */}

          {/* </Row> */}
          {/* </Form> */}

          {/* <div className="danger-zone">
            <HeadingContainer heading={lang.text_danger_zone} className="danger-heading" />
            <div id="delete-user-profile">
            <Skeleton visible={skeletonLoading}>
              <TransparentButton
                className="danger-btn"
                onClick={() => {
                  this.toggleDeleteAccountModal();
                  // this._delete_userProfileAccount(this.props.profile.id);
                }}
                text={lang.text_delete_your_account}
              />
            </Skeleton>
            </div>
          </div> */}
        </div>
        <DeleteModal
          open={this.state.deleteAccountModal}
          className="delete-rent-modal"
          toggle={this.toggleDeleteAccountModal}
          record={this.state.accountID}
          handleDelete={() => this.deleteAccount(this.state.accountID)}
          // showInfo={[this.props.propertyData.name,]}
          type={'Nutzerprofil'}
          showtext={false}
          typeText={
            <div>
              <p>
                Sind Sie sicher, dass Sie Ihr hellohousing Konto löschen wollen? Sie können die hellohousing App dann nicht mehr nutzen und auch nicht mehr auf Ihre Daten zugreifen.
              </p>
              <p>
                <span style={{fontStyle: 'italic', fontWeight: 'bold'}}>Hinweis:</span> hellohousing speichert Ihre Daten für weitere 90 Tage. Danach werden die Daten <span style={{fontWeight: 'bold'}}>automatisch und unwiderruflich</span> gelöscht. Sie können sich an den hellohousing Service (<a href='mailto:service@hellohousing.de'>service@hellohousing.de</a>) wenden, um Ihre Daten sofort löschen zu lassen oder eine Wiederherstellung des Nutzerkontos zu beantragen.
              </p>
              <p>
                Sind Sie sicher, dass Sie mit der Löschung des Nutzerkontos fortfahren wollen?
              </p>
            </div>
          }
          modalId="delete-data-property"
          showDeleteText={false}
          textShowModal='Nutzerkonto löschen'
        />
        {emailChangeAlertOpen &&
        <AlertModal
        open={emailChangeAlertOpen}
        heading={'Email-Adresse Nutzerprofil ändern'}
        text={<span>Wenn Sie Ihre Email-Adresse ändern, dann ändert sich auch Ihre Zugangsdaten zu hellohousing. Zukünftig müssen Sie die Email-Adresse <span style={{fontWeight: '500'}}>{this.state.email}</span> bei der Anmeldung eingeben.</span>}
        cancel
        rightButtonText={'Ändern'}
        toggle={()=> this.setState({emailChangeAlertOpen: false, email: this.state.prevState.email})}
        onCancel={()=> this.setState({emailChangeAlertOpen: false, email: this.state.prevState.email})}
        onClick={()=> this.setState({emailChangeAlertOpen: false}, ()=> this._put_userProfileInfo())}
        />}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profile: state.generalReducer.profile,
    AccountApi: state.generalReducer.account_api_id,
  };
};

const actions = {
  updateUserProfile,
  Account_info,
  updateProfilePicture,
  setProfileData,
};

export default connect(mapStateToProps, actions)(PersonalDetails);
