import React, { useState } from 'react';
import DownArrowIcon from '../../../../../assets/images/module/general/DownArrowIcon.svg';
import Autoload from '../../../../Autoload';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];

export default function SortAlphabetically({ className, handleAscending, handleDescending }) {
  const [ascendingOrder, setAscendingOrder] = useState(true);

  const handleSort = () => {
    if (ascendingOrder) {
      //ascending true
      handleDescending();
    } else {
      //ascending false
      handleAscending();
    }
    setAscendingOrder(!ascendingOrder);
  };

  return (
    <div className={className}  onClick={() => handleSort()} style={{cursor:"pointer"}} >
      <span className="sort-lable">{lang.general.text_sort}: </span>
      {/* text change */}
      <span className="sort-value">{lang.newKeys.text_from} {`${ascendingOrder ? lang.general.text_a_z : lang.general.text_z_a}`}</span>
      {/* arrow change */}
      <img
        className="sort-icon"
        style={!ascendingOrder ? { transform: 'rotate(180deg)' } : {}}
        // onClick={() => handleSort()}
        src={DownArrowIcon}
        alt="arrow"
      />
    </div>
  );
}
