import { Group, Stack, Text } from '@mantine/core';
import {
  Table, 
  TableBody, 
  TableCell,
  TableContainer,
  TableHead,
  TableRow as Tr,
  Paper,
  Checkbox
} from '@material-ui/core';
import dayjs from 'dayjs';
import TableRow from './TableRow';
import { useEffect, useState } from 'react';
import EmptyState from '../../../neoverv/components/EmptyState';
import { ColorButton, EmptyStateContainer } from '../../../neoverv';
import PrintIcon from '../../../assets/images/module/general/PrintIcon.svg';
import MeterIcon from '../../../assets/images/module/general/blueEmptyMeter.svg';
import UnitsIcon from '../../../assets/images/module/general/UnitsIcon.svg';
import MultipleTenants from '../../../assets/images/module/general/MultipleTenants.svg';
import MovedInIcon from '../../../assets/images/module/general/MovedInIcon.svg';
import MovedOutIcon from '../../../assets/images/module/general/MovedOutIcon.svg';
import CalendarIcon from '../../../assets/images/module/general/CalendarIcon.svg';
import EmptyApartmentIcon from '../../../assets/images/module/general/emptyApartmentIcon2.svg';

const tableHeadCellStyling = {
  color: 'rgb(73, 80, 87)',
  padding: '14px 16px',
  fontWeight: '500',
  whiteSpace: 'nowrap',
  fontSize: '14px',
  lineHeight: '20px',
  border: '1px solid #DADAE6',
  textTransform: 'capitalize',
  //height: '48px',
  fontFamily: 'Inter, sans-serif',
  maxWidth: '135px'
}

const tableMobileHeadCellStyling = {
  color: '#85939d', 
  fontSize: '14px', 
  border: 'none', 
  padding: '0 16px', 
  fontFamily: 'Inter, sans-serif', 
  fontWeight: '400'
}

const renderOccasionIcon = (occasionName) => {
  switch (occasionName) {
    case 'Moved in':
    case 'move-in':
      return MovedInIcon;
    case 'Moved out':
    case 'move-out':
      return MovedOutIcon;
    case 'Annual':
    case 'annual':
      return CalendarIcon;
    case 'empty-tenant-move-out':
      return EmptyApartmentIcon;
    default:
      break;
  }
};

const SingleTable = ({ 
  data, 
  viewState, 
  onDateChange,
  onLastReadingEdit,
  onCurrentReadingEdit,
  onConsumptionChange,
  onNotesEdit,
  rentalUnitName,
  rentalUnitId,
  tenantFullName,
  periodStart,
  periodEnd,
  occasion,
  occasionDate,
  onAddMeter,
  editableIds,
  isMobile,
  billingPeriodId,
  billingPeriods,
  onNegativeValue,
  selectedTenant,
  selectedOccasion,
  selectedUnit,
  readings,
  index,
  rentalUnits,
  history,
  handleDataSaving,
  disableMobileUpdate,
  onBlur,
  onTableSelect,
  showArchivedMeters,
  vacant
}) => {
  const [filteredList, setFilteredList] = useState([]);
  const [tableSelected, setTableSelected] = useState(false);
  const printOccasion = (slug) => {
    switch (slug) {
      default: case 'annual':
        return 'Jahresablesung';
      case 'move-in':
        return 'Einzug';
      case 'move-out':
        return 'Auszug';
      case 'empty-tenant-move-out':
        return 'Leerstand';
    }
  }

  const sortMeterReadings =(meterReadings)=>{
    return meterReadings.sort((a, b) => {
      if (a.meter.allocationKey.allocationKeyName < b.meter.allocationKey.allocationKeyName) {
        return -1; // a should come before b
      } else if (a.meter.allocationKey.allocationKeyName > b.meter.allocationKey.allocationKeyName) {
        return 1; // a should come after b
      } else {
        // a and b have the same age, compare by score
        if (a.meter.meterNo < b.meter.meterNo) {
          return -1;
        } else if (a.meter.meterNo > b.meter.meterNo) {
          return 1;
        }
      }
    });
  }

  const noRentalUnitsEmptyState= (
  <Stack>
    <Group spacing={10} style={{marginTop: isMobile ? '16px' : '32px', marginLeft: '2px'}}>
      <img src={MultipleTenants} style={{marginTop: '3px'}}/> 
      <span style={{fontSize: '16px', fontWeight: '600', marginTop: '3px', color: '#0E0E1D'}}>{'Ablesung Mieterzähler'}</span>
    </Group>
    {/* <EmptyStateContainer
      heading={'Ablesungen sind noch nicht verfügbar.'}
      subheading={'Legen Sie zunächst Mieteinheiten an, um Ablesungen durchzuführen.'}
      className="empty-meter-reading"
      icon={MeterIcon}
      styleMainContainer={{marginTop: '40px', background: '#fff'}}
      btnHandler={() => history.push('/user')}
      btnText={'Einheit hinzufügen'}
    /> */}
    <EmptyState
        mainText={'Ablesungen sind noch nicht verfügbar.'}
        subText={'Legen Sie zunächst Mieteinheiten an, um Mieterablesungen durchzuführen.'}
        buttonText="Einheit hinzufügen"
        buttonAction={()=> history.push('/user')}
      />
  </Stack>);
  
  useEffect(() => {
    console.log('selectedTenant: ', vacant);
    let negativeArr = [];
    setFilteredList(sortMeterReadings(data.meterReadings)
    //.sort((a, b) => a.meter.allocationKey.allocationKeyName.localeCompare(b.meter.allocationKey.allocationKeyName))
    .filter((meterReading) =>
      meterReading.meter.meterNo.toUpperCase().includes(viewState.search.toUpperCase()) ||
      meterReading.meter.allocationKey.allocationKeyName.toUpperCase().includes(viewState.search.toUpperCase()) ||
      dayjs(meterReading.meterReadingDate).format('DD.MM.YYYY').includes(viewState.search)
    ).map((meterReading) => {
      const consumption = meterReading.currentValue !== null ? meterReading.currentValue - meterReading.startValue : null; // Just calculate consumption
      ((meterReading.currentValue !== null && meterReading.currentValue < meterReading.startValue) || consumption < 0) && negativeArr.push(true)
    }))
    onNegativeValue(negativeArr);
  }, [viewState.search, selectedTenant, selectedOccasion, selectedUnit, readings]);
  return (
    filteredList && filteredList.length ?
    <Stack spacing={16}>
      {isMobile ?
      <Stack sx={{ marginTop: index > 1 ? 32 : 20 }} spacing={isMobile ? 5 : 24}>
        {rentalUnitName && 
        <Group spacing={8}>
          <Text sx={(theme) => ({ color: theme.colors.lightText })}>Einheit:</Text>
          <Text>{rentalUnitName}</Text>
        </Group>}
        {tenantFullName &&
        <Group spacing={8}>
          <Text sx={(theme) => ({ color: theme.colors.lightText })}>Mieter:</Text>
          <Text>{tenantFullName}</Text>
        </Group>}
        <Group spacing={8}>
          <Text sx={(theme) => ({ color: theme.colors.lightText })}>Ablesezeitraum:</Text>
          <Text>{dayjs(periodStart).format('DD.MM.YYYY')} - {dayjs(periodEnd).format('DD.MM.YYYY')}</Text></Group>
        <Group spacing={8} className={'meter-management-readings-table-information'}>
          <Text sx={(theme) => ({ color: theme.colors.lightText })}>Ableseanlass:</Text>
          <img src={renderOccasionIcon(occasion)} />
          <Text>{vacant && occasion === 'move-in' ? '-' : printOccasion(occasion)}</Text>
        </Group>
        <Group spacing={8} className={'meter-management-readings-table-information'}>
          <Text sx={(theme) => ({ color: theme.colors.lightText })}>Datum:</Text>
          <Text>{occasion === 'empty-tenant-move-out' ? dayjs(periodEnd).format('DD.MM.YYYY') : dayjs(occasionDate).format('DD.MM.YYYY')}</Text>
        </Group>
      </Stack>
      :
      <Group sx={{ marginLeft: 0, marginTop: index > 1 ? 32 : 20 }} spacing={isMobile ? 5 : 24}>
        <Group style={{marginRight: '-15px'}}>
          <Checkbox
            checked={tableSelected}
            style={{color: tableSelected ? '#5555FF' : '#9A9AB5', padding: '0px'}}
            onClick={()=> {
              setTableSelected(!tableSelected);
              onTableSelect({readingsId: data.id, occupationId: data.occupationId});
            }}
          />
        </Group>
        {rentalUnitName && 
        <Group spacing={8}>
          <Text sx={(theme) => ({ color: theme.colors.lightText })}>Einheit:</Text>
          <Text>{rentalUnitName}</Text>
        </Group>}
        {tenantFullName &&
        <Group spacing={8}>
          <Text sx={(theme) => ({ color: theme.colors.lightText })}>Mieter:</Text>
          <Text>{tenantFullName}</Text>
        </Group>}
        <Group spacing={8}>
          <Text sx={(theme) => ({ color: theme.colors.lightText })}>Ablesungszeitraum</Text>
          <Text sx={{fontWeight: isMobile && '400'}}>{dayjs(periodStart).format('DD.MM.YYYY')} - {dayjs(periodEnd).format('DD.MM.YYYY')}</Text></Group>
        <Group spacing={8} className={'meter-management-readings-table-information'}>
          <Text sx={(theme) => ({ color: theme.colors.lightText })}>Ableseanlass:</Text>
          <img src={renderOccasionIcon(occasion)} />
          <Text>{vacant && occasion === 'move-in' ? '-' : printOccasion(occasion)}</Text>
        </Group>
        <Group spacing={8} className={'meter-management-readings-table-information'}>
          <Text sx={(theme) => ({ color: theme.colors.lightText })}>Datum:</Text>
          <Text>{occasion === 'empty-tenant-move-out' ? dayjs(periodEnd).format('DD.MM.YYYY') : dayjs(occasionDate).format('DD.MM.YYYY')}</Text>
        </Group>
        {/* <Group spacing={8}>
          <ColorButton
            buttonConfig={{ target: '_blank' }}
            onClick={() => window.open(`/meterReadingPdf/${billingPeriodId}?readingsId=${data.id}`, '_blank')}
            text={'Drucken'}
            className={'gray-btn'}
            rightImage={{ img: PrintIcon, style: { width: '16px', height: '16px' } }}
          />
        </Group> */}
      </Group>}

      {data && data.meterReadings.length > 0
        &&
        <>
          {console.log('SigleTable: ', data)}
          <TableContainer 
          component={Paper} 
          style={{
            background: 'transparent', 
            width: 'auto',
            }}>
          <Table 
            style={isMobile ? {
              borderCollapse: 'separate',
              borderSpacing: '0 8px',
            } : {width: '100%', tableLayout: 'fixed'}}>

            {/* Table head */}
            <TableHead>
              {isMobile ? 
              <Tr>
                <TableCell style={tableMobileHeadCellStyling}>Nummer</TableCell>
                <TableCell style={tableMobileHeadCellStyling}>Art</TableCell>
                <TableCell style={{...tableMobileHeadCellStyling, ...{textAlign: 'right'}}}>Ablesewert</TableCell>
              </Tr>
              :
              <Tr>
                <TableCell style={{...tableHeadCellStyling, ...{width: "10%"}}}>Zählernummer</TableCell>
                <TableCell style={{...tableHeadCellStyling, ...{width: "10%"}}}>Bezeichnung</TableCell>
                <TableCell style={{...tableHeadCellStyling, ...{width: "10%"}}}>Zählertyp</TableCell>
                <TableCell style={{...tableHeadCellStyling, ...{width: "10%"}}}>Standort</TableCell>
                {/* <TableCell style={{...tableHeadCellStyling, ...{width: "10%"}}}>Ablesedatum</TableCell> */}
                {occasion !== 'move-in' && <TableCell style={{...tableHeadCellStyling, ...{textAlign: 'right', width: "10%"}}}>Startwert</TableCell>}
                <TableCell style={{...tableHeadCellStyling, ...{textAlign: 'right', width: "10%"}}}>Ablesewert</TableCell>
                {occasion !== 'move-in' && <TableCell style={{...tableHeadCellStyling, ...{textAlign: 'right', width: "10%"}}}>Verbrauch</TableCell>}
                <TableCell style={{...tableHeadCellStyling, ...{width: '6%'}}}>Einheit</TableCell>
                <TableCell style={{...tableHeadCellStyling, ...{width: "10%", borderLeft: occasion === 'move-in' && 'none', borderRight: occasion === 'move-in' && 'none'}}}>Notiz</TableCell>
                {occasion === 'move-in' && 
                <>
                <TableCell style={{...tableHeadCellStyling, ...{textAlign: 'right', width: "10%", borderLeft: 'none', borderRight: 'none'}}}/>
                <TableCell style={{...tableHeadCellStyling, ...{textAlign: 'right', width: "10%", borderLeft: 'none'}}}/>
                </>}
              </Tr>}
            </TableHead>

            {/* Table body */}
            <TableBody style={{
              background: 'white',
              border: '1px solid rgb(218, 218, 230)'
              }}>

            {/* Reading */}
            {sortMeterReadings(data.meterReadings)
    //.sort((a, b) => a.meter.allocationKey.allocationKeyName.localeCompare(b.meter.allocationKey.allocationKeyName))
    .filter((meterReading) =>
      meterReading.meter.meterNo.toUpperCase().includes(viewState.search.toUpperCase()) ||
      meterReading.meter.allocationKey.allocationKeyName.toUpperCase().includes(viewState.search.toUpperCase()) ||
      dayjs(meterReading.meterReadingDate).format('DD.MM.YYYY').includes(viewState.search)
    ).map((meterReading, subIndex) => {
                const consumption = (meterReading.startValue?.value !== null && meterReading.currentValue !== null) ? meterReading.currentValue - meterReading.startValue?.value : null; // Just calculate consumption
                //let tableRow = 
                return (
                  <TableRow 
                    rowReading={meterReading} 
                    subIndex={subIndex} 
                    occasionDate={occasionDate}
                    consumption={consumption} 
                    onDateChange={onDateChange}
                    onLastReadingEdit={onLastReadingEdit}
                    onCurrentReadingEdit={onCurrentReadingEdit} 
                    onConsumptionChange={onConsumptionChange}
                    onNotesEdit={onNotesEdit}
                    end={occasion === 'move-in'} 
                    key={subIndex} 
                    editableIds={editableIds}
                    isMobile={isMobile}
                    search={viewState.search}
                    startValue={meterReading.startValue}
                    currentValue={meterReading.currentValue}
                    handleDataSaving={handleDataSaving}
                    disableMobileUpdate={disableMobileUpdate}
                    onBlur={onBlur}
                  />
                )
                // if(meterReading.meter.lastBillingPeriodId === null) {
                //   return tableRow;
                // }else if(dayjs(billingPeriods.find((bp)=> bp.id === billingPeriodId).startDate).isBefore(dayjs(billingPeriods.find((bp)=> bp.id === meterReading.meter.lastBillingPeriodId).startDate))){
                //   return tableRow;
                // }else{ 
                //   if(showArchivedMeters){
                //     return tableRow;
                //   }else if(data.meterReadings.filter((mr)=> mr.meter.lastBillingPeriodId !== null).length === data.meterReadings.length && !showArchivedMeters){
                //     return (
                //     <Tr>
                //       <TableCell colspan={9}>
                //         <EmptyState
                //           mainText={'Zähler sind archiviert'}
                //           subText={'Bitte legen Sie neue Zähler an oder heben Sie die Archivierung auf'}
                //         />
                //       </TableCell>
                //     </Tr>);
                //   }
                // }
                
              })
            }
            </TableBody>
          </Table>
          </TableContainer>
          {rentalUnits === null && noRentalUnitsEmptyState}
          {isMobile && index !== (readings.length-1) && <div className='divider' />}
        </>
        }
    </Stack> 
    :
    viewState.search !== '' || viewState.search !== null &&
    <Stack spacing={16}>
      {isMobile ?
      <Stack sx={{ marginTop: 32 }} spacing={isMobile ? 5 : 24}>
        {rentalUnitName &&
        <Group spacing={8}>
          <Text sx={(theme) => ({ color: theme.colors.lightText })}>Einheit:</Text>
          <Text>{rentalUnitName}</Text>
        </Group>}
        {tenantFullName &&
        <Group spacing={8}>
          <Text sx={(theme) => ({ color: theme.colors.lightText })}>Mieter:</Text>
          <Text>{tenantFullName}</Text>
        </Group>}
        <Group spacing={8}>
          <Text sx={(theme) => ({ color: theme.colors.lightText })}>Ablesezeitraum:</Text>
          <Text>{dayjs(periodStart).format('DD.MM.YYYY')} - {dayjs(periodEnd).format('DD.MM.YYYY')}</Text></Group>
        <Group spacing={8} className={'meter-management-readings-table-information'}>
          <Text sx={(theme) => ({ color: theme.colors.lightText })}>Ableseanlass:</Text>
          <img src={renderOccasionIcon(occasion)} />
          <Text>{vacant && occasion === 'move-in' ? '-' : printOccasion(occasion)}</Text>
        </Group>
        <Group spacing={8} className={'meter-management-readings-table-information'}>
          <Text sx={(theme) => ({ color: theme.colors.lightText })}>Datum:</Text>
          <Text>{occasion === 'empty-tenant-move-out' ? dayjs(periodEnd).format('DD.MM.YYYY') : dayjs(occasionDate).format('DD.MM.YYYY')}</Text>
        </Group>
      </Stack>
      :
      <Group sx={{ marginLeft: 0, marginTop: 32 }} spacing={isMobile ? 10 : 24}>
         <Group style={{marginRight: '-15px'}}>
          <Checkbox
            checked={tableSelected}
            style={{color: tableSelected ? '#5555FF' : '#9A9AB5', padding: '0px'}}
            onClick={()=> {
              setTableSelected(!tableSelected);
              onTableSelect({readingsId: data.id, occupationId: data.occupationId});
            }}
          />
        </Group>
        {rentalUnitName &&
        <Group spacing={8}>
          <Text sx={(theme) => ({ color: theme.colors.lightText })}>Einheit:</Text>
          <Text>{rentalUnitName}</Text>
        </Group>}
        {tenantFullName &&
        <Group spacing={8}>
          <Text sx={(theme) => ({ color: theme.colors.lightText })}>Mieter</Text>
          <Text>{tenantFullName}</Text>
        </Group>}
        <Group spacing={8}>
          <Text sx={(theme) => ({ color: theme.colors.lightText })}>Ablesungszeitraum</Text>
          <Text>{dayjs(periodStart).format('DD.MM.YYYY')} - {dayjs(periodEnd).format('DD.MM.YYYY')}</Text></Group>
        <Group spacing={8} className={'meter-management-readings-table-information'}>
          <Text sx={(theme) => ({ color: theme.colors.lightText })}>Ableseanlass:</Text>
          <img src={renderOccasionIcon(occasion)} />
          <Text>{vacant && occasion === 'move-in' ? '-' : printOccasion(occasion)}</Text>
        </Group>
        <Group spacing={8} className={'meter-management-readings-table-information'}>
          <Text sx={(theme) => ({ color: theme.colors.lightText })}>Datum:</Text>
          <Text>{occasion === 'empty-tenant-move-out' ? dayjs(periodEnd).format('DD.MM.YYYY') : dayjs(occasionDate).format('DD.MM.YYYY')}</Text>
        </Group>
      </Group> }
      <EmptyState
        mainText={(rentalUnitName && tenantFullName) ? "Für diese Mieteinheit liegen keine Zähler vor." : "Für diese Immobilie wurden noch keine Hauszähler angelegt."}
        subText={(rentalUnitName && tenantFullName) ? "Bitte legen Sie Zähler an bevor Sie mit der Ablesung beginnen." : "Legen Sie einen Hauszähler an, wenn Sie Gesamtverbräuche des Mietshauses erfassen wollen."}
        buttonText="Zähler hinzufügen"
        buttonAction={()=> onAddMeter(rentalUnitId)}
      />
      {rentalUnits === null && noRentalUnitsEmptyState}
    </Stack>
  )
}

export default SingleTable