import Profile from '../../../views/pages/Module/General/profile/Profile';

const pageList = [
  {
    name: 'Profile',
    path: '/profile/',
    component: Profile,
  }
];

export default pageList;
