const en = {
  general: {
    text_dashboard: 'Dashboard',
    text_property: 'Property',
    text_utilitybilling: 'Utility billing',
    text_receipt: 'Receipts',
    text_meter: 'Meter',
    text_tenants: 'Tenants',
    text_help: 'Help',
    text_welcome: 'Welcome',
    text_email: 'Email address',
    text_password: 'Password',
    text_forgot_password: 'Forgot password',
    text_login: 'Log in',
    text_invalid_login: 'Invalid login credentials',
    text_noaccount: "Don't have an account",
    text_signup: 'Sign up',
    text_forgot_pass_heading: 'Forgot your password',
    text_forgot_pass_instuctions:
      'Enter your email address and we will send you the instructions to reset your password.',
    text_go_back: 'Go back',
    text_informational_msg: 'Informational message.',
    text_login_with_new_pass: 'You can now log in with your new password!',
    text_Enter_password: 'Enter Password',
    text_reset_password: "Enter your new password that you'll use to log in.",
    text_send_instructions: 'Send instructions',
    text_reset_instruction_heading: 'Reset instructions sent',
    text_reset_instruction_info: 'Check your email and follow the instruction from there.',
    text_back_to_login: 'Back to log in',
    text_of: 'of',
    text_steps: 'steps',
    text_start_with_email: "Let's start with your email address.",
    text_have_account: 'Already have an account',
    text_next_subscription: 'Next step: Subscription plan',
    text_subscription_plan: 'Subscription plan',
    text_subscription_info: "Select a billing plan. You'll start with 1 month for free.",
    text_per_month: 'per month',
    text_per_year: 'per year',
    text_billed: 'Billed',
    text_anually: 'anually',
    text_monthly: 'monthly',
    text_direct_debit: 'Direct debit',
    text_Inhaber: 'Inhaber',
    text_Account: 'Account',
    text_Status: 'Status',
    text_forwareded_to_mollie: 'You will be forwarded to your payment provider mollie.',
    text_Erstellt_am: 'Erstellt am',
    text_save_anually: 'a year',
    text_per: 'per',
    text_next_personal_details: 'Next step: Personal details',
    text_personal_details: 'Personal details',
    text_personal_details_info: "Fill in your personal details. You'll use email address and password to log in.",
    text_full_name: 'Full name',
    text_address: 'Address',
    text_city: 'City',
    text_zip_code: 'Zip Code',
    text_next_payment: 'Next step: Payment details',
    text_payment_details: 'Payment details',
    text_payment_details_info: 'Add your credit card information to proceed with the payment.',
    text_cardholder_name: 'Cardholder Name',
    text_credit_card_num: 'Credit card number',
    text_exp_date: 'Exp. date',
    text_cvv: 'CVV',
    text_next_summary: 'Next step: Summary',
    text_summary: 'Summary',
    // text_summary_info: "Review your information & let's get started!",
    text_summary_info: 'Review your information and agree to consents!',
    text_confirm_signup: 'Confirm & Sign up',
    text_invalid_email: 'Invalid email',
    text_invalid_password: 'Invalid password',
    text_invalid_full_name: 'Invalid full name',
    text_invalid_company: 'Invalid Company',
    text_invalid_address: 'Invalid address',
    text_invalid_city: 'Invalid city',
    text_invalid_zip_code: 'Invalid zip code',
    text_invalid_cardholder_name: 'Invalid cardholder name',
    text_invalid_credit_card_num: 'Invalid credit card number',
    text_invalid_exp_date: 'Invalid exp. date',
    text_invalid_cvv: 'Invalid cvv',
    text_cancel: 'Cancel',
    text_search: 'Search',
    text_sort: 'Sort',
    text_new_old: 'New to Old',
    text_old_new: 'Old to New',
    text_a_z: 'A to Z',
    text_z_a: 'Z to A',
    text_show: 'Show',
    text_edit: 'Edit',
    text_delete: 'Delete',
    text_upload_photo: 'Upload photo',
    text_upload_photo_ins: 'Min 240 x 240px PNG or JPG file',
    text_company_optional: 'Company (Optional)',
    text_invalid_company_name: 'Invalid company name',
    text_danger_zone: 'Danger zone',
    text_delete_your_account: 'Delete your account',
    text_current_password: 'Current password',
    text_invalid_current_password: 'Invalid current password',
    text_new_password: 'New password',
    text_invalid_new_password: 'Invalid new password',
    text_save_credit_card_information: 'Save credit card information',
    text_subscription_renewed: 'Your subscription will be renewed',
    text_and: 'and',
    text_withdraw_from_card: 'will be withdraw from your credit card.',
    text_change_plan: 'Change plan',
    text_cancel_susbscription: 'Cancel my subscription',
    text_bill_name: 'Bill name',
    text_amount: 'Amount',
    text_date: 'Date',
    text_cost_type: 'Cost type',
    text_your_profile: 'Your profile',
    text_log_out: 'Log out',
    text_your_subscription: 'Your subscription',
    text_payment_history: 'Payment history',
    text_you_be_billed: "You'll be billed",
    text_on: 'on',
    text_next_payment_withdraw: 'Next payment will be withdraw',
    text_download: 'Download',
    text_phone_number: 'Phone number',
    text_enter: 'Enter',
    text_invalid: 'Invalid',
    text_no_file_selected: 'No file selected',
    text_err_file_size: 'File is larger than 5mb',
    text_err_file_type: 'File must be jpg or pdf',
    text_name: 'Name',
    text_add_property: 'Add property',
    text_Save_Changes: 'Save Changes',
    text_contact_email: 'Contact email',
    text_housing_mail: 'contact@hellohousing.de',
    text_contact_phone: 'Contact phone',
    phone_no: '(0049) 000 00 000 00',
    text_go_back_home: 'Go back',
    text_no_property_added: 'You have not added any property.',
    text_must_add_property: 'You must add property first to manage utility billing.',
  },
  receipt: {
    text_receipts: 'Receipts',
    text_utility_filter: 'Show only utility bill relevant receipts',
    text_receipt: 'Receipt',
    text_amount_eur: 'Amount (EUR)',
    text_time_frame: 'Time frame',
    text_amount: 'Amount',
    text_date: 'Date',
    text_billing_split: 'Operating cost allocation',
    text_cost_type: 'Cost type',
    text_add_receipt: 'Add receipt',
    text_edit_receipt: 'Edit receipt',
    text_receipt_name: 'Receipt name',
    text_utility_bill_relevant: 'This receipt is utility bill relevant.',
    text_delete_receipt: 'Delete receipt',
    text_delete_confirmation: 'Are you sure you want to delete this',
    text_no_keep_it: 'No, keep it',
    text_yes_delete_it: 'Yes, delete it',
    text_current_billing_period: 'Current billing period',
    text_last_billing_period: 'Last billing period',
    text_custom: 'Custom',
    text_close_preview: 'Close preview',
    text_invalid_receipt_name: 'Invalid receipt name',
    text_invalid_amount: 'Invalid amount',
    text_save_receipt: 'Save receipt',
    text_receipt_details: 'Receipt details',
    text_drag_file: 'Drag file in here or click to browse',
    text_add_file_restrictions: 'JPG or PDF,5mb max',
    text_error_msg: 'Not Found',
    text_upload_photo: 'Upload Photo',
  },

  property: {
    text_property: 'Property',
    no_match_result: 'No Matching Result',
    generalinfo_text: 'Upload Photo',
    generalinfo_photo_update: 'Min 240 x 240px PNG or JPG file',
    generalinfo_propertyname: 'Property Name',
    generalinfo_propertynameError: 'Invalid Name',
    generalinfo_propertyaddress: 'Address',
    generalinfo_propertyaddressErr: 'Invalid Address',
    generalinfo_Property_no_of_Units: 'No. Units',
    generalinfo_Property_no_of_Units_error: 'Invalid Value',
    generalinfo_Area_of_unit: 'Area of Units',
    generalinfo_cut_off_date: 'Cut-off Date',
    generalinfo_heating_type: 'Heating Type',
    generalinfo_warm_water: 'central with warm water',
    generalinfo_without_warm_water: 'central without warm water',
    generalinfo_decentralized: 'decentralized',
    generalinfo_heating_temp: 'Heating temp',
    generalinfo_text_one: 'How much of the cost should be allocated',
    generalinfo_text_two: 'by consumption?',
    generalinfo_custom: 'Custom',
    generalinfo_delete_property: 'Delete this property',
    allocationKeys_text_head: 'Allocation Keys',
    allocationKeys_text_add_allocationkeys: 'Add allocation key',
    allocationKeys_tablehead_name: 'Name',
    allocationKeys_tablehead_unit: 'Measurement Unit',
    allocationKeys_tablehead_meter: 'Meter Data',
    allocationKeys_tablehead_active: 'Active',
    Text: 'text',
    costType_tablehead_name: 'Name',
    costType_tablehead_active: 'Active',
    costType_tablehead_allocationkeys: 'Allocation Keys',
    text_cost: 'Cost',
    text_cost_addcosttype: 'Add cost type',
    text_allocation_key: ' Allocation key',
  },
  tenant: {
    text_tenant: 'Tenant',
    text_rental_unit: 'rental unit',
    text_add_rental_unit: 'Add rental unit',
    text_move_in: 'Move in',
    text_move_out: 'Move out',
    text_add_tenant: 'Add tenant',
    text_tenant_information: 'Tenant information',
    text_rent_address: 'Rent address',
    text_documents: 'Documents',
    text_notes: 'Notes',
    text_add_tenant_as: 'Add tenant as',
    text_edit_tenant_as: 'Edit tenant as',
    text_private: 'Private',
    text_commercial: 'Commercial',
    text_occupancy_dates: 'Occupancy dates',
    text_move_in_date: 'Move in date',
    text_move_out_date: 'Move out date',
    text_rent: 'Rent',
    text_billed_monthly: 'billed monthly',
    text_rented_area: 'Rented area',
    text_save_tenant: 'Save Tenant',
    // text_move_in: 'Move in',
    // text_move_out: 'Move out',
    text_edit_tenant: 'Edit tenant',
    text_payments: 'Payments',
    text_no_rent_defined: "You haven't defined monthly rent for this tenant.",
    text_edit_rental_unit: 'Edit rental unit',
    // text_add_rental_unit: 'Add rental unit',
    text_net_rent: 'Net rent',
    text_advance_payments: 'Advance payments',
    text_end_date_opt: 'End date (Optional)',
    text_start_date: 'Start date',
    text_save_rent: 'Save rent',
    text_add_rent: 'Add rent',
    // text_edit_rental_unit: 'Edit rental unit',
    // text_add_rental_unit: 'Add rental unit',
    text_size: 'Size',
    text_edit_rent: 'Edit rent',
    text_save_rental_unit: 'Save rental unit',
    text_show_current_tenants: 'show current tenants',
    text_show_previous_tenants: 'show previous tenants',
    text_show_less: 'show less',
    text_show_previous_rents: 'show previous rents',
    text_no_rental_units: "You haven't added any rental units",
    text_no_unit_and_property: 'You must add property first to add rental units.',
    text_save_payments: 'Save payments',
    text_edit_payments: 'Edit payments',
  },
  utility: {
    text_Consumption: 'Consumption',
    text_Energy_consumption: 'Energy consumption',
    text_calculate_heating_cost: 'Calculate heating costs',
    text_current_billing_period: 'Current billing period',
    text_last_billing_period: 'Last billing period',
    text_edit: 'Edit',
    text_total_costs: 'Total costs',
    text_Heating_costs: 'Heating costs',
    text_start_utility_billing: 'Start utility billing',
    text_utility_billing: 'Utility Billing',
    text_tenant: 'Tenant',
    text_rental_unit: 'rental unit',
    text_occupancy_period: 'Occupancy Period',
    text_go_back: 'Go back',
    text_next_utility_bills: 'Next Step: Utility bills',
    text_next_overview: 'Next Step: Overview',
    text_save_utility_billing: 'Save utility billing',
    text_cost_allocation: 'Costs allocation',
    text_heating_cost_allocation_desc: 'Hier ermitteln Sie die Heizkosten und legen diese um',
    text_cost_allocation_desc:
      'Add costs and assign the right allocation keys with receipts to divide the costs between tenants.',
    text_add_cost_position: 'Add cost position',
    text_edit_cost_position: 'Edit cost position',
    text_cost_type: 'Cost type',
    text_amount: 'Amount',
    text_allocation_key: ' Allocation key',
    text_meter_data: 'Meter data',
    text_Receipt: 'Receipt',
    text_total_cost: 'Total cost',
    text_total_value: 'Total value',
    text_tenant_value: 'Tenant value',
    text_unit: 'Unit',
    text_tenant_cost_share: 'Tenant Cost Share',
    text_comment: 'Comment',
    text_no_tenant_bills: "There's no utility billings of tenants",
    text_utility_bills: 'Utility bills',
    text_ub_desc: 'You can see all the utility bills for every tenant.',
    text_stayed_for: 'Stayed for',
    text_total_allocation: 'Total allocation',
    text_prepayments: 'Prepayments',
    text_balance: 'Balance',
    text_print_pdf: 'Print as PDF',
    text_add_comment: 'Add comment',
    text_Cost_type: 'Cost type',
    text_Summe_Mieter: 'Summe Mieter',
    text_Mike_Jonathon_Stanfordinthebay: 'Mike Jonathon Stanfordinthebay ',
    text_Stacy_Smith: 'Stacy Smith',
    text_Oxford_Bulldog: 'Oxford-Bulldog',
    text_Anabell_Harvard: 'Anabell Harvard',
    text_Tom_Yale: 'Tom Yale',
    text_House_Bell_GmbH: 'House&Bell GmbH',
    text_utiiity_allocation_key: 'Allocation key',
    text_Total_cost: 'Total cost',
    text_utility_total_value: 'Total Value',
    text_utility_tenant_value: 'Tenant Value',
    text_utility_unit: 'Unit',
    text_Tenant_Cost_share: 'Tenant Cost share',
    text_utility_comment: 'Comments ',
    text_Set_allocation: 'Set allocation',
    text_set_custom_allocation: 'Set custom allocation',
    // text_occupancy_period: 'Occupancy period',
    text_heating_cost_switch: 'Disable heating cost calculation',
    text_no_utility_billing: "There's no utility billing to start",
    text_error_billing_period: 'Unable to start billing period.',
    text_error_no_pdf: 'No pdf available for billing statements',
  },
  meter: {
    text_empty_state: "There's no meter readings available",
    text_empty_alert: 'You must add any meter first to manage meter reading data.',
    text_add_meter: 'Add meter',
    text_add_meter_data: 'Add meter data',
    text_edit_meter_data: 'Edit meter data',
    text_Rental_Unit: 'Rental unit',
    text_number: 'Number',
    text_invalid_number: 'Invalid number',
    text_type: 'Type',
    text_invalid_type: 'Invalid type',
    text_location: 'Location',
    text_invalid_location: 'Invalid location',
    text_note: 'Note',
    text_add: 'Add',
    text_save: 'Save',
    text_individual_reading: 'Individual reading',
    text_annual_reading: 'Annual reading',
    text_status: 'Status',
    text_reading: 'Reading',
    text_master_data: 'Master data',
    text_annual_meter_data: 'Annual meter reading',
    text_meter_number: 'Meter number',
    text_meter_type: 'Meter type',
    text_reading_date: 'Reading date',
    text_start_value: 'Start Value',
    text_current_value: 'Current Value',
    text_consumption: 'Consumption',
    text_invalid_value: 'Invalid value',
    text_invalid_date: 'Invalid date',
    text_no_master_data: "There's no meter master data",
    text_no_master_data_subheading: 'You must add property first to manage meter master data.',
    text_edit_readings: 'Edit meter readings',
    text_individual_meter_reading: 'Individual meter reading',
    text_annual: 'Annual',
    text_no_billing_warning: 'No readings in this billing Period',
    is_property_meter: "It's a property meter",
    annual_error_message_updating: 'Error in updating record!',
    annual_success_message: 'Readings updated successfully.',
    meter_units: 'Unit',
    text_error_meter_readings_delete: 'Reading can not be deleted',
    text_error_meter_readings_save_error: 'Readings can not be saved',
  },
  user: {
    text_error_subscription: 'Choose any subscription plan',
    text_error_subscriptions_list: 'No subscription plans available',
    text_credit_card: 'Credit card',
    text_error_payment_status: 'Unable to get payment status',
    text_authorize_payment: 'Payment method authorized',
    text_unauthorize_payment: 'Payment method not authorized',
    text_process_further: 'You can proceed to the next step.',
    text_try_again: 'Please try again.',
    text_error_payment_mode: 'Choose payment mode.',
    text_error_payment_server: 'Server not responding.',
    text_payment_details: 'Payment details',
    text_signup_successful: 'Sign up successful!',
    text_login_now: 'You can now log in.',
    text_go_to_login: 'Go to log in',
    text_error_process: 'Invalid process',
    text_error_authorize_payment: 'Payment gateway error',
    text_payment_sub_heading: 'Select preferred payment method. We ll redirect you to your payment provider mollie.',
    text_payment_provider_mollie:
      'Select preferred payment method. We will redirect you to your payment provider mollie.',
    text_error_account_activate: 'Unable to activate account',
    text_terms_and_condition: 'Terms and conditions',
    text_testing_hellohousing: 'You are testing hello',
    text_free_untill: 'for free until',
    text_cancel_any_time: 'You can cancel your subscription at any time',
    text_you_will_use: 'you will use hellohousing using the',
    text_plan_cancellation: 'plan with monthly cancellation.',
    text_first_payment_withdrawn: 'Your first payment will be withdrawn on from your',
    text_accept_terms_and_condition: 'Please accept terms and condition',
  },
  newKeys: {
    text_see_all: 'See All',
    text_billing_period: 'billing period',
    text_empty_receipt_one: "You haven't added any receipts",
    text_empty_receipt_two: 'You must add property first to manage receipts.',
    text_empty_receipt_three: "You haven't added any receipts",
    text_empty_utility_one: "There's no utility billing to start",
    text_empty_utility_two: "Here, you'll see if you need to do utility billing.",
    text_empty_meter_one: "There's no meter to read",
    text_empty_meter_two: "Here, you'll be able to start meter reading.",
    text_Rental_units: 'Rental units',
    text_empty_rentalUnit_one: "You haven't added any rental units",
    text_empty_rentalUnit_two: "Here, you'll see rental units you added.",
    text_general_info: 'General Information',
    text_from: 'from',
    text_system_cost_type: 'System cost type',
    text_System_key: 'System key',
    text_add_allocation_key: 'Add Alloction Key',
    text_edit_allocation_key: 'Edit Allocation Key',
    text_save_allocation_key: 'Save Allocation Key',
    text_error_measurement_unit: 'Invalid Measurement Unit',
    text_save_cost_type: 'Save Cost Type',
    text_select: 'Select',
    text_select_error_msg: 'Select/Add allocation Key.',
    text_edit_cost_type: 'Edit Cost Type',
    text_legal: 'Legal',
    text_last_name: 'Last Name',
    text_first_name: 'First Name',
    text_subscription_one: 'you are testing hellohousing one month for free until',
    text_subscription_two: 'Starting',
    text_subscription_three: 'You will be billed',
    text_subscription_four: 'on a',
    text_subscription_five: 'basis',
    text_subscription_at: 'at',
    text_error_msg_checkbox: 'Check the box',
    text_error_msg_checkbox_2:
      'Bitte stimmen Sie der Datenschutzerklärung und den Bestimmungen zur Datenverarbeitung zu.',
    text_email_already_taken: 'An account has already been assigned for this email address. Please log in.',
    text_first_payment_withdrawns: 'Ihre erste Zahlung wird adgebucht am',
    text_SEPA: 'SEPA-Lastschriftmandat',
    text_Energy_source: 'Energy source',
    text_heatingType_error: 'Select a heating type.',
    text_fuelType_error: 'Select a fuel type.',
    text_warm_water_error: 'Select a warm water.',
    // text_SEPA: 'SEPA-Lastschriftmandat',
    text_signup_monthly_basic: 'monthly',
    text_signup_monthly: 'monthly',
    text_signup_yearly: 'yearly',
    text_signup_yearly_basic: 'yearly',
    text_utility_billing_err: 'No billing periods found.',
    text_add_new_property: 'Add New property',
    text_add_your_property: 'Add your property',
    text_add_Property_and_manage: 'Add a property and start managing it.',
    text_Password_must: 'Password must:',
    text_Password_must_condition_one: 'Be at least 8 characters long',
    text_Password_must_condition_two: 'Have at least one lowercase',
    text_Password_must_condition_three: 'Have at least one uppercase',
    text_Password_must_condition_four: 'Have one number',
    text_Password_must_condition_five: 'Have one special character (e.g. %, &, #)',
    text_net_rent: 'Net rent (EUR)',
    text_advance_rent: 'Advance payments (EUR)',
    text_Add_Note: 'Add Note',
    text_heading_meter_readings: 'Meter readings',
    text_heading_metermaster_data: 'Meter master data',
    text_save_money: '',
    text_save_money_10: 'sparen',
    text_monthly_plan: 'Monthly',
    text_yearly_plan: 'Yearly',
    btn_text_authorize_payment: 'Authorize payment method',
    text_subscription_plan_one: '1. Monthly cancelation possible',
    text_subscription_plan_two: '2. Annual cancelation possible',
    text_No_utility_billings_found: 'No utility billings found.',
    text_error_not_found: 'Error from server',
    text_error_found: 'Some Error Occured',
    text_utility_error: 'Heating cost system types not found!',
    text_utility_not_found: 'Utility Billing not found!',
    text_system_fuel_not_found: 'System fuel types not found',
    text_error_adding_heat_cost: 'Error occured while Adding Heating cost',
    text_error_delete_heat_cost: 'Error occured while deleting Heating cost!',
    text_error_save_heat_cost: 'Error occured while saving cost positions!',
    text_no_billing_found: 'No Utility Billing Data Found!',
    text_error_Incorrect_Cost_Allocation: 'Incorrect Cost Allocation',
    text_error_update_Cost_Allocation: 'Unable to update cost Allocation',
    text_error_Billing_Statements: 'Error Getting Billing Statements :  ',
    text_Error_message: 'Error :  ',
    text_No_tenants_found_for_rental_unit: 'No tenants found for rental unit.',
    text_error_Tenant_readings_not_found: 'Tenant readings not found.',
    text_error_no_reading_found: 'No occupation readings available.',
    text_error_no_meter_reading: 'No meter readings available for billing period.',
    text_error_no_occupation_reading: 'Occupation readings not found for billing period.',
    text_error_invalid_rental_unit: 'Rental unit is not valid',
    text_error_Consumption_value: 'Consumption value is not valid',
    text_error_No_tenants_for_rental_unit: 'No tenants for rental unit available',
    text_error_no_meter_reading_available: 'No meter readings available',
    text_invalid_data: 'Invalid Data',
    text_invalid_or_empty_data: 'Data is invalid or empty',
    text_error_remove_receipt: 'Error in removing Receipt',
    text_error_saving_cost_position: 'Error in saving/updating cost position',
    text_error_calculate_heating_cost: 'If heating cost is enable then user must calculate heating cost',
    text_error_no_cost_position_found: 'No Cost Positions Found',
    text_error_Heating_Cost_switch_response: 'Heating Cost switch response not found',
    text_error_Error_in_removing_receipt_assignment: 'Error in removing receipt assignment!',
    text_error_meter_added_to_renatal_unit: 'There are no meter added to this rental unit.',
    text_empty_add_meters_first_to_manage_annual: 'You must add meters first to manage their annual readings.',
    text_profile: 'Profile',
    tooltip_default_text: 'some help text',
    Property_no_of_Units_Tooltip: 'some help text',
    Property_Area_of_Unit_Tooltip: 'some help text',
    Property_consumption_Tooltip: 'some help text',
    text_billing_period_dashboard: 'billing period',
    text_dashboard_file_name: 'file name',
    text_dashboard_cost: 'Cost (EUR)',
    text_receipt_period_tooltip: 'Time frame which the bill covers.',
    text_record: 'record',
    text_single_allocationKey: 'allocation key',
    text_single_costtype: 'cost type',
    text_single_tenant: 'tenant',
    text_change_pw: 'Change password',
    text_heating_cost: 'Heating costs',
    text_heating_cost_not_available: 'Heating costs not available',
    text_heating_cost_available: 'Heating costs available',
    text_paymentUpdated_success: 'Payment status updated successfully',
    text_heating_cost_not_avaialable: 'Heating costs not available',
    text_heating_cost_avaialable: 'Heating costs available',
    text_cost_positions: 'Cost positions ',
    text_Consumption_type: 'Consumption type',
    text_add_cp: 'Add costs position',
    text_heating_cost_type_error_msg: 'Invalid type or value',
    text_select_cost_type: 'Select Cost Types',
    text_days: 'days',
    select_allocation_key_text: 'Select the allocation key',
    text_billed_monthly: 'billed monthly',
    text_signup_first_name: 'Enter first name',
    text_signup_last_name: 'Enter last name',
    text_Change_payment_option: 'Change payment option',
    text_your_current_plan: 'Your Current Plan',
    text_add_payments_rent: 'Add Payments',
    Go_back_to_payment_details: 'Go back to payment details',
    text_allocation_key_error_msg: 'Please select',
    text_error_msg_tenants_rent: 'Invalid input',
    text_not_required: 'Not Required',
    text_available: 'Available',
    text_not_available: 'Not available',
    warmwater_amount_is_greater_than_heating_amount: <span>Bitte prüfen sie die eingegebenen Energiemengen im Heizkostenrechner. Der <span style={{fontWeight: 'bold'}}>Energieverbrauch Warmwasser</span> darf den <span style={{fontWeight: 'bold'}}>Gesamtenergieverbrauch</span> nicht überschreiten.</span>,
    central_warmwater_allocation_key_not_set: <span>Es wurde kein Verteilerschlüssel für den zentralen Warmwasserzähler gefunden. Bitte prüfen Sie die <span style={{fontWeight: 'bold', color: '#5555FF'}} onClick={()=> window.open(`https://${window.location.hostname.includes('app') ? 'app' : 'dev'}.hellohousing.de/property/heatingCostInformation`, '_self')}>Einstellungen der Heizungsanlage.</span></span>,
    warmwater_cosumption_must_be_in_meter_cube: 'Bitte prüfen Sie den Verteilerschlüssel für den Warmwasserverbrauch. Der Warmwasserverbrauch muss in m3 oder als Wärmemenge verfügbar sein.'
  },
};

export default en;
