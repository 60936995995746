import React, { Component } from 'react';
import CustomModal from './index';
import { ColorButton, SwitchContainer } from '../../../../index';
import Autoload from '../../../../Autoload';
import { Form, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import GlobalServices from '../../../../../neoverv/helpers/services/GlobalServices';
import MeterDataHandler from '../../../../../neoverv/helpers/DataHandler/Meter';
import GeneralDataHandler from '../../../../../neoverv/helpers/DataHandler/General';
import uparrow from '../../../../../assets/images/module/general/SelectArrowUp.svg';
import downArrow from '../../../../../assets/images/module/general/SelectArrowDown.svg';
import RedCross from '../../../../../assets/images/module/general/RedCross.svg';
import GreenTick from '../../../../../assets/images/module/general/GreenTick.svg';
import EyeIcon from '../../../../../assets/images/module/general/EyeIcon.svg';
import { connect } from 'react-redux';
import Help from '../../../../../assets/images/module/general/Help.svg';
import { showNotification } from '@mantine/notifications';
import { Box, Image, Popover, Progress, Stack, Text } from '@mantine/core';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default].general;
var lang_newKeys = Setting[Setting.language_default].newKeys;

const MOBILE_SIZE = 500;

// Requirements for the password validation
const passwordRequirements = [
  { regex: /[0-9]/, label: 'Eine Nummer haben' },                                                 // one digit
  { regex: /[a-z]/, label: 'Mindestens einen Kleinbuchstaben haben' },                            // lowercase letter
  { regex: /[A-Z]/, label: 'Mindestens einen Großbuchstaben haben' },                             // uppercase letter
  { regex: /[$&+,:;=?@#|'<>.^*()%!-]/, label: 'Ein Sonderzeichen enthalten (z. B. %, &, #)' }     // special chars
];

// Password requirement markup
const PasswordRequirement = ({ checks, label }) => {
  return (
    <Text
      color="white"
      sx={{ display: 'flex', alignItems: 'center', opacity: checks ? 1 : 0.5 }}
      mt={7}
      size="sm"
    >
      <Image width={15} src={`${process.env.PUBLIC_URL}/icons/icon-check-badge.svg`} alt="Check icon" />
      <Box ml={10}>{label}</Box>
    </Text>
  )
};

// Show password strength
const getStrength = (password) => {
  let multiplier = password.length > 5 ? 0 : 1;

  passwordRequirements.forEach((requirement) => {
    if (!requirement.regex.test(password)) {
      multiplier += 1;
    }
  });

  return Math.max(100 - (100 / (passwordRequirements.length + 1)) * multiplier, 10);
}

class ChangePasswordModal extends Component {
  state = {
    newPassword: '',
    newPassErr: false,
    currentPassword: '',
    currentPassErr: false,
    isMobile: window.innerWidth < MOBILE_SIZE,
    passwordPopoverOpened: false,
    strength: 0,
    color: 'red'
  };

  checks =()=> passwordRequirements.map((requirement, index) => (
    <PasswordRequirement key={index} label={requirement.label} checks={requirement.regex.test(this.state.newPassword)} />
  ));

  constructor(props) {
    super(props);
  }

  componentDidMount() {
  }

  toggleShowPassword = (id) => {
    var x = document.getElementById(id);
    if (x.type === 'text') {
      x.setAttribute('type', 'password');
    } else {
      x.setAttribute('type', 'text');
    }
  };

  onInputChange = (field, value) => {
    this.setState({ [field]: value }, ()=> this.setState({strength: getStrength(this.state.newPassword), color: getStrength(this.state.newPassword) === 100 ? 'teal' : getStrength(this.state.newPassword) > 50 ? 'yellow' : 'red'}));
  };

  savePasswordChanges = (e) => {
    e.preventDefault();
    this.setState({ newPassErr: false, currentPassErr: false });
    const validPass = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
    let newPassErr,
      currentPassErr = false;
    if (!this.state.newPassword || !validPass.test(this.state.newPassword)) {
      newPassErr = true;
    }
    // if (!this.state.currentPassword || !validPass.test(this.state.currentPassword)) {
    //   currentPassErr = true;
    // }
    this.setState({ newPassErr, currentPassErr });
    if (!newPassErr && !currentPassErr) {
      // console.log('call api')
      this.put_password_api();
    }
    // if (newPassErr || currentPassErr) {
    //   this.setState({ newPassErr, currentPassErr });
    //   return false;
    // } else {
    //   this.setState({ newPassErr, currentPassErr });
    //   return true;
    // }
  };

  put_password_api() {
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'block';
    const userAccountID = this.props.profile.id;
    let btnelement = document.getElementById('save-btn-user-password-info');
    const updatedPassword = {
      accountId: userAccountID,
      newPassword: this.state.newPassword,
      oldPassword: this.state.currentPassword,
    };
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.user_api_baseurl + userAccountID + '/changePassword',
      // Autoload.Setting.user_api_baseurl + userAccountID + '/changePassword',
      'PUT',
      updatedPassword,
      btnelement.children[0],
      false,
      true,
      false,
      this,
      'handle_put_user_password',
      { updatedPassword },
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  handle_put_user_password(response) {
    // console.log('handle_put_user_password', response);
    if (response.status === true) {
      //Autoload.DataHandlerGeneral.GeneralErrorMessage(true, 'Passwort erfolgreich aktualisiert');
      window.Intercom('trackEvent', 'password-changed');
      showNotification({
        message: 'Passwort erfolgreich geändert.',
        icon: <img src={GreenTick} alt='green-tick'/>
      });
      this.props.toggle();
    } else {
      //Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang_newKeys.text_error_found);
      window.Intercom('trackEvent', 'password-changed-failed');
      showNotification({
        message: 'Es ist ein Fehler aufgetreten. Bitte prüfen Sie das alte und das neue Passwort.',
        icon: <img src={RedCross} alt='red-cross'/>
      });
    }
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'none';
  }

  render() {
    const {open, toggle, className} = this.props; 
    const {passwordPopoverOpened, strength, color} = this.state;
    
    return (
      <CustomModal
        heading={'Passwort ändern'}
        headingClassName={'tooltips change-password'}
        open={open}
        toggle={toggle}
        className={`${className}`}
      >
        <div className="account-width pw" style={{width: '100%'}}>
          <Form onSubmit={this.savePasswordChanges}>
            <Row>
              <Col xs={12}>
                <Form.Group>
                  <Form.Label> {lang.text_current_password} </Form.Label>
                  <div className="password-field" style={{ position: 'relative', top: 0, left: 0 }}>
                    <Form.Control
                      id="current-password"
                      name="current-password"
                      isInvalid={this.state.currentPassErr}
                      type="password"
                      value={this.state.currentPassword}
                      onChange={(e) => {
                        this.onInputChange('currentPassword', e.target.value);
                      }}
                    />
                    <img src={EyeIcon} onClick={() => this.toggleShowPassword('current-password')} alt="eye-icon" />
                    <Form.Control.Feedback className="err" type="invalid">
                      {' '}
                      {lang.text_invalid_current_password}{' '}
                    </Form.Control.Feedback>
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Form.Group>
                  <Stack spacing={0}>
                  <Form.Label> {lang.text_new_password} </Form.Label>
                  <Popover
                    opened={passwordPopoverOpened}
                    target={
                      <div className="password-field" style={{ position: 'relative', top: 0, left: 0 }}>
                        <Form.Control
                          id="new-password"
                          name="new-password"
                          isInvalid={this.state.newPassErr}
                          type="password"
                          value={this.state.newPassword}
                          onChange={(e) => {
                            this.onInputChange('newPassword', e.target.value);
                          }}
                        />
                        <img src={EyeIcon} onClick={() => this.toggleShowPassword('new-password')} alt="eye-icon" />
                        <Form.Control.Feedback className="err" type="invalid">
                          {' '}
                          {lang.text_invalid_new_password}{' '}
                        </Form.Control.Feedback>
                      </div>
                    }
                    position={window.innerWidth < 993 ? "top" : "left"}
                    placement="center"
                    trapFocus={false}
                    zIndex={99999}
                    transition="pop"
                    onFocusCapture={() => this.setState({passwordPopoverOpened: true})}
                    onBlurCapture={() => this.setState({passwordPopoverOpened: false})}
                    styles={(theme) => ({
                      popover: { width: '100%', backgroundColor: theme.colors.brandDarkText },
                      arrow: { backgroundColor: theme.colors.brandDarkText, border: 'none' },
                      body: { border: 'none' }
                    }) }
                    withArrow
                  >
                    <Progress color={color} value={strength} size={7} style={{ marginBottom: 16, border: '1px solid white' }} />
                    <PasswordRequirement label="Mindestens 8 Zeichen lang sein" checks={this.state.newPassword.length > 8} />
                    {this.checks()}
                  </Popover>
                  </Stack>
                </Form.Group>
              </Col>
            </Row>
            <div id="save-btn-user-password-info" style={{display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: '15px'}}>
              <div>
                <ColorButton
                  text={'Schließen'}
                  className="gray-btn"
                  onClick={()=> toggle()}
                />
              </div>
              <div>
                <ColorButton
                  buttonConfig={{ type: 'submit' }}
                  // onClick={() => {
                  //   //this.put_password_api();
                  // }}
                  text={lang_newKeys.text_change_pw}
                  className="purple-btn"
                  buttonStyle={{ padding: '10px'}}
                />
              </div>
            </div>
          </Form>
        </div>
      </CustomModal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    propertySelected: state.generalReducer.property,
    profile: state.generalReducer.profile,
  };
};

export default connect(mapStateToProps)(ChangePasswordModal);
