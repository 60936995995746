import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { HeadingContainer } from '../../../../neoverv';
import GeneralInformation from './generalInformation';
import CostType from './costType';
import AllocationKeys from './allocatonKeys';
import HeatingInformation from './heatingInformation';
import { connect } from 'react-redux';
import Autoload from '../../../../neoverv/Autoload';
import HelpLogo from '../../../../assets/images/module/general/book.svg';
import TourIcon from '../../../../assets/images/module/general/TourIcon.svg';
import breadcrumbsContent from '../../../../../src/assets/breadcrumbs.json';
// import { withRouter } from 'react-router-dom';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
const routes = [
  {
    name: 'GeneralInformation',
    route: '/property/generalInformation',
    label: lang.newKeys.text_general_info,
    heading: 'General Information',
  },
  {
    name: 'CostType',
    route: '/property/costType',
    label: lang.general.text_cost_type,
    heading: 'Cost Type',
  },
  {
    name: 'Allocation Keys',
    route: '/property/allocationKeys',
    label: lang.property.allocationKeys_text_head,
    heading: 'Allocation Keys',
  },
  {
    name: 'HeatingCostInformation',
    route: '/property/heatingCostInformation',
    label: 'Heizung',
    heading: 'Heizung',
  },
];
const MOBILE_SIZE = 992;

class List extends Component {
  state={
    isMobile: window.innerWidth <= MOBILE_SIZE,
  }
  componentDidMount(){
    window.addEventListener('resize', this.handleResize);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }
  changePage(route) {
    this.props.history.push(route);
  }

  changeHeading(active) {
    const activeRoute = routes.filter((route) => {
      return active === route.route;
    });
    return activeRoute[0].heading;
  }

  handleResize = () => {
    // TODO: this causes data refetch on the meters reading details screen...
    if (window.innerWidth <= MOBILE_SIZE) {
      this.setState({ isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
  };

  render() {
    //const pathname = '/' + this.props.location.pathname.split('/')[2];
    const {propertySelected} = this.props;
    const {isMobile} = this.state;
    let pathname = this.props.location.pathname;
    console.log(pathname);
    if (pathname === '/property') {
      pathname = routes[0].route;
    }
    console.log('propertySelected: ', propertySelected);
    return (
      <div>
        {!isMobile && <HeadingContainer heading={lang.general.text_property} />}
        <div style={{display: 'flex'}}>
          {breadcrumbsContent.map((item)=> item.area === (window.location.href.includes('/costType') ? '/property/costType' : window.location.href.includes('/allocationKeys') ? '/property/allocationKeys' : '/property') && 
          item.breadcrumbs.map((breadcrumb)=>
          breadcrumb.type === 'tour' ? breadcrumb.view === (this.state.isMobile ? 'mobile' : 'desktop') &&
          <div style={{display: 'flex', width: 'auto', alignItems: 'center', cursor: 'pointer', marginRight: '22px'}} onClick={()=> window.Intercom('startTour', breadcrumb.id)}>
            <img src={TourIcon} />
            <span style={{color: '#5555FF', marginLeft: '5px', fontSize: '14px'}}>{breadcrumb.label}</span>
          </div>
          :
          breadcrumb.view === (this.state.isMobile ? 'mobile' : 'desktop') &&
          <div style={{display: 'flex', width: 'auto', alignItems: 'center', cursor: 'pointer', marginRight: '22px'}} onClick={()=> window.Intercom('showArticle', breadcrumb.id)}>
            <img src={HelpLogo} />
            <span style={{color: '#5555FF', marginLeft: '5px', fontSize: '14px'}}>{breadcrumb.label}</span>
          </div>
          ))}
        </div>
        <div
          style={{ display: 'flex', cursor: 'pointer', pointerEvents: this.props.allProperties.length === 0 && 'none' }}
          className="property-navbar"
        >
          {routes.map((btn, index) => {
            if(propertySelected && propertySelected.propertyType === 'CONDOMINIUM'){
              if(btn.name !== 'HeatingCostInformation'){
                return (
                  <p
                    className={`switch-btn ${btn.name}-btn ${
                      this.props.allProperties.length > 0 && btn.route === pathname && 'active'
                    }`}
                    style={{
                      color: btn.route === pathname ? '#111147' : '#727293',
                      borderBottom: btn.route === pathname ? '3px solid #5555FF' : null,
                      fontSize: btn.route === pathname ? '14px' : '14px',
                      marginRight: '25px',
                      padding: '32px 0px',
                    }}
                    onClick={() => {
                      this.changePage(btn.route);
                    }}
                    key={index}
                  >
                    {btn.label}
                  </p>
                );
              }
            }else{
              return (
                <p
                  className={`switch-btn ${btn.name}-btn ${
                    this.props.allProperties.length > 0 && btn.route === pathname && 'active'
                  }`}
                  style={{
                    color: btn.route === pathname ? '#111147' : '#727293',
                    borderBottom: btn.route === pathname ? '3px solid #5555FF' : null,
                    fontSize: btn.route === pathname ? '14px' : '14px',
                    marginRight: '25px',
                    padding: '32px 0px',
                  }}
                  onClick={() => {
                    this.changePage(btn.route);
                  }}
                  key={index}
                >
                  {btn.label}
                </p>
              );
            }
          })}
        </div>
        <hr style={{ marginTop: '-18px' }} />
        <div>
          <Route
            path={this.props.match.url}
            style={{ color: this.props.allProperties.length === 0 && '#111147' }}
            exact
            component={GeneralInformation}
          />
          <Route
            path={this.props.match.url + '/generalInformation'}
            style={{ color: this.props.allProperties.length === 0 && '#111147' }}
            exact
            component={GeneralInformation}
          />
          <Route path={this.props.match.url + '/costType'} exact 
          render={(props) => <CostType {...props} isMobile={isMobile} />}
          //component={CostType} 
          />
          <Route path={this.props.match.url + '/allocationKeys'} exact 
          render={(props) => <AllocationKeys {...props} isMobile={isMobile} />}
          //component={AllocationKeys} 
          />
          <Route path={this.props.match.url + '/heatingCostInformation'} exact 
          render={(props) => <HeatingInformation {...props} isMobile={isMobile} />}
          //component={HeatingInformation} 
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    allProperties: state.generalReducer.allProperties,
    propertySelected: state.generalReducer.property,
  };
};

export default connect(mapStateToProps, null)(List);
