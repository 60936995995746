import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setTenantBills } from '../../../../redux/action/UtilityBilling/UtilityBillingActions';
import { formatNumber } from '../../../../helpers/stringsManipulations';
import { EmptyStateContainer, UtilityBillsTableRow } from '../../../../neoverv';
import Autoload from '../../../../neoverv/Autoload';
import tap from '../../../../assets/images/module/general/TapIconDark.svg';
import MeterIcon from '../../../../assets/images/module/general/blueEmptyMeter.svg';


var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];

export class MeterReadingsTable extends Component {

  tableHeadCellStyling = {
    color: '#727293',
    padding: '0 9px 0 9px',
    fontWeight: '500',
    whiteSpace: 'nowrap',
    margin: '0px',
    fontSize: '14px',
    lineHeight: '20px',
    border: '1px solid #DADAE6',
    textTransform: 'capitalize',
    height: '48px',
  }
  tableBodyCellStyling = {
    color: '#0e0e1d',
    padding: '5px 9px 5px 9px',
    fontWeight: '400',
    whiteSpace: 'nowrap',
    fontSize: '14px',
    lineHeight: '20px',
    border: '1px solid #DADAE6',
    textTransform: 'none',
    height: '48px',
    minWidth: '90px',
    // borderBottom: 'none',
    // borderRight: 'none'
  }

  allocationKey= '';
  allocationKeyValueTotal= 0;
  allocationKeyUnit= '';
  allocationCounter = 0;

  constructor(props){
    super(props);
    if(props.messagePreview){
      this.tableBodyCellStyling.fontSize = '7pt';
      this.tableBodyCellStyling.height = '0px'
      this.tableBodyCellStyling.padding = '1mm'
      this.tableBodyCellStyling.lineHeight = '11pt'

      this.tableHeadCellStyling.fontSize = '7pt';
      this.tableHeadCellStyling.height = '0px'
      this.tableHeadCellStyling.padding = '1mm'
      this.tableHeadCellStyling.lineHeight = '11pt'
      //this.tableBodyCellStyling.minWidth = '40px';
    }
  }

  getTableLabels = () => {
    return (
      <TableRow>
        <TableCell style={{...this.tableHeadCellStyling}}>{'Gerätenummer'}</TableCell>
        <TableCell style={{...this.tableHeadCellStyling}}>{'Bezeichnung'}</TableCell>
        <TableCell style={{...this.tableHeadCellStyling, ...{textAlign: 'right'}}}>{'Startwert'}</TableCell>
        <TableCell style={{...this.tableHeadCellStyling, ...{textAlign: 'right'}}}>{'Ablesewert'}</TableCell>
        <TableCell style={{...this.tableHeadCellStyling, ...{textAlign: 'right'}}}>{'Verbrauch'}</TableCell>
      </TableRow>
    );
  };
  calculateTotal=(i)=>{
    let total=0;
    //console.log('calculateTotal: ',this.props.selectedTenantData.tenantRows[i].totalCost);
    this.props.selectedTenantData.tenantRows.map((row, index)=> {
      if(index <= i){
        total = total + row.tenantCostShare;
      }
    })
    console.log('calculateTotal: ', total);
    return formatNumber(total, true);;
  }
    heatingRow =(index)=>{
        return (
          <TableRow>
            <TableCell style={{...this.tableBodyCellStyling, ...{textAlign: 'right', borderBottom: this.props.selectedTenantData.tenantRows.length === (index + 1) && '1px solid #DADAE6'}}} colSpan={6}>
              Heizkosten gesamt
            </TableCell>
            <TableCell style={{...this.tableBodyCellStyling, ...{fontWeight: 'bold', textAlign: 'right', borderBottom: this.props.selectedTenantData.tenantRows.length === (index + 1) && '1px solid #DADAE6'}}} colSpan={1}>
              {this.calculateTotal(index)}
            </TableCell>
            <TableCell style={{...this.tableBodyCellStyling, ...{fontWeight: 'bold', borderBottom: this.props.selectedTenantData.tenantRows.length === (index + 1) && '1px solid #DADAE6'}}} colSpan={1}/>
          </TableRow>
        );
      }
      utilityTotalRow=(index)=>{
        return(
        <>
        <TableRow>
          <TableCell style={{...this.tableBodyCellStyling, ...{textAlign: 'right'}}} colSpan={6}>
            Betriebskosten gesamt
          </TableCell>
          <TableCell style={{...this.tableBodyCellStyling, ...{fontWeight: 'bold', textAlign: 'right'}}} colSpan={1}>
            {this.calculateTotal(index)}
          </TableCell>
          <TableCell style={{...this.tableBodyCellStyling, ...{fontWeight: 'bold', borderBottom: this.props.selectedTenantData.tenantRows.length === (index + 1) && '1px solid #DADAE6'}}} colSpan={1}/>
        </TableRow>
        <TableRow>
          <TableCell style={{...this.tableBodyCellStyling, ...{textAlign: 'right'}}} colSpan={6}>
            Vorauszahlungen
          </TableCell>
          <TableCell style={{...this.tableBodyCellStyling, ...{fontWeight: 'bold', textAlign: 'right'}}} colSpan={1}>
            {this.props.selectedTenantData ? Autoload.MainHelper.ConverterCurrencyDe_DisplayOnly(this.props.selectedTenantData.prepayments, false) + ' €' : '0,00 €'}
          </TableCell>
          <TableCell style={{...this.tableBodyCellStyling, ...{fontWeight: 'bold', borderBottom: this.props.selectedTenantData.tenantRows.length === (index + 1) && '1px solid #DADAE6'}}} colSpan={1}/>
        </TableRow>
        <TableRow>
          <TableCell style={{...this.tableBodyCellStyling, ...{textAlign: 'right'}}} colSpan={6}>
            {this.props.selectedTenantData ? this.props.selectedTenantData.prepayments - this.props.selectedTenantData.totalAllocations >= 0 ? this.props.billingTemplate ? lang.utility.text_balance_positive : <lable>{lang.utility.text_balance_positive}</lable> : this.props.billingTemplate ? lang.utility.text_balance_negative : <lable>{lang.utility.text_balance_negative}</lable> : this.props.billingTemplate ? lang.utility.text_balance : <lable>{lang.utility.text_balance}</lable>}
          </TableCell>
          <TableCell style={{...this.tableBodyCellStyling, ...{fontWeight: 'bold', textAlign: 'right'}}} colSpan={1}>
            {this.props.selectedTenantData
            ? Autoload.MainHelper.ConverterCurrencyDe_DisplayOnly(this.props.selectedTenantData.prepayments - this.props.selectedTenantData.totalAllocations, false) + ' €'
            : '0,00 €'}
          </TableCell>
          <TableCell style={{...this.tableBodyCellStyling, ...{fontWeight: 'bold', borderBottom: this.props.selectedTenantData.tenantRows.length === (index + 1) && '1px solid #DADAE6'}}} colSpan={1}/>
        </TableRow>
        </>);
      }
    render(){
      const {utilityData, readings, billingTemplate, messagePreview} = this.props
      console.log('readings1: ', this.props.readings?.meterReadings)//.sort((a,b)=> a.meter.allocationKey.allocationKeyName.localeCompare(b.meter.allocationKey.allocationKeyName)));
        return (
        readings &&
          readings.meterReadings &&
          readings.meterReadings.length ? 
          <TableContainer component={Paper} style={{...this.props.style, ...{background: 'transparent'}}}>
        <Table id='ub-meter-readings-table' style={{width: '100%', //borderBottom: billingTemplate && '1px solid #DADAE6', 
        borderCollapse: 'collapse'}}>
          {/* {this.props.billingTemplate ?
          <TableHead style={{border: '1px solid #DADAE6', borderBottom: 'none', borderTop: 'none', borderLeft: 'none'}}>
            {this.props.tableCells ? this.props.tableCells : this.getTableLabels()}
          </TableHead>
          :
          <TableHead style={{border: '1px solid #DADAE6'}}>
            {this.props.tableCells ? this.props.tableCells : this.getTableLabels()}
          </TableHead>} */}
          <TableBody style={{background: 'white', border: '1px solid #DADAE6'}}>
                <>{
              this.props.readings.meterReadings.sort((a,b)=> a.meter.allocationKey.allocationKeyName.localeCompare(b.meter.allocationKey.allocationKeyName))
              .map((meterReading, index) => {
                console.log('meterReading_122: ', meterReading);
                if(index === 0){
                  this.allocationKey = meterReading.meter.allocationKey.allocationKeyName;
                  this.allocationKeyValueTotal = meterReading.consumption;
                  this.allocationKeyUnit = meterReading.meter.allocationKey.units;
                  this.allocationCounter = 1;
                  return(
                  <>
                    <TableRow key={meterReading.meter.allocationKey.allocationKeyId}>
                      <TableCell style={{...this.tableBodyCellStyling, ...{fontWeight: 'bold', background: !billingTemplate && '#F7F7FA', borderRight: '1px solid rgb(218, 218, 230)'}}} colSpan={5}>
                        {this.allocationKey} {!this.allocationKey.includes(this.allocationKeyUnit) && `(${this.allocationKeyUnit})`}
                      </TableCell>
                    </TableRow>
                    {this.getTableLabels()}
                    <TableRow key={`meterReading_${meterReading.id}`}>
                      <TableCell style={{...this.tableBodyCellStyling, ...{}}}>
                        {meterReading.meter.meterNo}
                      </TableCell>
                      <TableCell style={{...this.tableBodyCellStyling, ...{}}}>
                        {meterReading.meter.meterName}
                      </TableCell>
                      <TableCell style={{...this.tableBodyCellStyling, ...{textAlign: 'right'}}}>
                        {meterReading.startValue ? formatNumber(meterReading.startValue ? meterReading.startValue : 0) : '-'}
                      </TableCell>
                      <TableCell style={{...this.tableBodyCellStyling, ...{textAlign: 'right'}}}>
                        {meterReading.currentValue ? formatNumber(meterReading.currentValue ? meterReading.currentValue : 0) : '-'}
                      </TableCell>
                      <TableCell style={{...this.tableBodyCellStyling, ...{textAlign: 'right', borderRight: '1px solid rgb(218, 218, 230)'}}}>
                        {meterReading.consumption ? formatNumber(meterReading.consumption ? meterReading.consumption : 0) : '-'}
                      </TableCell>
                    </TableRow>
                  </>);
                }else if(this.allocationKey !== meterReading.meter.allocationKey.allocationKeyName){
                  let value = this.allocationKeyValueTotal;
                  let allocationKey = this.allocationKey;
                  let unit = this.allocationKeyUnit;
                  let counter = this.allocationCounter;
                  this.allocationKey = meterReading.meter.allocationKey.allocationKeyName;
                  this.allocationKeyValueTotal = meterReading.consumption;
                  this.allocationKeyUnit = meterReading.meter.allocationKey.units;
                  this.allocationCounter = 1;
                  return(
                    <>
                      <TableRow key={`${meterReading.meter.allocationKey.allocationKeyId}_total`}>
                        <TableCell style={{...this.tableBodyCellStyling, ...{textAlign: 'right'}}} colSpan={4}>
                          Gesamt {allocationKey} {!allocationKey.includes(unit) && `(${unit})`}
                        </TableCell>
                        <TableCell style={{...this.tableBodyCellStyling, ...{fontWeight: 'bold', textAlign: 'right'}}} colSpan={1}>
                          {value ? formatNumber(value ? value : 0) : '-'}
                        </TableCell>
                      </TableRow>
                      <TableRow key={meterReading.meter.allocationKey.allocationKeyId}>
                        <TableCell style={{...this.tableBodyCellStyling, ...{fontWeight: 'bold', background: !billingTemplate && '#F7F7FA', borderRight: '1px solid rgb(218, 218, 230)'}}} colSpan={5}>
                          {this.allocationKey} {!this.allocationKey.includes(this.allocationKeyUnit) && `(${this.allocationKeyUnit})`}
                        </TableCell>
                      </TableRow>
                      {this.getTableLabels()}
                      <TableRow key={`meterReading_${meterReading.id}`}>
                        <TableCell style={{...this.tableBodyCellStyling, ...{}}}>
                          {meterReading.meter.meterNo}
                        </TableCell>
                        <TableCell style={{...this.tableBodyCellStyling, ...{}}}>
                          {meterReading.meter.meterName}
                        </TableCell>
                        <TableCell style={{...this.tableBodyCellStyling, ...{textAlign: 'right'}}}>
                          {meterReading.startValue ? formatNumber(meterReading.startValue ? meterReading.startValue : 0) : '-'}
                        </TableCell>
                        <TableCell style={{...this.tableBodyCellStyling, ...{textAlign: 'right'}}}>
                          {meterReading.currentValue ? formatNumber(meterReading.currentValue ? meterReading.currentValue : 0) : '-'}
                        </TableCell>
                        <TableCell style={{...this.tableBodyCellStyling, ...{textAlign: 'right', borderRight: '1px solid rgb(218, 218, 230)'}}}>
                          {meterReading.consumption ? formatNumber(meterReading.consumption ? meterReading.consumption : 0) : '-'}
                        </TableCell>
                      </TableRow>
                    </>);
                }else{
                  this.allocationKeyValueTotal = this.allocationKeyValueTotal + meterReading.consumption;
                  this.allocationCounter = this.allocationCounter + 1;
                  return(
                    <>
                      <TableRow key={`meterReading_${meterReading.id}`}>
                        <TableCell style={{...this.tableBodyCellStyling, ...{}}}>
                          {meterReading.meter.meterNo}
                        </TableCell>
                        <TableCell style={{...this.tableBodyCellStyling, ...{}}}>
                          {meterReading.meter.meterName}
                        </TableCell>
                        <TableCell style={{...this.tableBodyCellStyling, ...{textAlign: 'right'}}}>
                          {meterReading.startValue ? formatNumber(meterReading.startValue ? meterReading.startValue : 0) : '-'}
                        </TableCell>
                        <TableCell style={{...this.tableBodyCellStyling, ...{textAlign: 'right'}}}>
                          {meterReading.currentValue ? formatNumber(meterReading.currentValue ? meterReading.currentValue : 0) : '-'}
                        </TableCell>
                        <TableCell style={{...this.tableBodyCellStyling, ...{textAlign: 'right', borderRight: '1px solid rgb(218, 218, 230)'}}}>
                          {meterReading.consumption ? formatNumber(meterReading.consumption ? meterReading.consumption : 0) : '-'}
                        </TableCell>
                      </TableRow>
                    </>);
                }
              })
            }
            <TableRow key={`${readings.id}_total`}>
              <TableCell style={{...this.tableBodyCellStyling, ...{textAlign: 'right'}}} colSpan={4}>
                Gesamt {this.allocationKey} {!this.allocationKey.includes(this.allocationKeyUnit) && `(${this.allocationKeyUnit})`}
              </TableCell>
              <TableCell style={{...this.tableBodyCellStyling, ...{fontWeight: 'bold', textAlign: 'right', borderRight: '1px solid rgb(218, 218, 230)'}}} colSpan={1}>
                {this.allocationKeyValueTotal ? formatNumber(this.allocationKeyValueTotal ? this.allocationKeyValueTotal : 0) : '-'}
              </TableCell>
            </TableRow>
            </>
          </TableBody>
        </Table>
      </TableContainer>
    : <div style={{background: 'white', padding: '40px'}}>
        <EmptyStateContainer styleMainContainer={{marginTop: 0}} heading={'Keine Ablesungen vorhanden.'} subheading={'Für diesen Mieter liegen keine Ablesungen für die Abrechnungsperiode vor.'} icon={MeterIcon} showBtn={false} />
      </div>
  );
            
    }
}

const mapStateToProps = (state) => {
  // console.log('stateee', state.UtilityBillingReducer)
  return {
    // allUtilityBills: state.UtilityBillingReducer.utilityBillingData,
    allReducer: state,
    currentDataId: state.UtilityBillingReducer.currentBillingId,
    tenantBills: state.UtilityBillingReducer.utilityBillingData,
    utilityBillingData: state.UtilityBillingReducer.utilityBillingData,
    heatingFilter: state.UtilityBillingReducer.HeatingAllocationFilter,
  };
};

const mapDispatchToProps = {
  setTenantBills,
};

export default connect(mapStateToProps, mapDispatchToProps)(MeterReadingsTable);

