import React, { Component } from 'react';
import CustomModal from './index';
// import { Table } from 'reactstrap';
import { Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { ColorButton } from '../../../../index';
import { SaveZuweisungData } from '../../../../../redux/action/UtilityBilling/UtilityBillingActions';
import Autoload from '../../../../Autoload';
import moment from 'moment';
import { Grow, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { showNotification } from '@mantine/notifications';
import RedCross from '../../../../../assets/images/module/general/RedCross.svg';
import GreenTick from '../../../../../assets/images/module/general/GreenTick.svg';
import BlueTick from '../../../../../assets/images/module/general/partner-bullet-icon.svg';
import { Group, Input, Stack } from '@mantine/core';
import CardSelection from '../CardSelection/CardSelection';
import { NumericFormat } from 'react-number-format';
import { formatNumber } from '../../../../../helpers/stringsManipulations';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
const { checkProperty, emptyFieldValidation, invalidSize, loader } = Autoload.MainHelper;

class MessagingPricingModal extends Component {
  constructor(props) {
    super(props);
    this.state = this.initialState;
  }
  initialState = {
    data: [],
    hover1: false,
    hover2: false,
    hover3: false,
    hover4: false,
    hover5: false,
    selectedType: null,
    customAmount: null
  };
  componentDidMount() {
  }

  render() {
    const { open, toggle, className, paymentDetails, accountData, isMobile } = this.props;
    const { hover1, hover2, hover3, hover4, hover5, selectedType, customAmount } = this.state;
    console.log('paymentDetails: ', paymentDetails);
    return (
        <CustomModal
            heading={paymentDetails ? 'Portoguthaben aufladen' : 'Zahlungsmittel hinterlegen'}
            headingClassName={'message-pricing-modal'}
            open={open}
            toggle={() => {
            toggle();
            }}
            className={`${className}`}
        >
            <Stack spacing={30}>
                {paymentDetails ?
                <Stack spacing={30}>
                    <Stack spacing={25}>
                        {/* <Group><span className='modal-sub-headings'>Guthaben aufladen</span></Group> */}
                        <Stack spacing={0} className='override-documents-modal-text' style={{fontSize: '14px', lineHeight: '1.55'}}><span>Ihr aktuelles Guthaben beträgt <span style={{fontWeight: 'bold'}}>{accountData ? checkProperty(accountData, 'accountPostalCredit', null) ? formatNumber(accountData.accountPostalCredit, true) : '0,00 €' : '0,00 €'}</span>.</span><span>Bitte wählen Sie den Zusatzbetrag, den Sie aufladen wollen:</span></Stack>
                        <Group grow={!isMobile} >
                            <CardSelection
                            title={'5,00 €'}
                            width={'auto'}
                            hover={hover1}
                            hoverName={'hover1'}
                            handleMouseEnter={(hoverName)=> this.setState({[hoverName]: true})}
                            handleMouseLeave={(hoverName)=> this.setState({[hoverName]: false})}
                            className={'custom-card-pricing'}
                            type={5}
                            selectedType={selectedType}
                            onClick={()=> this.setState({selectedType: 5})}
                            />
                            <CardSelection
                            title={'10,00 €'}
                            width={'auto'}
                            hover={hover2}
                            hoverName={'hover2'}
                            handleMouseEnter={(hoverName)=> this.setState({[hoverName]: true})}
                            handleMouseLeave={(hoverName)=> this.setState({[hoverName]: false})}
                            className={'custom-card-pricing'}
                            type={10}
                            selectedType={selectedType}
                            onClick={()=> this.setState({selectedType: 10})}
                            />
                            <CardSelection
                            title={'20,00 €'}
                            width={'auto'}
                            hover={hover3}
                            hoverName={'hover3'}
                            handleMouseEnter={(hoverName)=> this.setState({[hoverName]: true})}
                            handleMouseLeave={(hoverName)=> this.setState({[hoverName]: false})}
                            className={'custom-card-pricing'}
                            type={20}
                            selectedType={selectedType}
                            onClick={()=> this.setState({selectedType: 20})}
                            />
                            <CardSelection
                            title={'50,00 €'}
                            width={'auto'}
                            hover={hover4}
                            hoverName={'hover4'}
                            handleMouseEnter={(hoverName)=> this.setState({[hoverName]: true})}
                            handleMouseLeave={(hoverName)=> this.setState({[hoverName]: false})}
                            className={'custom-card-pricing'}
                            type={50}
                            selectedType={selectedType}
                            onClick={()=> this.setState({selectedType: 50})}
                            />
                            <CardSelection
                            title={selectedType === 'other' ? 
                            <NumericFormat
                            className={`custom-pricing-numeric-input`}
                            value={customAmount}
                            thousandSeparator="." 
                            decimalSeparator=","
                            onValueChange={(values, sourceInfo) => {
                                this.setState({
                                    customAmount: values.floatValue,
                                })
                            }}
                            suffix={' €'}
                            placeholder='0,00 €'
                            decimalScale={2}
                            fixedDecimalScale={2}
                            allowNegative={false}
                            onBlur={(e)=> parseFloat(e.target.value) < 5 ? 
                                this.setState({customAmount: 5}, ()=> 
                                    showNotification({
                                        message: 'Der Mindestbetrag zum Aufladen des Portoguthabens beträgt 5 €.',
                                        icon: <img src={RedCross} alt='red-cross'/>,
                                    })
                                )
                            :
                            parseFloat(e.target.value) > 250 &&
                            this.setState({customAmount: 250}, ()=> 
                                showNotification({
                                    message: 'Der maximale Aufladebetrag beträgt 250 €.',
                                    icon: <img src={RedCross} alt='red-cross'/>,
                                })
                            )
                            }
                            />
                            : 
                            'Anderer'}
                            width={'auto'}
                            hover={hover5}
                            hoverName={'hover5'}
                            handleMouseEnter={(hoverName)=> this.setState({[hoverName]: true})}
                            handleMouseLeave={(hoverName)=> this.setState({[hoverName]: false})}
                            className={'custom-card-pricing'}
                            type={'other'}
                            selectedType={selectedType}
                            onClick={()=> this.setState({selectedType: 'other'})}
                            />
                        </Group>
                    </Stack>
                    <Stack spacing={12}>
                        <Group><span className='modal-sub-headings'>{'Portogebühren für Druck, Frankierung und Versand'}</span></Group>
                        <Stack>
                            <ul style={{paddingLeft: 13, marginBottom: 0}}>
                                <li spacing={8}><span className='override-documents-modal-text' style={{fontSize: '12px', lineHeight: '18px'}}>Standardbrief: 1,25 € (1-3 Blatt, 1 Blatt inkl., jedes weitere Blatt 0,12 €)</span></li>
                                <li spacing={8}><span className='override-documents-modal-text' style={{fontSize: '12px', lineHeight: '18px'}}>Kompaktbrief: 1,95 € (4-8 Blatt, 4 Blatt inkl., jedes weitere Blatt 0,12 €)</span></li>
                                <li spacing={8}><span className='override-documents-modal-text' style={{fontSize: '12px', lineHeight: '18px'}}>Großbrief: 3,45 € (9-95 Blatt, 10 Blatt inkl., jedes weitere Blatt 0,12 €)</span></li>
                            </ul>
                        </Stack>
                        <Group>
                            <span className='override-documents-modal-text' style={{fontSize: '12px', lineHeight: '20px', textAlign: 'left'}}>Kosten umfassen einseitigen Druck in Farbe, Frankierung und Versand innerhalb Deutschlands. Alle Angaben inkl. MwSt. hellohousing wählt beim Versand automatisch das geeignetste Briefformat aus.</span>
                        </Group>
                    </Stack>
                    <Stack spacing={12}>
                        <Group><span className='modal-sub-headings'>{'Bestätigung'}</span></Group>
                        <Group>
                            <span className='override-documents-modal-text' style={{fontSize: '12px', lineHeight: '20px', textAlign: 'left'}}>Durch Drücken der Schaltfläche Kostenpflichtig aufladen werden {selectedType ? selectedType !== 'other' ? formatNumber(selectedType, true) : customAmount ? formatNumber(customAmount, true) : '0,00 €' : '0,00 €'} über Ihr hinterlegtes Zahlungsmittel ({paymentDetails.paymentMethod === 'creditcard' ? 'Kreditkarte' : paymentDetails.paymentMethod === 'directdebit' ? 'Lastschrifteinzug' : paymentDetails.paymentMethod}) eingezogen und Ihrem Portoguthaben gutgeschrieben. Sie finden die Rechnung im Anschluss in Ihrem Nutzerkonto.</span>
                        </Group>
                    </Stack>
                </Stack>
                :
                <Group>
                    <span className='override-documents-modal-text'>Um Ihr Portoguthaben aufzuladen, müssen Sie ein Zahlungsmittel hinterlegen. Wollen Sie jetzt Ihrem Nutzerkonto ein Zahlungsmittel hinzufügen?</span>
                </Group>}
                <Group position='apart'>
                    <div>
                        <ColorButton
                        text={'Abbrechen'}
                        className='gray-btn'
                        onClick={()=> toggle()}
                        />
                    </div>
                    <div>
                        <ColorButton
                        text={'Kostenpflichtig aufladen'}
                        className='purple-btn'
                        disabled={selectedType === null || (selectedType === 'other' && customAmount === null)}
                        onClick={()=> { 
                            if(!paymentDetails){
                                this.props.history.push('/profile/companyDetails');
                            }else if(selectedType === 'other' && customAmount === null){
                                showNotification({
                                    message: 'Angabe notwendig',
                                    icon: <img src={RedCross} />
                                })
                            }else if(selectedType === 'other'){
                                this.props.putTopUpCredit(customAmount);
                            }
                            else {
                                this.props.putTopUpCredit(selectedType);
                            }
                        }}
                        />
                    </div>
                </Group>
            </Stack>
        </CustomModal>
    );
  }
}
const mapStateToProps = (state) => {
    return {
        accountData: state.generalReducer.accountData
    }
}

export default connect(mapStateToProps)(MessagingPricingModal);
