import React, { Component } from 'react';
import '../../../../scss/components/profilePic.scss';
import DummyImage from '../../../../../assets/images/module/general/logo1.PNG';

class ProfilePicView extends Component {
  state = {
    loadedImage: null,
    imageFile: null,
  };

  handleImageChnage = (event) => {
    const file = event.target.files[0];
    // console.log('Property Image:', file);
    const reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
      reader.addEventListener('load', () => {
        this.setState({ loadedImage: reader.result, imageFile: file });
      });
    }
  };

  render() {
    return (
      <div className="profilepic_view">
        <div className="profile_div">
          <div>
            {this.state.loadedImage ? (
              <div className="EP-DPContainer2">
      
           <img
                  className="image_container"
                  alt='profileimage'
                  src={this.state.loadedImage}
                  style={{
                    // background: `url('${DummyImage}')`,
                    width: this.props.width,
                    height: this.props.height,
                    borderRadius: this.props.borderRadius,
                  }}
                />
              </div>
            ) : (
              <div className="EP-DPContainer2">
                <img
                  className="image_container"
                  alt='profileimage'
                  src={DummyImage}
                  style={{
                    // background: `url('${DummyImage}')`,
                    width: this.props.width,
                    height: this.props.height,
                    borderRadius: this.props.borderRadius,
                  }}
                />
              </div>
            )}
          </div>

          <div className="button_text">
            <div>
              <label class="custom-file-upload">
                <input type="file" name="image" accept="image/*" onChange={this.handleImageChnage} />
                Upload Button
              </label>
              <div className="png_jpg_text">
                <p>Min 240 x 240px PNG or JPG file</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProfilePicView;
