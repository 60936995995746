import React from 'react';

export default function FiltersContainer({ sortElement,switchElement,searchElement, style, styleSection1, hideSection2=false, hideSection3=false }) {
    return (
        <div className='filters' style={style}>
            <div className='section-1' style={styleSection1}>
                {searchElement}
            </div>
            {!hideSection2 &&
            <div className='section-2'>
                {switchElement && 
                <div className='switch-filter'>
                    {switchElement}
                </div>}
            </div>}
            {!hideSection3 &&
            <div className='section-3'>
                <div className='sort-filter'>
                    {sortElement}
                </div>
            </div>}
        </div>
    );
}
