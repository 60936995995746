import React, { Component } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import Receipt from '../../../../../assets/images/module/general/ReceiptDummy.png';
import { Row, Col, Form, Tooltip, OverlayTrigger } from 'react-bootstrap';
import CustomModal from '.';
import { showNotification } from '@mantine/notifications';
import ColorButton from '../Buttons/ColorButton';
import CopyIcon from '../../../SVGs/copyIcon';
import GreenTick from '../../../../../assets/images/module/general/GreenTick.svg';
import { Checkbox, Group, SimpleGrid, Stack, TextInput } from '@mantine/core';
import EmptyStateContainer from '../EmptyStateContainer/EmptyStateContainer';
import TenantIconBlue from '../../../../../assets/images/module/general/TenantIconBlue.svg';
import TenantIcon from '../../../../../assets/images/module/general/TenantIcon.svg';
import MessagesIconBlue from '../../../../../assets/images/module/general/MessagesIcon.svg';
import MessagesIcon from '../../../../../assets/images/module/general/MessagesIconGray.svg';
import { NumericFormat } from 'react-number-format';
import CardSelection from '../CardSelection/CardSelection';

const MOBILE_SIZE = 992;
const TABLET_SIZE = 1200;

export class SelectMessageTypeModal extends Component {
  state = {
    isMobile: window.innerWidth <= MOBILE_SIZE,
    isTablet: window.innerWidth <= TABLET_SIZE,
    hover1: false,
    hover2: false,
    selectedType: null
  };
  
  constructor(props) {
    super(props);
  }

  handleMouseEnter=(type)=>{
    this.setState({[type]: true});
  }

  handleMouseLeave=(type)=>{
    this.setState({[type]: false});
  }
  
  render() {
    const { open, toggle, className, createNewMessage } = this.props;
    const { hover1, hover2, selectedType, isMobile } = this.state;
    
    return (
      <CustomModal
        heading={'Nachrichtenart auswählen'}
        headingClassName={'message-type-selection-modal-heading'}
        open={open}
        toggle={() => {
          toggle();
        }}
        className={`${className}`}
      >
        <Stack>
            <Stack>
            <SimpleGrid //grow noWrap style={{margin: '0px 0px 10x'}}
            cols={2}
            >
                  <CardSelection
                  title={'Mieter'}
                  subText={'Sie wollen eine Nachricht an Ihre Mieter (aktuelle oder vergangene) senden.'}
                  icon={TenantIcon}
                  blueIcon={TenantIconBlue}
                  hover={hover1}
                  hoverName={'hover1'}
                  selectedType={selectedType}
                  type={'Tenant'}
                  disabled={false}
                  onClick={()=> this.setState({selectedType: 'Tenant'})}
                  handleMouseEnter={(hoverName)=> this.handleMouseEnter(hoverName)}
                  handleMouseLeave={(hoverName)=> this.handleMouseLeave(hoverName)}
                  isMobile={isMobile}
                  />
                  <CardSelection
                  title={'Extern'}
                  subText={'Sie wollen eine Nachricht an externe Adressaten senden, die keine Mieter sind.'}
                  icon={MessagesIcon}
                  blueIcon={MessagesIconBlue}
                  hover={hover2}
                  hoverName={'hover2'}
                  selectedType={selectedType}
                  type={'General'}
                  disabled={false}
                  onClick={()=> this.setState({selectedType: 'General'})}
                  handleMouseEnter={(hoverName)=> this.handleMouseEnter(hoverName)}
                  handleMouseLeave={(hoverName)=> this.handleMouseLeave(hoverName)}
                  isMobile={isMobile}
                  />
              </SimpleGrid>
            </Stack>
            <Group position='apart'>
                <div>
                    <ColorButton
                    text={'Abbrechen'}
                    className='gray-btn'
                    onClick={()=> toggle()}
                    />
                </div>
                <div>
                    <ColorButton
                    text={'Weiter'}
                    className='purple-btn'
                    onClick={()=> createNewMessage(selectedType)}
                    />
                </div>
            </Group>
        </Stack>
      </CustomModal>
    );
  }
}

export default SelectMessageTypeModal;
