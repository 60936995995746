import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CustomModal, EmptyStateContainer, HeadingContainer, Divider, ColorButton } from '../../../../neoverv';
import tap from '../../../../assets/images/module/general/TapIconDark.svg';
import { Col, Row } from 'react-bootstrap';
import Autoload from '../../../../neoverv/Autoload';
import CrossIcon from '../../../../assets/images/module/general/CrossIcon.svg';

const { yearDifferenceMoment, checkProperty, loader, ConverterCurrencyDe } = Autoload.MainHelper;
const MOBILE_SIZE = 425;
var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
let tenantsName = [];
let daysArr = [];

export class CostOrientUtilityBillsTable extends Component {
  state = {
    openModal: false,
    currentRowId: 0,
    isMobile: window.innerWidth <= MOBILE_SIZE,
    utilityData: {
      date: '',
      recordStatus: 'last',
    },
    tableRows: [],
    tenantsList: [],
    tenantDays: [],
  };
  initialSum = 0;

  componentDidMount() {
    let data = this.props.utilityBillingData.find((ub) => ub.id == this.props.currentDataId);
    let diff = yearDifferenceMoment(data.date.split('-')[0]);
    // console.log('data', data)
    if (data !== undefined) {
      this.setState({ utilityData: { date: data.date, recordStatus: data.recordStatus } });
    }

    this.handleResize();
    window.addEventListener('resize', this.handleResize);
    //this.updateTenantOccupations();
    this.updateConsumptions();
  }

  updateConsumptions = () => {
    loader(true);
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.ub_api_base_url + 'updateConsumptions/' + this.props.currentDataId,
      'PUT',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleUpdateConsumptionsResponse',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };
  handleUpdateConsumptionsResponse = (response) => {
    !response.status && console.log('Update Consumptions Failed');

    loader(false);
    this._getCostAllocationsData();
  };

  updateTenantOccupations = () => {
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.ub_api_base_url + 'updateTenantOccupations/' + this.props.currentDataId,
      'PUT',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleUpdateTenantOccupationsResponse',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };
  handleUpdateTenantOccupationsResponse = (response) => {
    !response.status && console.log('Update Tenant Occupations Failed');
  };

  _getCostAllocationsData() {
    loader(true);
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.ub_api_base_url +
      'CostAllocations/forAllCostPositions?utilityBillingId=' +
      this.props.currentDataId,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      '_handleCostAllocationResponse',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }
  _handleCostAllocationResponse(response) {
    console.log('response cost allocation overview screen', response);
    if (!response.status) {
      Autoload.Redirection.redirection_error();
      Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang.newKeys.text_Error_message + response.message);
    }

    if (response.status === true && response.statuscode === 401) {
      localStorage.clear();
      window.location.reload();
    }

    if (response.status === true && response.statuscode === 200) {
      tenantsName = [];
      daysArr = [];

      let data = response.result.map((record) => {
        return {
          costPosition: {
            costPositionId: checkProperty(checkProperty(record, 'costPosition'), 'costPositionId'),
            utilityBillingId: checkProperty(checkProperty(record, 'costPosition'), 'utilityBillingId'),
            amount: Autoload.MainHelper.ConverterCurrencyDe(
              checkProperty(checkProperty(record, 'costPosition'), 'amount') + ''
            ),
            costTypeId: checkProperty(checkProperty(record, 'costPosition'), 'costTypeId'),
            costTypeName: checkProperty(checkProperty(record, 'costPosition'), 'costTypeName'),
            allocationKeyId: checkProperty(checkProperty(record, 'costPosition'), 'allocationKeyId'),
            allocationKeyName: checkProperty(checkProperty(record, 'costPosition'), 'allocationKeyName'),
            consumptionDataAvailable: checkProperty(checkProperty(record, 'costPosition'), 'consumptionDataAvailable'),
          },
          costAllocations:
            checkProperty(record, 'costAllocations', []).length > 0
              ? record.costAllocations.map((allocation) => {
                return {
                  allocationId: checkProperty(allocation, 'allocationId'),
                  utilityBillingId: checkProperty(allocation, 'utilityBillingId'),
                  costPositionId: checkProperty(allocation, 'costPositionId'),
                  costTypeName: checkProperty(allocation, 'costTypeName'),
                  allocationTypeName: checkProperty(allocation, 'allocationTypeName'),
                  billingStatementId: checkProperty(allocation, 'billingStatementId'),
                  tenantName: checkProperty(allocation, 'tenantName'),
                  rentalUnitId: checkProperty(allocation, 'rentalUnitId'),
                  rentalUnitName: checkProperty(allocation, 'rentalUnitName'),
                  tenantOccupation: {
                    startDate: checkProperty(checkProperty(allocation, 'tenantOccupation'), 'startDate'),
                    endDate: checkProperty(checkProperty(allocation, 'tenantOccupation'), 'endDate'),
                    totalDays: checkProperty(checkProperty(allocation, 'tenantOccupation'), 'totalDays'),
                  },
                  tenantConsumption: checkProperty(allocation, 'tenantConsumption'),
                  totalConsumption: checkProperty(allocation, 'totalConsumption'),
                  consumptionUnits: checkProperty(allocation, 'consumptionUnits'),
                  totalCostShare: checkProperty(allocation, 'totalCostShare'),
                  tenantCostShare: checkProperty(allocation, 'tenantCostShare'),
                  totalAdvancePayments: 0,
                  totalAllocatedUtilityCosts: 0,
                };
              })
              : [],
        };
      });

      data.forEach((record) => {
        record.costAllocations.forEach((tenants, i) => {
          if (!tenantsName.includes(tenants.tenantName)) {
            tenantsName.push(tenants.tenantName);
            daysArr.push(tenants.tenantOccupation.totalDays);
          } else {
            let position = tenantsName.indexOf(tenants.tenantName);
            daysArr[position] = daysArr[position] + tenants.tenantOccupation.totalDays;
          }
        });
      });

      this.setState({ tenantsList: tenantsName, tableRows: data, tenantDays: daysArr }, () =>
        this.getBillingStatements()
      );

      console.log('tenants list :', tenantsName);
      console.log('tableRows list :', data);
    } else loader(false);
  }

  getBillingStatements = () => {
    loader(true);

    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.ub_api_base_url + 'billingStatements?utilityBillingId=' + this.props.currentDataId,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleGetBillingStatements',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };
  handleGetBillingStatements = (response) => {
    if (!response.status) {
      Autoload.Redirection.redirection_error();
      Autoload.DataHandlerGeneral.GeneralErrorMessage(
        true,
        lang.newKeys.text_error_Billing_Statements + response.message
      );
    }

    if (response.status && response.result && response.result.length > 0) {
      let updatedRows = this.state.tableRows.map((i) => {
        let updatedCostAllocations = i.costAllocations.map((ca) => {
          let bs = response.result.find((bs) => bs.tenantName === ca.tenantName);

          return bs
            ? {
              ...ca,
              totalAdvancePayments: bs.totalAdvancePayments,
              totalAllocatedUtilityCosts: bs.totalAllocatedUtilityCosts,
            }
            : { ...ca, totalAdvancePayments: 0, totalAllocatedUtilityCosts: 0 };
        });

        return { ...i, costAllocations: updatedCostAllocations };
      });

      this.setState(
        {
          tableRows: updatedRows,
        },
        () => loader(false)
      );
    } else loader(false);
  };

  getAllData = () => {
    const { allUtilityBills } = this.props;
    let currentBillingData = allUtilityBills.find((ub) => ub.id == this.props.currentDataId);
    let allTenantOverview = currentBillingData.billingData.overviewData;
    return allTenantOverview.length > 0 ? allTenantOverview : [];
  };

  onInputChange = (field, value) => {
    this.setState({ [field]: value });
  };
  toggleShowModal = (id) => {
    this.setState({ openModal: !this.state.openModal, currentRowId: id }, () =>
      console.log('modal', id, this.state.openModal)
    );
  };
  handleResize = () => {
    if (window.innerWidth <= MOBILE_SIZE) {
      this.setState({ isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
  };

  getSumRow = () => {
    let sum = 0;
    let jsx = this.state.tenantsList.map((tenant) => {
      let allocation = 0;

      this.state.tableRows.forEach((i) => {
        let ca = i.costAllocations.find((ca) => ca.tenantName === tenant);
        if (ca) allocation += ca.tenantCostShare;
      });
      sum += allocation;
      return <td className="width-160">{ConverterCurrencyDe(allocation.toString())}</td>;
    });

    jsx.unshift(<td className="summe-mieter">{ConverterCurrencyDe(sum.toString())}</td>);

    return jsx;
  };
  getTotalAllocationsRow = () => {
    let sum = 0;
    let jsx = this.state.tenantsList.map((tenant) => {
      if (this.state.tableRows.length > 0) {
        let ca = this.state.tableRows[0].costAllocations.find((ca) => ca.tenantName === tenant);
        if (ca) {
          sum += ca.totalAllocatedUtilityCosts;
          return <td className="width-160">{ConverterCurrencyDe(ca.totalAllocatedUtilityCosts.toString())}</td>;
        }
      }
      return <td className="width-160">{ConverterCurrencyDe('0')}</td>;
    });

    jsx.unshift(<td className="summe-mieter">{ConverterCurrencyDe(sum.toString())}</td>);

    return jsx;
  };
  getPrepaymentsRow = () => {
    let sum = 0;
    let jsx = this.state.tenantsList.map((tenant) => {
      if (this.state.tableRows.length > 0) {
        let ca = this.state.tableRows[0].costAllocations.find((ca) => ca.tenantName === tenant);
        if (ca) {
          sum += ca.totalAdvancePayments;
          return <td className="width-160">{ConverterCurrencyDe(ca.totalAdvancePayments.toString())}</td>;
        }
      }
      return <td className="width-160">{ConverterCurrencyDe('0')}</td>;
    });

    jsx.unshift(<td className="summe-mieter">{ConverterCurrencyDe(sum.toString())}</td>);

    return jsx;
  };
  getBalanceRow = () => {
    let sum = 0; //totalAllocatedUtilityCosts
    let jsx = this.state.tenantsList.map((tenant) => {
      if (this.state.tableRows.length > 0) {
        let ca = this.state.tableRows[0].costAllocations.find((ca) => ca.tenantName === tenant);
        if (ca) {
          let balance = ca.totalAdvancePayments - ca.totalAllocatedUtilityCosts;
          sum += balance;
          return <td className="width-160">{ConverterCurrencyDe(balance.toString())}</td>;
        }
      }
      return <td className="width-160">{ConverterCurrencyDe('0')}</td>;
    });

    jsx.unshift(<td className="summe-mieter">{ConverterCurrencyDe(sum.toString())}</td>);

    return jsx;
  };

  render() {
    const { isMobile, utilityData } = this.state;

    return (
      <div className="view-utility-billing">
        <HeadingContainer
          heading={lang.utility.text_utility_billing}
          subheading={utilityData.date}
          className="profile-heading tenant-head utility-billing-head"
          headingIcon={CrossIcon}
          iconHandler={
            () => {
              this.props.history.push('/utility-billing');
            }
            // this.goBackIcon()
          }
          subheadingClassName="utility-subheading"
          subheadingbadge={
            <span className={utilityData.recordStatus === 'last' ? 'last-period-badge' : 'no-recent-period-bagde'}>
              {utilityData.recordStatus === 'last' ? lang.utility.text_last_billing_period : ''}
            </span>
          }
          rightContent={
            <div className="btn-container utility-bility-button">
              <ColorButton
                onClick={() => {
                  // this.goBack();
                  this.props.goBack();
                }}
                text={lang.utility.text_go_back}
                className={`gray-btn`}
              />
              <ColorButton
                onClick={() => {
                  // this.moveForward();
                  this.props.history.push('/utility-billing');
                }}
                text={lang.utility.text_save_utility_billing}
                className="purple-btn mr-1"
              />
            </div>
          }
        />

        <div className=" mobile-top-btn-section btn-container utility-bility-button">
          <ColorButton
            onClick={() => {
              // this.goBack();
              this.props.goBack();
            }}
            text={lang.utility.text_go_back}
            className={`gray-btn`}
          />
          <ColorButton
            onClick={() => {
              // this.moveForward();
              this.props.history.push('/utility-billing');
            }}
            text={lang.utility.text_save_utility_billing}
            className="purple-btn mr-1"
          />
        </div>

        <Divider className="utilitiy--divider" />

        {this.state.tenantsList.length === 0 && this.state.tableRows.length === 0 ? (
          <EmptyStateContainer heading="There's no utility billings of tenants" icon={tap} showBtn={false} />
        ) : (
          <div>
            <div className="cost-allocation-section utility-bills-section overview-section">
              <div className="abc">
                <h5 className="utility-cost-allocation">{lang.utility.text_utility_bills}</h5>
              </div>
              <p className="utility-cost-allocation-subheading">{lang.utility.text_ub_desc}</p>
              <table className={`table borderless mb-0 ub-table`}>
                <tbody className="ub-body">
                  <tr className={`ub-row table-heading`}>
                    <td className="cost-type">Cost type</td>
                    <td className="summe-mieter">Summe Mieter</td>
                    {this.state.tenantsList.map((tenant, idx) => {
                      return (
                        <td className="width-160">
                          <span>{tenant}</span>
                        </td>
                      );
                    })}
                  </tr>

                  {this.state.tableRows.map((costAlloc, index) => {
                    return (
                      <tr className={`ub-row`} onClick={() => this.toggleShowModal(index)}>
                        <td className="cost-type">{costAlloc.costPosition.costTypeName}</td>
                        <td className="summe-mieter">
                          {costAlloc.costAllocations.length > 0
                            ? ConverterCurrencyDe(costAlloc.costAllocations[0].totalCostShare.toString())
                            : '-'}
                        </td>
                        {this.state.tenantsList.map((tenant) => {
                          let tenantFound = costAlloc.costAllocations.find((record) => record.tenantName === tenant);
                          return (
                            <td className="width-160">
                              {tenantFound && Object.keys(tenantFound).length > 0
                                ? ConverterCurrencyDe(tenantFound.tenantCostShare.toString())
                                : '-'}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}

                  <tr className={`ub-row top-line hide-on-web`}>
                    <td className="cost-type">Sum</td>
                    {this.getSumRow()}
                  </tr>

                  <tr className={`ub-row top-line`}>
                    <td className="cost-type">Stayed for</td>
                    <td className="summe-mieter">{`${this.state.tenantDays.reduce(
                      (total, current) => total + current,
                      0
                    )} ${lang.newKeys.text_days}`}</td>
                    {this.state.tenantDays.map((tenant) => {
                      return (
                        <td className="width-160">
                          <span>{`${tenant} ${lang.newKeys.text_days}`}</span>
                        </td>
                      );
                    })}
                  </tr>

                  <tr className={`ub-row top-line hide-on-web`}>
                    <td className="cost-type">Total allocation</td>
                    {this.getTotalAllocationsRow()}
                  </tr>

                  <tr className={`ub-row top-line hide-on-web`}>
                    <td className="cost-type">Prepayments</td>
                    {this.getPrepaymentsRow()}
                  </tr>

                  <tr className={`ub-row top-line hide-on-web`}>
                    <td className="cost-type">Balance</td>
                    {this.getBalanceRow()}
                  </tr>
                </tbody>
              </table>

              {isMobile && (
                <CustomModal
                  // className="utility-bills-modal"
                  open={this.state.openModal}
                  toggle={() => {
                    // this.toggleShowModal();
                    this.setState({ openModal: false });
                  }}
                  heading="Cost type details"
                >
                  {/* abc */}

                  <>
                    <Row className="utility-step-two-three">
                      <Col xs={7} className="costBased-user1">
                        {lang.utility.text_Cost_type}
                      </Col>
                      <Col xs={5} className="costBased_value bold-user">
                        {this.state.tableRows[this.state.currentRowId].costPosition.costTypeName}
                      </Col>
                    </Row>
                    <Row className="utility-step-two-three">
                      <Col xs={7} className="costBased-user1">
                        {lang.utility.text_Summe_Mieter}
                      </Col>
                      <Col xs={5} className="costBased_value">
                        {this.state.tableRows[this.state.currentRowId].costAllocations.length > 0
                          ? ConverterCurrencyDe(
                            this.state.tableRows[this.state.currentRowId].costAllocations[0].totalCostShare.toString()
                          )
                          : 0}
                      </Col>
                    </Row>
                    {this.state.tableRows[this.state.currentRowId].costAllocations.map((tenant) => {
                      return (
                        <Row className="utility-step-two-three">
                          <Col xs={7} className="costBased-user1">
                            {tenant.tenantName}
                          </Col>
                          <Col xs={5} className="costBased_value">
                            {ConverterCurrencyDe(tenant.tenantCostShare.toString())}
                          </Col>
                        </Row>
                      );
                    })}
                    {/* <Row className="utility-step-two-three">
                              <Col xs={9} className="costBased-user1">
                                {lang.utility.text_Mike_Jonathon_Stanfordinthebay}
                              </Col>
                              <Col xs={3} className="costBased_value">

                                {data[this.state.currentRowId][2]}{' '}
                              </Col>
                            </Row>
                            <Row className="utility-step-two-three">
                              <Col xs={7} className="costBased-user1">
                                {lang.utility.text_Stacy_Smith}
                              </Col>
                              <Col xs={5} className="costBased_value">

                                {data[this.state.currentRowId][3]}{' '}
                              </Col>
                            </Row>
                            <Row className="utility-step-two-three">
                              <Col xs={7} className="costBased-user1">
                                {lang.utility.text_Oxford_Bulldog}
                              </Col>
                              <Col xs={5} className="costBased_value">

                                {data[this.state.currentRowId][4]}{' '}
                              </Col>
                            </Row>
                            <Row className="utility-step-two-three">
                              <Col xs={7} className="costBased-user1">
                                {lang.utility.text_Anabell_Harvard}
                              </Col>
                              <Col xs={5} className="costBased_value">

                                {data[this.state.currentRowId][5]}{' '}
                              </Col>
                            </Row>
                            <Row className="utility-step-two-three">
                              <Col xs={7} className="costBased-user1">
                                {lang.utility.text_Tom_Yale}
                              </Col>
                              <Col xs={5} className="costBased_value">

                                {data[this.state.currentRowId][6]}{' '}
                              </Col>
                            </Row>
                            <Row className="utility-step-two-three">
                              <Col xs={7} className="costBased-user1">
                                {lang.utility.text_House_Bell_GmbH}
                              </Col>
                              <Col xs={5} className="costBased_value">

                                {data[this.state.currentRowId][7]}{' '}
                              </Col>
                            </Row> */}
                  </>
                </CustomModal>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

const convertToNumber = (value) =>
  parseFloat(value.split('€')[0].split(',')[0] + '.' + value.split('€')[0].split(',')[1]);

const mapStateToProps = (state) => ({
  costType: state.UtilityBillingReducer.costType,
  allUtilityBills: state.UtilityBillingReducer.utilityBillingData,
  allReducer: state,
  currentDataId: state.UtilityBillingReducer.currentBillingId,
  utilityBillingData: state.UtilityBillingReducer.utilityBillingData,
});

export default connect(mapStateToProps)(CostOrientUtilityBillsTable);
