import React from 'react';
import { ColorButton, HeadingContainer, RecordOptions, TenantsRecord } from '../../../../../neoverv/index';
import Autoload from '../../../../../neoverv/Autoload';
import { Group } from '@mantine/core';
var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];

export default function TenantRecordRow({
  parent,
  showAll,
  history,
  unitData,
  handleAddTenant,
  handleAddEmptyTenant,
  handleEditUnit,
  handleUnitDelete,
  TenantsAfterAddModal,
  tenantAdministration= false,
  propertySelected
}) {
  return (
    <div className="tenant-record-row">
      <div className="row-heading-container">
        <HeadingContainer
          heading={
          <Group spacing={10} noWrap>
            {unitData.name}
            {propertySelected.propertyType === 'REGULAR' && 
              <RecordOptions
                toggleEditModal={() => handleEditUnit(unitData.id)}
                toggleDeleteModal={() => handleUnitDelete(unitData.id)}
                buttonStyle={{background: 'none', border: 'none'}}
              />}
          </Group>}
          rightContent={
            !tenantAdministration &&
            <div>
              <ColorButton onClick={()=>handleAddTenant()} text={lang.tenant.text_add_tenant} />
            </div>
          }
        />
      </div>
      <div className="row-table-container">
        <TenantsRecord
          showAllTenantsAfterAdd={TenantsAfterAddModal}
          parent={parent}
          showAll={showAll}
          history={history}
          tenantAdministration={tenantAdministration}
          handleAddTenant={(startDate, endDate)=> handleAddEmptyTenant(startDate, endDate)}
          // handleTenantDelete={handleTenantDelete}
          parentId={unitData.id}
          data={unitData.tenantsData}
          editOption={true}
          labelClassName={['fullname', 'email', 'phone-number', 'move-in', 'move-out', //'tenant-portal-access'
        ]}
          labels={[
            'Mieter',//lang.general.text_full_name,
            lang.general.text_email,
            lang.general.text_phone_number,
            lang.tenant.text_move_in,
            lang.tenant.text_move_out,
            //'Mieterportal',
            '',
          ]}
        />
      </div>
    </div>
  );
}
TenantRecordRow.defaultProps = {
  data: [],
};