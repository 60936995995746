import Autoload from '../../Autoload';
import moment from 'moment';
const { checkProperty } = Autoload.MainHelper;

class MeterDataHandler {
  //@desc manipulating single meter data by meter id to show when editing meter
  static getMeterByMeterID = (meterData) => {
    return {
      id: Autoload.MainHelper.checkProperty(meterData, 'id'),
      propertyId: Autoload.MainHelper.checkProperty(meterData, 'propertyId'),
      rentalUnitId: Autoload.MainHelper.checkProperty(meterData, 'rentalUnitId'),
      rentalUnitName: Autoload.MainHelper.checkProperty(meterData, 'rentalUnitName'),
      meterNo: Autoload.MainHelper.checkProperty(meterData, 'meterNo'),
      location: Autoload.MainHelper.checkProperty(meterData, 'location'),
      allocationKey: Autoload.MainHelper.checkProperty(meterData, 'allocationKey'),
      allocationKeyName: Autoload.MainHelper.checkProperty(meterData, 'allocationKeyName'),
      notes: Autoload.MainHelper.checkProperty(meterData, 'notes'),
      calibratedUntil: Autoload.MainHelper.checkProperty(meterData, 'calibratedUntil'),
      propertyMeter: Autoload.MainHelper.checkProperty(meterData, 'propertyMeter'),
      meterType: Autoload.MainHelper.checkProperty(meterData, 'meterType'),
      meterName: Autoload.MainHelper.checkProperty(meterData, 'meterName'),
      lastBillingPeriodId: meterData.lastBillingPeriodId,
    };
  };

  //@desc manipulating all meter master response
  static getAllMeters = (meters) => {
    const checkedMeters = meters.map((meter) => {
      return {
        id: Autoload.MainHelper.checkProperty(meter, 'id'),
        propertyId: Autoload.MainHelper.checkProperty(meter, 'propertyId'),
        rentalUnitId: Autoload.MainHelper.checkProperty(meter, 'rentalUnitId'),
        rentalUnitName: Autoload.MainHelper.checkProperty(meter, 'rentalUnitName'),
        meterNo: Autoload.MainHelper.checkProperty(meter, 'meterNo'),
        location: Autoload.MainHelper.checkProperty(meter, 'location'),
        allocationKey: Autoload.MainHelper.checkProperty(meter, 'allocationKey'),
        allocationKeyName: Autoload.MainHelper.checkProperty(meter, 'allocationKeyName'),
        notes: Autoload.MainHelper.checkProperty(meter, 'notes'),
        calibratedUntil: Autoload.MainHelper.checkProperty(meter, 'calibratedUntil'),
        propertyMeter: Autoload.MainHelper.checkProperty(meter, 'propertyMeter'),
        meterType: Autoload.MainHelper.checkProperty(meter, 'meterType'),
        meterName: Autoload.MainHelper.checkProperty(meter, 'meterName'),
        lastBillingPeriodId: meter.lastBillingPeriodId
      };
    });
    return checkedMeters;
  };

  //@desc manipulating create meter data payload
  static generateMeterData = (meter) => {
    return {
      id: Autoload.MainHelper.checkProperty(meter, 'id', null),
      meterId: Autoload.MainHelper.checkProperty(meter, 'meterId'), //id
      propertyId: Autoload.MainHelper.checkProperty(meter, 'propertyId'),
      rentalUnitId: Autoload.MainHelper.checkProperty(meter, 'rentalUnitId'),
      rentalUnitName: Autoload.MainHelper.checkProperty(meter, 'rentalUnitName'),
      meterNo: Autoload.MainHelper.checkProperty(meter, 'meterNo'),
      location: Autoload.MainHelper.checkProperty(meter, 'location'),
      allocationKey: Autoload.MainHelper.checkProperty(meter, 'allocationKey'),
      allocationKeyName: Autoload.MainHelper.checkProperty(meter, 'allocationKeyName'),
      notes: Autoload.MainHelper.checkProperty(meter, 'notes'),
      propertyMeter: Autoload.MainHelper.checkProperty(meter, 'propertyMeter'),
      meterType: Autoload.MainHelper.checkProperty(meter, 'meterType'),
      meterName: Autoload.MainHelper.checkProperty(meter, 'meterName'),
      //calibratedUntil: Autoload.MainHelper.checkProperty(meter, 'calibratedUntil'),
    };
  };

  static generateMeterDataForNotesSave = (meter) => {
    return {
      id: Autoload.MainHelper.checkProperty(meter, 'meterId', null),
      propertyId: Autoload.MainHelper.checkProperty(meter, 'propertyId'),
      rentalUnitId: Autoload.MainHelper.checkProperty(meter, 'rentalUnitId'),
      rentalUnitName: Autoload.MainHelper.checkProperty(meter, 'rentalUnitName'),
      meterNo: Autoload.MainHelper.checkProperty(meter, 'meterNo'),
      location: Autoload.MainHelper.checkProperty(meter, 'location'),
      allocationKey: Autoload.MainHelper.checkProperty(meter, 'allocationKey'),
      notes: Autoload.MainHelper.checkProperty(meter, 'notes'),
      propertyMeter: Autoload.MainHelper.checkProperty(meter, 'propertyMeter'),
      meterType: Autoload.MainHelper.checkProperty(meter, 'meterType'),
    };
  };

  static generateMeterDatawithAllocationKey = (meter) => {
    return {
      id: Autoload.MainHelper.checkProperty(meter, 'id', null),
      meterId: Autoload.MainHelper.checkProperty(meter, 'id'),
      propertyId: Autoload.MainHelper.checkProperty(meter, 'propertyId'),
      rentalUnitId: Autoload.MainHelper.checkProperty(meter, 'rentalUnitId'),
      rentalUnitName: Autoload.MainHelper.checkProperty(meter, 'rentalUnitName'),
      meterNo: Autoload.MainHelper.checkProperty(meter, 'meterNo'),
      location: Autoload.MainHelper.checkProperty(meter, 'location'),
      allocationKey: {
        allocationKeyId: Autoload.MainHelper.checkProperty(
          Autoload.MainHelper.checkProperty(meter, 'allocationKey'),
          'id'
        ),
        id: Autoload.MainHelper.checkProperty(Autoload.MainHelper.checkProperty(meter, 'allocationKey'), 'id'),
      },
      allocationKeyName: Autoload.MainHelper.checkProperty(meter, 'allocationKeyName'),
      notes: Autoload.MainHelper.checkProperty(meter, 'notes'),
      propertyMeter: Autoload.MainHelper.checkProperty(meter, 'propertyMeter'),
      meterType: Autoload.MainHelper.checkProperty(meter, 'meterType'),
      meterName: Autoload.MainHelper.checkProperty(meter, 'meterName'),
      lastBillingPeriodId: meter.lastBillingPeriodId
      //calibratedUntil: Autoload.MainHelper.checkProperty(meter, 'calibratedUntil'),
    };
  };

  //@desc manipulating meter readings biling periods response
  static generateBilingPeriods = (billingPeriods, type = 'other') => {
    const checkedBilingPeriods = billingPeriods.map((period) => {
      return {
        id: Autoload.MainHelper.checkProperty(period, 'id'),
        propertyId: Autoload.MainHelper.checkProperty(period, 'propertyId'),
        endDate: Autoload.MainHelper.checkProperty(period, 'endDate'),
        startDate: Autoload.MainHelper.checkProperty(period, 'startDate'),
        type: type,
      };
    });
    return checkedBilingPeriods;
  };

  static generateBilingPeriodsObject = (billingPeriods, type = 'other') => {
    const checkedBilingPeriods = {
      id: Autoload.MainHelper.checkProperty(billingPeriods, 'id'),
      propertyId: Autoload.MainHelper.checkProperty(billingPeriods, 'propertyId'),
      endDate: Autoload.MainHelper.checkProperty(billingPeriods, 'endDate'),
      startDate: Autoload.MainHelper.checkProperty(billingPeriods, 'startDate'),
      type: type,
    };

    return checkedBilingPeriods;
  };

  //@desc manipulating meter occupations readings response
  static getOccupationReadings = (occupationReadings, billingId = null) => {
    if (occupationReadings.length === 0) {
      return [];
    }

    const checkedoccupationReadings = occupationReadings.map((reading) => {
      return {
        id: Autoload.MainHelper.checkProperty(reading, 'id'),
        contractType: Autoload.MainHelper.checkProperty(reading, 'contractType'),
        billingPeriodId: billingId === null ? Autoload.MainHelper.checkProperty(reading, 'billingPeriodId') : billingId,
        propertyId: Autoload.MainHelper.checkProperty(reading, 'propertyId'),
        rentalUnitId: Autoload.MainHelper.checkProperty(reading, 'rentalUnitId'),
        rentalUnitName: Autoload.MainHelper.checkProperty(reading, 'rentalUnitName'),
        tenantId: Autoload.MainHelper.checkProperty(reading, 'tenantId'),
        tenantFullName: Autoload.MainHelper.checkProperty(reading, 'tenantFullName'),
        periodStart: Autoload.MainHelper.checkProperty(reading, 'periodStart'),
        periodEnd: Autoload.MainHelper.checkProperty(reading, 'periodEnd'),
        periodStartReading: {
          id: Autoload.MainHelper.checkProperty(reading.periodStartReading, 'id'),
          billingPeriodId: Autoload.MainHelper.checkProperty(reading.periodStartReading, 'billingPeriodId'),
          rentalUnitId: Autoload.MainHelper.checkProperty(reading.periodStartReading, 'rentalUnitId'),
          occasion: Autoload.MainHelper.checkProperty(reading.periodStartReading, 'occasion'),
          occasionDate: Autoload.MainHelper.checkProperty(reading.periodStartReading, 'occasionDate'),
          meterReadings: Autoload.MainHelper.checkProperty(reading.periodStartReading, 'meterReadings')
            ? reading.periodStartReading.meterReadings.map((meterReading) => {
              return {
                meterReadingId: Autoload.MainHelper.checkProperty(meterReading, 'meterReadingId'),
                meterId: Autoload.MainHelper.checkProperty(meterReading, 'meterId'),
                readingId: Autoload.MainHelper.checkProperty(meterReading, 'readingId'),
                currentValue: Autoload.MainHelper.checkProperty(meterReading, 'currentValue'),
                startValue: Autoload.MainHelper.checkProperty(meterReading, 'startValue'),
                consumption: Autoload.MainHelper.checkProperty(meterReading, 'consumption'),
                meterReadingDate: Autoload.MainHelper.checkProperty(meterReading, 'meterReadingDate'),
              };
            })
            : [],
        },
        periodEndReading: {
          id: Autoload.MainHelper.checkProperty(reading.periodEndReading, 'id'),
          billingPeriodId: Autoload.MainHelper.checkProperty(reading.periodEndReading, 'billingPeriodId'),
          rentalUnitId: Autoload.MainHelper.checkProperty(reading.periodEndReading, 'rentalUnitId'),
          occasion: Autoload.MainHelper.checkProperty(reading.periodEndReading, 'occasion'),
          occasionDate: Autoload.MainHelper.checkProperty(reading.periodEndReading, 'occasionDate'),
          meterReadings: Autoload.MainHelper.checkProperty(reading.periodEndReading, 'meterReadings')
            ? reading.periodEndReading.meterReadings.map((meterReading) => {
              return {
                meterReadingId: Autoload.MainHelper.checkProperty(meterReading, 'meterReadingId'),
                meterId: Autoload.MainHelper.checkProperty(meterReading, 'meterId'),
                readingId: Autoload.MainHelper.checkProperty(meterReading, 'readingId'),
                currentValue: Autoload.MainHelper.checkProperty(meterReading, 'currentValue'),
                startValue: Autoload.MainHelper.checkProperty(meterReading, 'startValue'),
                consumption: Autoload.MainHelper.checkProperty(meterReading, 'consumption'),
                meterReadingDate: Autoload.MainHelper.checkProperty(meterReading, 'meterReadingDate'),
              };
            })
            : [],
        },
        periodDays: Autoload.MainHelper.checkProperty(reading, 'periodDays'),
        vacant: Autoload.MainHelper.checkProperty(reading, 'vacant'),
      };
    });
    return checkedoccupationReadings;
  };

  //@desc manipulating tenant billing periods response
  static getTenantsInBillingPeriods = (tenantBillingPeriods) => {
    const checkedTenantBillingPeriods = tenantBillingPeriods.map((tenantInBillingPeriod) => {
      return {
        tenant: {
          id: Autoload.MainHelper.checkProperty(tenantInBillingPeriod.tenant, 'id'),
          rentalUnitId: Autoload.MainHelper.checkProperty(tenantInBillingPeriod.tenant, 'rentalUnitId'),
          fullName: Autoload.MainHelper.checkProperty(tenantInBillingPeriod.tenant, 'fullName'),
          contractType: Autoload.MainHelper.checkProperty(tenantInBillingPeriod.tenant, 'contractType'),
          vacant: Autoload.MainHelper.checkProperty(tenantInBillingPeriod.tenant, 'vacant'),
          phone: Autoload.MainHelper.checkProperty(tenantInBillingPeriod.tenant, 'phone'),
          email: Autoload.MainHelper.checkProperty(tenantInBillingPeriod.tenant, 'email'),
          moveInDate: Autoload.MainHelper.checkProperty(tenantInBillingPeriod.tenant, 'moveInDate'),
          moveOutDate: Autoload.MainHelper.checkProperty(tenantInBillingPeriod.tenant, 'moveOutDate'),
          notes: Autoload.MainHelper.checkProperty(tenantInBillingPeriod.tenant, 'notes'),
        },
        occupation: {
          startDate: Autoload.MainHelper.checkProperty(tenantInBillingPeriod.occupation, 'startDate'),
          endDate: Autoload.MainHelper.checkProperty(tenantInBillingPeriod.occupation, 'endDate'),
          totalDays: Autoload.MainHelper.checkProperty(tenantInBillingPeriod.occupation, 'totalDays'),
        },
      };
    });
    return checkedTenantBillingPeriods;
  };

  //@desc manipulating tenants occupation reading periods response
  static getTenantOccupationReadings = (occupationReadingForTenant) => {
    if (occupationReadingForTenant === null || typeof occupationReadingForTenant === 'undefined') return null;
    return {
      id: checkProperty(occupationReadingForTenant, 'id'),
      readingId: checkProperty(occupationReadingForTenant, 'readingId'),
      billingPeriodId: checkProperty(occupationReadingForTenant, 'billingPeriodId'),
      propertyId: checkProperty(occupationReadingForTenant, 'propertyId'),
      rentalUnitId: checkProperty(occupationReadingForTenant, 'rentalUnitId'),
      rentalUnitName: checkProperty(occupationReadingForTenant, 'rentalUnitName'),
      tenantId: checkProperty(occupationReadingForTenant, 'tenantId'),
      tenantFullName: checkProperty(occupationReadingForTenant, 'tenantFullName'),
      periodStart: checkProperty(occupationReadingForTenant, 'periodStart'),
      periodEnd: checkProperty(occupationReadingForTenant, 'periodEnd'),
      periodStartReading: {
        id: checkProperty(occupationReadingForTenant.periodStartReading, 'id'),
        billingPeriodId: checkProperty(occupationReadingForTenant.periodStartReading, 'billingPeriodId'),
        rentalUnitId: checkProperty(occupationReadingForTenant.periodStartReading, 'rentalUnitId'),
        occasion: checkProperty(occupationReadingForTenant.periodStartReading, 'occasion'),
        occasionDate: checkProperty(occupationReadingForTenant.periodStartReading, 'occasionDate'),
        meterReadings: checkProperty(occupationReadingForTenant.periodStartReading, 'meterReadings')
          ? occupationReadingForTenant.periodStartReading.meterReadings.map((meterReading) => {
            return {
              id: checkProperty(meterReading, 'id'),
              // meter: checkProperty(meterReading, 'meter'),
              meter: {
                meterId: checkProperty(meterReading.meter, 'id'),
                propertyId: checkProperty(meterReading.meter, 'propertyId'),
                rentalUnitId: checkProperty(meterReading.meter, 'rentalUnitId'),
                propertyMeter: checkProperty(meterReading.meter, 'propertyMeter'),
                rentalUnitName: checkProperty(meterReading.meter, 'rentalUnitName'),
                meterNo: checkProperty(meterReading.meter, 'meterNo'),
                meterName: checkProperty(meterReading.meter, 'meterName'),
                location: checkProperty(meterReading.meter, 'location'),
                allocationKey: {
                  allocationKeyId: checkProperty(checkProperty(meterReading.meter, 'allocationKey'), 'id'), //'allocationKeyId'),
                  allocationKeyName: checkProperty(
                    checkProperty(meterReading.meter, 'allocationKey'),
                    'allocationKeyName'
                  ),
                  propertyId: checkProperty(checkProperty(meterReading.meter, 'allocationKey'), 'propertyId'),
                  units: checkProperty(checkProperty(meterReading.meter, 'allocationKey'), 'units'),
                  metered: checkProperty(checkProperty(meterReading.meter, 'allocationKey'), 'metered'),
                  systemAllocationKey: checkProperty(
                    checkProperty(meterReading.meter, 'allocationKey'),
                    'systemAllocationKey'
                  ),
                  type: checkProperty(checkProperty(meterReading.meter, 'allocationKey'), 'type'),
                },
                notes: checkProperty(meterReading.meter, 'notes'),
              },
              // meterId: checkProperty(meterReading, 'meterId'),
              readingId: checkProperty(meterReading, 'readingId'),
              currentValue: checkProperty(meterReading, 'currentValue', null),
              startValue: checkProperty(meterReading, 'startValue', null),
              consumption: checkProperty(meterReading, 'consumption', null),
              meterReadingDate: checkProperty(meterReading, 'meterReadingDate'),
            };
          })
          : [],
      },
      periodEndReading: {
        id: checkProperty(occupationReadingForTenant.periodEndReading, 'id'),
        billingPeriodId: checkProperty(occupationReadingForTenant.periodEndReading, 'billingPeriodId'),
        rentalUnitId: checkProperty(occupationReadingForTenant.periodEndReading, 'rentalUnitId'),
        occasion: checkProperty(occupationReadingForTenant.periodEndReading, 'occasion'),
        occasionDate: checkProperty(occupationReadingForTenant.periodEndReading, 'occasionDate'),
        meterReadings: checkProperty(occupationReadingForTenant.periodEndReading, 'meterReadings')
          ? occupationReadingForTenant.periodEndReading.meterReadings.map((meterReading) => {
            return {
              id: checkProperty(meterReading, 'id'),
              // meter: checkProperty(meterReading, 'meter'),
              // meterId: checkProperty(meterReading, 'meterId'),
              meter: {
                meterId: checkProperty(meterReading.meter, 'id'),
                propertyId: checkProperty(meterReading.meter, 'propertyId'),
                rentalUnitId: checkProperty(meterReading.meter, 'rentalUnitId'),
                propertyMeter: checkProperty(meterReading.meter, 'propertyMeter'),
                rentalUnitName: checkProperty(meterReading.meter, 'rentalUnitName'),
                meterNo: checkProperty(meterReading.meter, 'meterNo'),
                meterName: checkProperty(meterReading.meter, 'meterName'),
                location: checkProperty(meterReading.meter, 'location'),
                allocationKey: {
                  allocationKeyId: checkProperty(checkProperty(meterReading.meter, 'allocationKey'), 'id'), //'allocationKeyId'),
                  allocationKeyName: checkProperty(
                    checkProperty(meterReading.meter, 'allocationKey'),
                    'allocationKeyName'
                  ),
                  propertyId: checkProperty(checkProperty(meterReading.meter, 'allocationKey'), 'propertyId'),
                  units: checkProperty(checkProperty(meterReading.meter, 'allocationKey'), 'units'),
                  metered: checkProperty(checkProperty(meterReading.meter, 'allocationKey'), 'metered'),
                  systemAllocationKey: checkProperty(
                    checkProperty(meterReading.meter, 'allocationKey'),
                    'systemAllocationKey'
                  ),
                  type: checkProperty(checkProperty(meterReading.meter, 'allocationKey'), 'type'),
                },
                notes: checkProperty(meterReading.meter, 'meter'),
              },
              readingId: checkProperty(meterReading, 'readingId'),
              currentValue: checkProperty(meterReading, 'currentValue', null),
              startValue: checkProperty(meterReading, 'startValue', null),
              consumption: checkProperty(meterReading, 'consumption', null),
              meterReadingDate: checkProperty(meterReading, 'meterReadingDate'),
            };
          })
          : [],
      },
      periodDays: checkProperty(occupationReadingForTenant, 'periodDays'),
    };
  };

  //@desc manipulating annual reading periods api data
  static generateAnnualReadingData = (readingData) => {
    console.log(readingData, 'generateAnnualReadingData annual readingData ');
    return {
      id: checkProperty(readingData, 'id'),
      billingPeriodId: checkProperty(readingData, 'billingPeriodId'),
      propertyId: checkProperty(readingData, 'propertyId'),
      rentalUnitId: checkProperty(readingData, 'rentalUnitId'),
      occasion: checkProperty(readingData, 'occasion'),
      occasionDate: checkProperty(readingData, 'occasionDate'),
      meterReadings: checkProperty(readingData, 'meterReadings')
        ? readingData.meterReadings.map((meterReading) => {
          const meterReadingDate = checkProperty(meterReading, 'meterReadingDate', new Date());
          return {
            // meterReadingId: Autoload.MainHelper.checkProperty(meterReading, 'meterReadingId'),
            id: checkProperty(meterReading, 'id'),
            // meterId: checkProperty(meterReading, 'meterId'),
            readingId: checkProperty(meterReading, 'readingId'),
            currentValue: checkProperty(meterReading, 'currentValue', null),
            startValue: checkProperty(meterReading, 'startValue', null),
            consumption: checkProperty(meterReading, 'consumption', null),
            meterReadingDate: moment(meterReadingDate).format('YYYY-MM-DD'),
            meter: {
              meterId: checkProperty(meterReading.meter, 'meterId'),
              id: checkProperty(meterReading.meter, 'meterId'),
              propertyId: checkProperty(meterReading.meter, 'propertyId'),
              rentalUnitId: checkProperty(meterReading.meter, 'rentalUnitId'),
              propertyMeter: checkProperty(meterReading.meter, 'propertyMeter'),
              rentalUnitName: checkProperty(meterReading.meter, 'rentalUnitName'),
              meterNo: checkProperty(meterReading.meter, 'meterNo'),
              location: checkProperty(meterReading.meter, 'location'),
              // allocationKey: { ...meterReading.meter.allocationKey },
              allocationKey: {
                allocationKeyId: meterReading.meter.allocationKeyId,
                allocationKeyName: meterReading.meter.allocationKeyName,
                metered: meterReading.meter.metered,
                propertyId: meterReading.meter.propertyId,
                systemAllocationKey: meterReading.meter.systemAllocationKey,
                type: meterReading.meter.type,
                units: meterReading.meter.units,
              },
              notes: checkProperty(meterReading.meter, 'notes'),
            },
          };
        })
        : [],
    };
  };

  //@desc manipulating tenants occupation reading periods response
  static generateNewReadingData = (readingData) => {
    return {
      id: Autoload.MainHelper.checkProperty(readingData, 'id'),
      billingPeriodId: Autoload.MainHelper.checkProperty(readingData, 'billingPeriodId'),
      propertyId: Autoload.MainHelper.checkProperty(readingData, 'propertyId'),
      rentalUnitId: Autoload.MainHelper.checkProperty(readingData, 'rentalUnitId'),
      occasion: Autoload.MainHelper.checkProperty(readingData, 'occasion'),
      occasionDate: Autoload.MainHelper.checkProperty(readingData, 'occasionDate'),
      meterReadings: Autoload.MainHelper.checkProperty(readingData.meterReadings, 'meterReadings')
        ? readingData.meterReadings.map((meterReading) => {
          return {
            meterReadingId: Autoload.MainHelper.checkProperty(meterReading, 'meterReadingId'),
            meterId: Autoload.MainHelper.checkProperty(meterReading, 'meterId'),
            readingId: Autoload.MainHelper.checkProperty(meterReading, 'readingId'),
            currentValue: Autoload.MainHelper.checkProperty(meterReading, 'currentValue'),
            startValue: Autoload.MainHelper.checkProperty(meterReading, 'startValue'),
            consumption: Autoload.MainHelper.checkProperty(meterReading, 'consumption'),
            meterReadingDate: Autoload.MainHelper.checkProperty(meterReading, 'meterReadingDate'),
          };
        })
        : [],
    };
  };

  //@desc manipulating meter occupation reading (interum)
  static getMeterOccupationReading = (reading) => {
    return {
      id: checkProperty(reading, 'id'),
      readingId: checkProperty(reading, 'readingId'),
      billingPeriodId: checkProperty(reading, 'billingPeriodId'),
      propertyId: checkProperty(reading, 'propertyId'),
      rentalUnitId: checkProperty(reading, 'rentalUnitId'),
      rentalUnitName: checkProperty(reading, 'rentalUnitName'),
      tenantId: checkProperty(reading, 'tenantId'),
      tenantFullName: checkProperty(reading, 'tenantFullName'),
      periodStart: checkProperty(reading, 'periodStart'),
      periodEnd: checkProperty(reading, 'periodEnd'),
      occasion: checkProperty(reading, 'occasion', 'move-in'),
      occasionDate: checkProperty(reading, 'occasionDate'),
      periodStartReading: {
        id: checkProperty(reading.periodStartReading, 'id'),
        billingPeriodId: checkProperty(reading.periodStartReading, 'billingPeriodId'),
        propertyId: checkProperty(reading.periodStartReading, 'propertyId'),
        rentalUnitId: checkProperty(reading.periodStartReading, 'rentalUnitId'),
        occasion: checkProperty(reading.periodStartReading, 'occasion', 'move-in'),
        occasionDate: checkProperty(reading.periodStartReading, 'occasionDate'),
        meterReadings: checkProperty(reading.periodStartReading, 'meterReadings')
          ? reading.periodStartReading.meterReadings.map((meterReading) => {
            return {
              id: checkProperty(meterReading, 'id'),
              readingId: checkProperty(meterReading, 'readingId'),
              currentValue: checkProperty(meterReading, 'currentValue', null),
              startValue: checkProperty(meterReading, 'startValue', null),
              consumption: checkProperty(meterReading, 'consumption', null),
              meterReadingDate: checkProperty(reading.periodStartReading, 'occasionDate'),
              meter:
                checkProperty(meterReading, 'meter') !== ''
                  ? {
                    allocationKey: {
                      // allocationKeyId: checkProperty(meterReading.meter.allocationKey, 'id'),
                      allocationKeyId: checkProperty(
                        checkProperty(meterReading.meter, 'allocationKey'),
                        'allocationKeyId'
                      ),
                      allocationKeyName: checkProperty(
                        checkProperty(meterReading.meter, 'allocationKey'),
                        'allocationKeyName'
                      ),
                      propertyId: checkProperty(checkProperty(meterReading.meter, 'allocationKey'), 'propertyId'),
                      units: checkProperty(checkProperty(meterReading.meter, 'allocationKey'), 'units'),
                      metered: checkProperty(checkProperty(meterReading.meter, 'allocationKey'), 'metered'),
                      systemAllocationKey: checkProperty(
                        checkProperty(meterReading.meter, 'allocationKey'),
                        'systemAllocationKey'
                      ),
                      type: checkProperty(checkProperty(meterReading.meter, 'allocationKey'), 'type'),
                    },
                    // allocationKeyName: checkProperty(meterReading.meter, 'allocationKeyName'),
                    location: checkProperty(meterReading.meter, 'location'),
                    // meterId: checkProperty(meterReading.meter, 'meterId'),
                    meterId: checkProperty(meterReading.meter, 'id'),
                    meterNo: checkProperty(meterReading.meter, 'meterNo'),
                    notes: checkProperty(meterReading.meter, 'notes'),
                    propertyId: checkProperty(meterReading.meter, 'propertyId'),
                    propertyMeter: checkProperty(meterReading.meter, 'propertyMeter'),
                    rentalUnitId: checkProperty(meterReading.meter, 'rentalUnitId'),
                    rentalUnitName: checkProperty(meterReading.meter, 'rentalUnitName'),
                  }
                  : null,
            };
          })
          : [],
      },
      periodEndReading: {
        id: checkProperty(reading.periodEndReading, 'id'),
        billingPeriodId: checkProperty(reading.periodEndReading, 'billingPeriodId'),
        propertyId: checkProperty(reading.periodEndReading, 'propertyId'),
        rentalUnitId: checkProperty(reading.periodEndReading, 'rentalUnitId'),
        occasion: checkProperty(reading.periodEndReading, 'occasion', 'move-in'),
        occasionDate: checkProperty(reading.periodEndReading, 'occasionDate'),
        meterReadings: checkProperty(reading.periodEndReading, 'meterReadings')
          ? reading.periodEndReading.meterReadings.map((meterReading) => {
            return {
              id: checkProperty(meterReading, 'id'),
              readingId: checkProperty(meterReading, 'readingId'),
              currentValue: checkProperty(meterReading, 'currentValue', null),
              startValue: checkProperty(meterReading, 'startValue', null),
              consumption: checkProperty(meterReading, 'consumption', null),
              meterReadingDate: checkProperty(reading.periodEndReading, 'occasionDate'),
              meter:
                checkProperty(meterReading, 'meter') !== ''
                  ? {
                    allocationKey: {
                      allocationKeyId: checkProperty(
                        checkProperty(meterReading.meter, 'allocationKey'),
                        'allocationKeyId'
                      ),
                      // allocationKeyId: checkProperty(meterReading.meter.allocationKey, 'id'),
                      allocationKeyName: checkProperty(
                        checkProperty(meterReading.meter, 'allocationKey'),
                        'allocationKeyName'
                      ),
                      propertyId: checkProperty(checkProperty(meterReading.meter, 'allocationKey'), 'propertyId'),
                      units: checkProperty(checkProperty(meterReading.meter, 'allocationKey'), 'units'),
                      metered: checkProperty(checkProperty(meterReading.meter, 'allocationKey'), 'metered'),
                      systemAllocationKey: checkProperty(
                        checkProperty(meterReading.meter, 'allocationKey'),
                        'systemAllocationKey'
                      ),
                      type: checkProperty(checkProperty(meterReading.meter, 'allocationKey'), 'type'),
                    },
                    // allocationKey: checkProperty(meterReading.meter, 'allocationKey'),
                    // allocationKeyName: checkProperty(meterReading.meter, 'allocationKeyName'),
                    location: checkProperty(meterReading.meter, 'location'),
                    meterId: checkProperty(meterReading.meter, 'id'),
                    // meterId: checkProperty(meterReading.meter, 'meterId'),
                    meterNo: checkProperty(meterReading.meter, 'meterNo'),
                    notes: checkProperty(meterReading.meter, 'notes'),
                    propertyId: checkProperty(meterReading.meter, 'propertyId'),
                    propertyMeter: checkProperty(meterReading.meter, 'propertyMeter'),
                    rentalUnitId: checkProperty(meterReading.meter, 'rentalUnitId'),
                    rentalUnitName: checkProperty(meterReading.meter, 'rentalUnitName'),
                  }
                  : null,
            };
          })
          : [],
      },
      periodDays: checkProperty(reading, 'periodDays'),
    };
  };

  //@desc manipulating meter occupation reading (annual)
  static getAnnualMeterReadings = (reading) => {
    return {
      id: checkProperty(reading, 'id'),
      readingId: checkProperty(reading, 'readingId'),
      billingPeriodId: checkProperty(reading, 'billingPeriodId'),
      propertyId: checkProperty(reading, 'propertyId'),
      rentalUnitId: checkProperty(reading, 'rentalUnitId'),
      rentalUnitName: checkProperty(reading, 'rentalUnitName'),
      tenantId: checkProperty(reading, 'tenantId'),
      tenantFullName: checkProperty(reading, 'tenantFullName'),
      periodStart: checkProperty(reading, 'periodStart'),
      periodEnd: checkProperty(reading, 'periodEnd'),
      periodStartReading: {
        id: checkProperty(reading.periodStartReading, 'id'),
        billingPeriodId: checkProperty(reading.periodStartReading, 'billingPeriodId'),
        propertyId: checkProperty(reading.periodStartReading, 'propertyId'),
        rentalUnitId: checkProperty(reading.periodStartReading, 'rentalUnitId'),
        occasion: checkProperty(reading.periodStartReading, 'occasion', 'move-in'),
        occasionDate: checkProperty(reading.periodStartReading, 'occasionDate'),

        meterReadings: checkProperty(reading.periodStartReading, 'meterReadings')
          ? reading.periodStartReading.meterReadings.map((meterReading) => {
            return {
              id: checkProperty(meterReading, 'id'),
              readingId: checkProperty(meterReading, 'readingId'),
              currentValue: checkProperty(meterReading, 'currentValue'),
              startValue: checkProperty(meterReading, 'startValue', null),
              consumption: checkProperty(meterReading, 'consumption'),
              // meterReadingDate: checkProperty(meterReading, 'meterReadingDate'),
              notes: checkProperty(meterReading, 'notes'),
              meter:
                checkProperty(meterReading, 'meter') !== ''
                  ? {
                    allocationKey: {
                      // allocationKeyId: checkProperty(meterReading.meter.allocationKey, 'id'),
                      allocationKeyId: checkProperty(checkProperty(meterReading.meter, 'allocationKey'), 'id'),
                      allocationKeyName: checkProperty(
                        checkProperty(meterReading.meter, 'allocationKey'),
                        'allocationKeyName'
                      ),
                      propertyId: checkProperty(checkProperty(meterReading.meter, 'allocationKey'), 'propertyId'),
                      units: checkProperty(checkProperty(meterReading.meter, 'allocationKey'), 'units'),
                      metered: checkProperty(checkProperty(meterReading.meter, 'allocationKey'), 'metered'),
                      systemAllocationKey: checkProperty(
                        checkProperty(meterReading.meter, 'allocationKey'),
                        'systemAllocationKey'
                      ),
                      type: checkProperty(checkProperty(meterReading.meter, 'allocationKey'), 'type'),
                    },
                    location: checkProperty(meterReading.meter, 'location'),
                    meterId: checkProperty(meterReading.meter, 'id'),
                    meterNo: checkProperty(meterReading.meter, 'meterNo'),
                    notes: checkProperty(meterReading.meter, 'notes'),
                    propertyId: checkProperty(meterReading.meter, 'propertyId'),
                    propertyMeter: checkProperty(meterReading.meter, 'propertyMeter'),
                    rentalUnitId: checkProperty(meterReading.meter, 'rentalUnitId'),
                    rentalUnitName: checkProperty(meterReading.meter, 'rentalUnitName'),
                  }
                  : null,
            };
          })
          : [],
      },
      periodEndReading: {
        id: checkProperty(reading.periodEndReading, 'id'),
        billingPeriodId: checkProperty(reading.periodEndReading, 'billingPeriodId'),
        propertyId: checkProperty(reading.periodEndReading, 'propertyId'),
        rentalUnitId: checkProperty(reading.periodEndReading, 'rentalUnitId'),
        occasion: checkProperty(reading.periodEndReading, 'occasion', 'move-in'),
        occasionDate: checkProperty(reading.periodEndReading, 'occasionDate'),
        meterReadings: checkProperty(reading.periodEndReading, 'meterReadings')
          ? reading.periodEndReading.meterReadings.map((meterReading) => {
            return {
              id: checkProperty(meterReading, 'id'),
              readingId: checkProperty(meterReading, 'readingId'),
              currentValue: checkProperty(meterReading, 'currentValue'),
              startValue: checkProperty(meterReading, 'startValue', null),
              consumption: checkProperty(meterReading, 'consumption'),
              meterReadingDate: checkProperty(meterReading, 'meterReadingDate'),
              notes: checkProperty(meterReading, 'notes'),
              meter:
                checkProperty(meterReading, 'meter') !== ''
                  ? {
                    allocationKey: {
                      // allocationKeyId: checkProperty(meterReading.meter.allocationKey, 'id'),
                      allocationKeyId: checkProperty(checkProperty(meterReading.meter, 'allocationKey'), 'id'),
                      allocationKeyName: checkProperty(
                        checkProperty(meterReading.meter, 'allocationKey'),
                        'allocationKeyName'
                      ),
                      propertyId: checkProperty(checkProperty(meterReading.meter, 'allocationKey'), 'propertyId'),
                      units: checkProperty(checkProperty(meterReading.meter, 'allocationKey'), 'units'),
                      metered: checkProperty(checkProperty(meterReading.meter, 'allocationKey'), 'metered'),
                      systemAllocationKey: checkProperty(
                        checkProperty(meterReading.meter, 'allocationKey'),
                        'systemAllocationKey'
                      ),
                      type: checkProperty(checkProperty(meterReading.meter, 'allocationKey'), 'type'),
                    },
                    location: checkProperty(meterReading.meter, 'location'),
                    meterId: checkProperty(meterReading.meter, 'id'),
                    meterNo: checkProperty(meterReading.meter, 'meterNo'),
                    notes: checkProperty(meterReading.meter, 'notes'),
                    propertyId: checkProperty(meterReading.meter, 'propertyId'),
                    propertyMeter: checkProperty(meterReading.meter, 'propertyMeter'),
                    rentalUnitId: checkProperty(meterReading.meter, 'rentalUnitId'),
                    rentalUnitName: checkProperty(meterReading.meter, 'rentalUnitName'),
                  }
                  : null,
            };
          })
          : [],
      },
      periodDays: checkProperty(reading, 'periodDays'),
    };
  };

  static generateAnnualReadingRow = (readingRow) => {
    // const readingDate = checkProperty(readingRow, 'meterReadingDate', new Date());
    return {
      consumption: checkProperty(readingRow, 'consumption'),
      currentValue: checkProperty(readingRow, 'currentValue'),
      id: checkProperty(readingRow, 'id'),
      testId: checkProperty(readingRow, 'id'), // temp id
      meter: checkProperty(readingRow, 'meter'),
      // readingDate: moment(readingDate).format('DD.MM.YYYY'),
      readingId: checkProperty(readingRow, 'readingId'),
      startValue: checkProperty(readingRow, 'startValue', null),
      meterNumber: checkProperty(readingRow.meter, 'meterNo'),
      meterType: checkProperty(checkProperty(readingRow.meter, 'allocationKey'), 'allocationKeyName'),
      notes: checkProperty(readingRow.meter, 'notes'),
      meterId: checkProperty(readingRow.meter, 'meterId'),
    };
  };

  static generateReadingRow = (readingRow) => {
    const readingDate = checkProperty(readingRow, 'meterReadingDate', new Date());
    return {
      consumption: checkProperty(readingRow, 'consumption', null),
      currentValue: checkProperty(readingRow, 'currentValue', null),
      id: checkProperty(readingRow, 'id'),
      testId: checkProperty(readingRow, 'id'), // temp id
      meter: checkProperty(readingRow, 'meter'),
      readingDate: moment(readingDate).format('DD.MM.YYYY'),
      readingId: checkProperty(readingRow, 'readingId'),
      startValue: checkProperty(readingRow, 'startValue', null),
      meterNumber: checkProperty(readingRow.meter, 'meterNo'),
      meterType: checkProperty(checkProperty(readingRow.meter, 'allocationKey'), 'allocationKeyName'),
      notes: checkProperty(readingRow.meter, 'notes'),
      meterId: checkProperty(readingRow.meter, 'meterId'),
    };
  };
}

export default MeterDataHandler;
