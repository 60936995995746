import React, { Component } from 'react';
import { ColorButton } from '../../../../../neoverv';
import Autoload from '../../../../../neoverv/Autoload';
import { connect } from 'react-redux';
import { Group, Radio, RadioGroup, Stack } from '@mantine/core';
import Logo from '../../../../../assets/images/module/general/Logo.svg';
import paypal from '../../../../../assets/images/module/general/paypal.svg';
import mastercard from '../../../../../assets/images/module/general/mastercard.svg';
import visa from '../../../../../assets/images/module/general/visa.svg';
import { setCookie } from '../../../../../neoverv/helpers/Utils';
import {
  setSelectedSubscriptionForPaymentConfirmation
} from '../../../../../redux/action/General/generalActions';
import { showNotification } from '@mantine/notifications';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
var { checkProperty } = Autoload.MainHelper;
export class PaymentTemplate extends Component {
  state = {
    paymentMethodExist: false,
    type: null,
    selectedPaymentMethod: null,
    paymentMethodError: false,
    showLoader: false
  };

  componentDidMount() {
    this.getPaymentDetails();

    let url = new URL(window.location.href);
    console.log('type: ',url.searchParams.get("type"));
    this.setState({
      type: url.searchParams.get("type")
    })
  }

  getPaymentDetails = () => {
    console.log('this.props.profile', this.props.profile.accountId, this.props.profile);
    const userAccountID = this.props.profile.accountId;
    // console.log(userAccountID, 'userAccountID from redux - payment details page');
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.account_api_baseurl + userAccountID + '/paymentMandate',
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handle_account_payment_details',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };
  handle_account_payment_details(response) {
    console.log('handle_account_payment_details response', response);
    if (response.status == true && response.statuscode == 200) {
      this.setState({
        paymentMethodExist: response.result && response.result.paymentMethod ? true : false
      });
    }
}

authorizePayment=()=>{
    this.setState({paymentMethodError: false, showLoader: true})
    const userAccountID = this.props.profile.accountId;
    Autoload.HttpRequestAxios.HttpRequest(
        Autoload.Setting.account_api_baseurl + userAccountID + '/authorizePayment',
        'PUT',
        {
            accountId: userAccountID,
            paymentMethod: this.state.selectedPaymentMethod
        },
        null,
        false,
        true,
        false,
        this,
        'handle_account_authorize_payment',
        null,
        false,
        '',
        {
          Authorization: Autoload.Utils.getAuthToken(),
        }
      );
}
handle_account_authorize_payment(response){
    console.log('handle_account_authorize_payment: ', response);
    if(response.status){
        setCookie('PaymentAuthorizeInProgress', '1');
        setCookie('SubscriptionSelected', this.props.subscribtionSelected);
        this.props.setSelectedSubscriptionForPaymentConfirmation(this.props.subscribtionSelected);
        setCookie('PaymentAuthorizeRoute', this.props.route);
        //window.location.href = response.result;
        setTimeout(() => {
          window.open(response.result, '_self');
        }, 1000);
        this.props.toggle();
    }
    this.setState({showLoader: false});
}

reAuthorizePayment = () => {
  this.setState({paymentMethodError: false, showLoader: true})
  let accountId = this.props.profile.accountId;
  let payload = { accountId: accountId, paymentMethod: this.state.selectedPaymentMethod };

  Autoload.HttpRequestAxios.HttpRequest(
    Autoload.Setting.api_base_url + `user-api/Accounts/${accountId}/reauthorizePayment`,
    'PUT',
    payload,
    null,
    false,
    true,
    false,
    this,
    'handleChangePaymentOptionResponse',
    null,
    false,
    '',
    { Authorization: Autoload.Utils.getAuthToken() }
  );
};
handleChangePaymentOptionResponse = (response) => {
  if (response.result) {
    setCookie('paymentReAuthorizeInProgress', '1');
    setCookie('SubscriptionSelected', this.props.subscribtionSelected);
    this.props.setSelectedSubscriptionForPaymentConfirmation(this.props.subscribtionSelected);
    setCookie('PaymentAuthorizeRoute', this.props.route);
    window.open(response.result, '_self');
    this.props.toggle();
  }
  this.setState({showLoader: false});
};

  render() {
    const {type, toggle, accountData} = this.props;
    const {selectedPaymentMethod, paymentMethodError, showLoader} = this.state;
    console.log('selectedPaymentMethod: ', selectedPaymentMethod);
    console.log('this.props.subscribtionSelected: ', this.props.subscribtionSelected);
    console.log('this.props.subscribtionSelectedName: ', this.props.subscribtionSelectedName);
    console.log('accountData: ', accountData);
    return (
        <Stack className='payment-template' style={{marginTop: '0px'}} spacing={0}>
            {this.props.subscribtionSelected &&
            (this.props.subscribtionSelectedName === 'Premium' ? accountData.isPremiumPlanTested === false &&
            <Group>
              <span className='payment-template-text'>Um das kostenlose Probeabo zu starten, müssen Sie ein Zahlungsmittel hinterlegen. Es wird im ersten Monat keine Zahlung abgebucht. Das Probeabo ändert sich nach Ablauf des Probezeitraums in ein kostenpflichtiges Abo um. Sie können das Probeabo jederzeit kündigen.</span>
            </Group>
            :
            this.props.subscribtionSelectedName === 'Professional' && accountData.isProfessionalPlanTested === false &&
            <Group>
              <span className='payment-template-text'>Um das kostenlose Probeabo zu starten, müssen Sie ein Zahlungsmittel hinterlegen. Es wird im ersten Monat keine Zahlung abgebucht. Das Probeabo ändert sich nach Ablauf des Probezeitraums in ein kostenpflichtiges Abo um. Sie können das Probeabo jederzeit kündigen.</span>
            </Group>)
            }
            <Group>
              <span className='payment-template-text'>Bitte wählen Sie das gewünschte Zahlungsmittel:<span style={{color: '#dc3545', fontSize: '12px', fontWeight: '500'}}> *</span></span>
            </Group>
            <Stack style={{background: '#F7F7FA', padding: 10, borderRadius: '4px', marginBottom: '20px'}}>
              {paymentMethodError &&
              <Group>
                <span style={{color: '#dc3545', fontSize: '12px', fontWeight: '500'}}>Bitte wählen Sie eine Zahlungsmethode aus.</span>
              </Group>}
              <RadioGroup
                orientation="vertical"
                value={selectedPaymentMethod}
                onChange={(value)=> this.setState({selectedPaymentMethod: value, paymentMethodError: false})}
                required
              >
                <Radio value="creditcard" label={<span className='payment-template-options'>{"Kreditkarte"} <img src={"https://www.mollie.com/external/icons/payment-methods/creditcard.svg"} style={{marginLeft: '10px'}}/></span>} /> 
                <Radio value="paypal" label={<span className='payment-template-options'>{"Paypal"} <img src={"https://www.mollie.com/external/icons/payment-methods/paypal.svg"} style={{marginLeft: '10px'}}/></span>}/>
                {/* <Radio value="giropay" label={<span className='payment-template-options'>{"Giropay"}<img src={"https://www.mollie.com/external/icons/payment-methods/giropay.svg"} style={{marginLeft: '10px'}}/></span>}/> */}
                <Radio value="sofort" label={<span className='payment-template-options'>{"Lastschrifteinzug"}<span style={{verticalAlign: 'super', fontSize: '9px'}}>1</span><img src={'https://www.mollie.com/external/icons/payment-methods/sofort.svg'} style={{marginLeft: '10px'}}/><img src={"https://www.mollie.com/external/icons/payment-methods/directdebit.svg"} style={{marginLeft: '10px'}}/></span>}/>
              </RadioGroup>
            </Stack>
            <Group style={{marginBottom: '10px'}}>
              <span className='payment-template-options' style={{textAlign: 'left'}}>Zur Autorisierung des Zahlungsmittels leiten wir Sie im nächsten Schritt an unseren Zahlungsanbieter mollie weiter. Es wird noch keine Zahlung getätigt.</span>
            </Group>
            <Group>
              <span className='payment-template-options' style={{textAlign: 'left', fontSize: '10px'}}>1: Aus technischen Gründen wird zur Autorisierung ein Betrag von 0,10 EUR abgebucht. Dieser wird Ihnen mit der ersten Rechnung erstattet.</span>
            </Group>
            <Group style={{width: '100%', justifyContent: 'space-between', marginTop: '30px'}}>
                <div>
                    <ColorButton 
                        text={'Abbrechen'}
                        className='gray-btn'
                        onClick={toggle}
                    />
                </div>
                <div>
                    <ColorButton 
                        text={'Weiter'}
                        className='purple-btn'
                        onClick={()=>  type === 'authorize' ? (selectedPaymentMethod ? this.authorizePayment() : this.setState({paymentMethodError: true})) : (selectedPaymentMethod ? this.reAuthorizePayment() : this.setState({paymentMethodError: true}))}
                        showLoader={showLoader}
                    />
                </div>
            </Group>
        </Stack>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profile: state.generalReducer.profile,
    AccountApi: state.generalReducer.account_api_id,
    accountData: state.generalReducer.accountData,
  };
};

const actions={
  setSelectedSubscriptionForPaymentConfirmation
}

export default connect(mapStateToProps, actions)(PaymentTemplate);
