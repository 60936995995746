/**
 * 
 * @param { string | number } number - number to convert
 * @param { boolean } displayWithCurrency - if true, will display with currency (for now Euro only)
 * @returns Number formatted as XX,XXX.XX (€)
 */
export const formatNumber = (number, displayWithCurrency = false) => {
  const formattedNumber = number ?
  number
  .toFixed(2)
  .toString()
  .replace('.', ',')
  .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
  :
  0;

  return `${formattedNumber}${displayWithCurrency ? ' €' : ''}`;
}

export const formatNumberByLocale = (number) => new Intl.NumberFormat('de-DE', {
  style: 'decimal',
  minimumFractionDigits: 2
}).format(number);

/**
 * 
 * @param {string} unit - unit to print nicely
 * @returns Nicely formatted string
 */
export const formatUnit = (unit) => {
  switch (unit) {
    case 'm3': return 'm³';
    default: return unit;
  }
}