// import MeterStore from '../../views/pages/Module/Meter/store/reducers';

// export default MeterStore;
import { getReadingReducer } from './Meter/MeterReadingReducer';
import { getMasterDataReducer } from './Meter/MasterDataReducer';
import { generalReducer } from './General/GeneralReducer';
import { costTypeReducer, allocationKeyReducer, selectedCostTypeReducer } from './Property/PropertyReducer';
import { tenantDataReducer } from './Tenant/TenantDataReducer';
import { annualReadingReducer } from './Meter/AnnualReadingsReducer';
import { interimReadingReducer } from './Meter/InterimReadingsReducer';
import { ReceiptReducer } from './Receipt/ReceiptReducer';
import { UtilityBillingReducer } from './UtilityBilling/UtilityBillingReducer';
import { combineReducers } from 'redux';

const MeterStore = combineReducers({
    // deleteReadingReducer,
    getReadingReducer,
    getMasterDataReducer,
    generalReducer,
    costTypeReducer,
    allocationKeyReducer,
    tenantDataReducer,
    annualReadingReducer,
    interimReadingReducer,
    ReceiptReducer,
    UtilityBillingReducer,
    selectedCostTypeReducer

})
export default MeterStore