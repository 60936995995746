import React, { Component } from 'react';
import { UtilityCostAllocation, HeatingCostAllocation } from '../../../../neoverv';
import { connect } from 'react-redux';
import UtilityBillsTable from './UtilityBillsTable';
import CostOrientUtilityBillsTable from './CostBasedUtilityBills';
import { setCurrentBillingId, setUtilityBillingData } from '../../../../redux/action/UtilityBilling/UtilityBillingActions';
import Autoload from '../../../../neoverv/Autoload';
import UtilityBillingSending from './UtilityBillingSending';
import GlobalServices from '../../../../neoverv/helpers/services/GlobalServices';
import MeterDataHandler from '../../../../neoverv/helpers/DataHandler/Meter';
import moment from 'moment';
import UtilityBillingDataHandler from '../../../../neoverv/helpers/DataHandler/UtilityBillings';

const { searchData, filterByDateUtility, checkEmpty, checkProperty, loader } = Autoload.MainHelper;

const MOBILE_SIZE = 766;
const TABLET_SIZE = 1200;

var utilityArr = [];
var tableData = [];
var currentBillingPeriod = {};
var lastBillingPeriod = {};

class ViewUtilityBilling extends Component {
  state = {
    step: 1,
    calculated: false,
    isMobile: window.innerWidth <= MOBILE_SIZE,
    isTablet: window.innerWidth <= TABLET_SIZE
  };

  constructor(props){
    super(props);

    this.UtilityDataHandler = new UtilityBillingDataHandler();
  }

  componentDidMount() {
    this.props.setCurrentBillingId(Autoload.Utils.getUrlParameterByName('currentBillingId'));
    this.handleStep();
    this.props.history.location.state && this.props.history.location.state.costTypeName && this.setState({step: this.props.history.location.state.costTypeName === 'Heizung & Heizungswartung' ? 1 : 2})
    this.handleResize();
    window.addEventListener('resize', this.handleResize); 
    if(this.props.propertySelected && this.props.propertySelected.id !== undefined && this.props.utilityBillingData.length === 0){
      loader(true);
      GlobalServices._getBilingPeriodsByProperty(this, '_handleBillingPeriodResponse', this.props.propertySelected);
    }
  }

  componentWillUnmount(){
    this.props.setUtilityBillingData([]);
  }

  _handleBillingPeriodResponse(response) {
    console.log(response, '_handleBillingPeriodResponse');
    if (response.status && response.statuscode === 200) {
      const _billingPeriods = MeterDataHandler.generateBilingPeriods(response.result);
      console.log(_billingPeriods);
      let formatedBillingPeriods = _billingPeriods
      // .filter((value, index, self) =>
      // index === self.findIndex((t) => (
      //   t.startDate === value.startDate && t.endDate === value.endDate
      // )));
      this.setState(
        {
          billingPeriods: formatedBillingPeriods,
        },
        () => this._getUtillityBillings()
      );
    } else {
      //Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang.newKeys.text_utility_billing_err);
      loader(false);//this.ShowLoader();
    }
  }

  //data for current billing period
  _getUtillityBillings = () => {
    // loader(true);
    utilityArr = [];
    let propertyid = this.props.propertySelected && this.props.propertySelected.id;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'currentBillingPeriod?propertyId=' + propertyid,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      '_handleCurrentBillingPeriod',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
    // Autoload.HttpRequestAxios.HttpRequest(
    //   Autoload.Setting.property_api_base_url + 'lastBillingPeriod?propertyId=' + propertyid,
    //   'GET',
    //   null,
    //   null,
    //   false,
    //   true,
    //   false,
    //   this,
    //   '_handleLastBillingPeriod',
    //   null,
    //   false,
    //   '',
    //   {
    //     Authorization: Autoload.Utils.getAuthToken(),
    //   }
    // );
  };

  _handleCurrentBillingPeriod = (response) => {
    console.log('current utility billing api in utility ', response);
    if (response.status) {
      currentBillingPeriod = MeterDataHandler.generateBilingPeriodsObject(response.result, 'current');
      const billingPeriodStart = checkProperty(currentBillingPeriod, 'startDate');
      const billingPeriodEnd = checkProperty(currentBillingPeriod, 'endDate');
      this.setState({
        currentBillingPeriodDate: `${moment(billingPeriodStart).format('DD.MM.YYYY')} - ${moment(billingPeriodEnd).format('DD.MM.YYYY')}`,
        currentBillingPeriod
      });
    }
    if (response.status === false) {
      Autoload.Redirection.redirection_error();
      loader(false);//!this.state.create_ub && this.ShowLoader();
    }
    //data for last billing period
    let propertyid = this.props.propertySelected && this.props.propertySelected.id;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'lastBillingPeriod?propertyId=' + propertyid,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      '_handleLastBillingPeriod',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };
  _handleLastBillingPeriod = (response) => {
    if (response.status && response.statuscode === 200) {
      lastBillingPeriod = MeterDataHandler.generateBilingPeriodsObject(response.result, 'last');

      const billingPeriodStart = checkProperty(lastBillingPeriod, 'startDate');
      const billingPeriodEnd = checkProperty(lastBillingPeriod, 'endDate');

      this.setState({
        lastBillingPeriodDate: `${moment(billingPeriodStart).format('DD.MM.YYYY')} - ${moment(billingPeriodEnd).format('DD.MM.YYYY')}`,
        lastBillingPeriod
      });
    }else{
      loader(false);//!this.state.create_ub && this.ShowLoader();
    }


    const otherBillingPeriods = this.state.billingPeriods.filter(bp => bp.id !== currentBillingPeriod.id && bp.id !== lastBillingPeriod.id)
    console.log(otherBillingPeriods)
    this.setState({ otherBillingPeriods })

    if (response.status === false) {
      Autoload.Redirection.redirection_error();
      loader(false);//this.ShowLoader();
    }

    //get all utilities
    let propertyid = this.props.propertySelected && this.props.propertySelected.id;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.ub_api_base_url + 'utilityBillings?PropertyId=' + propertyid,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      '_handleUtillityBilling',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };

  _handleUtillityBilling = (response) => {
    console.log(response, 'all utilities');
    // billingPeriods: _billingPeriods,
    //
    if (response.status && response.statuscode === 200) {
      // last billing period
      if (Object.keys(this.state.lastBillingPeriod).length > 0) {
        let lastFound = {};

        lastFound = response.result.find(
          (record) => record.billingPeriod.id === this.state.lastBillingPeriod.id
        );

        if (lastFound && Object.keys(lastFound).length > 0) {
          utilityArr.push({
            ...this.state.lastBillingPeriod,
            ...this.UtilityDataHandler.createUtilityBillingObject(lastFound, 'last'),
            isSecured: lastFound.isSecured,
            heatingCostsActivated: lastFound.heatingCostsActivated,
            isVATHidden: lastFound.isVATHidden,
            isLaborCostHidden: lastFound.isLaborCostHidden
          });
        } else {
          const date = moment(this.state.lastBillingPeriod.startDate).format('DD.MM.YYYY') + ' - ' + moment(this.state.lastBillingPeriod.endDate).format('DD.MM.YYYY');
          utilityArr.push({
            billingPeriodId: this.state.lastBillingPeriod.id,
            propertyId: this.state.lastBillingPeriod.propertyId,
            startDate: this.state.lastBillingPeriod.startDate,
            endDate: this.state.lastBillingPeriod.endDate,
            type: 'last',
            recordStatus:'last',
            date,
            isSecured: this.state.lastBillingPeriod.isSecured,
            heatingCostsActivated: this.state.lastBillingPeriod.heatingCostsActivated
          });
        }
      }
      // current billing period
      if (Object.keys(this.state.currentBillingPeriod).length > 0) {
        let currentFound = {};

        currentFound = response.result.find(
          (record) => record.billingPeriod.id === this.state.currentBillingPeriod.id
        );

        if (currentFound && Object.keys(currentFound).length > 0) {
          utilityArr.push({
            ...this.state.currentBillingPeriod,
            ...this.UtilityDataHandler.createUtilityBillingObject(currentFound, 'current'),
            isSecured: currentFound.isSecured,
            heatingCostsActivated: currentFound.heatingCostsActivated,
            isVATHidden: currentFound.isVATHidden,
            isLaborCostHidden: currentFound.isLaborCostHidden
          });
        } else {
          const date = moment(this.state.currentBillingPeriod.startDate).format('DD.MM.YYYY') + ' - ' + moment(this.state.currentBillingPeriod.endDate).format('DD.MM.YYYY');
          utilityArr.push({
            billingPeriodId: this.state.currentBillingPeriod.id,
            propertyId: this.state.currentBillingPeriod.propertyId,
            startDate: this.state.currentBillingPeriod.startDate,
            endDate: this.state.currentBillingPeriod.endDate,
            type: 'current',
            recordStatus:'current',
            date,
            isSecured: this.state.currentBillingPeriod.isSecured,
            heatingCostsActivated: this.state.currentBillingPeriod.heatingCostsActivated
          });
        }
      }

      if (response.result.length > 1) {
        let filterLastCurrent = response.result.filter(
          (record) => record.billingPeriod.id !== this.state.currentBillingPeriod.id && record.billingPeriod.id !== this.state.lastBillingPeriod.id
        );
        console.log('otherBillingPeriods: ', filterLastCurrent);

        if(filterLastCurrent.length){
          const otherBillingPeriods = this.state.otherBillingPeriods.filter((bp=> filterLastCurrent.find((flc)=> bp.id === flc.billingPeriod.id) === undefined )).map(bp => ({
            billingPeriodId: bp.id,
            propertyId: bp.propertyId,
            startDate: bp.startDate,
            endDate: bp.endDate,
            type: bp.type,
            date: bp.startDate + ' - ' + bp.endDate,
            recordStatus: bp.type,
            isSecured: bp.isSecured,
            heatingCostsActivated: bp.heatingCostsActivated
          }));

          utilityArr = [
            ...utilityArr,
            ...filterLastCurrent.map((billingRow) => {
  
              return ({
                ...this.UtilityDataHandler.createUtilityBillingObject(billingRow),
                startDate: billingRow.billingPeriod.startDate,
                endDate: billingRow.billingPeriod.endDate,
                isSecured: billingRow.isSecured,
                heatingCostsActivated: billingRow.heatingCostsActivated,
                isVATHidden: billingRow.isVATHidden,
                isLaborCostHidden: billingRow.isLaborCostHidden
              })
            }),
            ...otherBillingPeriods
          ];
        } else {
            console.log('NofilterLastCurrent')
            const otherBillingPeriods = this.state.otherBillingPeriods.map(bp => ({
              billingPeriodId: bp.id,
              propertyId: bp.propertyId,
              startDate: bp.startDate,
              endDate: bp.endDate,
              type: bp.type,
              date: bp.startDate + ' - ' + bp.endDate,
              recordStatus: bp.type,
              isSecured: bp.isSecured,
              heatingCostsActivated: bp.heatingCostsActivated
            }))
            utilityArr = [...utilityArr, ...otherBillingPeriods]
        }
      } else if (this.state.otherBillingPeriods.length) {
        console.log('okokokokokokok')
        let filterLastCurrent = response.result.filter(
          (record) => record.billingPeriod.id !== this.state.currentBillingPeriod.id && record.billingPeriod.id !== this.state.lastBillingPeriod.id
        );

    

        if (filterLastCurrent.length) {
          const otherBillingPeriods = this.state.otherBillingPeriods.filter((bp=> filterLastCurrent.find((flc)=> bp.id === flc.billingPeriod.id) === undefined )).map(bp => ({
            billingPeriodId: bp.id,
            propertyId: bp.propertyId,
            startDate: bp.startDate,
            endDate: bp.endDate,
            type: bp.type,
            date: bp.startDate + ' - ' + bp.endDate,
            recordStatus: bp.type,
            isSecured: bp.isSecured,
            heatingCostsActivated: bp.heatingCostsActivated
          }));
          console.log('filterLastCurrent')
          utilityArr = [
            ...utilityArr,
            ...filterLastCurrent.map((billingRow) => {

              return ({
                ...this.UtilityDataHandler.createUtilityBillingObject(billingRow),
                startDate: billingRow.billingPeriod.startDate,
                endDate: billingRow.billingPeriod.endDate,
                isSecured: billingRow.isSecured,
                heatingCostsActivated: billingRow.heatingCostsActivated,
                isVATHidden: billingRow.isVATHidden,
                isLaborCostHidden: billingRow.isLaborCostHidden
              })
            }),
            ...otherBillingPeriods
          ];
        } else {
          console.log('NofilterLastCurrent')
          const otherBillingPeriods = this.state.otherBillingPeriods.map(bp => ({
            billingPeriodId: bp.id,
            propertyId: bp.propertyId,
            startDate: bp.startDate,
            endDate: bp.endDate,
            type: bp.type,
            date: bp.startDate + ' - ' + bp.endDate,
            recordStatus: bp.type,
            isSecured: bp.isSecured,
            heatingCostsActivated: bp.heatingCostsActivated
          }))
          utilityArr = [...utilityArr, ...otherBillingPeriods]
        }
      }
      console.log(utilityArr)
      this.setState({ filteredReadings: utilityArr }, () => this.getBillingStatements());
    }


    else {
      if (this.state.otherBillingPeriods.length) {
        utilityArr = [
          ...utilityArr,
          {
            billingPeriodId: this.state.currentBillingPeriod.id,
            propertyId: this.state.currentBillingPeriod.propertyId,
            date: moment(this.state.currentBillingPeriod.startDate).format('DD.MM.YYYY') +' - '+ moment(this.state.currentBillingPeriod.endDate).format('DD.MM.YYYY'),
            startDate: this.state.currentBillingPeriod.startDate,
            endDate: this.state.currentBillingPeriod.endDate,
            type: this.state.currentBillingPeriod.type,
            recordStatus: this.state.currentBillingPeriod.type,
            heatingCostsActivated: this.state.currentBillingPeriod.heatingCostsActivated
          },
          {
            billingPeriodId: this.state.lastBillingPeriod.id,
            propertyId: this.state.lastBillingPeriod.propertyId,
            date: moment(this.state.lastBillingPeriod.startDate).format('DD.MM.YYYY') +' - '+ moment(this.state.lastBillingPeriod.endDate).format('DD.MM.YYYY'),
            startDate: this.state.lastBillingPeriod.startDate,
            endDate: this.state.lastBillingPeriod.endDate,
            type: this.state.lastBillingPeriod.type,
            recordStatus: this.state.lastBillingPeriod.type,
            heatingCostsActivated: this.state.lastBillingPeriod.heatingCostsActivated
          },
        ];
        const otherBillingPeriods = this.state.otherBillingPeriods.map(bp => ({
          billingPeriodId: bp.id,
          propertyId: bp.propertyId,
          date: moment(bp.startDate).format('DD.MM.YYYY') +' - '+ moment(bp.endDate).format('DD.MM.YYYY'),
          startDate: bp.startDate,
          endDate: bp.endDate,
          type: bp.type,
          recordStatus: bp.type,
          heatingCostsActivated: bp.heatingCostsActivated
        }))
        utilityArr = [...utilityArr, ...otherBillingPeriods]


      } else {
        utilityArr = [
          ...utilityArr,
          {
            billingPeriodId: this.state.currentBillingPeriod.id,
            propertyId: this.state.currentBillingPeriod.propertyId,
            date: moment(this.state.currentBillingPeriod.startDate).format('DD.MM.YYYY') +' - '+ moment(this.state.currentBillingPeriod.endDate).format('DD.MM.YYYY'),
            startDate: this.state.currentBillingPeriod.startDate,
            endDate: this.state.currentBillingPeriod.endDate,
            type: this.state.currentBillingPeriod.type,
            heatingCostsActivated: this.state.currentBillingPeriod.heatingCostsActivated
          },
          {
            billingPeriodId: this.state.lastBillingPeriod.id,
            propertyId: this.state.lastBillingPeriod.propertyId,
            date: moment(this.state.lastBillingPeriod.startDate).format('DD.MM.YYYY') +' - '+ moment(this.state.lastBillingPeriod.endDate).format('DD.MM.YYYY'),
            startDate: this.state.lastBillingPeriod.startDate,
            endDate: this.state.lastBillingPeriod.endDate,
            type: this.state.lastBillingPeriod.type,
            heatingCostsActivated: this.state.lastBillingPeriod.heatingCostsActivated
          },
        ];
      }
      this.setState({ filteredReadings: utilityArr });
      let sortedList = filterByDateUtility(utilityArr, 'date', false);
      this.props.setUtilityBillingData(sortedList);
      loader(false);//!this.state.create_ub && this.ShowLoader();
    }
    //loader(false);

  };

  getBillingStatements = () => {
    // loader(true);
    utilityArr.map((utility, i) => {
      if(utility.id){
        Autoload.HttpRequestAxios.HttpRequest(
          Autoload.Setting.ub_api_base_url + 'billingStatements?utilityBillingId=' + utility.id,
          'GET',
          null,
          null,
          false,
          true,
          false,
          this,
          '_handleBillingStatementResponse',
          { utilityId: utility.id, loopEnd: i === utilityArr.length - 1 },
          false,
          '',
          {
            Authorization: Autoload.Utils.getAuthToken(),
          }
        );
      }else if(i === utilityArr.length - 1){
        ////!this.state.create_ub && this.ShowLoader();
        //this.getFiles();
      }
    });
  };
  _handleBillingStatementResponse = (response) => {
    console.log('_handleBillingStatementResponse in utility', response);
    if (response.status === false) {
      let sortedList = filterByDateUtility(utilityArr, 'date', false);
      this.props.setUtilityBillingData(sortedList);
      
      this.state.navigateUbId && this.navigateToBilling(false, this.state.navigateUbId);
      loader(false);//!this.state.create_ub && this.ShowLoader();
      
      Autoload.Redirection.redirection_error();
      // Autoload.DataHandlerGeneral.GeneralErrorMessage(true, 'Billing statement not found');
    }
    if (response.status === true && response.statuscode === 401) {
      localStorage.clear();
      window.location.reload();
      loader(false);//!this.state.create_ub && this.ShowLoader()
    }

    if (response.status === true && response.statuscode === 200) {
      const tenants = this.UtilityDataHandler.currentBillingStatementsDataHandler(response.result);
      //add tenants data in utility array
      utilityArr = utilityArr.map((utility) => {
        if (utility.id === tenants[0].utilityBillingId) {
          return {
            ...utility,
            tableData: tenants.sort((a,b)=> b.occupencyPeriod.localeCompare(a.occupencyPeriod)),
          };
        } else {
          return utility;
        }
      });
      // if (response.other.loopEnd) {
      // console.log('loop ed', response.other.loopEnd);
      let sortedList = filterByDateUtility(utilityArr, 'date', false);
      console.log('sortedList', sortedList);
      this.props.setUtilityBillingData(sortedList);
      this.state.navigateUbId && this.navigateToBilling(false, this.state.navigateUbId);
      // this.setState({ filteredReadings: sortedList, BillingStatmentstenants: tenants });
      this.setState({
        filteredReadings: sortedList,
        BillingStatmentstenants: tenants.sort((a,b)=> b.occupencyPeriod.localeCompare(a.occupencyPeriod)),
      },()=> loader(false))//()=> //!this.state.create_ub && this.ShowLoader());//
      // this.getFiles();
      // }
    }
    if (response.other.loopEnd) {
      // loader(false);
      //this.getFiles();
      loader(false);//!this.state.create_ub && this.ShowLoader();
    }
  };

  handleStep= ()=>{
    this.setState({
      step: (Autoload.Utils.getUrlParameterByName('ub-step') === 'heating-cost') ? 1
      : (Autoload.Utils.getUrlParameterByName('ub-step') === 'utility-cost') ? 2
      : (Autoload.Utils.getUrlParameterByName('ub-step') === 'billing-statements') ? 3
      : (Autoload.Utils.getUrlParameterByName('ub-step') === 'billing-statements-sending') ? 4
      : 1
    });
  }

  handleResize = () => {
    if (window.innerWidth <= MOBILE_SIZE) {
      this.setState({ isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
    if(window.innerWidth <= TABLET_SIZE) {
      this.setState({ isTablet: true });
    } else {
      this.setState({ isTablet: false });
    }
  };

  moveForward = () => {
    this.setState({ step: this.state.step + 1 });
  };

  goBack = () => {
    this.setState({ step: this.state.step - 1 });
  };
  goBackTwice = () => {
    this.setState({ step: this.state.step - 2 });
  };

  render() {
    const { heatingType } = this.props.propertySelected;
    const {isMobile, isTablet} = this.state;
    const {utilityBillingData} = this.props;
    console.log('utilityBillingData_000: ', utilityBillingData);
    // if (heatingType.toLowerCase() === 'decentralized') {
    //   this.setState({ step: this.state.step + 1 });
    // }

    
    switch (this.state.step) {
      case 1:
        return (
          <div>
            <HeatingCostAllocation
              history={this.props.history}
              isMobile={isMobile}
              isTablet={isTablet}
              className="utility-cost-allocation"
              goNext={this.moveForward}
              goBack={this.goBack}
              isEdit={this.props.history.location.state && this.props.history.location.state.isEdit}
              updateCalculationStatus={(status) => this.setState({ calculated: status })}
              calculated={this.state.calculated}
              currentDataId={Autoload.Utils.getUrlParameterByName('currentBillingId')}
            />
          </div>
        );
      case 2:
        return (
          <div>
            <UtilityCostAllocation
              history={this.props.history}
              isMobile={isMobile}
              isTablet={isTablet}
              className="utility-cost-allocation"
              goNext={this.moveForward}
              goBack={this.goBack}
              isEdit={this.props.history.location.state && this.props.history.location.state.isEdit}
              currentDataId={Autoload.Utils.getUrlParameterByName('currentBillingId')}
            />
          </div>
        );
      case 3:
        return (
          <div>
            <UtilityBillsTable 
              history={this.props.history} 
              isMobile={isMobile}
              isTablet={isTablet} 
              goNext={this.moveForward} 
              goBack={this.goBack} 
              goBackTwice={this.goBackTwice}
              currentDataId={Autoload.Utils.getUrlParameterByName('currentBillingId')}
            />
          </div>
        );
      case 4:
        return (
          <div>
            <UtilityBillingSending 
              history={this.props.history} 
              isMobile={isMobile}
              isTablet={isTablet} 
              goNext={this.moveForward} 
              goBack={this.goBack} 
              goBackTwice={this.goBackTwice}
              currentDataId={Autoload.Utils.getUrlParameterByName('currentBillingId')}
            />
          </div>
        );
      // case 4:
      //   return (
      //     <div>
      //       <CostOrientUtilityBillsTable history={this.props.history} goNext={this.moveForward} goBack={this.goBack} />
      //     </div>
      //   );
      default:
        return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    currentDataId: state.UtilityBillingReducer.currentBillingId,
    utilityBillingData: state.UtilityBillingReducer.utilityBillingData,
    propertySelected: state.generalReducer.property,
    filter: state.UtilityBillingReducer.HeatingAllocationFilter,
  };
};
const actions = {
  setCurrentBillingId,
  setUtilityBillingData
};
export default connect(mapStateToProps, actions)(ViewUtilityBilling);

// export default ViewUtilityBilling
