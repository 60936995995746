import * as Actiontypes from '../../types/utilityBilling';

export const setCostPositions = (data) => {
  // console.log('action', data)
  return {
    type: Actiontypes.SET_COST_POSITIONS,
    payload: data,
  };
};

export const setTenantBills = (data) => {
  // console.log('action', data)
  return {
    type: Actiontypes.SET_TENANT_BILLS,
    payload: data,
  };
};

export const deleteCostPosition = (id) => {
  // console.log('action', id)
  return {
    type: Actiontypes.DELETE_COST_POSITION,
    payload: id,
  };
};

export const addCostPosition = (data) => {
  return {
    type: Actiontypes.ADD_COST_POSITION,
    payload: data,
  };
};

export const editCostPosition = (data) => {
  // console.log('action EDIT_COST_POSITION', data)
  return {
    type: Actiontypes.EDIT_COST_POSITION,
    payload: data,
  };
};

export const setCurrentBillingId = (data) => {
  console.log('setCurrentBillingId', data);
  return {
    type: Actiontypes.SET_CURRENT_UB_PERIOD,
    payload: data,
  };
};
export const updateComments = (tenantID, billID, comment) => {
  // console.log('updateComments', billID,tenantID,comment)
   
  return {
    type: Actiontypes.UPDATE_COMMENTS,
    payload: {
      billID,
      tenantID,
      comment,
    },
  };
};

export const SaveZuweisungData = (costPosition, data) => {
  console.log('SaveZuweisungData', costPosition, data);
  return {
    type: Actiontypes.SAVE_ZUWEISUNG_DATA,
    payload: {
      costPosition,
      data,
    },
  };
};

export const setUtilityBillingData = (arr) => {
  return {
    type: Actiontypes.SET_UTILITY_DATA,
    payload: arr,
  };
};

export const setHeatingAllocation = (status) => {
  return {
    type: Actiontypes.SET_HEATING_ALLOCATION,
    payload: status,
  };
};

export const getUtilityBillingData = () => {
  return {
    type: Actiontypes.GET_UTILITY_DATA,
  };
};
