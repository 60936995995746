import React, { Component } from 'react'
import { ColorButton, UtilityCostAllocationRow, CostPositionModal } from '../../../../index';
import { connect } from 'react-redux'
import { setCostPositions, addCostPosition, deleteCostPosition, editCostPosition } from '../../../../../redux/action/UtilityBilling/UtilityBillingActions';
import Tab, { Form, Row, Col, Table, Container } from 'react-bootstrap';
import CustomModal from '../CustomModal/index';
import { Multiselect } from 'multiselect-react-dropdown';
import edit from '../../../../../assets/images/module/general/Edit.png';
import Autoload from "../../../../Autoload";

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];

const { checkProperty, emptyFieldValidation, invalidAmount, redirection } = Autoload.MainHelper

class MobileUtilityCostAllocation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModal: false,
        }
    }
    toggleShowModal = () => {
        this.setState({ openModal: true })

    }
    closeShowModal=()=>{
        this.setState({ openModal: false})
    }
    render() {
        const {row}=this.props
        return (
            <>
                <tr onClick={() => this.toggleShowModal()}>
                    <td className={`mobile-cost-type`} >
                        {row.costTypeName ? row.costTypeName : '-'}
                    </td>
                    <td className={`mobile-utility-edit-value`}>
                        <img src={edit} width='15.99px' height='15.98px' />
                    </td>
                </tr>
                {
                    this.state.openModal && <CostPositionModal
                        data={row}
                        edit={true}
                        open={this.state.openModal}
                        closeShowModal={() => this.closeShowModal()}
                        costTypesArr={this.props.costTypesArr}
                        allocationsArr={this.props.allocationsArr}
                        receiptsArr={this.props.receiptsArr}
                        isMobile={this.props.isMobile}
                        updateCp={this.props.updateCp}
                        // utilityBillingId={this.props.currentBillingId}
                        costTypesBackFunction={() => this.props.costTypesBackFunction}
                    />
                }
            </>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        costTypes: state.costTypeReducer,
        allocationData: state.allocationKeyReducer,
        utilityBillingData: state.UtilityBillingReducer.utilityBillingData,
        currentDataId: state.UtilityBillingReducer.currentBillingId,
    }
}
const actions = {
    deleteCostPosition,
    editCostPosition,
}
export default connect(mapStateToProps, actions)(MobileUtilityCostAllocation)
