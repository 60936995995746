import React, { Component } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import Receipt from '../../../../../assets/images/module/general/ReceiptDummy.png';
import { Row, Col, Form, Tooltip, OverlayTrigger } from 'react-bootstrap';
import CustomModal from '.';
import { showNotification } from '@mantine/notifications';
import ColorButton from '../Buttons/ColorButton';
import CopyIcon from '../../../SVGs/copyIcon';
import GreenTick from '../../../../../assets/images/module/general/GreenTick.svg';
import { Group } from '@mantine/core';
import EmptyStateContainer from '../EmptyStateContainer/EmptyStateContainer';
import TenantIconBlue from '../../../../../assets/images/module/general/TenantIconBlue.svg';

const MOBILE_SIZE = 992;
const TABLET_SIZE = 1200;

export class TenantAccessModal extends Component {
  state = {
    descriptionMsg: 'Belege bequem per Email bereitstellen.',
    descriptionMsg2: 'Alle Email-Anhänge (nur Bilder und PDF-Dateien), die Sie an diese Email senden, werden automatisch als Einzelbelege hier bereitgestellt.',
    descriptionMsg3: 'Am besten Sie speichern die Email-Adresse direkt in Ihrem Email-Programm ab, so dass Sie sie immer zur Hand haben.',
    accessLink: 'Access Link',
    hover: false,
    click: false,
    isMobile: window.innerWidth <= MOBILE_SIZE,
    isTablet: window.innerWidth <= TABLET_SIZE
  };

  descriptionMsgStyle={
    fontFamily: 'Inter, sans-serif',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '16px',
    color: '#0e0e1d',
  }
  
  constructor(props) {
    super(props);
  }

  componentDidMount(){
    console.log('this.props.selectedTenant: ', this.props.selectedTenant);
    this.setState({accessLink: this.props.selectedTenant.isActivated === false ? `https://${window.location.href.includes('app') ? 'app' : 'dev'}.hellohousing-mieterportal.de/signup?${this.props.selectedTenant.passwordResetCode}` : 'User activated account and verified email.'})
  }

  handleMouseEnter=()=>{
    this.setState({hover: true});
  }
  handleMouseLeave=()=>{
    this.setState({hover: false});
  }
  
  render() {
    const { open, toggle, className, selectedTenant } = this.props;
    const {descriptionMsg, descriptionMsg2, descriptionMsg3, accessLink, hover, click, isMobile, isTablet} = this.state;
    console.log('selectedTenant: ', selectedTenant);
    return (
      <CustomModal
        heading={selectedTenant && selectedTenant.email ? 'Zugangsdaten' :'Email hinterlegen'}
        headingClassName={'tenant-access-details-modal-heading'}
        open={open}
        toggle={() => {
          toggle();
        }}
        className={`${className}`}
      >
        <div>
            <div>
                <Row>
                    <Col xs={12}>
                        {selectedTenant && selectedTenant.email ?
                        <>
                        <Group><span style={{fontFamily: 'Inter, sans-serif', fontWeight: '400'}}>Senden Sie diesen persönlichen Zugangslink an den Mieter <span style={{fontWeight: 'bold'}}>{selectedTenant.fullName}</span>. Der Mieter kann sich über den Zugangslink beim Mieterportal registrieren. Der Mieter benötigt für die Registrierung Zugriff auf die Email <span style={{fontWeight: 'bold'}}>{selectedTenant.email}</span>, die als Nutzername hinterlegt wurde. Der Zugangslink ist nicht übertragbar.</span></Group>
                        <Group 
                        style={{
                            display: 'flex', 
                            justifyContent: 'center', 
                            margin: '15px 0px', 
                            color: '#5555FF',
                            cursor: 'pointer',
                            border: '1px dashed #bfbfd2',
                            boxSizing: 'border-box',
                            borderRadius: '4px',
                            padding: '16px 16px',
                            background: '#f7f7fa',
                            fontFamily: 'Inter, sans-serif',
                            fontWeight: '400'
                        }}
                        onMouseEnter={this.handleMouseEnter}
                        onMouseLeave={this.handleMouseLeave}
                        onClick={()=> {
                          if(accessLink.includes('signup')){
                            navigator.clipboard.writeText(accessLink); 
                            this.setState({click: true});
                            showNotification({
                                message: 'Die Link wurde kopiert.',
                                icon: <img src={GreenTick} alt='green-tick'/>,
                                style: {width: '100%'}
                            });
                          }
                        }}
                        >
                            <div style={{
                                display: 'flex',
                                wordBreak: 'break-all'
                            }}>
                                <span>{accessLink}
                                  {accessLink.includes('signup') && <span style={{marginLeft: '3px'}}><CopyIcon fill={click ? '#5555FF' : hover && '#5555FF'} width='15px' height='15px'/></span>}
                                </span>
                            </div>
                        </Group>
                        <Group><span style={{fontFamily: 'Inter, sans-serif', fontWeight: '400'}}>Klicken Sie auf Email erstellen, um den Zugangslink mit Ihrem Email-Programm an den Mieter zu senden.</span></Group>
                        </>
                        :
                        // <EmptyStateContainer
                        // heading="Daten fehlen"
                        // subheading={'Um Zugangsdaten zum Mieterportal zu erzeugen, muss eine Email-Adresse der Mieters hinterlegt sein. Wollen Sie jetzt eine Email hinterlegen?'}
                        // icon={TenantIconBlue}
                        // showBtn={false}
                        // styleMainContainer={{marginTop: '0px'}}
                        // />
                        <span style={{fontFamily: 'Inter, sans-serif', fontWeight: '400'}}>Für den Mieter <span style={{fontWeight: '500'}}>{selectedTenant.fullName}</span> ist keine Email-Adresse hinterlegt. Um Zugangsdaten zum Mieterportal zu erzeugen, muss eine Email-Adresse des Mieter angegeben werden. Wollen Sie jetzt eine Email hinterlegen?</span>
                        }
                    </Col>
                </Row>
              <div className="center-spacing add-receipt-modal-footer" style={{margin: '22px 0px 0px', display: 'flex', justifyContent: 'space-between'}} id="receipt-add-edit-btns">
                <div>
                  <ColorButton
                    onClick={() => {
                      toggle();
                    }}
                    className="gray-btn"
                    text={'Schließen'}
                  />
                </div>
                {selectedTenant && !selectedTenant.email ?
                <div>
                  <ColorButton
                    onClick={() => {
                      this.props.setCurrentTenant(selectedTenant, selectedTenant);
                      this.props.history.push(`/user/tenant/information?tenantId=${selectedTenant.id}&tenantAdministration=false`);
                      //this.props.history.push(`/user/tenant/information?tenantId=${ten.id}&tenantAdministration=false`)
                    }}
                    className="purple-btn"
                    text={'Email hinterlegen'}
                  />
                </div>
                :
                <div>
                  <ColorButton
                    onClick={() => {
                      window.location.href =`mailto:${selectedTenant.email}?subject=Ihr Zugang zum neuen Mieterportal&body=Sehr geehrte Mieterin, sehr geehrter Mieter,%0D%0A%0D%0A wir möchten Ihnen zukünftig Ihre monatlichen Verbrauchsinformationen bequem zukommen lassen. Zu diesem Zweck laden wir Sie ein, in wenigen Schritten Ihren persönlichen Zugang zu unserem Mieterportal einzurichten.%0D%0A%0D%0A Bitte nutzen Sie für die sichere Einrichtung Ihren persönlichen Zugangslink: %0D%0A%0D%0A ${encodeURIComponent(accessLink)} %0D%0A%0D%0A Kommen Sie bei Rückfragen gerne auf uns zu. %0D%0A%0D%0A%0D%0A Mit freundlichen Grüßen`;
                    }}
                    className="purple-btn"
                    text={'Email erstellen'}
                  />
                </div>
                }
              </div>
              </div>
          </div>
      </CustomModal>
    );
  }
}

export default TenantAccessModal;
