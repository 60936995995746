import React, { Component } from 'react';
import CustomModal from './index';
import {
  ColorButton,
  ValueText,
  SwitchContainer,
  DropFile,
  AddCostTypeModal,
  SelectDropdown
} from '../../../../index';
import { Row, Col, Form, Tooltip, OverlayTrigger } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import Help from '../../../../../assets/images/module/general/Help.svg';
import calendar from '../../../../../assets/images/module/general/CalendarIcon.svg';
import { connect } from 'react-redux';
import RangeDatePicker from './DateRangePicker';
import Autoload from '../../../../Autoload';
import moment from 'moment';
import GlobalServices from '../../../../helpers/services/GlobalServices';
import GeneralDataHandler from '../../../../helpers/DataHandler/General';

import { showNotification, hideNotification } from '@mantine/notifications';
import RedTick from '../../../../../assets/images/module/general/RedTick.svg';
import GreenTick from '../../../../../assets/images/module/general/GreenTick.svg';

// Get receipts endpoint from new config
import { config } from '../../../../config/Setting';
import { Check, ThreeDRotationSharp } from "@material-ui/icons";
import { Alert, Text } from "@mantine/core";
import * as customParseFormat from 'dayjs/plugin/customParseFormat'
import dayjs from 'dayjs';
import CopyIcon from '../../../SVGs/copyIcon';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
var { checkProperty, yearDifference, yearDifferenceMoment } = Autoload.MainHelper;
dayjs.extend(customParseFormat)

const MOBILE_SIZE = 992;
const TABLET_SIZE = 1200;

class ReceiptEmailModal extends Component {
  state = {
    descriptionMsg: 'Belege bequem per Email bereitstellen.',
    descriptionMsg2: 'Alle Email-Anhänge (nur Bilder und PDF-Dateien), die Sie an diese Email senden, werden automatisch als Einzelbelege hier bereitgestellt.',
    descriptionMsg3: 'Am besten Sie speichern die Email-Adresse direkt in Ihrem Email-Programm ab, so dass Sie sie immer zur Hand haben.',
    email: 'testemail@hellohousing.de',
    hover: false,
    click: false,
    isMobile: window.innerWidth <= MOBILE_SIZE,
    isTablet: window.innerWidth <= TABLET_SIZE
  };

  descriptionMsgStyle={
    fontFamily: 'Inter, sans-serif',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '16px',
    color: '#0e0e1d',
  }
  
  constructor(props) {
    super(props);
  }

  componentDidMount(){
    this.setState({email: this.props.propertySelected && this.props.propertySelected.receiptEmail})
  }

  handleMouseEnter=()=>{
    this.setState({hover: true});
  }
  handleMouseLeave=()=>{
    this.setState({hover: false});
  }
  
  render() {
    const { open, toggle, className } = this.props;
    const {descriptionMsg, descriptionMsg2, descriptionMsg3, email, hover, click, isMobile, isTablet} = this.state;
    var emailArr = email?.split('@');
    //var emailShort = emailArr[0].length >= 20 ? emailArr[0].slice(0,20) : emailArr[0];
    return (
      <>
        <CustomModal
          heading={'Belege per Email bereitstellen'}
          headingClassName='receipt-management-email-upload-modal-heading'
          open={open}
          toggle={() => {
            toggle();
          }}
          className={`${className}`}
        >
          <div>
            <div>
                <Row>
                    <Col xs={12}>
                        <div><p style={this.descriptionMsgStyle}>{descriptionMsg}</p></div>                        
                        <div style={{}}>
                            <span 
                            style={this.descriptionMsgStyle}>
                                {descriptionMsg2}
                            </span>
                        </div>
                        <div 
                        style={{
                            display: 'flex', 
                            justifyContent: 'center', 
                            margin: '15px 0px', 
                            color: '#5555FF',
                            cursor: 'pointer',
                            border: '1px dashed #bfbfd2',
                            boxSizing: 'border-box',
                            borderRadius: '4px',
                            padding: '16px 16px',
                            background: '#f7f7fa',
                            fontFamily: 'Inter, sans-serif',
                            fontWeight: '400',
                            width: '100%'
                        }}
                        onMouseEnter={this.handleMouseEnter}
                        onMouseLeave={this.handleMouseLeave}
                        onClick={()=> {
                            navigator.clipboard.writeText(email); 
                            this.setState({click: true});
                            showNotification({
                                message: 'Die Email-Adresse wurde kopiert.',
                                icon: <img src={GreenTick} alt='green-tick'/>,
                                style: {marginLeft: !isMobile && !isTablet && '24%', width: '100%'},
                            });
                        }}
                        >
                            <span style={{
                                display: 'inline-block',
                                overflowWrap: 'break-word',
                                width: '420px'
                            }}>
                                {email}
                                <span style={{marginLeft: '3px'}}><CopyIcon fill={click ? '#5555FF' : hover && '#5555FF'} width='15px' height='15px'/></span>
                            </span>
                        </div>
                        <div style={{textAlign: 'justify'}}>
                            <span style={this.descriptionMsgStyle}>{`Dies ist Ihre persönliche Beleg-Email für Ihre Immobilie `}</span>
                            <span style={{...this.descriptionMsgStyle, ...{fontWeight: '600'}}}>{`${this.props.propertySelected && this.props.propertySelected.name}. `}</span>
                            <span style={this.descriptionMsgStyle}>{descriptionMsg3}</span>
                        </div>
                    </Col>
                </Row>
              <div className="center-spacing add-receipt-modal-footer" id="receipt-add-edit-btns">
                <ColorButton
                  onClick={() => {
                    toggle();
                  }}
                  className="gray-btn"
                  text={'Schließen'}
                />
              </div>
              </div>
          </div>
        </CustomModal>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    propertySelected: state.generalReducer.property,
  };
};

export default connect(mapStateToProps)(ReceiptEmailModal);
