import React, { Component, forwardRef } from 'react';
import CustomModal from './index';
import {
  ColorButton,
  ValueText,
  SwitchContainer,
  DropFile,
  AddCostTypeModal,
  SelectDropdown
} from '../../../../index';
import { Row, Col, Form, Tooltip, OverlayTrigger, ButtonGroup, Button } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import Help from '../../../../../assets/images/module/general/Help.svg';
import calendar from '../../../../../assets/images/module/general/CalendarIcon.svg';
import { connect } from 'react-redux';
import RangeDatePicker from './DateRangePicker';
import Autoload from '../../../../Autoload';
import moment from 'moment';
import GlobalServices from '../../../../helpers/services/GlobalServices';
import GeneralDataHandler from '../../../../helpers/DataHandler/General';

import { showNotification, hideNotification } from '@mantine/notifications';
import RedTick from '../../../../../assets/images/module/general/RedTick.svg';
import GreenTick from '../../../../../assets/images/module/general/GreenTick.svg';
import AddIcon from '../../../../../assets/images/module/general/AddIcon.svg';
import Logo from '../../../../../assets/images/module/general/Logo.svg';
import Messenger from '../../../../../assets/images/module/general/Messenger.svg';
import SearchIcon from '../../../../../assets/images/module/general/SearchIcon.svg';
import Up from '../../../../../assets/images/module/general/SelectArrowUp.svg';
import Down from '../../../../../assets/images/module/general/SelectArrowDown.svg';
import TenantSingleIconBlue from '../../../../../assets/images/module/general/TenantSingleIconBlue.svg';

// Get receipts endpoint from new config
import { config } from '../../../../config/Setting';
import { Check, ThreeDRotationSharp } from "@material-ui/icons";
import { Alert, Checkbox, Group, Loader, Select, Skeleton, Stack, Text } from "@mantine/core";
import * as customParseFormat from 'dayjs/plugin/customParseFormat'
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import dayjs from 'dayjs';
import CopyIcon from '../../../SVGs/copyIcon';
import PreviewMessagePdf from '../PreviewMessage/PreviewMessagePdf';
import PreviewMessageEmail from '../PreviewMessage/PreviewMessageEmail';
import EmptyStateContainer from '../EmptyStateContainer/EmptyStateContainer';
import MessagesIcon from '../../../../../assets/images/module/general/MessagesIcon.svg';
import PreviewMessagePdfBillingStatement from '../PreviewMessage/PreviewMessagePdfBillingStatement';
import PreviewMessagePdfMeterReadingsAndConsumptions from '../PreviewMessage/PreviewMessagePdfMeterReadingsAndConsumptions';
import PreviewMessagePdfHeatingInformation from '../PreviewMessage/PreviewMessagePdfHeatingInformation';
import Pdf, { usePdf } from '@mikecousins/react-pdf';
import { ZoomIn, ZoomOut} from '@material-ui/icons';
import { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import PreviewMessageTenantPortal from '../PreviewMessage/PreviewMessageTenantPortal';
import UtilityBillingTemplate2 from '../../../../../views/pages/Module/UtilityBilling/UtilityBillingTemplate2';
import { saveAs } from 'file-saver';
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
var { checkProperty, yearDifference, yearDifferenceMoment } = Autoload.MainHelper;
dayjs.extend(customParseFormat);
var JSZip = require("jszip");

const MOBILE_SIZE = 992;
const TABLET_SIZE = 1200;


class BulkDownloadForReceiptsModal extends Component {
  state = {
    selectedReceipts: [],
    loader: false
  };
  
  constructor(props) {
    super(props);
  }

  componentDidMount(){
    const {selectedReceipts} = this.props;
    this.setState({loader: true, selectedReceipts: selectedReceipts.map((receiptId)=> ({id: receiptId, blob: null, fileType: null}))}, ()=> this.downloadReceipts());
  }

  convertToBase64=(data)=>{
    try {
        var reader = new FileReader();
        reader.readAsDataURL(data); 

        const setState=(base64)=>{
        // this.setState({customImageBase64: base64 ? base64 : null});
        return base64;
        }
        
        reader.onloadend = function() {
        var base64data = reader.result;                
        console.log('base64data: ', base64data);
        setState(base64data);
        }
    } catch (error) {
        console.log('convertToBase64Error: ', error);
    }
  }

  addUniqueFile = (zip, filename, content, fileType) => {
    let counter = 1;
    let baseFilename = filename;
    let fullFilename = `${filename}${fileType}`;

    console.log('zip.files: ', zip.files[fullFilename]);
    // Check if the filename already exists in the ZIP
    for(let i= 1; zip.files[fullFilename] !== undefined; i++){
      fullFilename = `${baseFilename}_${counter++}${fileType}`;
    }
    // while (zip.files[fullFilename]) {
    //     // Append a counter before the file extension and check again
    //     fullFilename = `${baseFilename}_${counter++}${fileType}`;
    // }

    console.log('addUniqueFile: ', fullFilename);  // Log the final filename being added
    // Add the file with the unique name to the ZIP
    zip.file(fullFilename, content);
  };

  setSelectedReceiptsNames=()=>{
    
  }
  

  downloadAllFiles=()=>{
    const {selectedReceipts} = this.state;
    const {toggle, receiptsData} = this.props;
    console.log('receiptsData: ', receiptsData.filter((receiptData)=> selectedReceipts.find((receipt)=> receipt.id === receiptData.id) !== undefined));
    var zip = new JSZip();

    let fileNames=[];

    const checkDuplicates= (fileName, index = 0) => {
      let checkName = fileName, ext = '';
      if(index){
        if(checkName.indexOf('.') > -1){
          let tokens = checkName.split('.'); 
          ext = '.' + tokens.pop();
          checkName = tokens.join('.');
        }
   
        // make any pattern here
        checkName = `${checkName}_${index}${ext}`; 
      }
      
      const nameExists = fileNames.find(f=> f.includes(checkName)) !== undefined;
      return nameExists ? checkDuplicates(checkName, index + 1) : checkName;
    }

    selectedReceipts.map((receipt, index)=> {
      let receiptData = receiptsData.find((data)=> data.id === receipt.id);
      let fileName = `${dayjs(receiptData?.receiptDate).format('YYYYMMDD')}_${receiptData?.name.replace(/ /g, '-')}`;
      let fileNameAfterDuplicateCheck= checkDuplicates(fileName+receipt.fileType, 0);
      fileNames.push(fileNameAfterDuplicateCheck);
      zip.file(fileNameAfterDuplicateCheck, receipt.blob);

      if(selectedReceipts.length === index+1){
        console.log('fileNames: ', fileNames);
        zip.generateAsync({type:"blob"}).then(function(content) {
          saveAs(content, `${dayjs().format('YYYYMMDD_hhmm')}_Belegexport.zip`);
          setTimeout(() => {
              toggle();
          }, 3000);
        });
      }
    });
  }

  async convertBlobToArrayBuffer(blob) {
    console.log('convertBlobToArrayBuffer_2234: ', blob);
    const buf = await blob.arrayBuffer();
    return buf;
  }

  async mergePdfs(pdfsToMerge) {
    console.log('mergePdfs', pdfsToMerge);
    const mergedPdf = await PDFDocument.create();
    const font = await mergedPdf.embedStandardFont(StandardFonts.Helvetica);

  const createInnerPromise = async (arrayBuffer) => {
    const pdf = await PDFDocument.load(arrayBuffer);
    return await mergedPdf.copyPages(pdf, pdf.getPageIndices());
  };

  const outerPromise = pdfsToMerge.map((arrayBuffer) => {
    const innerPromise = createInnerPromise(arrayBuffer);
    return innerPromise;
  });

  const resultOuterPromise = await Promise.all(outerPromise);

  let count = 0;
  let totalPages = 0;
  resultOuterPromise.forEach((pageArray, i) => {
    pageArray.forEach((page, index) => {
      totalPages = totalPages + 1;
    })
  });
  resultOuterPromise.forEach((pageArray, i) => {
    pageArray.forEach((page) => {
    //const { width, height } = page.getSize();
    //page.drawText(`Seite ${count+1} von ${totalPages}`, {
    //     x: width - 75.5,
    //     y: height - 34, //height - 47,
    //     size: 6,
    //     color: rgb(0.4470588235294118, 0.4470588235294118, 0.5803921568627451),
    //   })

      //add footer
      // var text = `${this.props.accountData.addressCompany && (this.props.accountData.addressCompany + ', ')}${this.props.accountData.addressStreet && (this.props.accountData.addressStreet + ',')} ${(this.props.accountData.addressZipCode)} ${this.props.accountData.addressCity}`;
      // const textWidth = font.widthOfTextAtSize(text, 8);
      // this.props.currentSubscription.subscriptionKey.split('_')[0] !== 'STARTER' && page.drawText(text, {
      //   x: page.getWidth() / 2 - textWidth / 2,
      //   y: height - (height-30),
      //   size: 8,
      //   color: rgb(0, 0, 0)//rgb(0.5, 0.5, 0.5)
      // })
      //
      mergedPdf.addPage(page);
      //count = count + 1;
    });
  });

  return (await mergedPdf.save()).buffer;
  }

  startDownloadProcess=()=>{
    const {selectedRecipientsList} = this.state;
    const {messageData} = this.props;

    console.log('startDownloadProcess', messageData, selectedRecipientsList);

    this.setState({selectedRecipient: selectedRecipientsList[0].id}, ()=> messageData.utilityBillingData !== null && this.getBillingStatements());
  }

  downloadReceipts = () => {
    const {selectedReceipts} = this.state;

    selectedReceipts.map((receipt, index)=> 
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.rm_api_base_url + 'attachment/' + receipt.id,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleDownloadReceipts',
      {id: receipt.id, eol: selectedReceipts.length === (index+1)},
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    ));
  };
  
  handleDownloadReceipts = (response) => {
    const {selectedReceipts} = this.state;
    if (response.status === false && response.message !== 'Not Found') {
      
    }

    if(response && response.result){
      fetch(Autoload.Setting.rm_api_base_url + 'attachment/' + response.other.id +'/download',
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
          'Authorization': Autoload.Utils.getAuthToken()
        }
      })
            .then(response1 => {
                response1.blob()
                .then(blob => {
                    console.log('blob: ', blob);

                    this.setState({selectedReceipts: selectedReceipts.map((receipt)=> {
                      if(receipt.id === response.other.id){
                        receipt.blob = blob;
                        receipt.fileType = response.result.includes('pdf') ? '.pdf' : response.result.includes('jpeg') ? '.jpeg' : response.result.includes('jpg') ? '.jpg' : response.result.includes('png') && '.png'
                      }
                      return receipt;
                    })});
                  });
              })
    }

    if(response.other.eol){
      setTimeout(() => {
        this.setState({loader: false});
      }, 1000);
    }
  };
  
  render() {
    const { open, toggle, className, propertySelected, accountData, isMobile, profile, currentSubscription} = this.props;
    const {selectedReceipts, loader} = this.state;

    console.log('selectedReceipts: ', selectedReceipts);
    return (
      <>
        <CustomModal
          heading={'Belegdokumente herunterladen'}
          headingClassName='bulk-download-receipts-heading'
          open={open}
          toggle={() => {
            toggle();
          }}
          className={`${className}`}
        >
            <Stack style={{minHeight: 'inherit'}} justify={'space-between'}>
                {(loader || selectedReceipts.find((receipt)=> receipt.blob === null) !== undefined) ?
                <Stack style={{minHeight: 'inherit'}} justify={'center'}>
                  <Group style={{width: '100%'}} position={'center'} spacing={4} noWrap>
                      <span style={{whiteSpace: 'nowrap', fontWeight: '400'}}>Dokumente werden bereitgestellt: <span style={{color: '#5555FF'}}>{`${selectedReceipts.filter((selectedReceipt)=> selectedReceipt.blob !== null).length} von ${selectedReceipts.length}`}</span></span>
                      <Loader variant='dots' size={10} style={{marginTop: '8px'}} color='#5555FF' />
                  </Group>
                </Stack>
                :
                <Stack>
                  <span style={{whiteSpace: 'nowrap', fontWeight: '400'}}>Die Dokumente können jetzt gespeichert werden.</span>
                </Stack>}
                <Group style={{width: '100%', marginTop: '20px'}} position='apart'>
                    <div>
                    <ColorButton
                    text={'Abbrechen'}
                    className={'gray-btn'}
                    onClick={()=> toggle()}
                    />
                    </div>

                    {<div>
                    <ColorButton
                    text={'Speichern'}
                    className={'purple-btn'}
                    disabled={loader || selectedReceipts.find((receipt)=> receipt.blob === null) !== undefined}
                    onClick={()=> this.downloadAllFiles()}
                    />
                    </div>}
                </Group>
            </Stack>
        </CustomModal>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    utilityBillingData: state.UtilityBillingReducer.utilityBillingData,
    currentDataId: state.UtilityBillingReducer.currentBillingId,
    propertySelected: state.generalReducer.property,
    profile: state.generalReducer.profile,
    rentalUnits: state.tenantDataReducer.rentalUnits,
    accountData: state.generalReducer.accountData,
    currentSubscription: state.generalReducer.currentSubscription
  };
};

export default connect(mapStateToProps)(BulkDownloadForReceiptsModal);
