import { store, persistor } from "../../store/index";
import Autoload from "../Autoload";
import imageCompression from 'browser-image-compression';

export function getAuthToken() {
  // grab current state
  const state = store.getState();
  // get the token out of it
  const authToken = state.generalReducer.profile.auth.token;
  // return the token 
  return authToken
}


export async function compressImage(file, res) {
  const options = {
    maxSizeMB: 0.2,
    useWebWorker: true,
  }
  const compressedFile =  await imageCompression(file, options);
  console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
  console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
  res(compressedFile);
}

export function check_auth(page = null) {

  const state = store.getState();

  const profile = state.generalReducer.profile;
  // if(skip_auth(page.props.location.pathname)){

  //   return true;

  // }  
  var auth = Autoload.MainHelper.checkProperty(profile, "auth");

  var token = Autoload.MainHelper.checkProperty(auth, "token");


  if (page.props.location.pathname == "/login") {
    if (auth == "" || token == "") {

      return true;

    } else {
      let lastRoute= localStorage.getItem('lastRoute');
      lastRoute ? window.location.href = lastRoute :
      Autoload.Redirection.redirection_dashboard();
    }

  }

  if (auth == "") {
    localStorage.setItem('lastRoute', window.location.href.includes('localhost') ? window.location.href.split('http://localhost:3000')[1] : window.location.href.includes('dev.hellohousing.de') ? window.location.href.split('https://dev.hellohousing.de')[1] : window.location.href.split('https://app.hellohousing.de')[1]);
    Autoload.Redirection.redirection_login();
  }

  if (token == "") {
    localStorage.setItem('lastRoute', window.location.href.includes('localhost') ? window.location.href.split('http://localhost:3000')[1] : window.location.href.includes('dev.hellohousing.de') ? window.location.href.split('https://dev.hellohousing.de')[1] : window.location.href.split('https://app.hellohousing.de')[1]);
    Autoload.Redirection.redirection_login();
  }

  // alert();
  // console.log("page",);


  return true;


}


export function skip_auth(url = "") {

  // var urldata = ['/404','/500'];
  // if(urldata.find(item=>item==url) === 'undefined'){
  //     return false;
  // }

  return false;

};

export function getUrlParameterByName(name, url = window.location.href) {
  console.log('param', name, url)
  let p = url.split(name)[1]
  console.log(p)
  if (p) {
    p = p.split('&')[0].replace('=', '').trim()
    return p
  } else {
    return null
  }
  // name = name.replace(/[\[\]]/g, '\\$&');
  // var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
  //   results = regex.exec(url);
  // if (!results) return null;
  // if (!results[2]) return '';
  // return decodeURIComponent(results[2].replace(/\+/g, ' '));
}


export function clear_session_data(type = "logout") {

  if (type == "logout") {
    // alert("aa");

    localStorage.clear();
  }

};

export function setCookie(name, value, days = 1) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

export function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
}

export function delete_cookie(name, path = "/", domain) {
  if (getCookie(name)) {
    document.cookie = name + "=" +
      ((path) ? ";path=" + path : "") +
      ((domain) ? ";domain=" + domain : "") +
      ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
  }
}
