import React, { Component } from 'react';
import CustomModal from '../../../../neoverv/components/Module/General/CustomModal/index';
import { Form, Col } from 'react-bootstrap';
import Autoload from '../../../../neoverv/Autoload';
import { CustomDateInput_v2 } from '../../../../neoverv/index.js';
import moment from 'moment';
import DatePicker, { registerLocale } from 'react-datepicker';
import de from 'date-fns/locale/de';
import { formatNumberByLocale } from '../../../../helpers/stringsManipulations';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default].meter;

class MobileInterimReading extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      id: 0,
    };
  }

  toggleShowModal = (id) => {
    this.setState({
      openModal: !this.state.openModal,
      currentMeter: id,
      id,
    });
  };

  getTableLabels = () => {
    return this.props.labels.map((label, index) => {
      return (
        <th key={index} className={`${this.props.labelClassName[index]} `}>
          {label === '' ? '' : label}
        </th>
      );
    });
  };

  getTableData = () => {
    return this.props.data.sort((a, b) => a.meterNumber.localeCompare(b.meterNumber))
    .filter((item) =>
      item.meterNumber.toUpperCase().includes(this.props.search.toUpperCase()) ||
      item.meterType.toUpperCase().includes(this.props.search.toUpperCase())).map((item, i) => {
      return (
        <tr key={item.id} onClick={() => this.toggleShowModal(i)}>
          <td className={`mobile-meter-type`} style={{ maxWidth: '30vw', textOverflow: 'ellipsis', whiteSpace: 'wrap', overflow: 'hidden'}}>{item.meterNumber}</td>
          <td className={`mobile-meter-type`} style={{ maxWidth: '30vw', textOverflow: 'ellipsis', whiteSpace: 'wrap', overflow: 'hidden'}}>{item.meterType}</td>
          <td className={`mobile-meter-current-value`}>{formatNumberByLocale(item.currentValue)}</td>
        </tr>
      );
    });
  };

  render() {
    const { className, onChange, handleSubmit, validated, noValidate, data, edit, onDateChange, form, parentContext } =
      this.props;
    const { id, openModal } = this.state;
    return (
      <div className="mobile-interim" style={{marginTop: '25px'}}>
        <table className={`table borderless ${className} mb-0`}>
          <thead>
            <tr className="table-heading mobile-interim-heading">{this.getTableLabels()}</tr>
          </thead>
          <tbody className="mobile-reading-table-rows">{this.getTableData()}</tbody>
        </table>
        <CustomModal
          open={openModal}
          toggle={() => this.toggleShowModal()}
          heading="Zählerdaten"
          className="mobile-meter-detail-modal"
        >
          {data[id] !== undefined && (
            <Form
              onSubmit={handleSubmit}
              id={form}
              noValidate={noValidate}
              validated={validated}
              className="mobile-meter-form"
            >
              <Form.Row>
                <Col className="meter-label" xs={5}>
                  {lang.text_meter_number}
                </Col>
                <Col className="meter-mobile meter-mobile-number" xs={7}>
                  <Form.Control
                    size="xs"
                    type="text"
                    className={edit && 'dis-input'}
                    // disabled={edit}
                    disabled={true}
                    name="meterNumber"
                    onChange={(e) => onChange('meterNumber', e.target.value, data[id])}
                    placeholder="42201"
                    required
                    defaultValue={data[id].meterNumber}
                  />
                  <Form.Control.Feedback type="invalid">{lang.text_invalid_number}</Form.Control.Feedback>
                </Col>
              </Form.Row>
              <Form.Row>
                <Col xs={5} className="meter-label">
                  {lang.text_meter_type}
                </Col>
                <Col className="meter-mobile meter-mobile-type" xs={7}>
                  <Form.Control
                    className={edit && 'dis-input'}
                    name="meterType"
                    disabled={true}
                    placeholder="Wärmemenge (MWh)"
                    size="xs"
                    defaultValue={data[id].meterType}
                    required
                    onChange={(e) => onChange('meterType', e.target.value, data[id])}
                  />
                  <Form.Control.Feedback type="invalid">{lang.text_invalid_type}</Form.Control.Feedback>
                </Col>
              </Form.Row>
              <Form.Row>
                <Col className="meter-label" xs={5}>
                  {lang.text_reading_date}
                </Col>
                <Col className="meter-mobile meter-mobile-date" xs={7}>
                  {/* <Form.Control className={edit&&"dis-input"} name="readingDate"
                                    placeholder="30.12.21"
                                    pattern="[0-3]{1}[0-9]{1}[.][0-1]{1}[0-9]{1}[.][0-9]{2}"
                                    size="xs"
                                    defaultValue={data[id].readingDate} required
                                    onChange={(e) => onChange('readingDate', e.target.value, data[id]) }
                                />
                                <Form.Control.Feedback type="invalid">
                                    {lang.text_invalid_date}
                                </Form.Control.Feedback> */}
                  <DatePicker
                    locale="de"
                    dateFormatCalendar="LLLL"
                    className={edit && 'dis-input'}
                    showMonthYearPicker={false}
                    dateFormat="dd.MM.yyyy"
                    customInput={<CustomDateInput_v2 />}
                    placeholderText="TT.MM.JJJJ"
                    style={{ display: 'block !important' }}
                    popperPlacement="bottom-end"
                    selected={new Date(moment(data[id].readingDate, 'DD.MM.YYYY'))}
                    onChange={(e) => onDateChange('readingDate', e, data[id])}
                  />
                </Col>
              </Form.Row>
              <Form.Row>
                <Col className="meter-label" xs={5}>
                  {lang.text_start_value}
                </Col>
                <Col className="meter-mobile meter-mobile-start" xs={7}>
                  <Form.Control
                    className={edit && 'dis-input'}
                    name="startValue"
                    placeholder="30,5"
                    size="xs"
                    type="number"
                    disabled={!data[id].editStartValue}
                    value={data[id].startValue}
                    required
                    onChange={(e) => onChange('startValue', e.target.value, data[id])}
                  />
                  <Form.Control.Feedback type="invalid">{lang.text_invalid_value}</Form.Control.Feedback>
                </Col>
              </Form.Row>
              <Form.Row>
                <Col className="meter-label" xs={5}>
                  {lang.text_current_value}
                </Col>
                <Col className="meter-mobile meter-mobile-current" xs={7}>
                  <Form.Control
                    className={edit && 'dis-input'}
                    name="currentValue"
                    placeholder="30,5"
                    size="xs"
                    type="number"
                    required
                    value={data[id].currentValue}
                    onChange={(e) => onChange('currentValue', e.target.value, data[id])}
                  />
                  <Form.Control.Feedback type="invalid">{lang.text_invalid_value}</Form.Control.Feedback>
                </Col>
              </Form.Row>
              <Form.Row>
                <Col className="meter-label" xs={5}>
                  {lang.text_consumption}
                </Col>
                <Col className=" meter-mobile meter-mobile-consumption" xs={7}>
                  <Form.Control
                    className={edit && 'dis-input'}
                    name="consumption"
                    placeholder="17,5MWh"
                    size="xs"
                    disabled={true}
                    type="number"
                    value={data[id].consumption}
                    required
                    onChange={(e) => onChange('consumption', e.target.value, data[id])}
                  />
                  <Form.Control.Feedback type="invalid">{lang.text_invalid_value}</Form.Control.Feedback>
                </Col>
              </Form.Row>
              <Form.Row>
                <Col className="meter-label" xs={12}>
                  {lang.text_note}
                </Col>
                <Col className="" xs={12}>
                  <Form.Control
                    className={edit && 'dis-input'}
                    name="notes"
                    size="xs"
                    value={data[id].notes}
                    onChange={(e) => onChange('notes', e.target.value, data[id])}
                    onBlur={(e) => {
                      parentContext.saveMeterNotes(data[id], e.target.value);
                    }}
                  />
                </Col>
              </Form.Row>
            </Form>
          )}
        </CustomModal>
      </div>
    );
  }
}
export default MobileInterimReading;
