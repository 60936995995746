import React, { useEffect, useState } from 'react';
import { RecordOptions, DeleteModal, SwitchContainer } from '../../../../index';
import TenantSingleIcon from '../../../../../assets/images/module/general/TenantSingleIcon.svg';
import TenantLiteIcon from '../../../../../assets/images/module/general/TenantLite.svg';
import buildingIcon from '../../../../../assets/images/module/general/building_icon.svg';
import GreenTick from '../../../../../assets/images/module/general/GreenTick.svg';
import AddIcon from '../../../../../assets/images/module/general/AddIcon.svg';
import SelfIcon from '../../../../../assets/images/module/general/SelfIcon.svg';
import { connect } from 'react-redux';
import { deleteTenant, setCurrentTenant } from '../../../../../redux/action/Tenant/TenantActions';
import moment from 'moment';
import Autoload from '../../../../Autoload';
import TransparentButton from '../Buttons/TransparentButton';
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import { showNotification } from '@mantine/notifications';
import dayjs from 'dayjs';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];

function TenantsRecord({
  showAll,
  deleteTenant,
  setCurrentTenant,
  currentRecord,
  parentId,
  history,
  data = [],
  labels,
  className,
  labelClassName,
  dataClassName,
  dataIcons,
  showOption,
  editOption,
  parent,
  addtenant,
  showAllTenants,
  showAllTenantsAfterAdd,
  tenantAdministration= false,
  handleAddTenant
}) {
  // console.log(showAll, showOption, setCurrentTenant, 'props in tenant recordx');
  // const [currentRecord,setCurrentRecord]=useState(null)
  const [updatedData, setUpdatedData] = useState(data);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [showPreviousTenants, setShowPreviousTenants] = useState(showAllTenantsAfterAdd);

  const toggleDeleteModal = () => {
    setDeleteOpen(!deleteOpen);
  };

  const onTenantEdit = (id) => {
    getRecord(id);

    console.log("----------------------");
    console.log("TENANT EDIT:");
    console.log(id);
    console.log("----------------------");

    // console.log('parentId', parentId);
    // TODO: Refactor this
    history.push(tenantAdministration ? '/user/tenant/access?tenantId=' + id + '&tenantAdministration='+ tenantAdministration : '/user/tenant/information?tenantId=' + id + '&tenantAdministration='+ tenantAdministration, { unitId: parentId });
  };

  useEffect(()=> {
    setUpdatedData(data);
  }, [data]);

  console.log('TenantsRecord: ', updatedData);

  const getRecord = (id) => {
    const record = updatedData.find((rcd) => rcd.id === id);
    // console.log(record, 'record on edit tenant');
    setCurrentTenant(record, record);
  };

  const deleteTenantApi = (id) => {
    //call delete api for tenants
    let btnelement = document.getElementById('tenant-delete-modal-footer');
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'Tenants/' + id,
      'DELETE',
      null,
      btnelement.children[1],
      false,
      true,
      false,
      parent,
      'handleDeleteTenant',
      { cancelbtn: btnelement.children[0] },
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );

    deleteUser(id);
  };

  const deleteUser=(id)=>{
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.user_api_baseurl +id+'/tenant',
      'DELETE',
      null,
      null,
      false,
      true,
      true,
      this,
      '_handleDeleteUser',
      null,
      false,
      '',
      { 'Content-Type': 'application/json', Authorization: Autoload.Utils.getAuthToken() },
      true
    );
  }

  // const handleDeleteTenant=(response)=>{
  //   console.log('delete tenant', response)
  //   // deleteTenant(parentId,id)
  // }

  const getTableLabels = () => {
    return labels.map((label, index) => {
      return (
        <TableCell key={index} style={{whiteSpace: 'nowrap'}} className={labelClassName[index]}>
          {label === '' ? '' : label}
        </TableCell>
      );
    });
  };

  //
  const filterData = () => {
    let filtered = [];
    // if (showAll == true ? true : showPreviousTenants == true ? true : false) {
    if (showAll == true ? true : showPreviousTenants == true ? true : false) {
      filtered = updatedData;
      // console.log(updatedData, 'updatedData in filter');
    } else {
      if (updatedData.length > 0) {
        filtered = updatedData.filter((tenant) => {
          if (tenant.current) {
            return tenant.current == true;
          } else {
            return false;
          }
        });
      } else {
        filtered = [];
      }
    }
    // console.log('showAll', showAll, 'pre', showPreviousTenants, filtered)
    return filtered;
  };

  const rowRenderer=(row, rowIndex)=>{
    return (<TableRow key={rowIndex} className="table-data">
    <TableCell className="tenant-name" style={{cursor: row.vacant && 'auto'}} onClick={() => row.vacant === false && onTenantEdit(row.id)}>
      <img
        src={row.vacant === true ? TenantLiteIcon : row.type == 'commercial' ? buildingIcon : row.type === 'self' ? SelfIcon : TenantSingleIcon}
        alt="data-icon"
        style={{width: row.type === 'self' && 'auto' }}
      />
      <span>{row.name !== '' ? row.name : '-'}</span>
      {/* <a href="javascript:;" onClick={()=>onTenantEdit(row.id)}><span>{row.name!=='' ? row.name : '-'}</span></a> */}
    </TableCell>
    <TableCell className="tenant-email">
      <div style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', color: !row.email && '#0E0E1D', cursor: !row.email && 'auto'}} onClick={()=> {if(row.email){ window.location.href =`mailto:${row.email}`; }}}>
        {row.email !== '' ? row.email : '-'}
      </div>
    </TableCell>
    <TableCell className="tenant-num" style={{color: !row.phone && '#0E0E1D', cursor: !row.phone && 'auto'}} 
    onClick={()=> {
      if(row.phone){
        window.location.href =`tel:${row.phone}`;
      }
    }}>
      {row.phone !== '' ? row.phone : '-'}
    </TableCell>
    <TableCell className="tenant-in">{row.in !== '' ? row.in : '-'}</TableCell>
    <TableCell className="tenant-out">{row.out !== '' ? row.out : '-'}</TableCell>
    {/* {Object.keys(row).map((keyName, index) => {
              if(keyName==='id'){
                return <TableCell style={{display:'none'}}></TableCell>
              }else{
                return (
                  <TableCell key={index} className={dataClassName[index]} >
                    {dataIcons[index] && dataIcons[index]!=='' && <img src={dataIcons[index]} alt='data-icon' />}
                    {row[keyName] === "" ? "-" : row[keyName]}
                  </TableCell>
                );
              }
            })} */}
    {/* <TableCell className="tenant-portal-access">
      {<SwitchContainer
      switchText={row.vacant ? 'Deaktivieren' : 'Aktive'}
      checked={row.vacant ? false: true}
      disabled={row.vacant}
      />}
    </TableCell> */}
    <TableCell className="empty">
      <div style={{display: 'flex', justifyContent: 'end', width: '100%'}}>
      {editOption && row.vacant === false ? (
        <RecordOptions
          toggleDeleteModal={() => toggleDeleteModal()}
          toggleEditModal={() => onTenantEdit(row.id)}
          //showDeleteButton={row.current}
          getRecord={()=> getRecord(row.id)}
        />
      ) : (
        <div style={{cursor: 'pointer', width: '16px', textAlign: 'center'}} onClick={()=> handleAddTenant(row.in, row.out)}>
          <img src={AddIcon}/>
        </div>
      )}
      </div>
    </TableCell>
  </TableRow>)
  }

  const getTableData = () => {
    console.log('getTableData: ', updatedData);
    return updatedData
    .filter((row, index)=> !showPreviousTenants ? ((index === 0 && updatedData.length > 1) ? row.vacant == true ? dayjs(updatedData[1].out, 'DD.MM.YYYY').isBefore(dayjs(), 'date') : row : row) : row)
    .map((row, rowIndex) => {
      console.log(showAllTenantsAfterAdd, 'updatedData showAllTenantsAfterAdd in tenant record');
      if(!showPreviousTenants){
        if(rowIndex === 0){
          return rowRenderer(row, rowIndex);
        }
      }else{
        return rowRenderer(row, rowIndex);
      }
    });
  };
  console.log('currentrecord', showAllTenants);
  return (
    updatedData.length > 0 && (
      <>
        {/* {console.log(updatedData, addtenant, 'updatedData in 150')} */}
        <Table className={`table borderless ${className} mb-0`}>
          <TableBody>
            <TableRow className="table-heading">{getTableLabels()}</TableRow>
            {getTableData()}
          </TableBody>

          <DeleteModal
            record={currentRecord}
            className="delete-rent-modal"
            open={deleteOpen}
            toggle={() => toggleDeleteModal()}
            handleDelete={(id) => {
              deleteTenantApi(id);
            }}
            // showInfo={[currentRecord.name, '']}
            type="Mieter"
            showtext={false}
            typeText={'Sind Sie sicher, dass Sie diesen Mieter löschen wollen. Sämtliche Mieterdaten gehen unwiderruflich verloren.'}
            // type={lang.newKeys.text_single_tenant}
            modalId="tenant-delete-modal-footer"
            textShowModal={"Mietverhältnis löschen"}
            showDeleteText={false}
          />
        </Table>
        {/* {
          showAllTenants&& updatedData.length > 1 &&(

          )
        } */}
        {/* {!showAll && updatedData.length > 1 && (
          <TransparentButton
            onClick={() => setShowPreviousTenants(!showPreviousTenants)}
            // onClick={() => showAllTenants(!showAll)}
            text={showPreviousTenants ? lang.tenant.text_show_less : lang.tenant.text_show_previous_tenants}
            className="tc-dark-purple mb-0 p-0"
          />
        )} */}
        {
          // !showAll &&
          updatedData.length > 1 && (
            <TransparentButton
              onClick={() => setShowPreviousTenants(!showPreviousTenants)}
              text={showPreviousTenants ? lang.tenant.text_show_less : lang.tenant.text_show_previous_tenants}
              className="tc-dark-purple mb-0 p-0"
            />
          )
        }
      </>
    )
  );
}
TenantsRecord.defaultProps = {
  parentId: null,
  data: [],
  labels: [],
  dataClassName: [],
  labelClassName: [],
  dataIcons: [],
};
const mapStateToProps = (state) => {
  return {
    currentRecord: state.tenantDataReducer.currentTenant,
    // addtenant: state.tenantDataReducer.unit,
  };
};
const actions = {
  deleteTenant,
  setCurrentTenant,
};
export default connect(mapStateToProps, actions)(TenantsRecord);
