import React, { Component, forwardRef } from 'react';
import CustomModal from './index';
import { connect } from 'react-redux';
import Autoload from '../../../../Autoload';
import * as customParseFormat from 'dayjs/plugin/customParseFormat'
import dayjs from 'dayjs';
import { Accordion, Button, Group, MultiSelect, Stack, Stepper, CloseButton, Select } from '@mantine/core';
import DropFile from '../DropFile/DropFile';
import { Form } from 'react-bootstrap';
import SelectDropdown from '../SelectDropdown/SelectDropdown';
import ColorButton from '../Buttons/ColorButton';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import RedCross from '../../../../../assets/images/module/general/RedCross.svg';
import SelfIcon from '../../../../../assets/images/module/general/SelfIcon.svg';
import TenantSingleIcon from '../../../../../assets/images/module/general/TenantSingleIcon.svg';
import TenantSingleIconBlue from '../../../../../assets/images/module/general/TenantSingleIconBlue.svg';
import TenantLiteIcon from '../../../../../assets/images/module/general/TenantLite.svg';
import buildingIcon from '../../../../../assets/images/module/general/building_icon.svg';
import Up from '../../../../../assets/images/module/general/SelectArrowUp.svg';
import Down from '../../../../../assets/images/module/general/SelectArrowDown.svg';
import SearchIcon from '../../../../../assets/images/module/general/SearchIcon.svg';
import EditForm from '../../../../../assets/images/module/general/EditForm.svg';
import DeutschePostIcon from '../../../../../assets/images/module/general/DeutschePostIcon.svg';
import TenantPortalIcon from '../../../../../assets/images/module/general/TenantPortalIcon.svg';
import PenIcon from '../../../../../assets/images/module/general/PenIconWhite.svg';
import EmailIcon from '@material-ui/icons/Email';
import { showNotification } from '@mantine/notifications';
import { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import SwitchContainer from '../SwitchContainer';
import EmptyStateContainer from '../EmptyStateContainer/EmptyStateContainer';
import AddRecipientModal from './AddRecipientModal';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
var { checkProperty, yearDifference, yearDifferenceMoment, loader } = Autoload.MainHelper;
dayjs.extend(customParseFormat)

const MOBILE_SIZE = 992;
const TABLET_SIZE = 1200;

function StepperForTenants(props) {
  const [active, setActive] = useState(0);
  const nextStep = () => setActive((current) => (current < 3 ? current + 1 : current));
  const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current));
  console.log('rentalUnits: ', props.rentalUnits);
  const tenantTableContent=(headers, tenants)=>{
    return (<TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {headers.map((header)=>
            <TableCell>
              {header}  
            </TableCell>)}
          </TableRow>
        </TableHead>
        <TableBody>
          {tenants.map((row)=>
          <TableRow>
            <TableCell>
              {row.name}
            </TableCell>
            <TableCell>
              <SwitchContainer
              checked={true}
              switchText={''}
              />
            </TableCell>
            <TableCell>
              <SwitchContainer
              checked={true}
              switchText={''}
              />
            </TableCell>
          </TableRow>)}
        </TableBody>
      </Table>
    </TableContainer>)
  }
  return (
    <>
      <Stepper active={active} onStepClick={setActive} breakpoint="xs">
        <Stepper.Step label="First step" description="Create an account">
          <Stack>
            <Accordion
            classNames={{
              control: 'ub-sending-select-tenant-modal-accordion-control',
              content: 'ub-sending-select-tenant-modal-accordion-content',
              icon: 'ub-sending-select-tenant-modal-accordion-icon',
              contentInner: 'ub-sending-select-tenant-modal-accordion-contentInner'
            }}>
              {props.rentalUnits.map((rentalUnit)=>
              <Accordion.Item
              label={<Group style={{cursor: 'pointer', backgroundColor: 'white', padding: '10px', borderRadius: '4px'}}>
                <span style={{fontWeight: '500', fontSize: '14px', fontFamily: 'Inter, sans-sarif'}}>{rentalUnit.name}</span>
              </Group>}>
                  {tenantTableContent(['Name', 'über E-Mail', 'über Deutsche Post'], rentalUnit.tenantsData)}
              </Accordion.Item>)}
            </Accordion>
          </Stack>
        </Stepper.Step>
        <Stepper.Step label="Second step" description="Verify email">
          Step 2 content: Verify email
        </Stepper.Step>
        <Stepper.Step label="Final step" description="Get full access">
          Step 3 content: Get full access
        </Stepper.Step>
        <Stepper.Completed>
          Completed, click back button to get to previous step
        </Stepper.Completed>
      </Stepper>

      <Group position="center" mt="xl">
        <Button variant="default" onClick={prevStep}>Back</Button>
        <Button onClick={nextStep}>Next step</Button>
      </Group>
    </>
  );
}

function Value({
  value,
  label,
  onRemove,
  classNames,
  ...others
}) {
  console.log('Value: ', value, label, others);
  return (
      <Group spacing={5} className='message-card-ten-cards' key={value} noWrap style={{height: '20.6px', maxHeight: '20.6px', background: '#F2F2FC', paddingRight: 3, marginRight: '3px'}}>
          <Group noWrap spacing={5} style={{minWidth: '55px'}}>
              <img src={label.picture} style={{marginLeft: '5px'}}/>
              <span style={{whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}}>{label.name}</span>
          </Group>
          <Group>
              <CloseButton
              onMouseDown={onRemove}
              variant="transparent"
              size={15}
              iconSize={14}
              tabIndex={-1}
              />
          </Group>
      </Group>
  );
}

const Item = forwardRef(({ label, value, ...others }, ref) => {
  console.log('Item: ', label, value, others);
  //const Flag = flags[value];
  return (
    <div ref={ref} {...others}>
      {/* <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box mr={10}>
          <img src={others.picture}/>
        </Box>
        <div>{others.name}</div>
      </Box> */}

      {value === 'addRecipient' ?
      <span onClick={()=> others.openAddRecipientModal()}>{others.name}</span>
      :
      value === 'selectAll' ?
      <span onClick={()=> others.selectAllRecipients()}>{others.name}</span>
      :
      <Group spacing={10} className='message-card-ten-cards' key={value} 
      style={{
        height: '20.6px', 
        maxHeight: '20.6px', 
        background: 'transparent', 
        width: 'auto', 
        color: '#0e0e1d',
        fontSize: '14px',
        fontWeight: '500',
        fontFamily: 'sans-serif'
      }}>
          {others.picture && <img src={others.picture} style={{marginTop: '-3px'}}/>}
          <Group spacing={5}>
            <span>{others.name}</span>
            {others.rentalUnit && <span>{`(${others.rentalUnit})`}</span>}
          </Group>
          {/* {others.current && <span>(aktuell)</span>} */}
      </Group>}
    </div>
  );
});

class AddMessagingRecipientsModal extends Component {
  state = {
    isMobile: window.innerWidth <= MOBILE_SIZE,
    isTablet: window.innerWidth <= TABLET_SIZE,
    selectedRecipients: [],
    openAddRecipientModal: false,
    dropdownIcon: Down,
    data:[],
    tenants: [],
    editRecipient: false,
    recipientData: null,
    initiallyOpened: false,
    dropdownVisible: true
  };

  componentDidMount(){
    const {rentalUnits, previouslySelectedRecipients, propertySelected, showUtilityBillingMessages, disableTenantsSelection, disableExternalContactsAdd} = this.props;
    let {selectedRecipients, data, tenants} = this.state;
    selectedRecipients= previouslySelectedRecipients.length > 0 ? previouslySelectedRecipients : selectedRecipients;
    console.log('selectedRecipients: ', selectedRecipients);
    let ub= this.props.utilityBillingData.find((ub1)=> ub1.id === this.props.currentDataId);
    rentalUnits.map((rentalUnit)=>
      rentalUnit.tenantsData.filter((row)=> row.vacant === false).map((tenant)=> {
        // selectedRecipients.push({
        //   id: tenant.id,
        //   isEmailEnabled: checkProperty(tenant, 'email', false) ? true : false,
        //   isPostEnabled: true
        // });
        if(disableTenantsSelection === false){
          if(showUtilityBillingMessages){
            if((dayjs(tenant.in, 'DD.MM.YYYY').isBefore(dayjs(ub?.billingPeriod ? ub?.billingPeriod.endDate : ub?.billingPeriodEnd))) && (tenant.out.length > 0 ? dayjs(tenant.out, 'DD.MM.YYYY').isAfter(dayjs(ub?.billingPeriod ? ub?.billingPeriod.startDate : ub?.billingPeriodStart)) : true)){
              tenants.push({
                ...tenant,
                street: propertySelected.propertyAddress,
                city: propertySelected.propertyCity,
                zipCode: propertySelected.propertyZipCode,
                value: tenant.id,
                label: tenant.name,
                isEmailEnabled: tenant.email.length > 0 ? true : false,
                isPostEnabled: (propertySelected.propertyAddress && propertySelected.propertyCity && propertySelected.propertyZipCode) ? true : false,
                isHiddenForTenantPortal: true
              });
              data.push({
                value: tenant.id,
                name: tenant.name,
                picture: tenant.vacant === true ? TenantLiteIcon : tenant.type == 'commercial' ? buildingIcon : tenant.type === 'self' ? SelfIcon : TenantSingleIcon,
                label: tenant.name,
                rentalUnit: rentalUnit.name,
                current: tenant.current,
                group: tenant.current ? 'Aktuelle Mieter' : 'Vergangene Mieter'
              });
            }
          }else{
            tenants.push({
              ...tenant,
              street: propertySelected.propertyAddress,
              city: propertySelected.propertyCity,
              zipCode: propertySelected.propertyZipCode,
              value: tenant.id,
              label: tenant.name,
              isEmailEnabled: tenant.email.length > 0 ? true : false,
              isPostEnabled: (propertySelected.propertyAddress && propertySelected.propertyCity && propertySelected.propertyZipCode) ? true : false,
              isHiddenForTenantPortal: true
            });
            data.push({
              value: tenant.id,
              name: tenant.name,
              picture: tenant.vacant === true ? TenantLiteIcon : tenant.type == 'commercial' ? buildingIcon : tenant.type === 'self' ? SelfIcon : TenantSingleIcon,
              label: tenant.name,
              rentalUnit: rentalUnit.name,
              current: tenant.current,
              group: tenant.current ? 'Aktuelle Mieter' : 'Vergangene Mieter'
            });
          }
        }
      })
    );
    data = data.sort((a,b)=> a.name.toString().localeCompare(b.name.toString())).sort((a,b)=> a.group.toString().localeCompare(b.group.toString()));
    if(disableTenantsSelection === false){
      data.push({
        value: 'selectAll',
        label: 'Alle auswählen',
        name: 'Alle auswählen',
        rentalUnit: null,
        selectAllRecipients: ()=> this.selectAllRecipients()
      })
      data.push({
        value: 'selectAllCurrent',
        label: 'Alle aktuellen Mieter auswählen',
        name: 'Alle aktuellen Mieter auswählen',
        rentalUnit: null,
        selectAllRecipients: ()=> this.selectAllRecipients(true),
        group: 'Aktuelle Mieter'
      })
    }
    !disableExternalContactsAdd && data.push({
      value: 'addRecipient',
      label: 'Empfänger anlegen',
      name: <span style={{fontWeight: '500', color: 'black'}}>{'+ Neuen Empfänger erstellen'}</span>,
      rentalUnit: null,
      openAddRecipientModal: ()=> this.setState({openAddRecipientModal: true})
    })
    this.setState({selectedRecipients, data, tenants});
  }

  selectAllRecipients=(current=false)=>{
    const {propertySelected} = this.props;
    const {tenants} = this.state;
    let selectedRecipientsCopy = this.state.selectedRecipients;

    console.log('selectedRecipientsCopy: ', tenants.map((tenant)=> selectedRecipientsCopy.find((recipient)=> recipient.tenantId === tenant.id) === undefined));

    tenants.map((tenant)=> {
        if(selectedRecipientsCopy.find((recipient)=> recipient.tenantId === tenant.id) === undefined){
          
          if(current){
            if(tenant.current){
              console.log('testing1234: ', tenant);
              selectedRecipientsCopy.push({
                ...tenant,
                id: null,
                tenantId: tenant.id,
                isEmailEnabled: checkProperty(tenant, 'email', false) ? true : false,
                isPostEnabled: (propertySelected.propertyAddress && propertySelected.propertyCity && propertySelected.propertyZipCode) ? true : false,
                isHiddenForTenantPortal: true,
                value: tenant.id,
                name: tenant.name,
                //picture: tenant.vacant === true ? TenantLiteIcon : tenant.type == 'commercial' ? buildingIcon : tenant.type === 'self' ? SelfIcon : TenantSingleIcon,
                label: tenant.name,
              })
            }
          }else{
            selectedRecipientsCopy.push({
              ...tenant,
              id: null,
              tenantId: tenant.id,
              isEmailEnabled: checkProperty(tenant, 'email', false) ? true : false,
              isPostEnabled: (propertySelected.propertyAddress && propertySelected.propertyCity && propertySelected.propertyZipCode) ? true : false,
              isHiddenForTenantPortal: true,
              value: tenant.id,
              name: tenant.name,
              //picture: tenant.vacant === true ? TenantLiteIcon : tenant.type == 'commercial' ? buildingIcon : tenant.type === 'self' ? SelfIcon : TenantSingleIcon,
              label: tenant.name,
            })
          }
        }
      });
      this.postMessageRecipients(selectedRecipientsCopy.filter((recipient)=> recipient.id === null));
  }

  componentDidUpdate(prevProps, prevState){
    if(this.props.previouslySelectedRecipients.length > 0 && prevProps.previouslySelectedRecipients !== this.props.previouslySelectedRecipients){
      this.setState({selectedRecipients: this.props.previouslySelectedRecipients, recipientData: this.state.recipientData && this.props.previouslySelectedRecipients.find((recipient)=> this.state.recipientData.id === recipient.id)});
    }
  }

  switchChange=(recipientId, keyName)=>{
    let {selectedRecipients} = this.state;
    selectedRecipients.map((recipient)=> {
      if(recipient.id === recipientId){
        recipient[keyName]= !recipient[keyName];
        return recipient;
      }
      return recipient;
    });
    this.setState({selectedRecipients}, ()=> this.updateRecipient(selectedRecipients.find((recipient)=> recipient.id === recipientId)));
  }

  deleteMessageRecipient=(id)=>{
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.user_reg_api_baseurl + 'messageRecipients/'+ id,
      'DELETE',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleDeleteMessageRecipients',
      {id},
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  handleDeleteMessageRecipients(response){
    if(response.status && response.statuscode === 200){
      this.props.getMessageRecipientsByMessageId();
    }
  }

  postMessageRecipients=(recipientsData)=>{
    const {tenants} = this.state;
    const {messageId} = this.props;
    //if(allRecipients.length > 0){
    let payload=  recipientsData.map((recipientData)=> {
      return {
        messageId,
        tenantId: recipientData.tenantId ? recipientData.tenantId : (recipientData.id ? recipientData.id : null),
        //contactId,
        name: recipientData.name,
        email: recipientData.email,
        street: recipientData.street,
        zipCode: recipientData.zipCode,
        city: recipientData.city,
        isEmailEnabled: recipientData.isEmailEnabled,
        isPostEnabled: recipientData.isPostEnabled,
        isHiddenForTenantPortal: recipientData.isHiddenForTenantPortal
      };
    }) 
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.user_reg_api_baseurl + 'messageRecipients',
      'POST',
      payload,
      null,
      false,
      true,
      false,
      this,
      'handlePostMessageRecipients',
      payload,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
    //}
  }

  handlePostMessageRecipients(response){
    if(response.status && response.statuscode === 200){
      this.props.getMessageRecipientsByMessageId();
    }
  }

  updateRecipient=(recipientData)=>{
    let payload = recipientData;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.user_reg_api_baseurl + 'messageRecipients/'+ recipientData.id,
      'PUT',
      payload,
      null,
      false,
      true,
      false,
      this,
      'handlePutMessageRecipients',
      payload,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  handlePutMessageRecipients(response){
    if(response.status && response.statuscode === 200){
      //this.props.getMessageRecipientsByMessageId();
    }
  }



  tenantTableContent=(headers)=>{
    const { selectedRecipients, tenants } = this.state;
    const {rentalUnits, propertySelected, disableRecipientSelection, disableTenantsSelection, isMobile} = this.props;
    console.log('selectedRecipients_456_123: ', selectedRecipients);

    const renderEmailSwitch=(row)=> 
    <Group spacing={0} onClick={()=> checkProperty(row, 'email', false) === false && this.setState({editRecipient: true, openAddRecipientModal: true, recipientData: selectedRecipients.find((recipient)=> recipient.id === row.id)})}>
      <SwitchContainer
      checked={selectedRecipients.find((tenant)=> row.id === tenant.id)?.isEmailEnabled}
      switchText={isMobile && 
      <Group noWrap style={{margin: '7px 0px 0px 5px'}} spacing={5}>
        <EmailIcon 
        style={{
        color: '#5655FE',
        fontSize: '15px'
        }}/> 
        <span style={{whiteSpace: 'nowrap'}}>{'E-Mail'}</span>
      </Group>}
      disabled={!checkProperty(row, 'email', false)}
      onChange={()=> checkProperty(row, 'email', false) ? this.switchChange(row.id, 'isEmailEnabled') : this.setState({openAddRecipientModal: true})}
      />  
    </Group>;

    const renderPostalSwitch=(row)=> 
    <Group spacing={0} onClick={()=> (checkProperty(row, 'street', false) === false || checkProperty(row, 'city', false) === false || checkProperty(row, 'zipCode', false) === false) && this.setState({editRecipient: true, openAddRecipientModal: true, recipientData: selectedRecipients.find((recipient)=> recipient.id === row.id)})}>
      <SwitchContainer
      checked={selectedRecipients.find((tenant)=> row.id === tenant.id)?.isPostEnabled}
      switchText={isMobile && 
      <Group noWrap style={{margin: '7px 0px 0px 5px'}} spacing={5}>
        <img src={DeutschePostIcon} width='15px'/>
        <span style={{whiteSpace: 'nowrap'}}>{'Brief'}</span>
      </Group>}
      disabled={!(checkProperty(row, 'street', false) && checkProperty(row, 'city', false) && checkProperty(row, 'zipCode', false))}
      onChange={()=> this.switchChange(row.id, 'isPostEnabled')}
      />
    </Group>;

    const renderTenantPortalSwitch=(row)=> 
    <Group spacing={0}>
      <SwitchContainer
      checked={row.tenantId === null ? false : !selectedRecipients.find((tenant)=> row.id === tenant.id)?.isHiddenForTenantPortal}
      switchText={isMobile && 
      <Group noWrap style={{margin: '7px 0px 0px 5px'}} spacing={5}>
        <img src={TenantPortalIcon} width='15px'/>
        <span style={{whiteSpace: 'nowrap'}}>{'Mieterportal'}</span>
      </Group>}
      disabled={row.tenantId === null ? true : false}
      onChange={()=> this.switchChange(row.id, 'isHiddenForTenantPortal')}
      />
    </Group>;
    return (
    
    <TableContainer style={{maxHeight: isMobile && '300px'}}>
      <Table stickyHeader className='documents-list-table'>
        {!isMobile &&
        <TableHead>
          <TableRow>
            {headers.map((header)=>
            <TableCell className={`tooltips recipient-add-modal-table-header-${header.toLowerCase()}`} style={{paddingRight: header === 'Versand:' && 0}}>
              {header === 'E-Mail' ? 
              <Group noWrap spacing={5}>
                <EmailIcon 
                style={{
                color: '#5655FE',
                fontSize: '15px'
                }}/> 
                <span style={{whiteSpace: 'nowrap'}}>{header}</span>
              </Group>
              :
              header === 'Brief' ? 
              <Group noWrap spacing={5}>
                <img src={DeutschePostIcon} width='15px'/>
                <span style={{whiteSpace: 'nowrap'}}>{header}</span>
              </Group>
              :
              disableTenantsSelection === false ?
                header === 'Mieterportal' ?
                <Group noWrap spacing={5}>
                  <img src={TenantPortalIcon} width='15px'/>
                  <span style={{whiteSpace: 'nowrap'}}>{header}</span>
                </Group>
                :
                header
              :
              null
              }  
            </TableCell>)}
          </TableRow>
        </TableHead>}
        <TableBody style={{borderCollapse: 'separate', borderSpacing: '8px'}}>
          {selectedRecipients.length > 0 &&
          selectedRecipients
          //.filter((row)=> row.vacant === false //&& selectedRecipients.find((tenant)=> tenant.id === row.id) !== undefined
          //)
          .sort((a,b)=> a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
          .map((row)=>
          <TableRow style={{background: '#F7F7FA'}}>
            <TableCell>
              <Stack>
                <Group spacing={10} noWrap>
                  <Group spacing={10} noWrap style={{cursor: 'pointer'}} onClick={()=> this.setState({editRecipient: true, openAddRecipientModal: true, recipientData: selectedRecipients.find((recipient)=> recipient.id === row.id)})}>
                    <img
                      src={row.tenantId ? (row.vacant === true ? TenantLiteIcon : row.type == 'commercial' ? buildingIcon : row.type === 'self' ? SelfIcon : TenantSingleIcon) : TenantSingleIcon}
                      alt="data-icon"
                      style={{width: row.type === 'self' && 'auto' }}
                    />
                    <span>{row.name}{row.tenantId && <span>{row.current && ' (aktuell)'}</span>}</span>
                  </Group>
                  {/* <span 
                  onClick={()=> this.setState({editRecipient: true, openAddRecipientModal: true, recipientData: row})} 
                  style={{fontSize: '12px', fontWeight: '400', textDecoration: 'underline', color: '#5555FF', cursor: 'pointer'}}
                  >
                    Bearbeiten
                  </span> */}
                  <Group style={{background: '#5555FF', width: '20px', height: '20px', borderRadius: '200px', cursor: 'pointer'}} align={'center'} position='center'>
                    <img 
                    src={PenIcon} 
                    alt='Bearbeiten' 
                    style={{width: '10px'}}
                    onClick={()=> this.setState({editRecipient: true, openAddRecipientModal: true, recipientData: selectedRecipients.find((recipient)=> recipient.id === row.id)})} 
                    />
                  </Group>
                </Group>
                {isMobile &&
                <Stack>
                  <Group noWrap>
                    {renderEmailSwitch(row)}
                  </Group>
                  <Group noWrap>
                    {renderPostalSwitch(row)}
                  </Group>
                  <Group noWrap>
                    {renderTenantPortalSwitch(row)}
                  </Group>
                </Stack>}
              </Stack>
              
            </TableCell>
            {!isMobile && <TableCell width={'1%'}/>}
            {!isMobile &&
            <TableCell width={'15%'}>
              {renderEmailSwitch(row)}
            </TableCell>}
            {!isMobile && <TableCell width={'15%'}>
              {renderPostalSwitch(row)}
            </TableCell>}
            {!isMobile && disableTenantsSelection === false &&
            <TableCell width={'15%'}>
              {renderTenantPortalSwitch(row)}
            </TableCell>}
            <TableCell width={'5%'}>
              {disableRecipientSelection === false &&
              <button 
              onClick={()=> {
                let {selectedRecipients} = this.state;
                this.setState({selectedRecipients: selectedRecipients.filter((tenant)=> tenant.id !== row.id)}, ()=> this.deleteMessageRecipient(row.id));
              }} 
              type="button" class="close delete-cost-position" aria-label="Close"><span aria-hidden="true">×</span></button>}
            </TableCell>
          </TableRow>)}
        </TableBody>
      </Table>
    </TableContainer>)
  }

  render() {
    const { selectedRecipients, openAddRecipientModal, dropdownIcon, data, tenants, editRecipient, recipientData, initiallyOpened, dropdownVisible } = this.state;
    const { open, toggle, className, active, rentalUnits, propertySelected, setSelectedRecipients, isMobile, disableRecipientSelection } = this.props;
    console.log('rentalUnits: ', rentalUnits);
    console.log('selectedRecipients: ', selectedRecipients);
    console.log('tenants: ', tenants);
    console.log('recipientData: ', recipientData);
    return (
      <>
        <CustomModal
          heading={'Empfänger bearbeiten'}
          headingClassName='create-folder-modal-heading'
          open={open}
          toggle={() => {
            setSelectedRecipients(selectedRecipients);
            toggle();
          }}
          className={`${className}`}
          style={{minWidth: !isMobile && '900px'}}
        >
          <Stack style={{width: '100%', height: selectedRecipients.length > 4 ? 'auto' : '400px'}}>
            {/* <StepperForTenants 
            rentalUnits={rentalUnits}/> */}
            {/* <Accordion
            classNames={{
              control: 'ub-sending-select-tenant-modal-accordion-control',
              content: 'ub-sending-select-tenant-modal-accordion-content',
              icon: 'ub-sending-select-tenant-modal-accordion-icon',
              contentInner: 'ub-sending-select-tenant-modal-accordion-contentInner'
            }}
            initialItem={0}
            >
              {rentalUnits.map((rentalUnit)=>
              <Accordion.Item
              label={<Group style={{cursor: 'pointer', backgroundColor: 'white', padding: '10px', borderRadius: '4px'}}>
                <span style={{fontWeight: '500', fontSize: '14px', fontFamily: 'Inter, sans-sarif'}}>{rentalUnit.name}</span>
              </Group>}>
                  {this.tenantTableContent(['Name', 'über E-Mail', 'über Deutsche Post'], rentalUnit.tenantsData)}
              </Accordion.Item>)}
            </Accordion> */}
            <Group>
              {dropdownVisible &&
              <Select
              data={selectedRecipients.filter((recipient)=> data.find((dt)=> dt.value === recipient.tenantId) !== undefined).length === data.filter((dt)=> dt.value !== 'addRecipient' && dt.value !== 'selectAll').length ? data.filter((dt)=> dt.value === 'addRecipient') : data.filter((dt)=> selectedRecipients.find((tenant)=> tenant.tenantId === dt.value) === undefined)}
              className={'multi-select-messages-tenants'}
              id={'multi-select-messages-tenants'}
              classNames={{
                input: 'recipients-selection-input'
              }}
              
              //limit={20}
              ref={this.myRef}
              //valueComponent={Value}
              valueComponent={()=> null}
              itemComponent={Item}
              searchable
              onSearchChange={(e)=> console.log('onSearchChange: ', e)}
              value={null}
              placeholder="Suche nach Empfängern"
              creatable={true}
              getCreateLabel={(query) => `+ Erstellen ${query}`}
              onCreate={(query) => this.setState({openAddRecipientModal: true})}
              //onClick={()=> this.setState({openRecipientsModal: true})} 
              icon={<img src={SearchIcon}/>}
              rightSection={<img src={dropdownIcon}/>}
              onDropdownOpen={()=> this.setState({dropdownIcon: Up})}
              onDropdownClose={()=> this.setState({dropdownIcon: Down})}
              style={{width: '100%'}}
              initiallyOpened={initiallyOpened}
              styles={{
                dropdown: {
                  display: 'block'
                },
                item: {
                  color: '#0e0e1d',
                  fontSize: '14px',
                  fontWeight: '500',
                  fontFamily: 'sans-serif'
                }
              }}
              onChange={(value)=> {
                console.log('onChange: ', value);
                if(value === 'addRecipient'){
                  this.setState({openAddRecipientModal: true});
                } else if(value === 'selectAll'){
                  this.selectAllRecipients();
                } else if(value === 'selectAllCurrent'){
                  this.selectAllRecipients(true);
                }
                else {
                  let {selectedRecipients} = this.state;
                  let ten = tenants.find((tenant)=> tenant.id === value);
                  if(ten !== undefined){
                    selectedRecipients.push({
                      ...ten,
                      id: value,
                      isEmailEnabled: checkProperty(ten, 'email', false) ? true : false,
                      isPostEnabled: ((ten.street && ten.street.length > 0) && (ten.city && ten.city.length > 0) && (ten.zipCode && ten.zipCode.length > 0)) ? true : false,
                      isHiddenForTenantPortal: true,
                      value,
                      name: ten ? ten.name : '',
                      picture: ten ? ten.vacant === true ? TenantLiteIcon : ten.type == 'commercial' ? buildingIcon : ten.type === 'self' ? SelfIcon : TenantSingleIcon : TenantSingleIcon,
                      label: ten ? ten.name : '',
                      rentalUnit: rentalUnits.find((rentalUnit)=> rentalUnit.tenantsData.find((tenant)=> tenant.id === value) !== undefined).name
                    });
                    this.setState({selectedRecipients}, ()=> this.postMessageRecipients([ten]));
                  }else{
                    this.setState({openAddRecipientModal: true, recipientData: {name: value}});
                  }
                }
              }}
              {...this.props} 
              zIndex={9999999}
              disabled={disableRecipientSelection}
              />}
            </Group>
            <Stack style={{height: 'inherit'}} justify={'space-between'}>
              {selectedRecipients.length > 0 ? 
              <Group>
                {this.tenantTableContent(!isMobile ? ['Name', 'Versand:', 'E-Mail', 'Brief', 'Mieterportal'] : ['Name'])}
              </Group>
              :
              <EmptyStateContainer
              heading={'Keine Empfänger ausgewählt.'}
              subheading={'Bitte wählen Sie Empfänger aus, die die Nachricht erhalten sollen.'}
              showBtn={true}
              icon={TenantSingleIconBlue}
              styleMainContainer={{marginTop: '25px'}}
              btnText={'Empfänger wählen'}
              btnHandler={()=> {
                this.setState({dropdownVisible: false, initiallyOpened: false},()=> this.setState({dropdownVisible: true, initiallyOpened: true}))
              }}
              />}
              <Group position='right'>
                <div>
                  <ColorButton
                  text={'Schließen'}
                  className="gray-btn"
                  onClick={()=> {
                    setSelectedRecipients(selectedRecipients);
                    toggle();
                  }}
                  />
                </div>
                {/* <div>
                  <ColorButton
                  text={'Weiter'}
                  className="purple-btn"
                  onClick={()=> toggle()}
                  />
                </div> */}
              </Group>
            </Stack>
          </Stack>
          {openAddRecipientModal &&
          <AddRecipientModal
          open={openAddRecipientModal}
          toggle={()=> this.setState({openAddRecipientModal: false, editRecipient: false, recipientData: null})}
          getMessageRecipientsByMessageId={()=> this.props.getMessageRecipientsByMessageId()}
          edit={editRecipient}
          recipientData={recipientData}
          messageId={this.props.messageId}
          saveInformation={(data)=> 
            this.setState({
              //selectedRecipients: selectedRecipients.map((recipient)=> recipient.id === data.id ? {...recipient, email: data.email, phone: data.phone, name: data.name, street: data.street, city: data.city, zipCode: data.zipCode} : recipient), 
              openAddRecipientModal: false, 
              editRecipient: false, 
              recipientData: null
            })}
          />}
        </CustomModal>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    propertySelected: state.generalReducer.property,
    rentalUnits: state.tenantDataReducer.rentalUnits,
    currentDataId: state.UtilityBillingReducer.currentBillingId,
    utilityBillingData: state.UtilityBillingReducer.utilityBillingData,
  };
};

export default connect(mapStateToProps)(AddMessagingRecipientsModal);
