import React, { Component } from 'react'
import CustomModal from './index';
import {ColorButton} from '../../../../index';
import Autoload from "../../../../Autoload";
import { Group, Stack } from '@mantine/core';
import { connect } from 'react-redux';
import visa from '../../../../../assets/images/module/general/visa.svg';
import mastercard from '../../../../../assets/images/module/general/mastercard.svg';
import PremiumBadgeIcon from '../../../../../assets/images/module/general/PremiumBadgeIcon.svg';
import ProfessionalBadgeIcon from '../../../../../assets/images/module/general/ProfessionalBadgeIcon.svg';
import { formatNumber } from '../../../../../helpers/stringsManipulations';
import moment from 'moment';
import PaymentTemplate from '../../../../../views/pages/Module/General/profile/PaymentTemplate';
import PaymentTemplateForModal from '../../../../../views/pages/Module/General/profile/PaymentTemplateForModal';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];

class PaymentTemplateModal extends Component {
    state={
        holderName: '',
        holderCardNo: '',
        type: null,
    }

    componentDidMount(){
     this.getAccountDetails();
    }

    getAccountDetails() {
        const userAccountID = this.props.profile.accountId;
    
        Autoload.HttpRequestAxios.HttpRequest(
          // Autoload.Setting.user_api_baseurl + 'b13c75a4-ddaa-4102-c080-08d92bf06bb3',
          Autoload.Setting.account_api_baseurl + userAccountID,
          'GET',
          null,
          null,
          false,
          true,
          false,
          this,
          'handle_get_user_Data',
          null,
          false,
          '',
          {
            'Content-Type': 'application/json',
            Authorization: Autoload.Utils.getAuthToken(),
          }
        );
      }
      handle_get_user_Data(response) {
        console.log('response of handle_get_user_Data', response);
        if(response.status && response.statuscode === 200){
          this.setState({type: response.result.accountPaymentStatus ? 'reAuthorize' : 'authorize'});
        }
      }
    render(){
        const {open, toggle, className, type, route=null, subscribtionSelected=null, subscribtionSelectedName=null} = this.props;
        return (
            <CustomModal 
            heading= {'Auswahl Zahlungsmittel'}  
            open={open} 
            toggle={toggle} 
            className={`${className}`}>
                <PaymentTemplateForModal
                type={this.state.type}
                toggle={()=> toggle()}
                route={route}
                subscribtionSelected={subscribtionSelected}
                subscribtionSelectedName={subscribtionSelectedName}
                />
            </CustomModal>
         )
    }
}
const mapStateToProps = (state) => {
    return {
        propertySelected: state.generalReducer.property,
        profile: state.generalReducer.profile,
        AccountApi: state.generalReducer.account_api_id,
    };
};

export default connect(mapStateToProps)(PaymentTemplateModal);
