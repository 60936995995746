import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import {
  ColorButton,
  UtilityBillsTableRow,
  MobileUtilityCostAllocation,
  EmptyStateContainer,
  HeadingContainer,
  Divider,
  PageHeader,
  SwitchContainer,
  RecordOptions,
} from '../../../../neoverv';
import PrintIcon from '../../../../assets/images/module/general/PrintIcon.svg';
import downArrow from '../../../../assets/images/module/general/SelectArrowDown.svg';
import uparrow from '../../../../assets/images/module/general/SelectArrowUp.svg';
import tap from '../../../../assets/images/module/general/TapIconDark.svg';
import { setTenantBills, setUtilityBillingData } from '../../../../redux/action/UtilityBilling/UtilityBillingActions';
import Autoload from '../../../../neoverv/Autoload';
import CrossIcon from '../../../../assets/images/module/general/CrossIcon.svg';
import LockIcon from '../../../../assets/images/module/general/LockIconRed.svg';
import LockIconBlue from '../../../../assets/images/module/general/LockIconBlue.svg';
import PrintIconBlue from '../../../../assets/images/module/general/PrintIconBlue.svg';
import LockOpenIcon from '../../../../assets/images/module/general/LockOpenIcon.svg';
import LockOpenIconBlue from '../../../../assets/images/module/general/LockOpenIconBlue.svg';
import LockOpenIconRed from '../../../../assets/images/module/general/LockOpenIconRed.svg';
import GoldenLockIcon from '../../../../assets/images/module/general/GoldenLockIcon.svg';
import DotsIcon from '../../../../assets/images/module/general/DotsIcon.svg';
import { OverlayTrigger } from 'react-bootstrap';
import {Tooltip as TooltipRb} from 'react-bootstrap';
import AlertModal from '../../../../neoverv/components/Module/General/CustomModal/AlertModal';
import {
  Table, 
  TableBody, 
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@material-ui/core';
import dayjs from 'dayjs';
import moment from 'moment';
import Logo from '../../../../assets/images/module/general/Logo.svg';
import html2canvas from 'html2canvas';
import jsPdf from 'jspdf';
import { Checkbox, Group, Menu, Popover, Skeleton, Stack, Text } from '@mantine/core';
import {
  Tooltip, 
  IconButton, 
  Popper, 
  FormControl, 
  Select, 
  InputLabel, 
  MenuItem,
  Drawer
} from '@material-ui/core';
import { formatNumber } from '../../../../helpers/stringsManipulations';
import UtilityBillTable from './UtilityBillTable';
import CompositionOfHeatingCostsTable from './CompositionOfHeatingCostsTable';
import CostSplitWarmWaterAndHeatingTable from './CostSplitWarmWaterAndHeatingTable';
import SplitBaseConsumptionCosts from './SplitBaseConsumptionCosts';
import MeterDataHandler from '../../../../neoverv/helpers/DataHandler/Meter';
import { NumericFormat } from 'react-number-format';
import HeadingContainerNew from '../../../../neoverv/components/Module/General/HeadingContainerNew';
import breadcrumbsContent from '../../../../assets/breadcrumbs.json';
import HelpLogo from '../../../../assets/images/module/general/book.svg';
import TourIcon from '../../../../assets/images/module/general/TourIcon.svg';
import MeterReadingsTable from './MeterReadingsTable';
import UtilityBillingDataHandler from '../../../../neoverv/helpers/DataHandler/UtilityBillings';
import EnergyConsumptionAndCO2EmissionsCostTable from './EnergyConsumptionAndCO2EmissionsCostTable';
import CompareHeatingConsumptionBarChart from './CompareHeatingConsumptionBarChart';
import BenchmarkingAverageUserBuilding from './BenchmarkingAverageUserBuilding';
import CalculationOfCO2TaxTable from './CalculationOfCO2TaxTable';
import TableChartIcon from '@material-ui/icons/TableChart';
import ShowControlSheetForBillingStatementModal from '../../../../neoverv/components/Module/General/CustomModal/ShowControlSheetForBillingStatementModal';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
const { yearDifferenceMoment, checkProperty, loader } = Autoload.MainHelper;
const MOBILE_SIZE = 766;
const TABLET_SIZE = 1400;
//let allBills = [];
let interval = null;
const ref = React.createRef();

export class UtilityBillsTable extends Component {
  static propTypes = {
    prop: PropTypes,
  };

  
  tableHeadCellStyling = {
    color: '#727293',
    padding: '0 9px 0 9px',
    fontWeight: '500',
    whiteSpace: 'nowrap',
    margin: '0px',
    fontSize: '14px',
    lineHeight: '20px',
    border: '1px solid #DADAE6',
    textTransform: 'capitalize',
    height: '48px',
  }
  tableBodyCellStyling = {
    color: '#0e0e1d',
    padding: '5px 9px 5px 9px',
    fontWeight: '400',
    whiteSpace: 'nowrap',
    fontSize: '14px',
    lineHeight: '20px',
    border: '1px solid #DADAE6',
    textTransform: 'capitalize',
    height: '48px',
    minWidth: '90px',
    // borderBottom: 'none',
    // borderRight: 'none'
  }

  state = {
    selectedTenantID: '1',
    DropdownState: 'true',
    isMobile: window.innerWidth <= MOBILE_SIZE,
    showEmpty: true,
    utilityData: {
      date: '',
      recordStatus: 'last',
      isSecured: false
    },
    utilityBillTableData: null,
    selectedTenantData: null,
    data: null,
    currentUtilityBillingData: null,
    open: false,
    dropdownOpen: false,
    freezeAlertHeading: '',
    freezeAlertMessage: '',
    isTablet: window.innerWidth <= TABLET_SIZE,
    skeletonLoading: false,
    warmWaterCostTypeFound: false,
    heatingCosts:[],
    heatingCostsSwitch: false,
    costSplitWarmWaterAndHeatingSwitch: false,
    splitBaseConsumptionCostsSwitch: false,
    readingsSwitch: false,
    readings: null,
    vatSwitch: false,
    laborCostSwitch: false,
    hoverOnStarter: false,
    hoverOnStarter2: false,
    utilityBillingData: null,
    allBills: [],
    numberBillingStatementTableRows: 0,
    numberMeterReadingsTableRows: 0,
    energyConsumptionCO2EmissionsSwitch: false,
    compareHeatingConsumptionBarChartSwitch: false,
    energyLevelsInfoSwitch: false,
    energyAdviceCenterInfoSwitch: false,
    arbitraryBoardInfoSwitch: false,
    co2TaxInfoSwitch: false,
    hideTenantZeroCostsSwitch: false,
    openShowControlSheetForBillingStatementModal: false,
    openTaxContextMenu: false,
    isPreTaxInfoHidden: false
  };

  constructor(props){
    super(props);
    this.UtilityDataHandler = new UtilityBillingDataHandler();
  }

  componentDidMount() {
    this.componentDidMountStuff();
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount(){
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener("scroll", this.handleScroll);
  }

  componentDidMountStuff=(runEndPoints=true)=>{
    let data = this.props.utilityBillingData.find((ub) => ub.id == this.props.currentDataId);
    console.log('componentDidMount: ', data?.tableData[0], this.props.tenantBills);
    if (this.props.tenantBills.length) {
      const selectedTenantData = data?.tableData[0];
      
      this.setState({ 
        selectedTenantData, 
        currentUtilityBillingData: data
      });
    }
    console.log('+++++++++++++++++++++++++++++++++++++++++++++++', this.props.utilityBillingData);

    // console.log('data CDM', data);
    // let diff = yearDifferenceMoment(data.date.split('-')[0])
    if (data !== undefined) {
      this.setState(
        {
          utilityData: {
            date: data && data?.date ? data?.date : '',
            recordStatus: data && data?.recordStatus ? data?.recordStatus : 'last',
            isSecured: data && data?.isSecured ? data?.isSecured : false,
            data: data
          }
        },
        () => {
          if(runEndPoints){
            //this.updateTenantOccupations();
            if(data?.isSecured === false){
              this.updateConsumptions();
            }else{
              this.callApis();
            }
          }
        }
      );
    }
  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps.utilityBillingData.length === 0 && this.props.utilityBillingData.length > 0){
      this.componentDidMountStuff();
    }
    
    let billingStatementTableRows = document.getElementById("ub-billing-statement-table") ? document.getElementById("ub-billing-statement-table").rows.length : 0;
    let meterReadingsTableRows = document.getElementById("ub-meter-readings-table") ? document.getElementById("ub-meter-readings-table").rows.length : 0;

    if(prevState.numberBillingStatementTableRows !== billingStatementTableRows){
      this.setState({numberBillingStatementTableRows: billingStatementTableRows});
    }

    if(prevState.numberMeterReadingsTableRows !== meterReadingsTableRows){
      this.setState({numberMeterReadingsTableRows: meterReadingsTableRows});
    }

    // if(prevState.readingsSwitch !== this.state.readingsSwitch || 
    //   prevState.splitBaseConsumptionCostsSwitch !== this.state.splitBaseConsumptionCostsSwitch ||
    //   prevState.costSplitWarmWaterAndHeatingSwitch !== this.state.costSplitWarmWaterAndHeatingSwitch ||
    //   prevState.heatingCostsSwitch !== this.state.heatingCostsSwitch ||
    //   prevState.energyConsumptionCO2EmissionsSwitch !== this.state.energyConsumptionCO2EmissionsSwitch || 
    //   prevState.compareHeatingConsumptionBarChartSwitch !== this.state.compareHeatingConsumptionBarChartSwitch){
    //     if(this.state.utilityData && this.state.utilityData.isSecured === false){
    //       this.updateUtilityBilling();
    //     }
    // }
  }

  getUtilityBilling= ()=>{
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.ub_api_base_url + 'utilityBillings/' + this.props.currentDataId,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleGetUtilityResponse',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  handleGetUtilityResponse(response){
    console.log('handleGetUtilityResponse: ', response.result);
    if(response.status && response.statuscode === 200){
      this.setState({
        utilityBillingData: response.result,
        vatSwitch: !response.result.isVATHidden,
        laborCostSwitch: !response.result.isLaborCostHidden,
        readingsSwitch: !response.result.isMeterReadingAndConsumptionInfoHidden,
        heatingCostsSwitch: !response.result.isHeatingCostInfoHidden,
        splitBaseConsumptionCostsSwitch: !response.result.isSplitBaseConsumptionCostInfoHidden,
        costSplitWarmWaterAndHeatingSwitch: !response.result.isCostSplitWarmWaterAndHeatingCostInfoHidden,
        energyConsumptionCO2EmissionsSwitch: !response.result.isEnergyConsumptionCO2EmissionsInfoHidden,
        compareHeatingConsumptionBarChartSwitch: !response.result.isPreviousPeriodEnergyComparisonInfoHidden,
        energyLevelsInfoSwitch: !response.result.isEnergyLevelsInfoHidden,
        energyAdviceCenterInfoSwitch: !response.result.isEnergyAdviceCenterInfoHidden,
        arbitraryBoardInfoSwitch: !response.result.isArbitraryBoardInfoHidden,
        co2TaxInfoSwitch: (dayjs(response.result.billingPeriod.endDate).year() >= 2023) ? (response.result.isCO2TaxActivated ? !response.result.isCO2TaxInfoHidden : false) : false,
        isPreTaxInfoHidden: response.result.isPreTaxInfoHidden
      }, ()=> {
        if(this.props.currentSubscription.subscriptionName === 'Starter' && this.state.utilityData.isSecured === false && response.result.isVATHidden === false){
          this.setState({vatSwitch: false}, ()=> this.updateUtilityBilling());
        }
        if(response.result.isCostSplitWarmWaterAndHeatingCostInfoHidden === false && this.props.propertySelected.heatingType === 'central without warm water'){
          this.setState({costSplitWarmWaterAndHeatingSwitch: false}, ()=> setTimeout(() => {
            this.updateUtilityBilling()
          }, 1000))
        }
      });

      let {utilityBillingData} = this.props;
      let newUtilityBillingData = utilityBillingData.map((ub)=> {
        if(ub.id === response.result.id){
          return {...ub, ...response.result//householdEnergyEfficiency: response.result.householdEnergyEfficiency, householdEnergyEfficiencyLevel: response.result.householdEnergyEfficiencyLevel
          };
        }else{
          return ub;
        }
      });
      console.log('newUtilityBillingData: ', newUtilityBillingData);
      this.props.setUtilityBillingData(newUtilityBillingData);
      this.componentDidMountStuff(false);
    }
  }

  updateConsumptions = () => {
    console.log('+++++++++++++++++++++++++++++++++++++++++++++++');
    this.setState({skeletonLoading: true});
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.ub_api_base_url + 'updateConsumptions/' + this.props.currentDataId,
      'PUT',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleUpdateConsumptionsResponse',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };
  handleUpdateConsumptionsResponse = (response) => {
    !response.status && console.log('Update Consumptions Failed');

    this.setState({skeletonLoading: false});//loader(false);
    this.callApis();
  };

  updateTenantOccupations = () => {
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.ub_api_base_url + 'updateTenantOccupations/' + this.props.currentDataId,
      'PUT',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleUpdateTenantOccupationsResponse',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };
  handleUpdateTenantOccupationsResponse = (response) => {
    !response.status && console.log('Update Tenant Occupations Failed');
  };

  callApis = (tenantId=null) => {
    this.setState({
      skeletonLoading: true, 
      //costSplitWarmWaterAndHeatingSwitch: this.props.heatingFilter && true, splitBaseConsumptionCostsSwitch: this.props.heatingFilter && true, energyConsumptionCO2EmissionsSwitch: this.props.heatingFilter && true, compareHeatingConsumptionBarChartSwitch: this.props.heatingFilter && true, 
      allBills: []}); //loader(true);
    this.getUtilityBilling();
    this.getHeatingCosts();
    //allBills = [];
    //get all billing statements
    Autoload.HttpRequestAxios.HttpRequest(
      // Autoload.Setting.swagger_mocked_api_baseUrl +
      //   'billingStatements?offset=0&results=10&utilityBillingId=' +
      //   this.props.currentDataId,
      Autoload.Setting.ub_api_base_url + 'billingStatements?utilityBillingId=' + this.props.currentDataId,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      '_handleGetBS',
      {tenantId},
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };
  _handleGetBS = (response) => {
    console.log('response1 Billing Statement', response);
    if (response.status === false && response.message !== 'Not Found') {
      // Autoload.Redirection.redirection_error();
      Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang.newKeys.text_error_not_found);
    }
    if (response.result.length > 0) {
      const tenants = this.UtilityDataHandler.currentBillingStatementsDataHandler(response.result);
      let otherTenants = []
      response.result.map((bs) => {
        console.log(bs);
        let otherData = {
          tenantID: checkProperty(bs, 'tenantId'),
          tenantName: checkProperty(bs, 'tenantName'),
          stayedFor: checkProperty(checkProperty(bs, 'tenantOccupation'), 'totalDays'),
          totalAllocations: checkProperty(bs, 'totalAllocatedUtilityCosts'),
          prepayments: checkProperty(bs, 'totalAdvancePayments'),
          billingStatementId: checkProperty(bs, 'id'),
          utilityBillingId: checkProperty(bs, 'utilityBillingId'),
          rentalUnitId: checkProperty(bs, 'rentalUnitId'),
          rentalUnitName: checkProperty(bs, 'rentalUnitName'),
          tenantOccupation: checkProperty(bs, 'tenantOccupation'),
          totalVATAmount: checkProperty(bs, 'totalVATAmount'),
          totalLaborCost: checkProperty(bs, 'totalLaborCost'),
          totalAllocatedHeatingCosts: checkProperty(bs, 'totalAllocatedHeatingCosts')
        }
        otherTenants.push(otherData);
      });

      this.setState({
        allTenants: otherTenants,
        selectedTenantData: response.other.tenantId ? response.result.find((bs)=> bs.tenantId === response.other.tenantId) : response.result[0],
        currentUtilityBillingData: {...this.state.currentUtilityBillingData, tableData: tenants}
      }, ()=> {
        let {utilityBillingData, setUtilityBillingData} = this.props;
        let currentUB = utilityBillingData.find((ub)=> ub?.id && ub.id === this.state.currentUtilityBillingData.id);
        currentUB.tableData = tenants;
        utilityBillingData= utilityBillingData.map((ub)=> {
          if(ub?.id && ub.id === currentUB.id){
            return currentUB;
          }else{
            return ub;
          }
        });
        setUtilityBillingData(utilityBillingData);
        this.getBillingStatements(this.state.selectedTenantData)
      });
      console.log('stateeeee', this.state);
    } else {
      //store empty array to tenantBills field of redux
      this.props.setTenantBills(this.state.allBills);
      this.setState({ showEmpty: false });
      this.setState({skeletonLoading: false}); //loader(false);
    }
  };

  getBillingStatements = (selectedTenantData) => {
    console.log('getBillingStatements: ', selectedTenantData);
    this.setState({skeletonLoading: true}); //loader(true);
    this._getOccupationReadingTenants(selectedTenantData.tenantId);
    const {allTenants} = this.state;
    const billingId = checkProperty(selectedTenantData, 'billingStatementId')
          ? checkProperty(selectedTenantData, 'billingStatementId')
          : checkProperty(selectedTenantData, 'id');
    let data = {
      tenantID: checkProperty(selectedTenantData, 'tenantId'),
      tenantName: checkProperty(selectedTenantData, 'tenantName'),
      stayedFor: checkProperty(checkProperty(selectedTenantData, 'tenantOccupation'), 'totalDays'),
      totalAllocations: checkProperty(selectedTenantData, 'totalAllocatedUtilityCosts'),
      prepayments: checkProperty(selectedTenantData, 'totalAdvancePayments'),
      billingStatementId: checkProperty(selectedTenantData, 'id'),
      utilityBillingId: checkProperty(selectedTenantData, 'utilityBillingId'),
      rentalUnitId: checkProperty(selectedTenantData, 'rentalUnitId'),
      rentalUnitName: checkProperty(selectedTenantData, 'rentalUnitName'),
      tenantOccupation: checkProperty(selectedTenantData, 'tenantOccupation'),
      totalVATAmount: checkProperty(selectedTenantData, 'totalVATAmount'),
      totalLaborCost: checkProperty(selectedTenantData, 'totalLaborCost')
    };
    
    
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.ub_api_base_url + 'billingStatements/' + billingId,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      '_handleGetBSpdf',
      { BSData: allTenants,
      selectedData: data },
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }
  _handleGetBSpdf = (response) => {
    console.log('response1x', response);
    if (response.status === false && response.message !== 'Not Found') {
      // Autoload.Redirection.redirection_error();
      Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang.newKeys.text_error_not_found);
    }
    //call apis for tenantRows
    console.log(this.props.heatingFilter, 'Heating Filter');
    this.props.heatingFilter
      ? Autoload.HttpRequestAxios.HttpRequest(
        // Autoload.Setting.swagger_mocked_api_baseUrl +
        //   'costAllocations/forBillingStatement?utilityBillingId=' +
        //   response.other.BSData.utilityBillingId +
        //   '&billingStatementId=' +
        //   response.other.BSData.billingStatementId,
        Autoload.Setting.ub_api_base_url +
        'costAllocations/forBillingStatement?utilityBillingId=' +
        response.other.selectedData.utilityBillingId +
        '&billingStatementId=' +
        response.other.selectedData.billingStatementId,
        'GET',
        null,
        null,
        false,
        true,
        false,
        this,
        '_handleGetBSRows',
        { data: { ...response.other.selectedData, ...response.result, fileURL: response.status ? response.result : '' } }, //...response.other.BSData,
        false,
        '',
        {
          Authorization: Autoload.Utils.getAuthToken(),
        }
      )
      : Autoload.HttpRequestAxios.HttpRequest(
        // Autoload.Setting.swagger_mocked_api_baseUrl +
        //   'costAllocations/forBillingStatement?utilityBillingId=' +
        //   response.other.BSData.utilityBillingId +
        //   '&billingStatementId=' +
        //   response.other.BSData.billingStatementId,
        Autoload.Setting.ub_api_base_url +
        'costAllocations/forBillingStatement?utilityBillingId=' +
        response.other.selectedData.utilityBillingId +
        '&billingStatementId=' +
        response.other.selectedData.billingStatementId +
        '&filter=non-heating',
        'GET',
        null,
        null,
        false,
        true,
        false,
        this,
        '_handleGetBSRows',
        { data: { ...response.other.selectedData, ...response.result, fileURL: response.status ? response.result : '' } }, //...response.other.BSData,
        false,
        '',
        {
          Authorization: Autoload.Utils.getAuthToken(),
        }
      );
  };
  _handleGetBSRows = (response) => {
    // console.log('response2 forBillingStatement', response);
    let formattedResponse = [];
    let mergedData = {};
    if (response.status === false && response.message !== 'Not Found') {
      // Autoload.Redirection.redirection_error();
      Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang.newKeys.text_error_not_found);
    }

    if (response.result.length > 0) {
      console.log('formattedResponse', formattedResponse, response.other.data);
      formattedResponse = response.result.map((tr) => ({
        // id: checkProperty(tr, 'id'),
        id: checkProperty(tr, 'id'),
        costType: checkProperty(tr, 'costTypeName'),
        totalCost: checkProperty(tr, 'totalCostShare'),
        allocationKey: checkProperty(tr, 'allocationTypeName'),
        totalValue: checkProperty(tr, 'totalConsumption'),
        tenantValue: checkProperty(tr, 'tenantConsumption'),
        unit: checkProperty(tr, 'consumptionUnits'),
        tenantCostShare: checkProperty(tr, 'tenantCostShare'),
        comment: checkProperty(tr, 'comment'),
        utilityBillingId: checkProperty(tr, 'utilityBillingId'),
        costPositionId: checkProperty(tr, 'costPositionId'),
        billingStatementId: checkProperty(tr, 'billingStatementId'),
        tenantName: checkProperty(tr, 'tenantName'),
        rentalUnitId: checkProperty(tr, 'rentalUnitId'),
        rentalUnitName: checkProperty(tr, 'rentalUnitName'),
        tenantOccupation: checkProperty(tr, 'tenantOccupation'),
        billingStatementId: checkProperty(tr, 'billingStatementId'),
        orderNumber: checkProperty(tr, 'orderNumber'),
      }));
      let warmWaterCostTypeFound = formattedResponse.find((row)=> row.costType === 'Warmwasser (Verbrauch)') !== undefined ? true : false;
      mergedData = {
        ...response.other.data,
        tenantRows: formattedResponse,
      };
      //merge data in array
      let {allBills} = this.state;
      allBills.push(mergedData);
      const data = allBills.find((ub) => ub.utilityBillingId == this.props.currentDataId);
      console.log('allBills', allBills, mergedData, this.props.currentDataId, data);
      this.props.setTenantBills(allBills);
      this.getAllData();

      this.setState({skeletonLoading: true}); //loader(true);
      this.setState(
        {
          showEmpty: data ? false : true,

          utilityBillTableData: data,
        },
        () => this.setState({skeletonLoading: false, warmWaterCostTypeFound}) //loader(false)
      );
    } else this.setState({skeletonLoading: false}) //loader(false);
  };

  saveCurrentData = () => {
    //call get api for data
    // Autoload.Setting.swagger_mocked_api_baseUrl + 'costAllocations/' + '2',
    if (
      this.state.utilityBillTableData &&
      Array.isArray(this.state.utilityBillTableData.tenantRows) &&
      this.state.utilityBillTableData.tenantRows.length > 0
    ) {
      this.setState({skeletonLoading: true}); //loader(true);
      this.state.utilityBillTableData.tenantRows.forEach((i, index) => {
        Autoload.HttpRequestAxios.HttpRequest(
          Autoload.Setting.ub_api_base_url + `costAllocations/${i.id}`,
          'GET',
          null,
          null,
          false,
          true,
          false,
          this,
          '_handleGetCostAllocation',
          {
            allocationId: i.id,
            isLastResponse: this.state.utilityBillTableData.tenantRows.length === index + 1,
            tenantRow: i,
          },
          false,
          '',
          {
            Authorization: Autoload.Utils.getAuthToken(),
          }
        );
      });
    } else Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang.newKeys.text_no_billing_found);
  };
  _handleGetCostAllocation = (response) => {
    console.log('response4', response);
    response.status == false && Autoload.Redirection.redirection_error();

    //call put api and tenant update data
    // const { utilityBillingData } = this.props;
    // let currentBillingData = utilityBillingData.find((ub) => ub.id == this.props.currentDataId);
    // let allTenantBills =
    //   currentBillingData &&
    //   currentBillingData.billingData &&
    //   currentBillingData.billingData.tenantBills &&
    //   currentBillingData.billingData.tenantBills;
    // let currentTenant = allTenantBills.find((tb) => tb.billingStatementId == this.state.selectedTenantID);
    let data = {
      id: checkProperty(response.result, 'id'),
      utilityBillingId: checkProperty(response.result, 'utilityBillingId'),
      costPositionId: checkProperty(response.result, 'costPositionId'),
      costTypeName: checkProperty(response.result, 'costTypeName'),
      allocationTypeName: checkProperty(response.result, 'allocationTypeName'),
      billingStatementId: checkProperty(response.result, 'billingStatementId'),
      tenantName: checkProperty(response.result, 'tenantName'),
      rentalUnitId: checkProperty(response.result, 'rentalUnitId'),
      rentalUnitName: checkProperty(response.result, 'rentalUnitName'),
      tenantOccupation: checkProperty(response.result, 'tenantOccupation', {}),
      tenantConsumption: checkProperty(response.result, 'tenantConsumption'),
      totalConsumption: checkProperty(response.result, 'totalConsumption'),
      consumptionUnits: checkProperty(response.result, 'consumptionUnits'),
      totalCostShare: checkProperty(response.result, 'totalCostShare'),
      tenantCostShare: checkProperty(response.result, 'tenantCostShare'),
      comment: response.other.tenantRow.comment,
    };
    console.log(response.result);

    if (response.other && response.other.allocationId) {
      Autoload.HttpRequestAxios.HttpRequest(
        // Autoload.Setting.swagger_mocked_api_baseUrl + 'costAllocations/' + '2',
        Autoload.Setting.ub_api_base_url + `costAllocations/${response.other.allocationId}`,
        'PUT',
        data,
        null,
        false,
        true,
        false,
        this,
        '_handleUpdateCostAllocation',
        { isLastResponse: response.other.isLastResponse },
        false,
        '',
        {
          Authorization: Autoload.Utils.getAuthToken(),
        }
      );
    } else Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang.newKeys.text_error_Incorrect_Cost_Allocation);
  };
  _handleUpdateCostAllocation = (response) => {
    if (response.status == false) {
      Autoload.Redirection.redirection_error();
      Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang.newKeys.text_error_update_Cost_Allocation);
    }
    if (response.other.isLastResponse) {
      this.props.goNext();
      this.setState({skeletonLoading: false}); //loader(false);
    }
  };

  getTableLabels = () => {
    return (
      <TableRow>
        <TableCell style={this.tableHeadCellStyling}>{lang.utility.text_cost_type}</TableCell>
        <TableCell style={this.tableHeadCellStyling}>{lang.utility.text_total_cost}</TableCell>
        <TableCell style={this.tableHeadCellStyling}>{lang.utility.text_allocation_key}</TableCell>
        <TableCell style={this.tableHeadCellStyling}>{lang.utility.text_total_value}</TableCell>
        <TableCell style={this.tableHeadCellStyling}>{lang.utility.text_tenant_value}</TableCell>
        <TableCell style={this.tableHeadCellStyling}>{lang.utility.text_unit}</TableCell>
        <TableCell style={this.tableHeadCellStyling}>{lang.utility.text_tenant_cost_share}</TableCell>
        <TableCell style={this.tableHeadCellStyling}>{lang.utility.text_comment}</TableCell>
      </TableRow>
    );
  };
  getAllData = () => {
    console.log('get All data func ');
    const { utilityBillingData } = this.props;
    let currentBillingData = utilityBillingData.find((ub) => ub.id == this.props.currentDataId);
    console.log(currentBillingData);
    let allTenantBills =
      currentBillingData &&
      currentBillingData.billingData &&
      currentBillingData.billingData.tenantBills &&
      currentBillingData.billingData.tenantBills;
    let currentTenant = this.state.selectedTenantData;
    let selectedTenantData = allTenantBills.find((o)=> o.tenantID == currentTenant.tenantId)
    if (allTenantBills.length && selectedTenantData) {
      this.setState({ selectedTenantData: selectedTenantData });
    }
    console.log('getData', allTenantBills, currentTenant);
    this.setState({
      data: {
        currentTenant: currentTenant ? currentTenant : {},
        allTenantBills: Array.isArray(allTenantBills) && allTenantBills.length > 0 ? allTenantBills : [],
      },
    });
    return {
      currentTenant: currentTenant ? currentTenant : {},
      allTenantBills: Array.isArray(allTenantBills) && allTenantBills.length > 0 ? allTenantBills : [],
    };
  };

  updateCommentLocalState = (tenantId, billId, data) => {
    this.setState({skeletonLoading: true}); //loader(true);
    this.updateComment(data, tenantId)
    this.setState(
      {
        selectedTenantData: {
          ...this.state.selectedTenantData,
          tenantRows: this.state.utilityBillTableData.tenantRows.map((i) =>
            i.id === billId ? { ...i, comment: data.comment } : i
          ),
        },
      },
      () => this.setState({skeletonLoading: false})// loader(false)
    );
  };
  updateComment = (data, tenantId) => {
    clearTimeout(interval)
    interval = setTimeout(() => {
      Autoload.HttpRequestAxios.HttpRequest(
        Autoload.Setting.ub_api_base_url + 'costAllocations/' + data.id,
        'PUT',
        data,
        null,
        false,
        true,
        false,
        this,
        'updateCommentResponse',
        {tenantId},
        false,
        '',
        {
          Authorization: Autoload.Utils.getAuthToken(),
        }
      );
    }, 1000)

  };
  updateCommentResponse = (response) => {
    !response.status && console.log('Update Consumptions Failed');

    this.setState({skeletonLoading: false}); //loader(false);
    this.callApis(response.other.tenantId);
  };

  downloadPDF = (file) => {
    const {propertySelected} = this.props;
    //window.open(`/billingStatement/${file ? file : this.state.selectedTenantData && this.state.selectedTenantData.id}?heatingFilter=${this.props.heatingFilter}&meterReadings=${this.state.readingsSwitch}&compositionOfHeatingCosts=${this.state.heatingCostsSwitch}&costSplitWarmWaterAndHeating=${this.state.costSplitWarmWaterAndHeatingSwitch}&splitBaseConsumptionCosts=${this.state.splitBaseConsumptionCostsSwitch}&vatSwitch=${this.state.vatSwitch}&laborCostSwitch=${this.state.laborCostSwitch}`, '_blank');
    window.open(`/billingStatement2/${file ? file : this.state.selectedTenantData && this.state.selectedTenantData.id}?heatingFilter=${propertySelected.propertyType === 'CONDOMINIUM' ? false : this.props.heatingFilter}&meterReadings=${propertySelected.propertyType === 'CONDOMINIUM' ? false : this.state.readingsSwitch}&compositionOfHeatingCosts=${propertySelected.propertyType === 'CONDOMINIUM' ? false : this.state.heatingCostsSwitch}&costSplitWarmWaterAndHeating=${propertySelected.propertyType === 'CONDOMINIUM' ? false : this.state.costSplitWarmWaterAndHeatingSwitch}&splitBaseConsumptionCosts=${propertySelected.propertyType === 'CONDOMINIUM' ? false : this.state.splitBaseConsumptionCostsSwitch}&energyConsumptionCO2EmissionsSwitch=${propertySelected.propertyType === 'CONDOMINIUM' ? false : this.state.energyConsumptionCO2EmissionsSwitch}&compareHeatingConsumptionBarChartSwitch=${propertySelected.propertyType === 'CONDOMINIUM' ? false : this.state.compareHeatingConsumptionBarChartSwitch}&energyLevelsInfoSwitch=${propertySelected.propertyType === 'CONDOMINIUM' ? false : this.state.energyLevelsInfoSwitch}&arbitraryBoardInfoSwitch=${propertySelected.propertyType === 'CONDOMINIUM' ? false : this.state.arbitraryBoardInfoSwitch}&energyAdviceCenterInfoSwitch=${propertySelected.propertyType === 'CONDOMINIUM' ? false : this.state.energyAdviceCenterInfoSwitch}&co2TaxInfoSwitch=${propertySelected.propertyType === 'CONDOMINIUM' ? false : this.state.co2TaxInfoSwitch}&vatSwitch=${this.state.vatSwitch}&laborCostSwitch=${this.state.laborCostSwitch}&isPreTaxInfoHidden=${this.state.isPreTaxInfoHidden}`, '_blank');
  };
  calculateBalance = (data) => {
    if (data) {
      const { totalAllocations, prepayments } = data?.currentTenant;
      return parseFloat(totalAllocations) - parseFloat(prepayments);
    }
  };
  getBalance(data) {
    var totalAllocations = data ? checkProperty(data, 'totalAllocations', '-') : '-';
    var prePayments = data ? checkProperty(data, 'prepayments', '-') : '-';
    // console.log(data)
    // console.log(totalAllocations)
    // console.log(prePayments)
    var getbalance = totalAllocations - prePayments + '€';
    return getbalance === 'NaN€' ? '-' : getbalance;
  }
  handleResize = () => {
    window.innerWidth <= MOBILE_SIZE ? this.setState({ isMobile: true }) : this.setState({ isMobile: false });
    window.innerWidth <= TABLET_SIZE ? this.setState({ isTablet: true }) : this.setState({ isTablet: false });
  };

  handleScroll=()=>{
    this.setState({openTaxContextMenu: false});
  }
  onInputChange = (field, value) => {
    const selectedTenantData = this.state.currentUtilityBillingData.tableData.find(
      (o) => o.tenantId == value
    );
    this.setState({ selectedTenantData }, () => {
      //this.updateTenantOccupations();
      //this.updateConsumptions();
      this.getBillingStatements(selectedTenantData);
    });
  };

  renderTooltip = (props) => (
    <TooltipRb id="button-tooltip" {...props}>
      <span>Betriebskostenabrechnung ist festgeschrieben und wird nicht automatisch aktualisiert. </span>
      <span 
      onClick={() => 
        this.setState({
          open: true, 
          freezeAlertHeading: this.state.utilityData.isSecured ? lang.utility.text_freeze_alert_heading : lang.utility.text_unfreeze_alert_heading, 
          freezeAlertMessage: this.state.utilityData.isSecured ? lang.utility.text_freeze_alert_message : (<span>{lang.utility.text_unfreeze_alert_message} <a href="https://wissen.hellohousing.de/was-bedeutet-eine-festschreibung-der-betriebskostenabrechnung" target="_blank">(mehr erfahren)</a>.</span>)
        })}
      style={{cursor: 'pointer', fontWeight: 'bold', color: '#5555FF'}}>
          Festschreibung aufheben.
      </span>
    </TooltipRb>
  );

  lockUtilityBilling = (id, isSecured) => {
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.ub_api_base_url + 'utilityBillings/' + id +'/Secured/' + !isSecured,
      'PUT',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleLockUtilityBilling',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };

  handleLockUtilityBilling = (response) => {
    console.log('response lock billings', response);
    if (response.status == false) {
      Autoload.Redirection.redirection_error();
    }
    let {utilityData} = this.state;
    utilityData.isSecured = !utilityData.isSecured;
    this.setState({utilityData}, ()=> this.props.history.push('/utility-billing'))
  };

  renderUtilityBillsTotalDetailsSub = () =>{
    const {isPreTaxInfoHidden, vatSwitch, selectedTenantData} = this.state;
    const commercialTenantWithVatSwitch = vatSwitch && this.props.currentSubscription.subscriptionKey.split('_')[0] !== 'STARTER' && (selectedTenantData?.tenantOccupation.contractType === 'Default' || selectedTenantData?.tenantOccupation.contractType === 'commercial' || selectedTenantData?.tenantOccupation.contractType === 'self');
    const sumOfVAT = selectedTenantData?.totalVATAmount ? selectedTenantData?.totalVATAmount : 0;
    const netUtilityBillingCost = (selectedTenantData && selectedTenantData?.tenantRows ? this.calculateTotal(selectedTenantData?.tenantRows.length - 1, false) : selectedTenantData?.totalAllocations) - sumOfVAT;
    const VAT = (isPreTaxInfoHidden ? (selectedTenantData && selectedTenantData?.tenantRows ? this.calculateTotal(selectedTenantData?.tenantRows.length - 1, false) : selectedTenantData?.totalAllocations) : netUtilityBillingCost) * 0.19;
    const grossUtilityBillingCost = !isPreTaxInfoHidden ? (netUtilityBillingCost + VAT) : ((selectedTenantData && selectedTenantData?.tenantRows ? this.calculateTotal(selectedTenantData?.tenantRows.length - 1, false) : selectedTenantData?.totalAllocations) + VAT);
    const creditAmount = selectedTenantData ? selectedTenantData?.prepayments - grossUtilityBillingCost : 0;

    console.log('grossUtilityBillingCost: ', grossUtilityBillingCost);
    return (
    <div className='utility-bills-total-details-sub' style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', width: '620px' }}>
                
                <div className="days">
                  <lable>{lang.utility.text_time_period}</lable>
                  <p>
                    {dayjs(this.state.selectedTenantData?.tenantOccupation.startDate, 'YYYY-MM-DD').format('DD.MM.YYYY') + ' - ' + dayjs(this.state.selectedTenantData?.tenantOccupation.endDate, 'YYYY-MM-DD').format('DD.MM.YYYY')}
                    {console.log('this.state.selectedTenantData: ', this.state.selectedTenantData)}
                  </p>
                </div>
                <div className="days" style={{width: '84px', marginRight: '10px'}}>
                  <lable>{lang.utility.text_stayed_for}</lable>
                  <p>
                    {this.state.selectedTenantData?.stayedFor?.toString()} {lang.newKeys.text_days}
                  </p>
                </div>
                <div className="total-allocation" style={{width: '84px', marginRight: '10px'}}>
                  <lable>{lang.utility.text_total_allocation}</lable>
                  <p
                    style={{
                      color:
                      (this.state.selectedTenantData && typeof this.state.selectedTenantData.totalAllocations === 'number') 
                      ? this.state.selectedTenantData.totalAllocations < 0
                        ? 'red'
                        : "#0E0E1D"
                      : "#0E0E1D",
                      //textAlign: 'right'
                    }}
                  >
                    {this.state.selectedTenantData ? 
                    <NumericFormat 
                    className='numeric-format-text-view'
                    style={{color: (this.state.selectedTenantData && typeof this.state.selectedTenantData.totalAllocations === 'number') 
                      ? this.state.selectedTenantData.totalAllocations < 0
                        ? 'red'
                        : "#0E0E1D"
                      : "#0E0E1D"}} 
                    thousandSeparator="." 
                    decimalSeparator="," 
                    value={commercialTenantWithVatSwitch ? grossUtilityBillingCost : this.state.selectedTenantData && this.state.selectedTenantData.tenantRows ? this.calculateTotal(this.state.selectedTenantData.tenantRows.length - 1) : this.state.selectedTenantData.totalAllocations} suffix={' €'} //this.state.selectedTenantData.totalAllocations
                    placeholder='0,00 €'
                    decimalScale={2}
                    fixedDecimalScale={2}
                    disabled={true}/> : '0,00 €'}
                    
                    {/* {this.state.selectedTenantData ? Autoload.MainHelper.ConverterCurrencyDe_DisplayOnly(this.state.selectedTenantData.totalAllocations, false) + ' €' : '0,00 €'} */}
                  </p>
                </div>
                <div className="payments" style={{width: '102px', marginRight: '24px'}}>
                  <lable>{lang.utility.text_prepayments}</lable>
                  <p
                    style={{
                      color:
                        (this.state.selectedTenantData && typeof this.state.selectedTenantData.prepayments === 'number') 
                        ? this.state.selectedTenantData.prepayments < 0
                          ? 'red'
                          : '#0E0E1D'
                        : '#0E0E1D',
                      //textAlign: 'right',
                    }}
                  >
                     
                    {this.state.selectedTenantData ? 
                    <NumericFormat 
                    className='numeric-format-text-view'
                    style={{color: (this.state.selectedTenantData && typeof this.state.selectedTenantData.prepayments === 'number') 
                      ? this.state.selectedTenantData.prepayments < 0
                        ? 'red'
                        : '#0E0E1D'
                      : '#0E0E1D'}} 
                    thousandSeparator="." 
                    decimalSeparator="," 
                    value={this.state.selectedTenantData.prepayments} 
                    suffix={' €'} 
                    placeholder='0,00 €'
                    decimalScale={2}
                    fixedDecimalScale={2}
                    allowNegative={true}
                    disabled={true}/>
                    : '0,00 €'}
                    {/* {this.state.selectedTenantData ? Autoload.MainHelper.ConverterCurrencyDe_DisplayOnly(this.state.selectedTenantData.prepayments, false) + ' €' : '0,00 €'} */}
                  </p>
                </div>
                {console.log('typeof: ', this.state.selectedTenantData?.prepayments, this.state.selectedTenantData?.totalAllocations)}
                <div className="balance" style={{width: '84px', marginLeft: '0px'}}>
                  {(this.state.selectedTenantData && typeof this.state.selectedTenantData.prepayments  === 'number' && typeof this.state.selectedTenantData.totalAllocations === 'number') ? ((this.state.selectedTenantData.prepayments - this.state.selectedTenantData.totalAllocations) >= 0 ? <lable>{lang.utility.text_balance_positive}</lable> : <lable>{lang.utility.text_balance_negative}</lable>) : <lable>{lang.utility.text_balance_positive}</lable>}
                  <p
                    style={{
                      color:
                        (this.state.selectedTenantData && typeof this.state.selectedTenantData.prepayments  === 'number' && typeof this.state.selectedTenantData.totalAllocations === 'number') 
                        ? ((this.state.selectedTenantData.prepayments - this.state.selectedTenantData.totalAllocations) < 0
                          ? 'red'
                          : '#0E0E1D')
                        : '#0E0E1D',
                        //textAlign: 'right'
                    }}
                  >
                    {this.state.selectedTenantData
                      ? 
                      <NumericFormat 
                      className='numeric-format-text-view'
                      style={{color: (this.state.selectedTenantData && typeof this.state.selectedTenantData.prepayments  === 'number' && typeof this.state.selectedTenantData.totalAllocations === 'number') 
                      ? ((this.state.selectedTenantData.prepayments - this.state.selectedTenantData.totalAllocations) < 0 
                        ? 'red'
                        : '#0E0E1D')
                      : '#0E0E1D'}} 
                      thousandSeparator="." 
                      decimalSeparator="," 
                      value={commercialTenantWithVatSwitch ? creditAmount : this.state.selectedTenantData && this.state.selectedTenantData.tenantRows ? (this.state.selectedTenantData.prepayments - this.calculateTotal(this.state.selectedTenantData.tenantRows.length - 1, false)) : (this.state.selectedTenantData.prepayments - this.state.selectedTenantData.totalAllocations)} 
                      suffix={' €'} 
                      placeholder='0,00 €'
                      decimalScale={2}
                      fixedDecimalScale={2}
                      allowNegative={true}
                      disabled={true}/>
                      // Autoload.MainHelper.ConverterCurrencyDe_DisplayOnly(this.state.selectedTenantData.prepayments - this.state.selectedTenantData.totalAllocations, false) + ' €'
                      : '0,00 €'}
                  </p>
                </div>
    </div>
    );
  }
  
  printDocument() {
    const input = document.getElementById('divToPrint');
    const logo = document.getElementById('logo');
    const propertyName= this.props.currentProperty?.propertyAdress?.streetno;
    const rentalUnitName= this.state.invoiceData?.rentalUnitName;
    const tenantName= this.state.invoiceData?.tenantName;
    const closeWindow = ()=> {
      this.setState({skeletonLoading: false}); //loader(false);
      window.close();
    }
    html2canvas(logo, {
      scale: 4
    }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      var doc = new jsPdf("p", "pt", 'a4');
      doc.addImage(imgData, 'JPEG',21, 20, 75,38, undefined, 'NONE');
      
      doc.html(input, {
        callback: function (doc) {
          doc.save(`${propertyName}-${rentalUnitName}-${tenantName}`);
          // setTimeout(() => {
          //   closeWindow();
          // }, 500);
          this.setState({skeletonLoading: false}); //loader(false);
        },
        margin: [0,10,0,10],
        x: 10,
        y: 15,
        autoPaging: 'text',
        html2canvas: {useCORS: true, scale: 0.505
          , height: 0 },
      });
    });
  }

  getHeatingCosts = (addEmptyHC = false) => {
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.ub_api_base_url + 'heatingCosts?utilityBillingId=' + this.props.currentDataId,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      '_handleHeatingCostsResponse',
      { addEmptyHC },
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };

  _handleHeatingCostsResponse =(response)=>{
    console.log('_handleHeatingCostsResponse: ', response);
    if(response.status){
      this.setState({
        heatingCosts: response.result, 
        //heatingCostsSwitch: response.result.length > 0 && true
      });
    }
  }

  handleHeatingCostsSwitchChange=()=>{
    this.setState({heatingCostsSwitch: !this.state.heatingCostsSwitch}, ()=> this.updateUtilityBilling());
  }
  handleReadingsSwitchChange=()=>{
    this.setState({readingsSwitch: !this.state.readingsSwitch}, ()=> this.updateUtilityBilling());
  }
  handleCostSplitWarmWaterAndHeatingSwitchChange=()=>{
    this.setState({costSplitWarmWaterAndHeatingSwitch: !this.state.costSplitWarmWaterAndHeatingSwitch}, ()=> this.updateUtilityBilling());
  }

  handleSplitBaseConsumptionCosts=()=>{
    this.setState({splitBaseConsumptionCostsSwitch: !this.state.splitBaseConsumptionCostsSwitch}, ()=> this.updateUtilityBilling())
  }
  handleEnergyConsumptionCO2EmissionsChange=()=>{
    this.setState({energyConsumptionCO2EmissionsSwitch: !this.state.energyConsumptionCO2EmissionsSwitch}, ()=> this.updateUtilityBilling());
  }

  handleCompareHeatingConsumptionBarChartChange=()=>{
    this.setState({compareHeatingConsumptionBarChartSwitch: !this.state.compareHeatingConsumptionBarChartSwitch}, ()=> this.updateUtilityBilling());
  }

  handleEnergyLevelsInfoSwitchChange=()=>{
    this.setState({energyLevelsInfoSwitch: !this.state.energyLevelsInfoSwitch}, ()=> this.updateUtilityBilling());
  }

  handleEnergyAdviceCenterInfoSwitchChange=()=>{
    this.setState({energyAdviceCenterInfoSwitch: !this.state.energyAdviceCenterInfoSwitch}, ()=> this.updateUtilityBilling());
  }

  handleArbitraryBoardInfoSwitchSwitchChange=()=>{
    this.setState({arbitraryBoardInfoSwitch: !this.state.arbitraryBoardInfoSwitch}, ()=> this.updateUtilityBilling());
  }

  handleCO2TaxInfoSwitchSwitchChange=()=>{
    this.setState({co2TaxInfoSwitch: !this.state.co2TaxInfoSwitch}, ()=> this.updateUtilityBilling());
  }
  
  getOccupationPeriodDate(periodStart, periodEnd) {
    const occupationStartDate = moment(periodStart, 'YYYY-MM-DD').format('DD.MM.YYYY');
    const occupationEndDate = moment(periodEnd, 'YYYY-MM-DD').format('DD.MM.YYYY');
    return occupationStartDate + ' - ' + occupationEndDate;
  }

  updateOccasion() {
    const { defaultOccasion, tenantOccupationReadings } = this.state;
    console.log('tenantOccupationReadings', tenantOccupationReadings);
    let readingList = [];
    let readingType = null;
    const { periodStartReading = null, periodEndReading = null } = tenantOccupationReadings;
    // if (defaultOccasion === 'move-in' || defaultOccasion === 'Move in') {
    //   const { periodStartReading = null, periodEndReading = null } = tenantOccupationReadings;
    //   if (checkProperty(periodStartReading, 'occasion') === 'move-in') {
    //     readingType = periodStartReading;
    //   } else {
    //     readingType = periodEndReading;
    //   }
    //   // readingType = tenantOccupationReadings.periodStartReading.occasion === 'move-in' ? tenantOccupationReadings.periodStartReading : tenantOccupationReadings.periodEndReading;
    //   if (readingType && readingType.meterReadings && readingType.meterReadings.length > 0) {
    //     readingList = readingType.meterReadings.map((data, index) => ({
    //       ...data,
    //       ...MeterDataHandler.generateReadingRow(data),
    //       editStartValue: data.startValue === null ? true : false,
    //     }));
    //     readingList.map((reading, index) => {
    //       readingList[index].id = readingList[index].testId;
    //     });
    //     this.setState({ readings: readingList, disableUpdation: false });
    //     readingList = [];
    //     document.getElementById('errorattop').style.display = 'none';
    //   } else {
    //     // this.renderErrorMessage(lang.newKeys.text_error_no_meter_reading);
    //     this.setState({ readings: readingList, disableUpdation: true });
    //   }
    // } else 
    if (periodStartReading && periodStartReading?.occasionDate >= tenantOccupationReadings.periodStart) {
      //let editableLastIds = editableIds;
    //   if(item.periodStartReading && item.periodStartReading.occasion === 'move-in'){
    //     const { meterReadings } = item.periodStartReading;
    //     meterReadings.map((reading)=> (reading.startValue === null || reading.startValue === 0 || reading.startValue === '0,00') && editableLastIds.push(reading.id))
    //   }
    console.log('periodStartReading');
      const periodReading = {
        ...periodStartReading,
        periodStart: tenantOccupationReadings.periodStart,
        periodEnd: tenantOccupationReadings.periodEnd,
        rentalUnitName: tenantOccupationReadings.rentalUnitName,
        tenantFullName: tenantOccupationReadings.tenantFullName,
        tenantId: tenantOccupationReadings.tenantId,
        occupationId: tenantOccupationReadings.id,
        vacant: tenantOccupationReadings.vacant
      }
      this.setState({readings: periodReading
        // , readingsSwitch: periodReading &&
        // periodReading.meterReadings &&
        // periodReading.meterReadings.length > 0 ? true : false
      });
      //setEditableIds(editableLastIds);
    };
    if (periodEndReading) {
      console.log('periodEndReading');
      let { meterReadings } = periodEndReading;
      meterReadings= meterReadings.map((meterReading)=> {
        const meterReadingsFromPeriodStartReading = periodStartReading.meterReadings.find((meterReading1)=> meterReading1.meter.meterId === meterReading.meter.meterId);
        console.log('meterReadingsFromPeriodStartReading: ', meterReadingsFromPeriodStartReading);
        if(meterReadingsFromPeriodStartReading !== undefined){
          meterReading.startValue = meterReadingsFromPeriodStartReading.currentValue;
          meterReading.consumption = (meterReading.currentValue === null || meterReading.startValue === null) ? null : meterReading.currentValue - meterReading.startValue;
        }
        return meterReading;
      });
      const periodReading = {
        ...periodEndReading,
        periodStart: tenantOccupationReadings.periodStart,
        periodEnd: tenantOccupationReadings.periodEnd,
        rentalUnitName: tenantOccupationReadings.rentalUnitName,
        tenantFullName: tenantOccupationReadings.tenantFullName,
        tenantId: tenantOccupationReadings.tenantId,
        occupationId: tenantOccupationReadings.id,
        vacant: tenantOccupationReadings.vacant,
        occasion: (tenantOccupationReadings.vacant && periodEndReading?.occasion === 'move-in') ? 'empty-tenant-move-out' : periodEndReading?.occasion
      }
      console.log('periodReading: ', periodStartReading, periodEndReading);
      this.setState({readings: periodReading
        // , readingsSwitch: periodReading &&
        // periodReading.meterReadings &&
        // periodReading.meterReadings.length > 0 ? true : false
      });
    };
  }

  _getOccupationReadingTenants(tenantId) {
    const propertyid = this.props.propertySelected && this.props.propertySelected.id;
    let ubData = this.props.utilityBillingData.find((ub) => ub.id == this.props.currentDataId)
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.meter_api_base_url +
      'occupationreadings/occupationReadingForTenant?TenantId=' +
      tenantId +
      '&BillingPeriodId=' +
      ubData.billingPeriodId +
      '&PropertyId=' +
      propertyid,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      '_handleOccupationReadingTenantResponse',
      null,
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  _handleOccupationReadingTenantResponse(response) {
    console.log(response, 'occupation api meter');
    if (response.status === true && response.statuscode === 401) {
      localStorage.clear();
      window.location.reload();
    }

    if (response.status === true && response.statuscode === 200) {
      const tenantOccupationReadings = MeterDataHandler.getTenantOccupationReadings(response.result);
      if (tenantOccupationReadings) {
        const startOccasion = tenantOccupationReadings.periodStartReading.occasion;
        const endOccasion = tenantOccupationReadings.periodEndReading.occasion;
        const occupancyDate = this.getOccupationPeriodDate(
          tenantOccupationReadings.periodStart,
          tenantOccupationReadings.periodEnd
        );
        const disableRadioSelection = {
          disableMoveIn:
            tenantOccupationReadings.periodStartReading.occasion === 'move-in' ||
              tenantOccupationReadings.periodEndReading.occasion === 'move-in'
              ? false
              : true,
          disableAnnual:
            tenantOccupationReadings.periodStartReading.occasion === 'annual' ||
              tenantOccupationReadings.periodEndReading.occasion === 'annual'
              ? false
              : true,
          disableMoveOut:
            (tenantOccupationReadings.periodStartReading.occasion === 'move-out' && tenantOccupationReadings.tenantFullName !== '(leer)') ||
              (tenantOccupationReadings.periodEndReading.occasion === 'move-out' && tenantOccupationReadings.tenantFullName !== '(leer)')
              ? false
              : true,
        };
        this.setState(
          {
            tenantOccupationReadings,
            occupancyDate,
            defaultOccasion: tenantOccupationReadings.tenantFullName === '(leer)' ? 'annual' : startOccasion || endOccasion,
            disableRadioSelection,
          },
          () => this.updateOccasion()
        );

        // document.getElementById('errorattop').style.display = 'none';
        console.log('response _handleOccupationReadingTenantResponse', tenantOccupationReadings);
      } else {
        //this.renderErrorMessage(lang.newKeys.text_error_Tenant_readings_not_found);
        this.setState({ disableUpdation: true, readings: [] });
      }
    } else {
      //this.renderErrorMessage(lang.newKeys.text_error_no_reading_found);
      this.setState({ disableUpdation: true, readings: [] });
    }
    //this.ShowLoader();
    console.log(this.state.readings, 'data to show meter 1');
  }

  calculateTotal=(i, deCurrency=true, type)=>{
    if(type === 'heating'){
      let total = this.state.selectedTenantData?.totalAllocatedHeatingCosts;
      console.log('calculateTotal: ', total);
      return deCurrency === false ? total : formatNumber(total, true);
    }else{
      let total = 0;
      this.state.selectedTenantData?.tenantRows?.map((row, index)=> {
        console.log('row: ', row);
        if(row.costType !== "Heizung & Heizungswartung"){
          if(index <= i){
            total = total + row.tenantCostShare;
          }
        }
      })
      console.log('calculateTotal: ', total);
      return deCurrency === false ? total : formatNumber(total, true);
    }
  }

  updateUtilityBilling=()=>{
    let {utilityBillingData, vatSwitch, laborCostSwitch, readingsSwitch, costSplitWarmWaterAndHeatingSwitch, splitBaseConsumptionCostsSwitch, heatingCostsSwitch, energyConsumptionCO2EmissionsSwitch, compareHeatingConsumptionBarChartSwitch, energyLevelsInfoSwitch, energyAdviceCenterInfoSwitch, arbitraryBoardInfoSwitch, co2TaxInfoSwitch, isPreTaxInfoHidden} = this.state;
    const {propertySelected} = this.props;
    console.log('utilityBillingData: ', utilityBillingData);
    if(utilityBillingData){
      let data = {
        ...utilityBillingData,
        isVATHidden: !vatSwitch,
        isLaborCostHidden: !laborCostSwitch,
        isMeterReadingAndConsumptionInfoHidden: propertySelected.propertyType === 'CONDOMINIUM' ? true : !readingsSwitch,
        isHeatingCostInfoHidden: propertySelected.propertyType === 'CONDOMINIUM' ? true : !heatingCostsSwitch,
        isSplitBaseConsumptionCostInfoHidden: propertySelected.propertyType === 'CONDOMINIUM' ? true : !splitBaseConsumptionCostsSwitch,
        isCostSplitWarmWaterAndHeatingCostInfoHidden: propertySelected.propertyType === 'CONDOMINIUM' ? true : !costSplitWarmWaterAndHeatingSwitch,
        heatingCostsActivated: propertySelected.propertyType === 'CONDOMINIUM' ? false : utilityBillingData?.heatingCostsActivated,
        isEnergyConsumptionCO2EmissionsInfoHidden: propertySelected.propertyType === 'CONDOMINIUM' ? true : !energyConsumptionCO2EmissionsSwitch,
        isPreviousPeriodEnergyComparisonInfoHidden: propertySelected.propertyType === 'CONDOMINIUM' ? true : !compareHeatingConsumptionBarChartSwitch,
        isEnergyLevelsInfoHidden: propertySelected.propertyType === 'CONDOMINIUM' ? true : !energyLevelsInfoSwitch,
        isArbitraryBoardInfoHidden: !arbitraryBoardInfoSwitch,
        isEnergyAdviceCenterInfoHidden: !energyAdviceCenterInfoSwitch,
        isCO2TaxInfoHidden: propertySelected.propertyType === 'CONDOMINIUM' ? true : !co2TaxInfoSwitch,
        isPreTaxInfoHidden: isPreTaxInfoHidden
      }
      Autoload.HttpRequestAxios.HttpRequest(
        Autoload.Setting.ub_api_base_url + 'utilityBillings/' + this.props.currentDataId,
        'PUT',
        data,
        null,
        false,
        true,
        false,
        this,
        'handleUpdateUtilityBilling',
        {data},
        false,
        '',
        {
          Authorization: Autoload.Utils.getAuthToken(),
        }
      );
    }
  }

  handleUpdateUtilityBilling(response){
    if(response.status && response.statuscode === 200){
      this.props.setUtilityBillingData(
        this.props.utilityBillingData.find((ub)=> ub.id == response.other.data.id) === undefined ? 
        [...this.props.utilityBillingData, {...response.other.data }] 
        :
        this.props.utilityBillingData.map((ub)=> {
          if(ub.id === response.other.data.id){
            return {...ub, ...response.other.data};
          }else{
            return ub;
          }
        })
      );
      this.setState({
        readingsSwitch: !response.other.data.isMeterReadingAndConsumptionInfoHidden,
        heatingCostsSwitch: !response.other.data.isHeatingCostInfoHidden,
        splitBaseConsumptionCostsSwitch: !response.other.data.isSplitBaseConsumptionCostInfoHidden,
        costSplitWarmWaterAndHeatingSwitch: !response.other.data.isCostSplitWarmWaterAndHeatingCostInfoHidden,
        energyConsumptionCO2EmissionsSwitch: !response.other.data.isEnergyConsumptionCO2EmissionsInfoHidden,
        compareHeatingConsumptionBarChartSwitch: !response.other.data.isPreviousPeriodEnergyComparisonInfoHidden,
        energyLevelsInfoSwitch: !response.other.data.isEnergyLevelsInfoHidden,
        arbitraryBoardInfoSwitch: !response.other.data.isArbitraryBoardInfoHidden,
        energyAdviceCenterInfoSwitch: !response.other.data.isEnergyAdviceCenterInfoHidden,
        co2TaxInfoSwitch: !response.other.data.isCO2TaxInfoHidden
      });
    }
  }

  renderVatPopover=()=>{
    const {openTaxContextMenu, skeletonLoading, isPreTaxInfoHidden} = this.state;
    return (
      <Group>
        <Popover
        opened={openTaxContextMenu}
        onClose={() => this.setState({openTaxContextMenu: false})}
        position="bottom"
        placement="center"
        withArrow={false}
        trapFocus={false}
        closeOnEscape={true}
        width={180}
        classNames={{
          wrapper: 'tax-context-popover-wrapper'
        }}
        target={
        <Group 
        style={{padding: '10px', marginTop: '3px', cursor: 'pointer'}} 
        onClick={()=> !skeletonLoading && this.setState({openTaxContextMenu: !openTaxContextMenu})}>
          <img src={DotsIcon} />
        </Group>
        }>
            <Checkbox
            checked={isPreTaxInfoHidden}
            onChange={()=> this.setState({isPreTaxInfoHidden: !isPreTaxInfoHidden}, ()=> this.updateUtilityBilling())}
            label={'ohne VSt.-Abzug'}
            styles={{
              label: {
                fontSize: '12px',
                fontWeight: '400',
                color: '#727293',
                paddingLeft: 10,
                marginTop: '-2px'
              },
              input: {
                cursor: 'pointer'
              }
            }}
            classNames={{input: 'tenants-list-checkbox-rental-income-input', inner: 'tenants-list-checkbox-rental-income-inner'}}
            />
        </Popover>
      </Group>
    );
  }

  render() {
    // const data = this.getAllData();
    const { showEmpty, utilityData, utilityBillTableData, data, open, freezeAlertHeading, freezeAlertMessage, isTablet, allTenants, skeletonLoading, heatingCosts, vatSwitch, laborCostSwitch, hoverOnStarter, hoverOnStarter2, numberBillingStatementTableRows, numberMeterReadingsTableRows, hideTenantZeroCostsSwitch, openShowControlSheetForBillingStatementModal, openTaxContextMenu, isPreTaxInfoHidden } = this.state;
    const { currentSubscription, accountData, propertySelected } = this.props;
    const balance = this.calculateBalance(data);
    console.log('UtilityBillsTable: ', this.state.selectedTenantData?.tenantID);
    console.log('this.state.readings: ', this.state.readings);
    console.log('heatingCosts: ', heatingCosts);
    console.log('heatingFilter: ', this.state.heatingFilter);
    console.log('this.state.selectedTenantData: ', this.state.selectedTenantData);
    console.log('numberBillingStatementTableRows: ', numberBillingStatementTableRows);
    console.log('numberMeterReadingsTableRows: ', numberMeterReadingsTableRows);

    console.log('utilityData_123: ', utilityData);
    console.log('tenant_data: ', this.state.selectedTenantData);

    console.log('heatingCostsSwitch: ', this.state.heatingCostsSwitch);
    // const data = {
    //     currentTenant:{},
    //     allTenantBills:[]
    // };
    // if ((Object.keys(data.currentTenant).length == 0 && data.allTenantBills.length == 0) || showEmpty) {
    //   return <EmptyStateContainer heading={lang.utility.text_no_tenant_bills} icon={tap} showBtn={false} />;
    // }

    return (
      <div className="view-utility-billing">
        <AlertModal
        heading={freezeAlertHeading}
        open={open}
        dataSecured={utilityData.isSecured}
        cancel
        onCancel={()=> this.setState({open: false})}
        text={
          <span style={{fontSize: '14px'}}>
            {freezeAlertMessage}
          </span>
        }         
        onClick={(e) => {
          e.preventDefault();
          this.setState({open: false});
          this.lockUtilityBilling(this.props.currentDataId, utilityData.isSecured);
        }}
        toggle={() => {
          this.setState({open: false});
        }}
        />
        <HeadingContainerNew
          heading={lang.utility.text_utility_billing}
          subheading={<>
            <span>{utilityData.date}</span>
            {utilityData.isSecured && 
            <OverlayTrigger placement="bottom" delay={{ show: 450, hide: 1200 }} overlay={this.renderTooltip}>
                <img src={LockIcon} width='16px' style={{marginBottom: 4, marginLeft: 4}}/>
            </OverlayTrigger>}
          </>}
          className="profile-heading tenant-head utility-billing-head"
          headingIcon={CrossIcon}
          iconHandler={
            () => {
              this.props.setUtilityBillingData([]);
              this.props.history.push('/utility-billing');
            }
            // this.goBackIcon()
          }
          subheadingClassName="utility-subheading"
          subheadingbadge={
            <span className={utilityData.recordStatus === 'last' ? 'last-period-badge' : 'no-recent-period-bagde'}>
              {utilityData.recordStatus === 'last' ? lang.utility.text_last_billing_period : ''}
            </span>
          }
          rightContent={
            <div className="btn-container utility-bility-button">
              <ColorButton
                onClick={() => {
                  // this.goBack();
                  this.props.goBack();
                  this.props.history.push(`/utility-billing/utility?currentBillingId=${this.props.currentDataId}&ub-step=utility-cost`);
                }}
                text={lang.utility.text_go_back}
                className={`gray-btn`}
              />
              <ColorButton
                onClick={() => {
                  // this.moveForward();
                  // this.saveCurrentData();
                  if (!skeletonLoading) {
                    this.props.goNext();
                    this.props.history.push(`/utility-billing/utility?currentBillingId=${this.props.currentDataId}&ub-step=billing-statements-sending`, { isEdit: true, numberBillingStatementTableRows, numberMeterReadingsTableRows });
                  }
                }}
                text={lang.utility.text_next_overview}
                className="purple-btn mr-1"
                showLoader={skeletonLoading}
                //disabled
              />
            </div>
          }
          breadcrumbs={<div style={{display: 'flex'}}>
          {breadcrumbsContent.map((item)=> item.area.includes('ub-step=billing-statements') && 
          item.breadcrumbs.map((breadcrumb)=>
          breadcrumb.type === 'tour' ? breadcrumb.condition === 'rental_unit_created' && breadcrumb.view === 'desktop' &&
          <div style={{display: 'flex', width: 'auto', alignItems: 'center', cursor: 'pointer', marginRight: '22px'}} onClick={()=> window.Intercom('startTour', breadcrumb.id)}>
            <Skeleton visible={skeletonLoading}>
              <img src={TourIcon} style={{marginTop: '-3px'}}/>
              <span style={{color: '#5555FF', marginLeft: '5px', fontSize: '14px'}}>{breadcrumb.label}</span>
            </Skeleton>
          </div>
          :
          breadcrumb.view === 'desktop' &&
          breadcrumb.condition === propertySelected.propertyType &&
          <div style={{display: 'flex', width: 'auto', alignItems: 'center', cursor: 'pointer', marginRight: '22px'}} onClick={()=> window.Intercom('showArticle', breadcrumb.id)}>
            <Skeleton visible={skeletonLoading}>
              <img src={HelpLogo} style={{marginTop: '-3px'}}/>
              <span style={{color: '#5555FF', marginLeft: '5px', fontSize: '14px'}}>{breadcrumb.label}</span>
            </Skeleton>
          </div>
          ))}
        </div>}
        />

        <div className=" mobile-top-btn-section btn-container utility-bility-button">
          <ColorButton
            onClick={() => {
              // this.goBack();
              this.props.goBack();
            }}
            text={lang.utility.text_go_back}
            className={`gray-btn`}
          />
          <ColorButton
            onClick={() => {
              // this.moveForward();
              this.saveCurrentData();
            }}
            text={lang.utility.text_next_overview}
            className="purple-btn mr-1"
            disabled={true}
          />
        </div>

        <Divider className="utilitiy--divider" />

        {/* {(Object.keys(data.currentTenant).length == 0 && data.allTenantBills.length == 0) || showEmpty ? ( */}
        {/* {showEmpty ? (
          <EmptyStateContainer heading={lang.utility.text_no_tenant_bills} icon={tap} showBtn={false} />
        ) : ( */}
        <div>
          <div className="cost-allocation-section utility-bills-section">
            <div className="tooltips ub-final-statements-headline">
              <h5 className="utility-cost-allocation">{`${propertySelected.propertyType === 'REGULAR' ? '3' : '2'}. Abrechnungen fertigstellen`}</h5>
            </div>
            <p className="utility-cost-allocation-subheading">{'Prüfen Sie nun die Abrechnung je Mietpartei. '}<span style={{color: '#5555FF', marginLeft: '5px', fontSize: '14px', cursor: 'pointer'}} onClick={()=> { 
                  if (!skeletonLoading) {
                    this.props.goNext();
                    this.props.history.push(`/utility-billing/utility?currentBillingId=${this.props.currentDataId}&ub-step=billing-statements-sending`, { isEdit: true });
                  }
                }}>{'Klicken Sie auf Weiter'}</span>{', um die Abrechnungen zu versenden.'}</p> {/* lang.utility.text_ub_desc */}
            <div className="utility-bills-total-details" style={isTablet ? {display: 'flex', justifyContent: 'space-between', marginTop: '30px'} : {marginTop: '30px', justifyContent: 'space-between', alignItems: 'flex-end'} }>
              <div style={{display: 'flex'}}>
                {skeletonLoading ? 
                <Skeleton visible={skeletonLoading} style={{width: '220px', marginRight: !isTablet && '24px', height: '36px', marginTop: '22px'}}/>
                :
                <div className="interim-unit-dropbox" style={{width: '220px', marginRight: !isTablet && '24px'
                }}
                >
                  <lable>{lang.utility.text_tenant}</lable>
                  <UncontrolledDropdown
                    id="myid"
                    onBlur={() => {
                      this.setState({
                        DropdownState: document.getElementById('myid').firstElementChild.getAttribute('aria-expanded'),
                      });
                    }}
                    onFocus={() => {
                      this.setState({
                        DropdownState: document.getElementById('myid').firstElementChild.getAttribute('aria-expanded'),
                      });
                    }}
                    onClick={() => {
                      this.setState({
                        DropdownState: document.getElementById('myid').firstElementChild.getAttribute('aria-expanded'),
                      });
                    }}
                    disabled={showEmpty}
                  >
                    <DropdownToggle className="dropdown-select" disabled={showEmpty} style={{width: (isTablet || utilityData.isSecured) && '366px'}}>
                      <span className='dropdown-scroll' style={{textOverflow: 'ellipsis', overflowX: 'scroll', whiteSpace: 'nowrap', marginRight: '5px'}}>{this.state.selectedTenantData?.tenantName}</span>
                      {this.state.DropdownState === 'true' ? (
                        <img src={downArrow} alt="" />
                      ) : (
                        <img src={uparrow} alt="" />
                      )}
                    </DropdownToggle>
                    <DropdownMenu className="ub-menu" style={{width: 'auto'}}>
                      {allTenants?.sort((a, b) => a.tenantName.localeCompare(b.tenantName)).map((utilityRow) => {
                        console.log('utilityRow: ',utilityRow)
                        return (
                          <DropdownItem
                            onClick={() => {
                              this.onInputChange('selectedTenantID', utilityRow.tenantID);
                            }}
                            className="utilityRow-options">
                            <span className=" ">
                              {utilityRow.rentalUnitName+': '} <span style={{fontWeight: 'bold'}}> {utilityRow.tenantName} </span>
                              {  ' (' + dayjs(utilityRow.tenantOccupation.startDate, 'YYYY-MM-DD').format('DD.MM.YYYY') + ' - ' + dayjs(utilityRow.tenantOccupation.endDate, 'YYYY-MM-DD').format('DD.MM.YYYY') + ')' }
                            </span>
                          </DropdownItem>
                        );
                      })}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>}
                {!isTablet && skeletonLoading ? <Skeleton visible={skeletonLoading} style={{width: '620px', height: '36px', marginTop: '22px'}}/>
                :
                (!isTablet && !utilityData.isSecured) && this.renderUtilityBillsTotalDetailsSub()}
              </div>
              <Group //className="print-pdf" 
              noWrap //style={{width: 'auto'}}
              >
                
                {/* <div style={{marginLeft: 10}}>
                  {skeletonLoading ? 
                  <Skeleton visible={skeletonLoading} style={{height: '36px', marginTop: !isTablet && '22px', width: '145px'}}/>
                  :
                  <ColorButton
                    onClick={() => this.setState({
                      open: true, 
                      freezeAlertHeading: utilityData.isSecured ? lang.utility.text_freeze_alert_heading : lang.utility.text_unfreeze_alert_heading, 
                      freezeAlertMessage: utilityData.isSecured ? lang.utility.text_freeze_alert_message : (<span>{lang.utility.text_unfreeze_alert_message} <a href="https://wissen.hellohousing.de/was-bedeutet-eine-festschreibung-der-betriebskostenabrechnung" target="_blank">(mehr erfahren)</a>.</span>)
                    })}
                    text={utilityData.isSecured ? 'Festschreibung aufheben' : 'Festschreiben'}
                    rightImage={{ img: utilityData.isSecured ? LockOpenIcon : LockIcon, style: { width: '16px', height: '16px', marginRight: window.innerWidth <= 1400 ? '10px' : 0 } }}
                  />}
                </div>
                <div>
                {skeletonLoading ? 
                <Skeleton visible={skeletonLoading} style={{height: '36px', marginTop: !isTablet && '22px', width: '110px'}}/>
                :
                <ColorButton
                  // onClick={() => this.downloadPDF(data.currentTenant.fileURL)}
                  onClick={() => this.downloadPDF(this.state.selectedTenantData.billingStatementId)}
                  text={lang.utility.text_print_pdf}
                  rightImage={{ img: PrintIcon, style: { width: '16px', height: '16px', marginRight: window.innerWidth <= 1400 ? '10px' : 0  } }}
                  disabled={showEmpty || (utilityBillTableData && utilityBillTableData.fileURL == '')}
                />}
                </div> */}
                      <Group noWrap spacing={0}//style={{display: 'flex', alignItems: 'center', marginRight: '10px'}}
                      >
                        {/* {<Text sx={(theme) => ({ color: '#454576', fontSize: '14px', marginRight: '5px' })}>{'Festschreiben:'}</Text>} */}
                        <Tooltip title={utilityData.isSecured ? 'Festschreibung aufheben' : 'Festschreiben'} >
                          <IconButton 
                          aria-label="filter list" 
                          onClick={() => this.setState({
                            open: true, 
                            freezeAlertHeading: utilityData.isSecured ? lang.utility.text_freeze_alert_heading : lang.utility.text_unfreeze_alert_heading, 
                            freezeAlertMessage: utilityData.isSecured ? lang.utility.text_freeze_alert_message : (<span>{lang.utility.text_unfreeze_alert_message} <a href="https://wissen.hellohousing.de/was-bedeutet-eine-festschreibung-der-betriebskostenabrechnung" target="_blank">(mehr erfahren)</a>.</span>)
                          })}
                          style={{
                            background: 'white', 
                            border: '1px solid #DADAE5', 
                            boxShadow: '0 1px 2px 0 rgba(14, 14, 29, .06)', 
                            padding: '5px'
                            }}>
                            <img
                            src={utilityData.isSecured ? LockOpenIconRed : LockIcon} 
                            style={{
                              width: '15px',
                              height: '15px',
                              }}/>
                          </IconButton>
                        </Tooltip>
                      </Group>
                      <Group noWrap spacing={0} //style={{display: 'flex', alignItems: 'center', marginRight: '10px'}}
                      >
                        {/* {<Text sx={(theme) => ({ color: '#454576', fontSize: '14px', marginRight: '5px' })}>Drucken:</Text>} */}
                        <Tooltip title="Drucken" >
                          <IconButton 
                          aria-label="filter list" 
                          onClick={() => this.downloadPDF(this.state.selectedTenantData.billingStatementId)}
                          style={{
                            background: 'white', 
                            border: '1px solid #DADAE5', 
                            boxShadow: '0 1px 2px 0 rgba(14, 14, 29, .06)', 
                            padding: '5px'
                            }}>
                            <img
                            src={PrintIconBlue} 
                            style={{
                              width: '15px',
                              height: '15px',
                              }}/>
                          </IconButton>
                        </Tooltip>
                      </Group>
                      <Group noWrap spacing={0}>
                        <Popover
                        opened={currentSubscription.subscriptionKey.split('_')[0] === 'STARTER' && hoverOnStarter2}
                        onClose={() => this.setState({hoverOnStarter2: false})}
                        position="top"
                        placement="center"
                        withArrow
                        trapFocus={false}
                        closeOnEscape={false}
                        transition="pop-top-left"
                        width={300}
                        target={
                        <Group onMouseEnter={()=> this.setState({hoverOnStarter2: true})} noWrap spacing={0}>
                          <Tooltip title="Kontrollblatt" >
                            <IconButton 
                            aria-label="filter list" 
                            onClick={() => currentSubscription.subscriptionKey.split('_')[0] !== 'STARTER' && this.setState({openShowControlSheetForBillingStatementModal: true})}
                            style={{
                              background: 'white', 
                              border: '1px solid #DADAE5', 
                              boxShadow: '0 1px 2px 0 rgba(14, 14, 29, .06)', 
                              padding: '5px'
                              }}>
                              <TableChartIcon
                              style={{
                                width: '15px',
                                height: '15px',
                                color: '#5555FF'
                                }}/>
                            </IconButton>
                          </Tooltip>
                        </Group>
                        }
                        >
                          <Stack style={{padding: '10px'}} spacing={15}>
                            <Group>
                              <span style={{color: 'rgb(85, 85, 255)', fontSize: '1rem'}}>Abrechnungen kontrollieren</span>
                            </Group>
                            <Group style={{justifyContent: 'center'}} noWrap>
                              <img src={GoldenLockIcon} width='18px'/>
                              <span style={{color: '#5f5f7a', fontSize: '11px', fontWeight: '400'}}>Alle Abrechnungen auf einen Blick vergleichen mit Plausibilitätscheck und Excel-Export - mit hellohousing <span onClick={()=> this.props.history.push('/profile/yourSubscription', {newSubscription: true})} style={{textDecoration: 'underline', fontWeight: '500', cursor: 'pointer'}}>Premium</span>.</span>
                            </Group>
                            <Group>
                              <ColorButton
                              text={currentSubscription ? (currentSubscription.subscriptionName === 'Starter' ? (accountData.isPremiumPlanTested === false ? '1 Monat kostenlos testen' : 'Jetzt freischalten') : currentSubscription.subscriptionName === 'Premium' ? (accountData.isProfessionalPlanTested === false ? '1 Monat kostenlos testen' : 'Jetzt freischalten') : 'Jetzt freischalten') : 'Jetzt freischalten'}
                              onClick={()=> this.props.history.push('/profile/yourSubscription', {newSubscription: true})}
                              className={'purple-btn'}
                              />
                            </Group>
                          </Stack>
                        </Popover>
                      </Group>
              </Group>
            </div>
            {isTablet && skeletonLoading ? <Skeleton visible={skeletonLoading} style={{width: '620px', height: '36px', marginTop: '22px'}}/>
              :
              (isTablet || utilityData.isSecured) && this.renderUtilityBillsTotalDetailsSub()}
            {/* <div className="mobile-view-tenant-bill-info">
              <div className="days">
                <lable>{lang.utility.text_stayed_for}</lable>
                <p>
                  {this.state.selectedTenantData?.stayedFor?.toString()} {lang.newKeys.text_days}
                </p>
              </div>
              <div className="total-allocation">
                <lable>{lang.utility.text_total_allocation}</lable>
                <p>{this.state.selectedTenantData ? Autoload.MainHelper.ConverterCurrencyDe_DisplayOnly(this.state.selectedTenantData.totalAllocations, false) + ' €' : '0,00 €'}</p>
              </div>
              <div className="payments">
                <lable>{lang.utility.text_prepayments}</lable>
                <p>{this.state.selectedTenantData ? Autoload.MainHelper.ConverterCurrencyDe_DisplayOnly(this.state.selectedTenantData.prepayments, false) + ' €' : '0,00 €'}</p>
              </div>
              <div className="balance">
                <lable>{lang.utility.text_balance}</lable>
                <p className={balance > 0 ? 'green' : 'red'}>
                  {this.state.selectedTenantData
                    ? Autoload.MainHelper.ConverterCurrencyDe_DisplayOnly(this.state.selectedTenantData.prepayments - this.state.selectedTenantData.totalAllocations, false) + ' €'
                    : '0,00 €'}
                </p>
              </div>
            </div> */}

            {/* <table className={`table borderless mb-0 ub-table`}>
                <thead>
                  <th className="table-heading ub-heading">{this.getTableLabels()}</th>
                </thead>
                <tbody className="ub-body"> */}
            {/* {data.currentTenant &&
                    data.currentTenant.tenantRows &&
                    data.currentTenant.tenantRows.map((billRow) => {
                      return <UtilityBillsTableRow tenantID={this.state.selectedTenantID} billRow={billRow} />;
                    })} */}
            <HeadingContainer 
              heading={'Betriebskostenabrechnung'} 
              headingClassName={'ub-betriebskostenabrechung mt-5'}
              headingRightContentClassName={'mt-5 ub-betriebskostenabrechung-right-content'}
              rightContent={!isTablet &&
                <Group noWrap>
                  <div onMouseEnter={()=> this.setState({hoverOnStarter: true})}>
                    <SwitchContainer
                    className="ub-vat-labour-switch"
                    textClassName={`tooltips ub-vat-labour-text ub-labour-switch-label`}
                    onChange={()=> this.setState({laborCostSwitch: !laborCostSwitch} //TODO: if tenant -> contractType === 'private' ? this.setState({laborCostSwitch: !laborCostSwitch} : this.setState({vatSwitch: !vatSwitch}
                    , ()=> this.updateUtilityBilling())}
                    checked={laborCostSwitch} //TODO: if tenant -> contractType === 'private' ? laborCostSwitch : vatSwitch
                    switchText={'Lohnkosten ausweisen (private Mieter)'}//TODO: if tenant -> contractType === 'private' ? 'Lohnkosten ausweisen (private Mieter)' : 'MwSt. ausweisen (gewerbliche Mieter)'
                    disabled={utilityData.isSecured}
                    />
                  </div>
                  <Popover
                  opened={currentSubscription.subscriptionKey.split('_')[0] === 'STARTER' && hoverOnStarter}
                  onClose={() => this.setState({hoverOnStarter: false})}
                  position="top"
                  placement="center"
                  withArrow
                  trapFocus={false}
                  closeOnEscape={false}
                  transition="pop-top-left"
                  width={300}
                  //styles={{ body: { pointerEvents: 'none' } }}
                  //style={{width: '100%'}}
                  target={
                    <Group onMouseEnter={()=> this.setState({hoverOnStarter: true})} noWrap spacing={0}>
                      <SwitchContainer
                      className="ub-vat-labour-switch"
                      textClassName={`tooltips ub-vat-labour-text ub-vat-switch-label`}
                      onChange={()=> this.setState({vatSwitch: !vatSwitch} //TODO: if tenant -> contractType === 'private' ? this.setState({laborCostSwitch: !laborCostSwitch} : this.setState({vatSwitch: !vatSwitch}
                      , ()=> this.updateUtilityBilling())}
                      checked={vatSwitch} //TODO: if tenant -> contractType === 'private' ? laborCostSwitch : vatSwitch
                      switchText={'MwSt. ausweisen (gewerbliche Mieter)'}//TODO: if tenant -> contractType === 'private' ? 'Lohnkosten ausweisen (private Mieter)' : 'MwSt. ausweisen (gewerbliche Mieter)'
                      disabled={currentSubscription.subscriptionKey.split('_')[0] === 'STARTER' || utilityData.isSecured}
                      />
                      {this.renderVatPopover()}
                    </Group>
                  }
                >
                  <Stack style={{padding: '10px'}} spacing={15}>
                    <Group>
                      <span style={{color: 'rgb(85, 85, 255)', fontSize: '1rem'}}>MwSt. für gewerbliche Mieter</span>
                    </Group>
                    <Group style={{justifyContent: 'center'}} noWrap>
                      <img src={GoldenLockIcon} width='18px'/>
                      <span style={{color: '#5f5f7a', fontSize: '11px', fontWeight: '400'}}>MwSt. für gewerbliche Mieter ausweisen mit hellohousing <span onClick={()=> this.props.history.push('/profile/yourSubscription', {newSubscription: true})} style={{textDecoration: 'underline', fontWeight: '500', cursor: 'pointer'}}>Premium</span>.</span>
                    </Group>
                    {/* <Group style={{justifyContent: 'center'}}>
                      
                    </Group> */}
                    <Group>
                        <ColorButton
                        text={currentSubscription ? (currentSubscription.subscriptionName === 'Starter' ? (accountData.isPremiumPlanTested === false ? '1 Monat kostenlos testen' : 'Jetzt freischalten') : currentSubscription.subscriptionName === 'Premium' ? (accountData.isProfessionalPlanTested === false ? '1 Monat kostenlos testen' : 'Jetzt freischalten') : 'Jetzt freischalten') : 'Jetzt freischalten'}
                        onClick={()=> this.props.history.push('/profile/yourSubscription', {newSubscription: true})}
                        className={'purple-btn'}
                        />
                    </Group>
                  </Stack>
                  </Popover>
              </Group>
              }
              subheading={isTablet &&
              <Group style={{marginTop: '10px'}} noWrap>
                  <div onMouseEnter={()=> this.setState({hoverOnStarter: true})}>
                    <SwitchContainer
                    className="ub-vat-labour-switch"
                    textClassName={`tooltips ub-vat-labour-text ub-labour-switch-label`}
                    onChange={()=> this.setState({laborCostSwitch: !laborCostSwitch} //TODO: if tenant -> contractType === 'private' ? this.setState({laborCostSwitch: !laborCostSwitch} : this.setState({vatSwitch: !vatSwitch}
                    , ()=> this.updateUtilityBilling())}
                    checked={laborCostSwitch} //TODO: if tenant -> contractType === 'private' ? laborCostSwitch : vatSwitch
                    switchText={'Lohnkosten ausweisen (private Mieter)'}//TODO: if tenant -> contractType === 'private' ? 'Lohnkosten ausweisen (private Mieter)' : 'MwSt. ausweisen (gewerbliche Mieter)'
                    disabled={utilityData.isSecured}
                    />
                  </div>
                  <Popover
                  opened={currentSubscription.subscriptionKey.split('_')[0] === 'STARTER' && hoverOnStarter}
                  onClose={() => this.setState({hoverOnStarter: false})}
                  position="top"
                  placement="center"
                  withArrow
                  trapFocus={false}
                  closeOnEscape={false}
                  transition="pop-top-left"
                  width={300}
                  //styles={{ body: { pointerEvents: 'none' } }}
                  //style={{width: '100%'}}
                  target={
                    <Group onMouseEnter={()=> this.setState({hoverOnStarter: true})} noWrap>
                      <SwitchContainer
                      className="ub-vat-labour-switch"
                      textClassName={`tooltips ub-vat-labour-text ub-vat-switch-label`}
                      onChange={()=> this.setState({vatSwitch: !vatSwitch} //TODO: if tenant -> contractType === 'private' ? this.setState({laborCostSwitch: !laborCostSwitch} : this.setState({vatSwitch: !vatSwitch}
                      , ()=> this.updateUtilityBilling())}
                      checked={vatSwitch} //TODO: if tenant -> contractType === 'private' ? laborCostSwitch : vatSwitch
                      switchText={'MwSt. ausweisen (gewerbliche Mieter)'}//TODO: if tenant -> contractType === 'private' ? 'Lohnkosten ausweisen (private Mieter)' : 'MwSt. ausweisen (gewerbliche Mieter)'
                      disabled={currentSubscription.subscriptionKey.split('_')[0] === 'STARTER' || utilityData.isSecured}
                      />
                      {this.renderVatPopover()}
                    </Group>
                  }
                >
                  <Stack style={{padding: '10px'}} spacing={15}>
                    <Group>
                      <span style={{color: 'rgb(85, 85, 255)', fontSize: '1rem'}}>MwSt. für gewerbliche Mieter</span>
                    </Group>
                    <Group style={{justifyContent: 'center'}} noWrap>
                      <img src={GoldenLockIcon} width='18px'/>
                      <span style={{color: '#5f5f7a', fontSize: '11px', fontWeight: '400'}}>MwSt. für gewerbliche Mieter ausweisen mit hellohousing <span onClick={()=> this.props.history.push('/profile/yourSubscription', {newSubscription: true})} style={{textDecoration: 'underline', fontWeight: '500', cursor: 'pointer'}}>Premium</span>.</span>
                    </Group>
                    {/* <Group style={{justifyContent: 'center'}}>
                      
                    </Group> */}
                    <Group>
                        <ColorButton
                        text={currentSubscription ? (currentSubscription.subscriptionName === 'Starter' ? (accountData.isPremiumPlanTested === false ? '1 Monat kostenlos testen' : 'Jetzt freischalten') : currentSubscription.subscriptionName === 'Premium' ? (accountData.isProfessionalPlanTested === false ? '1 Monat kostenlos testen' : 'Jetzt freischalten') : 'Jetzt freischalten') : 'Jetzt freischalten'}
                        onClick={()=> this.props.history.push('/profile/yourSubscription', {newSubscription: true})}
                        className={'purple-btn'}
                        />
                    </Group>
                  </Stack>
                  </Popover>
                  {/* <div>
                    <SwitchContainer
                    className="ub-vat-labour-switch"
                    textClassName={`tooltips ub-vat-labour-text ub-labour-switch-label`}
                    onChange={()=> this.setState({hideTenantZeroCostsSwitch: !hideTenantZeroCostsSwitch} //TODO: if tenant -> contractType === 'private' ? this.setState({laborCostSwitch: !laborCostSwitch} : this.setState({vatSwitch: !vatSwitch}
                    //, ()=> this.updateUtilityBilling()
                    )}
                    checked={hideTenantZeroCostsSwitch} //TODO: if tenant -> contractType === 'private' ? laborCostSwitch : vatSwitch
                    switchText={'XXX'}//TODO: if tenant -> contractType === 'private' ? 'Lohnkosten ausweisen (private Mieter)' : 'MwSt. ausweisen (gewerbliche Mieter)'
                    disabled={utilityData.isSecured}
                    />
                  </div> */}
              </Group>
              }
            />
            <Skeleton visible={skeletonLoading} style={{marginTop: '20px'}}>
              <UtilityBillTable
                selectedTenantData={this.state.selectedTenantData}
                utilityData={utilityData}
                warmWaterCostTypeFound={this.state.warmWaterCostTypeFound}
                vatSwitch={vatSwitch}
                laborCostSwitch={laborCostSwitch}
                updateCommentLocalState={this.updateCommentLocalState}
                goBack={()=> this.props.goBack()}
                messagePreview={false}
                hideTenantZeroCostsSwitch={hideTenantZeroCostsSwitch}
                isPreTaxInfoHidden={isPreTaxInfoHidden}
              />
            </Skeleton>
            {propertySelected.propertyType === 'REGULAR' &&
            <HeadingContainer 
              heading={
                <Group>
                <span>Ablesungen und Verbräuche</span>
                <SwitchContainer
                  className="heating-cost-switch"
                  textClassName={'tooltips ub-meter-readings-switch'}
                  onChange={this.handleReadingsSwitchChange}
                  checked={this.state.readingsSwitch}
                  switchText={'einblenden'}
                  disabled={utilityData.isSecured}
                />
                </Group>} 
              headingClassName={'ub-betriebskostenabrechung mt-5'}
            />}
            {this.state.readingsSwitch && propertySelected.propertyType === 'REGULAR' &&
            <Skeleton visible={skeletonLoading} style={{marginTop: '20px'}}>
              <MeterReadingsTable
                readings={this.state.readings}
                utilityData={utilityData}
              />
            </Skeleton>}
            {this.props.heatingFilter && propertySelected.propertyType === 'REGULAR' &&
            <>
            <HeadingContainer 
              heading={
              <Group>
              <span>Zusammensetzung der Heizungskosten</span>
              <SwitchContainer
                className="heating-cost-switch"
                textClassName={'tooltips ub-heating-costs-switch'}
                //textClassName={`tooltips ${this.state.heatingSwitch ? 'ub-heating-cost-switch-active' : 'ub-heating-cost-switch-deactive'}`}
                onChange={this.handleHeatingCostsSwitchChange}
                checked={this.state.heatingCostsSwitch}
                switchText={'einblenden'}
                disabled={utilityData.isSecured}
              />
              </Group>} 
              headingClassName={'ub-betriebskostenabrechung mt-5'}
              
            />
            {this.state.heatingCostsSwitch && 
            <Skeleton visible={skeletonLoading} style={{marginTop: '20px'}}>
              <CompositionOfHeatingCostsTable
                selectedTenantData={this.state.selectedTenantData}
                utilityData={utilityData}
                warmWaterCostTypeFound={this.state.warmWaterCostTypeFound}
                heatingCosts={this.state.heatingCosts}
                isTablet={isTablet}
                history={this.props.history}
                goBackTwice={this.props.goBackTwice}
              />
            </Skeleton>}
            </>}
            {this.props.heatingFilter && propertySelected.propertyType === 'REGULAR' && propertySelected.heatingType !== 'central without warm water' &&
              <>
              <HeadingContainer 
              heading={
              <Group>
              <span>Aufteilung Heizkosten in Kosten für Warmwasser und Heizkosten</span>
              <SwitchContainer
                className="heating-cost-switch"
                textClassName={'tooltips ub-cost-split-warmwater-and-heating-switch'}
                //textClassName={`tooltips ${this.state.heatingSwitch ? 'ub-heating-cost-switch-active' : 'ub-heating-cost-switch-deactive'}`}
                onChange={this.handleCostSplitWarmWaterAndHeatingSwitchChange}
                checked={this.state.costSplitWarmWaterAndHeatingSwitch}
                switchText={'einblenden'}
                disabled={utilityData.isSecured}
              />
              </Group>} 
              headingClassName={'ub-betriebskostenabrechung mt-5'}
            />
            {this.state.costSplitWarmWaterAndHeatingSwitch && 
            <Skeleton visible={skeletonLoading} style={{marginTop: '20px'}}>
              <CostSplitWarmWaterAndHeatingTable
                // selectedTenantData={this.state.selectedTenantData}
                // utilityData={utilityData}
                // warmWaterCostTypeFound={this.state.warmWaterCostTypeFound}
                // heatingCosts={this.state.heatingCosts}
                isTablet={isTablet}
                utilityBilling={this.props.utilityBillingData.find((ub) => ub.id == this.props.currentDataId)}
              />
            </Skeleton>}
            </>}

            {this.props.heatingFilter && propertySelected.propertyType === 'REGULAR' &&
            <>
            <HeadingContainer 
              heading={
              <Group>
              <span>Aufteilung Heizkosten in Grundkosten und Verbrauchskosten</span>
              <SwitchContainer
                className="heating-cost-switch"
                textClassName={'tooltips ub-split-base-consumption-cost-switch'}
                //textClassName={`tooltips ${this.state.heatingSwitch ? 'ub-heating-cost-switch-active' : 'ub-heating-cost-switch-deactive'}`}
                onChange={this.handleSplitBaseConsumptionCosts}
                checked={this.state.splitBaseConsumptionCostsSwitch}
                switchText={'einblenden'}
                disabled={utilityData.isSecured}
              />
              </Group>} 
              headingClassName={'ub-betriebskostenabrechung mt-5'}
              
            />
            {this.state.splitBaseConsumptionCostsSwitch && 
            <Skeleton visible={skeletonLoading} style={{marginTop: '20px'}}>
              <SplitBaseConsumptionCosts
                selectedTenantData={this.state.selectedTenantData}
                utilityData={utilityData}
                warmWaterCostTypeFound={this.state.warmWaterCostTypeFound}
                heatingCosts={this.state.heatingCosts}
                isTablet={isTablet}
              />
            </Skeleton>}
            </>}
            {/* New heating information sections start from here */}
            {this.props.heatingFilter && propertySelected.propertyType === 'REGULAR' &&
            <>
            <HeadingContainer 
              heading={
              <Group>
                <span style={{marginTop: '4px'}}>{'Energieverbrauch & CO'}<span style={{verticalAlign: 'sub', fontSize: '12px'}}>2</span>{'-Emissionen'}</span>
              <SwitchContainer
                className="heating-cost-switch"
                textClassName={'tooltips ub-energy-consumption-co2-emissions-switch'}
                onChange={this.handleEnergyConsumptionCO2EmissionsChange}
                checked={this.state.energyConsumptionCO2EmissionsSwitch}
                switchText={'einblenden'}
                disabled={utilityData.isSecured}
              />
              </Group>} 
              headingClassName={'ub-betriebskostenabrechung mt-5'}
              
            />
            {this.state.energyConsumptionCO2EmissionsSwitch && 
            <Skeleton visible={skeletonLoading} style={{marginTop: '20px'}}>
              <EnergyConsumptionAndCO2EmissionsCostTable
                selectedTenantData={this.state.selectedTenantData}
                utilityData={utilityData}
                warmWaterCostTypeFound={this.state.warmWaterCostTypeFound}
                heatingCosts={this.state.heatingCosts}
                isTablet={isTablet}
              />
            </Skeleton>}
            </>}

            {this.props.heatingFilter && propertySelected.propertyType === 'REGULAR' && utilityData?.data?.isCO2TaxActivated && (dayjs(utilityData?.data?.billingPeriod?.endDate).year() >= 2023) &&
            <>
            <HeadingContainer 
              heading={
              <Group>
                <span style={{marginTop: '4px'}}>{'Berechnung der CO'}<span style={{verticalAlign: 'sub', fontSize: '12px'}}>2</span>{'-Steuer'}</span>
              <SwitchContainer
                className="heating-cost-switch"
                textClassName={'tooltips ub-energy-consumption-co2-emissions-switch'}
                onChange={this.handleCO2TaxInfoSwitchSwitchChange}
                checked={this.state.co2TaxInfoSwitch}
                switchText={'einblenden'}
                disabled={utilityData.isSecured}
              />
              </Group>} 
              headingClassName={'ub-betriebskostenabrechung mt-5'}
              
            />
            {this.state.co2TaxInfoSwitch && 
            <Skeleton visible={skeletonLoading} style={{marginTop: '20px'}}>
              <CalculationOfCO2TaxTable
                selectedTenantData={this.state.selectedTenantData}
                utilityData={utilityData}
                warmWaterCostTypeFound={this.state.warmWaterCostTypeFound}
                heatingCosts={this.state.heatingCosts}
                isTablet={isTablet}
              />
            </Skeleton>}
            </>}

            {this.props.heatingFilter && utilityData.data && propertySelected.propertyType === 'REGULAR' &&
            <>
            <HeadingContainer 
              heading={
              <Group>
                <span>{'Entwicklung Heizungsverbrauch und Heizungskosten'}</span>
                <SwitchContainer
                  className="heating-cost-switch"
                  textClassName={'tooltips ub-heating-consumption-bar-chart-switch'}
                  //textClassName={`tooltips ${this.state.heatingSwitch ? 'ub-heating-cost-switch-active' : 'ub-heating-cost-switch-deactive'}`}
                  onChange={this.handleCompareHeatingConsumptionBarChartChange}
                  checked={this.state.compareHeatingConsumptionBarChartSwitch}
                  switchText={'einblenden'}
                  disabled={utilityData.isSecured}
                />
              </Group>} 
              headingClassName={'ub-betriebskostenabrechung mt-5'}
              
            />
            {this.state.compareHeatingConsumptionBarChartSwitch && 
            <Skeleton visible={skeletonLoading} style={{marginTop: '20px'}}>
              {this.state.selectedTenantData && Object.keys(this.state.selectedTenantData).find((key)=> key === 'previousTenantHeatingEnergyAmountkWh') &&
              <CompareHeatingConsumptionBarChart
                selectedTenantData={this.state.selectedTenantData}
                utilityData={utilityData}
                warmWaterCostTypeFound={this.state.warmWaterCostTypeFound}
                heatingCosts={this.state.heatingCosts}
                isTablet={isTablet}
              />}
            </Skeleton>}
            </>}

            {this.props.heatingFilter && propertySelected.propertyType === 'REGULAR' &&
            <>
            <HeadingContainer 
              heading={
              <Group>
                <span>{'Energieverbrauch im Hausvergleich'}</span>
                <SwitchContainer
                  className="heating-cost-switch"
                  textClassName={'tooltips ub-benchmarking-average-user-building-switch'}
                  //textClassName={`tooltips ${this.state.heatingSwitch ? 'ub-heating-cost-switch-active' : 'ub-heating-cost-switch-deactive'}`}
                  onChange={this.handleEnergyLevelsInfoSwitchChange}
                  checked={this.state.energyLevelsInfoSwitch}
                  switchText={'einblenden'}
                  disabled={utilityData.isSecured}
                />
              </Group>} 
              headingClassName={'ub-betriebskostenabrechung mt-5'}
              
            />
            {this.state.energyLevelsInfoSwitch &&
            <Skeleton visible={skeletonLoading} style={{marginTop: '20px'}}>
              <BenchmarkingAverageUserBuilding
                selectedTenantData={this.state.selectedTenantData}
                utilityData={utilityData}
                warmWaterCostTypeFound={this.state.warmWaterCostTypeFound}
                heatingCosts={this.state.heatingCosts}
                isTablet={isTablet}
              />
            </Skeleton>}
            </>}

            {this.props.heatingFilter && propertySelected.propertyType === 'REGULAR' &&
            <>
            <HeadingContainer 
              heading={
              <Group>
                <span>{'Energieberatung der Verbraucherzentrale'}</span>
                <SwitchContainer
                  className="heating-cost-switch"
                  textClassName={'tooltips ub-energy-advice-switch'}
                  //textClassName={`tooltips ${this.state.heatingSwitch ? 'ub-heating-cost-switch-active' : 'ub-heating-cost-switch-deactive'}`}
                  onChange={this.handleEnergyAdviceCenterInfoSwitchChange}
                  checked={this.state.energyAdviceCenterInfoSwitch}
                  switchText={'einblenden'}
                  disabled={utilityData.isSecured}
                />
              </Group>} 
              headingClassName={'ub-betriebskostenabrechung mt-5'}
              
            />
            {this.state.energyAdviceCenterInfoSwitch &&
            <Skeleton visible={skeletonLoading} style={{marginTop: '20px'}}>
              <Stack spacing={10} style={{background: 'white', padding: '20px', width: '80%', height: '125px'}}>
                <span style={{fontWeight: '400'}}>Bei der Energieberatung der Verbraucherzentrale erhalten Sie Tipps und Beratung zur Reduzierung des Heizungsverbrauches.</span>
                <span style={{fontWeight: '400'}}><h5>Webseite</h5>
                  <a style={{color: '#5555FF', textDecoration: 'none'}} href='https://verbraucherzentrale-energieberatung.de/heizen/heizkostenabrechnung/' target='_blank'>
                    https://verbraucherzentrale-energieberatung.de/heizen/heizkostenabrechnung/
                  </a>
                </span>
              </Stack>
            </Skeleton>}
            </>}

            {propertySelected.propertyType === 'REGULAR' &&//this.props.heatingFilter &&
            <>
            <HeadingContainer 
              heading={
              <Group>
                <span>{'Kontaktinformationen zur Schlichtungsstelle'}</span>
                <SwitchContainer
                  className="heating-cost-switch"
                  textClassName={'tooltips ub-contact-information-switch'}
                  //textClassName={`tooltips ${this.state.heatingSwitch ? 'ub-heating-cost-switch-active' : 'ub-heating-cost-switch-deactive'}`}
                  onChange={this.handleArbitraryBoardInfoSwitchSwitchChange}
                  checked={this.state.arbitraryBoardInfoSwitch}
                  switchText={'einblenden'}
                  disabled={utilityData.isSecured}
                />
              </Group>} 
              headingClassName={'ub-betriebskostenabrechung mt-5'}
              
            />
            {this.state.arbitraryBoardInfoSwitch && 
            <Skeleton visible={skeletonLoading} style={{marginTop: '20px'}}>
              <Stack style={{background: 'white', padding: '20px', width: '80%'}}>
                <span style={{fontWeight: '400'}}>Die Universalschlichtungsstelle des Bundes hilft Streitigkeiten im Rahmen der Betriebskostenabrechnung beizulegen.</span> 
                <span style={{fontWeight: '400'}}><h5>Webseite</h5><a style={{color: '#5555FF', textDecoration: 'none'}} href='https://www.universalschlichtungsstelle.de'>https://www.universalschlichtungsstelle.de</a></span>
                <span style={{fontWeight: '400'}}><h5>Email</h5><a style={{color: '#5555FF', textDecoration: 'none'}} href='mailto:mail@universalschlichtungsstelle.de'>mail@universalschlichtungsstelle.de</a></span>
                <span style={{fontWeight: '400'}}><h5>Anschrift</h5>Straßburger Str. 8<br/><span>Kehl, 77694</span><br/><span>Deutschland</span></span>
              </Stack>
            </Skeleton>}
            </>}
            {/* New heating information sections end here */}
            {/* </tbody>
              </table> */}
          </div>
        </div>
        {/* )} */}
        {openShowControlSheetForBillingStatementModal &&
        <ShowControlSheetForBillingStatementModal
        open={openShowControlSheetForBillingStatementModal}
        toggle={()=> this.setState({openShowControlSheetForBillingStatementModal: false})}
        heatingFilter={this.props.heatingFilter}
        allBillingStatements={allTenants}
        propertySelected={this.props.propertySelected}
        utilityData={utilityData}
        />}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  // console.log('stateee', state.UtilityBillingReducer)
  return {
    // allUtilityBills: state.UtilityBillingReducer.utilityBillingData,
    allReducer: state,
    currentDataId: state.UtilityBillingReducer.currentBillingId,
    tenantBills: state.UtilityBillingReducer.utilityBillingData,
    utilityBillingData: state.UtilityBillingReducer.utilityBillingData,
    heatingFilter: state.UtilityBillingReducer.HeatingAllocationFilter,
    propertySelected: state.generalReducer.property,
    currentSubscription: state.generalReducer.currentSubscription,
    accountData: state.generalReducer.accountData
  };
};

const mapDispatchToProps = {
  setTenantBills,
  setUtilityBillingData
};

export default connect(mapStateToProps, mapDispatchToProps)(UtilityBillsTable);
