import React, { Component } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { setAddEditFormData } from '../../../../redux/action/Tenant/TenantActions';
import GreenTick from '../../../../assets/images/module/general/GreenTick.svg';
import Autoload from '../../../../neoverv/Autoload';
import { Skeleton } from '@mantine/core';
import { hideNotification, showNotification } from '@mantine/notifications';
import moment from 'moment';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
const { checkProperty, loader } = Autoload.MainHelper;

export class TenantNotes extends Component {
  state = {
    notes: '',
    skeletonLoading: false,
  };

  componentDidMount() {
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'none';
    this.setState({skeletonLoading: true});
    //get form data from redux and set state with that data
    const { formData } = this.props;
    this.setState({
      notes: checkProperty(formData, 'note'),
    });
    this.ShowLoader();
  }

  onInputChange = (field, value) => {
    this.setState({ [field]: value });
  };

  //save data to redux everytime state changes
  componentDidUpdate(prevProps, prevState) {
    if (this.state !== prevState) {
      const { notes } = this.state;
      let data = {
        ...this.props.formData,
        note: notes,
      };
      //save data to redux
      this.props.setAddEditFormData(data);
    }
  }
  ShowLoader = () => {
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'none';
    var emptyDiv = document.getElementById('empty-tenant-notes-page');
    emptyDiv.style.display = 'none';
    var dataDiv = document.getElementById('tenant-notes-page');
    dataDiv.style.display = 'block';
    this.setState({skeletonLoading: false});
  };

  editTenant = () => {
    hideNotification('saveTenantData');
    console.log('form data', this.props.formData);
    var data = JSON.stringify({
      tenantId: checkProperty(this.props.formData, 'id'),
      rentalUnitId: checkProperty(this.props.formData, 'rentalUnitId'),
      fullName: checkProperty(this.props.formData, 'name'),
      contractType: checkProperty(this.props.formData, 'type'),
      vacant: false,
      phone: checkProperty(this.props.formData, 'phone'),
      email: checkProperty(this.props.formData, 'email'),
      moveInDate:
        checkProperty(this.props.formData, 'in') == ''
          ? ''
          : moment(checkProperty(this.props.formData, 'in'), 'DD.MM.YYYY').format('YYYY-MM-DDTHH:mm:ss'),
      moveOutDate:
        checkProperty(this.props.formData, 'out') == null || checkProperty(this.props.formData, 'out') == ''
          ? null
          : moment(checkProperty(this.props.formData, 'out'), 'DD.MM.YYYY').format('YYYY-MM-DDTHH:mm:ss'),
      notes: checkProperty(this.props.formData, 'note'),
      numberOfOccupants: parseInt(checkProperty(this.props.formData, 'numberOfOccupants')),
    });
    console.log('form data', data);
    // debugger
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'tenants/' + checkProperty(this.props.formData, 'id'),
      'PUT',
      data,
      null,
      false,
      true,
      false,
      this,
      'handleEditTenant',
      null,
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );

    // this.handleEditTenant()
  };
  handleEditTenant = (response) => {
    console.log('response edit tenant api', response);
    // const unitId = checkProperty(this.props.history.location.state, 'unitId')
    // let record = {
    //   ...this.props.formData,
    // }
    // this.props.editTenant(unitId, record)
    if(response.status === true && response.statuscode === 200){
      showNotification({
        id: 'saveTenantData',
        message: 'Die Änderungen wurden übernommen.',
        icon: <img src={GreenTick} alt='green-tick'/>,
      });
    }
    loader(false);
  };

  render() {
    return (
      <>
        <div id="empty-tenant-notes-page"></div>
        <div className="tenant-notes-section" id="tenant-notes-page" style={{ display: 'block' }}>
          <Form noValidate>
            <Row>
              <Col xs={12}>
                <Form.Group>
                  <Form.Label> {lang.tenant.text_notes} </Form.Label>
                  <Skeleton visible={this.state.skeletonLoading}>
                    <Form.Control
                      id="tenant-note"
                      name="tenant-note"
                      // isInvalid={false}
                      type="textarea"
                      placeholder=""
                      value={this.state.notes}
                      onChange={(e) => this.onInputChange('notes', e.target.value)}
                      onBlur={()=> this.editTenant()}
                      as="textarea"
                      style={{ height: 108, width: 352 }}
                    />
                  </Skeleton>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentRecord: state.tenantDataReducer.currentTenant,
    formData: state.tenantDataReducer.addEditFormData,
  };
};
const actions = {
  setAddEditFormData,
};

export default connect(mapStateToProps, actions)(TenantNotes);
