import Autoload from "../Autoload";

const redirection_404 = (route = "/404") => {
    window.location.href = route;
  };
  
const redirection_500 = (route = "/500") => {
  // window.location.href = route;
  // alert(route)
};

const redirection_error = (route = "/500") => {
  // window.location.href = route;
  // alert(route)
};

const redirection_login = () => {
  window.location.href = "/login";
};

const redirection_dashboard = () => {
  window.location.href = "/dashboard";
};


const redirection = (route = "/") => {
  window.location.href = route;
};

const reload = () => {
  window.location.reload();
};

// const check_auth = (page = null,profile = {}) => {
 
//   // if(skip_auth(page.props.location.pathname)){

//   //   return true;
 
//   // }
  
//   var auth = Autoload.MainHelper.checkProperty(profile,"auth");
//   var token  = "";
//   if(auth == ""){    
//     redirection_login();
//   }
//   token = Autoload.MainHelper.checkProperty(auth,"token");
//   if(token == ""){
//     redirection_login();
//   }

//   return true;


// };

const Redirection = {
    redirection_404,
    redirection_500,
    redirection_error,
    redirection_login,
    redirection_dashboard,
    reload,
    redirection
    // check_auth
  };
  


  export default (Redirection);