import React, { Component } from 'react';
import { ColorButton } from '../../../../../neoverv';
import { Row, Col, Form } from 'react-bootstrap';
import EyeIcon from '../../../../../assets/images/module/general/EyeIcon.svg';
import { connect } from 'react-redux';
import Autoload from '../../../../../neoverv/Autoload';
import { updateUserProfile } from '../../../../../redux/action/General/generalActions';
import { showNotification } from '@mantine/notifications';
import RedCross from '../../../../../assets/images/module/general/RedCross.svg';
import GreenTick from '../../../../../assets/images/module/general/GreenTick.svg';
import { Stack } from '@mantine/core';
import ChangePasswordModal from '../../../../../neoverv/components/Module/General/CustomModal/ChangePasswordModal';
var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default].general;
var lang_newKeys = Setting[Setting.language_default].newKeys;
//const { checkProperty } = Autoload.MainHelper;
const MOBILE_SIZE = 500;

export class ProfilePassword extends Component {
  state = {
    newPassword: '',
    newPassErr: false,
    currentPassword: '',
    currentPassErr: false,
    isMobile: window.innerWidth < MOBILE_SIZE,
    openChangePasswordModal: false
  };

  toggleShowPassword = (id) => {
    var x = document.getElementById(id);
    if (x.type === 'text') {
      x.setAttribute('type', 'password');
    } else {
      x.setAttribute('type', 'text');
    }
  };

  onInputChange = (field, value) => {
    this.setState({ [field]: value });
  };

  savePasswordChanges = (e) => {
    e.preventDefault();
    this.setState({ newPassErr: false, currentPassErr: false });
    const validPass = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    let newPassErr,
      currentPassErr = false;
    if (!this.state.newPassword || !validPass.test(this.state.newPassword)) {
      newPassErr = true;
    }
    // if (!this.state.currentPassword || !validPass.test(this.state.currentPassword)) {
    //   currentPassErr = true;
    // }
    this.setState({ newPassErr, currentPassErr });
    if (!newPassErr && !currentPassErr) {
      // console.log('call api')
      this.put_password_api();
    }
    // if (newPassErr || currentPassErr) {
    //   this.setState({ newPassErr, currentPassErr });
    //   return false;
    // } else {
    //   this.setState({ newPassErr, currentPassErr });
    //   return true;
    // }
  };

  put_password_api() {
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'block';
    const userAccountID = this.props.profile.id;
    let btnelement = document.getElementById('save-btn-user-password-info');
    const updatedPassword = {
      accountId: userAccountID,
      newPassword: this.state.newPassword,
      oldPassword: this.state.currentPassword,
    };
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.user_api_baseurl + userAccountID + '/changePassword',
      // Autoload.Setting.user_api_baseurl + userAccountID + '/changePassword',
      'PUT',
      updatedPassword,
      btnelement.children[0],
      false,
      true,
      false,
      this,
      'handle_put_user_password',
      { updatedPassword },
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  handle_put_user_password(response) {
    // console.log('handle_put_user_password', response);
    if (response.status === true) {
      //Autoload.DataHandlerGeneral.GeneralErrorMessage(true, 'Passwort erfolgreich aktualisiert');
      window.Intercom('trackEvent', 'password-changed');
      showNotification({
        message: 'Passwort erfolgreich geändert.',
        icon: <img src={GreenTick} alt='green-tick'/>
      });
    } else {
      //Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang_newKeys.text_error_found);
      window.Intercom('trackEvent', 'password-changed-failed');
      showNotification({
        message: 'Es ist ein Fehler aufgetreten. Bitte prüfen Sie das alte und das neue Passwort.',
        icon: <img src={RedCross} alt='red-cross'/>
      });
    }
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'none';
  }

  render() {
    const {isMobile, openChangePasswordModal} = this.state;
    return (
      <Stack>
      <Row style={{marginTop: '25px'}}>
        <Col xs={12}>
          <div className="payment-heading-main" style={{fontSize: '16px'}}>
            Passwort ändern
          </div>
        </Col>
      </Row>
        <div id="save-btn-user-password-info">
          <ColorButton
            onClick={() => this.setState({openChangePasswordModal: true})}
            text={lang_newKeys.text_change_pw}
            className="purple-btn"
            buttonStyle={{ padding: '10px'}}
          />
        </div>
        {openChangePasswordModal &&
        <ChangePasswordModal
        open={openChangePasswordModal}
        toggle={()=> this.setState({openChangePasswordModal: false})}
        />}
      </Stack>
      // <div className="account-width pw" style={{width: '100%'}}>
      //   <Form onSubmit={this.savePasswordChanges}>
      //     <Row style={{marginBottom: '18px', marginTop: '25px'}}>
      //           <Col xs={12}>
      //             <div className="payment-heading-main" style={{fontSize: '16px'}}>
      //               {/* {lang.general.text_direct_debit} */}
      //               Passwort ändern
      //               {/* {this.state.heading === 'creditcard' ? 'Kreditkarte' : lang.general.text_direct_debit} */}
      //             </div>
      //           </Col>
      //     </Row>
      //     <Row>
      //       <Col xs={12}>
      //         <Form.Group>
      //           <Form.Label> {lang.text_current_password} </Form.Label>
      //           <div className="password-field" style={{ position: 'relative', top: 0, left: 0 }}>
      //             <Form.Control
      //               id="current-password"
      //               name="current-password"
      //               isInvalid={this.state.currentPassErr}
      //               type="password"
      //               value={this.state.currentPassword}
      //               onChange={(e) => {
      //                 this.onInputChange('currentPassword', e.target.value);
      //               }}
      //             />
      //             <img src={EyeIcon} onClick={() => this.toggleShowPassword('current-password')} alt="eye-icon" />
      //             <Form.Control.Feedback className="err" type="invalid">
      //               {' '}
      //               {lang.text_invalid_current_password}{' '}
      //             </Form.Control.Feedback>
      //           </div>
      //         </Form.Group>
      //       </Col>
      //     </Row>
      //     <Row>
      //       <Col xs={12}>
      //         <Form.Group>
      //           <Form.Label> {lang.text_new_password} </Form.Label>
      //           <div className="password-field" style={{ position: 'relative', top: 0, left: 0 }}>
      //             <Form.Control
      //               id="new-password"
      //               name="new-password"
      //               isInvalid={this.state.newPassErr}
      //               type="password"
      //               value={this.state.newPassword}
      //               onChange={(e) => {
      //                 this.onInputChange('newPassword', e.target.value);
      //               }}
      //             />
      //             <img src={EyeIcon} onClick={() => this.toggleShowPassword('new-password')} alt="eye-icon" />
      //             <Form.Control.Feedback className="err" type="invalid">
      //               {' '}
      //               {lang.text_invalid_new_password}{' '}
      //             </Form.Control.Feedback>
      //           </div>
      //         </Form.Group>
      //       </Col>
      //     </Row>
      //     <div id="save-btn-user-password-info">
      //       <ColorButton
      //         buttonConfig={{ type: 'submit' }}
      //         // onClick={() => {
      //         //   //this.put_password_api();
      //         // }}
      //         text={lang_newKeys.text_change_pw}
      //         className="purple-btn"
      //         buttonStyle={{ padding: '10px'}}
      //       />
      //     </div>
      //   </Form>
      // </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    profile: state.generalReducer.profile,
  };
};

const actions = {
  updateUserProfile,
};

export default connect(mapStateToProps, actions)(ProfilePassword);
// export default ProfilePassword;
