import React, { useState, useRef, useEffect } from 'react';
import onClickOutside from 'react-onclickoutside';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortUp, faSortDown, faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import Up from '../../../../../assets/images/module/general/SelectArrowUp.svg';
import Down from '../../../../../assets/images/module/general/SelectArrowDown.svg';
// import { render } from 'node-sass';
import Autoload from '../../../../Autoload';
import { Checkbox, Group, Stack } from '@mantine/core';
import { Table, TableCell, TableHead, TableRow, TableBody, TableContainer } from '@material-ui/core';
var { isValidDate } = Autoload.MainHelper;

class MultiselectDropdown extends React.Component {
  //   //states
  //   const inputRef = useRef(null);
  //   const [open, setOpen] = useState(false);
  //   const [selection, setSelection] = useState([]);
  //   const [inputFocus, toggleInputFocus]= useState(false)
  //   const [mouseInList, setMouseInList]= useState(false)
  //   const [inputText, setInputText]= useState('');
  //   const [filterList, setFilterList]= useState([]);
  //   // const [showInput, setShowInput]= useState(true);

  // constructor(){
  //     super(this.props)
  // }
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      selection: [],
      inputFocus: false,
      mouseInList: false,
      inputText: '',
      filterList: [],
      disable: false,
    };
    this.inputRef = React.createRef(null);
  }

  //   //component did mount
  //   useEffect(()=>{
  //     //   alert('mount');
  //     console.log('did mount',className, selectedValues, options)
  //     setSelection(selectedValues.length>0 ? selectedValues : [])
  //     setFilterList(options.length>0 ? options : [])
  //   },[selectedValues,options])

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.updateState(this.props);
    }
  }
  componentDidMount() {
    this.updateState(this.props);
  }

  updateState = (data) => {
    this.setState({
      // placeholder=data.placeholder,
      filterList: data.options,
      selection: data.selectedValues,
      // multiSelect = data.multiSelect,
      // onSelect=data.onSelect,
      // onRemove=data.onRemove,
      // displayValue=data.displayValue,
      disable: data.disable,
      // endListContent=data.endListContent,
      // tableView=data.tableView,
      // displayValue2=data.displayValue2,
      // className=data.className
    });
  };

  toggle = () => this.setState({ open: !this.state.open });

  handleClickOutside = () => {
    //   // ;
    this.setState({
      filterList: this.props.options,
      inputText: '',
      open: false,
    });
  };

  // updateInputWidth = () => {
  //     if (this.state.inputText.length === 0) {
  //         if (this.state.selection.length === 0 && this.props.placeholder !=='' ) {
  //             this.inputRef.current.style.width = `${this.inputRef.current.attributes.placeholder.nodeValue.length + 1}ch`
  //         } else {
  //             this.inputRef.current.style.width = '20px'
  //         }
  //     } else {
  //         this.inputRef.current.style.width = `${this.inputRef.current.value.length + 1}ch`
  //     }
  // }

  //in end
  //   useEffect(()=>{
  //     // // ;
  //     if(inputText.length===0){
  //       if(selection.length===0 && placeholder ){
  //         this.inputRef.current.style.width=`${this.inputRef.current.attributes.placeholder.nodeValue.length+1}ch`
  //       }else{
  //         this.inputRef.current.style.width='20px'
  //       }
  //     }else{
  //       this.inputRef.current.style.width=`${this.inputRef.current.value.length+1}ch`
  //     }
  //   },[inputText,selection])

  handleOnClick = (item) => {
    console.log('selection', this.props.className, item);
    //   // ;
    if (!this.state.selection.some((current) => current.id === item.id)) {
      if (!this.props.multiSelect) {
        this.setState({
          selection: [item],
          inputText: item[this.props.displayValue],
        });
        this.props.onSelect([item], item);
      } else if (this.props.multiSelect) {
        this.setState({
          selection: [...this.state.selection, item],
        });
        this.props.onSelect([...this.state.selection, item], item);
      }
    } else {
      let selectionAfterRemoval = this.state.selection;
      selectionAfterRemoval = selectionAfterRemoval.filter((current) => current.id !== item.id);
      this.setState({
        selection: [...selectionAfterRemoval],
      });
      if (!this.props.multiSelect) {
        this.setState({
          filterList: [...this.props.options],
          inputText: '',
        });
      }
      this.props.onRemove(selectionAfterRemoval, item);
    }
  };

  removeItem = (item) => {
    let selectionAfterRemoval = this.state.selection;
    selectionAfterRemoval = selectionAfterRemoval.filter((current) => current.id !== item.id);
    this.setState({ selection: [...selectionAfterRemoval] });
    this.props.onRemove(selectionAfterRemoval, item);
  };

  isItemInSelection = (item) => {
    if (this.state.selection.some((current) => current.id === item.id)) {
      return true;
    }
    return false;
  };

  filterItems = (value) => {
    let arr = [];
    if (value === '') {
      this.setState({ filterList: this.props.options });
    } else {
      arr = this.props.options.filter((item) => {
        return item[this.props.displayValue].toString().toLowerCase().includes(value.toLowerCase());
      });
      this.setState({ filterList: arr });
    }
    this.setState({ inputText: value });
  };
  render() {
    const { disable, inputFocus, selection, inputText, open, filterList } = this.state;
    const { multiSelect, displayValue, displayValue2, placeholder, tableView, endListContent, className, newTable=false, onClick, stopOpen=false } = this.props;

    console.log('open: ', open);
    return (
      <div onClick={onClick} className={`dd-wrapper ${className}`} style={{background: disable && '#f7f7fa', cursor: 'pointer'}}>
        <div
          tabIndex={0}
          // onBlur={ ()=> {
          //     console.log('blurr')
          //     if(mouseInList){
          //         setOpen(open)
          //     }else{

          //         setOpen(false)
          //     }
          // }}
          className="dd-header"
          role="button"
          // onKeyPress={() => !disable && setOpen(true)}
          onFocus={()=> this.setState({
            inputFocus: true,
          })}
          onClick={() => !disable && this.setState({
              open: stopOpen ? false : !open,
            })
          }
        >
          <div className="interaction-area" style={{ width: '270px'}}>
            {/* <div className="dd-header-title"> */}
            {multiSelect &&
              selection.map((item) => (
                <div className="dd-header-title-bold selection" key={item.id} style={{}}>
                  {this.props.viewDetails ? (
                    <span
                      style={{
                        width: '60px',
                        display: 'inline-block',
                        overflow: 'clip',
                        textOverflow: 'ellipsis'
                      }}
                     onClick={() => this.props.toggleViewDetailsModal(item.receiptId ? item.receiptId : item.id)}>{item[displayValue]}</span>
                  ) : (
                    <span>{item[displayValue]}</span>
                  )}
                  {/* <span>{item[displayValue]}</span> */}
                  <button
                    className="delete-btn"
                    onClick={(e) => {
                      if (!disable) {
                        e.stopPropagation();
                        this.removeItem(item);
                      }
                    }}
                  >
                    <FontAwesomeIcon icon={faTimes} color="#727293" style={{ fontSize: '10px' }} />
                  </button>
                </div>
              ))}
            {/* <div className="dd-header-title-bold"> */}

            <input
              className="dd-header-title-bold"
              // style={{width:'170px'}}
              autofocus={true}
              ref={this.inputRef}
              // onKeyPress={()=>changeWidth()}
              placeholder={selection.length === 0 ? placeholder : ''}
              type="text"
              onFocus={() => {
                this.setState({
                  inputFocus: true,
                });
              }}
              onBlur={() => {
                this.setState({
                  inputFocus: false,
                });
              }}
              value={inputText}
              onChange={(e) => {
                // if(e.target.value.length===0){
                //   if(selection.length===0 && placeholder ){
                //     this.inputRef.current.style.width=`${this.inputRef.current.attributes.placeholder.nodeValue.length+1}ch`
                //   }else{
                //     this.inputRef.current.style.width='20px'
                //   }
                // }else{
                //   this.inputRef.current.style.width=`${this.inputRef.current.value.length+1}ch`
                // }
                this.filterItems(e.target.value);
              }}
              disabled={disable}
            />
            {/* </div> */}

            {/* </div> */}
          </div>
          <div className="dd-header-action">
            <img className="dd-header-action-icon" src={open ? Up : Down} style={{ fontSize: '10px' }} />
          </div>
        </div>
        {open && (
          <>
          {newTable ?
          <Stack style={{position: 'absolute', top: 38, right: 0, left: 0, marginLeft: 'auto', marginRight: 'auto', background: 'white', zIndex: 10, border: '1px solid #DADAE5', //minHeight: '200px'
          }}>
          <TableContainer className='multiselect-table-container' style={{height: '100%'}}>
          <Table className='multiselect-table' style={{borderCollapse: 'separate', borderSpacing: '0px 4px'}}>
            <TableHead>
              {tableView && filterList.length > 0 && (
                <TableRow>
                  <TableCell style={{padding: '5px 5px 5px 16px'}}>
                    <span className="cover-space">Beleg</span>
                  </TableCell>
                  <TableCell className='table-without-cell-borders'>
                    <span className="cover-space">Datum</span>
                  </TableCell>
                  <TableCell style={{padding: '5px 16px 5px 5px', textAlign: 'center'}}>
                    <Group style={{
                      width: '100%', 
                      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                      fontWeight: '400',
                      lineHeight: '1.43',
                      letterSpacing: '0.01071em',
                      fontSize: '14px'
                      }} position='center' >
                      Hinzufügen
                    </Group>
                  </TableCell>
                </TableRow>    
              )}
            </TableHead>
            <TableBody>
              {filterList.map((item) => (
                <TableRow key={item.id} className={`multiselect-table-rows ${this.isItemInSelection(item) && 'multiselect-table-rows-selected'}`}>
                  <TableCell onClick={()=> this.props.toggleViewDetailsModal(item.id)} className='table-without-cell-borders'>
                    <span className="cover-space">{item[displayValue]}</span>
                  </TableCell>
                  <TableCell onClick={()=> this.props.toggleViewDetailsModal(item.id)} className='table-without-cell-borders'>
                    <span className="cover-space">
                      {item[displayValue2]}
                    </span>
                  </TableCell>
                  <TableCell className='table-without-cell-borders'>
                    <Group position='center'>
                      <Checkbox 
                      classNames={{input: 'tenants-list-checkbox-rental-income-input', inner: 'tenants-list-checkbox-rental-income-inner'}}
                      checked={this.isItemInSelection(item)}
                      onClick={() => this.handleOnClick(item)}
                      />
                    </Group>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          </TableContainer>
          {endListContent && 
          <Group grow style={{padding: '5px'}}>
            {endListContent}
          </Group>}
          </Stack>
          :
          <ul
            className="dd-list"
            style={{
              top: 38,
              // top: !multiSelect ? 36 : selection.length === 0 ? 36 : 52,
              height: filterList.length > 0 ? '200px' : 'auto',
            }}
            // onMouseEnter={()=>setMouseInList(true)}
            // onMouseLeave={()=>setMouseInList(false)}
          >
            {filterList.length > 0 && (
              <div style={{ flex: 1 }}>
                {tableView && filterList.length > 0 && (
                  <li className="dd-list-item heading">
                    <span className="cover-space">Beleg</span>
                    <span className="cover-space">Datum</span>
                    <span>
                      <FontAwesomeIcon icon={faCheck} color={'transparent'} />
                    </span>
                  </li>
                )}
                {!tableView
                  ? filterList.map((item) => (
                      <li className={`dd-list-item ${this.isItemInSelection(item) ? 'selected' : ''}`} key={item.id}>
                        <button type="button" onClick={() => this.handleOnClick(item)}>
                          <span className="cover-space" style={{maxWidth: '80px'}}>{item[displayValue]}</span>
                          <span>
                            {
                              <FontAwesomeIcon
                                icon={faCheck}
                                color={this.isItemInSelection(item) ? '#5555FF' : '#BFBFD2'}
                              />
                            }
                          </span>
                        </button>
                      </li>
                    ))
                  : filterList.map((item) => (
                      <li className={`dd-list-item ${this.isItemInSelection(item) ? 'selected' : ''}`} key={item.id}>
                        <button type="button" onClick={() => this.handleOnClick(item)}>
                          <span className="cover-space">{item[displayValue]}</span>
                          <span className="cover-space">
                            {item[displayValue2]}
                          </span>
                          <Checkbox
                          checked={this.isItemInSelection(item)}
                          />
                        </button>
                      </li>
                    ))}
              </div>
            )}
            {endListContent && <li className="last-content">{endListContent}</li>}
          </ul>}
          </>
        )}
      </div>
    );
  }
}

MultiselectDropdown.defaultProps = {
  placeholder: '',
  options: [],
  selectedValues: [],
  multiSelect: false,
  onSelect: () => {},
  onRemove: () => {},
  displayValue: '',
  disable: false,
  endListContent: <div></div>,
  tableView: false,
  displayValue2: '',
  className: '',
  viewDetails: false,
};

// const clickOutsideConfig = {
//   handleClickOutside: () => MultiselectDropdown.handleClickOutside,
// };

// export default onClickOutside(MultiselectDropdown, clickOutsideConfig);
export default onClickOutside(MultiselectDropdown);

