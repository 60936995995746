import React, { useState } from 'react';
import { Badge } from 'react-bootstrap';
import { ColorButton, HeadingContainer, MeterReadingRecord, TransparentButton, Divider } from '../../../../index.js';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import Autoload from '../../../../Autoload';
import uparrow from '../../../../../assets/images/module/general/SelectArrowUp.svg';
import downArrow from '../../../../../assets/images/module/general/SelectArrowDown.svg';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core';
import OtherBillingPeriodMeter from './otherBillingPeriodMeter.js';
var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];

const MeterReadingData = ({ data, history, match, parent, rentalUnitsExist=true, propertySelected }) => {
  //clickable accordion
  let badgeText = '';
  let badgeStyle = '';
  console.log(data)

  // console.log("parent.state.current_billingPeriod", parent.state.current_billingPeriod);
  // console.log("parent.state", parent.state);

  //each data item recieved
  // const ToggleItems = () => {
  // const [accordianArrow, setaccordianArrow] = useState(false)
  const newDate = moment(new Date()).format('YYYY.DD.MM');
  // const newDate = `01.01.${moment(new Date()).format('YYYY')} - 31.12.${moment(new Date()).format('YYYY')}`
  // const current_billingPeriod = parent.state.current_billingPeriod === true ? (
  //   <div>
  //   <HeadingContainer
  //     className="meter-reading-heading"
  //     // heading={`${moment(item.startDate).format('DD.MM.YYYY')} - ${moment(item.endDate).format('DD.MM.YYYY')}`}
  //     heading={`01.01.${moment(new Date()).format('YYYY')} - 31.12.${moment(new Date()).format('YYYY')}`}
  //     rightContent={
  //       <div className="btn-container meter-reading-buttons">

  //         <ColorButton
  //           onClick={() =>
  //             history.push({ pathname:`${Autoload.Setting.get_meter_reading_interium_link('c-0','c-r-0', newDate, "start" )}`, data: {}, edit: false })
  //           }
  //           text={lang.meter.text_individual_reading}
  //           className={` mr-1 gray-btn interim-btn`}
  //         />

  //         <ColorButton
  //           onClick={() =>
  //             history.push({ pathname:`${Autoload.Setting.get_meter_reading_annual_link('c-0','c-r-0')}`, data: { ...{}, "badgeText": lang.receipt.text_current_billing_period } })
  //           }
  //           text={lang.meter.text_annual_reading}
  //           className={` mr-1 annual-btn purple-btn `}
  //         />
  //       </div>
  //     }
  //     badge={
  //       <Badge variant="info" className={`year-badge white`}>
  //         {lang.receipt.text_current_billing_period}
  //       </Badge>
  //     }
  //   />
  //   <div className="divider"></div>
  //   </div>
  // ) : '';

  // const least_billingPeriod = parent.state.least_billingPeriod === true ? (
  //   <div>
  //   <HeadingContainer
  //     className="meter-reading-heading"
  //     // heading={`${moment(item.startDate).format('DD.MM.YYYY')} - ${moment(item.endDate).format('DD.MM.YYYY')}`}
  //     heading={`01.01.${moment(new Date()).subtract(1, 'years').format('YYYY')} - 31.12.${moment(new Date()).subtract(1, 'years').format('YYYY')}`}
  //     rightContent={
  //       <div className="btn-container meter-reading-buttons">

  //         <ColorButton
  //           onClick={() =>
  //             history.push({ pathname: `${Autoload.Setting.get_meter_reading_interium_link('c-1','c-r-0', newDate, "start")}`, data: {}, edit: false })
  //           }
  //           text={lang.meter.text_individual_reading}
  //           className={` mr-1 gray-btn interim-btn`}
  //         />

  //         <ColorButton
  //           onClick={() =>
  //             history.push({ pathname: `${Autoload.Setting.get_meter_reading_annual_link('c-1','c-r-0')}`, data: { ...{}, "badgeText": lang.receipt.text_last_billing_period } })
  //           }
  //           text={lang.meter.text_annual_reading}
  //           className={` mr-1 annual-btn purple-btn `}
  //         />
  //       </div>
  //     }
  //     badge={
  //       <Badge variant="info" className={`year-badge purple`}>
  //         {lang.receipt.text_last_billing_period}
  //       </Badge>
  //     }
  //   />
  //   <div className="divider"></div>
  //   </div>
  // ) : '';
  const other_billingPeriod = data
  // .filter((value, index, self) =>
  // index === self.findIndex((t) => (
  //   t.billingPeriodData.startDate === value.billingPeriodData.startDate && t.billingPeriodData.endDate === value.billingPeriodData.endDate
  // )))
  .map((item, index) => {
    return (
      <OtherBillingPeriodMeter
        item={item}
        history={history}
        parent={parent}
        data={data}
        index={index}
        key={`obp-1-${index}`}
        newDate={newDate}
        rentalUnitsExist={rentalUnitsExist}
        propertySelected={propertySelected}
      />
    );

    // console.log('item', accordianArrow)
    // const [accordianArrow, setaccordianArrow] = useState(false)
    //setting badge and its style based on date
    // const itemYear = moment(item.billingPeriodData.endDate).year(); //Number('20' + item.billingPeriod.split('-')[0].split('.')[2]);
    // const currentYear = new Date().getFullYear();
    // const dateDiff = currentYear - itemYear;
    // switch (item.type) {
    //   case "current":
    //     badgeText = lang.receipt.text_current_billing_period;
    //     badgeStyle = 'white';
    //     break;
    //   case "last":
    //     badgeText = lang.receipt.text_last_billing_period;
    //     badgeStyle = 'purple';
    //     break;
    //   default:
    //     badgeText = '';
    //     badgeStyle = '';
    //     break;
    // }
    // // var open=document.getElementById('panel2a-header').getAttribute('aria-expanded');
    // // console.log(open,'==open====')
    // return (
    //   <div >
    //      <Accordion key={index} >
    //      <AccordionSummary  aria-controls="panel2a-content" id="panel2a-header"
    //      onClick={() => {
    //       setaccordianArrow(!accordianArrow)
    //     // this.setState({
    //     //    accordianArrow: document.getElementById('panel2a-header').getAttribute('aria-expanded'),
    //     //          });
    //           }} >
    //     <Typography >
    //     <HeadingContainer
    //       className="meter-reading-heading"
    //       // heading={`${moment(item.startDate).format('DD.MM.YYYY')} - ${moment(item.endDate).format('DD.MM.YYYY')}`}
    //       heading={
    //         <div className='d-flex mt-1'>
    //           <div style={{marginRight:'12px'}}><img src={ accordianArrow===true ? downArrow : uparrow } alt='icon'/></div>
    //           <div>
    //          { `${moment(item.billingPeriodData.startDate).format('DD.MM.YYYY')} - ${moment(item.billingPeriodData.endDate).format('DD.MM.YYYY')}`}
    //           </div>
    //         </div>
    //         }
    //       rightContent={
    //         <div className="btn-container meter-reading-buttons">
    //           {badgeText !== '' ? (
    //             <ColorButton
    //               onClick={() =>
    //                 history.push({ pathname: `${Autoload.Setting.get_meter_reading_interium_link(item.id,'c-r-0', newDate, "start")}`, data: item, edit: false })
    //               }
    //               text={lang.meter.text_individual_reading}
    //               className={` mr-1 gray-btn interim-btn`}
    //             />
    //           ) : (
    //             <TransparentButton
    //               onClick={() =>
    //                 history.push({ pathname: `${Autoload.Setting.get_meter_reading_interium_link(item.id,'c-r-0', newDate, "start")}`, data: item, edit: false })
    //               }
    //               text={lang.meter.text_individual_reading}
    //               className={`mr-1 interim-transparent-btn`}
    //             />
    //           )}
    //           <ColorButton
    //             onClick={() =>
    //               history.push({ pathname: `${Autoload.Setting.get_meter_reading_annual_link(item.id,'c-r-0')}`, data: { ...item, badgeText }, edit: false })
    //             }
    //             text={lang.meter.text_annual_reading}
    //             className={` mr-1 ${badgeText !== '' ? 'annual-btn purple-btn' : ' interim-btn gray-btn '} `}
    //           />
    //         </div>
    //       }
    //       badge={
    //         <Badge variant="info" className={`year-badge ${badgeStyle}`}>
    //           {badgeText}
    //         </Badge>
    //       }
    //     />
    //     </Typography>
    //   </AccordionSummary>
    //   <AccordionDetails>
    //     <Typography style={{width:"100%"}}>
    //     {item.occupationReadings.length === 0 ? null : (
    //       <MeterReadingRecord
    //         className="meter-reading-table"
    //         data={item}
    //         labels={[
    //           lang.meter.text_Rental_Unit,
    //           lang.tenant.text_tenant,
    //           lang.utility.text_occupancy_period,
    //           lang.meter.text_status,
    //           lang.general.text_date,
    //         ]}
    //         labelClassName={['unit', 'tenant','Occupation period' , 'status', 'date', '']}
    //         history={history}
    //         parent={parent}
    //         ccbFunc={(response) => console.log('res', response)}
    //         billingPeriod={dateDiff}
    //       />
    //     )}
    //     {data.length !== index + 1 && <div className="divider"></div>}
    //     </Typography>
    //   </AccordionDetails>
    // </Accordion>
    // <hr/>
    //     {/* {index == 0 ? (
    //       { current_billingPeriod }
    //     ) : ''}
    //     {index == 0 ? (
    //       { least_billingPeriod }
    //     ) : ''} */}

    //   </div>
    // );
  });

  return (
    <div //style={{height: '100vh'}}
    >
      {/* 
          {current_billingPeriod}
          {least_billingPeriod}         
        */}

      {other_billingPeriod}
    </div>
  );
  // return {
  //   ,,
  // }

  // };
  // return ToggleItems();
  // return data.length > 0 && ToggleItems();
};

export default withRouter(MeterReadingData);
