import * as ActionTypes from '../../types/property';
// import Autoload from '../../../neoverv/Autoload';
// var { checkEmpty } = Autoload.MainHelper;

const costData = [];

const allocationData = [];

const selectedCostData = [];
// export
export const selectedCostTypeReducer = (state = selectedCostData, action) => {
  switch (action.type) {
    case ActionTypes.SET_SELECTED_COST_TYPE:
      //console.log("Actionnnn", action.payload)
      if (state.length > 0) {
        state.map((obj) => {
          if (obj.id != action.payload.id) {
            return [...state, action.payload];
          }
        });
        return state;
      } else return [...state, action.payload];
    default:
      return state;
  }
};
export const costTypeReducer = (state = costData, action) => {
  switch (action.type) {
    case ActionTypes.SET_COST_DATA:
      //  console.log("propertyy reducerrr", action.payload)
      let result;
      state.map((cost) => {
        if (cost.id != action.payload.id) {
          result = [...state, action.payload];
        } else result = state;
      });
      return result;
    case ActionTypes.GET_COST_DATA:
      return state;

    case ActionTypes.DELETE_COST_DATA:
      return [...state.filter((item) => item.id !== action.payload)];

    case ActionTypes.EDIT_COST_DATA:
      const item = JSON.parse(action.payload);
      let editArr = [...state];
      let editIndex;
      for (let i = 0; i < editArr.length; i++) {
        if (editArr[i].id === item.id) {
          editIndex = i;
          break;
        }
      }
      editArr[editIndex] = { ...item };
      return editArr;

    case ActionTypes.ADD_COST_DATA:
      const additem = JSON.parse(action.payload);
      let copyCostDataArr = [...state];
      copyCostDataArr.push(additem);
      return copyCostDataArr;

    default:
      return state;
  }
};

export const allocationKeyReducer = (state = allocationData, action) => {
  switch (action.type) {
    case ActionTypes.GET_ALLOCATION_DATA:
      return state;

    case ActionTypes.DELETE_ALLOCATION_DATA:
      return [...state.filter((item) => item.id !== action.payload)];

    case ActionTypes.ADD_ALLOCATION_DATA:
      const additem = JSON.parse(action.payload);
      let copyAllocationDataArr = [...state];
      copyAllocationDataArr.push(additem);
      return copyAllocationDataArr;

    case ActionTypes.EDIT_ALLOCATION_DATA:
      const itemAllocation = JSON.parse(action.payload);
      let editArrAllocation = [...state];
      let editIndexAllocation;
      for (let i = 0; i < editArrAllocation.length; i++) {
        if (editArrAllocation[i].id === itemAllocation.id) {
          editIndexAllocation = i;
          break;
        }
      }
      editArrAllocation[editIndexAllocation] = { ...itemAllocation };
      return editArrAllocation;

    default:
      return state;
  }
};
