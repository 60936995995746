import React, { Component } from 'react';

import CustomModal from '.';
import ColorButton from '../Buttons/ColorButton';
import { Group, Input, Stack, TextInput } from '@mantine/core';

const MOBILE_SIZE = 992;
const TABLET_SIZE = 1200;

export class AddHeatingCostModal extends Component {
  state = {
    isMobile: window.innerWidth <= MOBILE_SIZE,
    isTablet: window.innerWidth <= TABLET_SIZE,
    name: null,
    nameValidationFailed: false
  };

  descriptionMsgStyle={
    fontFamily: 'Inter, sans-serif',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '16px',
    color: '#0e0e1d',
  }
  
  constructor(props) {
    super(props);
  }

  validateName=()=>{
    const { name } = this.state;
    const { saveHeatingCost } = this.props;
    if(name){
      saveHeatingCost(name);
    }else{
      this.setState({nameValidationFailed: true});
    }
  }
  
  render() {
    const { open, toggle, className, saveHeatingCost } = this.props;
    const { name, nameValidationFailed } = this.state;
    
    return (
      <CustomModal
        heading={'Kostenart hinzufügen (Heizung)'}
        headingClassName={'ub-add-heating-cost-modal-heading'}
        open={open}
        toggle={() => {
          toggle();
        }}
        className={`${className}`}
      >
        <Stack spacing={30}>
            <Group grow>
                <TextInput 
                label="Name"
                styles={{
                    label: {
                        fontWeight: 'normal',
                        fontSize: '12px',
                        lineHeight: '20px',
                        color: '#727293',
                        margin: '0px 0px 3px'
                    }
                }}
                value={name} 
                onChange={(event) => this.setState({name: event.currentTarget.value, nameValidationFailed: false})}
                error={nameValidationFailed && "Angabe notwendig"}
                />
            </Group>
            <Group position='apart'>
                <div>
                    <ColorButton
                    text={'Abbrechen'}
                    className={'gray-btn'}
                    onClick={() => toggle()}
                    />
                </div>
                <div>
                    <ColorButton
                    text={'Speichern'}
                    className={'purple-btn'}
                    onClick={() => this.validateName()}
                    disabled={name === null}
                    />
                </div>
            </Group>
        </Stack>
      </CustomModal>
    );
  }
}

export default AddHeatingCostModal;
