import React, { Component } from 'react';
import CustomModal from './index';
import { connect } from 'react-redux';
import Autoload from '../../../../Autoload';
import * as customParseFormat from 'dayjs/plugin/customParseFormat'
import dayjs from 'dayjs';
import { Group, Stack } from '@mantine/core';
import DropFile from '../DropFile/DropFile';
import { Form } from 'react-bootstrap';
import SelectDropdown from '../SelectDropdown/SelectDropdown';
import ColorButton from '../Buttons/ColorButton';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import RedCross from '../../../../../assets/images/module/general/RedCross.svg';
import { showNotification } from '@mantine/notifications';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
var { checkProperty, yearDifference, yearDifferenceMoment, loader } = Autoload.MainHelper;
dayjs.extend(customParseFormat)

const MOBILE_SIZE = 992;
const TABLET_SIZE = 1200;

class EditDocumentModal extends Component {
  state = {
    isMobile: window.innerWidth <= MOBILE_SIZE,
    isTablet: window.innerWidth <= TABLET_SIZE,
    folderName: null
  };

  componentDidMount(){
    console.log('this.props.data: ', this.props.data);
    this.setState({folderName: this.props.data.name});
  }

  updateDocument=()=>{
    const {folderName} = this.state;
    const {documentsInCurrentFolder, propertySelected} = this.props;
    if(folderName){
        if(documentsInCurrentFolder.find((dt)=> dt.name === folderName && dt.id !== this.props.data.id) === undefined){
            loader(true);
            const data = new FormData();
      
            data.append('Name', folderName);
            // data.append('accountId', propertySelected.accountId);
            this.props.data.propertyId && data.append('propertyId', this.props.data.propertyId);
            this.props.data.referencedObjectId && data.append('referencedObjectId', this.props.data.referencedObjectId);
            data.append('isReadOnly', this.props.data.isReadOnly);        
            data.append('isHiddenForTenantPortal', this.props.data.isHiddenForTenantPortal);
            data.append('isShared', this.props.data.isShared);
            // data.append('isDirectory', false);
            // data.append('isReadOnly', false);
            // data.append('isShared', false);
            // data.append('fileType', 'General');
            // currentFolderId !== null && data.append('parentDirectoryId', currentFolderId);
      
            Autoload.HttpRequestAxios.HttpRequest(
            Autoload.Setting.document_api_base_url + 'documents/'+ this.props.data.id,
            'PUT',
            data,
            null,
            false,
            true,
            false,
            this,
            'handleUpdateDocument',
            null,
            false,
            '',
            {
              //'Content-Type': 'application/json',
              Authorization: Autoload.Utils.getAuthToken(),
            },
            true
            )
        }else{
            if(this.props.data.isDirectory){
                showNotification({
                    message: 'Ein Ordner mit diesem Namen existiert hier bereits.',
                    icon: <img src={RedCross} alt='red-cross'/>,
                    id: 'updateDocument',
                });
            }else{
                showNotification({
                    message: 'Ein Dokument mit diesem Namen existiert hier bereits.',
                    icon: <img src={RedCross} alt='red-cross'/>,
                    id: 'updateDocument',
                });
            }
        }
    }else{
      showNotification({
        message: 'Erfolgreich aktualisiert.',
        icon: <img src={RedCross} alt='red-cross'/>,
        id: 'updateDocument',
      });
    }
  }

  handleUpdateDocument(response){
    if(response.status && response.statuscode === 200){
        this.props.getDocuments();
        this.props.toggle();
    }
    loader(false);
  }

  handleUploadFile(response){
    console.log('handleUploadFile: ', response);
    if(response.status && response.statuscode === 200){
      console.log('all done');
      this.props.getDocuments();
      this.props.toggle();
      loader(false);
    } else {
      showNotification({
        message: 'Ordner nicht erfolgreich erstellt.',
        icon: <img src={RedCross} alt='red-cross'/>,
        id: 'folderNotCreatedSuccessfully',
      });
      loader(false);
    }
  }
  
  render() {
    const { open, toggle, className } = this.props;
    const {folderName} = this.state;
    return (
      <>
        <CustomModal
          heading={'Dokument oder Ordner umbennen'}
          headingClassName='create-folder-modal-heading'
          open={open}
          toggle={() => {
            toggle();
          }}
          className={`${className}`}
        >
          <Stack style={{width: '100%'}}>
            <Group grow>
              <Stack>
                <Group grow>
                <Form.Group>
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                    //isInvalid={!!this.state.errors.title}
                    type="text"
                    placeholder=""
                    value={folderName}
                    onChange={(e) => this.setState({folderName: e.target.value})}
                    //onKeyPress={this.handleEnterKeyPress}
                    />
                </Form.Group>
                {/* <Form.Group>
                    <Form.Label>Ordner type</Form.Label>
                    <SelectDropdown
                    options={[]} // Options to display in the dropdown
                    //selectedValue={selectedVatType.length > 0 && selectedVatType[0]} // Preselected value to persist in dropdown
                    // onSelect={this.onVatPercentageSelect} // Function will trigger on select event
                    // onRemove={this.onVatPercentageRemove} // Function will trigger on remove event
                    displayValue="label"
                    placeholder={lang.newKeys.text_select}
                    showSelectedHighlight={false}
                    ddHeaderClassName={`custom-select-dropdown ${'custom-select-dropdown-pt-0'}`}
                    //disable={this.state.amount ? false : true}
                    />
                </Form.Group> */}
                </Group>
                <Group position='apart'>
                    <div>
                        <ColorButton
                        onClick={()=> toggle()}
                        className="gray-btn"
                        text={'Abbrechen'}
                        />
                    </div>
                    <div>
                        <ColorButton
                        onClick={()=> this.updateDocument()}
                        className="purple-btn"
                        text={'Umbennen'}
                        />
                    </div>
                </Group>
              </Stack>
            </Group>
          </Stack>
        </CustomModal>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    propertySelected: state.generalReducer.property,
  };
};

export default connect(mapStateToProps)(EditDocumentModal);
