export const DELETE_METER_READING='DELETE_METER_READING';
export const GET_METER_READING='GET_METER_READING';
export const GET_MASTER_DATA='GET_MASTER_DATA';
export const DELETE_MASTER_DATA='DELETE_MASTER_DATA';
export const EDIT_MASTER_DATA='EDIT_MASTER_DATA';
export const GET_ANNUAL_READINGS='GET_ANNUAL_READINGS';
export const GET_INTERIM_READINGS='GET_INTERIM_READINGS';
export const TO_EDIT_INTERIM_READING='TO_EDIT_INTERIM_READING';
export const DATA_FILTER='DATA_FILTER';
export const ADD_METER='ADD_METER';
export const ADD_INTERIM_READING='ADD_INTERIM_READING';
export const EDIT_INTERIM_READING='EDIT_INTERIM_READING';
export const ADD_ANNUAL_READINGS='ADD_ANNUAL_READINGS';