import { Group, Stack } from '@mantine/core';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import CustomModal from '.';
import Autoload from '../../../../Autoload';
import MeterDataHandler from '../../../../helpers/DataHandler/Meter';
import ColorButton from '../Buttons/ColorButton';
import SwitchContainer from '../SwitchContainer';
import {
    setUtilityBillingData
  } from '../../../../../redux/action/UtilityBilling/UtilityBillingActions';

const MOBILE_SIZE = 992;
const TABLET_SIZE = 1200;

export class UtilityBillingPrintingOptionsModal extends Component {
  state = {
    isMobile: window.innerWidth <= MOBILE_SIZE,
    isTablet: window.innerWidth <= TABLET_SIZE,
    meterReadings: false,
    compositionOfHeatingCosts: false,
    costSplitWarmWaterAndHeating: false,
    splitBaseConsumptionCosts: false,
    energyConsumptionAndCO2Emissions: false,
    calculationOfCO2Tax: false,
    previousComparisonBarChart: false,
    benchmarkingHouseAverage: false,
    energyAdvice: false,
    contactInformation: false,

    meterReadingsAvailable: false,
    compositionOfHeatingCostsAvailable: false,
    costSplitWarmWaterAndHeatingAvailable: false,
    splitBaseConsumptionCostsAvailable: false,
    energyConsumptionAndCO2EmissionsAvailable: false,
    calculationOfCO2TaxAvailable: false,
    previousComparisonBarChartAvailable: false,
    benchmarkingHouseAverageAvailable: false,
    energyAdviceAvailable: false
  };
  
  constructor(props) {
    super(props);
  }

  componentDidMount(){
    const {ubData, currentSubscription} = this.props;
    console.log('ubData_234: ', ubData);
    this._getOccupationReadingTenants(this.props.tenantId, this.props.billingPeriodId);

    let subscriptionAndHeatingCostStatus = (currentSubscription.subscriptionKey.split('_')[0] !== 'STARTER' && ubData.heatingCostsActivated) ? true : false;
    
    if(subscriptionAndHeatingCostStatus){
      this.getHeatingCosts();
    }
    this.setState({
      meterReadings: !ubData.isMeterReadingAndConsumptionInfoHidden,
      compositionOfHeatingCosts: subscriptionAndHeatingCostStatus ? !ubData.isHeatingCostInfoHidden : false,
      costSplitWarmWaterAndHeating: subscriptionAndHeatingCostStatus ? !ubData.isCostSplitWarmWaterAndHeatingCostInfoHidden : false,
      splitBaseConsumptionCosts: subscriptionAndHeatingCostStatus ? !ubData.isSplitBaseConsumptionCostInfoHidden : false,
      energyConsumptionAndCO2Emissions: subscriptionAndHeatingCostStatus ? !ubData.isEnergyConsumptionCO2EmissionsInfoHidden : false,
      calculationOfCO2Tax: subscriptionAndHeatingCostStatus ? !ubData.isCO2TaxInfoHidden : false,
      previousComparisonBarChart: subscriptionAndHeatingCostStatus ? !ubData.isPreviousPeriodEnergyComparisonInfoHidden : false,
      benchmarkingHouseAverage: subscriptionAndHeatingCostStatus ? !ubData.isEnergyLevelsInfoHidden : false,
      energyAdvice: subscriptionAndHeatingCostStatus ? !ubData.isEnergyAdviceCenterInfoHidden : false,
      contactInformation: !ubData.isArbitraryBoardInfoHidden
    })
  }

  _getOccupationReadingTenants(tenantId, billingPeriodId) {
    const propertyid = this.props.propertyId;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.meter_api_base_url +
      'occupationreadings/occupationReadingForTenant?TenantId=' +
      tenantId +
      '&BillingPeriodId=' +
      billingPeriodId +
      '&PropertyId=' +
      propertyid,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      '_handleOccupationReadingTenantResponse',
      null,
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  getOccupationPeriodDate(periodStart, periodEnd) {
    const occupationStartDate = moment(periodStart, 'YYYY-MM-DD').format('DD.MM.YYYY');
    const occupationEndDate = moment(periodEnd, 'YYYY-MM-DD').format('DD.MM.YYYY');
    return occupationStartDate + ' - ' + occupationEndDate;
  }

  _handleOccupationReadingTenantResponse(response) {
    console.log(response, 'occupation api meter');
    if (response.status === true && response.statuscode === 401) {
      localStorage.clear();
      window.location.reload();
    }

    if (response.status === true && response.statuscode === 200) {
      const tenantOccupationReadings = MeterDataHandler.getTenantOccupationReadings(response.result);
      if (tenantOccupationReadings) {
        const startOccasion = tenantOccupationReadings.periodStartReading.occasion;
        const endOccasion = tenantOccupationReadings.periodEndReading.occasion;
        const occupancyDate = this.getOccupationPeriodDate(
          tenantOccupationReadings.periodStart,
          tenantOccupationReadings.periodEnd
        );
        const disableRadioSelection = {
          disableMoveIn:
            tenantOccupationReadings.periodStartReading.occasion === 'move-in' ||
              tenantOccupationReadings.periodEndReading.occasion === 'move-in'
              ? false
              : true,
          disableAnnual:
            tenantOccupationReadings.periodStartReading.occasion === 'annual' ||
              tenantOccupationReadings.periodEndReading.occasion === 'annual'
              ? false
              : true,
          disableMoveOut:
            (tenantOccupationReadings.periodStartReading.occasion === 'move-out' && tenantOccupationReadings.tenantFullName !== '(leer)') ||
              (tenantOccupationReadings.periodEndReading.occasion === 'move-out' && tenantOccupationReadings.tenantFullName !== '(leer)')
              ? false
              : true,
        };
        this.setState(
          {
            tenantOccupationReadings,
            occupancyDate,
            defaultOccasion: tenantOccupationReadings.tenantFullName === '(leer)' ? 'annual' : startOccasion || endOccasion,
            disableRadioSelection,
          },
          () => this.updateOccasion()
        );

        // document.getElementById('errorattop').style.display = 'none';
        console.log('response _handleOccupationReadingTenantResponse', tenantOccupationReadings);
      } else {
        //this.renderErrorMessage(lang.newKeys.text_error_Tenant_readings_not_found);
        this.setState({ disableUpdation: true, readings: [] });
      }
    } else {
      //this.renderErrorMessage(lang.newKeys.text_error_no_reading_found);
      this.setState({ disableUpdation: true, readings: [] });
    }
    //this.ShowLoader();
    console.log(this.state.readings, 'data to show meter 1');
  }

  updateOccasion() {
    const { defaultOccasion, tenantOccupationReadings } = this.state;
    console.log('tenantOccupationReadings', tenantOccupationReadings);
    let readingList = [];
    let readingType = null;
    const { periodStartReading = null, periodEndReading = null } = tenantOccupationReadings;
    // if (defaultOccasion === 'move-in' || defaultOccasion === 'Move in') {
    //   const { periodStartReading = null, periodEndReading = null } = tenantOccupationReadings;
    //   if (checkProperty(periodStartReading, 'occasion') === 'move-in') {
    //     readingType = periodStartReading;
    //   } else {
    //     readingType = periodEndReading;
    //   }
    //   // readingType = tenantOccupationReadings.periodStartReading.occasion === 'move-in' ? tenantOccupationReadings.periodStartReading : tenantOccupationReadings.periodEndReading;
    //   if (readingType && readingType.meterReadings && readingType.meterReadings.length > 0) {
    //     readingList = readingType.meterReadings.map((data, index) => ({
    //       ...data,
    //       ...MeterDataHandler.generateReadingRow(data),
    //       editStartValue: data.startValue === null ? true : false,
    //     }));
    //     readingList.map((reading, index) => {
    //       readingList[index].id = readingList[index].testId;
    //     });
    //     this.setState({ readings: readingList, disableUpdation: false });
    //     readingList = [];
    //     document.getElementById('errorattop').style.display = 'none';
    //   } else {
    //     // this.renderErrorMessage(lang.newKeys.text_error_no_meter_reading);
    //     this.setState({ readings: readingList, disableUpdation: true });
    //   }
    // } else 
    if (periodStartReading && periodStartReading?.occasionDate >= tenantOccupationReadings.periodStart) {
      //let editableLastIds = editableIds;
    //   if(item.periodStartReading && item.periodStartReading.occasion === 'move-in'){
    //     const { meterReadings } = item.periodStartReading;
    //     meterReadings.map((reading)=> (reading.startValue === null || reading.startValue === 0 || reading.startValue === '0,00') && editableLastIds.push(reading.id))
    //   }
    console.log('periodStartReading');
      const periodReading = {
        ...periodStartReading,
        periodStart: tenantOccupationReadings.periodStart,
        periodEnd: tenantOccupationReadings.periodEnd,
        rentalUnitName: tenantOccupationReadings.rentalUnitName,
        tenantFullName: tenantOccupationReadings.tenantFullName,
        tenantId: tenantOccupationReadings.tenantId,
        occupationId: tenantOccupationReadings.id
      }
      this.setState({
        readings: periodReading, 
        meterReadings: periodReading &&
        periodReading.meterReadings &&
        periodReading.meterReadings.length > 0 ? true : false, 
        meterReadingsAvailable: periodReading &&
        periodReading.meterReadings &&
        periodReading.meterReadings.length > 0 ? true : false});
      //setEditableIds(editableLastIds);
    };
    if (periodEndReading) {
      console.log('periodEndReading');
      const periodReading = {
        ...periodEndReading,
        periodStart: tenantOccupationReadings.periodStart,
        periodEnd: tenantOccupationReadings.periodEnd,
        rentalUnitName: tenantOccupationReadings.rentalUnitName,
        tenantFullName: tenantOccupationReadings.tenantFullName,
        tenantId: tenantOccupationReadings.tenantId,
        occupationId: tenantOccupationReadings.id
      }
      this.setState({
        readings: periodReading, 
        meterReadings: periodReading &&
        periodReading.meterReadings &&
        periodReading.meterReadings.length > 0 ? true : false,
        meterReadingsAvailable: periodReading &&
        periodReading.meterReadings &&
        periodReading.meterReadings.length > 0 ? true : false});
    };
  }

  getHeatingCosts = () => {
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.ub_api_base_url + 'heatingCosts?utilityBillingId=' + this.props.ubData.id,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      '_handleHeatingCostsResponse',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };

  _handleHeatingCostsResponse =(response)=>{
    const {ubData} = this.props;
    console.log('_handleHeatingCostsResponse: ', response);
      this.setState({
        compositionOfHeatingCosts: ubData.heatingCostsActivated ? response.result.length > 0 && true : false, 
        compositionOfHeatingCostsAvailable: ubData.heatingCostsActivated ? response.result.length > 0 && true : false, 
        costSplitWarmWaterAndHeating: ubData.heatingCostsActivated, 
        costSplitWarmWaterAndHeatingAvailable: ubData.heatingCostsActivated, 
        splitBaseConsumptionCosts: ubData.heatingCostsActivated, 
        splitBaseConsumptionCostsAvailable: ubData.heatingCostsActivated,
        energyConsumptionAndCO2EmissionsAvailable: ubData.heatingCostsActivated,
        calculationOfCO2TaxAvailable: ubData.heatingCostsActivated,
        previousComparisonBarChartAvailable: ubData.heatingCostsActivated,
        benchmarkingHouseAverageAvailable: ubData.heatingCostsActivated,
        energyAdviceAvailable: ubData.heatingCostsActivated
      });
  }

  handleChange=(field) =>{
    this.setState({[field]: !this.state[field]});
  }
  
  render() {
    const { open, toggle, className, utilityBillingData, setUtilityBillingData, ubData } = this.props;
    const { 
        isMobile, 
        isTablet, 
        meterReadings, 
        compositionOfHeatingCosts, 
        costSplitWarmWaterAndHeating, 
        splitBaseConsumptionCosts,
        energyConsumptionAndCO2Emissions,
        calculationOfCO2Tax,
        previousComparisonBarChart,
        benchmarkingHouseAverage,
        energyAdvice,
        contactInformation,

        meterReadingsAvailable,
        compositionOfHeatingCostsAvailable,
        costSplitWarmWaterAndHeatingAvailable,
        splitBaseConsumptionCostsAvailable,
        energyConsumptionAndCO2EmissionsAvailable,
        calculationOfCO2TaxAvailable,
        previousComparisonBarChartAvailable,
        benchmarkingHouseAverageAvailable,
        energyAdviceAvailable
    } = this.state;
    
    return (
      <CustomModal
        heading={'Druckabschnitte wählen'}
        headingClassName={'select-ub-printing-options'}
        open={open}
        toggle={() => {
          toggle();
        }}
        className={`${className}`}
      >
        <Stack>
            <Group style={{fontWeight: '500'}}
            >Welche Abschnitte sollen gedruckt werden?</Group>
            <Group noWrap>
                {/* <span>Betriebskostenabrechnung</span> */}
                <SwitchContainer
                disabled={true}
                switchText={<span>Betriebskostenabrechnung</span>}
                onColor="#5555FF"
                offColor="#DADAE6"
                checked={true}
                textClassName='select-ub-printing-options-switch-label-text'
                />
            </Group>
            <Group noWrap>
                {/* <span>Ablesungen und Verbräuche</span> */}
                <SwitchContainer
                disabled={false}
                switchText={<span>{'Ablesungen und Verbräuche '}{!meterReadingsAvailable && <span style={{color: '#727293'}}>(nicht verfügbar)</span>}</span>}
                onColor="#5555FF"
                offColor="#DADAE6"
                onChange={()=> this.handleChange('meterReadings')}
                checked={meterReadings}
                textClassName='select-ub-printing-options-switch-label-text'
                />
            </Group>
            <Group noWrap>
                {/* <span>Zusammensetzung der Heizungskosten</span> */}
                <SwitchContainer
                disabled={ubData.heatingCostsActivated ? false : true}
                switchText={<span>{'Zusammensetzung der Heizungskosten '}{!compositionOfHeatingCostsAvailable && <span style={{color: '#727293'}}>(nicht verfügbar)</span>}</span>}
                onColor="#5555FF"
                offColor="#DADAE6"
                onChange={()=> this.handleChange('compositionOfHeatingCosts')}
                checked={ubData.heatingCostsActivated ? compositionOfHeatingCosts : false}
                textClassName='select-ub-printing-options-switch-label-text'
                />
            </Group>
            <Group noWrap>
                {/* <span>Aufteilung Heizkosten in Kosten für Warmwasser und Heizkosten</span> */}
                <SwitchContainer
                disabled={ubData.heatingCostsActivated ? false : true}
                switchText={<span>{'Aufteilung Heizkosten in Kosten für Warmwasser und Heizkosten '}{!costSplitWarmWaterAndHeatingAvailable && <span style={{color: '#727293'}}>(nicht verfügbar)</span>}</span>}
                onColor="#5555FF"
                offColor="#DADAE6"
                onChange={()=> this.handleChange('costSplitWarmWaterAndHeating')}
                checked={ubData.heatingCostsActivated ? costSplitWarmWaterAndHeating : false}
                textClassName='select-ub-printing-options-switch-label-text'
                />
            </Group>
            <Group noWrap>
                {/* <span>Aufteilung Heizkosten in Grundkosten und Verbrauchskosten</span> */}
                <SwitchContainer
                disabled={ubData.heatingCostsActivated ? false : true}
                switchText={<span>{'Aufteilung Heizkosten in Grundkosten und Verbrauchskosten '}{!splitBaseConsumptionCostsAvailable && <span style={{color: '#727293'}}>(nicht verfügbar)</span>}</span>}
                onColor="#5555FF"
                offColor="#DADAE6"
                onChange={()=> this.handleChange('splitBaseConsumptionCosts')}
                checked={ubData.heatingCostsActivated ? splitBaseConsumptionCosts : false}
                textClassName='select-ub-printing-options-switch-label-text'
                />
            </Group>
            {/* start of new sections */}
            <Group noWrap>
                <SwitchContainer
                disabled={ubData.heatingCostsActivated ? false : true}
                switchText={<span>{'Energieverbrauch & CO'}<span style={{verticalAlign: 'sub', fontSize: '8px'}}>2</span>{'-Emissionen '}{!energyConsumptionAndCO2EmissionsAvailable && <span style={{color: '#727293'}}>(nicht verfügbar)</span>}</span>}
                onColor="#5555FF"
                offColor="#DADAE6"
                onChange={()=> this.handleChange('energyConsumptionAndCO2Emissions')}
                checked={ubData.heatingCostsActivated ? energyConsumptionAndCO2Emissions : false}
                textClassName='select-ub-printing-options-switch-label-text'
                />
            </Group>
            <Group noWrap>
                <SwitchContainer
                disabled={ubData.heatingCostsActivated ? false : true}
                switchText={<span>{'Berechnung der CO'}<span style={{verticalAlign: 'sub', fontSize: '8px'}}>2</span>{'-Steuer '}{!calculationOfCO2TaxAvailable && <span style={{color: '#727293'}}>(nicht verfügbar)</span>}</span>}
                onColor="#5555FF"
                offColor="#DADAE6"
                onChange={()=> this.handleChange('calculationOfCO2Tax')}
                checked={ubData.heatingCostsActivated ? calculationOfCO2Tax : false}
                textClassName='select-ub-printing-options-switch-label-text'
                />
            </Group>
            <Group noWrap>
                <SwitchContainer
                disabled={ubData.heatingCostsActivated ? false : true}
                switchText={<span>{'Entwicklung Heizungsverbrauch und Heizungskosten '}{!previousComparisonBarChartAvailable && <span style={{color: '#727293'}}>(nicht verfügbar)</span>}</span>}
                onColor="#5555FF"
                offColor="#DADAE6"
                onChange={()=> this.handleChange('previousComparisonBarChart')}
                checked={ubData.heatingCostsActivated ? previousComparisonBarChart : false}
                textClassName='select-ub-printing-options-switch-label-text'
                />
            </Group>
            <Group noWrap>
                <SwitchContainer
                disabled={ubData.heatingCostsActivated ? false : true}
                switchText={<span>{'Energieverbrauch im Hausvergleich '}{!benchmarkingHouseAverageAvailable && <span style={{color: '#727293'}}>(nicht verfügbar)</span>}</span>}
                onColor="#5555FF"
                offColor="#DADAE6"
                onChange={()=> this.handleChange('benchmarkingHouseAverage')}
                checked={ubData.heatingCostsActivated ? benchmarkingHouseAverage : false}
                textClassName='select-ub-printing-options-switch-label-text'
                />
            </Group>
            <Group noWrap>
                <SwitchContainer
                disabled={ubData.heatingCostsActivated ? false : true}
                switchText={<span>{'Energieberatung der Verbraucherzentrale '}{!energyAdviceAvailable && <span style={{color: '#727293'}}>(nicht verfügbar)</span>}</span>}
                onColor="#5555FF"
                offColor="#DADAE6"
                onChange={()=> this.handleChange('energyAdvice')}
                checked={ubData.heatingCostsActivated ? energyAdvice : false}
                textClassName='select-ub-printing-options-switch-label-text'
                />
            </Group>
            <Group noWrap>
                <SwitchContainer
                disabled={false}
                switchText={<span>{'Kontaktinformationen zur Schlichtungsstelle'}</span>}
                onColor="#5555FF"
                offColor="#DADAE6"
                onChange={()=> this.handleChange('contactInformation')}
                checked={contactInformation}
                textClassName='select-ub-printing-options-switch-label-text'
                />
            </Group>
            {/* end of new sections */}
            <Group style={{width: '100%', marginTop: '15px'}} position='apart'>
                <div>
                    <ColorButton
                    onClick={() => {
                        this.props.toggle();
                      }}
                    className="gray-btn"
                    text={'Abbrechen'}
                    />
                </div>
                <div>
                    <ColorButton
                    onClick={() => {
                        if(utilityBillingData.find((ub)=> ub.id === ubData.id) === undefined){
                            setUtilityBillingData([...utilityBillingData, {...ubData}]);
                        }
                        this.props.printPdf(meterReadings, compositionOfHeatingCosts, costSplitWarmWaterAndHeating, splitBaseConsumptionCosts, energyConsumptionAndCO2Emissions, calculationOfCO2Tax, previousComparisonBarChart, benchmarkingHouseAverage, energyAdvice, contactInformation);
                        this.props.toggle();
                      }}
                    className="purple-btn"
                    text={'Drucken'}
                    />
                </div>
            </Group>
        </Stack>
        
      </CustomModal>
    );
  }
}
const mapStateToProps = (state) => {
    return {
      utilityBillingData: state.UtilityBillingReducer.utilityBillingData,
      currentSubscription: state.generalReducer.currentSubscription,
    };
  };
  const actions = {
    setUtilityBillingData,
  };
export default connect(mapStateToProps, actions)(UtilityBillingPrintingOptionsModal);
