import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setTenantBills } from '../../../../redux/action/UtilityBilling/UtilityBillingActions';
import { formatNumber } from '../../../../helpers/stringsManipulations';
import { EmptyStateContainer } from '../../../../neoverv';
import Autoload from '../../../../neoverv/Autoload';
import tap from '../../../../assets/images/module/general/TapIconDark.svg';


var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];

export class CostSplitWarmWaterAndHeatingTable extends Component {

  tableHeadCellStyling = {
    color: '#727293',
    padding: '0 9px 0 9px',
    fontWeight: '500',
    whiteSpace: 'nowrap',
    margin: '0px',
    fontSize: '14px',
    lineHeight: '20px',
    border: '1px solid #DADAE6',
    textTransform: 'capitalize',
    height: '48px',
  }
  tableBodyCellStyling = {
    color: '#0e0e1d',
    padding: '5px 9px 5px 9px',
    fontWeight: '400',
    whiteSpace: 'nowrap',
    fontSize: '14px',
    lineHeight: '20px',
    border: '1px solid #DADAE6',
    height: '48px',
    minWidth: '90px',
    // borderBottom: 'none',
    // borderRight: 'none'
  }
  state={
    energyWarmWater: 0,
    energyHeating: 0,
    costWarmWater: 0,
    costHeating: 0
  }

  constructor(props){
    super(props);
    if(props.messagePreview){
      this.tableBodyCellStyling.fontSize = '7pt';
      this.tableBodyCellStyling.height = '0px'
      this.tableBodyCellStyling.padding = '1mm'
      this.tableBodyCellStyling.lineHeight = '11pt'

      this.tableHeadCellStyling.fontSize = '7pt';
      this.tableHeadCellStyling.height = '0px'
      this.tableHeadCellStyling.padding = '1mm'
      this.tableHeadCellStyling.lineHeight = '11pt'
      //this.tableBodyCellStyling.minWidth = '40px';
    } 
  }

  componentDidMount(){
    const {utilityBilling, isTablet} = this.props;
    this.setState({
      energyWarmWater: Math.round((utilityBilling.energyAmountWarmWaterKWH / utilityBilling.heatingEnergyAmountKWH) * 100),
      energyHeating: Math.round((utilityBilling.energyAmountRoomHeatingKWH / utilityBilling.heatingEnergyAmountKWH) * 100),
      costWarmWater: Math.round((utilityBilling.energyAmountWarmWaterKWH / utilityBilling.heatingEnergyAmountKWH) * 100),
      costHeating: Math.round((utilityBilling.energyAmountRoomHeatingKWH / utilityBilling.heatingEnergyAmountKWH) * 100)
    })
  }

  getTableLabels = () => {
    return (
      <TableRow>
        <TableCell style={{...this.tableHeadCellStyling}}>{lang.utility.text_cost_type}</TableCell>
        <TableCell style={{...this.tableHeadCellStyling, ...{textAlign: 'right'}}}>{lang.utility.text_total_cost}</TableCell>
        <TableCell style={{...this.tableHeadCellStyling, ...{textAlign: 'right'}}}>{'Anteil'}</TableCell>
      </TableRow>
    );
  };
  calculateTotal=()=>{
    let total=0;
    //console.log('calculateTotal: ',this.props.selectedTenantData.tenantRows[i].totalCost);
    this.props.heatingCosts.map((row)=> {
        total = total + row.heatingCost;
    })
    console.log('calculateTotal: ', total);
    return formatNumber(total, true);;
  }
    
    render(){
      const {utilityBilling, isTablet, messagePreview} = this.props;
      const {energyHeating, energyWarmWater, costHeating, costWarmWater} = this.state;
      console.log('utilityBilling: energyAmountWarmWaterKWH: ', utilityBilling.energyAmountWarmWaterKWH);
      console.log('utilityBilling: heatingEnergyAmountKWH: ', utilityBilling.heatingEnergyAmountKWH);
      console.log('utilityBilling: energyAmountRoomHeatingKWH: ', utilityBilling.energyAmountRoomHeatingKWH);
      console.log('utilityBilling.heatingEnergyAmount: ', utilityBilling.heatingEnergyAmount);
        return (
        <TableContainer component={Paper} style={{background: 'transparent', width: messagePreview ? '60%' : !isTablet && '50%'}}>
        <Table style={{borderCollapse: 'collapse'}}>
          <TableHead style={{border: '1px solid #DADAE6', borderBottom: 'none'}}>
            {this.getTableLabels()}
          </TableHead>
          <TableBody style={{background: 'white', border: '1px solid #DADAE6'}}>
            {utilityBilling ?
            <>
            <TableRow>
                <TableCell style={{...this.tableBodyCellStyling, ...{fontWeight: 'bold'}}}>
                    {`Brennstoffmenge - ${this.props.propertySelected.energyFuelType.fuelTypeName}`}
                </TableCell>
                <TableCell style={{...this.tableBodyCellStyling, ...{fontWeight: 'bold', textAlign: 'right'}}}>
                    {utilityBilling.heatingEnergyAmount !== null ? formatNumber(Number.isInteger(utilityBilling.heatingEnergyAmount) ? utilityBilling.heatingEnergyAmount : parseFloat(utilityBilling.heatingEnergyAmount)) : '-'}
                </TableCell>
                <TableCell style={{...this.tableBodyCellStyling, ...{fontWeight: 'bold', textAlign: 'right', borderRight: '1px solid #DADAE6'}}}>
                    {utilityBilling.heatingEnergyAmount > 0 ? '100%' : '0%'}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{...this.tableBodyCellStyling, ...{fontWeight: 'bold'}}}>
                    {`Energieverbrauch kWh`}
                </TableCell>
                <TableCell style={{...this.tableBodyCellStyling, ...{fontWeight: 'bold', textAlign: 'right'}}}>
                    {utilityBilling.heatingEnergyAmountKWH !== null ? formatNumber(utilityBilling.heatingEnergyAmountKWH ? utilityBilling.heatingEnergyAmountKWH : 0) : '-'}
                </TableCell>
                <TableCell style={{...this.tableBodyCellStyling, ...{fontWeight: 'bold', textAlign: 'right', borderRight: '1px solid #DADAE6'}}}>
                    {utilityBilling.heatingEnergyAmountKWH ? '100%' : '0%'}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={this.tableBodyCellStyling}>
                    {`Energiemenge Warmwasser (kWh)`}
                </TableCell>
                <TableCell style={{...this.tableBodyCellStyling, ...{textAlign: 'right'}}}>
                    {utilityBilling.energyAmountWarmWaterKWH !== null ? formatNumber(utilityBilling.energyAmountWarmWaterKWH ? utilityBilling.energyAmountWarmWaterKWH : 0) : '-'}
                </TableCell>
                <TableCell style={{...this.tableBodyCellStyling, ...{textAlign: 'right', borderRight: '1px solid #DADAE6'}}}>
                    {`${energyWarmWater ? energyWarmWater : 0}%`}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={this.tableBodyCellStyling}>
                    {`Energiemenge Heizung (kWh)`}
                </TableCell>
                <TableCell style={{...this.tableBodyCellStyling, ...{textAlign: 'right'}}}>
                    {utilityBilling.energyAmountRoomHeatingKWH !== null ? formatNumber(utilityBilling.energyAmountRoomHeatingKWH ? utilityBilling.energyAmountRoomHeatingKWH : 0) : '-'}
                </TableCell>
                <TableCell style={{...this.tableBodyCellStyling, ...{textAlign: 'right', borderRight: '1px solid #DADAE6'}}}>
                    {`${energyHeating ? energyHeating : 0}%`}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{...this.tableBodyCellStyling, ...{fontWeight: 'bold'}}}>
                    {`Gesamte Heizkosten`}
                </TableCell>
                <TableCell style={{...this.tableBodyCellStyling, ...{fontWeight: 'bold', textAlign: 'right'}}}>
                    {utilityBilling.totalHeatingCost !== null ? formatNumber(Number.isInteger(utilityBilling.totalHeatingCost) ? utilityBilling.totalHeatingCost : parseFloat(utilityBilling.totalHeatingCost), true) : '-'}
                </TableCell>
                <TableCell style={{...this.tableBodyCellStyling, ...{fontWeight: 'bold', textAlign: 'right', borderRight: '1px solid #DADAE6'}}}>
                    {utilityBilling.totalHeatingCost ? '100%' : '0%'}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={this.tableBodyCellStyling}>
                    {`Kosten Warmwassererhitzung`}
                </TableCell>
                <TableCell style={{...this.tableBodyCellStyling, ...{textAlign: 'right'}}}>
                    {utilityBilling.heatingEnergyAmountKWH !== null && utilityBilling.energyAmountWarmWaterKWH !== null ? formatNumber(utilityBilling.heatingEnergyAmountKWH && utilityBilling.energyAmountWarmWaterKWH ? (utilityBilling.energyAmountWarmWaterKWH / utilityBilling.heatingEnergyAmountKWH) * utilityBilling.totalHeatingCost : 0, true) : '-'}
                </TableCell>
                <TableCell style={{...this.tableBodyCellStyling, ...{textAlign: 'right', borderRight: '1px solid #DADAE6'}}}>
                    {`${costWarmWater ? costWarmWater : 0}%`}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{...this.tableBodyCellStyling, ...{borderBottom: '1px solid #DADAE6'}}}>
                    {`Kosten Heizung`}
                </TableCell>
                <TableCell style={{...this.tableBodyCellStyling, ...{textAlign: 'right', borderBottom: '1px solid #DADAE6'}}}>
                    {utilityBilling.heatingEnergyAmountKWH !== null && utilityBilling.energyAmountRoomHeatingKWH !== null ? formatNumber(utilityBilling.heatingEnergyAmountKWH && utilityBilling.energyAmountRoomHeatingKWH ? (utilityBilling.energyAmountRoomHeatingKWH / utilityBilling.heatingEnergyAmountKWH) * utilityBilling.totalHeatingCost : 0, true) : '-'}
                </TableCell>
                <TableCell style={{...this.tableBodyCellStyling, ...{textAlign: 'right', borderRight: '1px solid #DADAE6', borderBottom: '1px solid #DADAE6'}}}>
                    {`${costHeating ? costHeating : 0}%`}
                </TableCell>
            </TableRow>
            </>
            : (
              <TableRow>
                <TableCell colSpan={3}>
                  <EmptyStateContainer heading={lang.utility.text_no_tenant_bills} icon={tap} showBtn={false} />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>);
            
    }
}

const mapStateToProps = (state) => {
  return {
    allReducer: state,
    currentDataId: state.UtilityBillingReducer.currentBillingId,
    tenantBills: state.UtilityBillingReducer.utilityBillingData,
    utilityBillingData: state.UtilityBillingReducer.utilityBillingData,
    heatingFilter: state.UtilityBillingReducer.HeatingAllocationFilter,
    propertySelected: state.generalReducer.property,
  };
};

const mapDispatchToProps = {
  setTenantBills,
};

export default connect(mapStateToProps, mapDispatchToProps)(CostSplitWarmWaterAndHeatingTable);

