import React from 'react';
import { HeadingContainer, FiltersContainer } from '../../../../../neoverv/index';
import Autoload from '../../../../../neoverv/Autoload';

export default function PageHeader({ heading, rightContent, headingClassName, sortElement, sortElement1, switchElement, switchElement1, searchElement, filterStyle, className, thirdRowContent=null, isMobile=false, secondRowContent=null, hideDivider=false, headingContainerMarginBottom=3, secondRowContentMarginBottom=19, section2Style, section1Width, justifyFiltersContentSpaceBetween=false, hideHeadingContainer=false, hidePageHeader=false }) {
  return (hidePageHeader === false &&
    <div className="page-header">
        {hideHeadingContainer === false &&
        <HeadingContainer 
        heading={heading} 
        marginBottom={headingContainerMarginBottom}
        rightContent={rightContent} 
        headingClassName={headingClassName}
        className={className}
        />}
        <div style={{marginBottom: `${secondRowContentMarginBottom}px`}}>
            {secondRowContent && secondRowContent}
        </div>
        <div className='filters' style={{display: 'flex', alignItems: 'center', marginBottom: '0px', justifyContent: justifyFiltersContentSpaceBetween ? 'space-between' : 'normal'}}>
        {thirdRowContent &&
        <div className='section-1' style={{width: section1Width ? section1Width : '100%', display: 'flex', alignItems: 'center'}}>
                {thirdRowContent}
                {switchElement1 &&
                <div className='section-2' style={section2Style}>
                    <div className='switch-filter'>
                        {switchElement1}
                    </div>
                </div>}
        </div>}
        {/* {switchElement1 && 
        <div className='section-2'>
            
        </div>} */}
        {sortElement1 && 
        <div className='section-3'>
            <div className='sort-filter'>
                {sortElement1}
            </div>
        </div>}
        </div>
        {!hideDivider && <div className='divider'></div>}
    </div>
  );
}
