import moment from 'moment';
import React from 'react';
import Autoload from '../../../../Autoload';

export default function BorderlessTable({ data,labels, className, showOption,editOption, isTablet, onClick }) {
    const getTableLabels=()=>{
        return labels.map((label, index) => {
            return (
                <td key={index} style={{
                  fontSize: '12px',
                  fontWeight: '400',
                  color: '#727293'
                  }}>
                    {label === "" ? "" : label}
                </td>
            );
          });
    }
    const getTableData = () => {
        return data.map((row, rowIndex) => {
          console.log('getTableData: ', row);
          if(rowIndex <= 2) {
            return (
              <tr key={rowIndex} className='table-data' style={{cursor: 'pointer'}} onClick={()=> onClick(row.id)} >
                <td className={!isTablet && `text-nl text-normal value-text-`}> 
                  {row.name}
                </td>
                <td className={!isTablet && `text-nl text-normal value-text-`}> 
                  {moment(row.receiptDate).format('DD.MM.YYYY')}
                </td>
                {!isTablet && <td className={!isTablet && `text-nl text-normal value-text- right-side`}> 
                  {Autoload.MainHelper.ConverterCurrencyDe_DisplayOnly(row.costs + '', false)}
                </td>}
              </tr>
            );
          }
        });
      };
  return (
    <table className={`table borderless ${className} mb-0`}>
        <tbody>
            <tr className='table-heading'>
                {getTableLabels()}
            </tr>
            {getTableData()}
        </tbody>
    </table>
  );
}
// {Object.values(row).map((value, index) => {
//   if(isTablet ? index !== 2 : index !== 3){
//     return (
//       <td key={index} 
//       className={!isTablet && `text-nl text-normal value-text- ${typeof value == 'number'? 'right-side': ''}`}
//       /* ${typeof value == 'number'? 'right-side': ''}`} style={{backgroundColor: 'grey', textAlign: 'right'}} > */
//       > 
//         {value && typeof value === 'number' ? Autoload.MainHelper.ConverterCurrencyDe_DisplayOnly(value + '', false) : value}
//         {/* {value && value} */}
//         {/* {value === "" ? "-" : value} */}
//       </td>
//     );
//   }
// })}