import React from 'react';
import Switch from 'react-switch';
import { LabelText } from '../../../../index';

export default function SwitchContainer({
  className,
  onChange,
  checked,
  offColor,
  onColor,
  width,
  height,
  switchText,
  textClassName,
  disabled,
}) {
  return (
    <div className={`switch-container ${className}`} style={{display: 'flex', alignItems: 'center'}}>
        <Switch
          onChange={() => onChange()}
          checked={checked ? checked : false}
          checkedIcon={false}
          uncheckedIcon={false}
          offColor={offColor ? offColor : '#DADAE6'}
          onColor={onColor ? onColor : '#5555FF'}
          height={height ? height : 16}
          width={width ? width : 28}
          disabled={disabled}
          className='mt-1'
        />
      {switchText && <LabelText divStyle={{marginTop: '2px'}} className={`px-2 mb-0 ${textClassName}`} text={switchText ? switchText : ''} />}
    </div>
  );
}
SwitchContainer.defaultProps = {
  onChange: () => {},
  disabled: {},
};
