import { Component } from 'react';
import { RichTextEditor } from '@mantine/rte';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState, Modifier, RichUtils } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import '../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import PropTypes from 'prop-types';
import { Group, Loader, Stack } from '@mantine/core';
import RepeatIcon from '@material-ui/icons/Repeat';
import { SwitchContainer } from '../../../../neoverv';
import SaveIcon from '@material-ui/icons/Save';
import BlueTick from '../../../../assets/images/module/general/BlueTick.svg'
import ChordComponent from './decorators/ChordComponent';
import { CompositeDecorator } from 'draft-js';
import { SelectionState } from 'draft-js';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';


class CustomOption extends Component {
    static propTypes = {
      onChange: PropTypes.func,
      editorState: PropTypes.object,
    };

    state={
      scrollX: 0,
      maxScrollX: 400
    }

    componentDidMount(){
      if(this.props.isMobile === true){
        this.setState({maxScrollX: 900});
      }else{
        this.setState({maxScrollX: 400});
      }
    }

    componentDidUpdate(prevProps){
      if(prevProps.isMobile === false && this.props.isMobile === true){
        this.setState({maxScrollX: 900});
      }

      if(prevProps.isMobile === true && this.props.isMobile === false){
        this.setState({maxScrollX: 400});
      }
    }
  
    addPlaceHolder = (placeholderName) => {
    const { editorState, onChange, subjectInFocus, addPlaceHolderToSubject } = this.props;
    const currentContent = editorState.getCurrentContent();
    const currentSelection = editorState.getSelection();

    let placeholder = `${placeholderName}`;
    // const newContent = Modifier.replaceText(
    //   currentContent,
    //   currentSelection,
    //   placeholder
    // );
    
    //const newEditorState = EditorState.push(editorState, newContent, 'insert-characters');

    const html = `<a href="#" class="rdw-mention-link">${placeholder}</a>`;
    if(subjectInFocus){
      addPlaceHolderToSubject(html);
    }else{
      console.log('newContent: ', html);
      const contentBlock = htmlToDraft(html);
      if (contentBlock) {
          // const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
          // const editorState1 = EditorState.createWithContent(contentState);
          // const editorState2 = EditorState.moveFocusToEnd(editorState1);
  
          const newContent = Modifier.replaceWithFragment(
            currentContent,
            currentSelection,
            ContentState.createFromBlockArray(contentBlock.contentBlocks).getBlockMap()
          );
          onChange(EditorState.push(editorState, newContent, 'insert-characters'));
      }
    }
    
    
    //onChange(EditorState.push(editorState, newContent, 'insert-characters'));
    
    //return  EditorState.forceSelection(newEditorState, newContent.getSelectionAfter());

    //console.log('addPlaceHolder: ', draftToHtml(convertToRaw(editorState.getCurrentContent())));
    
    // const html = `${draftToHtml(convertToRaw(editorState.getCurrentContent()))}<a class="rdw-mention-link"><span><span>${placeholderName}</span></span></a>&nbsp;`;
    // const contentBlock = htmlToDraft(html);
    // if (contentBlock) {
    //     const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    //     const editorState1 = EditorState.createWithContent(contentState);
    //     const editorState2 = EditorState.moveFocusToEnd(editorState1);
    //     onChange(EditorState.push(editorState2, contentState, 'insert-characters'));
    // }
    };
  
    render() {
      const {placeholders, savingLoader, isMobile} = this.props;
      const {scrollX, maxScrollX} = this.state;
      console.log('maxScrollX: ', maxScrollX, scrollX);
      return (
        <Group spacing={4} position='apart' style={{width: isMobile && '100%'}} noWrap={!isMobile}>
          {!isMobile && <Group style={{margin: '0px 5px 6px', fontWeight: '400'}}>|</Group>}
          {/* {isMobile &&
          <Group style={{marginBottom: '6px'}} noWrap>
            {savingLoader ?
            <Group spacing={5} align={'center'} noWrap>
              <Loader size={'xs'}/>
              <span style={{fontSize: '14px', fontWeight: 400, fontFamily: 'Inter, sans-serif', color: '#13135C', marginTop: '-2px'}}>speichern</span>
            </Group>
            :
            <Group spacing={5} align={'center'} noWrap>
              <img src={BlueTick} />
              <span style={{fontSize: '14px', fontWeight: 400, fontFamily: 'Inter, sans-serif', color: '#13135C', marginTop: '-2px'}}>gesichert</span>
            </Group>}
          </Group>} */}
          <Group spacing={10} style={{width: isMobile && '100%'}} noWrap>
            <Group 
            onClick={()=> {
              let container = window.document.getElementById('place-holder-container-id');
              console.log('scrollX >= (isMobile ? 50 : 100)', scrollX);
              if(scrollX >= (isMobile ? 50 : 100)){
                container.scrollTo({left: scrollX-(isMobile ? 50 : 100), top: 0, behavior: 'smooth'});
                this.setState({scrollX: scrollX-(isMobile ? 50 : 100)});
              }
            }}
            onDoubleClick={()=>{
              let container = window.document.getElementById('place-holder-container-id');
              container.scrollTo({left: 0, top: 0, behavior: 'smooth'});
              this.setState({scrollX: 0});
            }}
            style={{opacity: scrollX <= 0 ? 0.5 : 1}}
            className={'chevron-button'}
            >
              <ChevronLeftIcon
              style={{
                color: '#000'
              }}
              />
            </Group>
            <Group className='place-holder-container' id='place-holder-container-id' style={{marginBottom: '6px', maxWidth: isMobile ? '330px' : '550px', overflowX: 'scroll'}} noWrap spacing={4}>  
                {placeholders.userName && <Group noWrap spacing={5} onClick={()=> this.addPlaceHolder('Absender')} className={'editor-add-tenant-name-placeholder'} style={{background: '#e6e6ff'}}><span style={{whiteSpace: 'nowrap'}}>{'Absender'}</span></Group>}
                {placeholders.billingPeriod && <Group noWrap spacing={5} onClick={()=> this.addPlaceHolder('Abrechnungszeitraum')} className={'editor-add-tenant-name-placeholder'} style={{background: '#e6e6ff'}}><span style={{whiteSpace: 'nowrap'}}>{'Abrechnungszeitraum'}</span></Group>}
                {placeholders.moveOutDate && <Group noWrap spacing={5} onClick={()=> this.addPlaceHolder('Auszugsdatum')} className={'editor-add-tenant-name-placeholder'} style={{background: '#e6e6ff'}}><span style={{whiteSpace: 'nowrap'}}>{'Auszugsdatum'}</span></Group>}
                {placeholders.billingStatementTotalCosts && <Group noWrap spacing={5} onClick={()=> this.addPlaceHolder('Betriebskosten')} className={'editor-add-tenant-name-placeholder'} style={{background: '#e6e6ff'}}><span style={{whiteSpace: 'nowrap'}}>{'Betriebskosten'}</span></Group>}
                {placeholders.moveInDate && <Group noWrap spacing={5} onClick={()=> this.addPlaceHolder('Einzugsdatum')} className={'editor-add-tenant-name-placeholder'} style={{background: '#e6e6ff'}}><span style={{whiteSpace: 'nowrap'}}>{'Einzugsdatum'}</span></Group>}
                {placeholders.recipient && <Group noWrap spacing={5} onClick={()=> this.addPlaceHolder('Empfänger')} className={'editor-add-tenant-name-placeholder'} style={{background: '#e6e6ff'}}><span style={{whiteSpace: 'nowrap'}}>{'Empfänger'}</span></Group>}
                {placeholders.recievedRent && <Group noWrap spacing={5} onClick={()=> this.addPlaceHolder('erhaltene Miete')} className={'editor-add-tenant-name-placeholder'} style={{background: '#e6e6ff'}}><span style={{whiteSpace: 'nowrap'}}>{'erhaltene Miete'}</span></Group>}
                {/* {placeholders.balance && <Group noWrap spacing={5} onClick={()=> this.addPlaceHolder('Guthaben')} className={'editor-add-tenant-name-placeholder'} style={{background: '#e6e6ff'}}><span style={{whiteSpace: 'nowrap'}}>{'Guthaben'}</span></Group>} */}
                {placeholders.payableRent && <Group noWrap spacing={5} onClick={()=> this.addPlaceHolder('gesamte ausstehende Miete')} className={'editor-add-tenant-name-placeholder'} style={{background: '#e6e6ff'}}><span style={{whiteSpace: 'nowrap'}}>{'gesamte ausstehende Miete'}</span></Group>}
                {placeholders.billingStatementBalance && <Group noWrap spacing={5} onClick={()=> this.addPlaceHolder('Guthaben / Nachzahlung')} className={'editor-add-tenant-name-placeholder'} style={{background: '#e6e6ff'}}><span style={{whiteSpace: 'nowrap'}}>{'Guthaben / Nachzahlung'}</span></Group>}
                {placeholders.netRent && <Group noWrap spacing={5} onClick={()=> this.addPlaceHolder('Kaltmiete')} className={'editor-add-tenant-name-placeholder'} style={{background: '#e6e6ff'}}><span style={{whiteSpace: 'nowrap'}}>{'Kaltmiete'}</span></Group>}
                {placeholders.tenant && <Group noWrap spacing={5} onClick={()=> this.addPlaceHolder('Mieter')} className={'editor-add-tenant-name-placeholder'} style={{background: '#e6e6ff'}}><span style={{whiteSpace: 'nowrap'}}>{'Mieter'}</span></Group>}
                {placeholders.rentalUnit && <Group noWrap spacing={5} onClick={()=> this.addPlaceHolder('Mieteinheit')} className={'editor-add-tenant-name-placeholder'} style={{background: '#e6e6ff'}}><span style={{whiteSpace: 'nowrap'}}>{'Mieteinheit'}</span></Group>}
                {placeholders.rentMonth && <Group noWrap spacing={5} onClick={()=> this.addPlaceHolder('Mietmonat')} className={'editor-add-tenant-name-placeholder'} style={{background: '#e6e6ff'}}><span style={{whiteSpace: 'nowrap'}}>{'Mietmonat'}</span></Group>}
                {placeholders.property && <Group noWrap spacing={5} onClick={()=> this.addPlaceHolder('Objektadresse')} className={'editor-add-tenant-name-placeholder'} style={{background: '#e6e6ff'}}><span style={{whiteSpace: 'nowrap'}}>{'Objektadresse'}</span></Group>}
                {/* {placeholders.prepayments && <Group noWrap spacing={5} onClick={()=> this.addPlaceHolder('Vorauszahlung')} className={'editor-add-tenant-name-placeholder'} style={{background: '#e6e6ff'}}><span style={{whiteSpace: 'nowrap'}}>{'Vorauszahlung'}</span></Group>} */}
                {placeholders.companyName && <Group noWrap spacing={5} onClick={()=> this.addPlaceHolder('Vermieter')} className={'editor-add-tenant-name-placeholder'} style={{background: '#e6e6ff'}}><span style={{whiteSpace: 'nowrap'}}>{'Vermieter'}</span></Group>}
                {placeholders.billingStatementAdvancePayments && <Group noWrap spacing={5} onClick={()=> this.addPlaceHolder('Vorauszahlungen')} className={'editor-add-tenant-name-placeholder'} style={{background: '#e6e6ff'}}><span style={{whiteSpace: 'nowrap'}}>{'Vorauszahlungen'}</span></Group>}
                {placeholders.advancePayment && <Group noWrap spacing={5} onClick={()=> this.addPlaceHolder('Vorauszahlung')} className={'editor-add-tenant-name-placeholder'} style={{background: '#e6e6ff'}}><span style={{whiteSpace: 'nowrap'}}>{'Vorauszahlung'}</span></Group>}
                {placeholders.totalRent && <Group noWrap spacing={5} onClick={()=> this.addPlaceHolder('Warmmiete')} className={'editor-add-tenant-name-placeholder'} style={{background: '#e6e6ff'}}><span style={{whiteSpace: 'nowrap'}}>{'Warmmiete'}</span></Group>}
            </Group>
            <Group 
            className={'chevron-button'}
            style={{opacity: scrollX >= maxScrollX ? 0.5 : 1}} 
            onClick={()=> {
              let container = window.document.getElementById('place-holder-container-id');
              if(scrollX < maxScrollX){
                container.scrollTo({left: scrollX+(isMobile ? 50 : 100), top: 0, behavior: 'smooth'});
                this.setState({scrollX: scrollX+(isMobile ? 50 : 100)});
              }
            }}
            onDoubleClick={()=>{
              let container = window.document.getElementById('place-holder-container-id');
              container.scrollTo({left: maxScrollX, top: 0, behavior: 'smooth'});
              this.setState({scrollX: maxScrollX});
            }}>
              <ChevronRightIcon
              style={{
                color: '#000'
              }}
              />
            </Group>
          </Group>
          {!isMobile && <Group style={{margin: '0px 5px 6px', fontWeight: '400'}}>|</Group>}
          {!isMobile &&
          <Group style={{marginBottom: '6px'}} noWrap>
            {savingLoader ?
            <Group spacing={5} align={'center'} noWrap>
              <Loader size={'xs'}/>
              <span style={{fontSize: '14px', fontWeight: 400, fontFamily: 'Inter, sans-serif', color: '#13135C', marginTop: '-2px'}}>speichern</span>
            </Group>
            :
            <Group spacing={5} align={'center'} noWrap>
              <img src={BlueTick} />
              <span style={{fontSize: '14px', fontWeight: 400, fontFamily: 'Inter, sans-serif', color: '#13135C', marginTop: '-2px'}}>gesichert</span>
            </Group>
            }
          </Group>}
        </Group>
        
        
      );
    }
}

// const styleMap = {
//     'DEFAULT': {
//         color: '#000'
//     },
//     'TENANTBLOCK': {
//         color: '#4949F5'
//     },
// };

class MessagingEditor extends Component{
    decorator= new CompositeDecorator([
      {
        strategy: this.chordStrategy,
        component: ChordComponent,
        props: {
          setSelection: this.setSelection.bind(this)
        }
      }
    ])
    state={
        editorState: EditorState.createEmpty(this.decorator),
        enableTextEditor: false
    }
    constructor(props) {
        super(props);
        this.typingTimeout = null; // Timer to handle the debounce
        // const html = '<h1 style="text-align:center;"><span style="font-weight:bold;">hellohousing</span> Editor</h1>';
        // const contentBlock = htmlToDraft(html);
        // if (contentBlock) {
        //   const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        //   const editorState = EditorState.createWithContent(contentState);
        //   this.state = {
        //     editorState,
        //   };
        // }
      }

      chordStrategy(contentBlock, callback, contentState) {

        function findWithRegex(regex, contentBlock, callback) {
          const text = contentBlock.getText();
          console.log('findWithRegex: ', text);
          let matchArr, start;
          while ((matchArr = regex.exec(text)) !== null) {
            start = matchArr.index;
            callback(start, start + matchArr[0].length);
          }
        }
        findWithRegex(/<a[\s]+([^>]+)>((?:.(?!\<\/a\>))*.)<\/a>/g, contentBlock, callback);
      };

      setSelection(selectionStart, selectionEnd, blockKey) {
        const selectionState = SelectionState.createEmpty(blockKey);
        const newSelectionState = selectionState.merge({
          anchorOffset: selectionStart,
          focusOffset: selectionEnd,
        });
    
        const newEditorState = EditorState.forceSelection(this.state.editorState, newSelectionState);
        console.log('newEditorState: ', newEditorState);
        this.setState({editorState: newEditorState});
      }
      
      componentDidMount(){
        const {body} = this.props;
        if(body){
          this.htmlToDraftConversion(body);
        }
      }

      componentDidUpdate(prevProps){
        const {body} = this.props;
        if(prevProps.body === null && body !== null){
          if(body){
            this.htmlToDraftConversion(body);
          }
        }

      }

      htmlToDraftConversion=(data)=>{
        const contentBlock = htmlToDraft(data);
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState1 = EditorState.createWithContent(contentState);
            const editorState2 = EditorState.moveFocusToEnd(editorState1);
            this.onEditorStateChange(editorState2);
            return 'handled';
        }
      }
    
      onEditorStateChange= (editorState) => {
        console.log('editorState: ', editorState);
        this.setState({
          editorState,
        });

        if (this.typingTimeout) {
          clearTimeout(this.typingTimeout);
        }
    
        // // Set a new timeout to trigger the API call after 2 seconds of inactivity
        this.typingTimeout = setTimeout(this.handleUserStoppedTyping, 1500);
      };

      handleUserStoppedTyping = () => {
        const {editorState} = this.state;
        console.log('User stopped typing. Making API call...');
        // Replace this with your API call
        let body = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        this.props.updateBody(body);
        this.typingTimeout = null;
      };

      handleKeyCommand=(command, editorState)=>{
        const currentSelection = editorState.getSelection();
        
        const selectionState = editorState.getSelection();
        const anchorKey = selectionState.getAnchorKey();
        const currentContent = editorState.getCurrentContent();
        const currentContentBlock = currentContent.getBlockForKey(anchorKey);
        const start = selectionState.getStartOffset();
        const end = selectionState.getEndOffset();
        const selectedText = currentContentBlock.getText().slice(start, end);

        console.log('selectionState: ', anchorKey, currentContent, currentContentBlock, start, end, selectedText, selectionState.getStartOffset());
        
        // console.log('currentSelection: ', currentSelection);
        // console.log('command_124: ', convertToRaw(editorState.getCurrentContent()));

        // const newState = RichUtils.handleKeyCommand(this.state.editorState, command);
        // if (newState) {
        //   this.onEditorStateChange(newState);
        //   return true;
        // }
        // return false;
        // console.log('newState_1: ', RichUtils.handleKeyCommand(this.state.editorState, command), command);
        if(command === 'backspace'){
          if(window.getSelection().focusNode.data === 'Mieteinheit'){
            const newState = RichUtils.handleKeyCommand(this.state.editorState, 'backspace-word');
            console.log('newState: ', newState, RichUtils.handleKeyCommand(this.state.editorState, 'backspace-word'));
            if (newState) {
              this.onEditorStateChange(newState);
              return true;
            }
            return false;
          }
        }

        // return 'not-handled';
        
        // if(command === 'backspace'){
        //   let html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        //   let convertedRaw = convertToRaw(editorState.getCurrentContent());
        //   let convertedRaw2 = convertToRaw(editorState.getCurrentContent());
        //   let text = convertedRaw.blocks[0].text.slice(0, selectionState.getStartOffset()-1);
        //   //let text2 = convertedRaw2.blocks[0].text.slice(selectionState.getStartOffset(), convertedRaw2.blocks[0].text.length);
        //   convertedRaw.blocks[0].text = text;
        //   //convertedRaw2.blocks[0].text = text2;
        //   let newHtml = draftToHtml(convertedRaw);
        //   //let remainingHtml = draftToHtml(convertedRaw2);
          
        //   if(newHtml.slice(newHtml.length - 9, newHtml.length - 5) === '</a>'){
        //     //newHtml.lastIndexOf('<a');
        //     let newHtml1= newHtml.slice(0, newHtml.lastIndexOf('<a')) //remainingHtml.slice(3, remainingHtml.length);
        //     let newHtml2 = newHtml1.slice(0, newHtml.lastIndexOf('<a'))
        //     console.log('handleKeyCommand: ', newHtml2);
        //     this.htmlToDraftConversion(newHtml2);
        //     return 'handled';
        //   }
        // }
        // const newState = RichUtils.onBackspace(editorState);
        // //console.log('handleKeyCommand: ', editorState);
        // if (newState) {
        //   this.onEditorStateChange(newState);
        //   return 'handled';
        // }

        return 'not-handled';
        
      }
    
      render() {
        const { editorState, enableTextEditor } = this.state;
        const { placeholders, allRecipients, messageSavingLoader, subjectInFocus, addPlaceHolderToSubject, onFocus, isMobile } = this.props;
        console.log('toolbarOnFocus: ', isMobile);
        return (
          <Stack>
            {isMobile &&
            <Group>
              <CustomOption editorState={editorState} isMobile={isMobile} placeholders={placeholders} savingLoader={messageSavingLoader} subjectInFocus={subjectInFocus} addPlaceHolderToSubject={addPlaceHolderToSubject} onChange={this.onEditorStateChange}/>
            </Group>}
            <Editor
              editorState={editorState}
              wrapperClassName="demo-wrapper"
              editorClassName="demo-editor"
              toolbarClassName={isMobile && 'message-body-toolbar-mobile'}
              onEditorStateChange={this.onEditorStateChange}
              //toolbarOnFocus={isMobile}
              localization={{
                locale: 'de',
              }}
              // onBlur={()=> {
              //   let body = draftToHtml(convertToRaw(editorState.getCurrentContent()));
              //   this.props.updateBody(body);
              // }}
              //customStyleMap={styleMap}
              toolbar={{
                inline: {
                  strikethrough: { className: 'rdw-option-hide' },
                  monospace: { className: 'rdw-option-hide' },
                  superscript: { className: 'rdw-option-hide' },
                  subscript: { className: 'rdw-option-hide' },
                },
                // list: {
                //   indent: { className: 'rdw-option-hide' },
                //   outdent: { className: 'rdw-option-hide' },
                // },
                fontSize: {className: 'rdw-option-hide'}
            }}
            onFocus={()=> onFocus()}
            // mention={{
            //     separator: ' ',
            //     trigger: '@',
            //     suggestions: tenants.map((ten)=>{return {text: 
            //     <Group spacing={5} className='message-card-ten-cards' key={ten.value} style={{height: '20.6px', maxHeight: '20.6px', background: 'transparent', width: 'auto'}}>
            //         <img src={ten.picture} style={{height: '18.6px', maxHeight: '18.6px'}}/>
            //         <span>{ten.name}</span>
            //     </Group>, 
            //     value: ten.name, url: 'apple'}}),
            // }}
            toolbarCustomButtons={isMobile ? [] : [<CustomOption  placeholders={placeholders} savingLoader={messageSavingLoader} subjectInFocus={subjectInFocus} addPlaceHolderToSubject={addPlaceHolderToSubject}/>]}
            handleKeyCommand={this.handleKeyCommand}
            />
            {/* <SwitchContainer
            checked={enableTextEditor}
            onChange={()=> this.setState({enableTextEditor: !enableTextEditor})}
            switchText={'Enable editing HTML'}
            disabled={false}
            />
            {enableTextEditor ?
            <textarea
              onBlur={(e)=> {
                this.htmlToDraftConversion(e.target.value);
              }}
              style={{ width: '100%', height: '400px'}}
              //value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
            />
            :
            <textarea
              style={{ width: '100%', height: '400px'}}
              value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
              disabled
            />} */}
            <button id="bar" type="button" style={{display: 'none'}}>Fire key event</button>
          </Stack>
        );
      }
}

export default MessagingEditor;