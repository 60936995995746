import React, { Component } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import Receipt from '../../../../../assets/images/module/general/ReceiptDummy.png';
import PdfViewer from '../PdfViewer/PdfViewer';

export class ImageModal extends Component {
  getFilePreview = (file) => {
    
    if (file) {
      let re = /(?:\.([^.]+))?$/;
      var extension = re.exec(this.props.receiptDetailModalAdd ? file.name : file)[1];
      extension = extension && extension.split('?')[0];
    
      if (extension === 'jpg' || extension === 'png' || extension === 'jpeg') {
        const url = this.props.receiptDetailModalAdd ? URL.createObjectURL(file) : file;
        return <img src={url} className="main-img" alt="no" />;
      } else if (typeof(file) === 'object') {
        const url =URL.createObjectURL(file);
        return (
          <PdfViewer file={url}/>
        );
      } else {
        return <img src={Receipt} className="main-img" alt="no" />;
      }
    }
  };
  render() {
    const { open, toggle, className, heading, img } = this.props;

    return (
      <Modal size="lg" isOpen={open} toggle={() => toggle()} className={`image-modal ${className}`}>
        {/* <img src={CrossIcon} alt='noo' className='close-icon' onClick={()=>toggle()} /> */}
        <ModalHeader toggle={() => toggle()} className="modal-header"></ModalHeader>
        <ModalBody>
          <div className="main-div" style={{ position: 'relative', top: 0, left: 0 }}>
            {this.getFilePreview(img)}
          </div>
        </ModalBody>
      </Modal>
    );
  }
}
ImageModal.defaultProps = {
  heading: 'no',
  open: true,
  img: Receipt,
  toggle: () => {},
};

export default ImageModal;
