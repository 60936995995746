import { useState } from 'react';
import { RichTextEditor } from '@mantine/rte';

function MessagingEditorMantine() {
  const [value, onChange] = useState('');

  return <RichTextEditor controls={[
    ['bold', 'italic', 'underline', 'image'],
    ['unorderedList', 'orderedList', 'h1', 'h2', 'h3'],
    ['sup', 'sub'],
    ['alignLeft', 'alignCenter', 'alignRight'],
  ]} 
  value={value} 
  onChange={onChange}
  styles={{
    root: {border: 'none'},
    toolbar: {},
    toolbarInner: { },
    toolbarGroup: { },
    toolbarControl: { },
  }} />;
}

export default MessagingEditorMantine;