import React, { Component } from 'react';
import { CustomCard, ColorButton } from '../../../../../neoverv';
import { Form } from 'react-bootstrap';
import Autoload from '../../../../../neoverv/Autoload';
import Spinner from 'react-bootstrap/Spinner';
import EyeOpened from "../../../../../neoverv/components/SVGs/eyeOpened";
// Base libs
import { useState } from "react";

// Mantine UI libs
import { useForm } from "@mantine/form";
import {
  Box,
  Button,
  Image,
  PasswordInput,
  Popover,
  Progress,
  Stack,
  Text
} from '@mantine/core';

// Configs etc
import APIClient from '../../../../../neoverv/config/APIClient';
import { config } from '../../../../../neoverv/config/Setting';
import { showNotification } from '@mantine/notifications';
import RedCross from '../../../../../assets/images/module/general/RedCross.svg';
import GreenTick from '../../../../../assets/images/module/general/GreenTick.svg';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default].general;
// Requirements for the password validation
const passwordRequirements = [
  { regex: /[0-9]/, label: 'Eine Nummer haben' },                                                 // one digit
  { regex: /[a-z]/, label: 'Mindestens einen Kleinbuchstaben haben' },                            // lowercase letter
  { regex: /[A-Z]/, label: 'Mindestens einen Großbuchstaben haben' },                             // uppercase letter
  { regex: /[$&+,:;=?@#|'<>.^*()%!-]/, label: 'Ein Sonderzeichen enthalten (z. B. %, &, #)' }     // special chars
];

// Show password strength
const getStrength = (password) => {
  let multiplier = password.length > 5 ? 0 : 1;

  passwordRequirements.forEach((requirement) => {
    if (!requirement.regex.test(password)) {
      multiplier += 1;
    }
  });

  return Math.max(100 - (100 / (passwordRequirements.length + 1)) * multiplier, 10);
}

// Password requirement markup
const PasswordRequirement = ({ checks, label }) => {
  return (
    <Text
      color="white"
      sx={{ display: 'flex', alignItems: 'center', opacity: checks ? 1 : 0.5 }}
      mt={7}
      size="sm"
    >
      <Image width={15} src={`${process.env.PUBLIC_URL}/icons/icon-check-badge.svg`} alt="Check icon" />
      <Box ml={10}>{label}</Box>
    </Text>
  )
};

/**
 * SignUp component markup
 * @returns {JSX.Element}
 * @constructor
 */
const ResetPassword = () => {
  const unknownErrorString = 'Es ist ein unerwarteter Fehler aufgetreten.'; // TODO: move it to translations file
  const [loading, setLoading] = useState(false);
  const [passwordPopoverOpened, setPasswordPopoverOpened] = useState(false);
  const [apiError, setApiError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [password, setPassword] = useState('');
  const form = useForm({
    initialValues: {
      email: '',
      password: '',
      terms: false,
    },
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Ungültige e-mail')
    }
  });
  const checks = passwordRequirements.map((requirement, index) => (
    <PasswordRequirement key={index} label={requirement.label} checks={requirement.regex.test(form.getInputProps('password').value)} />
  ));
  const strength = getStrength(form.getInputProps('password').value);
  const color = strength === 100 ? 'teal' : strength > 50 ? 'yellow' : 'red';
  
  // Handle form submission
  const handleFormSubmit = async (values) => {
    console.log('working.... ', strength);
    if(form.getInputProps('password').value.length !== 0){
      if(strength === 100){
        setLoading(true);
        var url = new URL(window.location.href);
        await APIClient({
          method: 'POST',
          url: config.resetPassword,
          data: { 
            // email: '',
            newPassword: form.getInputProps('password').value,
            passwordResetCode: url.search.split('?')[1]
          } 
        })
        .then((response) => {
          handleSentInsBtn();
          if (response.status === 200) {
            
          } else {
            setLoading(false);
          }
        })
        .catch((e) => {
          console.log('catch:', e.response);
          if(e.response.status === 400){
            showNotification({
              icon: <img src={RedCross} alt='red-cross'/>,
              message: 'Link nicht mehr gültig. Bitte beantragen Sie einen neuen Link.'
            });
          }
          setLoading(false);
        });
      } else {
        showNotification({
          icon: <img src={RedCross} alt='red-tick'/>,
          message: 'Bitte wählen Sie ein starkes Passwort.'
        })
      }
    } else {
      showNotification({
        //title: 'Fehler',
        icon: <img src={RedCross} alt='red-tick'/>,
        message: 'Bitte geben Sei ein Passwort ein.'
      })
    }
  };

  

  const handleSentInsBtn=()=> {
    // console.log('okay');
    var forgotForm = document.getElementById('forgot-form');
    var confirmationBox = document.getElementById('ins-sent');
    forgotForm.style.display = 'none';
    confirmationBox.style.display = 'block';
  }
  
  // Render html
  return (
             <div className="center-content forgot-pw-page">
         <div className="hh_loader" id="hello-housing-loader" style={{ display: 'none' }}>
           <div className="hh_loader_bg"></div>
           <Spinner animation="border" className="hh_loader_spinner" variant="primary" />
         </div>
         <div className="logo-section">
           <img src={Setting.logo} alt="logo" />
         </div>
         <div id="forgot-form" className="main-form-section">
          <CustomCard className="show forgot-form" heading="Neues Passwort wählen">
              <form onSubmit={form.onSubmit((values) => handleFormSubmit(values))} style={{ position: 'relative' }}> 
                {/* Form */}
                <Stack spacing={12}>
                <p className="forgot-text">{lang.text_reset_password}</p>
                      {/* Password input with strength popover */}
                      <Popover
                        opened={passwordPopoverOpened}
                        target={
                          <PasswordInput 
                            label="Passwort" {...form.getInputProps('password')}
                            styles={{ label: { color: "#727293" } }}
                            visibilityToggleIcon={({ reveal, size }) =>
                              reveal ? <EyeOpened size={size} /> : <EyeOpened size={size} />
                            }
                            required 
                          />
                        }
                        position={"left"}
                        placement="center"
                        trapFocus={false}
                        transition="pop"
                        onFocusCapture={() => setPasswordPopoverOpened(true)}
                        onBlurCapture={() => setPasswordPopoverOpened(false)}
                        styles={(theme) => ({
                          popover: { width: '100%', backgroundColor: theme.colors.brandDarkText },
                          arrow: { backgroundColor: theme.colors.brandDarkText, border: 'none' },
                          body: { border: 'none' }
                        }) }
                        withArrow
                      >
                        <Progress color={color} value={strength} size={7} style={{ marginBottom: 16, border: '1px solid white' }} />
                        <PasswordRequirement label="Mindestens 8 Zeichen lang sein" checks={form.getInputProps('password').value.length > 8} />
                        {checks}
                      </Popover>
                      <Button variant="gradient" onClick={()=> handleFormSubmit(null)} gradient={{ from: '#5555FF', to: '#4949F5', deg: 90 }} type="submit">Passwort speichern</Button>
                </Stack>
              </form>
        </CustomCard>
         </div>
         <div style={{ display: 'none' }} id="ins-sent" className="main-form-section">
           <CustomCard className="hide ins-sent" heading="Passwort geändert!">
               <p className="forgot-text">{lang.text_login_with_new_pass}</p>
               <div>
                  <ColorButton
                    onClick={()=> window.location.href= window.location.href.includes('app') ? "https://app.hellohousing.de/login" : "https://dev.hellohousing.de/login"}
                    className="purple-btn"
                    text={lang.text_back_to_login}
                  />
               </div>
           </CustomCard>
         </div>
       </div>
  );
};

export default ResetPassword;