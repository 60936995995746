import React, { Component } from 'react';
import moment from 'moment';
import { EmptyStateContainer, AddPropertyModal, SearchContainer, SortContainer } from '../../../../neoverv';
//import tap from '../../../../assets/images/module/general/TapIconDark.svg';
import tap from '../../../../assets/images/module/general/TapIconBlue.svg';
import PageHeader from '../../../elements/module/general/PageHeader';
import PageHeaderNew from '../../../elements/module/general/PageHeaderNew';
import UtilityBiliingRow from './UtilityBiliingRow.js';
import { connect } from 'react-redux';
import Autoload from '../../../../neoverv/Autoload';
import { ToastContainer } from 'react-toastify';
import {
  setUtilityBillingData,
  getUtilityBillingData,
} from '../../../../redux/action/UtilityBilling/UtilityBillingActions';
import MeterDataHandler from '../../../../neoverv/helpers/DataHandler/Meter';
import UtilityBillingDataHandler from '../../../../neoverv/helpers/DataHandler/UtilityBillings';
import GlobalServices from '../../../../neoverv/helpers/services/GlobalServices';
import { ThreeSixtyOutlined } from '@material-ui/icons';
import { Skeleton } from '@mantine/core';
import HelpLogo from '../../../../assets/images/module/general/book.svg';
import TourIcon from '../../../../assets/images/module/general/TourIcon.svg';
import breadcrumbsContent from '../../../../../src/assets/breadcrumbs.json';
import AddPropertyTypeModal from '../../../../neoverv/components/Module/General/CustomModal/AddPropertyTypeModal';
const { searchData, filterByDateUtility, checkEmpty, checkProperty, loader } = Autoload.MainHelper;

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];

var utilityArr = [];
var tableData = [];
var currentBillingPeriod = {};
var lastBillingPeriod = {};

const MOBILE_SIZE = 600;

class UtilityHeaders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dateDifference: 0,
      searchValue: '',
      ascendingOrder: true,
      filteredReadings: [],
      addPropertyModal: false,
      tableData: [],
      loader: false,
      currentBillingPeriodDate: '',
      lastBillingPeriodDate: '',
      billingPeriods: [],
      currentBillingPeriod: null,
      lastBillingPeriod: null,
      otherBillingPeriods: [],
      skeletonLoading: false,
      showArticleCondition: '',
      addPropertyTypeModal: false,
      selectedPropertyType: '',
      navigateUbId: null,
      BillingStatmentstenants: null,
      isMobile: window.innerWidth < MOBILE_SIZE
    };
    this.UtilityDataHandler = new UtilityBillingDataHandler();
  }

  componentDidMount() {
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'none';
    this.setState({skeletonLoading: true, showArticleCondition: this.props.rentalUnits.length > 0 ? 'rental_unit_created' : ''});
    // this.props.properties.length > 0
    //   ?
    this.setState({ filteredReadings: this.props.utilityBillingData }, () => {
      this.props.propertySelected && this.props.propertySelected.id !== undefined ? GlobalServices._getBilingPeriodsByProperty(this, '_handleBillingPeriodResponse', this.props.propertySelected) : this.ShowLoader()
      // this._getUtillityBillings();
    });
    // : loader(false);
    window.addEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    if (window.innerWidth <= MOBILE_SIZE) {
      this.setState({ isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
  };

  componentWillUnmount(){
    window.removeEventListener('resize', this.handleResize);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.rentalUnits.length !== this.props.rentalUnits.length) {
      this.setState({showArticleCondition: this.props.rentalUnits.length > 0 ? 'rental_unit_created' : ''});
    }
  }

  _handleBillingPeriodResponse(response) {
    console.log(response, '_handleBillingPeriodResponse');
    if (response.status && response.statuscode === 200) {
      const _billingPeriods = MeterDataHandler.generateBilingPeriods(response.result);
      console.log(_billingPeriods);
      let formatedBillingPeriods = _billingPeriods
      // .filter((value, index, self) =>
      // index === self.findIndex((t) => (
      //   t.startDate === value.startDate && t.endDate === value.endDate
      // )));
      this.setState(
        {
          billingPeriods: formatedBillingPeriods,
        },
        () => this._getUtillityBillings()
      );
    } else {
      //Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang.newKeys.text_utility_billing_err);
      this.ShowLoader();
    }
  }

  //data for current billing period
  _getUtillityBillings = () => {
    // loader(true);
    utilityArr = [];
    let propertyid = this.props.propertySelected && this.props.propertySelected.id;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'currentBillingPeriod?propertyId=' + propertyid,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      '_handleCurrentBillingPeriod',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
    // Autoload.HttpRequestAxios.HttpRequest(
    //   Autoload.Setting.property_api_base_url + 'lastBillingPeriod?propertyId=' + propertyid,
    //   'GET',
    //   null,
    //   null,
    //   false,
    //   true,
    //   false,
    //   this,
    //   '_handleLastBillingPeriod',
    //   null,
    //   false,
    //   '',
    //   {
    //     Authorization: Autoload.Utils.getAuthToken(),
    //   }
    // );
  };

  _handleCurrentBillingPeriod = (response) => {
    console.log('current utility billing api in utility ', response);
    if (response.status) {
      currentBillingPeriod = MeterDataHandler.generateBilingPeriodsObject(response.result, 'current');
      const billingPeriodStart = checkProperty(currentBillingPeriod, 'startDate');
      const billingPeriodEnd = checkProperty(currentBillingPeriod, 'endDate');
      this.setState({
        currentBillingPeriodDate: `${moment(billingPeriodStart).format('DD.MM.YYYY')} - ${moment(billingPeriodEnd).format('DD.MM.YYYY')}`,
        currentBillingPeriod
      });
    }
    if (response.status === false) {
      Autoload.Redirection.redirection_error();
      !this.state.create_ub && this.ShowLoader();
    }
    //data for last billing period
    let propertyid = this.props.propertySelected && this.props.propertySelected.id;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'lastBillingPeriod?propertyId=' + propertyid,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      '_handleLastBillingPeriod',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };
  _handleLastBillingPeriod = (response) => {
    if (response.status && response.statuscode === 200) {
      lastBillingPeriod = MeterDataHandler.generateBilingPeriodsObject(response.result, 'last');

      const billingPeriodStart = checkProperty(lastBillingPeriod, 'startDate');
      const billingPeriodEnd = checkProperty(lastBillingPeriod, 'endDate');

      this.setState({
        lastBillingPeriodDate: `${moment(billingPeriodStart).format('DD.MM.YYYY')} - ${moment(billingPeriodEnd).format('DD.MM.YYYY')}`,
        lastBillingPeriod
      });
    }else{
      !this.state.create_ub && this.ShowLoader();
    }


    const otherBillingPeriods = this.state.billingPeriods.filter(bp => bp.id !== currentBillingPeriod.id && bp.id !== lastBillingPeriod.id)
    console.log(otherBillingPeriods)
    this.setState({ otherBillingPeriods })

    if (response.status === false) {
      Autoload.Redirection.redirection_error();
      this.ShowLoader();
    }

    //get all utilities
    let propertyid = this.props.propertySelected && this.props.propertySelected.id;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.ub_api_base_url + 'utilityBillings?PropertyId=' + propertyid,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      '_handleUtillityBilling',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };

  _handleUtillityBilling = (response) => {
    console.log(response, 'all utilities');
    // billingPeriods: _billingPeriods,
    //
    if (response.status && response.statuscode === 200) {
      // last billing period
      if (Object.keys(this.state.lastBillingPeriod).length > 0) {
        let lastFound = {};

        lastFound = response.result.find(
          (record) => record.billingPeriod.id === this.state.lastBillingPeriod.id
        );

        if (lastFound && Object.keys(lastFound).length > 0) {
          utilityArr.push({
            ...this.state.lastBillingPeriod,
            ...this.UtilityDataHandler.createUtilityBillingObject(lastFound, 'last'),
            isSecured: lastFound.isSecured,
            heatingCostsActivated: lastFound.heatingCostsActivated,
            isVATHidden: lastFound.isVATHidden,
            isLaborCostHidden: lastFound.isLaborCostHidden
          });
        } else {
          const date = moment(this.state.lastBillingPeriod.startDate).format('DD.MM.YYYY') + ' - ' + moment(this.state.lastBillingPeriod.endDate).format('DD.MM.YYYY');
          utilityArr.push({
            billingPeriodId: this.state.lastBillingPeriod.id,
            propertyId: this.state.lastBillingPeriod.propertyId,
            startDate: this.state.lastBillingPeriod.startDate,
            endDate: this.state.lastBillingPeriod.endDate,
            type: 'last',
            recordStatus:'last',
            date,
            isSecured: this.state.lastBillingPeriod.isSecured,
            heatingCostsActivated: this.state.lastBillingPeriod.heatingCostsActivated
          });
        }
      }
      // current billing period
      if (Object.keys(this.state.currentBillingPeriod).length > 0) {
        let currentFound = {};

        currentFound = response.result.find(
          (record) => record.billingPeriod.id === this.state.currentBillingPeriod.id
        );

        if (currentFound && Object.keys(currentFound).length > 0) {
          utilityArr.push({
            ...this.state.currentBillingPeriod,
            ...this.UtilityDataHandler.createUtilityBillingObject(currentFound, 'current'),
            isSecured: currentFound.isSecured,
            heatingCostsActivated: currentFound.heatingCostsActivated,
            isVATHidden: currentFound.isVATHidden,
            isLaborCostHidden: currentFound.isLaborCostHidden
          });
        } else {
          const date = moment(this.state.currentBillingPeriod.startDate).format('DD.MM.YYYY') + ' - ' + moment(this.state.currentBillingPeriod.endDate).format('DD.MM.YYYY');
          utilityArr.push({
            billingPeriodId: this.state.currentBillingPeriod.id,
            propertyId: this.state.currentBillingPeriod.propertyId,
            startDate: this.state.currentBillingPeriod.startDate,
            endDate: this.state.currentBillingPeriod.endDate,
            type: 'current',
            recordStatus:'current',
            date,
            isSecured: this.state.currentBillingPeriod.isSecured,
            heatingCostsActivated: this.state.currentBillingPeriod.heatingCostsActivated
          });
        }
      }

      if (response.result.length > 1) {
        let filterLastCurrent = response.result.filter(
          (record) => record.billingPeriod.id !== this.state.currentBillingPeriod.id && record.billingPeriod.id !== this.state.lastBillingPeriod.id
        );
        console.log('otherBillingPeriods: ', filterLastCurrent);

        if(filterLastCurrent.length){
          const otherBillingPeriods = this.state.otherBillingPeriods.filter((bp=> filterLastCurrent.find((flc)=> bp.id === flc.billingPeriod.id) === undefined )).map(bp => ({
            billingPeriodId: bp.id,
            propertyId: bp.propertyId,
            startDate: bp.startDate,
            endDate: bp.endDate,
            type: bp.type,
            date: bp.startDate + ' - ' + bp.endDate,
            recordStatus: bp.type,
            isSecured: bp.isSecured,
            heatingCostsActivated: bp.heatingCostsActivated
          }));

          utilityArr = [
            ...utilityArr,
            ...filterLastCurrent.map((billingRow) => {
  
              return ({
                ...this.UtilityDataHandler.createUtilityBillingObject(billingRow),
                startDate: billingRow.billingPeriod.startDate,
                endDate: billingRow.billingPeriod.endDate,
                isSecured: billingRow.isSecured,
                heatingCostsActivated: billingRow.heatingCostsActivated,
                isVATHidden: billingRow.isVATHidden,
                isLaborCostHidden: billingRow.isLaborCostHidden
              })
            }),
            ...otherBillingPeriods
          ];
        } else {
            console.log('NofilterLastCurrent')
            const otherBillingPeriods = this.state.otherBillingPeriods.map(bp => ({
              billingPeriodId: bp.id,
              propertyId: bp.propertyId,
              startDate: bp.startDate,
              endDate: bp.endDate,
              type: bp.type,
              date: bp.startDate + ' - ' + bp.endDate,
              recordStatus: bp.type,
              isSecured: bp.isSecured,
              heatingCostsActivated: bp.heatingCostsActivated
            }))
            utilityArr = [...utilityArr, ...otherBillingPeriods]
        }
      } else if (this.state.otherBillingPeriods.length) {
        console.log('okokokokokokok')
        let filterLastCurrent = response.result.filter(
          (record) => record.billingPeriod.id !== this.state.currentBillingPeriod.id && record.billingPeriod.id !== this.state.lastBillingPeriod.id
        );

    

        if (filterLastCurrent.length) {
          const otherBillingPeriods = this.state.otherBillingPeriods.filter((bp=> filterLastCurrent.find((flc)=> bp.id === flc.billingPeriod.id) === undefined )).map(bp => ({
            billingPeriodId: bp.id,
            propertyId: bp.propertyId,
            startDate: bp.startDate,
            endDate: bp.endDate,
            type: bp.type,
            date: bp.startDate + ' - ' + bp.endDate,
            recordStatus: bp.type,
            isSecured: bp.isSecured,
            heatingCostsActivated: bp.heatingCostsActivated
          }));
          console.log('filterLastCurrent')
          utilityArr = [
            ...utilityArr,
            ...filterLastCurrent.map((billingRow) => {

              return ({
                ...this.UtilityDataHandler.createUtilityBillingObject(billingRow),
                startDate: billingRow.billingPeriod.startDate,
                endDate: billingRow.billingPeriod.endDate,
                isSecured: billingRow.isSecured,
                heatingCostsActivated: billingRow.heatingCostsActivated,
                isVATHidden: billingRow.isVATHidden,
                isLaborCostHidden: billingRow.isLaborCostHidden
              })
            }),
            ...otherBillingPeriods
          ];
        } else {
          console.log('NofilterLastCurrent')
          const otherBillingPeriods = this.state.otherBillingPeriods.map(bp => ({
            billingPeriodId: bp.id,
            propertyId: bp.propertyId,
            startDate: bp.startDate,
            endDate: bp.endDate,
            type: bp.type,
            date: bp.startDate + ' - ' + bp.endDate,
            recordStatus: bp.type,
            isSecured: bp.isSecured,
            heatingCostsActivated: bp.heatingCostsActivated
          }))
          utilityArr = [...utilityArr, ...otherBillingPeriods]
        }
      }
      console.log(utilityArr)
      this.setState({ filteredReadings: utilityArr }, () => this.getBillingStatements());
    }


    else {
      if (this.state.otherBillingPeriods.length) {
        utilityArr = [
          ...utilityArr,
          {
            billingPeriodId: this.state.currentBillingPeriod.id,
            propertyId: this.state.currentBillingPeriod.propertyId,
            date: moment(this.state.currentBillingPeriod.startDate).format('DD.MM.YYYY') +' - '+ moment(this.state.currentBillingPeriod.endDate).format('DD.MM.YYYY'),
            startDate: this.state.currentBillingPeriod.startDate,
            endDate: this.state.currentBillingPeriod.endDate,
            type: this.state.currentBillingPeriod.type,
            recordStatus: this.state.currentBillingPeriod.type,
            heatingCostsActivated: this.state.currentBillingPeriod.heatingCostsActivated
          },
          {
            billingPeriodId: this.state.lastBillingPeriod.id,
            propertyId: this.state.lastBillingPeriod.propertyId,
            date: moment(this.state.lastBillingPeriod.startDate).format('DD.MM.YYYY') +' - '+ moment(this.state.lastBillingPeriod.endDate).format('DD.MM.YYYY'),
            startDate: this.state.lastBillingPeriod.startDate,
            endDate: this.state.lastBillingPeriod.endDate,
            type: this.state.lastBillingPeriod.type,
            recordStatus: this.state.lastBillingPeriod.type,
            heatingCostsActivated: this.state.lastBillingPeriod.heatingCostsActivated
          },
        ];
        const otherBillingPeriods = this.state.otherBillingPeriods.map(bp => ({
          billingPeriodId: bp.id,
          propertyId: bp.propertyId,
          date: moment(bp.startDate).format('DD.MM.YYYY') +' - '+ moment(bp.endDate).format('DD.MM.YYYY'),
          startDate: bp.startDate,
          endDate: bp.endDate,
          type: bp.type,
          recordStatus: bp.type,
          heatingCostsActivated: bp.heatingCostsActivated
        }))
        utilityArr = [...utilityArr, ...otherBillingPeriods]


      } else {
        utilityArr = [
          ...utilityArr,
          {
            billingPeriodId: this.state.currentBillingPeriod.id,
            propertyId: this.state.currentBillingPeriod.propertyId,
            date: moment(this.state.currentBillingPeriod.startDate).format('DD.MM.YYYY') +' - '+ moment(this.state.currentBillingPeriod.endDate).format('DD.MM.YYYY'),
            startDate: this.state.currentBillingPeriod.startDate,
            endDate: this.state.currentBillingPeriod.endDate,
            type: this.state.currentBillingPeriod.type,
            heatingCostsActivated: this.state.currentBillingPeriod.heatingCostsActivated
          },
          {
            billingPeriodId: this.state.lastBillingPeriod.id,
            propertyId: this.state.lastBillingPeriod.propertyId,
            date: moment(this.state.lastBillingPeriod.startDate).format('DD.MM.YYYY') +' - '+ moment(this.state.lastBillingPeriod.endDate).format('DD.MM.YYYY'),
            startDate: this.state.lastBillingPeriod.startDate,
            endDate: this.state.lastBillingPeriod.endDate,
            type: this.state.lastBillingPeriod.type,
            heatingCostsActivated: this.state.lastBillingPeriod.heatingCostsActivated
          },
        ];
      }
      this.setState({ filteredReadings: utilityArr });
      let sortedList = filterByDateUtility(utilityArr, 'date', false);
      this.props.setUtilityBillingData(sortedList);
      !this.state.create_ub && this.ShowLoader();
    }
    //loader(false);

  };

  getBillingStatements = () => {
    // loader(true);
    utilityArr.map((utility, i) => {
      if(utility.id){
        Autoload.HttpRequestAxios.HttpRequest(
          Autoload.Setting.ub_api_base_url + 'billingStatements?utilityBillingId=' + utility.id,
          'GET',
          null,
          null,
          false,
          true,
          false,
          this,
          '_handleBillingStatementResponse',
          { utilityId: utility.id, loopEnd: i === utilityArr.length - 1 },
          false,
          '',
          {
            Authorization: Autoload.Utils.getAuthToken(),
          }
        );
      }else if(i === utilityArr.length - 1){
        //!this.state.create_ub && this.ShowLoader();
        //this.getFiles();
      }
    });
  };
  _handleBillingStatementResponse = (response) => {
    console.log('_handleBillingStatementResponse in utility', response);
    if (response.status === false) {
      let sortedList = filterByDateUtility(utilityArr, 'date', false);
      this.props.setUtilityBillingData(sortedList);
      
      this.state.navigateUbId && this.navigateToBilling(false, this.state.navigateUbId);
      !this.state.create_ub && this.ShowLoader();
      
      Autoload.Redirection.redirection_error();
      // Autoload.DataHandlerGeneral.GeneralErrorMessage(true, 'Billing statement not found');
    }
    if (response.status === true && response.statuscode === 401) {
      localStorage.clear();
      window.location.reload();
      !this.state.create_ub && this.ShowLoader()
    }

    if (response.status === true && response.statuscode === 200) {
      const tenants = this.UtilityDataHandler.currentBillingStatementsDataHandler(response.result);
      //add tenants data in utility array
      utilityArr = utilityArr.map((utility) => {
        if (utility.id === tenants[0].utilityBillingId) {
          return {
            ...utility,
            tableData: tenants.sort((a,b)=> b.occupencyPeriod.localeCompare(a.occupencyPeriod)),
          };
        } else {
          return utility;
        }
      });
      // if (response.other.loopEnd) {
      // console.log('loop ed', response.other.loopEnd);
      let sortedList = filterByDateUtility(utilityArr, 'date', false);
      console.log('sortedList', sortedList);
      this.props.setUtilityBillingData(sortedList);
      this.state.navigateUbId && this.navigateToBilling(false, this.state.navigateUbId);
      // this.setState({ filteredReadings: sortedList, BillingStatmentstenants: tenants });
      this.setState({
        filteredReadings: sortedList,
        BillingStatmentstenants: tenants.sort((a,b)=> b.occupencyPeriod.localeCompare(a.occupencyPeriod)),
      },()=> !this.state.create_ub && this.ShowLoader());//
      // this.getFiles();
      // }
    }
    if (response.other.loopEnd) {
      // loader(false);
      //this.getFiles();
      !this.state.create_ub && this.ShowLoader();
    }
  };

  getFiles = () => {
    //calling api for file url of each tableData row
    utilityArr.map((utility, indexMaster) => {
      checkProperty(utility, 'tableData', []).map((tableRow, i) => {
        Autoload.HttpRequestAxios.HttpRequest(
          Autoload.Setting.ub_api_base_url + `billingStatements/${tableRow.billingStatementId}/pdf`,
          'GET',
          null,
          null,
          false,
          true,
          false,
          this,
          '_handleBillingPDFResponse',
          {
            loopEnd: i === utility - 1,
            tableRowId: tableRow.id,
            utilityId: utility.id,
            isLastResponse: indexMaster === utilityArr.length && i + 1 === utility.tableData.length,
          },
          false,
          '',
          {
            Authorization: Autoload.Utils.getAuthToken(),
          }
        );
      });
    });
  };
  _handleBillingPDFResponse(response) {
    //find utility to which this file belongs
    let utility = utilityArr.find((utility) => utility.id === response.other.utilityId);
    //find table record in that utility to which this file belongs
    let tableRow =
      utility && utility.tableData ? utility.tableData.find((tenant) => tenant.id === response.other.tableRowId) : {};
    console.log('pdfResponse : ', response);
    //add that file
    let updatedRow = {
      ...tableRow,
      hasFile: response.result && !Array.isArray(response.result) ? true : false,
      fileURL: response.result && !Array.isArray(response.result) ? response.result : '',
    };
    //replace table row
    let updatedTable =
      utility && utility.tableData
        ? utility.tableData.map((row) => {
          if (row.id === updatedRow.id) {
            return updatedRow;
          } else {
            return row;
          }
        })
        : [];
    //replace utility bill
    let updatedUtility = utilityArr.map((util) => {
      if (util.id === utility.id) {
        return {
          ...util,
          tableData: updatedTable,
        };
      } else {
        return util;
      }
    });
    utilityArr = updatedUtility;
    // if(response.other.loopEnd) {
    let sortedList = filterByDateUtility(utilityArr, 'date', false);
    // this.props.setUtilityBillingData(sortedList);
    this.setState({ filteredReadings: sortedList });
    // }
    !this.state.create_ub && this.ShowLoader();
  }

  toggleAddPropertyModal = () => {
    this.setState({ addPropertyModal: !this.state.addPropertyModal });
  };
  toggleAddPropertyTypeModal = () => {
    this.setState({ addPropertyTypeModal: !this.state.addPropertyTypeModal });
  };
  handleSearchHandler = (e) => {
    // console.log('search')
    // let updatedList;
    // updatedList = searchData(e.target.value, this.props.utilityBillingData, ["date"], {
    //   tableData: ["tenant", "rentalUnit", "occupencyPeriod", "occupencyPeriodDays"],
    // })
    // this.setState({ searchValue: e.target.value, filteredReadings: updatedList });

    // console.log('search function', e.target.value)
    this.setState({ searchValue: e.target.value });
    let filtered = [];
    if (e.target.value === '') {
      filtered = this.props.utilityBillingData;
    } else {
      filtered = this.props.utilityBillingData.filter((reading) => {
        return reading.date.toString().toLowerCase().includes(e.target.value.toLowerCase()) === true;
      });
    }
    // console.log('filtered', filtered)
    //sort
    // let sortedList=utilityArr;

    let sortedList = filterByDateUtility(filtered, 'date', !this.state.ascendingOrder);
    // console.log('sortedList', sortedList)

    this.setState({ filteredReadings: sortedList });
  };
  filterListByDate = (data, param, ascendingOrder, nested, property, nestedParam) => {
    // let sortedList=utilityArr;
    let sortedList = filterByDateUtility(data, param, ascendingOrder, nested, property, nestedParam);
    this.setState({ filteredReadings: sortedList, ascendingOrder: !this.state.ascendingOrder });
  };
  ShowLoader = () => {
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'none';
    // var emptyDiv = document.getElementById('empty-utility_headers-type-page');
    // emptyDiv.style.display = 'none';
    // var dataDiv = document.getElementById('respond-utility_headers-type-page');
    // dataDiv.style.display = 'block';
    this.setState({skeletonLoading: false});
  };

  navigateToBilling = (isEdit, billingId = '') => {
    this.props.history.push(`/utility-billing/utility?currentBillingId=${isEdit ? this.props.data.id : billingId}&ub-step=${this.props.propertySelected.propertyType === 'REGULAR' ? 'heating-cost' : 'utility-cost'}`, {
      isEdit,
    });
    this.setState({navigateUbId: null});
  };

  render() {
    const { searchValue, ascendingOrder, filteredReadings, BillingStatmentstenants, addPropertyModal, skeletonLoading, showArticleCondition, billingPeriods, isMobile } = this.state;
    console.log('filteredReadings', billingPeriods);
    console.log('okkkkkkkkkkkkkkkkkkkkkkkkkkkkk');
    return (
      <>
        <div id="empty-utility_headers-type-page"></div>
        <div id="respond-utility_headers-type-page" style={{ display: 'block' }}>
          <div className="Utility-Billing-top-nav">
            <PageHeaderNew
              heading={lang.utility.text_utility_billing}
              secondRowContent={
                <div style={{display: 'flex'}}>
                  {!isMobile && !skeletonLoading && breadcrumbsContent.map((item)=> item.area === '/utility-billing' && 
                  item.breadcrumbs.map((breadcrumb)=>
                  breadcrumb.type === 'tour' ? breadcrumb.condition === showArticleCondition && breadcrumb.view === 'desktop' &&
                  <div style={{display: 'flex', width: 'auto', alignItems: 'center', cursor: 'pointer', marginRight: '22px'}} onClick={()=> window.Intercom('startTour', breadcrumb.id)}>
                    <img src={TourIcon} />
                    <span style={{color: '#5555FF', marginLeft: '5px', fontSize: '14px'}}>{breadcrumb.label}</span>
                  </div>
                  :
                  breadcrumb.view === 'desktop' &&
                  breadcrumb.condition === this.props.propertySelected.propertyType &&
                  <div style={{display: 'flex', width: 'auto', alignItems: 'center', cursor: 'pointer', marginRight: '22px'}} onClick={()=> window.Intercom('showArticle', breadcrumb.id)}>
                    <img src={HelpLogo} />
                    <span style={{color: '#5555FF', marginLeft: '5px', fontSize: '14px'}}>{breadcrumb.label}</span>
                  </div>
                  ))}
                </div>
              }
              thirdRowContent={
                <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                      <Skeleton visible={!isMobile && skeletonLoading} style={{width: '300px'}}>
                        <SearchContainer
                          placeholder={`${lang.general.text_search}...`}
                          value={this.state.searchValue}
                          onChange={this.handleSearchChange}
                          disabled={false}
                          onFocus={() => this.setState({ focus: true })}
                          onBlur={() => this.setState({ focus: false })}
                        />
                      </Skeleton>
                    
                </div>
              }
            />
          </div>

          <div>
            {!isMobile && skeletonLoading ?
            [...Array(5)].map((e, i) => 
            <div>
              <div style={{width: '100%', height: '45px', display: 'flex', justifyContent: 'space-between'}}>
                <Skeleton visible={skeletonLoading} style={{width: '400px', height: '34px'}}/>
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                  <Skeleton visible={skeletonLoading} style={{width: '20px', height: '10px', marginRight: '10px'}}/>
                  <Skeleton visible={skeletonLoading} style={{width: '125px', height: '36px'}}/>
                </div>
              </div>
              <div className='divider'/>
            </div>)
            :
            this.props.properties.length === 0 ? (
              <EmptyStateContainer
                heading={lang.general.text_no_property_added}
                subheading={lang.general.text_must_add_property}
                icon={tap}
                btnHandler={() => {
                  this.toggleAddPropertyTypeModal();
                }}
                btnText={lang.general.text_add_property}
              />
            ) : this.props.properties.length !== 0 && !this.props.rentalunits?.length ? ( //this.props.utilityBillingData.length === 0
              <EmptyStateContainer
                heading="Betriebskosten sind noch nicht verfügbar."
                subheading={checkEmpty(this.props.properties) ? "Legen Sie zunächst eine Immobilie und Mieteinheiten an, um Betriebskosten abzurechnen." : 'Legen Sie zunächst Mieteinheiten an, um Betriebskosten abzurechnen.'}
                icon={tap}
                btnHandler={() => {
                  this.props.history.push('/user');
                }}
                btnText="Einheit hinzufügen"
              // showBtn={false}
              />
            ) : (!isMobile &&
              filteredReadings.map((item, index) => {
                return (
                  <UtilityBiliingRow
                    history={this.props.history}
                    data={item}
                    index={index}
                    key={`fr-1-${index}`}
                    utilityBiliingRowIndex={index}
                    lastDate={this.state.lastBillingPeriodDate}
                    currentDate={this.state.currentBillingPeriodDate}
                    getUtillityBillings={(create_ub, ubId)=> {
                      this.setState({navigateUbId: ubId});
                      this._getUtillityBillings();
                      this.setState({create_ub: create_ub});
                    }}
                    loading={()=> this.setState({skeletonLoading: true})}
                    billingPeriods={billingPeriods}
                  />
                );
              })
            )}

            {/* {} */}
          </div>
        </div>
        {addPropertyModal && (
          <AddPropertyModal
            className="add_proprty_modal"
            edit={false}
            open={addPropertyModal}
            toggle={() => {
              this.toggleAddPropertyModal();
            }}
            propertyType={this.state.selectedPropertyType}
          />
        )}
        {this.state.addPropertyTypeModal &&
          <AddPropertyTypeModal
            className="add_proprty_type_modal"
            open={this.state.addPropertyTypeModal}
            toggle={() => {
              this.toggleAddPropertyTypeModal();
            }}
            next={(selectedPropertyType)=> {
              this.setState({selectedPropertyType});
              this.toggleAddPropertyTypeModal();
              this.toggleAddPropertyModal();
            }}
          />
          }
        <ToastContainer
          position="top-center"
          autoClose={false}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
        />
        {/* <p style={{color: this.state.dateDifference == 0 ? "grey" : "blue"}}>{this.state.dateDifference == 0  ? "Current billing address" : "Last billing address"}</p>
                {this.props.tabelData.length > 0 ? show table : null} */}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    utilityBillingData: state.UtilityBillingReducer.utilityBillingData,
    filteredBilling: state.UtilityBillingReducer.filteredBilling,
    properties: state.generalReducer.allProperties,
    propertySelected: state.generalReducer.property,
    loader: state.UtilityBillingReducer.loader,
    rentalunits: state.tenantDataReducer.filteredList,
    rentalUnits: state.tenantDataReducer.rentalUnits,
  };
};
const actions = {
  setUtilityBillingData,
  getUtilityBillingData,
};
export default connect(mapStateToProps, actions)(UtilityHeaders);
