import React, { Component } from 'react';
// import { DateRangePicker } from 'rsuite';
// import "rsuite/dist/styles/rsuite-default.css"
import DateRangePicker, { registerLocale } from 'react-bootstrap-daterangepicker';
import de from 'date-fns/locale/de';
import 'bootstrap/dist/css/bootstrap.css';
// you will also need the css that comes with bootstrap-daterangepicker
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';
import { Row, Col, Form, InputGroup } from 'react-bootstrap';
import calendar from '../../../../../assets/images/module/general/CalendarIcon.svg';
import * as customParseFormat from 'dayjs/plugin/customParseFormat'
import dayjs from 'dayjs';

// registerLocale('de', de);
dayjs.extend(customParseFormat);

export class RangeDatePicker extends Component {
  state = {
    focus: false,
  };

  handleCallback = (start, end) => {
    const customDate = {
      start: start._d,
      end: end._d,
    };
    // const frame = {
    //   startFrame: startDateRange2,
    //   endFrame: EndDateRange2,
    // };

    var startDateMonth = customDate.start.getMonth() + 1;
    var startDateDate = customDate.start.getDate();

    var startDateRange = `${startDateDate > 9 ? startDateDate : '0' + startDateDate
  }.${startDateMonth > 9 ? startDateMonth : '0' + startDateMonth}.${customDate.start.getFullYear().toString()}`;

    // var startDateRange2 = `${customDate.start.getFullYear()}-${
    //   startDateMonth > 10 ? startDateMonth : '0' + startDateMonth
    // }-${startDateDate > 10 ? startDateDate : '0' + startDateDate}`;

    var endDateMonth = customDate.end.getMonth() + 1;
    var endDateDate = customDate.end.getDate();

    var EndDateRange = `${endDateDate > 9 ? endDateDate : '0' + endDateDate}.${endDateMonth > 9 ? endDateMonth : '0' + endDateMonth}.${customDate.end.getFullYear().toString()}`;
    // var EndDateRange2 = `${customDate.end.getFullYear()}-${endDateMonth > 10 ? endDateMonth : '0' + endDateMonth}-${
    //   endDateDate > 10 ? endDateDate : '0' + endDateDate
    // }`;

    // console.log(EndDateRange, startDateRange, 'range =====EndDateRange');
    this.props.setDate({ start: startDateRange, end: EndDateRange });
  };

  //08.23.21 --> yy mm dd (js)
  formatDate = (date) => {
    return date === '' ? dayjs() : dayjs(date, 'DD.MM.YYYY').format('DD.MM.YYYY');
  };

  render() {
    const { start, end } = this.props.customDate;
    const months = [
      'Januar', //[["name","shortName"], ... ]
      'Februar',
      'März',
      'April',
      'Mai',
      'Juni',
      'Juli',
      'August',
      'September',
      'Oktober',
      'November',
      'Dezember',
    ];
    const weekDays = [
      ['sun', 's'], //[["name","shortName"], ... ]
      ['mon', 'm'],
      ['tue', 't'],
      ['wed', 'w'],
      ['thu', 't'],
      ['fri', 'f'],
      ['sat', 's'],
    ];
    // console.log('date passed in component Date range picker', this.props.customDate);
    return (
      <div className={this.props.mainContainerClassName} style={{ position: 'relative', top: 0, left: 0 }}>
        <img src={calendar} alt="no" className={this.props.imgClassName} style={{ position: 'absolute', top: 9, left: '5px' }} />
        <DateRangePicker

          placeholder="abc"
          onCallback={this.handleCallback}
          // formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 1)}
          // months={months}
          // weekDays="de"
          initialSettings={{
            startDate: start ? this.formatDate(start) : dayjs(),
            endDate: end ? this.formatDate(end) : dayjs(),
            drops: 'up',
            autoApply: true,
            autoUpdateInput: true,
            // locale: {
            //   monthNames: months
            // }
            locale: { format: 'DD.MM.YYYY', monthNames: months, weekDays:'de' },
            // locale: 'de',
          }}
        >
          <input
            style={{ 
              paddingLeft: '40px', //paddingBottom: '8.5px' 
              zIndex: 99999
            }}
            className={`form-control date-range-focus ${this.props.inputClassName}`}
            type="text"
            placeholder="placeholder"
            // onKeyDown={(event) => {
            //   //event.preventDefault();
            // }}
          />
        </DateRangePicker>
      </div>
    );
  }
}

export default RangeDatePicker;
