import React, { Component } from 'react';
import CustomModal from './index';
// import { Table } from 'reactstrap';
import { Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { ColorButton } from '../../../../index';
import { SaveZuweisungData } from '../../../../../redux/action/UtilityBilling/UtilityBillingActions';
import Autoload from '../../../../Autoload';
import moment from 'moment';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { showNotification } from '@mantine/notifications';
import RedCross from '../../../../../assets/images/module/general/RedCross.svg';
import GreenTick from '../../../../../assets/images/module/general/GreenTick.svg';
import BlueUnitsIcon from '../../../../../assets/images/module/general/BlueEmptyTenant.svg';
import EmptyStateContainer from '../EmptyStateContainer/EmptyStateContainer';
import { NumericFormat } from 'react-number-format';
import { Group, Loader } from '@mantine/core';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
const { checkProperty, emptyFieldValidation, invalidSize, loader } = Autoload.MainHelper;

class FixedAllocationKeyModal extends Component {
  constructor(props) {
    super(props);
    this.state = this.initialState;
  }
  initialState = {
    data: [],
    rentalUnits: [],
    tenants: [],
    loader: false,
    valueInFocus: false,
    totalValueInFocus: false
  };
  componentDidMount() {
    this.getRentalUnits();
    console.log('propertySelected: ', this.props.propertySelected.propertyType);
  }

  getRentalUnits=()=>{
    this.setState({loader: true});
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'rentalUnits?offset=0&fetch=100&propertyId=' + this.props.propertySelected.id,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleGetRentalUnits',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  handleGetRentalUnits(response) {
    console.log('handleGetRentalUnits: ', response);
    if(response.status && response.statuscode === 200){
      this.setState({rentalUnits: response.result}, async ()=> {
        await response.result.map((rentalUnit)=> this.getTenants(rentalUnit.id));
        this.getAllocationKeyValues(this.props.allocationKeyId);
      });
    }else{
      this.setState({loader: false});
    }
  }


  getTenants=(id)=>{
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'tenants?offset=0&fetch=100&rentalUnitId=' + id,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleGetTenants',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  handleGetTenants(response){
    if(response.status && response.statuscode === 200){
      let {tenants} = this.state;
      response.result.map((tenant)=> tenants.push(tenant));
      this.setState({tenants});
    }
  }

  getAllocationKeyValues=(id)=>{
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'allocationKeyValues?allocationKeyId=' + id,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleGetAllocationKeyValues',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  putAllocationKeyValue=()=>{
    loader(true);
    const {data} = this.state;
    data.map((dt, index)=> 
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'allocationKeyValues/' + dt.id,
      'PUT',
      this.props.type === 'FIXED_RENTAL_UNIT' ? 
      {
        rentalUnitId: dt.rentalUnitId,
        value: dt.value,
        id: dt.id
      }
      :
      this.props.type === 'FIXED_TENANT' ?
        {
          tenantId: dt.tenantId,
          value: dt.value,
          id: dt.id
        } 
        : 
        null,
      null,
      false,
      true,
      false,
      this,
      'handlePutAllocationKeyValue',
      {index},
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    ));
  }

  handlePutAllocationKeyValue(response){
    console.log('handlePutAllocationKeyValue: ', response);
    if(response.status && response.statuscode === 200){
      if(response.other.index === (this.state.data.length - 1)){
        showNotification({
          message: 'Die Änderungen wurden übernommen.',
          icon: <img src={GreenTick} alt='red-tick'/>
        })
        loader(false);
        this.props.toggle(true);
      }
    }
  }

  handleGetAllocationKeyValues(response){
    console.log(response);
    if(response.status && response.statuscode === 200){
      this.setState({data: response.result});
      this.setState({loader: false});
    }else{
      this.setState({loader: false});
    }
  }

  resetForm = () => {
    this.setState(this.initialState);
  };
  onAmountChange = (recordId, field, value) => {
    //get record to which this amount belongs
    let tenantRecord = this.state.data.find((item) => item.id == recordId);
    //update this record with your input amount
    let updatedtenantRecord = { ...tenantRecord, value: parseFloat(value)};
    //replace this record with old record
    let updatedRecord = this.state.data.map((rc) => {
      if (rc.id == updatedtenantRecord.id) {
        return updatedtenantRecord;
      } else {
        return rc;
      }
    });
    // console.log('updatedRecord',updatedRecord, this.props.costPositionId)
    this.setState({ data: updatedRecord });
  };
  validateData = (data, field, fieldName) => {
    let invalidErr, emptyErr;
    emptyErr = emptyFieldValidation(data.amount.replaceAll('€', '').trim());
    invalidErr = invalidSize(data.amount.replaceAll('€', '').trim());
    if (emptyErr) {
      let updatedtenantRecord = { ...data, err: true, msg: `${lang.general.text_enter} ${fieldName}` };
      let updatedRecord = this.state.data.map((rc) => {
        if (rc.id == updatedtenantRecord.id) {
          return updatedtenantRecord;
        } else {
          return rc;
        }
      });
      this.setState({ data: updatedRecord });
      return true;
    } else if (invalidErr) {
      let updatedtenantRecord = { ...data, err: true, msg: `${lang.general.text_invalid} ${fieldName}` };
      let updatedRecord = this.state.data.map((rc) => {
        if (rc.id == updatedtenantRecord.id) {
          return updatedtenantRecord;
        } else {
          return rc;
        }
      });
      this.setState({ data: updatedRecord });
      return true;
    } else {
      let updatedtenantRecord = { ...data, err: false, msg: '' };
      let updatedRecord = this.state.data.map((rc) => {
        if (rc.id == updatedtenantRecord.id) {
          return updatedtenantRecord;
        } else {
          return rc;
        }
      });
      this.setState({ data: updatedRecord });
      return false;
    }
  };
  // handleSubmit = async (e) => {
  //   const { data } = this.state;
  //   e.preventDefault();
  //   e.stopPropagation();
  //   let errors = [];
  //   let err;
  //   for (let i = 0; i < data.length; i++) {
  //     err = await this.validateData(data[i], 'amount', 'amount');
  //     errors.push(err);
  //   }
  //   let isInvalid = errors.find((item) => item == true);
  //   if (isInvalid) {
  //     console.log('invalid data');
  //   } else {
  //     this.addData();
  //   }
  // };
  addData = () => {  
    loader(true);
    let formatedData = this.state.data.map((data) => {
      return {
        id: checkProperty(data, 'id'),
        utilityBillingId: checkProperty(data, 'utilityBillingId'),
        costPositionId: checkProperty(data, 'costPositionId'),
        costTypeName: checkProperty(data, 'costTypeName'),
        allocationTypeName: checkProperty(data, 'allocationTypeName'),
        billingStatementId: checkProperty(data, 'billingStatementId'),
        tenantName: checkProperty(data, 'tenant'),
        rentalUnitId: checkProperty(data, 'rentalUnitId'),
        rentalUnitName: checkProperty(data, 'rentalUnit'),
        tenantOccupation: checkProperty(data, 'tenantOccupation', {}),
        tenantConsumption: checkProperty(data, 'tenantConsumption'),
        totalConsumption: checkProperty(data, 'totalConsumption'),
        consumptionUnits: checkProperty(data, 'consumptionUnits'),
        totalCostShare: checkProperty(data, 'totalCostShare'),
        tenantCostShare: Autoload.MainHelper.ConverterLocaleNumber(data.amount, 'de'),
        // amount: Autoload.MainHelper.ConverterLocaleNumber(data.amount, "de"),
      };
    });
    Autoload.HttpRequestAxios.HttpRequest(
      // Autoload.Setting.swagger_mocked_api_baseUrl +
      Autoload.Setting.ub_api_base_url +
        'costAllocations/costAllocations/forCostPosition?utilityBillingId=' +
        this.props.billingId +
        '&costPositionId=' +
        this.props.costPositionId,
      'PUT',
      formatedData,
      null,
      false,
      true,
      false,
      this,
      'handleAddData',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };
  handleAddData = (response) => {
    if (response.status == false) {
      Autoload.Redirection.redirection_error();
      Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang.newKeys.text_error_saving_cost_position);
      showNotification({
        message: 'Es ist ein Fehler aufgetreten. Bitte probieren Sie es erneut oder wenden Sie sich an den Support.',
        icon: <img src={RedCross} alt='red-cross'/>,
      })
    }
    if(response.status === true) {
      showNotification({
        message: 'Die Änderungen wurden übernommen.',
        icon: <img src={GreenTick} alt='green-tick'/>,
      })
    }
    this.props.toggle();
    this.props.saveAmount(this.getTotalAmount());
    loader(false);
    this.resetForm();
  };

  getTotalAmount = () =>{
    return Autoload.MainHelper.ConverterCurrencyDe_DisplayOnly(this.state.data.reduce(
      (total, currentValue) => total + Autoload.MainHelper.ConvertCurrencDe_BackToNumber(currentValue.amount, 'de'),
      0) + '', true)
  }

  handleEnterKeyPress = (e) => {
    if (e.charCode == 13) {
      e.preventDefault();
      this.handleSubmit(e);
    }
  };

  render() {
    const { open, toggle, className, edit, record, type, name } = this.props;
    const {data, rentalUnits, tenants, loader, valueInFocus, totalValueInFocus} = this.state;
    console.log('data: ', data);
    return (
      <div className="Zuweisung-table">
        <CustomModal
          heading={`Fester Verteilerschlüssel ${type === 'FIXED_RENTAL_UNIT' ? `(${name})` : '(Mieter)'}: Werte festlegen`}
          headingClassName={'ub-individual-assignment-modal-heading'}
          open={open}
          toggle={() => {
            toggle();
          }}
          className={`${className}`}
        >
          {/* <Form //onSubmit={this.handleSubmit} 
          noValidate> */}
          {!loader ? rentalUnits.length > 0 ? 
            <>
            <Table responsive
            className="table-material-Zuweisung"
            style={{borderCollapse: 'separate', borderSpacing: '0px 8px'}}
            >
              {data.length > 0 && (
                <TableHead
                >
                  {type === 'FIXED_RENTAL_UNIT' ?
                  <TableRow>
                    <TableCell
                    >{'Mieteinheit'}</TableCell>
                    <TableCell
                    >{'Einheit'}</TableCell>
                    <TableCell
                    >{'Wert'}</TableCell>
                  </TableRow>
                  :
                  <TableRow>
                    <TableCell
                    >{'Mieter'}</TableCell>
                    <TableCell
                    >{'Mieteinheit'}</TableCell>
                    <TableCell
                    >{'Mietzeitraum'}</TableCell>
                    <TableCell>{'Einheit'}</TableCell>
                    <TableCell
                    >{'Wert'}</TableCell>
                  </TableRow>}
                </TableHead>
              )}
              <TableBody>
              {data.length > 0 &&
                data.filter((dt)=> type === 'FIXED_RENTAL_UNIT' ? rentalUnits.find((rentalUnit)=> rentalUnit.id === dt.rentalUnitId) !== undefined : tenants.find((tenant)=> tenant.id === dt.tenantId) !== undefined)
                .sort((a, b)=> type === 'FIXED_RENTAL_UNIT' ? 
                rentalUnits.find((rentalUnit)=> a.rentalUnitId === rentalUnit.id)?.name.toString().localeCompare(rentalUnits.find((rentalUnit)=> b.rentalUnitId === rentalUnit.id)?.name.toString())
                :
                tenants.find((tenant)=> tenant.id === a.tenantId)?.fullName.toString().localeCompare(tenants.find((tenant)=> tenant.id === b.tenantId)?.fullName.toString()))
                .map((dt, idx) => {
                  return (
                    type === 'FIXED_RENTAL_UNIT' ?
                    <TableRow key={idx}
                    >
                      <TableCell
                      width={'60%'}
                      >
                        <div>{rentalUnits.find((rentalUnit)=> dt.rentalUnitId === rentalUnit.id)?.name}</div>
                      </TableCell>
                      <TableCell
                      width={'20%'}
                      >
                        <div>{this.props.unit}</div>
                      </TableCell>
                      <TableCell
                      width={'20%'}
                      >
                        {/* <Form.Control
                          type="number"
                          isInvalid={dt.err}
                          placeholder="0,00"
                          value={dt.value}
                          onChange={(e) => this.onAmountChange(dt.id, 'value', e.target.value)}
                          style={{textAlign: 'right'}}
                          // onFocus={(e) => this.removeSymbol(e.target.value, '€')}
                          // onBlur={(e) => this.addSymbol(e.target.value, '€')}
                          onBlur={(e) =>
                            this.onAmountChange(
                              dt.id,
                              'value',
                              e.target.value
                            )
                          }
                        />
                        <Form.Control.Feedback className="err" type="invalid">
                          {' '}
                          {dt.msg}{' '}
                        </Form.Control.Feedback> */}
                        <NumericFormat 
                        className={`form-control ${dt.err && 'invalid'}`}
                        value={dt.value}
                        thousandSeparator="." 
                        decimalSeparator=","
                        onValueChange={(values, sourceInfo) => {
                          this.onAmountChange(dt.id, 'value', values.floatValue);
                        }}
                        onKeyPress={this.handleEnterKeyPress}
                        placeholder='0,00'
                        style={{textAlign: 'right'}}
                        onFocus={()=> this.setState({valueInFocus: dt.id})}
                        onBlur={()=> this.setState({valueInFocus: false})}
                        decimalScale={valueInFocus === dt.id ? 5 : 2}
                        fixedDecimalScale={valueInFocus === dt.id ? false : true}
                        allowNegative={true}
                        />
                      </TableCell>
                    </TableRow>
                    :
                    <TableRow key={idx}
                    >
                      <TableCell
                      width={'20%'}
                      style={{}}
                      ><div>{tenants.find((tenant)=> tenant.id === dt.tenantId)?.fullName}</div></TableCell>
                      <TableCell
                      width={'20%'}
                      >
                        <div>{rentalUnits.find((rentalUnit)=> tenants.find((tenant)=> tenant.id === dt.tenantId)?.rentalUnitId === rentalUnit.id)?.name}</div>
                      </TableCell>
                      <TableCell 
                      width={'20%'}
                      >
                        <span>
                          {tenants.find((tenant)=> tenant.id === dt.tenantId)?.moveInDate ? `${tenants.find((tenant)=> tenant.id === dt.tenantId)?.moveInDate && moment(tenants.find((tenant)=> tenant.id === dt.tenantId)?.moveInDate).format('DD.MM.YYYY')} - ${tenants.find((tenant)=> tenant.id === dt.tenantId)?.moveOutDate ? moment(
                            tenants.find((tenant)=> tenant.id === dt.tenantId)?.moveOutDate
                          ).format('DD.MM.YYYY') : 'aktuell'}` : '-'}
                        </span>
                      </TableCell>
                      <TableCell
                      width={'20%'}
                      >
                        <div>{this.props.unit}</div>
                      </TableCell>
                      <TableCell
                      width={'20%'}
                      >
                        {/* <Form.Control
                          type="text"
                          isInvalid={dt.err}
                          placeholder="0,00"
                          value={dt.value}
                          onChange={(e) => this.onAmountChange(dt.id, 'value', e.target.value)}
                          style={{textAlign: 'right'}}
                          // onFocus={(e) => this.removeSymbol(e.target.value, '€')}
                          // onBlur={(e) => this.addSymbol(e.target.value, '€')}
                          onBlur={(e) =>
                            this.onAmountChange(
                              dt.id,
                              'value',
                              e.target.value
                            )
                          }
                        /> */}
                        <NumericFormat 
                        className={`form-control ${dt.err && 'invalid'}`}
                        value={dt.value}
                        thousandSeparator="." 
                        decimalSeparator=","
                        onValueChange={(values, sourceInfo) => {
                          this.onAmountChange(dt.id, 'value', values.floatValue);
                        }}
                        onKeyPress={this.handleEnterKeyPress}
                        placeholder='0,00'
                        style={{textAlign: 'right'}}
                        onFocus={()=> this.setState({valueInFocus: dt.id})}
                        onBlur={()=> this.setState({valueInFocus: false})}
                        decimalScale={valueInFocus === dt.id ? 5 : 2}
                        fixedDecimalScale={valueInFocus === dt.id ? false : true}
                        allowNegative={true}
                        />
                        <Form.Control.Feedback className="err" type="invalid">
                          {' '}
                          {dt.msg}{' '}
                        </Form.Control.Feedback>
                      </TableCell>
                    </TableRow>
                  );
                })}
                </TableBody>
            </Table>
            <Table responsive
            className="table-material-Zuweisung-2"
            >
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell style={{paddingBottom: '8px'}}>
                    <span>
                        {this.props.propertySelected.propertyType === 'CONDOMINIUM' ? 'Gesamt (Mietshaus)' : 'Gesamt'}
                    </span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow key={this.state.data.length}>
                  <TableCell width={'20%'}></TableCell>
                  <TableCell width={'20%'}></TableCell>
                  <TableCell width={'20%'}></TableCell>
                  <TableCell width={'20%'}></TableCell>
                  <TableCell width={'20%'}>
                    {this.props.propertySelected.propertyType === 'CONDOMINIUM' ? 
                    data.length > 0 && data.map((dt)=>
                    dt.valueType === 'TOTAL' &&
                      // <Form.Control
                      //     type="text"
                      //     isInvalid={dt.err}
                      //     placeholder="0,00"
                      //     value={dt.value}
                      //     onChange={(e) => this.onAmountChange(dt.id, 'value', e.target.value)}
                      //     style={{textAlign: 'right'}}
                      //     // onFocus={(e) => this.removeSymbol(e.target.value, '€')}
                      //     // onBlur={(e) => this.addSymbol(e.target.value, '€')}
                      //     onBlur={(e) =>
                      //       this.onAmountChange(
                      //         dt.id,
                      //         'value',
                      //         e.target.value
                      //       )
                      //     }
                      //   />
                      <NumericFormat 
                        className={`form-control ${dt.err && 'invalid'}`}
                        value={dt.value}
                        thousandSeparator="." 
                        decimalSeparator=","
                        onValueChange={(values, sourceInfo) => {
                          this.onAmountChange(dt.id, 'value', values.floatValue);
                        }}
                        onKeyPress={this.handleEnterKeyPress}
                        placeholder='0,00'
                        style={{textAlign: 'right'}}
                        onFocus={()=> this.setState({valueInFocus: dt.id})}
                        onBlur={()=> this.setState({valueInFocus: false})}
                        decimalScale={valueInFocus === dt.id ? 5 : 2}
                        fixedDecimalScale={valueInFocus === dt.id ? false : true}
                        allowNegative={true}
                        />
                        )
                      :
                      <NumericFormat 
                        className={`form-control`}
                        value={data.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.value ? a.value : null), 0)}
                        thousandSeparator="." 
                        decimalSeparator=","
                        placeholder='0,00'
                        style={{textAlign: 'right'}}
                        onFocus={()=> this.setState({totalValueInFocus: true})}
                        onBlur={()=> this.setState({totalValueInFocus: false})}
                        decimalScale={totalValueInFocus === false ? 2 : 5}
                        fixedDecimalScale={totalValueInFocus === false ? true : false}
                        allowNegative={true}
                        disabled={true}
                        />
                      // <span style={{textAlign: 'right', background: 'rgb(247, 247, 250)'}} className="form-control">{data.reduce((partialSum, a) => partialSum + a.value, 0)}</span>
                    }
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            </>
            : 
            <EmptyStateContainer
              heading="Es sind noch keine Mieteinheiten verfügbar."
              subheading='Legen Sie zunächst Mieteinheiten an, um Verteilerwerte zuzuweisen.'
              icon={BlueUnitsIcon}
              showBtn={true}
              btnHandler={()=> this.props.history.push('/user')}
              btnText={'Mieteinheit anlegen'}
              styleMainContainer={{marginTop: '30px'}}
            />
          :
          <Group position='center' grow style={{margin: '100px 0px'}}>
            <Loader color="rgba(86, 85, 255, 1.00)"/>
          </Group>}
            <div className="cancel_addbtn-Zuweisung">
              <div>
                <ColorButton
                  onClick={(e) => {
                    e.preventDefault();
                    this.resetForm();
                    toggle();
                  }}
                  className="gray-btn"
                  text={lang.general.text_cancel}
                />
              </div>
              
              <div>
                <ColorButton
                  onClick={()=> this.putAllocationKeyValue()}
                  className="purple-btn"
                  text={'Speichern'}
                  disabled={rentalUnits.length <= 0}
                />
              </div>
            </div>
          {/* </Form> */}
        </CustomModal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profileData: state.generalReducer.profile,
    allProperties: state.generalReducer.allProperties,
    propertySelected: state.generalReducer.property,
  };
};

const actions = {
  SaveZuweisungData,
};
export default connect(mapStateToProps, actions)(FixedAllocationKeyModal);
