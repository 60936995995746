import { Group, Loader } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import React from 'react';
import RedCross from '../assets/images/module/general/RedCross.svg';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    console.error("Error caught by Error Boundary:", error, info);
    
    var metadata = {
        error: error.toString(),
        info: JSON.stringify(info)
    };
    window.Intercom('trackEvent', 'error-boundary', metadata);

    showNotification({
        message: <span style={{fontWeight: '300', fontSize: '12px', fontFamily: 'Inter, sans-serif'}}>Es ist ein Fehler aufgetreten. Bitte probieren Sie es erneut oder wenden Sie sich an den Support.</span>,
        icon: <img src={RedCross} alt='red-cross'/>,
        id: 'boundaryError'
    });
    if(!window.location.href.includes('localhost')){
        setTimeout(() => {
            window.location.reload();    
        }, 2000);
    }
  }

  render() {
    if (this.state.hasError) {
      return <Group position='center' align='center' style={{height: '100vh', width: '100%'}}>
        <Loader size={'lg'}/>
      </Group>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
