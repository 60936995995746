import Autoload from "../../Autoload";

class GlobalServices {

    _getCostTypeByProperty(functionCallingContext, responseFunctionName, props, cbData = null) {
        console.log('_getCostTypeByProperty: ', cbData);
        props.id !== undefined && 
        Autoload.HttpRequestAxios.HttpRequest(
            Autoload.Setting.property_api_base_url + "costTypes?propertyId=" + props.id + "&offset=0&fetch=100",
            "GET",
            null, null, false, true, false,
            functionCallingContext,
            responseFunctionName,
            cbData, false, "",
            {
                'Content-Type': 'application/json',
                'Authorization': Autoload.Utils.getAuthToken()
            }
        );
    }

    _getAllocationKeysByProperty(functionCallingContext, responseFunctionName, props, others) {
        console.log('_getAllocationKeysByProperty: ', others);
        props.id !== undefined && 
        Autoload.HttpRequestAxios.HttpRequest(
            Autoload.Setting.property_api_base_url+"AllocationKeys?propertyId="+props.id+"&offset=0&fetch=100",
            "GET", 
            null, null, false, true, false,
            functionCallingContext,
            responseFunctionName,
            others ? others : null, false, "",
            {
                'Content-Type': 'application/json',
                Authorization: Autoload.Utils.getAuthToken()
            }
        );
    }

    _getReceiptsByProperty(functionCallingContext, responseFunctionName, props) {
        Autoload.HttpRequestAxios.HttpRequest(
            // Autoload.Setting.rm_api_base_url+"receipts?propertyId="+props.id,
            Autoload.Setting.rm_api_base_url+"property/"+props.id,
            // Autoload.Setting.api_base_url+"receipt-api/receipt?propertyId="+props.id,
            "GET", 
            null, null, false, true, false,
            functionCallingContext,
            responseFunctionName, 
            null, false, "",
            { 
                'Content-Type': 'application/json',
                'Authorization': Autoload.Utils.getAuthToken()
            }
        );
    }

    _getRentalUnitsByProperty(functionCallingContext, responseFunctionName, props) {
        props.id !== undefined &&
        Autoload.HttpRequestAxios.HttpRequest(
            Autoload.Setting.property_api_base_url+"rentalUnits?propertyId="+props.id+"&offset=0&fetch=100",
            "GET", 
            null, null, false, true, false,
            functionCallingContext,
            responseFunctionName, 
            null, false, "",
            { 
                'Content-Type': 'application/json',
                'Authorization': Autoload.Utils.getAuthToken()
            }
        );
    }

    _getTenantsByRentalUnitID(functionCallingContext, responseFunctionName, props) {
        Autoload.HttpRequestAxios.HttpRequest(
            Autoload.Setting.property_api_base_url+"tenants?rentalUnitId="+props.rentalUnit.id+"&offset=0&fetch=100",
            "GET", 
            null, null, false, true, false,
            functionCallingContext,
            responseFunctionName, 
            props, false, "",
            { 
                'Content-Type': 'application/json',
                'Authorization': Autoload.Utils.getAuthToken()
            }
        );
    }

    _getRentsByTenantId(functionCallingContext, responseFunctionName, props){
        Autoload.HttpRequestAxios.HttpRequest(
            Autoload.Setting.property_api_base_url + 'rents?offset=0&fetch=100&tenantId=' + props.tenant.id,
            'GET',
            null, null, false, true, false,
            functionCallingContext,
            responseFunctionName,
            props,
            false, "",
            {
              'Content-Type': 'application/json',
              Authorization: Autoload.Utils.getAuthToken()
            }
        );
    }

    _getBilingPeriodsByProperty(functionCallingContext, responseFunctionName, props, url_part ="BillingPeriods?propertyId=" ) {
        Autoload.HttpRequestAxios.HttpRequest(
            Autoload.Setting.property_api_base_url+url_part+props.id+"&offset=0&fetch=100",
            "GET", 
            null, null, false, true, false,
            functionCallingContext,
            responseFunctionName, 
            null, false, "",
            { 
                'Content-Type': 'application/json',
                'Authorization': Autoload.Utils.getAuthToken()
            }
        );
    }

    _getUtilityBillingsByProperty(functionCallingContext, responseFunctionName, props, url_part ="utilityBillings?propertyId=" ) {
        Autoload.HttpRequestAxios.HttpRequest(
            Autoload.Setting.ub_api_base_url+url_part+props.id,
            "GET", 
            null, null, false, true, false,
            functionCallingContext,
            responseFunctionName, 
            null, false, "",
            { 
                'Content-Type': 'application/json',
                'Authorization': Autoload.Utils.getAuthToken()
            }
        );
    }

    _getPropertiesByAccountId(functionCallingContext, responseFunctionName, props) {
        Autoload.HttpRequestAxios.HttpRequest(
            Autoload.Setting.property_api_base_url + 'properties?accountId=' + props.accountId + '&offset=0&fetch=100',
            "GET", 
            null, null, false, true, false,
            functionCallingContext,
            responseFunctionName, 
            null, false, "",
            { 
                'Content-Type': 'application/json',
                'Authorization': Autoload.Utils.getAuthToken()
            }
        );
    }
}


export default new GlobalServices();