import React, { Component } from 'react';
import { ColorButton, AddCostTypeModal, ZuweisungModal, MultiselectDropdown, SelectDropdown, ReceiptDetailModal } from '../../../../index';
import { Multiselect } from 'multiselect-react-dropdown';
import { connect } from 'react-redux';
import { Form, OverlayTrigger, Tooltip, Popover } from 'react-bootstrap';
import { deleteCostPosition, editCostPosition } from '../../../../../redux/action/UtilityBilling/UtilityBillingActions';
import RedTick from '../../../../../assets/images/module/general/RedTick.svg';
import YellowTick from '../../../../../assets/images/module/general/YellowTick.svg';
import GreenTick from '../../../../../assets/images/module/general/GreenTick.svg';
import GrayTick from '../../../../../assets/images/module/general/GrayTick.svg';
import EditForm from '../../../../../assets/images/module/general/EditForm.svg';
import Autoload from '../../../../Autoload';
import moment from 'moment';
import APIClient from '../../../../config/APIClient';
import { config } from '../../../../config/Setting';
import {
  TableRow,
  TableCell,
} from '@material-ui/core';
import { formatNumber } from '../../../../../helpers/stringsManipulations';
import { NumericFormat } from 'react-number-format';
import FixedAllocationKeyModal from '../CustomModal/FixedAllocationKeyModal';
import AddAllocationKeyModal from '../CustomModal/AddAllocationKeyModal';
import GeneralDataHandler from '../../../../helpers/DataHandler/General';
import GlobalServices from '../../../../helpers/services/GlobalServices';
import RecordOptions from '../../General/RecordOptions/index.js';
import EditVatAndLabourAmount from '../../../Module/General/CustomModal/EditVatAndLabourAmount';
import { Group } from '@mantine/core';
import CostPositionValuesModal from '../CustomModal/CostPositionValuesModal';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
const { checkProperty, emptyFieldValidation, invalidAmount, redirection, invalidSize, loader } = Autoload.MainHelper;
const tableCellStyle = {
  fontFamily: 'Inter',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '20px',
  letterSpacing: '0',
  color: '#0e0e1d',
  paddingTop: '8px !important',
  paddingBottom: '8px !important'
}
class HeatingCostAllocationRow extends Component {
  constructor(props) {
    super(props);
    this.multiselectRef = React.createRef();
    this.state = this.initialState;
  }

  costPositionId ='';
  initialState = {
    dataValues: {
      costType: [],
      amount: '',
      allocationKey: '',
      meterData: '',
      receipt: [],
      selectedReceipt: [],
      vatAmount: null,
      laborCost: null
    },
    amountTouch: false,
    amountErr: false,
    amountMsg: '',
    showModal: false,
    currentCp: '',
    receipts: [],
    fixedAllocationKeyModal: false,
    selectedAllocationKeyType: '',
    selectedAllocationId: '',
    selectedAllocationUnits: '',
    selectedAllocationKeyName: '',
    addAllocationKeyModal: false,
    newAllocationKey: false,
    openEditVatAndLabourAmount: false,
    openCostPositionValuesModal: false
  };
  updateState = (data) => {
    console.log('updateState: ', data);
    this.costPositionId = data.id;
    this.setState({
      dataValues: {
        ...this.state.dataValues,
        costTypeId: checkProperty(data, 'costTypeId'),
        costTypeName: checkProperty(data, 'costTypeName'),
        amount: checkProperty(data, 'amount')
          ? `${formatNumber(checkProperty(data, 'amount') ? checkProperty(data, 'amount') : 0, true)}` //`${Autoload.MainHelper.ConverterCurrencyDe_DisplayOnly(checkProperty(data, 'amount') + '', false)} €`
          : '',
        allocationKeyId: checkProperty(data, 'allocationKeyId'),
        allocationKeyName: checkProperty(data, 'allocationKeyName'),
        meterData: checkProperty(data, 'meterData'),
        selectedReceipt: checkProperty(data, 'selectedReceipt', []),
        allocationKey: checkProperty(data, 'allocationKey'),
        vatAmount: data.vatAmount,
        laborCost: data.laborCost
      },
    });
  };
  componentDidMount() {
    const { data, billingPeriodId } = this.props;
    this.costTypeId = data.costTypeId;
    this.billingPeriodId = billingPeriodId;
    if (data && !data.allocationKeyName) {
      this.props.abc(data.id);
    }
    this.updateState(data);
    //this.getAllByBillingPeriodCostType(this.billingPeriodId, this.costTypeId);
    //this.getSelectedReceipts();
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps && this.props.editReceiptOpen === false) {
      //this.getAllByBillingPeriodCostType(this.billingPeriodId, this.costTypeId);
    }
    if(this.props.data !== prevProps.data){
      this.updateState(this.props.data);
    }
  }

  getAllByBillingPeriodCostType = async (billingPeriodId, costTypeId) => {
    await APIClient({
      method: 'GET',
      url: config.receipt + "getAllBy?billingPeriodId=" + billingPeriodId + "&costTypeId=" + costTypeId,
      headers: {
        "Content-Type": "application/octet-stream",
        "Authorization": Autoload.Utils.getAuthToken(),
      }
    })
      .then((response) => {
        response.data.result.map((receipt)=> {
          let rec = receipt;
          rec.receiptDate = moment(receipt.receiptDate).format('DD.MM.YYYY');
          return rec;
        })
        this.setState({
          receipts: response.data.result
        })
      })
      .catch((error) => {
        console.log("getAllByBillingPeriodCostType: ", error);
      });
  }

  //update data to everytime redux changes

  updateStore = () => {
    const {
      costType,
      costTypeId,
      costTypeName,
      amount,
      allocationKey,
      allocationKeyId,
      allocationKeyName,
      meterData,
      receipt,
      selectedReceipt,
      vatAmount,
      laborCost
    } = this.state.dataValues;
    // console.log('update store', this.state.dataValues);
    // let newAmount= amount? Autoload.MainHelper.ConverterLocaleNumber(amount, "de") : ''
    let data = {
      id: this.props.data.id,
      costType: costType,
      costTypeId: costTypeId,
      costTypeName: costTypeName,
      amount: Autoload.MainHelper.ConverterLocaleNumber(amount, 'de'),
      allocationKey: allocationKey,
      allocationKeyId: allocationKeyId,
      allocationKeyName: allocationKeyName,
      meterData,
      receipt,
      selectedReceipt,
      vatAmount,
      laborCost
    };
    //save data to redux
    // console.log('updateStore in redux', data)
    this.props.editCostPosition(data);
  };
  updateRow = () => {
    loader(true);
    const { costTypeId, costTypeName, amount, allocationKeyId, allocationKeyName, meterData, costType, vatAmount, laborCost } =
      this.state.dataValues;
      console.log('updateRow: ', vatAmount, laborCost);
    // if (this.props.data.id === 0) {
    //   //post request

    //   let payload = {
    //     costPositionId: null,
    //     utilityBillingId: checkProperty(this.props.data, 'utilityBillingId'),
    //     amount: Autoload.MainHelper.ConverterLocaleNumber(amount, 'de')
    //       ? Autoload.MainHelper.ConverterLocaleNumber(amount, 'de')
    //       : 0,
    //     costTypeId: costTypeId,
    //     costTypeName: costTypeName,
    //     allocationKeyId: allocationKeyId,
    //     allocationKeyName: allocationKeyName,
    //     consumptionDataAvailable: meterData,
    //     costTypeIdentifier: 'ROOM_HEATING_BASIS',
    //     allcationKeyUnits: 'mWh',
    //     vatAmount: vatAmount,
    //     laborCost: laborCost
    //   };
    //   console.log('call post api', payload);
    //   Autoload.HttpRequestAxios.HttpRequest(
    //     Autoload.Setting.ub_api_base_url + 'costPositions',
    //     'POST',
    //     payload,
    //     null,
    //     false,
    //     true,
    //     false,
    //     this,
    //     '_handleSaving',
    //     null,
    //     false,
    //     '',
    //     {}
    //   );
    // } else {
    //  console.log(this.props);
      //put request
      let payload = {
        id: checkProperty(this.props.data, 'id'),
        utilityBillingId: checkProperty(this.props.data, 'utilityBillingId'),
        amount: Autoload.MainHelper.ConverterLocaleNumber(amount, 'de')
          ? Autoload.MainHelper.ConverterLocaleNumber(amount, 'de')
          : 0,
        costTypeId: costTypeId,
        costTypeName: costTypeName,
        allocationKeyId: allocationKeyId,
        allocationKeyName: allocationKeyName,
        consumptionDataAvailable: meterData,
        costTypeIdentifier: this.props.data.costTypeIdentifier,
        allcationKeyUnits: 'mWh',
        orderNo: this.props.data.orderNo,
        vatAmount: vatAmount,
        laborCost: laborCost
      };
      console.log('call put api', payload);
      Autoload.HttpRequestAxios.HttpRequest(
        Autoload.Setting.ub_api_base_url + 'costPositions/' + checkProperty(this.props.data, 'id'),
        'PUT',
        payload,
        null,
        false,
        true,
        false,
        this,
        '_handleSaving',
        null,
        false,
        '',
        {
          Authorization: Autoload.Utils.getAuthToken(),
        }
      );
    // }
  };
  _handleSaving = (response) => {
    if (response.status === false && response.message !== 'Not Found') {
      // Autoload.Redirection.redirection_error();
      Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang.newKeys.text_error_saving_cost_position);
    }
    this.props.updateCp();
    this.props.updateConsumptionsByCostPositionId(response.result.id);
    //window.open(window.location.href,'_self')
    //this.props.callUpdate();
    loader(false);

  };

  getSelectedReceipts = () => {
    //get reciepts with costPositionId
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.ub_api_base_url + 'receiptAssignments?costPositionId='+ this.costPositionId,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      '_handleGetSelectedReceipt',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  _handleGetSelectedReceipt = (response) => {
    if (response.status === false && response.message !== 'Not Found') {
      Autoload.Redirection.redirection_error();
      // Autoload.DataHandlerGeneral.GeneralErrorMessage(true, 'Error Saving receipt assignment :  ' + response.message);
    }
    let dataValues = this.state.dataValues;
    dataValues.selectedReceipt = response.result;
    this.setState({
      dataValues: dataValues 
    })
  }

  onCreateReceiptSelect=(id)=>{
    setTimeout(() => this.onReceiptSelect('', this.state.receipts.find((receipt)=> receipt.id === id)), 1000);
  }

  onReceiptSelect = (selectedList, selectedItem) => {
    if(selectedItem !== undefined){
      loader(true);
      let addedReceipt = {
        receiptId: selectedItem.id,
        receiptName: selectedItem.name,
        proRataDays: 123,
        costPositionId: this.props.data.id,
        id: null
      };
      Autoload.HttpRequestAxios.HttpRequest(
        Autoload.Setting.ub_api_base_url + 'receiptAssignments',
        'POST',
        addedReceipt,
        null,
        false,
        true,
        false,
        this,
        '_handlePostReceipt',
        null,
        false,
        '',
        {
          Authorization: Autoload.Utils.getAuthToken(),
        }
      );
    }
    // this.setState({dataValues:{...this.state.dataValues, selectedReceipt: selectedList }},()=>this.updateStore())
  };

  _handlePostReceipt = (response) => {
    if (response.status === false && response.message !== 'Not Found') {
      Autoload.Redirection.redirection_error();
      // Autoload.DataHandlerGeneral.GeneralErrorMessage(true, 'Error Saving receipt assignment :  ' + response.message);
    }
    //this.getSelectedReceipts();
    loader(false);
  };

  onReceiptRemove = (selectedList, removedItem) => {
    loader(true);
    let receipt = this.state.dataValues.selectedReceipt.find((record) => record.receiptId === removedItem.id);
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.ub_api_base_url + 'receiptAssignments/' + receipt.id,
      'DELETE',
      null,
      null,
      false,
      true,
      false,
      this,
      '_handleDeleteReceipt',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };
  _handleDeleteReceipt(response) {
    if (response.status == false) {
      Autoload.Redirection.redirection_error();
      Autoload.DataHandlerGeneral.GeneralErrorMessage(
        true,
        lang.newKeys.text_error_Error_in_removing_receipt_assignment
      );
    }
    //this.getSelectedReceipts();
    loader(false);
  }

  renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      There is no meter data available. <br></br>
      <a href="/meter">Add meter data</a> to proceed.
    </Tooltip>
  );

  renderStatusTooltip = (message) => (
    <Popover id="button-tooltip" style={{background: '#E3E3FC', border: 'none', boxShadow: '0 1px 2px 0 rgba(14, 14, 29, .1)', }}>
      <Popover.Content>
        <Group position='center' style={{width: '100%', fontFamily: 'Inter, sans-serif', fontSize: '14px'}}>
          {message}
        </Group>
      </Popover.Content>
    </Popover>
  );

  statusView = (meterData, showStatus=false) => {
    // console.log(meterData);
    //  text_not_required:"nicht benötigt",
    // text_available:"verfügbar",
    // text_not_available:"nicht verfügbar"
    let icon;
    let status = meterData;
    let color;
    switch (meterData) {
      case '3 - complete':
        icon = GreenTick;
        status = 'verfügbar';
        color='#059266';
        // status = lang.newKeys.text_green_avialable;
        break;
      case '1 - none available':
        icon = RedTick;
        status = 'nicht verfügbar';
        color='#E8193C'
        // status = lang.newKeys.text_not_available;
        break;
      case '2 - some available':
        icon = YellowTick;
        status = 'teilweise verfügbar';
        color='#D97706';
        break;
      case '0 - not required':
        icon = GrayTick;
        status = 'nicht benötigt';
        color='#727293'
        // status = lang.newKeys.text_not_required;
        break;
      default:
        status = '-';
        break;
    }
    if (status == 'Not available') {
      return (
        <OverlayTrigger
          trigger="click"
          placement="top"
          // delay={{ show: 450, hide:700}}
          // overlay={<Popover id="button-Popover ">
          // Time frame which the bill covers.
          // </Popover >}
          overlay={this.renderTooltip()}
        >
          {/* <div style={{ cursor: 'pointer' }} className={status.replace(/\s/g, '')}>
            {icon && <img src={icon} alt="status" />}
            <span style={{ marginLeft: '5px', width: '200px' }}>{status}</span>
          </div> */}
          <div style={{ cursor: 'pointer' }} className={status.replace(/\s/g, '')}>
            {icon && 
            <OverlayTrigger
            trigger={'hover'}
            placement="top"
            overlay={this.renderStatusTooltip(status)}
            >
              <img src={icon} alt="status" />
            </OverlayTrigger>}
            {showStatus && <span style={{ marginLeft: '5px', width: '200px !important' }}>{status}</span>}
          </div>
        </OverlayTrigger>
      );
    } else {
      return (
        // <div className={status.replace(/\s/g, '')} style={{display: 'flex'}}>
        //   {icon && <img src={icon} alt="status" />}
        //   <span style={{ marginLeft: '5px', color: status === 'verfügbar' && '#059266', width: '200px !important', display: 'block', whiteSpace: 'nowrap' }}>{status}</span>
        // </div>
        <div className={status.replace(/\s/g, '')} style={{display: 'flex'}}>
          {icon && 
          <OverlayTrigger
          trigger={'hover'}
          placement="top"
          overlay={this.renderStatusTooltip(status)}
          >
            <img src={icon} alt="status" />
          </OverlayTrigger>}
          {showStatus && <span style={{ color: color, marginLeft: '5px', width: '200px !important', display: 'block', whiteSpace: this.props.isTablet ? 'normal' : 'nowrap' }}>{status}</span>}
        </div>
      );
    }
  };

  validateAmount = () => {
    let invalidErr, emptyErr;
    emptyErr = emptyFieldValidation(this.state.dataValues.amount.replace('€', ''));
    invalidErr = invalidSize(this.state.dataValues.amount.replace('€', ''));
    if (emptyErr) {
      this.setState({ amountMsg: 'Betrag eingeben', amountErr: true });
      // console.log('dont save data')
    } else if (invalidErr) {
      this.setState({ amountMsg: lang.receipt.text_invalid_amount, amountErr: true });
      // console.log('dont save data')
    } else {
      this.setState({ amountMsg: '', amountErr: false });
      // console.log('save data')
      // this.updateStore()
    }
  };
  // onAmountChange=(field, value)=>{
  // this.setState({ dataValues: {...this.state.dataValues, [field]: value }},()=>this.validateAmount())
  // }
  // onAmountBlur=(field, value)=>{
  // this.setState({ dataValues: {...this.state.dataValues, [field]: value }},()=>{
  // if(!this.state.amountErr){
  // this.updateStore()
  // }
  // })
  // }
  onAllocationSelect = (selectedList, selectedItem) => {
    console.log('allloc change', this.state.dataValues);
    // if(this.props.propertySelected.propertyType === 'CONDOMINIUM' && selectedItem?.allocationKeyName === 'Personenanzahl' && !this.props.propertySelected.totalOccupantsCount){
    //   console.log('onAllocationSelect: if');
    //   console.log('_handleHeatingCostPositionResponse: ', selectedItem.allocationKeyName, this.props.propertySelected);
    //   this.props.opentotalOccupantsCountNullAlert();
    //   this.updateRow();
    // }else{
      this.setState(
        {
          dataValues: {
            ...this.state.dataValues,
            allocationKeyId: selectedItem.id,
            allocationKeyName: selectedItem.allocationKeyName,
            allocationKey: selectedList,
          },
        },
        () => {
          console.log('allloc change setState', this.state.dataValues);
          this.updateStore();
          this.updateRow();
          this.props.onDropdownOpen(false);
        }
      );
    //}
  };

  toggleViewDetailsModal = (recordId) => {
    this.props.toggleViewDetailsModal(recordId)
  }

  toggleAddReceiptModal = (cp) => {
    if (cp !== undefined) {
      this.setState({currentCp: cp});
    }
    this.setState({ addReceiptOpen: !this.state.addReceiptOpen });
    this.getAllByBillingPeriodCostType(this.billingPeriodId, this.costTypeId);
    this.props.callApis();
  };
  
  bind_list_receipt_data = () => {
    let propertyid = this.props.propertySelected && this.props.propertySelected.id;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.rm_api_base_url + 'property/' + propertyid,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handle_get_list_data',
      null,
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };

  handle_get_list_data(response) {
    // if (this.state.currentCp) {
    //   this.onReceiptSelect({ id: response.result[0]?.id, name: response.result[0]?.name }, this.state.currentCp);
    // }
  }

  openEditFixedAllocationKeyModal = (allocationKey) => {
    this.setState({ fixedAllocationKeyModal: true, selectedAllocationId: allocationKey.id, selectedAllocationKeyType: allocationKey.allocationKeyValueType, selectedAllocationUnits: allocationKey.units, selectedAllocationKeyName: allocationKey.allocationKeyName });
  };

  _handle_allocation_keys_response(response) {
    if (response.status === false && response.message !== 'Not Found') {
      Autoload.DataHandlerGeneral.GeneralErrorMessage(true, 'Error :  ' + response.message);
    }
    const keys = GeneralDataHandler.getAllocationKeys(response.result);

    this.onAllocationSelect([keys.find((allocationKey)=> allocationKey.id === this.state.selectedAllocationId)], keys.find((allocationKey)=> allocationKey.id === this.state.selectedAllocationId));
  }

  getTenantsConsumption = (allocationKeyId, costPositionId) => {
    //get reciepts with costPositionId
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.meter_api_base_url + 'tenantsConsumptions?billingPeriodId='+ this.billingPeriodId +'&allocationKeyId='+ allocationKeyId,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      '_handleGetTenantsConsumption',
      {costPositionId},
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  _handleGetTenantsConsumption(response){
    if(response.status === true && response.statuscode === 200){
      this.props.updateMeterData(response.result.availability, response.other.costPositionId);
    }
  }

  updateConsumptionsByCostPositionId = (costPositionIds, getTenantsConsumption=false) => {
    this.setState({skeletonLoading: true});
    //loader(true);
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.ub_api_base_url + 'updateConsumptions/' + this.props.currentDataId+'?costPositionIds='+costPositionIds,
      'PUT',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleUpdateConsumptionsByCostPositionIdResponse',
      {getTenantsConsumption},
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };

  handleUpdateConsumptionsByCostPositionIdResponse = (response) => {
    !response.status && console.log('Update Consumptions Failed');
    this.setState({skeletonLoading: false});
    if(response.other.getTenantsConsumption){
      this.getTenantsConsumption(this.state.selectedAllocationId, this.props.data.id, false);
    }
  };

  render() {
    // console.log('render of child', this.props.receiptsArr, this.state.dataValues.selectedReceipt);
    const {utilityData, isMobile, isTablet, billingPeriodId, data, propertySelected} = this.props
    const { id, utilityBillingId } = this.props.data;
    const { addCostTypeModal, fixedAllocationKeyModal, selectedAllocationId, selectedAllocationKeyType, selectedAllocationUnits, selectedAllocationKeyName, addAllocationKeyModal, newAllocationKey, openEditVatAndLabourAmount, openCostPositionValuesModal } = this.state;
    const { costTypeName, amount, allocationKeyName, receipt, selectedReceipt, zuweisung, meterData, vatAmount, laborCost } =
      this.state.dataValues;

    console.log('cp: ', data);
    
    return (
      <>
        <TableRow //className="table-data utility-cost-row"
        className='utility-table-row'
        style={{
          background: 'white',
        }}
        >
          <TableCell //className="heating-ub-cost-type"
            style={{borderBottom: 'none', //maxWidth: '190px'
          }}
            width={'22%'}
          >
            <div className="heating-cost-type"
            style={{ ...tableCellStyle, ...{
              outline: 'none',
              background: '#f7f7fa',
              border: '1px solid #d5dce6',
              borderRadius: '4px',
              padding: '6px 12.8px',
              height: '36px',
              
              paddingLeft: '5px',
              paddingRight: '5px',
              display: 'flex',
              alignItems: 'center'
            }}}
            ><span className="heating-cost-type" style={{whiteSpace: 'nowrap',
            overflow: 'scroll',
            textOverflow: 'ellipsis',}}>{costTypeName}</span></div>
          </TableCell>
          <TableCell //className="heating-ub-amount d-none d-md-table-cell"
            style={{
              borderBottom: 'none',
              width: '220px',
              //maxWidth: '95px'
            }}
          >
            <Group noWrap spacing={10} style={{width: data.costTypeIdentifier === 'HEATING_TOTAL' ? '100%' : '111px'}}>
              <div //className="form-control-utility"
              style={{ ...tableCellStyle, ...{
                outline: 'none',
                background: '#f7f7fa',
                border: '1px solid #d5dce6',
                borderRadius: '4px',
                padding: '6px 12.8px',
                height: '36px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                textAlign: 'right',
                color: amount ? '#0e0e1d' : '#b1b1b1',
                paddingLeft: '5px',
                paddingRight: '5px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                minWidth: '111px'
              }}}
              >{amount ? 
              <NumericFormat 
              className='numeric-format-text-view'
              style={{width: data.costTypeIdentifier === 'HEATING_TOTAL' ? '99px' : '100%', textAlign: 'right'}}
              thousandSeparator="." 
              decimalSeparator="," 
              value={amount} 
              suffix={' €'} 
              placeholder='0,00 €'
              decimalScale={2}
              fixedDecimalScale={2}
              allowNegative={true}
              disabled={true}/> : '0,00 €'}</div>
              {data.costTypeIdentifier === 'HEATING_TOTAL' &&
              <>
                <div className={vatAmount ? 'sub-text-ub-costposition-amount' : 'sub-text-ub-costposition-amount-disabled'} onClick={()=> this.setState({openEditVatAndLabourAmount: true})}>
                  Mwst
                </div>
                <div className={laborCost ? 'sub-text-ub-costposition-amount' : 'sub-text-ub-costposition-amount-disabled'} onClick={()=> this.setState({openEditVatAndLabourAmount: true})}>
                  Lohn
                </div>
              </>}
            </Group>
              
            {/* <Form.Control
              disable
              type="text"
              isInvalid={this.state.amountErr}
              placeholder="0,00 €"
              value={amount}
              style={{textAlign: 'right'}}
            />
            <Form.Control.Feedback className="err" type="invalid">
              {' '}
              {this.state.amountMsg}{' '}
            </Form.Control.Feedback> */}
          </TableCell>
          <TableCell //className="heating-ub-allocation-key d-none d-md-table-cell" 
          style={{ ...tableCellStyle, ...{
            outline: 'none',
            background: '#fff',
            padding: '6px 12.8px',
            height: '36px',
            borderBottom: 'none',
            maxWidth: '190px'
            //width: isTablet && '100px'
          }}}
          //width={'22%'}
          >
            {console.log('this.props.allocationKeys: ', this.props.allocationKeys)}
            {console.log('this.props.data: ', this.props.data)}
            {allocationKeyName === '' ? null : (
              <Multiselect
                className="ub_multiselect_id"
                style={{
                  chips: {
                    borderRadius: '0px', 
                    color: '#111147', 
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    width: isTablet ? '100px' : 'auto',
                    paddingLeft: '5px',
                    marginTop: '4px',
                    marginRight: '10px',
                    overflowX: 'scroll',
                    fontSize: '14px',
                  },
                  searchBox: {
                    //overflowX: 'scroll',
                    //padding: '3px 5px 0',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    height: '36px',
                    border: '1px solid #d5dce6',
                    paddingRight: '10px',
                    paddingLeft: 0
                    // overflowY: 'hidden',
                  },
                  multiselectContainer: { 
                    margin: 0,
                    padding: 0,
                    
                    //marginRight: '15px', 
                    //maxWidth: isTablet ? '200px' : '260px', 
                    //marginLeft: '5px',
                },
                  inputField: { marginRight: '0px', width: '100%', paddingLeft: '5px', paddingRight: '5px' },
                  option: {
                    fontSize: '14px',
                    fontWeight: '400',
                    lineHeight: '20px',
                    color: '#0e0e1d',
                    padding: '10px'
                  }

                }}
                options={this.props.data.costTypeIdentifier === 'WARMWATER_BASIS' || this.props.data.costTypeIdentifier === 'ROOM_HEATING_BASIS' ?
                this.props.allocationKeys.filter(
                  (o) => (o.allocationKeyName === 'Wohnfläche' || o.allocationKeyName === 'Anzahl Wohnungen' || o.allocationKeyValueType === 'FIXED_TENANT' || o.allocationKeyValueType === 'FIXED_RENTAL_UNIT') && o.allocationKeyType !== 'NON_HEATING_INDIVIDUAL' //(old logic)
                ).sort((a,b)=>a.allocationKeyName.toLowerCase().localeCompare(b.allocationKeyName.toLowerCase())) 
                : 
                this.props.allocationKeys.filter(
                  (o) => o.allocationKeyType === this.props.data.allocationKeyType //(old logic)
                )} // Options to display in the
                selectedValues={this.props.allocationKeys.filter(
                  (o) => o.allocationKeyId === this.props.data.allocationKeyId
                )} // Preselected value to persist in dropdown
                displayValue={'allocationKeyId'}
                selectedValueDecorator={(id)=> {
                  let allocationKey = this.props.allocationKeys.find((o) => o.allocationKeyId === id);
                  return `${allocationKey.allocationKeyName} ${allocationKey.units && allocationKey.units !== '-' ? `(${allocationKey.units})` : ''}`
                }}
                optionValueDecorator={(id)=> {
                  let allocationKey = this.props.allocationKeys.find((o) => o.allocationKeyId === id);
                  return `${allocationKey.allocationKeyName} ${allocationKey.units && allocationKey.units !== '-' ? `(${allocationKey.units})` : ''}`
                }}
                singleSelect={true}
                onSelect={this.onAllocationSelect} // Function will trigger on select event
                placeholder="Wählen"
                closeIcon="cancel"
                avoidHighlightFirstOption={true}
                isMobileView={isMobile}
                disable={utilityData.isSecured || (this.props.allocationKeys.length > 0 ? false : true)}
                closeOnSelect={false}
                renderCustomButton={
                  (this.props.data.costTypeIdentifier === 'WARMWATER_BASIS' || this.props.data.costTypeIdentifier === 'ROOM_HEATING_BASIS') &&
                  <ColorButton
                    className="gray-btn utitlity-add-cost-button"
                    text={lang.property.allocationKeys_text_add_allocationkeys}
                    onClick={() => this.setState({addAllocationKeyModal: true})}
                  />
                }          
              />
            )}
          </TableCell>
          {console.log('this.state.dataValues: ', this.state.dataValues)}
          <TableCell //className="heating-ub-meter-data d-none d-md-table-cell"
          style={{borderBottom: 'none'}}
          >
            {!allocationKeyName ? '-' : 
            (this.state.dataValues && this.state.dataValues.allocationKey && this.state.dataValues.allocationKey.allocationKeyName !== 'Wohnfläche' && this.state.dataValues.allocationKey.allocationKeyName !== 'Anzahl Wohnungen' && this.state.dataValues.allocationKey.allocationKeyName !== 'Personenanzahl' && (this.state.dataValues.allocationKey.allocationKeyValueType === 'FIXED_TENANT' || this.state.dataValues.allocationKey.allocationKeyValueType === 'FIXED_RENTAL_UNIT')) ?  
              //   <ColorButton
              //   className="gray-btn"
              //   text={'Verteilerwerte'}
              //   onClick={() => this.openEditFixedAllocationKeyModal(this.state.dataValues?.allocationKey)}
              //   disabled={utilityData.isSecured}
              // />
              <div style={{cursor: 'pointer'}} //onClick={() => this.openEditFixedAllocationKeyModal(this.state.dataValues?.allocationKey)}
              >
                {/* <Group spacing={10}>
                  {this.statusView(meterData)}
                  <OverlayTrigger
                    trigger={'hover'}
                    placement="top"
                    overlay={this.renderStatusTooltip('Verteilerwerte anpassen')}
                  >
                    <img src={EditForm} style={{marginBottom: '2px'}}/>
                  </OverlayTrigger>
                </Group> */}
                <Group spacing={10} noWrap>
                  <div>
                    <ColorButton
                    className="gray-btn"
                    text={propertySelected && propertySelected.propertyType === 'REGULAR' ? 'Prüfen' : 'Zuweisen'}
                    onClick={() => this.setState({openCostPositionValuesModal: true})}
                    buttonStyle={{minWidth: '85px'}}
                    />
                  </div>
                  {this.statusView(meterData, true)}
                </Group>
              </div>
                :
                (this.state.dataValues && this.state.dataValues.allocationKey && this.state.dataValues.allocationKey.allocationKeyValueType === 'USAGE_BASED') ?  
                <div style={{cursor: 'pointer'}} //onClick={() => this.props.history.push(`/meter/reading/annual/${billingPeriodId}/c-r-0`)}
                >
                  {/* <Group spacing={10}>
                    {this.statusView(meterData)}
                    <OverlayTrigger
                      trigger={'hover'}
                      placement="top"
                      overlay={this.renderStatusTooltip('Verbrauchswerte anpassen')}
                    >
                      <img src={EditForm} style={{marginBottom: '2px'}}/>
                    </OverlayTrigger>
                  </Group> */}
                  <Group spacing={10} noWrap>
                    <div>
                      <ColorButton
                      className="gray-btn"
                      text={propertySelected && propertySelected.propertyType === 'REGULAR' ? 'Prüfen' : 'Zuweisen'}
                      onClick={() => this.setState({openCostPositionValuesModal: true})}
                      buttonStyle={{minWidth: '85px'}}
                      />
                    </div>
                    {this.statusView(meterData, true)}
                  </Group>
                </div>
                :
                <>
                  <Group spacing={10} noWrap>
                    <div>
                      <ColorButton
                      className="gray-btn"
                      text={propertySelected && propertySelected.propertyType === 'REGULAR' ? 'Prüfen' : 'Zuweisen'}
                      onClick={() => this.setState({openCostPositionValuesModal: true})}
                      buttonStyle={{minWidth: '85px'}}
                      />
                    </div>
                    {this.statusView(meterData, true)}
                  </Group>
                </>}
          </TableCell>
          <TableCell className='receipt-german-three-dots' style={{textAlign: 'right'}}>
            {data.costTypeIdentifier === 'HEATING_TOTAL' &&
            <RecordOptions
            showEditButton={true}
            editButtonText={'Mwst. & Lohnkosten'}
            showDeleteButton={false}
            toggleEditModal={() => this.setState({openEditVatAndLabourAmount: true})}
            menuStyle={{width: '190px'}}
            style={{marginRight: '0px'}}
            />}
          </TableCell>
          {/* <TableCell //className="heating-ub-receipt d-none d-md-table-cell multiselect-page3"
          style={{
            borderBottom: 'none',
            maxWidth: isTablet ? '200px' : '280px'
          }}
          >
            {
              allocationKeyName ? null : (
                <MultiselectDropdown
                  className="cost-allocation-receipts"
                  tableView={true}
                  viewDetails={true}
                  toggleViewDetailsModal={this.toggleViewDetailsModal}
                  options={this.state.receipts} // Options to display in the dropdown
                  selectedValues={this.state.receipts.filter((receipt) => selectedReceipt.find((selected) => (selected.costPositionId === this.costPositionId && selected.receiptId === receipt.id)))} // Preselected value to persist in dropdown
                  onSelect={this.onReceiptSelect} // Function will trigger on select event
                  onRemove={this.onReceiptRemove} // Function will trigger on remove event
                  displayValue="name"
                  displayValue2="receiptDate"
                  placeholder="Wählen"
                  multiSelect
                  disable={utilityData.isSecured}
                  endListContent={
                    <ColorButton
                      className="gray-btn utitlity-add-cost-button"
                      text={lang.receipt.text_add_receipt}
                      onClick={() => this.toggleAddReceiptModal(this.props.data)}
                    />
                  }
                />
              )
            }
            {this.state.addReceiptOpen && (
              <ReceiptDetailModal
                record={null}
                parent={this}
                onCreateReceiptSelect={(id)=> this.onCreateReceiptSelect(id)}
                className="add-edit-receipt-modal"
                edit={false}
                open={this.state.addReceiptOpen}
                toggle={(id) => this.toggleAddReceiptModal(id)}
                utilityData={this.props.utilityData}
                timeFrame={this.props.timeFrame}
                billingRelevant
                selectedCostType={[{id: this.props.cpId.costTypeId}]}
                cp={this.props.data}
              />
            )}
          </TableCell> */}
          {openEditVatAndLabourAmount &&
          <EditVatAndLabourAmount
          vatAmount={vatAmount}
          laborCost={laborCost}
          isSecured={utilityData.isSecured}
          open={openEditVatAndLabourAmount}
          toggle={()=> this.setState({openEditVatAndLabourAmount: false})}
          saveVatLaborValues={(vatAmount, laborCost)=> this.setState({dataValues: {...this.state.dataValues, vatAmount, laborCost}}, ()=> {
            this.updateStore();
            this.updateRow();
          })}
          />}
          {fixedAllocationKeyModal &&
          <FixedAllocationKeyModal
          open={fixedAllocationKeyModal}
          className={'individual-allocation-key-modal'}
          type={selectedAllocationKeyType}
          allocationKeyId={selectedAllocationId}
          toggle={(onUpdate=false)=> {
            onUpdate && newAllocationKey && GlobalServices._getAllocationKeysByProperty(this, '_handle_allocation_keys_response', this.props.propertySelected);
            onUpdate && this.updateConsumptionsByCostPositionId(id, true)
            setTimeout(() => {
              this.setState({fixedAllocationKeyModal: false, selectedAllocationKeyType: '', selectedAllocationUnits: '', selectedAllocationKeyName: '', newAllocationKey: false});
            }, onUpdate && newAllocationKey ? 5000 : 0);
          }}
          unit={selectedAllocationUnits}
          history={this.props.history}
          name={selectedAllocationKeyName}
          />}
          {addAllocationKeyModal && (
          <AddAllocationKeyModal
            record={null}
            parent={this}
            className="add_allocation_key_modal"
            edit={false}
            open={addAllocationKeyModal}
            allocationData={this.props.allocationKeys}
            toggle={()=> this.setState({addAllocationKeyModal: false})}
            callBackFunction={() => this.props.fetchAllocationKeysByProperty()}
            selectedType={(type, id, units, name)=> (type === 'FIXED_RENTAL_UNIT' || type === 'FIXED_TENANT') && this.setState({selectedAllocationKeyType: type, fixedAllocationKeyModal: true, selectedAllocationId: id, selectedAllocationUnits: units, selectedAllocationKeyName: name, newAllocationKey: true})}
          />
          )}
          {console.log('this.state.dataValues?.allocationKey[0]?.allocationKeyType: ', this.state.dataValues?.allocationKey?.allocationKeyValueType)}
          {openCostPositionValuesModal && 
          <CostPositionValuesModal
          open={openCostPositionValuesModal}
          cosumptionType={this.state.dataValues?.allocationKey?.allocationKeyValueType}
          consumptionStatus={this.statusView(this.props.data.meterData, true)}
          allocationKey={this.props.allocationKeys.filter((o) => o.id === this.props.data.allocationKeyId)[0]}
          allocationKeyId={this.props.data.allocationKeyId}
          costType={costTypeName}
          costPositionId={this.props.data.id}
          utilityBillingId={this.props.data.utilityBillingId}
          toggle={()=> this.setState({openCostPositionValuesModal: false})}
          openFixedAllocationKeyModal={()=> this.openEditFixedAllocationKeyModal(this.state.dataValues?.allocationKey)}
          redirectToConsumptionEditValues={()=> this.props.history.push(`/meter/reading/annual/${billingPeriodId}/c-r-0`)}
          saveAmount={(amount)=> this.onAmountBlur('amount', formatNumber(amount, true))}
          redirectToTenantScreen={()=> this.props.history.push(`/user`)}
          redirectToPropertyScreen={()=> this.props.history.push(`/property`)}
          />}
        </TableRow>  
      </>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    // costTypes:state.costTypeReducer,
    // allocationData:state.allocationKeyReducer,
    propertySelected: state.generalReducer.property,
    costTypesArr: ownProps.costTypesArr ? ownProps.costTypesArr : [],
    allocationsArr: ownProps.allocationsArr ? ownProps.allocationsArr : [],
  };
};
const actions = {
  deleteCostPosition,
  editCostPosition,
};
export default connect(mapStateToProps, actions)(HeatingCostAllocationRow);
