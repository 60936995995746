import Setting from './config/Setting';
import MainHelper from './helpers/MainHelper';
import HttpRequestAxios from './helpers/RequestAxios';
import Redirection from './helpers/Redirection';
import DataHandlerGeneral from './helpers/DataHandler/General';
import * as Utils from './helpers/Utils';

const Autoload = {
    Setting: Setting,
    MainHelper: MainHelper,
    HttpRequestAxios,
    Redirection,
    Utils,
    DataHandlerGeneral
  };
  
export default Autoload;

