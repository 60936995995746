import React from 'react';
import { Button } from 'reactstrap';


export default function TransparentButton({ text, leftImage, rightImage, className, onClick, textStyle }) {
  return (
    <Button color="link" className={`transparent-btn ${className}`} onClick={onClick} >
      <div className='button-img-wrapper'>
        {leftImage && <img className='btn-img left' src={leftImage.img} alt='left icon' style={{ ...leftImage.style }} />}
        <p className={`btn-text`} style={textStyle}>{text}</p>
        {rightImage && <img className='btn-img right' src={rightImage.img} alt='left icon' style={{ ...rightImage.style }} />}
      </div>
    </Button>
  );
}