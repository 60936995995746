import React, { Component } from 'react';
import { ColorButton, Divider, MeterReadingData2, RecordOptions, DeleteModal } from '../../../../neoverv';
import { Table } from 'reactstrap';
import { connect } from 'react-redux';
import { setCurrentBillingId } from '../../../../redux/action/UtilityBilling/UtilityBillingActions';
import DownloadIcon from '../../../../assets/images/module/general/DownloadIcon.svg';
import LockIcon from '../../../../assets/images/module/general/LockIconRed.svg';
import Autoload from '../../../../neoverv/Autoload';
// import { withRouter } from 'react-router-dom';
import moment from 'moment';
import uparrow from '../../../../assets/images/module/general/SelectArrowUp.svg';
import downArrow from '../../../../assets/images/module/general/SelectArrowDown.svg';
import { EmptyStateContainer } from '../../../../neoverv';
import tap from '../../../../assets/images/module/general/TapIconDark.svg';
import RedCross from '../../../../assets/images/module/general/RedCross.svg';
import PrintIcon from '../../../../assets/images/module/general/PrintIcon.svg';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import AlertModal from '../../../../neoverv/components/Module/General/CustomModal/AlertModal';
import UtilityBillingPrintingOptionsModal from '../../../../neoverv/components/Module/General/CustomModal/UtilityBillingPrintingOptionsModal';
import { showNotification } from '@mantine/notifications';


const { redirection, loader } = Autoload.MainHelper;
var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];

const { checkProperty } = Autoload.MainHelper;

class UtilityBiliingRow extends Component {
  state = {
    accordianArrow: false,
    accordiaCounter: 0,
    deleteUtilityModal: false,
    open: false,
    openUtilityBillingAlertMessage: false,
    selectedLockId: '',
    selectedLockStatus: false,
    freezeAlertHeeading: '',
    freezeAlertMessage: '',
    loadingButton: false,
    openPrintingOptionsModal: false,
    selectedTenantId: null,
    showUbLockTooltip: false
    // utilitydata: '',
    // propertyIdToDelete: '',
  };

  toggleDeleteUtilityBilling = (id) => {
    this.setState({ deleteid: id, deleteUtilityModal: !this.state.deleteUtilityModal });
  };

  lockUtilityBilling = (id, isSecured) => {
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.ub_api_base_url + 'utilityBillings/' + id +'/Secured/' + !isSecured,
      'PUT',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleLockUtilityBilling',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };

  handleLockUtilityBilling = (response) => {
    console.log('response lock billings', response);
    if (response.status == false) {
      Autoload.Redirection.redirection_error();
    }
    this.props.getUtillityBillings();
  };

  deleteUnit = () => {
    this.setState({loadingButton: true});
    let btnelement = document.getElementById('unit-delete-modal-footer');
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.ub_api_base_url + 'utilityBillings/' + this.state.deleteid,
      'DELETE',
      null,
      btnelement.children[1],
      false,
      true,
      false,
      this,
      'handleDeleteUtilityBilling',
      { cancelbtn: btnelement.children[0], id: this.state.deleteid },
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };
  handleDeleteUtilityBilling = (response) => {
    console.log('response delete billings', response);
    if (response.status == false) {
      Autoload.Redirection.redirection_error();
    }
    this.props.getUtillityBillings();
    this.toggleDeleteUtilityBilling();
    setTimeout(() => {
      this.setState({loadingButton: false});  
    }, 4000);
  };

  _createUtilityBillings() {
    this.setState({loadingButton: true});
    //this.props.loading();
    const { data, billingPeriods } = this.props;
    let btnElement = document.getElementById(checkProperty(data, 'billingPeriodId'));
    const payload = {
      //BillingPeriodId: checkProperty(data, 'billingPeriodId'),
      billingPeriod: billingPeriods.find((bp)=> bp.id === data.billingPeriodId),//bp object
      propertyId: checkProperty(data, 'propertyId'),
      // billingPeriod: {
      //   billingPeriodId: checkProperty(data, 'billingPeriodId'),
      //   propertyId: checkProperty(data, 'propertyId'),
      //   startDate: checkProperty(data, 'billingPeriodStart'),
      //   endDate: checkProperty(data, 'billingPeriodEnd'),
      // },
      // heatingEnergyAmountKWH: checkProperty(data, 'heatingEnergyAmountKWH'),
      // heatingEnergyAmount: checkProperty(data, 'heatingEnergyAmount'),
      // heatingEnergyType: checkProperty(data, 'heatingEnergyType', {
      //   id: 1,
      //   fuelTypeName: 'Erdgas H (m3)',
      //   conversionkWh: '10,00',
      // }),
      // energyAmountRoomHeatingKWH: checkProperty(data, 'energyAmountRoomHeatingKWH'),
      // energyAmountWarmWaterKWH: checkProperty(data, 'energyAmountWarmWaterKWH'),
      // totalHeatingCost: checkProperty(data, 'totalHeatingCost'),
      // totalRoomHeatingCost: checkProperty(data, 'totalRoomHeatingCost'),
      // totalWarmWaterHeatingCost: checkProperty(data, 'totalWarmWaterHeatingCost'),
      // heatingCostsAllocated: checkProperty(data, 'heatingCostsAllocated'),
      // 'non-heatingCostsAllocated': checkProperty(data, 'nonHeatingCostsAllocated'),
    };
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.ub_api_base_url + 'utilityBillings',
      'POST',
      payload,
      btnElement.children[0],
      false,
      true,
      false,
      this,
      '_createUtilityBillingsResponse',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }
  _createUtilityBillingsResponse(response) {
    this.setState({loadingButton: true});
    console.log('response create billinbgs', response);
    if (response.status === true && response.statuscode === 401) {
      localStorage.clear();
      window.location.reload();
    }

    if (response.status === true && response.statuscode === 200) {
      
      //loader(true);
      loader(false);
      window.Intercom("update", {
        api_base: "https://api-iam.intercom.io",
        app_id: "vvrs5bx3",
        'Utility Billing Created': true
      });
      window.Intercom('trackEvent', 'utility-billing-conducted');
      this.props.getUtillityBillings(true, response.result);
      //this.props.loading();
      // setTimeout(() => {
      //   this.navigateToBilling(false, response.result);
      // }, 6000); 
    } else {
      //Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang.utility.text_error_billing_period);
      showNotification({
        message: 'Die Betriebskostenabrechnung konnte nicht angelegt werden. Bitte probieren Sie es erneut oder wenden Sie sich an den hellohousing Service.',
        icon: <img src={RedCross} alt='red-cross'/>,
      });
      this.setState({loadingButton: false}, ()=> this.props.getUtillityBillings(true, null));
    }
    
    //window.open('/utility-billing', '_self');
  }

  // componentDidUpdate(prevProps, prevState) {

  // }
  navigateToBilling = (isEdit, billingId = '') => {
    //this.props.getUtillityBillings();
    //console.log(this.props.getUtillityBillings(), this.props.data.id, '==========================');
    // this.props.setCurrentBillingId(isEdit ? this.props.data.Id : billingId); // Id or UtilityBillingId
    // ;
    this.props.history.push(`/utility-billing/utility?currentBillingId=${isEdit ? this.props.data.id : billingId}&ub-step=${this.props.propertySelected.propertyType === 'REGULAR' ? 'heating-cost' : 'utility-cost'}`, {
      isEdit,
    });
    // this.props.history.push('/utility-billing/utility', { isEdit });
  };

  downloadPDF = (file) => {
    file
      ? window.open(file, '_blank')
      : Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang.utility.text_error_no_pdf);
  };

  renderTooltip = (props, data) => (
    <Tooltip id="button-tooltip" {...props} onMouseEnter={()=> this.setState({showUbLockTooltip: true})} onMouseLeave={()=> this.setState({showUbLockTooltip: false})}>
      <span>
        Betriebskostenabrechnung ist festgeschrieben und wird nicht automatisch aktualisiert. <span 
        onClick={() => 
          this.setState({
            selectedLockId: data.id, 
            selectedLockStatus: data.isSecured, 
            open: true, 
            freezeAlertHeeading: data.isSecured ? lang.utility.text_freeze_alert_heading : lang.utility.text_unfreeze_alert_heading, 
            freezeAlertMessage: data.isSecured ? lang.utility.text_freeze_alert_message : (<span>{lang.utility.text_unfreeze_alert_message}</span>)
          })}
        style={{cursor: 'pointer', fontWeight: 'bold', color: '#5555FF'}}>
            Festschreibung aufheben.
        </span>
      </span>
    </Tooltip>
  );

  render() {
    const {data, propertySelected, utilityBiliingRowIndex, index, setCurrentBillingId} = this.props;
    const {open, freezeAlertHeeading, freezeAlertMessage, openUtilityBillingAlertMessage, loadingButton, openPrintingOptionsModal, selectedTenantId, showUbLockTooltip} = this.state;
    if (Object.keys(data).length > 0) {
      const { startDate, endDate, type, tableData = [] } = data;
      console.log(data, 'utility billing rows screen 01');
      return (
        <div className="utility_headers_containers">
          <AlertModal
            heading={freezeAlertHeeading}
            headingClassName={'ub-freeze-alert-modal-heading'}
            open={open}
            cancel
            onCancel={()=> this.setState({open: false})}
            dataSecured={data.isSecured}
            text={
              <span style={{fontSize: '14px'}}>
                {freezeAlertMessage}
              </span>
            }         
            onClick={(e) => {
              e.preventDefault();
              this.setState({open: false});
              this.lockUtilityBilling(this.state.selectedLockId, this.state.selectedLockStatus);
            }}
            toggle={() => {
              this.setState({open: false});
            }}
        />

        <AlertModal 
          heading={'Betriebskostenabrechnung starten'}
          headingClassName={'ub-freeze-alert-modal-heading'}
          open={openUtilityBillingAlertMessage}
          cancel
          onCancel={()=> this.setState({openUtilityBillingAlertMessage: false})}
          text={
            <span style={{fontSize: '14px'}}>
              {`Sie erstellen die Betriebskostenabrechnung für die akuell laufende Betriebskostenperiode (${moment(startDate).format('DD.MM.YYYY')} - ${moment(endDate).format('DD.MM.YYYY')}). Diese ist noch nicht abgeschlossen. Sind Sie sicher, dass Sie fortfahren möchten?`}
            </span>
          }         
          onClick={(e) => {
            e.preventDefault();
            this.setState({openUtilityBillingAlertMessage: false});
            this._createUtilityBillings()
          }}
          toggle={() => {
            this.setState({openUtilityBillingAlertMessage: false});
          }}
          rightButtonText={'Fortfahren'}
        />
          <Accordion key={data.id}>
            <AccordionSummary
              aria-controls="panel2a-content"
              id="panel2a-header"
              onClick={() => {
                this.setState({
                  accordiaCounter: this.state.accordiaCounter + 1,
                  // accordianArrow: document.getElementById('panel2a-header').getAttribute('aria-expanded'),
                });
              }}
            >
              <Typography>
                <div className="utility_headers_box">
                  <div className="utility_headers_date">
                    {/* {console.log(this.state.accordianArrow,'this.props.expanded')} */}
                    <div style={{ marginRight: '12px' }}>
                      {' '}
                      <img src={this.state.accordiaCounter % 2 === 0 ? downArrow : uparrow} alt="" />
                    </div>
                    <div>
                      {' '}
                      {moment(startDate).format('DD.MM.YYYY')} - {moment(endDate).format('DD.MM.YYYY')}
                    </div>
                  </div>
                  {/* <div className="utility_headers_date">{data.recordStatus === 'last' ? lastDate : currentDate}</div> */}
                  {

                    data.recordStatus === 'last' || data.type === 'last' ?
                      <div className="utility_headers_previous_period">
                        {lang.utility.text_last_billing_period}
                      </div>
                      :
                      data.recordStatus === 'current' || data.type === 'current' ?
                        <div className="utility_headers_current_period">
                          {lang.receipt.text_current_billing_period}
                        </div>
                        : null


                  }
                  <div className="utility_headers_start_billing_button">
                    {/* {tableData.length > 0 ? ( */}
                    {data.id ? (
                      <>
                        <RecordOptions
                          className="delete-utility"
                          showEditButton={false}
                          toggleDeleteModal={() => this.toggleDeleteUtilityBilling(data.id)}
                          lockUtilityBilling={()=> this.setState({
                            selectedLockId: data.id, 
                            selectedLockStatus: data.isSecured, 
                            open: true, 
                            freezeAlertHeeading: data.isSecured ? lang.utility.text_freeze_alert_heading : lang.utility.text_unfreeze_alert_heading, 
                            freezeAlertMessage: data.isSecured ? lang.utility.text_freeze_alert_message : (<span>{lang.utility.text_unfreeze_alert_message}</span>)
                          })}
                          showLockButton={true}
                          isSecured={data.isSecured}
                          menuStyle={{width: data.isSecured ? '230px' : '160px'}}
                        />
                        <ColorButton
                          onClick={() => {
                            this.navigateToBilling(true);
                          }}
                          text={data.isSecured ? 'Anzeigen' : lang.utility.text_edit}
                          className={(data.recordStatus === 'last' || data.type === 'last' || utilityBiliingRowIndex === 1) ? 'purple-btn' : "gray-btn"}
                          showLoader={loadingButton}
                        />
                        {data.isSecured && 
                        <div style={{marginLeft: 10, marginTop: 5}} 
                        onClick={(e)=> {
                          e.stopPropagation();
                          // this.setState({
                          //   selectedLockId: data.id, 
                          //   selectedLockStatus: data.isSecured, 
                          //   open: true, 
                          //   freezeAlertHeeading: data.isSecured ? lang.utility.text_freeze_alert_heading : lang.utility.text_unfreeze_alert_heading, 
                          //   freezeAlertMessage: data.isSecured ? lang.utility.text_freeze_alert_message : (<span>{lang.utility.text_unfreeze_alert_message} <a href="https://wissen.hellohousing.de/was-bedeutet-eine-festschreibung-der-betriebskostenabrechnung" target="_blank">(mehr erfahren).</a></span>)
                          // })
                          }}>
                          <OverlayTrigger placement="bottom" delay={{ show: 450, hide: 850 }} show={showUbLockTooltip} overlay={(props)=>this.renderTooltip(props, data)}>
                            <img src={LockIcon} onMouseEnter={()=> this.setState({showUbLockTooltip: true})} onMouseLeave={()=> this.setState({showUbLockTooltip: false})} />
                          </OverlayTrigger>
                          
                        </div>}
                      </>
                    ) : (
                      // id="start-utility-btn"
                      <div id={data.billingPeriodId}>
                        <ColorButton
                          onClick={() => (data.recordStatus === 'current' || data.type === 'current') ? this.setState({openUtilityBillingAlertMessage: true}) : loadingButton === false && this._createUtilityBillings()}
                          text={lang.utility.text_start_utility_billing}
                          className={(data.recordStatus === 'last' || data.type === 'last' || utilityBiliingRowIndex === 1) ? 'purple-btn' : "gray-btn"}
                          showLoader={loadingButton}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography style={{ width: '100%' }}>
                {tableData.length > 0 ? (
                  // <div style={{ display: tableData.length > 0 ? 'block' : 'none' }}>
                  <div>
                    <Table responsive bordered={false}>
                      <thead>
                        <tr className="utility-billing-table">
                          <th className="utility-billing-table-th tenant">{lang.utility.text_tenant}</th>
                          <th className="utility-billing-table-th rental-unit">{lang.utility.text_rental_unit}</th>
                          <th className="utility-billing-table-th occupancy-period">
                            {lang.utility.text_occupancy_period}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableData.map((billingStatementRow, index) => (
                          <tr className="table-data" key={billingStatementRow.id + index}>
                            <td className="tenant">{billingStatementRow.tenant}</td>
                            <td className="rental-unit">{billingStatementRow.rentalUnit}</td>
                            <td className="occupancy-period">
                              {' '}
                              {`${billingStatementRow.occupencyPeriod} (${billingStatementRow.occupencyPeriodDays} ${lang.newKeys.text_days})`}
                            </td>
                            <td className="download">
                              <div className="download-btn-web">
                                {/* //download pdf  */}
                                <ColorButton
                                  buttonConfig={{ target: '_blank' }}
                                  onClick={()=> propertySelected.propertyType === 'REGULAR' ? 
                                    this.setState({openPrintingOptionsModal: true, selectedTenantId: billingStatementRow.tenantId}) 
                                    : 
                                    setTimeout(() => {
                                      window.open(`/billingStatement2/${billingStatementRow.id}?heatingFilter=${false}&meterReadings=${false}&compositionOfHeatingCosts=${false}&costSplitWarmWaterAndHeating=${false}&splitBaseConsumptionCosts=${false}&vatSwitch=${false}&laborCostSwitch=${false}`, '_blank');  
                                    }, 2000)
                                  }
                                  text={lang.utility.text_print_pdf}
                                  className={'gray-btn'}
                                  rightImage={{ img: PrintIcon, style: { width: '16px', height: '16px' } }}
                                />
                                {console.log('openPrintingOptionsModal: ', data)}
                                {openPrintingOptionsModal && selectedTenantId && selectedTenantId === billingStatementRow.tenantId &&
                                <UtilityBillingPrintingOptionsModal
                                ubData={data}
                                propertyId={propertySelected.id}
                                billingPeriodId={data.billingPeriodId}
                                tenantId={billingStatementRow.tenantId}
                                billingStatementRow={billingStatementRow}
                                open={openPrintingOptionsModal}
                                toggle={()=> this.setState({openPrintingOptionsModal: false, selectedTenantId: null})}
                                printPdf={(meterReadings, compositionOfHeatingCosts, costSplitWarmWaterAndHeating, splitBaseConsumptionCosts, energyConsumptionAndCO2Emissions, calculationOfCO2Tax, previousComparisonBarChart, benchmarkingHouseAverage, energyAdvice, contactInformation)=> {
                                  setCurrentBillingId(data.id);
                                  setTimeout(() => {
                                    window.open(`/billingStatement2/${billingStatementRow.id}?heatingFilter=${propertySelected.heatingType !== 'decentralized' ? data.heatingCostsActivated : false}&meterReadings=${meterReadings}&compositionOfHeatingCosts=${compositionOfHeatingCosts}&costSplitWarmWaterAndHeating=${costSplitWarmWaterAndHeating}&splitBaseConsumptionCosts=${splitBaseConsumptionCosts}&energyConsumptionCO2EmissionsSwitch=${energyConsumptionAndCO2Emissions}&compareHeatingConsumptionBarChartSwitch=${previousComparisonBarChart}&energyLevelsInfoSwitch=${benchmarkingHouseAverage}&arbitraryBoardInfoSwitch=${contactInformation}&energyAdviceCenterInfoSwitch=${energyAdvice}&co2TaxInfoSwitch=${calculationOfCO2Tax}&vatSwitch=${data.isVATHidden !== undefined ? !data.isVATHidden : false}&laborCostSwitch=${data.isLaborCostHidden !== undefined ? !data.isLaborCostHidden : false}`, '_blank');  
                                  }, 2000);
                                }}
                                className='ub-printing-options-modal'
                                />}
                              </div>
                              <div className="download-btn-mobile">
                                <img src={DownloadIcon} alt="download" />
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                ) : (
                  <>
                    {/* <p>No Records found</p> */}
                    <EmptyStateContainer
                      heading={'Hier werden Ihnen später die Abrechnungen der Mieter angezeigt.'}//lang.utility.text_no_utility_billing}
                      className="utility_billing_row_emptyState"
                      subheading=""
                      icon={tap}
                      showBtn={false}
                    />
                  </>
                )}
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Divider />
          <DeleteModal
            open={this.state.deleteUtilityModal}
            className="delete-rent-modal"
            toggle={() => this.toggleDeleteUtilityBilling()}
            record={this.props.data}
            handleDelete={() => this.deleteUnit()}
            // showInfo={[this.state.currentUnit.name, `${this.state.currentUnit.size} m²`]}
            type={'Betriebskostenabrechnung'}
            showtext={false}
            typeText={
              'Sind Sie sicher, dass Sie diese Betriebskostenabrechnung löschen wollen? Dadurch werden alle Informationen und Mieterabrechnungen unwiderruflich gelöscht.'
            }
            showDeleteText={false}
            textShowModal={'Betriebskostenabrechnung löschen'}
            modalId="unit-delete-modal-footer"
          />
        </div>
      );
    } else {
      return <div>{/* <Spinner animation="border" variant="primary" /> */}</div>;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    utilityBillingData: state.UtilityBillingReducer.utilityBillingData,
    propertySelected: state.generalReducer.property,
  };
};
const actions = {
  setCurrentBillingId
};
export default connect(mapStateToProps, actions)(UtilityBiliingRow);
// export default withRouter(UtilityBiliingRow)
