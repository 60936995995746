import React, { Component } from 'react';
import { Table } from 'reactstrap';
import AllocationKeysRows from '../../../../neoverv/components/Module/General/AllocationKeyRows/AllocationKeysRows.js';
import { ColorButton, SearchContainer, SortAlphabetically, EmptyStateContainer } from '../../../../neoverv';
import PageHeader from '../../../elements/module/general/PageHeader';
import Autoload from '../../../../neoverv/Autoload';
import AddAllocationKeyModal from '../../../../neoverv/components/Module/General/CustomModal/AddAllocationKeyModal.js';
import { getAllocationData } from '../../../../redux/action/Property/propertyActions';
import { connect } from 'react-redux';
import GlobalServices from '../../../../neoverv/helpers/services/GlobalServices';
import GeneralDataHandler from '../../../../neoverv/helpers/DataHandler/General.js';
import emptyPropertyHome from '.././../../../assets/images/module/general/PropertyIcon.svg';
import { Grid, Skeleton } from '@mantine/core';
import FixedAllocationKeyModal from '../../../../neoverv/components/Module/General/CustomModal/FixedAllocationKeyModal.jsx';
var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
const MOBILE_SIZE = 500;

class AllocationKeys extends Component {
  state = {
    AddAllocationKeyModal: false,
    searchValue: '',
    sortingalphabetically: false,
    ascendingOrder: true,
    checkFilter: false,
    filterData: [],
    isMobile: window.innerWidth <= MOBILE_SIZE,
    skeletonLoading: false,
    openFixedAllocationKeyModal: false,
    selectedAllocationKeyType: '',
    selectedAllocationId: null,
    selectedAllocationUnits: '',
    selectedAllocationKeyName: ''
  };

  componentDidMount() {
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'none';
    this.setState({skeletonLoading: true});
    this._fetchAllocationKeyByProperty();
  }

  _fetchAllocationKeyByProperty() {
    GlobalServices._getAllocationKeysByProperty(this, '_handle_allocation_keys_response', this.props.propertySelected);
  }

  _handle_allocation_keys_response(response) {
    if (response.status === false && response.message !== 'Not Found') {
      // Autoload.Redirection.redirection_error();
      Autoload.DataHandlerGeneral.GeneralErrorMessage(
        true,
        lang.newKeys.text_error_not_found
        // + response.message
      );
    }
    const allocationDataRes = GeneralDataHandler.getAllocationKeys(response.result);
    // this.setState({ allocationData: allocationData });
    let sortedarray = [];
    let field = 'allocationKeyName';
    sortedarray = allocationDataRes.sort((a, b) =>
      (a[field] || '').toString().localeCompare((b[field] || '').toString())
    );
    this.setState({
      allocationData: sortedarray,
    });
    this.ShowLoader();
  }

  filterListBySearch = (e, properties) => {
    let filteredArray = [];
    filteredArray = this.state.allocationData.filter((item) => {
      for (let i = 0; i < properties.length; i++) {
        if (item[properties[i]].toLowerCase().includes(e.target.value.toLowerCase()) === true) {
          return true;
        }
      }
      return false;
    });
    this.setState({
      searchValue: e.target.value,
      filterData: filteredArray,
    });
  };

  sortAlphabetically = () => {
    // A to Z
    let filteredArray = [];
    let field = 'allocationKeyName';
    filteredArray = this.state.allocationData.sort((a, b) =>
      (a[field] || '').toString().localeCompare((b[field] || '').toString())
    );
    this.setState({
      allocationData: filteredArray,
    });
  };

  sortReverseAlphabetically = () => {
    // Z to A
    let filteredArray = [];
    let field = 'allocationKeyName';
    filteredArray = this.state.allocationData.sort((a, b) =>
      (b[field] || '').toString().localeCompare((a[field] || '').toString())
    );
    this.setState({
      allocationData: filteredArray,
    });
  };

  toggleAddAllocationKeyModal = () => {
    this.setState({ AddAllocationKeyModal: !this.state.AddAllocationKeyModal });
  };

  ShowLoader = () => {
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'none';
    var emptyDiv = document.getElementById('empty-allocation-type-page');
    emptyDiv.style.display = 'none';
    var dataDiv = document.getElementById('respond-allocation-type-page');
    dataDiv.style.display = 'block';
    this.setState({skeletonLoading: false});
  };

  renderAddAllocationKeyButton=()=>{
    const {skeletonLoading} = this.state;
    return(
      <Skeleton visible={skeletonLoading}>
        <ColorButton
          onClick={() => {
            this.toggleAddAllocationKeyModal();
          }}
          text={lang.property.allocationKeys_text_add_allocationkeys}
          className="purple-btn"
        />
      </Skeleton>
    )
  }

  renderSearchBar=()=>{
    const {skeletonLoading, searchValue} = this.state;
    return(
      <Skeleton visible={skeletonLoading}>
        <SearchContainer
          placeholder={`${lang.general.text_search}...`}
          value={searchValue}
          disabled={false}
          onChange={(e) => this.filterListBySearch(e, ['allocationKeyName', 'units'])}
        />
      </Skeleton>
    )
  }

  render() {
    const {skeletonLoading, openFixedAllocationKeyModal, selectedAllocationKeyType, selectedAllocationId, selectedAllocationUnits, selectedAllocationKeyName, allocationData} = this.state;
    const {propertySelected, isMobile} = this.props;

    // console.log('allocationData: ', allocationData);
    return (
      <>
        <div id="empty-allocation-type-page"></div>
        <div className="allocation-page" id="respond-allocation-type-page" style={{ display: 'block', marginTop: isMobile && '30px' }}>
          {/* page header */}
          <PageHeader
            heading={!isMobile && <Skeleton visible={skeletonLoading}>{lang.property.allocationKeys_text_head}</Skeleton>}
            headingClassName={'tooltips property-allocation-keys-heading'}
            rightContent={!isMobile && 
              this.renderAddAllocationKeyButton()
            }
            searchElement={
              isMobile ?
              <Grid>
                <Grid.Col span={8}>
                  {this.renderSearchBar()}
                </Grid.Col>
                <Grid.Col span={4}>
                  {this.renderAddAllocationKeyButton()}
                </Grid.Col>                
              </Grid>
              :
              this.renderSearchBar()
            }
            sortElement={
              <Skeleton visible={skeletonLoading}>
                <SortAlphabetically
                  sortingParam="alphabet"
                  handleAscending={this.sortAlphabetically}
                  handleDescending={this.sortReverseAlphabetically}
                />
              </Skeleton>
            }
          />

          {/* /////////////////////////// web view ///////////////////////// */}

          {this.state.allocationData ?
          <Table responsive bordered={false} className="table-responsive-allocation">
            <thead>
              <tr>
                <th width={'25%'} className="table-heading-allocaion name-allocation">
                  {lang.property.allocationKeys_tablehead_name}
                </th>
                <th width={'20%'} className="table-heading-allocaion allocation-measure">
                  {lang.property.allocationKeys_tablehead_unit}
                </th>
                <th width={'20%'} className="table-heading-allocaion allocation-meter" >{lang.newKeys.text_System_key}</th>
                <th width={'20%'} className="table-heading-allocaion" >{'Art'}</th>
                {/* <th className='table-heading-allocaion active-allocation'>{lang.property.allocationKeys_tablehead_active}</th> */}
                <th className="table-heading-allocaion dots-allocation">{lang.property.Text}</th>
              </tr>
            </thead>

            {this.state.searchValue === '' && this.state.sortingalphabetically === false ? (
              this.state.allocationData &&
              this.state.allocationData.map((data, index) => {
                return (
                  <tbody style={{borderTop: 'none'}}>
                    <AllocationKeysRows 
                    key={`1-${index}`} 
                    selectedType={(type, id, units, name)=> (type === 'FIXED_RENTAL_UNIT' || type === 'FIXED_TENANT') && this.setState({selectedAllocationKeyType: type, openFixedAllocationKeyModal: true, selectedAllocationId: id, selectedAllocationUnits: units, selectedAllocationKeyName: name})} 
                    allocationData={data}
                    allocationWholeData={allocationData}
                    propertySelected={propertySelected}
                    parent={this}/>
                  </tbody>
                );
              })
            ) : this.state.filterData.length > 0 ? (
              this.state.filterData.map((data, index) => {
                return (
                  <tbody>
                    <AllocationKeysRows 
                    key={`2-${index}`} 
                    selectedType={(type, id, units, name)=> (type === 'FIXED_RENTAL_UNIT' || type === 'FIXED_TENANT') && this.setState({selectedAllocationKeyType: type, openFixedAllocationKeyModal: true, selectedAllocationId: id, selectedAllocationUnits: units, selectedAllocationKeyName: name})} 
                    allocationData={data} 
                    allocationWholeData={allocationData}
                    propertySelected={propertySelected}
                    parent={this} />
                  </tbody>
                );
              })
            ) : (
              <div style={{ backgroundColor: '#F7F7FA', textAlign: 'center' }} className="property-types-emptystates">
                <EmptyStateContainer
                  heading="Keine Übereinstimmung gefunden."
                  // subheading="You must add property first to manage utility billing."
                  icon={emptyPropertyHome}
                  // showbtn={false}
                  btnHandler={() => {
                    this.setState({ AddAllocationKeyModal: !this.state.AddAllocationKeyModal });
                  }}
                  // buttonDisable={true}
                  btnText={'Verteilerschüssel hinzufügen'} //"Add cost type"
                />
              </div>
            )}
          </Table>
          :
          <>
          <Skeleton visible={skeletonLoading} style={{height: '23px', marginBottom: '10px'}}>
            <div>
            </div>
          </Skeleton>
          {[...Array(10)].map((e, i) => 
            <Skeleton visible={skeletonLoading} style={{height: '75px', marginBottom: '10px'}}>
              <div>
              </div>
            </Skeleton>
          )}
          </>
          }

          {this.state.AddAllocationKeyModal && (
            <AddAllocationKeyModal
              record={null}
              parent={this}
              allocationData={allocationData}
              className="add_allocation_key_modal"
              edit={false}
              open={this.state.AddAllocationKeyModal}
              toggle={this.toggleAddAllocationKeyModal}
              callBackFunction={() => this._fetchAllocationKeyByProperty()}
              selectedType={(type, id, units, name)=> (type === 'FIXED_RENTAL_UNIT' || type === 'FIXED_TENANT') && this.setState({selectedAllocationKeyType: type, openFixedAllocationKeyModal: true, selectedAllocationId: id, selectedAllocationUnits: units, selectedAllocationKeyName: name})}
            />
          )}
          {openFixedAllocationKeyModal &&
          <FixedAllocationKeyModal
          open={openFixedAllocationKeyModal}
          className={'individual-allocation-key-modal'}
          type={selectedAllocationKeyType}
          allocationKeyId={selectedAllocationId}
          toggle={()=> this.setState({openFixedAllocationKeyModal: false, selectedAllocationKeyType: '', selectedAllocationKeyName: '', selectedAllocationUnits: '', selectedAllocationId: ''})}
          unit={selectedAllocationUnits}
          name={selectedAllocationKeyName}
          history={this.props.history}
          />}
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    allocationData: state.allocationKeyReducer,
    propertySelected: state.generalReducer.property,
  };
};
export default connect(mapStateToProps, { getAllocationData })(AllocationKeys);
