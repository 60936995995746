import React, { useState } from 'react';
import { RecordOptions, DeleteModal } from '../../../../index.js';
import TenantSingleIcon from '../../../../../assets/images/module/general/TenantSingleIcon.svg';
import TenantLite from '../../../../../assets/images/module/general/TenantLite.svg';
import MovedInIcon from '../../../../../assets/images/module/general/MovedInIcon.svg';
import MovedOutIcon from '../../../../../assets/images/module/general/MovedOutIcon.svg';
import CalendarIcon from '../../../../../assets/images/module/general/CalendarIcon.svg';
import buildingIcon from '../../../../../assets/images/module/general/building_icon.svg';
import MultipleTenants from '../../../../../assets/images/module/general/MultipleTenants.svg';
import PropertyIcon from '../../../../../assets/images/module/general/PropertyIcon.svg';
import MeterIcon from '../../../../../assets/images/module/general/blueEmptyMeter.svg';
import EmptyApartmentIcon from '../../../../../assets/images/module/general/emptyApartmentIcon2.svg';
import {
  EmptyStateContainer,
} from '../../../../../neoverv';
import { deleteMeterReadings } from '../../../../../redux/action/Meter/meterActions';
import { connect } from 'react-redux';
import Autoload from '../../../../../neoverv/Autoload';
import moment from 'moment';
import { Group } from '@mantine/core';
var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
const { checkProperty } = Autoload.MainHelper;
var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
function MeterReadingRecord({
  data,
  className,
  labels,
  deleteMeterReadings,
  history,
  labelClassName,
  parent,
  billingPeriod,
  rentalUnitsExist
}) {
  const [openDelete, setOpenDelete] = useState(false);
  const [currentSelection, setCurrentSelection] = useState(0);
  const toggleDeleteModal = () => setOpenDelete(!openDelete);

  const editMeterReading = (data, period = 'start') => {
    // console.log('data', data, period);
    const { billingPeriodId, periodStartReading, id } = data;
    history.push({
      pathname: `${Autoload.Setting.get_meter_reading_annual_link(billingPeriodId, 'c-r-0')}`,
      data: {...data, period},
      edit: true,
    })
  };
  console.log('dataaa', data)
  const deleteReading = (rowId) => {
    // var loader = document.getElementById('hello-housing-loader');
    // loader.style.display = 'block';
    if (rowId == null) {
      return;
    }
    // // ;
    let btnelement = document.getElementById('meter_reading_delete_modal');
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.meter_api_base_url + 'readings/' + rowId,
      'DELETE',
      null,
      btnelement.children[1],
      false,
      true,
      false,
      parent,
      'handle_delete_reading_list_data',
      { rowId: rowId, closeModalFunc: toggleDeleteModal },
      false,
      ''
    );
  };

  const setIndexes = (rowIndex) => {
    setCurrentSelection(rowIndex);
  };

  const getTableLabels = () => {
    return labels.map((label, index) => {
      return (
        <td key={index} className={`${labelClassName[index]} d-none d-md-table-cell`}>
          {label}
        </td>
      );
    });
  };

  const renderOccasionIcon = (occasionName) => {
    switch (occasionName) {
      case 'Moved in':
      case 'move-in':
        return MovedInIcon;
      case 'Moved out':
      case 'move-out':
        return MovedOutIcon;
      case 'Annual':
      case 'annual':
        return CalendarIcon;
      case 'empty-tenant-move-out':
        return EmptyApartmentIcon;
      default:
        break;
    }
  };

  const renderOccasionName = (occasionName) => {
    switch (occasionName) {
      case 'Moved in':
      case 'move-in':
        return 'Moved in';
      case 'Moved out':
      case 'move-out':
        return 'Moved out';
      case 'Annual':
      case 'annual':
        return 'Annual';
      default:
        break;
    }
  };

  const renderOccasionName_de = (occasionName) => {
    switch (occasionName) {
      case 'Moved in':
      case 'move-in':
        // return 'Moved in';
        return lang.tenant.text_move_in;
      case 'Moved out':
      case 'move-out':
        // return 'Moved out';
        return lang.tenant.text_move_out;
      case 'Annual':
      case 'annual':
        // return 'Annual';
        return lang.meter.text_annual;
      case 'empty-tenant-move-out':
          return 'Leerstand';
      default:
        break;
    }
  };

  const getTableData = () => {
    return (
      <>
        {data.occupationReadings
        // .filter(a => (
        //   ((a.tenantFullName !== '(leer)')
        //     || (a.tenantFullName === '(leer)' && a.periodEndReading.occasion === 'annual'))
        //   && moment(a.periodStartReading.occasionDate).format('YYYY') === moment(data.periodEnd).format('YYYY')
        // ))
        .map((row, rowIndex) => {
          // // ;

          const itemYear = moment(row.periodEnd).year();
          const currentYear = new Date().getFullYear();
          const dateDiff = currentYear - itemYear;

          // if( dateDiff !== billingPeriod ) return null;
          // if( row.id === '' ) return null;

          let statusIcon = MovedInIcon;
          switch (row.periodStartReading.occasion) {
            case 'Moved in':
            case 'move-in':
              statusIcon = MovedInIcon;
              break;
            case 'Moved out':
            case 'move-out':
              statusIcon = MovedOutIcon;
              break;
            case 'Annual':
            case 'annual':
              statusIcon = CalendarIcon;
              break;
            default:
              break;
          }
          var show_psr = true;
          var show_per = true;

          /* 
  
          //Enable when integeate Live API
          
          var show_psr = false;
          var show_per = false;
          
          var occasionDate = moment(row.periodStartReading.occasionDate);
         
          if(
            occasionDate.isBetween(moment(row.periodStart), moment(row.periodEnd)) 
            || occasionDate.isSame(moment(row.periodStart))
            || occasionDate.isSame(moment(row.periodEnd))
            ){
              show_psr = true;
            
          }
  
          var occasionDate = moment(row.periodEndReading.occasionDate);
         
          if(
            occasionDate.isBetween(moment(row.periodStart), moment(row.periodEnd)) 
            || occasionDate.isSame(moment(row.periodStart))
            || occasionDate.isSame(moment(row.periodEnd))
            ){
              show_per = true;
            
          }
          */
          let periodStart = moment(checkProperty(row, 'periodStart'), 'YYYY-MM-DD').format('DD.MM.YYYY');
          let periodEnd = moment(checkProperty(row, 'periodEnd'), 'YYYY-MM-DD').format('DD.MM.YYYY');
          let occasionDate = moment(checkProperty(row.periodStartReading, 'occasionDate'), 'YYYY-MM-DD').format(
            'DD.MM.YYYY'
          );
          const tem_periodStartReading = row.periodStartReading.occasionDate >= data.periodStart && row.tenantFullName !== '(leer)'
            ? (row.periodStartReading.id === '' ? (null)
              : (<tr key={row.id + '-' + row.periodStartReading.id + '-psr'} className="table-data" style={{ cursor: 'pointer' }} onClick={() => editMeterReading(row, 'start')}>
                <td
                  className={`text-nl text-normal value-text-meter-rental `}
                  style={{ cursor: 'pointer' }}
                  //onClick={() => editMeterReading(row, 'start')}
                >
                  {row.rentalUnitName}
                </td>
                <td
                  className={`text-nl text-normal value-text-meter-tenant d-none d-md-table-cell`}
                  style={{ cursor: 'pointer', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                  //onClick={() => editMeterReading(row, 'start')}
                >
                  {/* <img src={row.tenantFullName === '(leer)' ? TenantLite : TenantSingleIcon} alt="tenant" /> */}
                  <img
                    src={
                      row.tenantFullName === '(leer)'
                        ? TenantLite
                        : row.contractType === 'private'
                          ? TenantSingleIcon
                          : row.contractType === 'commercial'
                            ? buildingIcon
                            : row.contractType === 'Default'
                              ? null
                              : null
                    }
                    alt=" "
                  />
                  {row.tenantFullName}
                </td>
                <td className={`text-nl text-normal value-text-occupency-dates d-none d-md-table-cell`}>
                  {/* {checkProperty(row, 'periodStart')} - {checkProperty(row, 'periodEnd')} ({checkProperty(row, 'periodDays')} days) */}
                  {periodStart} - {periodEnd} ({checkProperty(row, 'periodDays')}
                  {` ${lang.newKeys.text_days}`})
                </td>
                <td className={`text-nl text-normal value-text-meter-ocassion d-none d-md-table-cell`}
                style={{whiteSpace: 'nowrap'}}>
                  <img
                    src={renderOccasionIcon(row.periodStartReading.occasion)}
                    alt="tenant"
                    className="status-icon-meter"
                  />
                  {renderOccasionName_de(row.periodStartReading.occasion)}
                </td>
                <td className={`text-nl text-normal value-text-meter-readingDate d-none d-md-table-cell`}>
                  {occasionDate}
                </td>
                <td className="text-nl text-normal value-text-meter-empty " onClick={() => setIndexes(rowIndex)}>
                  <RecordOptions
                    // toggleDeleteModal={() => toggleDeleteModal()}
                    toggleEditModal={() => editMeterReading(row, 'start')}
                    showDeleteButton={false}
                    showPrintButton={true}
                    onPrint={()=> window.open(`/meterReadingPdf/${data.billingPeriodData.id}?occupationReadings=${JSON.stringify([{readingsId: row.periodStartReading.id, occupationId: row.id}])}`, '_blank')}
                  />
                </td>
              </tr>
              )
            ) : (
              ''
            );

          periodStart = moment(checkProperty(row, 'periodStart'), 'YYYY-MM-DD').format('DD.MM.YYYY');
          periodEnd = moment(checkProperty(row, 'periodEnd'), 'YYYY-MM-DD').format('DD.MM.YYYY');
          occasionDate = moment(checkProperty(row.periodEndReading, 'occasionDate'), 'YYYY-MM-DD').format('DD.MM.YYYY');
          const tem_periodEndReading =
            row.periodEndReading.occasionDate <= data.periodEnd &&
              (row.tenantFullName !== '(leer)' || (row.tenantFullName === '(leer)' && row.periodEndReading.occasion === 'annual') || (row.tenantFullName === '(leer)' && row.periodEndReading.occasion === 'move-in')
              ) ?
              (
                row.periodEndReading.id === '' ? (
                  ''
                ) : (
                  <tr key={row.id + '-' + row.periodEndReading.id + '-per'} className="table-data" onClick={() => editMeterReading(row, 'end')} style={{ cursor: 'pointer' }}>
                    <td
                      className={`text-nl text-normal value-text-meter-rental `}
                      style={{ cursor: 'pointer' }}
                      //onClick={() => editMeterReading(row, 'end')}
                    >
                      {!row.rentalUnitName ? '-' : row.rentalUnitName}
                    </td>
                    <td
                      className={`text-nl text-normal value-text-meter-tenant d-none d-md-table-cell`}
                      style={{ cursor: 'pointer', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'  }}
                      //onClick={() => editMeterReading(row, 'end')}
                    >
                      <img
                        src={
                          row.tenantFullName === '(leer)'
                            ? TenantLite
                            : row.contractType === 'private'
                              ? TenantSingleIcon
                              : row.contractType === 'commercial'
                                ? buildingIcon
                                : row.contractType === 'Default'
                                  ? null
                                  : null
                          // row.tenantFullName === '(leer)' ? TenantLite : TenantSingleIcon
                        }
                        alt=""
                      />
                      {!row.tenantFullName ? '-' : row.tenantFullName}
                    </td>

                    <td className={`text-nl text-normal value-text-occupency-dates d-none d-md-table-cell`}>
                      {/* {checkProperty(row, 'periodStart')} - {checkProperty(row, 'periodEnd')} ({checkProperty(row, 'periodDays')} days) */}
                      {periodStart} - {periodEnd} ({checkProperty(row, 'periodDays')}
                      {` ${lang.newKeys.text_days}`})
                    </td>
                    <td className={`text-nl text-normal value-text-meter-ocassion d-none d-md-table-cell`}
                    style={{whiteSpace: 'nowrap', //overflow: 'hidden', textOverflow: 'ellipsis' 
                    }}>
                      {!(row.tenantFullName === '(leer)' && row.periodEndReading.occasion === 'move-in') ? 
                      <>
                        <img
                          src={renderOccasionIcon(row.periodEndReading.occasion)}
                          alt="tenant"
                          className="status-icon-meter"
                        />
                        {renderOccasionName_de(row.periodEndReading.occasion)}
                      </>
                      :
                      <>
                        <img
                          src={renderOccasionIcon('empty-tenant-move-out')}
                          alt="tenant"
                          className="status-icon-meter"
                        />
                        {renderOccasionName_de('empty-tenant-move-out')}
                      </>}
                    </td>
                    <td className={`text-nl text-normal value-text-meter-readingDate d-none d-md-table-cell`}>
                      {(row.tenantFullName === '(leer)' && row.periodEndReading.occasion === 'move-in') ? periodEnd : occasionDate}
                    </td>
                    <td className="text-nl text-normal value-text-meter-empty " onClick={() => setIndexes(rowIndex)}>
                      <RecordOptions
                        // toggleDeleteModal={() => toggleDeleteModal()}
                        showDeleteButton={false}
                        toggleEditModal={() => editMeterReading(row, 'end')}
                        showPrintButton={true}
                        onPrint={()=> window.open(`/meterReadingPdf/${data.billingPeriodData.id}?occupationReadings=${JSON.stringify([{readingsId: row.periodEndReading.id, occupationId: row.id}])}`, '_blank')}
                      />
                    </td>
                  </tr>
                )
              ) : (
                ''
              );
          console.log('roww', row, tem_periodEndReading)
          const propertyHeading = (
          <Group spacing={10} style={{marginTop: '32px', marginBottom: '15px', marginLeft: '2px'}} noWrap>
            <img src={PropertyIcon}/> 
            <span style={{fontSize: '16px', fontWeight: '600', marginTop: '4px', color: '#0E0E1D', whiteSpace: 'nowrap', fontFamily: 'Inter, sans-serif'}}>{'Ablesung Hauszähler'}</span>
          </Group>);
          const rentalUnitHeading = (
            <Group spacing={10} style={{marginTop: '32px', marginBottom: '15px', marginLeft: '2px'}} noWrap>
              <img src={MultipleTenants} style={{marginTop: '4px'}}/> 
              <span style={{fontSize: '16px', fontWeight: '600', marginTop: '4px', color: '#0E0E1D', whiteSpace: 'nowrap', fontFamily: 'Inter, sans-serif'}}>{'Ablesung Mieterzähler'}</span>
            </Group>);
          const column= (<tr className="table-heading">{getTableLabels()}</tr>);
          const tem_bill_period = [rowIndex === 0 && !row.rentalUnitName ? propertyHeading : rowIndex === 1 && row.rentalUnitName && rentalUnitHeading, rowIndex <= 1 && column, tem_periodStartReading, tem_periodEndReading];

          return tem_bill_period;
        })}
        {rentalUnitsExist === false &&
        <>
          <Group spacing={10} style={{marginTop: '32px', marginBottom: '15px', marginLeft: '2px'}} noWrap>
            <img src={MultipleTenants} style={{marginTop: '4px'}}/> 
            <span style={{fontSize: '16px', fontWeight: '600', marginTop: '4px', color: '#0E0E1D', whiteSpace: 'nowrap', fontFamily: 'Inter, sans-serif'}}>{'Ablesung Mieterzähler'}</span>
          </Group>
          <tr>
            <td colSpan={6}>
              <EmptyStateContainer
                heading={'Ablesungen sind noch nicht verfügbar.'}
                subheading={'Legen Sie zunächst Mieteinheiten an, um Mieterablesungen durchzuführen.'}
                className="empty-meter-reading"
                icon={MeterIcon}
                styleMainContainer={{marginTop: '20px'}}
                btnHandler={() => parent.props.history.push('/user')}
                btnText={'Einheit hinzufügen'}
              />
            </td>
          </tr>
        </>}
      </>
    );
  };

  console.log(data);
  return (
    <>
      <table className={`table borderless ${className} mb-0`}>
        <tbody>
          {getTableData()}
        </tbody>
        <DeleteModal
          modalId="meter_reading_delete_modal"
          record={data.occupationReadings[currentSelection]}
          className="delete-receipt-modal"
          open={openDelete}
          toggle={() => toggleDeleteModal()}
          handleDelete={deleteReading}
          type={lang.newKeys.text_record}
        />
      </table>
    </>
  );
}
export default connect(null, { deleteMeterReadings })(MeterReadingRecord);
