import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setTenantBills } from '../../../../redux/action/UtilityBilling/UtilityBillingActions';
import { formatNumber } from '../../../../helpers/stringsManipulations';
import { EmptyStateContainer, UtilityBillsTableRow } from '../../../../neoverv';
import Autoload from '../../../../neoverv/Autoload';
import tap from '../../../../assets/images/module/general/TapIconBlue.svg';
import accounting from 'accounting';


var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];

var tableHeadCellStyling = {
  color: '#727293',
  padding: '0 9px 0 9px',
  fontWeight: '500',
  whiteSpace: 'nowrap',
  margin: '0px',
  fontSize: '14px',
  lineHeight: '20px',
  border: '1px solid #DADAE6',
  textTransform: 'capitalize',
  height: '48px',
}
var tableBodyCellStyling = {
  color: '#0e0e1d',
  padding: '5px 9px 5px 9px',
  fontWeight: '400',
  whiteSpace: 'nowrap',
  fontSize: '14px',
  lineHeight: '20px',
  border: '1px solid #DADAE6',
  height: '48px',
  minWidth: '90px',
  // borderBottom: 'none',
  // borderRight: 'none'
}

export class UtilityBillTable extends Component {

  constructor(props){
    super(props);

    if(props.messagePreview){
      tableBodyCellStyling.minWidth= '40px';
      tableBodyCellStyling.height= '0px';
      tableBodyCellStyling.padding= '1mm';
      tableBodyCellStyling.fontSize= '7pt';
    }

  }

  componentDidMount(){
    if(this.props.messagePreview){
      tableBodyCellStyling= {
        color: '#0e0e1d',
        padding: '1mm',
        fontWeight: '500',
        whiteSpace: 'nowrap',
        margin: '0px',
        fontSize: '7pt',
        lineHeight: '20px',
        border: '1px solid #DADAE6',
        textTransform: 'capitalize',
        height: '0px',
        minWidth: '40px'
      }
      tableBodyCellStyling.minWidth= '40px';
      tableBodyCellStyling.height= '0px';
      tableBodyCellStyling.padding= '1mm';
      tableBodyCellStyling.fontSize= '7pt';
    }
  }

  getTableLabels = () => {
    return (
      <TableRow>
        <TableCell style={tableHeadCellStyling}>{lang.utility.text_cost_type}</TableCell>
        <TableCell style={tableHeadCellStyling}>{lang.utility.text_total_cost}</TableCell>
        <TableCell style={tableHeadCellStyling}>{lang.utility.text_allocation_key}</TableCell>
        <TableCell style={tableHeadCellStyling}>{lang.utility.text_total_value}</TableCell>
        <TableCell style={tableHeadCellStyling}>{lang.utility.text_tenant_value}</TableCell>
        <TableCell style={tableHeadCellStyling}>{lang.utility.text_unit}</TableCell>
        <TableCell style={tableHeadCellStyling}>{lang.utility.text_tenant_cost_share}</TableCell>
        <TableCell style={tableHeadCellStyling}>{lang.utility.text_comment}</TableCell>
      </TableRow>
    );
  };
  calculateTotal=(i, deCurrency=true, type)=>{
    if(type === 'heating'){
      let total = this.props.messagePreview ? this.props?.invoiceData?.totalAllocatedHeatingCosts : this.props.selectedTenantData.totalAllocatedHeatingCosts;
      console.log('calculateTotal: ', total);
      return deCurrency === false ? total : formatNumber(total, true);
    }else{
      let total = 0;
      //console.log('calculateTotal: ',this.props.selectedTenantData.tenantRows[i].totalCost);
      this.props.selectedTenantData.tenantRows.map((row, index)=> {
        console.log('row: ', row);
        if(row.costType !== "Heizung & Heizungswartung"){
          if(index <= i){
            total = total + row.tenantCostShare;
          }
        }
      })
      console.log('calculateTotal: ', total);
      return deCurrency === false ? total : formatNumber(total, true);
    }
  }
    heatingRow =(index)=>{
      const {messagePreview, utilityBillingData, currentDataId, selectedTenantData} = this.props;
      // let heatingTotalRow = this.props.selectedTenantData.tenantRows.find((tenantRow)=> tenantRow.costType === 'Heizung & Heizungswartung');
      console.log('heatingRow: ', this.props.selectedTenantData.tenantRows.find((tenantRow)=> tenantRow.costType === 'Heizung & Heizungswartung'));
      let currentUB = utilityBillingData.find((ub)=> ub.id === currentDataId);
        return (
          <TableRow>
            <TableCell style={{...tableBodyCellStyling, ...{textAlign: 'right', borderBottom: this.props.selectedTenantData.tenantRows.length === (index + 1) && '1px solid #DADAE6', fontSize: messagePreview ? '7pt' : '14px', height: messagePreview ? '0px' : '48px', padding: messagePreview ? '1mm' :  '0 9px 0 9px', lineHeight: '11pt'}}} colSpan={6}>
              Heizkosten gesamt
            </TableCell>
            <TableCell style={{...tableBodyCellStyling, ...{fontWeight: 'bold', textAlign: 'right', borderBottom: this.props.selectedTenantData.tenantRows.length === (index + 1) && '1px solid #DADAE6', fontSize: messagePreview ? '7pt' : '14px', height: messagePreview ? '0px' : '48px', padding: messagePreview ? '1mm' :  '0 9px 0 9px', lineHeight: '11pt'}}} colSpan={1}>
              {this.calculateTotal(index, true, 'heating')}
              {/* {heatingTotalRow !== undefined ? formatNumber(heatingTotalRow?.tenantCostShare, true) : '-'} */}
            </TableCell>
            <TableCell style={{...tableBodyCellStyling, ...{fontWeight: 'bold', borderBottom: this.props.selectedTenantData.tenantRows.length === (index + 1) && '1px solid #DADAE6', borderRight: '1px solid #DADAE6', fontSize: messagePreview ? '7pt' : '14px', height: messagePreview ? '0px' : '48px', padding: messagePreview ? '1mm' :  '0 9px 0 9px', lineHeight: '11pt'}}} colSpan={1}/>
          </TableRow>
        );
      }
      utilityTotalRow=(index)=>{
        const {messagePreview, utilityBillingData, currentDataId, hideTenantZeroCostsSwitch, isPreTaxInfoHidden} = this.props;
        let currentUB = utilityBillingData.find((ub)=> ub.id === currentDataId);
        console.log('this.props.invoiceData: ', this.props.billingTemplate && (this.props.invoiceData.tenantOccupation.contractType === 'Default' || this.props.invoiceData.tenantOccupation.contractType === 'commercial'));
        
        const commercialTenantWithVatSwitch = this.props.vatSwitch && this.props.currentSubscription.subscriptionKey.split('_')[0] !== 'STARTER' && (this.props.billingTemplate ? (this.props.invoiceData.tenantOccupation.contractType === 'Default' || this.props.invoiceData.tenantOccupation.contractType === 'commercial' || this.props.invoiceData.tenantOccupation.contractType === 'self') : (this.props.selectedTenantData.tenantOccupation.contractType === 'Default' || this.props.selectedTenantData.tenantOccupation.contractType === 'commercial' || this.props.selectedTenantData.tenantOccupation.contractType === 'self'));
        const sumOfVAT = this.props.billingTemplate ? (this.props.invoiceData.totalVATAmount ? this.props.invoiceData.totalVATAmount : 0) : (this.props.selectedTenantData.totalVATAmount ? this.props.selectedTenantData.totalVATAmount : 0);
        const netUtilityBillingCost = this.calculateTotal(index, false) - sumOfVAT;
        const VAT = (isPreTaxInfoHidden ? this.calculateTotal(index, false) : netUtilityBillingCost) * 0.19;
        const grossUtilityBillingCost = !isPreTaxInfoHidden ? (netUtilityBillingCost + VAT) : (this.calculateTotal(index, false) + VAT);
        const creditAmount = this.props.billingTemplate ? 
        (this.props.invoiceData ? this.props.invoiceData.totalAdvancePayments - grossUtilityBillingCost : 0)
        :
        (this.props.selectedTenantData ? this.props.selectedTenantData.prepayments - grossUtilityBillingCost : 0);

        return(
        <>
        <TableRow>
          <TableCell style={{...tableBodyCellStyling, ...{fontSize: messagePreview ? '7pt' : '14px', height: messagePreview ? '0px' : '48px', padding: messagePreview ? '1mm' :  '0 9px 0 9px', lineHeight: '11pt', textAlign: 'right'}}}>
            Betriebskosten gesamt
          </TableCell>
          <TableCell style={{...tableBodyCellStyling, ...{fontWeight: 'bold', textAlign: 'right', borderBottom: this.props.selectedTenantData.tenantRows.length === (index + 1) && '1px solid #DADAE6', fontSize: messagePreview ? '7pt' : '14px', height: messagePreview ? '0px' : '48px', padding: messagePreview ? '1mm' :  '0 9px 0 9px', lineHeight: '11pt'}}} colSpan={1}>
            {currentUB !== undefined ? formatNumber(currentUB.totalUtilityBillingCost, true) : '-'}
          </TableCell>
          <TableCell style={{...tableBodyCellStyling, ...{textAlign: 'right', fontSize: messagePreview ? '7pt' : '14px', height: messagePreview ? '0px' : '48px', padding: messagePreview ? '1mm' :  '0 9px 0 9px', lineHeight: '11pt'}}} colSpan={4}>
            Ihr Betriebskostenanteil
          </TableCell>
          <TableCell style={{...tableBodyCellStyling, ...{fontWeight: 'bold', textAlign: 'right', fontSize: messagePreview ? '7pt' : '14px', height: messagePreview ? '0px' : '48px', padding: messagePreview ? '1mm' :  '0 9px 0 9px', lineHeight: '11pt'}}} colSpan={1}>
            {this.calculateTotal(index)}
          </TableCell>
          <TableCell style={{...tableBodyCellStyling, ...{fontWeight: 'bold', //borderBottom: this.props.selectedTenantData.tenantRows.length === (index + 1) && '1px solid #DADAE6', 
          borderRight: this.props.billingTemplate && '1px solid #DADAE6', fontSize: messagePreview ? '7pt' : '14px', height: messagePreview ? '0px' : '48px', padding: messagePreview ? '1mm' :  '0 9px 0 9px', lineHeight: '11pt'}}} colSpan={1}/>
        </TableRow>
        {/* New Rows Start */}
        {commercialTenantWithVatSwitch && !isPreTaxInfoHidden &&
        <>
          <TableRow>
            <TableCell style={{...tableBodyCellStyling, ...{borderLeft: hideTenantZeroCostsSwitch && '0px', textAlign: 'right', fontSize: messagePreview ? '7pt' : '14px', height: messagePreview ? '0px' : '48px', padding: messagePreview ? '1mm' :  '0 9px 0 9px', lineHeight: '11pt'}}} colSpan={6}>
              Vorsteuer Vermieter
            </TableCell>
            <TableCell style={{...tableBodyCellStyling, ...{fontWeight: 'bold', textAlign: 'right', fontSize: messagePreview ? '7pt' : '14px', height: messagePreview ? '0px' : '48px', padding: messagePreview ? '1mm' :  '0 9px 0 9px', lineHeight: '11pt'}}} colSpan={1}>
              -{accounting.formatMoney(sumOfVAT, '€', 2, '.', ',', '%v %s')}
            </TableCell>
            <TableCell style={{...tableBodyCellStyling, ...{fontWeight: 'bold', //borderBottom: this.props.selectedTenantData.tenantRows.length === (index + 1) && '1px solid #DADAE6', 
            borderRight: this.props.billingTemplate && '1px solid #DADAE6', fontSize: messagePreview ? '7pt' : '14px', height: messagePreview ? '0px' : '48px', padding: messagePreview ? '1mm' :  '0 9px 0 9px', lineHeight: '11pt'}}} colSpan={1}/>
          </TableRow>
          <TableRow>
            <TableCell style={{...tableBodyCellStyling, ...{borderLeft: hideTenantZeroCostsSwitch && '0px', textAlign: 'right', fontSize: messagePreview ? '7pt' : '14px', height: messagePreview ? '0px' : '48px', padding: messagePreview ? '1mm' :  '0 9px 0 9px', lineHeight: '11pt'}}} colSpan={6}>
              Ihr Betriebskostenanteil (netto)
            </TableCell>
            <TableCell style={{...tableBodyCellStyling, ...{fontWeight: 'bold', textAlign: 'right', fontSize: messagePreview ? '7pt' : '14px', height: messagePreview ? '0px' : '48px', padding: messagePreview ? '1mm' :  '0 9px 0 9px', lineHeight: '11pt'}}} colSpan={1}>
              {accounting.formatMoney(netUtilityBillingCost, '€', 2, '.', ',', '%v %s')}
            </TableCell>
            <TableCell style={{...tableBodyCellStyling, ...{fontWeight: 'bold', //borderBottom: this.props.selectedTenantData.tenantRows.length === (index + 1) && '1px solid #DADAE6', 
            borderRight: this.props.billingTemplate && '1px solid #DADAE6', fontSize: messagePreview ? '7pt' : '14px', height: messagePreview ? '0px' : '48px', padding: messagePreview ? '1mm' :  '0 9px 0 9px', lineHeight: '11pt'}}} colSpan={1}/>
          </TableRow>
        </>
        }



        {commercialTenantWithVatSwitch &&
        <>
          <TableRow>
            <TableCell style={{...tableBodyCellStyling, ...{borderLeft: hideTenantZeroCostsSwitch && '0px', textAlign: 'right', fontSize: messagePreview ? '7pt' : '14px', height: messagePreview ? '0px' : '48px', padding: messagePreview ? '1mm' :  '0 9px 0 9px', lineHeight: '11pt'}}} colSpan={6}>
              Umsatzsteuer (19%)
            </TableCell>
            <TableCell style={{...tableBodyCellStyling, ...{fontWeight: 'bold', textAlign: 'right', fontSize: messagePreview ? '7pt' : '14px', height: messagePreview ? '0px' : '48px', padding: messagePreview ? '1mm' :  '0 9px 0 9px', lineHeight: '11pt'}}} colSpan={1}>
              {accounting.formatMoney(VAT, '€', 2, '.', ',', '%v %s')}
            </TableCell>
            <TableCell style={{...tableBodyCellStyling, ...{fontWeight: 'bold', //borderBottom: this.props.selectedTenantData.tenantRows.length === (index + 1) && '1px solid #DADAE6', 
            borderRight: this.props.billingTemplate && '1px solid #DADAE6', fontSize: messagePreview ? '7pt' : '14px', height: messagePreview ? '0px' : '48px', padding: messagePreview ? '1mm' :  '0 9px 0 9px', lineHeight: '11pt'}}} colSpan={1}/>
          </TableRow>
          
          <TableRow>
            <TableCell style={{...tableBodyCellStyling, ...{borderLeft: hideTenantZeroCostsSwitch && '0px', textAlign: 'right', fontSize: messagePreview ? '7pt' : '14px', height: messagePreview ? '0px' : '48px', padding: messagePreview ? '1mm' :  '0 9px 0 9px', lineHeight: '11pt'}}} colSpan={6}>
              Betriebskosten (brutto)
            </TableCell>
            <TableCell style={{...tableBodyCellStyling, ...{fontWeight: 'bold', textAlign: 'right', fontSize: messagePreview ? '7pt' : '14px', height: messagePreview ? '0px' : '48px', padding: messagePreview ? '1mm' :  '0 9px 0 9px', lineHeight: '11pt'}}} colSpan={1}>
              {accounting.formatMoney(grossUtilityBillingCost, '€', 2, '.', ',', '%v %s')}
            </TableCell>
            <TableCell style={{...tableBodyCellStyling, ...{fontWeight: 'bold', //borderBottom: this.props.selectedTenantData.tenantRows.length === (index + 1) && '1px solid #DADAE6', 
            borderRight: this.props.billingTemplate && '1px solid #DADAE6', fontSize: messagePreview ? '7pt' : '14px', height: messagePreview ? '0px' : '48px', padding: messagePreview ? '1mm' :  '0 9px 0 9px', lineHeight: '11pt'}}} colSpan={1}/>
          </TableRow>
        </>}


        <TableRow>
          <TableCell style={{...tableBodyCellStyling, ...{textAlign: 'right', fontSize: messagePreview ? '7pt' : '14px', height: messagePreview ? '0px' : '48px', padding: messagePreview ? '1mm' :  '0 9px 0 9px', lineHeight: '11pt'}}} colSpan={6}>
            Vorauszahlungen {commercialTenantWithVatSwitch && '(brutto)'}
          </TableCell>
          <TableCell style={{...tableBodyCellStyling, ...{fontWeight: 'bold', textAlign: 'right', fontSize: messagePreview ? '7pt' : '14px', height: messagePreview ? '0px' : '48px', padding: messagePreview ? '1mm' :  '0 9px 0 9px', lineHeight: '11pt'}}} colSpan={1}>
            {this.props.billingTemplate ?
            (this.props.invoiceData
              ? formatNumber(
                  this.props.invoiceData.totalAdvancePayments,
                  true
                )
              : '0,00€')
            :
            this.props.selectedTenantData ? formatNumber(this.props.selectedTenantData.prepayments, true) : '0,00 €'}
          </TableCell>
          <TableCell style={{...tableBodyCellStyling, ...{fontWeight: 'bold', //borderBottom: this.props.selectedTenantData.tenantRows.length === (index + 1) && '1px solid #DADAE6', 
          borderRight: this.props.billingTemplate && '1px solid #DADAE6', fontSize: messagePreview ? '7pt' : '14px', height: messagePreview ? '0px' : '48px', padding: messagePreview ? '1mm' :  '0 9px 0 9px', lineHeight: '11pt'}}} colSpan={1}/>
        </TableRow>
        <TableRow>
          <TableCell style={{...tableBodyCellStyling, ...{textAlign: 'right', fontSize: messagePreview ? '7pt' : '14px', height: messagePreview ? '0px' : '48px', padding: messagePreview ? '1mm' :  '0 9px 0 9px', lineHeight: '11pt'}}} colSpan={6}>
            {this.props.billingTemplate ?
            (this.props.invoiceData ? (((commercialTenantWithVatSwitch && !isPreTaxInfoHidden) ? creditAmount >= 0 : (this.props.invoiceData.totalAdvancePayments - this.props.invoiceData.totalAllocatedUtilityCosts  >= 0)) ? this.props.billingTemplate ? lang.utility.text_balance_positive : <lable>{lang.utility.text_balance_positive}</lable> : this.props.billingTemplate ? lang.utility.text_balance_negative : <lable>{lang.utility.text_balance_negative}</lable>) : this.props.billingTemplate ? lang.utility.text_balance_positive : <lable>{lang.utility.text_balance_positive}</lable>)
            :
            ((this.props.selectedTenantData && typeof this.props.selectedTenantData.prepayments  === 'number') ? (((commercialTenantWithVatSwitch && !isPreTaxInfoHidden) ? creditAmount >= 0 : (this.props.selectedTenantData.prepayments - this.calculateTotal(index, false))  >= 0) ? lang.utility.text_balance_positive : lang.utility.text_balance_negative) : lang.utility.text_balance_positive)
            } {commercialTenantWithVatSwitch && '(brutto)'}
          </TableCell>
          <TableCell style={{...tableBodyCellStyling, ...{fontWeight: 'bold', textAlign: 'right', fontSize: messagePreview ? '7pt' : '14px', height: messagePreview ? '0px' : '48px', padding: messagePreview ? '1mm' :  '0 9px 0 9px', lineHeight: '11pt'}}} colSpan={1}>
          {this.props.billingTemplate ?
            (commercialTenantWithVatSwitch ?
            (this.props.invoiceData
              ? accounting.formatMoney(creditAmount, '€', 2, '.', ',', '%v %s')
              : '0,00€')
            :
            (this.props.invoiceData
              ? formatNumber(this.props.invoiceData.totalAdvancePayments - this.props.invoiceData.totalAllocatedUtilityCosts, true)
              : '0,00€'))
          :
            (commercialTenantWithVatSwitch ?
            (this.props.selectedTenantData
              ? accounting.formatMoney(creditAmount, '€', 2, '.', ',', '%v %s')
              : '0,00€')
            :
            (this.props.selectedTenantData
              ? formatNumber(this.props.selectedTenantData.prepayments - this.calculateTotal(index, false), true)
              : '0,00€'))
          }
            {/* {this.props.selectedTenantData
            ? Autoload.MainHelper.ConverterCurrencyDe_DisplayOnly(this.props.selectedTenantData.prepayments - this.props.selectedTenantData.totalAllocations, false) + ' €'
            : '0,00 €'} */}
          </TableCell>
          <TableCell style={{...tableBodyCellStyling, ...{fontWeight: 'bold', //borderBottom: this.props.selectedTenantData.tenantRows.length === (index + 1) && '1px solid #DADAE6', 
          borderRight: this.props.billingTemplate && '1px solid #DADAE6', fontSize: messagePreview ? '7pt' : '14px', height: messagePreview ? '0px' : '48px', padding: messagePreview ? '1mm' :  '0 9px 0 9px', lineHeight: '11pt'}}} colSpan={1}/>
        </TableRow>

        {this.props.laborCostSwitch && (this.props.billingTemplate ? (this.props.invoiceData.tenantOccupation.contractType === 'Default' || this.props.invoiceData.tenantOccupation.contractType === 'private' || this.props.invoiceData.tenantOccupation.contractType === 'self') : (this.props.selectedTenantData.tenantOccupation.contractType === 'Default' || this.props.selectedTenantData.tenantOccupation.contractType === 'private' || this.props.selectedTenantData.tenantOccupation.contractType === 'self')) &&
        <TableRow>
          <TableCell style={{...tableBodyCellStyling, ...{textAlign: 'right', fontSize: messagePreview ? '7pt' : '14px', height: messagePreview ? '0px' : '48px', padding: messagePreview ? '1mm' :  '0 9px 0 9px', lineHeight: '11pt'}}} colSpan={6}>
            enthaltene Lohnkosten nach §35a EStG
          </TableCell>
          <TableCell style={{...tableBodyCellStyling, ...{fontWeight: 'bold', textAlign: 'right', fontSize: messagePreview ? '7pt' : '14px', height: messagePreview ? '0px' : '48px', padding: messagePreview ? '1mm' :  '0 9px 0 9px', lineHeight: '11pt'}}} colSpan={1}>
            {this.props.billingTemplate ? (this.props.invoiceData.totalLaborCost ? formatNumber(this.props.invoiceData.totalLaborCost, true) : '0,00 €') : (this.props.selectedTenantData.totalLaborCost ? formatNumber(this.props.selectedTenantData.totalLaborCost, true) : '0,00 €')}
          </TableCell>
          <TableCell style={{...tableBodyCellStyling, ...{fontWeight: 'bold', //borderBottom: this.props.selectedTenantData.tenantRows.length === (index + 1) && '1px solid #DADAE6', 
          borderRight: this.props.billingTemplate && '1px solid #DADAE6', fontSize: messagePreview ? '7pt' : '14px', height: messagePreview ? '0px' : '48px', padding: messagePreview ? '1mm' :  '0 9px 0 9px', lineHeight: '11pt'}}} colSpan={1}/>
        </TableRow>}
        </>);
      }
    render(){
      const {utilityData, utilityBillingData, messagePreview} = this.props
      console.log('utilityData, utilityBillingData: ', utilityData, utilityBillingData);
      console.log('messagePreview: ', messagePreview);
        return (
        this.props.selectedTenantData &&
        this.props.selectedTenantData.tenantRows &&
        this.props.selectedTenantData.tenantRows.length ?
        <TableContainer component={Paper} style={{...this.props.style, ...{background: 'transparent'}}}>
        <Table style={{width: messagePreview ? '100%' : this.props.billingTemplate && 'auto', //borderBottom: '1px solid #DADAE6', 
        wordBreak: messagePreview && !window.location.href.includes('billingStatement2/') && 'break-word',
        borderCollapse: 'collapse'
        }} id={'ub-billing-statement-table'}>
          {this.props.billingTemplate ?
          <TableHead style={{borderRight: '1px solid #DADAE6', borderBottom: 'none', borderTop: 'none', borderLeft: 'none'}}>
            {this.props.tableCells ? this.props.tableCells : this.getTableLabels()}
          </TableHead>
          :
          <TableHead style={{border: '1px solid #DADAE6'}}>
            {this.props.tableCells ? this.props.tableCells : this.getTableLabels()}
          </TableHead>}
          <TableBody style={{background: 'white', border: '1px solid #DADAE6'}}>
            {this.props.selectedTenantData.tenantRows.sort((a,b)=> a.orderNumber - b.orderNumber).map((billRow, index) => {
                if(this.props.warmWaterCostTypeFound){
                  if(billRow.costType === 'Warmwasser (Verbrauch)'){
                    return (
                      <>
                      <UtilityBillsTableRow
                        messagePreview={messagePreview}
                        tenantID={this.props.selectedTenantData?.tenantID}
                        billRow={billRow}
                        updateCommentLocalState={this.props.updateCommentLocalState}
                        isSecured={utilityData.isSecured}
                        billingTemplate={this.props.billingTemplate}
                      />
                      {this.heatingRow(index)}
                      {index === (this.props.selectedTenantData.tenantRows.length -1) && this.utilityTotalRow(index)}
                      </>
                    );

                  }
                  else{
                    if(index === (this.props.selectedTenantData.tenantRows.length -1)){
                      return (
                        <>
                        <UtilityBillsTableRow
                          messagePreview={messagePreview}
                          tenantID={this.props.selectedTenantData?.tenantID}
                          billRow={billRow}
                          updateCommentLocalState={this.props.updateCommentLocalState}
                          isSecured={utilityData.isSecured}
                          billingTemplate={this.props.billingTemplate}
                        />
                        {this.utilityTotalRow(index)}
                        </>
                      );
                    }else{
                      return (
                        <UtilityBillsTableRow
                          messagePreview={messagePreview}
                          tenantID={this.props.selectedTenantData?.tenantID}
                          billRow={billRow}
                          updateCommentLocalState={this.props.updateCommentLocalState}
                          isSecured={utilityData.isSecured}
                          billingTemplate={this.props.billingTemplate}
                        />
                      );
                    }
                  }
                }
                else {
                  if(billRow.costType === 'Heizung (Verbrauch)'){
                  return (
                    <>
                    <UtilityBillsTableRow
                      messagePreview={messagePreview}
                      tenantID={this.props.selectedTenantData?.tenantID}
                      billRow={billRow}
                      updateCommentLocalState={this.props.updateCommentLocalState}
                      isSecured={utilityData.isSecured}
                      billingTemplate={this.props.billingTemplate}
                    />
                    {this.heatingRow(index)}
                    {index === (this.props.selectedTenantData.tenantRows.length -1) && this.utilityTotalRow(index)}
                    </>
                  );

                }else{
                  if(index === (this.props.selectedTenantData.tenantRows.length -1)){
                    return (
                      <>
                      <UtilityBillsTableRow
                        messagePreview={messagePreview}
                        tenantID={this.props.selectedTenantData?.tenantID}
                        billRow={billRow}
                        updateCommentLocalState={this.props.updateCommentLocalState}
                        isSecured={utilityData.isSecured}
                        billingTemplate={this.props.billingTemplate}
                      />
                      {this.utilityTotalRow(index)}
                      </>
                    );
                  }else{
                    return (
                      <UtilityBillsTableRow
                        messagePreview={messagePreview}
                        tenantID={this.props.selectedTenantData?.tenantID}
                        billRow={billRow}
                        updateCommentLocalState={this.props.updateCommentLocalState}
                        isSecured={utilityData.isSecured}
                        billingTemplate={this.props.billingTemplate}
                      />
                    );
                  }
                }}
            })}
          </TableBody>
        </Table>
      </TableContainer>
      :
      <div style={{background: 'white', padding: '40px'}}>
        <EmptyStateContainer styleMainContainer={{marginTop: '0px'}} heading={'Es konnte noch keine Betriebskostenabrechnung erstellt werden.'} subheading={'Gehen Sie zurück und legen Sie Kostenpositionen an, die hier angezeigt werden sollen.'} icon={tap} showBtn={true} btnText={'Zurück gehen'} btnHandler={()=> this.props.goBack()} />
      </div>
      );    
    }
}

const mapStateToProps = (state) => {
  // console.log('stateee', state.UtilityBillingReducer)
  return {
    // allUtilityBills: state.UtilityBillingReducer.utilityBillingData,
    allReducer: state,
    currentDataId: state.UtilityBillingReducer.currentBillingId,
    tenantBills: state.UtilityBillingReducer.utilityBillingData,
    utilityBillingData: state.UtilityBillingReducer.utilityBillingData,
    heatingFilter: state.UtilityBillingReducer.HeatingAllocationFilter,
    currentSubscription: state.generalReducer.currentSubscription,
  };
};

const mapDispatchToProps = {
  setTenantBills,
};

export default connect(mapStateToProps, mapDispatchToProps)(UtilityBillTable);

