import React, { Component } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import Receipt from '../../../../../assets/images/module/general/ReceiptDummy.png';
import { Row, Col, Form, Tooltip, OverlayTrigger } from 'react-bootstrap';
import CustomModal from '.';
import { showNotification } from '@mantine/notifications';
import ColorButton from '../Buttons/ColorButton';
import CopyIcon from '../../../SVGs/copyIcon';
import GreenTick from '../../../../../assets/images/module/general/GreenTick.svg';
import { Checkbox, Group, Stack, TextInput } from '@mantine/core';
import EmptyStateContainer from '../EmptyStateContainer/EmptyStateContainer';
import TenantIconBlue from '../../../../../assets/images/module/general/TenantIconBlue.svg';
import { NumericFormat } from 'react-number-format';

const MOBILE_SIZE = 992;
const TABLET_SIZE = 1200;

export class DisplayModalForMobileView extends Component {
  state = {
    isMobile: window.innerWidth <= MOBILE_SIZE,
    isTablet: window.innerWidth <= TABLET_SIZE
  };
  
  constructor(props) {
    super(props);
  }

//   componentDidMount(){
//     this.setState({...this.props.state});
//   }

//   componentDidUpdate(prevProps){
//     if(JSON.stringify(prevProps.state) !== JSON.stringify(this.props.state)){
//         this.setState({...this.props.state});
//     }
//   }
  
  render() {
    const { open, toggle, className, update, heading, headingClassName, content } = this.props;
    
    return (
      <CustomModal
        heading={heading}
        headingClassName={headingClassName}
        open={open}
        toggle={() => {
          toggle();
        }}
        className={`${className}`}
      >
        <Stack>
            <Group>
              {content}
            </Group>
            <Group position='apart'>
                <div>
                    <ColorButton
                    text={'Schließen'}
                    className='gray-btn'
                    onClick={()=> toggle()}
                    />
                </div>
            </Group>
        </Stack>
      </CustomModal>
    );
  }
}

export default DisplayModalForMobileView;
