import React, { Component } from 'react';
import { Container, Row, Col, Form } from 'react-bootstrap';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { ColorButton } from '../../../../index';
import CustomModal from './index';
import Autoload from '../../../../Autoload';
import AddAllocationKeyModal from './AddAllocationKeyModal.js';
import downArrow from '../../../../../assets/images/module/general/SelectArrowDown.svg';
import uparrow from '../../../../../assets/images/module/general/SelectArrowUp.svg';
import { connect } from 'react-redux';
import GlobalServices from '../../../../helpers/services/GlobalServices';
import GeneralDataHandler from '../../../../helpers/DataHandler/General';
import { showNotification } from '@mantine/notifications';
import RedCross from '../../../../../assets/images/module/general/RedCross.svg';
import GreenTick from '../../../../../assets/images/module/general/GreenTick.svg';
import FixedAllocationKeyModal from './FixedAllocationKeyModal';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
var { checkProperty } = Autoload.MainHelper;

class AddCostTypeModal extends Component {
  initialState = {
    name: '',
    nameErrMsg: false,
    AddAllocationKeyErrMsg: false,
    allocationKeyValue: '',
    arrow: true,
    AddAllocationKeyModal: false,
    allocationKeyID: null,
    DropdownState: 'true',
    allocationKeys: [],
    units: null,
    allocationKeyType: '',
    openFixedAllocationKeyModal: false,
    selectedAllocationKeyType: '',
    selectedAllocationId: null,
    selectedAllocationUnits: '',
    selectedAllocationKeyName: ''
  };
  //
  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  componentDidMount() {
    this.getCostTypes();
  }

  getCostTypes() {
    if (this.props.edit === true) {
      Autoload.HttpRequestAxios.HttpRequest(
        Autoload.Setting.property_api_base_url + 'CostTypes/' + this.props.record.id,
        'GET',
        null,
        null,
        false,
        true,
        false,
        this,
        '_handle_get_costtype_by_id',
        null,
        false,
        '',
        {
          'Content-Type': 'application/json',
          Authorization: Autoload.Utils.getAuthToken(),
        }
      );
    }
    this.getAllocationKeys();
  }

  getAllocationKeys() {
    this.props.propertySelected && GlobalServices._getAllocationKeysByProperty(this, '_handleAllocationKeyResponse', this.props.propertySelected);
  }

  _handle_get_costtype_by_id(response) {
    console.log(response, 'cost type by id');
    if (response.status === true) {
      this.setState({
        name: checkProperty(response.result, 'costTypeName'),
        allocationKeyID: checkProperty(checkProperty(response.result, 'allocationKey'), 'id'),
        allocationKeyValue: checkProperty(checkProperty(response.result, 'allocationKey'), 'allocationKeyName'),
        allocationKeyType: checkProperty(response.result, 'allocationKeyType'),
        units: checkProperty(checkProperty(response.result, 'allocationKey'), 'units'),
        costTypeIdentifier: checkProperty(response.result, 'costTypeIdentifier'),
      });
      // console.log(this.state.allocationKeyType,'abc')
    }
  }

  _handleAllocationKeyResponse(response) {
    //  const {allocationKeyType}=this.state;
    console.log('_handleAllocationKeyResponse', response);
    if (response.status === true && response.statuscode === 401) {
      localStorage.clear();
      window.location.reload();
    }

    if (response.status === true && response.statuscode === 200) {
      const allocationKeys = GeneralDataHandler.getAllocationKeys(response.result).sort((a,b)=> a.allocationKeyName.toLowerCase().localeCompare(b.allocationKeyName.toLowerCase()));
      console.log(allocationKeys, this.state.allocationKeyType, 'allocation keys cost modal');

      //for add logic only non-heating allowed
      var addNonHeatingAllocationKeys = allocationKeys.filter((keys) => (keys.allocationKeyType === 'NON_HEATING' && keys.meterType === 'RENTAL_UNIT') || keys.allocationKeyType === 'NON_HEATING_INDIVIDUAL');
      // console.log(addNonHeatingAllocationKeys,'== add non heating allocation keys ===')

      //for edit logic
      // const result=this.props.record;
      // console.log(result,'result this.props.costTypeData')
      console.log('this.state.costTypeIdentifier: ', this.state.costTypeIdentifier);
      if (this.props.edit === false) {
        //add
        this.setState({ allocationKeys: addNonHeatingAllocationKeys });
      } else {
        //edit
        const data = allocationKeys.filter((keys) => (this.state.costTypeIdentifier === 'WARMWATER_BASIS' || this.state.costTypeIdentifier === 'ROOM_HEATING_BASIS') ? ((keys.allocationKeyName === 'Wohnfläche' || keys.allocationKeyName === 'Anzahl Wohnungen'|| keys.allocationKeyValueType === 'FIXED_TENANT' || keys.allocationKeyValueType === 'FIXED_RENTAL_UNIT') && keys.allocationKeyType !== 'NON_HEATING_INDIVIDUAL') : (this.state.costTypeIdentifier === 'WARMWATER_CONSUMPTION' || this.state.costTypeIdentifier === 'ROOM_HEATING_CONSUMPTION') ? (keys.allocationKeyType === this.state.allocationKeyType && keys.meterType !== 'PROPERTY') : ((keys.allocationKeyType === 'NON_HEATING' && keys.meterType === 'RENTAL_UNIT') || keys.allocationKeyType === 'NON_HEATING_INDIVIDUAL'));//keys.allocationKeyType === this.state.allocationKeyType);
        this.setState({ allocationKeys: data === '' ? '-' : data });
      }

      // const ROOM_HEATING = result.filter((keys)=>keys.allocationKeyType === 'ROOM_HEATING');
      // const NON_HEATING = result.filter((keys)=>keys.allocationKeyType === 'NON_HEATING');
      // const NONE= result.filter((keys)=>keys.allocationKeyType === 'NONE');
      // const WARMWATER = result.filter((keys)=>keys.allocationKeyType === 'WARMWATER');
      // console.log(ROOM_HEATING,'ROOM_HEATING')
      // console.log(NON_HEATING,'NON_HEATING')
      // console.log(NONE,'NONE')
      // console.log(WARMWATER,'WARMWATER')

      // this.props.edit?this.setState({allocationKeys:keys}):this.setState({ allocationKeys:addNonHeatingAllocationKeys });
      // this.setState({ allocationKeys:allocationKeys })
      // console.log(allocationKeys,'allocationKeys')
    }
  }

  resetForm = () => {
    this.setState({ ...this.initialState });
    // this.props.toggle();
  };

  validateForm = () => {
    this.setState({
      nameErrMsg: false,
      AddAllocationKeyErrMsg: false,
    });
    let nameErrMsg,
      AddAllocationKeyErrMsg = false;
    //const validNum = /^[0-9]/;
    // //const validName = /^[a-zA-Z]+(?:[\s]+[a-zA-Z]+)*$/;
    // const validName=/[^a-zA-Z0-9äöüÄÖÜß]/;
    if (!this.state.name) {
      nameErrMsg = true;
    }
    if (!this.state.allocationKeyValue || this.state.allocationKeyValue === lang.newKeys.text_select) {
      AddAllocationKeyErrMsg = true;
    }
    this.setState({ nameErrMsg, AddAllocationKeyErrMsg });
    if (nameErrMsg || AddAllocationKeyErrMsg) {
      return false;
    } else {
      return true;
    }
  };

  showDuplicateNameError=()=>{
    this.setState({nameErrMsg: true},()=> 
      showNotification({
        message: 'Unter dieser Bezeichnung existiert bereits eine Kostenart. Bitte wählen Sie einen anderen Namen.',
        icon: <img src={RedCross} alt='red-cross'/>,
        id: 'costTypeDuplicateError'
      })
    );
  }

  handleSubmit = (e) => {
    e.preventDefault();
    let isValid = this.validateForm();
    if (isValid) {
      //this.resetForm();
      // this.props.toggle();
      {
        if (this.props.edit) {
          let count = 0;
          this.props.showCostData.map((costType)=> costType.costTypeName === this.state.name ? count+=1 : count);
          console.log('count: ', count);
          if(count < 2){
            this.saveChanges();
          }else{
            this.showDuplicateNameError();
          }
        } else {
          if(this.props.showCostData.find((costType)=> costType.costTypeName === this.state.name) === undefined){
            this.addData();
          }else{
            this.showDuplicateNameError();
          }
        }
      }
    }
  };

  saveChanges = () => {
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'block';
    const editData = JSON.stringify({
      id: this.props.record.id,
      costTypeName: this.state.name,
      allocationKeyId: this.state.allocationKeyID,
      // propertyId: this.props.propertySelected.id,
      // systemCostType: true,
      // allocationKey: {
      //   allocationKeyId: this.state.allocationKeyID,
      //   allocationKeyName: this.state.allocationKeyValue,
      //   propertyId: this.props.propertySelected.id,
      //   units: this.state.units,
      //   metered: true,
      //   systemAllocationKey: true,
      // },
      // useInReceiptMgmt: true,
      // heatingCostType: true,
    });

    let add_btn = document.getElementById('costtype_add_btn');
    let cancel_btn = document.getElementById('costtype_cancel_btn');
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'CostTypes/' + this.props.record.id,
      'PUT',
      editData,
      add_btn.children[0],
      false,
      true,
      false,
      this,
      '_handle_update_costtype',
      { cancelbtn: cancel_btn.children[0] },
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };

  addData = () => {
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'block';
    const data = {
      id: null,
      costTypeName: this.state.name,
      propertyId: this.props.propertySelected.id,
      systemCostType: true,
      allocationKey: {
        allocationKeyId: this.state.allocationKeyID,
        allocationKeyName: this.state.allocationKeyValue,
        propertyId: this.props.propertySelected.id,
        units: this.state.units,
        metered: true,
        systemAllocationKey: true,
      },
      useInReceiptMgmt: true,
      heatingCostType: true,
      costTypeIdentifier: 'NON_HEATING',
      allocationKeyType: 'NON_HEATING',
    };
    let add_btn = document.getElementById('costtype_add_btn');
    let cancel_btn = document.getElementById('costtype_cancel_btn');
    console.log('addCostType: ', data);
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'CostTypes',
      'POST',
      data,
      add_btn.children[0],
      false,
      true,
      false,
      this,
      '_handle_add_costtype',
      { cancelbtn: cancel_btn.children[0] },
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };

  _handle_add_costtype(response) {
    console.log('_handle_add_costtype', response);
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'none';
    if(response.status === true && response.statuscode === 200){
      showNotification({
        message: 'Die neue Kostenart wurde erfolgreich angelegt.',
        icon: <img src={GreenTick} alt='green-tick'/>,
      });
      this.props.callBackFunction(response.result.id);
    } else {
      showNotification({
        message: 'Es ist ein Fehler aufgetreten. Bitte probieren Sie es erneut oder wenden Sie sich an den Support.',
        icon: <img src={RedCross} alt='red-cross'/>,
      });
      this.props.callBackFunction();
    }
     //parent.getCostTypes();
    this.props.toggle();
  }

  _handle_update_costtype(response) {
    console.log(response, '_handle_update_costtype');
    // this.props.parent.bind_list_cost_type_data();
    this.props.callBackFunction(); //parent.getCostTypes();
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'none';
    if(response.status === true && response.statuscode === 200){
      showNotification({
        message: 'Die Änderungen wurden übernommen.',
        icon: <img src={GreenTick} alt='green-tick'/>,
      });
    } else {
      showNotification({
        message: 'Es ist ein Fehler aufgetreten. Bitte probieren Sie es erneut oder wenden Sie sich an den Support.',
        icon: <img src={RedCross} alt='red-cross'/>,
      });
    }
    this.props.toggle();
    response.other.cancelbtn.click();
  }

  toggleAddAllocationKeyModal = () => {
    this.setState({ AddAllocationKeyModal: !this.state.AddAllocationKeyModal });
    // var loader = document.getElementById('hello-housing-loader');
    // loader.style.display = 'block';
    // this.props.toggle();
  };

  selectNewAllocationKey(newKeyDetails) {
    if (newKeyDetails && newKeyDetails.status && Object.keys(newKeyDetails.result).length > 0) {
      this.setState({
        allocationKeyValue: newKeyDetails.result.allocationKeyName,
        allocationKeyID: newKeyDetails.result.id,
        units: newKeyDetails.result.units,
      });
    }
  }

  handleKeyPress = (event) => {
    if (event.key == 'Enter') {
      // console.log('abc 2 key');
    }
  };

  handleEnterKeyPress = (e) => {
    if (e.charCode == 13) {
      e.preventDefault();
      this.handleSubmit(e);
    }
  };

  render() {
    const { open, toggle, className, edit, record } = this.props;
    const { allocationKeys, name, allocationKeyValue, nameErrMsg, openFixedAllocationKeyModal, selectedAllocationId, selectedAllocationKeyName, selectedAllocationKeyType, selectedAllocationUnits, units } = this.state;
    console.log(open, edit, ' add edit in cost type');
    return (
      <div className="costtype-modal">
        <CustomModal
          heading={edit ? lang.newKeys.text_edit_cost_type : 'Kostenart hinzufügen'}
          open={open}
          toggle={() => {
            this.resetForm();
            toggle();
          }}
          className={`${className}`}
        >
          <Form
            onSubmit={(e) => this.handleSubmit(e)}
            // onKeyDown={this.onKeyDownHandler}
            // onKeyPress={this.handleKeyPress}
          >
            <Container>
              <Row>
                <Col xs={12} md={12}>
                  <Form.Group>
                    <Form.Label className="property-modal-label">{lang.general.text_name}</Form.Label>
                    <Form.Control
                      isInvalid={nameErrMsg}
                      type="text"
                      placeholder=""
                      // init
                      value={name}
                      onChange={(e) =>
                        this.setState({
                          name: e.target.value,
                        })
                      }
                      disabled={edit && record && record.systemCostType}
                      onKeyPress={this.handleEnterKeyPress}
                    />
                    <Form.Control.Feedback className="err" type="invalid">
                      {lang.property.generalinfo_propertynameError}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col xs={12} md={12}>
                  <Form.Group>
                    <Form.Label className="property-modal-label">{lang.property.text_allocation_key}</Form.Label>
                    <UncontrolledDropdown
                      id="keyid"
                      onClick={() => {
                        this.setState({
                          DropdownState: document
                            .getElementById('keyid')
                            .firstElementChild.getAttribute('aria-expanded'),
                        });
                      }}
                      onBlur={() => {
                        this.setState({
                          DropdownState: document
                            .getElementById('keyid')
                            .firstElementChild.getAttribute('aria-expanded'),
                        });
                      }}
                      onFocus={() => {
                        this.setState({
                          DropdownState: document
                            .getElementById('keyid')
                            .firstElementChild.getAttribute('aria-expanded'),
                        });
                      }}
                    >
                      <DropdownToggle
                        className="property-single_dropdown-allocation-modal"
                        style={{
                          border: this.state.AddAllocationKeyErrMsg === true ? '1px solid #dc3545' : null,
                        }}
                      >
                        <span className="cost-modal-value">
                          {allocationKeyValue === '' ? lang.newKeys.text_select : `${allocationKeyValue} ${(units && units !== '-') ? `(${units})` : ''}`}
                        </span>

                        <div>
                          {this.state.DropdownState === 'true' ? (
                            <img src={downArrow} alt="" />
                          ) : (
                            <img src={uparrow} alt="" />
                          )}
                        </div>
                      </DropdownToggle>
                      <DropdownMenu
                        className={`property-single-dropdown-menu  ${
                          allocationKeys.length === 2
                            ? 'two-allocationKey'
                            : allocationKeys.length === 3
                            ? 'three-allocationKey'
                            : 'four-allocationKey'
                        }`}
                      >
                        {allocationKeys.length > 0 &&
                          allocationKeys.map((key, index) => (
                            <DropdownItem
                              // disabled={edit && record && record.systemCostType}
                              key={index}
                              className="px-0 "
                              onClick={() => {
                                this.setState({
                                  allocationKeyValue: key?.allocationKey?.allocationKeyName || key.allocationKeyName,
                                  allocationKeyID: key?.allocationKey?.id || key.id,
                                  units: key?.allocationKey?.units || key.units,
                                });
                              }}
                            >
                              <span className="property-single-dropdown-options ">
                                {`${key?.allocationKey?.allocationKeyName || key?.allocationKeyName} ${((key?.allocationKey?.units && key?.allocationKey?.units !== '-') ? `(${key?.allocationKey?.units})` : '') || ((key?.units && key?.units !== '-') ? `(${key?.units})` : '')}`}
                              </span>
                            </DropdownItem>
                          ))}

                        <DropdownItem className="example allocation-cost-type-modal">
                          <ColorButton
                            onClick={() => {
                              this.toggleAddAllocationKeyModal();
                            }}
                            text={lang.property.allocationKeys_text_add_allocationkeys}
                            className="add-allocationkey-dropdown-btn"
                          />
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    <div className="red-error-msg">
                      {this.state.AddAllocationKeyErrMsg === true ? <p>{lang.newKeys.text_select_error_msg}</p> : null}
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              <div className="cancel_addbtn">
                <div id="costtype_cancel_btn">
                  <ColorButton
                    onClick={(e) => {
                      e.preventDefault();
                      this.resetForm();
                      this.props.toggle();
                    }}
                    className="gray-btn"
                    text={lang.general.text_cancel}
                  />
                  {/* <ColorButton onClick={() => edit?this.resetForm():null }  className="gray-btn" text={lang.general.text_cancel} /> */}
                </div>
                <div id="costtype_add_btn">
                  <ColorButton
                    buttonConfig={{ type: 'submit' }}
                    className="purple-btn"
                    text={edit ? lang.newKeys.text_save_cost_type : 'Hinzufügen'}
                  />
                  {/* <ColorButton buttonConfig={{ type: 'submit' }} className="purple-btn" text={edit?"Save Cost Type":"Add Cost Type"} /> */}
                </div>
              </div>
            </Container>
          </Form>
        </CustomModal>
        {this.state.AddAllocationKeyModal && (
          <AddAllocationKeyModal
            record={null}
            className="add_allocation_key_modal"
            edit={false}
            open={this.state.AddAllocationKeyModal}
            toggle={this.toggleAddAllocationKeyModal}
            allocationData={allocationKeys}
            setInCostTypeDropDown={(id, name) => this.setState({ allocationKeyValue: name, allocationKeyID: id })}
            parent={this}
            callBackFunction={(response) => {
              this.getAllocationKeys();
              this.selectNewAllocationKey(response);
            }}
            selectedType={(type, id, units, name)=> (type === 'FIXED_RENTAL_UNIT' || type === 'FIXED_TENANT') && this.setState({selectedAllocationKeyType: type, openFixedAllocationKeyModal: true, selectedAllocationId: id, selectedAllocationUnits: units, selectedAllocationKeyName: name, newAllocationKey: true})}
          />
        )}
        {openFixedAllocationKeyModal &&
          <FixedAllocationKeyModal
          open={openFixedAllocationKeyModal}
          className={'individual-allocation-key-modal'}
          type={selectedAllocationKeyType}
          allocationKeyId={selectedAllocationId}
          toggle={()=> this.setState({openFixedAllocationKeyModal: false, selectedAllocationKeyType: '', selectedAllocationKeyName: '', selectedAllocationUnits: '', selectedAllocationId: ''})}
          unit={selectedAllocationUnits}
          name={selectedAllocationKeyName}
          history={this.props.history}
          />}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    propertySelected: state.generalReducer.property,
  };
};

export default connect(mapStateToProps)(AddCostTypeModal);
