import List from '../../../views/pages/Module/TenantPortal/List';


const pageList = [
  {
    name: 'TenantPortal List',
    path: '/tenantPortal/',
    component: List,
  },
 
];

export default pageList;
