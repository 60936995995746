import { v4 as uuidv4 } from 'uuid';
import { Image, Stack, Box, Text } from '@mantine/core';
import SwiperCore, { Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Used solution from here because those from official docs doesn't work: https://stackoverflow.com/a/69357137/15572548
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';

// v6 docs here: https://swiper6.vercel.app/swiper-api
SwiperCore.use([Pagination, Autoplay]);

const SignUpSlider = () => {
  const secondSlideData = [
    {
      icon: 'icon-shield',
      heading: 'Datenschutz-konform',
      text: 'hellohousing erfüllt die Vorgaben der DSGVO.'
    },
    {
      icon: 'icon-lock',
      heading: 'Hohe Datensicherheit',
      text: 'Daten werden SSL-verschlüsselt übermittelt und sicher aufbewahrt.'
    },
    {
      icon: 'icon-no-hidden-costs',
      heading: 'Keine versteckten Gebühren',
      text: 'Dauerhaft kostenlose Basisversion mit vollem Funktionsumfang.'
    },
    {
      icon: 'icon-independent',
      heading: 'Anbieter-unabhängig',
      text: 'Wir sind unabhängig von Ablesefirmen und anderen Immobiliendienstleistern.'
    }
  ];
  
  return (
    <Box sx={(theme) => ({ position: "relative", height: "100%", color: theme.colors.brandDarkText, backgroundColor: theme.colors.brandLight, "&:before": { content: '""', position: "absolute", top: 0, right: "100%", bottom: 0, height: "100%", width: "50vw", backgroundColor: "inherit" }  })}>
      <Swiper style={{ height: "100%" }} pagination={{ clickable: true }} autoplay={{ delay: 7500, pauseOnMouseEnter: true }}>
        
        {/* Slide 1 */}
        <SwiperSlide style={{ height: "100%" }}>
          <Stack sx={() => ({ height: "100%" })} align="start" justify="center">
            <Image src={`${process.env.PUBLIC_URL}/slide-1.png`} />
            <Text component="h3" size="xl" sx={{ marginLeft: 20, lineHeight: 1.1 }} weight={700}>Immobilien einfach online verwalten</Text>
          </Stack>
        </SwiperSlide>
        
        {/* Slide 2 */}
        <SwiperSlide>
          <Stack sx={() => ({ height: "100%", paddingRight: 100 })} spacing={48} align="start" justify="center">

            {secondSlideData.map(item => (
              <Stack key={uuidv4()} spacing={8}>
                <Image width={44} src={`${process.env.PUBLIC_URL}/icons/${item.icon}.svg`} />
                <Text weight={700} size="md">{item.heading}</Text>
                <Text size="sm">{item.text}</Text>
              </Stack>
            ))}
            
          </Stack>
        </SwiperSlide>
        
        {/* Slide 3 */}
        <SwiperSlide>
          <Stack sx={() => ({ height: "100%" })} align="end" justify="center">
            <Image src={`${process.env.PUBLIC_URL}/images/slide-3.png`} />
          </Stack>
        </SwiperSlide>
        
      </Swiper>
    </Box>
  )
}

export default SignUpSlider;