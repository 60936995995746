import React from 'react';
import { ColorButton } from '../../../../index';
import '../../../../scss/components/emptyState.scss';
import Help from '../../../../../assets/images/module/general/Help.svg';

const EmptyStateDashboard = ({
  heading,
  subheading,
  icon,
  buttonDisable = false,
  blueButtonDisable = false,
  btnHandler,
  btnText,
  className,
  showBtn,
}) => {
  return (
    <div>
      <div className={`emptystate-dashboard ${className}`}>
        <div className="main-container-dashbaord">
          <div className="empty-state-icon-dashbaord" >
            <img src={icon} style={{background: 'white', padding: '14px', borderRadius: '4px'}} width={'44'} height={'44'} alt="empty"/>
          </div>
          <h4 className="empty-state-heading-dashbaord">{heading}</h4>
          <h5 className="empty-state-subheading-dashbaord">{subheading}</h5>

          {buttonDisable && (
            <ColorButton
              text={btnText}
              onClick={() => {
                btnHandler();
              }}
              className="gray-btn"
            />
          )}

          {blueButtonDisable && (
            <ColorButton
              text={btnText}
              onClick={() => {
                btnHandler();
              }}
              className="purple-btn"
            />
          )}
        </div>
      </div>
    </div>
  );
};

EmptyStateDashboard.defaultProps = {
  heading: '',
  subheading: '',
  icon: Help,
  btnHandler: () => {
    // console.log('btn empty state');
  },
  btnText: 'Add',
  showBtn: true,
};
export default EmptyStateDashboard;
