import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { PageContent } from '../../../neoverv';
import ContextProviders from '../../../neoverv/components/Module/General/utilities/ContextProviders';
import Login from '../../../views/pages/Module/General/login/Login';
import Signup from '../../../views/pages/Module/General/signup/Signup';
import ResetPassword from '../../../views/pages/Module/General/forgotpassword/ResetPassword';
import PaymentVerification from '../../../views/pages/Module/General/signup/PaymentVerification';
import Forgotpassword from '../../../views/pages/Module/General/forgotpassword/Forgotpassword';
import UtilityBillingTemplate from '../../../views/pages/Module/UtilityBilling/UtilityBillingTemplate';
import InvoiceTemplate from '../../../views/pages/Module/General/profile/InvoiceTemplate';
export default class GeneralLayout extends Component {
  render() {
    return (
      <ContextProviders>
        <PageContent>
          <Switch>
            <Route path="/resetpassword" component={ResetPassword} />
            <Route path="/login" component={Login} />
            <Route path="/forgotpassword" component={Forgotpassword} />
            <Route path="/signup" exact component={Signup} />
            <Route path="/signup/verification" component={PaymentVerification} />
            <Route path="/signup/verification/:status" component={PaymentVerification} />
            {/*<Route path="/billingStatement/:id" exact component={UtilityBillingTemplate} /> Moved to /src/App.js */}
            <Route path={'/user/invoice/:id'} component={InvoiceTemplate} exact />
          </Switch>
        </PageContent>
      </ContextProviders>
    );
  }
}
