import React, { Component } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import Receipt from '../../../../../assets/images/module/general/ReceiptDummy.png';
import { Row, Col, Form, Tooltip, OverlayTrigger } from 'react-bootstrap';
import CustomModal from '.';
import { showNotification } from '@mantine/notifications';
import ColorButton from '../Buttons/ColorButton';
import CopyIcon from '../../../SVGs/copyIcon';
import GreenTick from '../../../../../assets/images/module/general/GreenTick.svg';
import { Checkbox, Group, Stack, TextInput } from '@mantine/core';
import EmptyStateContainer from '../EmptyStateContainer/EmptyStateContainer';
import TenantIconBlue from '../../../../../assets/images/module/general/TenantIconBlue.svg';
import { NumericFormat } from 'react-number-format';

const MOBILE_SIZE = 992;
const TABLET_SIZE = 1200;

export class DeleteMultipleDocumentsCofirmationModal extends Component {
  state = {
    isMobile: window.innerWidth <= MOBILE_SIZE,
    isTablet: window.innerWidth <= TABLET_SIZE
  };
  
  constructor(props) {
    super(props);
  }
  
  render() {
    const { open, toggle, className, numberOfFiles, next } = this.props;
    
    return (
      <CustomModal
        heading={'Belege löschen'}
        headingClassName={'delete-multiple-files-confirmation-modal-heading'}
        open={open}
        toggle={() => {
          toggle();
        }}
        className={`${className}`}
      >
        <Stack>
            <Group>
              <span style={{fontFamily: 'Inter, sans-serif', fontWeight: '400'}}>Sie haben {numberOfFiles} Belege ausgewählt. Sind Sie sicher, dass Sie die Belege unwiderruflich löschen wollen?</span>
            </Group>
            <Group position='apart'>
                <div>
                    <ColorButton
                    text={'Nein, behalten'}
                    className='gray-btn'
                    onClick={()=> toggle()}
                    />
                </div>
                <div>
                    <ColorButton
                    text={'Ja, löschen'}
                    className='red-btn'
                    onClick={()=> next()}
                    />
                </div>
            </Group>
        </Stack>
      </CustomModal>
    );
  }
}

export default DeleteMultipleDocumentsCofirmationModal;
