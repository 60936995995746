import React, { Component } from 'react';
import { 
  IconButton, 
  FormControl, 
  Select, 
  InputLabel, 
  MenuItem,
  Divider,
  Drawer,
  OutlinedInput
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import FilterListIcon from '@material-ui/icons/FilterList';
import DeleteIcon from '@material-ui/icons/Delete';
import { Box, Center, Group, Loader, Stack, Text, TextInput, Notification, Skeleton, Popover, Tooltip } from "@mantine/core";
import {
  ColorButton,
  ReceiptRecord,
  ReceiptDetailModal,
  AddPropertyModal,
  SearchContainer,
  SortContainer,
  SwitchContainer,
  FiltersContainer,
  ReceiptEmailModal,
  RecordOptions,
} from '../../../../neoverv';
import PageHeader from '../../../elements/module/general/PageHeader';
import PageHeaderNew from '../../../elements/module/general/PageHeaderNew';
import Autoload from '../../../../neoverv/Autoload';
import EmptyState from '../../../../neoverv/components/Module/General/EmptyStateContainer/EmptyStateContainer';
import EmptyIcon from '../../../../assets/images/EmptyReciept.svg';
import { connect } from 'react-redux';
import { setReceipts } from '../../../../redux/action/Receipt/ReceiptActions';
import ReceiptDataHandler from '../../../../neoverv/helpers/DataHandler/Receipt';
import GlobalServices from '../../../../neoverv/helpers/services/GlobalServices';
import GeneralDataHandler from '../../../../neoverv/helpers/DataHandler/General';
import MeterDataHandler from '../../../../neoverv/helpers/DataHandler/Meter';
import moment from 'moment';
import DownloadIcon from '../../../../neoverv/components/SVGs/downloadIcon';
import { downloadExcel } from 'react-export-table-to-excel';
import EmailIcon from '@material-ui/icons/Email';
import { CSVLink } from "react-csv";
import { showNotification } from '@mantine/notifications';
import RedTick from '../../../../assets/images/module/general/RedTick.svg';
import breadcrumbsContent from '../../../../assets/breadcrumbs.json';
import HelpLogo from '../../../../assets/images/module/general/book.svg';
import TourIcon from '../../../../assets/images/module/general/TourIcon.svg';
import GridIcon from '../../../../assets/images/module/general/Grid.svg';
import PdfIcon from '../../../../assets/images/module/general/PdfNewIcon.svg';
import XlsxIcon from '../../../../assets/images/module/general/XlsxIcon.svg';
import CsvIcon from '../../../../assets/images/module/general/CsvIcon.svg';
import AddPropertyTypeModal from '../../../../neoverv/components/Module/General/CustomModal/AddPropertyTypeModal';
import BuyPremiumModal from '../../../../neoverv/components/Module/General/CustomModal/BuyPremiumModal';
import { formatNumber } from '../../../../helpers/stringsManipulations';
import {setRefreshReceiptDocumentsData} from '../../../../redux/action/General/generalActions';
import { browserName } from "react-device-detect";
import RangeDatePicker from '../../../../neoverv/components/Module/General/CustomModal/DateRangePicker';
import dayjs from 'dayjs';
import accounting from 'accounting';
import { Button, ButtonGroup } from 'react-bootstrap';
import { Route } from 'react-router-dom';
import TenantRentalIncomeTracking from '../User/TenantRentalIncomeTracking';
import AddIcon from '@material-ui/icons/Add';
import BulkDownloadForReceiptsModal from '../../../../neoverv/components/Module/General/CustomModal/BulkDownloadForReceiptsModal';
import MultipleReceiptsUploadModal from '../../../../neoverv/components/Module/General/CustomModal/MultipleReceiptsUploadModal';
import DeleteMultipleDocumentsCofirmationModal from '../../../../neoverv/components/Module/General/CustomModal/DeleteMultipleDocumentsCofirmationModal';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
const { filterBySearchBar, filterBySwitchBtn, filterByDate, loader } = Autoload.MainHelper;
const TABLET_SIZE = 1200;
const MOBILE_SIZE = 800;

const routes = [
  {
    name: 'receiptsRecord',
    className: 'tooltips receipts-record',
    route: '/accounting/receipt',
    label: 'Belege',
    heading: lang.newKeys.text_heading_meter_readings,
  },
  {
    name: 'paymentsRecord',
    className: 'tooltips payments-record',
    route: '/accounting/payments',
    label: 'Finanzen',
    heading: 'Finanzen',
  }
];

class ReceiptsList extends Component {
  state = {
    addReceiptOpen: false,
    receiptsData: [],
    propertyData: [],
    filterdReceipts: [],
    searchValue: '',
    ascendingOrder: true,
    checkFilter: false,
    property_id: null,
    addPropertyModal: false,
    costTypesData: [],
    abc: [],
    ReceiptPage: true,
    openFilter: false,
    costTypeOptions: [],
    billingPeriods: [],
    selectedAllocation: '-',
    selectedMinCost: '',
    selectedMaxCost: '',
    selectedCostType: '-',
    selectedBillingPeriod: '-',
    selectedTaxCostType: '-',
    selectedPositiveNegativeAmounts: '-',
    selectedReceiptsDateRange: '-',
    checked: true,
    tableLabels: [
      {
        name: lang.receipt.text_receipt,
        property: 'name'
      },
      {
        name: 'Anhang',
        property: 'attachment'
      },
      {
        name: lang.receipt.text_amount_eur,
        property: 'costs'
      },
      {
        name: 'Kostenart Betriebskosten',
        property: 'cost_type'
      },
      {
        name: 'Steuerliche Kostenart',
        property: 'cost_type_tax'
      },
      {
        name: lang.receipt.text_date,
        property: 'receiptDate'
      },
      { 
        name: lang.receipt.text_time_frame,
        property: 'time_frame'
      },
      {
        name: 'Abgerechnet',
        property: 'utilityBillingAllocated'
      },
      {
        name: '',
        property: ''
      },
    ],
    selectedReceipts: [],
    emailOpen: false,
    csvBody: [],
    csvHeader: [],
    skeletonLoading: false,
    showArticleCondition: '',
    isMobile: window.innerWidth <= MOBILE_SIZE,
    isTablet: window.innerWidth <= TABLET_SIZE,
    addPropertyTypeModal: false,
    selectedPropertyType: '',
    memoryStatus: null,
    memoryStatusExceededPackageWise: false,
    usedSpaceInMB: null,
    openPaidModal: false,
    firstRowLoader: false,
    resetSelectedReceipts: false,
    taxCostTypeOptions: [
      // {label: 'Abschreibungen für GWG'},
      {label: 'Schuldzinsen'},
      {label: 'Geldbeschaffungskosten'},
      {label: 'Erhaltungsaufwendungen'},
      {label: 'Laufende Betriebskosten'},
      {label: 'Verwaltungskosten'},
      {label: 'Sonstige Kosten'},

      {label: 'Erstattete Vorauszahlungen'},
      {label: 'Erhaltene Nachzahlungen'},
      {label: 'Vereinnahmte Kautionen / Mieten aus Vorjahren'},
      {label: 'Vom Finanzamt erstattete Umsatzsteuer'},
      {label: 'Öffentliche Zuschüsse'},
      {label: 'AfA Gebäude'},
      {label: 'AfA bewegliche Wirtschaftsgüter'},
      {label: 'An Finanzamt gezahlte Umsatzsteuer'},
      {label: 'Renten, dauernde Lasten'}
    ],
    positiveNegativeAmountOptions: [
      {label: 'Einnahmen'},
      {label: 'Ausgaben'}
    ],
    receiptsDateRangeOptions: [
      {
        value: 'current',
        label: `${dayjs().startOf('year').format('DD.MM.YYYY')} - ${dayjs().endOf('year').format('DD.MM.YYYY')}`, 
        date: {
          start: dayjs().startOf('year'), 
          end: dayjs().endOf('year')
        }
      },
      {
        value: 'last',
        label: `${dayjs().subtract(1, 'year').startOf('year').format('DD.MM.YYYY')} - ${dayjs().subtract(1, 'year').endOf('year').format('DD.MM.YYYY')}`, 
        date: {
          start: dayjs().subtract(1, 'year').startOf('year'), 
          end: dayjs().subtract(1, 'year').endOf('year')
        }
      },
      {
        value: 'custom',
        label: "benutzerdefiniert",
        date: {
          start: dayjs().startOf('year'), 
          end: dayjs().endOf('year')
        }
      }
    ],
    orderBy: '',
    order: 'asc',
    openBulkReceiptDownloadModal: false,
    openDownloadPopover: false,
    openMultipleReceiptsUploadModal: false,
    openDeleteMultipleDocumentsCofirmationModal: false
  };

  constructor(props){
    super(props);
    this.csvLinkEl = React.createRef();
  }

  componentDidUpdate(prevProps, prevState){
    if(this.props.refreshReceiptDocumentsData){
      console.log('componentDidUpdate working');
      this.bind_list_receipt_data(true);
      this.props.setRefreshReceiptDocumentsData(null);
    }

    if(this.props.transactionAssignment){
      if(prevState.selectedReceipts !== this.state.selectedReceipts){
        this.props.updateSelectedReceipts(this.state.selectedReceipts);
      }
    }
  }

  toggleAddPropertyModal = () => {
    this.setState({ addPropertyModal: !this.state.addPropertyModal });
  };
  toggleAddPropertyTypeModal = () => {
    this.setState({ addPropertyTypeModal: !this.state.addPropertyTypeModal });
  };

  componentDidMount = () => {
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'none';
    this.handleResize();
    this.setState({skeletonLoading: true, showArticleCondition: 'property_created && rental_unit_created'}, ()=> console.log('showArticleCondition: ', this.state.showArticleCondition));
    this.props.propertySelected && this.props.propertySelected.id !== undefined ? this.bind_list_receipt_data() : this.ShowLoader();
    window.addEventListener('resize', this.handleResize);
    this.props.propertySelected && this.getDocumentsStatusByAccountId();
  };
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize= ()=> {
    if (window.innerWidth <= MOBILE_SIZE) {
      this.setState({ isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
    if (window.innerWidth <= TABLET_SIZE) {
      this.setState({ isTablet: true });
    } else {
      this.setState({ isTablet: false });
    }
  }

  _handleBillingPeriodResponse(response) {
    console.log('billing period response', response)
    if (response.status === true && response.statuscode === 401) {
      localStorage.clear();
      window.location.reload();
    }

    if (response.status === true && response.statuscode === 200) {
      const billingPeriods = MeterDataHandler.generateBilingPeriods(response.result);
      console.log('billing period response', billingPeriods)
      this.setState({ 
        billingPeriods: billingPeriods.filter((billingPeriod)=> 
          this.state.receiptsData.find((receipt)=> 
          receipt.relevantBillingPeriods.length !== 0 && receipt.relevantBillingPeriods.find((relevantBillingPeriod)=> 
          relevantBillingPeriod === billingPeriod.id)
          )
        )
      })
    }
  }

  bind_list_receipt_data = (firstRowLoader=false) => {
    this.setState({firstRowLoader});
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'none';
    let propertyid = this.props.propertySelected && this.props.propertySelected.id;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.rm_api_base_url + 'property/' + propertyid,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handle_get_list_data',
      null,
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      },
      true
    );
  };

  handle_get_list_data(response) {
    console.log('handle_get_list_data: ', response);
    if (response.status === false && response.statuscode === 404 && response.message === 'NotFound') {
      this.setState({skeletonLoading: false, filterdReceipts: [], receiptsData: [], firstRowLoader: false});
    }else if(response.status === false) {
      this.setState({skeletonLoading: false, firstRowLoader: false});
    }else{
      const receipts = ReceiptDataHandler.getReceipts(response.result);
      // console.log('new recepits', receipts);
      // this.state.receiptsData = receipts;
      this.props.setReceipts(receipts);
      this.setState({ filterdReceipts: receipts, receiptsData: receipts, firstRowLoader: false }, ()=> {
        this.getCostTypes();
        this.props.propertySelected && GlobalServices._getBilingPeriodsByProperty(this, '_handleBillingPeriodResponse', this.props.propertySelected);
        this.ShowLoader();
      });
    }

    console.log('this.props.selectedReceipts: ', this.props.selectedReceipts);
    if(this.props.transactionAssignment){
      this.props.getReceipts();
      this.setState({selectedReceipts: this.props.selectedReceipts});
    }
    
  }

  deleteMultipleReceipts=(ids)=>{
    ids.map((id, index)=> this.deleteReceipt(id, ids.length === (index+1)));
  }

  deleteReceipt = (id, eol=null) => {
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'block';
    let btnelement= null;
    if(eol !== null){
      btnelement = document.getElementById('receipt-delete-modal-footer');
    }
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.rm_api_base_url + 'receipt/' + id,
      'DELETE',
      null,
      btnelement?.children[1],
      false,
      true,
      false,
      this,
      'handle_delete_list_data',
      { id: id, cancelbtn: btnelement?.children[0], eol},
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };

  handle_delete_list_data(response) {
    if (response.status === false && response.message !== 'Not Found') {
      // Autoload.Redirection.redirection_error();
      Autoload.DataHandlerGeneral.GeneralErrorMessage(
        true,
        lang.newKeys.text_error_not_found
        // + response.message
      );
    } else if(response.status === true && response.statuscode === 200) {
      showNotification({
        message: 'Die Belege wurden gelöscht.',
        icon: <img src={RedTick} alt='red-tick'/>,
        id: 'deleteReceipt'
      });
    }

    if(response.other.eol === null || response.other.eol === true){
      var loader = document.getElementById('hello-housing-loader');
      loader.style.display = 'none';
      this.bind_list_receipt_data();
      response.other?.cancelbtn?.click();
      this.setState({selectedReceipts: [], checked: false, resetSelectedReceipts: true});
    }
    
  }

  toggleAddReceiptModal = (newAddedReceipt=null) => {
    const {selectedTransaction, getReceipts} = this.props;
    console.log('toggleAddReceiptModal: ', newAddedReceipt, selectedTransaction);
    this.setState({ addReceiptOpen: !this.state.addReceiptOpen }, ()=> {
      if(selectedTransaction){
        getReceipts(newAddedReceipt);
      }
      this.bind_list_receipt_data();
    });
  };
  toggleCheckBtn = () => {
    this.setState({ checkFilter: false });
  };

  handleSearchChange = (e) => {
    this.setState({ searchValue: e.target.value }, () =>
      this.searchSwitchFilteration(this.state.searchValue, 'utilityBillingRelevant', this.state.checkFilter)
    );
  };
  toggleCheckFilter = () => {
    this.setState({ checkFilter: !this.state.checkFilter }, () =>
      this.searchSwitchFilteration(this.state.searchValue, 'utilityBillingRelevant', this.state.checkFilter)
    );
  };

  handleSort = (data, param, ascendingOrder) => {
    const sortedList = filterByDate(data, param, ascendingOrder);
    this.setState({ filterdReceipts: sortedList, ascendingOrder: !this.state.ascendingOrder });
  };

  searchSwitchFilteration = (searchValue, switchParam, switchValue) => {
    let updatedList;
    updatedList = filterBySearchBar(searchValue, this.state.receiptsData, [
      'name',
      'costs',
      // 'cost_type',
      'date',
      'time_frame',
    ]);
    //by switch
    // // ;
    if (switchValue) {
      updatedList = filterBySwitchBtn(updatedList, switchParam, switchValue);
    }
    this.setState({ filterdReceipts: updatedList });
  };
  ShowLoader = () => {
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'none';
    var emptyDiv = document.getElementById('empty-receipt-page');
    emptyDiv.style.display = 'none';
    var dataDiv = document.getElementById('respond-receipt-page');
    dataDiv.style.display = 'block';
    this.setState({skeletonLoading: false});
  };

  handleClick = (event) => {
    this.setState({openFilter: !this.state.openFilter});
  };

  getCostTypes() {
    this.props.propertySelected && GlobalServices._getCostTypeByProperty(this, '_handleCostTypeDropDown', this.props.propertySelected);
  }

  _handleCostTypeDropDown(response) {
    if (response.statuscode === 500) {
      this.setState({ multiselectEnable: true });
    } else {
      const keys = GeneralDataHandler.getCostTypes(response.result);
      const costTypes = keys.filter((keys) => keys.useInReceiptMgmt === true);
      this.setState({ costTypeOptions: costTypes.filter((costType)=> 
        this.state.receiptsData.find((receipt)=> 
          receipt.SelectedCostTypes.length !== 0 && receipt.SelectedCostTypes[0].id === costType.id
        )
      )});
    }
  }

  handleChange = (event, type) => {
    if(type === 'allocation'){
      this.setState({selectedAllocation: event.target.value, selectedReceipts: [], resetSelectedReceipts: true, checked: true})
    } 
    if(type === 'minCost' || type === 'maxCost'){
      type === 'minCost' ? this.setState({selectedMinCost: event.target.value, selectedReceipts: [], resetSelectedReceipts: true, checked: true}) : this.setState({selectedMaxCost: event.target.value, selectedReceipts: [], resetSelectedReceipts: true})
    }
    if(type === 'costType'){
      this.setState({selectedCostType: event.target.value, selectedReceipts: [], resetSelectedReceipts: true, checked: true})
    }
    if(type === 'utilityBilling'){
      this.setState({selectedBillingPeriod: event.target.value, selectedReceipts: [], resetSelectedReceipts: true, checked: true})
    }
    if(type === 'taxCostType'){
      this.setState({selectedTaxCostType: event.target.value, selectedReceipts: [], resetSelectedReceipts: true, checked: true})
    }
    if(type === 'positiveNegativeAmount'){
      this.setState({selectedPositiveNegativeAmounts: event.target.value, selectedReceipts: [], resetSelectedReceipts: true, checked: true})
    }
    if(type === 'receiptsDateRange'){
      this.setState({selectedReceiptsDateRange: event.target.value, selectedReceipts: [], resetSelectedReceipts: true, checked: true})
    }
  };
  handleReset = (e) => {
    this.setState({
      selectedAllocation: '-',
      selectedCostType: '-',
      selectedBillingPeriod: '-',
      selectedTaxCostType: '-',
      selectedPositiveNegativeAmounts: '-',
      selectedReceiptsDateRange: '-',
      selectedMinCost: '',
      selectedMaxCost: '',
      selectedReceipts: [],
      resetSelectedReceipts: true,
      checked: true
    })
  }

  downloadExcelTable = () => {
    const {selectedReceipts, filterdReceipts, tableLabels, order, orderBy} = this.state;
    var header = tableLabels.map((label)=> label.name);
    var body = this.stableSort(filterdReceipts, this.getComparator(order, orderBy)).map((row)=> {
      if(selectedReceipts.find((receiptId)=> receiptId === row.id)) {
        console.log('row: ', row);
        return {
          'Beleg': row.name,
          'Betrag (EUR)': accounting.formatNumber(row.costs, 2, '.', ','),
          'MWst.-Satz': row.vatPercent ? `${row.vatPercent}%` : '-',
          'MwSt.-Betrag (EUR)': accounting.formatNumber(row.vatAmount, 2, '.', ','),
          'Netto-Betrag (EUR)': accounting.formatNumber(row.netAmount, 2, '.', ','),
          'Lohnkosten nach §35a EStG (EUR)': accounting.formatNumber(row.laborCost, 2, '.', ','),
          'Steuerliche Kostenart': row.taxCostType.length > 0 ? row.taxCostType : '-',
          'Kostenart': row.cost_type.length > 0 ? row.cost_type[0] : '-',
          'Datum': moment(row.receiptDate).format('DD.MM.YYYY'),
          'Zeitraum': row.utilityBillingRelevant ? row.time_frame : '-',
          'Abgerechnet': row.utilityBillingRelevant ? (row.utilityBillingAllocated === 'NONE' ? 'nicht abgerechnet' : row.utilityBillingAllocated === 'PARTIALLY' ? 'teilweise' : row.utilityBillingAllocated === 'COMPLETELY' && 'vollständig') : '-'
        }
      } else if(this.state.isMobile && selectedReceipts.length === 0) {
        return {
          'Beleg': row.name,
          'Betrag (EUR)': accounting.formatNumber(row.costs, 2, '.', ','),
          'MWst.-Satz': row.vatPercent ? `${row.vatPercent}%` : '-',
          'MwSt.-Betrag (EUR)': accounting.formatNumber(row.vatAmount, 2, '.', ','),
          'Netto-Betrag (EUR)': accounting.formatNumber(row.netAmount, 2, '.', ','),
          'Lohnkosten nach §35a EStG (EUR)': accounting.formatNumber(row.laborCost, 2, '.', ','),
          'Steuerliche Kostenart': row.taxCostType.length > 0 ? row.taxCostType : '-',
          'Kostenart': row.cost_type.length > 0 ? row.cost_type[0] : '-',
          'Datum': moment(row.receiptDate).format('DD.MM.YYYY'),
          'Zeitraum': row.utilityBillingRelevant ? row.time_frame : '-',
          'Abgerechnet': row.utilityBillingRelevant ? (row.utilityBillingAllocated === 'NONE' ? 'nicht abgerechnet' : row.utilityBillingAllocated === 'PARTIALLY' ? 'teilweise' : row.utilityBillingAllocated === 'COMPLETELY' && 'vollständig') : '-'
        }
      }
    }).filter((row)=> row !== undefined);


    this.setState({csvBody: body},()=> {
      setTimeout(() => {
        this.csvLinkEl.current.link.click();
      })
    });
    
    // downloadExcel({
    //   fileName: this.props.propertySelected.name,
    //   sheet: "sheet 1",
    //   tablePayload: {
    //     header,
    //     body: body
    //   }
    // });
  }

  toggleEmailOpen = () =>{
    this.setState({emailOpen: !this.state.emailOpen});
  }

  getDocumentsStatusByAccountId=()=>{
    const {propertySelected} = this.props;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.document_api_base_url + 'documents/status?accountId=' + propertySelected.accountId,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleGetDocumentsStatusByAccountId',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  handleGetDocumentsStatusByAccountId(response){
    if(response.status && response.statuscode === 200){
      let usedSpaceInMB = (response.result.usedSpaceInBytes/1000000);//this.convertFileSize(response.result.usedSpace);
      let memoryStatusExceededPackageWise = this.props.currentSubscription.subscriptionKey.split('_')[0] === 'STARTER' && usedSpaceInMB >= 100
      this.setState({memoryStatus: response.result, usedSpaceInMB, memoryStatusExceededPackageWise});
      window.Intercom("update", {
        api_base: "https://api-iam.intercom.io",
        app_id: "vvrs5bx3",
        //'Documents file size': response.result.usedSpaceInBytes
        'document file size': (Math.round(response.result.usedSpaceInBytes / (1024*1024) * 100) / 100)
      });
    }
  }

  convertFileSize=(sizeString)=>{
    if(sizeString){
      if(sizeString.includes('KB')){
        return parseFloat(sizeString) / 1000;
      }else if(sizeString.includes('GB')){
        return parseFloat(sizeString) * 1000;
      }else if(sizeString.includes('TB')){
        return parseFloat(sizeString) * 1000000;
      }else if(sizeString.includes('B') && (!sizeString.includes('M') || !sizeString.includes('G') || !sizeString.includes('T') || !sizeString.includes('K'))){
        return parseFloat(sizeString) / 1000000;
      }else {
        return parseFloat(sizeString);
      }
    }else{
      return 0;
    }
  }

  ////sorting functions start
  descendingComparator = (a, b, orderBy)=> {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  
  getComparator= (order, orderBy)=> {
    return order === 'desc'
      ? (a, b) => this.descendingComparator(a, b, orderBy)
      : (a, b) => -this.descendingComparator(a, b, orderBy);
  }
  
  stableSort=(array, comparator)=> {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  changePage = (route, state) => {
    this.props.history.push(route, state);
  };

  renderDrawer=()=>{
    const {openFilter, costTypeOptions, billingPeriods, range, selectedAllocation, selectedMinCost, selectedMaxCost, selectedCostType, selectedBillingPeriod, checked, tableLabels, emailOpen, skeletonLoading, showArticleCondition, memoryStatus, memoryStatusExceededPackageWise, openPaidModal, usedSpaceInMB, firstRowLoader, selectedReceipts, resetSelectedReceipts, taxCostTypeOptions, selectedTaxCostType, positiveNegativeAmountOptions, selectedPositiveNegativeAmounts, receiptsDateRangeOptions, selectedReceiptsDateRange, order, orderBy, isMobile, openBulkReceiptDownloadModal, openDownloadPopover, receiptsData, openMultipleReceiptsUploadModal} = this.state;
    return(
    <Drawer 
    anchor={'right'}
    open={openFilter} 
    onClose={this.handleClick} 
    style={{zIndex: 1000000}}
    >
      <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100vh'}}>
        <div style={{
            padding: '10px'}}>
            <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '2px'}}>
              <Text sx={(theme) => ({ color: '#111147', fontSize: '16px', margin: '10px' })}>Belege filtern</Text>
              <IconButton size='medium'
              onClick={this.handleClick}
              >
                <CloseIcon style={{
                  color: '#727293'
                  }}
                  />
              </IconButton>
            </div>
            <Divider/>
            <div>
              <div style={{margin: '15px'}}>
                <InputLabel id="demo-simple-select-outlined-label">Kostenart Betriebskosten</InputLabel>
                <FormControl variant="outlined" style={{width: '250px'}}>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={selectedCostType}
                    style={{height: '40px'}}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                      },
                      style: {zIndex: 10000000, height: '400px'},
                      getContentAnchorEl: null
                    }}
                    onChange={(e)=> this.handleChange(e, 'costType')}
                  >
                    <MenuItem value={'-'}><span style={{fontStyle: 'italic'}} defaultValue>Alle</span></MenuItem>
                    {this.state.filterdReceipts.find((receipt)=> receipt.utilityBillingRelevant === false) && <MenuItem value={'ohne'}><span style={{fontStyle: 'italic'}}>Ohne</span></MenuItem>}
                    {costTypeOptions.map((costType)=>
                      <MenuItem value={costType.id}>{costType.costTypeName}</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </div>
              <div style={{margin: '15px'}}>
                <InputLabel id="demo-simple-select-outlined-label">Steuerliche Kostenart</InputLabel>
                <FormControl variant="outlined" style={{width: '250px'}}>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={selectedTaxCostType}
                    style={{height: '40px'}}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                      },
                      style: {zIndex: 10000000, height: '400px'},
                      getContentAnchorEl: null
                    }}
                    onChange={(e)=> this.handleChange(e, 'taxCostType')}
                  >
                    <MenuItem value={'-'}><span style={{fontStyle: 'italic'}} defaultValue>Alle</span></MenuItem>
                    {/* <MenuItem value={'ohne'}><span style={{fontStyle: 'italic'}}>Ohne</span></MenuItem> */}
                    {taxCostTypeOptions.sort((a,b)=> a.label.toLowerCase().localeCompare(b.label.toLowerCase())).map((costType)=>
                      <MenuItem value={costType.label}>{costType.label}</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </div>
              <div style={{margin: '15px'}}>
                <InputLabel id="demo-simple-select-outlined-label">Betriebskostenperiode</InputLabel>
                <FormControl variant="outlined" style={{width: '250px'}}>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={selectedBillingPeriod}
                    style={{height: '40px'}}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                      },
                      style: {zIndex: 10000000, height: '400px'},
                      getContentAnchorEl: null
                    }}
                    onChange={(e)=> this.handleChange(e, 'utilityBilling')}
                  >
                    <MenuItem value={'-'}><span style={{fontStyle: 'italic'}} defaultValue>Alle</span></MenuItem>
                    {this.state.filterdReceipts.find((receipt)=> receipt.utilityBillingRelevant === false) && <MenuItem value={'ohne'}><span style={{fontStyle: 'italic'}}>Ohne</span></MenuItem>}
                    {billingPeriods.map((billingPeriod)=>
                      <MenuItem value={billingPeriod.id}>{moment(billingPeriod.startDate).format('DD.MM.YYYY') + ' - ' + moment(billingPeriod.endDate).format('DD.MM.YYYY')}</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </div>
              <div style={{margin: '15px'}}>
                <InputLabel id="demo-simple-select-outlined-label">Betrag</InputLabel>
                <div style={{display: 'flex'}}>
                <FormControl variant="outlined" style={{width: '120px'}}>
                  <OutlinedInput
                    id="outlined-adornment-weight"
                    value={selectedMinCost}
                    onChange={(e)=> this.handleChange(e, 'minCost')}
                    endAdornment={'€'}
                    placeholder={'Min.'}
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      'aria-label': 'weight',
                    }}
                    labelWidth={0}
                    style={{height: '40px'}}
                  />
                </FormControl>
                <div style={{width: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}><span style={{color: '#111147'}}> - </span></div>
                <FormControl variant="outlined" style={{width: '120px'}}>
                  <OutlinedInput
                      id="outlined-adornment-weight"
                      value={selectedMaxCost}
                      onChange={(e)=> this.handleChange(e, 'maxCost')}
                      endAdornment={'€'}
                      placeholder={'Max.'}
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        'aria-label': 'weight',
                      }}
                      labelWidth={0}
                      style={{height: '40px'}}
                  />
                </FormControl>
                </div>
              </div>
              <div style={{margin: '15px'}}>
                <InputLabel id="demo-simple-select-outlined-label">Status Abrechnung</InputLabel>
                <FormControl variant="outlined" style={{width: '250px'}}>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={selectedAllocation}
                    style={{height: '40px'}}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                      },
                      style: {zIndex: 10000000, height: '400px'},
                      getContentAnchorEl: null
                    }}
                    onChange={(e)=> this.handleChange(e, 'allocation')}
                  >
                    <MenuItem value={'-'}><span style={{fontStyle: 'italic'}} defaultValue>Alle</span></MenuItem>
                    {this.state.filterdReceipts.find((receipt)=> receipt.utilityBillingRelevant === false) && <MenuItem value={'ohne'}><span style={{fontStyle: 'italic'}}>Ohne</span></MenuItem>}
                    <MenuItem value={'COMPLETELY'}><span>Vollständig</span></MenuItem>
                    <MenuItem value={'PARTIALLY'}><span>Teilweise</span></MenuItem>
                    <MenuItem value={'NONE'}><span>Nicht abgerechnet</span></MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div style={{margin: '15px'}}>
                <InputLabel id="demo-simple-select-outlined-label">Einnahmen / Ausgaben</InputLabel>
                <FormControl variant="outlined" style={{width: '250px'}}>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={selectedPositiveNegativeAmounts}
                    style={{height: '40px'}}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                      },
                      style: {zIndex: 10000000, height: '400px'},
                      getContentAnchorEl: null
                    }}
                    onChange={(e)=> this.handleChange(e, 'positiveNegativeAmount')}
                  >
                    <MenuItem value={'-'}><span style={{fontStyle: 'italic'}} defaultValue>Alle</span></MenuItem>
                    {/* <MenuItem value={'ohne'}><span style={{fontStyle: 'italic'}}>Ohne</span></MenuItem> */}
                    {positiveNegativeAmountOptions.map((positiveNegativeAmount)=>
                      <MenuItem value={positiveNegativeAmount.label}>{positiveNegativeAmount.label}</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </div>

              <div style={{margin: '15px'}}>
                <InputLabel id="demo-simple-select-outlined-label">Belegdatum</InputLabel>
                <FormControl variant="outlined" style={{width: '250px'}}>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={selectedReceiptsDateRange}
                    style={{height: '40px'}}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                      },
                      style: {zIndex: 10000000, height: '400px'},
                      getContentAnchorEl: null
                    }}
                    onChange={(e)=> this.handleChange(e, 'receiptsDateRange')}
                  >
                    <MenuItem value={'-'}><span style={{fontStyle: 'italic'}} defaultValue>Alle</span></MenuItem>
                    {/* <MenuItem value={'ohne'}><span style={{fontStyle: 'italic'}}>Ohne</span></MenuItem> */}
                    {receiptsDateRangeOptions.map((receiptsDateRange)=>
                      <MenuItem value={receiptsDateRange.value}>{receiptsDateRange.label}</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </div>
              <div style={{margin: '15px'}}>
                {selectedReceiptsDateRange === 'custom' &&
                  <RangeDatePicker
                    inputClassName={'receipts-filter-range-date-picker-input'}
                    imgClassName={'receipts-filter-range-date-picker-img'}
                    locale="de"
                    customDate={receiptsDateRangeOptions[2].date}
                    //customDate={`${new Date(receiptsDateRangeOptions[2].date.start)} - ${new Date(receiptsDateRangeOptions[2].date.end)}`}
                    setDate={(customDate) => {
                      console.log('customDate: ', customDate);
                      let newReceiptsDateRangeOptions = receiptsDateRangeOptions.map((range, index)=> {
                        if(index === 2){
                          range.date = {
                            start: dayjs(customDate.start, 'DD.MM.YYYY'),
                            end: dayjs(customDate.end, 'DD.MM.YYYY')
                          };
                          return range;
                        }else{
                          return range;
                        }
                      });
                      
                      this.setState({ receiptsDateRangeOptions: newReceiptsDateRangeOptions });
                    }}
                  />}
              </div>
              {/* <div style={{margin: '15px'}}>
                <InputLabel id="demo-simple-select-outlined-label">Mietverhältnis</InputLabel>
                <FormControl variant="outlined" style={{width: '250px'}}>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={selectedTenant}
                    disabled={selectedUnit === ''}
                    style={{height: '40px'}}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                      },
                      style: {zIndex: 10000000},
                      getContentAnchorEl: null
                    }}
                    onChange={(e)=> handleChange(e, 'tenant')}
                  >
                    <MenuItem value={'-'}><span style={{fontStyle: 'italic'}}>Alle</span></MenuItem>
                    {tenants.map((item)=>
                      selectedUnit !== '-' ? (
                        selectedOccasion !== '-' ? (readings.some((reading)=> reading.occasion === selectedOccasion && item.tenant.id === reading.tenantId && reading.rentalUnitId === selectedUnit) &&
                      <MenuItem value={item.tenant.id}>{item.tenant.fullName}</MenuItem>) :
                      item.tenant.rentalUnitId === selectedUnit && <MenuItem value={item.tenant.id}>{item.tenant.fullName}</MenuItem>)
                      : 
                      selectedOccasion !== '-' ? (readings.some((reading)=> reading.occasion === selectedOccasion && item.tenant.id === reading.tenantId) &&
                      <MenuItem value={item.tenant.id}>{item.tenant.fullName}</MenuItem>)
                      : 
                      <MenuItem value={item.tenant.id}>{item.tenant.fullName === '(leer)' ? (`${rentalUnits.find((unit)=> unit.id === item.tenant.rentalUnitId).name}: ${item.tenant.fullName}` ) : item.tenant.fullName}</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </div>
              <div style={{margin: '15px'}}>
                <InputLabel id="demo-simple-select-outlined-label">Anlass</InputLabel>
                <FormControl variant="outlined" style={{width: '250px'}}>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={selectedOccasion}
                    style={{height: '40px'}}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                      },
                      style: {zIndex: 10000000},
                      getContentAnchorEl: null
                    }}
                    onChange={(e)=> handleChange(e, 'occasion')}
                  >
                    <MenuItem value={'-'}><span style={{fontStyle: 'italic'}}>Alle</span></MenuItem>
                    {occasions.map((occasion)=> (selectedTenant !== '-' && selectedUnit !== '-') ? 
                    readings.some((reading)=> ((selectedTenant === reading.tenantId) && (selectedUnit === reading.rentalUnitId)) && (reading.occasion === occasion.value)) && <MenuItem value={occasion.value}>{occasion.name}</MenuItem> :
                    (selectedTenant !== '-' || selectedUnit !== '-') ? 
                    readings.some((reading)=> ((selectedTenant === reading.tenantId) || (selectedUnit === reading.rentalUnitId)) && (reading.occasion === occasion.value)) && <MenuItem value={occasion.value}>{occasion.name}</MenuItem> :
                    <MenuItem value={occasion.value}>{occasion.name}</MenuItem>)}
                  </Select>
                </FormControl>
              </div> */}
            </div>
        </div>
        <div style={{margin: '15px', display: 'flex', justifyContent: 'space-between'}}>
          {/* <Button disabled={selectedAllocation === '-' && selectedCostType === '-' && selectedBillingPeriod ==='-'  && selectedMinCost === '' && selectedMaxCost === ''}  
          variant={selectedAllocation === '-' && selectedCostType === '-' && selectedBillingPeriod ==='-'  && selectedMinCost === '' && selectedMaxCost === '' ? "outlined" : 'filled'}
          onClick={(e)=> this.handleReset(e)}
          >
            Zurücksetzen
          </Button> */}
          <ColorButton
          disabled={selectedReceiptsDateRange === '-' && selectedPositiveNegativeAmounts === '-' && selectedTaxCostType === '-' && selectedAllocation === '-' && selectedCostType === '-' && selectedBillingPeriod ==='-'  && selectedMinCost === '' && selectedMaxCost === ''}  
          onClick={(e)=> this.handleReset(e)}
          className={'gray-btn'}
          //className={selectedReceiptsDateRange === '-' && selectedPositiveNegativeAmounts === '-' && selectedTaxCostType === '-' && selectedAllocation === '-' && selectedCostType === '-' && selectedBillingPeriod ==='-'  && selectedMinCost === '' && selectedMaxCost === '' ? "gray-btn" : 'purple-btn'}
          text={'Zurücksetzen'}
          buttonStyle={{
            cursor: selectedReceiptsDateRange === '-' && selectedPositiveNegativeAmounts === '-' && selectedTaxCostType === '-' && selectedAllocation === '-' && selectedCostType === '-' && selectedBillingPeriod ==='-'  && selectedMinCost === '' && selectedMaxCost === '' && 'not-allowed',
            margin: '0 4px 0 4px'
          }}
          />
          <ColorButton
          onClick={this.handleClick}
          className={"purple-btn"}
          text={'Übernehmen'}
          buttonStyle={{
            margin: '0 4px 0 4px'
          }}
          />
          
          {/* <Button
          variant='outlined'
          onClick={(e)=> this.handleReset(e)}
          >
            Zurücksetzen
          </Button> */}
        </div>
      </div>
    </Drawer>
    )
  }

  renderSearchBar=()=>{
    const {skeletonLoading, searchValue} = this.state;
    return (
    <Skeleton visible={skeletonLoading} style={{width: '100%'}}>
      <SearchContainer
      placeholder={`${lang.general.text_search}...`}
      value={searchValue}
      onChange={this.handleSearchChange}
      disabled={false}
      />
    </Skeleton>
    )
  }

  renderSwitchForCheckFilter=()=>{
    const {checkFilter} = this.state;
    return(
    <div>
      <SwitchContainer
        onChange={this.toggleCheckFilter}
        checked={checkFilter}
        switchText={lang.receipt.text_utility_filter}
        disabled={false}
      /> 
    </div>
    )
  }

  renderFilterButton=()=>{
    const {selectedAllocation, selectedMinCost, selectedMaxCost, selectedCostType, selectedBillingPeriod, checked, selectedReceipts, selectedTaxCostType, selectedPositiveNegativeAmounts, selectedReceiptsDateRange} = this.state;
    return (
      <Tooltip label="Belege filtern" >
        <IconButton 
        aria-label="filter list" 
        onClick={this.handleClick} 
        style={{
          background:  (selectedReceiptsDateRange !== '-' || selectedPositiveNegativeAmounts !== '-' || selectedTaxCostType !== '-' || selectedAllocation !== '-' || selectedCostType !== '-' || selectedBillingPeriod !== '-' || selectedMinCost !== '' || selectedMaxCost !== '') ? '#5655FE' : 'white', //(selectedTenant !== '-' || selectedUnit !== '-'  || selectedOccasion !== '-') ? '#5655FE' :
          border: '1px solid #DADAE5', 
          boxShadow: '0 1px 2px 0 rgba(14, 14, 29, .06)', 
          padding: '5px'
          }}>
          <FilterListIcon 
          style={{
            color:  (selectedReceiptsDateRange !== '-' || selectedPositiveNegativeAmounts !== '-' || selectedTaxCostType !== '-' || selectedAllocation !== '-' || selectedCostType !== '-' || selectedBillingPeriod !== '-'  || selectedMinCost !== '' || selectedMaxCost !== '') ? 'white' : '#5655FE', // (selectedTenant !== '-' || selectedUnit !== '-'  || selectedOccasion !== '-') ? 'white' :
            fontSize: '15px'
            }}/>
        </IconButton>
      </Tooltip>
    )
  }

  renderActionButtons=()=>{
    const {selectedAllocation, selectedMinCost, selectedMaxCost, selectedCostType, selectedBillingPeriod, checked, selectedReceipts, selectedTaxCostType, selectedPositiveNegativeAmounts, selectedReceiptsDateRange, isMobile, openDownloadPopover, receiptsData, checkFilter} = this.state;
    const {transactionAssignment=false} = this.props;
    return(isMobile ?
      <RecordOptions
      listOfItems={[
          {
            text: transactionAssignment ? 'Neuer Beleg' : this.state.isMobile ? lang.receipt.text_mobile_add_receipt : lang.receipt.text_add_receipt,
            className: 'receipts-main-menu-dropdown-item',
            leftImage: 
            <AddIcon 
            style={{
              color: '#5655FE',
              fontSize: '15px'
            }}/>,
            openFunction: (()=> this.toggleAddReceiptModal())
          },
          {
            text: 'Sammel-Upload',
            className: 'receipts-main-menu-dropdown-item',
            leftImage: <img src={GridIcon} style={{width: '16px', height: '16px', minWidth: '16px', minHeight: '16px'}} />,
            openFunction: (()=> this.setState({openMultipleReceiptsUploadModal: true}))
          },
          {
            text: 'Belege via Email',
            className: 'receipts-main-menu-dropdown-item',
            leftImage: 
            <EmailIcon 
            style={{
              color: '#5655FE',
              fontSize: '15px'
            }}/>,
            openFunction: (()=> this.toggleEmailOpen())
          },
          {
            text: 'Belege löschen',
            className: 'receipts-main-menu-dropdown-item',
            leftImage: <DeleteIcon style={{fontSize: '14px', color: checked ? '#E8193C' : 'white'}}/>,
            openFunction: (()=> !checked && this.setState({openDeleteMultipleDocumentsCofirmationModal: true}))
          },
          {
            text: <span style={{color: 'rgb(37, 37, 99)', fontSize: '12px', fontWeight: '400'}}>{'Exportieren'}</span>,
            style: {backgroundColor: 'rgb(233, 233, 240)', padding: '5px 10px'},
            leftImage: null,
            openFunction: (()=> null)
          },
          {
            text: 'CSV exportieren',
            className: 'receipts-main-menu-dropdown-item',
            leftImage: <img src={CsvIcon} width={'15px'} />,
            openFunction: (()=> !checked && this.downloadExcelTable())
          },
          {
            text: 'Herunterladen',
            className: 'receipts-main-menu-dropdown-item',
            leftImage: <DownloadIcon size='15px' fill={'#5655FE'}/>,
            openFunction: (()=> !checked && receiptsData.find((receipt)=> selectedReceipts.find((selectedId)=> selectedId === receipt.id && (receipt.fileName && receipt.fileName.length > 0)) !== undefined) !== undefined && this.setState({openBulkReceiptDownloadModal: true}))
          }
      ]}
      style={{marginRight: '0px'}}
      buttonStyle={{border: 'none', background: 'transparent'}}
      />
      :
      <Group spacing={10} align={'center'} position='right'>
        <div className='tooltips receipt-management-email-upload-button'>
        </div>
        {<Tooltip label={'Sammel-Upload für mehrere Belegdokumente'}>
          <IconButton 
          aria-label="filter list"
          onClick={()=> this.setState({openMultipleReceiptsUploadModal: true})} 
          style={{
            background: 'white',
            border: '1px solid #DADAE5', 
            boxShadow: '0 1px 2px 0 rgba(14, 14, 29, .06)', 
            padding: '5px',
            cursor: 'pointer',
            marginRight: '10px'
            }}>
            <img src={GridIcon} style={{width: '16px', height: '16px', minWidth: '16px', minHeight: '16px'}} />
          </IconButton>
        </Tooltip>}
        {transactionAssignment === false &&
        <Tooltip label={'Belege via Email bereitstellen'}>
          <IconButton 
          aria-label="filter list"
          onClick={()=> this.toggleEmailOpen()} 
          style={{
            background: 'white',
            border: '1px solid #DADAE5', 
            boxShadow: '0 1px 2px 0 rgba(14, 14, 29, .06)', 
            padding: '5px',
            cursor: 'pointer',
            marginRight: '10px'
            }}>
            <EmailIcon 
            style={{
              color: '#5655FE',
              fontSize: '15px'
              }}/>
          </IconButton>
        </Tooltip>}
        {transactionAssignment === false &&
        <Popover
        opened={openDownloadPopover}
        width={'auto'}
        position="bottom"
        withArrow
        onClose={() => this.setState({openDownloadPopover: false})}
        target={
        <Tooltip label={checked ? "Wählen Sie einen oder mehrere zu exportierende Belege aus." : "Belege und Beleginformation exportieren"}>
          <IconButton 
          aria-label="filter list"
          onClick={()=> !checked && this.setState({openDownloadPopover: true})//this.downloadExcelTable()
          } 
          style={{
            background:  checked ? 'white' : '#5655FE', //(selectedTenant !== '-' || selectedUnit !== '-'  || selectedOccasion !== '-') ? '#5655FE' :
            border: '1px solid #DADAE5', 
            boxShadow: '0 1px 2px 0 rgba(14, 14, 29, .06)', 
            padding: '5px',
            cursor: checked ? 'not-allowed' : 'pointer',
            marginRight: '10px'
            }}>
            <DownloadIcon size='14px' width='11px' height='11px' fill={checked ? '#5655FE': 'white'}/>
          </IconButton>
        </Tooltip>
        }
        styles={{
          inner: { padding: '5px'},
        }}>
          <Stack spacing={0}>
            <Tooltip label={"Ausgewählte Belege exportieren."} >
              <Group 
              style={{cursor: 'pointer'}}
              sx={() => ({
                padding: '10px',
                borderRadius: '4px',
                '&:hover': {
                  backgroundColor: '#E3E3FC',
                }
              })}
              onClick={()=> this.downloadExcelTable()}
              >
                <img src={CsvIcon} width={'20px'} />
                <span>CSV exportieren</span>
              </Group>
            </Tooltip>
            
            <Tooltip label={receiptsData.find((receipt)=> selectedReceipts.find((selectedId)=> selectedId === receipt.id && (receipt.fileName && receipt.fileName.length > 0)) !== undefined) !== undefined ? "Belegdokumente herunterladen" : "Für die aktuell ausgewählten Belege sind keine Anhänge verfügbar."} >
              <Group 
              style={{cursor: 'pointer'}} 
              sx={() => ({
                padding: '10px',
                borderRadius: '4px',
                '&:hover': {
                  backgroundColor: '#E3E3FC',
                }
              })}
              onClick={()=> receiptsData.find((receipt)=> selectedReceipts.find((selectedId)=> selectedId === receipt.id && (receipt.fileName && receipt.fileName.length > 0)) !== undefined) !== undefined && this.setState({openBulkReceiptDownloadModal: true})}
              >
                <DownloadIcon size='20px' fill={'#5655FE'}/>
                <span>Belegdokumente herunterladen</span>
              </Group>
            </Tooltip>
            
          </Stack>
        </Popover>}
        {<Tooltip label={!checked ? 'Belege löschen' : 'Bitte wählen Sie einen oder mehrere zu löschende Belege aus.'}>
          <IconButton 
          aria-label="filter list"
          onClick={()=> !checked && this.setState({openDeleteMultipleDocumentsCofirmationModal: true})} 
          style={{
            background:  checked ? 'white' : '#E8193C',
            border: '1px solid #DADAE5', 
            boxShadow: '0 1px 2px 0 rgba(14, 14, 29, .06)', 
            padding: '5px',
            cursor: checked ? 'not-allowed' : 'pointer',
            marginRight: '10px'
            }}>
              <DeleteIcon style={{fontSize: '14px', color: checked ? '#E8193C' : 'white'}}/>
          </IconButton>
        </Tooltip>}
        {this.renderFilterButton()}
        <div style={{marginLeft: '10px'}}>
          <ColorButton
            //onClick={() => memoryStatusExceededPackageWise ? this.setState({openPaidModal: true}) : this.toggleAddReceiptModal()}
            onClick={() => this.toggleAddReceiptModal()}
            text={transactionAssignment ? 'Neuer Beleg' : this.state.isMobile ? lang.receipt.text_mobile_add_receipt : lang.receipt.text_add_receipt}
            className="purple-btn"
          />
        </div>
      </Group>
    )
  }

  renderSwitchActionButtons=()=>{
    const {selectedAllocation, selectedMinCost, selectedMaxCost, selectedCostType, selectedBillingPeriod, checked, selectedReceipts, selectedTaxCostType, selectedPositiveNegativeAmounts, selectedReceiptsDateRange, isMobile, openDownloadPopover, receiptsData, checkFilter} = this.state;
    const {transactionAssignment=false} = this.props;
    return(
    <Group position='apart' noWrap spacing={5} style={{width: '100%', marginTop: !isMobile && '20px'}}>
      {!isMobile && this.renderSwitchForCheckFilter()}
      {isMobile && this.renderFilterButton()}
      {this.renderActionButtons()}
    </Group>
    )
  }

  renderPageHeader=()=>{
    const {openFilter, costTypeOptions, billingPeriods, range, selectedAllocation, selectedMinCost, selectedMaxCost, selectedCostType, selectedBillingPeriod, checked, tableLabels, emailOpen, skeletonLoading, showArticleCondition, memoryStatus, memoryStatusExceededPackageWise, openPaidModal, usedSpaceInMB, firstRowLoader, selectedReceipts, resetSelectedReceipts, taxCostTypeOptions, selectedTaxCostType, positiveNegativeAmountOptions, selectedPositiveNegativeAmounts, receiptsDateRangeOptions, selectedReceiptsDateRange, order, orderBy, isMobile, openBulkReceiptDownloadModal, openDownloadPopover, receiptsData, openMultipleReceiptsUploadModal} = this.state;
    const {currentSubscription, propertySelected, transactionAssignment=false} = this.props;
    return(
      <PageHeaderNew
      heading={null}//lang.receipt.text_receipts}
      //headingClassName={'receipt-management-heading'}
      //filterStyle={{marginTop: '0px'}}
      isMobile={this.state.isMobile}
      // rightContent={
      //   <ColorButton
      //     //onClick={() => memoryStatusExceededPackageWise ? this.setState({openPaidModal: true}) : this.toggleAddReceiptModal()}
      //     onClick={() => this.toggleAddReceiptModal()}
      //     text={this.state.isMobile ? lang.receipt.text_mobile_add_receipt : lang.receipt.text_add_receipt}
      //     className="purple-btn"
      //   />
      // }
      hideHeadingContainer={transactionAssignment || isMobile}
      section2Style={{flex: null}}
      section1Width={'auto'}
      justifyFiltersContentSpaceBetween={true}
      secondRowContent={
        transactionAssignment === false &&
        <div style={{display: 'flex'}}>
          {!skeletonLoading && breadcrumbsContent.map((item)=> item.area === '/receipt' && 
          item.breadcrumbs.map((breadcrumb)=>
          breadcrumb.type === 'tour' ? breadcrumb.view === (this.state.isMobile ? 'mobile' : 'desktop') &&
          <div style={{display: 'flex', width: 'auto', alignItems: 'center', cursor: 'pointer', marginRight: '22px'}} onClick={()=> window.Intercom('startTour', breadcrumb.id)}>
            <img src={TourIcon} />
            <span style={{color: '#5555FF', marginLeft: '5px', fontSize: '14px',}}>{breadcrumb.label}</span>
          </div>
          :
          breadcrumb.view === (this.state.isMobile ? 'mobile' : 'desktop') &&
          <div style={{display: 'flex', width: 'auto', alignItems: 'center', cursor: 'pointer', marginRight: '22px'}} onClick={()=> window.Intercom('showArticle', breadcrumb.id)}>
            <img src={HelpLogo} />
            <span style={{color: '#5555FF', marginLeft: '5px', fontSize: '14px'}}>{breadcrumb.label}</span>
          </div>
          )
          )}
        </div>
        }
        switchElement1={!this.state.isMobile &&
          <Skeleton visible={skeletonLoading}>
            <SwitchContainer
              onChange={this.toggleCheckFilter}
              checked={this.state.checkFilter}
              switchText={lang.receipt.text_utility_filter}
              disabled={false}
            />
          </Skeleton>
        }
        thirdRowContent={
          this.state.isMobile ?
          <Stack style={{width: '100%'}}>
            <Group noWrap={isMobile}>
              {this.renderSearchBar()}
              {<Group>
                {this.renderSwitchActionButtons()}
              </Group>}
            </Group>
            {isMobile && 
            <Group noWrap>
              {this.renderSwitchForCheckFilter()}
            </Group>}
          </Stack>
        :
        <div>
          <Skeleton visible={skeletonLoading} style={{width: this.state.isMobile ? 'auto' : '300px'}}>
            <SearchContainer
              placeholder={`${lang.general.text_search}...`}
              value={this.state.searchValue}
              onChange={this.handleSearchChange}
              disabled={false}
            />
          </Skeleton>
        </div>
        }
        sortElement1={!this.state.isMobile &&
          <Skeleton visible={skeletonLoading}>
            <Group spacing={10} noWrap align={'center'}>
              <div className='tooltips receipt-management-email-upload-button'>
              </div>
                {<Tooltip label='Sammel-Upload für mehrere Belegdokumente'>
                  <IconButton 
                  aria-label="filter list"
                  onClick={()=> this.setState({openMultipleReceiptsUploadModal: true})} 
                  style={{
                    background: 'white',
                    border: '1px solid #DADAE5', 
                    boxShadow: '0 1px 2px 0 rgba(14, 14, 29, .06)', 
                    padding: '5px',
                    cursor: 'pointer',
                    marginRight: '10px'
                    }}>
                    <img src={GridIcon} style={{width: '16px', height: '16px', minWidth: '16px', minHeight: '16px'}} />
                  </IconButton>
                </Tooltip>}
              {/* {(!this.state.isMobile && !this.state.isTablet) && transactionAssignment === false && <Text sx={(theme) => ({ color: '#454576', fontSize: '14px', marginRight: '5px' })}>Email:</Text>} */}
              {transactionAssignment === false && 
              <Tooltip label='Belege via Email bereitstellen'>
                <IconButton 
                aria-label="filter list"
                onClick={()=> this.toggleEmailOpen()} 
                style={{
                  background: 'white',
                  border: '1px solid #DADAE5', 
                  boxShadow: '0 1px 2px 0 rgba(14, 14, 29, .06)', 
                  padding: '5px',
                  cursor: 'pointer',
                  marginRight: '10px'
                  }}>
                  <EmailIcon 
                  style={{
                    color: '#5655FE',
                    fontSize: '15px'
                    }}/>
                </IconButton>
              </Tooltip>}
              {/* {(!this.state.isMobile && !this.state.isTablet) && transactionAssignment === false && <Text sx={(theme) => ({ color: '#454576', fontSize: '14px', marginRight: '5px' })}>Exportieren:</Text>} */}
              {transactionAssignment === false &&
              <Popover
              opened={openDownloadPopover}
              width={'auto'}
              position="bottom"
              withArrow
              onClose={() => this.setState({openDownloadPopover: false})}
              target={
              <Tooltip label={checked ? "Wählen Sie einen oder mehrere zu exportierende Belege aus." : "Belege und Beleginformation exportieren"}>
                <IconButton 
                aria-label="filter list"
                onClick={()=> !checked && this.setState({openDownloadPopover: true})//this.downloadExcelTable()
                } 
                style={{
                  background:  checked ? 'white' : '#5655FE', //(selectedTenant !== '-' || selectedUnit !== '-'  || selectedOccasion !== '-') ? '#5655FE' :
                  border: '1px solid #DADAE5', 
                  boxShadow: '0 1px 2px 0 rgba(14, 14, 29, .06)', 
                  padding: '5px',
                  cursor: checked ? 'not-allowed' : 'pointer',
                  marginRight: '10px'
                  }}>
                  <DownloadIcon size='14px' width='11px' height='11px' fill={checked ? '#5655FE': 'white'}/>
                </IconButton>
              </Tooltip>
              }
              styles={{
                inner: { padding: '5px'},
              }}>
                <Stack spacing={0}>
                  <Tooltip label={"Ausgewählte Belege exportieren."} >
                    <Group 
                    style={{cursor: 'pointer'}}
                    sx={() => ({
                      padding: '10px',
                      borderRadius: '4px',
                      '&:hover': {
                        backgroundColor: '#E3E3FC',
                      }
                    })}
                    onClick={()=> this.downloadExcelTable()}
                    >
                      <img src={CsvIcon} width={'20px'} />
                      <span>CSV exportieren</span>
                    </Group>
                  </Tooltip>
                  
                  <Tooltip label={receiptsData.find((receipt)=> selectedReceipts.find((selectedId)=> selectedId === receipt.id && (receipt.fileName && receipt.fileName.length > 0)) !== undefined) !== undefined ? "Belegdokumente herunterladen" : "Für die aktuell ausgewählten Belege sind keine Anhänge verfügbar."} >
                    <Group 
                    style={{cursor: 'pointer'}} 
                    sx={() => ({
                      padding: '10px',
                      borderRadius: '4px',
                      '&:hover': {
                        backgroundColor: '#E3E3FC',
                      }
                    })}
                    onClick={()=> receiptsData.find((receipt)=> selectedReceipts.find((selectedId)=> selectedId === receipt.id && (receipt.fileName && receipt.fileName.length > 0)) !== undefined) !== undefined && this.setState({openBulkReceiptDownloadModal: true})}
                    >
                      <DownloadIcon size='20px' fill={'#5655FE'}/>
                      <span>Belegdokumente herunterladen</span>
                    </Group>
                  </Tooltip>
                  
                </Stack>
              </Popover>}
              {/* {(!this.state.isMobile && !this.state.isTablet) && transactionAssignment === false && <Text sx={(theme) => ({ color: '#454576', fontSize: '14px', marginRight: '5px' })}>Löschen:</Text>} */}
              {<Tooltip label={!checked ? 'Belege löschen' : 'Bitte wählen Sie einen oder mehrere zu löschende Belege aus.'}>
                <IconButton 
                aria-label="filter list"
                onClick={()=> !checked && this.setState({openDeleteMultipleDocumentsCofirmationModal: true})} 
                style={{
                  background:  checked ? 'white' : '#E8193C',
                  border: '1px solid #DADAE5', 
                  boxShadow: '0 1px 2px 0 rgba(14, 14, 29, .06)', 
                  padding: '5px',
                  cursor: checked ? 'not-allowed' : 'pointer',
                  marginRight: '10px'
                  }}>
                    <DeleteIcon style={{fontSize: '14px', color: checked ? '#E8193C' : 'white'}}/>
                </IconButton>
              </Tooltip>}
              {/* {(!this.state.isMobile && !this.state.isTablet) && <Text sx={(theme) => ({ color: '#454576', fontSize: '14px', marginRight: '5px' })}>Filtern:</Text>} */}
              <Tooltip label='Belege filtern'>
                <IconButton 
                aria-label="filter list" 
                onClick={this.handleClick} 
                style={{
                  background:  (selectedReceiptsDateRange !== '-' || selectedPositiveNegativeAmounts !== '-' || selectedTaxCostType !== '-' || selectedAllocation !== '-' || selectedCostType !== '-' || selectedBillingPeriod !== '-' || selectedMinCost !== '' || selectedMaxCost !== '') ? '#5655FE' : 'white', //(selectedTenant !== '-' || selectedUnit !== '-'  || selectedOccasion !== '-') ? '#5655FE' :
                  border: '1px solid #DADAE5', 
                  boxShadow: '0 1px 2px 0 rgba(14, 14, 29, .06)', 
                  padding: '5px',
                  cursor: 'pointer'
                  }}>
                  <FilterListIcon 
                  style={{
                    color:  (selectedReceiptsDateRange !== '-' || selectedPositiveNegativeAmounts !== '-' || selectedTaxCostType !== '-' || selectedAllocation !== '-' || selectedCostType !== '-' || selectedBillingPeriod !== '-'  || selectedMinCost !== '' || selectedMaxCost !== '') ? 'white' : '#5655FE', // (selectedTenant !== '-' || selectedUnit !== '-'  || selectedOccasion !== '-') ? 'white' :
                    fontSize: '15px'
                    }}/>
                </IconButton>
              </Tooltip>
              <div style={{marginLeft: '15px'}}>
                <ColorButton
                  onClick={() => this.toggleAddReceiptModal()}
                  text={transactionAssignment ? 'Neuer Beleg' : this.state.isMobile ? lang.receipt.text_mobile_add_receipt : lang.receipt.text_add_receipt}
                  className="purple-btn"
                />
              </div>
            </Group>
          </Skeleton>
        }
      filterStyle={{marginBottom: this.state.isMobile && 0}}
      />
    )
  }

  render() {
    const {openFilter, costTypeOptions, billingPeriods, range, selectedAllocation, selectedMinCost, selectedMaxCost, selectedCostType, selectedBillingPeriod, checked, tableLabels, emailOpen, skeletonLoading, showArticleCondition, memoryStatus, memoryStatusExceededPackageWise, openPaidModal, usedSpaceInMB, firstRowLoader, selectedReceipts, resetSelectedReceipts, taxCostTypeOptions, selectedTaxCostType, positiveNegativeAmountOptions, selectedPositiveNegativeAmounts, receiptsDateRangeOptions, selectedReceiptsDateRange, order, orderBy, isMobile, openBulkReceiptDownloadModal, openDownloadPopover, receiptsData, openMultipleReceiptsUploadModal, openDeleteMultipleDocumentsCofirmationModal} = this.state;
    const {currentSubscription, propertySelected, transactionAssignment=false, selectedTransaction} = this.props;
    //let pathname = this.props.location.pathname;

    console.log('selectedReceipts_2234: ', selectedReceipts);

    console.log('filterdReceipts: ', this.state.filterdReceipts, selectedPositiveNegativeAmounts);
    console.log('receiptsDateRangeOptions: ', receiptsDateRangeOptions[2]);

    console.log('receiptsData: ', receiptsData);

    console.log('ReceiptsList: selectedTransaction: ', selectedTransaction);
    return (
      <>
        {transactionAssignment === false && <div id="empty-receipt-page"></div>}
        <div className="receiptsClass" id="respond-receipt-page" style={{ display: 'block', overflowX: transactionAssignment && 'scroll', width: '100%' }}>
          {this.state.receiptsData.length > 0 ? (
            <div>
              <div>
                {/* page header */}
                {this.renderPageHeader()}
                <CSVLink data={this.state.csvBody.length!==0 ? this.state.csvBody : []} separator=";" ref={this.csvLinkEl} filename={`${moment().format('YYYYMMDD')}-Export-Belege-${this.props.propertySelected.name}${browserName === 'Safari' ? '.csv' : ''}`}  />
              </div>
              {/* reciepts list */}
              {this.renderDrawer()}
              <ReceiptRecord
                isTablet={this.state.isTablet}
                isMobile={this.state.isMobile}
                parent={this}
                transactionAssignment={transactionAssignment}
                firstRowLoader={firstRowLoader}
                selectedReceipts={selectedReceipts}
                resetSelectedReceipts={resetSelectedReceipts}
                data={(selectedBillingPeriod !== '-' || selectedAllocation !== '-' || selectedMinCost !== '' || selectedMaxCost !== '' || selectedCostType !== '-' || selectedTaxCostType !== '-' || selectedPositiveNegativeAmounts !== '-' || selectedReceiptsDateRange !== '-') ?
                this.state.filterdReceipts.filter((receipt)=> 
                //(selectedAllocation === '-' || receipt.utilityBillingAllocated === selectedAllocation || selectedAllocation === 'ohne' && receipt.utilityBillingRelevant === false) &&
                (selectedAllocation === '-' ? receipt : selectedAllocation === 'ohne' ? (receipt.utilityBillingAllocated === 'NONE' && receipt.utilityBillingRelevant === false) : selectedAllocation === 'NONE' ? (receipt.utilityBillingAllocated === 'NONE' && receipt.utilityBillingRelevant) : receipt.utilityBillingAllocated === selectedAllocation) &&
                ((receipt.costs >= selectedMinCost && receipt.costs <= selectedMaxCost) || (selectedMinCost.length === 0 && receipt.costs <= selectedMaxCost) || (selectedMaxCost.length === 0 && receipt.costs >= selectedMinCost)) &&
                (selectedCostType === '-' || receipt.SelectedCostTypes.length !== 0 && receipt.SelectedCostTypes[0].id === selectedCostType || selectedCostType === 'ohne' && receipt.utilityBillingRelevant === false) &&
                (selectedBillingPeriod === '-' || receipt.relevantBillingPeriods.length !== 0 && receipt.relevantBillingPeriods.find((id)=> id === selectedBillingPeriod) || selectedBillingPeriod === 'ohne' && receipt.utilityBillingRelevant === false)
                ).filter((receipt)=> selectedTaxCostType !== '-' ? receipt.taxCostType === selectedTaxCostType : receipt)
                .filter((receipt)=> selectedPositiveNegativeAmounts !== '-' ? (selectedPositiveNegativeAmounts === 'Einnahmen' ? receipt.costs < 0 : receipt.costs >= 0) : receipt)
                .filter((receipt)=> {
                  if(selectedReceiptsDateRange !== '-'){
                    let selectedRange = receiptsDateRangeOptions.find((range)=> range.value === selectedReceiptsDateRange)
                    if(selectedRange !== undefined){
                      if((dayjs(receipt.receiptDate).isSame(selectedRange.date.start) || dayjs(receipt.receiptDate).isAfter(selectedRange.date.start)) && (dayjs(receipt.receiptDate).isSame(selectedRange.date.end) || dayjs(receipt.receiptDate).isBefore(selectedRange.date.end))){
                        return receipt;
                      }
                    }else{
                      return receipt
                    }
                  }else{
                    return receipt;
                  }
                })
                : this.state.filterdReceipts}
                labels={tableLabels}
                handleDelete={this.deleteReceipt}
                labelClassName={['receipt', 'amount', 'cost-type', 'cost-type', 'date', 'time-frame', 'empty']}
                showOption={true}
                editOption={true}
                onEditSave={() => this.bind_list_receipt_data()}
                className="record-list-table"
                disableDownload={(disable)=> this.setState({checked: disable})}
                onReceiptSelect={(selectedReceipts)=> this.setState({selectedReceipts: selectedReceipts})}
                refreshData={()=> this.bind_list_receipt_data()}
                showFirstRowLoader={()=> this.setState({firstRowLoader: true})}
                hideFirstRowLoader={()=> this.setState({firstRowLoader: false})}
                setResetSelectedReceipts={()=> this.setState({resetSelectedReceipts: false})}
                setOrder={(order, orderBy)=> this.setState({order, orderBy})}
              />
              {/* <Route path={this.props.match.url + '/payments'} exact component={TenantRentalIncomeTracking} /> */}
            </div>
          ) : this.props.properties.length > 0 && this.state.filterdReceipts.length === 0 ? (
            <div>
              {/* property is > 0 and receipt is 0 */}
              <div>
                {/* page header */}
                {this.renderPageHeader()}
                {skeletonLoading ? 
                <>
                <Skeleton visible={skeletonLoading} style={{height: '23px', marginBottom: '10px', marginTop: '30px'}}/>
                {[...Array(10)].map((e, i) => <Skeleton visible={skeletonLoading} style={{height: '75px', marginBottom: '8px'}}/>)}
                </>
                :
                <EmptyState
                  heading={lang.newKeys.text_empty_receipt_one}
                  subheading={lang.newKeys.text_empty_receipt_two}
                  icon={EmptyIcon}
                  btnHandler={this.toggleAddReceiptModal}
                  btnText={transactionAssignment ? 'Neuer Beleg' : lang.receipt.text_add_receipt}
                />}
              </div>
            </div>
          ) : (
            <div>
              {/* property is  0 and receipt is 0 */}
              <div>
                {this.renderPageHeader()}
                <EmptyState
                  heading={lang.newKeys.text_empty_receipt_one}
                  subheading={'Legen Sie zunächst eine Immobilie an, um Belege zu nutzen.'}//lang.newKeys.text_empty_receipt_two}
                  icon={EmptyIcon}
                  btnHandler={() => {
                    this.toggleAddPropertyTypeModal();
                  }}
                  // btnText="Add Property"
                  btnText={lang.general.text_add_property}
                />
                <AddPropertyModal
                  className="add_proprty_modal"
                  edit={false}
                  open={this.state.addPropertyModal}
                  toggle={() => {
                    this.toggleAddPropertyModal();
                  }}
                  propertyType={this.state.selectedPropertyType}
                />
                {this.state.addPropertyTypeModal &&
                <AddPropertyTypeModal
                  className="add_proprty_type_modal"
                  open={this.state.addPropertyTypeModal}
                  toggle={() => {
                    this.toggleAddPropertyTypeModal();
                  }}
                  next={(selectedPropertyType)=> {
                    this.setState({selectedPropertyType});
                    this.toggleAddPropertyTypeModal();
                    this.toggleAddPropertyModal();
                  }}
                />
                }
              </div>
            </div>
          )}
          {/* add receipt modal */}
          {this.state.addReceiptOpen && (
                <ReceiptDetailModal
                  memoryStatusExceededPackageWise={memoryStatusExceededPackageWise}
                  usedSpaceInMB={usedSpaceInMB}
                  history={this.props.history}
                  record={null}
                  receiptPage={this.state.ReceiptPage}
                  parent={this}
                  className="add-edit-receipt-modal"
                  edit={false}
                  open={this.state.addReceiptOpen}
                  toggle={this.toggleAddReceiptModal}
                  checkfilterToggleBtn={this.toggleCheckBtn}
                  isMobile={this.state.isMobile}
                  showFirstRowLoader={()=> this.setState({firstRowLoader: true})}
                  hideFirstRowLoader={()=> this.setState({firstRowLoader: false})}
                  transactionAssigment={selectedTransaction}
                />
              )}
            <ReceiptEmailModal
              open={emailOpen}
              className={'add-edit-receipt-modal'}
              toggle={()=> this.toggleEmailOpen()}
            />
            {openPaidModal &&
            <BuyPremiumModal
            heading={'Zusätzlichen Speicherplatz freischalten'}
            open={openPaidModal}
            text={<span style={{fontSize: '15px', color: 'rgb(19, 19, 92)', fontWeight: '400', textAlign: 'center', width: '350px'}}>Sie haben {usedSpaceInMB ? formatNumber(usedSpaceInMB, false) : '0,00'} MB von 100,00 MB verfügbarem Speicherplatz im Starter Paket genutzt. Schalten Sie hellohousing <span onClick={()=> this.props.history.push('/profile/yourSubscription', {newSubscription: true})} style={{color: 'rgb(85, 85, 255)', textDecoration: 'underline', cursor: 'pointer'}}>{currentSubscription?.subscriptionKey.split('_')[0] === 'STARTER' ? 'Premium' : 'Professional'}</span> frei, um weiteren Speicherplatz zu nutzen.</span>}
            onClick={()=> this.props.history.push('/profile/yourSubscription', {newSubscription: true})}
            onCancel={()=> this.setState({openPaidModal: false})}
            />}

            {openBulkReceiptDownloadModal &&
            <BulkDownloadForReceiptsModal
            open={openBulkReceiptDownloadModal}
            toggle={()=> this.setState({openBulkReceiptDownloadModal: false})}
            selectedReceipts={selectedReceipts.filter((selectedReceiptId)=> receiptsData.find((receipt)=> selectedReceiptId === receipt.id && (receipt.fileName && receipt.fileName.length > 0)) !== undefined)}
            receiptsData={receiptsData}
            />}

            {openMultipleReceiptsUploadModal &&
            <MultipleReceiptsUploadModal
            open={openMultipleReceiptsUploadModal}
            selectedFiles={[]}
            propertySelected={propertySelected}
            toggle={()=> this.setState({openMultipleReceiptsUploadModal: false})}
            showFirstRowLoader={()=> this.setState({firstRowLoader: true})}
            />}
            {openDeleteMultipleDocumentsCofirmationModal &&
            <DeleteMultipleDocumentsCofirmationModal
            open={openDeleteMultipleDocumentsCofirmationModal}
            numberOfFiles={selectedReceipts.length}
            toggle={()=> this.setState({openDeleteMultipleDocumentsCofirmationModal: false})}
            next={()=> this.setState({openDeleteMultipleDocumentsCofirmationModal: false}, ()=> this.deleteMultipleReceipts(selectedReceipts))}
            />}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    propertySelected: state.generalReducer.property,
    properties: state.generalReducer.allProperties,
    currentSubscription: state.generalReducer.currentSubscription,
    refreshReceiptDocumentsData: state.generalReducer.refreshReceiptDocumentsData
  };
};
const actions = {
  setReceipts,
  setRefreshReceiptDocumentsData
};

export default connect(mapStateToProps, actions)(ReceiptsList);
