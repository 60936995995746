import React, { Component } from 'react'
import CustomModal from './index';
import {ColorButton} from '../../../../index';
import Autoload from "../../../../Autoload";
import { Group, Stack } from '@mantine/core';
import { connect } from 'react-redux';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];

class ReceiptAssigmentRemoveConfirmationForCpAmountModal extends Component {
    state={
        success: null
    }
    constructor(props){
        super(props);
    }

    componentDidMount(){
     
    }
    render(){
        const {success} = this.state;
        const {open, toggle, className, type, history, removeReceipt, newAmount} = this.props;
        console.log('type: ', type);
        return (
            <CustomModal 
            heading= {'Automatische Aktualisierung deaktivieren'}  
            open={open} 
            toggle={toggle} 
            className={`${className}`}>
                <Stack>
                    <Group>
                        <span style={{fontWeight: '400'}}>Wenn Sie den Betrag manuell anpassen, wird die automatische Aktualisierung deaktiviert und die zugeordneten Belege entfernt. Sie können die automatische Aktualisierung wieder aktivieren, indem Sie Belege erneut hinzufügen.</span>
                    </Group>
                    <Group position='apart' noWrap>
                        <div>
                            <ColorButton
                            text={'Abbrechen'}
                            className={'gray-btn'}
                            onClick={()=> toggle()}
                            />
                        </div>
                        <div>
                            <ColorButton
                            text={'Fortfahren'}
                            className={'purple-btn'}
                            onClick={()=> removeReceipt()}
                            />
                        </div>
                    </Group>
                </Stack>
            </CustomModal>
         )
    }
}
const mapStateToProps = (state) => {
    return {
        propertySelected: state.generalReducer.property,
        profile: state.generalReducer.profile,
        AccountApi: state.generalReducer.account_api_id,
    };
};

export default connect(mapStateToProps)(ReceiptAssigmentRemoveConfirmationForCpAmountModal);
