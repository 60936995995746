import List from '../../../views/pages/Module/Partners/List';

const pageList = [
  {
    name: 'Partner Lists',
    path: '/partner/',
    component: List,
  },
];

export default pageList;
