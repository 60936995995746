import * as Actiontypes from '../../types/property';

export const getInfoData = () => {
  return {
    type: Actiontypes.GET_GENERAL_INFO_DATA,
  };
};

export const deleteCostType = (id) => {
  return {
    type: Actiontypes.DELETE_COST_DATA,
    payload: id,
  };
};
export const getCostType = () => {
  return {
    type: Actiontypes.GET_COST_DATA,
  };
};

export const addCostType = (data) => {
  return {
    type: Actiontypes.ADD_COST_DATA,
    payload: data,
  };
};

export const editCostType = (data) => {
  return {
    type: Actiontypes.EDIT_COST_DATA,
    payload: data,
  };
};

export const getAllocationData = () => {
  return {
    type: Actiontypes.GET_ALLOCATION_DATA,
  };
};

export const deleteAllocationData = (id) => {
  return {
    type: Actiontypes.DELETE_ALLOCATION_DATA,
    payload: id,
  };
};

export const addAllocationData = (data) => {
  return {
    type: Actiontypes.ADD_ALLOCATION_DATA,
    payload: data,
  };
};

export const editAllocationData = (data) => {
  return {
    type: Actiontypes.EDIT_ALLOCATION_DATA,
    payload: data,
  };
};

export const setCostData = (data) => {
  return {
    type: Actiontypes.SET_COST_DATA,
    payload: data
  }
}
export const setSelectedCostTypes = (data) => {
  return {
    type: Actiontypes.SET_SELECTED_COST_TYPE,
    payload: data
  }
}