import React, { useEffect, useState } from 'react';
import { Badge } from 'react-bootstrap';
import { ColorButton, HeadingContainer, MeterReadingRecord, TransparentButton } from '../../../../index.js';
// import { withRouter } from 'react-router-dom';
import moment from 'moment';
import Autoload from '../../../../Autoload';
import uparrow from '../../../../../assets/images/module/general/SelectArrowUp.svg';
import downArrow from '../../../../../assets/images/module/general/SelectArrowDown.svg';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core';
import MobileHeadingContainer from '../MobileHeadingContainer/index.js';
import APIClient from '../../../../config/APIClient.js';
import MeterDataHandler from '../../../../helpers/DataHandler/Meter.js';
import { Group, Loader } from '@mantine/core';
var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
const MOBILE_SIZE= 992;
const SMALLER_MOBILE_SIZE = 410;

export default function OtherBillingPeriodMeter({ item, history, parent, data, index, newDate, rentalUnitsExist, propertySelected//getOccupationReadings 
}) {
  console.log(item)
  const [accordianArrow, setaccordianArrow] = useState(false);
  const [itemData, setItemData] = useState(item);
  const [loader, setLoader] = useState(false);
  //const [isMobile, setIsMobile] = useState(window.innerWidth <= MOBILE_SIZE);
  var isMobile =  window.innerWidth <= MOBILE_SIZE;
  var isSmallMobile = window.innerWidth <= SMALLER_MOBILE_SIZE;
  //clickable accordion
  let badgeText = '';
  let badgeStyle = '';
  //setting badge and its style based on date
  const itemYear = moment(item.billingPeriodData.endDate).year(); //Number('20' + item.billingPeriod.split('-')[0].split('.')[2]);
  const currentYear = new Date().getFullYear();
  const dateDiff = currentYear - itemYear;
  switch (item.type) {
    case 'current': //'current'
      badgeText = isMobile ? lang.receipt.text_mobile_current_billing_period : lang.receipt.text_current_billing_period;
      badgeStyle = 'white';
      break;
    case 'last': //'last'
      badgeText = isMobile ? lang.receipt.text_mobile_last_billing_period : lang.receipt.text_last_billing_period;
      badgeStyle = 'purple';
      break;
    default:
      badgeText = '';
      badgeStyle = '';
      break;
  }
  // var open=document.getElementById('panel2a-header').getAttribute('aria-expanded');

  const getOccupationReadings=(billingPeriod, index, type = 'other')=> {
    setLoader(true);
    console.log('_getOccupationReadings', billingPeriod);
    const propertyid = propertySelected && propertySelected.id;
    APIClient({
      method: "GET",
      url: Autoload.Setting.meter_api_base_url + 'occupationReadings?propertyId=' + propertyid + '&billingPeriodId=' + billingPeriod.id,
      headers: { Authorization: Autoload.Utils.getAuthToken() }
    })
    .then((response)=> {
      if(response.data.status && response.status === 200){
        console.log('handle_get_list_data', response.data.result);
        const newOccupationReadings= MeterDataHandler.getOccupationReadings(response.data.result, billingPeriod.id).sort((a, b) =>(a['rentalUnitName'] || '').toString().localeCompare((b['rentalUnitName'] || '').toString()));
        
        let newItemData = itemData;
        newItemData.occupationReadings = newOccupationReadings;
        setItemData(newItemData);
        setLoader(false);
      }
    }).catch((e)=> {
      setLoader(false);
      console.log('getOccupationReadingsError: ', e);
    });
  }
  
  useEffect(()=>{
    window.addEventListener('resize', () =>{
      isMobile= window.innerWidth <= MOBILE_SIZE;
      isSmallMobile= window.innerWidth <= SMALLER_MOBILE_SIZE;
    },[window.innerWidth]);
  })

  useEffect(()=>{
    if(accordianArrow && item.occupationReadings.length === 0){
       getOccupationReadings(item);
    }
  }, [accordianArrow]);

  useEffect(()=>{
    setItemData(item);
  }, [item]);
  return (
    <div className={`meter-billing-period-${index}`}>
      <Accordion key={index}>
        <AccordionSummary
          aria-controls="panel2a-content"
          id="panel2a-header"
          onClick={() => {
            setaccordianArrow(!accordianArrow);
            // this.setState({
            //    accordianArrow: document.getElementById('panel2a-header').getAttribute('aria-expanded'),
            //          });
          }}
        >
          <Typography>
            <MobileHeadingContainer
              className="meter-reading-heading"
              isMobile={isMobile}
              // heading={`${moment(item.startDate).format('DD.MM.YYYY')} - ${moment(item.endDate).format('DD.MM.YYYY')}`}
              heading={
                  <div className="d-flex"  style={{marginLeft: isMobile && window.innerWidth <= 992 && '0px', minWidth: '80px', whiteSpace: 'nowrap', marginTop: !isMobile && '7px'}}>
                    <div style={{ marginRight: '12px' }}>
                      <img src={accordianArrow === false ? downArrow : uparrow} alt="icon" style={{minWidth: '8px'}} />
                    </div>
                    <div style={{
                      fontSize: isMobile ? '14px' : '16px',
                      fontFamily: 'Inter',
                      fontStyle: 'normal',
                      fontWeight: '600',
                      lineHeight: '24px',
                      color: '#000000'
                      }}>
                      {`${moment(item.billingPeriodData.startDate).format('DD.MM.YYYY')} - ${moment(
                        item.billingPeriodData.endDate
                      ).format('DD.MM.YYYY')}`}
                    </div>
                  </div>
              }
              headingButton={
                isMobile &&
                <ColorButton
                      onClick={() =>
                        history.push({
                          pathname: `${Autoload.Setting.get_meter_reading_annual_link(item.id, 'c-r-0')}`,
                          data: { ...item, badgeText },
                          edit: false,
                        })
                      }
                      buttonStyle={{maxWidth: isMobile && 'auto', maxHeight: isMobile && '35px', marginRight: '0px !important'}}
                      text={lang.meter.text_mobile_individual_reading}
                      textStyle={{fontSize: isMobile && 12}}
                      className={` mr-1 annual-btn purple-btn meter-billing-period-button-${index}`}
                    />
              }
              
              rightContent={ !isMobile &&
                <div className="btn-container meter-reading-buttons" style={{justifyContent: 'flex-end', marginRight: isMobile && window.innerWidth <= 400 && '16px', marginTop: isMobile && '16px'}}>
                  {badgeText !== '' ? (null
                    // <ColorButton
                    //   onClick={() =>
                    //     history.push({
                    //       pathname: `${Autoload.Setting.get_meter_reading_interium_link(
                    //         item.id,
                    //         'c-r-0',
                    //         newDate,
                    //         'start'
                    //       )}`,
                    //       data: item,
                    //       edit: false,
                    //     })
                    //   }
                    //   buttonStyle={{maxWidth: isMobile && '100px', maxHeight: isMobile && '100px'}}
                    //   text={lang.meter.text_individual_reading}
                    //   textStyle={{fontSize: isMobile && 12}}
                    //   className={` mr-1 gray-btn interim-btn`}
                    // />
                  ) : (
                    null
                    // <TransparentButton
                    //   onClick={() =>
                    //     history.push({
                    //       pathname: `${Autoload.Setting.get_meter_reading_interium_link(
                    //         item.id,
                    //         'c-r-0',
                    //         newDate,
                    //         'start'
                    //       )}`,
                    //       data: item,
                    //       edit: false,
                    //     })
                    //   }
                    //   text={lang.meter.text_individual_reading}
                    //   className={`mr-1 interim-transparent-btn`}
                    // />
                  )}
                  {!isMobile && <ColorButton
                    onClick={() => {
                      console.log(item);
                      console.log("HISTORY:");
                      console.log(history);
                      history.push({
                        pathname: `${Autoload.Setting.get_meter_reading_annual_link(item.id, 'c-r-0')}`,
                        //pathname: `meter/reading/annual/${item.id}/c-r-0`,
                        data: { ...item, badgeText },
                        edit: false,
                      })
                    }}
                    text={lang.meter.text_reading}
                    className={` mr-1 ${badgeText !== '' ? 'annual-btn purple-btn' : ' annual-btn gray-btn '} meter-billing-period-button-${index}`}
                  />}
                </div>
              }
              badge={
                !isSmallMobile && 
                <div style={{marginTop: !isMobile && '6px'}}>
                  <Badge variant="info" className={`year-badge ${badgeStyle}`} style={{
                    backgroundColor: badgeStyle === 'purple' ? '#F2F2FC' : 'white',
                    color: badgeStyle === 'purple' ? '#5555FF' : '#727293',
                    fontSize: isMobile ? '12px' : '14px',
                    fontWeight: '500',
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    marginLeft: window.innerWidth <= 350 ? '45px' : '10px',
                    padding: '4px 8px',
                    lineHeight: '20px',
                    borderRadius: '4px'
                    }}>
                    {badgeText} 
                  </Badge>
                </div>
              }
            />
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography style={{ width: '100%' }}>
            {loader ?
            <Group style={{width: '100%'}} align='center' position='center'>
              <Loader size={'sm'}/>
            </Group>
            :
            itemData.occupationReadings.length === 0//item.occupationReadings.length === 0 
            ? null : (
              <MeterReadingRecord
                className="meter-reading-table"
                data={itemData}
                labels={[
                  lang.meter.text_Rental_Unit,
                  lang.tenant.text_tenant,
                  lang.utility.text_occupancy_period,
                  lang.meter.text_status,
                  lang.general.text_date,
                ]}
                labelClassName={['unit', 'tenant', 'Occupation period', 'status', 'date', '']}
                history={history}
                parent={parent}
                ccbFunc={(response) => console.log('res', response)}
                billingPeriod={dateDiff}
                rentalUnitsExist={rentalUnitsExist}
              />
            )}
            {data.length !== index + 1 && <div className="divider"></div>}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <hr />
      {/* {index == 0 ? (
            { current_billingPeriod }
          ) : ''}
          {index == 0 ? (
            { least_billingPeriod }
          ) : ''} */}
    </div>
  );
}
