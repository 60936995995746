import React, { Component } from 'react';
import Autoload from '../../../../neoverv/Autoload';
import { connect } from 'react-redux';
import { showNotification } from '@mantine/notifications';
import CopyIcon from '../../../../neoverv/components/SVGs/copyIcon';
import GreenTick from '../../../../assets/images/module/general/GreenTick.svg';
import { Group } from '@mantine/core';
import { EmptyStateContainer } from '../../../../neoverv';
import MeterIcon from '../../../../assets/images/module/general/blueEmptyMeter.svg';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
const pStyle= {
    fontFamily: 'Inter, sans-serif',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '24px',
    color: 'rgb(14, 14, 29)'
}
export class MeterEmailCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
        hover: false, 
        click: false,
        allMappingsFound: false
    };
  }

  componentDidMount = () => {
    const {meterMappings} = this.props;
    if(meterMappings){
      this.checkAllMappings(meterMappings.find((mapping)=> mapping.isActive));
    }
  };
  componentDidUpdate = (prevProps) => {
    const {meterMappings} = this.props;
    if(prevProps.meterMappings !== meterMappings){
      this.checkAllMappings(meterMappings.find((mapping)=> mapping.isActive));
    }
  }

  checkAllMappings = (meterMapping) => {
    console.log('checkAllMappings: ', meterMapping);
    if(meterMapping){
      if(meterMapping.mappingType === 'CSV'){
        if(meterMapping.meterIdMapping && meterMapping.meterIdMapping !== '' && 
        meterMapping.dateTimeMapping && meterMapping.dateTimeMapping !== '' && 
        meterMapping.consumptionMapping && meterMapping.consumptionMapping !== ''){
            this.setState({allMappingsFound: true});
        }else{
            this.setState({allMappingsFound: false});
        }
      }else{
        if(meterMapping.filePassword && meterMapping.filePassword !== ''){
          this.setState({allMappingsFound: true});
        }else{
          this.setState({allMappingsFound: false});
        }
      }
    }else{
      this.setState({allMappingsFound: false});
    }
  }
  
  render() {
    const email = this.props.propertySelected ? this.props.propertySelected.meterReadingsEmail : '';
    const {hover, click, allMappingsFound} = this.state;
    const {isMobile, isTablet} = this.props;

    return (
        <div 
        style={{
          width: '100%', //isTablet ? '100%' : '540px', 
          height: '100%', //isTablet ? '100%' : '400px',
          boxShadow: '0 1px 3px rgba(0,0,0,.08)', 
          borderRadius: '4px',
          background: 'white'
          }}>
          <div 
          className='tooltips meter-email-card'
          style={{
              padding: '12.1px 24px',
              borderBottom: '1px solid #dadae6',
              borderTopLeftRadius: '4px',
              borderTopRightRadius: '4px',
              background: 'white'
          }}>
              <h5 
              style={{
                  fontWeight: '500',
                  fontSize: '16px',
                  lineHeight: '24px',
                  color: '#0e0e1d',
                  marginBottom: '0px'
                  }}>Automatische Datenbereitstellung</h5>
          </div>
          <div 
          style={{
              padding: '12.1px 24px 30px',
          }}>
          
          {allMappingsFound ?
          <>
          <div><span style={pStyle}>An diese Email-Adresse können Sie automatisiert Zählerdaten für Ihre Immobilie {<span style={{...pStyle, ...{fontWeight: '600'}}}>{`${this.props.propertySelected && this.props.propertySelected.name} `}</span>} bereitstellen.</span></div>
          <div 
          style={{
              display: 'flex', 
              justifyContent: 'center', 
              margin: '15px 0px', 
              color: '#5555FF',
              cursor: 'pointer',
              border: '1px dashed #bfbfd2',
              boxSizing: 'border-box',
              borderRadius: '4px',
              padding: '16px 16px',
              background: '#f7f7fa',
              fontFamily: 'Inter, sans-serif',
              fontWeight: '400',
              width: '100%'
          }}
          // onMouseEnter={this.handleMouseEnter}
          // onMouseLeave={this.handleMouseLeave}
          onClick={()=> {
              navigator.clipboard.writeText(email); 
              this.setState({click: true});
              showNotification({
                  message: 'Email-Adresse Datenbereitstellung kopiert',
                  icon: <img src={GreenTick} alt='green-tick'/>,
                  //style: {marginLeft: !isMobile && !isTablet && '24%', width: '100%'},
              });
          }}
          >
              <div style={{
                  whiteSpace: isTablet && !isMobile && 'nowrap',
              }}>
                  {email}
                  <span style={{marginLeft: '3px'}}><CopyIcon fill={click ? '#5555FF' : hover && '#5555FF'} width='15px' height='15px'/></span>
              </div>
          </div>
          <span style={pStyle}>Hinterlegen Sie diese Email-Adresse bei Ihrem Zählerdatenanbieter für die regelmäßige automatisierte Zusendung der Zählerdaten.</span>
          </>
          :
          <Group position='center' style={{background: 'rgb(247, 247, 250)'}}>
            <EmptyStateContainer
              heading="Automatisierte Datenbereitstellung noch nicht verfügbar."
              subheading={"Sie müssen erst die Datenbereitstellung im oberen Bereich einrichten, um den Datenempfang zu ermöglichen."}
              icon={MeterIcon}
              showBtn={false}
              styleMainContainer={{margin: 50}}
            />
          </Group>}
          </div>
        </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    propertySelected: state.generalReducer.property,
  };
};
export default connect(mapStateToProps)(MeterEmailCard);
