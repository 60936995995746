const DownloadIcon = ({fill=null, width="16", height="16", size='14px'}) => (
  <div style={{width: size, height: size, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.08585 0.378613C7.32827 0.136191 7.65707 0 7.9999 0C8.34274 0 8.67154 0.136191 8.91396 0.378613C9.15638 0.621035 9.29257 0.94983 9.29257 1.29267V7.33667C9.29257 7.38087 9.31013 7.42326 9.34139 7.45452C9.37264 7.48577 9.41503 7.50333 9.45924 7.50333H11.2319C11.3559 7.50338 11.4773 7.53909 11.5816 7.60619C11.6858 7.67329 11.7686 7.76896 11.8201 7.8818C11.8715 7.99464 11.8894 8.11988 11.8716 8.24261C11.8539 8.36534 11.8012 8.48037 11.7199 8.574L8.4879 12.2913C8.42726 12.3612 8.35231 12.4173 8.26811 12.4557C8.18392 12.4942 8.09245 12.514 7.9999 12.514C7.90736 12.514 7.81589 12.4942 7.73169 12.4557C7.6475 12.4173 7.57255 12.3612 7.5119 12.2913L4.2799 8.574C4.1986 8.48037 4.14593 8.36534 4.12817 8.24261C4.11041 8.11988 4.12831 7.99464 4.17974 7.8818C4.23117 7.76896 4.31396 7.67329 4.41824 7.60619C4.52252 7.53909 4.6439 7.50338 4.7679 7.50333H6.54057C6.58477 7.50333 6.62717 7.48577 6.65842 7.45452C6.68968 7.42326 6.70724 7.38087 6.70724 7.33667V1.29267C6.70724 0.94983 6.84343 0.621035 7.08585 0.378613Z" fill={fill ? fill : "#727293"}/>
      <path d="M14.5399 11.5287C14.6914 11.3771 14.8969 11.292 15.1112 11.292C15.2176 11.2916 15.323 11.3121 15.4215 11.3525C15.5199 11.393 15.6093 11.4524 15.6847 11.5275C15.7601 11.6025 15.8199 11.6918 15.8607 11.79C15.9016 11.8883 15.9226 11.9936 15.9226 12.1V13.3367C15.9226 14.0439 15.6416 14.7222 15.1415 15.2223C14.6414 15.7224 13.9631 16.0033 13.2559 16.0033H2.7439C2.03723 16.0024 1.35981 15.7211 0.860436 15.2211C0.36106 14.7211 0.0805659 14.0433 0.0805664 13.3367V12.1C0.0805664 11.8857 0.165695 11.6802 0.317224 11.5287C0.468754 11.3771 0.674271 11.292 0.888566 11.292C1.10286 11.292 1.30838 11.3771 1.45991 11.5287C1.61144 11.6802 1.69657 11.8857 1.69657 12.1V13.3367C1.69692 13.6143 1.8074 13.8804 2.00375 14.0766C2.20011 14.2728 2.4663 14.3832 2.7439 14.3833H13.2559C13.5335 14.3832 13.7997 14.2728 13.996 14.0766C14.1924 13.8804 14.3029 13.6143 14.3032 13.3367V12.1C14.3032 11.8857 14.3884 11.6802 14.5399 11.5287Z" fill={fill ? fill : "#727293"}/>
    </svg>
  </div>
)
  
  export default DownloadIcon
