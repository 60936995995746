import React, { Component } from 'react'
import CustomModal from './index';
import {ColorButton} from '../../../../index';
import Autoload from "../../../../Autoload";
import { Group, Stack } from '@mantine/core';
import { connect } from 'react-redux';
import visa from '../../../../../assets/images/module/general/visa.svg';
import mastercard from '../../../../../assets/images/module/general/mastercard.svg';
import PremiumBadgeIcon from '../../../../../assets/images/module/general/PremiumBadgeIcon.svg';
import ProfessionalBadgeIcon from '../../../../../assets/images/module/general/ProfessionalBadgeIcon.svg';
import { formatNumber } from '../../../../../helpers/stringsManipulations';
import moment from 'moment';
import PaymentTemplate from '../../../../../views/pages/Module/General/profile/PaymentTemplate';
import PaymentTemplateForModal from '../../../../../views/pages/Module/General/profile/PaymentTemplateForModal';
import PaymentTemplateSuccessForModal from '../../../../../views/pages/Module/General/profile/PaymentTemplateSuccessForModal';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];

class PaymentTemplateSuccessModal extends Component {
    state={
        success: null
    }
    constructor(props){
        super(props);
    }

    componentDidMount(){
     
    }
    render(){
        const {success} = this.state;
        const {open, toggle, className, type, history, onNext, reAuthorize, authorize, getPaymentDetails, getPaymentHistoryTable} = this.props;
        console.log('type: ', type);
        return (
            <CustomModal 
            heading= {type === 'authorize' ? 'Autorisierung Zahlungsmethode' : (success === null ? 'Zahlungsstatus' : success === true ? 'Änderung erfolgreich' : 'Änderung fehlgeschlagen')}  
            open={open} 
            toggle={toggle} 
            className={`${className}`}>
                <PaymentTemplateSuccessForModal
                type={type}
                toggle={()=> toggle()}
                history={history}
                onNext={onNext}
                onStatusChange={(status)=> this.setState({success: status})}
                reAuthorize={reAuthorize}
                authorize={authorize}
                getPaymentDetails={()=> getPaymentDetails()}
                getPaymentHistoryTable={()=> getPaymentHistoryTable()}
                />
            </CustomModal>
         )
    }
}
const mapStateToProps = (state) => {
    return {
        propertySelected: state.generalReducer.property,
        profile: state.generalReducer.profile,
        AccountApi: state.generalReducer.account_api_id,
    };
};

export default connect(mapStateToProps)(PaymentTemplateSuccessModal);
