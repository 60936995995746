import * as ActionTypes from '../../types/utilityBilling';
const initialState = {
  currentBillingId: null,
  loader: true,
  HeatingAllocationFilter: false,
  utilityBillingData: [],
  filteredBilling: [],
};

export const UtilityBillingReducer = (state = initialState, action) => {
  let tenantBills,
    updatedRecords,
    tenantBillRow,
    utilityBill,
    updateUB,
    updateCP,
    updatedTenantBills,
    updatedTenantBillRow,
    updatedTenantBillsArr,
    replacedTenantBillRecord,
    updatedUtilityBill,
    replaceUtilityBillingData;
  switch (action.type) {
    case ActionTypes.GET_UTILITY_DATA:
      return state;
    case ActionTypes.SET_UTILITY_DATA:
      return {
        ...state,
        filteredBilling: [...action.payload],
        utilityBillingData: [...action.payload],
      };

    // console.log(action.payload)
    // const { id, date, tableData } = action.payload
    // const result = [...state.utilityBillingData.slice(0, 2)]
    // console.log("checking resulttt", result)
    // return { ...state, utilityBillingData: [...state.utilityBillingData.slice(0, 2), { id, date, tableData }] }

    // case ActionTypes.SET_COST_POSITIONS:
    //     return { ...state, costAllocations: action.payload }

    case ActionTypes.SET_COST_POSITIONS:
      //find the utility data to which this record belongs
      utilityBill = state.utilityBillingData.find((ub) => ub.id == state.currentBillingId);
      utilityBill =
        utilityBill && utilityBill.billingData
          ? { ...utilityBill, billingData: { ...utilityBill.billingData, costPosition: action.payload } }
          : { ...utilityBill, billingData: { costPosition: action.payload } };
      updatedRecords = state.utilityBillingData.map((ub) => {
        if (ub.id == utilityBill.id) {
          return utilityBill;
        } else {
          return ub;
        }
      });
      //update state
      return { ...state, utilityBillingData: updatedRecords };

    case ActionTypes.SET_TENANT_BILLS:
      //find the utility data to which this record belongs
      utilityBill = state.utilityBillingData.find((ub) => ub.id == state.currentBillingId);

      if (typeof utilityBill !== 'undefined') {
        utilityBill = { ...utilityBill, billingData: { ...utilityBill.billingData, tenantBills: action.payload } };
        updatedRecords = state.utilityBillingData.map((ub) => {
          if (ub.id == utilityBill.id) {
            return utilityBill;
          } else {
            return ub;
          }
        });
        //update state
        return { ...state, utilityBillingData: updatedRecords };
      } else return { ...state, utilityBillingData: [] };

    case ActionTypes.DELETE_COST_POSITION:
      //find the utility data to which this cp belongs
      utilityBill = state.utilityBillingData.find((ub) => ub.id == state.currentBillingId);
      //remove that cp
      updateCP = utilityBill.billingData.costPosition.filter((cp) => cp.id != action.payload);
      updateUB = { ...utilityBill, billingData: { ...utilityBill.billingData, costPosition: updateCP } };
      //combine data
      updatedRecords = state.utilityBillingData.map((ub) => {
        if (ub.id == updateUB.id) {
          return updateUB;
        } else {
          return ub;
        }
      });
      return { ...state, utilityBillingData: updatedRecords };

    case ActionTypes.ADD_COST_POSITION:
      //find the utility data to which this cp belongs
      utilityBill = state.utilityBillingData.find((ub) => ub.id == state.currentBillingId);
      //add cp in existing cps
      updateCP = [...utilityBill.billingData.costPosition, { ...action.payload }];
      updateUB = { ...utilityBill, billingData: { ...utilityBill.billingData, costPosition: updateCP } };
      //combine data
      updatedRecords = state.utilityBillingData.map((ub) => {
        if (ub.id == updateUB.id) {
          return updateUB;
        } else {
          return ub;
        }
      });
      return { ...state, utilityBillingData: updatedRecords };

    case ActionTypes.SAVE_ZUWEISUNG_DATA:
      //find the utility data to which this record belongs
      utilityBill = state.utilityBillingData.find((ub) => ub.id == state.currentBillingId);
      //replace data with new data given
      updateCP = utilityBill.billingData.costPosition.map((cp) => {
        if (cp.id == action.payload.costPosition) {
          return {
            ...cp,
            zuweisung: action.payload.data,
          };
        } else {
          return cp;
        }
      });
      updateUB = { ...utilityBill, billingData: { ...utilityBill.billingData, costPosition: updateCP } };
      //combine data
      updatedRecords = state.utilityBillingData.map((ub) => {
        if (ub.id == updateUB.id) {
          return updateUB;
        } else {
          return ub;
        }
      });
      //update state
      return { ...state, utilityBillingData: updatedRecords };

    case ActionTypes.EDIT_COST_POSITION:
      //find the utility data to which this record belongs

      utilityBill = state.utilityBillingData.find((ub) => ub.id == state.currentBillingId);
      console.log(utilityBill, 'utilityBill reducer');
      //replace data with new data given
      updateCP = utilityBill.billingData.costPosition.map((cp) => {
        if (cp.id == action.payload.id) {
          return action.payload;
        } else {
          return cp;
        }
      });

      updateUB = { ...utilityBill, billingData: { ...utilityBill.billingData, costPosition: updateCP } };
      //combine data
      updatedRecords = state.utilityBillingData.map((ub) => {
        if (ub.id == updateUB.id) {
          return updateUB;
        } else {
          return ub;
        }
      });

      //update state
      return { ...state, utilityBillingData: updatedRecords };

    case ActionTypes.UPDATE_COMMENTS:
      //find root data
      utilityBill = state.utilityBillingData.find((ub) => ub.id == state.currentBillingId);
      // console.log('utilityBill',utilityBill);
      //find the tenant record to which this comment belongs
      tenantBills = utilityBill.billingData.tenantBills.find(
        (bill) => bill.tenantID == action.payload.tenantID
      );
      console.log('tenantBills',tenantBills);
      //go to the exact bill of that tenant which is to be updated
      tenantBillRow = tenantBills.tenantRows.find((tr) => tr.id == action.payload.billID);
      // console.log('tenantBillRow',tenantBillRow);
      //update bill record
      updatedTenantBillRow = { ...tenantBillRow, comment: action.payload.comment };
      // console.log('updatedTenantBillRow',updatedTenantBillRow);
      //update complete tenant bills
      updatedTenantBillsArr = tenantBills.tenantRows.map((tb) => {
        // console.log('loop in updatedTenantBillsArr',tb.id , action.payload.billID )
        if (tb.id == action.payload.billID) {
          return updatedTenantBillRow;
        } else {
          return tb;
        }
      });
      // console.log('updatedTenantBillsArr',updatedTenantBillsArr);
      updatedTenantBills = {
        ...tenantBills,
        tenantRows: updatedTenantBillsArr,
      };
      // console.log('updatedTenantBills',updatedTenantBills);
      replacedTenantBillRecord = utilityBill.billingData.tenantBills.map((item) => {
        // console.log('loop in replacedTenantBillRecord',item.tenantID , action.payload.tenantID )
        if (item.billingStatementId == action.payload.tenantID) {
          return updatedTenantBills;
        } else {
          return item;
        }
      });
      // console.log('replacedTenantBillRecord',replacedTenantBillRecord);
      updatedUtilityBill = {
        ...utilityBill,
        billingData: {
          ...utilityBill.billingData,
          tenantBills: replacedTenantBillRecord,
        },
      };
      // console.log('updatedUtilityBill',updatedUtilityBill);
      replaceUtilityBillingData = state.utilityBillingData.map((ub) => {
        if (ub.id == updatedUtilityBill.id) {
          return updatedUtilityBill;
        } else {
          return ub;
        }
      });
      // console.log('replaceUtilityBillingData',replaceUtilityBillingData);
      //update state
      return { ...state, utilityBillingData: replaceUtilityBillingData };

    case ActionTypes.SET_CURRENT_UB_PERIOD:
      return { ...state, currentBillingId: action.payload };

    case ActionTypes.SET_HEATING_ALLOCATION:
      return { ...state, HeatingAllocationFilter: action.payload };

    default:
      return state;
  }
};
