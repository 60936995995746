import { Box, Button, Center, Group, Loader, Stack, Text, TextInput, Notification } from "@mantine/core";
import { useLocalStorage } from "@mantine/hooks";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { showNotification, hideNotification } from '@mantine/notifications';
import RedCross from '../../../assets/images/module/general/RedCross.svg';
import GreenTick from '../../../assets/images/module/general/GreenTick.svg';
import CrossIcon from '../../../assets/images/module/general/CrossIcon.svg';
import HelpLogo from '../../../assets/images/module/general/book.svg';
import TourIcon from '../../../assets/images/module/general/TourIcon.svg';
import PrintIconBlue from '../../../assets/images/module/general/PrintIconBlue.svg';
import PrintIconWhite from '../../../assets/images/module/general/PrintIconWhite.svg';
import MultipleTenants from '../../../assets/images/module/general/MultipleTenants.svg';
import PropertyIcon from '../../../assets/images/module/general/PropertyIcon.svg';
import breadcrumbsContent from '../../../../src/assets/breadcrumbs.json';
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import { updatePropetyInfo } from "../../../redux/action/General/generalActions";
  

import APIClient from "../../../neoverv/config/APIClient";
import axios from "axios";
import { config } from "../../../neoverv/config/Setting";
import Autoload from "../../../neoverv/Autoload";

import SingleTable from './SingleTable';

import Icon from "../../../components/Icon";
import { ColorButton, MeterModal, SwitchContainer } from "../../../neoverv";
import { connect } from "react-redux";
import MeterDataHandler from "../../../neoverv/helpers/DataHandler/Meter";
import {
  Tooltip, 
  IconButton, 
  Popper, 
  FormControl, 
  Select, 
  InputLabel, 
  MenuItem,
  Divider,
  Drawer
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import FilterListIcon from '@material-ui/icons/FilterList';
import {
  setMeterReadingsToPrint
} from '../../../redux/action/General/generalActions';
import MobileHeadingContainer from "../../../neoverv/components/Module/General/MobileHeadingContainer";


dayjs.extend(utc);
const MOBILE_SIZE = 500;
// Table of the readings
const AnnualReadingById = ({propertySelected, selectedMeterReadings}) => {
  const history = useHistory();
  const { id } = useParams(); // occupationReadingsId available
  const [ chosenProperty ] = useState(propertySelected.id);//useLocalStorage({ key: 'chosenProperty' });
  const [ viewState, setViewState ] = useState({ issues: [], loading: true, search: '', hasChanged: false, meterModalOpened: false, negativeConsumptionPresent: false, negativeCurrentReading: false, rentalUnitNameForNewMeterReading: ''});
  const [ changedMeterReadings, setChangedMeterReadings ] = useState([]);
  const [ savingPossible, setSavingPossible ] = useState(false);
  const [ initialReadings, setInitialReadings ] = useState([]);
  const [ readings, setReadings ] = useState([]);
  const [ rentalUnits, setRentalUnits ] = useState([]);
  const [ selectedUnit, setSelectedUnit ] = useState(history.location.edit ? history.location.data.rentalUnitId : '-'); 
  const [ selectedTenant, setSelectedTenant ] = useState(history.location.edit ? history.location.data.tenantId : '-');
  const [ selectedOccasion, setSelectedOccasion ] = useState(history.location.edit ? (history.location.data.period === 'start' ? history.location.data.periodStartReading.occasion : history.location.data.periodEndReading.occasion) : '-');
  const [ tenants, setTenants ] = useState([]);
  const [ openFilter, setOpenFilter] = useState(false);
  const [ occasions, setOccasions ] = useState([
    {
      value: 'move-in',
      name: 'Einzug'
    }, {
      value: 'move-out',
      name: 'Auszug'
    }, {
      value: 'annual',
      name: 'Jahresablesung'
    }])
  const [editableIds, setEditableIds] = useState([]);
  //const [isMobile, setIsMobile] = useState(window.innerWidth <= MOBILE_SIZE);
  const [negativeValues, setNegativeValues] = useState([]);
  const [selectedRentalUnitId, setSelectedRentalUnitId] = useState('');
  const [showArticleCondition, setShowArticleCondition] = useState('');
  const [selectedTables, setSelectedTables] = useState([]);
  const [showArchivedMeters, setShowArchivedMeters] = useState(false);
  const [billingPeriods, setBillingPeriods] = useState([]);
  var isMobile =  window.innerWidth <= MOBILE_SIZE;
  
  const popperId = openFilter ? 'simple-popper' : undefined;

  const sortMeterReadings =(meterReadings)=>{
    return meterReadings.sort((a, b) => {
      if (a.meter?.allocationKey?.allocationKeyName < b.meter?.allocationKey?.allocationKeyName) {
        return -1; // a should come before b
      } else if (a.meter?.allocationKey?.allocationKeyName > b.meter?.allocationKey?.allocationKeyName) {
        return 1; // a should come after b
      } else {
        // a and b have the same age, compare by score
        if (a.meter?.meterNo < b.meter?.meterNo) {
          return -1;
        } else if (a.meter?.meterNo > b.meter?.meterNo) {
          return 1;
        }
      }
    });
  }

  const handleClick = (event) => {
    setOpenFilter(!openFilter);
  };
  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setOpenFilter(open);
  };
  console.log('viewState.meterModalOpened: ', viewState.meterModalOpened);
  // Go back to the previous page
  const goBack = () => 
    history.replace({
      pathname: '/meter'
    });
  
  const getBillingPeriods = () => {
    APIClient({
      method: "GET",
      url: `${config.propertyApi}BillingPeriods?propertyId=${chosenProperty}&offset=0&fetch=100`,
      headers: { Authorization: Autoload.Utils.getAuthToken() }
    })
    .then((response)=> {
      if(response.data.status && response.status === 200){
        setBillingPeriods(response.data.result);
      }
    })
  }
  // Get fresh data
  const getFreshData = () => {
    setReadings([]);

    setViewState({
      ...viewState,
      loading: true,
      meterModalOpened: false
    });

    APIClient({
      method: "GET",
      url: `${config.meter}occupationReadings?propertyId=${chosenProperty}&billingPeriodId=${id}`,
      headers: { Authorization: Autoload.Utils.getAuthToken() }
    })
      .then((response) => {

        // Put everything in view state
        setViewState({
          ...viewState,
          loading: false,
          hasChanged: false,
          meterModalOpened: false
        });

        // Rearrange data to make it easier to use
        response.data?.result?.filter((data) => (
          (!data.vacant) || 
          (data.vacant && data.periodEndReading?.occasion === 'annual') 
          || (data.vacant && data.periodEndReading?.occasion === 'move-in') 
          //|| (data.tenantFullName === '(leer)' && data.periodStartReading?.occasion === 'move-out')
        )).forEach((item, i) => {
          console.log(`ITEM ${i}: `, item);
          if (item.periodStartReading && item.periodStartReading?.occasionDate >= item.periodStart) {
            let editableLastIds = editableIds;
            if(item.periodStartReading && item.periodStartReading.occasion === 'move-in'){
              const { meterReadings } = item.periodStartReading;
              meterReadings.map((reading)=> (reading.startValue === null || reading.startValue === 0 || reading.startValue === '0,00') && editableLastIds.push(reading.id))
            }
            const periodReading = {
              ...item.periodStartReading,
              periodStart: item.periodStart,
              periodEnd: item.periodEnd,
              rentalUnitName: item.rentalUnitName,
              tenantFullName: item.tenantFullName,
              tenantId: item.tenantId,
              occupationId: item.id,
              vacant: item.vacant
            }
            setReadings(readings => [ ...readings, periodReading ]);
            setEditableIds(editableLastIds);
          };
          if (item.periodEndReading) {
            let { meterReadings } = item.periodEndReading;
            meterReadings= meterReadings.map((meterReading)=> {
              const meterReadingsFromPeriodStartReading = item.periodStartReading.meterReadings.find((meterReading1)=> meterReading1.meter.id === meterReading.meter.id);
              if(meterReadingsFromPeriodStartReading !== undefined){
                meterReading.startValue = {
                  id:   meterReadingsFromPeriodStartReading.id,
                  value: meterReadingsFromPeriodStartReading.currentValue,
                  notes: meterReadingsFromPeriodStartReading.notes
                };
              }
              return meterReading;
            });
            const periodReading = {
              ...item.periodEndReading,
              periodStart: item.periodStart,
              periodEnd: item.periodEnd,
              rentalUnitName: item.rentalUnitName,
              tenantFullName: item.tenantFullName,
              tenantId: item.tenantId,
              occupationId: item.id,
              vacant: item.vacant,
              occasion: (item.vacant && item.periodEndReading?.occasion === 'move-in') ? 'empty-tenant-move-out' : item.periodEndReading?.occasion
            }
            setReadings(readings => [ ...readings, periodReading ]);
          };
        });

        // We need this to compare data with changed one
        setInitialReadings(readings);
      })
      .catch((e) => {
        console.log('getFreshData: ', e);
        showNotification({
          id: 'retreivingData',
          //title: 'Fehler',
          message: 'Es ist ein Fehler aufgetreten. Bitte probieren Sie es erneut oder wenden Sie sich an den Support.',
          icon: <img src={RedCross} alt='red-cross'/>,
        });
      });
  }

  const getDropDownContent =()=> {
    APIClient({
      method: "GET",
      url: Autoload.Setting.property_api_base_url+"rentalUnits?propertyId="+propertySelected.id+"&offset=0&fetch=100",
      headers: { Authorization: Autoload.Utils.getAuthToken() }
    })
      .then((response) => {
        setRentalUnits(response.data?.result);
        APIClient({
          method: "GET",
          url: Autoload.Setting.property_api_base_url +
          'Tenants/TenantsInBillingPeriod?propertyId=' +
          propertySelected.id +
          '&billingPeriodId=' +
          id,
          headers: { Authorization: Autoload.Utils.getAuthToken() }
        })
          .then((response) => {
            setTenants(response.data?.result);
          })
          .catch((e) => {
            console.log('getDropDownContent: ', e);
          });
      })
      .catch((e) => {
        console.log('getDropDownContent: ', e);
      });
  }

  // Save fresh data
  const handleDataSaving = async () => {
    hideNotification('saveValue');
    setSavingPossible(true);
    console.log('changedMeterReadings: ', changedMeterReadings);
  //   const newReadings= JSON.parse(JSON.stringify(readings));
  // console.log('readings 200: ', newReadings.map((reading, index)=> {
  //   var reading1 = reading;
  //   var meterReadings1 = sortMeterReadings(reading1.meterReadings)//.sort((a, b) => a.meter.allocationKey.allocationKeyName.localeCompare(b.meter.allocationKey.allocationKeyName))
  //   .filter((meterReading)=> meterReading.currentValue >= meterReading.startValue || (meterReading.currentValue && meterReading.startValue === null) || (meterReading.currentValue === null && meterReading.startValue) || (meterReading.currentValue=== null && meterReading.startValue === null));
  //   reading1.meterReadings = meterReadings1;
  //   return reading1;
  // }));
  //   let requests = [];

  //   newReadings.forEach(reading => {
  //     console.log("Sending data: ", reading);
  //     requests.push(APIClient({
  //       method: 'PUT',
  //       url: `${config.meter}readings/${reading.id}`,
  //       data: reading,
  //       headers: { Authorization: Autoload.Utils.getAuthToken() }
  //     }))
  //   });

  //   axios.all(requests)
  //     .then(() => {
  //       showNotification({
  //         message: 'Die Änderungen wurden übernommen.',
  //         icon: <img src={GreenTick} alt='green-tick'/>,
  //         id: 'saveValue'
  //       });
  //       setSavingPossible(false);
  //       propertySelected.meterReadingConducted === false && saveMeterConducted();
  //       //getFreshData();
  //       window.Intercom("update", {
  //         api_base: "https://api-iam.intercom.io",
  //         app_id: "vvrs5bx3",
  //         'Meter Reading Conducted': true
  //       });
  //       window.Intercom('trackEvent', 'meter-reading-conducted');
  //     })
  //     .catch(() => {
  //       showNotification({
  //         message: 'Es ist ein Fehler aufgetreten. Bitte probieren Sie es erneut oder wenden Sie sich an den Support.',
  //         icon: <img src={RedCross} alt='red-cross'/>,
  //       });
  //       setSavingPossible(false);
  //     });

    if(changedMeterReadings.length > 0){

      let requests = [];
      changedMeterReadings.map((meterReading)=> {
        requests.push(
          APIClient({
          method: 'PUT',
          url: `${config.meter}meterReadings/${meterReading.id}`,
          data: meterReading,
          headers: { Authorization: Autoload.Utils.getAuthToken() }
          })
        );
        if(meterReading.startValue !== undefined){
          requests.push(
            APIClient({
            method: 'PUT',
            url: `${config.meter}meterReadings/${meterReading.startValue.id}`,
            data: {
              id: meterReading.startValue.id,
              currentValue: meterReading.startValue.value,
              notes: meterReading.startValue.notes
            },
            headers: { Authorization: Autoload.Utils.getAuthToken() }
            })
          );
        }
      });
      axios.all(requests).then(()=> {
        showNotification({
          message: 'Die Änderungen wurden übernommen.',
          icon: <img src={GreenTick} alt='green-tick'/>,
          id: 'saveValue'
        });
        setSavingPossible(false);
        propertySelected.meterReadingConducted === false && saveMeterConducted();
        window.Intercom("update", {
          api_base: "https://api-iam.intercom.io",
          app_id: "vvrs5bx3",
          'Meter Reading Conducted': true
        });
        window.Intercom('trackEvent', 'meter-reading-conducted');
      })
      .catch(()=> {
        showNotification({
          message: 'Es ist ein Fehler aufgetreten. Bitte probieren Sie es erneut oder wenden Sie sich an den Support.',
          icon: <img src={RedCross} alt='red-cross'/>,
        });
        setSavingPossible(false);
      });
    }

    
      
    setViewState({
      ...viewState, 
      hasChanged: false
    });
    setChangedMeterReadings([]);
  }

  const saveMeterConducted = () =>{
    let propertyData= {
      accountId: propertySelected.accountId,
      name: propertySelected.name,
      propertyAdress: {
        streetno: propertySelected.propertyAddress,
        city: propertySelected.propertyCity,
        zipCode: propertySelected.propertyZipCode,
      },
      totalRentingSpace: propertySelected.totalRentingSpace,
      numberOfRentalUnits: propertySelected.numberOfRentalUnits,
      cutOffDate: propertySelected.cutOffDate,
      heatingType: propertySelected.heatingType,
      heatingTemperature: propertySelected.heatingTemperature,
      consumptionShare: propertySelected.consumptionShare,
      receiptEmail: propertySelected.receiptEmail,
      meterReadingsEmail: propertySelected.meterReadingsEmail,
      meterReadingConducted: propertySelected.meterReadingConducted,
      propertyType: propertySelected.propertyType,
      energyFuelType: propertySelected.energyFuelType,
      centralWarmWaterAllocationKey: propertySelected.centralWarmWaterAllocationKey,
      id: propertySelected.id,
      isConsumptionSectionHidden: propertySelected.isConsumptionSectionHidden,
      isDocumentSectionHidden: propertySelected.isDocumentSectionHidden,
      isMessageSectionHidden: propertySelected.isMessageSectionHidden
    };
    propertyData.meterReadingConducted = true;
    APIClient({
      method: "PUT",
      data: propertyData,
      url: Autoload.Setting.property_api_base_url + 'properties/' + propertySelected.id,
      headers: { Authorization: Autoload.Utils.getAuthToken() }
    }).then((response) => {
      console.log('saveMeterConducted: ', response);
      if(response.status){
        let newPropertyData= propertySelected;
        newPropertyData.meterReadingConducted = true;
        updatePropetyInfo(newPropertyData);
      }
    })
  }

  // Find negative consumption in readings
  const findNegativeConsumption = () => {
    let consumptions = [];

    readings.forEach((reading) => {
      reading.meterReadings.forEach((meterReading) => consumptions.push(meterReading.consumption));
    });

    return consumptions.some(consumption => consumption < 0);
  }

  // Find negative current in readings
  const findNegativeCurrentReading = () => {
    // console.log('findNegativeCurrentReading: ', selectedUnit);
    // console.log('findNegativeCurrentReading: ', selectedTenant);
    // console.log('findNegativeCurrentReading: ', selectedOccasion);
    // let consumptions = [];
    // if(selectedUnit !== '-' || selectedTenant !== '-' || selectedOccasion !== '-'){
      
    //   readings.map((reading) => {
    //     if(selectedUnit === reading.rentalUnitId || 
    //     selectedTenant === reading.tenantId || 
    //     selectedOccasion === reading.occasion){
          
    //       selectedTenant !== '-' && selectedTenant === reading.tenantId && selectedOccasion !== '-' ? selectedOccasion === reading.occasion &&
    //       reading.meterReadings.sort((a, b) => a.meter.meterNo.localeCompare(b.meter.meterNo))
    //       .filter((meterReading) =>
    //         meterReading.meter.meterNo.toUpperCase().includes(viewState.search.toUpperCase()) ||
    //         meterReading.meter.allocationKey.allocationKeyName.toUpperCase().includes(viewState.search.toUpperCase()) ||
    //         dayjs(meterReading.meterReadingDate).format('DD.MM.YYYY').includes(viewState.search)
    //       )
    //       .forEach((meterReading) => consumptions.push({
    //         currentValue: meterReading.currentValue,
    //         startValue: meterReading.startValue
    //       })) : console.log('findNegativeCurrentReading: if') &&
    //       // selectedUnit === reading.rentalUnitId && selectedTenant === reading.tenantId && selectedOccasion === reading.occasion ?
    //       // reading.meterReadings.sort((a, b) => a.meter.meterNo.localeCompare(b.meter.meterNo))
    //       // .filter((meterReading) =>
    //       //   meterReading.meter.meterNo.toUpperCase().includes(viewState.search.toUpperCase()) ||
    //       //   meterReading.meter.allocationKey.allocationKeyName.toUpperCase().includes(viewState.search.toUpperCase()) ||
    //       //   dayjs(meterReading.meterReadingDate).format('DD.MM.YYYY').includes(viewState.search)
    //       // )
    //       // .forEach((meterReading) => consumptions.push({
    //       //   currentValue: meterReading.currentValue,
    //       //   startValue: meterReading.startValue
    //       // }))
    //       // :
    //       reading.meterReadings.sort((a, b) => a.meter.meterNo.localeCompare(b.meter.meterNo))
    //       .filter((meterReading) =>
    //         meterReading.meter.meterNo.toUpperCase().includes(viewState.search.toUpperCase()) ||
    //         meterReading.meter.allocationKey.allocationKeyName.toUpperCase().includes(viewState.search.toUpperCase()) ||
    //         dayjs(meterReading.meterReadingDate).format('DD.MM.YYYY').includes(viewState.search)
    //       )
    //       .forEach((meterReading) => consumptions.push({
    //         currentValue: meterReading.currentValue,
    //         startValue: meterReading.startValue
    //       }))
    //     }
    //   })
    // } else {
    //   console.log('findNegativeCurrentReading: else');
    //   readings.forEach((reading) => {
    //     reading.meterReadings.sort((a, b) => a.meter.meterNo.localeCompare(b.meter.meterNo))
    //     .filter((meterReading) =>
    //       meterReading.meter.meterNo.toUpperCase().includes(viewState.search.toUpperCase()) ||
    //       meterReading.meter.allocationKey.allocationKeyName.toUpperCase().includes(viewState.search.toUpperCase()) ||
    //       dayjs(meterReading.meterReadingDate).format('DD.MM.YYYY').includes(viewState.search)
    //     )
    //     .forEach((meterReading) => consumptions.push({
    //       currentValue: meterReading.currentValue,
    //       startValue: meterReading.startValue
    //     }));
    //   });
    // }

    //return consumptions.some(consumption => consumption.currentValue !== null && consumption.currentValue < consumption.startValue);
    return negativeValues.length > 0;
  }

  const _addNewMeter = (data) => {
    data.propertyId = propertySelected && propertySelected.id;
    const payload = MeterDataHandler.generateMeterData(data);
    let btnelement = document.getElementById('meter-button-container');
    APIClient({
      method: 'POST',
      url: Autoload.Setting.meter_api_base_url + 'meters',
      data: payload,
      headers: { Authorization: Autoload.Utils.getAuthToken() }
    })
    .then((response) => {
      if (response.status === false) Autoload.Redirection.redirection_error();
        getFreshData();
    }).catch((e)=>
      {
        if(e.response.request.status === 409){
          showNotification({
            id: 'meterDuplicate',
            message: "Diese Zählernummer wird bereits verwendet. Bitte wählen Sie eine andere Zählernummer.",
            icon: <img src={RedCross} />
          })
        }
      }
    )
  }

  const handleChange = (event, type) => {
    if(type === 'unit' && event.target.value ==='-'){
      setSelectedUnit('-');
      setSelectedTenant('-');
      setSelectedOccasion('-');
    } else if(type === 'tenant' && event.target.value ==='-') {
      setSelectedTenant('-');
    }
     else if(type === 'occasion' && event.target.value ==='-') {
      setSelectedOccasion('-');
    }
    else {
      if(type === 'unit'){   
        setSelectedUnit(event.target.value); 
        setSelectedTenant('-');
        setSelectedOccasion('-');
      }
      else if(type === 'tenant'){ 
        setSelectedTenant(event.target.value); 
        setSelectedUnit(tenants.find((item)=> item.tenant.id === event.target.value).tenant.rentalUnitId);
      }
      else if(type === 'occasion'){
        setSelectedOccasion(event.target.value);
      }
    }
    if(event.target.value ==='-' && viewState.hasChanged === true){
      getFreshData();
      setOpenFilter(false);
    }
    setNegativeValues([]);
  };

  const handleReset = (event) => {
      setSelectedUnit('-');
      setSelectedTenant('-');
      setSelectedOccasion('-');
      if(viewState.hasChanged === true){
        //getFreshData();
        setOpenFilter(false);
      }
      setNegativeValues([]);
  };
  
  useEffect(()=>{
    window.addEventListener('resize', () =>{
      isMobile= window.innerWidth <= MOBILE_SIZE;
    },[window.innerWidth]);
  })
  // Check for negative values when readings changes
  useEffect(() => {
    console.log("READINGS: ", findNegativeCurrentReading());
    setViewState({ 
      ...viewState, 
      //negativeConsumptionPresent: findNegativeConsumption(),
      negativeCurrentReading: findNegativeCurrentReading()
     });
    setSavingPossible(!viewState.negativeCurrentReading && viewState.hasChanged);
  }, [readings, selectedUnit, selectedTenant, selectedOccasion, viewState.search, selectedTables]);

  // Check if consumption is negative when meter readings changes
  useEffect(() => {
    if (//viewState.negativeConsumptionPresent === true || 
      viewState.negativeCurrentReading === true) {
      setSavingPossible(false);
      showNotification({
        id: 'negativeValue',
        //disallowClose: true,
        //title: 'Fehler',
        autoClose: false,
        message: 'Die Eingaben sind fehlerhaft. Bitte überprüfen Sie die Werte.',
        icon: <img src={RedCross} alt='red-cross'/>,
      });
    } 
    // else if (viewState.negativeCurrentReading === true) {
    //   setSavingPossible(false);
    //   showNotification({
    //     id: 'negativeValue',
    //     //disallowClose: true,
    //     title: 'Fehler',
    //     autoClose: false,
    //     message: 'Eingaben fehlerhaft. Bitte überprüfen Sie Ihre Eingaben.',
    //     icon: <img src={RedCross} alt='red-tick'/>,
    //   });
    // } 
    else {
      setSavingPossible(viewState.hasChanged);
      hideNotification('negativeValue');
      //hideNotification('negativeCurrentValue');
    }
  }, [viewState.negativeCurrentReading]);

  useEffect(() => {
    console.log('rentalUnits: ', showArticleCondition);
    if(rentalUnits !== null && rentalUnits.length > 0){
      setShowArticleCondition('rental_unit_created');
    }else{
      setShowArticleCondition('');
    }
  }, [rentalUnits]);

  // Get the data from the API
  useEffect(() => {
    getBillingPeriods();
    getFreshData();
    getDropDownContent();
  }, []);

  useEffect(() => {
    console.log('useEffect: selectedTables: ', selectedTables);
    setMeterReadingsToPrint(selectedTables);
  }, [selectedTables]);

  //Unmount
  useEffect(() => {
    // returned function will be called on component unmount 
    return () => {
      hideNotification('negativeValue');
      hideNotification('retreivingData');
      hideNotification('saveValue');
    }
  }, [])

  const updateAllRelatedValues=(allReadings, meterReadingToUpdate, val, start=false, index, i)=>{
    let meterReadingsToUpdate =[];
    allReadings.map((freshReading, readingIndex)=> sortMeterReadings(freshReading.meterReadings.map((meterReading, meterReadingIndex)=> (readingIndex !== i && meterReadingIndex !== index) && (meterReading.id === meterReadingToUpdate.id || meterReading?.startValue?.id === meterReadingToUpdate.id) && meterReadingsToUpdate.push(meterReading))));
    
    if(meterReadingsToUpdate.length > 0){
      return meterReadingsToUpdate.map((meterReading)=> {
        if(meterReading?.startValue?.id === meterReadingToUpdate.id){
          meterReading.startValue.value = val;
        }else if(start){
          meterReading.startValue.value = val;
        }else{
          meterReading.currentValue = val;
        }
        return meterReading;
      });
    }else{
      return [];
    }
  }

  const renderCross=()=>{
    return(
      <img src={CrossIcon} alt='cross-icon' style={{cursor: 'pointer'}} onClick={()=>{
        goBack();
        hideNotification('negativeValue');
        hideNotification('retreivingData');
        hideNotification('saveValue');
      }}/>
    )
  }
  
  return (
    <Stack p={0} sx={{ margin: -24 }}>{/* We need this negative margin here because of global structure changes, look at src/layouts/Main.js:228 for more details */}
{console.log('readings.map: ', readings.map((reading)=> reading.id))}
{console.log('readings: ', readings.find((reading)=> reading.meterReadings.find((meterReading)=> meterReading.startValue !== undefined) !== undefined))}
      {viewState.issues && viewState.issues.map((issue) =>
        (<Notification color="green" title="Fehler">
          {issue.message}
        </Notification>)
      )}

      {/* Meter modal */}
      {/* <MeterModal
        // meter={meter}
        className="add-meter-modal"
        open={viewState.meterModalOpened}
        addNewMeter={(newMeterData) => this._addNewMeter(newMeterData)}
        toggle={() => this.setState({ openMeterModal: !this.state.openMeterModal })}
        propertyId={this.props.propertySelected.id}
        disableRentalUnit={true}
        currentMeter={this.state.rentalUnitNameForNewMeterReading}
      /> */}

      {/* Heading */}
      <Group position="apart" sx={(theme) => ({ borderBottom: `1px solid ${theme.colors.divider}`, margin: '24px 24px 0 24px' })}>
        <Stack style={{width: '100%', marginBottom: '19px'}}>
          {!isMobile && 
          <Group style={{marginTop: isMobile && '5px'}} position={'apart'}>
            <Group>
              {renderCross()}
              <Text className="meter-management-readings-heading" component="h1" size="xl" sx={{ margin: 0, fontWeight: '600', fontSize: isMobile && '18px' }}>Ablesungen</Text>
            </Group>
          
            <Group style={{//display: isMobile && 'flex', justifyContent: isMobile && 'space-between', 
            width: isMobile && 'auto'}}>
              <ColorButton
                onClick={() => {
                  goBack();
                  hideNotification('negativeValue');
                  hideNotification('retreivingData');
                  hideNotification('saveValue');
                }}
                text={'Schließen'}
                className="gray-btn"
                buttonStyle={{marginLeft: 0}}
              />
              {/* {!isMobile && 
              <ColorButton
                onClick={handleDataSaving}
                disabled={!viewState.hasChanged || !savingPossible}
                text={'Speichern'}
                className="purple-btn"
                textStyle={{color: (!viewState.hasChanged || !savingPossible) && 'rgb(173, 181, 189)'}}
                buttonStyle={{cursor: (!viewState.hasChanged || !savingPossible) ? 'not-allowed' : 'pointer'}}
              />} */}
            </Group>
          </Group>}
          <Group noWrap position='apart'>
            <div style={{display: 'flex'}}>
                {breadcrumbsContent.map((item)=> item.area === '/meter/reading/' && 
                item.breadcrumbs.map((breadcrumb)=>
                breadcrumb.type === 'tour' ? breadcrumb.condition === showArticleCondition && breadcrumb.view === (isMobile ? 'mobile' : 'desktop') &&
                <div style={{display: 'flex', width: 'auto', alignItems: 'center', cursor: 'pointer', marginRight: '22px'}} onClick={()=> window.Intercom('startTour', breadcrumb.id)}>
                  <img src={TourIcon} />
                  <span style={{color: '#5555FF', marginLeft: '5px', fontSize: '14px'}}>{breadcrumb.label}</span>
                </div>
                :
                breadcrumb.view === (isMobile ? 'mobile' : 'desktop') &&
                <div style={{display: 'flex', width: 'auto', alignItems: 'center', cursor: 'pointer', marginRight: '22px'}} onClick={()=> window.Intercom('showArticle', breadcrumb.id)}>
                  <img src={HelpLogo} />
                  <span style={{color: '#5555FF', marginLeft: '5px', fontSize: '14px'}}>{breadcrumb.label}</span>
                </div>
                ))}
            </div>
            {isMobile && renderCross()}
          </Group>
          {/* Search bar and select */}
          <Group>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
                    <Group style={{maxWidth: isMobile && '100%', minWidth: '150px', marginRight: '15px'}} noWrap>
                      <TextInput className="meter-readings-searchbar" placeholder="Suche nach Zählernummer, Zählerart, Datum..." value={viewState.search} icon={<Icon name="search" />} sx={{ width: 380 }} onChange={(event) => {setViewState({ ...viewState, search: event.target.value }); setNegativeValues([]);}} />
                      {/* <SwitchContainer
                      className="heating-cost-switch"
                      onChange={() => setShowArchivedMeters(!showArchivedMeters)}
                      checked={showArchivedMeters}
                      switchText={'archivierte Zähler einblenden'}
                      disabled={false}
                      /> */}
                    </Group>
                    <div style={{display: 'flex'}}>
                      {!isMobile &&
                      <div style={{display: 'flex', alignItems: 'center', marginRight: '10px'}}>
                        {!isMobile && <Text sx={(theme) => ({ color: '#454576', fontSize: '14px', marginRight: '5px' })}>Drucken:</Text>}
                        <Tooltip title={selectedTables.length === 0 ? 'Bitte wählen Sie eine oder mehrere Ablesungen aus, die Sie drucken wollen.' : "Drucken"} >
                          <IconButton 
                          //disabled={selectedTables.length === 0}
                          aria-label="filter list" 
                          onClick={()=> selectedTables.length > 0 && window.open(`/meterReadingPdf/${id}?occupationReadings=${JSON.stringify(selectedTables)}`, '_blank')} 
                          style={{
                            background: selectedTables.length > 0 ? '#5655FE' : 'white', 
                            border: '1px solid #DADAE5', 
                            boxShadow: '0 1px 2px 0 rgba(14, 14, 29, .06)', 
                            padding: '5px'
                            }}>
                            <img
                            src={selectedTables.length > 0 ? PrintIconWhite : PrintIconBlue} 
                            style={{
                              width: '15px',
                              height: '15px',
                              }}/>
                          </IconButton>
                        </Tooltip>
                      </div>}
                      <div style={{display: 'flex', alignItems: 'center'}}>
                        {!isMobile && <Text sx={(theme) => ({ color: '#454576', fontSize: '14px', marginRight: '5px' })}>Filtern:</Text>}
                        <Tooltip title="Filtern" >
                          <IconButton 
                          aria-label="filter list" 
                          onClick={handleClick} 
                          style={{
                            background: (selectedTenant !== '-' || selectedUnit !== '-'  || selectedOccasion !== '-') ? '#5655FE' : 'white', 
                            border: '1px solid #DADAE5', 
                            boxShadow: '0 1px 2px 0 rgba(14, 14, 29, .06)', 
                            padding: '5px'
                            }}>
                            <FilterListIcon 
                            style={{
                              color: (selectedTenant !== '-' || selectedUnit !== '-'  || selectedOccasion !== '-') ? 'white' : '#5655FE', 
                              fontSize: '15px'
                              }}/>
                          </IconButton>
                        </Tooltip>
                      </div>
                      <div>
                        <Drawer anchor={'right'} open={openFilter} onClose={toggleDrawer(false)} style={{zIndex: 1000000}}>
                          <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100vh'}}>
                          <div style={{
                              padding: '10px'}}>
                              <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '2px'}}>
                                <Text sx={(theme) => ({ color: '#111147', fontSize: '16px', margin: '10px' })}>Ablesungen filtern</Text>
                                <IconButton size='medium'  onClick={toggleDrawer(false)}>
                                  <CloseIcon style={{
                                    color: '#727293'
                                    }}
                                    />
                                </IconButton>
                              </div>
                              <Divider/>
                              <div>
                                <div style={{margin: '15px'}}>
                                  <InputLabel id="demo-simple-select-outlined-label">Einheit</InputLabel>
                                  <FormControl variant="outlined" style={{width: '250px'}}>
                                    <Select
                                      labelId="demo-simple-select-outlined-label"
                                      id="demo-simple-select-outlined"
                                      className="selectMenu"
                                      value={selectedUnit}
                                      style={{height: '40px'}}
                                      MenuProps={{
                                        anchorOrigin: {
                                          vertical: "bottom",
                                          horizontal: "left"
                                        },
                                        transformOrigin: {
                                          vertical: "top",
                                          horizontal: "left"
                                        },
                                        style: {
                                          zIndex: 10000000
                                        },
                                        getContentAnchorEl: null,
                                      }}
                                      onChange={(e)=> handleChange(e, 'unit')}
                                    >
                                      <MenuItem value={'-'}><span style={{fontStyle: 'italic'}} defaultValue>Alle</span></MenuItem>
                                      {rentalUnits !== null && rentalUnits?.length > 0 && rentalUnits.map((unit)=>
                                        <MenuItem value={unit.id}>{unit.name}</MenuItem>
                                      )}
                                    </Select>
                                  </FormControl>
                                </div>
                                <div style={{margin: '15px'}}>
                                  <InputLabel id="demo-simple-select-outlined-label">Mietverhältnis</InputLabel>
                                  <FormControl variant="outlined" style={{width: '250px'}}>
                                    <Select
                                      labelId="demo-simple-select-outlined-label"
                                      id="demo-simple-select-outlined"
                                      value={selectedTenant}
                                      disabled={selectedUnit === ''}
                                      style={{height: '40px'}}
                                      MenuProps={{
                                        anchorOrigin: {
                                          vertical: "bottom",
                                          horizontal: "left"
                                        },
                                        transformOrigin: {
                                          vertical: "top",
                                          horizontal: "left"
                                        },
                                        style: {zIndex: 10000000},
                                        getContentAnchorEl: null
                                      }}
                                      onChange={(e)=> handleChange(e, 'tenant')}
                                    >
                                      <MenuItem value={'-'}><span style={{fontStyle: 'italic'}}>Alle</span></MenuItem>
                                      {tenants.map((item)=>
                                        selectedUnit !== '-' ? (
                                          selectedOccasion !== '-' ? (readings.some((reading)=> reading.occasion === selectedOccasion && item.tenant.id === reading.tenantId && reading.rentalUnitId === selectedUnit) &&
                                        <MenuItem value={item.tenant.id}>{item.tenant.fullName}</MenuItem>) :
                                        item.tenant.rentalUnitId === selectedUnit && <MenuItem value={item.tenant.id}>{item.tenant.fullName}</MenuItem>)
                                        : 
                                        selectedOccasion !== '-' ? (readings.some((reading)=> reading.occasion === selectedOccasion && item.tenant.id === reading.tenantId) &&
                                        <MenuItem value={item.tenant.id}>{item.tenant.fullName}</MenuItem>)
                                        : 
                                        <MenuItem value={item.tenant.id}>{item.tenant.fullName === '(leer)' ? (`${rentalUnits.find((unit)=> unit.id === item.tenant.rentalUnitId).name}: ${item.tenant.fullName}` ) : item.tenant.fullName}</MenuItem>
                                      )}
                                    </Select>
                                  </FormControl>
                                </div>
                                <div style={{margin: '15px'}}>
                                  <InputLabel id="demo-simple-select-outlined-label">Anlass</InputLabel>
                                  <FormControl variant="outlined" style={{width: '250px'}}>
                                    <Select
                                      labelId="demo-simple-select-outlined-label"
                                      id="demo-simple-select-outlined"
                                      value={selectedOccasion}
                                      style={{height: '40px'}}
                                      MenuProps={{
                                        anchorOrigin: {
                                          vertical: "bottom",
                                          horizontal: "left"
                                        },
                                        transformOrigin: {
                                          vertical: "top",
                                          horizontal: "left"
                                        },
                                        style: {zIndex: 10000000},
                                        getContentAnchorEl: null
                                      }}
                                      onChange={(e)=> handleChange(e, 'occasion')}
                                    >
                                      <MenuItem value={'-'}><span style={{fontStyle: 'italic'}}>Alle</span></MenuItem>
                                      {occasions.map((occasion)=> (selectedTenant !== '-' && selectedUnit !== '-') ? 
                                      readings.some((reading)=> ((selectedTenant === reading.tenantId) && (selectedUnit === reading.rentalUnitId)) && (reading.occasion === occasion.value)) && <MenuItem value={occasion.value}>{occasion.name}</MenuItem> :
                                      (selectedTenant !== '-' || selectedUnit !== '-') ? 
                                      readings.some((reading)=> ((selectedTenant === reading.tenantId) || (selectedUnit === reading.rentalUnitId)) && (reading.occasion === occasion.value)) && <MenuItem value={occasion.value}>{occasion.name}</MenuItem> :
                                      <MenuItem value={occasion.value}>{occasion.name}</MenuItem>)}
                                    </Select>
                                  </FormControl>
                                </div>
                              </div>
                          </div>
                          <div style={{margin: '15px', display: 'flex', justifyContent: 'space-between'}}>
                            <ColorButton
                            disabled={selectedTenant === '-' && selectedUnit === '-'  && selectedOccasion === '-'}
                            onClick={(e)=> handleReset(e)}
                            className={selectedTenant === '-' && selectedUnit === '-'  && selectedOccasion === '-' ? "gray-btn" : 'purple-btn'}
                            text={'Zurücksetzen'}
                            buttonStyle={{
                              cursor: selectedTenant === '-' && selectedUnit === '-'  && selectedOccasion === '-' && 'not-allowed',
                              margin: '0 4px 0 4px'
                            }}
                            />
                            <ColorButton
                            onClick={handleClick}
                            className={"gray-btn"}
                            text={'Schließen'}
                            buttonStyle={{
                              margin: '0 4px 0 4px'
                            }}
                            />
                            {/* <Button disabled={selectedTenant === '-' && selectedUnit === '-'  && selectedOccasion === '-'}  
                            variant={selectedTenant === '-' && selectedUnit === '-'  && selectedOccasion === '-' ? "outlined" : 'filled'}
                            onClick={(e)=> handleReset(e)}>
                              Zurücksetzen
                            </Button> */}
                          </div>
                          </div>
                        </Drawer>
                      </div>
                    </div>
            </div>
          </Group>
        </Stack>
      </Group>

      {/* Content */}
      <Box px={24} py={24} pt={0}>
        {viewState.loading
          ? (
            <Center sx={{ height: isMobile ? '50vh' : 'calc(100vh - 100px)' }}>
              <Loader/>
            </Center>
          )
          : (
            <>
              {/* Datas */}
              {console.log('selectedUnit: ', selectedUnit)}
              {readings && readings
              .sort((a,b)=> new Date(a.occasionDate) - new Date(b.occasionDate))
              .sort((a,b)=> (b.rentalUnitName === null) - (a.rentalUnitName === null) || (a.rentalUnitName?.toLowerCase().localeCompare(b.rentalUnitName?.toLowerCase())))
              .map((_, i) => (
                (selectedUnit === '-' || selectedUnit === readings[i].rentalUnitId || selectedUnit.length === 0 && readings[i].rentalUnitId === null) &&
                (selectedTenant === '-' || selectedTenant === readings[i].tenantId || selectedTenant.length === 0 && readings[i].tenantId === null) &&
                (selectedOccasion === '-' || selectedOccasion === readings[i].occasion) &&
                <>
                  {i < 2 &&
                  <Group spacing={10} style={{marginTop: i > 0 && (isMobile ? '16px' : '32px'), marginLeft: '2px'}}>
                    <img style={{marginTop: readings[i].rentalUnitName && '3px'}} src={readings[i].rentalUnitName ? MultipleTenants : PropertyIcon}/> 
                    <span style={{fontSize: '16px', fontWeight: '600', marginTop: '3px', color: '#0E0E1D'}}>{readings[i].rentalUnitName ? 'Ablesung Mieterzähler' : 'Ablesung Hauszähler'}</span>
                  </Group>}
                  
                  <SingleTable
                    viewState={viewState}
                    data={readings[i]} 
                    readings={readings}
                    index={i}
                    rentalUnits={rentalUnits}
                    history={history}
                    tenantFullName={readings[i].tenantFullName}
                    vacant={readings[i].vacant}
                    rentalUnitName={readings[i].rentalUnitName}
                    rentalUnitId={readings[i].rentalUnitId}
                    periodStart={readings[i].periodStart}
                    periodEnd={readings[i].periodEnd}
                    occasion={readings[i].occasion}
                    occasionDate={readings[i].occasionDate}
                    selectedTenant={selectedTenant}
                    selectedOccasion={selectedOccasion}
                    selectedUnit={selectedUnit}
                    editableIds={editableIds}
                    isMobile={isMobile}
                    billingPeriods={billingPeriods}
                    billingPeriodId={id}
                    showArchivedMeters={showArchivedMeters}
                    onTableSelect={(table)=> {  
                      let tables = selectedTables;
                      // var index = tables.indexOf(table.readingsId);
                      // if (index !== -1) {
                      //   tables.splice(index, 1);
                      // }else{
                      //   tables.push(readingsId);
                      // }
                      if(tables.find((item)=> item.occupationId === table.occupationId && item.readingsId === table.readingsId) !== undefined){
                        console.log('onTableSelect: remove');
                        tables = tables.filter((item)=> item.readingsId !== table.readingsId);
                      }else{
                        console.log('onTableSelect: add');
                        tables.push(table);
                      }
                      setSelectedTables([...tables]);
                      console.log('selectedTables: ', selectedTables);
                      setMeterReadingsToPrint(selectedTables);
                    }}
                    onBlur={(negativeValue)=> (viewState.hasChanged && !negativeValue) ? handleDataSaving() : console.log('viewState.hasChanged ', viewState.hasChanged, !negativeValue) //viewState.hasChanged && //!negativeValue && handleDataSaving()
                    }//&& !viewState.negativeCurrentReading
                    onAddMeter={(rentalUnitId)=> {setViewState({ ...viewState, meterModalOpened: true}); setSelectedRentalUnitId(rentalUnitId);}}
                    handleDataSaving={handleDataSaving}
                    disableMobileUpdate={!viewState.hasChanged || !savingPossible}
                    onNegativeValue={(negativeValuesArr)=> {
                      let negativeArr = negativeValues;
                      if(negativeValuesArr.length !== 0){
                        negativeValuesArr.map((value)=> negativeArr.push(value));
                      }
                      setNegativeValues(negativeArr)
                    }}
                    onDateChange={(value, index, meterId) => {
                      // const freshReadings = JSON.parse(JSON.stringify(readings)); // Create a deep copy of the object to work on without modyfing oiriginal one

                      // //freshReadings[i].occasionDate = dayjs(value).utc(true).format();
                      // freshReadings[i].meterReadings.sort((a, b) => a.meter.meterNo.localeCompare(b.meter.meterNo))[index].meterReadingDate = dayjs(value).utc(true).format();

                      // setReadings(freshReadings);
                      // setViewState({ ...viewState, hasChanged: JSON.stringify(freshReadings) !== JSON.stringify(initialReadings) })
                      // setNegativeValues([]);
                    }}
                    onLastReadingEdit={(value, index, sync, meterId) => {
                      // Startwert	editing
                      let newChangedMeterReadings = sync ? changedMeterReadings : [];
                      const freshReadings = JSON.parse(JSON.stringify(readings)); // Create a deep copy of the object to work on without modyfing oiriginal one
                      const val = typeof value?.value === 'number' ? value?.value : null;

                      viewState.search ? sortMeterReadings(freshReadings[i].meterReadings)//.sort((a, b) => a.meter.allocationKey.allocationKeyName.localeCompare(b.meter.allocationKey.allocationKeyName))
                      .find((meterReading)=> meterReading.meter.id === meterId).startValue.value = val :
                      sortMeterReadings(freshReadings[i].meterReadings)[index].startValue.value = val;//.sort((a, b) => a.meter.allocationKey.allocationKeyName.localeCompare(b.meter.allocationKey.allocationKeyName))

                      let foundMeterReading = viewState.search ? sortMeterReadings(freshReadings[i].meterReadings).find((meterReading)=> meterReading.meter.id === meterId) 
                      :
                      sortMeterReadings(freshReadings[i].meterReadings)[index];

                      newChangedMeterReadings.push(foundMeterReading)
                      // console.log('foundMeterReading: ', foundMeterReading);
                      // if(newChangedMeterReadings?.find((meterReading)=> meterReading.id === foundMeterReading.id) === undefined){
                        
                      // }else{
                      //   newChangedMeterReadings = newChangedMeterReadings.map((meterReading)=> meterReading.id === foundMeterReading.id ? foundMeterReading : meterReading);
                      // }
                      

                      //find same id to update
                      if(sync === true){
                        updateAllRelatedValues(freshReadings, sortMeterReadings(freshReadings[i].meterReadings).find((meterReading)=> meterReading.meter.id === meterId), val, false, index, i);
                      }


                      // Autofill related succeeding fields
                      if (sync === true && i !== 0 && freshReadings[i - 1] !== undefined && freshReadings[i].rentalUnitId === freshReadings[i - 1].rentalUnitId && sortMeterReadings(freshReadings[i - 1].meterReadings)[index])//.sort((a, b) => a.meter.allocationKey.allocationKeyName.localeCompare(b.meter.allocationKey.allocationKeyName)) //condition removed freshReadings[i].tenantId === freshReadings[i - 1].tenantId
                      {
                        if(viewState.search){
                          let meterReadingToUpdate = sortMeterReadings(freshReadings[i - 1].meterReadings)//.sort((a, b) => a.meter.allocationKey.allocationKeyName.localeCompare(b.meter.allocationKey.allocationKeyName))
                          .find((meterReading)=> meterReading.meter.id === meterId);
                          // if(meterReadingToUpdate.currentValue === null){ //soft sync logic
                            meterReadingToUpdate.currentValue = val;

                            newChangedMeterReadings.push(meterReadingToUpdate);
                            updateAllRelatedValues(freshReadings, meterReadingToUpdate, val, false);
                          // }
                        } else {
                          let meterReadingToUpdate = sortMeterReadings(freshReadings[i - 1].meterReadings)[index];//.sort((a, b) => a.meter.allocationKey.allocationKeyName.localeCompare(b.meter.allocationKey.allocationKeyName))
                          // if(meterReadingToUpdate.currentValue === null){ //soft sync logic
                            meterReadingToUpdate.currentValue = val;

                            newChangedMeterReadings.push(meterReadingToUpdate);
                            updateAllRelatedValues(freshReadings, meterReadingToUpdate, val, false);
                          // }
                        }
                        
                      }
                      
                      console.log('array_changedMeterReadings: ', changedMeterReadings);
                      console.log('onLastReadingEdit: ', JSON.stringify(freshReadings) !== JSON.stringify(initialReadings));
                      sync && setChangedMeterReadings(newChangedMeterReadings);
                      setReadings(freshReadings);
                      setViewState({ ...viewState, hasChanged: JSON.stringify(freshReadings) !== JSON.stringify(initialReadings) });
                      setNegativeValues([]);
                    }}
                    onCurrentReadingEdit={(value, index, sync, meterId) => {
                      // Ablesewert editing
                      let newChangedMeterReadings = sync ? changedMeterReadings : [];
                      const freshReadings = JSON.parse(JSON.stringify(readings)); // Create a deep copy of the object to work on without modyfing oiriginal one
                      const val = typeof value === 'number' ? value : null;

                      viewState.search ? sortMeterReadings(freshReadings[i].meterReadings)//.sort((a, b) => a.meter.allocationKey.allocationKeyName.localeCompare(b.meter.allocationKey.allocationKeyName))
                      .find((meterReading)=> meterReading.meter.id === meterId).currentValue = val :
                      sortMeterReadings(freshReadings[i].meterReadings)[index].currentValue = val;//.sort((a, b) => a.meter.allocationKey.allocationKeyName.localeCompare(b.meter.allocationKey.allocationKeyName))

                      let foundMeterReading = viewState.search ? sortMeterReadings(freshReadings[i].meterReadings).find((meterReading)=> meterReading.meter.id === meterId) 
                      :
                      sortMeterReadings(freshReadings[i].meterReadings)[index];

                      newChangedMeterReadings.push(foundMeterReading);



                      //find same id to update
                      // if(sync === true){
                      //   newChangedMeterReadings.push(updateAllRelatedValues(freshReadings, sortMeterReadings(freshReadings[i].meterReadings).find((meterReading)=> meterReading.meter.id === meterId), val));
                      // }

                      if(sync === true){
                        updateAllRelatedValues(freshReadings, sortMeterReadings(freshReadings[i].meterReadings).find((meterReading)=> meterReading.meter.id === meterId), val);
                      }
                      

                      // Autofill related field
                      if (sync === true && i < freshReadings.length && freshReadings[i + 1] !== undefined && freshReadings[i].tenantId === freshReadings[i + 1].tenantId) {
                        if(viewState.search){
                          let meterReadingToUpdate = sortMeterReadings(freshReadings[i + 1].meterReadings)//.sort((a, b) => a.meter.allocationKey.allocationKeyName.localeCompare(b.meter.allocationKey.allocationKeyName))
                          .find((meterReading)=> meterReading.meter.id === meterId);
                          // if(meterReadingToUpdate.startValue?.value === null){ //soft sync logic
                            meterReadingToUpdate.startValue.value = val;

                            newChangedMeterReadings.push(meterReadingToUpdate);
                            
                            //updateAllRelatedValues(freshReadings, meterReadingToUpdate, val, true);
                          // }
                        } else {
                          let meterReadingToUpdate = sortMeterReadings(freshReadings[i + 1].meterReadings)[index];//.sort((a, b) => a.meter.allocationKey.allocationKeyName.localeCompare(b.meter.allocationKey.allocationKeyName))
                          // if(meterReadingToUpdate.startValue?.value === null){//soft sync logic
                            meterReadingToUpdate.startValue.value = val;

                            newChangedMeterReadings.push(meterReadingToUpdate);

                            //updateAllRelatedValues(freshReadings, meterReadingToUpdate, val, true);
                          // }
                        } 
                      }

                      console.log('array_changedMeterReadings: ', newChangedMeterReadings);
                      sync && setChangedMeterReadings(newChangedMeterReadings);
                      setReadings(freshReadings);
                      setViewState({ ...viewState, hasChanged: JSON.stringify(freshReadings) !== JSON.stringify(initialReadings) });
                      setNegativeValues([]);
                    }}
                    onNotesEdit={(value, index, meterId) => {
                      let newChangedMeterReadings = [];
                      const freshReadings = JSON.parse(JSON.stringify(readings)); // Create a deep copy of the object to work on without modyfing oiriginal one
                      const val = value;
                      // sortMeterReadings(freshReadings[i].meterReadings)[index].notes = value;//.sort((a, b) => a.meter.allocationKey.allocationKeyName.localeCompare(b.meter.allocationKey.allocationKeyName))

                      viewState.search ? sortMeterReadings(freshReadings[i].meterReadings)//.sort((a, b) => a.meter.allocationKey.allocationKeyName.localeCompare(b.meter.allocationKey.allocationKeyName))
                      .find((meterReading)=> meterReading.meter.id === meterId).notes = val :
                      sortMeterReadings(freshReadings[i].meterReadings)[index].notes = val;//.sort((a, b) => a.meter.allocationKey.allocationKeyName.localeCompare(b.meter.allocationKey.allocationKeyName))

                      let foundMeterReading = viewState.search ? sortMeterReadings(freshReadings[i].meterReadings).find((meterReading)=> meterReading.meter.id === meterId) 
                      :
                      sortMeterReadings(freshReadings[i].meterReadings)[index];

                      newChangedMeterReadings.push(foundMeterReading);

                      setReadings(freshReadings);
                      setViewState({ ...viewState, hasChanged: JSON.stringify(freshReadings) !== JSON.stringify(initialReadings) });
                      setChangedMeterReadings(newChangedMeterReadings);
                      setNegativeValues([]);
                    }}
                    onConsumptionChange={(value, index) => {
                      const freshReadings = JSON.parse(JSON.stringify(readings)); // Create a deep copy of the object to work on without modyfing oiriginal one
                      sortMeterReadings(freshReadings[i].meterReadings)[index].consumption = value;//.sort((a, b) => a.meter.allocationKey.allocationKeyName.localeCompare(b.meter.allocationKey.allocationKeyName))

                      setReadings(freshReadings);
                      setViewState({ ...viewState, hasChanged: JSON.stringify(freshReadings) !== JSON.stringify(initialReadings) });
                      setNegativeValues([]);
                    }}
                  />
                  {/* {i === 0 && <div className="divider" style={{marginTop: '32px'}}/>} */}
                </>
              ))}
            </>
          )
        }
      </Box>
      {
          viewState.meterModalOpened && (
            <MeterModal
              history={history}
              meter={null}
              className="add-meter-modal"
              open={viewState.meterModalOpened}
              addNewMeter={(newMeterData) => _addNewMeter(newMeterData)}
              toggle={() => setViewState({ ...viewState, meterModalOpened: !viewState.meterModalOpened })}
              propertyId={propertySelected.id}
              disableRentalUnit={true}
              rentalUnitId={selectedRentalUnitId}
            />
          )
        }
    </Stack>
  )
}

function mapStateToProps(state) {
  console.log('mapStateToProps: ', state.generalReducer.meterReadingsToPrint);
  return {
    propertySelected: state.generalReducer.property,
    rentalUnits: state.tenantDataReducer.rentalUnits,
    selectedMeterReadings: state.generalReducer.meterReadingsToPrint
  };
}

const actions = {
  updatePropetyInfo,
  setMeterReadingsToPrint
};

export default connect(mapStateToProps, actions)(AnnualReadingById);
