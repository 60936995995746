import RightArrowPartner from '../../assets/images/module/general/RightArrowPartner.svg';
const isMobile = window.innerWidth < 500;
const du = {
  general: {
    text_dashboard: 'Übersicht',
    text_property: 'Immobilie',
    text_utilitybilling: 'Betriebskosten',
    text_receipt: 'Belege',
    text_meter: 'Ablesungen',
    text_tenants: 'Mieter',
    text_help: 'Hilfe',
    text_welcome: `Testen Sie hellohousing einen Monat kostenlos.`,
    text_email: 'E-Mail Adresse',
    text_password: 'Passwort',
    text_forgot_password: 'Passwort vergessen',
    text_login: 'Anmelden',
    text_invalid_login: 'Nutzername oder Passwort sind nicht korrekt. Bitte wiederholen Sie Ihre Eingabe oder wenden Sie sich an den hellohousing Service.',
    text_invalid_role_permission_login: 'Sie haben keinen Zugriff auf diesen Bereich. Bei Fragen wenden Sie sich bitte an den hellohousing Service.',
    text_noaccount: 'Noch nicht registriert',
    text_signup: 'Hier registrieren',
    text_forgot_pass_heading: 'Passwort vergessen',
    text_forgot_pass_instuctions: 'Bitte geben Sie hier Ihre E-Mail Adresse ein, um Ihr Passwort zurückzusetzen.',
    text_go_back: 'Zurück',
    text_informational_msg: 'Hinweis',
    text_login_with_new_pass: 'Sie können sich jetzt mit Ihrem neuen Passwort anmelden.',
    text_Enter_password: 'Passwort eingeben',
    text_reset_password: 'Legen Sie Ihr neues Passwort fest.',
    text_send_instructions: 'Senden',
    text_reset_instruction_heading:
      'Wir haben Ihnen soeben eine Anleitung zum Zurücksetzen des Passworts an Ihre E-Mail Adresse gesendet',
    text_reset_instruction_info:
      'Prüfen Sie jetzt Ihren E-Mail Posteingang und folgen Sie der Anleitung in der E-Mail.',
    text_back_to_login: 'Zur Anmeldung',
    text_of: 'von',
    text_steps: 'Schritt',
    text_start_with_email: 'Unverbindlich und jederzeit kündbar. Jetzt registrieren!',
    text_have_account: 'Bereits registriert?',
    text_next_subscription: 'Weiter',
    text_subscription_plan: 'Abonnement',
    text_subscription_info:
      'Bitte wählen Sie das gewünschte Abo-Modell aus. In jedem Fall testen Sie hellohousing einen Monat kostenfrei und unverbindlich.',
    text_per_month: 'pro Monat',
    text_per_year: 'pro Jahr',
    text_billed: 'zahlbar',
    text_anually: 'jährlich',
    text_monthly: 'monatlich',
    text_direct_debit: 'Lastschrift',
    text_Inhaber: 'Name des Karteninhabers',
    text_Account: 'Konto',
    text_Status: 'Status',
    text_forwareded_to_mollie: 'Sie werden zu unserem Zahlungsdienstleister mollie weitergeleitet.',
    text_Erstellt_am: 'Erstellt am',
    text_save_anually: 'pro Jahr',
    text_per: 'pro',
    text_next_personal_details: 'Weiter',
    text_personal_details: 'Ihre Angaben',
    text_personal_user_details: 'Nutzerprofil',
    text_personal_details_info: 'Bitte ergänzen Sie hier Ihre persönlichen Angaben.',
    text_company_details: 'Kontodaten',
    text_full_name: 'Vor- und Nachname',
    text_address: 'Adresse + Hausnr.',
    text_company_address: 'Adresse der Firma',
    text_company_phone: 'Kontakt-Telefonnummer',
    text_company_email: 'Kontakt-Email',
    text_city: 'Ort',
    text_zip_code: 'PLZ',
    text_next_payment: 'Weiter',
    text_payment: 'Zahlung',
    text_payment_details: 'Zahlungsmethode',
    text_payment_details_info: 'Bitte eine Kreditkarte hinzufügen, um fortzufahren.',
    text_cardholder_name: 'Name auf der Karte',
    text_credit_card_num: 'Kartennummer',
    text_exp_date: 'Gültig bis',
    text_cvv: 'Kartenprüfnummer',
    text_next_summary: 'Weiter',
    text_summary: 'Zusammenfassung',
    // text_summary_info: 'Überprüfen Sie Ihre eingegebenen Daten.',
    text_summary_info: 'Bitte bestätigen Sie Ihre Angaben.',
    text_confirm_signup: 'Registrieren',
    text_invalid_email: 'Ungültige E-Mail Adresse',
    text_invalid_password: 'Ungültiges Passwort',
    text_invalid_full_name: 'Ungültiger Name',
    text_invalid_company: 'Ungültige Firma',
    text_invalid_address: 'Ungültige Adresse',
    text_invalid_city: 'Ungültiger Ort',
    text_invalid_zip_code: 'Ungültige Postleitzahl',
    text_invalid_cardholder_name: 'Ungültiger Name',
    text_invalid_credit_card_num: 'Ungültige Kartennummer',
    text_invalid_exp_date: 'Ungültiges Ablaufdatum',
    text_invalid_cvv: 'Ungültige Prüfnummer',
    text_cancel: 'Abbrechen',
    text_search: 'Suche',
    text_sort: 'Sortieren',
    text_new_old: 'neu nach alt',
    text_old_new: 'alt nach neu',
    text_a_z: 'A nach Z',
    text_z_a: 'Z nach A',
    text_show: 'Öffnen',
    text_edit: 'Bearbeiten',
    text_allcation_key_values_edit: 'Verteilerwerte bearbeiten',
    text_delete: 'Löschen',
    text_freez: 'Festschreiben',
    text_unfreez: 'Festschreibung aufheben',
    text_upload_photo: 'Bild hochladen',
    text_upload_photo_ins: 'Mind. 240 x 240 Pixel in PNG oder JPG Format',
    text_upload_photo_ins_svg: 'Mind. 240 x 240 Pixel, max. 5 MB',
    text_upload_photo_ins_all_formats: 'max. 5 MB',
    text_company_optional: 'Firmenname (optional)',
    text_company: 'Vermieter / Firma',
    text_invalid_company_name: 'Ungültiger Firmenname',
    text_danger_zone: 'Nutzerkonto löschen',
    text_delete_your_account: 'Klicken Sie hier, wenn Sie Ihr hellohousing Konto löschen möchten.',
    text_current_password: 'Aktuelles Passwort',
    text_invalid_current_password: 'Aktuelles Passwort ungültig',
    text_new_password: 'Neues Passwort',
    text_invalid_new_password: 'Neues Passwort ungültig',
    text_save_credit_card_information: 'Kreditkarte speichern',
    text_subscription_renewed: 'Die Laufzeit Ihres Abos erneuert sich automatisch am',
    text_and: 'und',
    text_withdraw_from_card: 'werden mit Ihrer gewählten Zahlungsmethode eingezogen.',
    text_change_plan: 'Abo ändern',
    text_cancel_susbscription: 'Abo kündigen',
    text_bill_name: 'Bezeichnung',
    text_amount: 'Betrag',
    text_vat: 'MwSt.',
    text_net_amount: 'Netto-Betrag',
    text_vat_amount: 'MwSt.-Betrag',
    text_date: 'Datum',
    text_cost_type: 'Kostenarten',
    text_your_profile: 'Konto',
    text_log_out: 'Abmelden',
    text_your_subscription: 'Abonnements',
    text_payment_history: 'Zahlungen',
    text_you_be_billed: 'Die nächste Zahlung ist fällig',
    text_on: 'am',
    text_next_payment_withdraw: 'Die nächste Zahlung wird eingezogen',
    text_download: 'Download',
    text_phone_number: 'Telefonnummer',
    text_enter: 'Eingabe',
    text_invalid: 'Ungültig',
    text_no_file_selected: 'Keine Datei ausgewählt',
    text_err_file_size: 'Datei größer als 5 MB',
    text_err_file_type: 'Dateityp muss jpg oder pdf sein',
    text_name: 'Name',
    text_add_property: 'Immobilie hinzufügen',
    text_Save_Changes: 'Speichern',
    text_contact_email: 'Kontakt E-Mail',
    text_housing_mail: 'service@hellohousing.de',
    text_contact_phone: 'Kundenservice',
    phone_no: '+49 (0) 30/20 60 86 21',
    text_go_back_home: 'Zurück',
    text_no_property_added: 'Betriebskosten sind noch nicht verfügbar.',//'Sie haben noch keine Immobilie angelegt.',
    text_must_add_property:
      'Legen Sie zunächst eine Immobilie und Mieteinheiten an, um Betriebskosten abzurechnen.',//'Sie müssen zuerst eine Immobilie anlegen, bevor Sie mit der Betriebskostenabrechnung starten können.',
  },
  receipt: {
    text_receipts: 'Belege',
    text_utility_filter: 'nur Betriebskosten',
    text_receipt: 'Beleg',
    text_amount_eur: 'Betrag (EUR)',
    text_time_frame: 'Zeitraum',
    text_amount: 'Betrag',
    text_date: 'Datum',
    text_billing_split: 'Betriebskostenverrechnung',
    text_cost_type: 'Kostenart',
    text_add_receipt: 'Beleg hinzufügen',
    text_mobile_add_receipt: 'Hinzufügen',
    text_edit_receipt: 'Beleg bearbeiten',
    text_mobile_edit_receipt: 'Bearbeiten',
    text_receipt_name: 'Belegname',
    text_utility_bill_relevant: 'Beleg ist relevant für die Betriebskostenabrechnung.',
    text_delete_receipt: 'Beleg löschen',
    text_delete_confirmation: 'Sind Sie sicher, dass Sie dieses Dokument löschen wollen?',
    text_no_keep_it: 'Nein, behalten',
    text_yes_delete_it: 'Ja, löschen',
    text_current_billing_period: 'Aktuelle Betriebskostenperiode',//'laufende Betriebskostenperiode',
    text_mobile_current_billing_period: 'laufende',
    text_last_billing_period: 'Letzte Betriebskostenperiode',
    text_past_billing_period: 'Letzte Betriebskostenperiode',//'vergangene Betriebskostenperiode',
    text_mobile_last_billing_period: 'letzte',
    text_custom: 'Individuell festlegen',
    text_close_preview: 'Vorschau schließen',
    text_invalid_receipt_name: 'Ungültiger Belegname',
    text_invalid_amount: 'Ungültiger Betrag',
    text_save_receipt: 'Beleg speichern',
    text_mobile_save_receipt: 'Speichern',
    text_receipt_details: 'Belegdaten',

    text_drag_file: 'Datei hinziehen oder Ordner durchsuchen',
    text_add_file_restrictions: 'JPG oder PDF, max. 5 MB',
    text_add_file_restrictions_csv: 'CSV-Format, max. 5 MB',
    text_error_msg: 'Nicht gefunden',
    text_upload_photo: 'Bild hochladen',
  },

  property: {
    text_property: 'Immobilie',
    no_match_result: 'Keine Übereinstimmung',
    generalinfo_text: 'Bild hochladen',
    generalinfo_photo_update: 'Mind. 240 x 240 Pixel in PNG oder JPG Format',
    generalinfo_propertyname: 'Name der Immobilie',
    generalinfo_propertynameError: 'Ungültiger Name',
    generalinfo_propertyaddress: 'Adresse',
    generalinfo_propertyaddressErr: 'Ungültige Adresse',
    generalinfo_propertyRentalUnitsErr: 'Ungültige Mieteinheiten',
    generalinfo_propertyRentingSpaceErr: 'Ungültige Mietfläche',
    generalinfo_Property_no_of_Units: 'Einheiten',
    generalinfo_Property_no_of_Units_error: 'Ungültiger Wert',
    generalinfo_Area_of_unit: 'Fläche',
    generalinfo_cut_off_date: 'Stichtag',
    generalinfo_heating_type: 'Heizungsart',
    generalinfo_warm_water: 'Zentral mit Warmwasser',
    generalinfo_without_warm_water: 'Zentral ohne Warmwasser',
    generalinfo_decentralized: 'Dezentral',
    generalinfo_heating_temp: 'Temperatur',
    generalinfo_text_one: 'Verbrauchsabhängiger Kostenanteil soll nach Verbrauch ',
    generalinfo_text_two: 'umgelegt werden?',
    generalinfo_custom: 'Anpassen',
    generalinfo_delete_property: 'Immobilie löschen',
    generalinfo_edit_property_cut_off_date: 'Stichtag ändern',
    generalinfo_edit_property_cut_off_date_text: 'Die Änderung des Stichtages sollte nur in Ausnahmefällen durchgeführt werden. Sind Sie sicher, dass Sie fortfahren möchten?',
    allocationKeys_text_head: 'Verteilerschüssel',
    allocationKeys_text_add_allocationkeys: isMobile ? 'Hinzufügen' : 'Verteilerschüssel hinzufügen',
    allocationKeys_tablehead_name: 'Name',
    allocationKeys_tablehead_unit: 'Einheit',
    allocationKeys_tablehead_meter: 'Verbräuche',
    allocationKeys_tablehead_active: 'Aktiv',
    Text: 'Text',
    costType_tablehead_name: 'Name',
    costType_tablehead_active: 'Aktiv',
    costType_tablehead_allocationkeys: 'Verteilerschüssel',
    text_cost: 'Kostenarten',
    text_cost_addcosttype: isMobile ? 'Hinzufügen' : 'Kostenart hinzufügen',
    text_allocation_key: ' Verteilerschüssel',
  },
  tenant: {
    text_tenant: 'Mieter',
    text_rental_unit: 'Mieteinheit',
    text_add_rental_unit: 'Einheit hinzufügen',
    text_move_in: 'Einzug',
    text_move_out: 'Auszug',
    text_add_tenant: 'Mieter hinzufügen',
    text_tenant_information: 'Mieterdaten',
    text_rent_address: 'Mietadresse',
    text_documents: 'Dokumente',
    text_notes: 'Notizen',
    text_add_tenant_as: 'Mietertyp',
    text_edit_tenant_as: 'Mietertyp',
    text_private: 'Privat',
    text_commercial: 'Geschäftlich',
    text_occupancy_dates: 'Mietzeitraum',
    text_move_in_date: 'Einzugsdatum',
    text_move_out_date: 'Auszugsdatum (opt.)',
    text_rent: 'Miete',
    text_billed_monthly: 'monatlich',
    text_rented_area: 'vermietete Fläche',
    text_save_tenant: 'Speichern',
    // text_move_in: 'Einzug',
    // text_move_out: 'Auszug',
    text_edit_tenant: 'Mieter bearbeiten',
    text_payments: 'Mietzahlung',
    text_no_rent_defined: 'Sie haben für dieses Mietverhältnis noch keine Miethöhe festgelegt.',
    text_edit_rental_unit: 'Bearbeiten',
    // text_add_rental_unit: 'Mieteinheit hinzufügen',
    text_net_rent: 'Kaltmiete',
    text_advance_payments: 'Vorauszahlungen',
    text_end_date_opt: 'Enddatum (optional)',
    text_start_date: 'Anfangsdatum',
    text_save_rent: 'Miete speichern',
    text_add_rent: 'Mietanpassung erfassen',//'Hinzufügen',
    // text_edit_rental_unit: 'Mieteinheit bearbeiten',
    // text_add_rental_unit: 'Mieteinheit hinzufügen',
    text_size: 'Größe (m²)',
    text_edit_rent: 'Miete bearbeiten',//'Bearbeiten',
    text_save_rental_unit: 'Speichern',
    text_show_current_tenants: 'Laufende Mietverhältnisse anzeigen',
    text_show_previous_tenants: 'mehr anzeigen',
    text_show_less: 'weniger anzeigen',
    text_show_previous_rents: 'mehr anzeigen',
    text_no_rental_units: 'Sie haben noch keine Mieteinheiten angelegt.',
    text_no_unit_and_property: 'Legen Sie zunächst eine Immobilie an, um dann Mieteinheiten hinzuzufügen.',//'Sie müssen zuerst eine Immobilie anlegen bevor Sie Mieteinheiten hinzufügen können.',
    text_save_payments: 'Speichern',
    text_edit_payments: 'Bearbeiten',
  },
  utility: {
    text_Consumption: 'Verbrauch',
    text_Energy_consumption: 'Energieverbrauch',
    text_heat_quantity_hot_water_boiler: 'Wärmemenge Warmwasserboiler',
    text_calculate_heating_cost: 'Heizkosten berechnen',
    text_confirm_alert: 'Bestätigen Sie die Änderung',
    text_current_billing_period: 'Aktuelle Betriebskostenperiode',//'laufende Betriebskostenperiode',
    text_last_billing_period: 'Letzte Betriebskostenperiode',
    text_past_billing_period: 'Letzte Betriebskostenperiode',//'vergangene Betriebskostenperiode',
    text_edit: 'Bearbeiten',
    text_total_costs: 'Summe Heizkosten',
    text_Heating_costs: 'Heizkosten',
    text_start_utility_billing: 'Starten',
    text_utility_billing: 'Betriebskostenabrechnung',
    text_tenant: 'Mieter',
    text_rental_unit: 'Mieteinheit',
    text_occupancy_period: 'Zeitraum',
    text_go_back: 'Zurück',
    text_next_utility_bills: 'Weiter',
    text_next_overview: 'Weiter',
    text_save_utility_billing: 'Speichern',
    text_cost_allocation: '2. Weitere Nebenkosten verteilen',//'Nebenkosten verteilen',
    text_heating_cost_allocation: '1. Heizkosten verteilen',
    text_heating_cost_allocation_desc: 'Hier können Sie Heizkosten abrechnen. Klicken Sie auf Weiter, um weitere Nebenkosten und extern erstellte Heizkosten abzurechnen.',//'Hier ermitteln Sie die Heizkosten und legen diese um.',
    text_cost_allocation_desc: 'Bitte wählen Sie die Kostenarten die Sie umlegen möchten und bestimmen Sie deren Höhe.',
    text_add_cost_position: 'Kostenposition hinzufügen',
    text_edit_cost_position: 'Kostenposition anpassen',
    text_cost_type: 'Kostenart',
    text_amount: 'Betrag',
    text_allocation_key: ' Verteilerschlüssel',
    text_meter_data: 'Verbräuche',
    text_meter_distributor_value: 'Verteilerwerte',
    text_Receipt: 'Belege',
    text_total_cost: 'Gesamt',
    text_total_value: 'Gesamtwert',
    text_tenant_value: 'Wert Mieter',
    text_unit: 'Einheit',
    text_tenant_cost_share: 'Kosten Mieter',
    text_comment: 'Bemerkung',
    text_no_tenant_bills: 'Es gibt noch keine Betriebskostenabrechnung',
    text_utility_bills: '3. Abrechnungen fertigstellen',//'Betriebskostenabrechnungen',
    text_ub_desc: 'Prüfen Sie nun die Abrechnung je Mietpartei. Sie können diese dann drucken und versenden.',//'Hier können Sie sich für jeden Mieter die Betriebskostenabrechnungen anzeigen lassen.',
    text_stayed_for: 'Tage',
    text_time_period: 'Zeitraum',
    text_total_allocation: 'Kosten',
    text_prepayments: 'Vorauszahlungen',
    text_balance: 'Differenz',
    text_balance_positive: 'Guthaben',
    text_balance_negative: 'Nachzahlung',
    text_print_pdf: 'Drucken',//'Öffnen',
    text_add_comment: 'eingeben...',
    text_Cost_type: 'Kostenart',
    text_Summe_Mieter: 'Summe Mieter',
    text_Mike_Jonathon_Stanfordinthebay: 'Mike Jonathon Stanfordinthebay ',
    text_Stacy_Smith: 'Stacy Smith',
    text_Oxford_Bulldog: 'Oxford-Bulldog',
    text_Anabell_Harvard: 'Anabell Harvard',
    text_Tom_Yale: 'Tom Yale',
    text_House_Bell_GmbH: 'House&Bell GmbH',
    text_utiiity_allocation_key: 'Verteilerschlüssel',
    text_Total_cost: 'Gesamt',
    text_utility_total_value: 'Gesamtwert',
    text_utility_tenant_value: 'Mieter',
    text_utility_unit: 'Einheit',
    text_Tenant_Cost_share: 'Kostenanteil Mieter',
    text_utility_comment: 'Notiz',
    text_Set_allocation: 'Individuelle Zuweisung ',
    text_set_allocation_button: 'Zuweisen',
    text_set_custom_allocation: 'Individuelle Zuweisung',
    // text_occupancy_period: 'Zeitraum',
    text_heating_cost_switch: 'Heizkostenrechner deaktivieren',
    text_no_utility_billing: 'Sie haben noch keine Kosten erfasst',
    text_error_billing_period: 'Unable to start billing period.',
    text_error_no_pdf: 'Es sind noch keine Dateien vorhanden',
    text_freeze_alert_heading: 'Festschreibung aufheben?',
    text_unfreeze_alert_heading: 'Betriebskostenabrechnung festschreiben?',
    text_freeze_alert_message: 'Diese Betriebskostenabrechnung ist gegenwärtig festgeschrieben. Bei Aufhebung der Festschreibung wird die Abrechnung wieder bearbeitbar, wodurch sich die Ergebnisse ändern können.',
    text_unfreeze_alert_message: 'Sie sind dabei die Betriebskostenabrechnung festzuschreiben. Festgeschriebene Betriebskostenabrechnungen können nicht geändert werden. Die Festschreibung kann bei Bedarf wieder aufgehoben werden.',
  },
  meter: {
    text_empty_state: 'Es wurden noch keine Zähler angelegt.',
    text_empty_alert: 'Sie müssen zuerst eine Immobilie hinzufügen, um Ablesungen durchzuführen.',
    text_add_meter: 'Hinzufügen',//'Zähler hinzufügen',
    text_add_meter_data: 'Zähler hinzufügen',
    text_edit_meter_data: 'Zähler bearbeiten',
    text_Rental_Unit: 'Mieteinheit',
    text_number: 'Zählernummer',
    text_invalid_number: 'Ungültige Zählernummer',
    text_type: 'Zählertyp',
    text_invalid_type: 'Ungültiger Zählertyp',
    text_location: 'Standort',
    text_invalid_location: 'Ungültiger Standort',
    text_note: 'Notiz',
    text_add: 'Hinzufügen',
    text_save: 'Speichern',
    text_individual_reading: 'Einzelablesung',
    text_mobile_individual_reading: 'Ablesung',
    text_annual_reading: 'Jahresablesung',
    text_status: 'Anlass',
    text_reading: 'Ablesungen',
    text_master_data: 'Zähler',
    text_annual_meter_data: 'Jahresablesung',
    text_meter_number: 'Zählernummer',
    text_meter_type: 'Zählertyp',
    text_mobile_meter_number: 'Nummer',
    text_mobile_meter_type: 'Art',
    text_reading_date: 'Ablesedatum',
    text_start_value: 'Startwert',
    text_current_value: 'Ablesewert',
    text_consumption: 'Verbrauch',
    text_invalid_value: 'Ungültiger Wert',
    text_invalid_date: 'Ungültiges Datum',
    text_no_master_data: 'Es wurden noch keine Zähler angelegt.',
    text_no_master_data_subheading: 'Sie müssen zuerst einen Zähler anlegen, um Ablesungen durchzuführen.',
    text_edit_readings: 'Einzelablesung',
    text_individual_meter_reading: 'Einzelablesung',
    text_annual: 'Jahresablesung',
    text_no_billing_warning: 'Für diese Betriebskostenperiode wurden noch keine Zählerstände und Verbräuche erfasst.',
    is_property_meter: 'Gebäudezähler – misst den Verbrauch der gesamten Immobilie.',
    annual_error_message_updating: 'Es ist ein Fehler aufgetreten.',
    annual_success_message: 'Zählerstände wurden aktualisiert.',
    meter_units: 'Einheit',
    text_error_meter_readings_delete: 'Zählerstände konnten nicht gelöscht werden.',
    text_error_meter_readings_save_error: 'Zählerstände konnten nicht gespeichert werden.',
  },
  user: {
    text_error_subscription: 'Bitte wählen Sie ein Abo aus.',
    text_error_subscriptions_list: 'Kein Abo verfügbar.',
    text_credit_card: 'Kreditkarte',
    text_error_payment_status: 'Ihre Zahlung konnte nicht bestätigt werden.',
    text_authorize_payment: 'Zahlungsmethode bestätigt.',
    text_unauthorize_payment: 'Zahlungsmethode konnte nicht bestätigt werden.',
    text_process_further: 'Vielen Dank für das Hinterlegen Ihrer Zahlungsmethode.',
    text_try_again: 'Bitte versuchen Sie es noch einmal.',
    text_error_payment_mode: 'Bitte wählen Sie eine Zahlungsmethode.',
    text_error_payment_server: 'Der Server reagiert nicht.',
    text_payment_details: 'Zahlungsdetails',
    text_signup_successful: 'Sie sind registriert!',
    text_login_now: 'Willkommen! Sie können sich jetzt anmelden.',
    text_go_to_login: 'Anmelden',
    text_error_process: 'ungültiger Vorgang',
    text_error_authorize_payment: 'Gateway Fehler beim Zahlungsdienstleister',
    text_payment_sub_heading:
      'Bitte wählen Sie Ihre Zahlungsmethode. Wir leiten Sie im Anschluss zu unserem Zahlungsdienstleister weiter.',
    text_payment_provider_mollie:
      'Bitte wählen Sie Ihre Zahlungsmethode. Sie werden im Anschluss zu unserem Zahlungsdienstleister weitergeleitet.\n\nNur wenn Sie hellohousing nach Ihrem Probemonat weiternutzen, belasten wir Ihr Zahlungsmittel.',
    text_error_account_activate: 'Wir konnten Ihr Nutzerkonto nicht aktivieren.',
    text_terms_and_condition: 'Nutzungsbedingungen',
    text_testing_hellohousing: 'Sie testen hellohousing ',
    text_free_untill: 'kostenfrei bis zum ',
    text_cancel_any_time: 'Sie können Ihr Abo jederzeit kündigen.',
    text_you_will_use: 'nutzen Sie hellohousing mit dem',
    text_plan_cancellation: 'Abo.',
    text_first_payment_withdrawn: 'Ihre erste Zahlung wird abgebucht am mittels ',
    text_accept_terms_and_condition: 'Bitte bestätigen Sie die Nutzungsbedingungen.',
  },
  newKeys: {
    text_see_all: 'Alle',
    text_billing_period: 'Aktuelle Betriebskostenperiode',//'laufende Betriebskostenperiode',
    text_empty_receipt_one: 'Es sind noch keine Belege vorhanden.',
    text_empty_receipt_two: 'Hier können Sie später Ihre Belege sehen.',
    text_empty_receipt_three: 'Belege sind noch nicht verfügbar.',//'Sie haben noch keine Belege hinzugefügt.',
    text_empty_utility_one: 'Betriebskosten sind noch nicht verfügbar.',
    text_empty_utility_two: 'Legen Sie zunächst eine Immobilie und Mieteinheiten an, um Betriebskosten abzurechnen.',//'Um Betriebskosten abzurechnen legen Sie zunächst eine Mieteinheit an.',
    text_empty_meter_one: 'Ablesungen sind noch nicht verfügbar.',//'Es sind noch keine Ablesungen verfügbar.',
    text_empty_meter_two: 'Legen Sie zunächst eine Immobilie und Mieteinheiten an, um Ablesungen durchzuführen.',//'Hier können Sie später Ablesungen vornehmen.',
    text_Rental_units: 'Mieteinheiten',
    text_empty_rentalUnit_one: 'Sie haben noch keine Mieteinheiten angelegt.',
    text_empty_rentalUnit_two: 'Legen Sie zunächst eine Immobilie an, um dann Mieteinheiten hinzuzufügen.',//'Hier können Sie später Ihre Mieteinheiten sehen.',
    text_general_info: 'Grunddaten',
    text_from: 'von',
    text_system_cost_type: 'Standard',
    text_System_key: 'Standard',
    text_add_allocation_key: isMobile ? 'Hinzufügen' : 'Verteilerschüssel hinzufügen',
    text_edit_allocation_key: 'Bearbeiten',
    text_save_allocation_key: 'Speichern',
    text_error_measurement_unit: 'Ungültige Einheit',
    text_save_cost_type: 'Speichern',
    text_select: 'Wählen',
    text_select_error_msg: 'Schlüssel wählen',
    text_edit_cost_type: 'Bearbeiten',
    text_legal: 'Rechtliches',
    text_last_name: 'Nachname',
    text_first_name: 'Vorname',
    text_subscription_one: 'Sie testen hellohousing einen Monat kostenfrei bis zum',
    text_subscription_two: 'Ab dem',
    text_subscription_three: 'Wir berechnen Ihnen',
    text_subscription_four: 'auf',
    text_subscription_five: 'Basis.',
    text_subscription_at: 'am',
    text_error_msg_checkbox: 'Bitte stimmen Sie den Nutzungsbedingungen zu.',
    text_error_msg_checkbox_2:
      'Bitte stimmen Sie der Datenschutzerklärung und den Bestimmungen zur Datenverarbeitung zu.',
    text_email_already_taken: 'Bitte geben Sie eine gültige E-Mail Adresse ein.',
    text_first_payment_withdrawns: 'Ihre erste Zahlung wird per',
    text_Energy_source: 'Brennstoff',
    text_heatingType_error: 'Heizungsart wählen.',
    text_fuelType_error: 'Brennstoff wählen',
    text_warm_water_error: 'Wärmemenge Warmwasserboiler wählen.',
    text_SEPA: 'SEPA-Lastschrift',
    text_signup_monthly_basic: 'monatlicher',
    text_signup_monthly: 'monatlichen',
    text_signup_yearly: 'jährlichen',
    text_signup_yearly_basic: 'jährlicher',
    text_utility_billing_err: 'Keine Abrechnungen vorhanden.',
    text_add_new_property: 'Sie haben noch keine Immobilie angelegt.',//'Noch keine Immobilie angelegt.',//'Neue Immobilie anlegen',
    text_add_your_property: 'Immobilie anlegen',
    text_add_Property_and_manage: 'Legen Sie eine erste Immobilie an, um mit hellohousing zu starten.',//'Legen Sie eine Immobilie an, um sie zu verwalten.',
    text_Password_must: 'Das Passwort muss:',
    text_Password_must_condition_one: 'mindestens 8 Zeichen lang sein',
    text_Password_must_condition_two: 'Kleinbuchstaben enthalten',
    text_Password_must_condition_three: 'Großbuchstaben enthalten',
    text_Password_must_condition_four: 'eine Zahl enthalten',
    text_Password_must_condition_five: 'mindestens ein Sonderzeichen enthalten (z.B. %, &, #)',
    text_net_rent: 'Kaltmiete (EUR)',
    text_advance_rent: 'Vorauszahlung (EUR)',
    text_Add_Note: 'hinzufügen',
    text_heading_meter_readings: 'Ablesungen',
    text_heading_metermaster_data: 'Zähler',
    text_save_money: '10€ sparen',
    text_save_money_10: 'sparen',
    text_monthly_plan: 'monatlich',
    text_yearly_plan: 'jährlich',
    btn_text_authorize_payment: 'Zahlungsmethode freigeben',
    text_subscription_plan_one: '1. monatliche Kündigung möglich',
    text_subscription_plan_two: '2. jährliche Kündigung möglich',
    text_No_utility_billings_found: 'Keine Betriebskosten verfügbar',
    text_error_not_found: 'Fehler: nicht gefunden',
    text_error_found: 'Ein Fehler ist aufgetreten.',
    text_utility_error: 'Heizkostenrechner nicht verfügbar!',
    text_utility_not_found: 'Betriebskosten wurden nicht gefunden',
    text_system_fuel_not_found: 'Standard Brennstoffe nicht gefunden',
    text_error_adding_heat_cost: 'Fehler beim Hinzufügen von Heizkosten',
    text_error_delete_heat_cost: 'Fehler beim Löschen von Heizkosten',
    text_error_save_heat_cost: 'Fehler beim Speichern von Kostenposition',
    text_no_billing_found: 'Keine Betriebskosten-Daten gefunden',
    text_error_Incorrect_Cost_Allocation: 'Fehlerhafte Kostenverteilung',
    text_error_update_Cost_Allocation: 'Fehler beim Update der Kostenverteilung',
    text_error_Billing_Statements: 'Betriebskosten konnten nicht angelegt werden :  ',
    text_Error_message: 'Fehler :  ',
    text_No_tenants_found_for_rental_unit: 'Es wurden keine Mieter für diese Einheit gefunden.',
    text_error_Tenant_readings_not_found: 'Keine Anlegung verfügbar',
    text_error_no_reading_found: 'Keine Ablesung verfügbar',
    text_error_no_meter_reading: 'Keine Ablesungen vorhanden für gewählte Periode.',
    text_error_no_occupation_reading: 'Ablesedaten wurden nicht gefunden.',
    text_error_invalid_rental_unit: 'Mieteinheit ist fehlerhaft',
    text_error_Consumption_value: 'Verbrauch ist fehlerhaft. Bitte prüfen Sie die Eingaben.',
    text_error_No_tenants_for_rental_unit: 'Es sind keine Mieter für diese Einheit vorhanden.',
    text_error_no_meter_reading_available: 'Keine Ablesungen vorhanden',
    text_invalid_data: 'Fehlerhafte Eingabe',
    text_invalid_or_empty_data: 'Eingabe ist fehlerhaft',
    text_error_remove_receipt: 'Fehler beim Löschen des Belegs',
    text_error_saving_cost_position: 'Es ist ein Fehler aufgetreten. Bitte probieren Sie es erneut oder wenden Sie sich an den Support.',
    text_error_calculate_heating_cost: 'Solange der Heizkostenrechner aktiviert ist müssen Sie Heizkosten berechnen.',
    text_error_no_cost_position_found: 'Keine Kostenpositionen gefunden',
    text_error_Heating_Cost_switch_response: 'Der Heizkostenregler reagiert nicht',
    text_error_Error_in_removing_receipt_assignment: 'Fehler beim Löschen der Rechnungszuweisung',
    text_error_meter_added_to_renatal_unit: 'Für diese Mieteinheit liegen keine Zähler vor.',
    text_empty_add_meters_first_to_manage_annual: 'Bitte legen Sie Zähler an bevor Sie mit der Ablesung beginnen.',
    text_profile: 'Profil',
    tooltip_default_text: 'some help text',
    Property_no_of_Units_Tooltip: 'Gesamtanzahl der Einheiten der Immobilie',
    Property_Area_of_Unit_Tooltip: 'Gesamtfläche der Immobilie',
    Property_consumption_Tooltip: 'Anteil der Kosten, die nach Verbrauch umgelegt werden sollen.',
    text_billing_period_dashboard: 'Abgeschlossene Betriebskostenperiode',//'abgeschlossene Periode',
    text_dashboard_file_name: 'Name',
    text_dashboard_cost: 'Betrag (EUR)',
    text_receipt_period_tooltip: 'Zeitraum, den die Rechnung abdeckt.',
    text_record: 'Daten',
    text_single_allocationKey: 'Verteilerschlüssel',
    text_single_costtype: 'Kostenart',
    text_single_tenant: 'Mieter',
    text_heating_cost: 'Heizkosten',
    text_heating_cost_not_available: 'Angaben fehlen',
    text_heating_cost_available: 'Angaben vollständig',
    text_heating_cost_available_incomplete: 'Angaben unvollständig',
    text_change_pw: 'Passwort ändern',
    text_paymentUpdated_success: 'Die Zahlungsmethode wurde erfolgreich geändert.',
    text_paymentUpdated_failure: 'Fehler: Die Zahlungsmethode konnte nicht geändert werden.',
    text_cost_positions: 'Kostenart',
    text_Consumption_type: 'Energieträger',
    text_unit: 'Einheit',
    text_add_cp: 'Position hinzufügen',
    text_heating_cost_type_error_msg: 'Ungültige Eingabe',
    //add from slack
    text_select_cost_type: 'Bitte auswählen',
    text_pass_success_message: 'Zahlungsmethode erfolgreich geändert',
    text_pass_failure_message: 'Zahlungsmethode konnte nicht geändert werden.',
    text_subs_plan_per_month: 'monatlich',
    text_subs_plan_current_plan: 'aktueller Plan',
    text_subs_plan_monthly: 'monatlich',
    text_subs_plan_annually: 'jährlich',
    text_allocation_key_error_msg: 'Bitte auswählen',
    text_days: 'Tage',
    select_allocation_key_text: 'Bitte auswählen',
    text_billed_monthly: 'monatlich',
    text_heating_cost_not_avaialable: 'Heating costs not available',
    text_signup_first_name: 'Ungültiger Vorname',
    text_signup_last_name: 'Ungültiger Nachname',
    text_Change_payment_option: 'Zahlungsmethode ändern',
    text_your_current_plan: 'Aktuelles Abo',
    text_add_payments_rent: 'Anpassen',
    text_error_msg_tenants_rent: 'Ungültige Eingabe',
    Go_back_to_payment_details: 'Zurück zur Auswahl der Zahlungsmethode',
    text_not_required: 'nicht benötigt',
    text_available: 'verfügbar',
    text_not_available: 'nicht verfügbar',
    warmwater_amount_is_greater_than_heating_amount: <span>Bitte prüfen sie die eingegebenen Energiemengen im Heizkostenrechner. Der <span style={{fontWeight: 'bold'}}>Energieverbrauch Warmwasser</span> darf den <span style={{fontWeight: 'bold'}}>Gesamtenergieverbrauch</span> nicht überschreiten.</span>,
    central_warmwater_allocation_key_not_set: <span style={{fontWeight: 400}}>Es wurde kein Verteilerschlüssel für den zentralen Warmwasserzähler gefunden. Bitte prüfen Sie die Einstellungen der Heizungsanlage. <span style={{color: '#5555FF', cursor: 'pointer'}} onClick={()=> window.open(`https://${window.location.hostname.includes('app') ? 'app' : 'dev'}.hellohousing.de/property/heatingCostInformation`, '_self')}>Einstellungen Heizungsanlage anpassen <img src={RightArrowPartner} /></span></span>,
    warmwater_cosumption_must_be_in_meter_cube: 'Bitte prüfen Sie den Verteilerschlüssel für den Warmwasserverbrauch. Der Warmwasserverbrauch muss in m3 oder als Wärmemenge verfügbar sein.',
    file_size_too_large: 'Es ist ein Fehler aufgetreten. Das Dokument ist zu groß.'
  },
};

export default du;
