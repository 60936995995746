import React from 'react';
import { LabelText, ValueText } from '../../../../index.js';

export default function InfoField({
  labelText,
  labelStyle,
  text,
  leftTextImage,
  rightTextImage,
  labelClassName,
  className,
  valueClassName,
  cardValueClassName
}) {
  return (
    <div className={`info-field ${className}`}>
      <LabelText style={labelStyle} text={labelText} className={labelClassName} />
      <ValueText
        text={text}
        // value={text}
        leftImage={leftTextImage}
        rightImage={rightTextImage}
        className={valueClassName}
        cardValueClassName={cardValueClassName}
      />
    </div>
  );
}
