// Color shades generated using this tool: https://smart-swatch.netlify.app/#443395
// More info here: https://mantine.dev/theming/extend-theme/#extend-or-replace-colors

// Global styling
export const theme = {
  colorScheme: 'light',
  colors: {
    brand: [
      '#e6e6ff',
      '#b5b6ff',
      '#8686f9',
      '#5656f6',
      '#2725f2',
      '#100dd9',
      '#0909a9',
      '#05077a',
      '#01034c',
      '#00001e',
    ],
    brandLight: '#F7F7FA',
    brandFancyPurple: '#7B61FF',
    brandDarkText: '#111147',
    brandDarkTextAlt: '#0E0E1',
    lightText: '#727293',
    divider: 'rgba(218, 218, 229, 1)'
  },
  primaryColor: 'brand',
  // Typography
  fontFamily: 'Inter, sans-serif',
  headings: {
    fontFamily: 'Inter, sans-serif'
  },
  fontSizes: {
    "xs": "0.75rem",  // 10.5px
    "sm": "0.85rem",  // 11.9px
    "md": "1rem",     // 14px :: default
    "lg": "1.25rem",  // 17.5px
    "xl": "1.5rem",   // 21px
    "2xl": "2rem",    // 28px
  }
};

// Default props
export const defaultProps = {
  Container: {
    sizes: {
      xs: 540,
      sm: 720,
      md: 960,
      lg: 1140,
      xl: 1440,
    },
  }
}

// Specific components styling
export const components = {
  Anchor: {
    root: {
      fontSize: 14
    }
  },
  Button: {
    root: {
      fontWeight: 400,
      fontSize: 14,
      
      '&[disabled]': {
        opacity: 0.65
      }
    },
    filled: {
      background: 'linear-gradient(180deg, #5555FF 0%, #4949F5 100%)',
      boxShadow: '0 1px 2px 0 rgba(0,0,0,.1)',
    },
    white: {
      background: 'linear-gradient(to bottom, #ffffff 0%, #f8f8fa 100%)',
      border: '1px solid #d2d2d2',
      boxShadow: '0 1px 2px 0 rgba(0,0,0,.1)',
      color: '#252563',
    }
  },
  Menu: {
    item: {
      fontSize: 14
    }
  },
  TextInput: {
    input: {
      fontSize: 14,
    },
    disabled: {
      backgroundColor: "transparent"
    },
    defaultVariant: {
      border: '1px solid #DADAE5',
      //boxShadow: '0 1px 2px 0 rgba(14, 14, 29, .06)',
    }
  },
  NumberInput: {
    input: {
      fontSize: 14,
    },
    disabled: {
      backgroundColor: "transparent !important"
    }
  },
  DatePicker: {
    input: {
      fontSize: 14,
    }
  },
  Table: {
    root: {
      border: '1px solid #DADAE5',
      borderSpacing: 0,
      borderCollapse: 'separate',
      backgroundColor: '#FFFFFF',
      borderRadius: 4,
      fontWeight: 400,
      
      'thead': {
        color: '#727293',
        
        'tr th': {
          fontWeight: 500,
          padding: '14px 16px',
          fontSize: 14,
          whiteSpace: 'nowrap',
          backgroundColor: 'rgba(247, 247, 250, 1.00)',
          
          '&:not(:last-of-type)': {
            borderRight: '1px solid rgba(218, 218, 229, 1.00)',
          },
          
          '&:first-of-type': {
            borderTopLeftRadius: 4,
          },

          '&:last-of-type': {
            borderTopRightRadius: 4,
          }
        }
      },
      
      'tbody': {
        color: '#111147',
        
        'tr': {
          borderBottom: '1px solid rgba(218, 218, 229, 1.00)',
          
          'td': {
            padding: 0,

            '& > div': {
              padding: '6px 16px'
            },

            '&.reduceSidePaddings > div': {
              padding: '6px 6px'
            },

            '&:not(:last-of-type)': {
              borderRight: '1px solid rgba(218, 218, 229, 1)',
            }
          }
        }
      }
    }
  }
};