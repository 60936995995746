import React, { Component } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import Receipt from '../../../../../assets/images/module/general/ReceiptDummy.png';
import { Row, Col, Form, Tooltip, OverlayTrigger } from 'react-bootstrap';
import CustomModal from '.';
import { showNotification } from '@mantine/notifications';
import ColorButton from '../Buttons/ColorButton';
import CopyIcon from '../../../SVGs/copyIcon';
import GreenTick from '../../../../../assets/images/module/general/GreenTick.svg';
import { Checkbox, Group, Loader, Stack, TextInput } from '@mantine/core';
import EmptyStateContainer from '../EmptyStateContainer/EmptyStateContainer';
import TenantIconBlue from '../../../../../assets/images/module/general/TenantIconBlue.svg';
import RentIconBlue from '../../../../../assets/images/module/general/RentIcon.svg';
import RentIcon from '../../../../../assets/images/module/general/RentIconGray.svg';
import ReceiptsIcon from '../../../../../assets/images/module/general/ReceiptsIconGray.svg';
import ReceiptsIconBlue from '../../../../../assets/images/module/general/ReceiptsIconBlue.svg';
import EmptyIcon from '../../../../../assets/images/EmptyReciept.svg';
import { NumericFormat } from 'react-number-format';
import CardSelection from '../CardSelection/CardSelection';
import { connect } from 'react-redux';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import Autoload from '../../../../Autoload';
import dayjs from 'dayjs';
import accounting from 'accounting';
import SelectDropdown from '../SelectDropdown/SelectDropdown';
import ReceiptRecord from '../ReceiptRecord';
import ReceiptsList from '../../../../../views/pages/Module/Receipt/ReceiptsList';

const MOBILE_SIZE = 992;
const TABLET_SIZE = 1200;
const SMALLER_MOBILE_SIZE = 500;

const tableCellStyle = {
    whiteSpace: 'nowrap', 
    overflow: 'hidden', 
    textOverflow: 'ellipsis', 
    fontSize: '14px', 
    fontFamily: 'Inter, sans-serif', 
    height: 'auto !important',
    fontWeight: '400',
    borderTop: '8px solid white'
}

export class SelectReceiptsForTransactionAssigmentModal extends Component {
  state = {
    isMobile: window.innerWidth <= MOBILE_SIZE,
    isTablet: window.innerWidth <= TABLET_SIZE,
    isSmallerMobile: window.innerWidth <= SMALLER_MOBILE_SIZE,
    selectedReceipts: [],
  };

  componentDidMount(){
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount(){
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    // TODO: this causes data refetch on the meters reading details screen...
    if (window.innerWidth <= MOBILE_SIZE) {
      this.setState({ isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
    if (window.innerWidth <= TABLET_SIZE) {
      this.setState({ isTablet: true });
    } else {
      this.setState({ isTablet: false });
    }

    if (window.innerWidth <= SMALLER_MOBILE_SIZE) {
      this.setState({ isSmallerMobile: true });
    } else {
      this.setState({ isSmallerMobile: false });
    }
  };
  
  render() {
    const { open, toggle, className, saveSelectedReceipts, selectedReceiptsFromParent, getReceipts, selectedTransaction } = this.props;
    const { selectedReceipts, isMobile, isTablet, isSmallerMobile } = this.state;

    console.log('selectedReceipts: ', selectedReceipts);

    return (
      <CustomModal
        heading={'Beleg zuordnen oder erzeugen'}
        headingClassName={'assign-receipt-transaction-modal-heading'}
        open={open}
        toggle={() => {
          toggle();
        }}
        className={`${className}`}
        style={{
            minWidth: isSmallerMobile ? 'auto' : isMobile ? '370px' : isTablet ? '800px' : '1200px'
        }}
        styleBody={{background: '#f7f7fa'}}
      >
        <Stack>
          <Group>
            <ReceiptsList 
            transactionAssignment={true} 
            selectedTransaction={selectedTransaction}
            selectedReceipts={selectedReceiptsFromParent} 
            updateSelectedReceipts={(selectedReceipts)=> this.setState({selectedReceipts})}
            getReceipts={(newAddedReceipt=null)=> getReceipts(newAddedReceipt)}
            />
          </Group>
          <Group position='apart'>
            <div>
                <ColorButton
                text={'Abrechen'}
                className='gray-btn'
                onClick={()=> toggle()}
                />
            </div>
            <div>
                <ColorButton
                text={'Speichern'}
                className='purple-btn'
                onClick={()=> saveSelectedReceipts(selectedReceipts)}
                disabled={selectedReceipts.length === 0}
                />
            </div>
          </Group>
        </Stack>
      </CustomModal>
    );
  }
}

const mapStateToProps = (state) => {
    return {
      propertySelected: state.generalReducer.property,
      rentalUnits: state.tenantDataReducer.rentalUnits
    };
  };
  
export default connect(mapStateToProps)(SelectReceiptsForTransactionAssigmentModal);
