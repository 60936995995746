import React from 'react';
import CustomModal from './index';
import { ColorButton } from '../../../../index';
import Autoload from '../../../../Autoload';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
const { checkProperty } = Autoload.MainHelper;

export default function DeleteModal({
  start,
  end,
  modalId,
  className,
  open,
  toggle,
  record,
  handleDelete,
  showInfo = ['', ''],
  type,
  showtext = true,
  currentTenant,
  typeText,
  textShowModal,
  showDeleteText = true,
  rightButtonText= false,
  leftButtonText=false,
  leftButtonClassName='gray-btn'
}) {
  console.log('record', showDeleteText);
  // console.log('showInfo',showInfo)
  return (
    <CustomModal
      heading={showDeleteText === false ? textShowModal : lang.receipt.text_delete_receipt}
      open={open}
      toggle={toggle}
      className={`${className}`}
    >
      <div>
        <div className="modal-inner-body delete-receipt-body">
          <p>
            {showtext && lang.receipt.text_delete_confirmation + ' ' + type + ' ' + '?'}
            {/* {lang.receipt.text_delete_confirmation} */}
            {typeText}
          </p>
          <div className="center-spacing">
            <span>
              {showInfo[0]}
              {/* { (
                            (type==='rent' && showInfo[0]==='net') 
                            // || (type==='receipt' && showInfo[1]==='cost')
                        ) && '€'} */}
            </span>
            <span className="right-side">
              {showInfo[1]}
              {/* { type==='receipt' && showInfo[1]==='cost' && '€'} */}
              {/* { showInfo[1]==='timeframe' && end ? `${start} - ${end}` : start} */}
            </span>
          </div>
        </div>
        <div className="center-spacing delete-modal-footer" id={modalId}>
          <ColorButton
            onClick={() => {
              toggle();
            }}
            className={leftButtonClassName ? leftButtonClassName : "gray-btn"}
            text={leftButtonText ? leftButtonText : lang.receipt.text_no_keep_it}
          />
          <ColorButton
            onClick={() => {
              console.log('button clicked', record);
              handleDelete(checkProperty(record, 'id', null));
              //    toggle()
            }}
            className="red-btn"
            text={rightButtonText ? rightButtonText : lang.receipt.text_yes_delete_it}
          />
        </div>
      </div>
    </CustomModal>
  );
}
DeleteModal.defaultProps = {
  handleDelete: () => {},
  toggle: () => {},
  open: true,
  record: {},
  type: lang.newKeys.text_record,
};
