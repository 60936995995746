import React, { Component } from 'react';
import { Container, Row, Col, Form } from 'react-bootstrap';
import { ColorButton } from '../../../../index';
import CustomModal from './index';
import Autoload from '../../../../Autoload';
import { addAllocationData, editAllocationData } from '../../../../../redux/action/Property/propertyActions';
import {
  updatePropetyInfo
} from '../../../../../redux/action/General/generalActions';
import { connect } from 'react-redux';
import GeneralDataHandler from '../../../../helpers/DataHandler/General';
import { showNotification } from '@mantine/notifications';
import RedCross from '../../../../../assets/images/module/general/RedCross.svg';
import GreenTick from '../../../../../assets/images/module/general/GreenTick.svg';
import TenantIcon from '../../../../../assets/images/module/general/TenantIcon.svg';
import TenantIconBlue from '../../../../../assets/images/module/general/TenantIconBlue.svg';
import PropertyIcon from '../../../../../assets/images/module/general/PropertyIcon.svg';
import PropertyIconBlue from '../../../../../assets/images/module/general/PropertyIconBlue.svg';
import plan from '../../../../../assets/images/module/general/plan.svg';
import planBlue from '../../../../../assets/images/module/general/planBlue.svg';
import MultipleTenants from '../../../../../assets/images/module/general/MultipleTenants.svg';
import MultipleTenantsBlue from '../../../../../assets/images/module/general/MultipleTenantsBlue.svg';
import MeterIcon from '../../../../../assets/images/module/general/MeterIcon.svg';
import BlueEmptyMeter from '../../../../../assets/images/module/general/blueEmptyMeter.svg';
import { Group, Stack } from '@mantine/core';
import Multiselect from 'multiselect-react-dropdown';
var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
var { checkProperty } = Autoload.MainHelper;

const MOBILE_SIZE = 500;

class AddAllocationKeyModal extends Component {
  initialState = {
    id: '',
    name: '',
    nameErrMsg: false,
    measurementUnit: '',
    measurementErrMsg: false,
    arrow: true,
    fuelTypes: [],
    type: '',
    hover1: false,
    hover2: false,
    hover3: false,
    hover4: false,
    hover5: false,
    selectedType: '',
    selectedMeterType: '',
    typeError: false,
    meterTypeError: false,
    numberOfPersonsForEmptyApartments: [
      {
        label: 'Belegung mit 0 Personen',
        value: 'OCCUPATION_0'
      },
      {
        label: 'Belegung mit 1 Person',
        value: 'OCCUPATION_1'
      },
      {
        label: 'Belegungsdurchschnitt aller Einheiten',
        value: 'OCCUPATION_AVG'
      }
    ],
    selectedNumberOfPersonsForEmptyApartments: 'OCCUPATION_AVG',
    isMobile: window.innerWidth <= MOBILE_SIZE,
  };

  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  componentDidMount() {
    if (this.props.edit) {
      this.setState({
        id: this.props.record.id,
        measurementUnit: this.props.record.units,
        name: this.props.record.allocationKeyName,
        metered: this.props.record.metered,
        type: this.props.record.allocationKeyType,
        selectedType: this.props.record.allocationKeyValueType,
        selectedMeterType: this.props.record.meterType,
      });
      this.getProperty();
      window.addEventListener('resize', this.handleResize);
    }
  }

  handleResize = () => {
    if (window.innerWidth <= MOBILE_SIZE) {
      this.setState({ isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
  };
  
  getProperty = ()=>{
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'properties/' + this.props.propertySelected.id,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      '_handle_get_property',
      null,
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  _handle_get_property(response){
    if(response.status === true && response.statuscode === 200){
      this.setState({selectedNumberOfPersonsForEmptyApartments: response.result.occupantsConfiguration});
    }
  }

  handleMouseEnter=(type)=>{
    this.setState({[type]: true});
  }

  handleMouseLeave=(type)=>{
      this.setState({[type]: false});
  }

  _handle_get_allocationKey_by_id(response) {
    if (response.status === false && response.message !== 'Not Found') {
      // Autoload.Redirection.redirection_error();
      //Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang.newKeys.text_error_not_found);
    }else{
      const allocationKey = GeneralDataHandler.checkAllocationKey(response.result);
      this.setState({
        id: allocationKey.id,
        measurementUnit: allocationKey.units,
        name: allocationKey.allocationKeyName,
        metered: allocationKey.metered,
      });
    }
  }

  _getFuelTypes() {
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'systemFuelTypes',
      // Autoload.Setting.property_api_base_url + 'systemUnits',
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      '_handleFuelTypeResponse',
      null,
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  _handleFuelTypeResponse(response) {
    if (response.status === false && response.message !== 'Not Found') {
      // Autoload.Redirection.redirection_error();
      //Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang.newKeys.text_error_not_found);
    }else{
      const fuelTypes = response.result.map((fuelType) => {
        return {
          id: checkProperty(fuelType, 'id'),
          fuelTypeName: fuelType,
          // fuelTypeName: checkProperty(fuelType, 'fuelTypeName'),
          conversionkWh: checkProperty(fuelType, 'conversionkWh'),
        };
      });
      this.setState({ fuelTypes });
    }
  }

  resetForm = () => {
    this.setState({ ...this.initialState });
    this.props.toggle();
  };

  validateForm = () => {
    // this.setState({
    //   nameErrMsg: false,
    // });
    let nameErrMsg = false;
    let measurementErrMsg = false;
    let typeError = false;
    let meterTypeError = false;
    // //const validName = /^[a-zA-Z]+(?:[\s]+[a-zA-Z]+)*$/;
    const validName = /[^a-zA-Z0-9äöüÄÖÜß]/;
    if (this.state.name.trim().length === 0) {
      nameErrMsg = true;
    }
    // validation check for unit is removed because it is now optional
    // if (this.state.measurementUnit.trim().length === 0) {
    //   measurementErrMsg = true;
    // }
    if(this.state.selectedType.trim().length === 0){
      typeError= true;
    }
    if(this.props.propertySelected.propertyType === 'REGULAR' && this.state.selectedType === 'USAGE_BASED' && this.state.selectedMeterType.trim().length === 0){
      meterTypeError= true;
    }
    this.setState({ nameErrMsg, measurementErrMsg, typeError, meterTypeError });
    if (nameErrMsg || measurementErrMsg || typeError || meterTypeError) {
      return false;
    } else {
      return true;
    }
  };

  showNameDuplicateError=()=>{
    this.setState({nameErrMsg: true},()=> 
      showNotification({
        message: 'Unter dieser Bezeichnung existiert bereits ein Verteilerschlüssel. Bitte wählen Sie einen anderen Namen.',
        icon: <img src={RedCross} alt='red-cross'/>,
        id: 'allocationKeyDuplicateError'
      })
    );
  }

  handleSubmit = (e) => {
    e.preventDefault();
    let isValid = this.validateForm();
    if (isValid) {
      console.log('this.props.allocationData: ', this.props.allocationData);
      if (this.props.edit) {
        let count = 0;
        this.props.allocationData.map((allocationKey)=> ((this.state.name !== 'Personenanzahl') && allocationKey.allocationKeyName === this.state.name) ? count+=1 : count);
        console.log('count: ', count);
        if(count < 2){
          this.saveChanges();
        }else{
          this.showNameDuplicateError();
        }
      } else {
        console.log('allocationKey: ', this.props.allocationData.find((allocationKey)=> (this.state.name !== 'Personenanzahl') && allocationKey.allocationKeyName === this.state.name) === undefined)
        if(this.props.allocationData.find((allocationKey)=> (this.state.name !== 'Personenanzahl') && allocationKey.allocationKeyName === this.state.name) === undefined){
          this.addData();
        }else{
          this.showNameDuplicateError();
        }
      }
    }
  };

  saveChanges = () => {
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'block';
    const editData = JSON.stringify({
      id: this.props.record.id,
      allocationKeyName: this.state.name,
      propertyId: this.props.propertySelected.id,
      units: this.state.measurementUnit,
      isMetered: (this.state.selectedType === 'FIXED_TENANT' || this.state.selectedType === 'FIXED_RENTAL_UNIT') ? false : true,
      systemAllocationKey: this.props.record.systemAllocationKey,
      type: this.state.type,
      allocationKeyType: this.state.selectedType,
      meterType: this.state.selectedMeterType
    });

    // let add_btn = document.getElementById('allocaltion_key_add_btn');
    // let cancel_btn = document.getElementById('allocaltion_key_cancel_btn');
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'AllocationKeys/' + this.props.record.id,
      'PUT',
      editData,
      null,
      false,
      true,
      false,
      this,
      '_handle_update_allocaltion_key',
      null,
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };

  addData = () => {
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'block';
    const data = JSON.stringify({
      id: null,
      allocationKeyName: this.state.name,
      name: this.state.name,
      propertyId: this.props.propertySelected.id,
      units: this.state.measurementUnit,
      metered: (this.state.selectedType === 'FIXED_TENANT' || this.state.selectedType === 'FIXED_RENTAL_UNIT') ? false : true,
      systemAllocationKey: true,
      type: 'NON_HEATING',
      allocationKeyType: this.state.selectedType,
      meterType: this.props.propertySelected.propertyType === 'REGULAR' ? (this.state.selectedType === 'USAGE_BASED' ? this.state.selectedMeterType : 'RENTAL_UNIT') : 'RENTAL_UNIT'
    });
    let add_btn = document.getElementById('allocaltion_key_add_btn');
    let cancel_btn = document.getElementById('allocaltion_key_cancel_btn');
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'AllocationKeys',
      'POST',
      data,
      add_btn.children[0],
      false,
      true,
      false,
      this,
      '_handle_add_allocaltion_key',
      { cancelbtn: cancel_btn.children[0] },
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };

  _handle_add_allocaltion_key(response) {
    this.props.callBackFunction(response); //parent.getCostTypes();
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'none';
    if(response.status === true && response.statuscode === 200){
      showNotification({
        message: 'Der Verteilerschlüssel wurde erfolgreich hinzugefügt.',
        icon: <img src={GreenTick} alt='green-tick'/>,
      });
      console.log('_handle_add_allocaltion_key: ', response);
      this.props.selectedType(this.state.selectedType, response.result.id, response.result.units, this.state.name);
    }else{
      showNotification({
        message: 'Es ist ein Fehler aufgetreten. Bitte probieren Sie es erneut oder wenden Sie sich an den Support.',
        icon: <img src={RedCross} alt='red-cross'/>,
      })
    }
    // this.props.toggle();
    this.resetForm();
    response.other.cancelbtn.click();
  }

  _handle_update_allocaltion_key(response) {
    this.props.callBackFunction(); //parent._fetchAllocationKeyByProperty();
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'none';
    if(response.status === true && response.statuscode === 200){
      showNotification({
        message: 'Die Änderungen wurden übernommen.',
        icon: <img src={GreenTick} alt='green-tick'/>,
      });
      //this.props.selectedType(this.state.selectedType, response.result.id, response.result.units);
    }else {
      showNotification({
        message: 'Es ist ein Fehler aufgetreten. Bitte probieren Sie es erneut oder wenden Sie sich an den Support.',
        icon: <img src={RedCross} alt='red-cross'/>,
      });
    }
    console.log('this.props.propertySelected: ', this.props.propertySelected);
    if(this.props.edit && this.state.name === 'Personenanzahl'){
      let paylaod = {
        ...this.props.propertySelected,
        occupantsConfiguration: this.state.selectedNumberOfPersonsForEmptyApartments
      }
      Autoload.HttpRequestAxios.HttpRequest(
        Autoload.Setting.property_api_base_url + 'properties/' + this.props.propertySelected.id,
        'PUT',
        paylaod,
        null,
        false,
        true,
        false,
        this,
        '_handle_update_property',
        null,
        false,
        '',
        {
          'Content-Type': 'application/json',
          Authorization: Autoload.Utils.getAuthToken(),
        }
      );
    }else{
      this.props.toggle();
    }
  }

  _handle_update_property(response){
    if(response.status === true && response.statuscode === 200){
      let {propertySelected} = this.props;
      propertySelected = {
        ...propertySelected,
        occupantsConfiguration: response.result.occupantsConfiguration
      }
      this.props.updatePropetyInfo(propertySelected);
      this.props.toggle();
    }
  }

  handleEnterKeyPress = (e) => {
    if (e.charCode == 13) {
      e.preventDefault();
      this.handleSubmit(e);
    }
  };

  handleSelect=(e)=>{
    console.log('selectedNumberOfPersonsForEmptyApartments: ', e);
    this.setState({selectedNumberOfPersonsForEmptyApartments: e[0].value});
  }

  render() {
    const { open, toggle, className, edit, record, propertySelected, allocationData } = this.props;
    const { selectedType, selectedMeterType, hover1, hover2, hover3, hover4, hover5, typeError, meterTypeError, numberOfPersonsForEmptyApartments, selectedNumberOfPersonsForEmptyApartments, isMobile } = this.state;

    console.log('allocationData: ', allocationData);
    return (
      <div className="allocation_key_modal">
        <CustomModal
          heading={edit ? lang.newKeys.text_edit_allocation_key : lang.newKeys.text_add_allocation_key}
          open={open}
          toggle={() => {
            toggle();
          }}
          className={`${className}`}
        >
          {!edit &&<Group style={{marginTop: '20px'}}><span className='add-allocation-sub-headings'>Art des Verteilerschüssels</span></Group>}
          {!edit && 
          <Stack spacing={0} style={{marginBottom: '20px'}}>
            <Group grow noWrap style={{margin: '20px 0px 5px'}}>
              <Group position='center'
                align={'flex-start'}
                className={isMobile ? 'allocation-type-button-mobile' : 'allocation-type-button'}
                onMouseEnter={()=> this.handleMouseEnter('hover1')}
                onMouseLeave={()=> this.handleMouseLeave('hover1')}
                onClick={()=> this.setState({selectedType: 'FIXED_RENTAL_UNIT', typeError: false})}
                style={{background: selectedType === 'FIXED_RENTAL_UNIT' && '#E3E3FC', borderColor: typeError && !hover1 && selectedType !== 'FIXED_RENTAL_UNIT' && '#dc3545'}}>
                    <Stack spacing={'5px'}>
                        <Group position={'apart'} noWrap align={'flex-start'}>
                            <span style={{fontWeight: '600', fontFamily: 'Inter, sans-sarif', fontSize: '16px', color: selectedType === 'FIXED_RENTAL_UNIT' ? '#5555FF' : hover1 ? '#5555FF' : '#252563', wordBreak: 'break-word'}}>Fester Verteilerschlüssel</span>
                            <img style={{marginTop: '3px'}} src={selectedType === 'FIXED_RENTAL_UNIT' ? planBlue : hover1 ? planBlue : plan} width='16px' height={'16px'} />
                        </Group>
                        <span style={{textAlign: '', fontWeight: '400', color: selectedType === 'FIXED_RENTAL_UNIT' ? '#252563' : '#252563'}}>Sie wollen feste Verteilerwerte je Mieteinheit nutzen (z.B. beheizte Wohnfläche).</span>
                    </Stack>
              </Group>
              {/* <Group position='center'
              align={'flex-start'}
              className='allocation-type-button'
              onMouseEnter={()=> this.handleMouseEnter('hover2')}
              onMouseLeave={()=> this.handleMouseLeave('hover2')}
              onClick={()=> this.setState({selectedType: 'FIXED_TENANT'})}
              style={{background: selectedType === 'FIXED_TENANT' && '#E3E3FC'}}>
                  <Stack spacing={'5px'}>
                      <Group position={'apart'} noWrap align={'flex-start'}>
                          <span style={{fontWeight: '600', fontFamily: 'Inter, sans-sarif', fontSize: '16px', color: selectedType === 'FIXED_TENANT' ? '#5555FF' : hover2 ? '#5555FF' : '#252563'}}>Fester Verteilerschlüssel (Mieter)</span>
                          <img style={{marginTop: '3px'}} src={selectedType === 'FIXED_TENANT' ? TenantIconBlue : hover2 ? TenantIconBlue : TenantIcon} width='16px' height={'16px'} />
                      </Group>
                      <span style={{textAlign: '', fontWeight: '400', color: selectedType === 'FIXED_TENANT' ? '#252563' : '#252563'}}>Sie wollen feste Verteilerwerte je Mieter nutzen (z.B. Personenanzahl).</span>
                  </Stack>
              </Group> */}
              <Group position='center'
              align={'flex-start'}
              className={isMobile ? 'allocation-type-button-mobile' : 'allocation-type-button'}
              onMouseEnter={()=> this.handleMouseEnter('hover3')}
              onMouseLeave={()=> this.handleMouseLeave('hover3')}
              onClick={()=> this.setState({selectedType: 'USAGE_BASED', typeError: false})}
              style={{background: selectedType === 'USAGE_BASED' && '#E3E3FC', borderColor: typeError && !hover3 && selectedType !== 'USAGE_BASED' && '#dc3545'}}>
                  <Stack spacing={'5px'}>
                      <Group position={'apart'} noWrap align={'flex-start'}>
                          <span style={{fontWeight: '600', fontFamily: 'Inter, sans-sarif', fontSize: '16px', color: selectedType === 'USAGE_BASED' ? '#5555FF' : hover3 ? '#5555FF' : '#252563', wordBreak: 'break-word'}}>Jährliche Verbrauchserfassung</span>
                          <img style={{marginTop: '3px'}} src={selectedType === 'USAGE_BASED' ? BlueEmptyMeter : hover3 ? BlueEmptyMeter : MeterIcon} width='16px' height={'16px'} />
                      </Group>
                      <span style={{textAlign: '', fontWeight: '400', color: selectedType === 'USAGE_BASED' ? '#252563' : '#252563'}}>Sie erfassen jährliche Verbräuche (z.B. Anzahl Müllleerungen).</span>
                  </Stack>
              </Group>
            </Group>
            {typeError && 
            <Group style={{width: '100%',
              fontSize: '80%',
              color: '#dc3545'}}>
              Bitte Auswahl treffen
            </Group>}
          </Stack>}
          {selectedType === 'USAGE_BASED' && propertySelected.propertyType === 'REGULAR' &&
          <Stack spacing={0} style={{marginBottom: '20px'}}>
            <Group style={{marginTop: edit && '20px'}}><span className='add-allocation-sub-headings'>Welchen Verbrauch erfassen Sie?</span></Group>
            <Group grow noWrap style={{margin: '20px 0px 5px'}}>
              <Group position='center'
              align={'flex-start'}
              className={isMobile ? 'allocation-meter-type-button-mobile' : 'allocation-meter-type-button'}
              onMouseEnter={()=> this.handleMouseEnter('hover5')}
              onMouseLeave={()=> this.handleMouseLeave('hover5')}
              onClick={()=> this.setState({selectedMeterType: 'RENTAL_UNIT', meterTypeError: false})}
              style={{background: selectedMeterType === 'RENTAL_UNIT' && '#E3E3FC', borderColor: meterTypeError && !hover5 && selectedMeterType !== 'RENTAL_UNIT' && '#dc3545'}}>
                  <Stack spacing={'5px'}>
                      <Group position={'apart'} noWrap align={'flex-start'}>
                          <span style={{fontWeight: '600', fontFamily: 'Inter, sans-sarif', fontSize: '16px', color: selectedMeterType === 'RENTAL_UNIT' ? '#5555FF' : hover5 ? '#5555FF' : '#252563', wordBreak: 'break-word'}}>Mieterverbräuche</span>
                          <img style={{marginTop: '3px'}} src={selectedMeterType === 'RENTAL_UNIT' ? MultipleTenantsBlue : hover5 ? MultipleTenantsBlue : MultipleTenants} width='16px' height={'16px'} />
                      </Group>
                      <span style={{textAlign: '', fontWeight: '400', color: selectedMeterType === 'RENTAL_UNIT' ? '#252563' : '#252563'}}>Verbräuche der Mieter (z.B. Müllentleerungen).</span>
                  </Stack>
              </Group>
              <Group position='center'
                align={'flex-start'}
                className={isMobile ? 'allocation-meter-type-button-mobile' : 'allocation-meter-type-button'}
                onMouseEnter={()=> this.handleMouseEnter('hover4')}
                onMouseLeave={()=> this.handleMouseLeave('hover4')}
                onClick={()=> this.setState({selectedMeterType: 'PROPERTY', meterTypeError: false})}
                style={{background: selectedMeterType === 'PROPERTY' && '#E3E3FC', borderColor: meterTypeError && !hover4 && selectedMeterType !== 'PROPERTY' && '#dc3545'}}>
                    <Stack spacing={'5px'}>
                        <Group position={'apart'} noWrap align={'flex-start'}>
                            <span style={{fontWeight: '600', fontFamily: 'Inter, sans-sarif', fontSize: '16px', color: selectedMeterType === 'PROPERTY' ? '#5555FF' : hover4 ? '#5555FF' : '#252563', wordBreak: 'break-word'}}>Hausverbräuche</span>
                            <img style={{marginTop: '3px'}} src={selectedMeterType === 'PROPERTY' ? PropertyIconBlue : hover4 ? PropertyIconBlue : PropertyIcon} width='16px' height={'16px'} />
                        </Group>
                        <span style={{textAlign: '', fontWeight: '400', color: selectedMeterType === 'PROPERTY' ? '#252563' : '#252563'}}>Verbräuche des Mietshauses (z.B. Allgemeinstrom).</span>
                    </Stack>
              </Group>
            </Group>
            {meterTypeError && 
            <Group style={{width: '100%',
              fontSize: '80%',
              color: '#dc3545'}}>
              Bitte Auswahl treffen
            </Group>}
          </Stack>}
          <Form onSubmit={(e) => this.handleSubmit(e)} style={{marginTop: !edit && '20px'}}>
          {!edit &&<Group style={{marginBottom: '5px'}}><span className='add-allocation-sub-headings'>{'Name & Einheit'}</span></Group>}
            {' '}
            <Container>
              <Row>
                <Col xs={edit ? 12 : 6}>
                  <Form.Group className="name-allocation-modal">
                    <Form.Label className="property-modal-label">{lang.general.text_name}</Form.Label>
                    <Form.Control
                      type="text"
                      isInvalid={this.state.nameErrMsg}
                      placeholder="z.B. beheizte Wohnfläche"
                      value={this.state.name}
                      onChange={(e) =>
                        this.setState({
                          name: e.target.value,
                          nameErrMsg: false
                        })
                      }
                      disabled={edit && record && record.systemAllocationKey}
                      onKeyPress={this.handleEnterKeyPress}
                    />
                    <Form.Control.Feedback className="err" type="invalid">
                      {lang.property.generalinfo_propertynameError}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                {!edit &&
                <Col xs={6}>
                  <Form.Group className="key-allocation-modal">
                    <Form.Label className="property-modal-label">
                      {lang.property.allocationKeys_tablehead_unit} (optional)
                    </Form.Label>
                    <Form.Control
                      type="text"
                      isInvalid={this.state.measurementErrMsg}
                      placeholder="z.B. m2"
                      value={this.state.measurementUnit}
                      onChange={(e) =>
                        this.setState({
                          measurementUnit: e.target.value,
                          measurementErrMsg: false
                        })
                      }
                      disabled={edit && record && record.systemAllocationKey}
                      onKeyPress={this.handleEnterKeyPress}
                    />
                    <Form.Control.Feedback className="err" type="invalid">
                      {lang.newKeys.text_error_measurement_unit}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>}
              </Row>
              {edit &&
              <Row>
                <Col xs={12} md={12}>
                  <Form.Group className="key-allocation-modal">
                    <Form.Label className="property-modal-label">
                      {lang.property.allocationKeys_tablehead_unit} (optional)
                    </Form.Label>
                    <Form.Control
                      type="text"
                      isInvalid={this.state.measurementErrMsg}
                      placeholder="z.B. m2"
                      value={this.state.measurementUnit}
                      onChange={(e) =>
                        this.setState({
                          measurementUnit: e.target.value,
                          measurementErrMsg: false
                        })
                      }
                      disabled={edit && record && record.systemAllocationKey}
                      onKeyPress={this.handleEnterKeyPress}
                    />
                    <Form.Control.Feedback className="err" type="invalid">
                      {lang.newKeys.text_error_measurement_unit}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>}
              {edit && this.state.name === 'Personenanzahl' &&
              <Row>
                <Col xs={12} md={12}>
                  <Form.Group className="key-allocation-modal">
                    <Form.Label className="property-modal-label tooltips number-of-occupants-for-empty-tenants">
                      {'Personenanzahl für leerstehende Wohnungen'}
                    </Form.Label>
                    <Multiselect
                    className="ub_multiselect_id"
                    options={numberOfPersonsForEmptyApartments}
                    displayValue="label"
                    style={{
                      chips: { 
                        borderRadius: '0px', 
                        color: '#111147', 
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        width: 'auto',
                        paddingLeft: '5px',
                        marginTop: '4px',
                        marginRight: '10px',
                        fontSize: '14px',
                        fontWeight: '400',
                        fontFamily: 'sans-serif'
                      },
                      searchBox: {
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        height: '36px',
                        border: '1px solid #d5dce6',
                        paddingRight: '10px',
                        paddingLeft: 0,
                        //background: utilityData.isSecured && '#f7f7fa',
                      },
                      multiselectContainer: { 
                        margin: 0,
                        padding: 0
                      },
                      inputField: { marginRight: '0px', width: '100%', paddingLeft: '5px', paddingRight: '5px', cursor: 'pointer' },
                      option: {
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: '#0e0e1d',
                        padding: '10px',
                      }
                    }}
                    placeholder="Wählen"
                    closeIcon="cancel"
                    avoidHighlightFirstOption={true}
                    singleSelect={true}
                    closeOnSelect={true}
                    selectedValues={numberOfPersonsForEmptyApartments.filter((dt)=> dt.value === selectedNumberOfPersonsForEmptyApartments)}
                    onSelect={this.handleSelect}
                    />
                    <Form.Control.Feedback className="err" type="invalid">
                      {lang.newKeys.text_error_measurement_unit}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>}
              <div className="cancel_addbtn">
                <div id="allocaltion_key_cancel_btn">
                  <ColorButton
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.toggle();
                    }}
                    className="gray-btn"
                    text={lang.general.text_cancel}
                  />
                </div>
                <div id="allocaltion_key_add_btn">
                  <ColorButton
                    buttonConfig={{ type: 'submit' }}
                    className="purple-btn"
                    text={edit ? lang.newKeys.text_save_allocation_key : 'Hinzufügen'}
                  />
                </div>
              </div>
            </Container>
          </Form>
        </CustomModal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    allocationDataRedux: state.allocationKeyReducer,
    propertySelected: state.generalReducer.property,
  };
};

export default connect(mapStateToProps, { addAllocationData, editAllocationData, updatePropetyInfo })(AddAllocationKeyModal);
