import List from '../../../views/pages/Module/Documents/List';

const pageList = [
  {
    name: 'Documents',
    path: '/documents/',
    component: List,
  },
];

export default pageList;
