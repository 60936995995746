import React, { Component, useEffect } from 'react';
import CrossIcon from '../../../../../assets/images/module/general/CrossIcon.svg';
import { ColorButton } from '../../../../../neoverv';
export default function ErrorMsg({ errorMsg, buttonDisable = false, ClassName }) {
  useEffect(() => {
    document.getElementById('errorattop').style.display = 'none';
  });

  return (
    <div className={ClassName}>
      <div className="info-msg" id="errorattop">
        <div></div>
        <div className="info-msg-div">
          <div>
            <p className="info-msg-text" id="hh_errorattop_mag">
              {errorMsg}
            </p>
          </div>
          {buttonDisable && (
            <div>
              <ColorButton className="gray-btn" text="label" />
            </div>
          )}
        </div>
        <div>
          <img
            style={{ cursor: 'pointer' }}
            src={CrossIcon}
            onClick={() => {
              document.getElementById('errorattop').style.display = 'none';
            }}
            alt="cancel-icon"
          />
        </div>
      </div>
    </div>
  );
}
