import React from 'react';
import { HeadingContainer, FiltersContainer } from '../../../../../neoverv/index';
import Autoload from '../../../../../neoverv/Autoload';

export default function PageHeader({ heading, rightContent, headingClassName, sortElement, sortElement1, switchElement, switchElement1, searchElement, filterStyle, className, thirdRowContent=null, isMobile=false, hideDivider=false, style=null, marginBottom, hideFilters=false }) {
  return (
    <div className="page-header" style={style}>
      {(heading || rightContent) &&
      <HeadingContainer 
        heading={heading} 
        rightContent={rightContent} 
        headingClassName={headingClassName}
        className={className}
        marginBottom={marginBottom}
      />}
      {!hideFilters &&
      <FiltersContainer 
        sortElement={sortElement}
        switchElement={switchElement}
        searchElement={searchElement}
        style={filterStyle}
        isMobile={isMobile}
      />}
      {!hideFilters &&
      <div className='filters' style={{display: 'flex', alignItems: 'center', marginBottom: '0px'}}>
        {thirdRowContent}
        {switchElement1 && 
        <div className='section-2'>
          <div className='switch-filter'>
              {switchElement1}
          </div>
        </div>}
        {sortElement1 && 
        <div className='section-3'>
          <div className='sort-filter'>
              {sortElement1}
          </div>
        </div>}
      </div>}
      {!hideDivider && <div className='divider' style={{margin: !isMobile && 0}}></div>}
    </div>
  );
}
