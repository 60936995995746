import React, { Component } from 'react';
import { CustomCard, ColorButton } from '../../../../../neoverv';
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Autoload from '../../../../../neoverv/Autoload';
import Spinner from 'react-bootstrap/Spinner';
var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default].general;

export class Forgotpassword extends Component {
  state = {
    email: '',
    emailErr: false,
  };
  onInputChange = (field, e) => {
    this.setState({ [field]: e.target.value });
  };
  handleSentInsBtn = () => {
    var forgotForm = document.getElementById('forgot-form');
    var confirmationBox = document.getElementById('ins-sent');
    forgotForm.style.display = 'none';
    confirmationBox.style.display = 'block';
  };
  validateEmail = (e) => {
    e.preventDefault();
    this.setState({ emailErr: false });
    let emailErr = false;
    const validEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!this.state.email || !validEmail.test(this.state.email)) {
      emailErr = true;
    }
    this.setState({ emailErr });
    if (!emailErr) {
      var data = JSON.stringify({ email: this.state.email });
      Autoload.HttpRequestAxios.HttpRequest(
        Autoload.Setting.sign_in_bas_url + 'signIn/passwordInstructions',
        'POST',
        data,
        null,
        false,
        true,
        false,
        this,
        'handle_forgotpassword',
        null,
        false,
        '',
        {
          'Content-Type': 'application/json',
        }
      );
      this.handleSentInsBtn();
    }
  };
  handle_forgotpassword = (response) => {
    console.log('response handle-forgot-password', response);
    if (response.status === true && response.statuscode === 401) {
      localStorage.clear();
      window.location.reload();
    }

    if (response.status === true && response.statuscode === 200) {
      this.setState({
        email: Autoload.MainHelper.checkProperty(response.result, 'email'),
      });
    }
  };

  render() {
    return (
      <div className="center-content forgot-pw-page">
          <div className="hh_loader" id="hello-housing-loader" style={{ display: 'none' }}>
          <div className="hh_loader_bg"></div>
          <Spinner animation="border" className="hh_loader_spinner" variant="primary" />
        </div>
        <div className="logo-section">
          <img src={Setting.logo} alt="logo" />
        </div>

        <div id="forgot-form" className="main-form-section">
          <CustomCard className="show forgot-form" heading={`${lang.text_forgot_pass_heading}?`}>
            <Form onSubmit={(e) => this.validateEmail(e)} noValidate>
              <p className="forgot-text">{lang.text_forgot_pass_instuctions}</p>
              <div>
                <Form.Group>
                  <Form.Label>{lang.text_email}</Form.Label>
                  <Form.Control
                    isInvalid={this.state.emailErr}
                    type="email"
                    value={this.state.email}
                    onChange={(e) => {
                      this.onInputChange('email', e);
                    }}
                  />
                  <Form.Control.Feedback type="invalid"> {lang.text_invalid_email} </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="center-spacing">
                <Link to="/login">{lang.text_go_back}</Link>
                <ColorButton
                  buttonConfig={{ type: 'submit' }}
                  className="purple-btn"
                  text={lang.text_send_instructions}
                />
              </div>
            </Form>
          </CustomCard>
        </div>
        <div style={{ display: 'none' }} id="ins-sent" className="main-form-section">
          <CustomCard className="hide ins-sent" heading={`${lang.text_reset_instruction_heading}!`}>
            <Form>
              <p className="forgot-text">{lang.text_reset_instruction_info}</p>
              {/* <div>
                <ColorButton
                  onClick={() => (window.location.href = '/resetpassword')}
                  className="purple-btn"
                  text={lang.text_back_to_login}
                />
              </div> */}
            </Form>
          </CustomCard>
        </div>
        <div>
          <br />
          {/* <ColorButton
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              window.open('/resetpassword', '_blank');
              // window.location.href = '/resetpassword';
              // // ;
            }}
            className="purple-btn"
            text="Temporary button to reset password"
          />
          <br /> */}
        </div>
      </div>
    );
  }
}

export default Forgotpassword;
