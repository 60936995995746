import React, { Component } from 'react';
import UploadIcon from '../../../../../assets/images/module/general/UploadIcon.svg';
import PdfIcon from '../../../../../assets/images/module/general/PdfIcon.svg';
import ImgIcon from '../../../../../assets/images/module/general/ImgIcon.svg';
import TrashIcon from '../../../../../assets/images/module/general/TrashIcon.svg';
import DocumentIcon from '../../../../../assets/images/module/general/DocumentIcon.svg';
import DocumentIconBlue from '../../../../../assets/images/module/general/DocumentIconBlue.svg';
import Dropzone from 'react-dropzone';
import Autoload from '../../../../Autoload';
import { ValueText } from '../../../../index';
import { Dropdown } from 'reactstrap';
import {DeleteModal} from '../../../../index';
import UtilityBillingAssignedIcon from '../../../SVGs/utilityBillingAssigned';
import { Row, Col, Form, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { Group } from '@mantine/core';
import EXIF from 'exif-js';


var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
var { checkEmpty } = Autoload.MainHelper;

class DropFile extends Component {
  state={
    deleteModal: false,
    selectedDeleteFile: null
  }
  showSelectedFile = (file, removeFile) => {
    if (file && file.length > 0) {
      if (file.length > 0) {
        return (
          <div className="file-details">
            <ValueText
              leftImage={{ img: this.props.fileType === 'img' ? ImgIcon : PdfIcon, style: { width: '16px', height: '16px' }, onClick: ()=> this.props.onClick()}}
              text={
                <div style={{cursor: 'pointer', wordBreak: 'break-all'}} onClick={()=> this.props.onClick()}>
                  {typeof file !== 'string' ? file[0].path ? file[0].path : file[0].name : this.props.filename}
                </div>
                // typeof file !== 'string' ?
                // file[0].path : file.split('\\').pop().split('/').pop()
              }
              rightImage={{ img: TrashIcon, style: { width: '16px', height: '16px' }, handler: removeFile }}
            />
          </div>
        );
      } else {
        return (
          <div className="file-details">
            <ValueText className="file-selection-err" text="Invalid file selection" />
          </div>
        );
      }
    }
  };
  showMultiFiles = (files, removeFile) => {
    console.log('show multi files', files)
    let width = document?.getElementById('file-details')?.offsetWidth;
    if (files && files.length > 0) {
      return files.map((file, i) => {
        // console.log('loop', file.path)
        if(this.props.downloadable && file.fileLocation){
          return (
            <div id='file-details' className="file-details">
              
              <ValueText
                leftImage={{ img: file && (file[file.path ? 'path' : 'name'].includes('jpg') || file[file.path ? 'path' : 'name'].includes('png') || file[file.path ? 'path' : 'name'].includes('jpeg') || file[file.path ? 'path' : 'name'].includes('svg') || file[file.path ? 'path' : 'name'].includes('gif')) ? ImgIcon : file[file.path ? 'path' : 'name'].includes('pdf') ? PdfIcon : DocumentIconBlue, style: { width: '16px', height: '16px' }}}
                text={
                  <a href={file.fileLocation} target='_blank' download style={{textDecoration:'none', color: '#111147'}}>
                  {typeof file !== 'string' ? file[file.path ? 'path' : 'name'] : this.props.filename}
                  </a>
                  // typeof file !== 'string' ?
                  // file[0].path : file.split('\\').pop().split('/').pop()
                }
                //textHandler={()=> <a href={file.fileLocation} target='_blank' download style={{textDecoration:'none'}}/>}
                textStyle={{
                  whiteSpace: 'nowrap', 
                  overflow: 'hidden', 
                  textOverflow: 'ellipsis', 
                  fontSize: '14px',
                  fontWeight: '300',
                  fontFamily: 'Inter, sans-serif',
                  textAlign: 'left',
                  marginRight: '8px',
                  width: width ? (width - 60) : '394px'
                }}
                rightImage={{ img: TrashIcon, style: { width: '16px', height: '16px', cursor: 'pointer' }, handler: () => this.setState({selectedDeleteFile: file, deleteModal: true}) }}
              />
              {/* </a> */}
            </div>
          );
        }else{
          return (
            <div id='file-details' className="file-details">
              <ValueText
                leftImage={{ img: file && (file[file.path ? 'path' : 'name'].includes('jpg') || file[file.path ? 'path' : 'name'].includes('png') || file[file.path ? 'path' : 'name'].includes('jpeg') || file[file.path ? 'path' : 'name'].includes('svg') || file[file.path ? 'path' : 'name'].includes('gif')) ? ImgIcon : file[file.path ? 'path' : 'name'].includes('pdf') ? PdfIcon : DocumentIconBlue, style: { width: '16px', height: '16px' } }}
                text={
                  typeof file !== 'string' ? file[file.path ? 'path' : 'name'] : this.props.filename
                  // typeof file !== 'string' ?
                  // file[0].path : file.split('\\').pop().split('/').pop()
                }
                textStyle={{
                  whiteSpace: 'nowrap', 
                  overflow: 'hidden', 
                  textOverflow: 'ellipsis', 
                  fontSize: '14px',
                  fontWeight: '300',
                  fontFamily: 'Inter, sans-serif',
                  textAlign: 'left',
                  marginRight: '8px',
                  width: width ? (width - 60) : '394px'
                }}
                rightImage={{ img: TrashIcon, style: { width: '16px', height: '16px', cursor: 'pointer' }, handler: () => removeFile(file) }}
              />
            </div>
          );
        }
      });
    }
    // else {
    //   return (
    //     <div className="file-details">
    //       <ValueText className="file-selection-err" text="Invalid file selection" />
    //     </div>
    //   );
    // }
  };

  toggleDeleteAccountModal=()=>{
    this.setState({deleteModal: !this.state.deleteModal});
  }

  handleDrop= async (acceptedFiles, rejectedFiles) =>{
    const {handleDrop} = this.props;
    const unprocessedFiles = acceptedFiles.filter(file => !file.type.startsWith('image/'));
    const processedFiles = acceptedFiles.filter(file => file.type.startsWith('image/')) // Filter to only process image files
    .map(file => 
      new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = function(event) {
          const img = new Image();
          img.src = event.target.result;

          img.onload = function() {
            // Get EXIF orientation data
            EXIF.getData(img, function() {
              const orientation = EXIF.getTag(this, "Orientation") || 1;

              console.log('orientation: ', orientation);

              // Create a canvas to apply orientation correction
              const canvas = document.createElement("canvas");
              const ctx = canvas.getContext("2d");

              let width = img.width;
              let height = img.height;

              // Adjust canvas size based on orientation
              if (orientation === 6 || orientation === 8) {
                canvas.width = width;
                canvas.height = height;
              } else {
                canvas.width = width;
                canvas.height = height;
              }

              // Apply the necessary rotation based on the EXIF orientation
              switch (orientation) {
                case 6: // 90 degrees
                  // ctx.rotate(90 * Math.PI / 180);
                  // ctx.translate(0, -height);
                  
                  break;
                case 8: // -90 degrees
                  // ctx.rotate(-90 * Math.PI / 180);
                  // ctx.translate(-width, 0);
                  break;
                case 3: // 180 degrees
                  ctx.rotate(Math.PI);
                  ctx.translate(-width, -height);
                  break;
                // case 2: ctx.translate(width, 0);     ctx.scale(-1,1); break;
                // case 3: ctx.translate(width,height); ctx.rotate(Math.PI); break;
                // case 4: ctx.translate(0,height);     ctx.scale(1,-1); break;
                // case 5: ctx.rotate(0.5 * Math.PI);   ctx.scale(1,-1); break;
                // case 6: ctx.rotate(0.5 * Math.PI);   ctx.translate(0,-height); break;
                // case 7: ctx.rotate(0.5 * Math.PI);   ctx.translate(width,-height); ctx.scale(-1,1); break;
                // case 8: ctx.rotate(-0.5 * Math.PI);  ctx.translate(-width,0); break;
                default: // Normal orientation
                  break;
              }

              // Draw the corrected image onto the canvas
              ctx.drawImage(img, 0, 0, width, height);

              // Convert the canvas to a data URL with corrected orientation
              const correctedImageData = canvas.toDataURL("image/jpeg");

              // Convert base64 image data to Blob
              const byteString = atob(correctedImageData.split(',')[1]); // Remove the base64 prefix and decode
              const byteArray = new Uint8Array(byteString.length);

              // Populate the byteArray with the decoded bytes
              for (let i = 0; i < byteString.length; i++) {
                byteArray[i] = byteString.charCodeAt(i);
              }

              // Create a Blob from the byteArray
              const imageBlob = new Blob([byteArray], { type: 'image/jpeg' });

               // Convert the Blob to a File
               const correctedFile = (orientation === 3 || orientation === 6 || orientation === 8) ? new File([imageBlob], file.name, {
                type: 'image/jpeg',
                lastModified: new Date().getTime(),
              })
              : file;

              // Resolve the promise with the corrected image data
              resolve(
                //originalFile: file,
                correctedFile
              );
            });
          };
        };
        reader.readAsDataURL(file);
      })
    );
    Promise.all(processedFiles).then((files) => {
      handleDrop([...files, ...unprocessedFiles], rejectedFiles);
    });
  }

  render() {
    const { maxSize, accept, handleDrop, file, removeFile, filename, customProps, multiSelect = false, downloadable=false, csvFormat=false, receiptModal=false, hideSecondText=false, secondText=null, wrapperStyle, innerWrapperStyle, currentSubscription, showAiEnableLabel=false } = this.props;
    console.log(!multiSelect && file && file.length>0 , 'modal props')
    console.log('file: ', file);
    console.log('accept: ', accept);
    return (
      <div style={wrapperStyle}>
        {receiptModal ? (file === null || file.length <= 0) &&
        <div
          className="file-dropzone"
          style={{...innerWrapperStyle, ...{
            cursor: !multiSelect || file || file.length > 0 ? 'default' : 'pointer',
          }}}
        >
          <Dropzone
            maxSize={maxSize}
            accept={accept ? accept : null}
            onDrop={(acceptedFiles, rejectedFiles) => this.handleDrop(acceptedFiles, rejectedFiles)}
            {...customProps}
          >
            {({ getRootProps, getInputProps }) => (
              <section>
                <div className={`drag-div  ${!checkEmpty(file) && 'fileSelected'}`} {...getRootProps()}>
                  {!multiSelect && file && file.length>0 ? <input {...getInputProps()} disabled /> : <input {...getInputProps()} />}
                  <img src={UploadIcon} alt="upload" width="16px" height="16px" />
                  <p className="first"> {lang.receipt.text_drag_file} </p>
                  {!hideSecondText && <p className="second"> {this.props.csvFormat ? lang.receipt.text_add_file_restrictions_csv : secondText ? secondText : lang.receipt.text_add_file_restrictions} </p>}
                </div>
              </section>
            )}
          </Dropzone>
        </div>
        :
        <div
          className="file-dropzone"
          style={{...innerWrapperStyle, ...{
            cursor: !multiSelect || file || file.length > 0 ? 'default' : 'pointer',
          }}}
        >
          <Dropzone
            maxSize={maxSize}
            accept={accept ? accept : null}
            onDrop={(acceptedFiles, rejectedFiles) => this.handleDrop(acceptedFiles, rejectedFiles)}
            {...customProps}
          >
            {({ getRootProps, getInputProps }) => (
              <section>
                <div className={`drag-div  ${!checkEmpty(file) && 'fileSelected'}`} {...getRootProps()}>
                  {!multiSelect && file && file.length>0 ? <input {...getInputProps()} disabled /> : <input {...getInputProps()} />}
                  <img src={UploadIcon} alt="upload" width="16px" height="16px" />
                  <p className="first"> {lang.receipt.text_drag_file} </p>
                  {!hideSecondText && <p className="second"> {this.props.csvFormat ? lang.receipt.text_add_file_restrictions_csv : secondText ? secondText : lang.receipt.text_add_file_restrictions} </p>}
                </div>
              </section>
            )}
          </Dropzone>
        </div>}
        {showAiEnableLabel &&
        <Form.Group>
          <Form.Label>
            <Group spacing={10} align={'center'}>
              {UtilityBillingAssignedIcon(currentSubscription.subscriptionKey.split('_')[0] === 'STARTER' ? '#9A9AB5' : '#5555FF')} 
              <span>{`Automatische Belegerkennung (KI) ${currentSubscription.subscriptionKey.split('_')[0] === 'STARTER' ? 'ist nicht aktiviert.' : 'ist aktiviert.'}`}</span>
            </Group>
          </Form.Label>
        </Form.Group>}
        {multiSelect ? this.showMultiFiles(file, removeFile) : this.showSelectedFile(file, removeFile)}
        <DeleteModal
          open={this.state.deleteModal}
          className="delete-rent-modal"
          toggle={this.toggleDeleteAccountModal}
          //record={this.state.accountID}
          handleDelete={() => {removeFile(this.state.selectedDeleteFile); this.toggleDeleteAccountModal();}}
          // showInfo={[this.props.propertyData.name,]}
          type={'Nutzerprofil'}
          showtext={false}
          typeText={
            'Sind Sie sicher, dass Sie dieses Dokument löschen möchten?'
          }
          modalId="delete-data-property"
          showDeleteText={false}
          textShowModal='Dokument löschen'
        />
      </div>
    );
  }
}

DropFile.defaultProps = {
  maxSize: 5242880,
  accept: 'image/jpeg,application/pdf',
  handleDrop: () => {},
  file: null,
  downloadable:false
};

export default DropFile;
