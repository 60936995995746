import React, { useState } from 'react';
import DownArrowIcon from '../../../../../assets/images/module/general/DownArrowIcon.svg';
import Autoload from "../../../../Autoload";

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default].general;
var lang_newKeys = Setting[Setting.language_default].newKeys;


SortContainer.defaultProps={
    ascendingOrder:true,
    sortingParam:'time',
    handleAscending:()=>{},
    handleDescending:()=>{},
    filterHandler:()=>{}
}

export default function SortContainer({ className, sortingParam, ascendingOrder, filterHandler}) {
    const [ascending, setAscending] = useState(ascendingOrder)
    const handleSort = () => {
        filterHandler()
        setAscending(!ascending)
    }
    let sortBy = ''
    switch (sortingParam) {
        case 'time':
            if(ascendingOrder){
                sortBy = lang.text_new_old
            }else{
                sortBy = lang.text_old_new
            }
            break;
        case 'alphabet':
            if(ascendingOrder){
                sortBy = lang.text_a_z
            }else{
                sortBy = lang.text_z_a
            }
            break;
        default:
            break;
    }
    return (
        <div className={className} onClick={() => handleSort()} style={{cursor:'pointer'}}>
            <span className='sort-lable' >{lang.text_sort}: </span>
            <span className='sort-value' >{`${lang_newKeys.text_from} ${sortBy}`}</span>
            <img
                className="sort-icon"
                style={!ascendingOrder ? { transform: 'rotate(180deg)' } : {}}
                
                src={DownArrowIcon}
                alt="arrow"
            />
        </div>
    );
}
