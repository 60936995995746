import React, { Component } from 'react';
import Autoload from '../../../../neoverv/Autoload';
import { connect } from 'react-redux';
import { hideNotification, showNotification } from '@mantine/notifications';
import CopyIcon from '../../../../neoverv/components/SVGs/copyIcon';
import GreenTick from '../../../../assets/images/module/general/GreenTick.svg';
import PlusIcon from '../../../../assets/images/module/general/PlusIcon.svg';
import RedTick from '../../../../assets/images/module/general/RedTick.svg';
import RedCross from '../../../../assets/images/module/general/RedCross.svg';
import UploadIcon from '../../../../assets/images/module/general/UploadIcon.svg';
import MeterIcon from '../../../../assets/images/module/general/blueEmptyMeter.svg'; 
import ExpandIconLogs from '../../../../assets/images/module/general/ExpandIconLogs.svg'; 
import RightIcon from '../../../../assets/images/module/general/RightIcon.svg';
import { Collapse, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TableSortLabel, Typography } from '@material-ui/core';
import dayjs from 'dayjs';
import TablePaginationActions from '@material-ui/core/TablePagination/TablePaginationActions';
import { DeleteModal, EmptyStateContainer, MeterModal } from '../../../../neoverv';
import { Accordion, Group, Popover, Skeleton } from '@mantine/core';
import MeterDataHandler from '../../../../neoverv/helpers/DataHandler/Meter';
import MeterLogsDetailMeterReadingsModal from '../../../../neoverv/components/Module/General/CustomModal/MeterLogsDetailMeterReadingsModal';
import VisibilityIcon from '@material-ui/icons/Visibility';
//import TablePaginationActions from './TablePaginationActions';

var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone') // dependent on utc plugin

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
const pStyle= {
    fontFamily: 'Inter, sans-serif',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '16px',
    color: 'rgb(14, 14, 29)'
}
const inputFieldStyle = {
  outline: 'none',
  lineHeight: '1.5em',
  paddingLeft: '0.8rem',
  paddingRight: '0.8rem',
  border: '1px solid #d5dce6',
  borderRadius: '4px',
  color: '#0E0E1D',
  height: '36px',
  background: '#fff',
  width: '150px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center'
}
const tableCellStyle = {
  whiteSpace: 'nowrap', 
  overflow: 'hidden', 
  textOverflow: 'ellipsis', 
  fontSize: '14px', 
  fontFamily: 'Inter, sans-serif', 
  height: 'auto !important',
  fontWeight: '400',
  borderTop: '8px solid white'
}
const chipStyle = {
  backgroundColor: 'white', //'#f2f2fc',
  borderRadius: '4px',
  padding: '2px 6px',
  fontSize: '14px',
  width: 'auto'
}
export class MeterUploadsLog extends Component {
  constructor(props) {
    super(props);

    dayjs.extend(utc);
    dayjs.extend(timezone);

    this.state = {
        tableRowValues: [
          {
            "id": "string",
            "readingId": "string",
            "readingDateTime": "2015-07-22T15:49:04-07:00",
            "hasError": false,
            "errorCodes": [
              "NO_CONSUMPTION"
            ],
            "meterNumbersFound": [
              "123",
              "123",
              "123",
              "123",
              "123",
              "123",
              "123",
              "123",
              "123",
              "123",
              "123",
              "123",
              "123",
              "123"
            ],
            "meterNumbersNotFound": [
              "123"
            ]
          },
          {
            "id": "string",
            "readingId": "string",
            "readingDateTime": "2016-07-20T15:49:04-07:00",
            "hasError": true,
            "errorCodes": [
              "NO_CONSUMPTION"
            ],
            "meterNumbersFound": [
              "123"
            ],
            "meterNumbersNotFound": [
              "123",
              "123",
              "123",
              "123",
              "123",
              "123",
              "123",
              "123",
              "123",
              "123",
              "123",
              "123",
              "123",
              "123"
            ]
          },
          {
            "id": "string",
            "readingId": "string",
            "readingDateTime": "2017-07-20T15:49:04-07:00",
            "hasError": false,
            "errorCodes": [
              "NO_CONSUMPTION"
            ],
            "meterNumbersFound": [
              "123"
            ],
            "meterNumbersNotFound": [
              "123"
            ]
          }
          ], 
        tableColumnNames: [
          {
          name:'Datum',
          key: 'date'
        },{
          name: 'Verarbeitung',
          key: 'status'
        },{
          name: 'Aktualisierte Zähler',
          key: 'meterNumbersFound'
        },{
          name: 'Nicht gefundene Zähler',
          key: 'meterNumbersNotFound'
        }],
        data: [],
        page: 0,
        rowsPerPage: 5,
        openMeterModal: false,
        edit: false,
        meter: {name: ''},
        openDelete: false,
        deleteReadingId: null,
        skeletonLoading: false,
        openFound: null,
        openNotFound: null,
        fetch: 10,
        offset: 0,
        failedErrorPopover: null,
        openMeterLogsDetailMeterReadings: false,
        selectedReadingId: null
    };
  }

  componentDidMount = () => {
    this.getUploadsLog();
    document.addEventListener('scroll', this.trackScrolling);
  };

  componentDidUpdate= (prevProps)=>{
    if(prevProps.refresh !== this.props.refresh){
      this.props.refresh && this.getUploadsLog(true);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.trackScrolling);
  }
  trackScrolling = () => {
    const wrappedElement = document.getElementById('app-content');
    if (this.isBottom(wrappedElement)) {
      console.log('offset: ', this.state.offset);
      this.setState({offset: this.state.data.length}, ()=> this.getUploadsLog());
      //document.removeEventListener('scroll', this.trackScrolling);
    }
  };

  isBottom(el) {
    return el.getBoundingClientRect().bottom <= window.innerHeight;
  }
  getUploadsLog = (refresh= false)=> {
    let offset = refresh ? 0 : this.state.offset;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.meter_api_base_url+ 'MeterReadingLogs?propertyId=' + this.props.propertySelected.id+'&offset='+offset+'&fetch='+this.state.fetch,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      '_handleUploadsLogResponse',
      {refresh},
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      },
      true
    );
  }
  _handleUploadsLogResponse = (response)=>{
    if(response.status == true){
      let data = response.other.refresh ? [] : this.state.data;
      response.result.map((item)=> data.find((dataItem)=> dataItem.id === item.id) ? null : data.push(item));
      this.setState({data}, ()=> console.log('_handleUploadsLogResponse: ', this.state.data));
    }else if(this.state.offset === 0 && response.status === false && response.message.includes('No log found')){
      this.setState({data: []});
    }
    this.props.logsLoaded();
    this.props.refreshLogsDisable();
    this.setState({skeletonLoading: false});
  }

  ////sorting functions start
  descendingComparator = (a, b, orderBy)=> {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  
  getComparator= (order, orderBy)=> {
    return order === 'desc'
      ? (a, b) => this.descendingComparator(a, b, orderBy)
      : (a, b) => -this.descendingComparator(a, b, orderBy);
  }
  
  stableSort=(array, comparator)=> {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  onRequestSort = (event, property) => {
    console.log('onRequestSort: ', property);
    const {order, orderBy} = this.state;
    const isAsc = orderBy === property && order === 'asc';
    this.setState({order: isAsc ? 'desc' : 'asc'});
    this.setState({orderBy: property});
  };
////sorting functions end

///// paging functions start
handleChangePage = (event, newPage) => {
  console.log('handleChangePage: ', newPage);
  this.setState({page: newPage});
};
handleChangeRowsPerPage = (event) => {
  this.setState({rowsPerPage: parseInt(event.target.value, 10)});
  this.setState({page: 0});
};
///// paging functions end
deleteReadings= (readingId)=>{
  hideNotification('deleteLogs');
  Autoload.HttpRequestAxios.HttpRequest(
    Autoload.Setting.meter_api_base_url+ 'readings/' + readingId,
    'DELETE',
    null,
    null,
    false,
    true,
    false,
    this,
    '_handleDeleteReadings',
    null,
    false,
    '',
    {
      'Content-Type': 'application/json',
      Authorization: Autoload.Utils.getAuthToken(),
    }
  );
}

_handleDeleteReadings=(response)=>{
  if(response.status){
    showNotification({
      message: 'Die Ablesedaten wurden gelöscht.',
      icon: <img src={RedTick} alt='red-tick'/>,
      id: 'deleteLogs'
    })
    this.state.offset === 1 && this.setState({offset: 0});
    this.toggleDeleteModal();
    this.getUploadsLog(true);
  }
}

toggleDeleteModal= ()=>{
  this.setState({openDelete: !this.state.openDelete});
}

_addNewMeter=(data)=>{
  {
    data.propertyId = this.props.propertySelected && this.props.propertySelected.id;
    const payload = MeterDataHandler.generateMeterDatawithAllocationKey(data);
    console.log(payload, data, 'data in meter=========');
    let btnelement = document.getElementById('meter-button-container');
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.meter_api_base_url + 'meters',
      'POST',
      payload,
      btnelement.children[0],
      false,
      true,
      true,
      this,
      '_handleCreateMeter',
      { btnelement: btnelement },
      false,
      '',
      { 'Content-Type': 'application/json', Authorization: Autoload.Utils.getAuthToken() }
    );
  }
}

_handleCreateMeter=(response)=>{
  if (response.statuscode !== 200) {
    if(response.statuscode === 409) {
      showNotification({
        id: 'meterDuplicate',
        message: "Diese Zählernummer wird bereits verwendet. Bitte wählen Sie eine andere Zählernummer.",
        icon: <img src={RedCross} alt='red-tick'/>,
      });
    }else {
      showNotification({
        message: "Es ist ein Fehler aufgetreten. Bitte probieren Sie es erneut oder wenden Sie sich an den Support.",
        icon: <img src={RedCross} alt='red-tick'/>,
      });
    }
  } else{
    window.Intercom("update", {
      api_base: "https://api-iam.intercom.io",
      app_id: "vvrs5bx3",
      'Meter Created': true
    });
    showNotification({
      message: "Der Zähler wurde angelegt.",
      icon: <img src={GreenTick} alt='green-tick'/>
    });
    window.Intercom('trackEvent', 'meter-created');
  }

  this.setState({ openMeterModal: !this.state.openMeterModal })
}
  renderChip=(item, type='meterNumbersNotFound', collapse=false)=>{
    return (
    <Group>
      <div style={chipStyle}>
        <span style={{display: 'flex', alignItems: 'center'}}>
          <span className='chipSpanText' style={{whiteSpace: 'nowrap', overflowX: item.length >= 30 && 'scroll', textOverflow: item.length >= 30 && 'ellipsis', width: item.length >= 30 ? '130px' : 'auto'}}>
            {item}
          </span>
        {type === 'meterNumbersNotFound' && <img src={PlusIcon} 
        onClick={()=> 
        this.setState({meter: {number: item}, openMeterModal: true})
        } style={{marginLeft: '8px', cursor: 'pointer', marginRight: collapse === false && item.length >= 14 && '8px'}} width={'10px'} height={'10px'} />}
        </span>
      </div>
    </Group>);
  }
  handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) { 
      this.setState({fetch: this.state.fetch + 10}, ()=> this.getUploadsLog())
    }
  }

  render() {
    const {isMobile, isTablet} = this.props;
    const {tableRowValues, tableColumnNames, data, order, orderBy, page, rowsPerPage, openMeterModal, edit, meter, skeletonLoading, openNotFound, openFound, failedErrorPopover, openMeterLogsDetailMeterReadings, selectedReadingId} = this.state;
    const createSortHandler = (property) => (event) => {
      this.onRequestSort(event, property);
    };
    return (
        <div 
        style={{
          width: '100%', 
          //marginTop: '20px',
          //height: '366px',
          background: 'white',
          boxShadow: '0 1px 3px rgba(0,0,0,.08)', 
          borderRadius: '4px'
          }}
          onScroll={this.handleScroll}>
          <Skeleton visible={skeletonLoading}>
            <div 
            className='tooltips meter-uploads-log'
            style={{
                padding: '12.1px 24px',
                borderBottom: '1px solid #dadae6',
                borderTopLeftRadius: '4px',
                borderTopRightRadius: '4px',
                background: 'white'
            }}>
                <h5 
                style={{
                    fontWeight: '500',
                    fontSize: '16px',
                    lineHeight: '24px',
                    color: '#0e0e1d',
                    marginBottom: '0px'
                    }}>Vergangene Bereitstellungen</h5>
            </div>
            <div style={{
              padding: '12.1px 24px',
              }}>

                {data.length !== 0 ?
                <>
                  <TableContainer style={{ marginTop: '0px'}}>
                  <Table stickyHeader
                  className='fixed' //className='table borderless record-list-table mb-0'
                  style={{
                    border: 'none'
                  }}
                  >
                  <TableBody>
                      <TableRow>
                          {tableColumnNames.map((row, index) => {
                          return <TableCell 
                                  style={{
                                      whiteSpace: 'nowrap',
                                      border: 'none',
                                      paddingBottom: '0px',
                                      fontSize: '12px',
                                      fontWeight: '400',
                                      color:'#727293',
                                      //width: '80px'
                                  }} 
                                  key={index}
                                  >
                                   {row.name}
                                  </TableCell>;
                          })}
                      </TableRow>
                      {data//.sort((a, b) => new Date(a.readingDateTime) - new Date(b.readingDateTime)).reverse()//.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, index) => {
                          return (
                          <React.Fragment>
                          <TableRow className="table-data" style={{background: '#F7F7FA', height: '70px'}} key={index}>
                              <TableCell style={{...tableCellStyle}} width='10%'>
                                  {dayjs.utc(row.readingDateTime).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('DD.MM.YYYY (HH:mm)') }
                              </TableCell>
                              <TableCell style={{
                                whiteSpace: 'nowrap', 
                                overflow: 'hidden', 
                                textOverflow: 'ellipsis', 
                                fontSize: '14px', 
                                fontFamily: 'Inter, sans-serif', 
                                fontWeight: '400',
                                borderTop: '8px solid white'
                              }}
                              width='5%'
                              >
                                {row.hasError ? 
                                <Popover
                                opened={failedErrorPopover === row.id}
                                target={
                                <div 
                                onMouseEnter={()=> this.setState({failedErrorPopover: row.id})}
                                onMouseLeave={()=> this.setState({failedErrorPopover: null})}
                                style={{cursor: 'pointer'}}
                                >
                                  <img style={{marginTop: '-2px'}} src={RedCross}/>
                                  <span 
                                  style={{
                                    marginLeft: '8%',
                                    fontSize: '12px'
                                  }}>Fehler
                                  </span>
                                </div>
                                }
                                withArrow={true}
                                closeOnEscape={true}
                                position='top'
                                >
                                  {row.errorCodes.map((errorCode)=>
                                  <Group>
                                    <span style={{fontWeight: '400'}}>{errorCode === 'INVALID_PASSWORD' ? 'Ungültiges Passwort' : 'NO_PASSWORD' && 'Fehlendes Passwort'}</span>
                                  </Group>)}
                                </Popover>
                                : 
                                <div>
                                  <img style={{marginTop: '-2px'}} src={GreenTick}/>
                                  <span
                                  style={{
                                    marginLeft: '8%',
                                    fontSize: '12px'
                                  }}>Erfolgreich
                                  </span>
                                </div>}
                              </TableCell>
                              <TableCell style={tableCellStyle} width='15%'>
                                {row.meterNumbersFound ?
                                  row.meterNumbersFound.length > 2 ?
                                  <Group  noWrap={true} style={{fontWeight: '400'}} position={'apart'}>
                                    <Group noWrap={true}>{row.meterNumbersFound.map((item, index)=> index <= 1 && this.renderChip(item, 'meterNumbersFound'))}</Group>
                                    <Group noWrap spacing={10}>
                                      <img src={ExpandIconLogs} style={{transform: openFound === index && 'rotate(180deg)', cursor: 'pointer'}} alt='expand' onClick={()=> row.meterNumbersFound && row.meterNumbersFound.length > 2 && (openFound === index ? this.setState({openFound: null}) : this.setState({openFound: index, openNotFound: null}))}/>
                                      <VisibilityIcon
                                      style={{
                                        cursor: 'pointer',
                                        //color: '#5555FF',
                                        fontSize: '16px',
                                        opacity: 0.4
                                      }} 
                                      onClick={()=> row.meterNumbersFound?.length > 0 && this.setState({openMeterLogsDetailMeterReadings: true, selectedReadingId: row.readingId})}
                                      />
                                    </Group>
                                  </Group>
                                  :
                                  <Group noWrap={true} position='apart'>
                                    <Group noWrap>
                                      {row.meterNumbersFound.map((item, index)=> this.renderChip(item, 'meterNumbersFound'))}
                                    </Group>
                                    <VisibilityIcon
                                    style={{
                                      cursor: 'pointer',
                                      // color: '#5555FF',
                                      fontSize: '16px',
                                      opacity: 0.4
                                    }} 
                                    onClick={()=> row.meterNumbersFound?.length > 0 && this.setState({openMeterLogsDetailMeterReadings: true, selectedReadingId: row.readingId})}
                                    />
                                  </Group>
                                  : null}
                              </TableCell>
                              <TableCell style={{...tableCellStyle}} width='15%'>
                                  {row.meterNumbersNotFound ?
                                  row.meterNumbersNotFound.length > 2 ?
                                  <Group noWrap={true} style={{fontWeight: '400'}} position={'apart'}>
                                    <Group noWrap={true}>{row.meterNumbersNotFound.map((item, index)=> index <= 1 && this.renderChip(item, 'meterNumbersNotFound'))}</Group>
                                    <img src={ExpandIconLogs} style={{transform: openNotFound === index && 'rotate(180deg)', cursor: 'pointer'}} alt='expand' onClick={()=> row.meterNumbersNotFound && row.meterNumbersNotFound.length > 2 && (openNotFound === index ? this.setState({openNotFound: null}) : this.setState({openNotFound: index, openFound: null}))}/>
                                  </Group>
                                  :
                                  <Group noWrap={true}>{row.meterNumbersNotFound.map((item, index)=> this.renderChip(item, 'meterNumbersNotFound'))}</Group>
                                  : null}
                                
                              </TableCell>
                              <TableCell style={{...tableCellStyle}} width='10%'>
                                <button onClick={()=> this.setState({deleteReadingId: row.readingId}, ()=> this.toggleDeleteModal())} type="button" class="close delete-cost-position" aria-label="Close"><span aria-hidden="true">×</span></button>
                              </TableCell>
                          </TableRow>
                          <TableRow style={{background: '#F7F7FA', margin: 0, borderCollapse: 'collapse', borderSpacing: '0px'}}>
                            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}/>
                            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}/>
                            <TableCell style={{ paddingBottom: openFound !== index && 0, paddingTop: 0 }}>
                              <Collapse in={openFound === index} timeout="auto" unmountOnExit>
                              {row.meterNumbersFound &&
                                    <Group>{row.meterNumbersFound.map((item, index)=> index > 1 && this.renderChip(item, 'meterNumbersFound', true))}</Group>}
                              </Collapse>
                            </TableCell>
                            <TableCell style={{ paddingBottom: openNotFound !== index && 0, paddingTop: 0 }}>
                              <Collapse in={openNotFound === index} timeout="auto" unmountOnExit>
                                  {row.meterNumbersNotFound &&
                                    <Group>{row.meterNumbersNotFound.map((item, index)=> index > 1 && this.renderChip(item, 'meterNumbersNotFound', true))}</Group>}
                              </Collapse>
                            </TableCell>
                            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}/>
                          </TableRow>
                          </React.Fragment>
                          );
                      })}
                      </TableBody>
                  </Table>
                  </TableContainer>
                  {/* <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={data.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  //labelRowsPerPage={''}
                  /> */}
                </>
                  :
                  <Group position='center' style={{background: 'rgb(247, 247, 250)'}}>
                    <EmptyStateContainer
                      heading="Es wurden noch keine Datenbereitstellungen gefunden."
                      subheading={"Laden Sie diese Seite neu, um zu sehen, ob in der Zwischenzeit Daten bereitgestellt wurden."}
                      icon={MeterIcon}
                      showBtn={false}
                      styleMainContainer={{margin: 50}}
                    />
                  </Group>
                }
            
            </div>
            {openMeterModal &&
            <MeterModal
              history={this.props.history}
              meter={meter}
              className="add-meter-modal"
              open={openMeterModal}
              edit={edit}
              meterUploads={true}
              addNewMeter={(newMeterData) => this._addNewMeter(newMeterData)}
              toggle={() => this.setState({ openMeterModal: !this.state.openMeterModal, edit: false })}
              propertyId={this.props.propertySelected.id}
              //rentalUnitName={this.state.unitName}
              //rentalUnitIndex={this.state.unitIndex}
            />}
              <DeleteModal
              parent={this.props.parent}
              open={this.state.openDelete}
              toggle={() => this.toggleDeleteModal()}
              handleDelete={() => this.deleteReadings(this.state.deleteReadingId)}
              modalId="ct-delete-modal-footer"
              showtext={false}
              typeText={'Sind Sie sicher, dass Sie diese Ablesung löschen wollen? Alle Ablesedaten gehen unwideruflich verloren.'}
              showDeleteText={false}
              textShowModal={'Ablesung löschen'}
              />

              {openMeterLogsDetailMeterReadings &&
              <MeterLogsDetailMeterReadingsModal
              open={openMeterLogsDetailMeterReadings}
              toggle={()=> this.setState({openMeterLogsDetailMeterReadings: false, selectedReadingId: null})}
              readingId={selectedReadingId}
              />}
            </Skeleton>
        </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    propertySelected: state.generalReducer.property,
  };
};
export default connect(mapStateToProps)(MeterUploadsLog);
