import React, { Component } from 'react';
import CustomModal from './index';
import {
  ColorButton,
  ValueText,
  SwitchContainer,
  DropFile,
  AddCostTypeModal,
  SelectDropdown
} from '../../../../index';
import { Row, Col, Form, Tooltip, OverlayTrigger } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import Help from '../../../../../assets/images/module/general/Help.svg';
import calendar from '../../../../../assets/images/module/general/CalendarIcon.svg';
import { connect } from 'react-redux';
import RangeDatePicker from './DateRangePicker';
import Autoload from '../../../../Autoload';
import moment from 'moment';
import GlobalServices from '../../../../helpers/services/GlobalServices';
import GeneralDataHandler from '../../../../helpers/DataHandler/General';

import { showNotification, hideNotification } from '@mantine/notifications';
import RedTick from '../../../../../assets/images/module/general/RedTick.svg';
import GreenTick from '../../../../../assets/images/module/general/GreenTick.svg';
import AddIcon from '../../../../../assets/images/module/general/AddIcon.svg';
import Logo from '../../../../../assets/images/module/general/Logo.svg';
import Messenger from '../../../../../assets/images/module/general/Messenger.svg';

// Get receipts endpoint from new config
import { config } from '../../../../config/Setting';
import { Check, ThreeDRotationSharp } from "@material-ui/icons";
import { Alert, Group, Stack, Text } from "@mantine/core";
import * as customParseFormat from 'dayjs/plugin/customParseFormat'
import dayjs from 'dayjs';
import CopyIcon from '../../../SVGs/copyIcon';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
var { checkProperty, yearDifference, yearDifferenceMoment } = Autoload.MainHelper;
dayjs.extend(customParseFormat)

const MOBILE_SIZE = 992;
const TABLET_SIZE = 1200;

class MessageTemplatesModal extends Component {
  state = {
    data: [{
        name: 'Template 1',
        description: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget.'
    }, {
        name: 'Template 2',
        description: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget.'
    }, {
        name: 'Template 3',
        description: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget.'
    }, {
        name: 'Template 4',
        description: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget.'
    }, {
        name: 'Template 5',
        description: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget.'
    }, {
        name: 'Template 6',
        description: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget.'
    }, {
        name: 'Template 7',
        description: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget.'
    }, {
        name: 'Template 8',
        description: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget.'
    }, {
        name: 'Template 9',
        description: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget.'
    }, {
        name: 'Template 10',
        description: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget.'
    }]
  };
  
  constructor(props) {
    super(props);
  }

  componentDidMount(){
    
  }
  
  render() {
    const { open, toggle, className, openNewMessage} = this.props;
    const { data } = this.state;
    return (
      <>
        <CustomModal
          heading={'Vorlage wählen'}
          headingClassName='select-template-heading'
          open={open}
          toggle={() => {
            toggle();
          }}
          className={`${className}`}
        >
            <Group noWrap style={{alignItems: 'flex-start'}} spacing={24}>
                {/* <Stack className='template-modal-categories-container'>
                    <Group className='template-modal-categories'>Kategorien</Group>
                    <Group className='template-modal-sub-categories' style={{background: '#e6e6ff'}}>Alle</Group>
                    <Group className='template-modal-sub-categories'>Nachrichten</Group>
                    <Group className='template-modal-sub-categories'>Hinweis</Group>
                    <Group className='template-modal-sub-categories'>Mietvertrag</Group>
                    <Group className='template-modal-sub-categories'>Heizungsrechnung</Group>
                    <Group className='template-modal-categories'>Entwurfsvorlagen</Group>
                    <Group className='template-modal-sub-categories' style={{}}>Alle</Group>
                    <Group className='template-modal-sub-categories'>Nachrichten</Group>
                    <Group className='template-modal-sub-categories'>Hinweis</Group>
                    <Group className='template-modal-sub-categories'>Mietvertrag</Group>
                    <Group className='template-modal-sub-categories'>Heizungsrechnung</Group>
                </Stack> */}
                <Stack justify={'flex-end'} style={{//borderLeft: '1px solid #DADAE6', 
                width: '100%'}}>
                    <Stack className='template-modal-main-container'>
                        <Stack>
                            <Group><span style={{fontSize: '14px', color: '#13135C', fontWeight: '500'}}>Neue Nachricht erstellen</span></Group>
                            <Group>
                                <Stack align={'center'} spacing={5} onClick={()=> openNewMessage()}>
                                    <Group className='blank-template-icon'>
                                        <img src={AddIcon}/>
                                    </Group>
                                    <Group className='template-title'>
                                        Neu
                                    </Group>
                                </Stack>
                            </Group>
                        </Stack>
                        <Stack spacing={0}>
                            <div className='divider'/>
                            <Stack style={{marginBottom: '30px'}}>
                                <Group><span style={{fontSize: '14px', color: '#13135C', fontWeight: '500'}}>Oder Vorlage wählen</span></Group>
                                <Group>
                                    {data.map((item)=><Stack spacing={0} className='template-icon' onClick={()=> openNewMessage()}>
                                        <Group className='template-icon-img' align={'center'} grow><img src={Logo}/></Group>
                                        <Stack style={{padding: '10px', height: '35%'}} spacing={5} justify='center'>
                                            {/* <Group spacing={5}><img src={Messenger} /><span className='template-icon-sub-text'>Nachrichten</span></Group> */}
                                            <Group className='template-icon-text'><span>{item.name}</span></Group>
                                            <Group><span className='template-icon-sub-text'>{item.description}</span></Group>
                                        </Stack>
                                    </Stack>)}
                                </Group>
                            </Stack>
                        </Stack>
                        
                    </Stack>
                    
                    {/* <Group style={{justifyContent: 'space-between'}}>
                        <div>
                            <ColorButton
                                text={'Abbrechen'}
                                className='gray-btn'
                                onClick={()=> toggle()}
                            />
                        </div>
                        <div>
                            <ColorButton
                                text={'Weiter'}
                                className='purple-btn'
                                onClick={()=> openNewMessage()}
                            />
                        </div>
                    </Group> */}
                </Stack>
            </Group>
        </CustomModal>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    propertySelected: state.generalReducer.property,
  };
};

export default connect(mapStateToProps)(MessageTemplatesModal);
