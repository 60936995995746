import { Skeleton } from '@mantine/core';
import React from 'react';
import { Card, CardBody, CardTitle } from 'reactstrap';

export default function CustomCard({ children, heading = 'No heading', topRightItem, className, onClick, style, skeletonLoading= false }) {
  return (
      <Card style={style}  className={`${className}`}>
        <Skeleton visible={skeletonLoading} style={{height: '100%'}}>
        <CardBody>
          <div className="d-flex flex-row align-items-center top-section">
            <div style={{ flex: 1 }} onClick={onClick}>
              <CardTitle className="card-heading">{heading}</CardTitle>
            </div>
            {topRightItem}
          </div>
          {children}
        </CardBody>
        </Skeleton>
      </Card>
  );
}
