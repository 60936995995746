import React, { Component } from 'react';
import CustomModal from './index';
import { connect } from 'react-redux';
import Autoload from '../../../../Autoload';
import * as customParseFormat from 'dayjs/plugin/customParseFormat'
import dayjs from 'dayjs';
import { Accordion, Group, Loader, Stack } from '@mantine/core';
import DropFile from '../DropFile/DropFile';
import { Form } from 'react-bootstrap';
import SelectDropdown from '../SelectDropdown/SelectDropdown';
import ColorButton from '../Buttons/ColorButton';
import { Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import FolderIcon from '@material-ui/icons/Folder';
import HomeIcon from '@material-ui/icons/Home';
import FolderSharedIcon from '@material-ui/icons/FolderShared';
import { showNotification } from '@mantine/notifications';
import GreenTick from '../../../../../assets/images/module/general/GreenTick.svg';
import AddUpdateDuplicateDocumentModal from './AddUpdateDuplicateDocumentModal';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
var { checkProperty, yearDifference, yearDifferenceMoment, loader } = Autoload.MainHelper;
dayjs.extend(customParseFormat)

const MOBILE_SIZE = 992;
const TABLET_SIZE = 1200;

class MoveCopyModalForDocuments extends Component {
  state = {
    isMobile: window.innerWidth <= MOBILE_SIZE,
    isTablet: window.innerWidth <= TABLET_SIZE,
    selectedFolder: false,
    disableMoveCopy: false,
    data: [],
    folderTreeArray: [],
    openDuplicateFilesModal: false,
    tenantDocumentsData: [],
    generalDocumentsData: [],
    selectedTenantForReferenceObjectId: null
  };

  componentDidMount(){
    const {data} = this.props;
    let updatedData = [];
    data.map((dt)=> {
      if(dt.parentDirectoryId !== null){
        if(updatedData.find((udt)=> udt.id === dt.id) !== undefined){
          let newDt = updatedData.find((udt)=> udt.id === dt.id)
          newDt = {...newDt, directory: newDt.directory ? [...newDt.directory, dt] : [dt]}
        }else{
          let newDt = data.find((dt1)=> dt1.id === dt.parentDirectoryId);
          newDt = newDt && {...newDt, directory: newDt.directory ? [...newDt.directory, dt] : [dt]} 
          newDt && updatedData.push(newDt);
        }
      }
      // else if(updatedData.find((udt)=> udt.id === dt.id) === undefined){
      //   updatedData.push({...dt, directory: []});
      // } 
    });
    console.log('updatedData: ', updatedData);
    this.getTenantDocumentsByAccountId();
    this.setState({selectedFolder: this.props.currentFolderId})
  }

  getDocumentsByAccountId=()=>{
    const {propertySelected, showTenantDocuments=false} = this.props;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.document_api_base_url + 'documents/account?accountId=' + propertySelected.accountId+'&fileTypes=General',//+'&offset='+ offset +'&fetch='+ fetch,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleGetDocumentsByAccountId',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      },
      true
    );
  }

  handleGetDocumentsByAccountId(response){
    if(response.status && response.statuscode === 200){
      this.setState({generalDocumentsData: response.result});
    }
    loader(false);
  }

  getTenantDocumentsByAccountId=()=>{
    const {propertySelected, showTenantDocuments=false} = this.props;
    loader(true);
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.document_api_base_url + 'documents/account?accountId=' + propertySelected.accountId +'&fileTypes=TenantDocument',//+'&offset='+ offset +'&fetch='+ fetch,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleGetTenantDocumentsByAccountId',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      },
      true
    );
  }

  handleGetTenantDocumentsByAccountId(response){
    if(response.status && response.statuscode === 200){
      this.setState({tenantDocumentsData: response.result});
    }

    if(this.props.showTenantDocuments){
      this.getDocumentsByAccountId();
    }else{
      loader(false);
    }
  }

  renderFolderData=(data, first=false, folderId=null, tenantId=null)=>{
    const {checkedItems, hideShareFolder} = this.props;
    const {selectedFolder, folderTreeArray} = this.state;
    const selectedFolderFiles = data.filter((dt)=> dt.isDirectory && (first ? dt.parentDirectoryId === null : dt.parentDirectoryId === folderId));
      return(
        <Accordion
        classNames={{
          control: 'move-copy-documents-modal-accordion-control',
          content: 'move-copy-documents-modal-accordion-content',
          icon: 'move-copy-documents-modal-accordion-icon',
          contentInner: 'move-copy-documents-modal-accordion-contentInner'
        }}>
          {selectedFolderFiles.map((dt)=> checkedItems.find((ci)=> ci === dt.id) === undefined &&
            <Accordion.Item
            opened={dt.parentDirectoryId === data?.find((dt1)=> dt1.id === selectedFolder)?.parentDirectoryId}
            key={dt.id}
            label={<Group 
              style={{cursor: 'pointer', backgroundColor: selectedFolder === dt.id ? '#E3E3FC' : 'white', //color: selectedFolder === dt.id && 'white', 
              padding: '10px', borderRadius: '4px'}} 
              onClick={()=> {
                let {selectedFolder, folderTreeArray} = this.state;
                selectedFolder = dt.id;//selectedFolder ? (selectedFolder === dt.id ? null : dt.id) : dt.id;
                folderTreeArray.find((ft)=> ft === dt.id) === undefined && folderTreeArray.push(dt.id)
                this.setState({selectedFolder, folderTreeArray, disableMoveCopy: false, selectedTenantForReferenceObjectId: tenantId});
              }}>
                <FolderIcon 
                style={{
                  color: '#5655FE',
                  fontSize: '20px'
                }}/>
                <span style={{fontWeight: '500', fontSize: '14px', fontFamily: 'Inter, sans-sarif'}}>{dt.name}</span>
              </Group>}
            >
              {//data.filter((dt1)=> dt1.isDirectory && dt1.parentDirectoryId === dt.id).length > 0 && 
              folderTreeArray.find((ft)=> ft === dt.id) !== undefined && this.renderFolderData(data, false, dt.id, tenantId)}              
              {/* <Group style={{width: '100%'}}>
                {this.renderFolderData(dt)
                  //<Loader size={'xs'}/>
                }
              </Group> */}
            </Accordion.Item>  
          )}
          {!hideShareFolder && first && this.renderShareFolder()}
          {first && this.renderTenantsFolder()}
        </Accordion>
      )
  }

  renderShareFolder=(data)=>{
    const {selectedFolder} = this.state;
    return (

          <Accordion.Item
          label={
            <Group 
            style={{cursor: 'pointer', backgroundColor: selectedFolder === 'sharedFolder' ? '#E3E3FC' : 'white', padding: '10px', borderRadius: '4px'}} 
            onClick={()=> {
              let {selectedFolder} = this.state;
              selectedFolder = 'sharedFolder';
              this.setState({selectedFolder, selectedTenantForReferenceObjectId: null});
          }}>
            <FolderSharedIcon 
            style={{
              color: '#5655FE',
              fontSize: '20px'
            }}/>
            <span style={{fontWeight: '500', fontSize: '14px', fontFamily: 'Inter, sans-sarif'}}>{'Gemeinsame Dokumente'}</span>
          </Group>
          }
          >
            {/* {this.renderFolderData(data.filter((dt)=> dt.propertyId === null), true)} */}
          </Accordion.Item>
    );
  }

  renderRentalUnitsFolders=(data)=>{
    const {selectedFolder} = this.state;
    const {rentalUnits} = this.props;
    return (

          rentalUnits.map((rentalUnit, index)=>
        <Accordion
        classNames={{
          control: 'move-copy-documents-modal-accordion-control',
          content: 'move-copy-documents-modal-accordion-content',
          icon: 'move-copy-documents-modal-accordion-icon',
          contentInner: 'move-copy-documents-modal-accordion-contentInner'
        }}>
          <Accordion.Item
          key={rentalUnit.id}
          label={
            <Group 
            style={{cursor: 'pointer', backgroundColor: selectedFolder === rentalUnit.id ? '#E3E3FC' : 'white', padding: '10px', borderRadius: '4px'}} 
            onClick={()=> {
              let {selectedFolder} = this.state;
              selectedFolder = rentalUnit.id;
              this.setState({selectedFolder, disableMoveCopy: true, selectedTenantForReferenceObjectId: null});
          }}>
            <FolderIcon 
            style={{
              color: '#5655FE',
              fontSize: '20px'
            }}/>
            <span style={{fontWeight: '500', fontSize: '14px', fontFamily: 'Inter, sans-sarif'}}>{rentalUnit.name}</span>
          </Group>
          }
          >
            {/* {this.renderFolderData(data.filter((dt)=> dt.propertyId === null), true)} */}
            <Accordion
            classNames={{
              control: 'move-copy-documents-modal-accordion-control',
              content: 'move-copy-documents-modal-accordion-content',
              icon: 'move-copy-documents-modal-accordion-icon',
              contentInner: 'move-copy-documents-modal-accordion-contentInner'
            }}>
              {rentalUnit.tenants && this.renderRentalUnitTenantsFolders(rentalUnit.tenants)}
            </Accordion>
            
          </Accordion.Item>
        </Accordion>)
    );
  }

  renderRentalUnitTenantsFolders=(data)=>{
    console.log('renderRentalUnitTenantsFolders: ', data);
    const {selectedFolder, tenantDocumentsData} = this.state;
    return (

          data.map((tenant, index)=>
          tenant.vacant === false &&
          <Accordion.Item
          key={tenant.id}
          label={
            <Group 
            style={{cursor: 'pointer', backgroundColor: selectedFolder === tenant.id ? '#E3E3FC' : 'white', padding: '10px', borderRadius: '4px'}} 
            onClick={()=> {
              let {selectedFolder} = this.state;
              selectedFolder = tenant.id;
              this.setState({selectedFolder, disableMoveCopy: false, selectedTenantForReferenceObjectId: tenant.id});
          }}>
            <FolderIcon 
            style={{
              color: '#5655FE',
              fontSize: '20px'
            }}/>
            <span style={{fontWeight: '500', fontSize: '14px', fontFamily: 'Inter, sans-sarif'}}>{tenant.fullName}</span>
          </Group>
          }
          >
            {this.renderFolderData(tenantDocumentsData.filter((dt)=> dt.referencedObjectId === tenant.id && dt.isDirectory), false, null, tenant.id)}
          </Accordion.Item>)
    );
  }

  renderTenantsFolder=()=>{
    const {selectedFolder} = this.state;
    return (

          <Accordion.Item
          label={
            <Group 
            style={{cursor: 'pointer', backgroundColor: selectedFolder === 'tenansDocuments' ? '#E3E3FC' : 'white', padding: '10px', borderRadius: '4px'}} 
            onClick={()=> {
              let {selectedFolder} = this.state;
              selectedFolder = 'tenansDocuments';
              this.setState({selectedFolder, disableMoveCopy: true, selectedTenantForReferenceObjectId: null});
          }}>
            <FolderIcon 
            style={{
              color: '#5655FE',
              fontSize: '20px'
            }}/>
            <span style={{fontWeight: '500', fontSize: '14px', fontFamily: 'Inter, sans-sarif'}}>{'Mieterdokumente'}</span>
          </Group>
          }
          >
            {/* {this.renderFolderData(data.filter((dt)=> dt.propertyId === null), true)} */}
            {this.renderRentalUnitsFolders()}
          </Accordion.Item>
    );
  }

  copyTenantFilesToFolder=(filesNotDuplicate=null)=>{
    console.log('copyTenantFilesToFolder');
    const {selectedFolder, selectedTenantForReferenceObjectId} = this.state;
    const {checkedItems, data, propertySelected, hideShareFolder, te} = this.props;
    let payload = {
      directoryId: selectedFolder === 'sharedFolder' ? null : selectedFolder === selectedTenantForReferenceObjectId ? null : selectedFolder,
      documentIds: filesNotDuplicate ? filesNotDuplicate.map((file)=> file.id) : checkedItems,
      propertyId: selectedFolder === 'sharedFolder' ? null : propertySelected.id,
      fileType: selectedTenantForReferenceObjectId ? 500 : 0,
      referencedObjectId: selectedTenantForReferenceObjectId
    }
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.document_api_base_url + 'documents/update/copy',
      'PUT',
      payload,
      null,
      false,
      true,
      false,
      this,
      'handleMoveFilesToFolder',
      null,
      false,
      '',
      {
        //'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      },
      true
    )
  }

  copyFilesToFolder=(filesNotDuplicate=null)=>{
    console.log('copyFilesToFolder');
    const {selectedFolder, selectedTenantForReferenceObjectId, generalDocumentsData} = this.state;
    const {checkedItems, data, propertySelected, hideShareFolder} = this.props;
    let payload = {
      directoryId: selectedFolder === 'sharedFolder' ? null : selectedFolder,
      documentIds: filesNotDuplicate ? filesNotDuplicate.map((file)=> file.id) : checkedItems,
      propertyId: selectedFolder === 'sharedFolder' ? null : propertySelected.id
    }
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.document_api_base_url + 'documents/copy',
      'PUT',
      payload,
      null,
      false,
      true,
      false,
      this,
      //selectedTenantForReferenceObjectId ? 'handleCopyForTenantDocuments' : 'handleMoveFilesToFolder',
      'handleMoveFilesToFolder',
      //{prevDocumentsData: generalDocumentsData},
      null,
      false,
      '',
      {
        //'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      },
      true
    )
  }

  handleCopyForTenantDocuments(response){

    if(response.status && response.statuscode === 200){
      // fetch({
      //   url: Autoload.Setting.document_api_base_url + 'documents/account?accountId=' + propertySelected.accountId+'&fileTypes=General'
      // })
    }
  }

  moveTenantFilesToFolder=(filesNotDuplicate=null)=>{
    console.log('moveTenantFilesToFolder');
    const {selectedFolder, selectedTenantForReferenceObjectId} = this.state;
    const {checkedItems, data, propertySelected, hideShareFolder, te} = this.props;
    let payload = {
      directoryId: selectedFolder === 'sharedFolder' ? null : selectedFolder === selectedTenantForReferenceObjectId ? null : selectedFolder,
      documentIds: filesNotDuplicate ? filesNotDuplicate.map((file)=> file.id) : checkedItems,
      propertyId: selectedFolder === 'sharedFolder' ? null : propertySelected.id,
      fileType: selectedTenantForReferenceObjectId ? 500 : 0,
      referencedObjectId: selectedTenantForReferenceObjectId
    }
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.document_api_base_url + 'documents/update/move',
      'PUT',
      payload,
      null,
      false,
      true,
      false,
      this,
      'handleMoveFilesToFolder',
      null,
      false,
      '',
      {
        //'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      },
      true
    )
  }
  
  moveFilesToFolder=(filesNotDuplicate=null)=>{
    const {selectedFolder} = this.state;
    const {checkedItems, data, propertySelected} = this.props;
    let payload = {
      directoryId: selectedFolder === 'sharedFolder' ? null : selectedFolder,
      documentIds: filesNotDuplicate ? filesNotDuplicate.map((file)=> file.id) : checkedItems,
      propertyId: selectedFolder === 'sharedFolder' ? null : propertySelected.id
    }
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.document_api_base_url + 'documents/move',
      'PUT',
      payload,
      null,
      false,
      true,
      false,
      this,
      'handleMoveFilesToFolder',
      null,
      false,
      '',
      {
        //'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      },
      true
    )  
  }

  handleMoveFilesToFolder(response){
    if(response.status && response.statuscode === 200){
      showNotification({
        message: `Erfolgreich ${this.props.type === 'move' ? 'verschoben' : 'kopiert'}`,
        icon: <img src={GreenTick} alt='green-tick'/>,
      });
      this.setState({copyGeneralToTenantFilesToFolder: null});
      this.props.getDocuments();
      this.props.toggle();
    }
  }

  checkForDuplicates=()=>{
    const {checkedItems, data, type, currentFolderId, hideShareFolder, showTenantDocuments} = this.props;
    const {selectedFolder, selectedTenantForReferenceObjectId, generalDocumentsData, tenantDocumentsData} = this.state;
    const allDocumentsInCurrentFolder = selectedTenantForReferenceObjectId ? tenantDocumentsData.filter((dt)=> dt.parentDirectoryId === selectedFolder) : generalDocumentsData.filter((dt)=> dt.parentDirectoryId === selectedFolder);;//data.filter((dt)=> dt.parentDirectoryId === selectedFolder);
    console.log('allDocumentsInCurrentFolder: ', allDocumentsInCurrentFolder, currentFolderId, selectedFolder);
    const selectedItems = data.filter((dt)=> checkedItems.find((checkedItem)=> checkedItem === dt.id) !== undefined);
    var duplicateFiles= [];
    var filesNotDuplicate= []
    allDocumentsInCurrentFolder.map((document)=> selectedItems.find((file)=> !hideShareFolder ? (file.name === document.name && document.isDeleted === false) : (file.name === document.name && document.isDeleted === false && document.propertyId !== null)) !== undefined && duplicateFiles.push({...document, file: selectedItems.find((file)=> !hideShareFolder ? (file.name === document.name && document.isDeleted === false) : (file.name === document.name && document.isDeleted === false && document.propertyId !== null))}));
    console.log('checkForDuplicates: ', selectedItems, allDocumentsInCurrentFolder);
    if(duplicateFiles.length > 0){
      filesNotDuplicate = selectedItems.filter((file)=> duplicateFiles.find((duplicateFile)=> duplicateFile.name === file.name) === undefined);
      //open duplicate files modal
      this.setState({filesNotDuplicate, duplicateFiles, openDuplicateFilesModal: true});
    }else{
      type === 'move' ? 
      // !hideShareFolder ? 
      //   this.moveToRootFolder() 
      //   : 
      //   (selectedFolder === 'sharedFolder' ? this.moveToshareDocuments() : 
      showTenantDocuments ? this.moveTenantFilesToFolder() : selectedTenantForReferenceObjectId ? this.moveTenantFilesToFolder() : this.moveFilesToFolder()//) 
      : 
      showTenantDocuments ? this.copyTenantFilesToFolder() : selectedTenantForReferenceObjectId ? this.copyTenantFilesToFolder() : this.copyFilesToFolder()
    }
  }

  moveToshareDocuments=()=>{
    const {checkedItems, data, hideShareFolder} = this.props;
    loader(true);

    if(checkedItems.length > 0){
      checkedItems.map((checkedItem, index)=> {
        const formData = new FormData();
        const item = data.find((dt)=> dt.id === checkedItem);
        formData.append('Name', item.name); 
        item.referencedObjectId && formData.append('referencedObjectId', item.referencedObjectId);
        formData.append('isReadOnly', item.isReadOnly);        
        formData.append('isHiddenForTenantPortal', item.isHiddenForTenantPortal);
        formData.append('isShared', true);
        
        Autoload.HttpRequestAxios.HttpRequest(
        Autoload.Setting.document_api_base_url + 'documents/'+ checkedItem,
        'PUT',
        formData,
        null,
        false,
        true,
        false,
        this,
        'handleShareDocument',
        {eol: (checkedItems.length - 1) === index},
        false,
        '',
        {
          //'Content-Type': 'application/json',
          Authorization: Autoload.Utils.getAuthToken(),
        },
        true
        );
      });
    }
  }

  handleShareDocument(response){
    if(response.status && response.statuscode === 200){
      if(response.other.eol){
        loader(false);
        this.props.getDocuments();
        this.props.toggle();
      }
    }else{
      loader(false); 
    }
  }

  moveToRootFolder=()=>{
    const {checkedItems, data, hideShareFolder, propertySelected} = this.props;
    loader(true);

    if(checkedItems.length > 0){
      checkedItems.map((checkedItem, index)=> {
        const formData = new FormData();
        const item = data.find((dt)=> dt.id === checkedItem);
        formData.append('Name', item.name); 
        item.referencedObjectId && formData.append('referencedObjectId', item.referencedObjectId);
        formData.append('isReadOnly', item.isReadOnly);        
        formData.append('isHiddenForTenantPortal', item.isHiddenForTenantPortal);
        formData.append('isShared', true);
        formData.append('propertyId', propertySelected.id);
        
        Autoload.HttpRequestAxios.HttpRequest(
        Autoload.Setting.document_api_base_url + 'documents/'+ checkedItem,
        'PUT',
        formData,
        null,
        false,
        true,
        false,
        this,
        'handleShareDocument',
        {eol: (checkedItems.length - 1) === index},
        false,
        '',
        {
          //'Content-Type': 'application/json',
          Authorization: Autoload.Utils.getAuthToken(),
        },
        true
        );
      });
    }
  }

  render() {
    const { open, toggle, className, type, data, checkedItems, showTenantDocuments } = this.props;
    const {selectedFolder, folderTreeArray, openDuplicateFilesModal, filesNotDuplicate, duplicateFiles, disableMoveCopy, generalDocumentsData, selectedTenantForReferenceObjectId} = this.state;
    console.log('selectedFolder: ', selectedFolder);
    console.log('folderTreeArray: ', folderTreeArray);
    console.log('selectedTenantForReferenceObjectId: ', selectedTenantForReferenceObjectId);
    console.log('showTenantDocuments: ', showTenantDocuments);
    return (
      <>
        <CustomModal
          heading={`Dokumente oder Ordner ${type === 'move' ? 'verschieben' : 'kopieren'}`}
          headingClassName='create-folder-modal-heading'
          open={open}
          toggle={() => {
            toggle();
          }}
          className={`${className}`}
        >
          <Stack style={{width: '100%'}}>
          <Group className='override-documents-modal-text'>Bitte wählen Sie den Zielordner:</Group>
            <Accordion 
            initialItem={0}
            classNames={{
              control: 'move-copy-documents-modal-accordion-control',
              content: 'move-copy-documents-modal-accordion-content',
              icon: 'move-copy-documents-modal-accordion-icon',
              contentInner: 'move-copy-documents-modal-accordion-contentInner'
            }}>
              <Accordion.Item
              label={
                <Group 
                style={{cursor: 'pointer', backgroundColor: selectedFolder === null ? '#E3E3FC' : 'white', padding: '10px', borderRadius: '4px'}} 
                onClick={()=> {
                  let {selectedFolder} = this.state;
                  selectedFolder = null;//selectedFolder ? (selectedFolder === 'root' ? null : 'root') : 'root';
                  this.setState({selectedFolder, disableMoveCopy: false});
              }}>
                <HomeIcon 
                style={{
                  color: '#5655FE',
                  fontSize: '20px'
                }}/>
                <span style={{fontWeight: '500', fontSize: '14px', fontFamily: 'Inter, sans-sarif'}}>{this.props.propertySelected.propertyName}</span>
              </Group>
              }
              >
                {this.renderFolderData(showTenantDocuments ? generalDocumentsData.filter((dt)=> dt.propertyId !== null && dt.propertyId === this.props.propertySelected.id) : data.filter((dt)=> dt.propertyId !== null && dt.propertyId === this.props.propertySelected.id), true)}
                {/* {this.renderShareFolder(data.filter((dt)=> dt.propertyId === null), true)} */}
              </Accordion.Item>
            </Accordion>
            <Group position='apart'>
              <div>
                  <ColorButton
                  onClick={()=> toggle()}
                  className="gray-btn"
                  text={'Abbrechen'}
                  />
              </div>
              <div>
                  <ColorButton
                  onClick={()=> this.checkForDuplicates()}
                  className="purple-btn"
                  text={type === 'move' ? 'Verschieben' : 'Kopieren'}
                  disabled={selectedFolder === false || disableMoveCopy}
                  />
              </div>
              <button style={{display: 'none'}} id='upload'/>
            </Group>
          </Stack>
        </CustomModal>
        {openDuplicateFilesModal &&
        <AddUpdateDuplicateDocumentModal
        duplicateFiles={duplicateFiles}
        files={filesNotDuplicate}
        open={openDuplicateFilesModal}
        getDocuments={()=> this.props.getDocuments()}
        toggle={()=> this.setState({openDuplicateFilesModal: false}, ()=> toggle())}
        uploadFiles={()=> type === 'move' ? 
        //(selectedFolder === 'sharedFolder' ? this.moveToshareDocuments() : 
        showTenantDocuments ? this.moveTenantFilesToFolder(filesNotDuplicate) : selectedTenantForReferenceObjectId ? this.moveTenantFilesToFolder(filesNotDuplicate) : this.moveFilesToFolder(filesNotDuplicate)//) 
        : 
        showTenantDocuments ? this.copyTenantFilesToFolder(filesNotDuplicate) : selectedTenantForReferenceObjectId ? this.copyTenantFilesToFolder(filesNotDuplicate) : this.copyFilesToFolder(filesNotDuplicate)}
        allDocumentsInCurrentFolder={data.filter((dt)=> dt.parentDirectoryId === selectedFolder)}
        currentFolderId={selectedFolder}
        moveCopyDocuments={true}
        />}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    propertySelected: state.generalReducer.property,
  };
};

export default connect(mapStateToProps)(MoveCopyModalForDocuments);
