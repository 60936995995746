import React from 'react';
import { ColorButton } from '../../../../index';
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import Autoload from '../../../../Autoload';
import loading from '../../../../../assets/images/module/general/loading.svg';
var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default].general;
const { loader } = Autoload.MainHelper;
export default function SignupLayout({ children, handleNext, handleBack, steps, VerificationStatus }) {
  let nextBtnLable = '';
  switch (steps) {
    case 1:
      nextBtnLable = lang.text_next_personal_details;
      break;
    case 2:
      nextBtnLable = lang.text_next_subscription;
      break;
    case 3:
      nextBtnLable = lang.text_next_payment;
      break;
    case 4:
      nextBtnLable = lang.text_next_summary;
      break;
    case 5:
      nextBtnLable = lang.text_next_summary;
      break;
    case 6:
      nextBtnLable = lang.text_confirm_signup;
      break;
    default:
      break;
  }
  const backtoSignup = () => {
    console.log('sign up go back');
    // debugger;
    window.location.href = '/signup';
  };

  console.log('steps', nextBtnLable, steps);
  console.log('varification status', VerificationStatus);
  return (
    <div className={`signup-page-wrapper signup-step-3 step-${steps}`}>
      <Form>
        <div className="center-content signup-page ">
          <div className="logo-section">
            <img src={Setting.logo} alt="logo" />
          </div>
          <div className="main-content-section">{children}</div>
        </div>
        <div className="footer signup-footer">
          <div className="bottom-text d-none d-md-block">
            <p>
              {lang.text_have_account} <Link to="/login">{`${lang.text_login}!`}</Link>
            </p>
          </div>
          <div className="bottom-btn-wrapper">
            <div>
              <ColorButton
                // disabled={
                //   //   // (steps === 4 ? true : false) ||
                //   // VerificationStatus === 'AUTHORIZED' && steps === 4 && true
                //   // VerificationStatus === 'AUTHORIZED' && steps === 4 && true
                // }
                // disabled={true}
                onClick={(e) => {
                  e.preventDefault();
                  handleBack();
                  VerificationStatus === 'AUTHORIZED' && steps === 4 && backtoSignup();
                }}
                text={lang.text_go_back}
                className="gray-btn"
              />
            </div>
            <div>
              <ColorButton
                // disabled={
                //   (VerificationStatus === 'failed' && steps === 4 && true) ||
                //   (VerificationStatus === 'success' && steps === 4 && false) ||
                //   (VerificationStatus === '' && steps === 4 && false)
                // }
                disabled={
                  VerificationStatus === 'SERVER_ERROR'
                    ? true
                    : false || VerificationStatus === 'PAYMENT_ERROR' || VerificationStatus === ''
                    ? true
                    : steps === 4 && VerificationStatus !== 'AUTHORIZED'
                    ? true
                    : false
                }
                // disabled={
                //   (VerificationStatus !== 'PAYMENT_ERROR' ? true : false)
                //   // || (steps === 4 && false)
                // }
                onClick={(e) => {
                  e.preventDefault();
                  VerificationStatus === 'AUTHORIZED' ? handleNext(6) : handleNext(steps);
                  // loader(true);
                }}
                text={nextBtnLable}
                className={`purple-btn`}
                loaderImage={{
                  img: loading,
                  status: true,
                  style: { display: 'none' },
                }}
              />
            </div>
          </div>
          <div className="bottom-spacer"></div>
        </div>
      </Form>
    </div>
  );
}
