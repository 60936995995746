import moment from 'moment';
import Autoload from '../../Autoload';

const { checkProperty } = Autoload.MainHelper;

class UtilityBillingDataHandler {

    currentBillingStatementsDataHandler(currentBillingStatements = []) {
        if(currentBillingStatements.length === 0) return null;
        let billingStatementsArray = currentBillingStatements.map((BSO) => ({
            id: checkProperty(BSO, 'id'),
            billingStatementId: checkProperty(BSO, 'id'),
            rentalUnitId: checkProperty(BSO, 'rentalUnitId'),
            rentalUnitName: checkProperty(BSO, 'rentalUnitName'),
            tenantId: checkProperty(BSO, 'tenantId'),
            tenantName: checkProperty(BSO, 'tenantName'),
            totalAdvancePayments: checkProperty(BSO, 'totalAdvancePayments'),
            totalAllocatedUtilityCosts: checkProperty(BSO, 'totalAllocatedUtilityCosts'),
            utilityBillingId: checkProperty(BSO, 'utilityBillingId'),
            tenant: checkProperty(BSO, 'tenantName'),
            rentalUnit: checkProperty(BSO, 'rentalUnitName'),
            tenantOccupation: checkProperty(BSO, 'tenantOccupation'),
            occupencyPeriod: `${moment(checkProperty(checkProperty(BSO, 'tenantOccupation'), 'startDate')).format('DD.MM.YYYY')} - ${moment(checkProperty(checkProperty(BSO, 'tenantOccupation'), 'endDate')).format('DD.MM.YYYY')}`,
            occupencyPeriodDays: checkProperty(checkProperty(BSO, 'tenantOccupation'), 'totalDays'),
            fileURL: '',
        }));
        return billingStatementsArray;
    }

    createUtilityBillingObject(utilityBillingObject = null, type='other') {
        console.log('createUtilityBillingObject: ', utilityBillingObject);
        if(utilityBillingObject === null) return null;
        const startDate = checkProperty(checkProperty(utilityBillingObject, 'billingPeriod'), 'startDate');
        const endDate = checkProperty(checkProperty(utilityBillingObject, 'billingPeriod'), 'endDate');
        return {
            id: checkProperty(utilityBillingObject, 'id'),
            date: `${moment(startDate).format('DD.MM.YYYY')} - ${moment(endDate).format('DD.MM.YYYY')}`,
            tableData: [],
            billingData: {
              costPosition: [],
              tenantBills: [],
              overviewData: [],
            },
            recordStatus: type,
            //extra data received from api. storing it for future use incase we need it
            billingPeriodStart: checkProperty(checkProperty(utilityBillingObject, 'billingPeriod'), 'startDate'),
            billingPeriodEnd: checkProperty(checkProperty(utilityBillingObject, 'billingPeriod'), 'endDate'),
            billingPeriodId: checkProperty(checkProperty(utilityBillingObject, 'billingPeriod'), 'id'),
            energyAmountRoomHeatingKWH: checkProperty(utilityBillingObject, 'energyAmountRoomHeatingKWH'),
            energyAmountWarmWaterKWH: checkProperty(utilityBillingObject, 'energyAmountWarmWaterKWH'),
            energyAmountWarmWater: checkProperty(utilityBillingObject, 'energyAmountWarmWater'),
            heatingCostsAllocated: checkProperty(utilityBillingObject, 'heatingCostsAllocated'),
            heatingEnergyAmount: checkProperty(utilityBillingObject, 'heatingEnergyAmount'),
            heatingEnergyAmountKWH: checkProperty(utilityBillingObject, 'heatingEnergyAmountKWH'),
            hasCentralWarmWaterAmount: checkProperty(utilityBillingObject, 'hasCentralWarmWaterAmount'),
            centralWarmWaterAllocationKey: checkProperty(utilityBillingObject, 'centralWarmWaterAllocationKey'),
            nonHeatingCostsAllocated: checkProperty(utilityBillingObject, 'nonHeatingCostsAllocated'),
            propertyId: checkProperty(utilityBillingObject, 'propertyId'),
            totalHeatingCost: checkProperty(utilityBillingObject, 'totalHeatingCost'),
            totalRoomHeatingCost: checkProperty(utilityBillingObject, 'totalRoomHeatingCost'),
            totalWarmWaterHeatingCost: checkProperty(utilityBillingObject, 'totalWarmWaterHeatingCost'),
            heatingEnergyType: {
              id: checkProperty(checkProperty(utilityBillingObject, 'heatingEnergyType'), 'id'),
              fuelTypeName: checkProperty(checkProperty(utilityBillingObject, 'heatingEnergyType'), 'fuelTypeName'),
              conversionkWh: checkProperty(checkProperty(utilityBillingObject, 'heatingEnergyType'), 'conversionkWh'),
            },
            isCostSplitWarmWaterAndHeatingCostInfoHidden: checkProperty(utilityBillingObject, 'isCostSplitWarmWaterAndHeatingCostInfoHidden'),
            isHeatingCostInfoHidden: checkProperty(utilityBillingObject, 'isHeatingCostInfoHidden'),
            isMeterReadingAndConsumptionInfoHidden: checkProperty(utilityBillingObject, 'isMeterReadingAndConsumptionInfoHidden'),
            isSplitBaseConsumptionCostInfoHidden: checkProperty(utilityBillingObject, 'isSplitBaseConsumptionCostInfoHidden'),
            totalUtilityBillingCost: checkProperty(utilityBillingObject, 'totalUtilityBillingCost'),
            heatingCostsActivated: checkProperty(utilityBillingObject, 'heatingCostsActivated'),
            isSecured: checkProperty(utilityBillingObject, 'isSecured'),
            isVATHidden: checkProperty(utilityBillingObject, 'isVATHidden'),
            isLaborCostHidden: checkProperty(utilityBillingObject, 'isLaborCostHidden'),
            isEnergyConsumptionCO2EmissionsInfoHidden: checkProperty(utilityBillingObject, 'isEnergyConsumptionCO2EmissionsInfoHidden'),
            isPreviousPeriodEnergyComparisonInfoHidden: checkProperty(utilityBillingObject, 'isPreviousPeriodEnergyComparisonInfoHidden'),
            isCO2TaxActivated: checkProperty(utilityBillingObject, 'isCO2TaxActivated'),
            totalCO2Emissions: checkProperty(utilityBillingObject, 'totalCO2Emissions'),
            isEnergyLevelsInfoHidden: checkProperty(utilityBillingObject, 'isEnergyLevelsInfoHidden'),
            householdEnergyEfficiency: checkProperty(utilityBillingObject, 'householdEnergyEfficiency'),
            householdEnergyEfficiencyLevel: checkProperty(utilityBillingObject, 'householdEnergyEfficiencyLevel'),
            cO2TaxTotal: checkProperty(utilityBillingObject, 'cO2TaxTotal'),
            cO2TaxTenantShare: checkProperty(utilityBillingObject, 'cO2TaxTenantShare'),
            cO2TaxLandlordShare: checkProperty(utilityBillingObject, 'cO2TaxLandlordShare'),
            isCO2TaxMainlyUsedForBusiness: checkProperty(utilityBillingObject, 'isCO2TaxMainlyUsedForBusiness'),
            isCO2TaxPreservationObject: checkProperty(utilityBillingObject, 'isCO2TaxPreservationObject'),
            cO2EmissionkgM2: checkProperty(utilityBillingObject, 'cO2EmissionkgM2'),
            isCO2TaxInfoHidden: checkProperty(utilityBillingObject, 'isCO2TaxInfoHidden'),
            isArbitraryBoardInfoHidden: checkProperty(utilityBillingObject, 'isArbitraryBoardInfoHidden'),
            isEnergyAdviceCenterInfoHidden: checkProperty(utilityBillingObject, 'isEnergyAdviceCenterInfoHidden'),
        }
    }
}

export default UtilityBillingDataHandler