import React, { Component } from 'react';
import { Container, Row, Col, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ColorButton } from '../../../../index';
import DatePicker, { registerLocale } from 'react-datepicker';
import de from 'date-fns/locale/de';
import { CustomDateInput, CustomDateInput_v3 } from '../../../../../neoverv/index.js';
import housingphoto from '../../../../../assets/images/module/general/house-log.png';
import greytick from '../../../../../assets/images/module/general/greyColorTick.png';
import bluetick from '../../../../../assets/images/module/general/blueColorTick.png';
import addicons from '../../../../../assets/images/module/general/AddIcon.svg';
import subtracticon from '../../../../../assets/images/module/general/subtracticon.svg';
import uparrow from '../../../../../assets/images/module/general/SelectArrowUp.svg';
import downArrow from '../../../../../assets/images/module/general/SelectArrowDown.svg';
import GreenTick from '../../../../../assets/images/module/general/GreenTick.svg';
import Info from '../../../../../assets/images/module/general/Info.svg';

import PropertyImage from '../../../../../assets/images/module/general/house-log.png';
import CustomModal from './index';
import {
  addPropertyData,
  setPropertyList,
  changeCurrentProperty,
} from '../../../../../redux/action/General/generalActions';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Autoload from '../../../../Autoload';
import { connect } from 'react-redux';
import moment from 'moment';
import PropertyDataHandler from '../../../../helpers/DataHandler/Property';

import { NumericFormat } from 'react-number-format';
import GlobalServices from '../../../../helpers/services/GlobalServices';
import { showNotification } from '@mantine/notifications';
var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
const { checkProperty } = Autoload.MainHelper;
class AddPropertyHeatingModal extends Component {
  initialState = {
    propertyName: '',
    propertyNameErrMsg: false,
    address: '',
    addressErrMsg: false,
    city: '',
    cityErrMsg: false,
    zipCode: '',
    zipCodeErrMsg: false,
    noOfUnits: null,
    noOfUnitsErrMsg: false,
    property_modal_date: new Date(new Date().getFullYear(), 11, 31),
    // property_modal_date:new Date(),
    areaOfUnitsErrMsg: false,
    areaOfUnits: null,
    heatingTemp: '60 °C',
    heatingTempErrMsg: '',
    heatingTypeDropdownValueErrMsg: false,
    selectedOptionErrMsg: false,
    fuelTypeErrMsg: false,
    warmWaterErrMsg: false,
    radiobtnValue: '',
    selectedOptionvalue: 50,
    selectedOption: 50,
    file: '',
    fileToUpload: '',
    fileUploadErr: { state: false, msg: '' },
    greytickImage: greytick,
    bluetickImage: bluetick,
    fuelTypeName: '',
    fuelTypes: [],
    energyFuelType: null,
    heatingTypeDropdownState: 'true',
    heatingTypeDropdownValue: '',
    energyTypeDropdownState: 'true',
    totalRentingSpace: '',
    totalRentingSpaceErrMsg: false,
    numberOfRentalUnits: null,
    numberOfRentalUnitsErrMsg: false,
    allocationOptions: [],
    selectedAllocation: null,
    selectedAllocationErr: false,
    dropdownStateTwo: 'true',
    // energyFuelType: {
    //   id: '',
    //   fuelTypeName: '',
    //   conversionkWh: null,
    // },
  };

  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  componentDidMount(){
    this.getAllocationOptions();
  }
  
  _getFuelTypes() {
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'systemFuelTypes',
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      '_handleFuelTypeResponse',
      null,
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }


  getAllocationOptions = () => {
    GlobalServices._getAllocationKeysByProperty(this, '_handleGetAllocationOptions', this.props.propertySelected);
  };
  _handleGetAllocationOptions = (response) => {
    if (response.status == false) {
      Autoload.Redirection.redirection_error();
      Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang.newKeys.text_system_fuel_not_found);
    }

    this.setState({ allocationOptions: response.result.filter((allocatonKey)=> allocatonKey.meterType === 'RENTAL_UNIT') });
  };
  allocationChange = (option) => {
    this.setState({
      selectedAllocation: {
        id: option.id,
        allocationKeyName: option.allocationKeyName,
        conversionkWh: Autoload.MainHelper.ConverterCurrencyDe(option.conversionkWh + '', false),
        units: option.units,
        propertyId: option.propertyId,
        metered: option.metered,
        systemAllocationKey: option.systemAllocationKey,
        type: option.type
      },
      selectedAllocationErr: false
    });
  };

  _handleFuelTypeResponse(response) {
    console.log('_handleFuelTypeResponse: ', response);
    if (response.status === false && response.message !== 'Not Found') {
      // Autoload.Redirection.redirection_error();
      Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang.newKeys.text_error_not_found);
    }
    const fuelTypes = response.result.map((fuelType) => {
      return {
        id: Autoload.MainHelper.checkProperty(fuelType, 'id'),
        fuelTypeName: Autoload.MainHelper.checkProperty(fuelType, 'fuelTypeName'),
        conversionkWh: Autoload.MainHelper.checkProperty(fuelType, 'conversionkWh'),
      };
    });
    this.setState({ fuelTypes });
  }

  resetForm = () => {
    this.setState({ ...this.initialState });
    // this.props.toggle();
  };

  // removeSymbol = (value, symbol) => {
  //   if (!isNaN(parseInt(value))) {
  //     this.setState({ areaOfUnits: value.replace(symbol, '') });
  //   }
  // };

  // addSymbol = (value, symbol) => {
  //   if (!isNaN(parseInt(value))) {
  //     this.setState({ areaOfUnits: `${value}${symbol}` });
  //   } else {
  //     this.setState({ areaOfUnits: value.replace(symbol, '') });
  //   }
  // };

  onInputChange = (field, value) => {
    console.log(field, value, 'input change');
    this.setState({ [field]: value });
  };

  validateForm = () => {
    // console.log('validate form');
    this.setState({
      //propertyNameErrMsg: false,
      // addressErrMsg: false,
      // areaOfUnitsErrMsg: false,
      // noOfUnitsErrMsg: false,
      // cityErrMsg: false,
      // zipCodeErrMsg: false,
      fuelTypeErrMsg: false,
      heatingTempErrMsg: false,
      heatingTypeDropdownValueErrMsg: false,
      selectedOptionErrMsg: false,
      selectedAllocationErr: false,
      // numberOfRentalUnitsErrMsg: false,
      // totalRentingSpaceErrMsg: false
    });
    let //propertyNameErrMsg,
      // addressErrMsg,
      // areaOfUnitsErrMsg,
      // cityErrMsg,
      // zipCodeErrMsg,
      // noOfUnitsErrMsg,
      //warmWaterErrMsg,
      fuelTypeErrMsg,
      heatingTempErrMsg,
      heatingTypeDropdownValueErrMsg,
      selectedOptionErrMsg,
      selectedAllocationErr= false;
      // numberOfRentalUnitsErrMsg,
      // totalRentingSpaceErrMsg 
      
    const validNum = /^[0-9]/;
    //const validName = /^[a-zA-Z]+(?:[\s]+[a-zA-Z]+)*$/;
    const validName = /[^a-zA-Z0-9äöüÄÖÜß]/;
    // if (!this.state.propertyName) {
    //   propertyNameErrMsg = true;
    // }

    // if(this.props.propertyType !== 'REGULAR' && !this.state.numberOfRentalUnits){
    //   numberOfRentalUnitsErrMsg = true;
    // }

    // if(this.props.propertyType !== 'REGULAR' && !this.state.totalRentingSpace){
    //   totalRentingSpaceErrMsg = true;
    // }

    if(this.state.heatingTypeDropdownValue !== lang.property.generalinfo_decentralized && (!this.state.fuelTypeName || this.state.fuelTypeName === lang.newKeys.text_select)) {
      fuelTypeErrMsg = true;
    }

    if(this.state.heatingTypeDropdownValue === lang.property.generalinfo_warm_water && (this.state.selectedAllocation === null || this.state.selectedAllocation === undefined)){
      selectedAllocationErr= true;
    }

    // if(this.state.heatingTypeDropdownValue !== lang.property.generalinfo_decentralized && (!this.state.warmWaterAllocationKeyName || this.state.warmWaterAllocationKeyName === lang.newKeys.text_select)) {
    //   //warmWaterErrMsg = true;
    // }
    // if (!this.state.address) {
    //   addressErrMsg = true;
    // }
    // if (!this.state.city) {
    //   cityErrMsg = true;
    // }
    // if (!this.state.zipCode) {
    //   zipCodeErrMsg = true;
    // }
    // if (!this.state.areaOfUnits || !validNum.test(this.state.areaOfUnits)) {
    //   areaOfUnitsErrMsg = true;
    // }
    // if (
    //   !this.state.noOfUnits ||
    //   !validNum.test(this.state.noOfUnits) ||
    //   !validNum.test((this.state.noOfUnits + '').replace('m²', ''))
    // ) {
    //   noOfUnitsErrMsg = true;
    // }

    if (
      !this.state.heatingTemp ||
      !validNum.test(this.state.heatingTemp) ||
      !validNum.test((this.state.heatingTemp + '').replace('°C', ''))
    ) {
      heatingTempErrMsg = true;
    }
    if (
      this.state.selectedOption < 0 ||
      !validNum.test(this.state.selectedOption) ||
      !validNum.test((this.state.selectedOption + '').replace('°%', ''))
    ) {
      selectedOptionErrMsg = true;
    }

    if (!this.state.heatingTypeDropdownValue || this.state.heatingTypeDropdownValue === lang.newKeys.text_select) {
      heatingTypeDropdownValueErrMsg = true;
    }

    this.setState({
      //propertyNameErrMsg,
      // addressErrMsg,
      // areaOfUnitsErrMsg,
      // noOfUnitsErrMsg,
      // cityErrMsg,
      // zipCodeErrMsg,
      //warmWaterErrMsg,
      fuelTypeErrMsg,
      heatingTypeDropdownValueErrMsg,
      selectedOptionErrMsg,
      heatingTempErrMsg,
      selectedAllocationErr,
      // totalRentingSpaceErrMsg,
      // numberOfRentalUnitsErrMsg
    });

    if (
      //propertyNameErrMsg ||
      // addressErrMsg ||
      // areaOfUnitsErrMsg ||
      // noOfUnitsErrMsg ||
      // cityErrMsg ||
      // zipCodeErrMsg ||
      // totalRentingSpaceErrMsg ||
      // numberOfRentalUnitsErrMsg ||
      selectedAllocationErr ||
      fuelTypeErrMsg ||
      heatingTempErrMsg ||
      heatingTypeDropdownValueErrMsg ||
      selectedOptionErrMsg ||
      !Autoload.MainHelper.isValidDate(this.state.property_modal_date)
    ) {
      return false;
    } else {
      return true;
    }
  };

  _addGeneralInfo() {
    const {propertySelected} = this.props;
    console.log('propertySelected: ', propertySelected);
    let heatingValue =
      this.state.heatingTypeDropdownValue === lang.property.generalinfo_warm_water
        ? 'central with warm water'
        : this.state.heatingTypeDropdownValue === lang.property.generalinfo_without_warm_water
          ? 'central without warm water'
          : this.state.heatingTypeDropdownValue === lang.property.generalinfo_decentralized
            ? 'decentralized'
            : '';
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'block';
    const propertyID = propertySelected.id;
    const accountID = this.props.profileData.accountId;
    const generalInfoData ={
      propertyId: propertyID,
      accountId: accountID,
      name: propertySelected.name,
      propertyAdress: {
        streetno: propertySelected.propertyAddress,
        city: propertySelected.propertyCity,
        zipCode: propertySelected.propertyZipCode,
      },
      totalRentingSpace: propertySelected.totalRentingSpace,
      numberOfRentalUnits: propertySelected.numberOfRentalUnits,
      cutOffDate: propertySelected.cutOffDate,
      heatingType: heatingValue ? heatingValue.toLowerCase() : '',
      heatingTemperature: this.state.heatingTemp !== '' ? parseInt(this.state.heatingTemp) : 0,
      consumptionShare: this.state.selectedOption,
      energyFuelType: this.state.energyFuelType,
      receiptEmail: propertySelected.receiptEmail,
      meterReadingsEmail: propertySelected.meterReadingsEmail,
      centralWarmwaterAllocationKey: this.state.selectedAllocation,
      meterReadingConducted: propertySelected.meterReadingConducted,
      propertyType: propertySelected.propertyType,
      isConsumptionSectionHidden: propertySelected.isConsumptionSectionHidden,
      isDocumentSectionHidden: propertySelected.isDocumentSectionHidden,
      isMessageSectionHidden: propertySelected.isMessageSectionHidden
    }
    console.log('generalInfoData', generalInfoData);
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'properties/' + propertyID,
      'PUT',
      generalInfoData,
      null,
      false,
      true,
      false,
      this,
      '_PutGeneralInfoResponse',
      { generalInfoData },
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  _PutGeneralInfoResponse(response) {
    if(response.status){
      showNotification({
        icon: <img src={GreenTick} alt='green-tick'/>,
        message: 'Die Änderungen wurden übernommen.',
      });
      this.updateProperties(response.other.generalInfoData);
      this.props.openHeatingCostModal();
      this.props.toggle();
    }
  }

  updateProperties(updated_property_response) {
    let {propertySelected} = this.props;
    propertySelected={
      ...propertySelected,
      heatingType: Autoload.MainHelper.checkProperty(updated_property_response, 'heatingType'),
      heatingTemperature: Autoload.MainHelper.checkProperty(updated_property_response, 'heatingTemperature'),
      heatingTemp: Autoload.MainHelper.checkProperty(updated_property_response, 'heatingTemperature'),
      consumptionValue: Autoload.MainHelper.checkProperty(updated_property_response, 'consumptionShare', 0),
      consumptionShare: Autoload.MainHelper.checkProperty(updated_property_response, 'consumptionShare', 0),
      energyFuelType: Autoload.MainHelper.checkProperty(updated_property_response, 'energyFuelType', null),
      centralWarmWaterAllocationKey: Autoload.MainHelper.checkProperty(updated_property_response, 'centralWarmwaterAllocationKey', null),
    }
    this.props.changeCurrentProperty(propertySelected);
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'none';
  }

  after_addProperties(response) {
    // // ;
    this.getProperties(response);
  }

  getProperties(response) {
    // console.log('get properteis', response);
    Autoload.HttpRequestAxios.HttpRequest(
      // Autoload.Setting.property_api_base_url +
      //   'properties?accountId=' +
      //   this.props.profileData.id +
      //   // response.result.accountId +
      //   '&offset=0&fetch=100',
      Autoload.Setting.property_api_base_url + 'properties/' + response.other.id,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handlePropertyData',
      { data: response.other.data, generalInfoData: response.other.generalInfoData, id: response.other.id },
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  handlePropertyData(response) {
    // // ;
    if (response.status == false) {
      Autoload.Redirection.redirection_error();
    }
    var data = {};
    if (response.status === true && response.statuscode === 401) {
      localStorage.clear();
      window.location.reload();
    }

    if (response.status === true && response.statuscode === 200) {
      // const properties = PropertyDataHandler.getProperties(response.result);
      // this.props.setPropertyList(properties);

      data = {
        // id: checkProperty(response.result.data, 'id'),
        // propertyName: checkProperty(response.other.generalInfoData, 'name'),
        // propertyAddress: checkProperty(checkProperty(response.other.generalInfoData, 'propertyAdress'), 'streetno'),
        // propertyCity: checkProperty(checkProperty(response.other.generalInfoData, 'propertyAdress'), 'city'),
        // propertyZipCode: checkProperty(checkProperty(response.other.generalInfoData, 'propertyAdress'), 'zipCode'),
        // propertyNoOfUnits: checkProperty(response.other.generalInfoData, 'numberOfRentalUnits'),
        // propertyAreaOfUnits: checkProperty(response.other.generalInfoData, 'totalRentalSpace'),
        // cutOffDate: `${checkProperty(checkProperty(response.other.generalInfoData, 'cutOffDate'), 'day', '31')} ${moment()
        //   .month(parseInt(checkProperty(checkProperty(response.other.generalInfoData, 'cutOffDate'), 'month', '12')) - 1)
        //   .format('MMMM')}`,
        // heatingType: checkProperty(response.other.generalInfoData, 'heatingType'),
        // heatingTemp: checkProperty(response.other.generalInfoData, 'heatingTemperature'),
        // consumptionValue: checkProperty(response.other.generalInfoData, 'consumptionShare',0),
        // energyFuelType: checkProperty(response.other.generalInfoData, 'energyFuelType'),
        id: checkProperty(response.result, 'id'),
        propertyName: checkProperty(response.result, 'name'),
        propertyAddress: checkProperty(checkProperty(response.result, 'propertyAdress'), 'streetno'),
        propertyCity: checkProperty(checkProperty(response.result, 'propertyAdress'), 'city'),
        propertyZipCode: checkProperty(checkProperty(response.result, 'propertyAdress'), 'zipCode'),
        propertyNoOfUnits: checkProperty(response.result, 'numberOfRentalUnits'),
        propertyAreaOfUnits: checkProperty(response.result, 'totalRentingSpace'),
        cutOffDate: `${checkProperty(checkProperty(response.result, 'cutOffDate'), 'day', '31')} ${moment()
          .month(parseInt(checkProperty(checkProperty(response.result, 'cutOffDate'), 'month', '12')) - 1)
          .format('MMMM')}`,
        heatingType: checkProperty(response.result, 'heatingType'),
        heatingTemp: checkProperty(response.result, 'heatingTemperature'),
        consumptionValue: checkProperty(response.result, 'consumptionShare'),
        energyFuelType: checkProperty(response.result, 'energyFuelType'),
        isConsumptionSectionHidden: checkProperty(response.result, 'isConsumptionSectionHidden'),
        isDocumentSectionHidden: checkProperty(response.result, 'isDocumentSectionHidden'),
        isMessageSectionHidden: checkProperty(response.result, 'isMessageSectionHidden'),
      };
      Autoload.HttpRequestAxios.HttpRequest(
        Autoload.Setting.property_api_base_url + 'properties/' + data.id + '/picture',
        'GET',
        null,
        null,
        false,
        true,
        false,
        this,
        '_getPropertyPictureResponse',
        { propertyData: data },
        false,
        '',
        {
          'Content-Type': 'application/json',
          Authorization: Autoload.Utils.getAuthToken(),
        }
      );
    }
    // this.props.changeCurrentProperty(data);
    // this.props.history.push('/dashboard');

    // Autoload.Redirection.redirection('/dashboard');
  }

  _getPropertyPictureResponse(response) {
    // console.log(response, 'response');
    // // ;
    response.other.propertyData.fileName = response.result === null ? PropertyImage : response.result;
    this.props.changeCurrentProperty(response.other.propertyData);
    this.resetForm();
    this.props.toggle();
    // // ;
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'none';
    debugger;
    Autoload.Redirection.redirection('/dashboard');
  }

  handleSubmit = (e) => {
    e.preventDefault();
    
    let isValid = this.validateForm();
    // this.addPropertyData();
    if (isValid) {
      console.log('working for add btn');
      this._addGeneralInfo();

      return;
      // console.log('valid data');
    } else {
      // console.log('invalid data add property form');
      // this.resetForm();
    }
  };

  putPropertyPicture(response) {
    // console.log('put picture', response);
    const payload = new FormData();
    payload.append('file', this.state.fileToUpload);
    // console.log('payload', payload);
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'properties/' + response.result.id + '/Picture',
      'PUT',
      payload,
      null,
      false,
      true,
      false,
      this,
      'putPropertyPictureResponse',
      { data: response.result, generalInfoData: response.other.generalInfoData, id: response.result.id },
      false,
      '',
      { 'Content-Type': 'application/json', Authorization: Autoload.Utils.getAuthToken() }
    );
  }

  putPropertyPictureResponse(response) {
    // console.log('put picture respiose', response);
    if (response.status === false && response.message !== 'Not Found') {
      // Autoload.Redirection.redirection_error();
      Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang.newKeys.text_error_found);
    }

    this.after_addProperties(response);
    // this.getProperties(response)
  }

  handlePictureUpload = (e) => {
    e.preventDefault();
    if (e.target.files[0] && typeof e.target.files[0] != 'undefined') {
      let fileTypeErr = false;
      let fileDimensionErr = false;
      const file = e.target.files[0];
      // var pattern = /image-*/;
      // var pattern = /[\/](jpg|png|jpeg)$/i;
      // console.log('file type', file, file.type);
      if (!file.type.includes('image')) {
        fileTypeErr = true;
      }
      var reader = new FileReader();
      reader.readAsDataURL(file);
      const classContext = this;
      reader.onload = function (e) {
        var image = new Image();
        image.src = e.target.result;
        image.onload = function () {
          var height = this.height;
          var width = this.width;
          // console.log('width', this.width, 'height', this.height);
          if (height < 240 || width < 240) {
            fileDimensionErr = true;
          }
          if (fileTypeErr || fileDimensionErr) {
            const msg = `Invalid file ${fileTypeErr ? 'type' : ''} ${fileTypeErr && fileDimensionErr ? 'and' : ''} ${
              fileDimensionErr ? 'dimension' : ''
            }`;
            classContext.setState({
              fileUploadErr: { state: true, msg },
            });
          } else {
            classContext.setState({
              file: URL.createObjectURL(file),
              fileToUpload: file,
              fileUploadErr: { state: false, msg: '' },
            });
          }
        };
      };
    }
  };

  onDateChange = (field, date) => {
    this.setState({ [field]: date });
  };

  renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {/* {lang.newKeys.tooltip_default_text} */}
      <span>
        Anteil der Heizkosten die verbrauchsabhängig umgelegt werden. Mehr Informationen im{' '}
        <a
          target="_blank"
          href="https://wissen.hellohousing.de/welcher-kostenanteil-soll-nach-verbrauch-umgelegt-werden"
        >
          Hilfe-Center
        </a>
        .
      </span>
    </Tooltip>
  );

  removeSymbol = (field, value, symbol) => {
    this.setState({ [field]: value.replaceAll(symbol, '') });
  };

  addSymbol = (field, value, symbol) => {
    console.log(field, value, symbol, 'add symbol');
    if (value.toString().includes(symbol)) {
      return;
    }
    if (!isNaN(parseFloat(value))) {
      this.setState({ [field]: `${value}${symbol}` });
    } else {
      this.setState({ [field]: value.replaceAll(symbol, '') });
    }
  };

  handleEnterKeyPress = (e) => {
    if (e.charCode == 13) {
      e.preventDefault();
      this.handleSubmit(e);
    }
  };

  render() {
    const { open, toggle, className, edit } = this.props;
    const { warmWaterAllocationKeys } = this.state;
    // console.log(propertySelected.id, 'propertySelected id');
    // console.log(properties, 'all properties data ');
    return (
      <div>
        <CustomModal
          heading={'Angaben zur Heizungsanlage'}
          headingClassName={'tooltips property-heating-modal-heading'}
          open={open}
          toggle={() => {
            this.resetForm();
            toggle();
          }}
          className={`${className}`}
        >
          <div style={{marginBottom: '25px'}}>
            <span style={{fontFamily: 'Inter, sans-serif', fontWeight: '400'}}>Bitte ergänzen Sie zur richtigen Ermittlung der Heizkosten zunächst einige Angaben zu Ihrer Heizungsanlage.</span>
          </div>
          <Form onSubmit={(e) => this.handleSubmit(e)}>
            <Container>
              <Row>
                <Col xs={12} sm={12} md={12} className="add_property_modal_dropdown">
                  <Form.Group>
                  <div className='tooltips property-heating-type-new' style={{marginBottom: '5px'}}>{'Welche Heizungsart wird verwendet?'//lang.property.generalinfo_heating_type
                  }</div>
                    <UncontrolledDropdown
                      id="myid"
                      onBlur={() => {
                        this.setState({
                          heatingTypeDropdownState: document
                            .getElementById('myid')
                            .firstElementChild.getAttribute('aria-expanded'),
                        });
                      }}
                      onClick={() => {
                        this.setState({
                          heatingTypeDropdownState: document
                            .getElementById('myid')
                            .firstElementChild.getAttribute('aria-expanded'),
                        });
                      }}
                      onFocus={() => {
                        this.setState({
                          heatingTypeDropdownState: document
                            .getElementById('myid')
                            .firstElementChild.getAttribute('aria-expanded'),
                        });
                      }}
                    >
                      <DropdownToggle
                        className="property-single_dropdown"
                        style={{
                          border: this.state.heatingTypeDropdownValueErrMsg === true ? '1px solid #dc3545' : null,
                        }}
                      >
                        <div>
                          {this.state.heatingTypeDropdownValue === ''
                            ? lang.newKeys.text_select
                            : this.state.heatingTypeDropdownValue}
                        </div>
                        <div>
                          {this.state.heatingTypeDropdownState === 'true' ? (
                            <img src={downArrow} alt="" />
                          ) : (
                            <img src={uparrow} alt="" />
                          )}
                        </div>
                      </DropdownToggle>
                      <DropdownMenu className="property-single-dropdown-menu">
                        <DropdownItem
                          onClick={() => {
                            this.setState({
                              heatingTypeDropdownValue: lang.property.generalinfo_warm_water,
                            });
                            this.getAllocationOptions();
                          }}
                          className="px-0 "
                        >
                          <span className="property-single-dropdown-options">
                            {lang.property.generalinfo_warm_water}
                          </span>
                          {this.state.heatingTypeDropdownValue === lang.property.generalinfo_warm_water ? (
                            <img src={bluetick} alt="" />
                          ) : (
                            <img src={greytick} alt="" />
                          )}
                        </DropdownItem>
                        <DropdownItem
                          className="px-0"
                          onClick={() => {
                            this.setState({
                              heatingTypeDropdownValue: lang.property.generalinfo_without_warm_water,
                              // 'central without warm water',
                            });
                            this.getAllocationOptions();
                          }}
                        >
                          <span className="property-single-dropdown-options">
                            {lang.property.generalinfo_without_warm_water}
                          </span>
                          {this.state.heatingTypeDropdownValue === lang.property.generalinfo_without_warm_water ? (
                            <img src={bluetick} alt="" />
                          ) : (
                            <img src={greytick} alt="" />
                          )}
                        </DropdownItem>
                        {/* <DropdownItem
                          className="px-0"
                          onClick={() => {
                            this.setState({
                              heatingTypeDropdownValue: lang.property.generalinfo_decentralized,
                              // 'decentralized',
                            });
                            // this.getAllocationOptions();
                          }}
                        >
                          <span className="property-single-dropdown-options">
                            {lang.property.generalinfo_decentralized}
                          </span>
                          {this.state.heatingTypeDropdownValue === lang.property.generalinfo_decentralized ? (
                            <img src={bluetick} alt="" />
                          ) : (
                            <img src={greytick} alt="" />
                          )}
                        </DropdownItem> */}
                      </DropdownMenu>
                    </UncontrolledDropdown>

                    <div className="heatingDropdown_errormsg">
                      {this.state.heatingTypeDropdownValueErrMsg === true ? (
                        <p>{lang.newKeys.text_heatingType_error}</p>
                      ) : null}
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={12} className="add_property_modal_dropdown">
                  <Form.Group>
                  <div className='tooltips property-energy-source-new' style={{marginBottom: '5px'}}>{'Mit welchem Brennstoff wird die Heizung betrieben?'//lang.newKeys.text_Energy_source
                  }</div>
                    {//(this.state.heatingTypeDropdownValue === lang.property.generalinfo_warm_water) || (this.state.heatingTypeDropdownValue === lang.property.generalinfo_without_warm_water) ? (
                      <>
                      <UncontrolledDropdown
                        id="energyid"
                        onBlur={() => {
                          this.setState({
                            energyTypeDropdownState: document
                              .getElementById('energyid')
                              .firstElementChild.getAttribute('aria-expanded'),
                          });
                        }}
                        onFocus={() => {
                          this.setState({
                            energyTypeDropdownState: document
                              .getElementById('energyid')
                              .firstElementChild.getAttribute('aria-expanded'),
                          });
                        }}
                        onClick={() => {
                          this.setState({
                            energyTypeDropdownState: document
                              .getElementById('energyid')
                              .firstElementChild.getAttribute('aria-expanded'),
                          });
                        }}
                      >
                        <DropdownToggle
                          className="property-single_dropdown"
                          style={{
                            border: this.state.fuelTypeErrMsg === true ? '1px solid #dc3545' : null,
                          }}
                        >
                          <div>
                            {this.state.fuelTypeName === '' ? lang.newKeys.text_select : this.state.fuelTypeName}
                          </div>
                          <div>
                            {this.state.energyTypeDropdownState === 'true' ? (
                              <img src={downArrow} alt="" />
                            ) : (
                              <img src={uparrow} alt="" />
                            )}
                          </div>
                        </DropdownToggle>
                        <DropdownMenu className="property-single-dropdown-menu">
                          {this.props.fuelTypes.sort((a,b)=> a.fuelTypeName.localeCompare(b.fuelTypeName))
                          .map((fuelType) => {
                            return (
                              <DropdownItem
                                className="px-0"
                                onClick={() => {
                                  this.setState({
                                    energyFuelType: {
                                      id: fuelType.id,
                                      fuelTypeName: fuelType.fuelTypeName,
                                      conversionkWh: fuelType.conversionkWh,
                                    },
                                    fuelTypeName: fuelType.fuelTypeName,
                                  });
                                }}
                              >
                                <span className="property-single-dropdown-options">{fuelType.fuelTypeName}</span>
                              </DropdownItem>
                            );
                          })}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                      <div className="heatingDropdown_errormsg">
                        {this.state.fuelTypeErrMsg ? (
                          <p>{lang.newKeys.text_fuelType_error}</p>
                        ) : null}
                      </div>
                    </>
                    // ) : (
                    //   <div>
                    //     <Form.Control
                    //       disabled
                    //       value={this.state.energyFuelType != null ? this.state.energyFuelType : ''}
                    //       placeholder={this.state.heatingTypeDropdownValue === lang.property.generalinfo_decentralized ? 'Nicht benötigt' : lang.newKeys.text_select}
                    //       className="disbaled-temperature"
                    //       // onChange={() => {
                    //       //   this.setState({
                    //       //     energyFuelType: null,
                    //       //   });
                    //       // }}
                    //     />
                    //   </div>
                    // )
                    }
                  </Form.Group>
                </Col>
              </Row>
              {this.state.heatingTypeDropdownValue === lang.property.generalinfo_warm_water &&
              <>
                <Row>
                <Col xs={12} sm={12} md={12} style={{paddingLeft: '15px'}} className='add_property_modal_dropdown'>
                    <Form.Group>
                    <div className='tooltips property-temperature-new' style={{marginBottom: '5px'}}>
                      {/* <Form.Label className="heating-temp"> */}
                      {this.state.heatingTypeDropdownValue === lang.property.generalinfo_warm_water ? 'Welche Vorlauftemperatur ist für das Warmwasser ist eingestellt?' : lang.property.generalinfo_heating_temp} 
                      {/* </Form.Label> */}
                      </div>
                      {this.state.heatingTypeDropdownValue === lang.property.generalinfo_warm_water ? (
                        <div>
                          <Form.Control
                            type="text"
                            isInvalid={this.state.heatingTempErrMsg}
                            value={this.state.heatingTemp}
                            placeholder="60 °C"
                            onFocus={(e) => this.removeSymbol('heatingTemp', e.target.value, ' °C')}
                            onBlur={(e) => this.addSymbol('heatingTemp', e.target.value, ' °C')}
                            onChange={(e) => this.onInputChange('heatingTemp', e.target.value, true)}
                            onKeyPress={this.handleEnterKeyPress}
                          />
                          <Form.Control.Feedback className="err" type="invalid">
                            {lang.property.generalinfo_Property_no_of_Units_error}
                          </Form.Control.Feedback>
                        </div>
                      ) : (
                        <div>
                          <Form.Control disabled placeholder="60 °C" className="disbaled-temperature" />
                        </div>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} sm={12} md={12} className="add_property_modal_dropdown">
                    <Form.Group>
                    <div className='tooltips property-warm-water-edit' style={{marginBottom: '5px'}}>
                      {/* <Form.Label className="cp-cost-position-label">  */}
                      {this.state.heatingTypeDropdownValue === lang.property.generalinfo_warm_water ? 'Wie wird der Verbrauch am zentralen Warmwasserboiler gemessen?' : 'Verteilerschlüssel Warmwasserboiler'}
                      {/* </Form.Label> */}
                      </div>
                    {(this.state.heatingTypeDropdownValue === lang.property.generalinfo_warm_water) ? 
                    <UncontrolledDropdown
                      id="keyid2"
                      onBlur={() => {
                        this.setState({
                          dropdownStateTwo: document
                            .getElementById('keyid2')
                            .firstElementChild.getAttribute('aria-expanded'),
                        });
                      }}
                      onFocus={() => {
                        this.setState({
                          dropdownStateTwo: document
                            .getElementById('keyid2')
                            .firstElementChild.getAttribute('aria-expanded'),
                        });
                      }}
                    >
                      <DropdownToggle className="property-single_dropdown"
                      style={{border: this.state.selectedAllocationErr === true ? '1px solid #dc3545' : null,}}>
                        <span style={{minWidth: 0, overflow: 'hidden', whiteSpace:'nowrap', textOverflow: 'ellipsis'}}> {this.state?.selectedAllocation ? `${this.state?.selectedAllocation?.allocationKeyName} (${this.state?.selectedAllocation?.units})` : lang.newKeys.text_select}</span>
                        {/* <span style={{paddingRight: 10}}> {this.state?.selectedAllocation?.units && `(${this.state?.selectedAllocation?.units})`}</span> */}
                        <span>
                          {' '}
                          {this.state.dropdownStateTwo === 'true' ? (
                            <img src={downArrow} alt="" />
                          ) : (
                            <img src={uparrow} alt="" />
                          )}
                        </span>
                      </DropdownToggle>
                      <DropdownMenu className="property-single-dropdown-menu">
                        {this.state.allocationOptions.sort((a,b)=> a.allocationKeyName.localeCompare(b.allocationKeyName))
                        .map((option) => option.type === 'WARMWATER' && (option.allocationKeyName !== 'Warmwasserverbrauch')  && (
                          <DropdownItem
                            onClick={() => {
                              this.allocationChange(option);
                            }}
                            className="cpRow-options"
                          >
                            {console.log('option: ', option)}
                            <span className="property-single-dropdown-options">{`${option.allocationKeyName} ${option.units && option.units !== '-' ? `(${option.units})` : ''}`}</span>
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    :
                    <div>
                      <Form.Control
                        disabled
                        value={this.state.selectedAllocation != null ? this.state.selectedAllocation : ''}
                        placeholder={this.state.heatingTypeDropdownValue === lang.property.generalinfo_without_warm_water ? 'Nicht benötigt' : lang.newKeys.text_select}
                        className="disbaled-temperature"
                        // onChange={() => {
                        //   this.setState({
                        //     energyFuelType: null,
                        //   });
                        // }}
                      />
                    </div>
                    }
                    {!this.state.selectedAllocation?.allocationKeyName && this.state.selectedAllocationErr && <span style={{
                      width: '100%',
                      marginTop: '0.25rem',
                      fontSize: '80%',
                      color: '#dc3545'
                      }}>Einheit erforderlich</span>}
                    </Form.Group>
                  </Col>
                </Row>
              </>}
              <div className="add-property-modal-text" style={{marginTop: 0}}>
                <p className="add-property-modal-text-INFO property-consumption-percentage-new">
                  {/* {lang.property.generalinfo_text_one} <br /> {lang.property.generalinfo_text_two} */}
                  {'Welcher Anteil der Heizkosten soll nach Verbrauch umgelegt werden?'}
                </p>
              </div>
              <div className="profile_modal_radiobtns">
                <div
                  className={`radio hello-housing-radio-options ${this.state.selectedOption === 70 ? `checked` : ``} `}
                >
                  <label style={{ display: 'flex' }}>
                    <input
                      type="radio"
                      value={70}
                      // className="pr-0"
                      checked={this.state.selectedOptionvalue == 70}
                      onChange={(e) => {
                        this.setState({
                          selectedOption: parseInt(e.target.value),
                          selectedOptionvalue: 70
                        });
                      }}
                      onKeyPress={this.handleEnterKeyPress}
                    />
                    <span className="profile_modal_radiobtns-label">70%</span>
                  </label>
                </div>
                <div
                  className={`radio hello-housing-radio-options ${this.state.selectedOption === 50 ? `checked` : ``} `}
                >
                  <label style={{ display: 'flex' }}>
                    <input
                      type="radio"
                      value={50}
                      //  className="pr-0"
                      checked={this.state.selectedOptionvalue == 50}
                      onChange={(e) => {
                        this.setState({
                          selectedOption: parseInt(e.target.value),
                          selectedOptionvalue: 50
                        });
                      }}
                      onKeyPress={this.handleEnterKeyPress}
                    />
                    <span className="profile_modal_radiobtns-label">50%</span>
                  </label>
                </div>
                <div
                  className={`radio hello-housing-radio-options ${this.state.selectedOption === 0 ? `checked` : ``} `}
                >
                  <label style={{ display: 'flex' }}>
                    <input
                      type="radio"
                      value={0}
                      //  className="pr-0"
                      checked={this.state.selectedOptionvalue == 0}
                      onChange={(e) => {
                        this.setState({
                          selectedOption: parseInt(e.target.value),
                          selectedOptionvalue: 0
                        });
                      }}
                      onKeyPress={this.handleEnterKeyPress}
                    />
                    <span className="profile_modal_radiobtns-label">0%</span>
                  </label>
                </div>
                <div
                  className={`radio hello-housing-radio-options ${
                    this.state.selectedOption === 'custom' || this.state.selectedOptionvalue == 'custom'
                      ? `checked`
                      : ``
                  } `}
                >
                  <label style={{ display: 'flex' }}>
                    <input
                      type="radio"
                      // className="pr-0"
                      value={this.state.selectedOption}
                      checked={this.state.selectedOptionvalue == 'custom'}
                      onChange={(e) => {
                        this.setState({
                          selectedOption: e.target.value,
                          selectedOptionvalue: 'custom',
                        });
                      }}
                      onKeyPress={this.handleEnterKeyPress}
                    />
                    <span className="profile_modal_radiobtns-label">{lang.property.generalinfo_custom}</span>
                  </label>
                </div>
              </div>
              <Row className="custom-input">
                {/* custom sizeee */}
                <Col xs={4} sm={4} md={2} className="custom-input_padding">
                  {this.state.selectedOptionvalue === 'custom' ? (
                    <div className="custom-input_margin" 
                    style={{ 
                      position: 'relative', 
                      top: 0, 
                      left: 0 }}>
                      <Form.Control
                        // isInvalid={this.state.selectedOption}
                        // placeholder=""
                        // type="number"
                        // onChange={(e) =>
                        //   this.setState({
                        //     selectedOption: e.target.value,
                        //   })
                        // }

                        value={this.state.selectedOption}
                        // onFocus={(e) => this.removeSymbol('selectedOption', e.target.value, ' %')}
                        // onBlur={(e) => this.addSymbol('selectedOption', e.target.value, ' %')}
                        onChange={(e) => {
                          this.onInputChange('selectedOption', e.target.value, true);
                        }}
                        // placeholder="%"
                        type="text"
                        onKeyPress={this.handleEnterKeyPress}
                        style={{
                          borderColor: this.state.selectedOptionErrMsg === true && 'rgb(220, 53, 69)',
                          //boxShadow: this.state.selectedOptionErrMsg === true && '0 0 0 2px rgba(240, 62, 62, 0.25)'
                        }}
                        // onFocus={(e) => this.removeSymbol('selectedOption', e.target.value, ' %')}
                        // onBlur={(e) => this.addSymbol('selectedOption', e.target.value, ' %')}
                        // onChange={(e) => {
                        //   this.setState({ selectedOption: e.target.value });
                        // }}
                      />
                      <span style={{ position: 'absolute', top: '8px', right: '13px' }}>%</span>
                    </div>
                  ) : null}
                  <div 
                  className="heatingDropdown_errormsg"
                  style={{
                    whiteSpace: 'nowrap',
                    marginLeft: '-23px'
                  }}>
                    {this.state.selectedOptionErrMsg === true ? (
                      <p>{lang.property.generalinfo_Property_no_of_Units_error}</p>
                    ) : null}
                  </div>
                </Col>
              </Row>

              <div className="cancel_addbtn">
                <div>
                  <ColorButton
                    onClick={(e) => {
                      e.preventDefault();
                      this.resetForm();
                      toggle();
                    }}
                    className="gray-btn"
                    text={lang.general.text_cancel}
                  />
                </div>
                <div id="property-add-btns">
                  <ColorButton
                    buttonConfig={{ type: 'submit' }}
                    className="purple-btn"
                    text={lang.utility.text_next_utility_bills}
                    //onClick={this.props.openHeatingCostModal}
                  />
                </div>
              </div>
            </Container>
          </Form>
        </CustomModal>
      </div>
    );
  }
}

const actions = {
  addPropertyData,
  setPropertyList,
  changeCurrentProperty,
};

const mapStateToProps = (state) => {
  return {
    propertySelected: state.generalReducer.property,
    properties: state.generalReducer.allProperties,
    profileData: state.generalReducer.profile,
    accountID: state.generalReducer.account_api_id,
    fuelTypes: state.generalReducer.fuelTypes
  };
};

export default connect(mapStateToProps, actions)(AddPropertyHeatingModal);
