import React, { Component } from 'react';
import { ColorButton, EmptyStateContainer } from '../../../../index.js';
import DownloadIcon from '../../../../../assets/images/module/general/DownloadIcon.svg';
import Autoload from '../../../../Autoload';
import Avatar from '../../../../../assets/images/module/general/UserAccountBlue.svg';
import { Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import moment from 'moment';
import { Stack } from '@mantine/core';
import { formatNumber } from '../../../../../helpers/stringsManipulations.js';
var Setting = Autoload.Setting;
let { redirection } = Autoload.MainHelper
var lang = Setting[Setting.language_default].general;

class ProfileHistoryRecord extends Component {
  state = {
    filename: '',
  };
  getPaymentHistoryPDF = (id) => {
    console.log(id, 'getPaymentHistoryPDF');
    Autoload.HttpRequestAxios.HttpRequest(
      // Autoload.Setting.user_reg_api_baseurl + 'Invoices/' + id + '/download', // direct download
      Autoload.Setting.user_reg_api_baseurl + 'Invoices/' + id,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handle_payment_history_pdf',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };

  handle_payment_history_pdf = (response) => {
    console.log('handle_payment_history_pdf', response);
    var fileLocation = Autoload.MainHelper.checkProperty(response.result, 'fileLocation');
    this.setState({
      filename: fileLocation,
    });

    var a = document.createElement('a');
    a.target = '_blank';
    a.href = fileLocation;
    a.click();
  };
  getTableLabels = () => {
    const { data, labels } = this.props;
    return labels.map((label, index) => {
      return (
        <TableCell key={index}>
          {label}
        </TableCell>
      );
    });
  };
  getTableData = () => {
    const { data } = this.props;
    console.log(data, 'data props');
    return data.map((row, rowIndex) => {
      return (
        <TableRow key={rowIndex} //style={{borderBottom: '1px solid #E3E3E3'}}
        >
          <TableCell>{moment(Autoload.MainHelper.checkProperty(row, 'invoiceDate')).format('DD.MM.YYYY')}</TableCell>
          <TableCell>{row && row.positions && row.positions.length > 0 && row.positions[0].description}</TableCell>
          <TableCell style={{fontWeight: 'bold'}}>{`${row.invoiceType === 'Credit' ? '-' : ''}${formatNumber(row.totalAmount, true)}`}</TableCell>
          <TableCell>
            {/* <a
              download
              target="_blank"
              href={this.state.filename ? this.state.filename : ''}
              // className={`download-icon ${record && record.file_name ? '' : 'disable'}`}
            >*/}
            {/* <ColorButton
              onClick={() => {
                window.open(`/user/invoice/${row.id}`, '_blank');
              }}
              buttonConfig={{ target: '_blank' }}
              className="download-btn"
              text={lang.text_download}
              rightImage={{ img: DownloadIcon, style: { width: '15px', height: '16px' } }}
            /> */}
            <Stack spacing={0}>

              {row.paymentStatus === 'PAID' ? 'Bezahlt' : row.paymentStatus === 'OPEN' ? 'Offen' : (row.paymentStatus === 'CANCELLED' || row.paymentStatus === 'FAILED') ? 'Fehlgeschlagen' : 'Unbezahlte'}

              <span onClick={() => {
                window.open(`/invoicePdf/${row.id}`, '_blank');
                //this.getPaymentHistoryPDF(row.id);
              }} className='payment-invoice-button'>Rechnung ansehen</span>
            </Stack>
            {/* </a>  */}

            {/* <a
              onClick={() => {
                this.getPaymentHistoryPDF(row.id);
              }}>

                <div className="button-img-wrapper">
                  <p className={`btn-text`}>{lang.text_download}</p>
                  <img className="btn-img right" src="{{ DownloadIcon }}" alt="left icon" style="width:'15px';height:'16px';" />

                </div>

              </a> */}
          </TableCell>
        </TableRow>
      );
    });
  };

  render() {
    return (
      <TableContainer className='invoice-table'>
        <Table style={{borderCollapse: 'separate', borderSpacing: '0 8px'}}>
          <TableBody>
            <TableRow //style={{borderBottom: '1px solid #E3E3E3'}}
            >{this.getTableLabels()}</TableRow>
            {this.props.data.length > 0 ? (
              this.getTableData()
            ) : (
              <TableRow>
                <TableCell colSpan={4}>
                  <EmptyStateContainer
                    heading="Keine Rechnungen vorhanden."
                    subheading="Hier finden Sie Ihre hellohousing Rechnungen, wenn Sie bezahlte Services nutzen."
                    icon={Avatar}
                    showBtn={false}
                    styleMainContainer={{margin: '30px 0px'}}
                  />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}

export default ProfileHistoryRecord;
