import React from 'react';
import { CardTitle ,CardSubtitle,CardHeader} from 'reactstrap';

export default function HeadingContainer({ subheading,subheadingbadge, subheadingClassName,heading,sideheadings, rightContent, className,badge,headingIcon,bagdeClassName,iconHandler, headingClassName, headingRightContentClassName, marginBottom=3, hideHeadingTextWrapper=false }) {
  return (
    <div className={`heading-container ${className} mb-${marginBottom}`} style={{display: 'flex', alignItems: 'center'}}>
        {hideHeadingTextWrapper === false &&
        <div className='heading-text-wrapper' >
            <CardTitle className='heading mb-0' >
                {headingIcon && <img className='heading-icon' src={headingIcon} onClick={()=>iconHandler()} alt='heading' />}
                {heading &&
                <span 
                className={headingClassName}
                >
                    {heading}
                </span>}
                {sideheadings}
                {badge}
            </CardTitle>
            {subheading && 
            <div>
            <span className={`${subheadingClassName} subheading`}>{subheading}</span>{subheadingbadge}
            </div>
             }
            {/* <CardHeader tag="h6" className="mb-2 text-muted" style={{ alignSelf: 'flex-start'}}>{sideheadings}</CardHeader> */}
        </div>}
        <div className={`heading-right-content ${headingRightContentClassName}`} style={{ alignSelf: 'flex-start'}}>
            {rightContent}
        </div>
  </div>
  );
}

HeadingContainer.defaultProps={
    heading:'No heading',  
    iconHandler:()=>{},
}