import List from '../../../views/pages/Module/UtilityBilling/List';


const pageList = [
  {
    name: 'Utility Billing List',
    path: '/utility-billing/',
    component: List,
  },
 
];

export default pageList;
