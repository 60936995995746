import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { InfoField } from '../../../../index.js';
import EmailIcon from '../../../../../assets/images/module/general/EmailIcon.svg';
import PhoneIcon from '../../../../../assets/images/module/general/PhoneIcon.svg';
import TenantIcon from '../../../../../assets/images/module/general/TenantIcon.svg';
import AreaIcon from '../../../../../assets/images/module/general/AreaIcon.svg';
import Autoload from '../../../../Autoload';
import { formatNumber } from '../../../../../helpers/stringsManipulations.js';

const { checkProperty } = Autoload.MainHelper;
var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];

export default function TiledContent({ data, tenant, onClick }) {
  const [rentalData, setRentalData] = useState(data);

  useEffect(() => {
    setRentalData(data);
  }, [data]);

  // const tenantName = checkProperty(rentalData, 'tenantsData') && rentalData.tenantsData[0] && rentalData.tenantsData[0].name;
  // const tenantEmail = checkProperty(rentalData, 'tenantsData') && rentalData.tenantsData[0] && rentalData.tenantsData[0].email;
  // const tenantPhone = checkProperty(rentalData, 'tenantsData') && rentalData.tenantsData[0] && rentalData.tenantsData[0].phone;
  const tenantName = checkProperty(tenant, 'name', '');
  const tenantEmail = checkProperty(tenant, 'email', '');
  const tenantPhone = checkProperty(tenant, 'phone', '');
  return (
    <div onClick={onClick} style={{marginTop: '-15px'}}>
      <Row>
        <Col xs={12} md={6}>
          <InfoField
            className="pt-3 name-dashboard-rentalunits"
            labelText={lang.general.text_tenants}
            text={tenantName ? tenantName : '𝘦𝘮𝘱𝘵𝘺'}
            // text={checkProperty(rentalData, 'tenantsData') && rentalData.tenantsData[0] && rentalData.tenantsData[0].name}
            labelClassName="text-xs"
            leftTextImage={{ img: TenantIcon, style: { width: '7.5px', height: '9.25px' } }}
          />
        </Col>
        <Col xs={6} className="d-none d-md-block">
          <InfoField
            className="pt-3"
            labelText={lang.property.generalinfo_Area_of_unit}
            text={checkProperty(rentalData, 'size') && formatNumber(rentalData.size, false) + ' m²'}
            labelClassName="text-xs"
            leftTextImage={{ img: AreaIcon, style: { width: '12px', height: '12px' } }}
          />
        </Col>
        <Col xs={6} className="d-none d-md-block">
          <InfoField
            className="pt-3"
            labelText={lang.general.text_email}
            text={tenantEmail ? tenantEmail : '-'}
            labelClassName="text-xs"
            leftTextImage={{ img: EmailIcon, style: { width: '12px', height: '8px' } }}
          />
        </Col>
        <Col xs={6} className="d-none d-md-block">
          <InfoField
            className="pt-3"
            labelText={lang.general.text_phone_number}
            text={tenantPhone ? tenantPhone : '-'}
            labelClassName="text-xs"
            leftTextImage={{ img: PhoneIcon, style: { width: '11.5px', height: '11.5px' } }}
          />
        </Col>
      </Row>
    </div>
  );
}
