import React, {Component} from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import calendar from '../../../../../assets/images/module/general/CalendarIcon.svg';

export default class CustomDateInput_v2 extends Component {
    state = {
      focus: false,
    }
    render() {
      const { onClick, value ,ref} = this.props
      // console.log(value)
      return (
       
          <InputGroup className={this.state.focus && "input-group-focus"}    
          onFocus={e => this.setState({ focus: true })}
          onBlur={e => this.setState({ focus: false })}
          onClick={onClick}>
            <InputGroup.Prepend>
              <InputGroup.Text   onFocus={e => this.setState({ focus: true })}>
                <img src={calendar} alt='calender icon'/>
              </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control required type="text"
              className="date-input"
              value={value}
              placeholder="TT.MM.JJ"
              // onFocus={e => this.setState({ focus: true })}
              // onBlur={e => this.setState({ focus: false })}
              onClick={onClick}
              // onClick={props.onClick} 
              ref={ref}              
              onChange={e => e.preventDefault()}
            />
          </InputGroup>
        
      )
    }
  }
  CustomDateInput_v2.defaultProps={
      onClick:()=>{},
      value:''
  }

  