import React, { useState } from 'react';
import { Form, Row, Table } from 'react-bootstrap';
import Autoload from '../../../../Autoload';
import DatePicker, { registerLocale } from 'react-datepicker';
import de from 'date-fns/locale/de';
import { CustomDateInput, CustomDateInput_v2 } from '../../../../../neoverv/index.js';

import moment from 'moment';
const { checkProperty } = Autoload.MainHelper;
// import
var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default].meter;
var langnewKeys = Setting[Setting.language_default].newKeys;
//  const { checkEmpty } = Autoload.MainHelper;

function ReadingRecord({
  data,
  form,
  className,
  labels,
  edit,
  occDate,
  onChange,
  handleSubmit,
  validated = false,
  noValidate,
  readingDate,
  labelClassName,
  parentContext = null,
  onDateChange,
  tableType = 'interim',
  occasionDate,
  search
}) {
  console.log('dataaaaaaa in reading record', occDate);
  if (readingDate !== undefined) {
    let month = readingDate.getMonth() + 1;
    month = month.toString();
    month = month.length < 2 ? '0' + month : month;
    let date = readingDate.getDate().toString();
    date = date.length < 2 ? '0' + date : date;
    let year = readingDate.getFullYear().toString().substr(-2);
    readingDate = `${date}.${month}.${year}`;
  }

  const getTableLabels = () => {
    return labels.map((label, index) => {
      return (
        <th className={`${labelClassName[index]}`} key={index}>
          {label}
        </th>
      );
    });
  };

  const calculateConsumption = (start, current) => {
    console.log(start, current, 'current and start value');
    let startValue;
    let currentValue;
    let consumption;
    if (typeof start === 'string') {
      startValue = start;
    } else {
      startValue = Autoload.MainHelper.ConverterCurrencyDe_DisplayOnly(start + '', false);
    }
    if (typeof current === 'string') {
      currentValue = current;
    } else {
      currentValue = Autoload.MainHelper.ConverterCurrencyDe_DisplayOnly(current + '', false);
    }
    if (start === null || start === '' || current === null || current === '') {
      consumption = '-';
    } else {
      consumption = Autoload.MainHelper.ConverterCurrencyDe(
        (
          Autoload.MainHelper.ConverterLocaleNumber(
            Autoload.MainHelper.ConverterCurrencyDe(currentValue.toString() + '', false, 'de'),
            'de'
          ) -
          Autoload.MainHelper.ConverterLocaleNumber(
            Autoload.MainHelper.ConverterCurrencyDe(startValue + '', false, 'de'),
            'de'
          )
        )
          .toString()
          .replace('.', ','),
        false,
        'de'
      );
    }

    return consumption;
  };

  const getTableData = () => {
    return (
      <>
        <Table responsive className="bordered readings-form ">
          <thead className="meter-record-heading annual-meter-reacord-headings">
            <tr>{getTableLabels()}</tr>
          </thead>
          <tbody>
            {data.sort((a, b) => a.meterNumber.localeCompare(b.meterNumber)).filter((item) =>
      item.meterNumber.toUpperCase().includes(search.toUpperCase()) ||
      item.meterType.toUpperCase().includes(search.toUpperCase())).map((row, rowIndex) => {
              // row.id = row.id === '' && rowIndex;
              const showConsumtionError =
                row.startValue !== null && row.startValue !== '' && row.currentValue !== null && row.currentValue !== ''
                  ? Autoload.MainHelper.ConverterLocaleNumber(
                      calculateConsumption(row.startValue, row.currentValue),
                      'de'
                    ) < 0
                  : row.consumption < 0;
              // console.log(row.isPeriodStartReading, 'row.isPeriodStartReading');
              return (
                <tr key={row.id}>
                  <td className="number d-none d-md-table-cell">
                    {' '}
                    <Form.Control
                      className={edit && 'dis-input'}
                      type="text"
                      name="meterNumber"
                      onChange={(e) => {
                        onChange('meterNumber', e.target.value, row);
                      }}
                      required
                      // disabled={edit}
                      disabled
                      size="xs"
                      defaultValue={row.meterNumber}
                    />
                    <Form.Control.Feedback type="invalid">{lang.text_invalid_number}</Form.Control.Feedback>
                  </td>
                  <td className="type d-none d-md-table-cell">
                    <Form.Control
                      size="xs"
                      type="text"
                      className={edit && 'dis-input'}
                      // disabled={edit}
                      disabled
                      name="meterType"
                      onChange={(e) => {
                        onChange('meterType', e.target.value, row);
                      }}
                      //placeholder="Wärmemenge (MWh)"
                      required
                      defaultValue={row.meterType}
                    />
                    <Form.Control.Feedback type="invalid">{lang.text_invalid_type}</Form.Control.Feedback>
                  </td>
                  <td className="meter-location">
                    <Form.Control
                      size="xs"
                      type="text"
                      className={edit && 'dis-input'}
                      disabled
                      name="meterLocation"
                      value={row.meter.location}
                    />
                    <Form.Control.Feedback type="invalid">{lang.text_invalid_type}</Form.Control.Feedback>
                  </td>
                  <td className="date meter-date-new-format ">
                    <DatePicker
                      locale="de"
                      className={edit && 'dis-input'}
                      // formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 1)}
                      dateFormatCalendar="LLLL"
                      showMonthYearPicker={false}
                      dateFormat="dd.MM.yyyy"
                      // customInput={ (tableType == "annual")?<CustomDateInput_v2 />:<CustomDateInput />}
                      customInput={<CustomDateInput_v2 />}
                      placeholderText="DD.MM.YYYY"
                      style={{ display: 'block !important' }}
                      popperPlacement="bottom-end"
                      selected={new Date(moment(occDate, 'DD.MM.YYYY'))}
                      onChange={(e) => onDateChange('readingDate', e, row)}
                      popperClassName="popperDatepicker"
                    />
                    <Form.Control.Feedback type="invalid">{lang.text_invalid_date}</Form.Control.Feedback>
                  </td>
                  <td className="start blue-border-input">
                    {tableType === 'annual' ? (
                      row.isPeriodStartReading && row.editStartValue ? (
                        <Form.Control value={'-'} disabled style={{ pointerEvents: 'none' }} />
                      ) : (
                        /* {tableType === 'annual' ? (
                          (row.isPeriodStartReading === true && row.periodStartReading.occasion === 'move-in') ||
                            (!row.isPeriodStartReading && row.periodEndReading.occasion === 'move-in') ? (
                            <Form.Control value={'-'} disabled style={{ pointerEvents: 'none' }} />
                          ) : !row.isPeriodStartReading ? (
                            <Form.Control
                              value={checkProperty(row, 'consumption')}
                              disabled
                              style={{ pointerEvents: 'none' }}
                            />
                          ) : (
                            <>
                              <Form.Control
                                // placeholder="30,5"
                                className={edit && 'dis-input'}
                                pattern="\d*([\.,][\d])?"
                                // disabled={edit && !checkEmpty(row.startValue)}
                                // disabled={!row.editStartValue}
                                // disabled={!row.editStartValue}
                                value={checkProperty(row, 'startValue')}
                                type="number"
                                required
                                onChange={(e) => {
                                  onChange('startValue', e.target.value, row);
                                }}
                              />
                              <Form.Control.Feedback type="invalid">{lang.text_invalid_value}</Form.Control.Feedback>
                            </>
                          )
                        ) : (
                          <>
                            <Form.Control
                              // placeholder="30,5"
                              className={edit && 'dis-input'}
                              pattern="\d*([\.,][\d])?"
                              // disabled={edit && !checkEmpty(row.startValue)}
                              disabled={!row.editStartValue || (row.isPeriodStartReading && tableType === 'annual')}
                              // disabled={!row.editStartValue}
                              value={checkProperty(row, 'startValue')}
                              type="number"
                              required
                              onChange={(e) => {
                                onChange('startValue', e.target.value, row);
                              }}
                            />
                            <Form.Control.Feedback type="invalid">{lang.text_invalid_value}</Form.Control.Feedback>
                          </>
                        )} */
                        <>
                          <Form.Control
                            // placeholder="30,5"
                            className={edit && 'dis-input'}
                            pattern="\d*([\.,][\d])?"
                            // disabled={edit && !checkEmpty(row.startValue)}
                            // disabled={!row.editStartValue}
                            // disabled={!row.editStartValue}
                            value={
                              row.startValue === null
                                ? '-'
                                : typeof row.startValue === 'string'
                                ? row.startValue
                                : Autoload.MainHelper.ConverterCurrencyDe_DisplayOnly(row.startValue + '', false)
                            }
                            type="text"
                            required
                            onChange={(e) => {
                              onChange('startValue', e.target.value, row);
                            }}
                          />
                          <Form.Control.Feedback type="invalid">{lang.text_invalid_value}</Form.Control.Feedback>
                        </>
                      )
                    ) : !row.startValue && occasionDate === 'move-in' ? (
                      <Form.Control value={'-'} disabled style={{ pointerEvents: 'none' }} />
                    ) : (
                      <>
                        <Form.Control
                          // placeholder="30,5"

                          className={edit && 'dis-input'}
                          pattern="\d*([\.,][\d])?"
                          // disabled={edit && !checkEmpty(row.startValue)}
                          // disabled={!row.editStartValue} // commented it qa feedback
                          // disabled={!row.editStartValue}
                          value={
                            occasionDate === 'move-in'
                              ? '-'
                              : row.startValue === null
                              ? '-'
                              : typeof row.startValue === 'string'
                              ? row.startValue
                              : Autoload.MainHelper.ConverterCurrencyDe_DisplayOnly(row.startValue + '', false)
                          }
                          type="text"
                          required
                          onChange={(e) => {
                            onChange('startValue', e.target.value, row);                            
                          }}
                        />
                        <Form.Control.Feedback type="invalid">{lang.text_invalid_value}</Form.Control.Feedback>
                      </>
                    )}
                    {/* {
                      (row.isPeriodStartReading === true &&
                      row.periodStartReading.occasion === 'move-in') ||
                    (!row.isPeriodStartReading &&
                      row.periodEndReading.occasion === 'move-in' ) ? (
                      <Form.Control value={'-'} disabled style={{ pointerEvents: 'none' }} />
                    ) : (
                      // : !row.isPeriodStartReading ? (
                      //   <Form.Control value={checkProperty(row, 'startValue')} disabled style={{ pointerEvents: 'none' }} />
                      // )
                      
                    )} */}
                    {/* <Form.Control
                      // placeholder="30,5"
                      className={edit && 'dis-input'}
                      pattern="\d*([\.,][\d])?"
                      // disabled={edit && !checkEmpty(row.startValue)}
                      disabled={!row.editStartValue}
                      value={checkProperty(row, 'startValue')}
                      type="number"
                      required
                      onChange={(e) => {
                        onChange('startValue', e.target.value, row);
                      }}
                    />
                    <Form.Control.Feedback type="invalid">{lang.text_invalid_value}</Form.Control.Feedback> */}
                  </td>
                  <td
                    className="current blue-border-input"
                    // style={{ border: showConsumtionError === true && '2px solid red' }}
                  >
                    <Form.Control
                      className={edit && 'dis-input'} //showConsumtionError
                      onChange={(e) => {
                        onChange('currentValue', e.target.value, row);
                      }}
                      // placeholder="30,5"
                      required
                      // disabled={edit}
                      type="text"
                      value={
                        row.currentValue === null
                          ? '-'
                          : typeof row.currentValue === 'string'
                          ? row.currentValue
                          : Autoload.MainHelper.ConverterCurrencyDe_DisplayOnly(row.currentValue + '', false)
                      }
                      pattern="\d*([\.,][\d])?"
                    />
                    <Form.Control.Feedback type="invalid">{lang.text_invalid_value}</Form.Control.Feedback>
                  </td>
                  <td className="consumption" style={{ border: showConsumtionError === true && '2px solid red' }}>
                    {tableType === 'annual' ? (
                      (row.isPeriodStartReading === true && row.periodStartReading.occasion === 'move-in') ||
                      (!row.isPeriodStartReading && row.periodEndReading.occasion === 'move-in') ? (
                        <Form.Control value={'-'} disabled style={{ pointerEvents: 'none' }} />
                      ) : !row.isPeriodStartReading ? (
                        <Form.Control
                          // value={(row.startValue && row.currentValue) ?
                          // Autoload.MainHelper.ConverterCurrencyDe(
                          //   (Autoload.MainHelper.ConverterLocaleNumber(Autoload.MainHelper.ConverterCurrencyDe(row.currentValue.toString() + '', false, 'de'), 'de')
                          //     - Autoload.MainHelper.ConverterLocaleNumber(Autoload.MainHelper.ConverterCurrencyDe(row.startValue + '', false, 'de'), 'de')).toString().replace('.', ',')
                          //   , false, 'de')
                          //   : ''}
                          value={
                            row.startValue !== null && row.currentValue !== null
                              ? calculateConsumption(row.startValue, row.currentValue)
                              : '-'
                          }
                          disabled
                          style={{ pointerEvents: 'none' }}
                        />
                      ) : (
                        <>
                          <Form.Control
                            // disabled={edit}
                            disabled
                            className={edit && 'dis-input'} //showConsumtionError
                            onChange={(e) => {
                              onChange('consumption', e.target.value, row);
                            }}
                            value={
                              row.startValue !== null && row.currentValue !== null
                                ? calculateConsumption(row.startValue, row.currentValue)
                                : '-'
                            }
                            // placeholder="30,5"
                            required
                          />
                          <Form.Control.Feedback type="invalid">{lang.text_invalid_value}</Form.Control.Feedback>
                        </>
                      )
                    ) : !row.startValue && occasionDate === 'move-in' ? (
                      <Form.Control value={'-'} disabled style={{ pointerEvents: 'none' }} />
                    ) : (
                      <>
                        <Form.Control
                          // disabled={edit}
                          disabled
                          className={edit && 'dis-input'} //showConsumtionError
                          onChange={(e) => {
                            onChange('consumption', e.target.value, row);
                          }}
                          value={
                            row.startValue !== null && row.currentValue !== null
                              ? calculateConsumption(row.startValue, row.currentValue)
                              : '-'
                          }
                          // placeholder="30,5"
                          required
                        />
                        <Form.Control.Feedback type="invalid">{lang.text_invalid_value}</Form.Control.Feedback>
                      </>
                    )}
                  </td>
                  <td className="unit">
                    <Form.Control
                      disabled
                      className={edit && 'dis-input'}
                      value={checkProperty(checkProperty(row.meter, 'allocationKey'), 'units')}
                      required
                    />
                    <Form.Control.Feedback type="invalid">{lang.text_invalid_value}</Form.Control.Feedback>
                  </td>
                  <td className="note">
                    <Form.Control
                      className={edit && 'dis-input'}
                      onChange={(e) => {
                        // onChange('notes', e.target.value, row);
                        // parentContext.saveMeterNotes(row);
                        // onChange('note', e.target.value, row);
                      }}
                      defaultValue={row.notes}
                      placeholder={langnewKeys.text_Add_Note}
                      onBlur={(e) => {
                        onChange('notes', e.target.value, row);

                        if (e.target.value != row.notes) {
                          parentContext.saveMeterNotes(row, e.target.value);
                        }
                      }}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </>
    );
  };

  return tableType == 'annual' ? (
    <Row>
      <Form id={form} className="annualForm" noValidate={noValidate} validated={validated} onSubmit={handleSubmit}>
        {getTableData(tableType)}
      </Form>
    </Row>
  ) : (
    data.length > 0 && (
      <>
        <Row>
          <Form id={form} noValidate={noValidate} validated={validated} onSubmit={handleSubmit}>
            {getTableData()}
          </Form>
        </Row>
      </>
    )
  );
}

ReadingRecord.defaultProps = {
  data: {},
  labels: [],
};
export default ReadingRecord;
