import React from 'react';
import { CardTitle ,CardSubtitle,CardHeader} from 'reactstrap';

export default function HeadingContainerNew({ subheading,subheadingbadge, subheadingClassName, headingIconId, heading,sideheadings, rightContent, className,badge,headingIcon,bagdeClassName,iconHandler, headingClassName, breadcrumbs= null, breadcrumbsClassName=null, classNameRightContent }) {
  return (
    <div className={`heading-container ${className} mb-3`} style={{display: 'flex', alignItems: 'center'}}>
        <div className='heading-text-wrapper' >
            {heading &&
            <CardTitle className='heading mb-0' >
                {headingIcon && <img className='heading-icon' id={headingIconId} src={headingIcon} onClick={()=>iconHandler()} alt='heading' />}
                <span 
                className={headingClassName}
                >
                    {heading}
                </span>
                {sideheadings}
                {badge}
            </CardTitle>}
            {subheading && 
            <div>
            <span className={`${subheadingClassName} subheading`}>{subheading}</span>{subheadingbadge}
            </div>
             }
             {breadcrumbs && 
             <div style={{marginLeft: '25px', marginTop: '15px'}} className={breadcrumbsClassName}>{breadcrumbs}</div>}
            {/* <CardHeader tag="h6" className="mb-2 text-muted" style={{ alignSelf: 'flex-start'}}>{sideheadings}</CardHeader> */}
        </div>
        <div className={`heading-right-content ${classNameRightContent}`} style={{ alignSelf: 'flex-start'}}>
            {rightContent}
        </div>
  </div>
  );
}

HeadingContainerNew.defaultProps={
    heading:'No heading',  
    iconHandler:()=>{},
}