import React, { Component } from 'react';
import { SignupLayout, SignupCards, ColorButton, CustomCard } from '../../../../../neoverv';
import { Form, Badge, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import EyeIcon from '../../../../../assets/images/module/general/EyeIcon.svg';
import Info from '../../../../../assets/images/module/general/Info.svg';
import Autoload from '../../../../../neoverv/Autoload';
import GreenVerify from '../../../../../assets/images/module/general/BlueVerify.svg';
import GrayVerify from '../../../../../assets/images/module/general/GreyVerified.png';
import moment from 'moment';
import { connect } from 'react-redux';
import { setCookie, getCookie, delete_cookie } from '../../../../../neoverv/helpers/Utils';
import {
  setProfileData,
  changeCurrentProperty,
  setPropertyList,
  stepCountSignUp,
  setUserDataSignUp,
  clearUserInfoSignup,
} from '../../../../../redux/action/General/generalActions';
//import Info from '../../../../../assets/images/module/general/Info.svg';
import { Link } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';

const { checkProperty, loader } = Autoload.MainHelper;

const subscriptionList = [
  {
    name: 'BASIC_ANNUALLY',
    description: 'hello housing Zugang (jährliche Abrechnung)',
    subscriptionType: 'ACTIVE',
    price: 50.0,
    taxPercent: 19.0,
    billing: 'annually',
    cancellation: 'annually',
    trialDays: 30,
    accounts: null,
    id: '44b79623-e3f6-439b-8bb4-a56f7e32db13',
    totalSave: '10',
    createdOn: '2021-02-10T13:52:55.544',
    updatedOn: '2021-02-10T13:52:55.544',
  },
  {
    name: 'BASIC_MONTHLY',
    description: 'hello housing Zugang (monatliche Abrechung)',
    subscriptionType: 'MAIN',
    price: 5.0,
    taxPercent: 19.0,
    billing: 'monthly',
    cancellation: 'monthly',
    trialDays: 30,
    accounts: null,
    id: 'd0f5733e-7e7c-44c2-aa2e-fc2f8c4f7e30',
    totalSave: '0',
    createdOn: '2021-02-10T13:52:55.544',
    updatedOn: '2021-02-10T13:52:55.544',
  },
];

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default].general;
// var lang_meter = Setting[Setting.language_default].meter;
var lang_user = Setting[Setting.language_default].user;
var lang2 = Setting[Setting.language_default].newKeys;
export class Signup extends Component {
  state = {
    email: '',
    emailErr: false,
    steps: 1,
    subscriptionPlan: null,
    selectedSubscriptionPlan: null,
    name: '',
    fullName: '',
    firstName: '',
    firstNameErr: '',
    lastName: '',
    lastNameErr: '',
    nameErr: '',
    address: '',
    addressErr: '',
    company: '',
    companyErr: '',
    city: '',
    cityErr: '',
    zip: null,
    zipErr: '',
    password: '',
    passErr: '',
    accountId: '',
    paymentMode: 'creditcard',
    subscriptionPlans: [],
    cookieData: null,
    userInfo: null,
    checkboxErrone: '',
    checkboxErrtwo: '',
    checkboxone: false,
    checkboxtwo: false,
    upperCaseErr: false,
    lowerCaseErr: false,
    oneDigitErr: false,
    specialCharacterErr: false,
    minEightDigitErr: false,
  };

  // constructor(){

  //   if(this.state.password != ""){

  //     this.passwordValidation(this.state.password);
  //   }
  // }

  onInputChange = (field, value) => {
    console.log(field, value, 'on input chnage');
    this.setState({ [field]: value });
    if (field === 'password') {
      this.passwordValidation(value);
    }
  };

  passwordValidation = (value) => {
    this.setState({
      upperCaseErr: false,
      lowerCaseErr: false,
      oneDigitErr: false,
      specialCharacterErr: false,
      minEightDigitErr: false,
    });
    let upperCaseErr = false,
      lowerCaseErr = false,
      oneDigitErr = false,
      specialCharacterErr = false,
      minEightDigitErr = false;
    const validDigit = /(?=.*\d)/; // should contain at least one digit
    const validLowerCase = /(?=.*[a-z])/; // should contain at least one lower case
    const validUpperCase = /(?=.*[A-Z])/; // should contain at least one upper case
    const validEightChar = /^(?=.*).{8,}$/; ///[a-zA-Z0-9]{8,}/  // should contain at least 8 from the mentioned characters
    const validcharacter = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    //  debugger;
    if (validDigit.test(value)) {
      console.log(!validDigit.test(this.state.password), 'abc');
      oneDigitErr = true;
    }
    if (validLowerCase.test(value)) {
      lowerCaseErr = true;
    }
    if (validUpperCase.test(value)) {
      upperCaseErr = true;
    }

    if (validcharacter.test(value)) {
      specialCharacterErr = true;
    }
    if (validEightChar.test(value)) {
      minEightDigitErr = true;
    }
    this.setState({ oneDigitErr, lowerCaseErr, upperCaseErr, minEightDigitErr, specialCharacterErr });
  };
  toggleShowPassword = (id) => {
    var x = document.getElementById(id);
    if (x.type === 'text') {
      x.setAttribute('type', 'password');
    } else {
      x.setAttribute('type', 'text');
    }
  };

  handleEmailVerification = () => {
    this.setState({ emailErr: false });
    let emailErr = false;
    // const validEmail=/^([\wäöüÜÖÄß])+(([\.]{0,1}[\wäöüÜÖÄß])?)*(([\+]{0,1}[\wäöüÜÖÄß])?)*([\wäöüÜÖÄß-])*\@([\wäöüÜÖÄß-]+\.)+([\w]{2,})+$/;
    const validEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!this.state.email || !validEmail.test(this.state.email)) {
      emailErr = true;
    }
    if (emailErr) {
      this.setState({ emailErr });
      return false;
    } else {
      this.setState({ emailErr });
      return true;
    }
  };

  handleSubscriptionPlan = () => {
    if (!this.state.selectedSubscriptionPlan) {
      return false;
    } else {
      return true;
    }
  };

  handlePersonalDetails = () => {
    // Error on empty states with space input
    // var tb = document.getElementById('signup-company-name');
    // var checkEmptySpace = tb.value.trim().length;
    // firstName:'',
    // firstNameErr:'',
    // lastName:'',
    // lastNameErr:'',
    this.setState({
      nameErr: false,
      //  companyErr: false,
      firstNameErr: false,
      lastNameErr: false,
      addressErr: false,
      cityErr: false,
      zipErr: false,
      passErr: false,
    });
    let nameErr,
      addressErr,
      cityErr,
      zipErr,
      firstNameErr,
      lastNameErr,
      //   companyErr,
      passErr = false;
    // const validEmail=
    // //const validName = /^[a-zA-Z]+(?:[\s]+[a-zA-Z]+)*$/;
    // const validName=/[^a-zA-Z0-9äöüÄÖÜß]/;
    // const validName=/[^a-zA-Z0-9äöüÄÖÜß]/;
    const validPass = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
    // if (!this.state.name || !validName.test(this.state.name)) {
    if (!this.state.firstName) {
      firstNameErr = true;
    }
    if (!this.state.lastName) {
      lastNameErr = true;
    }

    if (!this.state.address) {
      addressErr = true;
    }
    if (!this.state.city) {
      cityErr = true;
    }
    if (!this.state.zip) {
      zipErr = true;
    }
    if (!this.state.password || !validPass.test(this.state.password)) {
      passErr = true;
    }
    // // if (!this.state.companyErr || checkEmptySpace === 0) {
    // if (!this.state.company) {
    //   companyErr = true;
    // }
    if (firstNameErr || lastNameErr || addressErr || cityErr || zipErr || passErr) {
      this.setState({ firstNameErr, lastNameErr, addressErr, cityErr, zipErr, passErr });
      return false;
    } else {
      this.setState({ firstNameErr, lastNameErr, addressErr, cityErr, zipErr, passErr });
      return true;
    }
  };

  handlePaymentDetails = () => {
    if (!this.state.paymentMode) {
      return false;
    } else {
      return true;
    }
  };

  authorizePyamentMollie = () => {
    const isValid = this.handlePaymentDetails();
    if (isValid) {
      this.postSignupAuthorizePaymentApi();
      Autoload.DataHandlerGeneral.GeneralErrorMessage(false);
    } else {
      Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang_user.text_error_payment_mode);
    }
    // window.location.reload();
    // window.location.href = '/signup/success';
  };

  componentDidMount() {
    loader(true);

    console.log('COMPONENT DID MOUNT', this.state.cookieData, this.props.userInfo);
    const dataCookie = getCookie('signup_account_details');
    dataCookie && this.setState({ cookieData: JSON.parse(dataCookie) });
    // console.log('cookieData dataCookie', dataCookie);
    // debugger;
    this.setState({
      steps: this.props.location.state === undefined ? 1 : this.props.location.state.step,
      email: checkProperty(this.props.userInfo, 'email') === '' ? this.state.email : this.props.userInfo.email,
      // name: this.props.userInfo.name === '' ? this.state.name : this.props.userInfo.name,
      firstName:
        checkProperty(this.props.userInfo, 'firstName') === '' ? this.state.firstName : this.props.userInfo.firstName,

      lastName:
        checkProperty(this.props.userInfo, 'lastName') === '' ? this.state.lastName : this.props.userInfo.lastName,
      // fullName:
      //   checkProperty(this.props.userInfo, 'fullName') === '' ? this.state.fullName : this.props.userInfo.fullName,
      address: checkProperty(this.props.userInfo, 'address') === '' ? this.state.address : this.props.userInfo.address,
      city: checkProperty(this.props.userInfo, 'city') === '' ? this.state.city : this.props.userInfo.city,
      zip: checkProperty(this.props.userInfo, 'zip') === '' ? this.state.zip : this.props.userInfo.zip,
      password:
        checkProperty(this.props.userInfo, 'password') === '' ? this.state.password : this.props.userInfo.password,
      company: checkProperty(this.props.userInfo, 'company') === '' ? this.state.company : this.props.userInfo.company,
      // selectedSubscriptionPlan:
      //   checkProperty(this.props.userInfo, 'subscriptionPlan') === ''
      //     ? this.state.subscriptionPlan
      //     : this.props.userInfo.subscriptionPlan || checkProperty(this.state.cookieData, 'subscriptionPlan'),
      paymentMode:
        checkProperty(this.props.userInfo, 'paymentMode') === ''
          ? this.state.paymentMode
          : this.props.userInfo.paymentMode,
      paymentMethod: checkProperty(dataCookie, 'paymentMethod') || this.props.userInfo.paymentMethod,
      selectedSubscriptionPlan:
        checkProperty(this.props.userInfo, 'selectedSubscriptionPlan') === ''
          ? this.state.selectedSubscriptionPlan
          : this.props.userInfo.selectedSubscriptionPlan,
      userInfo: this.props.userInfo,
    });
    // console.log('this.pop', this.props);
    // if (this.props.verificationStatus === 'failed') {
    //   this.setState({
    //     steps: 5,
    // //   });
    //   this.props.stepCountSignUp(this.state.steps);
    // }
    // this.getSubscription();
    const sortedList = subscriptionList.sort((a, b) => a.price - b.price);
    this.setState({ subscriptionPlans: sortedList });
    const cookieData = getCookie('signup_account_details');
    cookieData && this.setState({ cookieData: JSON.parse(cookieData) });
    console.log('cookieData', cookieData);
    console.log('cookieData json', cookieData && JSON.parse(cookieData));
    this.state.steps === 1 && loader(false);
  }

  getSubscription() {
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.subscription_api_baseurl + 'Subscriptions',
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      '_handleSubscriptionsList',
      null,
      false,
      '',
      { 'Content-Type': 'application/json' }
    );
  }

  _handleSubscriptionsList(response) {
    console.log('response', response);
    if (response.status === true && response.statuscode === 401) {
      localStorage.clear();
      window.location.reload();
    }

    if (response.status === true && response.statuscode === 200) {
      Autoload.DataHandlerGeneral.GeneralErrorMessage(false);
      const planList = response.result.filter((item) => item.price !== 0);
      const sortedList = planList.sort((a, b) => a.price - b.price);
      this.setState({ subscriptionPlans: sortedList });
    } else {
      Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang_user.text_error_subscriptions_list);
    }
  }

  moveForward = (step) => {
    console.log('move forward', step);

    const {
      // name,
      // fullName,
      email,
      password,
      address,
      city,
      zip,
      // paymentMode,
      subscriptionPlan,
      company,
      accountId,
      selectedSubscriptionPlan,
      lastName,
      firstName,
    } = this.state;
    var userDataSignup = {};
    let isValid = false;
    switch (step) {
      case 1:
        isValid = this.handleEmailVerification();
        userDataSignup = { email };
        isValid && this.postSigninApi(userDataSignup);

        break;
      case 2:
        isValid = this.handlePersonalDetails();
        let fullName = this.state.firstName + ' ' + this.state.lastName;
        userDataSignup = { accountId, firstName, lastName, fullName, password, address, city, zip, company };
        console.log('setp 2 api', this.state.steps, userDataSignup);
        isValid && this.postSignupAccountApi(userDataSignup);

        break;
      case 3:
        isValid = this.handleSubscriptionPlan();
        isValid === false
          ? Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang_user.text_error_subscription)
          : Autoload.DataHandlerGeneral.GeneralErrorMessage(false);
        userDataSignup = { subscriptionPlan, selectedSubscriptionPlan };
        console.log('subscriptionPlan', selectedSubscriptionPlan, isValid);
        isValid && this.postSignupSubscriptionApi();

        break;
      case 4:
        // isValid = this.handlePaymentDetails();
        // userDataSignup = { paymentMode };
        // isValid && this.postSignupAuthorizePaymentApi();
        break;
      case 6:
        var btn_loader = document.getElementById('c-btn-loader');
        btn_loader.style.display = 'block';
        isValid = this.checkForm();
        isValid === false
          ? Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang_user.text_accept_terms_and_condition)
          : Autoload.DataHandlerGeneral.GeneralErrorMessage(false);
        isValid && this.postSignupActivateAccountApi();

        btn_loader.style.display = 'none';

        break;
      default:
        break;
    }
    if (isValid) {
      // this.setState({ steps: this.state.steps + 1 });
      // this.props.stepCountSignUp(this.state.steps + 1);
      // this.props.setUserDataSignUp(userDataSignup);
    }
  };

  checkForm = () => {
    // console.log('hello')
    this.setState({
      checkboxErrone: false,
      checkboxErrtwo: false,
    });
    let { checkboxErrone, checkboxErrtwo } = false;
    if (this.state.checkboxone === false) {
      checkboxErrone = true;
    }
    if (this.state.checkboxtwo === false) {
      checkboxErrtwo = true;
    }
    if (checkboxErrone || checkboxErrtwo) {
      this.setState({ checkboxErrone, checkboxErrtwo });
      // console.log(checkboxErrone,checkboxErrtwo,'abc ')
      return false;
    } else {
      this.setState({ checkboxErrone, checkboxErrtwo });
      // console.log(checkboxErrone,checkboxErrtwo,'abc ')
      return true;
    }
  };

  moveBack = () => {
    if (this.state.steps === 1) {
      window.location.href = '/login';
      return;
    }
    if (this.state.steps === 6) {
      Autoload.Redirection.redirection('/signup/verification/success');
    }
    // if (this.state.cookieData) {
    //   return;
    // }
    this.setState({ steps: this.state.steps - 1 });
    this.props.stepCountSignUp(this.state.steps - 1);
  };
  // renderTooltip = (props) => (
  //   <Tooltip id="button-tooltip" {...props}>
  //     <div style={{ textAlign: 'left', fontSize: '10px' }}>
  //       <div>Minimum 8 characters</div>
  //       <div>Atleast 1 numerical character</div>
  //       <div>Atleast 1 special character eg. , ! @ # ? ]</div>
  //       <div>Atleast 1 upper and lowercase character</div>
  //     </div>
  //   </Tooltip>
  // );

  postSigninApi = (userData) => {
    console.log('singup', userData);
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'block';

    var btn_loader = document.getElementById('c-btn-loader');
    btn_loader.style.display = 'block';

    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.sign_in_bas_url + 'SignUp/Account',
      // 'http://20.79.249.250:8090/auth-api/SignUp/Account',
      'POST',
      userData,
      null,
      false,
      true,
      false,
      this,
      'handle_post_signin_response',
      userData,
      false,
      '',
      { 'Content-Type': 'application/json' }
    );
  };

  handle_post_signin_response(response) {
    console.log(response, 'handle_post_signin_response screen 01');
    if (response.status === true && response.statuscode === 401) {
      localStorage.clear();
      window.location.reload();
    }

    if (response.status === true && response.statuscode === 200) {
      const email = Autoload.MainHelper.checkProperty(response.other, 'email');
      const accountId = Autoload.MainHelper.checkProperty(response.result, 'accountId');
      this.setState({ email, accountId, steps: this.state.steps + 1 });
      this.props.stepCountSignUp(this.state.steps + 1);
      this.props.setUserDataSignUp({ email, accountId });
      Autoload.DataHandlerGeneral.GeneralErrorMessage(false);
    } else {
      Autoload.DataHandlerGeneral.GeneralErrorMessage(true, 'Jetzt mit Ihrem Konto anmelden.');
      this.setState({ emailErr: true });
    }
    this.hideLoader();

    var btn_loader = document.getElementById('c-btn-loader');
    btn_loader.style.display = 'none';
  }

  postSignupAccountApi = (userData) => {
    var btn_loader = document.getElementById('c-btn-loader');
    btn_loader.style.display = 'block';

    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'block';
    const payload = {
      ...userData,
      fullName: userData.fullName,
      zipCode: userData.zip,
    };
    console.log(payload, 'payload postSignupAccountApi');
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.sign_in_bas_url + 'SignUp/AccountDetails',
      'PUT',
      payload,
      null,
      false,
      true,
      false,
      this,
      'handle_post_signup_account_response',
      { payload },
      false,
      '',
      { 'Content-Type': 'application/json' }
    );
  };

  handle_post_signup_account_response(response) {
    console.log(response, 'handle_post_signin_account_response screen 02');
    if (response.status === true && response.statuscode === 401) {
      localStorage.clear();
      window.location.reload();
    }

    if (response.status === true && response.statuscode === 200) {
      this.setState({ steps: this.state.steps + 1 });
      console.log('this.stesp', this.state.steps, this.state.steps + 1);
      console.log('other payload', response.other.payload);
      this.props.stepCountSignUp(this.state.steps + 1);
      this.props.setUserDataSignUp({ ...response.other.payload });
      Autoload.DataHandlerGeneral.GeneralErrorMessage(false);
    } else {
      Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang2.text_invalid_data);
    }
    this.hideLoader();

    var btn_loader = document.getElementById('c-btn-loader');
    btn_loader.style.display = 'none';
  }

  postSignupSubscriptionApi = () => {
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'block';

    var btn_loader = document.getElementById('c-btn-loader');
    btn_loader.style.display = 'block';

    const selectedSubscriptionItem = this.state.subscriptionPlans.find(
      (item) => item.id === this.state.selectedSubscriptionPlan
    );
    console.log('selectedSubscriptionItem', selectedSubscriptionItem);
    const subscriptionPlanType = selectedSubscriptionItem.subscriptionType;
    const payload = {
      accountId: this.state.accountId,
      subscriptionPlanType,
      ...selectedSubscriptionItem,
    };
    payload.subscriptionType = this.state.selectedSubscriptionPlan;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.sign_in_bas_url + 'SignUp/Subscription',
      'PUT',
      payload,
      null,
      false,
      true,
      false,
      this,
      'handle_post_signup_subscription_response',
      { payload },
      false,
      '',
      { 'Content-Type': 'application/json' }
    );
  };

  handle_post_signup_subscription_response(response) {
    console.log(response, 'handle_post_signin_subscription_response screen 03');
    this.hideLoader();
    var btn_loader = document.getElementById('c-btn-loader');
    btn_loader.style.display = 'none';

    if (response.status === true && response.statuscode === 401) {
      localStorage.clear();
      window.location.reload();
    }

    if (response.status === true && response.statuscode === 200) {
      this.setState({ steps: this.state.steps + 1 });
      this.props.stepCountSignUp(this.state.steps + 1);
      this.props.setUserDataSignUp({ ...response.other.payload });
    } else {
      Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang_user.text_error_subscription);
    }
  }

  hideLoader = () => {
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'none';
  };

  postSignupAuthorizePaymentApi = () => {
    const { paymentMode, accountId, userInfo } = this.state;
    const accountid = accountId && accountId !== '' ? accountId : userInfo.accountId;
    const payload = {
      accountId: accountid,
      paymentMethod: paymentMode,
    };
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.sign_in_bas_url + 'SignUp/AuthorizePayment',
      'PUT',
      payload,
      null,
      false,
      true,
      false,
      this,
      'handle_post_signup_AuthorizePayment_response',
      { payload },
      false,
      '',
      { 'Content-Type': 'application/json' }
    );
  };

  handle_post_signup_AuthorizePayment_response(response) {
    console.log(response, 'handle_post_signin_AuthorizePayment_response');
    if (response.status === true && response.statuscode === 401) {
      localStorage.clear();
      window.location.reload();
    }

    if (response.status === true && response.statuscode === 200) {
      const cookieData = { ...this.props.userInfo, ...response.other.payload };
      setCookie('signup_account_details', JSON.stringify(cookieData));
      // window.open('/signup/verification/success', '_blank');
      // window.open(response.result, '_blank');
      this.props.setUserDataSignUp({ ...response.other.payload });
      // debugger;
      window.location.href = response.result;
    } else {
      Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang_user.text_error_authorize_payment);
      // window.open('/signup/verification/failed', '_blank');
    }
  }

  postSignupActivateAccountApi() {
    loader(true)
    const accountId = this.state.cookieData.accountId;

    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.sign_in_bas_url + 'SignUp/ActivateAccount',
      'PUT',
      { accountId },
      null,
      false,
      true,
      false,
      this,
      'handle_post_signup_ActivateAccount_response',
      null,
      false,
      '',
      { 'Content-Type': 'application/json' }
    );
  }

  handle_post_signup_ActivateAccount_response(response) {
    console.log('response', response);
    if (response.status === true && response.statuscode === 401) {
      localStorage.clear();
      window.location.reload();
    }

    if (response.status === true && response.statuscode === 200) {
      this.setState({ steps: 7 });
      this.props.stepCountSignUp(7);
      loader(false)
      // this.props.setUserDataSignUp({ ...response.other.payload });
    } else {
      Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang_user.text_error_account_activate);
    }
    loader(false)
  }

  goToLogin() {
    loader(true);
    this.setState({ steps: null });
    Autoload.Utils.clear_session_data();
    this.props.clearUserInfoSignup();
    delete_cookie('signup_account_details');
    window.location.href = '/login';
    loader(false);
    // this.props.history.push('/login');
  }

  renderPlanName(planName) {
    switch (planName) {
      case 'FREE_ACCESS':
        return '';
      case 'BASIC_ANNUALLY':
        return 'year';
      case 'BASIC_MONTHLY':
        return 'month';
      default:
        break;
    }
  }

  renderNextWithdrawDate() {
    // const billingInfo = checkProperty(this.props.userInfo, 'billing');
    // if (billingInfo && billingInfo === 'annually') {
    //   return moment(new Date()).add(1, 'year').format('DD.MM.YYYY');
    // } else if (billingInfo && billingInfo === 'monthly') {
    //   return moment(new Date()).add(1, 'month').format('DD.MM.YYYY');
    // }
    // firstPaymentDueDate
    const testEnd = moment(checkProperty(this.props.userInfo, 'firstPaymentDueDate')).format('DD.MM.YYYY');
    // console.log('testPeriodEnd', testEnd);
    return testEnd;
  }

  renderPeriodEndDate() {
    // console.log('testPeriodEnd');
    const testEnd = moment(checkProperty(this.props.userInfo, 'testPeriodEnd')).format('DD.MM.YYYY');
    // console.log('testPeriodEnd', testEnd);
    return testEnd;
  }

  // renderTooltip = (props) => (
  //   <Tooltip id="button-tooltip" {...props}>
  //     At least 8 characters—the more characters, the better. A mixture of both uppercase and lowercase letters. A
  //     mixture of letters and numbers. Inclusion of at least one special character, e.g., ! @ # ? ]
  //   </Tooltip>
  // );
  handleEnterKeyPress = (e) => {
    if (e.charCode == 13) {
      e.preventDefault();
      // this.setState({ steps: this.state.steps + 1 });
      this.moveForward(this.state.steps);
    }
  };
  toggleclass() {
    var element = document.getElementById('radio-btn-cards');
    if (element !== null) {
      element.classList.toggle('checked');
      // var Id = $(this).find('input[type=radio]').attr('id');
      // document.getElementById(Id).click();
    }
  }

  render() {
    // console.log(this.props.verificationStatus, 'update in signup');
    const {
      subscriptionPlans,
      selectedSubscriptionPlan,
      steps,
      cookieData,
      userInfo,
      oneDigitErr,
      lowerCaseErr,
      upperCaseErr,
      minEightDigitErr,
      specialCharacterErr,
    } = this.state;
    console.log('steps', this.state.steps, cookieData);
    return (
      <div>
        <div className="hh_loader" id="hello-housing-loader" style={{ display: 'none' }}>
          <div className="hh_loader_bg"></div>
          <Spinner animation="border" className="hh_loader_spinner" variant="primary" />
        </div>
        <div>
          <SignupLayout handleNext={this.moveForward} handleBack={this.moveBack} steps={this.state.steps}>
            {/* step 1 content */}
            <div className="steps-content" style={{ display: this.state.steps === 1 ? 'block' : 'none' }}>
              <SignupCards
                steps={this.state.steps}
                heading={`${lang.text_welcome}`}
                subheading={lang.text_start_with_email}
                className="welcome-step"
              >
                <Form.Group>
                  <Form.Label>{lang.text_email}</Form.Label>
                  <Form.Control
                    id="signup-email"
                    name="signup-email"
                    className="custom-field"
                    isInvalid={this.state.emailErr}
                    type="email"
                    // value={this.state.email || this.props.userInfo.email}
                    value={this.state.email}
                    onChange={(e) => {
                      this.onInputChange('email', e.target.value);
                    }}
                    onKeyPress={this.handleEnterKeyPress}
                  />
                  <Form.Control.Feedback className="err" type="invalid">
                    {' '}
                    {/* {lang.text_invalid_email} */}
                    <br />
                    {lang2.text_email_already_taken}
                    {/* Für diese E-Mail Adresse wurde bereits ein Konto vergeben. Bitte loggen Sie sich ein. */}
                  </Form.Control.Feedback>
                </Form.Group>
              </SignupCards>
            </div>

            {/* step 2 content */}
            <div className="steps-content" style={{ display: this.state.steps === 3 ? 'block' : 'none' }}>
              <SignupCards
                heading={lang.text_subscription_plan}
                subheading={lang.text_subscription_info}
                steps={this.state.steps}
                className="subscription-step"
              >
                <div
                  id="radio-btn-cards"
                // onClick={this.toggleclass()}
                // onChange={(e) => this.onInputChange('selectedSubscriptionPlan', e.target.value)}
                >
                  {subscriptionPlans.length > 0
                    ? subscriptionPlans.map((subPlan) => {
                      return (
                        <div
                          onClick={(e) => this.onInputChange('selectedSubscriptionPlan', subPlan.id)}
                          key={subPlan.id}
                          // value={subPlan.id}
                          className={`subscription-options ${selectedSubscriptionPlan === subPlan.id ? `checked` : ``
                            } `}
                        >
                          <div className={`form-check subscription-card`}>
                            <input
                              name="subscriptionPlan"
                              className={`form-check-input`}
                              id={subPlan.id}
                              type="radio"
                              value={subPlan.id}
                              onKeyPress={this.handleEnterKeyPress}
                            //onChange={(e) => this.onInputChange('selectedSubscriptionPlan', e.target.value)}
                            />
                            {/* <label className="subs-label form-check-label" for={subPlan.id}> */}
                            <label className="subs-label form-check-label">
                              <div>
                                <p>
                                  <span>{subPlan.price}€</span>
                                  <span>
                                    {/* {lang.text_per}
                                     {this.renderPlanName(subPlan.name)} */}
                                    {subPlan.billing === 'annually' ? lang.text_per_year : lang.text_per_month}
                                  </span>
                                  {subPlan.billing === 'annually' && (
                                    <Badge variant="info" className="discount-badge">
                                      {/* {console.log(subPlan.totalSave, 'subPlan.totalSave')} */}
                                      {subPlan.totalSave ? subPlan.totalSave : 0}€ {lang.text_save_anually}{' '}
                                      {lang2.text_save_money_10}
                                    </Badge>
                                  )}
                                </p>
                                <p>
                                  {/* {this.renderPlanName(subPlan.name)} */}
                                  {/* {lang.text_billed}{' '} */}
                                  {subPlan.billing === 'annually' ? (
                                    <span>
                                      Jährliche Abrechnung<sup>2</sup>
                                    </span>
                                  ) : (
                                    <span>
                                      Monatliche Abrechnung<sup>1</sup>
                                    </span>
                                  )}
                                </p>
                              </div>
                            </label>
                          </div>
                        </div>
                      );
                    })
                    : null}
                </div>
                <div style={{ position: 'absolute', bottom: '90px' }}>
                  <div className="text_subscription">{lang2.text_subscription_plan_one}</div>
                  <div className="text_subscription">{lang2.text_subscription_plan_two}</div>
                </div>
              </SignupCards>
            </div>

            {/* step 3 content */}
            <div className="steps-content " style={{ display: this.state.steps === 2 ? 'block' : 'none' }}>
              <SignupCards
                steps={this.state.steps}
                heading={lang.text_personal_details}
                subheading={lang.text_personal_details_info}
                className="personal-step"
              >
                <Row>
                  <Col xs={6}>
                    <Form.Group>
                      <Form.Label>{lang2.text_first_name}</Form.Label>
                      <Form.Control
                        id="signup-full-name"
                        name="signup-full-name"
                        isInvalid={this.state.firstNameErr}
                        type="text"
                        // value={this.state.name}
                        value={this.state.firstName}
                        onKeyPress={this.handleEnterKeyPress}
                        onChange={(e) => {
                          this.onInputChange('firstName', e.target.value);
                        }}
                      />
                      <Form.Control.Feedback className="err" type="invalid">
                        {' '}
                        {lang2.text_signup_first_name}{' '}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col xs={6}>
                    <Form.Group>
                      <Form.Label>{lang2.text_last_name}</Form.Label>
                      <Form.Control
                        id="signup-full-name"
                        name="signup-full-name"
                        isInvalid={this.state.lastNameErr}
                        type="text"
                        // value={this.state.name}
                        value={this.state.lastName}
                        onKeyPress={this.handleEnterKeyPress}
                        onChange={(e) => {
                          this.onInputChange('lastName', e.target.value);
                        }}
                      />
                      <Form.Control.Feedback className="err" type="invalid">
                        {' '}
                        {lang2.text_signup_last_name}{' '}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <Form.Group>
                      <Form.Label>{lang.text_company_optional}</Form.Label>
                      <Form.Control
                        id="signup-company-name"
                        name="signup-company-name"
                        //  isInvalid={this.state.companyErr}
                        type="text"
                        value={this.state.company}
                        onKeyPress={this.handleEnterKeyPress}
                        onChange={(e) => {
                          this.onInputChange('company', e.target.value);
                        }}
                      />
                      {/* <Form.Control.Feedback className="err" type="invalid">
                        {' '}
                        {lang.text_invalid_company}{' '}
                      </Form.Control.Feedback> */}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <Form.Group>
                      <Form.Label> {lang.text_address} </Form.Label>
                      <Form.Control
                        id="signup-address"
                        name="signup-address"
                        isInvalid={this.state.addressErr}
                        onKeyPress={this.handleEnterKeyPress}
                        type="text"
                        value={this.state.address}
                        onChange={(e) => {
                          this.onInputChange('address', e.target.value);
                        }}
                      />
                      <Form.Control.Feedback className="err" type="invalid">
                        {' '}
                        {lang.text_invalid_address}{' '}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={9}>
                    <Form.Group>
                      <Form.Label> {lang.text_city} </Form.Label>
                      <Form.Control
                        id="signup-city"
                        name="signup-city"
                        isInvalid={this.state.cityErr}
                        type="text"
                        value={this.state.city == '' || this.state.city == null ? '' : this.state.city}
                        onKeyPress={this.handleEnterKeyPress}
                        onChange={(e) => {
                          this.onInputChange('city', e.target.value);
                        }}
                      />
                      <Form.Control.Feedback className="err" type="invalid">
                        {lang.text_invalid_city}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col xs={3}>
                    <Form.Group>
                      <Form.Label> {lang.text_zip_code} </Form.Label>
                      <Form.Control
                        className="signup-zip-code"
                        id="signup-zip"
                        name="signup-zip"
                        isInvalid={this.state.zipErr}
                        type="number"
                        value={this.state.zip == '' || this.state.zip == null ? '' : this.state.zip}
                        onKeyPress={this.handleEnterKeyPress}
                        onChange={(e) => {
                          this.onInputChange('zip', e.target.value);
                        }}
                      />
                      <Form.Control.Feedback className="err" type="invalid">
                        {lang.text_invalid_zip_code}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <Form.Group>
                      <Form.Label style={{ width: '99.9%' }}>
                        {' '}
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <div> {lang.text_password} </div>
                          {/* <div>
                            <OverlayTrigger
                              placement="top"
                              delay={{ show: 450, hide: 400 }}
                              overlay={this.renderTooltip}
                            >
                              <img src={Info} alt="info" width="16px" height="16px" title="some help text" />
                            </OverlayTrigger>
                          </div> */}
                        </div>
                      </Form.Label>
                      <div className="password-field" style={{ position: 'relative', top: 0, left: 0 }}>
                        <Form.Control
                          id="signup-password"
                          name="signup-password"
                          isInvalid={this.state.passErr}
                          type="password"
                          value={this.state.password}
                          onKeyPress={this.handleEnterKeyPress}
                          onChange={(e) => {
                            this.onInputChange('password', e.target.value);
                          }}
                        />
                        <img src={EyeIcon} onClick={() => this.toggleShowPassword('signup-password')} alt="eye-icon" />
                        <Form.Control.Feedback className="err" type="invalid">
                          {lang.text_invalid_password}
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                    <p className="pass-instr">{lang2.text_Password_must}</p>
                    <div className="form-info password-instructions">
                      <p>
                        {' '}
                        <img src={minEightDigitErr === false ? GrayVerify : GreenVerify} alt="icon" className="mr-2" />
                        {lang2.text_Password_must_condition_one}
                      </p>
                      <p>
                        {' '}
                        <img src={lowerCaseErr === false ? GrayVerify : GreenVerify} alt="icon" className="mr-2" />
                        {lang2.text_Password_must_condition_two}
                      </p>
                      <p>
                        {' '}
                        <img src={upperCaseErr === false ? GrayVerify : GreenVerify} alt="icon" className="mr-2" />
                        {lang2.text_Password_must_condition_three}
                      </p>
                      <p>
                        {' '}
                        <img src={oneDigitErr === false ? GrayVerify : GreenVerify} alt="icon" className="mr-2" />
                        {lang2.text_Password_must_condition_four}
                      </p>
                      <p>
                        {' '}
                        <img
                          src={specialCharacterErr === false ? GrayVerify : GreenVerify}
                          alt="icon"
                          className="mr-2"
                        />
                        {lang2.text_Password_must_condition_five}
                      </p>
                    </div>
                  </Col>
                </Row>
              </SignupCards>
            </div>

            {/* step 4 content */}
            <div
              className="steps-content payment-status"
              style={{ display: this.state.steps === 4 ? 'block' : 'none' }}
            >
              <SignupCards
                steps={this.state.steps}
                heading={lang.text_payment_details}
                subheading={lang_user.text_payment_provider_mollie}
                className="payment-step"
              >
                <div
                  onClick={() => this.onInputChange('paymentMode', 'creditcard')}
                  className={`paymentMode-options ${this.state.paymentMode === 'creditcard' ? `checked` : ``} `}
                >
                  <div className={`form-check paymentMode-card`}>
                    <input
                      name="paymentMode"
                      className={`form-check-input`}
                      id="CreditCard1"
                      type="radio"
                      value={'creditcard'}
                      onKeyPress={this.handleEnterKeyPress}
                      onChange={(e) => this.onInputChange('paymentMode', e.target.value)}
                    />
                    <label className="subs-label form-check-label">
                      <div className="credit-card-badge-box">
                        <p>{lang_user.text_credit_card}</p>
                        {/* <span>
                          <Badge className="creditcard-discount-badge">demnächst verfügbar</Badge>
                        </span> */}
                      </div>
                    </label>
                  </div>
                </div>
                {/* SEPA DISABLED USING CSS PROPERTY POINTEREVENTS : NONE */}
                <div
                  // style={{pointerEvents:'none'}}
                  onClick={() => this.onInputChange('paymentMode', 'giropay')}
                  className={`paymentMode-options ${this.state.paymentMode === 'giropay' ? `checked` : ``} `}
                >
                  <div className={`form-check paymentMode-card`}>
                    <input
                      name="paymentMode"
                      className={`form-check-input`}
                      id="SEPA1"
                      type="radio"
                      value={'giropay'}
                      onKeyPress={this.handleEnterKeyPress}
                      onChange={(e) => this.onInputChange('paymentMode', e.target.value)}
                    />
                    <label className="subs-label form-check-label">
                      {/* <div>
                        <p>{lang2.text_SEPA}</p>
                      </div> */}
                      <div className="credit-card-badge-box">
                        <p>{lang2.text_SEPA}</p>
                        {/* <span>
                          <Badge className="creditcard-discount-badge">bald verfügbar</Badge>
                        </span> */}
                      </div>
                    </label>
                  </div>
                </div>

                <ColorButton
                  onClick={(e) => {
                    e.preventDefault();
                    this.authorizePyamentMollie();
                  }}
                  // buttonConfig={{ type: 'submit' }}
                  className="purple-btn"
                  text={lang2.btn_text_authorize_payment}
                />
              </SignupCards>
            </div>

            {/* step 5 content */}
            {/* {this.renderAccountSummary()} */}
            <div className="steps-content  " style={{ display: steps === 6 ? 'block' : 'none' }}>
              <SignupCards
                steps={steps}
                heading={lang.text_summary}
                subheading={lang.text_summary_info}
                className="summary-step"
              >
                <div>
                  <div className="info-box">
                    <div className="info-heading">
                      <h5> {lang.text_subscription_plan} </h5>
                    </div>
                    <div className="form-info">
                      <p>
                        {lang2.text_subscription_one} <b>{this.renderPeriodEndDate()}</b>.
                      </p>
                      {/* you are testing hellohousing one month for free until */}
                      <br />
                      <p>
                        {lang2.text_subscription_two} <b>{this.renderNextWithdrawDate()}</b>{' '}
                        {lang_user.text_you_will_use}{' '}
                        <b>
                          {' '}
                          {userInfo &&
                            checkProperty(userInfo.subscriptionType, 'billing')
                              .replace('monthly', lang2.text_signup_monthly)
                              .replace('annually', lang2.text_signup_yearly)}
                        </b>{' '}
                        {lang_user.text_plan_cancellation}
                        {/* Starting {this.renderNextWithdrawDate()} , you will use hellohousing using 
                        the {userInfo && checkProperty(userInfo.subscriptionType, 'billing')} plan with monthly cancellation. */}
                      </p>
                      <br />
                      <p>
                        {lang2.text_subscription_three} <b>{checkProperty(cookieData, 'price')}€</b>{' '}
                        {lang2.text_subscription_four}{' '}
                        <b>
                          {userInfo &&
                            checkProperty(userInfo.subscriptionType, 'billing')
                              .replace('monthly', lang2.text_signup_monthly_basic)
                              .replace('annually', lang2.text_signup_yearly_basic)}
                        </b>{' '}
                        {lang2.text_subscription_five}
                        {/* you will be billed price on a monthly basis  */}
                      </p>
                      <br />
                      <p>
                        {lang2.text_first_payment_withdrawns}
                        {/* {userInfo && userInfo.paymentMethod}  */}
                        {/* {' '}{this.state.paymentMode === 'creditcard' ? lang_user.text_credit_card : lang2.text_SEPA} */}{' '}
                        <b>
                          {' '}
                          {
                            // userInfo && userInfo.paymentMode
                            checkProperty(cookieData, 'paymentMethod') === 'creditcard'
                              ? lang_user.text_credit_card
                              : lang2.text_SEPA
                          }{' '}
                        </b>
                        {lang2.text_subscription_at} <b>{this.renderNextWithdrawDate()} </b> beglichen.
                        {/* Your first payment will be posted on 'card' at 'date' */}
                        {/* {Ihre erste Zahlung wird per [payment option] am [payment due date] beglichen.”} */}
                      </p>
                    </div>
                  </div>

                  <div className="info-box">
                    <div className="info-heading">
                      <h5> {lang.text_personal_details} </h5>
                    </div>
                    <div className="form-info">
                      {/* <p>{this.state.name}</p> */}
                      <p>{checkProperty(cookieData, 'fullName')}</p>
                      <p>{checkProperty(cookieData, 'email')}</p>
                      <p>{checkProperty(cookieData, 'address')}</p>
                      <p>
                        {' '}
                        {checkProperty(cookieData, 'zip')} {checkProperty(cookieData, 'city')}{' '}
                      </p>
                    </div>
                  </div>

                  <div className="info-box">
                    <div className="info-heading">
                      <h5> {lang_user.text_terms_and_condition} </h5>
                    </div>
                    <div className="form-info">
                      {/* <p>{checkProperty(cookieData, 'fullName')}</p>
                      <div className="center-spacing">
                        <p>**** **** **** ****</p>
                        <p>
                          {this.state.expireDate} . {this.state.cvv}
                          **.** . ***
                        </p>
                      </div> */}
                      {/* <Form> */}
                      <div className="checkbox-div">
                        <Form.Group controlId="formBasicCheckboxOne" className="form-check-box">
                          <Form.Check
                            type="checkbox"
                            required
                            value={this.state.checkboxone}
                            isInvalid={this.state.checkboxErrone}
                            onChange={() => this.setState({ checkboxone: !this.state.checkboxone })}
                          />
                          <label className="text-label-inbox">
                            Hiermit bestätige ich die{' '}
                            <span className="underline-text" style={{ cursor: 'pointer' }}>
                              <a target="_blank" href="https://hellohousing.de/agb">
                                AGB
                              </a>
                            </span>{' '}
                            gelesen zu haben und damit einverstanden zu sein.
                          </label>
                        </Form.Group>
                        {this.state.checkboxErrone ? (
                          <p className="err checkbox-err"> {lang2.text_error_msg_checkbox} </p>
                        ) : (
                          ''
                        )}
                        <Form.Group controlId="formBasicCheckboxTwo" className="form-check-box">
                          <Form.Check
                            type="checkbox"
                            required
                            value={this.state.checkboxtwo}
                            isInvalid={this.state.checkboxErrtwo}
                            onChange={() => this.setState({ checkboxtwo: !this.state.checkboxtwo })}
                          />
                          {/* <Form.Check type="checkbox" required /> */}
                          <label className="text-label-inbox">
                            Ich habe die{' '}
                            <span className="underline-text" style={{ cursor: 'pointer' }}>
                              <a target="_blank" href="https://hellohousing.de/datenschutz">
                                Datenschutzerklärung{' '}
                              </a>
                            </span>
                            und die{' '}
                            <span className="underline-text" style={{ cursor: 'pointer' }}>
                              <a target="_blank" href="https://hellohousing.de/avv">
                                Hinweise zur Verarbeitung von Daten Dritter{' '}
                              </a>
                            </span>
                            gelesen und erkläre mich damit einverstanden.
                          </label>
                          <Form.Control.Feedback className="err" type="invalid">
                            {lang2.text_error_msg_checkbox_2}
                          </Form.Control.Feedback>
                        </Form.Group>
                        {this.state.checkboxErrtwo ? (
                          <p className="err checkbox-err">{lang2.text_error_msg_checkbox_2} </p>
                        ) : (
                          ''
                        )}
                      </div>
                      {/* </Form> Bitte stimmen Sie der Datenschutzerklärung und den Bestimmungen zur Datenverarbeitung zu. */}
                    </div>
                  </div>
                </div>
              </SignupCards>
            </div>

            {this.state.steps === 7 && (
              <div className="steps-content signup-successful ">
                <CustomCard className="signup-success-head" heading={lang_user.text_signup_successful}>
                  <div className="center-spacing">
                    <p className="signup-success-text">{lang_user.text_login_now}</p>
                    <ColorButton
                      onClick={() => this.goToLogin()}
                      className="purple-btn signup-login-btn"
                      text={lang_user.text_go_to_login}
                    />
                    {/* <Link to="/login">{lang_user.text_go_to_login}</Link> */}
                  </div>
                </CustomCard>
              </div>
            )}
          </SignupLayout>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    steps: state.generalReducer.step,
    verificationStatus: state.generalReducer.verificationStatus,
    userInfo: state.generalReducer.userDataSignup,
  };
};
const actions = {
  setProfileData,
  changeCurrentProperty,
  setPropertyList,
  stepCountSignUp,
  setUserDataSignUp,
  clearUserInfoSignup,
};
export default connect(mapStateToProps, actions)(Signup);
