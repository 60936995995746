import React, { Component } from 'react';
import Autoload from '../../../../neoverv/Autoload';
import { connect } from 'react-redux';
import { showNotification } from '@mantine/notifications';
import CopyIcon from '../../../../neoverv/components/SVGs/copyIcon';
import GreenTick from '../../../../assets/images/module/general/GreenTick.svg';
import PlusIcon from '../../../../assets/images/module/general/PlusIcon.svg';
import RedTick from '../../../../assets/images/module/general/RedTick.svg';
import RightIcon from '../../../../assets/images/module/general/RightIcon.svg';
import { Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TableSortLabel } from '@material-ui/core';
import dayjs from 'dayjs';
import TablePaginationActions from '@material-ui/core/TablePagination/TablePaginationActions';
import { MeterModal } from '../../../../neoverv';
//import TablePaginationActions from './TablePaginationActions';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
const pStyle= {
    fontFamily: 'Inter, sans-serif',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '16px',
    color: 'rgb(14, 14, 29)'
}
const inputFieldStyle = {
  outline: 'none',
  lineHeight: '1.5em',
  paddingLeft: '0.8rem',
  paddingRight: '0.8rem',
  border: '1px solid #d5dce6',
  borderRadius: '4px',
  color: '#0E0E1D',
  height: '36px',
  background: '#fff',
  width: '150px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center'
}
const tableCellStyle = {
  whiteSpace: 'nowrap', 
  overflow: 'hidden', 
  textOverflow: 'ellipsis', 
  fontSize: '14px', 
  fontFamily: 'Inter, sans-serif', 
  height: 'auto !important',
  fontWeight: '400',
  border: 'none',
}
const chipStyle = {
  backgroundColor: 'white', //'#f2f2fc',
  borderRadius: '4px',
  marginRight: '10px',
  padding: '2px 6px',
  fontSize: '14px',
}
export class TenantPortalPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
        tableRowValues: [
          {
            "id": "string",
            "readingId": "string",
            "readingDateTime": "2015-07-22T15:49:04-07:00",
            "hasError": false,
            "errorCodes": [
              "NO_CONSUMPTION"
            ],
            "meterNumbersFound": [
              "123",
              "123",
              "123",
              "123",
              "123",
              "123",
              "123",
              "123",
              "123",
              "123",
              "123",
              "123",
              "123",
              "123"
            ],
            "meterNumbersNotFound": [
              "123"
            ]
          },
          {
            "id": "string",
            "readingId": "string",
            "readingDateTime": "2016-07-20T15:49:04-07:00",
            "hasError": true,
            "errorCodes": [
              "NO_CONSUMPTION"
            ],
            "meterNumbersFound": [
              "123"
            ],
            "meterNumbersNotFound": [
              "123",
              "123",
              "123",
              "123",
              "123",
              "123",
              "123",
              "123",
              "123",
              "123",
              "123",
              "123",
              "123",
              "123"
            ]
          },
          {
            "id": "string",
            "readingId": "string",
            "readingDateTime": "2017-07-20T15:49:04-07:00",
            "hasError": false,
            "errorCodes": [
              "NO_CONSUMPTION"
            ],
            "meterNumbersFound": [
              "123"
            ],
            "meterNumbersNotFound": [
              "123"
            ]
          }
          ], 
        tableColumnNames: [
          {
          name:'Datum',
          key: 'date'
        },{
          name: 'Verarbeitung',
          key: 'status'
        },{
          name: 'Aktualisierte Zähler',
          key: 'meterNumbersFound'
        },{
          name: 'Nicht erkannte Zähler',
          key: 'meterNumbersNotFound'
        }],
        data: [],
        page: 0,
        rowsPerPage: 5,
        openMeterModal: false,
        edit: false,
        meter: {name: ''}
    };
  }

  componentDidMount = () => {
    this.getUploadsLog();
  };

  getUploadsLog = ()=> {
    let url= 'https://virtserver.swaggerhub.com/neoverv_berlin/NV-HH-API/0.9.2/readingsLog?propertyId=1';
    Autoload.HttpRequestAxios.HttpRequest(
      url,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      '_handleUploadsLogResponse',
      null,
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }
  _handleUploadsLogResponse = (response)=>{
    if(response.status == true){
      this.setState({data: response.result});
    }
  }

  ////sorting functions start
  descendingComparator = (a, b, orderBy)=> {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  
  getComparator= (order, orderBy)=> {
    return order === 'desc'
      ? (a, b) => this.descendingComparator(a, b, orderBy)
      : (a, b) => -this.descendingComparator(a, b, orderBy);
  }
  
  stableSort=(array, comparator)=> {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  onRequestSort = (event, property) => {
    console.log('onRequestSort: ', property);
    const {order, orderBy} = this.state;
    const isAsc = orderBy === property && order === 'asc';
    this.setState({order: isAsc ? 'desc' : 'asc'});
    this.setState({orderBy: property});
  };
////sorting functions end

///// paging functions start
handleChangePage = (event, newPage) => {
  this.setState({page: newPage});
};
handleChangeRowsPerPage = (event) => {
  this.setState({rowsPerPage: parseInt(event.target.value, 10)});
  this.setState({page: 0});
};
///// paging functions end
  
  render() {
    const {isMobile, isTablet} = this.props;
    const {tableRowValues, tableColumnNames, data, order, orderBy, page, rowsPerPage, openMeterModal, edit, meter} = this.state;
    const createSortHandler = (property) => (event) => {
      this.onRequestSort(event, property);
    };
    return (
        <div 
        style={{
          width: '100%', 
          //marginTop: '20px',
          // height: '500px',
          // background: 'white',
          // boxShadow: '0 1px 3px rgba(0,0,0,.08)', 
          // borderRadius: '4px'
          }}>
          <div 
          style={{
              padding: '12.1px 0px',
              // borderBottom: '1px solid #dadae6',
              // borderTopLeftRadius: '4px',
              // borderTopRightRadius: '4px',
              // background: 'white'
          }}>
              <h5 
              style={{
                // fontWeight: '600',
                // fontSize: '18px',
                // lineHeight: '32px',
                // color: '#0E0E1D',
                  fontWeight: '500',
                  fontSize: '16px',
                  lineHeight: '24px',
                  color: '#0e0e1d',
                  marginBottom: '0px'
                  }}>Übersicht</h5>
          </div>
          <div style={{
            //padding: '0px 0',
            }}>
              <a href='https://app.hellohousing.de/dashboard'>https://app.hellohousing.de/dashboard</a>
          </div>
          
        </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    propertySelected: state.generalReducer.property,
  };
};
export default connect(mapStateToProps)(TenantPortalPreview);
