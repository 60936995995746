export const GET_COST_DATA = 'GET_COST_DATA';
export const DELETE_COST_DATA = 'DELETE_COST_DATA';
export const EDIT_COST_DATA = 'EDIT_COST_DATA';
export const ADD_COST_DATA = 'ADD_COST_DATA';

export const SET_ALLOCATION_DATA = 'SET_ALLOCATION_DATA';
export const GET_ALLOCATION_DATA = 'GET_ALLOCATION_DATA';
export const DELETE_ALLOCATION_DATA = 'DELETE_ALLOCATION_DATA';
export const ADD_ALLOCATION_DATA = 'ADD_ALLOCATION_DATA';
export const EDIT_ALLOCATION_DATA = 'EDIT_ALLOCATION_DATA';

export const GET_GENERAL_INFO_DATA = 'GET_GENERAL_INFO_DATA';
export const SET_COST_DATA = "SET_COST_DATA";
export const SET_SELECTED_COST_TYPE = 'SET_SELECTED_COST_TYPE';