import React, { Component } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import Receipt from '../../../../../assets/images/module/general/ReceiptDummy.png';
import { Row, Col, Form, OverlayTrigger } from 'react-bootstrap';
import CustomModal from '.';
import { showNotification } from '@mantine/notifications';
import ColorButton from '../Buttons/ColorButton';
import CopyIcon from '../../../SVGs/copyIcon';
import GreenTick from '../../../../../assets/images/module/general/GreenTick.svg';
import { Checkbox, Group, Loader, Popover, Stack, TextInput } from '@mantine/core';
import EmptyStateContainer from '../EmptyStateContainer/EmptyStateContainer';
import TenantIconBlue from '../../../../../assets/images/module/general/TenantIconBlue.svg';
import { NumericFormat } from 'react-number-format';
import Autoload from '../../../../Autoload';
import DownloadIcon from '../../../SVGs/downloadIcon';
import PdfIcon from '../../../../../assets/images/module/general/PdfNewIcon.svg';
import XlsxIcon from '../../../../../assets/images/module/general/XlsxIcon.svg';
import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@material-ui/core';
import accounting from 'accounting';
import * as XLSX from 'xlsx';
import dayjs from 'dayjs';
import moment from 'moment';
import ControlSheetTable from '../../../../../views/pages/Module/UtilityBilling/ControlSheetTable';

const MOBILE_SIZE = 992;
const TABLET_SIZE = 1200;

var tableHeadCellStyling = {
    color: '#727293',
    padding: '0 9px 0 9px',
    fontWeight: '500',
    whiteSpace: 'nowrap',
    margin: '0px',
    fontSize: '14px',
    lineHeight: '20px',
    border: '1px solid #DADAE6',
    textTransform: 'capitalize',
    height: '48px'
}
var tableBodyCellStyling = {
    color: '#0e0e1d',
    padding: '5px 9px 5px 9px',
    fontWeight: '400',
    whiteSpace: 'nowrap',
    fontSize: '14px',
    lineHeight: '20px',
    border: '1px solid #DADAE6',
    height: '48px',
    minWidth: '90px'
}

export class ShowControlSheetForBillingStatementModal extends Component {
  state = {
    isMobile: window.innerWidth <= MOBILE_SIZE,
    isTablet: window.innerWidth <= TABLET_SIZE,
    allCostAllocationsForBillingStatements: [],
    allDataLoaded: false,
    costTypes: [],
    allReceiptAssignments: []
  };
  
  constructor(props) {
    super(props);
  }
  
  componentDidMount(){
    const {allBillingStatements} = this.props;
    console.log('allBillingStatements: ', allBillingStatements);
    allBillingStatements.map((billingStatement, index)=> this.getCostAllocationsForBillingStatements(billingStatement, index))
  }

  getCostAllocationsForBillingStatements=(billingStatement, currentIndex)=>{
    this.props.heatingFilter
      ? Autoload.HttpRequestAxios.HttpRequest(
        Autoload.Setting.ub_api_base_url +
        'costAllocations/forBillingStatement?utilityBillingId=' +
        billingStatement.utilityBillingId +
        '&billingStatementId=' +
        billingStatement.billingStatementId,
        'GET',
        null,
        null,
        false,
        true,
        false,
        this,
        'handleGetCostAllocationsForBillingStatements',
        {billingStatement, currentIndex},
        false,
        '',
        {
          Authorization: Autoload.Utils.getAuthToken(),
        }
      )
      : Autoload.HttpRequestAxios.HttpRequest(
        Autoload.Setting.ub_api_base_url +
        'costAllocations/forBillingStatement?utilityBillingId=' +
        billingStatement.utilityBillingId +
        '&billingStatementId=' +
        billingStatement.billingStatementId +
        '&filter=non-heating',
        'GET',
        null,
        null,
        false,
        true,
        false,
        this,
        'handleGetCostAllocationsForBillingStatements',
        {billingStatement, currentIndex},
        false,
        '',
        {
          Authorization: Autoload.Utils.getAuthToken(),
        }
      );
  }

  handleGetCostAllocationsForBillingStatements(response){
    if(response.status && response.statuscode === 200){
        let {allCostAllocationsForBillingStatements, costTypes} = this.state;
        const {allBillingStatements} = this.props;
        allCostAllocationsForBillingStatements.push(response.result);
        response.result.map((costAllocation)=> {
            if(costTypes.find((costType)=> costType.costPositionId === costAllocation.costPositionId) === undefined){
                costTypes.push({
                    costPositionId: costAllocation.costPositionId,
                    costTypeName: costAllocation.costTypeName,
                    costTypeIdentifier: costAllocation.costTypeIdentifier,
                    orderNumber: costAllocation.orderNumber,
                    receiptAssigments: []
                });
            }
        });

        this.setState({allCostAllocationsForBillingStatements, allDataLoaded: allBillingStatements.length === allCostAllocationsForBillingStatements.length, costTypes}, ()=> {
            if(allCostAllocationsForBillingStatements.length > 0 && (costTypes.length === allCostAllocationsForBillingStatements[0].length)){
                costTypes.map((costType)=> this.getReceiptAssignmentByCostPositionId(costType.costPositionId));
            }
        });
    }
  }

  getReceiptAssignmentByCostPositionId=(costPositionId)=>{
    Autoload.HttpRequestAxios.HttpRequest(
        Autoload.Setting.ub_api_base_url + 'receiptAssignments?costPositionId='+ costPositionId,
        'GET',
        null,
        null,
        false,
        true,
        false,
        this,
        'handleGetReceiptAssignmentByCostPositionId',
        null,
        false,
        '',
        {
          Authorization: Autoload.Utils.getAuthToken(),
        }
      );
  }

  handleGetReceiptAssignmentByCostPositionId(response){
    if(response.status && response.statuscode === 200){
        let {costTypes, allReceiptAssignments} = this.state;
        costTypes = costTypes.map((costType)=> {
            if(response.result[0].costPositionId === costType.costPositionId){
                //setting all receipt assignments
                response.result.map((receiptAssigment)=> {
                    if(allReceiptAssignments.find((allReceiptAssigment)=> allReceiptAssigment.id === receiptAssigment.id) === undefined){
                        allReceiptAssignments.push(receiptAssigment);
                    }
                });
                //setting receipt assignments for cost types
                costType.receiptAssigments = response.result;
                return costType;
            }else{
                return costType;
            }
        });
        
        this.setState({allReceiptAssignments, costTypes});
    }
  }

  downloadExcelTable = () => {
    const { allCostAllocationsForBillingStatements, costTypes, allReceiptAssignments } = this.state;
    const { allBillingStatements, utilityData } = this.props;
    let firstRow = `Kontrollblatt Betriebkosten ${this.props.propertySelected?.propertyAddress ? `${this.props.propertySelected?.propertyAddress}, ${this.props.propertySelected?.propertyZipCode} ${this.props.propertySelected?.propertyCity}` : this.props.propertySelected.name} (${utilityData.date})`;
    // `Mieteingang ${this.props.propertySelected?.propertyAddress ? `${this.props.propertySelected?.propertyAddress}, ${this.props.propertySelected?.propertyZipCode} ${this.props.propertySelected?.propertyCity}` : this.props.propertySelected.name} (${moment().format('DD.MM.YYYY')})`;

    let sumOfHeatingTotal = allCostAllocationsForBillingStatements[0]?.reduce((partialSum, a) => (partialSum ? partialSum : null) + ((a.totalCostShare && a.costTypeIdentifier !== 'NON_HEATING' && a.costTypeIdentifier !== 'HEATING_TOTAL') ? a.totalCostShare : null), 0);
    let sumOfTenantHeatingTotal = allCostAllocationsForBillingStatements?.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.length > 0 ? a.reduce((partialSum, b) => (partialSum ? partialSum : null) + ((b.tenantCostShare && b.orderNumber > 1 && b.orderNumber <= 5) ? b.tenantCostShare : null), 0) : null), 0);
    let sumOfReceiptAssignmentHeatingTotal = costTypes?.reduce((partialSum, a) => (partialSum ? partialSum : null) + ((a.length > 0 && a.orderNumber > 1 && a.orderNumber <= 5) ? a.receiptAssigments.reduce((partialSum, b) => (partialSum ? partialSum : null) + (b.proRataAmount ? b.proRataAmount : null), 0) : null), 0);

    let sumOfNonHeatingTotal = allCostAllocationsForBillingStatements[0].reduce((partialSum, a) => (partialSum ? partialSum : null) + ((a.totalCostShare && a.costTypeIdentifier === 'NON_HEATING') ? a.totalCostShare : null), 0);
    let sumOfTenantNonHeatingTotal = allCostAllocationsForBillingStatements.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.length > 0 ? a.reduce((partialSum, b) => (partialSum ? partialSum : null) + ((b.tenantCostShare && b.costTypeIdentifier === 'NON_HEATING') ? b.tenantCostShare : null), 0) : null), 0);
    let sumOfReceiptAssignmentNonHeatingTotal = costTypes.reduce((partialSum, a) => (partialSum ? partialSum : null) + ((a.receiptAssigments.length > 0 && a.costTypeIdentifier === 'NON_HEATING') ? a.receiptAssigments.reduce((partialSum, b) => (partialSum ? partialSum : null) + (b.proRataAmount ? b.proRataAmount : null), 0) : null), 0);

    var aoa = [
      [firstRow],
      ["", "", "", "", "", ""],
      ["Kostenart", ...allBillingStatements.map((billingStatement)=> `${billingStatement.tenantName} (${billingStatement.rentalUnitName})`), "Gesamtkosten", "Umgelegte Kosten", "Zugeordnete Belege"]
    ];
    costTypes.map((costType)=> {
        let tenantsCostAllocationsByCostType = [];
        let tenantCostAllocationsByCostTypeTotalCostShare = null;
        let sumOfTenants = allCostAllocationsForBillingStatements.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.find((costAllocation)=> costAllocation.costPositionId === costType.costPositionId) !== undefined ? a.find((costAllocation)=> costAllocation.costPositionId === costType.costPositionId).tenantCostShare : null), 0);
        let sumOfReceiptAssignments = costType.receiptAssigments.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.proRataAmount ? a.proRataAmount : null), 0);
        if(costType.costTypeIdentifier !== 'HEATING_TOTAL'){
            allBillingStatements.map((billingStatement, index)=> {
                return allCostAllocationsForBillingStatements.map((costAllocations)=> {
                        if(costAllocations[0].billingStatementId === billingStatement.billingStatementId){
                            let tenantCostAllocation = costAllocations.find((costAllocation)=> costAllocation.costPositionId === costType.costPositionId);
                            if(tenantCostAllocation !== undefined){
                                tenantsCostAllocationsByCostType.push(tenantCostAllocation.tenantCostShare);
                                tenantCostAllocationsByCostTypeTotalCostShare= tenantCostAllocation.totalCostShare;
                            }
                        }
                });
            });
            aoa.push([ 
                costType.costTypeName ? costType.costTypeName : '-', 
                ...tenantsCostAllocationsByCostType,
                tenantCostAllocationsByCostTypeTotalCostShare,
                sumOfTenants,
                sumOfReceiptAssignments
                // costType.receiptAssigments.length > 0 ? costType.receiptAssigments.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.proRataAmount ? a.proRataAmount : null), 0) : '-'
            ]);
            //HeatingTotal Row start
            if(costType.orderNumber === 5){
                let tenantsHeatingCostAllocations = [];
                allBillingStatements.map((billingStatement, index)=> {
                    return allCostAllocationsForBillingStatements.map((costAllocations)=> {
                        if(costAllocations[0].billingStatementId === billingStatement.billingStatementId){
                            let tenantCostAllocations = costAllocations.filter((costAllocation)=> costAllocation.costTypeIdentifier !== 'NON_HEATING' && costAllocation.costTypeIdentifier !== 'HEATING_TOTAL');
    
                            if(tenantCostAllocations.length > 0){
                                tenantsHeatingCostAllocations.push(tenantCostAllocations.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.tenantCostShare ? a.tenantCostShare : null), 0));
                            }
                        }
                    });
                });
                aoa.push([ 
                    'Heizkosten gesamt', 
                    ...tenantsHeatingCostAllocations,
                    sumOfHeatingTotal,
                    sumOfTenantHeatingTotal,
                    sumOfReceiptAssignmentHeatingTotal
                ]);
            }
            //HeatingTotal Row end
        }
    });

    //NonHeatingTotal Row start
    let tenantsNonHeatingCostAllocations = [];
    allBillingStatements.map((billingStatement, index)=> {
        return allCostAllocationsForBillingStatements.map((costAllocations)=> {
            if(costAllocations[0].billingStatementId === billingStatement.billingStatementId){
                let tenantCostAllocations = costAllocations.filter((costAllocation)=> costAllocation.costTypeIdentifier === 'NON_HEATING');

                if(tenantCostAllocations.length > 0){
                    tenantsNonHeatingCostAllocations.push(tenantCostAllocations.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.tenantCostShare ? a.tenantCostShare : null), 0));
                }
            }
        });
    });
    aoa.push([ 
        'Nebenkosten gesamt', 
        ...tenantsNonHeatingCostAllocations,
        sumOfNonHeatingTotal,
        sumOfTenantNonHeatingTotal,
        sumOfReceiptAssignmentNonHeatingTotal
    ]);
    //NonHeatingTotal Row end

    aoa.push([ 
        'Betriebkosten gesamt', 
        ...allBillingStatements.map((billingStatement)=> billingStatement.totalAllocations),
        (sumOfHeatingTotal + sumOfNonHeatingTotal),
        (sumOfTenantHeatingTotal + sumOfTenantNonHeatingTotal),
        (sumOfReceiptAssignmentHeatingTotal + sumOfReceiptAssignmentNonHeatingTotal)
    ]);

    aoa.push([ 
        'Vorauszahlungen', 
        ...allBillingStatements.map((billingStatement)=> billingStatement.prepayments),
        allBillingStatements.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.prepayments ? a.prepayments : null), 0),
        allBillingStatements.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.prepayments ? a.prepayments : null), 0),
        allBillingStatements.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.prepayments ? a.prepayments : null), 0)
    ]);

    aoa.push([ 
        'Differenz', 
        ...allBillingStatements.map((billingStatement)=>  billingStatement.prepayments - billingStatement.totalAllocations),
        (allBillingStatements.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.prepayments ? a.prepayments : null), 0))
        -
        (sumOfHeatingTotal + sumOfNonHeatingTotal),
        (allBillingStatements.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.prepayments ? a.prepayments : null), 0))
        -
        (sumOfTenantHeatingTotal + sumOfTenantNonHeatingTotal),
        (allBillingStatements.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.prepayments ? a.prepayments : null), 0))
        -
        (sumOfReceiptAssignmentHeatingTotal + sumOfReceiptAssignmentNonHeatingTotal)
    ]);

    

    

    const worksheet = XLSX.utils.aoa_to_sheet(aoa);

    let columns = [...Array(26).keys()].map(i => String.fromCharCode(i + 65));
    columns.forEach((column)=> {
      var colNum = XLSX.utils.decode_col(column); //decode_col converts Excel col name to an integer for col #
      var fmt = '0.00'; // or '"$"#,##0.00_);[Red]\\("$"#,##0.00\\)' or any Excel number format
  
      /* get worksheet range */
      var range = XLSX.utils.decode_range(worksheet['!ref']);
      for(var i = range.s.r + 1; i <= range.e.r; ++i) {
        /* find the data cell (range.s.r + 1 skips the header row of the worksheet) */
        var ref = XLSX.utils.encode_cell({r:i, c:colNum});
        /* if the particular row did not contain data for the column, the cell will not be generated */
        if(!worksheet[ref]) continue;
        /* `.t == "n"` for number cells */
        if(worksheet[ref].t != 'n') continue;
        /* assign the `.z` number format */
        worksheet[ref].z = fmt;
      }
    });

    var wscols = [...Array(allBillingStatements.length + 4)].map((e, i) => ({wch:25}));
    worksheet['!cols'] = wscols;

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Kontrollblatt");
    XLSX.writeFile(workbook, `${firstRow}.xlsx`);
  }
  
  render() {
    const { open, toggle, className, allBillingStatements } = this.props;
    const { allCostAllocationsForBillingStatements, allDataLoaded, costTypes, allReceiptAssignments } = this.state;

    return (
      <CustomModal
        heading={'Kontrollblatt'}
        headingClassName={'control-sheet-billing-statement-modal-heading'}
        open={open}
        toggle={() => {
          toggle();
        }}
        className={`${className}`}
        style={{minWidth: '80%'}}
      >
        <Stack style={{width: 'auto'}}>
            {allDataLoaded &&
            <Group>
                <Group spacing={5}>
                    <Group 
                    style={{cursor: 'pointer'}}
                    sx={() => ({
                        padding: '10px',
                        borderRadius: '4px',
                        '&:hover': allDataLoaded && {
                        backgroundColor: '#E3E3FC'
                        }
                    })}
                    onClick={()=> allDataLoaded && this.downloadExcelTable()}
                    >
                        <img src={XlsxIcon} width={'20px'} />
                        <span>Excel exportieren</span>
                    </Group>
                    {/* <Group 
                    style={{cursor: 'pointer'}} 
                    sx={() => ({
                        padding: '10px',
                        borderRadius: '4px',
                        '&:hover': allDataLoaded && {
                        backgroundColor: '#E3E3FC'
                        }
                    })}
                    onClick={()=> allDataLoaded && window.open(`/controlSheetPdfExport`, '_blank')}
                    >
                        <img src={PdfIcon} width={'20px'} />
                        <span>PDF exportieren</span>
                    </Group> */}
                </Group>
            </Group>}
            {allDataLoaded &&
            <ControlSheetTable
            allBillingStatements={allBillingStatements}allCostAllocationsForBillingStatements={allCostAllocationsForBillingStatements} 
            costTypes={costTypes} 
            allReceiptAssignments={allReceiptAssignments}
            />
            }
            {allDataLoaded === false &&
            <Stack style={{height: '100vh'}} align={'center'} justify='center'>
                <Group align={'center'} position='center'>
                    <Loader/>
                </Group>
            </Stack>}
        </Stack>
      </CustomModal>
    );
  }
}

export default ShowControlSheetForBillingStatementModal;
