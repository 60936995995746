import * as Actiontypes from '../../types/tenant';

export const getRentalUnitTenants = (data) => {
    // console.log('action', data)
    return {
        type: Actiontypes.GET_RENTAL_TENANTS,
        payload: data
    }
}
export const deleteTenant = (unitId, tenantid) => {
    return {
        type: Actiontypes.DELETE_TENANT,
        payload: { unitId, tenantid }
    }
}
export const editTenant = (unitId, tenantData) => {
    return {
        type: Actiontypes.EDIT_TENANT,
        payload: { unitId, tenantData }
    }
}
export const addTenant = (unitId, tenantData) => {
    // console.log('action', unitId, tenantData)
    return {
        type: Actiontypes.ADD_TENANT,
        payload: { unitId, tenantData }
    }
}
export const setCurrentTenant = (current, formData) => {
    // console.log('setCurrentTenant action', tenant)

    return {
        type: Actiontypes.SET_CURRENT_TENANT,
        payload: {current, formData}
    }
}
export const setAddEditFormData = (data) => {
    //console.log('setAddEditData action', data)
    return {
        type: Actiontypes.SET_ADD_EDIT_DATA,
        payload: data
    }
}
export const updateFilteredList = (data) => {
    // console.log('action', data)
    return {
        type: Actiontypes.SET_FILTERED_DATA,
        payload: data
    }
}
export const setAddEditFormValidation = (bool) => {
    // console.log('action validation', bool)
    return {
        type: Actiontypes.SET_ADD_EDIT_VALIDATION,
        payload: bool
    }
}
export const AddUnit = (unit)=>{
    return{
        type: Actiontypes.ADD_RENTAL_UNIT,
        payload: unit
    }
}
export const EditUnit = (unit)=>{
    return{
        type: Actiontypes.EDIT_RENTAL_UNIT,
        payload: unit
    }
}
export const DeleteUnit = (unitId)=>{
    // console.log('action', unitId)
    return{
        type: Actiontypes.DELETE_RENTAL_UNIT,
        payload: unitId
    }
}

export const loadingTenantsData = (value)=>{
    // console.log('action', unitId)
    return{
        type: Actiontypes.LOADING_TENANTS_DATA,
        payload: value
    }
}
// export const togglePreviousTenants = () => {
//     return {
//         type: Actiontypes.TOGGLE_PREVIOUS_TENANTS,
//     }
// }
// export const setCurrentUnit=(unit)=>{
//   return{
//       type:Actiontypes.SET_CURRENT_UNIT,
//       payload:unit
//   }
// }
// export const resetData=()=>{
//     return{
//         type:Actiontypes.RESET_DATA,
//     }
// }