import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { HeadingContainer, ColorButton, FiltersContainer } from '../../../../neoverv';
import RentAddress from './RentAddress';
import TenantDocuments from './TenantDocuments';
import TenantInfo from './TenantInfo';
import TenantNotes from './TenantNotes';
import CrossIcon from '../../../../assets/images/module/general/CrossIcon.svg';
import { addTenant, editTenant, setCurrentTenant } from '../../../../redux/action/Tenant/TenantActions';
import { connect } from 'react-redux';
import moment from 'moment';
import Autoload from '../../../../neoverv/Autoload';
import { ToastContainer, toast } from 'react-toastify';
import ErrorMsg from '../../../elements/module/general/ErrorMsg/ErrorMsg.js';
import { css } from 'glamor';
import { showNotification } from '@mantine/notifications';
import RedTick from '../../../../assets/images/module/general/RedTick.svg';
import GreenTick from '../../../../assets/images/module/general/GreenTick.svg';
import TenantAdministration from './TenantAdministration';
import TenantAccessDetails from './TenantAccessDetails';
import TenantAdministrateConsumption from './TenantAdministrateConsumption';
import TenantPortalPreview from './TenantPortalPreview';
import TenantSingleAdministration from './TenantSingleAdministration';
import breadcrumbsContent from '../../../../assets/breadcrumbs.json'
import { Stack } from '@mantine/core';
import HelpLogo from '../../../../assets/images/module/general/book.svg';
import TourIcon from '../../../../assets/images/module/general/TourIcon.svg';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
const { checkProperty, requiredFields, loader } = Autoload.MainHelper;

const routes = [
  {
    name: 'user-information',
    route: '/user/tenant/information',
    heading: lang.tenant.text_tenant_information,
  },
  // {
  //   name: 'user-address',
  //   route: '/user/tenant/address',
  //   heading: lang.tenant.text_rent_address,
  // },
  {
    name: 'user-documents',
    route: '/user/tenant/documents',
    heading: lang.tenant.text_documents,
  },
  {
    name: 'user-notes',
    route: '/user/tenant/notes',
    heading: lang.tenant.text_notes,
  },
  // {
  //   name: 'user-administration',
  //   route: '/user/tenant/administration',
  //   heading: 'Mieterzugang Details',
  // },
];
const routesTenantAdministration = [
  {
    name: 'user-access',
    route: '/user/tenant/access',
    heading: 'Zugang Details',
  },
  // {
  //   name: 'user-address',
  //   route: '/user/tenant/address',
  //   heading: lang.tenant.text_rent_address,
  // },
  {
    name: 'user-allocation',
    route: '/user/tenant/allocation',
    heading: 'Verbrauchszuweisung',
  },
  {
    name: 'user-preview',
    route: '/user/tenant/preview',
    heading: 'Übersicht',
  }
];

class AddTenant extends Component {
  state={
    tenantId: '',
    tenantAdministration: false
  }
  
  componentDidMount() {
    this.setState({
      tenantId: Autoload.Utils.getUrlParameterByName('tenantId'),
      tenantAdministration: Autoload.Utils.getUrlParameterByName('tenantAdministration') === 'true' ? true : false
    }, ()=> {
      console.log('this.tenantAdministration: ', this.state.tenantAdministration);
      if (!this.state.tenantId) {
        this.props.history.push('/user');
      } else {
        this.getTenantById(this.state.tenantId);
      }
    })
    
  }

  // componentDidUpdate(prevProps){
  //   if(prevProps.formData.in !== this.props.formData.in || prevProps.formData.out !== this.props.formData.out){
  //     this.addTenantBtn();
  //   }
  // }

  getTenantById(tenantId) {
    //loader(true);

    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + `Tenants/${tenantId}`,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleGetTenantByIdResponse',
      null,
      false,
      '',
      {
        //'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }
  handleGetTenantByIdResponse(response) {
    console.log('TENANT: handleGetTenantByIdResponse ', response);

    if (!response.status) {
      this.props.history.push({
        pathname: '/user',
        state: {
          data: response,
        },
      });
    }
    this.state.tenantAdministration && loader(false);
  }

  goBack = () => {
    this.props.history.push('/user');
    //window.history.back();
  };

  addTenantBtn = () => {
    loader(true);
    //check if form values are valid and data is not empty ( requiredFields(this.props.formData,['name', 'email', 'phone','in','out', 'address', 'city', 'zip', 'area']) )
    // if (this.props.isFormValid && requiredFields(this.props.formData, ['name', 'email', 'phone','in'])) {
    if (this.props.isFormValid && requiredFields(this.props.formData, ['name', 'in'])) {
      //for edit 'current data' is empty object
      if (Object.keys(this.props.currentRecord).length > 0) {
        //edit data
        this.editTenant();
      }
      // else {
      //   // add data
      //   this.addNewTenant()
      // }
      //after add edit go back to main screen
      //this.goBack();
    } else {
      // toast.dark('Data is invalid or empty', {
      //   position: 'top-center',
      //   autoClose: false,
      //   className: 'neoverv-toast',
      //   hideProgressBar: true,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      // });
      // <ErrorMsg errorMsg="Data is invalid or empty" />;
      Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang.newKeys.text_invalid_or_empty_data);
      loader(false);
      // alert('data is empty or not valid')
      //  console.log('data is empty or not valid')
    }
  };

  editTenant = () => {
    console.log('form data', this.props.formData);
    var data = JSON.stringify({
      tenantId: checkProperty(this.props.formData, 'id'),
      rentalUnitId: checkProperty(this.props.formData, 'rentalUnitId'),
      fullName: checkProperty(this.props.formData, 'name'),
      contractType: checkProperty(this.props.formData, 'type'),
      vacant: false,
      phone: checkProperty(this.props.formData, 'phone'),
      email: checkProperty(this.props.formData, 'email'),
      moveInDate:
        checkProperty(this.props.formData, 'in') == ''
          ? ''
          : moment(checkProperty(this.props.formData, 'in'), 'DD.MM.YYYY').format('YYYY-MM-DDTHH:mm:ss'),
      moveOutDate:
        checkProperty(this.props.formData, 'out') == null || checkProperty(this.props.formData, 'out') == ''
          ? null
          : moment(checkProperty(this.props.formData, 'out'), 'DD.MM.YYYY').format('YYYY-MM-DDTHH:mm:ss'),
      notes: checkProperty(this.props.formData, 'note'),
    });
    console.log('form data', data);
    // debugger
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'tenants/' + checkProperty(this.props.formData, 'id'),
      'PUT',
      data,
      null,
      false,
      true,
      false,
      this,
      'handleEditTenant',
      null,
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );

    // this.handleEditTenant()
  };
  handleEditTenant = (response) => {
    console.log('response edit tenant api', response);
    // const unitId = checkProperty(this.props.history.location.state, 'unitId')
    // let record = {
    //   ...this.props.formData,
    // }
    // this.props.editTenant(unitId, record)
    if(response.status === true && response.statuscode === 200){
      showNotification({
        message: 'Die Änderungen wurden übernommen.',
        icon: <img src={GreenTick} alt='green-tick'/>,
      })
    }
    loader(false);
  };

  // addNewTenant = () => {
  //   this.handleAddTenant()
  // }
  // handleAddTenant = () => {
  //   const unitId = checkProperty(this.props.history.location.state, 'unitId')
  //   let record = {
  //     ...this.props.formData,
  //     current: true,
  //   }
  //   this.props.addTenant(unitId, record)
  // }

  changePage(route) {
    if(route.includes('information')){
      let url = route+'?tenantId='+ this.state.tenantId;
      this.props.history.push(url, {
        unitId: checkProperty(this.props.history.location.state, 'unitId'),
      });
    }else{
      this.props.history.push(route + '?tenant=' + Autoload.Utils.getUrlParameterByName('tenant'), {
        unitId: checkProperty(this.props.history.location.state, 'unitId'),
      });
    }
  }

  render() {
    const {isMobile} = this.props;
    let pathname = this.props.location.pathname;
    if (pathname === '/user/tenant') {
      pathname = this.state.tenantAdministration ? routesTenantAdministration[0].route : routes[0].route;
    }

    console.log('window.location.href: ', window.location.href);

    return (
      <div>
        {/* <ErrorMsg errorMsg="Data is invalid or empty" /> */}
        {/* page title and buttons */}
        {!isMobile && 
        <HeadingContainer
          heading={
            // Object.keys(this.props.currentRecord).length > 0
            //   ?
            this.state.tenantAdministration ? 'Verwaltung' : lang.tenant.text_edit_tenant
            // : lang.tenant.text_add_tenant
          }
          className="profile-heading tenant-head"
          headingIcon={CrossIcon}
          iconHandler={() => this.goBack()}
          rightContent={
            <div //className="btn-container"
            style={{justifyContent: 'flex-end', display: 'flex'}}
            >
              <ColorButton
                onClick={() => {this.goBack();}}
                text={'Schließen'}
                className="gray-btn"
                buttonStyle={{maxWidth: '100px', padding: '8px', marginRight: '10px'}}
              />
              {/* <ColorButton
                onClick={() => {this.addTenantBtn();}}
                text={lang.tenant.text_save_tenant}
                className="purple-btn"
                buttonStyle={{maxWidth: '100px'}}
              /> */}
            </div>
          }
        />}
        <FiltersContainer
            style={{marginBottom: isMobile && '0px'}}
            hideSection2={true}
            hideSection3={true}
            searchElement={
                <Stack style={{width: isMobile && '100%'}}>
                    {<div style={{display: 'flex'}}>
                    {breadcrumbsContent.map((item)=> item.area === '/documents' && window.location.href.includes('tenant/documents') &&
                    item.breadcrumbs.map((breadcrumb)=>
                    breadcrumb.type === 'tour' ? breadcrumb.view === 'desktop' && !isMobile &&
                    <div style={{display: 'flex', width: 'auto', alignItems: 'center', cursor: 'pointer', marginRight: '22px'}} onClick={()=> window.Intercom('startTour', breadcrumb.id)}>
                        <img src={TourIcon} />
                        <span style={{color: '#5555FF', marginLeft: '5px', fontSize: '14px', whiteSpace: 'nowrap'}}>{breadcrumb.label}</span>
                    </div>
                    :
                    breadcrumb.view === 'desktop' &&
                    <div style={{display: 'flex', width: 'auto', alignItems: 'center', cursor: 'pointer', marginRight: '22px'}} onClick={()=> window.Intercom('showArticle', breadcrumb.id)}>
                        <img src={HelpLogo} />
                        <span style={{color: '#5555FF', marginLeft: '5px', fontSize: '14px', whiteSpace: 'nowrap'}}>{breadcrumb.label}</span>
                    </div>
                    ))}
                    </div>}
                </Stack>
            }
        />

        {/* tabs for page nativation */}

        <div style={{ display: 'flex', cursor: 'pointer' }} className="tenant-navbar">
          {this.state.tenantAdministration ? 
          routesTenantAdministration.map((btn, idx) => {
            return (
              <p
                key={idx}
                className={`switch-btn ${btn.name}-btn ${btn.route === pathname && 'active'}`}
                style={{
                  color: btn.route === pathname ? '#111147' : '#727293',
                  borderBottom: btn.route === pathname ? '3px solid #5555FF' : null,
                  fontSize: btn.route === pathname ? '14px' : '14px',
                  marginRight: '25px',
                  padding: '20px 0px',
                  paddingTop: (isMobile && window.location.href.includes('tenant/documents') === false) ? '0px' : '20px'
                }}
                onClick={() => {
                  this.changePage(btn.route);
                }}
              >
                {btn.heading}
              </p>
            );
          })
          :
           routes.map((btn, idx) => {
            return (
              <p
                key={idx}
                className={`switch-btn ${btn.name}-btn ${btn.route === pathname && 'active'}`}
                style={{
                  color: btn.route === pathname ? '#111147' : '#727293',
                  borderBottom: btn.route === pathname ? '3px solid #5555FF' : null,
                  fontSize: btn.route === pathname ? '14px' : '14px',
                  marginRight: '25px',
                  padding: '20px 0px',
                  paddingTop: (isMobile && window.location.href.includes('tenant/documents') === false) ? '0px' : '20px'
                }}
                onClick={() => {
                  this.changePage(btn.route);
                }}
              >
                {btn.heading}
              </p>
            );
          })}
        </div>
        <hr style={{ marginTop: '-18px' }} />

        {/* routes for different sections */}

        <div className="profile-section">
          <div className="profile-content">
            <div className="tab-pane">
              <div className="section-container">
                <div className="section-content" style={{height: '100%'}}>
                  {/* <Route path={this.props.match.url} exact component={TenantInfo} /> */}
                  {this.state.tenantAdministration ? 
                  <>
                  {/* <Route path={this.props.match.url + '/access'} exact component={TenantAccessDetails} />
                  <Route path={this.props.match.url + '/allocation'} exact component={TenantAdministrateConsumption} />
                  <Route path={this.props.match.url + '/preview'} exact component={TenantPortalPreview} /> */}
                  </>
                  :
                  <>
                  <Route path={this.props.match.url + '/information'} exact //component={TenantInfo} 
                  render={(props)=> <TenantInfo {...props} isMobile={isMobile} />}
                  />
                  <Route path={this.props.match.url + '/address'} exact component={RentAddress} />
                  <Route path={this.props.match.url + '/documents'} exact component={TenantDocuments} />
                  <Route path={this.props.match.url + '/notes'} exact component={TenantNotes} />
                  {/* <Route path={this.props.match.url + '/administration'} exact component={TenantSingleAdministration} /> */}
                  </>
                  }
                </div>
              </div>
            </div>
            {isMobile &&
          <ColorButton
            onClick={() => this.goBack()}
            text={'Schließen'}
            className="gray-btn"
            buttonStyle={{padding: '8px'}}
          />}
          </div>
        </div>
        <ToastContainer
          position="top-center"
          autoClose={false}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentRecord: state.tenantDataReducer.currentTenant,
    formData: state.tenantDataReducer.addEditFormData,
    rentalUnit: state.tenantDataReducer.rentalUnits,
    isFormValid: state.tenantDataReducer.addEditFormValid,
  };
};
const actions = {
  addTenant,
  editTenant,
  setCurrentTenant,
};

export default connect(mapStateToProps, actions)(AddTenant);
