import React, { Component } from 'react';
import { ColorButton } from '../../../../../neoverv';
import Autoload from '../../../../../neoverv/Autoload';
import { connect } from 'react-redux';
import { Group, Stack } from '@mantine/core';
import Logo from '../../../../../assets/images/module/general/Logo.svg';
import paypal from '../../../../../assets/images/module/general/paypal.svg';
import mastercard from '../../../../../assets/images/module/general/mastercard.svg';
import visa from '../../../../../assets/images/module/general/visa.svg';
import { setCookie } from '../../../../../neoverv/helpers/Utils';
import moment from 'moment';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { formatNumber } from '../../../../../helpers/stringsManipulations';
import jsPDF from 'jspdf';
import { browserName } from "react-device-detect";
import html2canvas from 'html2canvas';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
var { checkProperty, loader } = Autoload.MainHelper;
const ref = React.createRef();

const tableHeaderStyle={
  fontFamily: 'Inter, sans-serif',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '15px',
  lineHeight: '16px',
  paddingLeft: '10px',
  paddingRight: '10px'
}

const tableBodyStyle={
  fontFamily: 'Inter, sans-serif',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '15px',
  lineHeight: '16px',
  paddingLeft: '10px',
  paddingRight: '10px'
}
export class InvoicePdfTemplate extends Component {
  state = {
    id: null,
    invoice: null
  };

  componentDidMount() {
    var loaderWhole = document.getElementById('hello-housing-loader');
    loaderWhole.style.display = 'block';
    var loaderBackground = document.getElementById('hh_loader_bg');
    var loader = document.getElementById('hh_loader_spinner');
    var progressBar = document.getElementById('hh_progress_bar');
    loaderBackground.style.background = 'white';
    loaderBackground.style.opacity = '1';
    loader.style.zIndex = '99999';

    var downloadStatus = document.getElementById('download_status');
    downloadStatus.style.display = 'block';
    downloadStatus.textContent=`PDF-Dokumente zusammenfügen...`;

    const id = window.location.pathname.split('/')[2];
    this.setState({
      id: id
    });
    this.getInvoice(id);
  }

  getInvoice = (id) => {
    console.log(id, 'getPaymentHistoryPDF');
    Autoload.HttpRequestAxios.HttpRequest(
      // Autoload.Setting.user_reg_api_baseurl + 'Invoices/' + id + '/download', // direct download
      Autoload.Setting.user_reg_api_baseurl + 'Invoices/' + id,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handle_payment_history_pdf',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };

  handle_payment_history_pdf = (response) => {
    console.log('handle_payment_history_pdf', response);
    if(response.status){
      this.setState({invoice: response.result});
      this.printDocument();
    }
  };

  getTableLabels=()=>{
    return(
      <TableRow style={{...tableHeaderStyle}}>
        <TableCell style={{...tableHeaderStyle, ...{border: '1px solid #CDCBCB'}}}>
          Position
        </TableCell>
        <TableCell style={{...tableHeaderStyle, ...{border: '1px solid #CDCBCB', borderLeft: 'none'}}}>
          Leistungszeitraum
        </TableCell>
        <TableCell style={{...tableHeaderStyle, ...{textAlign: 'right', border: '1px solid #CDCBCB', borderLeft: 'none'}}}>
          Anzahl
        </TableCell>
        <TableCell style={{...tableHeaderStyle, ...{textAlign: 'right', border: '1px solid #CDCBCB', borderLeft: 'none'}}}>
          Kosten
        </TableCell>
        <TableCell style={{...tableHeaderStyle, ...{textAlign: 'right', border: '1px solid #CDCBCB', borderLeft: 'none'}}}>
          MwSt. inkl.
        </TableCell>
        <TableCell style={{...tableHeaderStyle, ...{textAlign: 'right', border: '1px solid #CDCBCB', borderLeft: 'none'}}}>
          Betrag
        </TableCell>
      </TableRow>
    )
  }

  printDocument() {
    const invoiceNumber= this.state.invoice?.invoiceNumber;
    const input = document.getElementById('divToPrint');
    const logo = document.getElementById('logo');
    const closeWindow = ()=> {
      var allowDownloadSafari = document.getElementById('allow_download');
      allowDownloadSafari.style.display = browserName === 'Safari' && 'block'; 
      var spinner = document.getElementById('hh_loader_spinner');
      browserName !== 'Safari' ? loader(false) : spinner.style.display = 'none';
      browserName !== 'Safari' && window.close();
    }
    html2canvas(logo, {
      scale: 4
    }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      var doc = new jsPDF("p", "pt", 'a4');
      doc.addImage(imgData, 'JPEG',489.5, 20, 75,38, undefined, 'NONE');
      //doc.addImage(imgData, 'JPEG',25.7, 20, 75,38, undefined, 'FAST');
      doc.html(input, {
        callback: function (doc) {
          var pageSize = doc.internal.pageSize;
          var pageHeight = pageSize.height
            ? pageSize.height
            : pageSize.getHeight();
          doc.setFontSize(8);
          doc.setTextColor('#828282');
          doc.text('Hello Housing GmbH, Wattstr. 11, 13355 Berlin, Registergericht: Berlin, Amtsgericht Charlottenburg, HRB 226520 B', 
          100, 
          pageHeight - 38);
          doc.text('Geschäftsführung: Jörg Radeke, Dr. Frank Radeke, UID: DE341845883', 
          170, 
          pageHeight - 25);
          doc.save(`Rechnung-${invoiceNumber}.pdf`, {returnPromise: true}).then(
            setTimeout(() => {
              closeWindow();
          }, 500))
        },
        margin: [0,15,0,15],
        x: 10,
        y: 15,
        autoPaging: 'text',
        html2canvas: {useCORS: true, scale: 0.505
          , height: 0 },
      });
    });
  }

  render() {
    const {invoice} = this.state;
    const {profile, accountData} = this.props;
    console.log('invoice: ', invoice);
    return (
      <div style={{ overflow: 'auto' }}>
        {/* <ReactToPdf targetRef={ref} filename="billing-statement.pdf" scale={0.65} onComplete={() => {
          // setTimeout(() => {
          //   window.close()
          // }, 1000);
        }} >
          {({ toPdf }) => (
            <button style={{ display: 'none' }} onClick={toPdf}>Generate pdf</button>
          )}
        </ReactToPdf> */}
        {/* <button onClick={this.printDocument}>Generate pdf</button> */}
        {/* <div style={{ width: '100vw', height: '100vh', position: 'absolute', zIndex: 1000, background:'red' }}></div> */}
          <div
            ref={ref}
            id={'divToPrint'}
            style={{width: '1070px'}}
          >
            <Stack style={{ marginBottom: 10 }}>
              <div style={{ marginBottom: 28 }}>
                <img src={Logo} id='logo' alt="logo" />
              </div>
              <Group grow>
                <Stack align={'flex-start'} spacing={0}>
                  <h2 //className='invoice-pdf-heading' 
                  >Rechnung</h2>
                </Stack>
                <Stack align={'flex-end'} spacing={0}>
                  <span className='invoice-pdf-company-address'//className='invoice-pdf-name' 
                  style={{fontWeight: '0', fontFamily: 'Inter, sans-serif', marginTop: '10px'}}
                  >Hello Housing GmbH</span>
                  <span className='invoice-pdf-company-address' style={{fontWeight: '0', fontFamily: 'Inter, sans-serif'}}>Wattstr. 11, 13355 Berlin</span>
                </Stack>
              </Group>
            </Stack>

            <Group grow align={'flex-start'} style={{marginBottom: '200px'}}>
              <Stack spacing={0}>
                {/* <h2 className='invoice-pdf-name' style={{fontSize: '16px', fontWeight: '700', fontFamily: 'Inter, sans-serif', marginBottom: '4px'}}>{`${profile.firstName} ${profile.lastName}`}</h2> */}
                <span className='invoice-pdf-company-address' style={{fontWeight: '0', fontFamily: 'Inter, sans-serif'}}>{`${profile.company}`}</span>
                <span className='invoice-pdf-company-address' style={{fontWeight: '0', fontFamily: 'Inter, sans-serif'}}>{`${profile.address}`}</span>
                <span className='invoice-pdf-company-address' style={{fontWeight: '0', fontFamily: 'Inter, sans-serif'}}>{`${profile.zip} ${profile.city}`}</span>
              </Stack>
            </Group>

            <Group spacing={120}>
              <Stack>
                <Stack spacing={10}>
                  <span className='invoice-label'>Kundenkonto</span>
                  <span className='invoice-label-value'>{invoice?.accountNo}</span>
                </Stack>
              </Stack>
              <Stack align={'center'}>
                <Stack spacing={10}>
                  <span className='invoice-label'>Rechnungsnummer</span>
                  <span className='invoice-label-value'>{invoice?.invoiceNumber}</span>
                </Stack>
              </Stack>
              <Stack align={'flex-end'}>
                <Stack spacing={10}>
                  <span className='invoice-label'>Rechnungsdatum</span>
                  <span className='invoice-label-value'>{moment(invoice?.invoiceDate).format('DD.MM.YYYY')}</span>
                </Stack>
              </Stack>
              <Stack align={'flex-end'}>
                <Stack spacing={10}>
                  <span className='invoice-label'>Status</span>
                  <span className='invoice-label-value'>{invoice?.paymentStatus === 'PAID' ? 'Bezahlt' : invoice?.paymentStatus === 'OPEN' ? 'Offen' : (invoice?.paymentStatus === 'CANCELLED' || invoice?.paymentStatus === 'FAILED') ? 'Fehlgeschlagen' : 'Unbezahlte'}</span>
                </Stack>
              </Stack>
            </Group>
            <>
              <TableContainer component={Paper} 
              style={{marginTop: '30px', background: 'transparent', borderRadius: '4px'}}>
                <Table //className={`table borderless mb-0 ub-table`} style={{width: 'auto'}} 
                >
                  <TableHead>
                    {this.getTableLabels()}
                  </TableHead>
                  <TableBody 
                  //className="ub-body"
                  style={{background: 'white'}}
                  >
                    {invoice?.positions.map((item, index) => {
                        return (
                          <TableRow style={{border: '1px solid #CDCBCB'}}>
                            <TableCell style={{...tableBodyStyle, ...{borderRight: '1px solid #CDCBCB'}}}>
                              {item.description}
                            </TableCell>
                            <TableCell style={{...tableBodyStyle, ...{borderRight: '1px solid #CDCBCB'}}}>
                              {`${moment(item.startDate).format('DD.MM.YYYY')} - ${moment(item.endDate).format('DD.MM.YYYY')}`}
                            </TableCell>
                            <TableCell style={{...tableBodyStyle, ...{textAlign: 'right', borderRight: '1px solid #CDCBCB'}}}>
                              1
                            </TableCell>
                            <TableCell style={{...tableBodyStyle, ...{textAlign: 'right', borderRight: '1px solid #CDCBCB'}}}>
                              {`${invoice?.invoiceType === 'Credit' ? '-' : ''} ${formatNumber(item.cost, true)}`}
                            </TableCell>
                            <TableCell style={{...tableBodyStyle, ...{textAlign: 'right', borderRight: '1px solid #CDCBCB'}}}>
                              {`${item.taxRate}%`}
                            </TableCell>
                            <TableCell style={{...tableBodyStyle, ...{textAlign: 'right'}}}>
                              {`${invoice?.invoiceType === 'Credit' ? '-' : ''} ${formatNumber(item.cost, true)}`}
                            </TableCell>
                          </TableRow>
                        );
                      })
                    }
                  </TableBody>
                </Table>
              </TableContainer>

              <Group style={{justifyContent: 'flex-end', marginTop: '30px'}}>
                    <Stack style={{width: '350px'}}>
                      <Group grow>
                        <span style={{fontSize: '16px'}}>Zwischensumme</span>
                        <span style={{textAlign: 'right', fontSize: '16px'}}>{invoice && `${invoice?.invoiceType === 'Credit' ? '-' : ''} ${formatNumber(invoice?.subTotalAmount, true)}`}</span>
                      </Group>
                      {invoice?.invoiceType !== 'Credit' &&
                      invoice?.creditAmount !== 0 &&
                      <Group grow>
                        <span style={{fontSize: '16px'}}>Guthaben</span>
                        <span style={{textAlign: 'right', fontSize: '16px'}}>{`- ${invoice && formatNumber(invoice?.creditAmount, true)}`}</span>
                      </Group>}
                      <div className='divider' style={{borderColor: '#000000', marginTop: 8, marginBottom: 8}}/>
                      <Group grow>
                        <span style={{fontSize: '18px'}}>Gesamtbetrag</span>
                        <span style={{textAlign: 'right', fontSize: '18px'}}>{invoice && `${invoice?.invoiceType === 'Credit' ? '-' : ''} ${formatNumber(invoice?.totalAmount, true)}`}</span>
                      </Group>
                      <Group grow>
                        <span style={{fontSize: '16px'}}>inkl. Mwst. (19%)</span>
                        <span style={{textAlign: 'right', fontSize: '16px'}}>{invoice && `${invoice?.invoiceType === 'Credit' ? '-' : ''} ${formatNumber(invoice?.totalVATAmount, true)}`}</span>
                      </Group>
                    </Stack>
              </Group>

              <Group style={{marginTop: '100px'}}>
                <span className='invoice-text'>{invoice?.invoiceType === 'Credit' ? 'Das Guthaben wurde Ihrem Konto gutgeschrieben. Vielen Dank, dass Sie hellohousing nutzen!' : `Der Gesamtbetrag wird am ${moment(invoice?.invoiceDate).format('DD.MM.YYYY')} über die von Ihnen autorisierte Zahlungsmethode eingezogen. Vielen Dank, dass Sie hellohousing nutzen!`}</span>
              </Group>
            </>
          </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profile: state.generalReducer.profile,
    AccountApi: state.generalReducer.account_api_id,
    accountData: state.generalReducer.accountData,
  };
};

export default connect(mapStateToProps, null)(InvoicePdfTemplate);
