import React, { Component } from 'react';
import { Form, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Accordion, createStyles, Group, Loader, SimpleGrid, Stack } from '@mantine/core';
import { connect } from 'react-redux';

import GlobalServices from '../../../../../neoverv/helpers/services/GlobalServices';
import MeterDataHandler from '../../../../../neoverv/helpers/DataHandler/Meter';
import GeneralDataHandler from '../../../../../neoverv/helpers/DataHandler/General';
import uparrow from '../../../../../assets/images/module/general/SelectArrowUp.svg';
import downArrow from '../../../../../assets/images/module/general/SelectArrowDown.svg';
import Help from '../../../../../assets/images/module/general/Help.svg';
import UnitsIcon from '../../../../../assets/images/module/general/UnitsIcon.svg';
import BlueUnitsIcon from '../../../../../assets/images/module/general/BlueEmptyTenant.svg';
import ArchiveIcon from '../../../../../assets/images/module/general/Archive.svg';
import RedCross from '../../../../../assets/images/module/general/RedCross.svg';
import PropertyIcon from '../../../../../assets/images/module/general/PropertyIcon.svg';
import PropertyIconBlue from '../../../../../assets/images/module/general/PropertyIconBlue.svg';
import MultipleTenants from '../../../../../assets/images/module/general/MultipleTenants.svg';
import MultipleTenantsBlue from '../../../../../assets/images/module/general/MultipleTenantsBlue.svg';
import { ColorButton, SwitchContainer, SelectDropdown } from '../../../../index';
import Autoload from '../../../../Autoload';
import CustomModal from './index';
import FixedAllocationKeyModal from './FixedAllocationKeyModal';
import AddAllocationKeyModal from './AddAllocationKeyModal';
import { hideNotification, showNotification } from '@mantine/notifications';
import { Checkbox } from '@material-ui/core';
import dayjs from 'dayjs';
import CardSelection from '../CardSelection/CardSelection';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];

const MOBILE_SIZE = 992;

class MeterModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      meterData: {
        id: '',
        meterNo: '',
        notes: '',
        location: '',
        allocationKey: '',
        allocationKeyName: '',
        rentalUnitId: '',
        rentalUnitName: '',
        meterType: '',
        meterName: ''
      },
      rentalUnits: [],
      allocationKeys: [],
      DropdownState: 'true',
      DropdownState2: 'true',
      numErr: false,
      typeErr: false,
      locationErr: false,
      propertyMeter: false,
      allocationkeyError: false,
      rentalUnitNameErr: false,
      allRentalUnits: [],
      isMobile: window.innerWidth <= MOBILE_SIZE,
      hover1: false,
      hover2: false,
      typeError: false,
      openAddAllocationKeyModal: false,
      openFixedAllocationKeyModal: false,
      selectedAllocationKeyType: '', 
      selectedAllocationKeyName: '', 
      selectedAllocationUnits: '', 
      selectedAllocationId: '',
      billingPeriods: [],
      selectedBillingPeriod: null,
      meterDataFetchComplete: false
    };
    this.propertyId = this.props.propertySelected;
  }

  handleResize = () => {
    if (window.innerWidth <= MOBILE_SIZE) {
      this.setState({isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
  };

  componentDidMount() {
    this.props.propertySelected && GlobalServices._getRentalUnitsByProperty(this, '_handle_rental_unit_response', this.props.propertySelected);
    this.props.propertySelected && GlobalServices._getAllocationKeysByProperty(this, '_handle_allocation_keys_response', this.props.propertySelected);
    this.props.edit && this.props.propertySelected && GlobalServices._getBilingPeriodsByProperty(this, '_handleBillingPeriodResponse', this.props.propertySelected)
    window.addEventListener('resize', this.handleResize);
    if(this.props.meter){
      let {meterData} = this.state;
      meterData.meterNo = this.props.meter.number;
      this.setState({meterData});
    }
    if(this.props.addPropertyMeter || (window.location.href.includes('meter/reading/annual/') && !this.props.rentalUnitId)){
      let {meterData} = this.state;
      meterData.meterType = 'PROPERTY';
      this.setState({meterData});
    }else if(this.props.addPropertyMeter === false || (window.location.href.includes('meter/reading/annual/') && this.props.rentalUnitId)){
      let {meterData} = this.state;
      meterData.meterType = 'RENTAL_UNIT';
      this.setState({meterData});
    }
  }

  _handleBillingPeriodResponse(response){
    if(response.status && response.statuscode === 200){
      let billingPeriods = response.result.map((bp)=> 
      {
        return {...bp, label: `${dayjs(bp.startDate).format('DD.MM.YYYY')} - ${dayjs(bp.endDate).format('DD.MM.YYYY')}`}
      }
      )
      this.setState({billingPeriods}, ()=> 
      setTimeout(() => {
        this.props.record && this.props.record.id && this._getMeterData(this.props.record.id);
      }, 100));
    }
  }

  componentWillUnmount(){
    hideNotification('addRentalUnit');
  }

  componentDidUpdate(prevProps){
    if(prevProps.meter !== this.props.meter){
      let {meterData} = this.state;
      meterData.meterNo = this.props.meter.number; 
      this.setState({meterData});
    }
  }

  //@desc rental units response handler
  _handle_rental_unit_response(response) {
    if (response.status === false && response.message !== 'Not Found') {
      // Autoload.Redirection.redirection_error();
      //Autoload.DataHandlerGeneral.GeneralErrorMessage(true, 'Error :  ' + response.message);
    }
    const rentalUnits = GeneralDataHandler.getRantalUnits(response.result);
    console.log(rentalUnits, 'rentalUnits from response');
    this.setState({
      allRentalUnits: rentalUnits.sort((a, b) => {
        let fa = a.name.toLowerCase(),
          fb = b.name.toLowerCase();
        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      }),
    });
    // if (this.props.rentalUnitId) {
    //   const selectedRentalUnit = rentalUnits.find((rentalUnit) => rentalUnit.id === this.props.rentalUnitId);
    //   this.setState({
    //     rentalUnits,

    // });
    if (this.props.rentalUnitId) {
      const selectedRentalUnit = rentalUnits.find((rentalUnit) => rentalUnit.id === this.props.rentalUnitId);

      this.setState({
        allRentalUnits: rentalUnits,
        meterData: {
          ...this.state.meterData,
          rentalUnitId: selectedRentalUnit.id,
          rentalUnitName: selectedRentalUnit.name,
        },
      });
    } else if (this.props.unitId) {
      const selectedRentalUnit = rentalUnits.find((rentalUnit) => rentalUnit.id === this.props.unitId);

      this.setState({
        allRentalUnits: rentalUnits,
        meterData: {
          ...this.state.meterData,
          rentalUnitId: selectedRentalUnit.id,
          rentalUnitName: selectedRentalUnit.name,
        },
      });
    } else {
      this.setState({
        allRentalUnits: rentalUnits,
        meterData: {
          ...this.state.meterData,
          rentalUnitId: this.state.meterData.meterType !== 'PROPERTY' ? rentalUnits.length > 0 && rentalUnits[this.props.rentalUnitIndex ? this.props.rentalUnitIndex : 0].id : null,
          rentalUnitName: this.state.meterData.meterType !== 'PROPERTY' ? rentalUnits.length > 0 && rentalUnits[this.props.rentalUnitIndex ? this.props.rentalUnitIndex : 0].name : null,
        },
      });
    }

    this.setState({
      meterData:{
        ...this.state.meterData,
        meterType: rentalUnits.length === 0 ? 'PROPERTY' : this.state.meterData.meterType
      }
    })
  }

  //@desc allocations keys response handler
  _handle_allocation_keys_response(response) {
    console.log(response, 'allocation keys in meter');
    if (response.status === false && response.message !== 'Not Found') {
      // Autoload.Redirection.redirection_error();
      Autoload.DataHandlerGeneral.GeneralErrorMessage(true, 'Error :  ' + response.message);
    }
    const keys = GeneralDataHandler.getAllocationKeys(response.result);
    // console.log(keys, 'allocation keys in meter');

    const allocationKeys = keys.filter((keys) => keys.metered === true);
    // console.log(allocationKeys, 'allocation keys in meter 2');
    console.log('_handle_allocation_keys_response: ', response.other);
    this.setState({
      allocationKeys,
      meterData: response.other ? {
        ...this.state.meterData,
        allocationKey: response.other.result,
        allocationKeyName: response.other.result.allocationKeyName,
        meterType: response.other.result.meterType === 'RENTAL_UNIT' ? 'RENTAL_UNIT' : 'PROPERTY'
      } : this.state.meterData,
    });
  }

  _getMeterData(meterId) {
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.meter_api_base_url + 'meters/' + meterId,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      '_handleMeterDataResponse',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  getAllocationKeys =(newAllocationKey= null)=>{
    console.log('getAllocationKeys: ', newAllocationKey);
    GlobalServices._getAllocationKeysByProperty(this, '_handle_allocation_keys_response', this.props.propertySelected, newAllocationKey);
  }

  _handleMeterDataResponse(response) {
    // console.log(response, 'edit data out');
    if (response.status && response.statuscode === 200) {
      const meterData = MeterDataHandler.getMeterByMeterID(response.result);
      this.setModalData(meterData);
    }
  }

  setModalData(record) {
    const { rentalUnitId, allocationKey } = record;
    const { rentalUnits, allRentalUnits, billingPeriods } = this.state;
    let rentalUnitName = null;
    allRentalUnits.map((rentalUnit) => (rentalUnit.id === rentalUnitId ? (rentalUnitName = rentalUnit.name) : null));
    // allocationKeyName = null;
    // allocationKeys.map((allocationObj) =>
    //   allocationObj.id === allocationKey ? (allocationKeyName = allocationObj.allocationKeyName) : null
    // );
    let fd = record.lastBillingPeriodId ? billingPeriods.find((bp)=> bp.id === record.lastBillingPeriodId) : null;
    fd = fd ? {...fd, label: `${dayjs(fd.startDate).format('DD.MM.YYYY')} - ${dayjs(fd.endDate).format('DD.MM.YYYY')}`} : null
    this.setState({
      meterData: {
        ...this.state.meterData,
        ...record,
        // rentalUnits,
        allocationKeyName: allocationKey.allocationKeyName,
        rentalUnitName,
      },
      propertyMeter: record.propertyMeter,
      selectedBillingPeriod: fd
    }, ()=> this.setState({meterDataFetchComplete: true}));
  }

  onInputChange = (field, value) => {
    this.setState({ meterData: { ...this.state.meterData, [field]: value } });
  };

  validateData = () => {
    this.setState({
      numErr: false,
      typeErr: false,
      locationErr: false,
      allocationkeyError: false,
      rentalUnitNameErr: false,
      typeError: false
    });
    let numErr,
      typeErr,
      locationErr,
      allocationkeyError,
      rentalUnitNameErr, typeError = false;
    // const validNum = /^[0-9]/;
    ////const validName = /^[a-zA-Z]+(?:[\s]+[a-zA-Z]+)*$/;
    const validName = /[^a-zA-Z0-9äöüÄÖÜß]/;
    if (this.props.meterUploads ? !this.props.meter.number : !this.state.meterData.meterNo) {
      numErr = true;
    }
    // if (!this.state.meterData.location) {
    //   locationErr = true;
    // }
    if(this.state.meterData.meterType.length === 0){
      typeError = true;
    }
    if (
      !this.state.meterData.allocationKeyName ||
      this.state.meterData.allocationKeyName === lang.newKeys.text_select
    ) {
      allocationkeyError = true;
    }
    if (this.state.meterData.meterType === 'RENTAL_UNIT' && !this.state.meterData.rentalUnitName || this.state.meterData.rentalUnitName === lang.newKeys.text_select) {
      rentalUnitNameErr = true;
    }
    this.setState({ numErr, typeErr, locationErr, allocationkeyError, rentalUnitNameErr, typeError });

    if (numErr || typeErr || locationErr || allocationkeyError || rentalUnitNameErr || typeError) {
      return false;
    } else {
      return true;
    }
  };

  handleSubmit = (e) => {
    const {meterData, selectedBillingPeriod} = this.state;
    console.log('handleSubmit');
    e.preventDefault();
    if (this.validateData()) {
      this.state.meterData.propertyMeter = this.state.propertyMeter;
      console.log('this.state.meterData: ', this.state.meterData);
      this.setState({meterData: {...meterData, lastBillingPeriodId: selectedBillingPeriod?.id}}, ()=> 
        this.props.edit ? this.props.handleEdit(this.state.meterData) : this.props.addNewMeter(this.state.meterData)
      );
      
    }
  };

  updatePropertyMeter() {
    const { meterData, propertyMeter } = this.state;
    this.setState({
      propertyMeter: !propertyMeter,
      meterData: { ...meterData, rentalUnitId: null, rentalUnitName: null },
    });
  }

  renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Property meter
    </Tooltip>
  );

  handleEnterKeyPress = (e) => {
    if (e.charCode == 13) {
      e.preventDefault();
      this.handleSubmit(e);
    }
  };

  handleMouseEnter=(type)=>{
    this.setState({[type]: true});
  }

  handleMouseLeave=(type)=>{
      this.setState({[type]: false});
  }

  toggleAddAllocationKeyModal = () => {
    this.setState({ openAddAllocationKeyModal: !this.state.openAddAllocationKeyModal });
  };

  handleCheckBox=()=>{
    const {selectedBillingPeriod, billingPeriods, meterData} = this.state;
    this.setState({selectedBillingPeriod: selectedBillingPeriod === null ? (meterData.lastBillingPeriodId ? billingPeriods.find((bp)=> bp.id === meterData.lastBillingPeriodId) : billingPeriods[0]) : null});
  }

  onBillingPeriodSelect=(selectedList, selectedItem)=>{
    this.setState({selectedBillingPeriod: selectedItem});
  }

  onBillingPeriodRemove=(selectedList, selectedItem)=>{
    this.setState({selectedBillingPeriod: selectedItem});
  }

  render() {
    const { className, open, toggle, edit, rentalUnitId = null, meterUploads=false, meter, addPropertyMeter } = this.props;
    const { numErr, nameErr, locationErr, rentalUnits, allocationKeys, propertyMeter, allRentalUnits, isMobile, meterData, hover1, hover2, typeError, openAddAllocationKeyModal, openFixedAllocationKeyModal, selectedAllocationKeyType, selectedAllocationKeyName, selectedAllocationUnits, selectedAllocationId, billingPeriods, selectedBillingPeriod, meterDataFetchComplete } = this.state;
    const { meterNo, location, notes, allocationKeyName, rentalUnitName, allocationKey, meterName } = this.state.meterData;
    console.log(allRentalUnits, rentalUnitName, 'props', this.props, 'allRentalUnits  in render');
    console.log('unitName: ', rentalUnitName);
    console.log('this.props.rentalUnitId: ', this.state.allRentalUnits.length === 0);
    return (
      <CustomModal
        heading={edit ? lang.meter.text_edit_meter_data : lang.meter.text_add_meter_data}
        headingClassName={edit ? 'tooltips meter-edit-heading' : 'tooltips meter-add-heading'}
        open={open}
        toggle={toggle}
        className={`${className}`}
      >
        <div>
          <Form onSubmit={(e) => this.handleSubmit(e)} noValidate>
            <div style={{ display: rentalUnitId === null ? 'block' : 'none' }}>
              {' '}
              <Row className="d-none">
                <SwitchContainer
                  className="heating-cost-switch"
                  onChange={() => this.updatePropertyMeter()}
                  checked={propertyMeter}
                  switchText={lang.meter.is_property_meter}
                  disabled={false}
                />
                <OverlayTrigger placement="top" delay={{ show: 450, hide: 400 }} overlay={this.renderTooltip}>
                  <img src={Help} alt="help" width="16px" height="16px" title="some help text" />
                </OverlayTrigger>
              </Row>
            </div>
            {!edit &&
            <Row>
              <Col xs={12}>
                <Group><span className='add-allocation-sub-headings'>Art des Zählers</span></Group>
                <Stack spacing={0} style={{marginBottom: '20px'}}>
                  <Group grow noWrap style={{margin: '20px 0px 5px'}}>
                    {/* <Group position='center'
                      align={'flex-start'}
                      className={isMobile ? 'meter-type-button-mobile' : 'meter-type-button'}
                      onMouseEnter={()=> this.state.allRentalUnits.length > 0 && this.handleMouseEnter('hover1')}
                      onMouseLeave={()=> this.state.allRentalUnits.length > 0 && this.handleMouseLeave('hover1')}
                      onClick={()=> {
                        if(this.state.allRentalUnits.length > 0){
                          let {meterData} = this.state;
                          meterData.meterType = 'RENTAL_UNIT';
                          this.setState({meterData, typeError: false});
                        }else{
                          showNotification({
                            message: <span>Um Mieterzähler anzulegen, müssen Sie zunächst <span style={{cursor: 'pointer', color: '#5555FF'}} onClick={()=> this.props.history.push('/user')}>Mieteinheiten erstellen.</span></span>,
                            icon: <img src={RedCross} alt='red-cross'/>,
                            id: 'addRentalUnit'
                          });
                        }
                      }}
                      style={{background: this.state.allRentalUnits.length === 0 ? '#f7f7fa' : meterData.meterType === 'RENTAL_UNIT' && '#E3E3FC', borderColor: typeError && !hover1 && meterData.meterType !== 'RENTAL_UNIT' && '#dc3545', border: hover1 && '1px #5555FF solid'}}>
                          <Stack spacing={'5px'}>
                              <Group position={'apart'} noWrap align={'flex-start'}>
                                  <span style={{fontWeight: '600', fontFamily: 'Inter, sans-sarif', fontSize: '16px', color: meterData.meterType === 'RENTAL_UNIT' ? '#5555FF' : hover1 ? '#5555FF' : '#252563'}}>Mieterzähler</span>
                                  <img style={{marginTop: '3px'}} src={meterData.meterType === 'RENTAL_UNIT' ? MultipleTenantsBlue : hover1 ? MultipleTenantsBlue : MultipleTenants} width='16px' height={'16px'} />
                              </Group>
                              <span style={{textAlign: '', fontWeight: '400', color: meterData.meterType === 'RENTAL_UNIT' ? '#252563' : '#252563'}}>Sie wollen Verbräuche eines Mieters (z.B. Raumheizung) erfassen.</span>
                          </Stack>
                    </Group> */}
                    <SimpleGrid cols={2}>
                      <CardSelection
                      title={'Mieterzähler'}
                      subText={'Sie wollen Verbräuche eines Mieters (z.B. Raumheizung) erfassen.'}
                      icon={MultipleTenants}
                      blueIcon={MultipleTenantsBlue}
                      hover={hover1}
                      hoverName={'hover1'}
                      selectedType={meterData.meterType}
                      type={'RENTAL_UNIT'}
                      disabled={this.state.allRentalUnits.length === 0}
                      onClick={()=> {
                        if(this.state.allRentalUnits.length > 0){
                          let {meterData} = this.state;
                          meterData.meterType = 'RENTAL_UNIT';
                          this.setState({meterData, typeError: false});
                        }else{
                          showNotification({
                            message: <span>Um Mieterzähler anzulegen, müssen Sie zunächst <span style={{cursor: 'pointer', color: '#5555FF'}} onClick={()=> this.props.history.push('/user')}>Mieteinheiten erstellen.</span></span>,
                            icon: <img src={RedCross} alt='red-cross'/>,
                            id: 'addRentalUnit'
                          });
                        }
                      }}
                      handleMouseEnter={(hoverName)=> this.state.allRentalUnits.length > 0 && this.handleMouseEnter(hoverName)}
                      handleMouseLeave={(hoverName)=> this.state.allRentalUnits.length > 0 && this.handleMouseLeave(hoverName)}
                      isMobile={isMobile}
                      typeError={typeError}
                      />
                      <CardSelection
                      title={'Hauszähler'}
                      subText={'Sie wollen Verbräuche des Mietshauses (z.B. Allgemeinstrom) erfassen.'}
                      icon={PropertyIcon}
                      blueIcon={PropertyIconBlue}
                      hover={hover2}
                      hoverName={'hover2'}
                      selectedType={meterData.meterType}
                      type={'PROPERTY'}
                      disabled={false}
                      onClick={()=> {
                        let {meterData} = this.state;
                        meterData.meterType = 'PROPERTY';
                        this.setState({meterData, typeError: false});
                      }}
                      handleMouseEnter={(hoverName)=> this.handleMouseEnter(hoverName)}
                      handleMouseLeave={(hoverName)=> this.handleMouseLeave(hoverName)}
                      isMobile={isMobile}
                      typeError={typeError}
                      />
                    </SimpleGrid>
                    {/* <Group position='center'
                    align={'flex-start'}
                    className={isMobile ? 'meter-type-button-mobile' : 'meter-type-button'}
                    onMouseEnter={()=> this.handleMouseEnter('hover2')}
                    onMouseLeave={()=> this.handleMouseLeave('hover2')}
                    onClick={()=> {
                      let {meterData} = this.state;
                      meterData.meterType = 'PROPERTY';
                      this.setState({meterData, typeError: false});
                    }}
                    style={{background: meterData.meterType === 'PROPERTY' && '#E3E3FC', borderColor: typeError && !hover2 && meterData.meterType !== 'PROPERTY' && '#dc3545', border: hover2 && '1px #5555FF solid'}}>
                        <Stack spacing={'5px'}>
                            <Group position={'apart'} noWrap align={'flex-start'}>
                                <span style={{fontWeight: '600', fontFamily: 'Inter, sans-sarif', fontSize: '16px', color: meterData.meterType === 'PROPERTY' ? '#5555FF' : hover2 ? '#5555FF' : '#252563'}}>Hauszähler</span>
                                <img style={{marginTop: '3px'}} src={meterData.meterType === 'PROPERTY' ? PropertyIconBlue : hover2 ? PropertyIconBlue : PropertyIcon} width='16px' height={'16px'} />
                            </Group>
                            <span style={{textAlign: '', fontWeight: '400', color: meterData.meterType === 'PROPERTY' ? '#252563' : '#252563'}}>Sie wollen Verbräuche des Mietshauses (z.B. Allgemeinstrom) erfassen.</span>
                        </Stack>
                    </Group> */}
                  </Group>
                  {typeError && 
                  <Group style={{width: '100%',
                    fontSize: '80%',
                    color: '#dc3545'}}>
                    Bitte Auswahl treffen
                  </Group>}
                  <Group>
                    {this.state.allRentalUnits.length === 0 && (
                      <p style={{cursor: 'pointer', marginBottom: '0px', fontSize: '80%', color: '#727293'}}>Um Mieterzähler anzulegen, müssen Sie zunächst <span style={{color: '#5555FF'}} onClick={()=> this.props.history.push('/user')}>Mieteinheiten erstellen.</span></p>
                    )}
                  </Group>
                </Stack>
              </Col>
            </Row>}
            <Group style={{marginBottom: '20px'}}><span className='add-allocation-sub-headings'>Zählerinformationen</span></Group>
            {isMobile ? 
            <div className="modal-inner-body meter-modal">
              {meterData.meterType !== 'PROPERTY' && 
              <Row>
                <Col xs={12}>
                  <Form.Group>
                    <div className='tooltips meter-rental-unit'><Form.Label className="form-rental-unit"> {lang.meter.text_Rental_Unit}</Form.Label></div>
                    {/* <Form.Control
                      className="form-rental-unit-control"
                      as="select"
                      disabled={rentalUnits.length > 0 ? false : true}
                      value={rentalUnitId}
                      onChange={(e) => this.onInputChange('rentalUnitId', e.target.value)}
                    >
                      {rentalUnits.map((rentalUnitRow) => {
                        return (
                          <option value={rentalUnitRow.id} className="form-rental-unit-value">
                            {rentalUnitRow.name}
                          </option>
                        );
                      })}
                    </Form.Control> */}
                    <UncontrolledDropdown
                      direction="down"
                      id="keyid"
                      onBlur={() => {
                        this.setState({
                          DropdownState: document
                            .getElementById('keyid')
                            .firstElementChild.getAttribute('aria-expanded'),
                        });
                      }}
                      onFocus={() => {
                        this.setState({
                          DropdownState: document
                            .getElementById('keyid')
                            .firstElementChild.getAttribute('aria-expanded'),
                        });
                      }}
                      onClick={() => {
                        this.setState({
                          DropdownState2: document
                            .getElementById('keyid')
                            .firstElementChild.getAttribute('aria-expanded'),
                        });
                      }}
                    >
                      <DropdownToggle
                        className="property-single_dropdown form-control"
                        // disabled={rentalUnitId ? true : false}
                        style={{
                          border: this.state.rentalUnitNameErr === true ? '1px solid #dc3545' : null,
                        }}
                        disabled={rentalUnitId || propertyMeter ? true : false}
                      >
                        <span>{rentalUnitName ? rentalUnitName : lang.newKeys.text_select}</span>
                        {/* <span>{currentMeter ? currentMeter : lang.newKeys.text_select}</span> */}
                        <span>
                          {this.state.DropdownState === 'true' ? (
                            <img src={downArrow} alt="" />
                          ) : (
                            <img src={uparrow} alt="" />
                          )}
                        </span>
                      </DropdownToggle>
                      <DropdownMenu className="property-single-dropdown-menu rental-unit" direction="down">
                        {this.state.allRentalUnits.length > 0 &&
                          this.state.allRentalUnits
                            // .sort((a, b) => (a.rentalUnitName < b.rentalUnitName))
                            .map((rentalUnitRow) => {
                              // console.log(rentalUnitRow, 'rental unit name');
                              return (
                                <DropdownItem
                                  key={rentalUnitRow.id}
                                  className="px-0 form-rental-unit-value"
                                  value={rentalUnitRow.id}
                                  onClick={(e) =>
                                    this.setState({
                                      meterData: {
                                        ...this.state.meterData,
                                        rentalUnitId: rentalUnitRow.id,
                                        rentalUnitName: rentalUnitRow.name,
                                      },
                                    })
                                  }
                                >
                                  <span className="property-single-dropdown-options">{rentalUnitRow.name}</span>
                                </DropdownItem>
                              );
                            })}{' '}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    <div>
                      {this.state.allRentalUnits.length === 0 && (
                        <p style={{cursor: 'pointer', marginBottom: '0px', fontSize: '80%', color: '#727293'}}>Um Mieterzähler anzulegen, müssen Sie zunächst <span style={{color: '#5555FF'}} onClick={()=> this.props.history.push('/user')}>Mieteinheiten erstellen.</span></p>
                      )}
                      {/* {this.state.rentalUnitNameErr === true ? <p>{lang.newKeys.text_error_msg_tenants_rent}</p> : null} */}
                    </div>
                  </Form.Group>
                </Col>
              </Row>}
              <Row>
                <Col xs={12}>
                  <Form.Group>
                  <div className='tooltips meter-number'><Form.Label>{lang.meter.text_number} </Form.Label></div>
                    <Form.Control
                      type="text"
                      isInvalid={numErr}
                      placeholder={'z.B. 123456789'}
                      value={meterUploads ? meter.number : meterNo}
                      onChange={(e) => this.onInputChange('meterNo', e.target.value)}
                      onKeyPress={this.handleEnterKeyPress}
                    />
                    <Form.Control.Feedback className="err" type="invalid">
                      {' '}
                      {lang.meter.text_invalid_number}{' '}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col xs={12}>
                  <Form.Group>
                  <div className='tooltips meter-number'><Form.Label>{'Bezeichnung (optional)'} </Form.Label></div>
                    <Form.Control
                      type="text"
                      isInvalid={nameErr}
                      placeholder={'z.B. WMZ Bad'}
                      value={meterUploads ? meter.meterName : meterName}
                      onChange={(e) => this.onInputChange('meterName', e.target.value)}
                      onKeyPress={this.handleEnterKeyPress}
                    />
                    <Form.Control.Feedback className="err" type="invalid">
                      {' '}
                      {lang.meter.text_invalid_number}{' '}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col xs={12}>
                  <Form.Group>
                  <div className='tooltips meter-allocation-key'><Form.Label>{lang.utility.text_allocation_key}</Form.Label></div>
                    {/* <Form.Control
                      as="select"
                      value={allocationKey}
                      disabled={allocationKeys.length > 0 ? false : true}
                      className="form-rental-unit-value"
                      onChange={(e) => this.onInputChange('allocationKey', e.target.value)}
                    >
                      {allocationKeys.map((allocationKeysRow) => {
                        return (
                          <option value={allocationKeysRow.id} className="form-rental-unit-value">
                            {allocationKeysRow.allocationKeyName}
                          </option>
                        );
                      })}
                    </Form.Control> */}

                    <UncontrolledDropdown
                      direction="down"
                      id="keyid2"
                      onBlur={() => {
                        this.setState({
                          DropdownState2: document
                            .getElementById('keyid2')
                            .firstElementChild.getAttribute('aria-expanded'),
                        });
                      }}
                      onFocus={() => {
                        this.setState({
                          DropdownState2: document
                            .getElementById('keyid2')
                            .firstElementChild.getAttribute('aria-expanded'),
                        });
                      }}
                      onClick={() => {
                        this.setState({
                          DropdownState2: document
                            .getElementById('keyid2')
                            .firstElementChild.getAttribute('aria-expanded'),
                        });
                      }}
                    >
                      <DropdownToggle
                        className="property-single_dropdown form-control"
                        style={{
                          border: this.state.allocationkeyError === true ? '1px solid #dc3545' : null,
                        }}
                      >
                        {/* <span>{allocationKeys.length === 0 lang.newKeys.text_select : allocationKeyName}</span> */}
                        <span>{allocationKeyName ? allocationKeyName : lang.newKeys.text_select}</span>
                        <div className="dropdown-arrow-height">
                          {this.state.DropdownState2 === 'true' ? (
                            <img src={downArrow} alt="" />
                          ) : (
                            <img src={uparrow} alt="" />
                          )}
                        </div>
                      </DropdownToggle>
                      <DropdownMenu className="property-single-dropdown-menu allocation-dropdown-menu" direction="down">
                        {allocationKeys
                          .sort((a, b) =>
                            (a['allocationKeyName'] || '')
                              .toString()
                              .localeCompare((b['allocationKeyName'] || '').toString())
                          )
                          .filter((allocationKey)=> meterData.meterType === 'PROPERTY' ? allocationKey.meterType === 'PROPERTY' : allocationKey.meterType === 'RENTAL_UNIT')
                          .map((allocationKeysRow, index) => {
                            // console.log(allocationKeysRow, 'allocationKeysRow');
                            console.log('DropdownMenu: ', index, allocationKeys.length - 1);
                            return (
                              <DropdownItem
                                key={allocationKeysRow.id}
                                className="px-0 form-rental-unit-value"
                                value={allocationKeysRow.id}
                                onClick={(e) =>
                                  this.setState({
                                    meterData: {
                                      ...this.state.meterData,
                                      allocationKey: allocationKeysRow,
                                      allocationKeyName: allocationKeysRow.allocationKeyName,
                                    },
                                  })
                                }
                              >
                                <span className="property-single-dropdown-options">
                                  {allocationKeysRow.allocationKeyName} {(allocationKeysRow.units && allocationKeysRow.units !== '-') ? `(${allocationKeysRow.units})` : ''}
                                  {/* {allocationKeysRow.allocationKeyType !== 'NON_HEATING' && (allocationKeysRow.units && allocationKeysRow.units !== '-') && `(${allocationKeysRow.units})`} */}
                                </span>
                              </DropdownItem>
                            );
                          })}
                          <DropdownItem className="example allocation-cost-type-modal">
                            <ColorButton
                              onClick={() => {
                                this.toggleAddAllocationKeyModal();
                              }}
                              text={lang.property.allocationKeys_text_add_allocationkeys}
                              className="add-allocationkey-dropdown-btn"
                            />
                          </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    <div className="heatingDropdown_errormsg">
                      {this.state.allocationkeyError === true ? <p>{lang.newKeys.select_allocation_key_text}</p> : null}
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Form.Group>
                    <Form.Label> {lang.meter.text_location} </Form.Label>
                    <Form.Control
                      type="text"
                      isInvalid={locationErr}
                      placeholder={'z.B. Keller, Bad'}
                      value={location}
                      onChange={(e) => this.onInputChange('location', e.target.value)}
                      onKeyPress={this.handleEnterKeyPress}
                    />
                    <Form.Control.Feedback className="err" type="invalid">
                      {' '}
                      {lang.meter.text_invalid_location}{' '}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Form.Group>
                    <Form.Label> {lang.meter.text_note}</Form.Label>
                    <Form.Control
                      type="textarea"
                      value={notes}
                      as="textarea"
                      onChange={(e) => this.onInputChange('notes', e.target.value)}
                      style={{ height: 80, width: '100%', minHeight: '36px' }}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </div>

            :
            <div className="modal-inner-body meter-modal">
              <Row>
                {meterData.meterType !== 'PROPERTY' && <Col xs={6}>
                  <Form.Group>
                  <div className='tooltips meter-rental-unit'><Form.Label className="form-rental-unit"> {lang.meter.text_Rental_Unit}</Form.Label></div>
                    {/* <Form.Control
                      className="form-rental-unit-control"
                      as="select"
                      disabled={rentalUnits.length > 0 ? false : true}
                      value={rentalUnitId}
                      onChange={(e) => this.onInputChange('rentalUnitId', e.target.value)}
                    >
                      {rentalUnits.map((rentalUnitRow) => {
                        return (
                          <option value={rentalUnitRow.id} className="form-rental-unit-value">
                            {rentalUnitRow.name}
                          </option>
                        );
                      })}
                    </Form.Control> */}
                    <UncontrolledDropdown
                      direction="down"
                      id="keyid"
                      onBlur={() => {
                        this.setState({
                          DropdownState: document
                            .getElementById('keyid')
                            .firstElementChild.getAttribute('aria-expanded'),
                        });
                      }}
                      onFocus={() => {
                        this.setState({
                          DropdownState: document
                            .getElementById('keyid')
                            .firstElementChild.getAttribute('aria-expanded'),
                        });
                      }}
                      onClick={() => {
                        this.setState({
                          DropdownState2: document
                            .getElementById('keyid')
                            .firstElementChild.getAttribute('aria-expanded'),
                        });
                      }}
                    >
                      <DropdownToggle
                        className="property-single_dropdown form-control"
                        // disabled={rentalUnitId ? true : false}
                        style={{
                          border: this.state.rentalUnitNameErr === true ? '1px solid #dc3545' : null,
                        }}
                        disabled={rentalUnitId || propertyMeter ? true : false || this.state.allRentalUnits.length === 0}
                      >
                        <span>{rentalUnitName ? rentalUnitName : lang.newKeys.text_select}</span>
                        {/* <span>{currentMeter ? currentMeter : lang.newKeys.text_select}</span> */}
                        <span>
                          {this.state.DropdownState === 'true' ? (
                            <img src={downArrow} alt="" />
                          ) : (
                            <img src={uparrow} alt="" />
                          )}
                        </span>
                      </DropdownToggle>
                      <DropdownMenu className="property-single-dropdown-menu rental-unit" direction="down">
                        {this.state.allRentalUnits.length > 0 &&
                          this.state.allRentalUnits
                            // .sort((a, b) => (a.rentalUnitName < b.rentalUnitName))
                            .map((rentalUnitRow) => {
                              // console.log(rentalUnitRow, 'rental unit name');
                              return (
                                <DropdownItem
                                  key={rentalUnitRow.id}
                                  className="px-0 form-rental-unit-value"
                                  value={rentalUnitRow.id}
                                  onClick={(e) =>
                                    this.setState({
                                      meterData: {
                                        ...this.state.meterData,
                                        rentalUnitId: rentalUnitRow.id,
                                        rentalUnitName: rentalUnitRow.name,
                                      },
                                    })
                                  }
                                >
                                  <span className="property-single-dropdown-options">{rentalUnitRow.name}</span>
                                </DropdownItem>
                              );
                            })}{' '}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    <div>
                      {this.state.allRentalUnits.length === 0 && (
                        <p style={{cursor: 'pointer', marginBottom: '0px', fontSize: '80%', color: '#727293'}}>Um Mieterzähler anzulegen, müssen Sie zunächst <span style={{color: '#5555FF'}} onClick={()=> this.props.history.push('/user')}>Mieteinheiten erstellen.</span></p>
                      )}
                      {/* {this.state.rentalUnitNameErr === true ? <p>{lang.newKeys.text_error_msg_tenants_rent}</p> : null} */}
                    </div>
                  </Form.Group>
                </Col>}
                <Col xs={meterData.meterType === 'PROPERTY' ? 12 : 6}>
                  <Form.Group>
                  <div className='tooltips meter-number'><Form.Label>{lang.meter.text_number} </Form.Label></div>
                  {console.log('meterUploads:123 ', meterUploads, meter)}
                    <Form.Control
                      type="text"
                      isInvalid={numErr}
                      placeholder={'z.B. 123456789'}
                      value={meterUploads ? meter.number : meterNo}
                      onChange={(e) => this.onInputChange('meterNo', e.target.value)}
                      onKeyPress={this.handleEnterKeyPress}
                    />
                    <Form.Control.Feedback className="err" type="invalid">
                      {' '}
                      {lang.meter.text_invalid_number}{' '}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Form.Group>
                  <div className='tooltips meter-number'><Form.Label>{'Bezeichnung (optional)'} </Form.Label></div>
                    <Form.Control
                      type="text"
                      isInvalid={nameErr}
                      placeholder={'z.B. WMZ Bad'}
                      value={meterUploads ? meter.meterName : meterName}
                      onChange={(e) => this.onInputChange('meterName', e.target.value)}
                      onKeyPress={this.handleEnterKeyPress}
                    />
                    <Form.Control.Feedback className="err" type="invalid">
                      {' '}
                      {lang.meter.text_invalid_number}{' '}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Form.Group>
                  <div className='tooltips meter-allocation-key'><Form.Label>{lang.utility.text_allocation_key}</Form.Label></div>
                    {/* <Form.Control
                      as="select"
                      value={allocationKey}
                      disabled={allocationKeys.length > 0 ? false : true}
                      className="form-rental-unit-value"
                      onChange={(e) => this.onInputChange('allocationKey', e.target.value)}
                    >
                      {allocationKeys.map((allocationKeysRow) => {
                        return (
                          <option value={allocationKeysRow.id} className="form-rental-unit-value">
                            {allocationKeysRow.allocationKeyName}
                          </option>
                        );
                      })}
                    </Form.Control> */}

                    <UncontrolledDropdown
                      direction="down"
                      id="keyid2"
                      onBlur={() => {
                        this.setState({
                          DropdownState2: document
                            .getElementById('keyid2')
                            .firstElementChild.getAttribute('aria-expanded'),
                        });
                      }}
                      onFocus={() => {
                        this.setState({
                          DropdownState2: document
                            .getElementById('keyid2')
                            .firstElementChild.getAttribute('aria-expanded'),
                        });
                      }}
                      onClick={() => {
                        this.setState({
                          DropdownState2: document
                            .getElementById('keyid2')
                            .firstElementChild.getAttribute('aria-expanded'),
                        });
                      }}
                    >
                      <DropdownToggle
                        className="property-single_dropdown form-control"
                        style={{
                          border: this.state.allocationkeyError === true ? '1px solid #dc3545' : null,
                        }}
                      >
                        {/* <span>{allocationKeys.length === 0 lang.newKeys.text_select : allocationKeyName}</span> */}
                        <span>{allocationKeyName ? `${allocationKeyName} ${(allocationKey.units && allocationKey.units !== '-') ? `(${allocationKey.units})` : ''}` : lang.newKeys.text_select}</span>
                        <div className="dropdown-arrow-height">
                          {this.state.DropdownState2 === 'true' ? (
                            <img src={downArrow} alt="" />
                          ) : (
                            <img src={uparrow} alt="" />
                          )}
                        </div>
                      </DropdownToggle>
                      <DropdownMenu className="property-single-dropdown-menu allocation-dropdown-menu" direction="down">
                        {console.log('allocationKeys: ', allocationKeys)}
                        {allocationKeys
                          .sort((a, b) =>
                            (a['allocationKeyName'] || '')
                              .toString()
                              .localeCompare((b['allocationKeyName'] || '').toString())
                          )
                          .filter((allocationKey)=> meterData.meterType === 'PROPERTY' ? allocationKey.meterType === 'PROPERTY' : allocationKey.meterType === 'RENTAL_UNIT')
                          .map((allocationKeysRow, index) => {
                            // console.log(allocationKeysRow, 'allocationKeysRow');
                            console.log('DropdownMenu: ', index, allocationKeys.filter((allocationKey)=> meterData.meterType === 'PROPERTY' ? allocationKey.meterType === 'PROPERTY' : allocationKey.meterType === 'RENTAL_UNIT').length);
                            return (
                              <DropdownItem
                                key={allocationKeysRow.id}
                                className="px-0 form-rental-unit-value"
                                value={allocationKeysRow.id}
                                onClick={(e) =>
                                  this.setState({
                                    meterData: {
                                      ...this.state.meterData,
                                      allocationKey: allocationKeysRow,
                                      allocationKeyName: allocationKeysRow.allocationKeyName,
                                    },
                                  })
                                }
                              >
                                <span className="property-single-dropdown-options">
                                  {allocationKeysRow.allocationKeyName} {(allocationKeysRow.units && allocationKeysRow.units !== '-') ? `(${allocationKeysRow.units})` : ''}
                                  {/* {allocationKeysRow.allocationKeyType !== 'NON_HEATING' && (allocationKeysRow.units && allocationKeysRow.units !== '-') && `(${allocationKeysRow.units})`} */}
                                </span>
                              </DropdownItem>
                            );
                          })}
                          <ColorButton
                            onClick={(e) => {
                              e.preventDefault();
                              this.toggleAddAllocationKeyModal();
                            }}
                            text={lang.property.allocationKeys_text_add_allocationkeys}
                            className="add-allocationkey-dropdown-btn"
                          />
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    <div className="heatingDropdown_errormsg">
                      {this.state.allocationkeyError === true ? <p>{lang.newKeys.select_allocation_key_text}</p> : null}
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Form.Group>
                    <Form.Label> {lang.meter.text_location} </Form.Label>
                    <Form.Control
                      type="text"
                      isInvalid={locationErr}
                      placeholder={'z.B. Keller, Bad'}
                      value={location}
                      onChange={(e) => this.onInputChange('location', e.target.value)}
                      onKeyPress={this.handleEnterKeyPress}
                    />
                    <Form.Control.Feedback className="err" type="invalid">
                      {' '}
                      {lang.meter.text_invalid_location}{' '}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Form.Group>
                    <Form.Label> {lang.meter.text_note}</Form.Label>
                    <Form.Control
                      type="textarea"
                      value={notes}
                      as="textarea"
                      onChange={(e) => this.onInputChange('notes', e.target.value)}
                      onKeyPress={this.handleEnterKeyPress}
                      style={{ height: 80, width: '100%', minHeight: '36px' }}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </div>}
            {edit && 
            <Stack spacing={5}>
              {meterDataFetchComplete ?
              <Accordion classNames={{
                control: 'meter-archive-accordion-control',
                icon: 'meter-archive-accordion-icon',
                contentInner: 'meter-archive-accordion-icon-contentInner',
              }}
              initialItem={selectedBillingPeriod ? 0 : null}>
                <Accordion.Item label={<Group style={{marginBottom: '10px', marginTop: '10px'}} spacing={8}><img src={ArchiveIcon} style={{width: '13px', marginTop: '1px'}}/><span className='add-allocation-sub-headings add-meter-archive-heading tooltips'>Archivieren</span></Group>} iconPosition={'right'}>
                  <Stack spacing={10}>
                    <Group spacing={5}>
                      <Checkbox
                      checked={selectedBillingPeriod}
                      style={{
                        color: selectedBillingPeriod ? '#5555FF' : '#9A9AB5', 
                        padding: '0px'
                      }}
                      onClick={()=> this.handleCheckBox()}
                      />
                      <span className='archive-meter-checkbox-label'>Zähler archivieren</span>
                    </Group>
                    <Group grow className="modal-inner-body meter-modal" styles={{child: {marginBottom: 0}}}>
                      <Form.Group>
                        <Form.Label>Letzte Abrechnungsperiode</Form.Label>
                        <SelectDropdown
                        options={billingPeriods} // Options to display in the dropdown
                        selectedValue={selectedBillingPeriod} // Preselected value to persist in dropdown
                        onSelect={this.onBillingPeriodSelect} // Function will trigger on select event
                        onRemove={this.onBillingPeriodRemove} // Function will trigger on remove event
                        displayValue="label"
                        placeholder={lang.newKeys.text_select}
                        showSelectedHighlight={false}
                        ddHeaderClassName={`custom-select-dropdown ${'custom-select-dropdown-pt-0'}`}
                        disable={selectedBillingPeriod === null ? true : false}
                        removeLongTextHiding={true}
                        />
                      </Form.Group>
                    </Group>
                  </Stack>
                </Accordion.Item>
              </Accordion>
              :
              <Group style={{width: '100%', height: '173.7px'}} position={'center'}>
                <Loader/>
              </Group>}
              {/* <Group style={{marginBottom: '10px', marginTop: '20px'}}><span className='add-allocation-sub-headings'>Archivieren</span></Group>
              {
              <Stack spacing={10}>
                <Group spacing={5}>
                  <Checkbox
                  checked={selectedBillingPeriod}
                  style={{
                    color: selectedBillingPeriod ? '#5555FF' : '#9A9AB5', 
                    padding: '0px'
                  }}
                  onClick={()=> this.handleCheckBox()}
                  />
                  <span className='archive-meter-checkbox-label'>Zähler archivieren</span>
                </Group>
                <Group grow className="modal-inner-body meter-modal" styles={{child: {marginBottom: 0}}}>
                  <Form.Group>
                    <Form.Label>Letzte Abrechnungsperiode</Form.Label>
                    <SelectDropdown
                    options={billingPeriods} // Options to display in the dropdown
                    selectedValue={selectedBillingPeriod} // Preselected value to persist in dropdown
                    onSelect={this.onBillingPeriodSelect} // Function will trigger on select event
                    onRemove={this.onBillingPeriodRemove} // Function will trigger on remove event
                    displayValue="label"
                    placeholder={lang.newKeys.text_select}
                    showSelectedHighlight={false}
                    ddHeaderClassName={`custom-select-dropdown ${'custom-select-dropdown-pt-0'}`}
                    disable={selectedBillingPeriod === null ? true : false}
                    />
                  </Form.Group>
                </Group>
              </Stack>} */}
              
            </Stack>}
            <div className="cancel_addbtn" style={{position: 'relative', padding: 0}}>
              <div>
                <ColorButton onClick={() => toggle()} className="gray-btn" text={lang.general.text_cancel} />
              </div>
              <div id="meter-button-container">
                {' '}
                <ColorButton
                  buttonConfig={{ type: 'submit' }}
                  className="purple-btn"
                  text={edit ? lang.meter.text_save : lang.meter.text_add}
                />
              </div>
            </div>
          </Form>
        </div>
        {openAddAllocationKeyModal && (
          <AddAllocationKeyModal
            record={null}
            className="add_allocation_key_modal"
            edit={false}
            allocationData={allocationKeys}
            open={openAddAllocationKeyModal}
            toggle={this.toggleAddAllocationKeyModal}
            parent={this}
            callBackFunction={(response) => {
              this.getAllocationKeys(response);
            }}
            selectedType={(type, id, units, name)=> (type === 'FIXED_RENTAL_UNIT' || type === 'FIXED_TENANT') && this.setState({selectedAllocationKeyType: type, openFixedAllocationKeyModal: true, selectedAllocationId: id, selectedAllocationUnits: units, selectedAllocationKeyName: name, newAllocationKey: true})}
          />
        )}
        {openFixedAllocationKeyModal &&
          <FixedAllocationKeyModal
          open={openFixedAllocationKeyModal}
          className={'individual-allocation-key-modal'}
          type={selectedAllocationKeyType}
          allocationKeyId={selectedAllocationId}
          toggle={()=> this.setState({openFixedAllocationKeyModal: false, selectedAllocationKeyType: '', selectedAllocationKeyName: '', selectedAllocationUnits: '', selectedAllocationId: ''})}
          unit={selectedAllocationUnits}
          name={selectedAllocationKeyName}
          history={this.props.history}
          />}
      </CustomModal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    propertySelected: state.generalReducer.property,
  };
};

export default connect(mapStateToProps)(MeterModal);
