import React from 'react';
import { CardTitle ,CardSubtitle,CardHeader} from 'reactstrap';

export default function MobileHeadingContainer({ subheading,subheadingbadge, subheadingClassName,heading,sideheadings, rightContent, className,badge,headingIcon, headingIconStyle, bagdeClassName,iconHandler, isMobile, headingButton, headingStyling }) {
  return (
    <div className={`heading-container ${className}`} style={{...{marginRight: isMobile && '30px'}, ...headingStyling}}>
        <div className={`heading-text-wrapper mt-${isMobile ? '0' : '0'}`}>
            <div style={{display: 'flex'}}>
                <CardTitle className='heading mb-0' style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: window.innerWidth <= 350 ? 'column' : 'row'}} >
                        {headingIcon && <img className='heading-icon' src={headingIcon} style={headingIconStyle} onClick={()=>iconHandler()} alt='heading' />}
                        <span>{heading}</span>
                        {sideheadings}
                        {badge}
                </CardTitle>
                {headingButton}
            </div>
            {subheading && 
            <div>
            <span className={`${subheadingClassName} subheading`}>{subheading}</span>{subheadingbadge}
            </div>
             }
            {/* <CardHeader tag="h6" className="mb-2 text-muted" style={{ alignSelf: 'flex-start'}}>{sideheadings}</CardHeader> */}
        </div>
        <div className='heading-right-content' style={{ alignSelf: 'flex-start'}}>
            {rightContent}
        </div>
  </div>
  );
}

MobileHeadingContainer.defaultProps={
    heading:'No heading',  
    iconHandler:()=>{},
}