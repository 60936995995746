import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  UtilityBillsTableRow,
  EmptyStateContainer,
  Loader
} from '../../../../neoverv';
import tap from '../../../../assets/images/module/general/TapIconBlue.svg';
import MeterIcon from '../../../../assets/images/module/general/blueEmptyMeter.svg';
import moment from 'moment';
import { setTenantBills } from '../../../../redux/action/UtilityBilling/UtilityBillingActions';
import Autoload from '../../../../neoverv/Autoload';
import Logo from '../../../../assets/images/module/general/Logo.svg';
import html2canvas from 'html2canvas';
import jsPdf from 'jspdf';
//import Html from 'react-pdf-html';
import { PDFDownloadLink, Document, Page } from 'react-pdf';
import { browserName } from "react-device-detect";

import $ from 'jquery';
import {
  Table, 
  TableBody, 
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@material-ui/core';
import { showNotification } from '@mantine/notifications';
import RedCross from '../../../../assets/images/module/general/RedCross.svg';
import GreenTick from '../../../../assets/images/module/general/GreenTick.svg';
import { formatNumber } from '../../../../helpers/stringsManipulations';
import UtilityBillTable from './UtilityBillTable';
import MeterReadingsTable from './MeterReadingsTable';
import MeterDataHandler from '../../../../neoverv/helpers/DataHandler/Meter';
import { PDFDocument, rgb } from 'pdf-lib';
import CompositionOfHeatingCostsTable from './CompositionOfHeatingCostsTable';
import CostSplitWarmWaterAndHeatingTable from './CostSplitWarmWaterAndHeatingTable';
import SplitBaseConsumptionCosts from './SplitBaseConsumptionCosts';


var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
const { yearDifferenceMoment, checkProperty, loader } = Autoload.MainHelper;
const MOBILE_SIZE = 766;
let allBills = [];
const ref = React.createRef();
const btnRef = React.createRef();

export class UtilityBillsTable extends Component {
  static propTypes = {
    prop: PropTypes,
  };

  tableHeadCellStyling = {
    color: 'rgb(114, 114, 147)',//'#727293',
    padding: '1mm',
    fontWeight: '500',
    whiteSpace: 'nowrap',
    margin: '0px',
    fontSize: '7pt',
    lineHeight: '11pt',
    border: '1px solid #DADAE6',
    textTransform: 'capitalize',
    height: '0px',
    borderBottom: 'none',
    borderRight: 'none'
  }

  tableBodyCellStyling = {
    color: '#0e0e1d',
    padding: '5px 9px 5px 9px',
    fontWeight: '400',
    whiteSpace: 'nowrap',
    fontSize: '10px',
    lineHeight: '20px',
    border: '1px solid #DADAE6',
    textTransform: 'capitalize',
    height: '38px',
    minWidth: '90px',
    borderBottom: 'none',
    borderRight: 'none'
  }

  state = {
    selectedTenantID: '1',
    DropdownState: 'true',
    isMobile: window.innerWidth <= MOBILE_SIZE,
    showEmpty: true,
    utilityData: {
      date: '',
      recordStatus: 'last',
    },
    utilityBillTableData: null,
    selectedTenantData: null,
    invoiceData: null,
    warmWaterCostTypeFound: false,
    readings: [],
    files: [],
    heatingCosts:[],

    meterReadingsSwitch: false,
    compositionOfHeatingCostsSwitch: false,
    costSplitWarmWaterAndHeatingSwitch: false,
    splitBaseConsumptionCostsSwitch: false,
    vatSwitch: false,
    laborCostSwitch: false,
    pages: '',
    customImageBase64: null
  };

  componentDidMount() {
    console.log('UtilityBillsTable3: ', this.props.utilityBillingData);
    let data = this.props.utilityBillingData.find((ub) => ub.id == this.props.currentDataId);
    console.log('data_12: ', data);
    //loader(true);
    let url = new URL(window.location.href);
    if(data !== undefined){
      this.setState({
        meterReadingsSwitch: !data.isMeterReadingAndConsumptionInfoHidden,
        compositionOfHeatingCostsSwitch: !data.isHeatingCostInfoHidden,
        costSplitWarmWaterAndHeatingSwitch: !data.isCostSplitWarmWaterAndHeatingCostInfoHidden,
        splitBaseConsumptionCostsSwitch: !data.isSplitBaseConsumptionCostInfoHidden,
        vatSwitch: !data.isVATHidden,
        laborCostSwitch: !data.isLaborCostHidden,
      })
    }
    var loaderWhole = document.getElementById('hello-housing-loader');
    if(!this.props.messagePreview){
      loaderWhole.style.display = 'block';
    }
    // var loaderBackground = document.getElementById('hh_loader_bg');
    // var loader = document.getElementById('hh_loader_spinner');
    // loaderBackground.style.background = 'white';
    // loaderBackground.style.opacity = '1';
    // loader.style.zIndex = '99999';
    const id = this.props.messagePreview ? this.props.billingStatmentId : window.location.pathname.split('/')[2];

    this.setState({pages: this.props.propertySelected.propertyType === 'REGULAR' ? JSON.parse(url.searchParams.get("meterReadings")) ? 
    (JSON.parse(url.searchParams.get("compositionOfHeatingCosts")) || JSON.parse(url.searchParams.get("costSplitWarmWaterAndHeating")) || JSON.parse(url.searchParams.get("splitBaseConsumptionCosts"))) ? 3 : 2 
    : (JSON.parse(url.searchParams.get("compositionOfHeatingCosts")) || JSON.parse(url.searchParams.get("costSplitWarmWaterAndHeating")) || JSON.parse(url.searchParams.get("splitBaseConsumptionCosts"))) ? 
    2 : 1
    : 1}
    // , ()=> {
    //   var downloadStatus = document.getElementById('download_status');
    //   downloadStatus.style.display = 'block';
    //   downloadStatus.textContent=`PDF-Dokumente erstellen ${0} / ${this.state.pages}`;
    // }
    )

    this.props.companyLogoEnable && this.getAccountPicture();
    this.callApis(id);
    // let data = this.props.utilityBillingData.find((ub) => ub.id == this.props.currentDataId);
    // if (this.props.tenantBills.length) {
    //   const selectedTenantData = this.props.tenantBills[0]?.billingData?.tenantBills[0];
    //   this.setState({ selectedTenantData });
    // }
    // console.log('data CDM', data);
    // let diff = yearDifferenceMoment(data.date.split('-')[0])
    // if (data !== undefined) {
    //   this.setState(
    //     {
    //       utilityData: {
    //         date: data && data.date ? data.date : '',
    //         recordStatus: data && data.recordStatus ? data.recordStatus : 'last',
    //       },
    //     },
    //     () => {
    //       this.updateConsumptions();
    //     }
    //   );
    // }

    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps.billingStatmentId !== this.props.billingStatmentId){
      this.callApis(this.props.billingStatmentId);
    }

    // if(prevState.readings.length !== this.state.readings.length){
    //   this.props.setReadings(this.state.readings);
    // }
  }

  getAccountPicture=()=>{
    fetch(Autoload.Setting.account_api_baseurl + this.props.accountData.accountId +'/picture/download', 
    {
      method: 'GET',
      headers: {
        'Content-Type': 'octet-stream',
        'Authorization': Autoload.Utils.getAuthToken()
      }
    }).then(response => {
      response.blob()
      .then(blob => {
        console.log('getAccountPicture: ', blob);
        const url =URL.createObjectURL(blob);
        this.setState({customImageBase64: blob.size > 0 ? url : null});
        console.log('getAccountPicture: url:', url);
        }).catch(e1=> console.log('getAccountPicture: error1: ', e1))
    }).catch(e=> console.log('getAccountPicture: error: ', e))
  }

  calculateTotal=(i)=>{
    let total=0;
    this.state.selectedTenantData.tenantRows.map((row, index)=> {
      if(index <= i){
        total = total + row.tenantCostShare;
      }
    })
    console.log('calculateTotal: ', total);
    return formatNumber(total, true);;
  }

  getInvoiceData = (id) => {
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.ub_api_base_url + 'billingStatements/' + id,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      '_handleGetInvoice',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };

  _handleGetInvoice = (response) => {
    console.log('get billing statement', response);
    if (response.status === false && response.message !== 'Not Found') {
      // Autoload.Redirection.redirection_error();
      Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang.newKeys.text_error_not_found);
    }
    this.setState({ invoiceData: response.result });
  };

  updateConsumptions = () => {
    // loader(true);
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.ub_api_base_url + 'updateConsumptions/' + this.props.currentDataId,
      'PUT',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleUpdateConsumptionsResponse',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };
  handleUpdateConsumptionsResponse = (response) => {
    !response.status && console.log('Update Consumptions Failed');

    //loader(false);
    // this.callApis();
  };

  callApis = (id) => {
    // loader(true);
    allBills = [];
    this.props.divName === 'divToPrint2' && this.getHeatingCosts();
    //get all billing statements
    (this.props.divName === 'divToPrint2' || this.props.divName === 'divToPrint0') && 
    Autoload.HttpRequestAxios.HttpRequest(
      // Autoload.Setting.swagger_mocked_api_baseUrl +
      //   'billingStatements?offset=0&results=10&utilityBillingId=' +
      //   this.props.currentDataId,
      Autoload.Setting.ub_api_base_url + 'billingStatements/' + id,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      '_handleGetBS',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
    this.props.divName === 'divToPrint1' && this._getOccupationReadingTenants(this.props.tenantId);

  };
  _handleGetBS = (response) => {
    console.log('response1 Billing Statement', response);
    if (response.status === false && response.message !== 'Not Found') {
      // Autoload.Redirection.redirection_error();
      Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang.newKeys.text_error_not_found);
      loader(false);
    }
    if (response.result) {
      const bs = response.result;
      this.state.meterReadingsSwitch && this._getOccupationReadingTenants(checkProperty(bs, 'tenantId'));
      let data = {
        tenantID: checkProperty(bs, 'tenantId'),
        tenantName: checkProperty(bs, 'tenantName'),
        stayedFor: checkProperty(checkProperty(bs, 'tenantOccupation'), 'totalDays'),
        totalAllocations: checkProperty(bs, 'totalAllocatedUtilityCosts'),
        prepayments: checkProperty(bs, 'totalAdvancePayments'),
        billingStatementId: checkProperty(bs, 'id'),
        utilityBillingId: checkProperty(bs, 'utilityBillingId'),
        rentalUnitId: checkProperty(bs, 'rentalUnitId'),
        tenantOccupation: checkProperty(bs, 'tenantOccupation'),
      };
      const billingId = checkProperty(bs, 'billingStatementId')
        ? checkProperty(bs, 'billingStatementId')
        : checkProperty(bs, 'id');
      //get pdf for each record
      this._handleGetBSpdf(data.utilityBillingId, billingId);
      this.setState({ invoiceData: response.result });
    } else {
      //store empty array to tenantBills field of redux
      setTimeout(() => {
        this.printDocument(0, 1);
      }, 500);
      this.props.setTenantBills(allBills);
      this.setState({ showEmpty: false });
      //loader(false);
    }
  };

  _handleGetBSpdf = (ubId, bsId) => {
    let url = new URL(window.location.href);
    const {heatingFilter} = this.props//url.searchParams.get("heatingFilter");
    console.log('_handleGetBSpdf: ', heatingFilter);
    //call apis for tenantRows
    heatingFilter === true ?
    Autoload.HttpRequestAxios.HttpRequest(
      // Autoload.Setting.swagger_mocked_api_baseUrl +
      //   'costAllocations/forBillingStatement?utilityBillingId=' +
      //   response.other.BSData.utilityBillingId +
      //   '&billingStatementId=' +
      //   response.other.BSData.billingStatementId,
      Autoload.Setting.ub_api_base_url +
        'costAllocations/forBillingStatement?utilityBillingId=' +
        ubId +
        '&billingStatementId=' +
        bsId,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      '_handleGetBSRows',
      {},
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    )
    : 
    Autoload.HttpRequestAxios.HttpRequest(
      // Autoload.Setting.swagger_mocked_api_baseUrl +
      //   'costAllocations/forBillingStatement?utilityBillingId=' +
      //   response.other.BSData.utilityBillingId +
      //   '&billingStatementId=' +
      //   response.other.BSData.billingStatementId,
      Autoload.Setting.ub_api_base_url +
        'costAllocations/forBillingStatement?utilityBillingId=' +
        ubId +
        '&billingStatementId=' +
        bsId +
        '&filter=non-heating',
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      '_handleGetBSRows',
      {},
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    )
  };
  _handleGetBSRows = (response) => {
    console.log('response2 forBillingStatement', response);
    let formattedResponse = [];
    if (response.status === false) {
      // Autoload.Redirection.redirection_error();
      //Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang.newKeys.text_error_not_found);
      showNotification({
        //title:'Fehler',
        message: 'Es gibt noch keine Betriebskostenabrechnung.',
        icon: <img src={RedCross} alt='red-tick'/>,
      })
      this.state.meterReadingsSwitch === false && setTimeout(() => {
        this.printDocument(0, 1);
      }, 500);
    }

    if (response.result.length > 0) {
      console.log('formattedResponse', formattedResponse);
      formattedResponse = response.result.map((tr) => ({
        // id: checkProperty(tr, 'id'),
        id: checkProperty(tr, 'id'),
        costType: checkProperty(tr, 'costTypeName'),
        totalCost: checkProperty(tr, 'totalCostShare'),
        allocationKey: checkProperty(tr, 'allocationTypeName'),
        totalValue: checkProperty(tr, 'totalConsumption'),
        tenantValue: checkProperty(tr, 'tenantConsumption'),
        unit: checkProperty(tr, 'consumptionUnits'),
        tenantCostShare: checkProperty(tr, 'tenantCostShare'),
        comment: checkProperty(tr, 'comment'),
        utilityBillingId: checkProperty(tr, 'utilityBillingId'),
        costPositionId: checkProperty(tr, 'costPositionId'),
        billingStatementId: checkProperty(tr, 'billingStatementId'),
        tenantName: checkProperty(tr, 'tenantName'),
        rentalUnitId: checkProperty(tr, 'rentalUnitId'),
        rentalUnitName: checkProperty(tr, 'rentalUnitName'),
        tenantOccupation: checkProperty(tr, 'tenantOccupation'),
      }));
      let warmWaterCostTypeFound = formattedResponse.find((row)=> row.costType === 'Warmwasser (Verbrauch)') !== undefined ? true : false;

      //merge data in array
      // allBills.push(mergedData);
      // const data = allBills.find((ub) => ub.utilityBillingId == this.props.currentDataId);
      // console.log('allBills', allBills, mergedData, this.props.currentDataId, data);
      // this.props.setTenantBills(allBills);
      // const { allTenantBills } = this.getAllData();

      // loader(true);
      this.setState(
        {
          selectedTenantData: { ...this.state.selectedTenantData, tenantRows: formattedResponse },
          warmWaterCostTypeFound
        },
        () => {
          //loader(false)
          // btnRef.current.click()
          this.state.meterReadingsSwitch === false && setTimeout(() => {
            this.printDocument(0, 1);
          }, 500);
        }
      );
    } else {
      // loader(false);
      this.state.meterReadingsSwitch === false && setTimeout(() => {
        this.printDocument(0, 1);
      }, 500);
    }
  };

  getTableLabels = () => {
    return (
      <TableRow>
        <TableCell style={this.tableHeadCellStyling}>{lang.utility.text_cost_type}</TableCell>
        <TableCell style={{...this.tableHeadCellStyling, ...{textAlign: 'right'}}}>{lang.utility.text_total_cost}</TableCell>
        <TableCell style={this.tableHeadCellStyling}>{lang.utility.text_allocation_key}</TableCell>
        <TableCell style={{...this.tableHeadCellStyling, ...{textAlign: 'right'}}}>{lang.utility.text_total_value}</TableCell>
        <TableCell style={{...this.tableHeadCellStyling, ...{textAlign: 'right'}}}>{lang.utility.text_tenant_value}</TableCell>
        <TableCell style={{...this.tableHeadCellStyling, ...{textAlign: 'right'}}}>{lang.utility.text_unit}</TableCell>
        <TableCell style={{...this.tableHeadCellStyling, ...{textAlign: 'right'}}}>{lang.utility.text_tenant_cost_share}</TableCell>
        <TableCell style={this.tableHeadCellStyling}>{lang.utility.text_comment}</TableCell>
      </TableRow>
    );
  };
  getAllData = () => {
    console.log('get All data func ');
    const { utilityBillingData } = this.props;
    let currentBillingData = utilityBillingData.find((ub) => ub.id == this.props.currentDataId);
    let allTenantBills =
      currentBillingData &&
      currentBillingData.billingData &&
      currentBillingData.billingData.tenantBills &&
      currentBillingData.billingData.tenantBills;
    let currentTenant =
      Array.isArray(allTenantBills) &&
      allTenantBills.find((tb) => tb.billingStatementId == this.state.selectedTenantID);
    // console.log('getData', allTenantBills, currentTenant)
    return {
      currentTenant: currentTenant ? currentTenant : {},
      allTenantBills: Array.isArray(allTenantBills) && allTenantBills.length > 0 ? allTenantBills : [],
    };
  };

  updateCommentLocalState = (tenantId, billId, value) => {
    // loader(true);
    this.setState({
      utilityBillTableData: {
        ...this.state.utilityBillTableData,
        tenantRows: this.state.utilityBillTableData.tenantRows.map((i) =>
          i.id === billId ? { ...i, comment: value } : i
        ),
      },
    });
  };

  downloadPDF = (file) => {
    window.open(file, '_blank');
  };
  calculateBalance = (data) => {
    const { totalAllocations, prepayments } = data.currentTenant;
    return parseFloat(totalAllocations) - parseFloat(prepayments);
  };
  getBalance(data) {
    var totalAllocations = checkProperty(data, 'totalAllocations', '-');
    var prePayments = checkProperty(data, 'prepayments', '-');
    // console.log(data)
    // console.log(totalAllocations)
    // console.log(prePayments)
    var getbalance = totalAllocations - prePayments + '€';
    return getbalance === 'NaN€' ? '-' : getbalance;
  }
  handleResize = () => {
    window.innerWidth <= MOBILE_SIZE ? this.setState({ isMobile: true }) : this.setState({ isMobile: false });
  };
  onInputChange = (field, value) => {
    const selectedTenantData = this.props.tenantBills[0]?.billingData?.tenantBills?.filter(
      (o) => o.tenantID === value
    )[0];
    // console.log(selectedTenantData)
    this.setState({ [field]: value, selectedTenantData }, () => console.log(this.state.selectedTenantData));
  };

  async printDocument(index, count) {
    if(!this.props.messagePreview){
      const {meterReadingsSwitch, compositionOfHeatingCostsSwitch, costSplitWarmWaterAndHeatingSwitch, splitBaseConsumptionCostsSwitch, readings, customImageBase64, selectedTenantData } = this.state;
      let meterReadingsLength = readings && readings.meterReadings ? readings.meterReadings.length : 0;
      var downloadStatus = document.getElementById('download_status');
      const input = document.getElementById(`divToPrint${index}`);
      const input1 = document.getElementById('divToPrint1');
      let {files, pages} = this.state;
      let delay = selectedTenantData?.tenantRows?.length > 10 ? 3000 : 1600;
      const logo = document.getElementById('logo');
      const emptyLogo = document.getElementById('emptyLogo');
      
      const logoWidth = this.props.companyLogoEnable ? this.props.accountData.pictureLocation !== null ? logo.offsetWidth : 75 : 75;
      const logoHeight = this.props.companyLogoEnable ? this.props.accountData.pictureLocation !== null ? logo.offsetHeight : 38 : 38;
      //var s = index === 1 && new XMLSerializer().serializeToString(document.getElementById("emptyLogoMeter"))
      const emptyLogoMeter = document.getElementById('emptyLogoMeter');
      const propertyName= this.props.currentProperty?.propertyAdress?.streetno ? this.props.currentProperty?.propertyAdress?.streetno : this.props.currentProperty.name;
      const rentalUnitName= this.state.invoiceData?.rentalUnitName;
      const tenantName= this.state.invoiceData?.tenantName;
      const closeWindow = ()=> {
        var allowDownloadSafari = document.getElementById('allow_download');
        allowDownloadSafari.style.display = browserName === 'Safari' && 'block'; 
        var spinner = document.getElementById('hh_loader_spinner');
        browserName !== 'Safari' ? loader(false) : spinner.style.display = 'none';
        browserName !== 'Safari' && window.close();
      }
      console.log('emptyLogoMeter: ', emptyLogoMeter);
      if((index === 1 && !meterReadingsSwitch) || (index === 2 && (!compositionOfHeatingCostsSwitch && !costSplitWarmWaterAndHeatingSwitch && !splitBaseConsumptionCostsSwitch))){
        setTimeout(() => {
          this.printDocument(index+1, count+1);  
        }, delay);
      }else{
        await html2canvas(logo, {
          scale: 4
        }).then(async(canvas) => {
          const imgData = this.props.companyLogoEnable ? (customImageBase64 !== null ? customImageBase64 : canvas.toDataURL('image/png'))  : canvas.toDataURL('image/png');
          var doc = new jsPdf("p", "pt", 'a4');
          console.log('imgData: ', imgData);
          doc.addImage(imgData, 'JPEG', 34.5, 20, logoWidth, logoHeight, undefined, 'FAST');
          /////////////////////
          doc.setFontSize(8);
          var text = `${this.props.accountData.addressCompany && (this.props.accountData.addressCompany + ', ')}${this.props.accountData.addressStreet && (this.props.accountData.addressStreet + ',')} ${(this.props.accountData.addressZipCode)} ${this.props.accountData.addressCity}`,
          xOffset = doc.internal.pageSize.width/2; 
          var pageSize = doc.internal.pageSize;
          var pageHeight = pageSize.height
            ? pageSize.height
            : pageSize.getHeight();
          
          doc.setTextColor('#828282');
          this.props.currentSubscription.subscriptionKey.split('_')[0] !== 'STARTER' && doc.text(text, 
          xOffset, 
          pageHeight - 30,
          {align: 'center'});
          /////////////////////
          // var pageSize = doc.internal.pageSize;
          // var pageWidth = pageSize.width
          // ? pageSize.width
          // : pageSize.getWidth();
          // doc.setFontSize(8);
          // doc.setTextColor('#000000');
          // doc.text(`Seite ${count > pages ? pages : count} von ${pages}`, 
          // pageWidth - 40, 
          // 26,
          // {align: 'center'});
          ////////////////////
          if(this.props.propertySelected.propertyType === 'REGULAR' ? count <= 3 : count < 2){
            //console.log('this.state.selectedTenantData: ', this.state.selectedTenantData);
            if(index === 0 ? this.state.selectedTenantData : true){
              if(index === 1 && meterReadingsLength === 0){
                await html2canvas(emptyLogoMeter, {
                  scale: 4
                }).then((canvas1)=>{
                  const imgData1 = canvas1.toDataURL('image/png');
                  doc.addImage(imgData1, 'JPEG',290, 355, 10,10, undefined, 'FAST');
                  doc.html(input, {
                    callback: async function (doc) {
                      downloadStatus.textContent=`PDF-Dokumente erstellen ${count > pages ? pages : count} / ${pages}`;
                      await files.push(doc.output('arraybuffer'));
                      // doc.save(`${propertyName}-${rentalUnitName}-${tenantName ? tenantName.replace(/,/g, '_') : 'Mieter'}.pdf`, {returnPromise: true}).then(
                      //   setTimeout(() => {
                      //     //closeWindow();
                      // }, 500))
                    },
                    margin: [50,24,62,24],
                    x: 10,
                    y: 0,
                    autoPaging: 'text',
                    html2canvas: {useCORS: true, scale: 0.505
                      , height: 0 },
                  })
                });
              }else{
                doc.html(input, {
                  callback: async function (doc) {
                    downloadStatus.textContent=`PDF-Dokumente erstellen ${count > pages ? pages : count} / ${pages}`;
                    await files.push(doc.output('arraybuffer'));
                    // doc.save(`${propertyName}-${rentalUnitName}-${tenantName ? tenantName.replace(/,/g, '_') : 'Mieter'}.pdf`, {returnPromise: true}).then(
                    //   setTimeout(() => {
                    //    // closeWindow();
                    // }, 500))
                  },
                  margin: [50,24,62,24],
                  x: 10,
                  y: 0,
                  autoPaging: 'text',
                  html2canvas: {useCORS: true, scale: 0.505
                    , height: 0 },
                })
              }
            }else{
              await html2canvas(emptyLogo, {
                scale: 4
              }).then((canvas1)=>{
                const imgData1 = canvas1.toDataURL('image/png');
                doc.addImage(imgData1, 'JPEG',290, 445, 10,10, undefined, 'FAST');
                doc.html(input, {
                  callback: async function (doc) {
                    downloadStatus.textContent=`PDF-Dokumente erstellen ${count > pages ? pages : count} / ${pages}`;
                    await files.push(doc.output('arraybuffer'));
                    // doc.save(`${propertyName}-${rentalUnitName}-${tenantName ? tenantName.replace(/,/g, '_') : 'Mieter'}.pdf`, {returnPromise: true}).then(
                    //   setTimeout(() => {
                    //     //closeWindow();
                    // }, 500))
                  },
                  margin: [50,24,62,24],
                  x: 10,
                  y: 0,
                  autoPaging: 'text',
                  html2canvas: {useCORS: true, scale: 0.505
                    , height: 0 },
                })
              });
            }
              

            this.setState({files});
              setTimeout(() => {
                  this.printDocument(index+1, count+1);  
              }, delay);
          }
          else{
            downloadStatus.textContent=`PDF-Dokumente zusammenfügen...`;
            setTimeout(() => {
                const pdfBytes = this.mergePdfs(this.state.files);
                pdfBytes.then(function(result) {
                    var blob = new Blob([result], {type: "application/pdf"});
                    var link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    //var fileName = reportName;
                    link.download = `${propertyName}-${rentalUnitName}-${tenantName ? tenantName.replace(/,/g, '_') : 'Mieter'}.pdf`;
                    link.click();
                    setTimeout(() => {
                        closeWindow();
                    }, 500);
                })
                console.log('pdfBytes: ', pdfBytes);
            }, delay);
        }
        });
      }
    }
  }
  async mergePdfs(pdfsToMerge) {
    console.log('mergePdfs', pdfsToMerge);
    const mergedPdf = await PDFDocument.create();

  const createInnerPromise = async (arrayBuffer) => {
    const pdf = await PDFDocument.load(arrayBuffer);
    return await mergedPdf.copyPages(pdf, pdf.getPageIndices());
  };

  const outerPromise = pdfsToMerge.map((arrayBuffer) => {
    const innerPromise = createInnerPromise(arrayBuffer);
    return innerPromise;
  });

  const resultOuterPromise = await Promise.all(outerPromise);

  let count = 0;
  let totalPages = 0;
  resultOuterPromise.forEach((pageArray, i) => {
    pageArray.forEach((page, index) => {
      totalPages = totalPages + 1;
    })
  });
  resultOuterPromise.forEach((pageArray, i) => {
    pageArray.forEach((page) => {
      const { width, height } = page.getSize();
      page.drawText(`Seite ${count+1} von ${totalPages}`, {
        x: width - 67,
        y: height - 26,
        size: 6,
        color: rgb(0.5, 0.5, 0.5),
      })
      mergedPdf.addPage(page);
      count = count + 1;
    });
  });

  return (await mergedPdf.save()).buffer;
  }
  heatingRow =(index)=>{
    return (
      <TableRow>
        <TableCell style={{...this.tableBodyCellStyling, ...{textAlign: 'right', borderBottom: this.state.selectedTenantData.tenantRows.length === (index + 1) && '1px solid #DADAE6'}}} colSpan={7}>
          Heizkosten gesamt
        </TableCell>
        <TableCell style={{...this.tableBodyCellStyling, ...{fontWeight: 'bold', borderBottom: this.state.selectedTenantData.tenantRows.length === (index + 1) && '1px solid #DADAE6'}}} colSpan={1}>
          {this.calculateTotal(index)}
        </TableCell>
      </TableRow>
    );
  }
  utilityTotalRow=(index)=>{
    return(
    <TableRow>
      <TableCell style={{...this.tableBodyCellStyling, ...{textAlign: 'right'}}} colSpan={1}>
        Betriebskosten gesamt
      </TableCell>
      <TableCell style={{...this.tableBodyCellStyling, ...{fontWeight: 'bold'}}} colSpan={1}>
        {this.calculateTotal(index)}
      </TableCell>
      <TableCell style={{...this.tableBodyCellStyling, ...{textAlign: 'right'}}} colSpan={2}>
        Vorauszahlungen
      </TableCell>
      <TableCell style={{...this.tableBodyCellStyling, ...{fontWeight: 'bold'}}} colSpan={2}>
        {this.state.selectedTenantData ? Autoload.MainHelper.ConverterCurrencyDe_DisplayOnly(this.state.selectedTenantData.prepayments, false) + ' €' : '0,00 €'}
      </TableCell>
      <TableCell style={{...this.tableBodyCellStyling, ...{textAlign: 'right'}}} colSpan={1}>
        {this.state.selectedTenantData ? this.state.selectedTenantData.prepayments - this.state.selectedTenantData.totalAllocations >= 0 ? lang.utility.text_balance_positive : lang.utility.text_balance_negative : lang.utility.text_balance}
      </TableCell>
      <TableCell style={{...this.tableBodyCellStyling, ...{fontWeight: 'bold'}}} colSpan={1}>
        {this.state.selectedTenantData
        ? Autoload.MainHelper.ConverterCurrencyDe_DisplayOnly(this.state.selectedTenantData.prepayments - this.state.selectedTenantData.totalAllocations, false) + ' €'
        : '0,00 €'}
      </TableCell>
    </TableRow>);
  }

  getOccupationPeriodDate(periodStart, periodEnd) {
    const occupationStartDate = moment(periodStart, 'YYYY-MM-DD').format('DD.MM.YYYY');
    const occupationEndDate = moment(periodEnd, 'YYYY-MM-DD').format('DD.MM.YYYY');
    return occupationStartDate + ' - ' + occupationEndDate;
  }

  updateOccasion() {
    const { defaultOccasion, tenantOccupationReadings } = this.state;
    console.log('tenantOccupationReadings', tenantOccupationReadings);
    let readingList = [];
    let readingType = null;
    const { periodStartReading = null, periodEndReading = null } = tenantOccupationReadings;
    if (periodStartReading && periodStartReading?.occasionDate >= tenantOccupationReadings.periodStart) {
    console.log('periodStartReading');
      const periodReading = {
        ...periodStartReading,
        periodStart: tenantOccupationReadings.periodStart,
        periodEnd: tenantOccupationReadings.periodEnd,
        rentalUnitName: tenantOccupationReadings.rentalUnitName,
        tenantFullName: tenantOccupationReadings.tenantFullName,
        tenantId: tenantOccupationReadings.tenantId,
        occupationId: tenantOccupationReadings.id
      }
      this.setState({readings: periodReading});
    };
    if (periodEndReading) {
      console.log('periodEndReading');
      const periodReading = {
        ...periodEndReading,
        periodStart: tenantOccupationReadings.periodStart,
        periodEnd: tenantOccupationReadings.periodEnd,
        rentalUnitName: tenantOccupationReadings.rentalUnitName,
        tenantFullName: tenantOccupationReadings.tenantFullName,
        tenantId: tenantOccupationReadings.tenantId,
        occupationId: tenantOccupationReadings.id
      }
      this.setState({readings: periodReading});
    };
    setTimeout(() => {
      this.printDocument(0, 1);
    }, 500);
  }

  _getOccupationReadingTenants(tenantId) {
    const propertyid = this.props.currentProperty && this.props.currentProperty.id;
    let ubData = this.props.utilityBillingData.find((ub) => ub.id == this.props.currentDataId)
    this.props.divName === 'divToPrint1' && Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.meter_api_base_url +
      'occupationreadings/occupationReadingForTenant?TenantId=' +
      tenantId +
      '&BillingPeriodId=' +
      (window.location.href.includes('/messaging/newMessage') ? ubData.billingPeriod.id : ubData.billingPeriodId) +
      '&PropertyId=' +
      propertyid,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      '_handleOccupationReadingTenantResponse',
      null,
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  _handleOccupationReadingTenantResponse(response) {
    console.log(response, 'occupation api meter');
    if (response.status === true && response.statuscode === 401) {
      localStorage.clear();
      window.location.reload();
    }

    if (response.status === true && response.statuscode === 200) {
      const tenantOccupationReadings = MeterDataHandler.getTenantOccupationReadings(response.result);
      if (tenantOccupationReadings) {
        const startOccasion = tenantOccupationReadings.periodStartReading.occasion;
        const endOccasion = tenantOccupationReadings.periodEndReading.occasion;
        const occupancyDate = this.getOccupationPeriodDate(
          tenantOccupationReadings.periodStart,
          tenantOccupationReadings.periodEnd
        );
        const disableRadioSelection = {
          disableMoveIn:
            tenantOccupationReadings.periodStartReading.occasion === 'move-in' ||
              tenantOccupationReadings.periodEndReading.occasion === 'move-in'
              ? false
              : true,
          disableAnnual:
            tenantOccupationReadings.periodStartReading.occasion === 'annual' ||
              tenantOccupationReadings.periodEndReading.occasion === 'annual'
              ? false
              : true,
          disableMoveOut:
            (tenantOccupationReadings.periodStartReading.occasion === 'move-out' && tenantOccupationReadings.tenantFullName !== '(leer)') ||
              (tenantOccupationReadings.periodEndReading.occasion === 'move-out' && tenantOccupationReadings.tenantFullName !== '(leer)')
              ? false
              : true,
        };
        this.setState(
          {
            tenantOccupationReadings,
            occupancyDate,
            defaultOccasion: tenantOccupationReadings.tenantFullName === '(leer)' ? 'annual' : startOccasion || endOccasion,
            disableRadioSelection,
          },
          () => this.updateOccasion()
        );

        // document.getElementById('errorattop').style.display = 'none';
        console.log('response _handleOccupationReadingTenantResponse', tenantOccupationReadings);
      } else {
        //this.renderErrorMessage(lang.newKeys.text_error_Tenant_readings_not_found);
        this.setState({ disableUpdation: true, readings: [] });
        setTimeout(() => {
          this.printDocument(0,1);
        }, 500);
      }
    } else {
      //this.renderErrorMessage(lang.newKeys.text_error_no_reading_found);
      this.setState({ disableUpdation: true, readings: [] });
      setTimeout(() => {
        this.printDocument(0,1);
      }, 500);
    }
    //this.ShowLoader();
    console.log(this.state.readings, 'data to show meter 1');
  }

  getHeatingCosts = (addEmptyHC = false) => {
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.ub_api_base_url + 'heatingCosts?utilityBillingId=' + this.props.currentDataId,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      '_handleHeatingCostsResponse',
      { addEmptyHC },
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };

  _handleHeatingCostsResponse =(response)=>{
    console.log('_handleHeatingCostsResponse: ', response);
    if(response.status){
      this.setState({heatingCosts: response.result});
    }
  }

  render() {
    // const data = this.getAllData();
    // const balance = this.calculateBalance(data);
    const { showEmpty, utilityData, utilityBillTableData, heatingCosts, vatSwitch, laborCostSwitch, meterReadingsSwitch, compositionOfHeatingCostsSwitch, costSplitWarmWaterAndHeatingSwitch, splitBaseConsumptionCostsSwitch } = this.state;
    const {messagePreview} = this.props;
    console.log(utilityData);

    console.log('heatingCosts: ', heatingCosts);

    console.log('this.props.divName: ', this.props.divName === 'divToPrint1');
    
    // Encode the SVG as base64
    //console.log('b64: ', b64);
    // const data = {
    //     currentTenant:{},
    //     allTenantBills:[]
    // };
    // if ((Object.keys(data.currentTenant).length == 0 && data.allTenantBills.length == 0) || showEmpty) {
    //   return <EmptyStateContainer heading={lang.utility.text_no_tenant_bills} icon={tap} showBtn={false} />;
    // }

    return (
      <div style={{ overflow: 'hidden' }} className='ub-preview-screen'>
        {!messagePreview && <div>
          <img src={this.props.companyLogoEnable ? this.props.accountData.pictureLocation !== null ? this.props.accountData.pictureLocation : Logo : Logo} id='logo' alt="logo" style={{height: this.props.companyLogoEnable && this.props.accountData.pictureLocation !== null && 38}}/>
        </div>}
        {/* <ReactToPdf targetRef={ref} filename="billing-statement.pdf" scale={0.65} onComplete={() => {
          // setTimeout(() => {
          //   window.close()
          // }, 1000);
        }} >
          {({ toPdf }) => (
            <button style={{ display: 'none' }} onClick={toPdf}>Generate pdf</button>
          )}
        </ReactToPdf> */}
        {/* <button onClick={this.printDocument}>Generate pdf</button> */}
        {/* <div style={{ width: '100vw', height: '100vh', position: 'absolute', zIndex: 1000, background:'red' }}></div> */}
          <div
            ref={ref}
            id={'divToPrint0'}
            style={{width: messagePreview ? 'auto' : '1050px', display: this.props.divName === 'divToPrint0' ? 'block' : 'none'}}
          >
            <div style={{ marginBottom: 38 }}>
              <img src={this.props.companyLogoEnable ? this.props.accountData.pictureLocation !== null ? this.props.accountData.pictureLocation : Logo : Logo} //id='logo' 
              alt="logo" style={{height: this.props.companyLogoEnable && this.props.accountData.pictureLocation !== null && '62.5px'}}/>
            </div>
            <div style={{ marginBottom: 38, marginTop: !messagePreview && '-50pt' }}>
              <h2 style={{fontSize: '22px'}}>Betriebskostenabrechnung</h2>
              <p style={{ fontSize: 10, marginBottom: 4, color: '#767676' }}>{utilityData.date}</p>
            </div>
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'start', alignItems: 'flex-start' }}>
              <div style={{ width: "20%", display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                <p style={{ fontSize: 10, marginBottom: 4, color: '#767676' }}>Mieter</p>
                <p style={{ fontSize: 11, overflow: 'hidden', whiteSpace: 'normal', textOverflow: 'ellipsis' }}>
                  {this.state.invoiceData?.tenantName}
                </p>
              </div>
              <div style={{ width: "20%", display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                <p style={{ fontSize: 10, marginBottom: 4, color: '#767676' }}>Immobilie</p>
                <p style={{ fontSize: 11, overflow: 'hidden', whiteSpace: 'normal', wordBreak: 'break-all', textOverflow: 'ellipsis', width: '85%' }}>
                  {this.props.currentProperty?.propertyAdress?.streetno ? this.props.currentProperty?.propertyAdress?.streetno : this.props.currentProperty.name}
                </p>
              </div>
              <div style={{ width: "20%", display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                <p style={{ fontSize: 10, marginBottom: 4, color: '#767676' }}>Mieteinheit</p>
                <p style={{ fontSize: 11, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                  {this.state.invoiceData?.rentalUnitName}
                </p>
              </div>
              <div style={{ width: "20%", display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                <p style={{ fontSize: 10, marginBottom: 4, color: '#767676' }}>Datum</p>
                <p style={{ fontSize: 11, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                  {moment().format('DD.MM.YYYY')}
                </p>
              </div>

              <div style={{ width: "20%", display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                <p style={{ fontSize: 10, marginBottom: 4, color: '#767676' }}>Abrechnungszeitraum</p>
                <p style={{ fontSize: 11, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                  {this.state.invoiceData?.tenantOccupation?.startDate && this.state.invoiceData?.tenantOccupation?.endDate
                    ? `${moment(this.state.invoiceData.tenantOccupation?.startDate).format('DD.MM.YYYY')} - ${moment(this.state.invoiceData.tenantOccupation?.endDate).format('DD.MM.YYYY')}`
                    : ''
                  }
                </p>
              </div>

              <div style={{ width: "20%", display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                <p style={{ fontSize: 10, marginBottom: 4, color: '#767676' }}>Anzahl Abrechnungstage</p>
                <p style={{ fontSize: 11, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                  {this.state.invoiceData?.tenantOccupation?.totalDays}
                </p>
              </div>
              <div style={{ width: "20%", display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                <p style={{ fontSize: 10, marginBottom: 4, color: '#767676' }}>Gesamte Betriebskosten</p>
                <p
                  style={{
                    fontSize: 11,
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    color:
                      (this.state.invoiceData && this.state.invoiceData.totalAllocatedUtilityCosts) < 0
                        ? 'red'
                        : '#000000',
                  }}
                >
                  {this.state.invoiceData
                    ? formatNumber(
                        this.state.invoiceData.totalAllocatedUtilityCosts,
                        true
                      )
                    : '0,00€'}
                </p>
              </div>
              <div style={{ width: "20%", display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                <p style={{ fontSize: 10, marginBottom: 4, color: '#767676' }}>Vorauszahlungen</p>
                <p
                  style={{
                    fontSize: 11,
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    color:
                      (this.state.invoiceData && this.state.invoiceData.totalAdvancePayments) < 0
                        ? 'red'
                        : '#000000',
                  }}
                >
                  {this.state.invoiceData
                    ? formatNumber(
                        this.state.invoiceData.totalAdvancePayments,
                        true
                      )
                    : '0,00€'}
                </p>
              </div>
              <div style={{ width: 270, display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                <p style={{ fontSize: 10, marginBottom: 4, color: '#767676' }}>{this.state.invoiceData ? this.state.invoiceData.totalAdvancePayments - this.state.invoiceData.totalAllocatedUtilityCosts >= 0 ? lang.utility.text_balance_positive : lang.utility.text_balance_negative : lang.utility.text_balance}</p>
                <p
                  style={{
                    fontSize: 11,
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    color:
                      (this.state.invoiceData && this.state.invoiceData.totalAdvancePayments - this.state.invoiceData.totalAllocatedUtilityCosts) < 0
                        ? 'red'
                        : '#000000',
                  }}
                >
                  {this.state.invoiceData
                    ? formatNumber(
                        this.state.invoiceData.totalAdvancePayments - this.state.invoiceData.totalAllocatedUtilityCosts,
                        true
                      )
                    : '0,00€'}
                </p>
              </div>
            </div>
            <>
              {this.state.selectedTenantData ? 
              <>
                <UtilityBillTable
                selectedTenantData={this.state.selectedTenantData}
                utilityData={utilityData}
                warmWaterCostTypeFound={this.state.warmWaterCostTypeFound}
                tableCells={this.getTableLabels()}
                billingTemplate={true}
                style={{marginTop: '20px', background: 'transparent'}}
                invoiceData={this.state.invoiceData}
                vatSwitch={vatSwitch}
                laborCostSwitch={laborCostSwitch}
                messagePreview={messagePreview}
                />
              </>
              :
              <div id='emptyState' style={{marginTop: messagePreview ? '300px' : '500px'}}>
                  <div>
                      <div>
                          <div className="main-container empty-state-container">
                          <div className="icon-container" style={{background: 'transparent'}}>
                              <img id='emptyLogo' src={tap} className="empty-state-icon" alt="empty" />
                          </div>
                          <h4 style={{fontSize: 11, fontFamily: 'Inter, sans-serif'}} className="empty-state-heading">{'Es konnte noch keine Betriebskostenabrechnung erstellt werden.'}</h4>
                          <h5 style={{fontSize: 10, fontFamily: 'Inter, sans-serif', marginTop: '6px'}} className="empty-state-subheading">{'Die Betriebskostenabrechnung enthält keine Kostenpositionen.'}</h5>
                          </div>
                      </div>
                  </div>
              </div>
              }
            </>
          </div>
          <div
          id={'divToPrint1'}
          style={{width: '184mm', display: this.props.divName === 'divToPrint1' ? 'block' : 'none'}}>
          {meterReadingsSwitch && this.state.readings && 
            <>
              <div style={{ marginBottom: 38 }}>
                <img src={this.props.companyLogoEnable ? this.props.accountData.pictureLocation !== null ? this.props.accountData.pictureLocation : Logo : Logo} //id='logo' 
                alt="logo" style={{height: this.props.companyLogoEnable && this.props.accountData.pictureLocation !== null && '62.5px'}} />
              </div>
              <div style={{ marginBottom: 38 }}>
                <h2 style={{textAlign: 'left', letterSpacing: '-.05ch', fontSize: '22px'}}>Ablesungen und Verbräuche</h2>
              </div>
              {this.state.readings.meterReadings && this.state.readings.meterReadings.length > 0 ?
              <MeterReadingsTable
                readings={this.state.readings}
                utilityData={utilityData}
                billingTemplate={true}
                messagePreview={messagePreview}
              />
            :
              <div id='emptyStateMeter' style={{marginTop: messagePreview ? '300px' : '500px'}}>
                  <div>
                      <div>
                          <div className="main-container empty-state-container">
                          <div className="icon-container" style={{background: 'transparent'}}>
                              <img id='emptyLogoMeter' src={MeterIcon} className="empty-state-icon" alt="empty" />
                          </div>
                          <h4 style={{fontSize: 11, fontFamily: 'Inter, sans-serif'}} className="empty-state-heading">{'Keine Ablesungen vorhanden.'}</h4>
                          <h5 style={{fontSize: 10, fontFamily: 'Inter, sans-serif'}} className="empty-state-subheading">{'Für diesen Mieter liegen keine Ablesungen für die Abrechnungsperiode vor.'}</h5>
                          </div>
                      </div>
                  </div>
              </div>}
            </>}
          </div>

          <div
          id={'divToPrint2'}
          style={{width: '186mm', display: this.props.divName === 'divToPrint2' ? 'block' : 'none', overflow: 'hidden'}}>
          {this.state.selectedTenantData && 
            <>
              <div style={{ marginBottom: 38 }}>
                <img src={this.props.companyLogoEnable ? this.props.accountData.pictureLocation !== null ? this.props.accountData.pictureLocation : Logo : Logo} //id='logo' 
                alt="logo" style={{height: this.props.companyLogoEnable && this.props.accountData.pictureLocation !== null && '62.5px'}} />
              </div>
              {compositionOfHeatingCostsSwitch && 
              <>
              <div style={{ marginBottom: 20 }}>
                <h2 style={{letterSpacing: '-.05ch', fontSize: '22px'}}>Zusammensetzung der Heizungskosten</h2>
              </div>
              {heatingCosts && heatingCosts.length > 0 ?
                <CompositionOfHeatingCostsTable
                selectedTenantData={this.state.selectedTenantData}
                utilityData={utilityData}
                warmWaterCostTypeFound={this.state.warmWaterCostTypeFound}
                heatingCosts={this.state.heatingCosts}
                print={true}
                //isTablet={isTablet}
                messagePreview={messagePreview}
              />
              :
              <div id='emptyStateHeatingCosts' style={{margin: '20px 0px'}}>
                  <div>
                      <div>
                          <div className="main-container empty-state-container" style={{marginTop: '30px'}}>
                            <div className="icon-container" style={{background: 'transparent'}}>
                                <img id='emptyLogoMeter' src={tap} className="empty-state-icon" alt="empty" />
                            </div>
                            <h4 style={{fontSize: '11px', fontFamily: 'Inter, sans-serif'}} className="empty-state-heading">{'Es liegen keine Heizkostendetails vor.'}</h4>
                            <h5 style={{fontSize: '10px', fontFamily: 'Inter, sans-serif'}} className="empty-state-subheading">{'Es liegen keine Details zur Zusammensetzung der Heizkosten vor.'}</h5>
                          </div>
                      </div>
                  </div>
              </div>}
              </>}
              {costSplitWarmWaterAndHeatingSwitch &&
              <>
              <div style={{ marginBottom: 20, marginTop: 20 }}>
                <h2 style={{letterSpacing: '-.05ch', fontSize: '22px'}}>Aufteilung Heizkosten in Kosten für Warmwasser und Heizkosten</h2>
              </div>
              <CostSplitWarmWaterAndHeatingTable
                utilityBilling={this.props.utilityBillingData.find((ub) => ub.id == this.props.currentDataId)}
                messagePreview={messagePreview}
              />
              </>}
              {splitBaseConsumptionCostsSwitch &&
              <>
              <div style={{ marginBottom: 20, marginTop: 20, display: 'flex' }}>
                <h2 style={{letterSpacing: '-.05ch', fontSize: '22px'}}>Aufteilung Heizkosten in Grundkosten und Verbrauchskosten</h2>
              </div>
              <SplitBaseConsumptionCosts
                selectedTenantData={this.state.selectedTenantData}
                utilityData={utilityData}
                warmWaterCostTypeFound={this.state.warmWaterCostTypeFound}
                heatingCosts={this.state.heatingCosts}
                messagePreview={messagePreview}
              />
              </>}
            </>}
          </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  // console.log('stateee', state.UtilityBillingReducer)
  return {
    // allUtilityBills: state.UtilityBillingReducer.utilityBillingData,
    currentProperty: state.generalReducer.property,
    currentDataId: state.UtilityBillingReducer.currentBillingId,
    tenantBills: state.UtilityBillingReducer.utilityBillingData,
    utilityBillingData: state.UtilityBillingReducer.utilityBillingData,
    companyLogoEnable: state.generalReducer.companyLogoEnable,
    accountData: state.generalReducer.accountData,
    currentSubscription: state.generalReducer.currentSubscription,
    propertySelected: state.generalReducer.property,
  };
};

const mapDispatchToProps = {
  setTenantBills,
};

export default connect(mapStateToProps, mapDispatchToProps)(UtilityBillsTable);
