import React, { Component } from 'react';
import CustomModal from './index';
import {
  ColorButton,
  ValueText,
  SwitchContainer,
  DropFile,
  AddCostTypeModal,
  SelectDropdown
} from '../../../../index';
import { Row, Col, Form, Tooltip, OverlayTrigger } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import Help from '../../../../../assets/images/module/general/Help.svg';
import calendar from '../../../../../assets/images/module/general/CalendarIcon.svg';
import { connect } from 'react-redux';
import RangeDatePicker from './DateRangePicker';
import Autoload from '../../../../Autoload';
import moment from 'moment';
import GlobalServices from '../../../../helpers/services/GlobalServices';
import GeneralDataHandler from '../../../../helpers/DataHandler/General';

import { showNotification, hideNotification } from '@mantine/notifications';
import RedTick from '../../../../../assets/images/module/general/RedTick.svg';
import GreenTick from '../../../../../assets/images/module/general/GreenTick.svg';
import AddIcon from '../../../../../assets/images/module/general/AddIcon.svg';
import Logo from '../../../../../assets/images/module/general/Logo.svg';
import Messenger from '../../../../../assets/images/module/general/Messenger.svg';

// Get receipts endpoint from new config
import { config } from '../../../../config/Setting';
import { Check, ThreeDRotationSharp } from "@material-ui/icons";
import { Alert, Group, Stack, Text } from "@mantine/core";
import * as customParseFormat from 'dayjs/plugin/customParseFormat'
import dayjs from 'dayjs';
import CopyIcon from '../../../SVGs/copyIcon';
import { formatNumber } from '../../../../../helpers/stringsManipulations';
import { onDelete } from 'draft-js/lib/RichTextEditorUtil';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
var { checkProperty, yearDifference, yearDifferenceMoment } = Autoload.MainHelper;
dayjs.extend(customParseFormat)

const MOBILE_SIZE = 992;
const TABLET_SIZE = 1200;

class MessageConfirmationModal extends Component {
  state = {
    
  };
  
  constructor(props) {
    super(props);
  }

  componentDidMount(){
    
  }
  
  render() {
    const { open, toggle, className, postalFee, onDelete } = this.props;
    const { data } = this.state;
    console.log('postalFee: ', postalFee);
    return (
      <>
        <CustomModal
          heading={'Nachricht löschen'}
          headingClassName='select-template-heading'
          open={open}
          toggle={() => {
            toggle();
          }}
          className={`${className}`}
        >
            <Stack>
                <Group><span style={{fontWeight: '400'}}>Sind Sie sicher, dass Sie diese Nachricht und alle Empfängerdaten unwiderruflich löschen wollen?</span></Group>
                <Group position='apart'>
                    <div>
                        <ColorButton
                        text={'Abbrechen'}
                        className={'gray-btn'}
                        onClick={()=> toggle()}
                        />
                    </div>
                    <div>
                        <ColorButton
                        text={'Löschen'}
                        className={'red-btn'}
                        onClick={()=> onDelete()}
                        />
                    </div>
                </Group>
            </Stack>
        </CustomModal>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    propertySelected: state.generalReducer.property,
  };
};

export default connect(mapStateToProps)(MessageConfirmationModal);
