import { Button, Group, SimpleGrid, Stack, Text } from '@mantine/core';
import { useEffect } from "react";
import APIClient from "../../neoverv/config/APIClient";
import PropertyDetailsCard from "./PropertyDetailsCard";
import SingleDataCard from './SingleDataCard';
import { useMediaQuery } from '@mantine/hooks';
import { Link } from 'react-router-dom';

const Dashboard = () => {
  const isMobile = useMediaQuery('(max-width: 900px)');
  
  useEffect(() => {
    APIClient({
      
    })
  }, []);
  
  return (
    <div style={{ minHeight: '100vh' }}>
      <Stack>
        <PropertyDetailsCard />
        <SimpleGrid cols={isMobile ? 1 : 3}>
          <SingleDataCard heading="Belege" moreLink="/receipt"></SingleDataCard>
          <SingleDataCard heading="Betriebskosten" moreLink="/utility-billing"></SingleDataCard>
          <SingleDataCard heading="Ablesungen" moreLink="/meter"></SingleDataCard>
        </SimpleGrid>
        
        <Group position="apart" mt={24}>
          <Text component="h3" sx={{ fontSize: 20 }} pl={16}>Mieteinheiten</Text>
          <Group sx={(theme) => ({ "& a": { color: theme.colors.brand[4] } })}>
            <Link to="/user">Alle &rsaquo;</Link>
            <Button variant="white" onClick={() => {}}>Einheit hinzufügen</Button>
          </Group>
        </Group>
      </Stack>
    </div>
  )
}

export default Dashboard;