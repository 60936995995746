import List from '../../../views/pages/Module/Property/List';

const pageList = [
  {
    name: 'Meter List',
    path: '/property/',
    // path: '/property/generalInformation',
    component: List,
  },
];

export default pageList;
