import * as Actiontypes from '../../types/general';

export const StateModal =(data)=>{
  // console.log('modal state in actions', data);
  return {
    type: Actiontypes.CUSTOM_MODAL_STATE,
    payload: data,
  };
}
export const Account_info = (data) => {
  // console.log('account id in actions', data);
  return {
    type: Actiontypes.ACCOUNT_INFO_APIDATA,
    payload: data,
  };
};
export const changeCurrentProperty = (data) => {
  // console.log('action changeCurrentProperty', data);
  return {
    type: Actiontypes.CHANGE_PROPERTY,
    payload: data,
  };
};
export const setProfileData = (data) => {
  return {
    type: Actiontypes.SET_PROFILE_DATA,
    payload: data,
  };
};

export const editProfileData = (data) => {
  //   console.log(data, 'edit profile data');
  return {
    type: Actiontypes.SET_UPDATE_PROPERTY_DATA,
    payload: data,
  };
};
export const setPropertyList = (list) => {
  return {
    type: Actiontypes.SET_PROPERTY_LIST,
    payload: list,
  };
};
export const setFuelTypesList = (list) => {
  return {
    type: Actiontypes.SET_FUEL_TYPES_LIST,
    payload: list,
  };
};
export const addPropertyData = (data) => {
  // console.log(data, 'add new property data in redux');
  return {
    type: Actiontypes.ADD_NEW_PROPERTY_DATA,
    payload: data,
  };
};
export const resetData = () => {
  return {
    type: Actiontypes.RESET_DATA,
  };
};
//@desc updating user profile data
export const updateUserProfile = (payload) => {
  return {
    type: Actiontypes.UPDATE_USER_PROFILE,
    payload,
  };
};

//@desc updating property data in general information tab
export const updatePropetyInfo = (payload) => {
  return {
    type: Actiontypes.UPDATE_PROPERTY_INFO,
    payload,
  };
};

//delete the property from general information
export const deleteProperty = (deleteId) => {
  // console.log('action delete property', deleteId)
  return {
    type: Actiontypes.SET_DELETE_PROPERTY_DATA,
    payload: deleteId,
  };
};

export const propertyPicture = (propertypic) => {
  return {
    type: Actiontypes.PROFILE_PICTURE,
    payload: propertypic,
  };
};

export const updatedProfileWithPicture = (data) => {
  // console.log(data, 'updated data in redux');
  return {
    type: Actiontypes.NEW_PICTURE_PROPERTY_DATA,
    payload: data,
  };
};

export const stepCountSignUp = (data) => {
  // console.log(data, 'steps in redux');
  return {
    type: Actiontypes.STEPS_COUNTER_SIGNUP,
    payload: data,
  };
};

export const setVerificationStatus = (data) => {
  // console.log(data, 'verification status redux');
  return {
    type: Actiontypes.VERIFICATION_STATUS,
    payload: data,
  };
};

export const setUserDataSignUp = (data) => {
  // console.log(data, ' User Data SignUp in redux');
  return {
    type: Actiontypes.USER_INFO_SIGNUP,
    payload: data,
  };
};

export const clearUserInfoSignup = () => {
  return {
    type: Actiontypes.CLEAR_USER_INFO_SIGNUP,
  };
};

export const updateProfilePicture = (data) => {
  return {
    type: Actiontypes.UPDATE_PROFILE_PICTURE,
    payload: data
  };
};

export const setMeterReadingsToPrint = (data) => {
  console.log('meterReadingsToPrint: ', data);
  return {
    type: Actiontypes.METER_READINGS_TO_PRINT,
    payload: data
  };
};

export const setSubscriptionData = (data) => {
  return {
    type: Actiontypes.SET_SUBSCRIPTION_DATA,
    payload: data,
  };
};

export const setCurrentSubscriptionData = (data) => {
  return {
    type: Actiontypes.SET_CURRENT_SUBSCRIPTION_DATA,
    payload: data,
  };
};

export const setCurrentSubscriptionDataWithPaymentError = (data) => {
  return {
    type: Actiontypes.SET_CURRENT_SUBSCRIPTION_WITH_PAYMENT_ERROR_DATA,
    payload: data,
  };
};

export const setSelectedSubscriptionForPaymentConfirmation = (data) => {
  return {
    type: Actiontypes.SET_SELECTED_SUBSCRIPTION_FOR_PAYMENT_CONFIRMATION,
    payload: data,
  };
};

export const updateSubscriptionData = (payload) => {
  return {
    type: Actiontypes.UPDATE_SUBSCRIPTION_DATA,
    payload,
  };
};

export const setCompanyLogo= (payload)=>{
  return{
    type: Actiontypes.SET_COMPANY_LOGO,
    payload,
  }
};

export const updateCompanyLogo= (payload)=>{
  return{
    type: Actiontypes.UPDATE_COMPANY_LOGO,
    payload,
  }
};

export const setAccountData= (payload)=>{
  return{
    type: Actiontypes.SET_ACCOUNT_DATA,
    payload,
  }
};
//General Document Mgmt.
export const setAddFileModal= (payload)=>{
  return{
    type: Actiontypes.ADD_FILE_MODAL,
    payload,
  }
};

export const setFiles= (payload)=>{
  return{
    type: Actiontypes.SET_FILES,
    payload,
  }
};

export const setCurrentFolderId= (payload)=>{
  return{
    type: Actiontypes.SET_CURRENT_FOLDER_ID,
    payload,
  }
};

export const setRefreshDocumentsData= (payload)=>{
  return{
    type: Actiontypes.SET_REFRESH_DOCUMENTS_DATA,
    payload,
  }
};

export const setFileType= (payload)=>{
  return{
    type: Actiontypes.SET_FILE_TYPE,
    payload,
  }
};

//Tenant Document
export const setAddTenantDocument= (payload)=>{
  return{
    type: Actiontypes.ADD_TENANT_DOCUMENT,
    payload,
  }
};

export const setTenantFiles= (payload)=>{
  return{
    type: Actiontypes.SET_TENANT_FILES,
    payload,
  }
};

export const setTenantCurrentFolderId= (payload)=>{
  return{
    type: Actiontypes.SET_TENANT_CURRENT_FOLDER_ID,
    payload,
  }
};

export const setRefreshTenantDocumentsData= (payload)=>{
  return{
    type: Actiontypes.SET_REFRESH_TENANT_DOCUMENTS_DATA,
    payload,
  }
};
//Receipt Document
export const setAddReceiptModal= (payload)=>{
  return{
    type: Actiontypes.ADD_RECEIPT_DOCUMENT,
    payload,
  }
};

export const setReceiptFiles= (payload)=>{
  return{
    type: Actiontypes.SET_RECEIPT_FILES,
    payload,
  }
};

export const setReceiptCurrentId= (payload)=>{
  return{
    type: Actiontypes.SET_RECEIPT_CURRENT_ID,
    payload,
  }
};

export const setRefreshReceiptDocumentsData= (payload)=>{
  return{
    type: Actiontypes.SET_REFRESH_RECEIPT_DOCUMENTS_DATA,
    payload,
  }
};

export const setSharedFolder= (payload)=>{
  return{
    type: Actiontypes.SET_SHARED_FOLDER,
    payload,
  }
};

export const setPublishMessageId= (payload)=>{
  return{
    type: Actiontypes.SET_PUBLISH_MESSAGE_ID,
    payload,
  }
};

export const setRefreshMessagesData= (payload)=>{
  return{
    type: Actiontypes.SET_REFRESH_MESSAGES_DATA,
    payload,
  }
};

export const setCurrentMessagesData= (payload)=>{
  return{
    type: Actiontypes.SET_CURRENT_MESSAGE_DATA,
    payload,
  }
};

export const setRunUpdateTenantOccupations = (payload) => {
  return {
    type: Actiontypes.SET_RUN_UPDATE_TENANT_OCCUPATIONS,
    payload
  };
};

export const setRunUpdateReceiptAssignment = (payload) => {
  return {
    type: Actiontypes.SET_RUN_UPDATE_RECEIPT_ASSIGNMENT,
    payload
  };
};

export const setRunUpdateConsumptions = (payload) => {
  return {
    type: Actiontypes.SET_RUN_UPDATE_CONSUMPTIONS,
    payload
  };
};

export const setRunUpdateHeatingCostSplit = (payload) => {
  return {
    type: Actiontypes.SET_RUN_UPDATE_HEATING_COST_SPLIT,
    payload
  };
};

export const setSelectedTaxYearForTaxDeclaration = (payload) => {
  return {
    type: Actiontypes.SET_SELECTED_TAX_YEAR_FOR_TAX_DECLARATION,
    payload
  };
};