import React from 'react'
import CustomModal from './index';
import {ColorButton} from '../../../../index';
import Autoload from "../../../../Autoload";

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];

export default function MeterReadingDetailModal({ className,open,toggle,record }) {
    // console.log('record',record)
    return (
       <CustomModal heading= {lang.receipt.text_delete_receipt}  open={open} toggle={toggle} className={`${className}`}>
           {/* <div>
               <div className='modal-inner-body delete-receipt-body'>
                <p> {lang.receipt.text_delete_confirmation} </p>
                <div className='center-spacing'>
                    <span>{record && record.file_name? record.file_name:''}</span>
                    <span className='right-side' >{record && record.cost? `${record.cost}€`:''}</span>
                </div>
               </div>
               <div className='center-spacing delete-modal-footer'>
                   <ColorButton onClick={()=>{toggle()}} className='gray-btn' text= {lang.receipt.text_no_keep_it} />
                   <ColorButton onClick={()=>{toggle()}} className='red-btn' text= {lang.receipt.text_yes_delete_it}  />
               </div>
           </div> */}
       </CustomModal>
    )
}
