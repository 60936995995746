import React, { Component } from 'react';
import Switch from 'react-switch';
import VerifiedSvg from '../../../../../assets/images/verifiedSvg';
import RecordOptions from '../../General/RecordOptions/index.js';
import { DeleteModal } from '../../../../index.js';
import AddAllocationKeyModal from '../CustomModal/AddAllocationKeyModal';
import { deleteAllocationData } from '../../../../../redux/action/Property/propertyActions';
import GrayTick from '../../../../../assets/images/module/general/GrayTick.svg';
import { connect } from 'react-redux';
import Autoload from '../../../../Autoload';
import { showNotification } from '@mantine/notifications';
import RedTick from '../../../../../assets/images/module/general/RedTick.svg';
import RedCross from '../../../../../assets/images/module/general/RedCross.svg';
import GreenTick from '../../../../../assets/images/module/general/GreenTick.svg';
var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
class AllocationKeysRows extends Component {
  constructor() {
    super();
    this.state = { checked: false, openDelete: false, openEditAllocation: false };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(checked) {
    this.setState({ checked });
  }

  toggleDeleteModal = () => {
    this.setState({
      openDelete: !this.state.openDelete,
      //switch state
    });
  };

  toggleEditAllocationModal = () => {
    this.setState({
      openEditAllocation: !this.state.openEditAllocation,
    });
  };

  handleDelete = (id) => {
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'block';
    let btnelement = document.getElementById('allocationkey-delete-modal-footer');
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'AllocationKeys/' + id,
      'DELETE',
      null,
      btnelement.children[1],
      false,
      true,
      false,
      this,
      'handle_delete_list_data',
      { id: id },
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };

  handle_delete_list_data = (response) => {
    console.log('handle_delete_list_data: ', response);
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'none';
    this.props.parent._fetchAllocationKeyByProperty();
    if(response.status === true){
      showNotification({
        message: 'Der Verteilerschlüssel wurde gelöscht.',
        icon: <img src={RedTick} alt='red-tick'/>,
      });
    } else {
      showNotification({
        message: 'Der Verteilerschlüssel wird noch verwendet und konnte nicht gelöscht werden.',
        icon: <img src={RedCross} alt='red-cross'/>,
      });
    }
    
    this.toggleDeleteModal();
  };

  render() {
    const { allocationData, propertySelected, allocationWholeData } = this.props;
    // console.log(allocationData, 'allocationData show');
    console.log('allocationData: ', allocationData);
    return (
      <>
        {/* /////////////////////////// web view ///////////////////////// */}
        <tr className="table-row">
          <td width={'25%'} className="name-allocation"><span className={(allocationData.allocationKeyName === 'Gesamtwasserverbrauch' && allocationData.meterType === 'RENTAL_UNIT') && 'tooltips allocation-key-name-total-water-consumption'}>{allocationData.allocationKeyName}</span></td>
          <td width={'20%'} className="table-measurement-row  allocation-measure">{allocationData.units}</td>
          <td width={'20%'} className="verified-row allocation-meter" style={{paddingLeft: '37px'}}>
            {allocationData.systemAllocationKey === true ? (
              // <span>
              //   <img src={VerifiedSvg} alt="icon" />
              // </span>
              <VerifiedSvg fill='rgba(86, 85, 255, 1.00)'/>
            ) : (
              <span>
                <img src={GrayTick} alt="icon" />
              </span>
            )}
            {/* <span className="text-verified"> {allocationData.meterData}</span> */}
          </td>
          {console.log('allocationData1: ', allocationData)}
          {<td width={'20%'} className="table-measurement-row  allocation-measure">{allocationData.allocationKeyValueType === 'FIXED_RENTAL_UNIT' ? 'Fest (Mieteinheit)' : allocationData.allocationKeyValueType === 'FIXED_TENANT' ? 'Fest (Mieter)' : allocationData.meterType === 'RENTAL_UNIT' ? 'Verbrauch (Mieter)' : 'Verbrauch (Haus)'}</td>}
          <td className="dots-allocation" style={{textAlign: 'end'}}>
            {((allocationData.allocationKeyName === 'Anzahl Wohnungen' || allocationData.allocationKeyName === 'Wohnfläche') || (allocationData.allocationKeyValueType === 'USAGE_BASED' && allocationData.systemAllocationKey === true)) ? (
              '') 
            : 
            (!(propertySelected.propertyType === 'CONDOMINIUM' && allocationData.allocationKeyName === 'Personenanzahl') &&
              <RecordOptions
                showEditButton={allocationData.allocationKeyName === 'Personenanzahl' || !allocationData.systemAllocationKey}
                editButtonText={'Verteilerschlüssel bearbeiten'}
                deleteButtonText={'Verteilerschlüssel löschen'}
                toggleDeleteModal={() => this.toggleDeleteModal()}
                toggleEditModal={() => this.toggleEditAllocationModal()}
                showDeleteButton={!allocationData.systemAllocationKey}
                showAllocationKeyValuesEditButton={allocationData.allocationKeyName !== 'Personenanzahl' && allocationData.allocationKeyName !== 'Anzahl Wohnungen' && allocationData.allocationKeyName !== 'Wohnfläche' && (allocationData.allocationKeyValueType === 'FIXED_RENTAL_UNIT' || allocationData.allocationKeyValueType === 'FIXED_TENANT')}
                toggleAllocationKeyValuesEditModal={()=> this.props.selectedType(allocationData.allocationKeyValueType, allocationData.id, allocationData.units, allocationData.allocationKeyName)}
              />
            )}
          </td>
        </tr>
        {/* /////////////////////////// mobile view ///////////////////////// */}
        <div className="allocation-card" style={{marginTop: '8px'}}>
          {/* /////////////////////////// left side ///////////////////////// */}
          <div className="left-side" style={{ width: '90%' }}>
            <ul>
              <li className="gray-text">{lang.property.costType_tablehead_name}</li>
              <li className="black-text">{allocationData.allocationKeyName}</li>
            </ul>
            <ul style={{ display: 'flex' }}>
              <div style={{ width: '50%' }}>
                <li className="gray-text">{lang.property.allocationKeys_tablehead_unit}</li>
                <li className="black-text allocation-measure">{allocationData.units}</li>
              </div>
              <div>
                <li className="gray-text">{lang.newKeys.text_System_key}</li>
                <li>
                  {allocationData.systemAllocationKey === true ? (
                    // <span>
                    //   <img src={VerifiedSvg} alt="icon" />
                      
                    // </span>
                    <VerifiedSvg fill='rgba(86, 85, 255, 1.00)'/>
                  ) : (
                    <span>
                      <img src={GrayTick} alt="icon" />
                    </span>
                  )}
                </li>
              </div>
            </ul>
            <ul></ul>
          </div>

          {/* <li className="gray-text">System key</li>
              <li className="active">
                {allocationData.systemAllocationKey === true ? (
                  <span>
                    <img src={Verified} alt="icon" />
                  </span>
                ) : (
                  <span>
                    <img src={GrayTick} alt="icon" />
                  </span>
                )}
              </li> */}
          {/* /////////////////////////// right side ///////////////////////// */}
          {/* <div className="center-side">
            <ul>
             
              <li className="gray-text">System key</li>
              <li>
                {allocationData.systemAllocationKey === true ? (
                  <span>
                    <img src={Verified} alt="icon" />
                  </span>
                ) : (
                  <span>
                    <img src={GrayTick} alt="icon" />
                  </span>
                )}
              </li>
            </ul>
          </div> */}
          {/* /////////////////////////// right side ///////////////////////// */}
         {console.log('allocationData: ', allocationData.allocationKeyName)} 
          <div className="right-side">
          {((allocationData.allocationKeyName === 'Anzahl Wohnungen' || allocationData.allocationKeyName === 'Wohnfläche') || (allocationData.allocationKeyValueType === 'USAGE_BASED' && allocationData.systemAllocationKey === true)) ? (
              '') 
            : 
            (!(propertySelected.propertyType === 'CONDOMINIUM' && allocationData.allocationKeyName === 'Personenanzahl') &&
              <RecordOptions
                showEditButton={allocationData.allocationKeyName === 'Personenanzahl' || !allocationData.systemAllocationKey}
                editButtonText={'Verteilerschlüssel bearbeiten'}
                deleteButtonText={'Verteilerschlüssel löschen'}
                toggleDeleteModal={() => this.toggleDeleteModal()}
                toggleEditModal={() => this.toggleEditAllocationModal()}
                showDeleteButton={!allocationData.systemAllocationKey}
                showAllocationKeyValuesEditButton={allocationData.allocationKeyName !== 'Personenanzahl' && allocationData.allocationKeyName !== 'Anzahl Wohnungen' && allocationData.allocationKeyName !== 'Wohnfläche' && (allocationData.allocationKeyValueType === 'FIXED_RENTAL_UNIT' || allocationData.allocationKeyValueType === 'FIXED_TENANT')}
                toggleAllocationKeyValuesEditModal={()=> this.props.selectedType(allocationData.allocationKeyValueType, allocationData.id, allocationData.units, allocationData.allocationKeyName)}
              />
            )}
          </div>
        </div>
        {/* {this.state.openDelete && ( */}
        <DeleteModal
          record={allocationData}
          classNameName="delete-receipt-modal"
          open={this.state.openDelete}
          parent={this.props.parent}
          toggle={() => this.toggleDeleteModal()}
          handleDelete={(id) => this.handleDelete(id)}
          modalId="allocationkey-delete-modal-footer"
          type={lang.newKeys.text_single_allocationKey}
          showDeleteText={false}
          textShowModal={'Verteilerschlüssel löschen'}
          showtext={false}
          typeText={'Sind Sie sicher, dass Sie diesen Verteilerschlüssel löschen wollen?'}
        />
        {/* )} */}
              {console.log('allocationDataTest: ', allocationData)}
        {this.state.openEditAllocation &&
        <AddAllocationKeyModal
          record={allocationData}
          className={`add_allocation_key_modal ${this.props.propertySelected.propertyType === 'REGULAR' ? ((allocationData.allocationKeyValueType === 'FIXED_RENTAL_UNIT' || allocationData.allocationKeyValueType === 'FIXED_TENANT') && 'edit_allocation_key_modal') : 'edit_allocation_key_modal'}`}
          parent={this.props.parent}
          edit={true}
          allocationData={allocationWholeData}
          open={this.state.openEditAllocation}
          toggle={this.toggleEditAllocationModal}
          callBackFunction={() => this.props.parent._fetchAllocationKeyByProperty()}
          //selectedType={(type, id, units)=> this.props.selectedType(type, id, units)}
        />}
      </>
    );
  }
}

export default connect(null, { deleteAllocationData })(AllocationKeysRows);
