import { hideNotification, showNotification } from '@mantine/notifications';
import Autoload from '../../Autoload';
import RedTick from '../../../assets/images/module/general/RedTick.svg';

class GeneralDataHandler {
  static getRantalUnits = (rantalUnits) => {
    const newRantalUnits = rantalUnits ? rantalUnits.map((rantalUnit) => {
      return {
        id: Autoload.MainHelper.checkProperty(rantalUnit, 'id'),
        propertyId: Autoload.MainHelper.checkProperty(rantalUnit, 'propertyId'),
        name: Autoload.MainHelper.checkProperty(rantalUnit, 'name'),
        space: Autoload.MainHelper.checkProperty(rantalUnit, 'space'),
      };
    })
    : [];
    return newRantalUnits;
  };

  static getAllocationKeys = (allocationKeys) => {
    const checkedAllocationKeys = allocationKeys.map((allocationKey) => {
      return {
        id: Autoload.MainHelper.checkProperty(allocationKey, 'id'),
        allocationKeyId: Autoload.MainHelper.checkProperty(allocationKey, 'id'),
        propertyId: Autoload.MainHelper.checkProperty(allocationKey, 'propertyId'),
        allocationKeyName: Autoload.MainHelper.checkProperty(allocationKey, 'allocationKeyName'),
        units: Autoload.MainHelper.checkProperty(allocationKey, 'units'),
        metered: Autoload.MainHelper.checkProperty(allocationKey, 'metered'),
        systemAllocationKey: Autoload.MainHelper.checkProperty(allocationKey, 'systemAllocationKey'),
        meterData: Autoload.MainHelper.checkProperty(allocationKey, 'meterData', 'Verified'),
        allocationKeyType: Autoload.MainHelper.checkProperty(allocationKey, 'type'),
        allocationKeyValueType: Autoload.MainHelper.checkProperty(allocationKey, 'allocationKeyType'),
        meterType: Autoload.MainHelper.checkProperty(allocationKey, 'meterType'),
      };
    });
    return checkedAllocationKeys;
  };
  // static getAllocationKeysID = (allocationKeys) => {
  //   const checkedAllocationKeys = allocationKeys.map((allocationKey) => {
  //     return {
  //       id: Autoload.MainHelper.checkProperty(allocationKey, 'id'),
  //     };
  //   });
  //   return checkedAllocationKeys;
  // };

  static checkAllocationKey = (allocationKey) => {
    return {
      id: Autoload.MainHelper.checkProperty(allocationKey, 'id'),
      propertyId: Autoload.MainHelper.checkProperty(allocationKey, 'propertyId'),
      allocationKeyName: Autoload.MainHelper.checkProperty(allocationKey, 'allocationKeyName'),
      units: Autoload.MainHelper.checkProperty(allocationKey, 'units'),
      metered: Autoload.MainHelper.checkProperty(allocationKey, 'metered'),
      systemAllocationKey: Autoload.MainHelper.checkProperty(allocationKey, 'systemAllocationKey'),
    };
  };

  static getCostTypes = (costTypes) => {
    const checkedCostTypes = costTypes.map((ct) => {
      return {
        id: Autoload.MainHelper.checkProperty(ct, 'id'),
        costTypeName: Autoload.MainHelper.checkProperty(ct, 'costTypeName'),
        propertyId: Autoload.MainHelper.checkProperty(ct, 'propertyId'),
        systemCostType: Autoload.MainHelper.checkProperty(ct, 'systemCostType'),
        allocationKey: {
          allocationKeyId: Autoload.MainHelper.checkProperty(ct.allocationKey, 'id'),
          allocationKeyName:
            Autoload.MainHelper.checkProperty(ct.allocationKey, 'allocationKeyName') === ''
              ? Autoload.MainHelper.checkProperty(ct, 'allocationKeyName')
              : Autoload.MainHelper.checkProperty(ct.allocationKey, 'allocationKeyName'),
          propertyId: Autoload.MainHelper.checkProperty(ct.allocationKey, 'propertyId'),
          units: Autoload.MainHelper.checkProperty(ct.allocationKey, 'units'),
          metered: Autoload.MainHelper.checkProperty(ct.allocationKey, 'metered'),
          systemAllocationKey: Autoload.MainHelper.checkProperty(ct.allocationKey, 'systemAllocationKey'),
        },
        allocationKeyName: Autoload.MainHelper.checkProperty(ct.allocationKey, 'allocationKeyName'),
        useInReceiptMgmt: Autoload.MainHelper.checkProperty(ct, 'useInReceiptMgmt'),
        heatingCostType: Autoload.MainHelper.checkProperty(ct, 'heatingCostType'),
        costTypeIdentifier: Autoload.MainHelper.checkProperty(ct, 'costTypeIdentifier'),
      };
    });
    return checkedCostTypes;
  };

  static GeneralErrorMessage = (type = false, message = '', btn = false, time = 5000) => {
    if (type && message.length > 0) {
      // document.getElementById('hh_errorattop_mag').innerHTML = message;
      // document.getElementById('errorattop').style.display = 'flex';
      showNotification({
        id: 'generalErrorMessage',
        message: message,
        icon: <img src={RedTick} alt='red-tick'/>,
      })
      // setTimeout(() => {
      //   // console.log("timer")
      //   document.getElementById('hh_errorattop_mag').innerHTML = '';
      //   document.getElementById('errorattop').style.display = 'none';
      // }, time);
    } else {
      document.getElementById('hh_errorattop_mag').innerHTML = '';
      document.getElementById('errorattop').style.display = 'none';
      hideNotification('generalErrorMessage');
    }
  };
}

export default GeneralDataHandler;
