import List from '../../../views/pages/Module/Meter/List';
// import AnnualReading from '../../../views/pages/Module/Meter/AnnualReading';
import AnnualReadingById from '../../meter/reading/AnnualReadingById';
import InterimReading from '../../../views/pages/Module/Meter/InterimReading';

const pageList = [
  {
    name: 'Annual meter reading',
    path: ['/meter/reading/annual/:id/:occupationReadingsId'],
    component: AnnualReadingById,
  },
  {
    name: 'Interim meter reading',
    path: '/meter/reading/interium/',
    component: InterimReading,
  },
  {
    name: 'Meter List',
    path: '/meter/',
    component: List,
  },
];

export default pageList;
