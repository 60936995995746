import React, { Component } from 'react';
import {
  FiltersContainer,
  SearchContainer,
  SortContainer,
  MeterReadingData,
  AddPropertyModal,
  MeterModal,
  EmptyStateContainer,
  SortAlphabetically,
} from '../../../../neoverv';
import Autoload from '../../../../neoverv/Autoload';
import { connect } from 'react-redux';
import { getMeterReadings } from '../../../../redux/action/Meter/meterActions';
import GlobalServices from '../../../../neoverv/helpers/services/GlobalServices';
import MeterDataHandler from '../../../../neoverv/helpers/DataHandler/Meter';
import GeneralDataHandler from '../../../../neoverv/helpers/DataHandler/General';
import MeterIcon from '../../../../assets/images/module/general/blueEmptyMeter.svg';
import HelpLogo from '../../../../assets/images/module/general/book.svg';
import TourIcon from '../../../../assets/images/module/general/TourIcon.svg';
import breadcrumbsContent from '../../../../../src/assets/breadcrumbs.json';
import { Skeleton } from '@mantine/core';
import AddPropertyTypeModal from '../../../../neoverv/components/Module/General/CustomModal/AddPropertyTypeModal';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
const { filterByDate, filterByAlphabet } = Autoload.MainHelper;
const MOBILE_SIZE = 992;

export class MeterReadings extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      searchValue: '',
      ascendingOrder: true,
      filteredReadings: [],
      current_billingPeriod_id: '',
      current_billingPeriod: null,
      last_billingPeriod: null,
      rentalUnits: [],
      addPropertyModal: false,
      openMeterModal: false,
      billingPeriods: [],
      meterReadingApi: [],
      skeletonLoading: false,
      isMobile: window.innerWidth <= MOBILE_SIZE,
      showArticleCondition: '',
      addPropertyTypeModal: false,
      selectedPropertyType: ''
    };
    this.data_billing_period = [];
  }

  componentDidMount = () => {
    const {propertySelected} = this.props;
    if(propertySelected.propertyType === 'REGULAR'){
      this._isMounted = true;
      var loader = document.getElementById('hello-housing-loader');
      loader.style.display = 'none';
      this._fetchPropertyMeters();
      this.setState({skeletonLoading: true});
      window.addEventListener('resize', this.handleResize);
      // this.filterListByDate(this.state.meterReadingApi, 'occasionDate', this.state.ascendingOrder)
      if(this.props.propertySelected && this.props.propertySelected.id !== undefined){
        GlobalServices._getRentalUnitsByProperty(this, '_handleRentalUnitsResponse', this.props.propertySelected);
        GlobalServices._getBilingPeriodsByProperty(
          this,
          '_handleCurrentBillingPeriodResponse',
          this.props.propertySelected,
          'currentBillingPeriod?propertyId='
        );
      } else {
        this.ShowLoader();
      }
    }
  };


  _fetchPropertyMeters() {
    const propertyid = this.props.propertySelected && this.props.propertySelected.id;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.meter_api_base_url + 'meters?propertyId=' + propertyid + '&offset=0&results=100',
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      '_handlePropertyMeters',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  //@desc meter master data response handler
  _handlePropertyMeters(response) {
    console.log('meter api response', response);
    const data = [];

    if (response.status === true && response.statuscode === 401) {
      localStorage.clear();
      window.location.reload();
    }

    if (response.status === true && response.statuscode === 200) {
      if (response.result[0].allocationKey === null) {
        Autoload.DataHandlerGeneral.GeneralErrorMessage(
          true,
          lang.newKeys.text_error_not_found
          // + response.message
        );
      }
      const meters = MeterDataHandler.getAllMeters(response.result);
      // meters.map((temp, i) => {
      //   meters[i].rentalUnitName = '-';
      //   return null;
      // });
      let tempMeterObject = {};
      meters.map((tem, i) => {
        if (tem.propertyMeter) {
          if (tempMeterObject['Property Meter']) {
            tempMeterObject['Property Meter'].push(tem);
          } else {
            tempMeterObject['Property Meter'] = [{ ...tem }];
          }
          return null;
        }
      });
      meters.map((tem, i) => {
        if (tempMeterObject[tem.rentalUnitId]) {
          tempMeterObject[tem.rentalUnitId] = [...tempMeterObject[tem.rentalUnitId], { ...tem }];
        } else {
          tempMeterObject[tem.rentalUnitId] = [{ ...tem }];
        }
        if (Object.keys(tempMeterObject).length < 1 && tem.rentalUnitId !== '') {
          tempMeterObject[tem.rentalUnitId] = [{ ...tem }];
        }
        return null;
      });
      Object.keys(tempMeterObject).map((key) => {
        data.push({ [key]: [...tempMeterObject[key]] });
        return null;
      });
    }

    this.setState({showArticleCondition: this.props.rentalUnits.length > 0 ? data.length > 0 ? 'rental_unit_created && meter_created' : 'rental_unit_created && !meter_created' : ''})
  }

  handleResize = () => {
    // TODO: this causes data refetch on the meters reading details screen...
    if (window.innerWidth <= MOBILE_SIZE) {
      this.setState({ isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  _handleCurrentBillingPeriodResponse(response) {
    this.setState({ billingPeriods: [] });
    if (response.status === true && response.statuscode === 401) {
      localStorage.clear();
      window.location.reload();
    }

    if (response.status === true && response.statuscode === 200) {
      const billingPeriods = MeterDataHandler.generateBilingPeriodsObject(response.result, 'current');
      console.log(billingPeriods)

      this.setState({ current_billingPeriod_id: billingPeriods.id, billingPeriods: [billingPeriods], current_billingPeriod: billingPeriods }, () => {
        var billingPeriod = {};
        billingPeriod.occupationReadings = [];
        billingPeriod.id = billingPeriods.id;
        billingPeriod.periodEnd = billingPeriods.endDate;
        billingPeriod.periodStart = billingPeriods.startDate;
        billingPeriod.billingPeriodData = billingPeriods;
        billingPeriod.type = billingPeriods.type;

        this.setState({filteredReadings: [...this.state.filteredReadings, billingPeriod]});
        
        GlobalServices._getBilingPeriodsByProperty(
          this,
          '_handleLastBillingPeriodResponse',
          this.props.propertySelected,
          'lastBillingPeriod?propertyId='
        );
        //this._getOccupationReadings(billingPeriods, 0, 'current')
      });
      // this.setState({ current_billingPeriod: true });
      // this.setState({ billingPeriods: [billingPeriods] });
    } else {
      GlobalServices._getBilingPeriodsByProperty(
        this,
        '_handleLastBillingPeriodResponse',
        this.props.propertySelected,
        'lastBillingPeriod?propertyId='
      );
    }
  }

  _handleLastBillingPeriodResponse(response) {
    if (response.status === true && response.statuscode === 401) {
      localStorage.clear();
      window.location.reload();
    }

    if (response.status === true && response.statuscode === 200) {
      // const billingPeriods = this.state.billingPeriods;

      const billingPeriod = MeterDataHandler.generateBilingPeriodsObject(response.result, 'last');

      this.setState({
        billingPeriods: [...this.state.billingPeriods, billingPeriod],
        least_billingPeriod_id: billingPeriod.id,
        last_billingPeriod: billingPeriod
      }, () => {
        var newBillingPeriod = {};
        newBillingPeriod.occupationReadings = [];
        newBillingPeriod.id = billingPeriod.id;
        newBillingPeriod.periodEnd = billingPeriod.endDate;
        newBillingPeriod.periodStart = billingPeriod.startDate;
        newBillingPeriod.billingPeriodData = billingPeriod;
        newBillingPeriod.type = billingPeriod.type;

        this.setState({filteredReadings: [...this.state.filteredReadings, newBillingPeriod]});
        GlobalServices._getBilingPeriodsByProperty(this, '_handleBillingPeriodResponse', this.props.propertySelected)
        //this._getOccupationReadings(billingPeriod, 1, 'last')
      });

      // this.setState({ least_billingPeriod: true });
      // this.setState({ least_billingPeriod_id: billingPeriod.id });
    } else {
      GlobalServices._getBilingPeriodsByProperty(this, '_handleBillingPeriodResponse', this.props.propertySelected);
    }
  }
  _handleBillingPeriodResponse(response) {
    // console.log('billing period response', response)
    if (response.status === true && response.statuscode === 401) {
      localStorage.clear();
      window.location.reload();
    }

    if (response.status === true && response.statuscode === 200) {
      // const billingPeriods = this.state.billingPeriods;
      const billingPeriods = MeterDataHandler.generateBilingPeriods(response.result);

      billingPeriods.map((billingPeriod, index) => {
        // if(
        //   moment(billingPeriod.startDate).format('DD.MM.YYYY') === moment(new Date()).format('01.01.YY')
        //  && moment(billingPeriod.endDate).format('DD.MM.YYYY') === moment(new Date()).format('31.12.YY')

        // ){
        //   this.setState({ current_billingPeriod: false });
        // }

        // if(
        //   moment(billingPeriod.startDate).format('DD.MM.YYYY') === moment(new Date()).subtract(1, 'years').format('01.01.YY')
        //  && moment(billingPeriod.endDate).format('DD.MM.YYYY') === moment(new Date()).subtract(1, 'years').format('31.12.YY')

        // ){
        //   this.setState({ least_billingPeriod: false });
        // }

        if (
          billingPeriod.id !== this.state.current_billingPeriod_id &&
          billingPeriod.id !== this.state.least_billingPeriod_id
        ) {
          //this._getOccupationReadings(billingPeriod, index, 'other');
          var newBillingPeriod = {};
          newBillingPeriod.occupationReadings = [];
          newBillingPeriod.id = billingPeriod.id;
          newBillingPeriod.periodEnd = billingPeriod.endDate;
          newBillingPeriod.periodStart = billingPeriod.startDate;
          newBillingPeriod.billingPeriodData = billingPeriod;
          newBillingPeriod.type = billingPeriod.type;

          this.setState({
            filteredReadings: [...this.state.filteredReadings, newBillingPeriod], 
            billingPeriods: [...this.state.billingPeriods, billingPeriod]
          });
        }
      });

      // console.log('this.state.billingPeriod', this.state.billingPeriod);
    }
    this.ShowLoader();
  }

  _handleRentalUnitsResponse(response) {
    // console.log('response rental unit', response)
    if (response.status === true && response.statuscode === 401) {
      localStorage.clear();
      window.location.reload();
    }

    if (response.status === true && response.statuscode === 200) {
      const rentalUnits = GeneralDataHandler.getRantalUnits(response.result);
      this.setState({ rentalUnits });
    }
  }

  _getOccupationReadings(billingPeriod, index, type = 'other') {
    console.log('_getOccupationReadings', billingPeriod);
    const propertyid = this.props.propertySelected && this.props.propertySelected.id;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.meter_api_base_url +
      'occupationReadings?propertyId=' +
      propertyid +
      '&billingPeriodId=' +
      billingPeriod.id,
      // Autoload.Setting.swagger_mocked_api_baseUrl + 'occuptionReadings?propertyId=' + propertyid + '&billingPeriodId=' + billingPeriod.id,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handle_get_list_data',
      { billingPeriod: billingPeriod, currentIndex: index },
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  handle_get_list_data(response) {
    console.log('response list data', response);
    var billingPeriod = {};
    billingPeriod.occupationReadings = [];
    billingPeriod.id = response.other.billingPeriod.id;
    billingPeriod.periodEnd = response.other.billingPeriod.endDate;
    billingPeriod.periodStart = response.other.billingPeriod.startDate;
    billingPeriod.billingPeriodData = response.other.billingPeriod;
    // alert(typeof MeterDataHandler.getOccupationReadings(response.result));
    billingPeriod.occupationReadings = MeterDataHandler.getOccupationReadings(response.result, billingPeriod.id);
    // // ;

    billingPeriod.type = response.other.billingPeriod.type;
    // if(
    //   moment(billingPeriod.billingPeriodData.startDate).format('DD.MM.YYYY') === moment(new Date()).format('01.01.YY')
    //   && moment(billingPeriod.billingPeriodData.endDate).format('DD.MM.YYYY') === moment(new Date()).format('31.12.YY')

    // ){
    //   billingPeriod.type = "current";
    // }else if(
    //   moment(billingPeriod.billingPeriodData.startDate).format('DD.MM.YYYY') === moment(new Date()).subtract(1, 'years').format('01.01.YY')
    //   && moment(billingPeriod.billingPeriodData.endDate).format('DD.MM.YYYY') === moment(new Date()).subtract(1, 'years').format('31.12.YY')

    // ){
    //   billingPeriod.type = "last";
    // }else{

    //   billingPeriod.type = "other";
    // }
    this.data_billing_period = [...this.data_billing_period, billingPeriod];
    if (response.other.currentIndex) {
      console.log(billingPeriod)
      console.log(this.state.last_billingPeriod)
      this.data_billing_period[1].occupationReadings = this.data_billing_period[1].occupationReadings.filter(a => {
        if (((a.periodStart >= this.state.last_billingPeriod?.startDate) || (a.periodEnd <= this.state.last_billingPeriod?.endDate)) && ((!a.vacant) || (a.vacant && a.periodEndReading?.occasion === 'annual') || (a.vacant && a.periodEndReading?.occasion === 'move-in')
        )) {
          return a;
        }
      })
    } else {
      console.log(billingPeriod)
      console.log(this.state.current_billingPeriod)
      this.data_billing_period[0].occupationReadings = this.data_billing_period[0].occupationReadings.filter(a => {
        if (((a.periodStart >= this.state.current_billingPeriod?.startDate) || (a.periodEnd <= this.state.current_billingPeriod?.endDate)) && ((!a.vacant) || (a.vacant && a.periodEndReading?.occasion === 'annual') || (a.vacant && a.periodEndReading?.occasion === 'move-in')
        )) {
          return a;
        }
      })
    }
    this.data_billing_period.sort(function (a, b) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return new Date(b.periodEnd) - new Date(a.periodEnd);
    });
    // this.state.meterReadingApi = this.data_billing_period;
    this.setState({ filteredReadings: this.data_billing_period, meterReadingApi: this.data_billing_period });
    console.log('this.data_billing_period', this.data_billing_period);
    
    if (response.other.billingPeriod.type === 'current') {
      GlobalServices._getBilingPeriodsByProperty(
        this,
        '_handleLastBillingPeriodResponse',
        this.props.propertySelected,
        'lastBillingPeriod?propertyId='
      );
    }

    if (response.other.billingPeriod.type === 'last') {
      GlobalServices._getBilingPeriodsByProperty(this, '_handleBillingPeriodResponse', this.props.propertySelected);
    }
  }

  handleSearchHandler = (e) => {
    this.setState({ searchValue: e.target.value }, () => this.filterListBySearch(this.state.searchValue));
  };

  toggleAddPropertyModal = () => {
    this.setState({ addPropertyModal: !this.state.addPropertyModal });
  };

  toggleAddPropertyTypeModal = () => {
    this.setState({ addPropertyTypeModal: !this.state.addPropertyTypeModal });
  };

  handle_delete_reading_list_data = (response) => {
    response.other.closeModalFunc();
    if (response.status !== true) {
      Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang.meter.text_error_meter_readings_delete);
      // Autoload.Redirection.redirection_error();
    }
    // this._getOccupationReadings();
    GlobalServices._getBilingPeriodsByProperty(this, '_handleBillingPeriodResponse', this.props.propertySelected);
  };

  filterListBySearch = (value) => {
    const { meterReadingApi } = this.state;
    if (value === '') {
      this.setState({ filteredReadings: meterReadingApi });
      return;
    }
    // let updatedList = meterReadingApi;
    // updatedList = filterBySearchBar(this.state.searchValue, meterReadingApi, [
    //   'tenantFullName',
    //   // 'periodEnd',
    //   'periodStart',
    //   'readingDate',
    //   // 'billingPeriod'
    // ]);
    let newReadings = [];
    meterReadingApi.map((individualReading) => {
      if (individualReading.occupationReadings.length > 0) {
        individualReading.occupationReadings.map((reading) => {
          const { tenantFullName } = reading;
          if (tenantFullName.toLowerCase().includes(this.state.searchValue.toLowerCase())) {
            if (newReadings.length > 0) {
              newReadings.map((newReadingItem) => {
                if (newReadingItem.id === individualReading.id) {
                  newReadingItem.occupationReadings = [...newReadingItem.occupationReadings, reading];
                } else {
                  newReadings.push({ ...individualReading, occupationReadings: [reading] });
                }
              });
            } else {
              newReadings.push({ ...individualReading, occupationReadings: [reading] });
            }
          }
        });
      }
    });
    this.setState({ filteredReadings: newReadings });
  };

  filterListByDate = (data, param, ascendingOrder, nested, property, nestedParam) => {
    // console.log(data, 'filterListByDate');
    let occasionDateArray = [];
    for (let i = 0; i < data.length; i++) {
      // console.log('filterListByDate 2');
      let dataObj = {
        ...data[i],
        occupationReadings: [],
      };
      // console.log(data[i].occupationReadings, 'filter');
      for (let j = 0; j < data[i].occupationReadings.length; j++) {
        const occupationReading = data[i].occupationReadings[j];
        // console.log(occupationReading, 'occupationReading');
        const periodStartOccasion = occupationReading.periodStartReading.occasionDate;
        const periodEndOccasion = occupationReading.periodEndReading.occasionDate;
        const occupationObj = {
          ...occupationReading,
          periodEndOccasion,
          periodStartOccasion,
        };
        dataObj.occupationReadings.push(occupationObj);
      }
      occasionDateArray.push(dataObj);
    }
    occasionDateArray.map((item) => {
      // item.occupationReadings.sort((a, b) => a.periodDays - b.periodDays);
      item.occupationReadings.length > 0 && item.occupationReadings.reverse();
    });
    // console.log(occasionDateArray, 'occasionDateArray');
    // newArray.map(item=> {
    //   const ax = item.occupationReadings.sort((a, b) => {
    //     console.log('a.periodStartOccasion - b.periodEndOccasion',new Date(a.periodStartOccasion) - new Date(b.periodEndOccasion) )
    //     return a.periodStartOccasion - b.periodEndOccasion
    //   })
    // })
    this.setState({ ascendingOrder: !ascendingOrder }, () => this.setState({ filteredReadings: occasionDateArray }));

    // let sortedList = filterByDate(data, param, ascendingOrder, true, property, nestedParam);
    // this.setState({ filteredReadings: sortedList, ascendingOrder: !this.state.ascendingOrder });
  };

  _addNewMeter(data) {
    data.propertyId = this.props.propertySelected && this.props.propertySelected.id;
    const payload = MeterDataHandler.generateMeterData(data);
    const btnelement = document.getElementById('meter-button-container');
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.meter_api_base_url + 'meters',
      'POST',
      payload,
      btnelement.children[0],
      false,
      true,
      false,
      this,
      '_handleCreateMeter',
      { btnelement: btnelement },
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  _handleCreateMeter(response) {
    if (response.status === false) Autoload.Redirection.redirection_error();
    // this._getOccupationReadings();
    GlobalServices._getBilingPeriodsByProperty(this, '_handleBillingPeriodResponse', this.props.propertySelected);
    this.setState({ openMeterModal: !this.state.openMeterModal });
  }
  ShowLoader = () => {
    // var loader = document.getElementById('hello-housing-loader');
    // loader.style.display = 'none';
    // var emptyDiv = document.getElementById('empty-meter-page');
    // emptyDiv.style.display = 'none';
    // var dataDiv = document.getElementById('respond-meter-page');
    // dataDiv.style.display = 'block';
    this.setState({skeletonLoading: false});
  };

  sortAlphabetically = () => {
    // A to Z
    console.log('alphabetically');
    let filteredArray = [];
    let field = 'tenantFullName';

    filteredArray = this.state.filteredReadings.map((i) => ({
      ...i,
      occupationReadings: i.occupationReadings.sort((a, b) =>
        (a[field] || '').toString().localeCompare((b[field] || '').toString())
      ),
    }));
    console.log(filteredArray, 'filteredArray alphabetically  ');
    this.setState({ filteredReadings: filteredArray });
  };
  sortReverseAlphabetically = () => {
    // Z to A
    console.log('reversae alphabetically');
    let filteredArray = [];
    let field = 'tenantFullName';

    // filteredArray = this.props.filteredList.sort((a, b) =>
    //   (b[field] || '').toString().localeCompare((a[field] || '').toString())
    // );
    filteredArray = this.state.filteredReadings.map((i) => ({
      ...i,
      occupationReadings: i.occupationReadings.sort((a, b) =>
        (b[field] || '').toString().localeCompare((a[field] || '').toString())
      ),
    }));
    console.log(filteredArray, 'filteredArray reverse');
    this.setState({ filteredReadings: filteredArray });
  };

  render() {
    const { properties, propertySelected } = this.props;
    const { searchValue, ascendingOrder, filteredReadings, meterReadingApi, skeletonLoading, isMobile, showArticleCondition } = this.state;
    console.log('this.state.filteredReadings', this.state.filteredReadings);
    return (
      <>
        <div id="empty-meter-page"></div>
        <div className="meter-reading-section" id="respond-meter-page" style={{ display: 'block' }}>
        <div style={{display: 'flex', marginBottom: '14px'}}>
                {!skeletonLoading && breadcrumbsContent.map((item)=> item.area === '/meter' && 
                item.breadcrumbs.map((breadcrumb)=>
                breadcrumb.type === 'tour' ? breadcrumb.condition === showArticleCondition && breadcrumb.view === (isMobile ? 'mobile' : 'desktop') &&
                <div style={{display: 'flex', width: 'auto', alignItems: 'center', cursor: 'pointer', marginRight: '22px'}} onClick={()=> window.Intercom('startTour', breadcrumb.id)}>
                  <img src={TourIcon} />
                  <span style={{color: '#5555FF', marginLeft: '5px', fontSize: '14px'}}>{breadcrumb.label}</span>
                </div>
                :
                breadcrumb.view === (isMobile ? 'mobile' : 'desktop') &&
                <div style={{display: 'flex', width: 'auto', alignItems: 'center', cursor: 'pointer', marginRight: '22px'}} onClick={()=> window.Intercom('showArticle', breadcrumb.id)}>
                  <img src={HelpLogo} />
                  <span style={{color: '#5555FF', marginLeft: '5px', fontSize: '14px'}}>{breadcrumb.label}</span>
                </div>
                ))}
          </div>
          <FiltersContainer
            searchElement={
              <div className={properties.length === 0 || filteredReadings.length === 0 ? 'emptyState' : ''}>
                <Skeleton visible={skeletonLoading} style={{width: isMobile ? 'auto' : '300px'}}>
                  <SearchContainer
                    placeholder={`${lang.general.text_search}...`}
                    value={searchValue}
                    onChange={this.handleSearchHandler}
                    disabled={properties.length === 0 || meterReadingApi.length === 0} //|| filteredReadings.length == 0
                    //className="meter-reading-search"
                  />
                </Skeleton>
              </div>
            }
            style={{marginBottom: isMobile && 0}}
            sortElement={
              null
              // <SortAlphabetically
              //   sortingParam="alphabet"
              //   handleAscending={this.sortAlphabetically}
              //   handleDescending={this.sortReverseAlphabetically}
              // />
            }

          // sortElement={
          //   <div className={properties.length === 0 || filteredReadings.length === 0 ? 'emptyState' : ''}>
          //     <SortContainer
          //       className="meter-reading-sort"
          //       sortingParam="time"
          //       // disabled={properties.length==0 || filteredReadings.length==0}
          //       ascendingOrder={ascendingOrder}
          //       // filterHandler={() => this.filterListByDate(Autoload.MainHelper.checkProperty(meterReadingApi, 'occupationReadings'), 'tenantFullName', ascendingOrder)}
          //       // filterHandler={() => this.filterListByDate(meterReadingApi, 'occasionDate', ascendingOrder)}
          //       filterHandler={() => this.filterListByDate(filteredReadings, 'occasionDate', ascendingOrder)}
          //       // filterHandler={() =>this.handleSort(filteredReadings, 'occasionDate', [], ascendingOrder, true)}
          //     />
          //   </div>
          // }
          />
          <div className="divider meter-reading-divider" style={{marginTop: isMobile && 0}}></div>
          <div className="data-section">
            {skeletonLoading ?
            [...Array(5)].map((e, i) => <div>
              <div style={{width: '100%', height: '45px', display: 'flex', justifyContent: 'space-between'}}>
                <Skeleton visible={skeletonLoading} style={{width: '400px', height: '34px'}}/>
                <Skeleton visible={skeletonLoading} style={{width: '125px', height: '36px'}}/>
              </div>
              <div className='divider'/>
            </div>)
            :
              //this.state.rentalUnits.length > 0 
              Autoload.MainHelper.checkEmpty(this.props.properties) === false ? (
                <MeterReadingData 
                data={this.state.filteredReadings} 
                parent={this} 
                rentalUnitsExist={this.state.rentalUnits.length > 0} 
                propertySelected={propertySelected}/>
              ) 
              : (
                <EmptyStateContainer
                  heading={
                    Autoload.MainHelper.checkEmpty(this.props.properties) ? 'Ablesungen sind noch nicht verfügbar.' :
                    this.state.rentalUnits.length === 0
                      ? 'Ablesungen sind noch nicht verfügbar.'
                      : 'Es wurden keine Ablesungen gefunden'
                  }
                  subheading={Autoload.MainHelper.checkEmpty(this.props.properties) ? 'Legen Sie zunächst eine Immobilie und Mieteinheiten an, um Zähler anzulegen.' : 'Legen Sie zunächst Mieteinheiten an, um Mieterablesungen durchzuführen.'}
                  className="empty-meter-reading"
                  // heading={lang.meter.text_empty_state} Legen Sie zunächst eine Mieteinheit an, um Ablesungen durchzuführen.
                  //subheading={Autoload.MainHelper.checkEmpty(this.props.properties) ? lang.meter.text_empty_alert : ''}
                  icon={MeterIcon}
                  btnHandler={() => {
                    if (Autoload.MainHelper.checkEmpty(this.props.properties)) {
                      this.toggleAddPropertyTypeModal();
                    } else {
                      this.props.history.push('/user');
                      // this.setState({ openMeterModal: !this.state.openMeterModal });
                    }
                  }}
                  btnText={
                    Autoload.MainHelper.checkEmpty(this.props.properties)
                      ? lang.general.text_add_property
                      : 'Einheit hinzufügen'
                    // lang.meter.text_add_meter
                  }
                />
              )
              // !checkEmpty(filteredReadings) ? (
              // ) : null
              // (
              // )
            }
          </div>
          {/* add property */}
          <AddPropertyModal
              className="add_proprty_modal"
              edit={false}
              open={this.state.addPropertyModal}
              toggle={() => {
                this.toggleAddPropertyModal();
              }}
              propertyType={this.state.selectedPropertyType}
            />
            {this.state.addPropertyTypeModal &&
          <AddPropertyTypeModal
            className="add_proprty_type_modal"
            open={this.state.addPropertyTypeModal}
            toggle={() => {
              this.toggleAddPropertyTypeModal();
            }}
            next={(selectedPropertyType)=> {
              this.setState({selectedPropertyType});
              this.toggleAddPropertyTypeModal();
              this.toggleAddPropertyModal();
            }}
          />
          }

          {/* add meter */}
          {this.state.openMeterModal && (
            <MeterModal
              history={this.props.history}
              meter={null}
              className="add-meter-modal"
              open={this.state.openMeterModal}
              addNewMeter={(newMeterData) => this._addNewMeter(newMeterData)}
              toggle={() => this.setState({ openMeterModal: !this.state.openMeterModal })}
            />
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    meterData: state.getReadingReducer,
    propertySelected: state.generalReducer.property,
    properties: state.generalReducer.allProperties,
    rentalUnits: state.tenantDataReducer.rentalUnits,
  };
};

export default connect(mapStateToProps, { getMeterReadings })(MeterReadings);
