import React from 'react';
import { Form } from 'react-bootstrap';
import CustomModal from '.';
import ColorButton from '../Buttons/ColorButton';


export default function AlertModal(props) {
  const { text, onClick, open, heading, toggle, cancel, onCancel, dataSecured, rightButtonText='', leftButtonText=null, headingClassName, redButton=false, middle=false, onMiddle, middleText, rightButton=true } = props;
  return (
    <CustomModal
        heading={heading}
        headingClassName={headingClassName}
        open={open}
        toggle={toggle}
      >
        <Form.Group>
          <p 
          style={{
            fontSize: '14px',
            lineHeight: '24px',
            color: '#0E0E1D',
            fontWeight: '400',
            fontFamily: 'Inter,sans-serif'
          }}>
            {text}
          </p>
          <div style={{display: 'flex', justifyContent: cancel ? (rightButton === false ? 'flex-end' : 'space-between') : 'flex-end', marginTop: '24px'}}>
            {cancel &&
            <div style={{width: 100}}>
                <ColorButton
                    onClick={onCancel}
                    className={"gray-btn"}
                    text={leftButtonText ? leftButtonText : 'Abbrechen'}
                    buttonStyle={{margin: '0'}}
                />
            </div>}
            {middle ?
            <div style={{display: 'flex', justifyContent: 'space-between', width: '220px'}}>
              <div style={{width: 100}}>
                <ColorButton
                    onClick={onMiddle}
                    className={"gray-btn"}
                    text={middleText}
                    buttonStyle={{margin: '0'}}
                />
              </div>
              {rightButton &&
              <div style={{width: cancel ? '' : 50}}>
                <ColorButton
                    buttonStyle={{minWidth: '100px'}}
                    onClick={onClick}
                    className={redButton ? "red-btn" : "purple-btn"}
                    text={rightButtonText.length !== 0 ? rightButtonText : (dataSecured ? 'Festschreiben aufheben' : 'Festschreiben')}  
                />
              </div>}
            </div>
            :
            rightButton &&
            <div style={{width: cancel ? '' : 50}}>
              <ColorButton
                  onClick={onClick}
                  className={redButton ? "red-btn" : "purple-btn"}
                  text={rightButtonText.length !== 0 ? rightButtonText : (dataSecured ? 'Festschreiben aufheben' : 'Festschreiben')}  
              />
            </div>
            }
          </div>
        </Form.Group>
      </CustomModal>
  );
}
