import React from 'react';
import onClickOutside from 'react-onclickoutside';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import Up from '../../../../../assets/images/module/general/SelectArrowUp.svg';
import Down from '../../../../../assets/images/module/general/SelectArrowDown.svg';
import GoldenLockIcon from '../../../../../assets/images/module/general/GoldenLockIcon.svg';
import { Group } from '@mantine/core';

class SelectDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      selection: [],
      inputFocus: false,
      mouseInList: false,
      inputText: '',
      filterList: [],
      disable: false,
    };
    this.inputRef = React.createRef(null);
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.updateState(this.props);
    }
  }
  componentDidMount() {
    this.updateState(this.props);
  }

  updateState = (data) => {
    this.setState({
      filterList: data.options,
      selection: data.selectedValue,
      disable: data.disable,
      inputText: data.selectedValue && data.selectedValue[data.displayValue],
    });
  };

  toggle = () => this.setState({ open: !this.state.open });

  handleClickOutside = () => {
    this.setState({
      open: false,
    });
  };
  
  handleOnClick = (item) => {
    if(this.state.selection && this.state.selection.id !== item.id) {
      this.props.onRemove(this.props.options, this.state.selection);
      this.setState({
        selection: item,
        inputText: item[this.props.displayValue],
      });
        this.props.onSelect(this.props.options, item);
    } else if (this.state.selection && this.state.selection.id === item.id){
      this.props.onRemove(this.props.options, item);
    }  else {
      this.props.onSelect(this.props.options, item);
    }
    this.setState({
      open: false,
    });
  };

  isItemInSelection = (item) => {
    const {showSelectedHighlight= true} = this.props;
    if (this.props.className == 'cost-allocation-receipts' && this.state.selection && this.state.selection.id === item.id) {
      return true;
    } else if(this.state.selection && showSelectedHighlight && this.state.selection.receiptId === item.id){
      return true;
    }
    return false;
  };

  filterItems = (value) => {
    let arr = [];
    if (value === '') {
      this.setState({ filterList: this.props.options });
    } else {
      arr = this.props.options.filter((item) => {
        return item[this.props.displayValue].toString().toLowerCase().includes(value.toLowerCase());
      });
      this.setState({ filterList: arr });
    }
    this.setState({ inputText: value });
  };

  removeItem = (item) => {
    this.setState({ selection: null });
    this.props.onRemove(this.props.options, item);
  };

  render() {
    const { disable, inputFocus, selection, inputText, open, filterList } = this.state;
    const { displayValue, displayValue2, placeholder, tableView, endListContent, className, ddHeaderClassName, removeLongTextHiding, currentSubscription=null } = this.props;
    return (
      <div className={`dd-wrapper ${className} ${disable && 'dd-wrapper-disable'}`}>
        <div
          tabIndex={0}
          className={`dd-header ${ddHeaderClassName}`}
          role="button"
          onFocus={() => !disable && this.setState({
              inputFocus: true,
              open: !open,
            })
          }>
            <div className="interaction-area">
            {/* <div className="dd-header-title"> */}
            {selection &&
                <div className="dd-header-title-bold" key={selection.id}>
                  {this.props.viewDetails ? (
                    <span onClick={() => this.props.toggleViewDetailsModal(selection.receiptId ? selection.receiptId : selection.id)}>{selection[displayValue]}</span>
                  ) : (
                    <span>{selection[displayValue]}</span>
                  )}
                  {/* <span>{item[displayValue]}</span> */}
                  {/* <button
                    className="delete-btn"
                    onClick={(e) => {
                      if (!disable) {
                        e.stopPropagation();
                        this.removeItem(selection);
                      }
                    }}
                  >
                    <FontAwesomeIcon icon={faTimes} color="#727293" style={{ fontSize: '10px' }} />
                  </button> */}
                </div>
              }
            {/* <div className="dd-header-title-bold"> */}

            <input
              className="dd-header-title-bold"
              autofocus={true}
              ref={this.inputRef}
              placeholder={selection ? '' : placeholder}
              type="text"
              onFocus={() => {
                this.setState({
                  inputFocus: true,
                  open: true,
                });
              }}
              onBlur={() => {
                this.setState({
                  inputFocus: false,
                });
              }}
              onChange={(e) => {
                this.filterItems(e.target.value);
              }}
              disabled={true}
            />
            {/* </div> */}

            {/* </div> */}
          </div>
          <div className="dd-header-action" //onClick={() => !disable && this.toggle()}
          >
            <img className="dd-header-action-icon" src={open ? Up : Down} style={{ fontSize: '10px' }} />
          </div>
        </div>
                
            {open && (
            <ul
                className="dd-list"
                style={{
                top: 38,
                height: filterList.length > 3 ? '200px' : 'auto',
                }}
            >
                {filterList.length > 0 && (
                <div style={{ flex: 1 }}>
                    {tableView && filterList.length > 0 && (
                    <li className="dd-list-item heading">
                        <span className="cover-space">Beleg</span>
                        <span className="cover-space">Datum</span>
                        <span>
                        <FontAwesomeIcon icon={faCheck} color={'transparent'} />
                        </span>
                    </li>
                    )}
                    {!tableView
                    ? filterList.map((item) => (
                        <li className={`dd-list-item ${selection && this.isItemInSelection(item) ? 'selected' : ''}`} key={item.id}>
                            <button type="button" onClick={() => this.handleOnClick(item)}>
                              <Group style={{width: '100%'}} noWrap position='apart'>
                                <span className={!removeLongTextHiding && "cover-space"}>{item[displayValue]}</span>
                                {(currentSubscription && currentSubscription.subscriptionKey.split('_')[0] === 'STARTER') && <img src={GoldenLockIcon} style={{marginTop: '-2px'}}/>}
                              </Group>
                            {/* <span>
                                {
                                <FontAwesomeIcon
                                    icon={faCheck}
                                    color={selection && this.isItemInSelection(item) ? '#5555FF' : '#BFBFD2'} 
                                />
                                }
                            </span> */}
                            </button>
                        </li>
                        ))
                    : filterList.map((item) => (
                        <li className={`dd-list-item ${selection && this.isItemInSelection(item) ? 'selected' : ''}`} key={item.id}>
                            <button type="button" onClick={() => this.handleOnClick(item)}>
                                <span className="cover-space">
                                    {item[displayValue]}
                                </span>
                                <span className="cover-space">
                                    {item[displayValue2]}
                                </span>
                                <span>
                                    {<FontAwesomeIcon
                                    icon={faCheck}
                                    color={selection && this.isItemInSelection(item) ? '#5555FF' : '#BFBFD2'}
                                    />}
                                </span>
                            </button>
                        </li>
                        ))}
                </div>
                )}
                {endListContent && <li className="last-content">{endListContent}</li>}
            </ul>
            )}
        </div>
    );
  }
}

SelectDropdown.defaultProps = {
  placeholder: '',
  options: [],
  selectedValues: [],
  multiSelect: false,
  onSelect: () => {},
  onRemove: () => {},
  displayValue: '',
  disable: false,
  endListContent: <div></div>,
  tableView: false,
  displayValue2: '',
  className: '',
  viewDetails: false,
};

export default onClickOutside(SelectDropdown);

