export const GET_RENTAL_TENANTS='GET_RENTAL_TENANTS';
export const DELETE_TENANT='DELETE_TENANT';
export const SET_CURRENT_TENANT='SET_CURRENT_TENANT';
export const SET_ADD_EDIT_DATA='SET_ADD_EDIT_DATA';
export const EDIT_TENANT='EDIT_TENANT';
export const ADD_TENANT='ADD_TENANT';
export const SET_FILTERED_DATA='SET_FILTERED_DATA';
export const SET_ADD_EDIT_VALIDATION='SET_ADD_EDIT_VALIDATION';
export const TOGGLE_PREVIOUS_TENANTS='TOGGLE_PREVIOUS_TENANTS';
export const ADD_RENTAL_UNIT='ADD_RENTAL_UNIT';
export const EDIT_RENTAL_UNIT='EDIT_RENTAL_UNIT';
export const DELETE_RENTAL_UNIT='DELETE_RENTAL_UNIT';
export const LOADING_TENANTS_DATA='LOADING_TENANTS_DATA';







