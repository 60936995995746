import React, { Component } from 'react';
import CustomModal from './index';
import {
  ColorButton,
  ValueText,
  SwitchContainer,
  DropFile,
  AddCostTypeModal,
  SelectDropdown
} from '../../../../index';
import { Row, Col, Form, Tooltip, OverlayTrigger } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import Help from '../../../../../assets/images/module/general/Help.svg';
import calendar from '../../../../../assets/images/module/general/CalendarIcon.svg';
import { connect } from 'react-redux';
import RangeDatePicker from './DateRangePicker';
import Autoload from '../../../../Autoload';
import moment from 'moment';
import GlobalServices from '../../../../helpers/services/GlobalServices';
import GeneralDataHandler from '../../../../helpers/DataHandler/General';

import { showNotification, hideNotification } from '@mantine/notifications';
import RedTick from '../../../../../assets/images/module/general/RedTick.svg';
import GreenTick from '../../../../../assets/images/module/general/GreenTick.svg';
import AddIcon from '../../../../../assets/images/module/general/AddIcon.svg';
import Logo from '../../../../../assets/images/module/general/Logo.svg';
import Messenger from '../../../../../assets/images/module/general/Messenger.svg';

// Get receipts endpoint from new config
import { config } from '../../../../config/Setting';
import { Check, ThreeDRotationSharp } from "@material-ui/icons";
import { Alert, Group, Stack, Text } from "@mantine/core";
import * as customParseFormat from 'dayjs/plugin/customParseFormat'
import dayjs from 'dayjs';
import CopyIcon from '../../../SVGs/copyIcon';
import { formatNumber } from '../../../../../helpers/stringsManipulations';
import { onDelete } from 'draft-js/lib/RichTextEditorUtil';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
var { checkProperty, yearDifference, yearDifferenceMoment } = Autoload.MainHelper;
dayjs.extend(customParseFormat)

const MOBILE_SIZE = 992;
const TABLET_SIZE = 1200;

class SelectMessageRecipientToPrint extends Component {
  state = {
    selectedRecipient: null
  };
  
  constructor(props) {
    super(props);
  }

  componentDidMount(){
    
  }
  
  render() {
    const { open, toggle, className, recipients, onPrint } = this.props;
    const {selectedRecipient} = this.state;
    return (
      <>
        <CustomModal
          heading={'Select Recipient'}
          headingClassName='select-template-heading'
          open={open}
          toggle={() => {
            toggle();
          }}
          className={`${className}`}
        >
            <Stack>
                <Group><span style={{fontWeight: '400'}}>Kindly select a recipient for which you want to print this letter.</span></Group>
                <Stack className='preview-recipients-list'>
                    {recipients.map((recipient)=> 
                        <Group className={`preview-recipients-list-item ${recipient.id === selectedRecipient && 'preview-selected-recipient'}`} onClick={()=> this.setState({selectedRecipient: recipient.id})} position='apart'>
                            <Group spacing={8} noWrap>
                                <Group style={{background: '#454576', width: '22px', borderRadius: '120px', minWidth: '22px'}} position='center' align={'center'}>
                                    <span style={{textTransform: 'capitalize', color: 'white'}}>{recipient.name[0]}</span>
                                </Group>
                                {recipient.name}
                            </Group>
                        </Group>
                    )}
                </Stack>
                <Group position='apart'>
                    <div>
                        <ColorButton
                        text={'Abbrechen'}
                        className={'gray-btn'}
                        onClick={()=> toggle()}
                        />
                    </div>
                    <div>
                        <ColorButton
                        text={'Drucken'}
                        className={'purple-btn'}
                        onClick={()=> onPrint(selectedRecipient)}
                        />
                    </div>
                </Group>
            </Stack>
        </CustomModal>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    propertySelected: state.generalReducer.property,
  };
};

export default connect(mapStateToProps)(SelectMessageRecipientToPrint);
