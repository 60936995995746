import React, { Component } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import Receipt from '../../../../../assets/images/module/general/ReceiptDummy.png';
import { Row, Col, Form, OverlayTrigger } from 'react-bootstrap';
import CustomModal from '.';
import { showNotification } from '@mantine/notifications';
import ColorButton from '../Buttons/ColorButton';
import CopyIcon from '../../../SVGs/copyIcon';
import GreenTick from '../../../../../assets/images/module/general/GreenTick.svg';
import RedCross from '../../../../../assets/images/module/general/RedCross.svg';
import { Checkbox, CloseButton, Group, Loader, MultiSelect, Select, SimpleGrid, Stack, TextInput } from '@mantine/core';
import EmptyStateContainer from '../EmptyStateContainer/EmptyStateContainer';
import TenantIconBlue from '../../../../../assets/images/module/general/TenantIconBlue.svg';
import RentIconBlue from '../../../../../assets/images/module/general/RentIcon.svg';
import RentIconGreen from '../../../../../assets/images/module/general/RentIconGreen.svg';
import ReceiptsIcon from '../../../../../assets/images/module/general/ReceiptsIconGray.svg';
import RentIcon from '../../../../../assets/images/module/general/RentIconGray.svg';
import ReceiptsIconBlue from '../../../../../assets/images/module/general/ReceiptsIconBlue.svg';
import CloseButtonIcon from '../../../../../assets/images/module/general/CloseButtonIcon.svg';
import Up from '../../../../../assets/images/module/general/SelectArrowUp.svg';
import Down from '../../../../../assets/images/module/general/SelectArrowDown.svg';
import EmptyIcon from '../../../../../assets/images/EmptyReciept.svg';
import { NumericFormat } from 'react-number-format';
import CardSelection from '../CardSelection/CardSelection';
import { connect } from 'react-redux';
import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@material-ui/core';
import Autoload from '../../../../Autoload';
import dayjs from 'dayjs';
import accounting from 'accounting';
import SelectDropdown from '../SelectDropdown/SelectDropdown';
import SelectReceiptsForTransactionAssigmentModal from './SelectReceiptsForTransactionAssigmentModal';
import SwitchContainer from '../SwitchContainer';
import { push } from 'draft-js/lib/EditorState';
import ReceiptDetailModal from './ReceiptDetailModal';
import AddIcon from '@material-ui/icons/Add';
import ShowModal from './ShowModal';
import ImageModal from './ImageModal';
import LaunchIcon from '@material-ui/icons/Launch';

const MOBILE_SIZE = 992;
const TABLET_SIZE = 1200;
var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];

const tableCellStyle = {
    whiteSpace: 'nowrap', 
    overflow: 'hidden', 
    textOverflow: 'ellipsis', 
    fontSize: '14px', 
    fontFamily: 'Inter, sans-serif', 
    height: 'auto !important',
    fontWeight: '400',
    borderTop: '8px solid white'
}

function Value({
  value,
  label,
  onRemove,
  classNames,
  ...others
}) {
  console.log('Value: ', others);
  return (
    <div {...others}>
      <Tooltip title={others.type === 'tenant' ? `Mietzahlung: ${label}` : `Beleg: ${label}`}>
        <Group
          style={{
            cursor: 'pointer',
            backgroundColor: others.type === 'tenant' ? 'rgb(247, 227, 205)' : '#E3E3FC',
            border: 'none',
            borderRadius: '10px',
            padding: '5px 12px',
          }}
          position={'center'}
          align={'center'}
          noWrap
          spacing={8}
        >
          <Group align={'center'} position={'apart'} noWrap spacing={8}>
            <img src={others.type === 'tenant' ? RentIcon : ReceiptsIcon} style={{width: '14px', height: '14px'}} />
            <span style={{ lineHeight: 1, fontSize: '12px', whiteSpace: 'nowrap', fontWeight: '300', color: '#111147' }}>{label}</span>
          </Group>
          {<Group align={'center'} position='right'>
            <CloseButton
              onMouseDown={onRemove}
              variant="transparent"
              size={14}
              iconSize={14}
              tabIndex={-1}
              style={{marginLeft: 8, marginRight: -4}}
            />
          </Group>}
        </Group>
      </Tooltip>
    </div>
  );
}

export class AssignTransactionModal extends Component {
  state = {
    isMobile: window.innerWidth <= MOBILE_SIZE,
    isTablet: window.innerWidth <= TABLET_SIZE,
    hover1: false,
    hover2: false,
    hover3: false,
    hover4: false,
    selectedType: null,
    tableHeader: ['Mieter', 'Mieteinheit', 'Einzug', 'Auszug'],
    receiptsTableHeader: ['Name', 'Betrag (€)', 'Datum'],
    selectedTenant: [],
    selectedReceiptType: null,
    receiptsList: [],
    selectedReceipts: [],
    receiptsLoader: false,
    vatTypes: [
        {label: '19,00 %'},
        {label: '7,00 %'},
        {label: 'anderer'}
    ],
    taxCostTypes: [
    // {label: 'Abschreibungen für GWG'},
    {label: 'Schuldzinsen'},
    {label: 'Geldbeschaffungskosten'},
    {label: 'Erhaltungsaufwendungen'},
    {label: 'Laufende Betriebskosten'},
    {label: 'Verwaltungskosten'},
    {label: 'Sonstige Kosten'},

    {label: 'Erstattete Vorauszahlungen'},
    {label: 'Erhaltene Nachzahlungen'},
    {label: 'Vereinnahmte Kautionen / Mieten aus Vorjahren'},
    {label: 'Vom Finanzamt erstattete Umsatzsteuer'},
    {label: 'Öffentliche Zuschüsse'},
    {label: 'AfA Gebäude'},
    {label: 'AfA bewegliche Wirtschaftsgüter'},
    {label: 'An Finanzamt gezahlte Umsatzsteuer'},
    {label: 'Renten, dauernde Lasten'},
    ],
    selectedVatType: [],
    customVatType: '0%',
    selectedTaxCostType: [],
    vatAmount: null, 
    netAmount: null,
    netInFocus: false,
    vatInFocus: false,
    vatPercentInFocus: false,
    selectReceiptsForTransactionAssigmentModalOpen: false,
    autoReceiptCreation: false,
    autoReceiptCreationOldStatus: false,
    tenants: [],
    dropdownIcon: Down,
    addReceiptOpen: false,
    ReceiptPage: true,
    rightSectionProps: {
      onClick: ()=> this.setState({selectReceiptsForTransactionAssigmentModalOpen: true})
    },
    showModal: false,
    ImageOpen: false,
    currentRecord: null,
    selectedMonth: null,
    dropdownIconForMonths: Down,
    isEarlyPaymentEnabled: false
  };

  constructor(props) {
    super(props);
  }

  componentDidMount(){
    const {rentalUnits, edit, selectedTransaction} = this.props;
    console.log('selectedTransaction: ', selectedTransaction, edit);
    console.log('componentDidMount: ', rentalUnits);
    let tenants = [];
    rentalUnits.map((rentalUnit)=> rentalUnit.tenantsData.map((tenant)=> {
        if(tenant.vacant === false){
            let noOfMonthBetweenMoveInMoveOut= this.getMonthsBetween(tenant.in, tenant.out.length > 0 ? tenant.out : null);
            console.log('noOfMonthBetweenMoveInMoveOut: ', noOfMonthBetweenMoveInMoveOut);
            tenants.push({...tenant, rentalUnitName: rentalUnit.name, value: tenant.id, label: `${rentalUnit.name}: ${tenant.name} (${tenant.in} - ${tenant.out ? tenant.out : 'aktuell'})`, noOfMonthBetweenMoveInMoveOut});
        }
    }))
    if(edit){
      if(selectedTransaction.transactionAssigments.receiptIds?.length > 0){
        this.setState({selectedReceipts: selectedTransaction.transactionAssigments.receiptIds, selectedType: 'CREDIT_DEBIT_BALANCE'}, ()=> this.getReceipts());
      }else if(selectedTransaction.transactionAssigments.tenantId){
        this.setState({selectedTenant: [selectedTransaction.transactionAssigments.tenantId], selectedMonth: selectedTransaction.transactionAssigments.rentalMonth && dayjs(selectedTransaction.transactionAssigments.rentalMonth).format('MMMM YYYY'), selectedType: 'RENTAL_INCOME'});
      }
    }
    this.setState({tenants}, ()=> edit && this.getTransactionMapping()); //, selectedMonth: dayjs(selectedTransaction.bankBookingDate).format('MMMM YYYY')
  }

  componentDidUpdate(prevProps, prevState){
    const {edit, selectedTransaction} = this.props;
    const {selectedMonth, selectedTenant, tenants} = this.state;
    if(selectedTenant.length === 0 && selectedMonth){
      this.setState({selectedMonth: null});
    }

    if((prevState.selectedTenant.length === 0 && selectedTenant.length > 0) || (prevState.selectedTenant.length > 0 && selectedTenant.length > 0 && prevState.selectedTenant[0] !== selectedTenant[0])){
      let sTenant = tenants.find((tenant)=> tenant.id === selectedTenant[0]);
      if(sTenant !== undefined){
        if(sTenant?.noOfMonthBetweenMoveInMoveOut?.length > 0){
          let sMonth= dayjs(sTenant?.noOfMonthBetweenMoveInMoveOut[0]?.value, 'MMMM YYYY').isAfter(dayjs(selectedTransaction?.bankBookingDate)) ? dayjs(sTenant?.noOfMonthBetweenMoveInMoveOut[sTenant?.noOfMonthBetweenMoveInMoveOut.length - 1]?.value).isBefore(dayjs(selectedTransaction?.bankBookingDate)) ? sTenant?.noOfMonthBetweenMoveInMoveOut[sTenant?.noOfMonthBetweenMoveInMoveOut.length - 1]?.value : dayjs(selectedTransaction?.bankBookingDate).format('MMMM YYYY') : sTenant?.noOfMonthBetweenMoveInMoveOut[0]?.value;
          if(edit && selectedMonth === null){
            this.setState({selectedMonth: selectedTransaction?.transactionAssigments?.rentalMonth && dayjs(selectedTransaction.transactionAssigments.rentalMonth).format('MMMM YYYY')})
          }else if(!edit){
            this.setState({selectedMonth: sMonth});
          }
        }
      }
    }
  }

  getMonthsBetween=(dateA, dateB)=> {
    const startDate = dayjs(dateA, 'DD.MM.YYYY');
    const endDate = dateB ? dayjs(dateB, 'DD.MM.YYYY') : dayjs();
    console.log('endDate: ', endDate, dateB);
    const months = [];
  
    // let current = startDate;
  
    // while (current.isBefore(endDate) || current.isSame(endDate, 'month')) {
    //   months.push({month: current, label: current.format('MMMM YYYY'), value: current.format('MMMM YYYY')}); // Customize the format as needed
    //   current = current.add(1, 'month');
    // }
  
    // return months;

    let current = endDate;

    while (current.isAfter(startDate) || current.isSame(startDate, 'month')) {
      months.push({month: current, label: current.format('MMMM YYYY'), value: current.format('MMMM YYYY')}); // Customize the format as needed
      current = current.subtract(1, 'month');
    }

    return months;
  }

  handleMouseEnter=(type)=>{
    this.setState({[type]: true});
  }

  handleMouseLeave=(type)=>{
      this.setState({[type]: false});
  }

  getReceipts=()=>{
    this.setState({receiptsLoader: true});
    Autoload.HttpRequestAxios.HttpRequest(
        Autoload.Setting.rm_api_base_url + 'property/' + this.props.propertySelected.id,
        'GET',
        null,
        null,
        false,
        true,
        false,
        this,
        'handleGetReceipts',
        null,
        false,
        '',
        {
          'Content-Type': 'application/json',
          Authorization: Autoload.Utils.getAuthToken(),
        },
        true
    );
  }

  handleGetReceipts(response){
    if(response.status && response.statuscode === 200){
        this.setState({receiptsList: response.result.map((receipt)=> ({...receipt, value: receipt.id, label: receipt.name}))});
    }
    // setTimeout(() => {
    //   this.setState({receiptsLoader: false});  
    // }, 5000);
    this.setState({receiptsLoader: false});  
  }

  postTransactionReceiptAssignment=(newReceiptsToAdd=[])=>{
    const {selectedTransaction, propertySelected} = this.props;
    const {selectedReceipts, selectedTenant, selectedType, autoReceiptCreation, autoReceiptCreationOldStatus, selectedMonth} = this.state;

    if(selectedType === 'CREDIT_DEBIT_BALANCE' && (selectedReceipts.length > 0 || newReceiptsToAdd.length > 0)){
      console.log('postTransactionReceiptAssignment: newReceiptsToAdd, selectedReceipts:', newReceiptsToAdd, selectedReceipts);
      Autoload.HttpRequestAxios.HttpRequest(
        Autoload.Setting.finance_api_base_url + 'ReceiptTenantTransactions',
        'POST',
        {
          transactionId: selectedTransaction.id,
          receiptIds: newReceiptsToAdd.length > 0 ? newReceiptsToAdd : selectedReceipts,
          tenantId: null,
          propertyId: propertySelected.id,
          rentalMonth: null
        },
        null,
        false,
        true,
        false,
        this,
        'handlePostTransactionReceiptAssignment',
        {type: 'receipt', eol: true},
        false,
        '',
        {
          'Content-Type': 'application/json',
          Authorization: Autoload.Utils.getAuthToken(),
        },
        true
      );
      
    }else if(selectedType === 'RENTAL_INCOME' && selectedTenant.length > 0){
      Autoload.HttpRequestAxios.HttpRequest(
        Autoload.Setting.finance_api_base_url + 'ReceiptTenantTransactions',
        'POST',
        {
          transactionId: selectedTransaction.id,
          receiptIds: null,
          tenantId: selectedTenant[0],
          propertyId: propertySelected.id,
          rentalMonth: dayjs(selectedMonth, 'MMMM YYYY').add(1, 'day').utc().format()
        },
        null,
        false,
        true,
        false,
        this,
        'handlePostTransactionReceiptAssignment',
        {type:'tenant', eol: true},
        false,
        '',
        {
          'Content-Type': 'application/json',
          Authorization: Autoload.Utils.getAuthToken(),
        },
        true
      );
    }
  }

  handlePostTransactionReceiptAssignment(response){
    const {edit, selectedTransaction, toggle} = this.props;
    const {autoReceiptCreation, selectedReceipts, autoReceiptCreationOldStatus} = this.state;
    
    if(response.status && response.statuscode === 200){
      showNotification({
        message: edit ? 'Änderungen übernommen.' : 'Beleg erfolgreich zugeordnet.',
        icon: <img src={GreenTick} alt='green-tick'/>,
        id: 'assignReceiptTransaction'
      });
      toggle(true);
    }else{
      showNotification({
        message: 'Es ist ein Fehler aufgetreten. Bitte probieren Sie es erneut oder wenden Sie sich an den Support.',
        icon: <img src={RedCross} alt='red-cross'/>,
        id: 'assignReceiptTransactionError'
      });
    }
  }

  checkForDeletion=()=>{
    const {selectedTransaction, toggle, edit} = this.props;
    const {selectedReceipts, selectedType, selectedTenant, autoReceiptCreation, autoReceiptCreationOldStatus, selectedMonth} = this.state;

    if(edit){
      this.firstDeleteThanPostTransactionMapping();
      
      if(selectedType === 'RENTAL_INCOME'){
        if(selectedTransaction.transactionAssigments?.receiptIds?.length > 0){
          this.deleteTransactionReceiptAssignment(selectedTransaction.transactionAssigments.receiptIds);
        }else{
          this.deleteTransactionTenantAssignment(selectedTransaction.transactionAssigments?.tenantId);
        }
        // else if(selectedTransaction.transactionAssigments?.tenantId && (selectedTenant.length === 0 || selectedTenant[0] !== selectedTransaction.transactionAssigments?.tenantId || selectedMonth !== selectedTransaction.transactionAssigments?.rentalMonth)){
        //   this.deleteTransactionTenantAssignment(selectedTransaction.transactionAssigments?.tenantId);
        // }
        // else if(selectedTransaction.transactionAssigments?.tenantId && (selectedTenant.length > 0 || selectedTenant[0] !== selectedTransaction.transactionAssigments?.tenantId || selectedMonth !== selectedTransaction.transactionAssigments?.rentalMonth)){
        //   this.handleAddOrRemoveReceiptAssigment();
        // }
      }else if(selectedType === 'CREDIT_DEBIT_BALANCE'){
        if(selectedTransaction.transactionAssigments?.tenantId){
          this.deleteTransactionTenantAssignment(selectedTransaction.transactionAssigments?.tenantId);
        }else{
          let oldReceiptsToDelete= selectedTransaction.transactionAssigments?.receiptIds?.length > 0 ? selectedTransaction.transactionAssigments.receiptIds.filter((receiptId)=> selectedReceipts.find((id)=> receiptId === id) === undefined) : [];
          if(oldReceiptsToDelete.length > 0){
            this.deleteTransactionReceiptAssignment(oldReceiptsToDelete);
          }else{
            this.handleAddOrRemoveReceiptAssigment();
          }
        } 
      }else{
        toggle(true);
      }
    }else{
      if(autoReceiptCreation && selectedType && (selectedTenant.length > 0 || selectedReceipts.length > 0)){
        this.transactionMappingSetup();
      }else{
        this.deleteTransactionMapping();
      }
      this.handleAddOrRemoveReceiptAssigment();
    }
  }

  firstDeleteThanPostTransactionMapping=()=> this.deleteTransactionMapping();

  transactionMappingSetup=()=>{
    const {selectedTransaction, selectedBankAccount, accountData, propertySelected} = this.props;
    const {selectedReceipts, selectedTenant, receiptsList, selectedType, isEarlyPaymentEnabled, autoReceiptCreation} = this.state;
    let payloads=[];
    if(selectedType === 'CREDIT_DEBIT_BALANCE' && selectedReceipts.length > 0){
      let receipts= receiptsList.filter((receipt)=> selectedReceipts.find((receiptId)=> receiptId === receipt.id) !== undefined)
      receipts.map((receipt)=> 
      payloads.push({
        accountId: accountData.accountId,
        propertyId: propertySelected.id,
        bankAccountId: selectedBankAccount.id,
        transactionType: 200,
        counterpartIban: selectedTransaction?.counterpartIban,
        utilityBillingCostTypeId: receipt?.selectedCostTypes.length > 0 ? receipt?.selectedCostTypes[0]?.id : null,
        taxCostType: receipt?.taxCostType,
        taxRate: receipt?.vatPercent,
        tenantId: null,
        isEarlyPaymentEnabled: false
      }));

      this.postTransactionMapping(payloads);
    }else if(selectedType === 'RENTAL_INCOME' && selectedTenant){
      payloads.push({
        accountId: accountData.accountId,
        propertyId: propertySelected.id,
        bankAccountId: selectedBankAccount.id,
        transactionType: 100,
        counterpartIban: selectedTransaction?.counterpartIban,
        utilityBillingCostTypeId: null,
        taxCostType: null,
        taxRate: null,
        tenantId: selectedTenant[0],
        isEarlyPaymentEnabled: autoReceiptCreation ? isEarlyPaymentEnabled : false
      });

      this.postTransactionMapping(payloads);
    }
  }

  postTransactionMapping=(payloads)=>{
    payloads.map((payload)=> 
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.finance_api_base_url + 'TransactionMappings',
      'POST',
      payload,
      null,
      false,
      true,
      false,
      this,
      'handlePostTransactionMapping',
      null,
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      },
      true
    ));
  }

  handlePostTransactionMapping(response){
    if(response.status && response.statuscode === 200){
      // showNotification({
      //   message: this.props.edit ? 'Änderungen übernommen.' : 'Beleg erfolgreich zugeordnet.',
      //   icon: <img src={GreenTick} alt='green-tick'/>,
      //   id: 'assignReceiptTransaction'
      // });
      this.props.toggle(true);
    }else{
      showNotification({
        message: 'Es ist ein Fehler aufgetreten. Bitte probieren Sie es erneut oder wenden Sie sich an den Support.',
        icon: <img src={RedCross} alt='red-cross'/>,
        id: 'PostTrasactionMappingError'
      });
    }
  }

  getTransactionMapping=()=>{
    const {selectedBankAccount, selectedTransaction, propertySelected} = this.props;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.finance_api_base_url + `TransactionMappings/Property/${propertySelected.id}/Iban/${selectedTransaction.counterpartIban}`,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleGetTransactionMapping',
      null,
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      },
      true
    );
  }

  handleGetTransactionMapping(response){
    const {selectedType} = this.state;
    if(response.status && response.statuscode === 200){
      this.setState({autoReceiptCreation: true, autoReceiptCreationOldStatus: true, isEarlyPaymentEnabled: selectedType === 'RENTAL_INCOME' ? (response.result.length > 0 ? response.result[0].isEarlyPaymentEnabled : false) : false});
    }
  }

  deleteTransactionMapping=()=>{
    const {selectedBankAccount, selectedTransaction, propertySelected} = this.props;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.finance_api_base_url + `TransactionMappings/Property/${propertySelected.id}/Iban/${selectedTransaction.counterpartIban}`,
      'DELETE',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleDeleteTransactionMapping',
      null,
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      },
      true
    );
  }

  handleDeleteTransactionMapping(response){
    const {autoReceiptCreation, selectedType, selectedTenant, selectedReceipts} = this.state;
    console.log('handleDeleteTransactionMapping: ', response);
    if(autoReceiptCreation && selectedType && (selectedTenant.length > 0 || selectedReceipts.length > 0)){
      this.transactionMappingSetup();
    }
  }

  deleteTransactionReceiptAssignment=(receipts)=>{
    const {selectedTransaction} = this.props;

    receipts.map((receiptId, index)=> 
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.finance_api_base_url + `ReceiptTenantTransactions/Transaction/${selectedTransaction.id}/Receipt/${receiptId}`,
      'DELETE',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleDeleteTransactionReceiptAssignment',
      {eol: receipts.length === (index + 1)},
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      },
      true
    ));
  }

  deleteTransactionTenantAssignment=(tenantId)=>{
    const {selectedTransaction} = this.props;

    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.finance_api_base_url + `ReceiptTenantTransactions/Transaction/${selectedTransaction.id}/Tenant/${tenantId}`,
      'DELETE',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleDeleteTransactionReceiptAssignment',
      {eol: true},
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      },
      true
    );
  }

  handleDeleteTransactionReceiptAssignment(response){
    if(response.other.eol){
      // setTimeout(() => {
      //   this.props.toggle();  
      // }, 2000);
      this.handleAddOrRemoveReceiptAssigment();
    }
  }

  handleAddOrRemoveReceiptAssigment=()=>{
    const {edit, selectedTransaction, toggle} = this.props;
    const {selectedReceipts, selectedType, selectedTenant, autoReceiptCreation, autoReceiptCreationOldStatus} = this.state;

    if(edit){
      if(selectedType === 'CREDIT_DEBIT_BALANCE' && selectedReceipts?.length > 0){
        let newReceiptsToAdd= selectedTransaction.transactionAssigments?.receiptIds?.length > 0 ? selectedReceipts.filter((id)=> selectedTransaction.transactionAssigments.receiptIds.find((receiptId)=> receiptId === id) === undefined) : selectedReceipts;
        if(newReceiptsToAdd.length > 0){
          this.postTransactionReceiptAssignment(newReceiptsToAdd);
        }else{
          toggle(true);
        }
      }else if(selectedType === 'RENTAL_INCOME' && selectedTenant.length > 0){
        this.postTransactionReceiptAssignment();
      }else{
        toggle(true);
      }
    }else{
      this.postTransactionReceiptAssignment();
    }
  }

  handleAddReceiptModalOpen=(e)=>{
    //e.preventDefault();
    this.setState({addReceiptOpen: true});
  }

  openShow = (id, props) => {

    console.log('openShow: ', props);
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.rm_api_base_url + 'attachment/' + id,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      '_handle_get_show_file',
      id,
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };
  
  _handle_get_show_file = (response) => {
    const {receiptsList} = this.state;
    if (response.status === false && response.message !== 'Not Found') {
      
    }

    if(response && response.result.includes('pdf')){
      fetch(Autoload.Setting.rm_api_base_url + 'attachment/' + response.other +'/download',
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
          'Authorization': Autoload.Utils.getAuthToken()
        }
      })
            .then(response1 => {
                response1.blob()
                .then(blob => {
                  console.log('blob: ', blob)
                  let currentRecord = receiptsList.find((receipt)=> receipt.id === response.other);
                  console.log('currentRecord: ', currentRecord, response, receiptsList);
                  if(currentRecord){
                    this.setState({
                      currentRecord: { ...currentRecord, file_name: blob.size > 0 ? blob : '' },
                      showModal: true,
                    });
                  }else{
                    showNotification({
                      message: 'Es ist ein Fehler aufgetreten. Bitte probieren Sie es erneut oder wenden Sie sich an den Support.',
                      icon: <img src={RedCross} alt='red-cross'/>
                    });
                  }})
                  var loader = document.getElementById('hello-housing-loader');
                  loader.style.display = 'none';
              })
    } else {
      let currentRecord = receiptsList.find((receipt)=> receipt.id === response.other);
      if(currentRecord){
        this.setState({
          currentRecord: { ...currentRecord, file_name: response.result != null ? response.result : '' },
          showModal: true,
        });
      }else{
        showNotification({
          message: 'Es ist ein Fehler aufgetreten. Bitte probieren Sie es erneut oder wenden Sie sich an den Support.',
          icon: <img src={RedCross} alt='red-cross'/>
        });
      }
      
      var loader = document.getElementById('hello-housing-loader');
      loader.style.display = 'none';
    }
  };
  
  render() {
    const { open, toggle, className, rentalUnits, selectedTransaction, edit } = this.props;
    const { hover1, hover2, selectedType, isMobile, receiptsLoader, receiptsList, selectedReceipts, selectedTenant, selectReceiptsForTransactionAssigmentModalOpen, autoReceiptCreation, tenants, dropdownIcon, addReceiptOpen, ReceiptPage, rightSectionProps, showModal, ImageOpen, currentRecord, selectedMonth, dropdownIconForMonths, isEarlyPaymentEnabled } = this.state;
    
    console.log('rentalUnits: ', rentalUnits);
    console.log('receiptsList: ', receiptsList);
    console.log('selectedReceipts_1234: ', selectedReceipts, receiptsList.filter((receipt)=> selectedReceipts.find((receiptId)=> receiptId === receipt.id) !== undefined));
    console.log('selectedTenant_1234: ', selectedTenant);

    console.log('tenants: ', tenants);

    console.log('edit: ', edit);
    return (
      <CustomModal
        heading={'Beleg zuordnen'}
        headingClassName={'assign-transaction-modal-heading'}
        open={open}
        toggle={() => {
          toggle();
        }}
        className={`${className}`}
      >
        <Stack spacing={20}>
          <Stack spacing={0}>
            <Group><span className='add-allocation-sub-headings'>Art der Zahlung</span></Group>
            {/* <Group grow noWrap style={{margin: '20px 0px 5px'}}> */}
            <SimpleGrid cols={2} style={{margin: '20px 0px 5px'}}>
                  <CardSelection
                  title={'Ausgabe'}
                  subText={'Bei der Zahlung handelt es sich um eine Ausgabe oder Erstattung.'}
                  icon={ReceiptsIcon}
                  blueIcon={ReceiptsIconBlue}
                  hover={hover1}
                  hoverName={'hover1'}
                  selectedType={selectedType}
                  type={'CREDIT_DEBIT_BALANCE'}
                  disabled={false}
                  onClick={()=> this.setState({selectedType: 'CREDIT_DEBIT_BALANCE'}, ()=> this.getReceipts())}
                  handleMouseEnter={(hoverName)=> this.handleMouseEnter(hoverName)}
                  handleMouseLeave={(hoverName)=> this.handleMouseLeave(hoverName)}
                  isMobile={isMobile}
                  //typeError={typeError}
                  />
                  <CardSelection
                  title={'Mieteinnahme'}
                  subText={'Bei der Zahlung handelt es sich um eine Mietzahlung.'}
                  icon={RentIcon}
                  blueIcon={RentIconBlue}
                  hover={hover2}
                  hoverName={'hover2'}
                  selectedType={selectedType}
                  type={'RENTAL_INCOME'}
                  disabled={false}
                  onClick={()=> this.setState({selectedType: 'RENTAL_INCOME'})}
                  handleMouseEnter={(hoverName)=> this.handleMouseEnter(hoverName)}
                  handleMouseLeave={(hoverName)=> this.handleMouseLeave(hoverName)}
                  isMobile={isMobile}
                  //typeError={typeError}
                  />
            </SimpleGrid>
            {/* </Group> */}
          </Stack>
            
            {selectedType === 'RENTAL_INCOME' &&
            <Stack>
                <Group>
                    <span className='modal-sub-headings'>Mieter auswählen</span>
                </Group>
                <Group grow>
                    <MultiSelect
                    data={tenants}
                    value={selectedTenant}
                    label={'Mieter'}
                    onChange={(value)=> this.setState({selectedTenant: value.length > 0 ? [value[value.length - 1]] : []})}
                    rightSection={<img src={dropdownIcon}/>}
                    valueComponent={(props)=> <Value {...props} type={'tenant'}/>}
                    className='multi-select-bank-transactions-receipt-assignment'
                    placeholder="Wählen"
                    styles={{
                        disabled: { background: 'white', cursor: 'pointer' },
                        input: {opacity: 1},
                        rightSection: {marginRight: '10px'},
                        //dropdown: {display: selectedTenant.length > 0 && 'none'},
                        //value: {maxWidth: '100px'}
                    }}
                    onClick={()=> tenants.length === 0 && 
                      showNotification({
                        message: 'Es wurden noch keine Mieter angelegt.', 
                        icon: <img src={RedCross} alt='red-cross'/>
                      })
                    }
                    maxSelectedValues={2}
                    //clearable
                    onDropdownOpen={()=> tenants.length > 0 && this.setState({dropdownIcon: Up})}
                    onDropdownClose={()=> tenants.length > 0 && this.setState({dropdownIcon: Down})}
                    zIndex={9999999}
                    //disabled={receiptsLoader}
                    />
                </Group>
                <Group grow>
                  {console.log('testing124: ',tenants.find((tenant)=> tenant.value === selectedTenant[0]), selectedTenant)}
                  <Select
                  placeholder="Wählen"
                  label={'Mietmonat'}
                  onChange={(value)=> this.setState({selectedMonth: value})}
                  data={tenants.find((tenant)=> tenant.value === selectedTenant[0]) ? tenants.find((tenant)=> tenant.value === selectedTenant[0])?.noOfMonthBetweenMoveInMoveOut : [{month: dayjs(selectedTransaction?.bankBookingDate), value: dayjs(selectedTransaction?.bankBookingDate).format('MMMM YYYY'), label: dayjs(selectedTransaction?.bankBookingDate).format('MMMM YYYY')}]}
                  value={selectedMonth}
                  searchable
                  // styles={{
                  //       item: {
                  //         color: '#0e0e1d',
                  //         fontSize: '14px',
                  //         fontWeight: '500',
                  //         fontFamily: 'sans-serif'
                  //       }
                  // }}
                  //className='multi-select-bank-transactions-receipt-assignment'
                  //maxSelectedValues={1}
                  rightSection={<img src={dropdownIconForMonths}/>}
                  onDropdownOpen={()=> this.setState({dropdownIconForMonths: Up})}
                  onDropdownClose={()=> this.setState({dropdownIconForMonths: Down})}
                  zIndex={9999999}
                  disabled={selectedTenant.length === 0}
                  />
                </Group>
            </Stack>}

            
            <Stack>
              {selectedType === 'CREDIT_DEBIT_BALANCE' &&
              <>
                <Group>
                    <span className='modal-sub-headings'>Beleg zuordnen</span>
                </Group>
                <Group position='apart' noWrap>
                  <Group grow style={{width: '100%', maxWidth: '411px'}}>
                    <MultiSelect
                    data={receiptsList}
                    value={selectedReceipts}
                    onChange={(values)=> this.setState({selectedReceipts: values})}
                    rightSection={receiptsLoader ? <Loader size={'xs'}/> : 
                    <LaunchIcon 
                    style={{fontSize: '15px', color: selectedReceipts.length > 0 ? '#5555FF' : '#9A9AB5'}}
                    onClick={()=> rightSectionProps.onClick()}
                    />}
                    valueComponent={(props)=> <Value {...props} onClick={()=> rightSectionProps.onClick()}/>} //onClick={()=> this.openShow(props.value, props)}
                    wrapperProps={selectedReceipts.length === 0 && rightSectionProps}
                    className='multi-select-bank-transactions-receipt-assignment'
                    placeholder="Wählen"
                    styles={{
                        disabled: { background: 'white', cursor: 'pointer' },
                        input: {opacity: 1},
                        rightSection: {
                          marginRight: '5px', 
                          marginLeft: '5px',
                          backgroundColor: 'white', 
                          borderTop: '1px solid #ced4da', 
                          borderBottom: '1px solid #ced4da',
                        },
                        dropdown: {display: 'none'},
                        wrapper: {width: '100%'},
                        values: {marginRight: '20px'}
                    }}
                    disabled={receiptsLoader}
                    />
                  </Group>  
                  <IconButton 
                  aria-label="filter list"
                  onClick={this.handleAddReceiptModalOpen}
                  style={{
                  background: '#5655FE',
                  border: '1px solid #5555FF', 
                  boxShadow: '0 1px 2px 0 rgba(14, 14, 29, .06)', 
                  padding: '5px',
                  cursor: 'pointer',
                  opacity: 1
                  }}>
                    <AddIcon 
                    style={{
                      color: 'white',
                      fontSize: '15px'
                      }}/>
                  </IconButton>
                </Group>
              </>}
              {selectedType &&
              <Stack style={{marginTop: '10px'}} spacing={'10px'}>
                <Group><span className='add-allocation-sub-headings tooltips remember-setting-for-transaction-assignment'>Einstellungen merken</span></Group>
                <Group>
                    <SwitchContainer
                    switchText={selectedType === 'RENTAL_INCOME' ? <span>Einstellungen merken und für Zahlungen mit IBAN <span style={{fontWeight: 'bold'}}>{selectedTransaction?.counterpartIban}</span> zukünftig automatisch Mietzahlung erzeugen.</span> : <span>Einstellungen merken und für Zahlungen mit IBAN <span style={{fontWeight: 'bold'}}>{selectedTransaction?.counterpartIban}</span> zukünftig automatisch Belege erzeugen</span>}
                    checked={(selectedReceipts.length > 0 || selectedTenant.length > 0) ? autoReceiptCreation : false}
                    onChange={()=> {
                      if(selectedTransaction?.counterpartIban){
                        this.setState({autoReceiptCreation: !autoReceiptCreation});
                      }else{
                        showNotification({
                          message: 'Für Transaktionen ohne IBAN können keine Regeln erstellt werden.', 
                          icon: <img src={RedCross} alt='red-cross'/>,
                          id: 'noCounterIbanError'
                        });
                      }
                    }}
                    disabled={selectedReceipts.length === 0 && selectedTenant.length === 0 && selectedTransaction?.counterpartIban === null}
                    textClassName={'transaction-assigment-modal-remember-switch-label'}
                    />
                </Group>

                {(selectedType === 'RENTAL_INCOME' && autoReceiptCreation && selectedTenant.length > 0) &&
                <Group>
                    <SwitchContainer
                    switchText={<span>Vorfristige Mietzahlungen berücksichtigen.</span>}
                    checked={isEarlyPaymentEnabled}
                    onChange={()=> this.setState({isEarlyPaymentEnabled: !isEarlyPaymentEnabled})}
                    disabled={!autoReceiptCreation}
                    textClassName={'transaction-assigment-modal-early-payments-switch-label tooltips'}
                    />
                </Group>}
              </Stack>}
            </Stack>
            <Group position='apart'>
                <div>
                    <ColorButton
                    text={'Abbrechen'}
                    className='gray-btn'
                    onClick={()=> toggle()}
                    />
                </div>
                <div>
                    <ColorButton
                    text={'Speichern'}
                    className='purple-btn'
                    onClick={()=> this.checkForDeletion()}
                    disabled={!edit && selectedReceipts.length === 0 && selectedTenant.length === 0}
                    //disabled={selectedType === 'RENTAL_INCOME' ? selectedTenant === null : selectedType === 'CREDIT_DEBIT_BALANCE' && selectedReceipts.length === 0}
                    />
                </div>
            </Group>
        </Stack>
        {selectReceiptsForTransactionAssigmentModalOpen &&
        <SelectReceiptsForTransactionAssigmentModal
        open={selectReceiptsForTransactionAssigmentModalOpen}
        toggle={()=> this.setState({selectReceiptsForTransactionAssigmentModalOpen: false})}
        selectedReceiptsFromParent={selectedReceipts}
        selectedTransaction={selectedTransaction}
        saveSelectedReceipts={(selectedReceipts)=> this.setState({selectedReceipts, selectReceiptsForTransactionAssigmentModalOpen: false})}
        getReceipts={(newAddedReceipt)=> {
          console.log('toggleAddReceiptModal: ', newAddedReceipt);
          if(newAddedReceipt){
            this.setState({selectedReceipts: [...selectedReceipts, newAddedReceipt.id]}, ()=> this.getReceipts());
          }else{
            this.getReceipts();
          }
        }}
        />}

        {addReceiptOpen && (
        <ReceiptDetailModal
          history={this.props.history}
          record={null}
          receiptPage={ReceiptPage}
          parent={this}
          className="add-edit-receipt-modal"
          edit={false}
          open={addReceiptOpen}
          toggle={(newAddedReceipt)=> {
            if(newAddedReceipt){
              this.setState({addReceiptOpen: false, selectedReceipts: [...selectedReceipts, newAddedReceipt.id]}, ()=> this.getReceipts());
            }else{
              this.setState({addReceiptOpen: false});
            }
            
          }}
          isMobile={isMobile}
          transactionAssigment={selectedTransaction}
        />
        )}

          {showModal &&
          <ShowModal
            //onEditClick={this.handleEditClick}
            onExpansionClick={()=> this.setState({ImageOpen: true})}
            record={currentRecord}
            className="show-receipt-modal"
            open={showModal}
            history={this.props.history}
            transactionAssigment={true}
            // toggle={this.toggleShowModal}
            closeShow={()=> this.setState({showModal: false, currentRecord: null})}
          />}
          {ImageOpen &&
          <ImageModal
            img={currentRecord && currentRecord.file_name !== '' ? currentRecord.file_name : Receipt}
            className="expandImageModal"
            open={ImageOpen}
            toggle={()=> this.setState({ImageOpen: false})}
          />}
      </CustomModal>
    );
  }
}

const mapStateToProps = (state) => {
    return {
      propertySelected: state.generalReducer.property,
      rentalUnits: state.tenantDataReducer.rentalUnits,
      accountData: state.generalReducer.accountData
    };
  };
  
export default connect(mapStateToProps)(AssignTransactionModal);
