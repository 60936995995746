import React, { Component } from 'react';
import CustomModal from './index';
import { ColorButton } from '../../../../index';
import { Row, Col, Form } from 'react-bootstrap';
import { AddUnit, EditUnit } from '../../../../../redux/action/Tenant/TenantActions';
import { connect } from 'react-redux';
import Autoload from '../../../../Autoload';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
const { emptyFieldValidation, checkProperty, invalidSize, invalidAlphaNumeric } = Autoload.MainHelper;

class RentalUnitModal extends Component {
  initialState = {
    name: {
      value: '',
      err: false,
      msg: '',
    },
    size: {
      value: '',
      err: false,
      msg: '',
    },
    rentalUnitNamePlaceholder: 'z.B. Erdgeschoss links'
  };

  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  componentDidMount() {
    if (this.props.edit) {
      const { record } = this.props;
      this.setState({
        name: { ...this.state.name, value: checkProperty(record, 'name'), err: false, msg: '' },
        size: { ...this.state.size, value: record.size ? `${checkProperty(record, 'size').toString().replaceAll('.',',')} m²` : null, err: false, msg: '' },
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps && this.props.edit) {
      const { record } = this.props;
      // console.log('recordddd', record)
      this.setState({
        name: { ...this.state.name, value: checkProperty(record, 'name'), err: false, msg: '' },
        size: { ...this.state.size, value: record.size ? `${checkProperty(record, 'size').toString().replaceAll('.',',')} m²` : null, err: false, msg: '' },
      });
    }
  }

  resetForm = () => {
    this.setState({ ...this.initialState });
  };

  onInputChange = (field, value) => {
    this.setState({ [field]: { ...this.state[field], value: value.replaceAll(' m²', '') } });
  };

  validateData = (data, field, fieldName, fieldType) => {
    let invalidErr, emptyErr;
    emptyErr = emptyFieldValidation(data.value.trim());
    switch (fieldType) {
      case 'name':
        invalidErr = invalidAlphaNumeric(data.value.trim());
        break;
      case 'decimal':
        invalidErr = invalidSize(data.value.replaceAll(' m²', ''));
        break;
      default:
        break;
    }
    if (emptyErr) {
      this.setState({ [field]: { ...this.state[field], err: true, msg: `${lang.general.text_enter} ${fieldName}` } });
      return true;
    } else if (invalidErr) {
      this.setState({ [field]: { ...this.state[field], err: true, msg: `${lang.general.text_invalid} ${fieldName}` } });
      return true;
    } else {
      this.setState({ [field]: { ...this.state[field], err: false, msg: '' } });
      return false;
    }
  };

  handleSubmit = (e) => {
    // ;
    const { name, size } = this.state;
    e.preventDefault();
    let nameErr = this.validateData(name, 'name', 'rental unit name');

    // if(!nameErr){
    //     if(name.value.trim() == ""){
    //         nameErr = "Enter rental unit name";
    //     }

    // }
    let sizeErr = this.validateData(size, 'size', 'unit size', 'decimal');
    if (nameErr || sizeErr) {
      console.log('invalid data');
    } else {
      if (this.props.edit) {
        this.editRentalUnitApi();
      } else {
        this.addRentalUnitApi();
      }
      // this.resetForm();
      // this.props.toggle();
    }
  };

  //api call function
  addRentalUnitApi = () => {
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'block';
    let btnelement = document.getElementById('rental-unit-modal-footer');
    let propertyid = this.props.propertySelected && this.props.propertySelected.id;
    const { name, size } = this.state;
    let space = size.value.replace('.','')
    space = space.replace(',','.');
    var data = JSON.stringify({
      rentalUnitId: null,
      propertyId: propertyid,
      name: name.value,
      space: space.replaceAll(' m²', ''),
    });
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'RentalUnits',
      'POST',
      data,
      btnelement.children[1],
      false,
      true,
      false,
      this,
      'handleAddUnit',
      { cancelbtn: btnelement.children[0] },
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );

    // this.handleAddUnit()
  };
  handleAddUnit = (response) => {
    if (response.status === false && response.message !== 'Not Found') {
      // Autoload.Redirection.redirection_error();
      Autoload.DataHandlerGeneral.GeneralErrorMessage(
        true,
        lang.newKeys.text_error_not_found
        // + response.message
      );
    }
    // const {name, size}=this.state
    // let unit={
    //     name: name.value,
    //     size: size.value.replaceAll(' m²',''),
    //     tenantsData:[]
    // }
    // this.props.AddUnit(unit)
    window.Intercom("update", {
      api_base: "https://api-iam.intercom.io",
      app_id: "vvrs5bx3",
      'Rental Unit Created': true
    });
    window.Intercom('trackEvent', 'rental-unit-created');
    response.other.cancelbtn.click();
    this.props.updateStore();
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'none';
  };

  editRentalUnitApi = () => {
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'block';
    let btnelement = document.getElementById('rental-unit-modal-footer');
    let propertyid = this.props.propertySelected && this.props.propertySelected.id;
    const { name, size } = this.state;
    let space = size.value.replace('.','')
    space = space.replace(',','.');
    var data = JSON.stringify({
      rentalUnitId: checkProperty(this.props.record, 'id'),
      propertyId: propertyid,
      name: name.value,
      space: space.replaceAll(' m²', ''),
    });
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'RentalUnits/' + checkProperty(this.props.record, 'id'),
      'PUT',
      data,
      btnelement.children[1],
      false,
      true,
      false,
      this,
      'handleEditUnit',
      { cancelbtn: btnelement.children[0] },
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };
  handleEditUnit = (response) => {
    if (response.status === false && response.message !== 'Not Found') {
      // Autoload.Redirection.redirection_error();
      Autoload.DataHandlerGeneral.GeneralErrorMessage(
        true,
        lang.newKeys.text_error_not_found
        // + response.message
      );
    }

    // const {name, size}=this.state
    // let unit={
    //     id: checkProperty(this.props.record,'id'),
    //     name: name.value,
    //     size: size.value.replaceAll(' m²',''),
    // }
    // this.props.EditUnit(unit)

    response.other.cancelbtn.click();
    this.props.updateStore();
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'none';
  };

  removeSymbol = (field, value, symbol) => {
    //     console.log('focus',symbol)
    //     if (!isNaN(parseFloat(value.replaceAll(symbol,'')))) {
    //     console.log('focus', value, isNaN(parseInt(value)))

    // }
    //console.log('focus',value.replaceAll(symbol, ''), value )
    this.setState({ [field]: { ...this.state[field], value: value.replaceAll(symbol, '') } });
  };

  addSymbol = (field, value, symbol) => {
    if (value.toString().includes(symbol)) {
      return;
    }
    if (!isNaN(parseFloat(value))) {
      this.setState({ [field]: { ...this.state[field], value: `${value}${symbol}` } });
    } else {
      this.setState({ [field]: { ...this.state[field], value: value.replaceAll(symbol, '') } });
    }
  };

  handleEnterKeyPress = (e) => {
    if (e.charCode == 13) {
      e.preventDefault();
      this.handleSubmit(e);
    }
  };

  render() {
    const { className, open, toggle, edit } = this.props;
    const { name, size, rentalUnitNamePlaceholder } = this.state;
    return (
      <CustomModal
        heading={edit ? lang.tenant.text_edit_rental_unit : lang.tenant.text_add_rental_unit}
        headingClassName={edit ? 'tenant-management-edit-rental-unit' : 'tenant-management-add-rental-unit'}
        open={open}
        toggle={() => {
          this.resetForm();
          toggle();
        }}
        className={className}
        style={{height: 'auto'}}
      >
        <div>
          <Form onSubmit={this.handleSubmit} noValidate>
            {/* <Form onSubmit={(e) => e.preventDefault()} noValidate> */}
            <div className="modal-inner-body rental-unit-modal">
              <Row>
                <Col lg={6}>
                  <Form.Group>
                    <Form.Label> {'Bezeichnung'} </Form.Label>
                    <Form.Control
                      id="rental-unit-name"
                      name="rental-unit-name"
                      isInvalid={name.err}
                      type="text"
                      placeholder={rentalUnitNamePlaceholder}
                      onFocus={()=> this.setState({rentalUnitNamePlaceholder: ''})}
                      onBlur={()=> this.setState({rentalUnitNamePlaceholder: 'z.B. Erdgeschoss links'})}
                      value={name.value}
                      onChange={(e) => this.onInputChange('name', e.target.value)}
                      onKeyPress={this.handleEnterKeyPress}
                    />
                    <Form.Control.Feedback className="err" type="invalid">
                      {lang.newKeys.text_error_msg_tenants_rent}
                      {/* {name.msg}{' '} */}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group>
                    <Form.Label> {lang.tenant.text_size} </Form.Label>
                    <Form.Control
                      id="rental-unit-size"
                      name="rental-unit-size"
                      isInvalid={size.err}
                      type="text"
                      placeholder="m²"
                      value={size.value}
                      onChange={(e) => this.onInputChange('size', e.target.value)}
                      onFocus={(e) => this.removeSymbol('size', e.target.value, ' m²')}
                      onBlur={(e) => this.addSymbol('size', e.target.value, ' m²')}
                      onKeyPress={this.handleEnterKeyPress}
                    />
                    <Form.Control.Feedback className="err" type="invalid">
                      {lang.newKeys.text_error_msg_tenants_rent}
                      {/* {size.msg}{' '} */}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
            </div>
            <div className="center-spacing rental-unit-modal-footer" id="rental-unit-modal-footer" style={{position:'relative' }}>
              <ColorButton
                onClick={(e) => {
                  e.preventDefault();
                  this.resetForm();
                  toggle();
                }}
                className="gray-btn"
                text={lang.general.text_cancel}
              />
              <ColorButton
                buttonConfig={{ type: 'submit' }}
                className="purple-btn"
                text={edit ? lang.tenant.text_save_rental_unit : 'Hinzufügen'}
              />
            </div>
          </Form>
        </div>
      </CustomModal>
    );
  }
}
// RentalUnitModal.defaultProps = {
//     toggle: () => { },
//     open: true,
//     record:{}

// }

const mapStateToProps = (state) => {
  return {
    rentalUnits: state.tenantDataReducer.rentalUnits,
    propertySelected: state.generalReducer.property,
  };
};
const actions = {
  AddUnit,
  EditUnit,
};

export default connect(mapStateToProps, actions)(RentalUnitModal);
