import * as Actiontypes from '../../types/meter';


export const deleteMeterReadings=(rowId)=>{
  return{
      type:Actiontypes.DELETE_METER_READING,
      payload:rowId
  }
}
export const getMeterReadings=()=>{
    return{
        type:Actiontypes.GET_METER_READING,
    
        
    }
  }

export const getMasterData=(data)=>{
    return{
        type:Actiontypes.GET_MASTER_DATA,
        payload:data
    }
}  

export const deleteMasterData=(id)=>{
    return{
        type: Actiontypes.DELETE_MASTER_DATA,
        payload:id
    }
}

export const editMasterData=(data)=>{
    console.log("action",data)
    return{
        type: Actiontypes.EDIT_MASTER_DATA,
        payload:data
    }
}

export const getAnnualReadings=()=>{
  return{
      type:Actiontypes.GET_ANNUAL_READINGS,
     
  }
}
export const getInterimReadings=(data)=>{
    return{
      type:Actiontypes.GET_INTERIM_READINGS,
      payload:data
    }

}
export const toEditMeterReading=(data)=>{
    return{
      type:Actiontypes.TO_EDIT_INTERIM_READING,
      payload:data
    }

}
export const dataFilteration=(state,data)=>{
    return{
        type:Actiontypes.DATA_FILTER,
        payload:{state:state,data:data}
    }
}
export const addMeterReading=(addData,billingId,unitId,tenantId)=>{
    console.log(billingId)
    return{
        type:Actiontypes.ADD_METER,
        payload:{addData,billingId,unitId,tenantId}
    }
}
export const addInterimReading=(editData,addData)=>{
    return{
       type:Actiontypes.ADD_INTERIM_READING,
       payload:{editData,addData}
    }
}
export const editInterimReading=(data)=>{
    return{
       type:Actiontypes.EDIT_INTERIM_READING,
       payload:data 
    }
}
export const addAnnualReading=(data,rentalId,editedData)=>{
     return{
         type:Actiontypes.ADD_ANNUAL_READINGS,
         payload:{addedData:data,rentalId,editedData}
     }
}