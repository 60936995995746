import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setTenantBills } from '../../../../redux/action/UtilityBilling/UtilityBillingActions';
import { formatNumber } from '../../../../helpers/stringsManipulations';
import { EmptyStateContainer } from '../../../../neoverv';
import Autoload from '../../../../neoverv/Autoload';
import tap from '../../../../assets/images/module/general/TapIconDark.svg';


var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];

export class SplitBaseConsumptionCosts extends Component {

  tableHeadCellStyling = {
    color: '#727293',
    padding: '0 9px 0 9px',
    fontWeight: '500',
    whiteSpace: 'nowrap',
    margin: '0px',
    fontSize: '14px',
    lineHeight: '20px',
    border: '1px solid #DADAE6',
    textTransform: 'capitalize',
    height: '48px',
  }
  tableBodyCellStyling = {
    color: '#0e0e1d',
    padding: '5px 9px 5px 9px',
    fontWeight: '400',
    whiteSpace: 'nowrap',
    fontSize: '14px',
    lineHeight: '20px',
    border: '1px solid #DADAE6',
    height: '48px',
    minWidth: '90px',
    // borderBottom: 'none',
    // borderRight: 'none'
  }

  state ={
    basicCostHeating: 0,
    consumptionCostHeating: 0,
    basicCostWarmWater: 0,
    consumptionCostWarmWater: 0,
    basicCostHeatingObject1: null,
    basicCostHeatingObject2: null,
    basicCostWarmWaterObject1: null,
    basicCostWarmWaterObject2: null
  }

  constructor(props){
    super(props);
    // if(props.messagePreview){
    //   this.tableBodyCellStyling.fontSize = '9px';
    //   this.tableBodyCellStyling.height = '38px';
    //   this.tableHeadCellStyling.fontSize = '9px';
    //   this.tableHeadCellStyling.height = '38px';
    // } 
  }

  componentDidMount(){
    if(this.props.messagePreview){
      this.tableBodyCellStyling.fontSize = '7pt';
      this.tableBodyCellStyling.height = '0px'
      this.tableBodyCellStyling.padding = '1mm'
      this.tableBodyCellStyling.lineHeight = '11pt'

      this.tableHeadCellStyling.fontSize = '7pt';
      this.tableHeadCellStyling.height = '0px'
      this.tableHeadCellStyling.padding = '1mm'
      this.tableHeadCellStyling.lineHeight = '11pt'
    }

    const {selectedTenantData} = this.props;
    console.log('selectedTenantData?.tenantRows: ', selectedTenantData?.tenantRows);
    const basicCostHeatingObject1 = selectedTenantData && selectedTenantData?.tenantRows && selectedTenantData?.tenantRows.find((tenRow)=> tenRow.costType === 'Heizung (Grundkosten)');
    const basicCostHeatingObject2 = selectedTenantData && selectedTenantData?.tenantRows && selectedTenantData?.tenantRows.find((tenRow)=> tenRow.costType === 'Heizung (Verbrauch)');
    const basicCostWarmWaterObject1 = selectedTenantData && selectedTenantData?.tenantRows && selectedTenantData?.tenantRows.find((tenRow)=> tenRow.costType === 'Warmwasser (Grundkosten)');
    const basicCostWarmWaterObject2 = selectedTenantData && selectedTenantData?.tenantRows && selectedTenantData?.tenantRows.find((tenRow)=> tenRow.costType === 'Warmwasser (Verbrauch)');
    
    selectedTenantData && selectedTenantData?.tenantRows &&
    this.setState({
      basicCostHeating: (basicCostHeatingObject1 && basicCostHeatingObject2) && Math.round((basicCostHeatingObject1.totalCost / (basicCostHeatingObject1.totalCost + basicCostHeatingObject2.totalCost)) * 100),
      consumptionCostHeating: (basicCostHeatingObject1 && basicCostHeatingObject2) && Math.round((basicCostHeatingObject2.totalCost / (basicCostHeatingObject1.totalCost + basicCostHeatingObject2.totalCost)) * 100),
      basicCostWarmWater: (basicCostWarmWaterObject1 && basicCostWarmWaterObject2) && Math.round((basicCostWarmWaterObject1.totalCost / (basicCostWarmWaterObject1.totalCost + basicCostWarmWaterObject2.totalCost)) * 100),
      consumptionCostWarmWater: (basicCostWarmWaterObject1 && basicCostWarmWaterObject2) && Math.round((basicCostWarmWaterObject2.totalCost / (basicCostWarmWaterObject1.totalCost + basicCostWarmWaterObject2.totalCost)) * 100),
      basicCostHeatingObject1,
      basicCostHeatingObject2,
      basicCostWarmWaterObject1,
      basicCostWarmWaterObject2
    })
  }

  componentDidUpdate(prevProps){
    const {selectedTenantData} = this.props;
    const basicCostHeatingObject1 = selectedTenantData && selectedTenantData?.tenantRows && selectedTenantData?.tenantRows.find((tenRow)=> tenRow.costType === 'Heizung (Grundkosten)');
    const basicCostHeatingObject2 = selectedTenantData && selectedTenantData?.tenantRows && selectedTenantData?.tenantRows.find((tenRow)=> tenRow.costType === 'Heizung (Verbrauch)');
    const basicCostWarmWaterObject1 = selectedTenantData && selectedTenantData?.tenantRows && selectedTenantData?.tenantRows.find((tenRow)=> tenRow.costType === 'Warmwasser (Grundkosten)');
    const basicCostWarmWaterObject2 = selectedTenantData && selectedTenantData?.tenantRows && selectedTenantData?.tenantRows.find((tenRow)=> tenRow.costType === 'Warmwasser (Verbrauch)');

    if(prevProps.selectedTenantData !== selectedTenantData){
      console.log('componentDidUpdate:');
      selectedTenantData && selectedTenantData?.tenantRows &&
      this.setState({
        basicCostHeating: (basicCostHeatingObject1 && basicCostHeatingObject2) && Math.round((basicCostHeatingObject1.totalCost / (basicCostHeatingObject1.totalCost + basicCostHeatingObject2.totalCost)) * 100),
        consumptionCostHeating: (basicCostHeatingObject1 && basicCostHeatingObject2) && Math.round((basicCostHeatingObject2.totalCost / (basicCostHeatingObject1.totalCost + basicCostHeatingObject2.totalCost)) * 100),
        basicCostWarmWater: (basicCostWarmWaterObject1 && basicCostWarmWaterObject2) && Math.round((basicCostWarmWaterObject1.totalCost / (basicCostWarmWaterObject1.totalCost + basicCostWarmWaterObject2.totalCost)) * 100),
        consumptionCostWarmWater: (basicCostWarmWaterObject1 && basicCostWarmWaterObject2) && Math.round((basicCostWarmWaterObject2.totalCost / (basicCostWarmWaterObject1.totalCost + basicCostWarmWaterObject2.totalCost)) * 100),
        basicCostHeatingObject1,
        basicCostHeatingObject2,
        basicCostWarmWaterObject1,
        basicCostWarmWaterObject2
      })
    }
  }

  getTableLabels = () => {
    return (
      <TableRow>
        <TableCell style={{...this.tableHeadCellStyling}}>{lang.utility.text_cost_type}</TableCell>
        <TableCell style={{...this.tableHeadCellStyling, ...{textAlign: 'right'}}}>{lang.utility.text_total_cost}</TableCell>
        <TableCell style={{...this.tableHeadCellStyling, ...{textAlign: 'right'}}}>{'Anteil'}</TableCell>
      </TableRow>
    );
  };
  calculateTotal=()=>{
    let total=0;
    this.props.heatingCosts.map((row)=> {
        total = total + row.heatingCost;
    })
    console.log('calculateTotal: ', total);
    return formatNumber(total, true);;
  }
    render(){
      const {selectedTenantData, isTablet, messagePreview} = this.props;
      const {basicCostHeating, basicCostWarmWater, consumptionCostHeating, consumptionCostWarmWater, basicCostHeatingObject1, basicCostHeatingObject2, basicCostWarmWaterObject1, basicCostWarmWaterObject2} = this.state;
      console.log('basicCostHeatingObject1: ', basicCostHeatingObject2);

        return (
        <TableContainer component={Paper} style={{background: 'transparent', width: messagePreview ? '60%' : !isTablet && '50%'}}>
        <Table style={{borderCollapse: 'collapse'}}>
          <TableHead style={{border: '1px solid #DADAE6', borderBottom: 'none'}}>
            {this.getTableLabels()}
          </TableHead>
          <TableBody style={{background: 'white', border: '1px solid #DADAE6'}}>
            {selectedTenantData && selectedTenantData?.tenantRows && selectedTenantData?.tenantRows.length > 0 ?
            <>
            {basicCostWarmWaterObject1 && basicCostWarmWaterObject2 &&
            <>
            <TableRow>
                <TableCell style={{...this.tableBodyCellStyling, ...{fontWeight: 'bold'}}}>
                    {'Kosten für Warmwassererhitzung'}
                </TableCell>
                <TableCell style={{...this.tableBodyCellStyling, ...{fontWeight: 'bold', textAlign: 'right'}}}>
                    {formatNumber(basicCostWarmWaterObject1.totalCost + basicCostWarmWaterObject2.totalCost, true)}
                </TableCell>
                <TableCell style={{...this.tableBodyCellStyling, ...{textAlign: 'right', fontWeight: 'bold', borderRight: '1px solid #DADAE6'}}}>
                  {basicCostWarmWaterObject1.totalCost + basicCostWarmWaterObject2.totalCost > 0 ? '100%' : '0%'}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={this.tableBodyCellStyling}>
                    {'Davon Grundkosten'}
                </TableCell>
                <TableCell style={{...this.tableBodyCellStyling, ...{textAlign: 'right'}}}>
                    {formatNumber(basicCostWarmWaterObject1.totalCost, true)}
                </TableCell>
                <TableCell style={{...this.tableBodyCellStyling, ...{textAlign: 'right', borderRight: '1px solid #DADAE6'}}}>
                    {`${basicCostWarmWater ? basicCostWarmWater : 0}%`}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={this.tableBodyCellStyling}>
                    {'Davon Verbrauchskosten'}
                </TableCell>
                <TableCell style={{...this.tableBodyCellStyling, ...{textAlign: 'right'}}}>
                    {formatNumber(basicCostWarmWaterObject2.totalCost, true)}
                </TableCell>
                <TableCell style={{...this.tableBodyCellStyling, ...{textAlign: 'right', borderRight: '1px solid #DADAE6'}}}>
                    {`${consumptionCostWarmWater ? consumptionCostWarmWater : 0}%`}
                </TableCell>
            </TableRow>
            </>}
            {basicCostHeatingObject1 && basicCostHeatingObject2 &&
            <>
            <TableRow>
                <TableCell style={{...this.tableBodyCellStyling, ...{fontWeight: 'bold'}}}>
                    {'Kosten für Heizung'}
                </TableCell>
                <TableCell style={{...this.tableBodyCellStyling, ...{fontWeight: 'bold', textAlign: 'right'}}}>
                    {formatNumber(basicCostHeatingObject1.totalCost + basicCostHeatingObject2.totalCost, true)}
                </TableCell>
                <TableCell style={{...this.tableBodyCellStyling, ...{textAlign: 'right', fontWeight: 'bold', borderRight: '1px solid #DADAE6'}}}>
                  {basicCostHeatingObject1.totalCost + basicCostHeatingObject2.totalCost > 0 ? '100%' : '0%'}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={this.tableBodyCellStyling}>
                    {'Davon Grundkosten'}
                </TableCell>
                <TableCell style={{...this.tableBodyCellStyling, ...{textAlign: 'right'}}}>
                    {formatNumber(basicCostHeatingObject1.totalCost, true)}
                </TableCell>
                <TableCell style={{...this.tableBodyCellStyling, ...{textAlign: 'right', borderRight: '1px solid #DADAE6'}}}>
                    {`${basicCostHeating ? basicCostHeating : 0}%`}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{...this.tableBodyCellStyling, ...{borderBottom: '1px solid #DADAE6'}}}>
                    {'Davon Verbrauchskosten'}
                </TableCell>
                <TableCell style={{...this.tableBodyCellStyling, ...{textAlign: 'right', borderBottom: '1px solid #DADAE6'}}}>
                    {formatNumber(basicCostHeatingObject2.totalCost, true)}
                </TableCell>
                <TableCell style={{...this.tableBodyCellStyling, ...{textAlign: 'right', borderRight: '1px solid #DADAE6', borderBottom: '1px solid #DADAE6'}}}>
                    {`${consumptionCostHeating ? consumptionCostHeating : 0}%`}
                </TableCell>
            </TableRow>
            </>}
            </>
            : (
              <TableRow>
                <TableCell colSpan={3}>
                  <EmptyStateContainer heading={lang.utility.text_no_tenant_bills} icon={tap} showBtn={false} />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>);
            
    }
}

const mapStateToProps = (state) => {
  return {
    allReducer: state,
    currentDataId: state.UtilityBillingReducer.currentBillingId,
    tenantBills: state.UtilityBillingReducer.utilityBillingData,
    utilityBillingData: state.UtilityBillingReducer.utilityBillingData,
    heatingFilter: state.UtilityBillingReducer.HeatingAllocationFilter,
  };
};

const mapDispatchToProps = {
  setTenantBills,
};

export default connect(mapStateToProps, mapDispatchToProps)(SplitBaseConsumptionCosts);

