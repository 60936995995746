import React, { Component } from 'react';
import { Navbar } from 'react-bootstrap';
import NavSpacer from './components/NavSpacer';
import NavDivider from './components/NavDivider';
import NavSingleItem from './components/NavSingleItem';
import NavDropdownItem from './components/NavDropdownItem';
import SidebarNav from './SidebarNav';
import { connect } from 'react-redux';
class MobileBottomNav extends Component {
  state = { sidebarOpen: false };
  // constructor(props) {
  //   super(props);
  // }
  render() {
    const { sidebarOpen } = this.state;
    const { selectedProperty, isTablet, isSmallerMobile } = this.props;
    console.log('selectedProperty: ', selectedProperty);
    const navbarItems = this.props.navTop;
    const navItems = (items) => {
        return items.map((item, index) => itemType(item, index));
    };    
    const itemType = (item, index) => {      
      if (item.divider) {
        return null;
      } else {
        return <NavSingleItem item={item} key={index} history={this.props.history} />;
      }
    };
    
    return (
      <ul id="main-menu">
        <Navbar >
          {navItems(navbarItems)}
          {navItems(this.props.navBottom)}
          {/* <Navbar.Collapse id="basic-navbar-nav">
            {navItems([this.props.navTop[1], ...this.props.navTop.slice(5)])}
            {navItems(this.props.navBottom)}
          </Navbar.Collapse> */}
        </Navbar>
      </ul>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    stateModal: state.generalReducer.stateModal,
    selectedProperty: state.generalReducer.property,
  };
};

export default connect(mapStateToProps)(MobileBottomNav);
