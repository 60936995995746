export const SET_COST_POSITIONS = 'SET_COST_POSITIONS';
export const DELETE_COST_POSITION = 'DELETE_COST_POSITION';
export const ADD_COST_POSITION = 'ADD_COST_POSITION';
export const SET_CURRENT_UB_PERIOD = 'SET_CURRENT_UB_PERIOD';
export const EDIT_COST_POSITION = 'EDIT_COST_POSITION';
export const UPDATE_COMMENTS = 'UPDATE_COMMENTS';
export const SAVE_ZUWEISUNG_DATA = 'SAVE_ZUWEISUNG_DATA';
export const SET_UTILITY_DATA = "SET_UTILITY_DATA";
export const SET_TENANT_BILLS = "SET_TENANT_BILLS";
export const GET_UTILITY_DATA = 'GET_UTILITY_DATA';
export const SET_HEATING_ALLOCATION = 'SET_HEATING_ALLOCATION';