import React, { Component } from 'react';
import Autoload from '../../../../neoverv/Autoload';
import { connect } from 'react-redux';
import { hideNotification, showNotification } from '@mantine/notifications';
import RightArrowIcon from '../../../../assets/images/module/general/RightArrowIcon.svg';
import GrayTick from '../../../../assets/images/module/general/GrayTick.svg';
import SelectArrowDown from '../../../../assets/images/module/general/SelectArrowDown.svg';
import UtilityBillingAssignedIcon from '../../../../neoverv/components/SVGs/utilityBillingAssigned';
import SelectArrowUp from '../../../../assets/images/module/general/SelectArrowUp.svg';
import GreenTick from '../../../../assets/images/module/general/GreenTick.svg';
import MinimizeIcon from '@material-ui/icons/Minimize';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
//import Papa from 'papaparse';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { Stack, Group, Select, Accordion } from '@mantine/core';
import { Col, Form, Row } from 'react-bootstrap';
import { ColorButton, SwitchContainer } from '../../../../neoverv';
import TemperatureNotificationModal from '../../../../neoverv/components/Module/General/CustomModal/TemperatureNotificationModal';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
const pStyle= {
    fontFamily: 'Inter, sans-serif',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '24px',
    color: 'rgb(14, 14, 29)'
}
const tableStyle= {
    border: 'none',
    padding: '5px 0px',
    textAlign: 'center'
}
const tableHeaderStyle= {
    // fontWeight: '500', 
    // lineHeight: '16px',
    // fontFamily: 'Inter, sans-serif',
    // whiteSpace: 'nowrap',
    // width: '200px',
    // maxWidth: '200px',
    // textAlign: 'left'
    whiteSpace: 'nowrap',
    border: 'none',
    paddingBottom: '0px',
    fontSize: '12px',
    fontWeight: '400',
    color:'#727293',
    width: '80px',
    width: '200px',
    maxWidth: '200px',
    textAlign: 'left'
}
export class TenantAdministrateMessages extends Component {
  constructor(props) {
    super(props);
    this.state = {
        parsedData: [],
        tableColumnNames: [],
        tableRowValues: [
            {
                name: 'Heizung',
                checked: false,
            },
            {
                name: 'Warmwasser',
                checked: false,
            }
        ],
        selectedColumns: [],
        expandModal: false,
        hover:false,
        data: [],
        propertyData: null,
        allocationKeyData: [],
        oldSelectedHeatingConsumptionAllocationKey: null,
        oldSelectedWarmWaterConsumptionAllocationKey: null,
        selectedHeatingConsumptionAllocationKey: null,
        selectedWarmWaterConsumptionAllocationKey: null,
        openTemperatureEmptyNotificationModal: false,
        minimize: true
    };
  }

//   componentDidMount = () => {
//     this.getPropertyData();
//   };

componentDidUpdate(prevProps){
    if(prevProps.propertyData !== this.props.propertyData){
        this.setState({propertyData: this.props.propertyData});
    }
}

  getPropertyData =()=>{
    Autoload.HttpRequestAxios.HttpRequest(
        Autoload.Setting.property_api_base_url + 'properties/' + this.props.propertySelected.id,
        'GET',
        null,
        null,
        false,
        true,
        false,
        this,
        'handle_get_property_data',
        null,
        false,
        '',
        {
          'Content-Type': 'application/json',
          Authorization: Autoload.Utils.getAuthToken(),
        }
      );
  }

  handle_get_property_data(response){
    if(response.status){
        let tableRowValues = this.state.tableRowValues;
        tableRowValues.map((row)=> {
            if(row.name === 'Heizung'){
                if(response.result.heatingConsumptionAllocationKey && response.result.heatingConsumptionAllocationKey.length !== 0){
                    row.checked = true;
                }else{
                    this.getHeatingAllocationKeys(true);
                }
            }
            if(row.name === 'Warmwasser'){
                if(response.result.warmWaterConsumptionAllocationKey && response.result.warmWaterConsumptionAllocationKey.length !== 0){
                    row.checked = true;
                }else{
                    response.result.isWarmWaterConsumptionAllocationKeyRequired && this.getHeatingAllocationKeys(false, true);
                }
            }
        });

        this.setState({
            propertyData: response.result,
            selectedHeatingConsumptionAllocationKey: response.result.heatingConsumptionAllocationKey,
            oldSelectedHeatingConsumptionAllocationKey: response.result.heatingConsumptionAllocationKey,
            selectedWarmWaterConsumptionAllocationKey: response.result.warmWaterConsumptionAllocationKey,
            oldSelectedWarmWaterConsumptionAllocationKey: response.result.warmWaterConsumptionAllocationKey,
            tableRowValues
        });
    }
  }

  putPropertyData =(enableWarmwaterSwitch=null, isConsumptionSectionHidden=null, isDocumentSectionHidden=null, isMessageSectionHidden=null)=>{
    hideNotification('save_property_data');
    let payload = this.state.propertyData;
    payload.heatingConsumptionAllocationKey = this.state.selectedHeatingConsumptionAllocationKey;
    payload.warmWaterConsumptionAllocationKey =  enableWarmwaterSwitch !== null ? (enableWarmwaterSwitch ? this.state.selectedWarmWaterConsumptionAllocationKey : null) : (payload.isWarmWaterConsumptionAllocationKeyRequired ? this.state.selectedWarmWaterConsumptionAllocationKey : null);
    payload.isWarmWaterConsumptionAllocationKeyRequired = enableWarmwaterSwitch !== null ? enableWarmwaterSwitch : payload.isWarmWaterConsumptionAllocationKeyRequired;
    payload.isConsumptionSectionHidden = isConsumptionSectionHidden !== null ? isConsumptionSectionHidden : payload.isConsumptionSectionHidden;
    payload.isDocumentSectionHidden = isDocumentSectionHidden !== null ? isDocumentSectionHidden : payload.isDocumentSectionHidden;
    payload.isMessageSectionHidden = isMessageSectionHidden !== null ? isMessageSectionHidden : payload.isMessageSectionHidden;
    Autoload.HttpRequestAxios.HttpRequest(
        Autoload.Setting.property_api_base_url + 'properties/' + this.props.propertySelected.id,
        'PUT',
        payload,
        null,
        false,
        true,
        false,
        this,
        'handle_put_property_data',
        null,
        false,
        '',
        {
          'Content-Type': 'application/json',
          Authorization: Autoload.Utils.getAuthToken(),
        }
      );
  }

  handle_put_property_data(response){
    if(response.status){
        showNotification({
            id: 'save_property_data',
            message: 'Änderung übernommen',
            icon: <img src={GreenTick} alt='green-tick'/>,
        });
        this.setState({oldSelectedHeatingConsumptionAllocationKey: this.state.selectedHeatingConsumptionAllocationKey, oldSelectedWarmWaterConsumptionAllocationKey: this.state.selectedWarmWaterConsumptionAllocationKey}, ()=> this.props.getPropertyData());
    }
  }
  
  render() {
    const {isMobile, isTablet, propertySelected} = this.props;
    const email = 'receipt-1f298e4c-685f-4373-9359-37b70958c3cc@upload.hellohousing.de';
    const {tableColumnNames, tableRowValues, expandModal, data, propertyData, selectedHeatingConsumptionAllocationKey, selectedWarmWaterConsumptionAllocationKey, oldSelectedHeatingConsumptionAllocationKey, oldSelectedWarmWaterConsumptionAllocationKey, openTemperatureEmptyNotificationModal, minimize} = this.state;
    console.log('render: ', selectedWarmWaterConsumptionAllocationKey);
    console.log('propertyData: ', propertyData, propertySelected);
    console.log('data: ', data);
    return (
        <div 
        style={{
          width: '100%',
          background: 'white',
          boxShadow: '0 1px 3px rgba(0,0,0,.08)', //!propertyData?.isConsumptionSectionHidden ? 'none' : '0 1px 3px rgba(0,0,0,.08)', 
          borderRadius: '4px'
          }}>
            <Accordion
            classNames={{
                label: 'tenant-administration-accordion-label',
                contentInner: 'tenant-administration-accordion-inner-content',
                control: 'tenant-administration-accordion-control',
                icon: 'tenant-administration-accordion-icon',
            }}

            styles={{
                content: {
                    display: 'none',
                    boxShadow: 'none',
                }
            }}
            iconPosition='right'
            //icon={null}
            >
                <Accordion.Item 
                label={
                <Group
                position='apart'
                style={{
                    padding: '12.1px 24px',
                    //borderBottom: !propertyData?.isConsumptionSectionHidden && minimize === false ? '1px solid #dadae6' : 'none',
                    borderTopLeftRadius: '4px',
                    borderTopRightRadius: '4px',
                    borderBottomLeftRadius: '4px',
                    borderBottomRightRadius: '4px',
                    background: 'white',
                    cursor: 'pointer'
                }}
                onClick={()=> this.setState({minimize: !minimize})}>
                    <Group>
                        <h5 
                        style={{
                            fontWeight: '500',
                            fontSize: '16px',
                            lineHeight: '24px',
                            color: '#0e0e1d',
                            marginBottom: '0px'
                        }}
                        className={'tenant-administration-display-messages-heading tooltips'}>
                            Nachrichten anzeigen
                        </h5>
                        <SwitchContainer
                        checked={!propertyData?.isMessageSectionHidden}
                        switchText={'aktivieren'}
                        disabled={false}
                        onChange={()=> this.putPropertyData(null, null, null, !propertyData?.isMessageSectionHidden)}
                        />
                    </Group>
                </Group>}>
                </Accordion.Item>
            </Accordion>
            
            
        </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    propertySelected: state.generalReducer.property,
  };
};
export default connect(mapStateToProps)(TenantAdministrateMessages);
