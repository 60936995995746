import React, {Component} from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import calendar from '../../../../../assets/images/module/general/CalendarIcon.svg';

export default class CustomDateInput_v3 extends Component {
    state = {
      focus: false,
    }
    render() {
      const { onClick, value } = this.props
      // console.log(value)
      return (
        // <Form>
          <InputGroup className={this.state.focus && "input-group-focus"}>
            <InputGroup.Prepend>
              <InputGroup.Text>
                <img src={calendar} />
              </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control required type="text"
              
              className="date-input"
              value={(value==null || value=='')?'':value}
              placeholder="TT.MM.JJ"
              onFocus={e => this.setState({ focus: true })}
              onBlur={e => this.setState({ focus: false })}
              onClick={onClick}
              onChange={e => e.preventDefault()}
            />
          </InputGroup>
        // </Form>
      )
    }
  }
  CustomDateInput_v3.defaultProps={
      onClick:()=>{},
      value:''
  }