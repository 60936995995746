import React, { Component } from 'react';
import { ColorButton, AddCostTypeModal, ZuweisungModal, CustomModal, MultiselectDropdown } from '../../../../index';
import { Multiselect } from 'multiselect-react-dropdown';
import { connect } from 'react-redux';
import { Form, OverlayTrigger, Tooltip, Col } from 'react-bootstrap';
import {
  deleteCostPosition,
  editCostPosition,
  addCostPosition,
} from '../../../../../redux/action/UtilityBilling/UtilityBillingActions';
import TrashIcon from '../../../../../assets/images/module/general/TrashIcon.svg';
import RedTick from '../../../../../assets/images/module/general/RedTick.svg';
import YellowTick from '../../../../../assets/images/module/general/YellowTick.svg';
import GreenTick from '../../../../../assets/images/module/general/GreenTick.svg';
import GrayTick from '../../../../../assets/images/module/general/GrayTick.svg';
import Autoload from '../../../../Autoload';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];

const { checkProperty, emptyFieldValidation, invalidAmount2, redirection, invalidSize } = Autoload.MainHelper;

export class CostPositionModal extends Component {
  constructor(props) {
    super(props);
    this.multiselectRef = React.createRef();
    this.state = this.initialState;
  }
  initialState = {
    dataValues: {
      costType: [],
      amount: '',
      allocationKey: '',
      meterData: '',
      receipt: [],
      selectedReceipt: [],
      oldSelectedReceipt: [],
      receiptDeleted: [],
      receiptAdded: [],
    },
    amountTouch: false,
    amountErr: false,
    amountMsg: '',

    addCostTypeModal: false,
    AddZuweisungModal: false,
    costTypeOptions: [],
    modalData: [],
  };
  updateState = (data) => {
    // console.log('data to be set in updatestate', data);
    this.setState({
      dataValues: {
        ...this.state.dataValues,
        costTypeId: checkProperty(data, 'costTypeId'),
        costTypeName: checkProperty(data, 'costTypeName'),
        costType: this.getObject(checkProperty(data, 'costTypeId'), this.props.costTypesArr),
        amount: checkProperty(data, 'amount')
          ? Autoload.MainHelper.ConverterCurrencyDe(checkProperty(data, 'amount') + '')
          : '',
        allocationKeyId: checkProperty(data, 'allocationKeyId'),
        allocationKeyName: checkProperty(data, 'allocationKeyName'),
        allocationKey: this.getObject(checkProperty(data, 'allocationKeyId'), this.props.allocationsArr),
        meterData: checkProperty(data, 'meterData'),
        receipt: checkProperty(data, 'receipt', []),
        selectedReceipt: checkProperty(data, 'selectedReceipt', []),
        oldSelectedReceipt: checkProperty(data, 'selectedReceipt', []),
      },
    });
  };
  getObject = (id, arr) => {
    // console.log('complete arr', arr, 'filtered', arr.filter(ct=>ct.id==id))
    return arr.filter((ct) => ct.id == id);
  };
  componentDidMount() {
    if (this.props.edit) {
      const { data } = this.props;
      this.updateState(data);
    }
  }

  toggleAddCostTypeModal = () => {
    this.setState({ addCostTypeModal: !this.state.addCostTypeModal });
  };

  toggleZuweisungModal = () => {
    //call get data api
    Autoload.HttpRequestAxios.HttpRequest(
      // Autoload.Setting.swagger_mocked_api_baseUrl +
      Autoload.Setting.ub_api_base_url +
        'costAllocations/forCostPosition?utilityBillingId=' +
        checkProperty(this.props.data, 'utilityBillingId') +
        '&costPositionId=' +
        checkProperty(this.props.data, 'id'),
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      '_handleGetModalData',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };

  closeDataModal = () => {
    this.setState({ AddZuweisungModal: false });
  };

  _handleGetModalData = (response) => {
    // console.log('data modal api', response)
    if (response.status == false) {
      Autoload.Redirection.redirection_error();
    }
    if (response.result.length > 0) {
      let formatted = response.result.map((data) => ({
        id: checkProperty(data, 'allocationId'),
        tenant: checkProperty(data, 'tenantName'),
        rentalUnit: checkProperty(data, 'rentalUnitName'),
        amount: Autoload.MainHelper.ConverterCurrencyDe(checkProperty(data, 'tenantCostShare') + ''),
        //extra data in api
        utilityBillingId: checkProperty(data, 'utilityBillingId'),
        costPositionId: checkProperty(data, 'costPositionId'),
        allocationTypeName: checkProperty(data, 'allocationTypeName'),
        billingStatementId: checkProperty(data, 'billingStatementId'),
        costTypeName: checkProperty(data, 'costTypeName'),
        rentalUnitId: checkProperty(data, 'rentalUnitId'),
        tenantOccupation: checkProperty(data, 'tenantOccupation', {}),
        tenantConsumption: checkProperty(data, 'tenantConsumption'),
        tetotalConsumptionnant: checkProperty(data, 'totalConsumption'),
        consumptionUnits: checkProperty(data, 'consumptionUnits'),
        totalCostShare: checkProperty(data, 'totalCostShare'),
        // tenantCostShare: checkProperty(data, 'tenantCostShare'),
      }));
      this.setState({ modalData: formatted, AddZuweisungModal: true });
    } else {
      this.setState({ modalData: [], AddZuweisungModal: false });
    }
  };

  //update data to everytime redux changes
  componentDidUpdate(prevProps) {
    if (this.props.edit) {
      if (this.props.data != prevProps.data) {
        this.updateState(this.props.data);
      }
    }
  }

  updateStore = () => {
    //abc
    const {
      costType,
      costTypeId,
      costTypeName,
      amount,
      allocationKey,
      allocationKeyId,
      allocationKeyName,
      meterData,
      receipt,
      selectedReceipt,
    } = this.state.dataValues;
    // console.log('update store', this.state.dataValues)
    // let newAmount= amount? Autoload.MainHelper.ConverterLocaleNumber(amount, "de") : ''
    let data = {
      id: this.props.data.id,
      costType: costType,
      costTypeId: costTypeId,
      costTypeName: costTypeName,
      amount: Autoload.MainHelper.ConverterLocaleNumber(amount, 'de'),
      allocationKey: allocationKey,
      allocationKeyId: allocationKeyId,
      allocationKeyName: allocationKeyName,
      meterData,
      receipt,
      selectedReceipt,
    };
    //save data to redux
    // console.log('updateStore in redux', data)
    this.props.editCostPosition(data);
  };

  updateRow = () => {
    // console.log('run1')
    const { costTypeId, costTypeName, amount, allocationKeyId, allocationKeyName, meterData } = this.state.dataValues;
    if (this.props.data.id === 0) {
      //post request
      // console.log('call post api')
      let payload = {
        costPositionId: null,
        utilityBillingId: checkProperty(this.props.data, 'utilityBillingId'),
        amount: Autoload.MainHelper.ConverterLocaleNumber(amount, 'de')
          ? Autoload.MainHelper.ConverterLocaleNumber(amount, 'de')
          : 0,
        costTypeId: costTypeId,
        costTypeName: costTypeName,
        allocationKeyId: allocationKeyId,
        allocationKeyName: allocationKeyName,
        consumptionDataAvailable: meterData,
      };
      Autoload.HttpRequestAxios.HttpRequest(
        // Autoload.Setting.swagger_mocked_api_baseUrl + 'costPositions',
        Autoload.Setting.ub_api_base_url + 'costPositions',
        'POST',
        payload,
        null,
        false,
        true,
        false,
        this,
        '_handleSaving',
        null,
        false,
        '',
        {
          Authorization: Autoload.Utils.getAuthToken(),
        }
      );
    } else {
      //put request
      // console.log('call put api')
      let payload = {
        costPositionId: checkProperty(this.props.data, 'id'),
        utilityBillingId: checkProperty(this.props.data, 'utilityBillingId'),
        amount: Autoload.MainHelper.ConverterLocaleNumber(amount, 'de')
          ? Autoload.MainHelper.ConverterLocaleNumber(amount, 'de')
          : 0,
        costTypeId: costTypeId,
        costTypeName: costTypeName,
        allocationKeyId: allocationKeyId,
        allocationKeyName: allocationKeyName,
        consumptionDataAvailable: meterData,
      };
      Autoload.HttpRequestAxios.HttpRequest(
        // Autoload.Setting.swagger_mocked_api_baseUrl + 'costPositions/' + checkProperty(this.props.data, 'id'),
        Autoload.Setting.ub_api_base_url + 'costPositions/' + checkProperty(this.props.data, 'id'),
        'PUT',
        payload,
        null,
        false,
        true,
        false,
        this,
        '_handleSaving',
        null,
        false,
        '',
        {
          Authorization: Autoload.Utils.getAuthToken(),
        }
      );
    }
  };

  _handleSaving = (response) => {
    if (response.status == false) {
      Autoload.Redirection.redirection_error();
    }
    this.props.updateCp();
  };

  onReceiptSelect = (selectedList, selectedItem) => {
    let found = this.state.dataValues.oldSelectedReceipt.find((receipt) => receipt.id === selectedItem.id);
    let found2 = this.state.dataValues.receiptAdded.find((receipt) => receipt.id === selectedItem.id);
    if (
      (!found || (found && Object.keys(found).length === 0)) &&
      (!found2 || (found2 && Object.keys(found2).length === 0))
    ) {
      this.setState(
        {
          dataValues: {
            ...this.state.dataValues,
            selectedReceipt: selectedList,
            receiptAdded: [...this.state.dataValues.receiptAdded, selectedItem],
            receiptDeleted: this.state.dataValues.receiptDeleted.filter((rpt) => rpt.id !== selectedItem.id),
          },
        },
        () => {
          // console.log('receipt selected and new receipt added', this.state)
        }
      );
    } else {
      this.setState(
        {
          dataValues: {
            ...this.state.dataValues,
            selectedReceipt: selectedList,
            receiptDeleted: this.state.dataValues.receiptDeleted.filter((rpt) => rpt.id !== selectedItem.id),
          },
        },
        () => {
          // console.log('receipt selected and NO new receipt added', this.state)
        }
      );
    }
  };

  onReceiptRemove = (selectedList, removedItem) => {
    let found = this.state.dataValues.oldSelectedReceipt.find((receipt) => receipt.id === removedItem.id);
    let found2 = this.state.dataValues.receiptDeleted.find((receipt) => receipt.id === removedItem.id);
    if (found && Object.keys(found).length > 0 && (!found2 || (found2 && Object.keys(found2).length === 0))) {
      this.setState(
        {
          dataValues: {
            ...this.state.dataValues,
            selectedReceipt: selectedList,
            receiptDeleted: [...this.state.dataValues.receiptDeleted, found],
            receiptAdded: this.state.dataValues.receiptAdded.filter((rpt) => rpt.id !== removedItem.id),
          },
        },
        () => {
          // console.log('receipt removed and it was in original data', this.state)
        }
      );
    } else {
      this.setState(
        {
          dataValues: {
            ...this.state.dataValues,
            selectedReceipt: selectedList,
            receiptAdded: this.state.dataValues.receiptAdded.filter((rpt) => rpt.id !== removedItem.id),
          },
        },
        () => {
          // console.log('receipt selected and it was NOT in original data', this.state)
        }
      );
    }
  };

  renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      There is no meter data available. <br></br>
      <a href="/meter">Add meter data</a> to proceed.
    </Tooltip>
  );
  statusView = (meterData) => {
    if (meterData === 'zuweisung') {
      // console.log('button meter')
      return (
        <ColorButton
          text="Zuweisung"
          className="gray-btn mannual-btn"
          onClick={(e) => {
            e.preventDefault();
            this.toggleZuweisungModal();
          }}
        />
      );
    } else {
      // console.log('text meter')
      //   text_not_required:"nicht benötigt",
      // text_available:"verfügbar",
      // text_not_available:"nicht verfügbar"

      let icon;
      let status = meterData;
      switch (meterData) {
        case 'available':
          icon = GreenTick;
          status = 'verfügbar';
          break;
        case 'Not available':
          icon = RedTick;
          status = 'nicht verfügbar';
          break;
        case 'Some available':
          icon = YellowTick;
          status = 'teilweise verfügbar';
          break;
        case 'Not required':
          icon = GrayTick;
          status = 'nicht benötigt';
          break;
        default:
          status = '-';
          break;
      }
      if (status == 'Not available') {
        return (
          <OverlayTrigger
            trigger="click"
            placement="top"
            // delay={{ show: 450, hide:700}}
            //     overlay={<Popover  id="button-Popover ">
            //     Time frame which the bill covers.
            //   </Popover >}
            overlay={this.renderTooltip()}
          >
            <div style={{ cursor: 'pointer' }} className={status.replace(/\s/g, '')}>
              {icon && <img src={icon} alt="status" />}
              <span>{status}</span>
            </div>
          </OverlayTrigger>
        );
      } else {
        return (
          <div className={status.replace(/\s/g, '')}>
            {icon && <img src={icon} alt="status" />}
            <span>{status}</span>
          </div>
        );
      }
    }
  };
  handleAddNewCost = () => {};
  validateAmount = () => {
    // console.log('validateAmount',this.state.dataValues.amount)
    let invalidErr, emptyErr;
    emptyErr = emptyFieldValidation(this.state.dataValues.amount.replace('€', ''));
    invalidErr = invalidAmount2(this.state.dataValues.amount.replaceAll('€', '').trim());
    // console.log('validateAmount emptyErr invalidErr',emptyErr, invalidErr)

    if (emptyErr) {
      this.setState({ amountMsg: 'Betrag eingeben', amountErr: true });
      // console.log('dont save data')
    } else if (invalidErr) {
      this.setState({ amountMsg: lang.receipt.text_invalid_amount, amountErr: true });
      // console.log('dont save data')
    } else {
      this.setState({ amountMsg: '', amountErr: false });
      // console.log('save data')
      // this.updateStore()
    }
  };
  onAmountChange = (field, value) => {
    // console.log('onAmountChange',value)
    this.setState({ dataValues: { ...this.state.dataValues, [field]: value } }, () => this.validateAmount());
  };
  onAmountBlur = (field, value) => {
    // console.log('onAmountBlur',value)
    this.setState({ dataValues: { ...this.state.dataValues, [field]: value } }, () => this.validateAmount());
  };

  formattingEU = (nStr) => {
    nStr += '';
    let x = nStr.split(',');
    let x1 = x[0];
    let x2 = x.length > 1 ? ',' + x[1] : '';
    let rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, '$1' + '.' + '$2');
    }
    return x1 + x2;
  };
  onCostSelect = (selectedList, selectedItem) => {
    let allocation = this.props.allocationsArr.find((alloc) => alloc.id == selectedItem.allocationKey.id);

    this.setState(
      {
        dataValues: {
          ...this.state.dataValues,
          costTypeId: selectedItem.id,
          costTypeName: selectedItem.costTypeName,
          costType: selectedList,
          allocationKeyId: allocation.id,
          allocationKeyName: allocation.allocationKeyName,
          allocationKey: allocation ? [{ ...allocation }] : [],
        },
      },
      () => {
        let receipts = [];
        this.props.receiptsArr.forEach((i) => {
          if (i.selectedCostTypes.find((z) => z.id === selectedItem.id)) {
            let alreadySelected =
              Array.isArray(this.state.dataValues.selectedReceipt) &&
              this.state.dataValues.selectedReceipt.find((z) => z.id === i.id);
            if (!alreadySelected) receipts.push(i);
          }
        });

        receipts.forEach((i) => this.onReceiptSelect([], i));
      }
    );
  };
  onAllocationSelect = (selectedList, selectedItem) => {
    this.setState({
      dataValues: {
        ...this.state.dataValues,
        allocationKeyId: selectedItem.id,
        allocationKeyName: selectedItem.allocationKeyName,
        allocationKey: selectedList,
      },
    });
  };
  deleteCostPosition = (id) => {
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'block';
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.ub_api_base_url + 'costPositions/' + id,
      'DELETE',
      null,
      null,
      false,
      true,
      false,
      this,
      'handle_delete_cp',
      null,
      false,
      '',
      {}
    );
  };
  handle_delete_cp = (response) => {
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'none';
    if (response.status == false) {
      Autoload.Redirection.redirection_error();
    }
    //update data
    this.props.updateCp();
  };
  resetForm = () => {
    this.setState(this.initialState);
  };
  updateReceipts = () => {
    // console.log('new',this.state.dataValues.receiptAdded,'deleted',this.state.dataValues.receiptDeleted);
    this.state.dataValues.receiptAdded.forEach((receipt) => {
      let addedReceipt = {
        ...receipt,
        costPositionId: this.props.data.id,
        receiptAssignmentId: null,
      };
      // console.log('addedReceipt',addedReceipt)
      Autoload.HttpRequestAxios.HttpRequest(
        // Autoload.Setting.swagger_mocked_api_baseUrl + 'receiptAssignments',
        Autoload.Setting.ub_api_base_url + 'receiptAssignments',
        'POST',
        addedReceipt,
        null,
        false,
        true,
        false,
        this,
        '_handlePostReceipt',
        null,
        false,
        '',
        {
          Authorization: Autoload.Utils.getAuthToken(),
        }
      );
    });
    this.state.dataValues.receiptDeleted.forEach((receipt) => {
      Autoload.HttpRequestAxios.HttpRequest(
        // Autoload.Setting.swagger_mocked_api_baseUrl + 'receiptAssignment/' + receipt.id,
        Autoload.Setting.ub_api_base_url + 'receiptAssignment/' + receipt.id,
        'DELETE',
        null,
        null,
        false,
        true,
        false,
        this,
        '_handleDeleteReceipt',
        null,
        false,
        '',
        {
          Authorization: Autoload.Utils.getAuthToken(),
        }
      );
    });
  };
  _handleDeleteReceipt(response) {
    if (response.status == false) {
      Autoload.Redirection.redirection_error();
    }
    // console.log('response of delete receipt', response)
    this.props.updateCp();
  }
  _handlePostReceipt = (response) => {
    if (response.status == false) {
      Autoload.Redirection.redirection_error();
    }
    // console.log('response of post receipt', response)
    this.props.updateCp();
  };
  handleSubmit = (e) => {
    e.preventDefault();
    if (!this.state.amountErr) {
      this.updateStore();
      this.updateRow();
      this.updateReceipts();
      this.resetForm();
      this.props.closeShowModal();
      // console.log('save', this.state.dataValues)
    } else {
      // console.log('invalid data');
    }
  };
  render() {
    // const {id}=this.props.data
    // console.log('check modal', this.props.receiptsArr)
    const { id, isMobile, utilityBillingId } = this.props.data;
    const { addCostTypeModal } = this.state;
    const { costType, amount, allocationKey, receipt, selectedReceipt, zuweisung, meterData } = this.state.dataValues;
    const { open, closeShowModal, edit } = this.props;
    return (
      <CustomModal
        open={open}
        toggle={() => {
          this.resetForm();
          closeShowModal();
        }}
        heading={edit ? lang.utility.text_edit_cost_position : lang.utility.text_add_cost_position}
        className="mobile-utlity-cost-modal"
      >
        <Form noValidate>
          <Form.Row>
            <Col className="mobile-costType" xs={12}>
              {lang.utility.text_cost_type}
            </Col>
            <Col xs={12}>
              <Multiselect
                // className='ub_multiselect_id_utility'
                // ref={this.multiselectRef}
                options={this.props.costTypesArr} // Options to display in the
                displayValue="costTypeName"
                selectedValues={costType} // Preselected value to persist in dropdown
                placeholder="Wählen"
                closeIcon="cancel"
                avoidHighlightFirstOption={true}
                onSelect={this.onCostSelect} // Function will trigger on select event
                onRemove={this.onCostRemove} // Function will trigger on remove event
                singleSelect={true}
                disable={this.props.costTypesArr.length > 0 ? false : true}
                closeOnSelect={false}
                // hidePlaceholder={true}
                renderCustomButton={
                  <ColorButton
                    className="gray-btn utitlity-add-cost-button"
                    text="Add cost type"
                    onClick={(e) => {
                      e.preventDefault();
                      this.toggleAddCostTypeModal();
                    }}
                  />
                }
                style={{
                  chips: { background: '#F2F2FC', borderRadius: '4px', color: '#111147' },
                  multiselectContainer: { marginRight: '0px', width: '192px' },
                  inputField: { marginRight: '0px', width: '100%' },
                }}
              />
            </Col>
            <Col className="mobile-amount" xs={12}>
              {lang.utility.text_amount}
            </Col>
            <Col xs={12}>
              <Form.Control
                className="mobile-amount-placeholder"
                size="xs"
                type="text"
                isInvalid={this.state.amountErr}
                placeholder="0,00€"
                value={amount}
                onChange={(e) => this.onAmountChange('amount', e.target.value)}
                // onFocus={(e) => this.removeSymbol(e.target.value, '€')}
                // onBlur={(e) => this.addSymbol(e.target.value, '€')}
                onBlur={(e) =>
                  this.onAmountBlur('amount', Autoload.MainHelper.ConverterCurrencyDe(amount + '', true, 'de'))
                }
              />
              <Form.Control.Feedback className="modal-error-msg" type="invalid">
                {' '}
                {this.state.amountMsg}{' '}
              </Form.Control.Feedback>
            </Col>
            <Col xs={12} className="mobile-allocationKey">
              {lang.utility.text_allocation_key}
            </Col>
            <Col xs={12}>
              <Multiselect
                className="ub_multiselect_id"
                style={{
                  chips: { background: '#F2F2FC', borderRadius: '4px', color: '#111147' },
                  multiselectContainer: { marginRight: '0px', width: '192px' },
                  inputField: { marginRight: '0px', width: '100%' },
                }}
                // ref={this.multiselectRef}
                options={this.props.allocationsArr} // Options to display in the
                selectedValues={allocationKey} // Preselected value to persist in dropdown
                displayValue="allocationKeyName"
                singleSelect={true}
                onSelect={this.onAllocationSelect} // Function will trigger on select event
                placeholder="Wählen"
                closeIcon="cancel"
                avoidHighlightFirstOption={true}
                isMobileView={isMobile}
                disable={this.props.allocationsArr.length > 0 ? false : true}
                closeOnSelect={false}
              />
            </Col>
            <Col className="mobile-meterData" xs={12}>
              {lang.utility.text_meter_data}
            </Col>
            <Col xs={12}>{this.statusView(meterData)}</Col>
            <Col className="mobile-receipt" xs={12}>
              {lang.utility.text_Receipt}
            </Col>
            <Col xs={12}>
              {/* <Multiselect
                className="ub_multiselect_id"
                style={{
                  chips: { background: '#F2F2FC', borderRadius: '4px', color: '#111147' },
                  multiselectContainer: { marginRight: '0px', width: '296px' },
                  inputField: { marginRight: '0px', width: '83px', overflowX: 'ellipses' },
                }}
                tableView={true}
                secondColumnKey="date"
                tableHeaders={[{ heading: 'receipt' }, { heading: 'date' }]}
                // ref={this.multiselectRef}
                options={this.props.receiptsArr} // Options to display in the dropdown
                selectedValues={selectedReceipt} // Preselected value to persist in dropdown
                onSelect={this.onReceiptSelect} // Function will trigger on select event
                onRemove={this.onReceiptRemove} // Function will trigger on remove event
                displayValue="receiptName"
                placeholder="Wählen"
                closeIcon="cancel"
                avoidHighlightFirstOption={true}
                showCheckbox={true}
                // disable={this.state.multiselectEnable}
                closeOnSelect={false}
                hidePlaceholder={true}
                renderCustomButton={
                  <ColorButton
                    className="gray-btn utitlity-add-cost-button"
                    text="Add receipt"
                    onClick={(e) => {
                      e.preventDefault();
                      redirection('/receipt');
                    }}
                  />
                }
                isMobileView={isMobile}
              /> */}
              <MultiselectDropdown
                className="cost-allocation-receipts"
                tableView={true}
                displayValue2="date"
                options={this.props.receiptsArr}
                selectedValues={selectedReceipt} // Preselected value to persist in dropdown
                onSelect={this.onReceiptSelect} // Function will trigger on select event
                onRemove={this.onReceiptRemove} // Function will trigger on remove event
                displayValue="receiptName"
                placeholder="Wählen"
                multiSelect
                endListContent={
                  <ColorButton
                    className="gray-btn utitlity-add-cost-button"
                    text={lang.receipt.text_add_receipt}
                    // onClick={() => redirection('/receipt')}
                  />
                }
              />
            </Col>
          </Form.Row>
          <AddCostTypeModal
            record={null}
            className="add_cost_type_modal"
            edit={false}
            open={addCostTypeModal}
            showCostData={this.props.costTypesArr}
            toggle={this.toggleAddCostTypeModal}
            callBackFunction={this.props.costTypesBackFunction}
            stopPropation={true}
          />
          <ZuweisungModal
            costPositionId={id}
            billingId={utilityBillingId}
            record={this.state.modalData}
            text="Zuweisung"
            className="Zuweisung-modal"
            // edit={zuweisung.length > 0 ? true : false}
            open={this.state.AddZuweisungModal}
            toggle={this.closeDataModal}
          />
          <div className="center-spacing rental-unit-modal-footer add-cost-btns">
            <ColorButton
              onClick={(e) => {
                e.preventDefault();
                this.resetForm();
                closeShowModal();
              }}
              className="gray-btn"
              text={lang.general.text_cancel}
            />
            <ColorButton
              // buttonConfig={{ type: 'submit' }}
              onClick={(e) => this.handleSubmit(e)}
              className="purple-btn"
              text={edit ? lang.utility.text_edit_cost_position : lang.utility.text_add_cost_position}
            />
          </div>
        </Form>
      </CustomModal>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    propertySelected: state.generalReducer.property,
    costTypesArr: ownProps.costTypesArr ? ownProps.costTypesArr : [],
    allocationsArr: ownProps.allocationsArr ? ownProps.allocationsArr : [],
    receiptsArr: ownProps.receiptsArr ? ownProps.receiptsArr : [],
  };
};
const actions = {
  deleteCostPosition,
  editCostPosition,
  addCostPosition,
};
export default connect(mapStateToProps, actions)(CostPositionModal);
