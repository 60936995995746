import List from '../../../views/pages/Module/Payment/List';


const pageList = [
  {
    name: 'Payment List',
    path: '/payment/',
    component: List,
  }
];

export default pageList;
