import React, { useState, useRef } from 'react';
import { usePdf } from '@mikecousins/react-pdf';
import { Button } from 'reactstrap';
import { ChevronRight, ChevronLeft, ZoomIn, ZoomOut} from '@material-ui/icons';
import ExpandIcon from '../../../../../assets/images/module/general/ExpandIcon.svg';
import { Group, Stack } from '@mantine/core';
import { useEffect } from 'react';
import { formatNumber } from '../../../../../helpers/stringsManipulations';
import dayjs from 'dayjs';
import accounting from 'accounting';

const MOBILE_SIZE = 992;
const SMALLER_MOBILE_SIZE = 500;
const PreviewMessagePdf = ({messageData, selectedRecipient, senderAddress, billingPeriod, rentalUnits, propertySelected, selectedBillingStatement, accountData, rentalPayment, rents, profile, tenantRemainingRentObject, vatSwitch, isPreTaxInfoHidden, currentSubscription}) => {
    const [newMessageBody, setNewMessageBody] = useState();
    const [newMessageSubject, setNewMessageSubject] = useState();

    console.log('accountData: ', accountData);
    console.log('PreviewMessagePdf: selectedRecipient: ', selectedRecipient);

    const replaceAllPlaceHolders=(html)=>{
      let newHtml= html;
      let rentalUnit = rentalUnits.find((ru)=> ru.tenantsData.find((tenant)=> tenant.id === selectedRecipient?.tenantId) !== undefined);
      let tenant = rentalUnit?.tenantsData.find((tenant)=> tenant.id === selectedRecipient?.tenantId);
      let rent = null;
      if(messageData.templateType === 'RentalPayment'){
        let findRentalPaymentRent = rents.find((rt)=> (dayjs(rt.startDate).isSame(rentalPayment.rentalMonth) || dayjs(rt.startDate).isBefore(rentalPayment.rentalMonth)) && (rt.endDate ? (dayjs(rt.endDate).isSame(rentalPayment.rentalMonth) || dayjs(rt.endDate).isAfter(rentalPayment.rentalMonth)) : true));
        rent = findRentalPaymentRent !== undefined ? findRentalPaymentRent : null;
      }else{
        let findRent = rents.find((rt)=> (dayjs(rt.startDate).isSame(new Date()) || dayjs(rt.startDate).isBefore(new Date())) && (rt.endDate ? (dayjs(rt.endDate).isSame(new Date()) || dayjs(rt.endDate).isAfter(new Date())) : true));
        rent = findRent !== undefined ? findRent : null;
      }


      const commercialTenantWithVatSwitch = vatSwitch && currentSubscription.subscriptionKey.split('_')[0] !== 'STARTER' && (selectedBillingStatement?.tenantOccupation.contractType === 'Default' || selectedBillingStatement?.tenantOccupation.contractType === 'commercial' || selectedBillingStatement?.tenantOccupation.contractType === 'self');
      const sumOfVAT = selectedBillingStatement?.totalVATAmount ? selectedBillingStatement?.totalVATAmount : 0;
      const netUtilityBillingCost = (selectedBillingStatement ? selectedBillingStatement?.totalAllocatedUtilityCosts : 0) - sumOfVAT;
      const VAT = (isPreTaxInfoHidden ? selectedBillingStatement?.totalAllocatedUtilityCosts : netUtilityBillingCost) * 0.19;
      const grossUtilityBillingCost = !isPreTaxInfoHidden ? (netUtilityBillingCost + VAT) : ((selectedBillingStatement ? selectedBillingStatement?.totalAllocatedUtilityCosts : 0) + VAT);
      const creditAmount = selectedBillingStatement ? selectedBillingStatement?.totalAdvancePayments - grossUtilityBillingCost : 0;


      console.log('tenant: ', profile);
      newHtml= newHtml.replaceAll('<a href="#" target="_self">Abrechnungszeitraum</a>', billingPeriod);
      newHtml= newHtml.replaceAll('<a href="#" target="_self">Betriebskosten</a>', commercialTenantWithVatSwitch ? accounting.formatMoney(grossUtilityBillingCost, '€', 2, '.', ',', '%v %s') : selectedBillingStatement?.totalAllocatedUtilityCosts !== null && formatNumber(selectedBillingStatement?.totalAllocatedUtilityCosts, true));
      newHtml= newHtml.replaceAll('<a href="#" target="_self">Empfänger</a>', selectedRecipient?.name);
      newHtml= newHtml.replaceAll('<a href="#" target="_self">Guthaben / Nachzahlung</a>', commercialTenantWithVatSwitch ? accounting.formatMoney(creditAmount, '€', 2, '.', ',', '%v %s') : selectedBillingStatement?.totalAllocatedUtilityCosts !== null && selectedBillingStatement?.totalAdvancePayments !== null && `${formatNumber(selectedBillingStatement?.totalAdvancePayments - selectedBillingStatement?.totalAllocatedUtilityCosts, true)}`);
      newHtml= newHtml.replaceAll('<a href="#" target="_self">Mieteinheit</a>', rentalUnit !== undefined ? rentalUnit?.name : 'Mieteinheit');
      newHtml= newHtml.replaceAll('<a href="#" target="_self">Objektadresse</a>', (propertySelected.propertyAddress && propertySelected.propertyZipCode && propertySelected.propertyCity) ? `${propertySelected.propertyAddress}, ${propertySelected.propertyZipCode} ${propertySelected.propertyCity}` : 'Objektadresse');
      newHtml= newHtml.replaceAll('<a href="#" target="_self">Vorauszahlungen</a>', selectedBillingStatement?.totalAdvancePayments !== null && formatNumber(selectedBillingStatement?.totalAdvancePayments, true));
      //new
      newHtml= newHtml.replaceAll('<a href="#" target="_self">Absender</a>', `${profile?.firstName} ${profile?.lastName}`);
      newHtml= newHtml.replaceAll('<a href="#" target="_self">Mieter</a>', tenant?.name);
      newHtml= newHtml.replaceAll('<a href="#" target="_self">Auszugsdatum</a>', tenant?.out);
      newHtml= newHtml.replaceAll('<a href="#" target="_self">Einzugsdatum</a>', tenant?.in);
      newHtml= newHtml.replaceAll('<a href="#" target="_self">erhaltene Miete</a>', rentalPayment ? accounting.formatMoney((rentalPayment.receivedNetRent + rentalPayment.receivedAdvancePayment), '€', 2, '.', ',', '%v %s') : 'erhaltene Miete');
      newHtml= newHtml.replaceAll('<a href="#" target="_self">Mietmonat</a>', rentalPayment ? dayjs(rentalPayment.rentalMonth).format('MMMM YYYY') : 'Mietmonat');
      newHtml= newHtml.replaceAll('<a href="#" target="_self">gesamte ausstehende Miete</a>', tenantRemainingRentObject !== null ? accounting.formatMoney(tenantRemainingRentObject, '€', 2, '.', ',', '%v %s') : 'gesamte ausstehende Miete');
      newHtml= newHtml.replaceAll('<a href="#" target="_self">Vermieter</a>', accountData?.addressCompany);
      newHtml= newHtml.replaceAll('<a href="#" target="_self">Vorauszahlung</a>', rent ? accounting.formatMoney(rent.prePayments, '€', 2, '.', ',', '%v %s') : rents.length > 0 ? accounting.formatMoney(rents[0].prePayments, '€', 2, '.', ',', '%v %s') : null);
      newHtml= newHtml.replaceAll('<a href="#" target="_self">Warmmiete</a>', rent ? accounting.formatMoney(rent.prePayments + rent.netRent, '€', 2, '.', ',', '%v %s') : rents.length > 0 ? accounting.formatMoney(rents[0].prePayments + rents[0].netRent, '€', 2, '.', ',', '%v %s') : null);
      newHtml= newHtml.replaceAll('<a href="#" target="_self">Kaltmiete</a>', rent ? accounting.formatMoney(rent.netRent, '€', 2, '.', ',', '%v %s') : rents.length > 0 ? accounting.formatMoney(rents[0].netRent, '€', 2, '.', ',', '%v %s') : null);



      newHtml= newHtml.replaceAll('<a href="" target="_self">Abrechnungszeitraum</a>', billingPeriod);
      newHtml= newHtml.replaceAll('<a href="" target="_self">Betriebskosten</a>', commercialTenantWithVatSwitch ? accounting.formatMoney(grossUtilityBillingCost, '€', 2, '.', ',', '%v %s') : selectedBillingStatement?.totalAllocatedUtilityCosts !== null && formatNumber(selectedBillingStatement?.totalAllocatedUtilityCosts, true));
      newHtml= newHtml.replaceAll('<a href="" target="_self">Empfänger</a>', selectedRecipient?.name);
      newHtml= newHtml.replaceAll('<a href="" target="_self">Guthaben / Nachzahlung</a>', commercialTenantWithVatSwitch ? accounting.formatMoney(creditAmount, '€', 2, '.', ',', '%v %s') : selectedBillingStatement?.totalAllocatedUtilityCosts !== null && selectedBillingStatement?.totalAdvancePayments !== null && `${formatNumber(selectedBillingStatement?.totalAdvancePayments - selectedBillingStatement?.totalAllocatedUtilityCosts, true)}`);
      newHtml= newHtml.replaceAll('<a href="" target="_self">Mieteinheit</a>', rentalUnit !== undefined ? rentalUnit?.name : 'Mieteinheit');
      newHtml= newHtml.replaceAll('<a href="" target="_self">Objektadresse</a>', (propertySelected.propertyAddress && propertySelected.propertyZipCode && propertySelected.propertyCity) ? `${propertySelected.propertyAddress}, ${propertySelected.propertyZipCode} ${propertySelected.propertyCity}` : 'Objektadresse');
      newHtml= newHtml.replaceAll('<a href="" target="_self">Vorauszahlungen</a>', selectedBillingStatement?.totalAdvancePayments !== null && formatNumber(selectedBillingStatement?.totalAdvancePayments, true));
      //new
      newHtml= newHtml.replaceAll('<a href="" target="_self">Absender</a>', `${profile?.firstName} ${profile?.lastName}`);
      newHtml= newHtml.replaceAll('<a href="" target="_self">Mieter</a>', tenant?.name);
      newHtml= newHtml.replaceAll('<a href="" target="_self">Auszugsdatum</a>', tenant?.out);
      newHtml= newHtml.replaceAll('<a href="" target="_self">Einzugsdatum</a>', tenant?.in);
      newHtml= newHtml.replaceAll('<a href="" target="_self">erhaltene Miete</a>', rentalPayment ? accounting.formatMoney((rentalPayment.receivedNetRent + rentalPayment.receivedAdvancePayment), '€', 2, '.', ',', '%v %s') : 'erhaltene Miete');
      newHtml= newHtml.replaceAll('<a href="" target="_self">Mietmonat</a>', rentalPayment ? dayjs(rentalPayment.rentalMonth).format('MMMM YYYY') : 'Mietmonat');
      newHtml= newHtml.replaceAll('<a href="" target="_self">gesamte ausstehende Miete</a>', tenantRemainingRentObject !== null ? accounting.formatMoney(tenantRemainingRentObject, '€', 2, '.', ',', '%v %s') : 'gesamte ausstehende Miete');
      newHtml= newHtml.replaceAll('<a href="" target="_self">Vermieter</a>', accountData?.addressCompany);
      newHtml= newHtml.replaceAll('<a href="" target="_self">Vorauszahlung</a>', rent ? accounting.formatMoney(rent.prePayments, '€', 2, '.', ',', '%v %s') : rents.length > 0 ? accounting.formatMoney(rents[0].prePayments, '€', 2, '.', ',', '%v %s') : null);
      newHtml= newHtml.replaceAll('<a href="" target="_self">Warmmiete</a>', rent ? accounting.formatMoney(rent.prePayments + rent.netRent, '€', 2, '.', ',', '%v %s') : rents.length > 0 ? accounting.formatMoney(rents[0].prePayments + rents[0].netRent, '€', 2, '.', ',', '%v %s') : null);
      newHtml= newHtml.replaceAll('<a href="" target="_self">Kaltmiete</a>', rent ? accounting.formatMoney(rent.netRent, '€', 2, '.', ',', '%v %s') : rents.length > 0 ? accounting.formatMoney(rents[0].netRent, '€', 2, '.', ',', '%v %s') : null);
      console.log('newMessageData: ', newHtml);
      //setNewMessageBody(newHtml);
      return newHtml;
    }
    
    useEffect(() => {
      setNewMessageBody(replaceAllPlaceHolders(messageData.body));
      setNewMessageSubject(replaceAllPlaceHolders(messageData.subject));
    },[selectedRecipient, rents]);

    console.log('selectedBillingStatement_12: ', selectedBillingStatement);
    console.log('newMessageBody', newMessageBody);
    console.log('newMessageSubject', newMessageSubject);
  return (
    <div style={{margin: '12mm 11mm'}}
    // style={{width: '210mm', height: '297mm', background: 'white', //boxShadow: '0px 1px 2px #000', 
    // padding: '12mm 23mm 20mm'}}
    >
      <Group style={{width: '100%'}} position='right'>
        {/* <Stack style={{height: '33mm'}} spacing={10} justify={'flex-end'} >
          <Stack spacing={0}>
            <span style={{fontFamily: 'Arial', fontSize: '9pt'}}>{accountData.addressCompany}</span>
            <span style={{fontFamily: 'Arial', fontSize: '9pt'}}>{accountData.addressStreet}</span>
            <span style={{fontFamily: 'Arial', fontSize: '9pt'}}>{`${accountData.addressZipCode} ${accountData.addressCity}`}</span>
          </Stack>
          <Stack spacing={0}>
            {accountData.companyPhoneNumber && <span style={{fontFamily: 'Arial', fontSize: '8pt'}}>{`Tel: ${accountData.companyPhoneNumber}`}</span>}
            {accountData.companyEmail && <span style={{fontFamily: 'Arial', fontSize: '8pt'}}>{`E-Mail: ${accountData.companyEmail}`}</span>}
          </Stack>
        </Stack> */}
        <div>
          <div style={{height: '33mm', maxHeight: '33mm', display: 'flex', justifyContent: 'flex-end'}}>
            <div>
                <div><span style={{fontSize: '9pt', fontFamily: 'Arial', lineHeight: '11pt'}}>{accountData.addressCompany}</span></div>
                <div><span style={{fontSize: '9pt', fontFamily: 'Arial', lineHeight: '11pt'}}>{accountData.addressStreet}</span></div>
                <div><span style={{fontSize: '9pt', fontFamily: 'Arial', lineHeight: '11pt'}}>{`${accountData.addressZipCode} ${accountData.addressCity}`}</span></div>
                {accountData.companyPhoneNumber && <div style={{marginTop: '2mm'}}><span style={{fontSize: '8pt', fontFamily: 'Arial', lineHeight: '10pt'}}>{`Tel: ${accountData.companyPhoneNumber}`}</span></div>}
                {accountData.companyEmail && <div><span style={{fontSize: '8pt', fontFamily: 'Arial', lineHeight: '10pt'}}>{`E-Mail: ${accountData.companyEmail}`}</span></div>}
            </div>
          </div>
        </div>
      </Group>
      
      <div class="mantine-Stack-root hello-housing-1fod3h5" style={{width: '85mm', marginTop: '-12mm'}}>
          <div class="mantine-Group-root hello-housing-6y1794"><span style={{fontSize: '8pt', fontFamily: 'Arial', lineHeight: '5.5pt', whiteSpace: 'nowrap'}} class="hello-housing-gnzaph mantine-Group-child">{senderAddress}</span></div>
          <div class="mantine-Group-root hello-housing-6y1794" style={{marginTop: '1.5mm', height: '16mm'}}></div>
          {(!selectedRecipient?.name || !selectedRecipient?.street || !selectedRecipient?.zipCode || !selectedRecipient.city) ?
          <span style={{color: '#dc3545', fontWeight: '500', fontSize: '9pt', fontFamily: 'Arial', lineHeight: '11pt'}}>Empfängeradresse unvollständig</span>
          :
          <div class="mantine-Stack-root hello-housing-gvsdna" style={{marginTop: '1mm', height: '21mm'}}>
              <div><span style={{fontSize: '9pt', fontFamily: 'Arial', lineHeight: '11pt', whiteSpace: 'nowrap'}}>{selectedRecipient?.name}</span></div>
              <div><span style={{fontSize: '9pt', fontFamily: 'Arial', lineHeight: '11pt', whiteSpace: 'nowrap'}}>{selectedRecipient?.street}</span></div>
              <div><span style={{fontSize: '9pt', fontFamily: 'Arial', lineHeight: '11pt', whiteSpace: 'nowrap'}}> {`${selectedRecipient?.zipCode} ${selectedRecipient?.city}`}</span></div>
          </div>}
      </div>

      {/* <div style={{width: '85mm'}}>
        <div style={{height: '5.5mm', marginBottom: '1.5mm'}}>
          <span style={{fontFamily: 'Arial', fontSize: '8pt'}}>{senderAddress}</span>
        </div>
        <div style={{height: '16mm', marginBottom: '1mm'}}/>
        {(!selectedRecipient?.name || !selectedRecipient?.street || !selectedRecipient?.zipCode || !selectedRecipient.city) ?
        <span style={{color: '#dc3545', fontWeight: '500', fontSize: '9pt', fontFamily: 'Arial', lineHeight: '11pt'}}>Empfängeradresse unvollständig</span>
        :
        <Stack style={{height: '21mm'}} spacing={0}>
          <span style={{fontSize: '9pt', fontFamily: 'Arial', lineHeight: '11pt'}}>{selectedRecipient?.name}</span>
          <span style={{fontSize: '9pt', fontFamily: 'Arial', lineHeight: '11pt'}}> {selectedRecipient?.street}</span>
          <span style={{fontSize: '9pt', fontFamily: 'Arial', lineHeight: '11pt'}}>{`${selectedRecipient?.zipCode} ${selectedRecipient?.city}`}</span>
        </Stack>}
      </div> */}
      <div style={{marginTop: '20mm', width: '100%', display: 'flex', justifyContent: 'flex-end', fontSize: '9pt', fontFamily: 'Arial', lineHeight: '11pt'}}>
        {`${accountData.addressCity ? accountData.addressCity+', ' : ''}${dayjs().format('DD.MM.YYYY')}`}
      </div>
      {/* <Group style={{width: '100%', marginTop: '10mm'}} position='right'>
        <span style={{fontFamily: 'Arial', fontSize: '9pt'}}>{`${accountData.addressCity ? accountData.addressCity+', ' : ''}${dayjs().format('DD.MM.YYYY')}`}</span>
      </Group> */}
      {/* <div style={{fontSize: '9pt', fontFamily: 'Arial', fontWeight: 'bold', marginTop: '10mm', marginBottom: '10mm'}} className='preview-pdf-subject' dangerouslySetInnerHTML={{__html: newMessageSubject}}/> */}
      <div style={{marginTop: '10mm', width: '100%', display: 'flex', justifyContent: 'space-between', fontSize: '9pt', fontFamily: 'Arial', lineHeight: '11pt', fontWeight: 'bold'}} dangerouslySetInnerHTML={{__html: newMessageSubject}}/>
      {/* <div style={{fontSize: '9pt', fontFamily: 'Arial', textAlign: 'justify'}} dangerouslySetInnerHTML={{__html: newMessageBody}}/> */}
      <div class="mantine-Stack-root hello-housing-lfk3cq" style={{fontSize: '9pt', fontFamily: 'Arial', lineHeight: '13.5pt', marginTop: '10mm'}} dangerouslySetInnerHTML={{__html: newMessageBody}}/>
    </div>
  );
};

export default PreviewMessagePdf;