import React, { Component } from 'react';
import Autoload from '../../../../neoverv/Autoload';
import { Form,Row,Col } from 'react-bootstrap';
import { setAddEditFormData, setAddEditFormValidation } from '../../../../redux/action/Tenant/TenantActions';
import {connect} from 'react-redux';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
const { emptyFieldValidation, checkProperty } = Autoload.MainHelper

export class RentAddress extends Component {
    state={
        address:{
            value:'',
            err:false,
            msg:'',
            touched:false,
        },
        city:{
            value:'',
            err:false,
            msg:'',
            touched:false,
        },
        zip:{
            value:'',
            err:false,
            msg:'',
            touched:false,
        },
        area:{
            value:'',
            err:false,
            msg:'',
            touched:false,
        },
    }


    componentDidMount(){
        //get form data from redux and set state with that data
        const {formData}= this.props
        this.setState({
            address:{
                ...this.state.address, 
                value: checkProperty(formData,'address')
            },
            city:{
                ...this.state.city, 
                value: checkProperty(formData,'city')
            },
            zip:{
                ...this.state.zip, 
                value: checkProperty(formData,'zip')
            },
            area:{
                ...this.state.area, 
                value: checkProperty(formData,'area')
            },
            
        })
    }


    //save data to redux everytime state changes
    componentDidUpdate(prevProps, prevState) {
        if (this.state !== prevState) {
            const { address, city, zip, area}=this.state;
            let data={
                ...this.props.formData,
                address: address.value,
                city: city.value,
                zip: zip.value,
                area: area.value,
            };
            //save data to redux
            this.props.setAddEditFormData(data) 
            //save form state (valid or invalid) in redux
            this.props.setAddEditFormValidation(
                !address.err &&
                !city.err &&
                !zip.err &&
                !area.err 
            )
        }
      }

    onInputChange = (field, value) => {
        this.setState({ [field]: {...this.state[field], value:value} });
    };

    onTouch = (field) => {
        this.setState({ [field]: {...this.state[field], touched:true} });
    };

    //for dynamic validation
    onTextBlur=(data, field, fieldName)=>{
        if(data.touched){
            let emptyErr=emptyFieldValidation(data.value)
            if(emptyErr){
                this.setState({[field]:{...this.state[field], err: true, msg:`${lang.general.text_enter} ${fieldName}`}})
            }else{
                this.setState({[field]:{...this.state[field], err: false, msg:''}})
            }
        }else{
            return;
        }
    }

    render() {
        const { address, city, zip, area } = this.state;
        return (
            <div className='tenant-address-section'>
                 <Form noValidate>
                    <p className='section-heading' > {lang.tenant.text_rent_address} </p>
                    <Row>
                        <Col xl={4} lg={6} md={6} xs={12}>
                            <Form.Group>
                                <Form.Label  >  {lang.general.text_address} </Form.Label>
                                <Form.Control
                                    id='tenant-address'
                                    name='tenant-address'
                                    isInvalid={address.err}
                                    type="text"
                                    placeholder=""
                                    value={address.value}
                                    onFocus={()=>this.onTouch('address')}
                                    onBlur={()=>this.onTextBlur(address, 'address')}
                                    onChange={(e) => this.onInputChange('address', e.target.value)}
                                />
                                <Form.Control.Feedback className="err" type="invalid">
                                    {' '} {address.msg} {' '}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={3} lg={4} md={4} xs={9}>
                            <Form.Group>
                                <Form.Label> {lang.general.text_city} </Form.Label>
                                <Form.Control
                                    id="tenant-city"
                                    name="tenant-city"
                                    isInvalid={city.err}
                                    type="text"
                                    value={city.value}
                                    onFocus={()=>this.onTouch('city')}
                                    onBlur={()=>this.onTextBlur(city, 'city')}
                                    onChange={(e) =>this.onInputChange('city', e.target.value)}
                                />
                                <Form.Control.Feedback className="err" type="invalid">
                                    {' '}{city.msg}{' '}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col xl={1} lg={2} md={2} xs={3}>
                            <Form.Group>
                                <Form.Label> {lang.general.text_zip_code} </Form.Label>
                                <Form.Control
                                    id="tenant-zip"
                                    name="tenant-zip"
                                    isInvalid={zip.err}
                                    type="number"
                                    value={zip.value}
                                    onFocus={()=>this.onTouch('zip')}
                                    onBlur={()=>this.onTextBlur(zip, 'zip')}
                                    onChange={(e) => this.onInputChange('zip', e.target.value)}
                                />
                                <Form.Control.Feedback className="err" type="invalid">
                                    {' '}{zip.msg}{' '}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={2} lg={3} md={3} xs={6}>
                            <Form.Group>
                                <Form.Label  >  {lang.tenant.text_rented_area} </Form.Label>
                                <Form.Control
                                    id='tenant-area'
                                    name='tenant-area'
                                    isInvalid={area.err}
                                    type="text"
                                    placeholder=""
                                    value={area.value}
                                    onFocus={()=>this.onTouch('area')}
                                    onBlur={()=>this.onTextBlur(area, 'area')}
                                    onChange={(e) => this.onInputChange('area', e.target.value)}
                                />
                                <Form.Control.Feedback className="err" type="invalid">
                                    {' '} {area.msg} {' '}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                 </Form>
            </div>
        )
    }
}

const mapStateToProps=(state)=>{
    return{
      currentRecord : state.tenantDataReducer.currentTenant,
      formData : state.tenantDataReducer.addEditFormData,
    }
  }
  const actions={
    setAddEditFormData,
    setAddEditFormValidation
  }


export default connect(mapStateToProps,actions)(RentAddress)
