import { useEffect, useState } from "react";
import Autoload from "../../neoverv/Autoload";
import APIClient from "../../neoverv/config/APIClient";
import { config } from "../../neoverv/config/Setting";
import { useLocalStorage, useMediaQuery } from '@mantine/hooks';
import { months } from "../../neoverv/config/Setting";
import axios from "axios";
import { useHistory } from "react-router-dom";
import PlayIcon from '../../assets/images/module/general/PenIcon.svg';
import plan from '../../assets/images/module/general/plan.svg';
import skyscraper from '../../assets/images/module/general/skyscraper.svg';

// Components
import { Button, Card, Center, Group, Loader, SimpleGrid, Skeleton, Stack, Text } from "@mantine/core";
import LabeledData from "../../components/LabeledData";
import { formatNumber } from "../../helpers/stringsManipulations";

const DefaultPropertyImage = ({isMobile}) => <img alt="" style={{ width: isMobile ? 55 : 'auto', height: isMobile ? 55 : 'auto',}} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAG4AAABuCAYAAADGWyb7AAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAO2SURBVHja7Ns9aBtnHIDx/1kfsWXlXOhBS30mWxW4rc6Qq9whBKPRXXzQrbRaWxoZ0tWhW4pl6CxDx6LLolFoyBJVGhq6VGDRqdGVdjhDc8gi6OR7M8SmJighTlL7Tn2e9Q5xvD/uvfc+pCml5LihUBLKi4hox3BDpSQThpOsUiKnMCkmpVKapNPpsaZJKCJ5TSk1VEoy4/EkC1j8u3QpM9Y0CedERMLwCLSEFIaTrIjInIhIFEWMSEKKomcn2BxDkcyAA46AI+CAI+AIOOAIOAKOgAOOgCPggCPgCDgCDjgCjoADjoAj4Ag44Ag4mnW4IBh5/2e4dNwPsF5vN4NglO31Hn0YBKMFz/Pzg8FBVtdzH3S7d4GLY57n9yqVvdK0bbqeY6ok4Ag4Ag44Ao6AA26GmvUnK+kZBPNrtdYftVprVddzkW0X/tra2vjHNA0LuJhWq7XuV6uNG0EwMo4R51zXX3bd9rJtFw4dZ+3B5maxBFxMqtfbzWq1se55/o0X7dPp9Bc7nX5pZ6cROU7xV8cpzif5LNSUUsMnT8LFOB6c5/m969dvTx1c0zSi3d0v7m9v//RJr/co+zq/v7lZ/NNxivu2ffVmktDm5zOHiYV7m5mmEW1tbbRsu2Am4SwE7rl0PSeOU+xvb39WiDvcTN4O6HpOLGtlfNZXP0EwklqtVXDddpPFyTmDlcvrD8vl9Su6njNOFi6u+2Ct0+m/8qwyGPgGcOeQbRcOK5VPu7ZduCkiq6e3OU6x5DhF8Ty/t7PTeMd128s8ObngLGtl3Gze+dl1v108RnvZAsTa3f1yudu926tUNh4Cd4Ht7X31u2WtfHzGFaRVqWys2nbhELgLyDSN6E2W7rZ9dR84Ai4OBcEoD1wCe/wYOAKOZu4G/Pk6nf0fB4ODwdLSwuVS6aNvgEtA1Wrjh2q18fWp24a/79277ZmmcY2pMqbV6+3vTqOJiHie//6tW3tc4+Kc5/lL06fO/jXgCDgCDjgCjoAj4ICjiyzWj7xM07C63e97L9nltd+Al8vrVxxnbepv6/rCe8C9Md67/8kHsbqeM04+4WOqpHMr1p+gnzXP838ZDA5+m7bNtgufzwpa7P87QC+GY6rkGkfAEXDAEXAEHHAEHAFHwAFHwBFwwBFwBBwBBxwBR8ABR8ARcAQccAQcnQVO0xiIhMIhl5RSKe1fuEwmNcYu/mmaJul0aizy7P9xIiJDpVRmMjnKHh0pRiiGYJp2coJpoYjkT+BERIYMUSLKi4g8HQDQflcjAOF6KQAAAABJRU5ErkJggg==" />;

const PropertyDetailsCard = ({propertySelected}) => {
  console.log('PropertyDetailsCard: ', propertySelected);
  const history = useHistory();
  const [ propertyID ] = useLocalStorage({ key: 'chosenProperty', defaultValue: '' });
  const [ propertyData, setPropertyData ] = useState(null);
  
  // Media queries
  const isDesktop = useMediaQuery('(max-width: 1300px)');
  const isTablet = useMediaQuery('(max-width: 640px)');
  const isMobile = useMediaQuery('(max-width: 500px)');

  useEffect(() => {
    const rentalUnitsUri = APIClient({ method: 'GET', url: `${config.baseUrl}property-api/rentalUnits?offset=0&fetch=100&propertyId=${propertySelected && propertySelected.id}`, headers: { Authorization: Autoload.Utils.getAuthToken() } });
    const propertyInfoUri = APIClient({ method: 'GET', url: `${config.baseUrl}${config.properties}/${propertySelected && propertySelected.id}`, headers: { Authorization: Autoload.Utils.getAuthToken() } });
    const propertyPictureUri = APIClient({ method: 'GET', url: `${config.baseUrl}${config.properties}/${propertySelected && propertySelected.id}/picture`, headers: { Authorization: Autoload.Utils.getAuthToken() } });

    axios.all([
      rentalUnitsUri,
      propertyInfoUri,
      propertyPictureUri
    ])
      .then((axios.spread(( ...responses ) => {
        const rentalUnitsResponse = responses[0];
        const propertyInfoResponse = responses[1];
        const propertyPictureResponse = responses[2];

        // console.log("rentalUnitsResponse: ", rentalUnitsResponse);
        console.log("propertyInfoResponse: ", rentalUnitsResponse);
        // console.log("propertyPictureResponse: ", propertyPictureResponse);

        setPropertyData({
          rentalUnits: propertyInfoResponse.data.result.propertyType === 'CONDOMINIUM' ?
          propertyInfoResponse.data.result.numberOfRentalUnits
          : rentalUnitsResponse.data.result.length,
          propertyPicture: propertyPictureResponse.data.result,
          propertyName: propertyInfoResponse.data.result.name,
          propertyAddress: propertyInfoResponse.data.result.propertyAdress.streetno,
          surfaceArea: propertyInfoResponse.data.result.propertyType === 'CONDOMINIUM' ?
          formatNumber(propertyInfoResponse.data.result.totalRentingSpace, false) + ' m²'
          : formatNumber(rentalUnitsResponse.data.result.reduce((a, b) => a + b.space, 0), false) + ' m²',
          cutOffDate: `${propertyInfoResponse.data.result.cutOffDate.day}. ${months[propertyInfoResponse.data.result.cutOffDate.month - 1]}`,
          propertyType: propertyInfoResponse.data.result.propertyType
        });
      })))
      .catch((error) => {
        console.log('catch: ', error);
        // setPropertyData({
        //   propertyName: propertySelected.name,
        //   propertyAddress: propertySelected?.propertyAdress === '' ? '' : propertySelected?.propertyAdress?.streetno,
        //   rentalUnits: 0,
        //   surfaceArea: '0 m²',
        //   cutOffDate: `${propertySelected.cutOffDate.day}. ${months[propertySelected.cutOffDate.month - 1]}`,
        //   propertyType: propertySelected.propertyType
        // });
        console.error(error);
      });

  }, [propertySelected]);

  return (
    <Card shadow="xs" onClick={() => isMobile && history.push("/property")} p={24} style={{marginBottom: isMobile && '15px'}}>
      {propertyData
        ? (
          <>
            <Group spacing={isMobile ? 15 : 24} align="start" >
              <Group style={{width: '100%'}}>
              {propertyData.propertyPicture
                ? <div style={{ width: isMobile ? 50 : 110, height: isMobile ? 50 : 110, borderRadius: 4, border: "1px solid #eee" }}><img src={propertyData.propertyPicture} alt={propertyData.propertyName} style={{ width: "100%", height: "100%", objectFit: "cover", objectPosition: "center" }} /></div>
                : <DefaultPropertyImage isMobile={isMobile} />
              }
              <Stack sx={{ flex: 1 }} spacing={12} style={{marginBottom: isMobile && '5px'}}>
                <Group position="apart" align="start">
                  <Stack spacing={0}>
                    <Text component="h1" sx={{ fontSize: isMobile ? 14 : 24, margin: 0 }}>{propertyData.propertyName}</Text>
                    <Text size="sm" color="dimmed">{propertyData.propertyAddress}</Text>
                  </Stack>
                  {!isMobile && <Button variant="white" onClick={() => history.push("/property")}>Bearbeiten</Button>}
                </Group>
                {!isMobile && 
                <SimpleGrid cols={isMobile ? 3 : isTablet ? 2 : isDesktop ? 3 : 5}>
                  <LabeledData label="Einheiten" icon="beds" value={propertyData.rentalUnits} />
                  <LabeledData label="Fläche" icon="area" value={propertyData.surfaceArea} />
                  <LabeledData label="Stichtag" icon="calendar" value={propertyData.cutOffDate} />
                  <Stack spacing={6}>
                    <Text size="sm" color="dimmed" weight={400}>{'Art der Immobilie'}</Text>
                    <Group>
                      <img src={propertyData.propertyType === 'CONDOMINIUM' ? plan : skyscraper} width={16} height={16} />
                      <Text>{propertyData.propertyType === 'CONDOMINIUM' ? 'Eigentumswohnung / Gewerbeeinheit' : 'Mehrparteienhaus'}</Text>
                    </Group>
                  </Stack>
                </SimpleGrid>}
              </Stack>
              {/* <div onClick={() => history.push("/property")} style={{
                //background: 'red'
              }} 
                >
                <img src={PlayIcon}></img>
              </div> */}
              </Group>
              {isMobile && 
              <SimpleGrid cols={isMobile ? 2 : isTablet ? 2 : isDesktop ? 3 : 5} style={{ width: '100%'}} >
                <LabeledData textStyle={{fontSize: isMobile && 12}} label="Einheiten" icon="beds" value={propertyData.rentalUnits} />
                <LabeledData textStyle={{fontSize: isMobile && 12}} label="Fläche" icon="area" value={propertyData.surfaceArea} />
                <LabeledData textStyle={{fontSize: isMobile && 12}} label="Stichtag" icon="calendar" value={propertyData.cutOffDate} />
                <Stack spacing={6}>
                    <Text size="sm" color="dimmed" weight={400}>{'Art der Immobilie'}</Text>
                    <Group>
                      <img src={propertyData.propertyType === 'CONDOMINIUM' ? plan : skyscraper} width={16} height={16} />
                      <Text style={{fontSize: isMobile && 12}}>{propertyData.propertyType === 'CONDOMINIUM' ? 'Eigentumswohnung / Gewerbeeinheit' : 'Mehrparteienhaus'}</Text>
                    </Group>
                  </Stack>
              </SimpleGrid>}
            </Group>
          </>
        )
        : (
          <Center sx={{ minHeight: 180 }}>
            <Loader color="blue" size="sm" />
          </Center>
        )
      }
    </Card>
  )
}

export default PropertyDetailsCard;