import { Group, Stack } from '@mantine/core';
import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import { NumericFormat } from 'react-number-format';
import CustomModal from '.';
import ColorButton from '../Buttons/ColorButton';

const MOBILE_SIZE = 992;
const TABLET_SIZE = 1200;

export class EditVatAndLabourAmount extends Component {
  state = {
    isMobile: window.innerWidth <= MOBILE_SIZE,
    isTablet: window.innerWidth <= TABLET_SIZE,
    vatAmount: null,
    laborCost: null,
    vatAmountInFocus: false,
    laborAmountInFocus: false
  };

  constructor(props) {
    super(props);
  }

  componentDidMount(){
    this.setState({vatAmount: this.props.vatAmount, laborCost: this.props.laborCost});
  }

  handleEnterKeyPress = (e) => {
    if (e.charCode == 13) {
      e.preventDefault();
      //this.handleSubmit(e);
    }
  };
  
  render() {
    const { open, toggle, className, isSecured } = this.props;
    const { vatAmount, laborCost, vatAmountInFocus, laborAmountInFocus } = this.state;
    
    return (
      <CustomModal
        heading={'Mehrwertsteuer und Lohnkosten ergänzen'}
        headingClassName={'vat-labour-amount-modal-title'}
        open={open}
        toggle={() => {
          toggle();
        }}
        className={`${className}`}
      >
        <Stack>
            <Group grow noWrap>
                <Form.Group>
                    <Form.Label style={{fontSize: '12px', color: '#727293', lineHeight: '20px', margin: '0 0 3px'}}>{'Enthaltene MwSt.'}</Form.Label>
                    <NumericFormat 
                    className='form-control'
                    value={vatAmount}
                    thousandSeparator="." 
                    decimalSeparator=","
                    onValueChange={(values, sourceInfo) => {
                        this.setState({vatAmount: values.floatValue})
                    }}
                    suffix={' €'}
                    onKeyPress={this.handleEnterKeyPress}
                    placeholder='0,00 €'
                    onFocus={()=> this.setState({vatAmountInFocus: true})}
                    onBlur={()=> this.setState({vatAmountInFocus: false})}
                    decimalScale={vatAmountInFocus === false ? 2 : 5}
                    fixedDecimalScale={vatAmountInFocus ? false : true}
                    allowNegative={true}
                    disabled={isSecured}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label style={{fontSize: '12px', color: '#727293', lineHeight: '20px', margin: '0 0 3px'}}>{'Lohnkosten nach §35a EStG'}</Form.Label>
                    <NumericFormat 
                    className='form-control'
                    value={laborCost}
                    thousandSeparator="." 
                    decimalSeparator=","
                    onValueChange={(values, sourceInfo) => {
                        this.setState({laborCost: values.floatValue})
                    }}
                    suffix={' €'}
                    onKeyPress={this.handleEnterKeyPress}
                    placeholder='0,00 €'
                    onFocus={()=> this.setState({laborAmountInFocus: true})}
                    onBlur={()=> this.setState({laborAmountInFocus: false})}
                    decimalScale={laborAmountInFocus === false ? 2 : 5}
                    fixedDecimalScale={laborAmountInFocus ? false : true}
                    allowNegative={true}
                    disabled={isSecured}
                    />
                </Form.Group>
            </Group>
            <Group style={{width: '100%'}} position='apart'>
                <div>
                    <ColorButton
                    className='gray-btn'
                    text='Schließen'
                    onClick={()=> toggle()}
                    />
                </div>
                <div>
                    <ColorButton
                    className='purple-btn'
                    text='Speichern'
                    onClick={()=> this.props.saveVatLaborValues(vatAmount, laborCost)}
                    disabled={isSecured}
                    />
                </div>
            </Group>
        </Stack>
      </CustomModal>
    );
  }
}

export default EditVatAndLabourAmount;
