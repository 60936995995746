import React, { Component } from 'react';
import CustomModal from './index';
import { connect } from 'react-redux';
import Autoload from '../../../../Autoload';
import * as customParseFormat from 'dayjs/plugin/customParseFormat'
import dayjs from 'dayjs';
import { Group, Stack } from '@mantine/core';
import DropFile from '../DropFile/DropFile';
import { Form } from 'react-bootstrap';
import SelectDropdown from '../SelectDropdown/SelectDropdown';
import ColorButton from '../Buttons/ColorButton';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import RedCross from '../../../../../assets/images/module/general/RedCross.svg';
import { showNotification } from '@mantine/notifications';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
var { checkProperty, yearDifference, yearDifferenceMoment, loader } = Autoload.MainHelper;
dayjs.extend(customParseFormat)

const MOBILE_SIZE = 992;
const TABLET_SIZE = 1200;

class AddFolderModal extends Component {
  state = {
    isMobile: window.innerWidth <= MOBILE_SIZE,
    isTablet: window.innerWidth <= TABLET_SIZE,
    folderName: null
  };

  componentDidMount(){
  }

  createFolder=()=>{
    const {folderName} = this.state;
    const {propertySelected, currentFolderId, hideShareFolder, showTenantDocuments=false, tenantId} = this.props;
    if(folderName){
      loader(true);
        const data = new FormData();
        data.append('accountId', propertySelected.accountId);
        !hideShareFolder && data.append('propertyId', propertySelected.id);
        data.append('name', folderName);
        data.append('isDirectory', true);
        data.append('isReadOnly', false);
        data.append('isShared', hideShareFolder);
        data.append('fileType', showTenantDocuments ? 'TenantDocument' : 'General');
        showTenantDocuments && data.append('referencedObjectId', tenantId);
        currentFolderId !== null && currentFolderId !== 0 && data.append('parentDirectoryId', currentFolderId);
        // data.append('file', null)
  
        Autoload.HttpRequestAxios.HttpRequest(
        Autoload.Setting.document_api_base_url + 'documents',
        'POST',
        data,
        null,
        false,
        true,
        false,
        this,
        'handleUploadFile',
        null,
        false,
        '',
        {
          //'Content-Type': 'application/json',
          Authorization: Autoload.Utils.getAuthToken(),
        },
        true
        );
    }else{
      showNotification({
        message: 'Bitte fügen Sie einen Ordnernamen hinzu.',
        icon: <img src={RedCross} alt='red-cross'/>,
        id: 'folderNameNotSelected',
      });
    }
  }

  handleUploadFile(response){
    console.log('handleUploadFile: ', response);
    if(response.status && response.statuscode === 200){
      console.log('all done');
      this.props.getDocuments();
      this.props.toggle();
      loader(false);
    } else {
      showNotification({
        message: 'Ordner nicht erfolgreich erstellt.',
        icon: <img src={RedCross} alt='red-cross'/>,
        id: 'folderNotCreatedSuccessfully',
      });
      loader(false);
    }
  }
  
  render() {
    const { open, toggle, className, folderName } = this.props;
    return (
      <>
        <CustomModal
          heading={
          <Group spacing={10}>
            <CreateNewFolderIcon 
            style={{
              color: '#5655FE',
              fontSize: '20px'
            }}/>
            <span>{'Neuen Ordner anlegen'}</span>
          </Group>
          }
          headingClassName='create-folder-modal-heading'
          open={open}
          toggle={() => {
            toggle();
          }}
          className={`${className}`}
        >
          <Stack style={{width: '100%'}}>
            <Group grow>
              <Stack>
                <Group grow>
                <Form.Group>
                    <Form.Label>Ordnername</Form.Label>
                    <Form.Control
                    //isInvalid={!!this.state.errors.title}
                    type="text"
                    placeholder=""
                    value={folderName}
                    onChange={(e) => this.setState({folderName: e.target.value})}
                    //onKeyPress={this.handleEnterKeyPress}
                    />
                </Form.Group>
                {/* <Form.Group>
                    <Form.Label>Ordner type</Form.Label>
                    <SelectDropdown
                    options={[]} // Options to display in the dropdown
                    //selectedValue={selectedVatType.length > 0 && selectedVatType[0]} // Preselected value to persist in dropdown
                    // onSelect={this.onVatPercentageSelect} // Function will trigger on select event
                    // onRemove={this.onVatPercentageRemove} // Function will trigger on remove event
                    displayValue="label"
                    placeholder={lang.newKeys.text_select}
                    showSelectedHighlight={false}
                    ddHeaderClassName={`custom-select-dropdown ${'custom-select-dropdown-pt-0'}`}
                    //disable={this.state.amount ? false : true}
                    />
                </Form.Group> */}
                </Group>
                <Group position='apart'>
                    <div>
                        <ColorButton
                        onClick={()=> toggle()}
                        className="gray-btn"
                        text={'Abbrechen'}
                        />
                    </div>
                    <div>
                        <ColorButton
                        onClick={()=> this.createFolder()}
                        className="purple-btn"
                        text={'Erstellen'}
                        />
                    </div>
                </Group>
              </Stack>
            </Group>
          </Stack>
        </CustomModal>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    propertySelected: state.generalReducer.property,
  };
};

export default connect(mapStateToProps)(AddFolderModal);
