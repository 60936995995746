import React, { Component } from 'react';
import Autoload from '../../../../neoverv/Autoload';
import { connect } from 'react-redux';
import { showNotification } from '@mantine/notifications';
import CopyIcon from '../../../../neoverv/components/SVGs/copyIcon';
import EyeIcon from '../../../../assets/images/module/general/EyeIcon.svg';
import { Form } from 'react-bootstrap';
import { Group, Stack } from '@mantine/core';
import { ColorButton } from '../../../../neoverv';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
const { checkProperty, requiredFields, loader } = Autoload.MainHelper;
const pStyle= {
    fontFamily: 'Inter, sans-serif',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '24px',
    color: 'rgb(14, 14, 29)'
}
export class TenantAccessDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
        hover: false, 
        click: false,
        username: '',
        password: ''
    };
  }

  componentDidMount = () => {
    loader(false);
  };
  toggleShowPassword=(id)=> {
    var x = document.getElementById(id);
    if (x.type === 'text') {
      x.setAttribute('type', 'password');
    } else {
      x.setAttribute('type', 'text');
    }
  }
  
  render() {
    const email = this.props.propertySelected.meterReadingsEmail;
    const {hover, click, username, password} = this.state;
    const {isMobile, isTablet} = this.props;

    return (
        <div 
        style={{
          width: 'auto',//isTablet ? '100%' : '540px', 
          //height: '400px', //isTablet ? '100%' : '400px',
          //boxShadow: '0 1px 3px rgba(0,0,0,.08)', 
          //borderRadius: '4px',
          //background: 'white'
          }}>
          <div 
          style={{
              padding: '12.1px 0',
              // borderBottom: '1px solid #dadae6',
              // borderTopLeftRadius: '4px',
              // borderTopRightRadius: '4px',
              //background: 'white'
          }}>
              <h5 
              style={{
                  fontWeight: '500',
                  fontSize: '16px',
                  lineHeight: '24px',
                  color: '#0e0e1d',
                  marginBottom: '0px'
                  }}>Zugang Details</h5>
          </div>
          <div 
          style={{
              padding: '0px 0px 30px',
          }}>
            <Stack spacing={10}>
              <Group>
                <Form.Group>
                  <Form.Label>{'Username'}</Form.Label>
                  <Form.Control
                  id="meter-number"
                  name="meter-number"
                  type="text"
                  placeholder={''}
                  value={username}
                  style={{height: '36px', paddingBottom: '6px'}}
                  onChange={(e)=> this.setState({username: e.target.value})}
                  
                  //value={this.state.propertyName}
                  //isInvalid={this.state.propertyNameErr}
                  //        onKeyDown={this._handleKeyDown}
                  //   onChange={(e) => {
                  //     this.onInputChange('propertyName', e.target.value, true);
                  //   }}
                  />
                  {/* <Form.Control.Feedback className="err" type="invalid">
                  {lang.property.generalinfo_propertynameError}
                  </Form.Control.Feedback> */}
                </Form.Group>
                <Form.Group>
                  <Form.Label>{'Passwort'}</Form.Label>
                  {/* <Form.Control
                  id="meter-number"
                  name="meter-number"
                  type="password"
                  placeholder={''}
                  value={password}
                  style={{height: '36px', paddingBottom: '6px'}}
                  onChange={(e)=> this.setState({password: e.target.value})}
                  disabled
                  /> */}
                  <div className="password-field" style={{ position: 'relative', top: 0, left: 0 }}>
                  <Form.Control
                    id="new-password"
                    name="new-password"
                    //isInvalid={this.state.newPassErr}
                    type="password"
                    value={password}
                    onChange={(e) => {
                      this.setState({password: e.target.value});
                    }}
                  />
                  <img src={EyeIcon} onClick={() => this.toggleShowPassword('new-password')} 
                  alt="eye-icon" />
                  {/* <Form.Control.Feedback className="err" type="invalid">
                    {' '}
                    {lang.text_invalid_new_password}{' '}
                  </Form.Control.Feedback> */}
                </div>
                </Form.Group>
              </Group>
              <Group>
                <ColorButton
                  text={'Zurücksetzen'}
                  className="gray-btn"
                  buttonStyle={{width: 'auto', padding: '8px', height: '36px', marginLeft: '0px'}}
                />
                <ColorButton
                  text={'Erzeugen'}
                  className="purple-btn"
                  buttonStyle={{width: 'auto', padding: '10px 8px'}}
                />
              </Group>
            </Stack>
          </div>
        </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    propertySelected: state.generalReducer.property,
  };
};
export default connect(mapStateToProps)(TenantAccessDetails);
