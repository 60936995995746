import React, { useEffect } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { connect } from 'react-redux';
import { StateModal } from '../../../../../redux/action/General/generalActions';
import { useDispatch } from 'react-redux';
const MOBILE_SIZE = 992;
const TABLET_SIZE = 1200;

function CustomModal({ heading, className, children, open, toggle, style, styleBody, center=false, headingClassName, styleHeader=null, hideCloseButton=false }) {
  // const [modal, setModal] = useState(false);
  // const toggle = () => setModal(!modal);
  //  this.props.StateModal(true)
  const dispatch = useDispatch();

  useEffect(() => {
    //dispatch(StateModal(open));
  });
  // const setToggle=()=>{
  //   dispatch(StateModal(!open))
  //   toggle()

  // }
  return (
    <Modal
      isOpen={open}
      toggle={() => toggle()}
      className={`custom-modal ${className} ${(window.innerWidth <= MOBILE_SIZE || window.innerWidth <= TABLET_SIZE || center) ? 'centered' : 'web-modal-center'}`}
      backdrop="static"
      keyboard={false}
      centered
      style={style}
    >
      {/* { console.log(open, 'open state in custom modal after ')} */}
      <ModalHeader style={styleHeader} className={`${headingClassName} ${hideCloseButton && 'hide-modal-header-close-button'}`} toggle={() => toggle()}>{heading ? heading : 'Modal title'}</ModalHeader>
      <ModalBody style={styleBody}>{children}</ModalBody>
    </Modal>
  );
}
CustomModal.defaultProps = {
  heading: '',
  open: true,
  toggle: () => {},
};
const actions = {
  StateModal,
};
export default connect(null, actions)(CustomModal);
