/*
  created by Rija
  added on 2/1/2021
  @desc meter main page to swtich between different options
*/

import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { ButtonGroup, Button } from 'react-bootstrap';
import { ColorButton, EmptyStateContainer, FiltersContainer, HeadingContainer, RecordOptions, SearchContainer } from '../../../../neoverv';
import Autoload from '../../../../neoverv/Autoload';
import Avatar from '../../../../assets/images/module/general/userAvatar.svg';
import defaultAvatar from '../../../../assets/images/module/general/defaultAvatarPicture.svg';
import { withRouter } from 'react-router-dom';
import MessagingEditor from './MessagingEditor';
import { TableContainer } from '@material-ui/core';
import { Group, Loader, Popover, Skeleton, Stack } from '@mantine/core';
import moment from 'moment';
import MessageTemplatesModal from '../../../../neoverv/components/Module/General/CustomModal/MessageTemplatesModal';
import breadcrumbsContent from '../../../../../src/assets/breadcrumbs.json';
import HelpLogo from '../../../../assets/images/module/general/book.svg';
import TourIcon from '../../../../assets/images/module/general/TourIcon.svg';
import MessagesIcon from '../../../../assets/images/module/general/MessagesIcon.svg';
import YellowTick from '../../../../assets/images/module/general/YellowTick.svg';
import GreenTick from '../../../../assets/images/module/general/GreenTick.svg';
import RedCross from '../../../../assets/images/module/general/RedCross.svg';
import ExplanationMarkIcon from '../../../../assets/images/module/general/ExplanationMarkIcon.svg';
import RightArrowPartner from '../../../../assets/images/module/general/RightArrowPartner.svg';
import { connect } from 'react-redux';
import { showNotification } from '@mantine/notifications';
import MessageDeleteModal from '../../../../neoverv/components/Module/General/CustomModal/MessageDeleteModal';
import { setPublishMessageId, setRefreshMessagesData } from '../../../../redux/action/General/generalActions';
import dayjs from 'dayjs';
import MessagePostviewModal from '../../../../neoverv/components/Module/General/CustomModal/MessagePostviewModal';
import SelectMessageTypeModal from '../../../../neoverv/components/Module/General/CustomModal/SelectMessageTypeModal';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];

const MOBILE_SIZE = 992;

class MessagesList extends Component {
  state = {
    messageList: [{
        id: '0',
        title: 'Message 1',
        description: 'Lorem ipsum dolor sit amet, msg1 consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem.',
        date: `${moment().format('DD-MM-YYYY')}T17:23:00`,
        tenant: [{
            name: 'Ten2',
            picture: defaultAvatar
        }]
    },
    {
        id: '1',
        title: 'Message 2',
        description: 'Lorem ipsum dolor sit amet, msg2 consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem.',
        date: '13-04-2023T09:21:00',
        tenant: [{
            name: 'Ten4',
            picture: Avatar
        }, {
            name: 'Ten2',
            picture: defaultAvatar
        }, {
            name: 'Ten1',
            picture: Avatar
        }]
    },
    {
        id: '2',
        title: 'Message 3',
        description: 'Lorem ipsum dolor sit amet, msg3 consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem.',
        date: '13-04-2023T12:10:00',
        tenant: [{
            name: 'Ten1',
            picture: defaultAvatar
        }]
    },
    {
        id: '3',
        title: 'Message 4',
        description: 'Lorem ipsum dolor sit amet, msg1 consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem.',
        date: '12-04-2023T22:00:00',
        tenant: [{
            name: 'Ten1',
            picture: Avatar
        }, {
            name: 'Ten3',
            picture: defaultAvatar
        }]
    },
    {
        id: '4',
        title: 'Message 5',
        description: 'Lorem ipsum dolor sit amet, msg5 consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem.',
        date: '12-04-2023T15:56:00',
        tenant: [{
            name: 'Ten1',
            picture: defaultAvatar
        }, {
            name: 'Ten2',
            picture: Avatar
        }, {
            name: 'Ten3',
            picture: defaultAvatar
        }, {
            name: 'Ten4',
            picture: Avatar
        }, {
            name: 'Ten5',
            picture: defaultAvatar
        }]
    }],
    search: '',
    openMessageTemplateModal: false,
    messagesList: [],
    skeletonLoading: false,
    fetch: 10, 
    offset: 0,
    stopOffsetChange: false,
    fetchLoader: false,
    openMessageDeletionModal: false,
    selectedMessageIdForDeletion: null,
    utilityBillings: [],
    openPropertyModal: false,
    isMobile: window.innerWidth <= MOBILE_SIZE,
    selectedMessageErrorPopover: null,
    openRecipientStatus: null,
    openPreviewModal: false,
    selectedRecipient: null,
    selectedMessage: null,
    openMessageTypeSelectionModal: false
  };

  componentDidMount(){
    this.getMessages(true);
    document.addEventListener('scrollend', this.trackScrolling);
    this.getUtilityBillingsByPropertyId();

    console.log('this.props: ', this.props);
    window.addEventListener('resize', this.handleResize);
  }

  componentDidUpdate(){
    if(this.props.refreshMessagesData === true){
        this.props.setRefreshMessagesData(null);
        this.getMessage(this.props.publishMessageId);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('scrollend', this.trackScrolling);
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    // TODO: this causes data refetch on the meters reading details screen...
    if (window.innerWidth <= MOBILE_SIZE) {
      this.setState({ isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
  };

  trackScrolling = () => {    
    const wrappedElement = document.getElementById('app-content');
    if (this.isBottom(wrappedElement) && !this.state.fetchLoader) {
      console.log('trackScrolling: ', this.state.messagesList.length, this.state.offset + this.state.fetch);
      //if(this.state.messagesList.length > (this.state.offset + this.state.fetch)){
        this.setState({
          offset: this.state.stopOffsetChange ? this.state.offset : this.state.offset + 10,
          fetchLoader: true}, ()=> this.getMessages()//this.getDocumentsByAccountId()//this.getDocumentsByAccountId()
        );
      //}
    }
  };

  isBottom(el) {
    return el.getBoundingClientRect().bottom <= window.innerHeight;
  }

  isTop(el) {
    return el.getBoundingClientRect().top <= window.innerHeight;
  }


  handleSearchHandler=(e)=>{
    this.setState({search: e.target.value});
  }

  getUtilityBillingsByPropertyId=()=>{
    Autoload.HttpRequestAxios.HttpRequest(
        Autoload.Setting.ub_api_base_url + 'utilityBillings?PropertyId=' + this.props.propertySelected.id,
        'GET',
        null,
        null,
        false,
        true,
        false,
        this,
        'handleGetUtilityBillingsByPropertyId',
        null,
        false,
        '',
        {
          Authorization: Autoload.Utils.getAuthToken(),
        }
      );
  }

  handleGetUtilityBillingsByPropertyId(response){
    if(response.status && response.statuscode === 200){
        if(response.result.length > 0){
            this.setState({utilityBillings: response.result});
        }
    }
  }

  getMessages = (firstLoading=false) => {
    const {fetch, offset} = this.state;
    firstLoading && this.setState({skeletonLoading: true});
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.user_reg_api_baseurl + `messages/GetAllByProperty?propertyId=${this.props.propertySelected.id}&fetch=${fetch}&offset=${offset}`,//GetAllByAccount
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleGetMessages',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };

  handleGetMessages(response){
    const {propertySelected, publishMessageId} = this.props;
    console.log('handleGetMessages: ', propertySelected);
    if(response.status && response.statuscode === 200){
        let propertyMessages = response.result.filter((message)=> message.propertyId === propertySelected.id);
      if(propertyMessages.length > 0){
        let {messagesList} = this.state;
        propertyMessages.map((message)=> {
            messagesList.push({...message, recipients: []});
        });
        this.setState({messagesList}, ()=> {
            if(publishMessageId){
                let messageStatus = messagesList.find((message)=> message.id === publishMessageId)?.status === 'Publish';
                if(messageStatus){
                    this.props.setPublishMessageId(null);
                }
            }
        });
        this.getMessageRecipientsByMessageIds(propertyMessages);
      }
    //   else{
    //     showNotification({message: 'none_found'});
    //   }
    }
    else if(!response.status && response.statuscode === 404 && response.message === 'Not Found'){
        this.setState({stopOffsetChange: true})
    }
    console.log('handleGetMessages: ', response);
    this.setState({skeletonLoading: false, fetchLoader: false});
  }

  getMessage= (id)=>{
    Autoload.HttpRequestAxios.HttpRequest(
        Autoload.Setting.user_reg_api_baseurl + `messages/${id}`,
        'GET',
        null,
        null,
        false,
        true,
        false,
        this,
        'handleGetMessage',
        null,
        false,
        '',
        {
          Authorization: Autoload.Utils.getAuthToken(),
        }
      );
  }

  handleGetMessage(response){
    if(response.status && response.statuscode){
        let {messagesList} = this.state;
        messagesList = messagesList.map((message)=> {
            if(message.id === response.result.id){
                return {...response.result, recipients: message.recipients};
            }else{
                return message;
            }
        });
        this.setState({messagesList},()=> this.getMessageRecipientsByMessageIds([{id: response.result.id}]));
    }
    this.props.setPublishMessageId(null);
  }

  getMessageRecipientsByMessageIds=(messages)=>{
    Autoload.HttpRequestAxios.HttpRequest(
    Autoload.Setting.user_reg_api_baseurl + 'messageRecipients?'+ messages.map((message)=> `messageIds=${message.id}`).toString().replaceAll(',','&'),//`${this.state.messagesList.map((message)=> message.id)}`,
    'GET',
    null,
    null,
    false,
    true,
    false,
    this,
    'handleGettMessageRecipients',
    messages,
    false,
    '',
    {
        Authorization: Autoload.Utils.getAuthToken(),
    },
    true
    );
  }

  handleGettMessageRecipients(response){
    if(response.status && response.statuscode === 200){
        let {messagesList} = this.state;
        messagesList.map((message)=> {
            let newRecipients = response.result.filter((recipient)=> recipient.messageId === message.id);
            message.recipients = newRecipients.length > 0 ? newRecipients : message.recipients;
            return message;
        });
        this.setState({messagesList});
    }
    // else if(response.statuscode === 429){
    //     setTimeout(() => {
    //         this.getMessageRecipientsByMessageIds(response.other);  
    //     }, 3000);
    // }
  }

  deleteMessagesById = (id) => {
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.user_reg_api_baseurl + 'messages/'+id,
      'DELETE',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleDeleteMessagesById',
      id,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };

  handleDeleteMessagesById(response){
    if(response.status && response.statuscode === 200){
        let {messagesList} = this.state;
        const index = messagesList.findIndex((message)=> message.id === response.other)
        console.log('handleDeleteMessagesById: ', index, response);
        if (index > -1) { 
            messagesList.splice(index, 1);
            this.setState({
                messagesList
            });
        }
    }
    this.setState({openMessageDeletionModal: false, selectedMessageIdForDeletion: null});
  }

  replaceAllPlaceHolders=(html, utilityBillingId)=>{
    const {propertySelected} = this.props;
    const {utilityBillings} = this.state;
    let selectedUtilityBilling= utilityBillings.find((ub)=> ub.id === utilityBillingId);
    let newHtml= html;
    //let rentalUnit = rentalUnits.find((ru)=> ru.tenantsData.find((tenant)=> tenant.id === selectedRecipient.tenantId) !== undefined);
    
    newHtml= newHtml.replaceAll('<a href="#" target="_self">Abrechnungszeitraum</a>', selectedUtilityBilling !== undefined ? `${dayjs(selectedUtilityBilling.billingPeriod.startDate).format('DD.MM.YYYY')} - ${dayjs(selectedUtilityBilling.billingPeriod.endDate).format('DD.MM.YYYY')}` : 'Abrechnungszeitraum');
    newHtml= newHtml.replaceAll('<a href="" target="_self">Abrechnungszeitraum</a>', selectedUtilityBilling !== undefined ? `${dayjs(selectedUtilityBilling.billingPeriod.startDate).format('DD.MM.YYYY')} - ${dayjs(selectedUtilityBilling.billingPeriod.endDate).format('DD.MM.YYYY')}` : 'Abrechnungszeitraum');
    //newHtml= newHtml.replaceAll('<a href="#" target="_self">Betriebskosten</a>', selectedBillingStatement?.totalAllocatedUtilityCosts !== null && formatNumber(selectedBillingStatement?.totalAllocatedUtilityCosts, true));
    //newHtml= newHtml.replaceAll('<a href="#" target="_self">Guthaben / Nachzahlung</a>', selectedBillingStatement?.totalAllocatedUtilityCosts !== null && selectedBillingStatement?.totalAdvancePayments !== null && `${formatNumber(selectedBillingStatement?.totalAdvancePayments - selectedBillingStatement?.totalAllocatedUtilityCosts, true)}`);
    //newHtml= newHtml.replaceAll('<a href="#" target="_self">Empfänger</a>', selectedRecipient?.name);
    //newHtml= newHtml.replaceAll('<a href="#" target="_self">Mieteinheit</a>', rentalUnit !== undefined ? rentalUnit.name : 'Mieteinheit');
    newHtml= newHtml.replaceAll('<a href="#" target="_self">Objektadresse</a>', (propertySelected.propertyAddress && propertySelected.propertyZipCode && propertySelected.propertyCity) ? `${propertySelected.propertyAddress}, ${propertySelected.propertyZipCode} ${propertySelected.propertyCity}` : 'Objektadresse');
    newHtml= newHtml.replaceAll('<a href="" target="_self">Objektadresse</a>', (propertySelected.propertyAddress && propertySelected.propertyZipCode && propertySelected.propertyCity) ? `${propertySelected.propertyAddress}, ${propertySelected.propertyZipCode} ${propertySelected.propertyCity}` : 'Objektadresse');
    //newHtml= newHtml.replaceAll('<a href="#" target="_self">Vorauszahlungen</a>', selectedBillingStatement?.totalAdvancePayments !== null && formatNumber(selectedBillingStatement?.totalAdvancePayments, true));
    console.log('newMessageData: ', newHtml);
    return newHtml;
  }

  replaceValuesWithPlaceHolders=(html)=>{
    let newHtml= html;

    newHtml= newHtml.replaceAll('{{BILLING_PERIOD}}', '<a href="#" target="_self">Abrechnungszeitraum</a>');
    newHtml= newHtml.replaceAll('{{TOTAL_ALLOCATED_UTILITY_COSTS}}', '<a href="#" target="_self">Betriebskosten</a>');
    newHtml= newHtml.replaceAll('{{BILLING_STATEMENT_BALANCE}}', '<a href="#" target="_self">Guthaben / Nachzahlung</a>');
    newHtml= newHtml.replaceAll('{{RECIPIENT_NAME}}', '<a href="#" target="_self">Empfänger</a>');
    newHtml= newHtml.replaceAll('{{RENTAL_UNIT_NAME}}', '<a href="#" target="_self">Mieteinheit</a>');
    newHtml= newHtml.replaceAll('{{PROPERTY_STREET}}, {{PROPERTY_ZIPCODE}} {{PROPERTY_CITY}}', '<a href="#" target="_self">Objektadresse</a>');
    newHtml= newHtml.replaceAll('{{TOTAL_ADVANCE_PAYMENTS}}', '<a href="#" target="_self">Vorauszahlungen</a>');

    //new
    newHtml= newHtml.replaceAll('{{TENANT_NAME}}', '<a href="#" target="_self">Mieter</a>');
    newHtml= newHtml.replaceAll('{{USER_FIRST_NAME}} {{USER_LAST_NAME}}', '<a href="#" target="_self">Absender</a>');
    newHtml= newHtml.replaceAll('{{TENANT_MOVE_OUT_DATE}}', '<a href="#" target="_self">Auszugsdatum</a>');
    newHtml= newHtml.replaceAll('{{TENANT_MOVE_IN_DATE}}', '<a href="#" target="_self">Einzugsdatum</a>');
    newHtml= newHtml.replaceAll('{{RENTAL_PAYMENT_RECEIVED_RENT}}', '<a href="#" target="_self">erhaltene Miete</a>');
    newHtml= newHtml.replaceAll('{{RENTAL_PAYMENT_EXPECTED_RENT_TOTAL}}', '<a href="#" target="_self">gesamte ausstehende Miete</a>');
    newHtml= newHtml.replaceAll('{{COLD_RENT}}', '<a href="#" target="_self">Kaltmiete</a>');
    newHtml= newHtml.replaceAll('{{RENTAL_PAYMENT_MONTH}}', '<a href="#" target="_self">Mietmonat</a>');
    newHtml= newHtml.replaceAll('{{ACCOUNT_COMPANY_NAME}}', '<a href="#" target="_self">Vermieter</a>');
    newHtml= newHtml.replaceAll('{{RENT_PRE_PAYMENTS}}', '<a href="#" target="_self">Vorauszahlung</a>');
    newHtml= newHtml.replaceAll('{{WARM_RENT}}', '<a href="#" target="_self">Warmmiete</a>');

    return newHtml;
  }

  renderNewMessageButton=()=>{
    return <ColorButton
    text={'Neue Nachricht'}
    className={'purple-btn'}
    onClick={()=> this.setState({openMessageTypeSelectionModal: true})} //this.props.history.push('/messaging/newMessage')
    textStyle={{whiteSpace: 'nowrap'}}
    />
  }
  

  render() {
    const {messageList, search, openMessageTemplateModal, messagesList, skeletonLoading, fetchLoader, openMessageDeletionModal, selectedMessageIdForDeletion, utilityBillings, isMobile, selectedMessageErrorPopover, openRecipientStatus, openPreviewModal, selectedRecipient, selectedMessage, openMessageTypeSelectionModal} = this.state;
    const {propertySelected, publishMessageId, accountData} = this.props;
    console.log('messagesList: ', messagesList);
    console.log('utilityBillings: ', utilityBillings);
    console.log('propertySelected: ', propertySelected);
    console.log('isMobile: ', isMobile);
    return (
      <div className="messages-list-screen">
        <FiltersContainer
            style={{marginBottom: isMobile && '0px'}}
            searchElement={
                <Stack style={{width: isMobile && '100%'}}>
                    {<div style={{display: 'flex'}}>
                    {breadcrumbsContent.map((item)=> item.area === '/messaging' && 
                    item.breadcrumbs.map((breadcrumb)=>
                    breadcrumb.type === 'tour' ? breadcrumb.view === 'desktop' && !isMobile &&
                    <div style={{display: 'flex', width: 'auto', alignItems: 'center', cursor: 'pointer', marginRight: '22px'}} onClick={()=> window.Intercom('startTour', breadcrumb.id)}>
                        <img src={TourIcon} />
                        <span style={{color: '#5555FF', marginLeft: '5px', fontSize: '14px', whiteSpace: 'nowrap'}}>{breadcrumb.label}</span>
                    </div>
                    :
                    breadcrumb.view === 'desktop' &&
                    <div style={{display: 'flex', width: 'auto', alignItems: 'center', cursor: 'pointer', marginRight: '22px'}} onClick={()=> window.Intercom('showArticle', breadcrumb.id)}>
                        <img src={HelpLogo} />
                        <span style={{color: '#5555FF', marginLeft: '5px', fontSize: '14px', whiteSpace: 'nowrap'}}>{breadcrumb.label}</span>
                    </div>
                    ))}
                    </div>}
                    <Group style={{width: isMobile ? '100%' : '300px'}} noWrap position='apart'>
                        <SearchContainer
                        placeholder={`Inhalt, Empfänger`}
                        value={search}
                        onChange={this.handleSearchHandler}
                        disabled={false}
                        className={isMobile ? "mobile-annual-reading-search" : "annual-reading-search"}
                        />
                        {isMobile && 
                        <div>
                          {this.renderNewMessageButton()}
                        </div>}
                    </Group>
                </Stack>
            }
            sortElement={!isMobile && this.renderNewMessageButton()}
        />
        <div className="divider" style={{marginTop: isMobile && '0px'}}></div>
        <div>
          <Stack>
            {skeletonLoading ?
            [...Array(5)].map((e, i) => <Skeleton visible={this.state.skeletonLoading} style={{height: '98px', marginBottom: '8px', zIndex: '1'}}/>)
            :
            Object.keys(propertySelected).length > 0 ?
                (messagesList.length > 0 ? 
                <Stack style={{marginBottom: '40px'}}>
                    {messagesList
                    .sort((a,b)=> moment(a.updatedOn).isBefore(moment(b.updatedOn)))
                    .filter((message)=> search !== '' ? (message.subject?.toLowerCase().includes(search.toLowerCase()) //|| message.tenant.find((ten)=> ten.name.toLowerCase().includes(search.toLowerCase())) !== undefined 
                    || message.body?.toLowerCase().includes(search.toLowerCase())) : message)
                    .map((message)=>
                    <Group 
                    key={`message-${message.id}`} 
                    align='flex-start' 
                    className={'message-card'} 
                    noWrap={true} 
                    onClick={()=>
                    message.id === publishMessageId ? 
                    showNotification({
                        message: 'Diese Nachricht kann nicht geöffnet werden, da sie gerade versendet wird. Sie können in wenigen Minuten auf die Nachricht zugreifen, wenn der Sendevorgang abgeschlossen ist.',
                        icon: <img src={YellowTick} alt='in-progress'/>
                    }) 
                    :
                    message.status === 'Draft' ? 
                    this.props.history.push('/messaging/newMessage', {message: message, utilityBilling: utilityBillings.find((ub)=> ub.id === message.utilityBillingId)}) 
                    : 
                    openRecipientStatus === null &&
                    this.props.history.push('/messaging/messageDetail', {message: message, utilityBilling: utilityBillings.find((ub)=> ub.id === message.utilityBillingId)})}>
                        {/* {//message.tenant.length > 1 ?
                        // <Group spacing={message.tenant.length <= 4 ? 2/message.tenant.length : 2/4} noWrap={true}>
                        //     {message.tenant.map((ten, index)=>
                        //         index <= 3 && <img src={ten.picture} style={{minWidth: `${message.tenant.length <= 4 ? 64/message.tenant.length : 64/4}px`, width: `${message.tenant.length <= 4 ? 64/message.tenant.length : 64/4}px`}}/>
                        //     )}
                        // </Group>
                        // :
                        <Group>
                            <img src={Avatar} style={{minWidth: '64px', width: '64px', borderRadius: '50px', border: '1px solid #4949F5'}}/>
                        </Group>} */}
                        <Stack justify={'space-between'} style={{overflowX: 'hidden', textOverflow: 'ellipsis', width: '100%', height: '100%'}}>
                            <Group style={{width: '100%'}} position='apart' noWrap>
                                <Group align={'center'} style={{height: '24px', flex: '1 1 auto', overflowY: 'auto', overflowX: 'hidden'}} spacing={10} noWrap>
                                    <Group className={message.status === 'Failed' ? 'partner-list-row-tag-badge-failed' : 'partner-list-row-tag-badge'} noWrap spacing={5} style={{paddingRight: message.recipients.find((recipient)=> recipient.postalStatus === 'Failed' || recipient.emailStatus === 'Failed') !== undefined ? '2px' : '6px'}}>
                                        {message.status === 'Failed' ?
                                        <span>Fehler</span>
                                        :
                                        message.status === 'Draft' ?
                                          publishMessageId === message.id ?
                                          <Group spacing={2} noWrap>
                                              <span style={{whiteSpace: 'nowrap'}}>Wird gesendet</span>
                                              <Loader variant='dots' size={10} style={{marginTop: '5px'}} color='#5555FF' />
                                          </Group>
                                          :
                                          <span>Entwurf</span>
                                        :
                                        (message.status === 'Publish' || message.status === 'Sent') ?
                                        <span>Gesendet</span>
                                        : null}
                                        {message.recipients.find((recipient)=> recipient.postalStatus === 'Failed' || recipient.emailStatus === 'Failed') !== undefined &&
                                        <Popover
                                        opened={selectedMessageErrorPopover === message.id}
                                        placement={'end'}
                                        position={'top'}
                                        withArrow
                                        //transitionDuration={openRecipientStatus === null ? 1000 : 500}
                                        target={
                                          <Group 
                                          onMouseEnter={() => this.setState({selectedMessageErrorPopover: message.id})} 
                                          onMouseLeave={() => this.setState({selectedMessageErrorPopover: null})}>
                                            <img style={{minWidth: '16px'}} src={ExplanationMarkIcon}/>
                                          </Group>
                                        }
                                        >
                                          {message.status === 'Failed' ?
                                          <span style={{fontSize: '12px', fontWeight: '400'}}>Es ist ein Fehler bei der Übermittlung der Nachricht aufgetreten. Die Nachricht konnte nicht gesendet werden. Wenden Sie sich bei Fragen an den Kundenservice.</span>
                                          :
                                          <span style={{fontSize: '12px', fontWeight: '400'}}>Es ist ein Übertragungsfehler aufgetreten. Bitte prüfen Sie den Versandstatus und wenden Sie sich bei Fragen an den Kundenservice.</span>}
                                        </Popover>}
                                    </Group>
                                    {(message.subject !== null && message.subject !== '<p></p>' && message.subject !== '<p></p>\n' && message.subject.length !== 0) ? <span style={{maxWidth: isMobile && (window.innerWidth - (moment(message.updatedOn).format('DD.MM.YYYY') === moment().format('DD.MM.YYYY') ? 255 : 225))}} className={`message-card-title ${isMobile && 'message-card-title-mobile'}`} dangerouslySetInnerHTML={{__html: this.replaceValuesWithPlaceHolders(message.subject)}}></span> : <span style={{maxWidth: isMobile && (window.innerWidth - (moment(message.updatedOn).format('DD.MM.YYYY') === moment().format('DD.MM.YYYY') ? 255 : 225))}} className={`message-card-title ${isMobile && 'message-card-title-mobile'}`}>{'(kein Betreff)'}</span>}
                                    {!isMobile &&
                                    <Group spacing={10} style={{minWidth: '0px', overflowY: 'hidden'}} className='messages-list-recipients' noWrap>
                                        {message.recipients.map((recipient, index)=>
                                        recipient.name &&
                                        <Popover
                                            opened={openRecipientStatus === recipient.id}
                                            placement={'center'}
                                            position={'top'}
                                            withArrow
                                            transitionDuration={openRecipientStatus === null ? 1000 : 500}
                                            style={{marginLeft: index === 0 && '12px', marginRight: index === (message.recipients.length -1) && '12px'}}
                                            target={
                                              <Group 
                                              onClick={()=> message.status !== 'Draft' && message.status !== 'Failed' &&  this.setState({openPreviewModal: true, selectedRecipient: recipient.id, selectedMessage: message})} 
                                              onMouseEnter={() => message.status !== 'Draft' && this.setState({openRecipientStatus: recipient.id})} 
                                              onMouseLeave={() => message.status !== 'Draft' && this.setState({openRecipientStatus: null})}
                                              spacing={5} className='message-card-ten-cards' style={{height: '20.6px', maxHeight: '20.6px', paddingRight: message.status !== 'Draft' ? '3px' : '10px'}} noWrap>
                                                <Group style={{background: '#454576', width: '18px', minWidth: '18px', borderRadius: '120px'}} position='center' align={'center'} noWrap>
                                                    <span style={{textTransform: 'capitalize', color: 'white'}}>{recipient.name[0]}</span>
                                                </Group>
                                                <span style={{whiteSpace: 'nowrap'}}>{recipient.name}</span>
                                                {message.status !== 'Draft' && 
                                                <Group>
                                                  <img style={{minWidth: '16px'}} src={(message.status === 'Failed' || (recipient.isPostEnabled && (recipient.postalStatus === 'Failed' || recipient.postalStatus === 'Verarbeitungsfehler')) || (recipient.isEmailEnabled && recipient.emailStatus === 'Failed')) ? ExplanationMarkIcon : (recipient.isPostEnabled && recipient.postalStatus === 'Publish') ? YellowTick : GreenTick}/>
                                                </Group>}
                                              </Group>
                                            }
                                            >
                                              {message.status === 'Failed' ?
                                              <span style={{fontSize: '12px', fontWeight: '400'}}>Es ist ein Fehler bei der Übermittlung der Nachricht aufgetreten. Die Nachricht konnte nicht gesendet werden. Wenden Sie sich bei Fragen an den Kundenservice.</span>
                                              :
                                              <Stack 
                                              spacing={10} 
                                              style={{fontSize: '12px', fontWeight: '400'}}
                                              onMouseEnter={() => this.setState({openRecipientStatus: recipient.id})}
                                              onMouseLeave={() => this.setState({openRecipientStatus: null})}>
                                                <Stack spacing={10}>
                                                  {recipient.isPostEnabled && 
                                                  <Group spacing={5}>
                                                    <span>Briefversand:</span>
                                                    {recipient.postalStatus === 'Publish' ? 
                                                    <img src={YellowTick}/>
                                                    :
                                                    recipient.postalStatus === 'Sent' ? 
                                                    <img src={GreenTick}/>
                                                    :
                                                    recipient.postalStatus === 'Verarbeitungsfehler' ?
                                                    <img src={RedCross}/>
                                                    :
                                                    recipient.postalStatus === 'Failed' ?
                                                    <img src={RedCross}/>
                                                    :
                                                    recipient.postalStatus === 'Draft' && 
                                                    <img src={YellowTick}/>}
                                                    {recipient.postalStatus === 'Failed' ? <span>Übertragungsfehler</span> : ''}
                                                    {recipient.postalStatusMessage && <span>{recipient.postalStatusMessage}</span>}
                                                  </Group>}
                                                  {recipient.isEmailEnabled && 
                                                  <Group spacing={5}>
                                                    <span>Email-Versand:</span>
                                                    {recipient.emailStatus === 'Publish' ? 
                                                    <img src={YellowTick}/>
                                                    :
                                                    recipient.emailStatus === 'Sent' ? 
                                                    <img src={GreenTick}/>
                                                    :
                                                    recipient.emailStatus === 'Error' ?
                                                    <img src={RedCross}/>
                                                    :
                                                    recipient.emailStatus === 'Failed' ?
                                                    <img src={RedCross}/>
                                                    :
                                                    recipient.emailStatus === 'Draft' &&
                                                    <img src={YellowTick}/>}
                                                    {recipient.emailStatus === 'Failed' ? <span>Übertragungsfehler</span> : recipient.emailStatus === 'Sent' ? 'Nachricht übermittelt' : ''}
                                                    {/* <span>{'{{emailStatusMessage}}'}</span> */}
                                                  </Group>}
                                                  {recipient.isHiddenForTenantPortal === false && 
                                                  <Group spacing={5}>
                                                    <span>Mieterportal:</span>
                                                    <img src={GreenTick}/>
                                                    <span>Nachricht übermittelt</span>
                                                  </Group>}
                                                </Stack>
                                                <Group onClick={()=> this.setState({openPreviewModal: true, selectedRecipient: recipient.id, selectedMessage: message})}><span style={{color: '#5555FF', cursor: 'pointer'}}>Empfängernachricht anzeigen <img src={RightArrowPartner} style={{width: '12px', marginLeft: '2px'}}/></span></Group>
                                              </Stack>}
                                            </Popover>)}
                                    </Group>}
                                </Group>
                                <Group noWrap>
                                    {<span className='message-card-time'>{moment(message.updatedOn).format('DD.MM.YYYY') === moment().format('DD.MM.YYYY') ? `heute (${moment(moment.utc(message.updatedOn).toDate()).local().format('LT')} Uhr)` : moment(message.updatedOn).format('DD.MM.YYYY')}</span>}
                                    {!isMobile && <RecordOptions
                                    className="delete-message"
                                    showEditButton={false}
                                    toggleDeleteModal={() => this.setState({openMessageDeletionModal: true, selectedMessageIdForDeletion: message.id})}
                                    showLockButton={false}
                                    buttonStyle={{background: 'none', border: 'none', minWidth: '37px'}}
                                    />}
                                </Group>
                            </Group>
                            {(message.body !== null && message.body !== '<p></p>' && message.body !== '<p></p>\n' && message.body.length !== 0) ? <span className={'message-card-description'} dangerouslySetInnerHTML={{__html: this.replaceValuesWithPlaceHolders(message.body)}}/> : <span className={'message-card-description'}>{'(keine Nachricht)'}</span>}
                        </Stack>
                    </Group>
                    )}
                    {fetchLoader &&
                    <Group style={{width: '100%'}} position='center'>
                        <Loader variant="dots" size={'md'} color={'#5555FF'}/>
                    </Group>}
                </Stack>
                :
                <EmptyStateContainer
                heading='Sie haben noch keine Nachrichten erstellt.'
                subheading={'Erstellte und gesendete Nachrichten werden hier angezeigt.'}
                icon={MessagesIcon}
                btnText={'Nachricht erstellen'}
                btnHandler={()=> this.props.history.push('/messaging/newMessage')}//this.setState({openMessageTemplateModal: true})}
                />)
            :
            <EmptyStateContainer
            heading='Sie haben noch keine Immobilie angelegt.'
            subheading={'Erstellte und gesendete Nachrichten werden hier angezeigt.'}
            icon={MessagesIcon}
            btnText={'Immobilie hinzufügen'}
            btnHandler={()=> this.props.history.push('/property')}//this.setState({openMessageTemplateModal: true})}
            />
            }
            
          </Stack>
        </div>
        {openMessageTemplateModal &&
        <MessageTemplatesModal
        open={openMessageTemplateModal}
        toggle={()=> this.setState({openMessageTemplateModal: false})}
        openNewMessage={()=> this.props.history.push('/messaging/newMessage')}
        className={'template-modal'}
        />}
        {openMessageDeletionModal &&
        <MessageDeleteModal
        open={openMessageDeletionModal}
        toggle={()=> this.setState({openMessageDeletionModal: false, selectedMessageIdForDeletion: null})}
        onDelete={()=> this.deleteMessagesById(selectedMessageIdForDeletion)}
        />}

        {openPreviewModal &&
        <MessagePostviewModal
        open={openPreviewModal}
        toggle={()=> this.setState({openPreviewModal: false, selectedRecipient: null, selectedMessage: null})}
        isMobile={isMobile}
        allRecipients={selectedMessage.recipients}
        messageData={selectedMessage}
        senderAddress={(!accountData.addressCompany || !accountData.addressStreet || !accountData.addressZipCode || !accountData.addressCity) ? <span style={{color: '#dc3545', fontWeight: '500'}}>Absenderadresse unvollständig</span> : ` ${accountData.addressCompany+','} ${accountData.addressStreet+','} ${accountData.addressZipCode} ${accountData.addressCity}`}
        senderEmail={accountData.companyEmail}
        billingPeriod={null}
        selectedRecipient={selectedRecipient}
        />}

        {openMessageTypeSelectionModal &&
        <SelectMessageTypeModal
        open={openMessageTypeSelectionModal}
        toggle={()=> this.setState({openMessageTypeSelectionModal: false})}
        createNewMessage={(messageType)=> this.props.history.push('/messaging/newMessage', {messageType})}
        />}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
    return {
        currentDataId: state.UtilityBillingReducer.currentBillingId,
        propertySelected: state.generalReducer.property,
        rentalUnits: state.tenantDataReducer.rentalUnits,
        currentSubscription: state.generalReducer.currentSubscription,
        accountData: state.generalReducer.accountData,
        profile: state.generalReducer.profile,
        refreshMessagesData: state.generalReducer.refreshMessagesData,
        publishMessageId: state.generalReducer.publishMessageId
    };
};

const actions = {
    setPublishMessageId, 
    setRefreshMessagesData
}
export default connect(mapStateToProps, actions)(MessagesList);
