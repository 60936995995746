import React, { Component } from 'react';
import Autoload from '../../../../neoverv/Autoload';
import { connect } from 'react-redux';
import { showNotification } from '@mantine/notifications';
import CopyIcon from '../../../../neoverv/components/SVGs/copyIcon';
import { Group, Stack } from '@mantine/core';
import TenantAccessDetails from './TenantAccessDetails';
import TenantAdministrateConsumption from './TenantAdministrateConsumption';
import TenantPortalPreview from './TenantPortalPreview';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
const MOBILE_SIZE = 992;
const TABLET_SIZE = 1420;

export class TenantSingleAdministration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: window.innerWidth <= MOBILE_SIZE,
      isTablet: window.innerWidth <= TABLET_SIZE
    };

  }

  componentDidMount = () => {
    window.addEventListener('resize', this.handleResize);
  };

  handleResize = () => {
    if (window.innerWidth <= MOBILE_SIZE) {
      this.setState({ isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
    if (window.innerWidth <= TABLET_SIZE) {
      this.setState({ isTablet: true });
    } else {
      this.setState({ isTablet: false });
    }
  };
  
  render() {
    const {isMobile, isTablet} = this.state;
    return (
        <>
        <Stack align={'flex-start'} style={{
          //background: 'red'
          }}>
            <Group grow style={{width: '100%'}} position='apart'>
                <TenantAccessDetails isMobile={isMobile} isTablet={isTablet} />
            </Group>
            {/* <Group grow position='left' style={{width: '100%'}}>
                <TenantAdministrateConsumption isMobile={isMobile} isTablet={isTablet} />
            </Group> */}
            <Group grow position='left' style={{width: '100%'}}>
                <TenantPortalPreview isMobile={isMobile} isTablet={isTablet} />
            </Group>
            {/* <Group grow position='left' style={{width: '100%'}}>
                
            </Group> */}
        </Stack>
        </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    propertySelected: state.generalReducer.property,
  };
};
export default connect(mapStateToProps)(TenantSingleAdministration);
