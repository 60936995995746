import * as ActionTypes from '../../types/meter';

const initialState=[]

export const getReadingReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_METER_READING:
      return state

    case ActionTypes.DELETE_METER_READING:
      let innerFilter = []
      let filtered = [...state.map(item => {
          innerFilter=item.data.filter(record=>{
            return record.id!=action.payload
          })
          return  {...item,data:innerFilter}
        })
      ]
      return filtered

    case ActionTypes.ADD_METER:
      let unitArr;
      let tenArr;
      const { addData, billingId, unitId, tenantId } = action.payload;
      addData.map((data,i)=>{
        data.id=addData.length+1
      })
      let finalState = state.map(item => {
        if (item.id == billingId) {
          unitArr= item.tableData.map(record => {
            if (record.unit.id == unitId) {
              tenArr= record.unit.tenant.map(ten => {
                if (ten.id == tenantId) {
                  let dataArr= [...ten.data, ...addData]

                  return {...ten,data:dataArr}
                }
                else 
                return ten
              })
              return {...record.unit,tenant:tenArr}
            } else{
              return record.unit
            }
          })
          return
        }
      })
      return finalState
    default:
      return state
  }
}
