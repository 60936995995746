import React, { useState } from 'react';
import { Accordion, useAccordionToggle, Badge } from 'react-bootstrap';
import { ColorButton, HeadingContainer, MeterReadingRecord } from '../../../../index.js';
import Autoload from '../../../../Autoload';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];

const MeterReadingToggle = ({ data, history, match }) => {
  const [panelIndex, setPanelIndex] = useState();

  //clickable accordion
  const CustomToggle = ({ children, eventKey }) => {
    // Decorate the click event to also set state in the component so we can
    // compare the open panel key with the state and toggle classes accordingly
    const customOnClick = useAccordionToggle(eventKey, () => {
      setPanelIndex(eventKey === panelIndex ? null : eventKey);
      // console.log('worked', panelIndex)
    });
    const customClass = eventKey === panelIndex ? 'open-class' : 'closed-class';
    return (
      <div className={`meter-reading-item ${customClass}`} onClick={customOnClick}>
        {children}
      </div>
    );
  };

  //each data item recieved
  const ToggleItems = () => {
    return data.map((item, index) => {
      //setting badge and its style based on date
      let badgeText = '';
      let badgeStyle = '';
      const itemYear = Number('20' + item.date.split('-')[0].split('.')[2]);
      const currentYear = new Date().getFullYear();
      const dateDiff = currentYear - itemYear;
      // console.log('item',itemYear,typeof itemYear, 'current', currentYear, typeof currentYear, 'diff', dateDiff, typeof dateDiff)
      switch (dateDiff) {
        case 1:
          badgeText = lang.receipt.text_current_billing_period;
          badgeStyle = 'white';
          break;
        case 2:
          badgeText = lang.receipt.text_last_billing_period;
          badgeStyle = 'purple';
          break;

        default:
          break;
      }

      return (
        <div key={index}>
          <CustomToggle eventKey={index + 1}>
            <HeadingContainer
              className="meter-reading-heading"
              heading={item.date}
              rightContent={
                <div className="btn-container">
                  <ColorButton
                    onClick={() => Autoload.MainHelper.redirection('/meter/reading/annual')}
                    text="Annual reading"
                    className="purple-btn mr-1 annual-btn"
                  />
                  <ColorButton
                    onClick={() => Autoload.MainHelper.redirection('/meter/reading/interium')}
                    text="Interium reading"
                    className="gray-btn interium-btn"
                  />
                </div>
              }
              badge={
                <Badge variant="info" className={`year-badge ${badgeStyle}`}>
                  {badgeText}
                </Badge>
              }
            />
          </CustomToggle>
          <Accordion.Collapse eventKey={index + 1}>
            <MeterReadingRecord
              className="meter-reading-table"
              data={item.tableData}
              labels={['Rental unit', 'Tenant', 'Occupation period', 'Status', 'Date', '']}
            />
          </Accordion.Collapse>
          {data.length !== index + 1 && <div className="divider"></div>}
        </div>
      );
    });
  };
  // console.log(history)
  return <Accordion>{data.length > 0 && ToggleItems()}</Accordion>;
};

MeterReadingToggle.defaultProps = {
  data: [],
};

export default MeterReadingToggle;
