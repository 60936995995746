import React, { Component } from 'react';
import { HeadingContainer, ColorButton, Divider, MeterModal, EmptyStateContainer } from '../../../../neoverv';
import moment from 'moment';
import { Form } from 'react-bootstrap';
import CrossIcon from '../../../../assets/images/module/general/CrossIcon.svg';
import ReadingRecord from '../../../../neoverv/components/Module/General/ReadingRecord/ReadingRecord';
import {
  toEditMeterReading,
  dataFilteration,
  addInterimReading,
  editInterimReading,
} from '../../../../redux/action/Meter/meterActions';
import { connect } from 'react-redux';
import Autoload from '../../../../neoverv/Autoload';
import MobileInterimReading from './MobileInterimReading';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import downArrow from '../../../../assets/images/module/general/SelectArrowDown.svg';
import uparrow from '../../../../assets/images/module/general/SelectArrowUp.svg';
import GlobalServices from '../../../../neoverv/helpers/services/GlobalServices';
import GeneralDataHandler from '../../../../neoverv/helpers/DataHandler/General';
import MeterDataHandler from '../../../../neoverv/helpers/DataHandler/Meter';
import meterIcon from '../../../../assets/images/module/general/MeterIcon.svg';
import Icon from "../../../../components/Icon";
import { Group, TextInput } from '@mantine/core';
const { checkProperty, checkEmpty } = Autoload.MainHelper;

var Setting = Autoload.Setting;

var lang = Setting[Setting.language_default];

let submitRecord = [];
const MOBILE_SIZE = 992;
const SMALLER_MOBILE_SIZE = 500
export class InterimReading extends Component {
  initialState = {
    DropdownState: 'true',
    DropdownStateTwo: 'true',
    isMobile: window.innerWidth <= MOBILE_SIZE,
    isSmallerMobile: window.innerWidth <= SMALLER_MOBILE_SIZE,
    date: new Date(),
    currentUnit: null,
    currentUnitName: '',
    currentTenant: null,
    currentTenantName: '',
    openMeterModal: false,
    meter: null,
    dataToShow: [],
    add: false,
    rentalUnitsArr: [],
    tenantArr: [],
    filteredTenantArr: [],
    occasions: ['Move in', 'Move out', 'Annual'],
    validated: false,
    noValidate: true,
    defaultOccasion: null,
    occupancyDate: '',
    allocationKeys: [],
    tenantOccupationReadings: null,
    disableUpdation: true,
    disableRadioSelection: {
      disableMoveIn: true,
      disableAnnual: true,
      disableMoveOut: true,
    },
    search: ''
  };
  constructor(props) {
    super(props);
    this.state = this.initialState;
    this.urlBillingId = null;
    this.urlOccupationReadingId = null;
    this.urlOccupationDate = null;
    this.errorRef = React.createRef();
  }
  renderButtons = () => {
    const {isMobile, disableUpdation} = this.state;
    return (
    <div className="btn-container">
      <ColorButton
        onClick={() => {
          this.goBack();
        }}
        text={lang.general.text_cancel}
        className="gray-btn cancel-btn"
        buttonStyle={{
          maxWidth: isMobile && '100px'
        }}
      />
      <ColorButton
        onClick={() => this.saveChanges('meterForm')}
        text={lang.meter.text_save}
        form="meterForm"
        // type="submit"
        type="button"
        className={!isMobile && "purple-btn mr-1 save-btn"}
        name="submit-1"
        buttonStyle={{
          maxWidth: isMobile && '100px',
          background: isMobile && "linear-gradient(180deg, #5555FF 0%, #4949F5 100%)"
        }}
        textStyle={{
          color: isMobile && '#FFFFFF'
        }}
        buttonConfig={{ disabled: disableUpdation }}
      />
    </div>
    );
  }

  renderErrorMessage(errorMessage) {
    Autoload.DataHandlerGeneral.GeneralErrorMessage(true, errorMessage);
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'none';
  }

  getOccupationPeriodDate(periodStart, periodEnd) {
    const occupationStartDate = moment(periodStart, 'YYYY-MM-DD').format('DD.MM.YYYY');
    const occupationEndDate = moment(periodEnd, 'YYYY-MM-DD').format('DD.MM.YYYY');
    return occupationStartDate + ' - ' + occupationEndDate;
  }

  handleResize = () => {
    if (window.innerWidth <= MOBILE_SIZE) {
      this.setState({ isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
  };

  componentDidMount() {
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'block';
    this.handleResize();
    let filteredArray = [];
    let field = 'meterNumber';
    filteredArray = this.state.dataToShow.sort((a, b) =>
      (a[field] || '').toString().localeCompare((b[field] || '').toString())
    );
    this.setState({
      dataToShow: filteredArray,
      // filteredTenantArr: sortedRentalUnitarray,
    });
    window.addEventListener('resize', this.handleResize);
    const billingId = Autoload.Utils.getUrlParameterByName('billingId');
    const occupationreadingsid = Autoload.Utils.getUrlParameterByName('occupationreadingsid');
    const occupancyDate = Autoload.Utils.getUrlParameterByName('occupationDate');
    this.urlOccupationDate = occupancyDate;
    this.urlBillingId = billingId;
    this.urlOccupationReadingId = occupationreadingsid;

    if (billingId === '' && occupationreadingsid === '') {
      // debugger;
      // Autoload.Redirection.redirection('/meter/reading/');
    }
    if (occupationreadingsid === 'c-r-0') {
      this.setState({ billingID: this.urlBillingId });
    } else {
      this._getMeterOccupationReading(occupationreadingsid);
    }
    GlobalServices._getRentalUnitsByProperty(this, '_handleRentalUnitResponse', this.props.propertySelected); //dropdown rental units
    GlobalServices._getAllocationKeysByProperty(this, '_handle_allocation_keys_response', this.props.propertySelected);
  }

  //@desc rental units response handler
  _handleRentalUnitResponse(response) {
    if (response.status === true && response.statuscode === 401) {
      localStorage.clear();
      window.location.reload();
    }

    if (response.status === true && response.statuscode === 200) {
      const rentalUnits = GeneralDataHandler.getRantalUnits(response.result);
      this.setState(
        {
          rentalUnitsArr: rentalUnits.sort((a, b) =>
            a.name.toLowerCase() > b.name.toLowerCase() ? 1 : b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 0
          ),
          currentUnitName: rentalUnits.length > 0 ? rentalUnits[0].name : null,
          currentUnit: rentalUnits.length > 0 ? rentalUnits[0].id : null,
        },
        () => this._getTenantsInBillingPeriods() // get tenant dropdown data
      );
    } else {
      const loader = document.getElementById('hello-housing-loader');
      loader.style.display = 'none';
      this.ShowLoader();
    }
  }

  //@desc allocations keys response handler
  _handle_allocation_keys_response(response) {
    if (response.status === true && response.statuscode === 401) {
      localStorage.clear();
      window.location.reload();
    }

    if (response.status === true && response.statuscode === 200) {
      const allocationKeys = GeneralDataHandler.getAllocationKeys(response.result);
      this.setState({ allocationKeys: allocationKeys });
    }
  }

  _getTenantsInBillingPeriods() {
    const propertyid = this.props.propertySelected && this.props.propertySelected.id;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url +
      'Tenants/TenantsInBillingPeriod?propertyId=' +
      propertyid +
      '&billingPeriodId=' +
      this.urlBillingId,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      '_handleTenantsResponse',
      null,
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  //@desc tenants in billing periods response handler
  _handleTenantsResponse(response) {
    // console.log(response, 'tenant response, api 1 ');
    if (response.status === true && response.statuscode === 401) {
      localStorage.clear();
      window.location.reload();
    }

    if (response.status === true && response.statuscode === 200) {
      const tenantsBillingPeriods = MeterDataHandler.getTenantsInBillingPeriods(response.result);
      if (tenantsBillingPeriods && tenantsBillingPeriods.length === 0) {
        this.renderErrorMessage(lang.newKeys.text_No_tenants_found_for_rental_unit);
        return null;
      }
      if (this.urlOccupationReadingId === 'c-r-0') {
        setTimeout(() => {
          const filteredTenants = tenantsBillingPeriods.filter(
            (tenantBillPeriod) => tenantBillPeriod.tenant.rentalUnitId === this.state.currentUnit
          );
          const currentTenantState = {
            currentTenantName: filteredTenants.length > 0 ? filteredTenants[0].tenant.fullName : null,
            currentTenant: filteredTenants.length > 0 ? filteredTenants[0].tenant.id : null,
          };
          this.setState({
            tenantArr: tenantsBillingPeriods,
            filteredTenantArr: filteredTenants,
            ...currentTenantState,
          });
          // this._getOccupationReadingTenants(tenantsBillingPeriods[0].tenant.id);
          this._getOccupationReadingTenants(currentTenantState && currentTenantState.currentTenant);
        }, 150);
      } else {
        this.setState({
          tenantArr: tenantsBillingPeriods,
          filteredTenantArr: tenantsBillingPeriods,
        });
      }
    }
  }

  _getOccupationReadingTenants(tenantId) {
    const propertyid = this.props.propertySelected && this.props.propertySelected.id;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.meter_api_base_url +
      'occupationreadings/occupationReadingForTenant?TenantId=' +
      tenantId +
      '&BillingPeriodId=' +
      this.urlBillingId +
      '&PropertyId=' +
      propertyid,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      '_handleOccupationReadingTenantResponse',
      null,
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  _handleOccupationReadingTenantResponse(response) {
    console.log(response, 'occupation api meter');
    if (response.status === true && response.statuscode === 401) {
      localStorage.clear();
      window.location.reload();
    }

    if (response.status === true && response.statuscode === 200) {
      const tenantOccupationReadings = MeterDataHandler.getTenantOccupationReadings(response.result);
      if (tenantOccupationReadings) {
        const startOccasion = tenantOccupationReadings.periodStartReading.occasion;
        const endOccasion = tenantOccupationReadings.periodEndReading.occasion;
        const occupancyDate = this.getOccupationPeriodDate(
          tenantOccupationReadings.periodStart,
          tenantOccupationReadings.periodEnd
        );
        const disableRadioSelection = {
          disableMoveIn:
            tenantOccupationReadings.periodStartReading.occasion === 'move-in' ||
              tenantOccupationReadings.periodEndReading.occasion === 'move-in'
              ? false
              : true,
          disableAnnual:
            tenantOccupationReadings.periodStartReading.occasion === 'annual' ||
              tenantOccupationReadings.periodEndReading.occasion === 'annual'
              ? false
              : true,
          disableMoveOut:
            (tenantOccupationReadings.periodStartReading.occasion === 'move-out' && tenantOccupationReadings.tenantFullName !== '(leer)') ||
              (tenantOccupationReadings.periodEndReading.occasion === 'move-out' && tenantOccupationReadings.tenantFullName !== '(leer)')
              ? false
              : true,
        };
        this.setState(
          {
            tenantOccupationReadings,
            occupancyDate,
            defaultOccasion: tenantOccupationReadings.tenantFullName === '(leer)' ? 'annual' : startOccasion || endOccasion,
            disableRadioSelection,
          },
          () => this.updateOccasion()
        );

        // document.getElementById('errorattop').style.display = 'none';
        console.log('response _handleOccupationReadingTenantResponse', tenantOccupationReadings);
      } else {
        this.renderErrorMessage(lang.newKeys.text_error_Tenant_readings_not_found);
        this.setState({ disableUpdation: true, dataToShow: [] });
      }
    } else {
      this.renderErrorMessage(lang.newKeys.text_error_no_reading_found);
      this.setState({ disableUpdation: true, dataToShow: [] });
    }
    this.ShowLoader();
    console.log(this.state.dataToShow, 'data to show meter 1');
  }

  updateOccasion() {
    const { defaultOccasion, tenantOccupationReadings } = this.state;
    console.log('tenantOccupationReadings', tenantOccupationReadings);
    let readingList = [];
    let readingType = null;
    if (defaultOccasion === 'move-in' || defaultOccasion === 'Move in') {
      const { periodStartReading = null, periodEndReading = null } = tenantOccupationReadings;
      if (checkProperty(periodStartReading, 'occasion') === 'move-in') {
        readingType = periodStartReading;
      } else {
        readingType = periodEndReading;
      }
      // readingType = tenantOccupationReadings.periodStartReading.occasion === 'move-in' ? tenantOccupationReadings.periodStartReading : tenantOccupationReadings.periodEndReading;
      if (readingType && readingType.meterReadings && readingType.meterReadings.length > 0) {
        readingList = readingType.meterReadings.map((data, index) => ({
          ...data,
          ...MeterDataHandler.generateReadingRow(data),
          editStartValue: data.startValue === null ? true : false,
        }));
        readingList.map((reading, index) => {
          readingList[index].id = readingList[index].testId;
        });
        this.setState({ dataToShow: readingList, disableUpdation: false });
        readingList = [];
        document.getElementById('errorattop').style.display = 'none';
      } else {
        // this.renderErrorMessage(lang.newKeys.text_error_no_meter_reading);
        this.setState({ dataToShow: readingList, disableUpdation: true });
      }
    } else if (defaultOccasion === 'annual' || defaultOccasion === 'Annual') {
      // readingType = tenantOccupationReadings && tenantOccupationReadings.periodEndReading;
      const { periodStartReading = null, periodEndReading = null } = tenantOccupationReadings;
      if (checkProperty(periodStartReading, 'occasion') === 'annual') {
        readingType = periodStartReading;
      } else {
        readingType = periodEndReading;
      }
      // readingType = tenantOccupationReadings.periodStartReading.occasion === 'annual' ? tenantOccupationReadings.periodStartReading : tenantOccupationReadings.periodEndReading;
      if (readingType && readingType.meterReadings && readingType.meterReadings.length > 0) {
        readingList = readingType.meterReadings.map((data, index) => ({
          ...data,
          ...MeterDataHandler.generateReadingRow(data),
          editStartValue: data.startValue === null ? true : false,
        }));
        readingList.map((reading, index) => {
          readingList[index].id = readingList[index].testId;
        });
        this.setState({ dataToShow: readingList, disableUpdation: false });
        readingList = [];
        document.getElementById('errorattop').style.display = 'none';
      } else {
        // this.renderErrorMessage(lang.newKeys.text_error_no_meter_reading);
        this.setState({ dataToShow: readingList, disableUpdation: true });
      }
    } else if (defaultOccasion === 'move-out' || defaultOccasion === 'Move out') {
      // readingType = tenantOccupationReadings.periodStartReading.occasion === 'move-out' ? tenantOccupationReadings.periodStartReading : tenantOccupationReadings.periodEndReading;
      // readingType = tenantOccupationReadings && tenantOccupationReadings.periodEndReading;
      const { periodStartReading = null, periodEndReading = null } = tenantOccupationReadings;
      if (checkProperty(periodStartReading, 'occasion') === 'move-out') {
        readingType = periodStartReading;
      } else {
        readingType = periodEndReading;
      }
      if (readingType && readingType.meterReadings && readingType.meterReadings.length > 0) {
        readingList = readingType.meterReadings.map((data, index) => ({
          ...data,
          ...MeterDataHandler.generateReadingRow(data),
          editStartValue: data.startValue === null ? true : false,
        }));
        readingList.map((reading, index) => {
          readingList[index].id = readingList[index].testId;
        });
        this.setState({ dataToShow: readingList, disableUpdation: false });
        readingList = [];
        document.getElementById('errorattop').style.display = 'none';
      } else {
        // this.renderErrorMessage(lang.newKeys.text_error_no_meter_reading);
        this.setState({ dataToShow: readingList, disableUpdation: true });
      }
    }
    console.log(this.state.dataToShow, 'data to show meter 2');
  }

  _getMeterOccupationReading(occupationreadingsid) {
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.meter_api_base_url + 'occupationReadings/' + occupationreadingsid,
      // Autoload.Setting.swagger_mocked_api_baseUrl + 'occupationReadings/'+occupationreadingsid,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      '_handlegetMeterOccupationReadingResponse',
      null,
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  _handlegetMeterOccupationReadingResponse(response) {
    console.log(response, '_handlegetMeterOccupationReadingResponse');
    if (response.status === true && response.statuscode === 401) {
      localStorage.clear();
      window.location.reload();
    }

    if (response.status === true && response.statuscode === 200) {
      const readingsData = MeterDataHandler.getMeterOccupationReading(response.result);
      if (readingsData && Object.keys(readingsData).length > 0) {
        let readingList = [];
        const occupationPeriod = Autoload.Utils.getUrlParameterByName('period');
        let readingType = null;
        readingType = occupationPeriod === 'start' ? readingsData.periodStartReading : readingsData.periodEndReading;
        if (readingType && readingType.meterReadings && readingType.meterReadings.length > 0) {
          readingType.meterReadings.map((data, index) => {
            const editStartValue = data.startValue === null ? true : false;
            readingList.push({
              ...data,
              ...MeterDataHandler.generateReadingRow(data),
              editStartValue,
            });
            return null;
          });
        } else {
          // this.renderErrorMessage(lang.newKeys.text_error_no_meter_reading);
        }
        const occupancyDate = this.getOccupationPeriodDate(readingsData.periodStart, readingsData.periodEnd);
        const disableRadioSelection = {
          disableMoveIn:
            readingsData.periodStartReading.occasion === 'move-in' ||
            readingsData.periodEndReading.occasion === 'move-in',
          disableAnnual:
            readingsData.periodStartReading.occasion === 'annual' || readingsData.periodEndReading.occasion === 'annual'
              ? false
              : true,
          disableMoveOut:
            (readingsData.periodStartReading.occasion === 'move-out' && readingsData.tenantFullName !== '(leer)') ||
              (readingsData.periodEndReading.occasion === 'move-out' && readingsData.tenantFullName !== '(leer)')
              ? false
              : true,
        };
        // console.log('readingsData', readingsData);
        this.setState({
          defaultOccasion: readingType && readingType.occasion,
          billingID: readingsData.billingPeriodId,
          currentUnitName: readingsData.rentalUnitName,
          currentUnit: readingsData.rentalUnitId,
          currentTenant: readingsData.tenantId,
          currentTenantName: readingsData.tenantFullName,
          tenantOccupationReadings: readingsData,
          occupancyDate,
          dataToShow: readingList,
          disableRadioSelection,
          disableUpdation: readingList.length > 0 ? false : true,
        });
        var loader = document.getElementById('hello-housing-loader');
        loader.style.display = 'none';
        var dataDiv = document.getElementById('respond-interium-reading-page');
        dataDiv.style.display = 'block';
        // document.getElementById('errorattop').style.display = 'none';
        // }
        // else {
        //   this.renderErrorMessage(lang.newKeys.text_error_no_meter_reading);
        // }
      }
    } else {
      this.renderErrorMessage(lang.newKeys.text_error_no_occupation_reading);
    }
  }

  goBack = () => {
    // Autoload.MainHelper.redirection('/meter/reading')
    // Autoload.Redirection.redirection('/meter/reading/');
    this.props.history.push('/meter/reading');
  };

  onRecordInputChange = (field, value, row) => {
    let newOccupationReading = this.state.tenantOccupationReadings;
    if (
      (this.state.defaultOccasion === 'move-in' || this.state.defaultOccasion === 'Move in') &&
      field === 'currentValue'
    ) {
      const endIndex = newOccupationReading.periodEndReading.meterReadings?.findIndex(
        (o) => o.meter.meterId === row.meter.meterId
      );
      const startIndex = newOccupationReading.periodStartReading.meterReadings?.findIndex(
        (o) => o.meter.meterId === row.meter.meterId
      );
      if (endIndex !== -1) {
        newOccupationReading.periodEndReading.meterReadings[endIndex].startValue = value;
        this.setState({ tenantOccupationReadings: newOccupationReading });
      }
      if (endIndex !== -1) {
        newOccupationReading.periodStartReading.meterReadings[startIndex].currentValue = value;
        this.setState({ tenantOccupationReadings: newOccupationReading });
      }
    }
    console.log(this.state.tenantOccupationReadings);
    console.log(field, value, row, 'on record input change');
    submitRecord = this.state.dataToShow.map((reading) => {
      if (reading.id === row.id) {
        let consumption = null;
        if (field === 'currentValue') {
          const remValue = value - reading.startValue;
          // this.state.defaultOccasion === 'move-in'
          //   ? (consumption = '-')
          //   :
          consumption = value == null || value == '' || value == 0 ? '' : remValue;
        } else if (field === 'startValue') {
          const remValue = reading.currentValue - value;
          // this.state.defaultOccasion === 'move-in'
          //   ? (consumption = '-')
          //   :
          consumption =
            reading.currentValue == 0 || reading.currentValue == null || reading.currentValue == '' ? '' : remValue;
        }
        return { ...reading, [field]: value, consumption };
      }
      return reading;
    });
    this.setState({ dataToShow: submitRecord });
    // console.log(submitRecord, this.state.defaultOccasion, 'submitRecord');
    console.log(this.state.dataToShow, 'data to show meter 3');
  };

  onReadingDateChange = (field, value, row) => {
    submitRecord = this.state.dataToShow.map((reading) => {
      if (reading.id === row.id) {
        return { ...reading, readingDate: moment(value).format('DD.MM.YYYY') };
      }
      return reading;
    });
    this.setState({ dataToShow: submitRecord });
    console.log(this.state.dataToShow, 'data to show meter 4');
  };

  validateData() {
    const { dataToShow, currentUnit, currentTenant } = this.state;
    if (currentUnit === null) {
      this.renderErrorMessage(lang.newKeys.text_error_invalid_rental_unit);
      return false;
    } else if (currentTenant === null) {
      // this.renderErrorMessage('Tenant for rental unit is not valid');
      this.renderErrorMessage(lang.newKeys.text_error_invalid_rental_unit);
      return false;
    } else if (dataToShow && dataToShow.length === 0) {
      // this.renderErrorMessage('No meter readings found');
      // return false;
      return true;
    } else if (dataToShow && dataToShow.length > 0) {
      let isValidated = false;
      for (let x = 0; x < dataToShow.length; x++) {
        console.log('consumption', dataToShow[x].consumption);
        // if (
        //   checkProperty(dataToShow[x], 'currentValue') == null ||
        //   checkProperty(dataToShow[x], 'currentValue') == '' ||
        //   checkProperty(dataToShow[x], 'currentValue') == 0
        // ) {
        //   this.renderErrorMessage('Please enter valid current value');
        //   isValidated = false;
        //   return false;
        // } else
        if (
          // checkProperty(dataToShow[x], 'consumption') == null ||
          // checkProperty(dataToShow[x], 'consumption') == '' ||
          // checkProperty(dataToShow[x], 'consumption') == 0 ||
          checkProperty(dataToShow[x], 'consumption') < 0
        ) {
          this.renderErrorMessage(lang.newKeys.text_error_Consumption_value);
          isValidated = false;
          return false;
        } else {
          isValidated = true;
          // return true;
        }
      }
      // dataToShow.map((dataRow) => {
      //   if (checkProperty(dataRow, 'currentValue') == null || checkProperty(dataRow, 'currentValue') == "" || checkProperty(dataRow, 'currentValue') == 0) {
      //     this.renderErrorMessage('Please enter valid current value');
      //     isValidated = false;
      //     return false;
      //   }
      //   // else if( checkProperty(dataRow, 'startValue') === '' || checkProperty(dataRow, 'startValue') === 0 ) {
      //   //   this.renderErrorMessage('Please enter valid start value');
      //   //   isValidated = false;
      //   // }
      //   // else if( checkProperty(dataRow, 'consumption') === '' || checkProperty(dataRow, 'consumption') === 0 ) {
      //   //   this.renderErrorMessage('Please enter valid consumption value');
      //   //   isValidated = false;
      //   // }
      //   else {
      //     console.log('else')
      //     isValidated = true;
      //     return true;
      //   }
      // });
      return isValidated;
    } else return false;
  }

  saveChanges = async (event) => {
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'block';
    const isValidInput = this.validateData();
    const { dataToShow, currentUnit, defaultOccasion, tenantOccupationReadings } = this.state;
    // console.log('dataToShow', dataToShow, isValidInput);
    if (isValidInput) {
      Autoload.DataHandlerGeneral.GeneralErrorMessage(false);
      let meterReadingId = null;
      if (defaultOccasion === 'move-in' || defaultOccasion === 'Move in')
        meterReadingId = tenantOccupationReadings.periodStartReading.id;
      else if (defaultOccasion === 'annual' || defaultOccasion === 'Annual')
        meterReadingId = tenantOccupationReadings.periodEndReading.id;
      else if (defaultOccasion === 'move-out' || defaultOccasion === 'Move out')
        meterReadingId = tenantOccupationReadings.periodEndReading.id;
      // const date = this.urlOccupationDate.split('.')
      let APIPayload = {
        id: meterReadingId,
        billingPeriodId: this.urlBillingId,
        propertyId: this.props.propertySelected.id,
        rentalUnitId: currentUnit,
        occasion: defaultOccasion,
        // occasionDate: moment(this.urlOccupationDate, 'YYYY.DD.MM').format('YYYY-MM-DD'),
        occasionDate: '2021-08-11T00:00:00',
        // occasionDate: this.urlOccupationDate,
        meterReadings: [],
      };
      // debugger;
      dataToShow.map((dataRow) => {
        APIPayload.meterReadings.push(this.generateReadingRow(dataRow));
      });
      console.log(APIPayload);
      // debugger;
      this.updateReadings(APIPayload);
    }
  };

  generateReadingRow(dataRow) {
    console.log(dataRow, 'data row generate reading row');
    const { defaultOccasion, tenantOccupationReadings } = this.state;
    let readingPeriodType = null;

    if (defaultOccasion === 'move-in' || defaultOccasion === 'Move in')
      readingPeriodType = tenantOccupationReadings.periodStartReading;
    else if (defaultOccasion === 'annual' || defaultOccasion === 'Annual')
      readingPeriodType = tenantOccupationReadings.periodEndReading;
    else if (defaultOccasion === 'move-out' || defaultOccasion === 'Move out')
      readingPeriodType = tenantOccupationReadings.periodEndReading;

    const payload = {
      id: dataRow.id,
      meter: {
        meterId: dataRow.meter.id,
        propertyId: dataRow.meter.propertyId, //this.props.propertySelected.id,
        rentalUnitId: dataRow.meter.rentalUnitId,
        propertyMeter: dataRow.meter.propertyMeter,
        rentalUnitName: dataRow.meter.rentalUnitName,
        meterNo: dataRow.meter.meterNo,
        location: dataRow.meter.location,
        allocationKey: {
          ...dataRow.meter.allocationKey,
          allocationKeyId: dataRow.meter.allocationKey.allocationKeyId,
          allocationKeyName: dataRow.meter.allocationKey.allocationKeyName,
          // "propertyId": 2,
          // "units": "mWh",
          metered: true,
          systemAllocationKey: true,
          // type: "ROOM_HEATING"
        },
        // allocationKey: dataRow.meter.allocationKey.allocationKeyId,
        // allocationKeyName: dataRow.meter.allocationKey.allocationKeyName,
        notes: dataRow.notes,
      },
      readingId: readingPeriodType.id,
      currentValue: parseFloat(dataRow.currentValue),
      startValue: dataRow.startValue == '' ? null : parseFloat(dataRow.startValue),
      consumption: parseFloat(dataRow.consumption),
      meterReadingDate: moment(dataRow.readingDate, 'DD.MM.YYYY').format('YYYY-MM-DD'),
    };
    return payload;
  }

  onInputChange = (field, value) => {
    this.setState({ [field]: value }, () => this.updateOccasion());
  };

  updateReadings = (data) => {
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.meter_api_base_url + 'readings/' + data.id, //this.urlOccupationReadingId,
      // Autoload.Setting.swagger_mocked_api_baseUrl + 'readings/' + this.urlOccupationReadingId,
      'PUT',
      data,
      null,
      false,
      true,
      false,
      this,
      '_handleReadingUpdateResponse',
      null,
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };

  _handleReadingUpdateResponse(response) {
    console.log(response, 'updated meter');
    // debugger;
    // // ;
    if (response.status === true && response.statuscode === 401) {
      localStorage.clear();
      window.location.reload();
    }

    if (response.status === true && response.statuscode === 200) {
      // Autoload.Redirection.redirection('/meter/reading/');
      this.renderErrorMessage(lang.meter.annual_success_message);
    } else {
      this.renderErrorMessage(lang.meter.text_error_meter_readings_save_error);
    }
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'none';
  }

  _addNewMeter(data) {
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'block';
    data.propertyId = this.props.propertySelected && this.props.propertySelected.id;
    const payload = MeterDataHandler.generateMeterData(data);
    let btnelement = document.getElementById('meter-button-container');
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.meter_api_base_url + 'meters',
      'POST',
      payload,
      btnelement.children[0],
      false,
      true,
      false,
      this,
      '_handleCreateMeter',
      { btnelement: btnelement },
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  //@desc creating new meter
  _handleCreateMeter(response) {
    if (response.status === false) Autoload.Redirection.redirection_error();
    if (this.urlOccupationReadingId === 'c-r-0') {
      this._getOccupationReadingTenants(this.state.currentTenant);
    } else {
      this._getMeterOccupationReading(this.urlOccupationReadingId);
    }
    this.setState({ openMeterModal: false });
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'none';
  }

  onTenantChange(tenant, updateRentalUnit = false) {
    const rentalUnit = this.state.rentalUnitsArr.find(
      (rentUnit) => rentUnit.id === tenant.rentalUnitId && rentUnit.name
    );
    const rentalUnitObj = updateRentalUnit && { currentUnitName: rentalUnit.name, currentUnit: rentalUnit.id };
    this.setState({ currentTenantName: tenant.fullName, currentTenant: tenant.id, ...rentalUnitObj });
    this._getOccupationReadingTenants(tenant.id);
  }

  saveMeterNotes(meterRow, notes) {
    meterRow.notes = meterRow.meter && meterRow.meter.notes;
    var meterdata = MeterDataHandler.generateMeterDataForNotesSave(meterRow.meter);
    meterdata.allocationKey.id = meterdata.allocationKey.allocationKeyId;
    meterdata.notes = notes;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.meter_api_base_url + 'meters/' + meterdata.id,
      'PUT',
      meterdata,
      null,
      false,
      true,
      false,
      this,
      '_handleUpdateMeterMasterResponse',
      null,
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  _handleUpdateMeterMasterResponse(response) { }

  onRentalUnitChange(rentalUnit) {
    const endDate = moment(this.state.occupancyDate.split('-')[1], 'DD.MM.YYYY').format('DD-MM-YYYY')
    const tenantsBillingPeriods = this.state.tenantArr.filter(
      (tenantBillPeriod) => tenantBillPeriod.tenant.rentalUnitId === rentalUnit.id && !((tenantBillPeriod.tenant.vacant) && (moment(tenantBillPeriod.occupation.endDate, 'YYYY-MM-DD').format('DD-MM-YYYY') !== endDate))
    );
    if (tenantsBillingPeriods && tenantsBillingPeriods.length > 0) {
      this.setState({
        currentUnitName: rentalUnit.name,
        currentUnit: rentalUnit.id,
        filteredTenantArr: tenantsBillingPeriods,
        currentTenantName: tenantsBillingPeriods.length > 0 && tenantsBillingPeriods[0].tenant.fullName,
        currentTenant: tenantsBillingPeriods.length > 0 && tenantsBillingPeriods[0].tenant.id,
      });
      tenantsBillingPeriods && this._getOccupationReadingTenants(tenantsBillingPeriods[0].tenant.id);
    } else {
      this.setState({
        currentUnitName: rentalUnit.name,
        currentUnit: rentalUnit.id,
      });
      this.renderErrorMessage(lang.newKeys.text_error_No_tenants_for_rental_unit);
    }
  }
  ShowLoader = () => {
    console.log('show loader abc');
    var loader = document.getElementById('hello-housing-loader');
    if (loader != null) {
      loader.style.display = 'none';
    }
    var emptyDiv = document.getElementById('empty-interium-reading-page');
    if (emptyDiv != null) {
      emptyDiv.style.display = 'none';
    }
    var dataDiv = document.getElementById('respond-interium-reading-page');
    if (dataDiv != null) {
      dataDiv.style.display = 'block';
    }
  };

  render() {
    const {
      dataToShow,
      add,
      rentalUnitsArr,
      validated,
      noValidate,
      filteredTenantArr,
      defaultOccasion,
      currentUnit,
      currentUnitName,
      currentTenantName,
      openMeterModal,
      meter,
      occupancyDate,
      disableUpdation,
      disableRadioSelection,
      isMobile,
      search,
      isSmallerMobile
    } = this.state;
    const {
      match: { params },
      location,
    } = this.props;
    const edit = location.edit;
    console.log(disableRadioSelection)
    console.log(this.state.dataToShow, 'data to show meter redner');
    return (
      <>
        <div id="empty-interium-reading-page"></div>
        <div className="interim-meter-reading-page" id="respond-interium-reading-page" style={{ display: 'none' }}>
          <div ref={this.errorRef} />
          {!isMobile && 
          <>
            <HeadingContainer
              className="interim-meter-heading"
              heading={location.edit ? lang.meter.text_edit_readings : lang.meter.text_individual_meter_reading}
              headingIcon={CrossIcon}
              iconHandler={() => this.goBack()}
              rightContent={
                this.renderButtons()
              }
              isMobile
            />
            <Divider />
          </>}
          <div className="" style={!isMobile ? {display: 'flex', flexDirection: window.innerWidth >= 1200 ? 'row': 'column',} : {}}>
            <div className="indivisual-dropdown-left-header" style={!isMobile ? {display: 'flex'} : {}}>
              <Form.Group className="indivisual-dropdown-one" style={!isMobile ? {minWidth: '220px', marginRight: '16px'}: {}}>
                {isMobile && <Form.Label>Einheit</Form.Label>}
                <UncontrolledDropdown
                  id="myid"
                  onBlur={() => {
                    this.setState({
                      DropdownState: document.getElementById('myid').firstElementChild.getAttribute('aria-expanded'),
                    });
                  }}
                  onFocus={() => {
                    this.setState({
                      DropdownState: document.getElementById('myid').firstElementChild.getAttribute('aria-expanded'),
                    });
                  }}
                  onClick={() => {
                    this.setState({
                      DropdownState: document.getElementById('myid').firstElementChild.getAttribute('aria-expanded'),
                    });
                  }}
                >
                  <DropdownToggle className="dropdown-select cost-type-menu-indivisual">
                    {currentUnitName === '' ? lang.newKeys.text_select : currentUnitName}
                    <div>
                      {this.state.DropdownState === 'true' ? (
                        <img src={downArrow} className="utility-cost-type-dropdown" alt="" />
                      ) : (
                        <img src={uparrow} alt="" />
                      )}
                    </div>
                  </DropdownToggle>
                  <DropdownMenu className="cost-type-menu-indivisual">
                    {rentalUnitsArr
                      .sort((a, b) => (a['name'] || '').toString().localeCompare((b['name'] || '').toString()))
                      .map((reading) => (
                        <DropdownItem
                          key={reading.id}
                          onClick={() => this.onRentalUnitChange(reading)}
                          className="cost-type-options"
                        >
                          <span className=" ">{reading.name}</span>
                        </DropdownItem>
                      ))}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Form.Group>
              <Form.Group className="indivisual-dropdown-two" style={!isMobile ? { minWidth: '220px'} : {}}>
              {isMobile && <Form.Label>Mietverhältnis</Form.Label>}
                <UncontrolledDropdown
                  id="myidtwo"
                  onBlur={() => {
                    this.setState({
                      DropdownStateTwo: document
                        .getElementById('myidtwo')
                        .firstElementChild.getAttribute('aria-expanded'),
                    });
                  }}
                  onFocus={() => {
                    this.setState({
                      DropdownStateTwo: document
                        .getElementById('myidtwo')
                        .firstElementChild.getAttribute('aria-expanded'),
                    });
                  }}
                >
                  <DropdownToggle className="dropdown-select cost-type-menu-indivisual">
                    {currentTenantName === '' ? lang.newKeys.text_select : currentTenantName}
                    <div>
                      {this.state.DropdownStateTwo === 'true' ? (
                        <img src={downArrow} className="utility-cost-type-dropdown" alt="" />
                      ) : (
                        <img src={uparrow} alt="" />
                      )}
                    </div>
                  </DropdownToggle>

                  <DropdownMenu className="cost-type-menu-indivisual">
                    {filteredTenantArr
                      .sort((a, b) =>
                        (a.tenant['fullName'] || '').toString().localeCompare((b.tenant['fullName'] || '').toString())
                      )
                      .map((ten) => (
                        <DropdownItem
                          key={ten.tenant.id}
                          onClick={() => this.onTenantChange(ten.tenant, true)}
                          className="cost-type-options"
                        >
                          <span className=" ">{ten.tenant.fullName}</span>
                        </DropdownItem>
                      ))}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Form.Group>
            </div>
            {
              <div className="indivisual-occupency-header" style={isMobile ? {display: 'flex', justifyContent: isSmallerMobile ?  'center' : 'flex-start' , width: '100%', fontWeight: 'normal'} : {marginLeft: window.innerWidth >= 1200 ? '16px' : 0, marginBottom: '12px', width: '100%'}}>
                {lang.tenant.text_occupancy_dates+': '} {occupancyDate}
              </div>}
            <div className="mb-1 indivisual-dropdown-right-header" style={isMobile ? {display: 'flex', justifyContent: isSmallerMobile ?  'center' : 'flex-start', width: '100%'} : {display: 'flex', width: '100%', alignSelf: 'flex-start'}}>
              <Form.Check
                className={` hello-housing-radio-options ${defaultOccasion === 'Move in' || defaultOccasion === 'move-in' ? `checked` : ``
                  } ${disableRadioSelection.disableMoveIn && 'disable-annual-btn'} `}
                checked={defaultOccasion ? defaultOccasion === 'Move in' || defaultOccasion === 'move-in' : false}
                id="move-in"
                type="radio"
                name="occupancystatus"
                label={lang.tenant.text_move_in}
                inline
                // disabled={edit}
                disabled={disableRadioSelection.disableMoveIn}
                // onChange={(e) => (edit ? null : this.onInputChange('status', e.target.id))}
                onChange={(e) => this.onInputChange('defaultOccasion', e.target.id)}
              />
              <Form.Check
                className={` hello-housing-radio-options ${defaultOccasion === 'Move out' || defaultOccasion === 'move-out' ? `checked` : ``
                  } 
               ${disableRadioSelection.disableMoveOut === true && 'disable-annual-btn'} 
                 
                  `}
                checked={defaultOccasion ? defaultOccasion === 'Move out' || defaultOccasion === 'move-out' : false}
                id="move-out"
                type="radio"
                name="occupancystatus"
                label={lang.tenant.text_move_out}
                inline
                // disabled={edit}
                disabled={disableRadioSelection.disableMoveOut}
                onChange={(e) => this.onInputChange('defaultOccasion', e.target.id)}
              />
              <Form.Check
                className={` hello-housing-radio-options ${defaultOccasion === 'Annual' || defaultOccasion === 'annual' ? `checked` : ``
                  } ${disableRadioSelection.disableAnnual === true && 'disable-annual-btn'}`}
                checked={defaultOccasion ? defaultOccasion === 'Annual' || defaultOccasion === 'annual' : false}
                id="annual"
                type="radio"
                name="occupancystatus"
                label={lang.meter.text_annual}
                inline
                // disabled={edit}
                disabled={disableRadioSelection.disableAnnual}
                onChange={(e) => this.onInputChange('defaultOccasion', e.target.id)}
              />
            </div>
          </div>
          <Divider />
            {
            <Group>
              <TextInput placeholder="Suche nach Zählernummer und Zählerart" icon={<Icon name="search" />} sx={{ width: 380 }} onChange={(event) => this.setState({ search: event.target.value })} />
            </Group>}
          {disableUpdation ? (
            <EmptyStateContainer
              style={{ marginBottom: '20px !important' }}
              className="empty-annual-meter-reading"
              heading={lang.newKeys.text_error_meter_added_to_renatal_unit} //"There are no meter added to this rental unit"
              subheading={lang.newKeys.text_empty_add_meters_first_to_manage_annual} //"You must add meters first to manage their annual readings.
              icon={meterIcon}
              btnHandler={() => {
                this.setState({ openMeterModal: !this.state.openMeterModal });
              }}
              buttonDisable={true}
              btnText={lang.meter.text_add_meter} //"Add Meter"
            />
          ) : !this.state.isMobile ? (
            <div className="meter-record-btn">
              <ReadingRecord
                form="meterForm"
                onChange={this.onRecordInputChange}
                onDateChange={this.onReadingDateChange}
                edit={this.urlOccupationReadingId === 'c-r-0' ? false : true}
                add={add}
                occasionDate={this.state.defaultOccasion}
                occDate={moment(this.state.occupancyDate.split('-')[1], 'DD.MM.YYYY').format('DD-MM-YYYY')}
                data={dataToShow}
                search={search}
                handleSubmit={() => {
                  return false;
                }}
                validated={validated}
                noValidate={noValidate}
                readingDate={this.state.date}
                className="interim-readings-table"
                labels={[
                  lang.meter.text_meter_number,
                  lang.meter.text_meter_type,
                  lang.meter.text_location,
                  lang.meter.text_reading_date,
                  lang.meter.text_start_value,
                  lang.meter.text_current_value,
                  lang.meter.text_consumption,
                  lang.meter.meter_units,
                  lang.meter.text_note,
                ]}
                labelClassName={['number', 'type', 'date', 'start', 'current', 'consumption', 'notes']}
                parentContext={this}
              />
              <ColorButton
                text={lang.meter.text_add_meter}
                buttonConfig={{ disabled: disableUpdation }}
                className="gray-btn add-meter-btn"
                onClick={() => {
                  this.setState({ openMeterModal: !openMeterModal });
                }}
              />
            </div>
          ) : (
            <>
            <div style={{marginBottom: '30px'}}>
              <MobileInterimReading
                form="meterForm"
                labels={[lang.meter.text_mobile_meter_number, lang.meter.text_mobile_meter_type, lang.meter.text_current_value]}
                onChange={this.onRecordInputChange}
                onDateChange={this.onReadingDateChange}
                edit={this.urlOccupationReadingId === 'c-r-0' ? false : true}
                // edit={edit}
                add={add}
                // edit={true}
                data={dataToShow}
                search={search}
                // handleSubmit={this.saveChanges}
                handleSubmit={() => {
                  return false;
                }}
                validated={validated}
                noValidate={noValidate}
                readingDate={this.state.date}
                labelClassName={['number', 'type', 'current']}
                className="mobile-data"
                parentContext={this}
              />
              <ColorButton
                text={lang.meter.text_add_meter}
                className="gray-btn add-meter-btn"
                buttonConfig={{ disabled: disableUpdation }}
                onClick={() => {
                  this.setState({ openMeterModal: !openMeterModal });
                }}
              />
              
            </div>
            <Divider/>
            {this.renderButtons()}
            </>
          )}
          { }

          {openMeterModal && (
            <MeterModal
history={this.props.history}
              meter={meter}
              className="add-meter-modal"
              open={openMeterModal}
              addNewMeter={(newMeterData) => this._addNewMeter(newMeterData)}
              toggle={() => this.setState({ openMeterModal: !this.state.openMeterModal })}
              propertyId={this.props.propertySelected.id}
              rentalUnitId={currentUnit}
            />
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    propertySelected: state.generalReducer.property,
  };
};
export default connect(mapStateToProps, {
  dataFilteration,
  toEditMeterReading,
  addInterimReading,
  editInterimReading,
})(InterimReading);
