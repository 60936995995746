import React, { Component } from 'react';
import Autoload from '../../../../neoverv/Autoload';
import { connect } from 'react-redux';
import { showNotification } from '@mantine/notifications';
//import Papa from 'papaparse';
import { Group, Skeleton, Stack } from '@mantine/core';
import { DropFile, EmptyStateContainer } from '../../../../neoverv';
import GreenTick from '../../../../assets/images/module/general/GreenTick.svg';
import RedCross from '../../../../assets/images/module/general/RedCross.svg';
import MeterIcon from '../../../../assets/images/module/general/blueEmptyMeter.svg';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
const { loader } = Autoload.MainHelper;

const pStyle= {
    fontFamily: 'Inter, sans-serif',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '24px',
    color: 'rgb(14, 14, 29)'
}
const tableStyle= {
    border: 'none',
    padding: '5px 16px',
    textAlign: 'center'
}
const tableHeaderStyle= {
    fontWeight: '500', 
    lineHeight: '16px',
    fontFamily: 'Inter, sans-serif',
    whiteSpace: 'nowrap',
    width: '200px',
    maxWidth: '200px',
    textAlign: 'left'
}
export class MeterManualData extends Component {
  constructor(props) {
    super(props);
    this.state = {
        parsedData: [],
        tableColumnNames: [],
        tableRowValues: [
            {
                name: 'Zählernummer',
                checked: false,
            },
            {
                name: 'Ablesedatum',
                checked: false,
            },
            {
                name: 'Verbrauch',
                checked: false,
            }
        ],
        selectedColumns: [],
        expandModal: false,
        hover:false,
        selectedFile: null,
        selectedFileErr: { state: false, msg: '' },
        allMappingsFound: false,
        skeletonLoading: false
    };
  }

  componentDidMount = () => {
    const {meterMappings} = this.props;
    if(meterMappings){
      this.checkAllMappings(meterMappings.find((mapping)=> mapping.isActive));
    }
  };
  componentDidUpdate = (prevProps) => {
    const {meterMappings} = this.props;
    if(prevProps.meterMappings !== meterMappings){
       this.checkAllMappings(meterMappings.find((mapping)=> mapping.isActive));
    }
  }

  checkAllMappings = (meterMapping) => {
    console.log('checkAllMappings: ', meterMapping);
    if(meterMapping){
      if(meterMapping.mappingType === 'CSV'){
        if(meterMapping.meterIdMapping && meterMapping.meterIdMapping !== '' && 
        meterMapping.dateTimeMapping && meterMapping.dateTimeMapping !== '' && 
        meterMapping.consumptionMapping && meterMapping.consumptionMapping !== ''){
            this.setState({allMappingsFound: true});
        }else{
            this.setState({allMappingsFound: false});
        }
      }else{
        if(meterMapping.filePassword && meterMapping.filePassword !== ''){
          this.setState({allMappingsFound: true});
        }else{
          this.setState({allMappingsFound: false});
        }
      }
    }else{
      this.setState({allMappingsFound: false});
    }
  }

  meterReadingsFromUpload = () => {
    this.setState({skeletonLoading: true});
    var data = new FormData();
    this.state.selectedFile && this.state.selectedFile.length > 0 && data.append('csvFile', this.state.selectedFile[0]);
    data.append('propertyId', this.props.propertySelected.id);
    Autoload.HttpRequestAxios.HttpRequest(
        Autoload.Setting.meter_api_base_url + 'meterReadings/fromUpload',
        'POST',
        data,
        null,
        false,
        true,
        true,
        this,
        '_handleMeterReadingsFromUpload',
        null,
        false,
        '',
        { 'Content-Type': 'multipart/form-data', 
        Authorization: Autoload.Utils.getAuthToken() }
      );
  }

  _handleMeterReadingsFromUpload(response) {
    console.log('_handleMeterReadingsFromUpload: ', response);
    if(response.status === true && response.message === 'Success'){
        showNotification({
            message: 'Die Datei wurde verarbeitet.',
            icon: <img src={GreenTick} alt='green-tick'/>,
        });
    }else{
      showNotification({
        message: 'Es ist ein Fehler aufgetreten. Bitte probieren Sie es erneut oder wenden Sie sich an den Support.',
        icon: <img src={RedCross} alt='red-cross'/>,
      });
    }
    this.setState({skeletonLoading: false});
    this.props.refreshLogs();
    this.setState({selectedFile: null});
  }

  getMeterReadingMappings = () => {
    Autoload.HttpRequestAxios.HttpRequest(
        Autoload.Setting.meter_api_base_url + 'meterReadingMappings?propertyId='+ this.props.propertySelected.id,
        'GET',
        null,
        null,
        false,
        true,
        true,
        this,
        '_handleGetMeterReadingMappings',
        null,
        false,
        '',
        { 'Content-Type': 'application/json', Authorization: Autoload.Utils.getAuthToken() }
      );
  }

  _handleGetMeterReadingMappings(response) {
    console.log('_handleGetMeterReadingMappings: ', response);
    if(response.status){
        response.result[0].meterIdMapping && 
        response.result[0].dataTimeMapping && 
        response.result[0].consumptionMapping && this.setState({allMappingsFound: true});
    }
  }

//   changeHandler = (event) => {
//     console.log('onChange:', event.target.files[0]);
//     Papa.parse(event.target.files[0], {
//         header: true,
//         skipEmptyLines: true,
//         complete: (results)=> {
//             showNotification({
//                 title: 'Erfolgreich!',
//                 color: 'green',
//                 message: 'Datei erfolgreich hochgeladen.',
//             });
//             console.log('Papa.parse:', results.data)
//             const rowsArray = [];
//             const valuesArray = [];

//             // Iterating data to get column name and their values
//             results.data.map((d) => {
//             rowsArray.push(Object.keys(d));
//             valuesArray.push(Object.values(d));
//             });

//             this.setState({
//                 parsedData: results.data,
//                 tableColumnNames: rowsArray[0],
//                 //tableRowValues: valuesArray
//             })
//         },
//         error: (e)=>{
//             showNotification({
//                 title: 'Fehler!',
//                 color: 'green',
//                 message: e.message,
//               });
//         }
//       });
//   };

onSelect = (name, value=true)=> {
    let tableRowValues = this.state.tableRowValues;
    tableRowValues.map((row)=> {
        if(row.name === name){
            row.checked = value;
        }
    });
    this.setState({tableRowValues}); 
}

handleMouseEnter=()=>{
    this.setState({hover: true});
  }
  handleMouseLeave=()=>{
    this.setState({hover: false});
  }

  handleFileChange = (acceptedFile, rejectedFile) => {
    if(this.state.allMappingsFound){
        // console.log(acceptedFile)
        if (acceptedFile[0]) {
            this.setState({ selectedFile: acceptedFile, receiptName: acceptedFile[0].path.replace(/\.[^/.]+$/, '') }, ()=> this.meterReadingsFromUpload());
        }
        if (rejectedFile[0]) {
            rejectedFile[0].errors.forEach((err) => {
            if (err.code === 'file-too-large') {
                this.setState({ selectedFileErr: { state: true, msg: 'File is larger than 5mb' } });
            }
            if (err.code === 'file-invalid-type') {
                this.setState({ selectedFileErr: { state: true, msg: 'File must be jpg or pdf' } });
            }
            });
        } else {
            this.setState({ selectedFileErr: { state: false } });
        }
    }else{
        showNotification({
            message: 'Sie müssen zunächst die Einrichtung im oberen Bereich "Datenbereitstellung einrichten" vollständig abschließen, um Dateien hochladen zu können.',
            icon: <img src={RedCross} alt='green-tick'/>,
        })
    }
    
  };

  removeImage = () => {
    console.log('remove');
    // this._deleteFile();
    this.setState({ selectedFile: null });
    // if (this.props.edit) {
    //   this._deleteFile(this.props.record.id);
    //   // this.setState({ deletedFile: true });
    // }
  };
  
  render() {
    const email = 'receipt-1f298e4c-685f-4373-9359-37b70958c3cc@upload.hellohousing.de';
    const {tableColumnNames, tableRowValues, expandModal, allMappingsFound, skeletonLoading} = this.state;
    return (
        <div 
        style={{
          width: '100%',
          //height: '400px',
          background: 'white',
          boxShadow: '0 1px 3px rgba(0,0,0,.08)', 
          borderRadius: '4px'
          }}>
            <Skeleton visible={skeletonLoading}>
            <div 
            className='tooltips meter-manual-upload'
            style={{
                padding: '12.1px 24px',
                borderBottom: '1px solid #dadae6',
                borderTopLeftRadius: '4px',
                borderTopRightRadius: '4px',
                background: 'white'
            }}>
              <h5 
              style={{
                  fontWeight: '500',
                  fontSize: '16px',
                  lineHeight: '24px',
                  color: '#0e0e1d',
                  marginBottom: '0px'
                  }}>Datei hochladen</h5>
            </div>
            <div style={{padding: '12.1px 24px', height: '100%'}}>
                {allMappingsFound ? <Stack justify='space-between' 
                style={{height: '100%'}}>
                <div>
                    <span style={pStyle}>Hier können Sie Zählerdateien hochladen, die von hellohousing direkt verarbeitet werden.</span>
                </div>

                <DropFile
                //csvFormat={true}
                //filename={edit && this.state.receiptName}
                accept={null}
                maxSize={100000000}
                err={this.state.selectedFileErr.state}
                //edit={edit}
                handleDrop={this.handleFileChange}
                file={this.state.selectedFile}
                removeFile={this.removeImage}
                hideSecondText={true}
                //onClick={()=> this.setState({imageOpen: true})}
                //accept={'.csv'}
                />

                </Stack>

                :
                  <Group position='center' style={{background: 'rgb(247, 247, 250)'}}>
                    <EmptyStateContainer
                      heading="Datei-Upload noch nicht verfügbar."
                      subheading={"Sie müssen erst die Datenbereitstellung im oberen Bereich einrichten, um Dateien hochzuladen."}
                      icon={MeterIcon}
                      showBtn={false}
                      styleMainContainer={{margin: 50}}
                    />
                  </Group>}
            </div>
            </Skeleton>
        </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    propertySelected: state.generalReducer.property,
  };
};
export default connect(mapStateToProps)(MeterManualData);
