import React, { Component } from 'react';
import { ProfileHistoryRecord } from '../../../../../neoverv';
import Autoload from '../../../../../neoverv/Autoload';
import moment from 'moment';
import { connect } from 'react-redux';
var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default].general;

class PaymentHistory extends Component {
  state = {
    historyData: [{ bill_name: '', date: '', amount: '' }],
  };

  componentDidMount() {
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'block';
    this.getPaymentHistoryTable();
  }

  getPaymentHistoryTable = () => {
    //const userAccountID = this.props.profile.id;
    const userAccountID = this.props.profile.accountId;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.user_reg_api_baseurl + 'Invoices?accountId=' + userAccountID,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handle_payment_history',
      null,
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };
  handle_payment_history(response) {
    console.log('handle payment history response', response);
    if (response.status == true && response.statuscode == 200) {
      this.setState({
        historyData: response.result.map((data, index) => {
          return {
            bill_name: Autoload.MainHelper.checkProperty(data, 'invoiceTitle'),
            amount: Autoload.MainHelper.checkProperty(data, 'totalAmount'),
            date: moment(Autoload.MainHelper.checkProperty(data, 'invoiceDate')).format('DD.MM.YYYY'),
            id: Autoload.MainHelper.checkProperty(data, 'id'),
          };
        }),
      });
    }
    this.ShowLoader();
  }
  ShowLoader = () => {
    //console.log('show loader abc');
    var loader = document.getElementById('hello-housing-loader');
    if (loader != null) {
      loader.style.display = 'none';
    }

    var emptyDiv = document.getElementById('empty-user-account-invoices-details');
    if (emptyDiv != null) {
      emptyDiv.style.display = 'none';
    }

    // var dataDiv = document.getElementById('response-user-account-invoices-details');
    // if (dataDiv != null) {
    //   dataDiv.style.display = 'block';
    // }
  };
  render() {
    console.log(this.state.historyData, 'this.state.historyData');
    return (
      <>
        <div id="empty-user-account-invoices-details"></div>
        <div id="response-user-account-invoices-details" style={{ display: 'block' }}>
          <ProfileHistoryRecord
            data={this.state.historyData}
            labels={[lang.text_bill_name, lang.text_date, lang.text_amount]}
            labelsClassName={['bill-name', 'date', 'amount']}
            className="record-list-table payment-history"
            // parentContext={this}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profile: state.generalReducer.profile,
    AccountApi: state.generalReducer.account_api_id,
  };
};

export default connect(mapStateToProps)(PaymentHistory);
