import React, { Component } from 'react';
import CustomModal from './index';
// import { Table } from 'reactstrap';
import { Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { ColorButton } from '../../../../index';
import { SaveZuweisungData } from '../../../../../redux/action/UtilityBilling/UtilityBillingActions';
import Autoload from '../../../../Autoload';
import moment from 'moment';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { showNotification } from '@mantine/notifications';
import RedCross from '../../../../../assets/images/module/general/RedCross.svg';
import GreenTick from '../../../../../assets/images/module/general/GreenTick.svg';
import { NumericFormat } from 'react-number-format';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
const { checkProperty, emptyFieldValidation, invalidSize, loader } = Autoload.MainHelper;

class ZuweisungModal extends Component {
  constructor(props) {
    super(props);
    this.state = this.initialState;
  }
  initialState = {
    data: [],
    tenantAmountInFocus: false
  };
  componentDidMount() {
    console.log('modal mount');
    const { record } = this.props;
    this.setState({
      data: record,
    });
  }
  //update data to everytime redux changes
  componentWillReceiveProps(nextProps) {
    if (this.props.record != nextProps.record) {
      this.setState({
        data: nextProps.record,
      });
    }
  }

  resetForm = () => {
    this.setState(this.initialState);
  };
  onAmountChange = (recordId, field, value) => {
    //get record to which this amount belongs
    let tenantRecord = this.state.data.find((item) => item.id == recordId);
    //update this record with your input amount
    let updatedtenantRecord = { ...tenantRecord, amount: value };
    //replace this record with old record
    let updatedRecord = this.state.data.map((rc) => {
      if (rc.id == updatedtenantRecord.id) {
        return updatedtenantRecord;
      } else {
        return rc;
      }
    });
    // console.log('updatedRecord',updatedRecord, this.props.costPositionId)
    this.setState({ data: updatedRecord });
  };
  validateData = (data, field, fieldName) => {
    let invalidErr, emptyErr;
    emptyErr = emptyFieldValidation(data.amount.replaceAll('€', '').trim());
    invalidErr = invalidSize(data.amount.replaceAll('€', '').trim());
    if (emptyErr) {
      let updatedtenantRecord = { ...data, err: true, msg: `${lang.general.text_enter} ${fieldName}` };
      let updatedRecord = this.state.data.map((rc) => {
        if (rc.id == updatedtenantRecord.id) {
          return updatedtenantRecord;
        } else {
          return rc;
        }
      });
      this.setState({ data: updatedRecord });
      return true;
    } else if (invalidErr) {
      let updatedtenantRecord = { ...data, err: true, msg: `${lang.general.text_invalid} ${fieldName}` };
      let updatedRecord = this.state.data.map((rc) => {
        if (rc.id == updatedtenantRecord.id) {
          return updatedtenantRecord;
        } else {
          return rc;
        }
      });
      this.setState({ data: updatedRecord });
      return true;
    } else {
      let updatedtenantRecord = { ...data, err: false, msg: '' };
      let updatedRecord = this.state.data.map((rc) => {
        if (rc.id == updatedtenantRecord.id) {
          return updatedtenantRecord;
        } else {
          return rc;
        }
      });
      this.setState({ data: updatedRecord });
      return false;
    }
  };
  handleSubmit = async (e) => {
    const { data } = this.state;
    e.preventDefault();
    e.stopPropagation();
    let errors = [];
    let err;
    for (let i = 0; i < data.length; i++) {
      err = await this.validateData(data[i], 'amount', 'amount');
      errors.push(err);
    }
    let isInvalid = errors.find((item) => item == true);
    if (isInvalid) {
      console.log('invalid data');
    } else {
      this.addData();
    }
  };
  addData = () => {  
    loader(true);
    let formatedData = this.state.data.map((data) => {
      return {
        id: checkProperty(data, 'id'),
        utilityBillingId: checkProperty(data, 'utilityBillingId'),
        costPositionId: checkProperty(data, 'costPositionId'),
        costTypeName: checkProperty(data, 'costTypeName'),
        allocationTypeName: checkProperty(data, 'allocationTypeName'),
        billingStatementId: checkProperty(data, 'billingStatementId'),
        tenantName: checkProperty(data, 'tenant'),
        rentalUnitId: checkProperty(data, 'rentalUnitId'),
        rentalUnitName: checkProperty(data, 'rentalUnit'),
        tenantOccupation: checkProperty(data, 'tenantOccupation', {}),
        tenantConsumption: checkProperty(data, 'tenantConsumption'),
        totalConsumption: checkProperty(data, 'totalConsumption'),
        consumptionUnits: checkProperty(data, 'consumptionUnits'),
        totalCostShare: checkProperty(data, 'totalCostShare'),
        tenantCostShare: Autoload.MainHelper.ConverterLocaleNumber(data.amount, 'de'),
        // amount: Autoload.MainHelper.ConverterLocaleNumber(data.amount, "de"),
      };
    });
    Autoload.HttpRequestAxios.HttpRequest(
      // Autoload.Setting.swagger_mocked_api_baseUrl +
      Autoload.Setting.ub_api_base_url +
        'costAllocations/costAllocations/forCostPosition?utilityBillingId=' +
        this.props.billingId +
        '&costPositionId=' +
        this.props.costPositionId,
      'PUT',
      formatedData,
      null,
      false,
      true,
      false,
      this,
      'handleAddData',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };
  handleAddData = (response) => {
    if (response.status == false) {
      Autoload.Redirection.redirection_error();
      Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang.newKeys.text_error_saving_cost_position);
      showNotification({
        message: 'Es ist ein Fehler aufgetreten. Bitte probieren Sie es erneut oder wenden Sie sich an den Support.',
        icon: <img src={RedCross} alt='red-cross'/>,
      })
    }
    if(response.status === true) {
      showNotification({
        message: 'Die Änderungen wurden übernommen.',
        icon: <img src={GreenTick} alt='green-tick'/>,
      })
    }
    this.props.toggle();
    this.props.saveAmount(this.getTotalAmount());
    loader(false);
    this.resetForm();
  };

  getTotalAmount = () =>{
    return Autoload.MainHelper.ConverterCurrencyDe_DisplayOnly(this.state.data.reduce(
      (total, currentValue) => total + Autoload.MainHelper.ConvertCurrencDe_BackToNumber(currentValue.amount, 'de'),
      0) + '', true)
  }

  render() {
    const { tenantAmountInFocus } = this.state;
    const { open, toggle, className, edit, record } = this.props;
    return (
      <div className="Zuweisung-table">
        <CustomModal
          heading={edit ? lang.utility.text_set_custom_allocation : lang.utility.text_set_custom_allocation}
          headingClassName={'ub-individual-assignment-modal-heading'}
          open={open}
          toggle={() => {
            this.resetForm();
            toggle();
          }}
          className={`${className}`}
        >
          <Form onSubmit={this.handleSubmit} noValidate>
            <Table responsive
            className="table-material-Zuweisung"
            style={{borderCollapse: 'separate', borderSpacing: '0px 8px'}}
            >
              {this.state.data.length > 0 && (
                <TableHead
                >
                  <TableRow>
                    <TableCell
                    >{lang.general.text_tenants}</TableCell>
                    <TableCell
                    >{lang.utility.text_occupancy_period}</TableCell>
                    <TableCell
                    >{lang.tenant.text_rental_unit}</TableCell>
                    <TableCell
                    >{lang.utility.text_amount}</TableCell>
                  </TableRow>
                </TableHead>
              )}
              <TableBody>
              {this.state.data.length > 0 &&
                this.state.data.map((tenant, idx) => {
                  return (
                    <TableRow key={idx}
                    >
                      <TableCell
                      width={'25%'}
                      style={{}}
                      ><div>{tenant.tenant}</div></TableCell>
                      <TableCell 
                      width={'25%'}
                      >
                        <span>
                          {`${moment(checkProperty(tenant.tenantOccupation, 'startDate')).format('DD.MM.YYYY')}-${moment(
                            checkProperty(tenant.tenantOccupation, 'endDate')
                          ).format('DD.MM.YYYY')}`}
                        </span>
                      </TableCell>
                      <TableCell
                      width={'25%'}
                      >
                        <div>{tenant.rentalUnit}</div>
                      </TableCell>
                      <TableCell
                      width={'13%'}
                      >
                        {/* <Form.Control
                          type="text"
                          isInvalid={tenant.err}
                          placeholder="0,00€"
                          value={tenant.amount}
                          onChange={(e) => this.onAmountChange(tenant.id, 'amount', e.target.value)}
                          style={{textAlign: 'right'}}
                          // onFocus={(e) => this.removeSymbol(e.target.value, '€')}
                          // onBlur={(e) => this.addSymbol(e.target.value, '€')}
                          onBlur={(e) =>
                            !e.target.value.includes('€') &&
                            this.onAmountChange(
                              tenant.id,
                              'amount',
                              Autoload.MainHelper.ConverterCurrencyDe_DisplayOnly(e.target.value+ '', true)
                              //Autoload.MainHelper.ConverterCurrencyDe(e.target.value + '', true, 'de')
                            )
                          }
                        /> */}
                        <NumericFormat 
                        className='form-control'
                        value={tenant.amount}
                        thousandSeparator="." 
                        decimalSeparator=","
                        onValueChange={(values, sourceInfo) => {
                            this.onAmountChange(tenant.id, 'amount', values.floatValue)
                        }}
                        suffix={' €'}
                        onKeyPress={this.handleEnterKeyPress}
                        placeholder='0,00 €'
                        onFocus={()=> this.setState({tenantAmountInFocus: tenant.id})}
                        onBlur={(e) => {
                          if(!e.target.value.includes('€')){
                            this.onAmountChange(tenant.id, 'amount', tenant.amount);
                          }
                          this.setState({tenantAmountInFocus: false});
                        }}
                        decimalScale={tenantAmountInFocus === tenant.id ? 5 : 2}
                        fixedDecimalScale={false}
                        allowNegative={true}
                        style={{textAlign: 'right'}}
                        />
                        <Form.Control.Feedback className="err" type="invalid">
                          {' '}
                          {tenant.msg}{' '}
                        </Form.Control.Feedback>
                      </TableCell>
                    </TableRow>
                  );
                })}
                </TableBody>
            </Table>
            <Table responsive
            className="table-material-Zuweisung-2"
            >
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell
                  style={{paddingBottom: '8px'}}>
                    <span>
                        {'Gesamt'}
                    </span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow key={this.state.data.length}>
                  <TableCell width={'25%'}></TableCell>
                  <TableCell width={'25%'}></TableCell>
                  <TableCell width={'25%'}></TableCell>
                  <TableCell
                  width={'13%'}
                  style={{paddingRight: '0px'}}
                  >
                    <span style={{textAlign: 'right', background: 'rgb(247, 247, 250)'}} className="form-control">{this.getTotalAmount()}</span>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <div className="cancel_addbtn-Zuweisung">
              <div>
                <ColorButton
                  onClick={(e) => {
                    e.preventDefault();
                    this.resetForm();
                    toggle();
                  }}
                  className="gray-btn"
                  text={lang.general.text_cancel}
                />
              </div>
              <div>
                <ColorButton
                  buttonConfig={{ type: 'submit' }}
                  className="purple-btn"
                  text={'Speichern'}
                />
              </div>
            </div>
          </Form>
        </CustomModal>
      </div>
    );
  }
}
// const mapStateToProps = (state) => {
//     return {
//         costTypes:state.costTypeReducer,
//         allocationData:state.allocationKeyReducer,

//     }
//   }
const actions = {
  SaveZuweisungData,
};
export default connect(null, actions)(ZuweisungModal);
