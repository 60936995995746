import en from '../language/en';
import du from '../language/du';
import Logo from '../../assets/images/module/general/Logo.svg';
// const api_base_url = process.env.REACT_APP_API_BASE_URL; //'http://20.52.129.99:8090/';
// const app_base_url = process.env.REACT_APP_APP_BASE_URL;//'http://20.52.129.99';
// const app_base_url = 'http://localhost:3000'

let api_base_url = 'https://dev.hellohousing.de/api/';//'https://app.hellohousing.de/api/'

if(window.location.hostname.includes('app')){
  api_base_url='https://app.hellohousing.de/api/' //'https://app.hellohousing.de:8090/'
}

// TODO: Move this variables into .env
const app_base_url = 'https://dev.hellohousing.de/';
//mock api
const swagger_mocked_api_baseUrl = process.env.REACT_APP_SWAGGER_MOCKED_API_BASE_URL; //'https://virtserver.swaggerhub.com/neoverv_berlin/NV-HH-API/0.9.2/';
//intercom api
const intercom_contact_search = 'https://api.intercom.io/contacts/search';
//other api
const rm_api_base_url = api_base_url + 'receipt-api/receipt/';
const rm_api_basic_base_url = api_base_url + 'receipt-api/';
const ub_api_base_url = api_base_url + 'utility-api/';
// const ub_api_base_url = swagger_mocked_api_baseUrl;
const auth_api_base_url = api_base_url + 'auth-api/auth/';
const sign_in_bas_url = api_base_url + 'auth-api/';
const meter_api_base_url = api_base_url + 'meter-api/';
const property_api_base_url = api_base_url + 'property-api/';
const costtype_api_base_url = api_base_url + 'costTypes/';
const user_reg_api_baseurl = api_base_url + 'user-api/';
const subscription_api_baseurl = api_base_url + 'subscription-api/';
const user_api_baseurl = api_base_url + 'user-api/users/';
const account_api_baseurl = api_base_url + 'account-api/accounts/';
const account_profile_subscription_api_baseurl = api_base_url + 'subscription-api/';
const document_api_base_url = api_base_url + 'storage-api/';
const finance_api_base_url = api_base_url + 'finance-api/';
//const account_subscriptionAssignments_base_url = api_base_url + 'subscriptionAssignments';

// const rental_unit_api_base_url= api_base_url+"rentalUnits"

const get_meter_reading_interium_link = (
  billingId = 'c-0',
  readingId = 'c-r-0',
  occupancyDate = '',
  period = 'start',
  type = 'relative'
) => {
  var url =
    '/meter/reading/interium/?billingId=' +
    billingId +
    '&occupationreadingsid=' +
    readingId +
    '&occupationDate=' +
    occupancyDate +
    '&period=' +
    period;

  if (type === 'relative') {
    return url;
  }

  // return url; // local host

  return app_base_url + url; // live
};

const get_meter_reading_annual_link = (
  billingId = 'c-0',
  readingId = 'c-r-0',
  occupancyDate = '',
  period = 'start',
  type = 'relative'
) => {
  //var url = '/meter/reading/annual/?billingId=' + billingId + '&occupationreadingsid=' + readingId;
  var url = '/meter/reading/annual/'+billingId+'/'+readingId;

  if (type === 'relative') {
    return url;
  }

  // return url;
  return app_base_url + url;
};

const UB_debug = false;

const Setting = {
  app_name: process.env.REACT_APP_NAME,
  app_base_url,
  language_default: process.env.REACT_APP_DEFAULT_LANGUAGE, //'du' ,
  en: en,
  du: du,
  logo: Logo,
  api_base_url,
  rm_api_base_url,
  rm_api_basic_base_url,
  auth_api_base_url,
  meter_api_base_url,
  property_api_base_url,
  ub_api_base_url,
  swagger_mocked_api_baseUrl,
  costtype_api_base_url,
  get_meter_reading_interium_link,
  get_meter_reading_annual_link,
  UB_debug,
  user_api_baseurl,
  user_reg_api_baseurl,
  account_api_baseurl,
  //account_subscriptionAssignments_base_url,
  subscription_api_baseurl,
  account_profile_subscription_api_baseurl,
  // rental_unit_api_base_url,
  sign_in_bas_url,
  intercom_contact_search,
  document_api_base_url,
  finance_api_base_url
};

// New config
export const config = {
  baseUrl: api_base_url,

  // Occupation readings
  occupationReadings: '',
  
  // Meters
  meter: 'meter-api/',
  
  // Properties
  properties: 'property-api/properties',
  propertyApi: 'property-api/',

  // Sign in / up
  signIn: 'auth-api/signIn',
  resetPassword: 'auth-api/signIn/resetPassword',
  signUpAccount: 'auth-api/signUp/account',
  signUpAccountDetails: 'auth-api/signUp/accountDetails',
  signUpSubscription: 'auth-api/signUp/subscription',
  signUpActivateAccount: 'auth-api/signUp/activateAccount',
  signUpEmailVerificationInstructions: 'auth-api/signIn/EmailVerificationInstructions',
  signUpVerifyEmail: 'auth-api/signIn/VerifyEmail',

  // Receipts
  receipts: 'receipt-api/receipt/receipt/',
  receipt: 'receipt-api/receipt/',

  // Finance
  financeApi: finance_api_base_url

};

// Month names to use without DayJS
export const months = [
  'Januar',
  'Februar',
  'März',
  'März',
  'Mai',
  'Juni',
  'Juli',
  'August',
  'September',
  'Oktober',
  'November',
  'Dezember',
];

export default Setting;
