import React, { Component } from 'react';
import CustomModal from './index';
import {
  ColorButton,
} from '../../../../index';
import { Row, Col, Form, Tooltip, OverlayTrigger } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import RangeDatePicker from './DateRangePicker';
import Autoload from '../../../../Autoload';
import DatePickerIcon from '../../../../../assets/images/module/general/DatePickerIcon.svg'

// Get receipts endpoint from new config
import { config } from '../../../../config/Setting';
import * as customParseFormat from 'dayjs/plugin/customParseFormat'
import dayjs from 'dayjs';
import { InputLabel, MenuItem, Select, FormControl } from '@material-ui/core';
import { Group, Stack } from '@mantine/core';
import moment from 'moment';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
var { checkProperty, yearDifference, yearDifferenceMoment } = Autoload.MainHelper;
dayjs.extend(customParseFormat)

const MOBILE_SIZE = 992;
const TABLET_SIZE = 1200;

const CustomInput = React.forwardRef((props, ref) => {
  return (
    <Group noWrap onClick={props.onClick} className='rental-income-export-date-range' spacing={10}>
      <img onClick={props.onClick} src={DatePickerIcon} width='16px'/>
      <span onClick={props.onClick} ref={ref}>         
        {props.value || props.placeholder}
      </span>
    </Group>
  );
});

class RentalIncomeExportRangeModal extends Component {
  state = {
    startDate: null,
    endDate: null,
    selectedDateRange: 'current',
    dateRangeOptions: [
        {
          value: 'current',
          label: `${dayjs().startOf('year').format('DD.MM.YYYY')} - ${dayjs().endOf('year').format('DD.MM.YYYY')}`, 
          date: {
            start: dayjs().startOf('year'), 
            end: dayjs().endOf('year')
          }
        },
        {
          value: 'last',
          label: `${dayjs().subtract(1, 'year').startOf('year').format('DD.MM.YYYY')} - ${dayjs().subtract(1, 'year').endOf('year').format('DD.MM.YYYY')}`, 
          date: {
            start: dayjs().subtract(1, 'year').startOf('year'), 
            end: dayjs().subtract(1, 'year').endOf('year')
          }
        },
        {
          value: 'custom',
          label: "benutzerdefiniert",
          date: {
            start: new Date(moment().startOf('year')), 
            end: new Date(moment().endOf('year'))
          }
        }
    ]
  };
  
  constructor(props) {
    super(props);
  }

  componentDidMount(){
  }

  handleChange = (event) => {
    this.setState({selectedDateRange: event.target.value});
  };

  handleDateRangeChange = (newDate, type) => {
    console.log('handleDateRangeChange: ', newDate, type);

    let newdateRangeOptions = this.state.dateRangeOptions.map((range, index)=> {
        if(index === 2){
          if(type === 'start'){
            range.date = {
              ...range.date,
              start: newDate
            };
          }else if(type === 'end'){
            range.date = {
              ...range.date,
              end: new Date(moment(newDate).endOf('M'))
            };
          }
        return range;
        }else{
        return range;
        }
    });
    
    this.setState({ dateRangeOptions: newdateRangeOptions });
  };
  
  render() {
    const { open, toggle, className, downloadTabel } = this.props;
    const {dateRangeOptions, selectedDateRange, startDate, endDate} = this.state;
    
    return (
      <>
        <CustomModal
          heading={'Zeitraum festlegen'}
          headingClassName='receipt-management-email-upload-modal-heading'
          open={open}
          toggle={() => {
            toggle();
          }}
          className={`${className}`}
        >
        <Stack>
            <Stack>
                <Stack spacing={0}>
                    <InputLabel id="demo-simple-select-outlined-label">Zeitraum</InputLabel>
                    <FormControl variant="outlined">
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={selectedDateRange}
                        style={{height: '36px'}}
                        MenuProps={{
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                        },
                        transformOrigin: {
                            vertical: "top",
                            horizontal: "left"
                        },
                        style: {zIndex: 10000000, height: '400px'},
                        getContentAnchorEl: null
                        }}
                        onChange={(e)=> this.handleChange(e)}
                    >
                        {dateRangeOptions.map((receiptsDateRange)=>
                        <MenuItem value={receiptsDateRange.value}>{receiptsDateRange.label}</MenuItem>
                        )}
                    </Select>
                    </FormControl>
                </Stack>
                {selectedDateRange === 'custom' &&
                <Group position='apart' noWrap className='rental-income-export-date-range'>
                    {/* {selectedDateRange === 'custom' &&
                    <RangeDatePicker
                        inputClassName={'rental-incom-range-date-picker-input'}
                        mainContainerClassName={'rental-incom-range-date-picker-main-container'}
                        locale="de"
                        customDate={dateRangeOptions[2].date}
                        setDate={(customDate) => {
                        console.log('customDate: ', customDate);
                        let newdateRangeOptions = dateRangeOptions.map((range, index)=> {
                            if(index === 2){
                            range.date = {
                                start: dayjs(customDate.start, 'DD.MM.YYYY'),
                                end: dayjs(customDate.end, 'DD.MM.YYYY')
                            };
                            return range;
                            }else{
                            return range;
                            }
                        });
                        
                        this.setState({ dateRangeOptions: newdateRangeOptions });
                        }}
                    />} */}
                    <DatePicker
                    id='rental-income-export-date-range-picker'
                    className='form-control'
                    selected={dateRangeOptions[2].date.start}
                    onChange={(date)=> this.handleDateRangeChange(date, 'start')}
                    startDate={dateRangeOptions[2].date.start}
                    selectsStart
                    dateFormat="dd.MM.yyyy"
                    showMonthYearPicker
                    popperPlacement='bottom'
                    locale={'de'}
                    maxDate={dateRangeOptions[2].date.end}
                    //customInput={<CustomInput/>}
                    />
                    <span>-</span>
                    <DatePicker
                    id='rental-income-export-date-range-picker'
                    className='form-control'
                    popperPlacement='bottom'
                    selected={dateRangeOptions[2].date.end}
                    onChange={(date)=> this.handleDateRangeChange(date, 'end')}
                    selectsEnd
                    endDate={dateRangeOptions[2].date.end}
                    dateFormat="dd.MM.yyyy"
                    showMonthYearPicker
                    locale={'de'}
                    minDate={dateRangeOptions[2].date.start}
                    //customInput={<CustomInput/>}
                    />
                </Group>}
            </Stack>
            <Group noWrap position='apart'>
            <div>
                <ColorButton
                    onClick={() => {
                    toggle();
                    }}
                    className="gray-btn"
                    text={'Schließen'}
                />
            </div>
            <div>
                <ColorButton
                    onClick={() => downloadTabel(dateRangeOptions.find((range)=> range.value === selectedDateRange).date)}
                    className="purple-btn"
                    text={'Exportieren'}
                />
            </div>
            </Group>
        </Stack>
          
        </CustomModal>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    propertySelected: state.generalReducer.property,
  };
};

export default connect(mapStateToProps)(RentalIncomeExportRangeModal);
