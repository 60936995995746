import React, { Component } from 'react';
import { ColorButton } from '../../../../../neoverv';
import Autoload from '../../../../../neoverv/Autoload';
import { connect } from 'react-redux';
import { Group, Loader, Stack } from '@mantine/core';
import Logo from '../../../../../assets/images/module/general/Logo.svg';
import paymentSuccessMessage from '../../../../../assets/images/module/general/paymentSuccessMessage.svg';
import paymentFailMessage from '../../../../../assets/images/module/general/paymentFailMessage.svg';
import paymentAuthorizeSuccessMessage from '../../../../../assets/images/module/general/paymentAuthorizeSuccessMessage.svg';
import paymentAuthorizeFailMessage from '../../../../../assets/images/module/general/paymentAuthorizeFailMessage.svg';

import { getCookie, setCookie } from '../../../../../neoverv/helpers/Utils';
import {
  setAccountData,
} from '../../../../../redux/action/General/generalActions';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
var { checkProperty, loader } = Autoload.MainHelper;
export class PaymentTemplateSuccess extends Component {
  state = {
    showPaymentMessage: null,
    paymentStatus: '',
    type: null
  };
  paymentLoop = 0;

  componentDidMount() {

    let paymentAuthorizeInProgress = getCookie('PaymentAuthorizeInProgress');
    let paymentReAuthorizeInProgress = getCookie('paymentReAuthorizeInProgress');
    if (paymentAuthorizeInProgress === '1') {
      //loader(true);
      setCookie('PaymentAuthorizeInProgress', '0');
      this.setState({type: 'authorize'});
      console.log('authorize');
      setTimeout(() => {
        this.getPaymentStatus();
        //loader(false);
      }, 3000);
    }else if(paymentReAuthorizeInProgress === '1'){
      //loader(true);
      setCookie('paymentReAuthorizeInProgress', '0');
      this.setState({type: 'reAuthorize'});
      console.log('reAuthorize');
      setTimeout(() => {
        this.getPaymentStatus();
        loader(false);
      }, 3000);
    }
    this.getPaymentStatus();
  }

  getPaymentStatus = () => {
    //loader(true);
    console.log('okkkkkkkkk')
    // Autoload.HttpRequestAxios.HttpRequest(
    //   Autoload.Setting.sign_in_bas_url + 'SignUp/PaymentStatus?accountId=' + this.props.profile.accountId,
    //   'GET',
    //   null,
    //   null,
    //   false,
    //   true,
    //   false,
    //   this,
    //   'handlePaymentStatusResponse',
    //   null,
    //   false,
    //   '',
    //   {
    //     'Content-Type': 'application/json',
    //   }
    // );
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.account_api_baseurl + this.props.profile.accountId,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handlePaymentStatusResponse',
      null,
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
    console.log('okkkkkkkkk')

  };
  handlePaymentStatusResponse(response) {
    console.log('okk')
    this.paymentLoop = this.paymentLoop + 1;
    if (response.status) {
      const status = Autoload.MainHelper.checkProperty(response.result, this.props.type === 'reAuthorize' ? 'accountReAuthorizedPaymentStatus' : 'accountPaymentStatus');
      console.log('status: ', status, this.props.type === 'reAuthorize' ? 'accountReAuthorizedPaymentStatus' : 'accountPaymentStatus');
      switch (status) {
        case 'INITIATED':
          if (this.paymentLoop < 10) {
            setTimeout(() => {
              this.getPaymentStatus();
            }, this.paymentLoop === 0 ? 3000 : this.paymentLoop === 1 ? 2000 : 1000);
            break;
          } else {
            this.setState({ paymentStatus: 'PAYMENT_ERROR' });
            window.Intercom('trackEvent', 'payment-auhorization-failed');
            //Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang.newKeys.text_paymentUpdated_failure);
            loader(false);
            this.setState({ showPaymentMessage: false });
            this.props.onStatusChange(false);
            break;
          }
        case 'AUTHORIZED':
          this.setState({ paymentStatus: status });
          this.props.onStatusChange(true);
          this.props.setAccountData(response.result);
          this.props.getPaymentDetails();
          if(window.location.href.includes('/paymentDetails')){
            this.props.getPaymentHistoryTable();
          }
          //Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang.newKeys.text_paymentUpdated_success);
          loader(false);
          this.setState({ showPaymentMessage: true });
          break;
        case 'PAYMENT_ERROR':
          this.setState({ paymentStatus: 'PAYMENT_ERROR' });
          this.props.onStatusChange(false);
          this.props.setAccountData(response.result);
          window.Intercom('trackEvent', 'payment-auhorization-failed');
          //Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang.newKeys.text_paymentUpdated_failure);
          loader(false);
          this.setState({ showPaymentMessage: false });
          break;
        default:
          this.setState({ paymentStatus: 'SERVER_ERROR' });
          this.props.onStatusChange(false);
          this.props.setAccountData(response.result);
          window.Intercom('trackEvent', 'payment-auhorization-failed');
          //Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang.newKeys.text_paymentUpdated_failure);
          loader(false);
          this.setState({ showPaymentMessage: false });
          break;
      }
    } else {
      this.setState({ paymentStatus: 'SERVER_ERROR' });
      this.props.onStatusChange(false);
      window.Intercom('trackEvent', 'payment-auhorization-failed');
      //Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang.newKeys.text_paymentUpdated_failure);
      this.setState({ showPaymentMessage: false });
      loader(false);
    }
  }

  render() {
    const {showPaymentMessage, paymentStatus} = this.state;
    const {onNext, toggle, type} = this.props;
    console.log('PaymentType:', type);
    return (
        <Stack className='payment-template-success' align={'center'} >
            <Group>
                {showPaymentMessage !== null ?
                  <img src={paymentStatus === 'AUTHORIZED' ? (type === 'authorize' ? (this.props.history.location.pathname.toString().includes('/paymentDetails') ? paymentSuccessMessage : paymentAuthorizeSuccessMessage) : paymentSuccessMessage) : (type === 'authorize' ? paymentAuthorizeFailMessage : paymentFailMessage)} />
                  :
                  <Group>
                    {/* <Loader style={{margin: '42px 0'}}/> */}
                    <span style={{margin: '42px 0'}}>Bitte haben Sie einen Moment Geduld.</span>
                  </Group>
                }
                {/* {paymentStatus} */}
            </Group>
            <Group style={{width: '100%', justifyContent: paymentStatus === 'AUTHORIZED' ? 'flex-end' : 'space-between', marginTop: '10px'}} >
                {paymentStatus !== 'AUTHORIZED' &&
                 <div>
                    <ColorButton 
                        text={'Abbrechen'}
                        className='gray-btn'
                        onClick={()=> toggle()
                          //type === 'authorize' ? this.props.history.push('/profile/yourSubscription') : this.props.history.push('/profile/paymentDetails/')
                        }
                    />
                </div> }
                <div>
                    <ColorButton 
                        text={paymentStatus !== 'AUTHORIZED' ? 'Erneut probieren' : (type === 'authorize' ? (this.props.history.location.pathname.toString().includes('/paymentDetails') ? 'Schließen' : 'Weiter') : 'Schließen')}
                        className='purple-btn'
                        onClick={()=> showPaymentMessage !== null && 
                        (paymentStatus !== 'AUTHORIZED' ? 
                        (type === 'authorize' ? this.props.authorize() : this.props.reAuthorize())
                        :
                        (showPaymentMessage === true && type === 'authorize') ? onNext() : toggle())
                          //type === 'authorize' ? this.props.history.push('/profile/yourSubscription?paymentStatus='+showPaymentMessage) : this.props.history.push('/profile/paymentDetails/')
                        }
                        showLoader={showPaymentMessage === null}
                    />
                </div>
            </Group>
        </Stack>
    );
  }
}

// export default YourSubscription;

const mapStateToProps = (state) => {
  return {
    profile: state.generalReducer.profile,
    AccountApi: state.generalReducer.account_api_id,
  };
};
const actions = {
  setAccountData,
}


export default connect(mapStateToProps, actions)(PaymentTemplateSuccess);
