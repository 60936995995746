import { Group, Modal, Stack } from '@mantine/core';
import React from 'react';
import { Form } from 'react-bootstrap';
import CustomModal from '.';
import ColorButton from '../Buttons/ColorButton';
import GoldenLockIcon from '../../../../../assets/images/module/general/GoldenLockIcon.svg';
import PremiumBadgeIcon from '../../../../../assets/images/module/general/PremiumBadgeIcon.svg';
import ProfessionalBadgeIcon from '../../../../../assets/images/module/general/ProfessionalBadgeIcon.svg';
import { connect } from 'react-redux';


function BuyPremiumModal(props) {
  const { text, onClick, open, heading, toggle, cancel, onCancel, dataSecured, rightButtonText='', headingClassName, redButton=false, middle=false, onMiddle, middleText, accountData, currentSubscription } = props;
  return (
    <>
    <Modal 
    zIndex={1000000}
    opened={open} 
    onClose={onCancel} 
    withCloseButton={false}
    title={<span style={{color: '#5555FF', fontSize: '16px'}}>{heading ? heading : 'Weitere Mieteinheiten freischalten'}</span>}
    style={{width: '424px'}}
    closeOnEscape={true}
    closeOnClickOutside={true}
    centered
    styles={{
        header:{
            display: 'flex',
            justifyContent: 'center'
        },
    }}
    >
        <Stack style={{padding: '10px'}}>
            <Group style={{justifyContent: 'center'}}>
                <img src={GoldenLockIcon} width='18px'/>
            </Group>
            <Group style={{justifyContent: 'center'}}>
                {text}
            </Group>
            <Group>
                <ColorButton
                text={currentSubscription ? (currentSubscription.subscriptionName === 'Starter' ? (accountData.isPremiumPlanTested === false ? '1 Monat kostenlos testen' : 'Jetzt freischalten') : currentSubscription.subscriptionName === 'Premium' ? (accountData.isProfessionalPlanTested === false ? '1 Monat kostenlos testen' : 'Jetzt freischalten') : 'Jetzt freischalten') : 'Jetzt freischalten'}
                onClick={onClick}
                className={'purple-btn'}
                />
            </Group>
        </Stack>
    </Modal>
    {/* <CustomModal
    heading={<img src={heading === 'Premium' ? PremiumBadgeIcon : ProfessionalBadgeIcon}/>}
    headingClassName={headingClassName}
    open={open}
    toggle={toggle}
    style={{width: '424px'}}
    styleHeader={{display: 'flex', justifyContent: 'center'}}
    >
        <Stack style={{padding: '10px'}}>
            <Group style={{justifyContent: 'center'}}>
                <img src={GoldenLockIcon} width='18px'/>
            </Group>
            <Group style={{justifyContent: 'center'}}>
                {text}
            </Group>
            <Group>
                <ColorButton
                text={'Jetzt freischalten'}
                onClick={onClick}
                className={'purple-btn'}
                />
            </Group>
        </Stack>
    </CustomModal> */}
    </>
  );
}
const mapStateToProps = (state) => {
    return {
      accountData: state.generalReducer.accountData,
      currentSubscription: state.generalReducer.currentSubscription,
    };
  };
  export default connect(mapStateToProps)(BuyPremiumModal);
  
