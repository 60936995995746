import React, { Component } from 'react';
import { ColorButton } from '../../../../../neoverv';
import Autoload from '../../../../../neoverv/Autoload';
import { connect } from 'react-redux';
import { Group, Stack } from '@mantine/core';
import Logo from '../../../../../assets/images/module/general/Logo.svg';
import paymentSuccessMessage from '../../../../../assets/images/module/general/paymentSuccessMessage.svg';
import paymentFailMessage from '../../../../../assets/images/module/general/paymentFailMessage.svg';

import { getCookie, setCookie } from '../../../../../neoverv/helpers/Utils';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
var { checkProperty } = Autoload.MainHelper;
export class PaymentTemplateSuccess extends Component {
  state = {
    showPaymentMessage: null,
    paymentStatus: '',
    type: null
  };
  paymentLoop = 0;

  componentDidMount() {

    let paymentAuthorizeInProgress = getCookie('PaymentAuthorizeInProgress');
    let paymentReAuthorizeInProgress = getCookie('paymentReAuthorizeInProgress');
    if (paymentAuthorizeInProgress === '1') {
      setCookie('PaymentAuthorizeInProgress', '0');
      this.setState({type: 'authorize'});
      setTimeout(() => {
        this.getPaymentStatus();
      }, 3000);
    }else if(paymentReAuthorizeInProgress === '1'){
      setCookie('paymentReAuthorizeInProgress', '0');
      this.setState({type: 'reAuthorize'});
      setTimeout(() => {
        this.getPaymentStatus();
      }, 3000);
    }
    this.getPaymentStatus();
  }

  getPaymentStatus = () => {
    //loader(true);
    console.log('okkkkkkkkk')
    // Autoload.HttpRequestAxios.HttpRequest(
    //   Autoload.Setting.sign_in_bas_url + 'SignUp/PaymentStatus?accountId=' + this.props.profile.accountId,
    //   'GET',
    //   null,
    //   null,
    //   false,
    //   true,
    //   false,
    //   this,
    //   'handlePaymentStatusResponse',
    //   null,
    //   false,
    //   '',
    //   {
    //     'Content-Type': 'application/json',
    //   }
    // );
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.account_api_baseurl + this.props.profile.accountId,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handlePaymentStatusResponse',
      null,
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
    console.log('okkkkkkkkk')

  };
  handlePaymentStatusResponse(response) {
    console.log('okk')
    this.paymentLoop = this.paymentLoop + 1;
    if (response.status) {
      const status = Autoload.MainHelper.checkProperty(response.result, this.state.type === 'reAuthorize' ? 'accountReAuthorizedPaymentStatus' : 'accountPaymentStatus');

      switch (status) {
        case 'INITIATED':
          if (this.paymentLoop < 4) {
            setTimeout(() => {
              this.getPaymentStatus();
            }, 5000);
            break;
          } else {
            this.setState({ paymentStatus: 'PAYMENT_ERROR' });
            //Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang.newKeys.text_paymentUpdated_failure);
            //loader(false);
            this.setState({ showPaymentMessage: false });
            break;
          }
        case 'AUTHORIZED':
          this.setState({ paymentStatus: status });
          //Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang.newKeys.text_paymentUpdated_success);
          //loader(false);
          this.setState({ showPaymentMessage: true });
          break;
        case 'PAYMENT_ERROR':
          this.setState({ paymentStatus: 'PAYMENT_ERROR' });
          //Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang.newKeys.text_paymentUpdated_failure);
          //loader(false);
          this.setState({ showPaymentMessage: false });
          break;
        default:
          this.setState({ paymentStatus: 'SERVER_ERROR' });
          //Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang.newKeys.text_paymentUpdated_failure);
          //loader(false);
          this.setState({ showPaymentMessage: false });
          break;
      }
    } else {
      this.setState({ paymentStatus: 'SERVER_ERROR' });
      //Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang.newKeys.text_paymentUpdated_failure);
      this.setState({ showPaymentMessage: false });
      //loader(false);
    }
  }

  render() {
    const {showPaymentMessage, paymentStatus, type} = this.state;
    return (
        <Stack className='payment-template-success' align={'center'} >
            <Group>
                <img src={Logo} width={'155px'} height={'79px'} />
            </Group>
            <Group>
                <span className='payment-template-success-title'>Zahlung erfolgreich</span>
            </Group>
            <Group>
                <img src={showPaymentMessage !== null && (showPaymentMessage ? paymentSuccessMessage: paymentFailMessage)} />
                {/* {paymentStatus} */}
            </Group>
            <Group style={{width: '352px', justifyContent: 'space-between', marginTop: '60px'}} >
                <div>
                    <ColorButton 
                        text={'Zurück'}
                        className='gray-btn'
                        onClick={()=> type === 'authorize' ? this.props.history.push('/profile/yourSubscription', {newSubscription: true}) : this.props.history.push('/profile/paymentDetails/')}
                    />
                </div>
                <div>
                    <ColorButton 
                        text={'Weiter'}
                        className='purple-btn'
                        onClick={()=> type === 'authorize' ? this.props.history.push('/profile/yourSubscription?paymentStatus='+showPaymentMessage) : this.props.history.push('/profile/paymentDetails/')}
                    />
                </div>
            </Group>
        </Stack>
    );
  }
}

// export default YourSubscription;

const mapStateToProps = (state) => {
  return {
    profile: state.generalReducer.profile,
    AccountApi: state.generalReducer.account_api_id,
  };
};

export default connect(mapStateToProps, null)(PaymentTemplateSuccess);
