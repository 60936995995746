import React, { Component } from 'react';
import ReactToPdf from 'react-to-pdf'
import Autoload from '../../../../../neoverv/Autoload';
import Logo from '../../../../../assets/images/module/general/Logo.svg';
import moment from 'moment';
import { EmptyStateContainer, UtilityBillsTableRow } from '../../../../../neoverv';
import { connect } from 'react-redux';
let { loader, ConverterCurrencyDe } = Autoload.MainHelper
var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];

const ref = React.createRef()
const btnRef = React.createRef();
class InvoiceTemplate extends Component {
  state = {
    invoiceData: null,
  };
  componentDidMount() {
    const id = window.location.pathname.split('/')[3]
    this.getPaymentHistory(id)
    // loader(false)

  }
  getPaymentHistory = (id) => {
    console.log(id, 'getPaymentHistoryPDF');
    Autoload.HttpRequestAxios.HttpRequest(
      // Autoload.Setting.user_reg_api_baseurl + 'Invoices/' + id + '/download', // direct download
      Autoload.Setting.user_reg_api_baseurl + 'Invoices/' + id,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handle_payment_history_pdf',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };

  handle_payment_history_pdf = (response) => {
    console.log('handle_payment_history_pdf', response);
    this.setState({
      invoiceData: response.result,
    });


  };

  getTableLabels = () => {
    return (
      <tr style={{ display: 'flex', height: 48, fontSize: 14, fontWeight: 500, flex: 1 }}>
        <td style={{ border: '1px solid #DADAE6', height: 48, display: 'flex', alignItems: 'center', justifyContent: 'center', width: '38%' }}>{`Position`}</td>
        <td style={{ border: '1px solid #DADAE6', height: 48, display: 'flex', alignItems: 'center', justifyContent: 'center', width: '19%' }}>{`Leistungszeitraum`}</td>
        <td style={{ border: '1px solid #DADAE6', height: 48, display: 'flex', alignItems: 'center', justifyContent: 'center', width: '14%' }}>{`Anzahl`}</td>
        <td style={{ border: '1px solid #DADAE6', height: 48, display: 'flex', alignItems: 'center', justifyContent: 'center', width: '10.5%' }}>{`Kosten`}</td>
        <td style={{ border: '1px solid #DADAE6', height: 48, display: 'flex', alignItems: 'center', justifyContent: 'center', width: '10.7%' }}>{`MwSt. inkl.`}</td>
        <td style={{ border: '1px solid #DADAE6', height: 48, display: 'flex', alignItems: 'center', justifyContent: 'center', width: '8.9%' }}>{`Betrag`}</td>
      </tr>
    );
  };

  renderTableRows = () => {
    if (this.state.invoiceData && this.state.invoiceData.positions?.length) {
      return this.state.invoiceData.positions.map((data) => (
        <tr style={{
          border: 'none',
          background: 'none',
          height: '48px',
          width: '100%',
          display: 'flex',
          textAlign: 'left',
        }}>
          <td className="ub-label-cost" style={{ width: '38%', padding: '14px 15px 14px 16px', margin: 0, fontSize: 14, lineHeight: '20px', border: '0.5px solid #DADAE6', display: 'flex', alignItems: 'center' }}>{data.description}</td>
          <td className="ub-label-total-cost" style={{ width: '19%', padding: '14px 15px 14px 16px', margin: 0, fontSize: 14, lineHeight: '20px', border: '0.5px solid #DADAE6', display: 'flex', alignItems: 'center' }}>{`${moment(data.startDate).format('DD.MM.YYYY')} - ${moment(data.endDate).format('DD.MM.YYYY')}`} </td>
          <td className="ub-label-key" style={{ justifyContent: 'flex-end', width: '14%', padding: '14px 15px 14px 16px', margin: 0, fontSize: 14, lineHeight: '20px', border: '0.5px solid #DADAE6', display: 'flex', alignItems: 'center' }}>{`1`}</td>
          <td className="ub-label-total-value" style={{ justifyContent: 'flex-end', width: '10.5%', padding: '14px 15px 14px 16px', margin: 0, fontSize: 14, lineHeight: '20px', border: '0.5px solid #DADAE6', display: 'flex', alignItems: 'center' }}>{`${data.cost.toFixed(2).replace('.', ',')}€`}</td>
          <td className="ub-label-tenant-value" style={{ justifyContent: 'flex-end', width: '10.7%', padding: '14px 15px 14px 16px', margin: 0, fontSize: 14, lineHeight: '20px', border: '0.5px solid #DADAE6', display: 'flex', alignItems: 'center' }}>{`${data.taxRate}%`}</td>
          <td className="ub-label-unit " style={{ justifyContent: 'flex-end', width: '8.9%', padding: '14px 15px 14px 16px', margin: 0, fontSize: 14, lineHeight: '20px', border: '0.5px solid #DADAE6', display: 'flex', alignItems: 'center' }}>{`${data.cost.toFixed(2).replace('.', ',')}€`}</td>
        </tr>
      ))
    }
  }

  render() {
    const { utilityData } = this.state;
    const { currentProperty, currentUser } = this.props;

    return (
      <div style={{ overflow: 'auto', background: '#fff', height: '95vh', }}>
        {/* <ReactToPdf targetRef={ref} filename="Invoice.pdf" scale={0.65} onComplete={() => {
          setTimeout(() => {
            window.close()
          }, 1000);
        }} >
          {({ toPdf }) => (
            <button onClick={toPdf}  ref={btnRef}>Generate pdf</button>
          )}
        </ReactToPdf> */}
        <div ref={ref} style={{ padding: '24px 48px',height:'100%',display:'flex',flexDirection:'column', justifyContent:'space-between' }}>
          <div style={{ overflow: 'hidden' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', flexDirection: 'column', }}>
                <div style={{ marginBottom: 32 }}>
                  <h2 style={{ color: '#13135C', fontWeight: 700 }}>Rechnung</h2>
                </div>
                <div style={{ marginBottom: 40 }}>
                  <p style={{ fontWeight: 600, fontSize: 18 }}>{currentUser?.name}</p>
                  <p style={{ fontSize: 15, marginBottom: 8 }}>{currentUser?.company}</p>
                  <p style={{ fontSize: 15, marginBottom: 8 }}>{currentUser?.address}</p>
                  <p style={{ fontSize: 15, marginBottom: 8 }}>{currentUser?.zip}</p>
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                <div style={{ marginBottom: 32 }}>
                  <img src={Logo} alt="" />
                </div>
                <div style={{ marginBottom: 40 }}>
                  <p style={{ textAlign: 'right' }}>Hello Housing GmbH<br />
                    Wattstr. 11, 13355 Berlin</p>
                  <p>{utilityData?.date}</p>
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', }}>
              <div style={{ width: 270, display: 'flex', justifyContent: 'center', flexDirection: 'column', }}>
                <p style={{ fontSize: 16, marginBottom: 4 }}>Kundenkonto</p>
                <p style={{ fontSize: 20, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{this.state.invoiceData?.accountNo ? this.state.invoiceData.accountNo : '-'}</p>
              </div>
              <div style={{ width: 270, display: 'flex', justifyContent: 'center', flexDirection: 'column', }}>
                <p style={{ fontSize: 16, marginBottom: 4 }}>Rechnungsnummer</p>
                <p style={{ fontSize: 20, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{this.state.invoiceData ? this.state.invoiceData.invoiceNumber : '-'}</p>
              </div>
              <div style={{ width: 270, display: 'flex', justifyContent: 'center', flexDirection: 'column', }}>
                <p style={{ fontSize: 16, marginBottom: 4 }}>Rechnungsdatum</p>
                <p style={{ fontSize: 20, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{moment(this.state.invoiceData ? this.state.invoiceData.invoiceDate : undefined).format('DD.MM.YYYY')}</p>
              </div>
            </div>
            <>
              <table style={{ width: '100%' }}>
                <thead>
                  <th style={{ background: '#E3E3FC', width: '100%', border: '1px solid #DADAE6', fontSize: 14, padding: 0, color: '#000', display: 'flex' }} >{this.getTableLabels()}</th>
                </thead>
                <tbody className="ub-body" style={{ background: 'white' }}>
                  {/* {this.state.selectedTenantData &&
                  this.state.selectedTenantData.tenantRows &&
                  this.state.selectedTenantData.tenantRows.length ? (
                  this.state.selectedTenantData.tenantRows.map((billRow) => {
                    return (
                      <UtilityBillsTableRow
                        tenantID={this.state.selectedTenantID}
                        billRow={billRow}
                        updateCommentLocalState={this.updateCommentLocalState}
                        billingTemplate
                      />
                    );
                  })
                ) : (
                  <EmptyStateContainer heading={lang.utility.text_no_tenant_bills} icon={null} showBtn={false} />
                )} */}

                  {this.renderTableRows()}

                </tbody>
              </table>
            </>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 24, marginBottom: 48 }}>
              <div style={{ width: 320 }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <p style={{ marginBottom: 6, fontSize: 14 }}>Zwischesumme</p>
                  <p style={{ marginBottom: 6, fontSize: 14 }}>{this.state.invoiceData ? `${this.state.invoiceData?.totalAmount?.toFixed(2).replace('.', ',')}€` : '0€'}</p>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <p style={{ marginBottom: 6, fontSize: 14 }}>Guthaben</p>
                  <p style={{ marginBottom: 6, fontSize: 14 }}>-0,00€</p>
                </div>
                <hr />
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <p style={{ marginBottom: 6, fontSize: 14 }}>Gesamtbetrag</p>
                  <p style={{ marginBottom: 6, fontSize: 14 }}>{this.state.invoiceData ? `${this.state.invoiceData.totalAmount?.toFixed(2).replace('.', ',')}€` : '0€'}</p>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <p style={{ marginBottom: 6, fontSize: 14 }}>inkl. Mwst.</p>
                  <p style={{ marginBottom: 6, fontSize: 14 }}>{this.state.invoiceData ? `${this.state.invoiceData.totalVATAmount?.toFixed(2).replace('.', ',')}€` : '0€'}</p>
                </div>
              </div>
            </div>
            <p style={{ fontSize: 12 }}>Der Gesamtbetrag wird am 01.12.2021 über die von Ihnen authorisierte Zahlungsmethode eingezogen. Vielen Dank, dass Sie hellohousing nutzen!</p>
          </div>
          <p style={{ fontSize: 10, letterSpacing: 0.5, textAlign: 'center', color: '#676767' }}> {`Hello Housing GmbH, Wattstr. 11, 13355 Berlin, Registergericht: Berlin, Amtsgericht Charlottenburg, HRB 226520 B Geschäftsführung: Jörg Radeke, Dr. Frank Radeke, UID: DE341845883`}</p>
        </div >
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  // console.log('stateee', state.UtilityBillingReducer)
  return {
    // allUtilityBills: state.UtilityBillingReducer.utilityBillingData,
    currentProperty: state.generalReducer.property,
    currentUser: state.generalReducer.profile,

  };
};

const mapDispatchToProps = {

};


export default connect(mapStateToProps, mapDispatchToProps)(InvoiceTemplate);
