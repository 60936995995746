import Dashboard from '../../../views/pages/Module/General/Dashboard';
import Error404 from '../../../views/pages/Module/General/error/404';
import Error500 from '../../../views/pages/Module/General/error/500';

const pageList = [
  {
    name: 'Dashboard',
    path: '/dashboard/',
    component: Dashboard,
  },
  {
    name: '404',
    path: '/404/',
    component: Error404,
  },
  {
    name: '500',
    path: '/500/',
    component: Error500,
  },
  
];

export default pageList;
