import React, { Component } from 'react';
import { SignupLayout, SignupCards, ColorButton } from '../../../../../neoverv';
import greenCross from '../../../../../assets/images/module/general/green-cross.svg';
import redCross from '../../../../../assets/images/module/general/red-cross.png';
import NotVerified from '../../../../../assets/images/module/general/nonVerified.png';
import Verified from '../../../../../assets/images/verified.svg';
import Autoload from '../../../../../neoverv/Autoload';

import { delete_cookie } from '../../../../../neoverv/helpers/Utils';
import { connect } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import {
  setProfileData,
  changeCurrentProperty,
  setPropertyList,
  stepCountSignUp,
  setVerificationStatus,
  setUserDataSignUp,
} from '../../../../../redux/action/General/generalActions';
import { getCookie } from '../../../../../neoverv/helpers/Utils';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];

export class PaymentVerification extends Component {
  state = {
    update: '',
    paymentStatus: '',
    steps: null,
    // status:'failed'
  };
  paymentLoop = 0;

  getPaymentStatus() {
    console.log('this.props.userDataSignUp', this.props.userDataSignUp);
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'block';
    var payment_page = document.getElementById('respond-payment-page');
    payment_page.style.display = 'none';
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.sign_in_bas_url + 'SignUp/PaymentStatus?accountId=' + this.props.userDataSignUp.accountId,
      // 'https://virtserver.swaggerhub.com/neoverv_berlin/NV-HH-API/0.9.2/signUp/paymentStatus/123',
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handlePaymentStatusResponse',
      null,
      false,
      '',

    );
  }

  handlePaymentStatusResponse(response) {
    console.log('ok')
    this.paymentLoop = this.paymentLoop + 1;
    const status = Autoload.MainHelper.checkProperty(response.result, 'paymentStatus');
    console.log(response)
    if (response.status) {
      const status = Autoload.MainHelper.checkProperty(response.result, 'paymentStatus');
      console.log(status)
      switch (status) {
        case 'INITIATED':
          if (this.paymentLoop < 3) {
            setTimeout(() => {
              this.getPaymentStatus();
            }, 4000);
            break;
          } else {
            this.setState({ paymentStatus: 'PAYMENT_ERROR' });
            Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang.user.text_error_payment_server);
            this.hideLoader();
            break;
          }
        case 'AUTHORIZED':
          this.setState({ paymentStatus: status });
          this.props.setUserDataSignUp({ ...response.result });
          Autoload.DataHandlerGeneral.GeneralErrorMessage(false);
          this.hideLoader();
          break;
        case 'PAYMENT_ERROR':
          this.setState({ paymentStatus: 'PAYMENT_ERROR' });
          Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang.user.text_error_payment_status);
          this.hideLoader();
          break;
        default:
          this.setState({ paymentStatus: 'SERVER_ERROR' });
          Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang.user.text_error_payment_server);
          this.hideLoader();

          delete_cookie('signup_account_details');
          break;
      }
    } else {
      this.setState({ paymentStatus: 'SERVER_ERROR' });
      Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang.user.text_error_payment_server);
      this.hideLoader();
      delete_cookie('signup_account_details');
      // switch (status) {
      //   case 'SERVER_ERROR':
      //     this.setState({ paymentStatus: 'SERVER_ERROR' });
      //     Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang.user.text_error_payment_server);
      //     this.hideLoader();
      //     break;
      //   default:
      //     this.setState({ paymentStatus: 'SERVER_ERROR' });
      //     Autoload.DataHandlerGeneral.GeneralErrorMessage(
      //       true,
      //       'Zugriff nicht möglich, bitte kontaktieren Sie den Support.'
      //     );
      //     this.hideLoader();

      //     delete_cookie('signup_account_details');
      //     break;
      // }
    }
  }

  moveForward = (step) => {
    var btn_loader = document.getElementById('c-btn-loader');
    btn_loader.style.display = 'block';
    // this.setState({ steps: this.props.steps + 1 });
    this.props.stepCountSignUp(6);
    this.props.history.push('/signup', { step: 6 });
    // window.location.href = '/signup';
    btn_loader.style.display = 'none';
  };

  moveBack = () => {
    // this.setState({ steps: this.props.steps - 1 });
    // this.props.stepCountSignUp(this.props.steps);
    this.props.stepCountSignUp(4);
    this.props.history.push('/signup', { step: 4 });
  };

  componentDidMount() {
    // const urlType = window.location.href.indexOf('success') > -1 ? 'success' : 'failed';
    // this.setState({ paymentStatus: urlType }, ()=> this.hideLoader());
    const cookieData = getCookie('signup_account_details');
    if (cookieData) {
      Autoload.DataHandlerGeneral.GeneralErrorMessage(false);
      this.getPaymentStatus();
    } else {
      Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang.user.text_error_process);
      this.props.history.push('/signup', { step: 1 });
    }
    // this.props.setVerificationStatus(window.location.href.indexOf('success') > -1 ? 'success' : 'failed');
  }

  hideLoader = () => {
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'none';
    var emptyDiv = document.getElementById('empty-payment-page');
    emptyDiv.style.display = 'none';
    var dataDiv = document.getElementById('respond-payment-page');
    dataDiv.style.display = 'block';
  };

  renderResponse() {
    const { paymentStatus } = this.state;
    if (paymentStatus === 'SERVER_ERROR') {
      return null;
    } else if (this.state.paymentStatus !== 'AUTHORIZED') {
      return (
        <div>
          <div className="failed-div">
            <div className="payment-details-div">
              <div className="payment-left-icon">
                <img src={NotVerified} alt="icon" />
              </div>
              <div className="payment-center-text">{lang.user.text_unauthorize_payment}</div>
              <div className="payment-right-icon">{/* <img src={redCross} alt="icon" /> */}</div>
            </div>
            <div className="payment-detail-row-two">
              <p className="payment-text-two">{lang.user.text_try_again}</p>
            </div>
          </div>
          <br />
          <ColorButton
            onClick={(e) => {
              e.preventDefault();
              this.moveBack();
              // window.location.href = '/signup';
            }}
            text={lang.newKeys.Go_back_to_payment_details}
            className="gray-btn"
          />
        </div>
      );
    } else {
      return (
        <div className="success-div">
          <div className="payment-details-div">
            <div className="payment-left-icon">
              <img src={Verified} alt="icon" />
            </div>
            <div className="payment-center-text">{lang.user.text_authorize_payment}</div>
            <div className="payment-right-icon">{/* <img src={greenCross} alt="icon" /> */}</div>
          </div>
          <div className="payment-detail-row-two">
            <p className="payment-text-two">{lang.user.text_process_further}</p>
          </div>
        </div>
      );
    }
  }

  render() {
    console.log(this.props.update, this.props.userDataSignUp, this.props.history, 'update in verify');
    let steps = this.props.steps;
    return (
      <div>
        <div className="hh_loader" id="hello-housing-loader" style={{ display: 'none' }}>
          <div className="hh_loader_bg"></div>
          <Spinner animation="border" className="hh_loader_spinner" variant="primary" />
        </div>
        <div id="empty-payment-page"></div>
        <div className="verify-non-berify-accounts" id="respond-payment-page">
          <SignupLayout
            handleNext={this.moveForward}
            handleBack={this.moveBack}
            steps={4}
            // VerificationStatus={this.props.verificationStatus}
            VerificationStatus={this.state.paymentStatus}
          >
            <div className="steps-content">
              <SignupCards
                steps={this.props.steps}
                heading={lang.user.text_payment_details}
                // subheading={lang.user.text_payment_sub_heading}
                className="welcome-step"
              >
                {/* {this.props.verificationStatus === 'success' ? ( */}
                {this.renderResponse()}
              </SignupCards>
            </div>
          </SignupLayout>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    steps: state.generalReducer.step,
    verificationStatus: state.generalReducer.verificationStatus,
    userDataSignUp: state.generalReducer.userDataSignup,
  };
};
const actions = {
  setProfileData,
  changeCurrentProperty,
  setPropertyList,
  stepCountSignUp,
  setVerificationStatus,
  setUserDataSignUp,
};
export default connect(mapStateToProps, actions)(PaymentVerification);
