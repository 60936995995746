import * as ActionTypes from '../../types/meter';

const initialState = [
  // {
  //   id: 0.1,
  //   meterNumber: 41120,
  //   //cost type
  //   meterType: 'Wärmemenge (MWh)',
  //   readingDate: '30.05.19',
  //   startValue: '30,5',
  //   currentValue: '48',
  //   consumption: '17,5 MWh',
  //   note: '',
  //   rentalUnitId: 1,
  //   tenantId: 3,
  //   occasion: 'Move out',
  // },
];

export const interimReadingReducer = (state = initialState, action) => {
  //data from
  // console.log("reducerrrr ", action.payload)
  switch (action.type) {
    case ActionTypes.GET_INTERIM_READINGS:
      return state;

    case ActionTypes.DATA_FILTER:
      let reading;
      let result = action.payload.data.tableData.filter((data) => {
        reading = action.payload.state.billingPeriod.filter((period) => {
          if (data.id == period.id) {
            //  console.log("period",period)
            return period.rentalUnits;
          }
        });
      });
      // console.log("to edit",reading)

      return reading;
    case ActionTypes.TO_EDIT_INTERIM_READING:
      let tenant;
      state.rentalUnits.filter((unit, x) => {
        if (unit.id == action.payload.unit.id) {
          tenant = unit.tenants.filter((ten, i) => {
            if (ten.id == action.payload.tenant.id) return ten.data;
          });
        }
      });

      //   console.log("to edit",tenant)

      return tenant;

    case ActionTypes.ADD_INTERIM_READING:
      //  console.log(action.payload);

      const { editData, addData } = action.payload;
      //  // ;

      let edited;
      let returned;
      let final = editData.map((edit) => {
        console.log(edit);
        edited = state.map((data, i) => {
          if (data.id == edit.id && data.rentalUnitId == edit.rentalUnitId && data.tenantId == edit.tenantId) {
            returned = Object.assign(data, edit);
            // // ;
            return returned;
          }
          return data;
        });
      });
      console.log(edited);
      if (addData == null) {
        return edited;
      } else {
        let newInterimReadings = [...edited, ...addData];
        return newInterimReadings;
      }

    //  return final

    // // ;
    // let addedRecord;
    // addedRecord= data.map((dat,i)=>{
    //    return {...dat,id:state.length+i,tenantId,rentalUnitId:unitId}

    // })

    // console.log(addedRecord)
    // return [...state,...addedRecord]

    case ActionTypes.EDIT_INTERIM_READING:
      console.log(action.payload);
      //  // 
      return [...state, ...action.payload];

    default:
      return state;
  }
};
