import React, { Component } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import Receipt from '../../../../../assets/images/module/general/ReceiptDummy.png';
import { Row, Col, Form, Tooltip, OverlayTrigger } from 'react-bootstrap';
import CustomModal from '.';
import { showNotification } from '@mantine/notifications';
import ColorButton from '../Buttons/ColorButton';
import CopyIcon from '../../../SVGs/copyIcon';
import GreenTick from '../../../../../assets/images/module/general/GreenTick.svg';
import { Group } from '@mantine/core';
import EmptyStateContainer from '../EmptyStateContainer/EmptyStateContainer';
import TenantIconBlue from '../../../../../assets/images/module/general/TenantIconBlue.svg';
import Autoload from '../../../../Autoload';

const MOBILE_SIZE = 992;
const TABLET_SIZE = 1200;

export class DeactivateTenantModal extends Component {
  state = {
    isMobile: window.innerWidth <= MOBILE_SIZE,
    isTablet: window.innerWidth <= TABLET_SIZE
  };
  
  constructor(props) {
    super(props);
  }

  componentDidMount(){
    console.log('this.props.selectedTenant: ', this.props.selectedTenant);
  }

  deactivateTenant=(ten)=>{
    Autoload.HttpRequestAxios.HttpRequest(
        Autoload.Setting.user_api_baseurl + ten.id +'/TenantActivation?isActivate='+ false,
        'PUT',
        null,
        null,
        false,
        true,
        true,
        this,
        '_handleDeactivateUser',
        null,
        false,
        '',
        { 'Content-Type': 'application/json', Authorization: Autoload.Utils.getAuthToken() },
        true
      );
  }

  _handleDeactivateUser(response){
    if(response.status && response.statuscode === 200){
        this.props.getAllUsersByAccountId();
        this.props.toggle();
    }
  }
  
  render() {
    const { open, toggle, className, selectedTenant } = this.props;
    const {isMobile, isTablet} = this.state;
    
    return (
      <CustomModal
        heading={'Zugang deaktivieren'}
        headingClassName={'tenant-deactivate-modal-heading'}
        open={open}
        toggle={() => {
          toggle();
        }}
        className={`${className}`}
      >
        <div>
            <div>
                <Row>
                    <Col xs={12}>
                        <span style={{fontFamily: 'Inter, sans-serif', fontWeight: '400'}}>Wenn Sie den Zugang deaktivieren, wird der Mieter nicht mehr auf das Mieterportal zugreifen können. Sind Sie sicher, dass Sie fortfahren wollen?</span>
                    </Col>
                </Row>
              <div className="center-spacing add-receipt-modal-footer" style={{margin: '22px 0px 0px', display: 'flex', justifyContent: 'space-between'}} id="receipt-add-edit-btns">
                <div>
                  <ColorButton
                    onClick={() => {
                      toggle();
                    }}
                    className="gray-btn"
                    text={'Abbrechen'}
                  />
                </div>
                <div>
                  <ColorButton
                    onClick={() => {
                      this.deactivateTenant(selectedTenant);
                    }}
                    className="red-btn"
                    text={'Deaktivieren'}
                  />
                </div>
              </div>
              </div>
          </div>
      </CustomModal>
    );
  }
}

export default DeactivateTenantModal;
