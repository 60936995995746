import React, { Component } from 'react';
import { ColorButton, CustomCard } from '../../../../../neoverv';
import { Form } from 'react-bootstrap';
import EyeIcon from '../../../../../assets/images/module/general/EyeIcon.svg';
import CrossIcon from '../../../../../assets/images/module/general/CrossIcon.svg';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  setProfileData,
  changeCurrentProperty,
  setPropertyList,
} from '../../../../../redux/action/General/generalActions';
import Autoload from '../../../../../neoverv/Autoload';
import moment from 'moment';
import Spinner from 'react-bootstrap/Spinner';
import { showNotification } from '@mantine/notifications';
const { loader } = Autoload.MainHelper;
var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default].general;

export class Login extends Component {
  state = {
    // email: 'luisa.groos@neoverv.com',
    email: '',
    emailErr: false,
    // password: 'Test4hellohousing!',
    password: '',
    passErr: false,
    showPassword: false,
    err_invalid_login: false,
    del_invalid_account: false,
    err_invalid_role_permission: false
  };

  componentDidMount() {
    Autoload.Utils.check_auth(this);
    window.Intercom("boot", {
      api_base: "https://api-iam.intercom.io",
      app_id: "vvrs5bx3",
      Environment: window.location.hostname.includes('app') ? 'Production' : 'Staging',
    });
  }

  onInputChange = (field, e) => {
    this.setState({ [field]: e.target.value });
  };
  toggleShowPassword = (id) => {
    var x = document.getElementById(id);
    if (x.type === 'text') {
      x.setAttribute('type', 'password');
    } else {
      x.setAttribute('type', 'text');
    }
  };
  validate = () => {
    this.setState({ emailErr: false, passErr: false });
    let emailErr,
      passErr = false;
    const validEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!this.state.email || !validEmail.test(this.state.email)) {
      emailErr = true;
    }
    if (!this.state.password) {
      passErr = true;
    }
    this.setState({ emailErr, passErr });
    if (emailErr || passErr) {
      return false;
    } else {
      return true;
    }
  };
  login = (e) => {
    // resetData();
    // Autoload.Utils.clear_session_data();
    e.preventDefault();
    // if (!this.state.email || !this.state.password) {
    //   this.setState({
    //     err_invalid_login: true,
    //   });
    // }

    if (this.validate()) {
      var loader = document.getElementById('hello-housing-loader');
      loader.style.display = 'block';
      var data = JSON.stringify({ email: this.state.email, password: this.state.password });
      Autoload.HttpRequestAxios.HttpRequest(
        Autoload.Setting.sign_in_bas_url + 'SignIn',
        'POST',
        data,
        null,
        false,
        true,
        false,
        this,
        'handleLogin',
        {},
        false,
        '',
        {
          'Content-Type': 'application/json',
        }
      );
      // this.handleLogin()
    }
  };
  handleLogin = (response) => {
    // email not exist -- false -- no data found
    //
    this.setState({err_invalid_role_permission: false});

    if (response.status === false) {
      var loader = document.getElementById('hello-housing-loader');
      if (loader) loader.style.display = 'none';
      // this.state.err_invalid_login = true;
      // alert(response);
      if (response.message === 'DELETED' || response.message === 'DENIED') {
        this.setState({
          del_invalid_account: true,
          err_invalid_login: false
        });
      } else {
        this.setState({
          err_invalid_login: true,
          del_invalid_account: false
        });
      }
      return;
    }
    console.log(response);
    let profileData = {
      email: response.result.email,
      firstName: response.result.firstName,
      lastName: response.result.lastName,
      address: '-',
      city: '-',
      zip: 0,
      company: '',
      phoneNumber: response.result.phoneNumber,
      id: response.result.id,
      file: '',
      propertyData: [],
      auth: { token: response.result.token },
      accountId: response.result.accountId
    };


    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.sign_in_bas_url + 'rolePermissions?permission=View:PropertyOwnerPortal',//TenantPortal',
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleRolePermissions',
      { profileData: profileData },
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: response.result.token,
      }
    );

    loader = document.getElementById('hello-housing-loader');
    if (loader) loader.style.display = 'none';
    return;
    // console.log(profileData, 'profile id in login');
    // Autoload.HttpRequestAxios.HttpRequest(
    //   Autoload.Setting.property_api_base_url + 'properties?accountId=' + profileData.id + '&offset=0&fetch=100',
    //   'GET',
    //   null,
    //   null,
    //   false,
    //   true,
    //   false,
    //   this,
    //   'handlePropertyData',
    //   { profileData: profileData },
    //   false,
    //   '',
    //   {
    //     'Content-Type': 'application/json',
    //     Authorization: profileData.auth.token,
    //   }
    // );
  };

  handleRolePermissions(response){
    if(response.status){
      if(response.result){
        window.Intercom("update", {
          api_base: "https://api-iam.intercom.io",
          app_id: "vvrs5bx3",
          name: `${response.other.profileData.firstName} ${response.other.profileData.lastName}`, // Full name
          email: response.other.profileData.email, // Email address
          user_id: response.other.profileData.id,
          'Account Id': response.other.profileData.accountId,
          //user_hash: Autoload.Utils.getAuthToken()
        });
        window.Intercom('trackEvent', 'log-in');
        this.props.setProfileData(response.other.profileData);
        this.props.history.push('/dashboard');
      }else{
        this.setState({err_invalid_role_permission: true});
      }
    }else{
      this.setState({err_invalid_role_permission: true});
    }
  }

  handlePropertyData(response) {
    // console.log(response, 'login page');

    var propertyData = [];

    // // ;
    if (response.status == true && response.statuscode == 200) {
      response.result.map((tem, i) => {
        // {
        //     id: '0000dwvfwfee0000000',
        //     propertyName: 'Parkallee 155',
        //     propertyAddress: 'Parkallee 155, 10999 Berlin',
        //     propertyCity: 'Berlin',
        //     propertyZipCode: '0000',
        //     propertyNoOfUnits: '7',
        //     propertyAreaOfUnits: '711 m2',
        //     cutOffDate: '30 June',
        //     heatingType: 'heating',
        //     heatingTemp: 0,
        //     consumptionValue: '0.0'
        // },
        // {
        //     id: '0000davfwfee0000000',
        //     name: 'Parkallee 155',
        //     address: 'Parkallee 155, 10999 Berlin',
        // },
        // {
        //     id: '0000dwbfwfee0000000',
        //     name: 'Parkallee 155',
        //     address: 'Parkallee 155, 10999 Berlin',
        // },
        //  tem.cost =  (new Intl.NumberFormat('de-DE', { style: 'currency',currency: 'EUR' }).format(tem.cost).slice(0,-1));

        propertyData.push({
          // id: tem.id,
          // propertyName: tem.name,
          // propertyAddress: tem.addressStreet,
          // propertyCity: tem.addressCity,
          // propertyZipCode: tem.addressZipCode,
          // propertyNoOfUnits: tem.numberOfRentalUnits,
          // propertyAreaOfUnits: '711 m2',
          // cutOffDate: moment(tem.cutOffDate).format('DD MMMM'),
          // heatingType: 'heating',
          // heatingTemp: 0,
          // consumptionValue: '0.0',
          id: Autoload.MainHelper.checkProperty(tem, 'id'),
          propertyName: Autoload.MainHelper.checkProperty(tem, 'name'), //tem.name,
          propertyAddress: Autoload.MainHelper.checkProperty(
            Autoload.MainHelper.checkProperty(tem, 'propertyAdress'),
            'streetno'
          ),
          propertyCity: Autoload.MainHelper.checkProperty(
            Autoload.MainHelper.checkProperty(tem, 'propertyAdress'),
            'city'
          ), //tem.propertyAdress.city,
          propertyZipCode: Autoload.MainHelper.checkProperty(
            Autoload.MainHelper.checkProperty(tem, 'propertyAdress'),
            'zipCode'
          ), //tem.propertyAdress.zipCode,
          propertyNoOfUnits: Autoload.MainHelper.checkProperty(tem, 'numberOfRentalUnits'), //tem.numberOfRentalUnits,
          propertyAreaOfUnits: Autoload.MainHelper.checkProperty(tem, 'totalRentingSpace'), //tem.totalRentingSpace,
          cutOffDate: `${Autoload.MainHelper.checkProperty(
            Autoload.MainHelper.checkProperty(tem, 'cutOffDate'),
            'day',
            '31'
          )} ${moment()
            .month(
              parseInt(
                Autoload.MainHelper.checkProperty(Autoload.MainHelper.checkProperty(tem, 'cutOffDate'), 'month', '12')
              ) - 1
            )
            .format('MMMM')}`,
          // cutOffDate: moment(tem.cutOffDate).format('DD MMMM'),
          heatingType: Autoload.MainHelper.checkProperty(tem, 'heatingType'), //tem.heatingType,
          heatingTemp: Autoload.MainHelper.checkProperty(tem, 'heatingTemperature'), //tem.heatingTemperature,
          consumptionValue: Autoload.MainHelper.checkProperty(tem, 'consumptionShare', 0), //tem.consumptionShare,
          energyFuelType: Autoload.MainHelper.checkProperty(tem, 'energyFuelType'), //tem.energyFuelType
        });
      });
    }

    //// ;

    response.other.profileData.propertyData = propertyData;
    this.props.setProfileData(response.other.profileData);
    // this.props.setProfileData(response.other.user);

    // this.props.setProfileData(
    //     {
    //         "token": "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1laWQiOiI5YmY1ZWJhMC02YTliLTRkNmQtM2I3MS0wOGQ4ZDAyMWVhMjkiLCJlbWFpbCI6ImVodGlzaGFtLmFAYWRtaW4uY29tIiwiRmlyc3ROYW1lIjoiRWh0aXNoYW0gQWhtZWQiLCJMYXN0TmFtZSI6IlNpZGRpcXVpIiwiUGhvbmVOdW1iZXIiOiIxMjM0NTY3ODkiLCJuYmYiOjE2MTUyMjIzMDksImV4cCI6MTYxNTgyNzEwOSwiaWF0IjoxNjE1MjIyMzA5fQ.2Y5-J4tBWGjzs1wF2Ui2GzdFsdyEVVXTyZSgfkW3E3I",
    //         "user": {
    //             "firstName": "Ehtisham Ahmed",
    //             "lastName": "Siddiqui",
    //             "email": "ehtisham.a@admin.com",
    //             "password": "B6BC7B58510319A151D168BA3D5AECB3AC0A9708D06DD930F37FBC89B6CDC697",
    //             "phoneNumber": "123456789",
    //             "isActive": false,
    //             "account": null,
    //             "subscription": null,
    //             "roles": [],
    //             "id": "9bf5eba0-6a9b-4d6d-3b71-08d8d021ea29",
    //             "createdOn": "2021-02-13T13:18:55.377173",
    //             "updatedOn": "2021-02-13T13:18:55.3771735",
    //             "isDeleted": false
    //         }
    //     }
    // )
    // // ;
    this.props.setPropertyList(propertyData);
    if (propertyData.length != 0) {
      this.props.changeCurrentProperty(propertyData[0]);
    }
    console.log('propertyList', propertyData);
    console.log('selectedProperty', propertyData[0]);
    // window.location.href='/dashboard'
    this.props.history.push('/dashboard');
  }
  handleEnterKeyPress = (e) => {
    if (e.charCode == 13) {
      e.preventDefault();
      this.login(e);
    }
  };
  goToSignup() {
    loader(true);
    window.location.href = '/signup';
    loader(false);
  }
  render() {
    return (
      <>
        {/* <div className="info-msg">
          <div></div>
          <div className="info-msg-div">
            <div>
              <p className="info-msg-text">{lang.text_informational_msg}</p>
            </div>
            <div>
              <ColorButton className="grey-btn" text="label" />
            </div>
          </div>
          <div>
            <img src={CrossIcon} onClick={() => {}} alt="cancel-icon" />
          </div>
        </div> */}

        <div className="center-content login-page">
          <div className="hh_loader" id="hello-housing-loader" style={{ display: 'none' }}>
            <div className="hh_loader_bg"></div>
            <Spinner animation="border" className="hh_loader_spinner" variant="primary" />
          </div>
          {/* <p>{process.env.REACT_APP_DEFAULT_LANGUAGE}</p> */}
          <div className="logo-section">
            <img src={Setting.logo} alt="logo" />
          </div>
          <div className="main-content-section">
            <CustomCard heading={`Willkommen!`}>
              <Form onSubmit={this.login} noValidate>
                <Form.Group>
                  <Form.Label>{lang.text_email}</Form.Label>
                  <Form.Control
                    id="login-email"
                    name="login-email"
                    isInvalid={this.state.emailErr}
                    type="email"
                    // value="ehtisham.a@admin.com"
                    value={this.state.email}
                    onChange={(e) => {
                      this.onInputChange('email', e);
                    }}
                    onKeyPress={this.handleEnterKeyPress}
                  />
                  <Form.Control.Feedback className="err" type="invalid">
                    {' '}
                    {lang.text_invalid_email}{' '}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                  <Form.Label>{lang.text_password}</Form.Label>
                  <div className="password-field input_password" style={{ position: 'relative', top: 0, left: 0 }}>
                    <Form.Control
                      id="login-password"
                      name="login-password"
                      isInvalid={this.state.passErr}
                      type="password"
                      // value="Pass@123"
                      value={this.state.password}
                      onChange={(e) => {
                        this.onInputChange('password', e);
                      }}
                      onKeyPress={this.handleEnterKeyPress}
                    />
                    <img src={EyeIcon} onClick={() => this.toggleShowPassword('login-password')} alt="eye-icon" />
                    <Form.Control.Feedback className="err" type="invalid">
                      {' '}
                      {lang.text_invalid_password}{' '}
                    </Form.Control.Feedback>
                  </div>
                </Form.Group>
                <div className="center-spacing">
                  <Link to="/forgotpassword">{`${lang.text_forgot_password}?`}</Link>

                  <ColorButton buttonConfig={{ type: 'submit' }} className="purple-btn" text={lang.text_login} />
                </div>

                <p
                  className="text-center g-error-message"
                  style={{ display: this.state.err_invalid_login ? 'block' : 'none' }}
                >
                  {lang.text_invalid_login}
                  {/* {lang.text_invalid_role_permission_login} */}
                </p>
                <p
                  className="text-center g-error-message"
                  style={{ display: this.state.err_invalid_role_permission ? 'block' : 'none' }}
                >
                  {/* {lang.text_invalid_role_permission_login} */}
                  Sie haben keinen Zugriff auf diesen Bereich. Bei Fragen wenden Sie sich bitte an den <span style={{color: '#5555FF', cursor: 'pointer'}} onClick={()=> {window.location.href = 'mailto:service@hellohousing.de'; }}>hellohousing Service.</span>
                </p>
                <p
                  className="text-center g-error-message"
                  style={{ display: this.state.del_invalid_account ? 'block' : 'none' }}
                >
                  {/* Es ist ein technischer Fehler aufgetreten. Der hellohousing Service wurde informiert. Bitte haben Sie einige Minuten Geduld und wiederholen Sie dann den Vorgang. */}
                  {lang.text_invalid_login}
                </p>
              </Form>
            </CustomCard>
          </div>
          <div className="bottom-section">
            <p>
              {lang.text_noaccount}?{' '}
              <span
                className="signup_link"
                style={{ fontWeight: 600, cursor: 'pointer' }}
                onClick={() => this.goToSignup()}
              >
                {lang.text_signup}!
              </span>
              {/* <Link to="/signup">{lang.text_signup}!</Link> */}
            </p>
          </div>
        </div>
      </>
    );
  }
}
const actions = {
  setProfileData,
  changeCurrentProperty,
  setPropertyList,
};
export default connect(null, actions)(Login);
