import React, { Component } from 'react';
import Autoload from '../../../../../neoverv/Autoload';
import { connect } from 'react-redux';
import moment from 'moment';
import { Skeleton } from '@mantine/core';
import { Col, Row } from 'react-bootstrap';

// var Setting = Autoload.Setting;
// var lang = Setting[Setting.language_default].general;

const { checkProperty } = Autoload.MainHelper;
class Legal extends Component {
  state = {
    dataPolicyDate: '',
    generalTermsDate: '',
    skeletonLoading: false
  };
  componentDidMount() {
    // var loader = document.getElementById('hello-housing-loader');
    // loader.style.display = 'block';
    //this.getLegalDates();
    this.setState({skeletonLoading: true});
    this.getAccountDetails();
  }

  getAccountDetails = ()=> {
    const userAccountID = this.props.profile.accountId;
    Autoload.HttpRequestAxios.HttpRequest(
      // Autoload.Setting.user_api_baseurl + 'b13c75a4-ddaa-4102-c080-08d92bf06bb3',
      Autoload.Setting.account_api_baseurl + userAccountID,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handle_get_account_details',
      null,
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  handle_get_account_details(response) {
    console.log('response of profile api two', response);
    this.setState({
      // createdAt: this.setDateFormat(checkProperty(response.result, 'generalTermsAcceptedAt')),
      // policyAcceptedDate: this.setDateFormat(checkProperty(response.result, 'dataPolicyAcceptedAt')),
      dataPolicyDate: moment(checkProperty(response.result, 'dataPolicyAcceptedAt')).format('DD.MM.YYYY'),
      generalTermsDate: moment(checkProperty(response.result, 'generalTermsAcceptedAt')).format('DD.MM.YYYY'),
    });
    this.ShowLoader();
  }
  getLegalDates = () => {
    const userAccountID = this.props.profile.accountId;
    console.log(userAccountID, 'userAccountID from redux - legal page');
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.account_api_baseurl + userAccountID + '/paymentMandate',
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handle_legal_dates_data',
      null,
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };
  handle_legal_dates_data(response) {
    console.log('handle_legal_dates_data: ', response);
    // var date = moment('2021-10-01T18:44:50+00:00').format('DD.MM.YYYY')
    // console.log(response, 'handle_legal_dates_data',date);
    this.setState({
      // createdAt: this.setDateFormat(checkProperty(response.result, 'generalTermsAcceptedAt')),
      // policyAcceptedDate: this.setDateFormat(checkProperty(response.result, 'dataPolicyAcceptedAt')),
      dataPolicyDate: moment(checkProperty(response.result, 'createdAt')).format('DD.MM.YYYY'),
      generalTermsDate: moment(checkProperty(response.result, 'dataPolicyAcceptedAt')).format('DD.MM.YYYY'),
    });
    this.ShowLoader();
  }
  ShowLoader = () => {
    //console.log('show loader abc');
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'none';
    var emptyDiv = document.getElementById('empty-user-account-legal-details');
    emptyDiv.style.display = 'none';
    var dataDiv = document.getElementById('response-user-account-legal-details');
    dataDiv.style.display = 'block';

    this.setState({skeletonLoading: false});
  };

  // setDateFormat = (date) => {
  //   if (date === '' || date == 'Invalid date') {
  //     return '';
  //   } else {
  //     return moment(date, 'DD.MM.YYYY')._d;
  //   }
  // };

  render() {
    const {skeletonLoading} = this.state;
    return (
      <>
        <div id="empty-user-account-legal-details"></div>
        <div className="legal-main-div" id="response-user-account-legal-details" style={{ display: 'block' }}>
          <Row style={{marginBottom: '18px', marginTop: '25px'}}>
                <Col xs={12}>
                  <div className="payment-heading-main" style={{fontSize: '16px'}}>
                    {/* {lang.general.text_direct_debit} */}
                    {skeletonLoading ? 
                    <Skeleton visible={skeletonLoading} style={{width: '160px', height: '24px'}}/>
                    :
                    'Rechtliches'}
                    {/* {this.state.heading === 'creditcard' ? 'Kreditkarte' : lang.general.text_direct_debit} */}
                  </div>
                </Col>
          </Row>
          {skeletonLoading ?
          <div>
            <Skeleton visible={skeletonLoading} height={17.5} width={450} style={{marginBottom: '5px'}} />
            <Skeleton visible={skeletonLoading} height={17.5} width={350} style={{marginBottom: '5px'}}/>
            <Skeleton visible={skeletonLoading} height={17.5} width={550} />
          </div>
          :
          <div className="legal-para">
            <div>
              Sie haben unserer{' '}
              <span className="text-underline" style={{ cursor: 'pointer' }}>
                <a target="_blank" href="https://hellohousing.de/datenschutz/">
                  Datenschutzerklärung
                </a>
              </span>{' '}
              am {this.state.dataPolicyDate} zugestimmt.
            </div>
            <div>
              Sie haben unseren{' '}
              <span className="text-underline" style={{ cursor: 'pointer' }}>
                <a target="_blank" href="https://hellohousing.de/agb">
                  AGBs
                </a>
              </span>{' '}
              am {this.state.generalTermsDate} zugestimmt.
            </div>
            <div>
              Ihre Zustimmung zu den Hinweisen der Verarbeitung von Daten Dritter finden Sie unter{' '}
              <span className="text-underline" style={{ cursor: 'pointer' }}>
                <a target="_blank" href="https://hellohousing.de/wp-content/legal/AVV.pdf">
                  folgendem Link
                </a>
              </span>
              .
            </div>
          </div>}
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    profile: state.generalReducer.profile,
    AccountApi: state.generalReducer.account_api_id,
  };
};

export default connect(mapStateToProps)(Legal);
