import React, { Component } from 'react';
import CustomModal from './index';
import { connect } from 'react-redux';
import Autoload from '../../../../Autoload';
import * as customParseFormat from 'dayjs/plugin/customParseFormat'
import dayjs from 'dayjs';
import { Group, Stack } from '@mantine/core';
import DropFile from '../DropFile/DropFile';
import { Form } from 'react-bootstrap';
import SelectDropdown from '../SelectDropdown/SelectDropdown';
import ColorButton from '../Buttons/ColorButton';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import RedCross from '../../../../../assets/images/module/general/RedCross.svg';
import RedTick from '../../../../../assets/images/module/general/RedTick.svg';
import { showNotification } from '@mantine/notifications';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
var { checkProperty, yearDifference, yearDifferenceMoment, loader } = Autoload.MainHelper;
dayjs.extend(customParseFormat)

const MOBILE_SIZE = 992;
const TABLET_SIZE = 1200;

class DeleteDocumentModal extends Component {
  state = {
    isMobile: window.innerWidth <= MOBILE_SIZE,
    isTablet: window.innerWidth <= TABLET_SIZE,
    folderName: null
  };

  componentDidMount(){
  }

  deleteFiles=()=>{
    const {checkedItems, data, showTrashBin} = this.props;
    if(showTrashBin){
      checkedItems.map((checkedItem, index)=>
      {
        let dt= data.find((dt)=> dt.id === checkedItem);
        this.deleteDocumentById(dt.id, index, checkedItems.length)
      })
    }else{
      this.moveDocumentToTrashBin(checkedItems);
    } 
  }

  deleteDocumentById=(id, currentIndex, endOfDeletion)=>{
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.document_api_base_url + 'documents/'+id,
      'DELETE',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleDeleteDocumentById',
      {endOfDeletion, currentIndex},
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  moveDocumentToTrashBin=(ids)=>{
    let payload={
      documentIds: ids
    }
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.document_api_base_url + 'documents/move/recycleBin',//'documents/'+id+'/RecycleBin',
      'PUT',
      payload,
      null,
      false,
      true,
      false,
      this,
      'handleMoveDocumentsToTrashBin',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  handleMoveDocumentsToTrashBin(response){    
    if(response.status && response.statuscode === 200){
      //this.props.getDocuments();
      this.props.toggle();
      showNotification({
        message: 'Verschoben nach Gelöschte Dokumente',
        icon: <img src={RedTick} alt='red-tick'/>,
        id: 'moveToTrashBin'
      });
    }
  }

  handleDeleteDocumentById(response){
    if((response.other.endOfDeletion-1) === response.other.currentIndex){
      this.props.getDocuments();
      this.props.toggle();
      showNotification({
        message: 'Inhalte endgültig gelöscht',
        icon: <img src={RedTick} alt='red-tick'/>,
        id: 'deleteDocuments'
      });
    }
  }
  
  render() {
    const { open, toggle, className, folderName } = this.props;
    return (
      <>
        <CustomModal
          heading={'Dokumente oder Ordner endgültig löschen'}
          headingClassName='create-folder-modal-heading'
          open={open}
          toggle={() => {
            toggle();
          }}
          className={`${className}`}
        >
          <Stack style={{width: '100%'}}>
            <Group grow>
              <Stack>
                <Group grow>
                    <span className='override-documents-modal-text'>Sind Sie sicher, dass Sie die Dokumente oder Ordner endgültig löschen wollen? Die Inhalte gehen unwiderruflich verloren.</span>
                </Group>
                <Group position='apart'>
                    <div>
                        <ColorButton
                        onClick={()=> toggle()}
                        className="gray-btn"
                        text={'Abbrechen'}
                        />
                    </div>
                    <div>
                        <ColorButton
                        onClick={()=> this.deleteFiles()}
                        className="red-btn"
                        text={'Endgültig löschen'}
                        />
                    </div>
                </Group>
              </Stack>
            </Group>
          </Stack>
        </CustomModal>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    propertySelected: state.generalReducer.property,
  };
};

export default connect(mapStateToProps)(DeleteDocumentModal);
