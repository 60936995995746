import List from '../../../views/pages/Module/Receipt/List';


const pageList = [
  {
    name: 'Accounting',
    path: '/accounting/',
    component: List,
  },
 
];

export default pageList;
