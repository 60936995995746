import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setTenantBills } from '../../../../redux/action/UtilityBilling/UtilityBillingActions';
import { formatNumber } from '../../../../helpers/stringsManipulations';
import { EmptyStateContainer } from '../../../../neoverv';
import Autoload from '../../../../neoverv/Autoload';
import tap from '../../../../assets/images/module/general/TapIconDark.svg';
import { Group } from '@mantine/core';


var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];

export class EnergyConsumptionAndCO2EmissionsCostTable extends Component {

  tableHeadCellStyling = {
    color: '#727293',
    padding: '0 9px 0 9px',
    fontWeight: '500',
    whiteSpace: 'nowrap',
    margin: '0px',
    fontSize: '14px',
    lineHeight: '20px',
    border: '1px solid #DADAE6',
    textTransform: 'capitalize',
    height: '48px',
  }
  tableBodyCellStyling = {
    color: '#0e0e1d',
    padding: '5px 9px 5px 9px',
    fontWeight: '400',
    whiteSpace: 'nowrap',
    fontSize: '14px',
    lineHeight: '20px',
    border: '1px solid #DADAE6',
    height: '48px',
    minWidth: '90px',
    // borderBottom: 'none',
    // borderRight: 'none'
  }

  state ={
    basicCostHeating: 0,
    consumptionCostHeating: 0,
    basicCostWarmWater: 0,
    consumptionCostWarmWater: 0,
    basicCostHeatingObject1: null,
    basicCostHeatingObject2: null,
    basicCostWarmWaterObject1: null,
    basicCostWarmWaterObject2: null
  }

  constructor(props){
    super(props);
    if(props.messagePreview){
      this.tableBodyCellStyling.fontSize = '7pt';
      this.tableBodyCellStyling.height = '0px'
      this.tableBodyCellStyling.padding = '1mm'
      this.tableBodyCellStyling.lineHeight = '11pt'

      this.tableHeadCellStyling.fontSize = '7pt';
      this.tableHeadCellStyling.height = '0px'
      this.tableHeadCellStyling.padding = '1mm'
      this.tableHeadCellStyling.lineHeight = '11pt'
    }
  }

  componentDidMount(){
    if(this.props.messagePreview){
      this.tableBodyCellStyling.fontSize = '7pt';
      this.tableBodyCellStyling.height = '0px'
      this.tableBodyCellStyling.padding = '1mm'
      this.tableBodyCellStyling.lineHeight = '11pt'

      this.tableHeadCellStyling.fontSize = '7pt';
      this.tableHeadCellStyling.height = '0px'
      this.tableHeadCellStyling.padding = '1mm'
      this.tableHeadCellStyling.lineHeight = '11pt'
    }
  }

  componentDidUpdate(prevProps){
    
  }

  getTableLabels = () => {
    return (
      <TableRow>
        <TableCell style={{...this.tableHeadCellStyling}}>{'Kennzahl'}</TableCell>
        <TableCell style={{...this.tableHeadCellStyling, ...{textAlign: 'right'}}}>{'Gesamt'}</TableCell>
        <TableCell style={{...this.tableHeadCellStyling, ...{textAlign: 'right'}}}>{'Ihr Anteil'}</TableCell>
      </TableRow>
    );
  };
  calculateTotal=()=>{
    let total=0;
    this.props.heatingCosts.map((row)=> {
        total = total + row.heatingCost;
    })
    console.log('calculateTotal: ', total);
    return formatNumber(total, true);;
  }
    render(){
      const {selectedTenantData, isTablet, messagePreview, propertySelected, utilityData} = this.props;
      const {basicCostHeating, basicCostWarmWater, consumptionCostHeating, consumptionCostWarmWater, basicCostHeatingObject1, basicCostHeatingObject2, basicCostWarmWaterObject1, basicCostWarmWaterObject2} = this.state;
      console.log('basicCostHeatingObject1: ', basicCostHeatingObject2);
      console.log('utilityData_122: ', utilityData, selectedTenantData);

        return (
        <TableContainer component={Paper} style={{background: 'transparent', width: messagePreview ? '60%' : !isTablet && '50%'}}>
        <Table style={{borderCollapse: 'collapse'}}>
          <TableHead style={{border: '1px solid #DADAE6', borderBottom: 'none'}}>
            {this.getTableLabels()}
          </TableHead>
          <TableBody style={{background: 'white', border: '1px solid #DADAE6'}}>
            <TableRow>
              <TableCell style={{...this.tableBodyCellStyling, ...{fontWeight: 'bold'}}}>
                {`Brennstoffmenge - ${this.props.propertySelected.energyFuelType.fuelTypeName}`}
              </TableCell>
              <TableCell style={{...this.tableBodyCellStyling, ...{textAlign: 'right', fontWeight: 'bold'}}}>
                  <Group position='right' className='energy-consumption-and-c02-emission-table-cell-inner-group' style={{overflowX: 'scroll'}}>
                    {formatNumber(utilityData?.data?.heatingEnergyAmount, false)}
                  </Group>
              </TableCell>
              <TableCell style={{...this.tableBodyCellStyling, ...{textAlign: 'right', fontWeight: 'bold', maxWidth: '122px'}}}>
                <Group position='right' 
                className='energy-consumption-and-c02-emission-table-cell-inner-group' style={{overflowX: 'scroll'}}>
                  {selectedTenantData?.tenantHeatingEnergyAmount ? formatNumber(selectedTenantData?.tenantHeatingEnergyAmount, false) : selectedTenantData?.tenantHeatingEnergyAmount === 0 ? '0,00' : '-'}
                </Group>
              </TableCell>
            </TableRow>
            {propertySelected.heatingType !== 'central without warm water' && 
            <TableRow>
              <TableCell style={{...this.tableBodyCellStyling, ...{fontWeight: 'bold'}}}>
                {'Energieverbrauch (kWh)'}
              </TableCell>
              <TableCell style={{...this.tableBodyCellStyling, ...{textAlign: 'right', fontWeight: 'bold'}}}>
                <Group position='right' className='energy-consumption-and-c02-emission-table-cell-inner-group' style={{overflowX: 'scroll'}}>
                  {formatNumber(utilityData?.data?.heatingEnergyAmountKWH, false)}
                </Group>
              </TableCell>
              <TableCell style={{...this.tableBodyCellStyling, ...{textAlign: 'right', fontWeight: 'bold', maxWidth: '122px'}}}>
                <Group position='right' 
                className='energy-consumption-and-c02-emission-table-cell-inner-group' style={{overflowX: 'scroll'}}>
                  {selectedTenantData?.tenantHeatingEnergyAmountkWh ? formatNumber(selectedTenantData?.tenantHeatingEnergyAmountkWh, false) : selectedTenantData?.tenantHeatingEnergyAmountkWh === 0 ? '0,00' : '-'}
                </Group>
              </TableCell>
            </TableRow>}
            <TableRow>
              <TableCell style={{...this.tableBodyCellStyling, ...{fontWeight: propertySelected.heatingType === 'central without warm water' && 'bold'}}}>
                {'Energieverbrauch Raumheizung (kWh)'}
              </TableCell>
              <TableCell style={{...this.tableBodyCellStyling, ...{textAlign: 'right', fontWeight: propertySelected.heatingType === 'central without warm water' && 'bold'}}}>
                <Group position='right' className='energy-consumption-and-c02-emission-table-cell-inner-group' style={{overflowX: 'scroll'}}>
                  {formatNumber(utilityData?.data?.energyAmountRoomHeatingKWH, false)}
                </Group>
              </TableCell>
              <TableCell style={{...this.tableBodyCellStyling, ...{textAlign: 'right', maxWidth: '122px', fontWeight: propertySelected.heatingType === 'central without warm water' && 'bold'}}}>
                <Group position='right' 
                className='energy-consumption-and-c02-emission-table-cell-inner-group' style={{overflowX: 'scroll'}}>
                  {selectedTenantData?.tenantRoomHeatingEnergyAmountkWh ? formatNumber(selectedTenantData?.tenantRoomHeatingEnergyAmountkWh, false) : selectedTenantData?.tenantRoomHeatingEnergyAmountkWh === 0 ? '0,00' : '-'}
                </Group>
              </TableCell>
            </TableRow>
            {propertySelected.heatingType !== 'central without warm water' &&
            <TableRow>
              <TableCell style={{...this.tableBodyCellStyling}}>
                {'Energieverbrauch Warmwasser (kWh)'}
              </TableCell>
              <TableCell style={{...this.tableBodyCellStyling, ...{textAlign: 'right'}}}>
                <Group position='right' className='energy-consumption-and-c02-emission-table-cell-inner-group' style={{overflowX: 'scroll'}}>
                  {formatNumber(utilityData?.data?.energyAmountWarmWaterKWH, false)}
                </Group>
              </TableCell>
              <TableCell style={{...this.tableBodyCellStyling, ...{textAlign: 'right', maxWidth: '122px'}}}>
                <Group position='right' 
                className='energy-consumption-and-c02-emission-table-cell-inner-group' style={{overflowX: 'scroll'}}>
                  {selectedTenantData?.tenantWarmWaterEnergyAmountkWh ? formatNumber(selectedTenantData?.tenantWarmWaterEnergyAmountkWh, false) : selectedTenantData?.tenantWarmWaterEnergyAmountkWh === 0 ? '0,00' : '-'}
                </Group>
              </TableCell>
            </TableRow>}
            <TableRow>
              <TableCell style={{...this.tableBodyCellStyling, ...{fontWeight: 'bold'}}}>
                <Group>
                  <span style={{lineHeight: 1.43}}>CO<span style={{verticalAlign: 'sub', fontSize: messagePreview ? '5pt' : '10px'}}>2</span>-Emissionen (kg)</span>
                </Group>
              </TableCell>
              <TableCell style={{...this.tableBodyCellStyling, ...{textAlign: 'right', fontWeight: 'bold', maxWidth: '122px'}}}>
                <Group position='right' 
                className='energy-consumption-and-c02-emission-table-cell-inner-group' style={{overflowX: 'scroll'}}>
                  {utilityData?.data?.totalCO2Emissions ? formatNumber(utilityData?.data?.totalCO2Emissions, false) : utilityData?.data?.totalCO2Emissions === 0 ? '0,00' : '-'}
                </Group>
              </TableCell>
              <TableCell style={{...this.tableBodyCellStyling, ...{textAlign: 'right', fontWeight: 'bold', maxWidth: '122px'}}}>
                <Group position='right' 
                className='energy-consumption-and-c02-emission-table-cell-inner-group' style={{overflowX: 'scroll'}}>
                  {selectedTenantData?.tenantCO2Emissions ? formatNumber(selectedTenantData?.tenantCO2Emissions, false) : selectedTenantData?.tenantCO2Emissions === 0 ? '0,00' : '-'}
                </Group>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>);
            
    }
}

const mapStateToProps = (state) => {
  return {
    allReducer: state,
    currentDataId: state.UtilityBillingReducer.currentBillingId,
    tenantBills: state.UtilityBillingReducer.utilityBillingData,
    utilityBillingData: state.UtilityBillingReducer.utilityBillingData,
    heatingFilter: state.UtilityBillingReducer.HeatingAllocationFilter,
    propertySelected: state.generalReducer.property,
  };
};

const mapDispatchToProps = {
  setTenantBills,
};

export default connect(mapStateToProps, mapDispatchToProps)(EnergyConsumptionAndCO2EmissionsCostTable);

