import { Box, Button, Card, Stack, Text } from "@mantine/core";
import Icon from "../../components/Icon";
import MeterIcon from '../../assets/images/module/general/blueEmptyMeter.svg';
import ColorButton from "./Module/General/Buttons/ColorButton";

const EmptyState = ({ mainText, subText, buttonText='', buttonAction }) => {
  return (
    <Card>
      <Stack justify="center" align="center" spacing={24} sx={{ paddingTop: 24, paddingBottom: 24 }}>
        <Box sx={{ boxShadow: '0 1px 2px 0 rgba(0,0,0,.1)', width: 32, height: 32, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 4 }}>
          {/* <Icon name="meter" /> */}
          <img src={MeterIcon} className="empty-state-icon" alt="empty" />
        </Box>
        <Stack spacing={0}>
          <Text align="center" color="brand" style={{lineHeight: '24px'}}>{mainText}</Text>
          <Text align="center" size="xs" color="dimmed" style={{lineHeight: '24px'}}>{subText}</Text>
        </Stack>
        {/* <Button variant="filled" className="purple-btn" onClick={() => buttonAction()}>{buttonText}</Button> */}
        {buttonText !== '' &&
        <ColorButton
        className="purple-btn"
        text={buttonText}
        onClick={() => buttonAction()}
        />}
      </Stack>
    </Card>
  )
}

export default EmptyState;