import React, { Component } from 'react';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import DotsIcon from '../../../../../assets/images/module/general/DotsIcon.svg';
import PenIcon from '../../../../../assets/images/module/general/PenIcon.svg';
import RedTrashIcon from '../../../../../assets/images/module/general/RedTrashIcon.svg';
import { ValueText } from '../../../../index';
import moment from 'moment';
import Autoload from '../../../../Autoload';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default].general;

export class MeterRecordOptions extends Component {
  state = {
    dropdownOpen: false,
  };
  toggle = () => {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  };
  render() {
    const { link1, link2, text1, text2, history } = this.props;

    return (
      <>
        <ButtonDropdown className="record-options" isOpen={this.state.dropdownOpen} toggle={() => this.toggle()}>
          <DropdownToggle className="record-dropdown">
            <img src={DotsIcon} alt="dots-icon" width="16px" height="8px" />
          </DropdownToggle>
          <DropdownMenu className="record-dropdown-menu edit-delete-dropdown">
            {/* <DropdownItem
              onClick={() =>
                history.push({
                  pathname: `${Autoload.Setting.get_meter_reading_interium_link(
                    link1.id,
                    'c-r-0',
                    moment(new Date()).format('YYYY.DD.MM'),
                    'start'
                  )}`,
                  data: { link1 },
                  edit: false,
                })
              }
            >
              <ValueText text={text1} />
            </DropdownItem> */}

            <DropdownItem
              onClick={() =>
                history.push({
                  pathname: `${Autoload.Setting.get_meter_reading_annual_link(link1.id, 'c-r-0')}`,
                  data: { link1 },
                })
              }
            >
              <ValueText text={text2} />
            </DropdownItem>

            {/* <DropdownItem onClick={()=>this.props.toggleDeleteModal()} disabled={showDeleteButton}>
                <ValueText className='tc-red' text={lang.text_delete} leftImage={{img:RedTrashIcon}} />
              </DropdownItem> */}
          </DropdownMenu>
        </ButtonDropdown>
      </>
    );
  }
}
// MeterRecordOptions.defaultProps = {
//   toggleEditModal: () => {},
//   toggleDeleteModal: () => {},
// };
export default MeterRecordOptions;
