import * as ActionTypes from '../../types/meter';

const initialState = {
  rentalUnit: [],
};

export const annualReadingReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_ANNUAL_READINGS:
      return state;
    case ActionTypes.ADD_ANNUAL_READINGS:
      const { addedData, rentalId, editedData } = action.payload;
      let records;
      let iner;
      if (addedData != null) {
        state.rentalUnit.map((unit, index) => {
          if (unit.id == rentalId) {
            records = {
              ...state,
              rentalUnit: Object.assign([], state.rentalUnit, {
                [index]: { ...state.rentalUnit[index], data: [...state.rentalUnit[index].data, ...addedData] },
              }),
            };
          }
          return state;
        });
        records.rentalUnit.map((unit, index) => {
          iner = {
            ...records,
            rentalUnit: Object.assign([], records.rentalUnit, {
              [index]: { ...records.rentalUnit[index], data: editedData[index] },
            }),
          };
        });
      } else {
        state.rentalUnit.map((unit, index) => {
          iner = {
            ...state,
            rentalUnit: Object.assign([], state.rentalUnit, {
              [index]: { ...state.rentalUnit[index], data: editedData[index] },
            }),
          };
        });
      }

      return iner;

    default:
      return state;
  }
};
