import React, { Component } from 'react';
import { Container, Row, Col, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ColorButton } from '../../../../index';
import DatePicker, { registerLocale } from 'react-datepicker';
import de from 'date-fns/locale/de';
import { CustomDateInput, CustomDateInput_v3 } from '../../../../../neoverv/index.js';
import housingphoto from '../../../../../assets/images/module/general/house-log.png';
import greytick from '../../../../../assets/images/module/general/greyColorTick.png';
import bluetick from '../../../../../assets/images/module/general/blueColorTick.png';
import addicons from '../../../../../assets/images/module/general/AddIcon.svg';
import subtracticon from '../../../../../assets/images/module/general/subtracticon.svg';
import RedCross from '../../../../../assets/images/module/general/RedCross.svg';
import uparrow from '../../../../../assets/images/module/general/SelectArrowUp.svg';
import downArrow from '../../../../../assets/images/module/general/SelectArrowDown.svg';
import Info from '../../../../../assets/images/module/general/Info.svg';

import PropertyImage from '../../../../../assets/images/module/general/house-log.png';
import CustomModal from './index';
import {
  addPropertyData,
  setPropertyList,
  changeCurrentProperty,
} from '../../../../../redux/action/General/generalActions';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Autoload from '../../../../Autoload';
import { connect } from 'react-redux';
import moment from 'moment';
import PropertyDataHandler from '../../../../helpers/DataHandler/Property';

import { NumericFormat } from 'react-number-format';
import GlobalServices from '../../../../helpers/services/GlobalServices';
import { showNotification } from '@mantine/notifications';

const MOBILE_SIZE = 992;
var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
const { checkProperty } = Autoload.MainHelper;
class AddPropertyModal extends Component {
  initialState = {
    isMobile: window.innerWidth <= MOBILE_SIZE,
    propertyName: '',
    propertyNameErrMsg: false,
    address: '',
    addressErrMsg: false,
    city: '',
    cityErrMsg: false,
    zipCode: '',
    zipCodeErrMsg: false,
    noOfUnits: null,
    noOfUnitsErrMsg: false,
    property_modal_date: new Date(new Date().getFullYear(), 11, 31),
    // property_modal_date:new Date(),
    areaOfUnitsErrMsg: false,
    areaOfUnits: null,
    heatingTemp: '60 °C',
    heatingTempErrMsg: '',
    heatingTypeDropdownValueErrMsg: false,
    selectedOptionErrMsg: false,
    fuelTypeErrMsg: false,
    warmWaterErrMsg: false,
    radiobtnValue: '',
    selectedOptionvalue: 50,
    selectedOption: 50,
    file: '',
    fileToUpload: '',
    fileUploadErr: { state: false, msg: '' },
    greytickImage: greytick,
    bluetickImage: bluetick,
    fuelTypeName: '',
    fuelTypes: [],
    energyFuelType: null,
    heatingTypeDropdownState: 'true',
    heatingTypeDropdownValue: '',
    energyTypeDropdownState: 'true',
    totalRentingSpace: '',
    totalRentingSpaceErrMsg: false,
    numberOfRentalUnits: null,
    numberOfRentalUnitsErrMsg: false,
    rentalUnitSpace: '',
    rentalUnitSpaceErrMsg: false
    // energyFuelType: {
    //   id: '',
    //   fuelTypeName: '',
    //   conversionkWh: null,
    // },
  };

  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  _getFuelTypes() {
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'systemFuelTypes',
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      '_handleFuelTypeResponse',
      null,
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  _handleFuelTypeResponse(response) {
    console.log('_handleFuelTypeResponse: ', response);
    if (response.status === false && response.message !== 'Not Found') {
      // Autoload.Redirection.redirection_error();
      Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang.newKeys.text_error_not_found);
    }
    const fuelTypes = response.result.map((fuelType) => {
      return {
        id: Autoload.MainHelper.checkProperty(fuelType, 'id'),
        fuelTypeName: Autoload.MainHelper.checkProperty(fuelType, 'fuelTypeName'),
        conversionkWh: Autoload.MainHelper.checkProperty(fuelType, 'conversionkWh'),
      };
    });
    this.setState({ fuelTypes });
  }

  resetForm = () => {
    this.setState({ ...this.initialState });
    // this.props.toggle();
  };

  // removeSymbol = (value, symbol) => {
  //   if (!isNaN(parseInt(value))) {
  //     this.setState({ areaOfUnits: value.replace(symbol, '') });
  //   }
  // };

  // addSymbol = (value, symbol) => {
  //   if (!isNaN(parseInt(value))) {
  //     this.setState({ areaOfUnits: `${value}${symbol}` });
  //   } else {
  //     this.setState({ areaOfUnits: value.replace(symbol, '') });
  //   }
  // };

  onInputChange = (field, value) => {
    console.log(field, value, 'input change');
    this.setState({ [field]: value });
  };

  validateForm = () => {
    // console.log('validate form');
    this.setState({
      propertyNameErrMsg: false,
      // addressErrMsg: false,
      // areaOfUnitsErrMsg: false,
      // noOfUnitsErrMsg: false,
      // cityErrMsg: false,
      // zipCodeErrMsg: false,
      // fuelTypeErrMsg: false,
      // heatingTempErrMsg: false,
      // heatingTypeDropdownValueErrMsg: false,
      // selectedOptionErrMsg: false,
      numberOfRentalUnitsErrMsg: false,
      totalRentingSpaceErrMsg: false,
      rentalUnitSpaceErrMsg: false
    });
    let propertyNameErrMsg,
      // addressErrMsg,
      // areaOfUnitsErrMsg,
      // cityErrMsg,
      // zipCodeErrMsg,
      // noOfUnitsErrMsg,
      //warmWaterErrMsg,
      // fuelTypeErrMsg,
      // heatingTempErrMsg,
      // heatingTypeDropdownValueErrMsg,
      // selectedOptionErrMsg,
      rentalUnitSpaceErrMsg,
      numberOfRentalUnitsErrMsg,
      totalRentingSpaceErrMsg = false;
    const validNum = /^[0-9]/;
    //const validName = /^[a-zA-Z]+(?:[\s]+[a-zA-Z]+)*$/;
    const validName = /[^a-zA-Z0-9äöüÄÖÜß]/;
    if (!this.state.propertyName) {
      propertyNameErrMsg = true;
    }

    if(this.props.propertyType !== 'REGULAR' && !this.state.numberOfRentalUnits){
      numberOfRentalUnitsErrMsg = true;
    }

    if(this.props.propertyType !== 'REGULAR' && !this.state.totalRentingSpace){
      totalRentingSpaceErrMsg = true;
    }

    if(this.props.propertyType !== 'REGULAR' && !this.state.rentalUnitSpace || this.props.propertyType !== 'REGULAR' && this.state.totalRentingSpace < this.state.rentalUnitSpace){
      rentalUnitSpaceErrMsg = true;
    }

    // if(this.state.heatingTypeDropdownValue !== lang.property.generalinfo_decentralized && (!this.state.fuelTypeName || this.state.fuelTypeName === lang.newKeys.text_select)) {
    //   fuelTypeErrMsg = true;
    // }

    // if(this.state.heatingTypeDropdownValue !== lang.property.generalinfo_decentralized && (!this.state.warmWaterAllocationKeyName || this.state.warmWaterAllocationKeyName === lang.newKeys.text_select)) {
    //   //warmWaterErrMsg = true;
    // }
    // if (!this.state.address) {
    //   addressErrMsg = true;
    // }
    // if (!this.state.city) {
    //   cityErrMsg = true;
    // }
    // if (!this.state.zipCode) {
    //   zipCodeErrMsg = true;
    // }
    // if (!this.state.areaOfUnits || !validNum.test(this.state.areaOfUnits)) {
    //   areaOfUnitsErrMsg = true;
    // }
    // if (
    //   !this.state.noOfUnits ||
    //   !validNum.test(this.state.noOfUnits) ||
    //   !validNum.test((this.state.noOfUnits + '').replace('m²', ''))
    // ) {
    //   noOfUnitsErrMsg = true;
    // }

    // if (
    //   !this.state.heatingTemp ||
    //   !validNum.test(this.state.heatingTemp) ||
    //   !validNum.test((this.state.heatingTemp + '').replace('°C', ''))
    // ) {
    //   heatingTempErrMsg = true;
    // }
    // if (
    //   this.state.selectedOption < 0 ||
    //   !validNum.test(this.state.selectedOption) ||
    //   !validNum.test((this.state.selectedOption + '').replace('°%', ''))
    // ) {
    //   selectedOptionErrMsg = true;
    // }

    // if (!this.state.heatingTypeDropdownValue || this.state.heatingTypeDropdownValue === lang.newKeys.text_select) {
    //   heatingTypeDropdownValueErrMsg = true;
    // }

    console.log('errors_: ', propertyNameErrMsg,
    totalRentingSpaceErrMsg,
    numberOfRentalUnitsErrMsg,
    rentalUnitSpaceErrMsg);

    this.setState({
      propertyNameErrMsg,
      totalRentingSpaceErrMsg,
      numberOfRentalUnitsErrMsg,
      rentalUnitSpaceErrMsg
    });

    if (
      propertyNameErrMsg ||
      // addressErrMsg ||
      // areaOfUnitsErrMsg ||
      // noOfUnitsErrMsg ||
      // cityErrMsg ||
      // zipCodeErrMsg ||
      totalRentingSpaceErrMsg ||
      numberOfRentalUnitsErrMsg ||
      rentalUnitSpaceErrMsg ||
      // fuelTypeErrMsg ||
      // heatingTempErrMsg ||
      // heatingTypeDropdownValueErrMsg ||
      // selectedOptionErrMsg ||
      !Autoload.MainHelper.isValidDate(this.state.property_modal_date)
    ) {
      return false;
    } else {
      return true;
    }
  };

  addPropertyData = () => {
    //   console.log('this.props.profileData.id', this.props.profileData.id);
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'block';
    let btnelement = document.getElementById('property-add-btns');
    let heatingValue =
      this.state.heatingTypeDropdownValue === lang.property.generalinfo_warm_water
        ? 'central with warm water'
        : this.state.heatingTypeDropdownValue === lang.property.generalinfo_without_warm_water
        ? 'central without warm water'
        : this.state.heatingTypeDropdownValue === lang.property.generalinfo_decentralized
        ? 'decentralized'
        : '';
    const generalInfoData = {
      propertyId: null,
      accountId: this.props.profileData.accountId,
      name: this.state.propertyName,
      propertyAdress: {
        streetno: this.state.address,
        zipCode: this.state.zipCode,
        city: this.state.city,
      },
      // numberOfRentalUnits: parseInt(this.state.noOfUnits),
      cutOffDate: {
        month: parseInt(moment(this.state.property_modal_date).format('M')),
        day: parseInt(moment(this.state.property_modal_date).format('D')),
      },
      // totalRentingSpace: this.state.areaOfUnits.split(' ')[0],
      consumptionShare: 50,//parseInt(this.state.selectedOption == null ? 0 : this.state.selectedOption),
      heatingType: heatingValue,
      heatingTemperature:
        this.state.heatingTypeDropdownValue === 'central with warm water' || lang.property.generalinfo_warm_water
          ? parseInt(this.state.heatingTemp.split(' ')[0] || this.state.heatingTemp)
          : 0,
      energyFuelType: this.state.energyFuelType === null ? this.state.energyFuelType : { ...this.state.energyFuelType },
      propertyType: this.props.propertyType ? this.props.propertyType : 'REGULAR',
      totalRentingSpace: this.state.totalRentingSpace ? this.state.totalRentingSpace : 0,
      numberOfRentalUnits: this.state.numberOfRentalUnits ? this.state.numberOfRentalUnits : 0,
      centralWarmWaterAllocationKey: null,
      rentalUnitSpace: this.state.rentalUnitSpace
    };
    console.log('property data', generalInfoData);
    // debugger
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'properties',
      'POST',
      generalInfoData,
      btnelement.children[1],
      false,
      true,
      false,
      this,
      'handleaddProperty',
      { btnelement: btnelement, generalInfoData: generalInfoData },
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };

  handleaddProperty(response) {
    console.log('response add proeprty', response);
    // debugger;
    if (response.status == false) {
      Autoload.Redirection.redirection_error();
    }

    if (this.state.file) {
      // this.putPropertyPicture(response.result.id);
      window.Intercom('trackEvent', 'property-created');
      this.putPropertyPicture(response);
    } else {
      // Autoload.Redirection.redirection('/dashboard');
      // this.props.history.push('/dashboard');
      window.Intercom('trackEvent', 'property-created');
      response.other.id = response.result.id;
      this.after_addProperties(response);
    }
  }

  after_addProperties(response) {
    // // ;
    this.getProperties(response);
  }

  getProperties(response) {
    // console.log('get properteis', response);
    Autoload.HttpRequestAxios.HttpRequest(
      // Autoload.Setting.property_api_base_url +
      //   'properties?accountId=' +
      //   this.props.profileData.id +
      //   // response.result.accountId +
      //   '&offset=0&fetch=100',
      Autoload.Setting.property_api_base_url + 'properties/' + response.other.id,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handlePropertyData',
      { data: response.other.data, generalInfoData: response.other.generalInfoData, id: response.other.id },
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  handlePropertyData(response) {
    // // ;
    if (response.status == false) {
      Autoload.Redirection.redirection_error();
    }
    var data = {};
    if (response.status === true && response.statuscode === 401) {
      localStorage.clear();
      window.location.reload();
    }

    if (response.status === true && response.statuscode === 200) {
      // const properties = PropertyDataHandler.getProperties(response.result);
      // this.props.setPropertyList(properties);

      data = {
        // id: checkProperty(response.result.data, 'id'),
        // propertyName: checkProperty(response.other.generalInfoData, 'name'),
        // propertyAddress: checkProperty(checkProperty(response.other.generalInfoData, 'propertyAdress'), 'streetno'),
        // propertyCity: checkProperty(checkProperty(response.other.generalInfoData, 'propertyAdress'), 'city'),
        // propertyZipCode: checkProperty(checkProperty(response.other.generalInfoData, 'propertyAdress'), 'zipCode'),
        // propertyNoOfUnits: checkProperty(response.other.generalInfoData, 'numberOfRentalUnits'),
        // propertyAreaOfUnits: checkProperty(response.other.generalInfoData, 'totalRentalSpace'),
        // cutOffDate: `${checkProperty(checkProperty(response.other.generalInfoData, 'cutOffDate'), 'day', '31')} ${moment()
        //   .month(parseInt(checkProperty(checkProperty(response.other.generalInfoData, 'cutOffDate'), 'month', '12')) - 1)
        //   .format('MMMM')}`,
        // heatingType: checkProperty(response.other.generalInfoData, 'heatingType'),
        // heatingTemp: checkProperty(response.other.generalInfoData, 'heatingTemperature'),
        // consumptionValue: checkProperty(response.other.generalInfoData, 'consumptionShare',0),
        // energyFuelType: checkProperty(response.other.generalInfoData, 'energyFuelType'),
        
        
        // id: checkProperty(response.result, 'id'),
        // propertyName: checkProperty(response.result, 'name'),
        // propertyAddress: checkProperty(checkProperty(response.result, 'propertyAdress'), 'streetno'),
        // propertyCity: checkProperty(checkProperty(response.result, 'propertyAdress'), 'city'),
        // propertyZipCode: checkProperty(checkProperty(response.result, 'propertyAdress'), 'zipCode'),
        // propertyNoOfUnits: checkProperty(response.result, 'numberOfRentalUnits'),
        // propertyAreaOfUnits: checkProperty(response.result, 'totalRentingSpace'),
        // cutOffDate: `${checkProperty(checkProperty(response.result, 'cutOffDate'), 'day', '31')} ${moment()
        //   .month(parseInt(checkProperty(checkProperty(response.result, 'cutOffDate'), 'month', '12')) - 1)
        //   .format('MMMM')}`,
        // heatingType: checkProperty(response.result, 'heatingType'),
        // heatingTemp: checkProperty(response.result, 'heatingTemperature'),
        // consumptionValue: checkProperty(response.result, 'consumptionShare'),
        // energyFuelType: checkProperty(response.result, 'energyFuelType'),



        id: checkProperty(response.result, 'id'),
        propertyName: checkProperty(response.result, 'name'),
        propertyAddress: checkProperty(
          checkProperty(response.result, 'propertyAdress'),
          'streetno'
        ),
        propertyCity: checkProperty(
          checkProperty(response.result, 'propertyAdress'),
          'city'
        ),
        propertyZipCode: checkProperty(
          checkProperty(response.result, 'propertyAdress'),
          'zipCode'
        ),
        propertyNoOfUnits: checkProperty(response.result, 'numberOfRentalUnits'),
        propertyAreaOfUnits: checkProperty(response.result, 'totalRentingSpace'),
        cutOffDate: `${checkProperty(
          checkProperty(response.result, 'cutOffDate'),
          'day',
          '31'
        )} ${moment()
          .month(
            parseInt(
              checkProperty(checkProperty(response.result, 'cutOffDate'), 'month', '12')
            ) - 1
          )
          .format('MMMM')}`,
        heatingType: checkProperty(response.result, 'heatingType'),
        heatingTemp: checkProperty(response.result, 'heatingTemperature'),
        consumptionValue: checkProperty(response.result, 'consumptionShare', 0),
        energyFuelType: checkProperty(response.result, 'energyFuelType'),
        propertyType: checkProperty(response.result, 'propertyType'),
        totalRentingSpace: checkProperty(response.result, 'totalRentingSpace'),
        numberOfRentalUnits: checkProperty(response.result, 'numberOfRentalUnits'),
        centralWarmWaterAllocationKey: checkProperty(response.result, 'centralWarmWaterAllocationKey'),
        meterReadingConducted: checkProperty(response.result, 'meterReadingConducted'),
        pictureLocation: checkProperty(response.result, 'pictureLocation'),
        isConsumptionSectionHidden: checkProperty(response.result, 'isConsumptionSectionHidden'),
        isDocumentSectionHidden: checkProperty(response.result, 'isDocumentSectionHidden'),
        isMessageSectionHidden: checkProperty(response.result, 'isMessageSectionHidden'),
        isConsumptionSectionHidden: checkProperty(response.result, 'isConsumptionSectionHidden'),
        isDocumentSectionHidden: checkProperty(response.result, 'isDocumentSectionHidden'),
        isMessageSectionHidden: checkProperty(response.result, 'isMessageSectionHidden')
      };
      // Autoload.HttpRequestAxios.HttpRequest(
      //   Autoload.Setting.property_api_base_url + 'properties/' + data.id + '/picture',
      //   'GET',
      //   null,
      //   null,
      //   false,
      //   true,
      //   false,
      //   this,
      //   '_getPropertyPictureResponse',
      //   { propertyData: data },
      //   false,
      //   '',
      //   {
      //     'Content-Type': 'application/json',
      //     Authorization: Autoload.Utils.getAuthToken(),
      //   }
      // );

      this.props.changeCurrentProperty(data);
      this.resetForm();
      this.props.toggle();
      // // ;
      // var loader = document.getElementById('hello-housing-loader');
      // loader.style.display = 'none';
      //debugger;
      setTimeout(() => {
        Autoload.Redirection.redirection('/dashboard');
      }, 5000);
    }else{
      showNotification({
        message: 'Es ist ein Fehler aufgetreten. Bitte probieren Sie es erneut oder wenden Sie sich an den Support.',
        icon: <img src={RedCross} alt='red-cross'/>,
      });
      var loader = document.getElementById('hello-housing-loader');
      loader.style.display = 'none';
    }
    // this.props.changeCurrentProperty(data);
    // this.props.history.push('/dashboard');

    // Autoload.Redirection.redirection('/dashboard');
  }

  _getPropertyPictureResponse(response) {
    // console.log(response, 'response');
    // // ;
    response.other.propertyData.fileName = response.result === null ? PropertyImage : response.result;
    this.props.changeCurrentProperty(response.other.propertyData);
    this.resetForm();
    this.props.toggle();
    // // ;
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'none';
    debugger;
    Autoload.Redirection.redirection('/dashboard');
  }

  handleSubmit = (e) => {
    e.preventDefault();
    
    let isValid = this.validateForm();
    // this.addPropertyData();
    if (isValid) {
      console.log('working for add btn');
      this.addPropertyData();

      return;
      // console.log('valid data');
    } else {
      // console.log('invalid data add property form');
      // this.resetForm();
    }
  };

  putPropertyPicture(response) {
    // console.log('put picture', response);
    const payload = new FormData();
    payload.append('file', this.state.fileToUpload);
    // console.log('payload', payload);
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'properties/' + response.result.id + '/Picture',
      'PUT',
      payload,
      null,
      false,
      true,
      false,
      this,
      'putPropertyPictureResponse',
      { data: response.result, generalInfoData: response.other.generalInfoData, id: response.result.id },
      false,
      '',
      { 'Content-Type': 'application/json', Authorization: Autoload.Utils.getAuthToken() }
    );
  }

  putPropertyPictureResponse(response) {
    // console.log('put picture respiose', response);
    if (response.status === false && response.message !== 'Not Found') {
      // Autoload.Redirection.redirection_error();
      Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang.newKeys.text_error_found);
    }

    this.after_addProperties(response);
    // this.getProperties(response)
  }

  handlePictureUpload = (e) => {
    e.preventDefault();
    if (e.target.files[0] && typeof e.target.files[0] != 'undefined') {
      let fileTypeErr = false;
      let fileDimensionErr = false;
      const file = e.target.files[0];
      // var pattern = /image-*/;
      // var pattern = /[\/](jpg|png|jpeg)$/i;
      // console.log('file type', file, file.type);
      if (!file.type.includes('image')) {
        fileTypeErr = true;
      }
      var reader = new FileReader();
      reader.readAsDataURL(file);
      const classContext = this;
      reader.onload = function (e) {
        var image = new Image();
        image.src = e.target.result;
        image.onload = function () {
          var height = this.height;
          var width = this.width;
          // console.log('width', this.width, 'height', this.height);
          if (height < 240 || width < 240) {
            fileDimensionErr = true;
          }
          if (fileTypeErr || fileDimensionErr) {
            const msg = `Invalid file ${fileTypeErr ? 'type' : ''} ${fileTypeErr && fileDimensionErr ? 'and' : ''} ${
              fileDimensionErr ? 'dimension' : ''
            }`;
            classContext.setState({
              fileUploadErr: { state: true, msg },
            });
          } else {
            classContext.setState({
              file: URL.createObjectURL(file),
              fileToUpload: file,
              fileUploadErr: { state: false, msg: '' },
            });
          }
        };
      };
    }
  };

  onDateChange = (field, date) => {
    this.setState({ [field]: date });
  };

  renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {/* {lang.newKeys.tooltip_default_text} */}
      <span>
        Anteil der Heizkosten die verbrauchsabhängig umgelegt werden. Mehr Informationen im{' '}
        <a
          target="_blank"
          href="https://wissen.hellohousing.de/welcher-kostenanteil-soll-nach-verbrauch-umgelegt-werden"
        >
          Hilfe-Center
        </a>
        .
      </span>
    </Tooltip>
  );

  removeSymbol = (field, value, symbol) => {
    this.setState({ [field]: value.replaceAll(symbol, '') });
  };

  addSymbol = (field, value, symbol) => {
    console.log(field, value, symbol, 'add symbol');
    if (value.toString().includes(symbol)) {
      return;
    }
    if (!isNaN(parseFloat(value))) {
      this.setState({ [field]: `${value}${symbol}` });
    } else {
      this.setState({ [field]: value.replaceAll(symbol, '') });
    }
  };

  handleEnterKeyPress = (e) => {
    if (e.charCode == 13) {
      e.preventDefault();
      this.handleSubmit(e);
    }
  };

  render() {
    const { open, toggle, className, edit } = this.props;
    const { warmWaterAllocationKeys, rentalUnitSpaceErrMsg, isMobile } = this.state;
    // console.log(propertySelected.id, 'propertySelected id');
    // console.log(properties, 'all properties data ');
    return (
      <div>
        <CustomModal
          heading={edit ? 'Edit Property' : this.props.propertyType !== 'REGULAR' ? 'Eigentumswohnung anlegen' : 'Immobilie anlegen'}
          headingClassName={'property-heading-new'}
          open={open}
          toggle={() => {
            this.resetForm();
            toggle();
          }}
          className={`${className}`}
        >
          <Form onSubmit={(e) => this.handleSubmit(e)}>
            <Container>
              <div className="image_container_property">
                <div className="profile-image-container">
                  <div className="profile-image">
                    <img alt="profile" src={this.state.file ? this.state.file : housingphoto} />
                  </div>
                  <div className="upload-container">
                    <label className="gray-btn upload-btn"
                    style={{
                      fontWeight: '500',
                      fontSize: '14px',
                      fontFamily: 'sans-serif',
                      padding: '7px 11px'
                    }} htmlFor="uploadProfileImage">
                      {lang.property.generalinfo_text}
                    </label>
                    <input
                      id="uploadProfileImage"
                      name="uploadProfileImage"
                      style={{ display: 'none' }}
                      type="file"
                      accept="image/*"
                      onChange={this.handlePictureUpload}
                    />
                    <span className="file-desc">{lang.property.generalinfo_photo_update}</span>
                    <span className="file-err">{this.state.fileUploadErr.state && this.state.fileUploadErr.msg}</span>
                  </div>
                </div>
                {/* <ProfilePicView borderRadius={4} width={108} height={108} />{' '} */}
              </div>
              <Row>
                <Col xs={12}>
                  <Form.Group>
                    <div className='tooltips property-name-new'><Form.Label>{lang.property.generalinfo_propertyname}</Form.Label></div>
                    <Form.Control
                      isInvalid={this.state.propertyNameErrMsg}
                      value={this.state.propertyName}
                      type="text"
                      placeholder=""
                      onChange={(e) =>
                        this.setState({
                          propertyName: e.target.value,
                        })
                      }
                      onKeyPress={this.handleEnterKeyPress}
                      style={{
                        borderColor: this.state.propertyNameErrMsg === true && 'rgb(220, 53, 69)',
                        boxShadow: 'none'
                      }}
                    />
                    <Form.Control.Feedback className="err" type="invalid">
                      {lang.property.generalinfo_propertynameError}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Form.Group>
                  <div className='tooltips property-address-new'><Form.Label>{lang.property.generalinfo_propertyaddress}</Form.Label></div>
                    <Form.Control
                      // isInvalid={this.state.addressErrMsg}
                      value={this.state.address}
                      type="text"
                      placeholder=""
                      onChange={(e) =>
                        this.setState({
                          address: e.target.value,
                        })
                      }
                      onKeyPress={this.handleEnterKeyPress}
                    />
                    {/* <Form.Control.Feedback className="err" type="invalid">
                      {lang.property.generalinfo_propertyaddressErr}
                    </Form.Control.Feedback> */}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={9} xs={9}>
                  <Form.Group>
                  <div className='tooltips property-city-new'><Form.Label>{lang.general.text_city}</Form.Label></div>
                    <Form.Control
                      // isInvalid={this.state.cityErrMsg}
                      value={this.state.city}
                      type="text"
                      placeholder=""
                      onChange={(e) =>
                        this.setState({
                          city: e.target.value,
                        })
                      }
                      onKeyPress={this.handleEnterKeyPress}
                    />
                    {/* <Form.Control.Feedback className="err" type="invalid">
                      Invalid City
                    </Form.Control.Feedback> */}
                  </Form.Group>
                </Col>
                <Col md={3} xs={3} className='zipcode-property'>
                  <Form.Group>
                  <div className='tooltips property-postal-code-new'><Form.Label>{lang.general.text_zip_code}</Form.Label></div>
                    <Form.Control
                      // isInvalid={this.state.zipCodeErrMsg}
                      value={this.state.zipCode}
                      type="text"
                      placeholder=""
                      onChange={(e) =>
                        this.setState({
                          zipCode: e.target.value,
                        })
                      }
                      onKeyPress={this.handleEnterKeyPress}
                    />
                    {/* <Form.Control.Feedback className="err" type="invalid">
                      Invalid Value
                    </Form.Control.Feedback> */}
                  </Form.Group>
                </Col>
              </Row>
              {this.props.propertyType !== 'REGULAR' &&
              <Row>
                <Col xs={6} style={{paddingRight: '7.5px'}}>
                  <Form.Group>
                  <div className='tooltips property-total-renting-space'><Form.Label>{'Gesamtmietfläche (WEG)'}</Form.Label></div>
                    <NumericFormat 
                      className={`form-control ${this.state.totalRentingSpaceErrMsg && 'invalid'}`}
                      value={this.state.totalRentingSpace}
                      thousandSeparator="." 
                      decimalSeparator=","
                      onValueChange={(values, sourceInfo) => {
                        this.setState({
                          totalRentingSpace: values.floatValue,
                        })
                      }}
                      suffix={' m²'}
                      onKeyPress={this.handleEnterKeyPress}
                      placeholder='0,00 m²'
                      decimalScale={2}
                      fixedDecimalScale={2}
                      allowNegative={false}
                      />
                    {/* <Form.Control
                      // isInvalid={this.state.addressErrMsg}
                      value={this.state.totalRentingSpace + ' m²'}
                      type="text"
                      placeholder="0,00 m²"
                      onChange={(e) =>
                        this.setState({
                          totalRentingSpace: e.target.value,
                        })
                      }
                      onKeyPress={this.handleEnterKeyPress}
                    /> */}
                    <Form.Control.Feedback style={{display: this.state.totalRentingSpaceErrMsg && 'block'}} className="err" type="invalid">
                      {lang.property.generalinfo_propertyRentingSpaceErr}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col xs={6} style={{paddingLeft: '7.5px'}}>
                  <Form.Group>
                  <div className='tooltips property-number-of-rental-units'><Form.Label>{'Mieteinheiten (WEG)'}</Form.Label></div>
                    <Form.Control
                      className={`form-control ${this.state.totalRentingSpaceErrMsg && 'invalid'}`}
                      //isInvalid={this.state.numberOfRentalUnitsErrMsg}
                      value={this.state.numberOfRentalUnits}
                      type="number"
                      placeholder="0"
                      onChange={(e) =>
                        this.setState({
                          numberOfRentalUnits: parseInt(e.target.value),
                        })
                      }
                      onKeyPress={this.handleEnterKeyPress}
                    />
                    <img
                      src={addicons}
                      onClick={() => this.setState({ numberOfRentalUnits: this.state.numberOfRentalUnits ? this.state.numberOfRentalUnits + 1 : 1 })}
                      alt=""
                      className="addbtn-icon"
                    />
                    <img
                      src={subtracticon}
                      alt=""
                      onClick={() => this.state.numberOfRentalUnits > 0 && this.setState({ numberOfRentalUnits: this.state.numberOfRentalUnits - 1 })}
                      className="subbtn-icon"
                    />
                    <Form.Control.Feedback style={{display: this.state.numberOfRentalUnitsErrMsg && 'block'}} className="err" type="invalid">
                      {lang.property.generalinfo_propertyRentalUnitsErr}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>}
              
              <Row>
                {this.props.propertyType !== 'REGULAR' && 
                <Col xs={6} style={{paddingRight: '7.5px'}}>
                  <Form.Group>
                  <div className='tooltips property-rental-space-condo'><Form.Label>{'Mietfläche der Einheit'}</Form.Label></div>
                    <NumericFormat 
                      className={`form-control ${rentalUnitSpaceErrMsg && 'invalid'}`}
                      value={this.state.rentalUnitSpace}
                      thousandSeparator="." 
                      decimalSeparator=","
                      onValueChange={(values, sourceInfo) => {
                        this.setState({
                          rentalUnitSpace: values.floatValue,
                        })
                      }}
                      suffix={' m²'}
                      onKeyPress={this.handleEnterKeyPress}
                      placeholder='0,00 m²'
                      decimalScale={2}
                      fixedDecimalScale={2}
                      allowNegative={false}
                      />
                    {/* <Form.Control
                      // isInvalid={this.state.addressErrMsg}
                      value={this.state.totalRentingSpace + ' m²'}
                      type="text"
                      placeholder="0,00 m²"
                      onChange={(e) =>
                        this.setState({
                          totalRentingSpace: e.target.value,
                        })
                      }
                      onKeyPress={this.handleEnterKeyPress}
                    /> */}
                    <Form.Control.Feedback style={{display: rentalUnitSpaceErrMsg && 'block'}} className="err" type="invalid">
                      {lang.property.generalinfo_propertyRentingSpaceErr}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>}
                <Col xs={6} style={{paddingLeft: this.props.propertyType !== 'REGULAR' && '7.5px'}}>
                  <Form.Group>
                  <div className='tooltips property-cut-off-date-new'><Form.Label>{lang.property.generalinfo_cut_off_date}</Form.Label></div>
                    {/* <br /> */}
                    <DatePicker
                      locale="de"
                      formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 1)}
                      withPortal={isMobile}
                      // dateFormat="dd MMMM"
                      dateFormat="dd. MMMM"
                      dateFormatCalendar="LLLL"
                      showMonthYearPicker={false}
                      // dateFormat="dd.MM.yyyy"
                      customInput={<CustomDateInput_v3 />}
                      placeholderText="TT.MM.YYYY"
                      style={{ display: 'block !important' }}
                      popperPlacement="bottom-end"
                      selected={new Date(this.state.property_modal_date)}
                      onChange={(e) => this.onDateChange('property_modal_date', e)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              {/* <Row>
                <Col xs={6} md={6} className="noofunit">
                  <Form.Group>
                    <Form.Label className="d-flex justify-content-between">
                      <span> {lang.property.generalinfo_Property_no_of_Units}</span>
                      <div className="add-property-modal-text-Icon  pl-2">
                        <OverlayTrigger placement="top" delay={{ show: 450, hide: 400 }} overlay={this.renderTooltip}>
                          <img
                            src={Info}
                            alt="info"
                            width="16px"
                            height="16px"
                            title={lang.newKeys.Property_no_of_Units_Tooltip}
                          />
                        </OverlayTrigger>
                      </div>
                    </Form.Label>
                    <Form.Control
                      isInvalid={this.state.noOfUnitsErrMsg}
                      value={this.state.noOfUnits != null ? this.state.noOfUnits : ''}
                      type="number"
                      placeholder=""
                      onChange={(e) => {
                        this.onInputChange('noOfUnits', e.target.value, true);
                      }}
                      onKeyPress={this.handleEnterKeyPress}
                    />
                    <img
                      src={addicons}
                      onClick={() => this.setState({ noOfUnits: this.state.noOfUnits + 1 })}
                      alt=""
                      className="addbtn-icon"
                    />
                    <img
                      src={subtracticon}
                      alt=""
                      onClick={() => this.setState({ noOfUnits: this.state.noOfUnits - 1 })}
                      className="subbtn-icon"
                    />
                    <Form.Control.Feedback className="err" type="invalid">
                      {lang.property.generalinfo_Property_no_of_Units_error}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col xs={6} md={6}>
                  <Form.Group>
                    <Form.Label className="d-flex justify-content-between">
                      <span> {lang.property.generalinfo_Area_of_unit} </span>
                      <div className="add-property-modal-text-Icon pl-2">
                        <OverlayTrigger placement="top" delay={{ show: 450, hide: 400 }} overlay={this.renderTooltip}>
                          <img
                            src={Info}
                            alt="info"
                            width="16px"
                            height="16px"
                            title={lang.newKeys.Property_Area_of_Unit_Tooltip}
                          />
                        </OverlayTrigger>
                      </div>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      isInvalid={this.state.areaOfUnitsErrMsg}
                      placeholder=" m²"
                      value={this.state.areaOfUnits != null ? this.state.areaOfUnits : ''}
                      onFocus={(e) => this.removeSymbol('areaOfUnits', e.target.value, ' m²')}
                      onBlur={(e) => this.addSymbol('areaOfUnits', e.target.value, ' m²')}
                      onChange={(e) => {
                        this.onInputChange('areaOfUnits', e.target.value, true);
                      }}
                      onKeyPress={this.handleEnterKeyPress}
                    />
                    <Form.Control.Feedback className="err" type="invalid">
                      {lang.property.generalinfo_Property_no_of_Units_error}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row> */}
              {/* <Row>
                <Col xs={7} md={6}>
                  <Form.Group>
                  <div className='tooltips property-cut-off-date-new'><Form.Label>{lang.property.generalinfo_cut_off_date}</Form.Label></div>
                    <DatePicker
                      locale="de"
                      formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 1)}
                      // dateFormat="dd MMMM"
                      dateFormat="dd. MMMM"
                      dateFormatCalendar="LLLL"
                      showMonthYearPicker={false}
                      // dateFormat="dd.MM.yyyy"
                      customInput={<CustomDateInput_v3 />}
                      placeholderText="TT.MM.YYYY"
                      style={{ display: 'block !important' }}
                      popperPlacement="bottom-end"
                      selected={new Date(this.state.property_modal_date)}
                      onChange={(e) => this.onDateChange('property_modal_date', e)}
                    />
                  </Form.Group>
                </Col>
              </Row> */}
              {/* <Row>
                <Col xs={12} sm={12} md={9} className="add_property_modal_dropdown">
                  <Form.Group>
                  <div className='tooltips property-heating-type-new'><Form.Label>{lang.property.generalinfo_heating_type}</Form.Label></div>
                    <UncontrolledDropdown
                      id="myid"
                      onBlur={() => {
                        this.setState({
                          heatingTypeDropdownState: document
                            .getElementById('myid')
                            .firstElementChild.getAttribute('aria-expanded'),
                        });
                      }}
                      onClick={() => {
                        this.setState({
                          heatingTypeDropdownState: document
                            .getElementById('myid')
                            .firstElementChild.getAttribute('aria-expanded'),
                        });
                      }}
                      onFocus={() => {
                        this.setState({
                          heatingTypeDropdownState: document
                            .getElementById('myid')
                            .firstElementChild.getAttribute('aria-expanded'),
                        });
                      }}
                    >
                      <DropdownToggle
                        className="property-single_dropdown"
                        style={{
                          border: this.state.heatingTypeDropdownValueErrMsg === true ? '1px solid #dc3545' : null,
                        }}
                      >
                        <div>
                          {this.state.heatingTypeDropdownValue === ''
                            ? lang.newKeys.text_select
                            : this.state.heatingTypeDropdownValue}
                        </div>
                        <div>
                          {this.state.heatingTypeDropdownState === 'true' ? (
                            <img src={downArrow} alt="" />
                          ) : (
                            <img src={uparrow} alt="" />
                          )}
                        </div>
                      </DropdownToggle>
                      <DropdownMenu className="property-single-dropdown-menu">
                        <DropdownItem
                          onClick={() => {
                            this.setState({
                              heatingTypeDropdownValue: lang.property.generalinfo_warm_water,
                            });
                          }}
                          className="px-0 "
                        >
                          <span className="property-single-dropdown-options">
                            {lang.property.generalinfo_warm_water}
                          </span>
                          {this.state.heatingTypeDropdownValue === lang.property.generalinfo_warm_water ? (
                            <img src={bluetick} alt="" />
                          ) : (
                            <img src={greytick} alt="" />
                          )}
                        </DropdownItem>
                        <DropdownItem
                          className="px-0"
                          onClick={() => {
                            this.setState({
                              heatingTypeDropdownValue: lang.property.generalinfo_without_warm_water,
                              // 'central without warm water',
                            });
                          }}
                        >
                          <span className="property-single-dropdown-options">
                            {lang.property.generalinfo_without_warm_water}
                          </span>
                          {this.state.heatingTypeDropdownValue === lang.property.generalinfo_without_warm_water ? (
                            <img src={bluetick} alt="" />
                          ) : (
                            <img src={greytick} alt="" />
                          )}
                        </DropdownItem>
                        <DropdownItem
                          className="px-0"
                          onClick={() => {
                            this.setState({
                              heatingTypeDropdownValue: lang.property.generalinfo_decentralized,
                              // 'decentralized',
                            });
                          }}
                        >
                          <span className="property-single-dropdown-options">
                            {lang.property.generalinfo_decentralized}
                          </span>
                          {this.state.heatingTypeDropdownValue === lang.property.generalinfo_decentralized ? (
                            <img src={bluetick} alt="" />
                          ) : (
                            <img src={greytick} alt="" />
                          )}
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>

                    <div className="heatingDropdown_errormsg">
                      {this.state.heatingTypeDropdownValueErrMsg === true ? (
                        <p>{lang.newKeys.text_heatingType_error}</p>
                      ) : null}
                    </div>
                  </Form.Group>
                </Col>
                <Col xs={12} sm={12} md={3} className='zipcode-property'>
                  <Form.Group>
                  <div className='tooltips property-temperature-new'><Form.Label className="heating-temp">{lang.property.generalinfo_heating_temp} </Form.Label></div>
                    {this.state.heatingTypeDropdownValue === lang.property.generalinfo_warm_water ? (
                      <div>
                        <Form.Control
                          type="text"
                          isInvalid={this.state.heatingTempErrMsg}
                          value={this.state.heatingTemp}
                          placeholder="60 °C"
                          onFocus={(e) => this.removeSymbol('heatingTemp', e.target.value, ' °C')}
                          onBlur={(e) => this.addSymbol('heatingTemp', e.target.value, ' °C')}
                          onChange={(e) => this.onInputChange('heatingTemp', e.target.value, true)}
                          onKeyPress={this.handleEnterKeyPress}
                        />
                        <Form.Control.Feedback className="err" type="invalid">
                          {lang.property.generalinfo_Property_no_of_Units_error}
                        </Form.Control.Feedback>
                      </div>
                    ) : (
                      <div>
                        <Form.Control disabled placeholder="60 °C" className="disbaled-temperature" />
                      </div>
                    )}
                  </Form.Group>
                </Col>
              </Row> */}
              {/* <Row>
                <Col xs={12} sm={12} md={12} className="add_property_modal_dropdown">
                  <Form.Group>
                  <div className='tooltips property-energy-source-new'><Form.Label>{lang.newKeys.text_Energy_source}</Form.Label></div>
                    {(this.state.heatingTypeDropdownValue === lang.property.generalinfo_warm_water) || (this.state.heatingTypeDropdownValue === lang.property.generalinfo_without_warm_water) ? (
                      <>
                      <UncontrolledDropdown
                        id="energyid"
                        onBlur={() => {
                          this.setState({
                            energyTypeDropdownState: document
                              .getElementById('energyid')
                              .firstElementChild.getAttribute('aria-expanded'),
                          });
                        }}
                        onFocus={() => {
                          this.setState({
                            energyTypeDropdownState: document
                              .getElementById('energyid')
                              .firstElementChild.getAttribute('aria-expanded'),
                          });
                        }}
                        onClick={() => {
                          this.setState({
                            energyTypeDropdownState: document
                              .getElementById('energyid')
                              .firstElementChild.getAttribute('aria-expanded'),
                          });
                        }}
                      >
                        <DropdownToggle
                          className="property-single_dropdown"
                          style={{
                            border: this.state.fuelTypeErrMsg === true ? '1px solid #dc3545' : null,
                          }}
                        >
                          <div>
                            {this.state.fuelTypeName === '' ? lang.newKeys.text_select : this.state.fuelTypeName}
                          </div>
                          <div>
                            {this.state.energyTypeDropdownState === 'true' ? (
                              <img src={downArrow} alt="" />
                            ) : (
                              <img src={uparrow} alt="" />
                            )}
                          </div>
                        </DropdownToggle>
                        <DropdownMenu className="property-single-dropdown-menu">
                          {this.props.fuelTypes.map((fuelType) => {
                            return (
                              <DropdownItem
                                className="px-0"
                                onClick={() => {
                                  this.setState({
                                    energyFuelType: {
                                      id: fuelType.id,
                                      fuelTypeName: fuelType.fuelTypeName,
                                      conversionkWh: fuelType.conversionkWh,
                                    },
                                    fuelTypeName: fuelType.fuelTypeName,
                                  });
                                }}
                              >
                                <span className="property-single-dropdown-options">{fuelType.fuelTypeName}</span>
                              </DropdownItem>
                            );
                          })}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                      <div className="heatingDropdown_errormsg">
                        {this.state.fuelTypeErrMsg ? (
                          <p>{lang.newKeys.text_fuelType_error}</p>
                        ) : null}
                      </div>
                    </>
                    ) : (
                      <div>
                        <Form.Control
                          disabled
                          value={this.state.energyFuelType != null ? this.state.energyFuelType : ''}
                          placeholder={this.state.heatingTypeDropdownValue === lang.property.generalinfo_decentralized ? 'Nicht benötigt' : lang.newKeys.text_select}
                          className="disbaled-temperature"
                          // onChange={() => {
                          //   this.setState({
                          //     energyFuelType: null,
                          //   });
                          // }}
                        />
                      </div>
                    )}
                  </Form.Group>
                </Col>
              </Row> */}
              {/* <div className="add-property-modal-text">
                <p className="add-property-modal-text-INFO property-consumption-percentage-new">
                  {lang.property.generalinfo_text_one} <br /> {lang.property.generalinfo_text_two}
                </p>
              </div> */}
              {/* <div className="profile_modal_radiobtns">
                <div
                  className={`radio hello-housing-radio-options ${this.state.selectedOption === 70 ? `checked` : ``} `}
                >
                  <label style={{ display: 'flex' }}>
                    <input
                      type="radio"
                      value={70}
                      // className="pr-0"
                      checked={this.state.selectedOptionvalue == 70}
                      onChange={(e) => {
                        this.setState({
                          selectedOption: parseInt(e.target.value),
                          selectedOptionvalue: 70
                        });
                      }}
                      onKeyPress={this.handleEnterKeyPress}
                    />
                    <span className="profile_modal_radiobtns-label">70%</span>
                  </label>
                </div>
                <div
                  className={`radio hello-housing-radio-options ${this.state.selectedOption === 50 ? `checked` : ``} `}
                >
                  <label style={{ display: 'flex' }}>
                    <input
                      type="radio"
                      value={50}
                      //  className="pr-0"
                      checked={this.state.selectedOptionvalue == 50}
                      onChange={(e) => {
                        this.setState({
                          selectedOption: parseInt(e.target.value),
                          selectedOptionvalue: 50
                        });
                      }}
                      onKeyPress={this.handleEnterKeyPress}
                    />
                    <span className="profile_modal_radiobtns-label">50%</span>
                  </label>
                </div>
                <div
                  className={`radio hello-housing-radio-options ${this.state.selectedOption === 0 ? `checked` : ``} `}
                >
                  <label style={{ display: 'flex' }}>
                    <input
                      type="radio"
                      value={0}
                      //  className="pr-0"
                      checked={this.state.selectedOptionvalue == 0}
                      onChange={(e) => {
                        this.setState({
                          selectedOption: parseInt(e.target.value),
                          selectedOptionvalue: 0
                        });
                      }}
                      onKeyPress={this.handleEnterKeyPress}
                    />
                    <span className="profile_modal_radiobtns-label">0%</span>
                  </label>
                </div>
                <div
                  className={`radio hello-housing-radio-options ${
                    this.state.selectedOption === 'custom' || this.state.selectedOptionvalue == 'custom'
                      ? `checked`
                      : ``
                  } `}
                >
                  <label style={{ display: 'flex' }}>
                    <input
                      type="radio"
                      // className="pr-0"
                      value={this.state.selectedOption}
                      checked={this.state.selectedOptionvalue == 'custom'}
                      onChange={(e) => {
                        this.setState({
                          selectedOption: e.target.value,
                          selectedOptionvalue: 'custom',
                        });
                      }}
                      onKeyPress={this.handleEnterKeyPress}
                    />
                    <span className="profile_modal_radiobtns-label">{lang.property.generalinfo_custom}</span>
                  </label>
                </div>
              </div> */}
              <Row className="custom-input">
                {/* custom sizeee */}
                <Col xs={4} sm={4} md={2} className="custom-input_padding">
                  {this.state.selectedOptionvalue === 'custom' ? (
                    <div className="custom-input_margin" 
                    style={{ 
                      position: 'relative', 
                      top: 0, 
                      left: 0 }}>
                      <Form.Control
                        // isInvalid={this.state.selectedOption}
                        // placeholder=""
                        // type="number"
                        // onChange={(e) =>
                        //   this.setState({
                        //     selectedOption: e.target.value,
                        //   })
                        // }

                        value={this.state.selectedOption}
                        // onFocus={(e) => this.removeSymbol('selectedOption', e.target.value, ' %')}
                        // onBlur={(e) => this.addSymbol('selectedOption', e.target.value, ' %')}
                        onChange={(e) => {
                          this.onInputChange('selectedOption', e.target.value, true);
                        }}
                        // placeholder="%"
                        type="text"
                        onKeyPress={this.handleEnterKeyPress}
                        style={{
                          borderColor: this.state.selectedOptionErrMsg === true && 'rgb(220, 53, 69)',
                          //boxShadow: this.state.selectedOptionErrMsg === true && '0 0 0 2px rgba(240, 62, 62, 0.25)'
                        }}
                        // onFocus={(e) => this.removeSymbol('selectedOption', e.target.value, ' %')}
                        // onBlur={(e) => this.addSymbol('selectedOption', e.target.value, ' %')}
                        // onChange={(e) => {
                        //   this.setState({ selectedOption: e.target.value });
                        // }}
                      />
                      <span style={{ position: 'absolute', top: '8px', right: '13px' }}>%</span>
                    </div>
                  ) : null}
                  <div 
                  className="heatingDropdown_errormsg"
                  style={{
                    whiteSpace: 'nowrap',
                    marginLeft: '-23px'
                  }}>
                    {this.state.selectedOptionErrMsg === true ? (
                      <p>{lang.property.generalinfo_Property_no_of_Units_error}</p>
                    ) : null}
                  </div>
                </Col>
              </Row>

              <div className="cancel_addbtn">
                <div>
                  <ColorButton
                    onClick={(e) => {
                      e.preventDefault();
                      this.resetForm();
                      toggle();
                    }}
                    className="gray-btn"
                    text={lang.general.text_cancel}
                  />
                </div>
                <div id="property-add-btns">
                  <ColorButton
                    buttonConfig={{ type: 'submit' }}
                    className="purple-btn"
                    text={edit ? null : 'Hinzufügen'}
                    // onClick={this.resetModal}
                  />
                </div>
              </div>
            </Container>
          </Form>
        </CustomModal>
      </div>
    );
  }
}

const actions = {
  addPropertyData,
  setPropertyList,
  changeCurrentProperty,
};

const mapStateToProps = (state) => {
  return {
    propertySelected: state.generalReducer.property,
    properties: state.generalReducer.allProperties,
    profileData: state.generalReducer.profile,
    accountID: state.generalReducer.account_api_id,
    fuelTypes: state.generalReducer.fuelTypes
  };
};

export default connect(mapStateToProps, actions)(AddPropertyModal);
