import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import NavSpacer from './components/NavSpacer';
import NavDivider from './components/NavDivider';
import NavSingleItem from './components/NavSingleItem';
import NavDropdownItem from './components/NavDropdownItem';
import PageAlertContext from '../PageAlert/PageAlertContext';
import MobileBottomNav from './MobileBottomNav';
import SideBarDropDown from './components/SideBarDropDown';
import { connect } from 'react-redux';
import { resetData } from '../../../../../redux/action/General/generalActions';
import Autoload from '../../../../Autoload';
import { Nav, NavItem } from 'reactstrap';

// import HelpModal from '../CustomModal/HelpModal';
class SidebarNav extends Component {
  state = {
    profilePicture: null,
    mobileSidebar: false,
  };

  componentDidMount() {
    this.getProfilePicture();
  }

  getProfilePicture() {
    const profileId =
      this.props && this.props.profile ? Autoload.MainHelper.checkProperty(this.props.profile, 'id') : null;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.user_api_baseurl + profileId + '/picture',
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleProfilePicture',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  handleProfilePicture(response) {
    if (response.status === true && response.statuscode === 401) {
      localStorage.clear();
      window.location.reload();
    }

    if (response.status === true && response.statuscode === 200) {
      this.setState({ profilePicture: response.result });
    }
  }

  render() {
    const {selectedProperty} = this.props;
    const navItems = (items) => {
      return items.map((item, index) => itemType(item, index));
    };
    const itemType = (item, index) => {
      if (item.children) {
        return <NavDropdownItem key={index} item={item} isSidebarCollapsed={this.props.isSidebarCollapsed} />;
      } else if (item.divider) {
        return <NavDivider key={index} />;
      } else {
        return (
          <NavSingleItem
            item={item}
            key={index}
            history={this.props.history}
            resetData={() => this.props.resetData()}
            {...this.props}
            profilePicture={
              this.state.profilePicture
                ? this.state.profilePicture
                : Autoload.MainHelper.checkProperty(this.props.profile, 'profilePicture')
            }
          />
        );
      }
    };
    const NavBrand = ({ logo, logoText }) => {
      return (
        <div className="site-logo-bar text-center">
          <NavLink to="/dashboard" className="navbar-brand">
            {logo && <img src={logo} alt="" />}
            {logoText && <span className="logo-text">{logoText}</span>}
          </NavLink>
        </div>
      );
    };

    return (
      <>
        {this.state.mobileSidebar && (
          <div style={{ width: '288px', height: '100vh', zIndex: '2', position: 'absolute', background: 'white' }}>
            <Nav className="sidebar-mobile">
              <ul className={this.props.properties.length > 0 ? 'nonempty-menu' : 'emptystate-menu'} id="main-menu">
                {navItems(this.props.nav.top)}
                <NavSpacer />
                {navItems(this.props.nav.bottom)}
              </ul>
            </Nav>
          </div>
        )}
        <div>
          <PageAlertContext.Consumer>
            {(consumer) => {
              const hasPageAlertClass = consumer.alert ? 'has-alert' : '';
              return (
                <div className={this.props.stateModal && this.props.isMobile ? 'hide-appbar' : ''}>
                  <div className={`app-sidebar ${hasPageAlertClass}`}>
                    <NavBrand logo={this.props.logo} logoText={this.props.logoText} />
                    <nav 
                    className='bottom-nav'
                      style={{
                        boxShadow: '0px 0px 6px 0px rgba(0, 0, 0, 0.1)',
                        //height: '65px',
                        padding: '0px'
                      }}>
                      {!this.props.isMobile && <SideBarDropDown />}

                      {this.props.isMobile ? (
                        <MobileBottomNav
                          navTop={this.props.nav.top}
                          navBottom={this.props.nav.bottom}
                          history={this.props.history}
                          isTablet={this.props.isTablet}
                          isSmallerMobile={this.props.isSmallerMobile}
                          toggleMobileSidebar={() => {
                            this.setState({ mobileSidebar: !this.state.mobileSidebar });
                          }}
                        />
                      ) : (
                        <ul
                          className={this.props.properties.length > 0 ? 'nonempty-menu' : 'emptystate-menu'}
                          id="main-menu"
                        >
                          {navItems(this.props.nav.top)}
                          <NavSpacer />
                          {navItems(this.props.nav.bottom)}
                        </ul>
                      )}
                    </nav>
                  </div>
                </div>
              );
            }}
          </PageAlertContext.Consumer>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    properties: state.generalReducer.allProperties,
    selectedProperty: state.generalReducer.property,
    stateModal: state.generalReducer.stateModal,
  };
};
const actions = {
  resetData,
};
export default connect(mapStateToProps, actions)(SidebarNav);
