import React, { Component } from 'react'
import CustomModal from './index';
import {ColorButton} from '../../../../index';
import Autoload from "../../../../Autoload";
import { Group, Stack } from '@mantine/core';
import { connect } from 'react-redux';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];

class ReceiptAssigmentRemoveConfirmationModal extends Component {
    state={
        success: null
    }
    constructor(props){
        super(props);
    }

    componentDidMount(){
     
    }
    render(){
        const {success} = this.state;
        const {open, toggle, className, type, history, removeReceipt, newAmount} = this.props;
        console.log('type: ', type);
        return (
            <CustomModal 
            heading= {'Beleg entfernen'}  
            open={open} 
            toggle={toggle} 
            className={`${className}`}>
                <Stack>
                    <Group>
                        <span style={{fontWeight: '400'}}>Wenn Sie den Beleg entfernen, wird die Belegeigenschaft "relevant für die Betriebskostenabrechnung" deaktiviert. Sie können diese Einstellung im Belegmanager für den Beleg jederzeit wieder aktivieren. Sind Sie sicher, dass Sie fortfahren wollen?</span>
                    </Group>
                    <Group position='apart' noWrap>
                        <div>
                            <ColorButton
                            text={'Abbrechen'}
                            className={'gray-btn'}
                            onClick={()=> toggle()}
                            />
                        </div>
                        <div>
                            <ColorButton
                            text={'Beleg entfernen'}
                            className={'purple-btn'}
                            onClick={()=> removeReceipt()}
                            />
                        </div>
                    </Group>
                </Stack>
            </CustomModal>
         )
    }
}
const mapStateToProps = (state) => {
    return {
        propertySelected: state.generalReducer.property,
        profile: state.generalReducer.profile,
        AccountApi: state.generalReducer.account_api_id,
    };
};

export default connect(mapStateToProps)(ReceiptAssigmentRemoveConfirmationModal);
