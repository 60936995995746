import * as ActionTypes from '../../types/receipt';
const initialState = {
  receipts:  []
}

export const ReceiptReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_RECEIPTS:
      return {...state, receipts: action.payload}

    default:
      return state
  }
}