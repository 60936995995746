import List from '../../../views/pages/Module/Messaging/List';

const pageList = [
  {
    name: 'Messaging',
    path: '/messaging/',
    component: List,
  },
];

export default pageList;
