import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import PaymentTemplateSuccess from '../General/profile/PaymentTemplateSuccess';


class List extends Component {
  render() {
    

    return (
      <div>
        <Route path={this.props.match.url +"/status"} exact component={PaymentTemplateSuccess} />
      </div>
    );
  }
}

export default List;
