import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setTenantBills } from '../../../../redux/action/UtilityBilling/UtilityBillingActions';
import { formatNumber } from '../../../../helpers/stringsManipulations';
import { EmptyStateContainer, UtilityBillsTableRow } from '../../../../neoverv';
import Autoload from '../../../../neoverv/Autoload';
import tap from '../../../../assets/images/module/general/TapIconDark.svg';
import MeterIcon from '../../../../assets/images/module/general/blueEmptyMeter.svg';
import accounting from 'accounting';
import { Popover } from '@mantine/core';

const MOBILE_SIZE = 992;
const TABLET_SIZE = 1200;

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];

export class ControlSheetTable extends Component {

  tableHeadCellStyling = {
    color: '#727293',
    padding: '5px 9px',
    fontWeight: '500',
    whiteSpace: 'normal',
    margin: '0px',
    fontSize: '14px',
    lineHeight: '20px',
    border: '1px solid #DADAE6',
    textTransform: 'capitalize',
    height: 'auto',
    minWidth: '150px',
    //verticalAlign: 'top',
    textAlign: 'center',
    backgroundColor: 'rgb(247, 247, 250)'
  }
  tableBodyCellStyling = {
    color: '#0e0e1d',
    padding: '5px 9px',
    fontWeight: '400',
    whiteSpace: 'nowrap',
    fontSize: '14px',
    lineHeight: '20px',
    border: '1px solid #DADAE6',
    textTransform: 'none',
    height: '48px',
    minWidth: '90px'
  }

  state = {
    isMobile: window.innerWidth <= MOBILE_SIZE,
    isTablet: window.innerWidth <= TABLET_SIZE,
    allCostAllocationsForBillingStatements: [],
    allDataLoaded: false,
    costTypes: [],
    allReceiptAssignments: [],
    exportRentalIncomeTablePopoverOpen: false,
    openPopover: null,
    openPopover2: null,
    openPopover3: null,
    openPopover4: null,
    openPopover5: null,
  };

  constructor(props){
    super(props);
    if(props.messagePreview){
      this.tableBodyCellStyling.fontSize = '7pt';
      this.tableBodyCellStyling.height = '0px'
      this.tableBodyCellStyling.padding = '1mm'
      this.tableBodyCellStyling.lineHeight = '11pt'

      this.tableHeadCellStyling.fontSize = '7pt';
      this.tableHeadCellStyling.height = '0px'
      this.tableHeadCellStyling.padding = '1mm'
      this.tableHeadCellStyling.lineHeight = '11pt'
    }
  }

  getTableHeader=()=>{
    const {allBillingStatements} = this.props;
    return (
        <TableHead>
            <TableRow>
                <TableCell style={{...this.tableHeadCellStyling, ...{minWidth: '200px'}}}>
                  Kostenart
                </TableCell>
                {allBillingStatements.map((billingStatement)=> 
                <TableCell style={{...this.tableHeadCellStyling}}>
                    {/* {`${billingStatement.tenantName} (${billingStatement.rentalUnitName})`} */}
                    <span>{billingStatement.tenantName}</span>
                    <br/>
                    <span>({billingStatement.rentalUnitName})</span>
                </TableCell>)}
                <TableCell style={{...this.tableHeadCellStyling, ...{borderLeft: '3px solid #DADAE6'}}}>
                  Gesamtkosten
                </TableCell>
                <TableCell style={{...this.tableHeadCellStyling}}>
                  Umgelegte Kosten
                </TableCell>
                <TableCell style={{...this.tableHeadCellStyling}}>
                  Zugeordnete Belege
                </TableCell>
            </TableRow>
        </TableHead>
    );
  }

  getTableBody=()=>{
    const {allBillingStatements, allCostAllocationsForBillingStatements, costTypes, allReceiptAssignments} = this.props;
    const {openPopover, openPopover2, openPopover3, openPopover4, openPopover5} = this.state;

    let sumOfHeatingTotal = allCostAllocationsForBillingStatements[0]?.reduce((partialSum, a) => (partialSum ? partialSum : null) + ((a.totalCostShare && a.costTypeIdentifier !== 'NON_HEATING' && a.costTypeIdentifier !== 'HEATING_TOTAL') ? a.totalCostShare : null), 0);
    let sumOfTenantHeatingTotal = allCostAllocationsForBillingStatements?.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.length > 0 ? a.reduce((partialSum, b) => (partialSum ? partialSum : null) + ((b.tenantCostShare && b.orderNumber > 1 && b.orderNumber <= 5) ? b.tenantCostShare : null), 0) : null), 0);
    let sumOfReceiptAssignmentHeatingTotal = costTypes?.reduce((partialSum, a) => (partialSum ? partialSum : null) + ((a.length > 0 && a.orderNumber > 1 && a.orderNumber <= 5) ? a.receiptAssigments.reduce((partialSum, b) => (partialSum ? partialSum : null) + (b.proRataAmount ? b.proRataAmount : null), 0) : null), 0);

    let sumOfNonHeatingTotal = allCostAllocationsForBillingStatements[0].reduce((partialSum, a) => (partialSum ? partialSum : null) + ((a.totalCostShare && a.costTypeIdentifier === 'NON_HEATING') ? a.totalCostShare : null), 0);
    let sumOfTenantNonHeatingTotal = allCostAllocationsForBillingStatements.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.length > 0 ? a.reduce((partialSum, b) => (partialSum ? partialSum : null) + ((b.tenantCostShare && b.costTypeIdentifier === 'NON_HEATING') ? b.tenantCostShare : null), 0) : null), 0);
    let sumOfReceiptAssignmentNonHeatingTotal = costTypes.reduce((partialSum, a) => (partialSum ? partialSum : null) + ((a.receiptAssigments.length > 0 && a.costTypeIdentifier === 'NON_HEATING') ? a.receiptAssigments.reduce((partialSum, b) => (partialSum ? partialSum : null) + (b.proRataAmount ? b.proRataAmount : null), 0) : null), 0);
    return(
    <TableBody>
        {costTypes.map((costType)=>
        costType.costTypeIdentifier !== 'HEATING_TOTAL' &&
        <>
          <TableRow>
              <TableCell style={{...this.tableBodyCellStyling, ...{backgroundColor: 'rgb(247, 247, 250)'}}}>
                  <span style={{whiteSpace: 'nowrap'}}>{costType.costTypeName ? costType.costTypeName : '-'}</span>
              </TableCell>
              {allBillingStatements.map((billingStatement, index)=> {
                  return allCostAllocationsForBillingStatements.map((costAllocations)=> {
                      if(costAllocations[0].billingStatementId === billingStatement.billingStatementId){
                          let tenantCostAllocation = costAllocations.find((costAllocation)=> costAllocation.costPositionId === costType.costPositionId);
                          let sumOfTenants = allBillingStatements.length === (index+1) ? allCostAllocationsForBillingStatements.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.find((costAllocation)=> costAllocation.costPositionId === costType.costPositionId) !== undefined ? a.find((costAllocation)=> costAllocation.costPositionId === costType.costPositionId).tenantCostShare : null), 0) : 0;
                          let sumOfReceiptAssignments = allBillingStatements.length === (index+1) ? costType.receiptAssigments.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.proRataAmount ? a.proRataAmount : null), 0) : 0;

                          return (
                              <>
                                  <TableCell onMouseEnter={()=> this.setState({openPopover3: tenantCostAllocation.id})} onMouseLeave={()=> this.setState({openPopover3: null})} style={{...this.tableBodyCellStyling, ...{textAlign: 'right', backgroundColor: tenantCostAllocation.tenantCostShare === 0 && '#f7e3cd', cursor: tenantCostAllocation.tenantCostShare === 0 && 'pointer'}}}>
                                      {tenantCostAllocation.tenantCostShare === 0 ?
                                      <Popover
                                      placement={'center'}
                                      position={'top'}
                                      withArrow
                                      zIndex={9999}
                                      opened={openPopover3 === tenantCostAllocation.id}
                                      target={<span style={{whiteSpace: 'nowrap'}}>{tenantCostAllocation !== undefined ? accounting.formatMoney(tenantCostAllocation.tenantCostShare, '€', 2, '.', ',', '%v %s') : '-'}</span>}
                                      >
                                        <span style={{whiteSpace: 'nowrap', fontWeight: '400'}}>Diesem Mieter wurden keine Kosten zugeordnet.</span>
                                      </Popover>
                                      :
                                      <span style={{whiteSpace: 'nowrap'}}>{tenantCostAllocation !== undefined ? accounting.formatMoney(tenantCostAllocation.tenantCostShare, '€', 2, '.', ',', '%v %s') : '-'}</span>}
                                  </TableCell>
                                  {allBillingStatements.length === (index+1) && 
                                  <TableCell style={{...this.tableBodyCellStyling, ...{textAlign: 'right', fontWeight: 'bold', borderLeft: '3px solid #DADAE6'}}}>
                                      <span style={{whiteSpace: 'nowrap'}}>{tenantCostAllocation !== undefined ? accounting.formatMoney(tenantCostAllocation.totalCostShare, '€', 2, '.', ',', '%v %s') : '-'}</span>
                                  </TableCell>}
                                  {allBillingStatements.length === (index+1) && 
                                  <TableCell onMouseEnter={()=> this.setState({openPopover: costType.costPositionId})} onMouseLeave={()=> this.setState({openPopover: null})} style={{...this.tableBodyCellStyling, ...{textAlign: 'right', backgroundColor: (Math.round(sumOfTenants *100)/100) < (Math.round(tenantCostAllocation.totalCostShare*100)/100) && '#FFCBCB', cursor: (Math.round(sumOfTenants *100)/100) < (Math.round(tenantCostAllocation.totalCostShare*100)/100) && 'pointer'}}}>
                                      {(Math.round(sumOfTenants *100)/100) < (Math.round(tenantCostAllocation.totalCostShare*100)/100) ?
                                        <Popover
                                        placement={'center'}
                                        position={'top'}
                                        withArrow
                                        zIndex={9999}
                                        opened={openPopover === costType.costPositionId}
                                        target={<span style={{whiteSpace: 'nowrap'}}>{allCostAllocationsForBillingStatements.length > 0 ? accounting.formatMoney(sumOfTenants, '€', 2, '.', ',', '%v %s', '€', 2, '.', ',', '%v %s') : '-'}</span>}
                                        >
                                          <span style={{whiteSpace: 'nowrap', fontWeight: '400'}}>Nicht alle Gesamtkosten wurden auf die Mieter verteilt.</span>
                                        </Popover>
                                      :
                                        <span style={{whiteSpace: 'nowrap'}}>{allCostAllocationsForBillingStatements.length > 0 ? accounting.formatMoney(sumOfTenants, '€', 2, '.', ',', '%v %s', '€', 2, '.', ',', '%v %s') : '-'}</span>}
                                  </TableCell>}
                                  {allBillingStatements.length === (index+1) && 
                                  <TableCell onMouseEnter={()=> this.setState({openPopover2: costType.costPositionId})} onMouseLeave={()=> this.setState({openPopover2: null})} style={{...this.tableBodyCellStyling, ...{textAlign: 'right', backgroundColor: costType.receiptAssigments.length > 0 && sumOfReceiptAssignments !== 0 && (Math.round(sumOfReceiptAssignments *100)/100) !== (Math.round(tenantCostAllocation.totalCostShare*100)/100) && '#FFCBCB', cursor: costType.receiptAssigments.length > 0 && sumOfReceiptAssignments !== 0 && (Math.round(sumOfReceiptAssignments *100)/100) !== (Math.round(tenantCostAllocation.totalCostShare*100)/100) && 'pointer'}}}>
                                    {(costType.receiptAssigments.length > 0 && sumOfReceiptAssignments !== 0 && (Math.round(sumOfReceiptAssignments *100)/100) !== (Math.round(tenantCostAllocation.totalCostShare*100)/100)) ?
                                        <Popover
                                        placement={'center'}
                                        position={'top'}
                                        withArrow
                                        zIndex={9999}
                                        opened={openPopover2 === costType.costPositionId}
                                        target={<span style={{whiteSpace: 'nowrap'}}>{costType.receiptAssigments.length > 0 ? accounting.formatMoney(sumOfReceiptAssignments, '€', 2, '.', ',', '%v %s', '€', 2, '.', ',', '%v %s') : '-'}</span>}
                                        >
                                          <span style={{whiteSpace: 'nowrap', fontWeight: '400'}}>Die Summe der zugeordneten Belege entspricht nicht den abgerechneten Gesamtkosten.</span>
                                        </Popover>
                                      :
                                      <span style={{whiteSpace: 'nowrap'}}>{costType.receiptAssigments.length > 0 ? accounting.formatMoney(sumOfReceiptAssignments, '€', 2, '.', ',', '%v %s') : '-'}</span>}
                                  </TableCell>}
                                  
                              </>
                          );
                      }else{
                          return null;
                      }
                  })
              })}
          </TableRow>
          {/* HeatingTotal Row */}
          {costType.orderNumber === 5 &&
          <TableRow style={{borderBottom: '3px solid #DADAE6'}}>
              <TableCell style={{...this.tableBodyCellStyling, ...{fontWeight: 'bold', backgroundColor: 'rgb(247, 247, 250)'}}}>
                  <span style={{whiteSpace: 'nowrap'}}>{'Heizkosten gesamt'}</span>
              </TableCell>
              {allBillingStatements.map((billingStatement, index)=> {
                return(
                  <TableCell style={{...this.tableBodyCellStyling, ...{fontWeight: 'bold', textAlign: 'right'}}}>
                    {accounting.formatMoney(allCostAllocationsForBillingStatements.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.length > 0 ? a.reduce((partialSum, b) => (partialSum ? partialSum : null) + ((b.billingStatementId === billingStatement.billingStatementId && b.tenantCostShare && b.orderNumber > 1 && b.orderNumber <= 5) ? b.tenantCostShare : null), 0) : null), 0),
                    '€', 2, '.', ',', '%v %s')}
                  </TableCell>
                )
              })}
              <TableCell style={{...this.tableBodyCellStyling, ...{fontWeight: 'bold', textAlign: 'right', borderLeft: '3px solid #DADAE6'}}}>
                {sumOfHeatingTotal ? accounting.formatMoney(sumOfHeatingTotal,
                '€', 2, '.', ',', '%v %s') : '-'}
              </TableCell>
              <TableCell onMouseEnter={()=> this.setState({openPopover: 'heatingTotal'})} onMouseLeave={()=> this.setState({openPopover: null})} style={{...this.tableBodyCellStyling, ...{fontWeight: 'bold', textAlign: 'right', backgroundColor: (Math.round(sumOfTenantHeatingTotal * 100) / 100) < (Math.round(sumOfHeatingTotal * 100) / 100) && '#FFCBCB', cursor: (Math.round(sumOfTenantHeatingTotal * 100) / 100) < (Math.round(sumOfHeatingTotal * 100) / 100) && 'pointer'}}}>
                {(Math.round(sumOfTenantHeatingTotal * 100) / 100) < (Math.round(sumOfHeatingTotal * 100) / 100) ?
                <Popover
                placement={'center'}
                position={'top'}
                withArrow
                zIndex={9999}
                opened={openPopover === 'heatingTotal'}
                target={sumOfTenantHeatingTotal ? accounting.formatMoney(sumOfTenantHeatingTotal,
                  '€', 2, '.', ',', '%v %s') : '-'}
                >
                  <span style={{whiteSpace: 'nowrap', fontWeight: '400'}}>Nicht alle Gesamtkosten wurden auf die Mieter verteilt.</span>
                </Popover>
                :
                sumOfTenantHeatingTotal ? accounting.formatMoney(sumOfTenantHeatingTotal,
                '€', 2, '.', ',', '%v %s') : '-'}
              </TableCell>
              <TableCell onMouseEnter={()=> this.setState({openPopover2: 'heatingTotalReceipts'})} onMouseLeave={()=> this.setState({openPopover2: null})} style={{...this.tableBodyCellStyling, ...{textAlign: 'right', fontWeight: 'bold', backgroundColor: sumOfReceiptAssignmentHeatingTotal !== 0 && (Math.round(sumOfReceiptAssignmentHeatingTotal * 100) / 100) < (Math.round(sumOfHeatingTotal * 100) / 100) && '#FFCBCB', cursor: sumOfReceiptAssignmentHeatingTotal !== 0 && (Math.round(sumOfReceiptAssignmentHeatingTotal * 100) / 100) < (Math.round(sumOfHeatingTotal * 100) / 100) && 'pointer'}}}>
                {sumOfReceiptAssignmentHeatingTotal !== 0 && (Math.round(sumOfReceiptAssignmentHeatingTotal * 100) / 100) < (Math.round(sumOfHeatingTotal * 100) / 100) ?
                <Popover
                placement={'center'}
                position={'top'}
                withArrow
                zIndex={9999}
                opened={openPopover2 === 'heatingTotalReceipts'}
                target={costTypes.find((costType)=> costType.orderNumber > 1 && costType.orderNumber <= 5 && costType.receiptAssigments.length > 0) !== undefined && sumOfReceiptAssignmentHeatingTotal ? accounting.formatMoney(sumOfReceiptAssignmentHeatingTotal,
                  '€', 2, '.', ',', '%v %s')
                  :
                  '-'}
                >
                  <span style={{whiteSpace: 'nowrap', fontWeight: '400'}}>Die Summe der zugeordneten Belege entspricht nicht den abgerechneten Gesamtkosten.</span>
                </Popover>
                :
                costTypes.find((costType)=> costType.orderNumber > 1 && costType.orderNumber <= 5 && costType.receiptAssigments.length > 0) !== undefined && sumOfReceiptAssignmentHeatingTotal ? accounting.formatMoney(sumOfReceiptAssignmentHeatingTotal,
                '€', 2, '.', ',', '%v %s')
                :
                '-'}
              </TableCell>
          </TableRow>}
        </>
        )}
        {/* NonHeatingTotal Row  */}
        <TableRow style={{borderBottom: '3px solid #DADAE6'}}>
              <TableCell style={{...this.tableBodyCellStyling, ...{fontWeight: 'bold', backgroundColor: 'rgb(247, 247, 250)'}}}>
                  <span style={{whiteSpace: 'nowrap'}}>{'Nebenkosten gesamt'}</span>
              </TableCell>
              {allBillingStatements.map((billingStatement, index)=> {
                return(
                  <TableCell style={{...this.tableBodyCellStyling, ...{fontWeight: 'bold', textAlign: 'right'}}}>
                    {accounting.formatMoney(allCostAllocationsForBillingStatements.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.length > 0 ? a.reduce((partialSum, b) => (partialSum ? partialSum : null) + ((b.billingStatementId === billingStatement.billingStatementId && b.tenantCostShare && b.costTypeIdentifier === 'NON_HEATING') ? b.tenantCostShare : null), 0) : null), 0),
                    '€', 2, '.', ',', '%v %s')}
                  </TableCell>
                )
              })}
              <TableCell style={{...this.tableBodyCellStyling, ...{fontWeight: 'bold', textAlign: 'right', borderLeft: '3px solid #DADAE6'}}}>
                {(allCostAllocationsForBillingStatements.length > 0 && sumOfNonHeatingTotal) ? accounting.formatMoney(sumOfNonHeatingTotal,
                '€', 2, '.', ',', '%v %s') : '-'}
              </TableCell>
              <TableCell onMouseEnter={()=> this.setState({openPopover: 'nonHeatingTotal'})} onMouseLeave={()=> this.setState({openPopover: null})} style={{...this.tableBodyCellStyling, ...{fontWeight: 'bold', textAlign: 'right', backgroundColor: (Math.round(sumOfTenantNonHeatingTotal * 100) / 100) < (Math.round(sumOfNonHeatingTotal * 100) / 100) && '#FFCBCB', cursor: (Math.round(sumOfTenantNonHeatingTotal * 100) / 100) < (Math.round(sumOfNonHeatingTotal * 100) / 100) && 'pointer'}}}>
                {(Math.round(sumOfTenantNonHeatingTotal * 100) / 100) < (Math.round(sumOfNonHeatingTotal * 100) / 100) ?
                <Popover
                placement={'center'}
                position={'top'}
                withArrow
                zIndex={9999}
                opened={openPopover === 'nonHeatingTotal'}
                target={sumOfTenantNonHeatingTotal ? accounting.formatMoney(sumOfTenantNonHeatingTotal,
                  '€', 2, '.', ',', '%v %s') : '-'}
                >
                  <span style={{whiteSpace: 'nowrap', fontWeight: '400'}}>Nicht alle Gesamtkosten wurden auf die Mieter verteilt.</span>
                </Popover>
                :
                sumOfTenantNonHeatingTotal ? accounting.formatMoney(sumOfTenantNonHeatingTotal,
                '€', 2, '.', ',', '%v %s') : '-'}
              </TableCell>
              <TableCell onMouseEnter={()=> this.setState({openPopover2: 'nonHeatingTotalReceipts'})} onMouseLeave={()=> this.setState({openPopover2: null})} style={{...this.tableBodyCellStyling, ...{textAlign: 'right', fontWeight: 'bold', backgroundColor: sumOfReceiptAssignmentNonHeatingTotal !== 0 && (Math.round(sumOfReceiptAssignmentNonHeatingTotal * 100) / 100) < (Math.round(sumOfNonHeatingTotal * 100) / 100) && '#FFCBCB', cursor: sumOfReceiptAssignmentNonHeatingTotal !== 0 && (Math.round(sumOfReceiptAssignmentNonHeatingTotal * 100) / 100) < (Math.round(sumOfNonHeatingTotal * 100) / 100) && 'pointer'}}}>
                {sumOfReceiptAssignmentNonHeatingTotal !== 0 && (Math.round(sumOfReceiptAssignmentNonHeatingTotal * 100) / 100) < (Math.round(sumOfNonHeatingTotal * 100) / 100) ?
                <Popover
                placement={'center'}
                position={'top'}
                withArrow
                zIndex={9999}
                opened={openPopover2 === 'nonHeatingTotalReceipts'}
                target={(costTypes.find((costType)=> costType.costTypeIdentifier === 'NON_HEATING' && costType.receiptAssigments.length > 0) !== undefined && sumOfReceiptAssignmentNonHeatingTotal) ? accounting.formatMoney(sumOfReceiptAssignmentNonHeatingTotal,
                  '€', 2, '.', ',', '%v %s')
                  :
                  '-'}
                >
                  <span style={{whiteSpace: 'nowrap', fontWeight: '400'}}>Die Summe der zugeordneten Belege entspricht nicht den abgerechneten Gesamtkosten.</span>
                </Popover>
                :
                (costTypes.find((costType)=> costType.costTypeIdentifier === 'NON_HEATING' && costType.receiptAssigments.length > 0) !== undefined && sumOfReceiptAssignmentNonHeatingTotal) ? accounting.formatMoney(sumOfReceiptAssignmentNonHeatingTotal,
                '€', 2, '.', ',', '%v %s')
                :
                '-'}
              </TableCell>
          </TableRow>
        <TableRow>
            <TableCell style={{...this.tableBodyCellStyling, ...{fontWeight: 'bold', backgroundColor: 'rgb(247, 247, 250)'}}}>
                Betriebkosten gesamt
            </TableCell>
            
            {allBillingStatements.map((billingStatement)=>
            <TableCell style={{...this.tableBodyCellStyling, ...{fontWeight: 'bold', textAlign: 'right'}}}>
                {accounting.formatMoney(billingStatement.totalAllocations, '€', 2, '.', ',', '%v %s')}
            </TableCell>)}
            <TableCell style={{...this.tableBodyCellStyling, ...{fontWeight: 'bold', textAlign: 'right', borderLeft: '3px solid #DADAE6'}}}>
                {(sumOfHeatingTotal || sumOfNonHeatingTotal) ? accounting.formatMoney(sumOfHeatingTotal + sumOfNonHeatingTotal,
                '€', 2, '.', ',', '%v %s') : '-'}
            </TableCell>
            <TableCell style={{...this.tableBodyCellStyling, ...{fontWeight: 'bold', textAlign: 'right'}}}>
                {(sumOfTenantHeatingTotal || sumOfTenantNonHeatingTotal) ? accounting.formatMoney(sumOfTenantHeatingTotal + sumOfTenantNonHeatingTotal,
                '€', 2, '.', ',', '%v %s') : '-'}
              </TableCell>
            <TableCell style={{...this.tableBodyCellStyling, ...{fontWeight: 'bold', textAlign: 'right'}}}>
                {(sumOfReceiptAssignmentHeatingTotal || sumOfReceiptAssignmentNonHeatingTotal) ? accounting.formatMoney(sumOfReceiptAssignmentHeatingTotal + sumOfReceiptAssignmentNonHeatingTotal
                  , '€', 2, '.', ',', '%v %s') : '-'}
            </TableCell>
        </TableRow>
        <TableRow>
            <TableCell style={{...this.tableBodyCellStyling, ...{backgroundColor: 'rgb(247, 247, 250)'}}}>
              Vorauszahlungen
            </TableCell>
            
            {allBillingStatements.map((billingStatement)=>
            <TableCell style={{...this.tableBodyCellStyling, ...{textAlign: 'right'}}}>
                {accounting.formatMoney(billingStatement.prepayments, '€', 2, '.', ',', '%v %s')}
            </TableCell>)}
            <TableCell style={{...this.tableBodyCellStyling, ...{textAlign: 'right', borderLeft: '3px solid #DADAE6'}}}>
                {accounting.formatMoney(allBillingStatements.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.prepayments ? a.prepayments : null), 0), '€', 2, '.', ',', '%v %s')}
            </TableCell>
            <TableCell style={{...this.tableBodyCellStyling, ...{textAlign: 'right'}}}>
              {accounting.formatMoney(allBillingStatements.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.prepayments ? a.prepayments : null), 0), '€', 2, '.', ',', '%v %s')}
            </TableCell>
            <TableCell style={{...this.tableBodyCellStyling, ...{textAlign: 'right'}}}>
                {accounting.formatMoney(allBillingStatements.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.prepayments ? a.prepayments : null), 0), '€', 2, '.', ',', '%v %s')}
            </TableCell>
        </TableRow>
        <TableRow>
            <TableCell style={{...this.tableBodyCellStyling, ...{backgroundColor: 'rgb(247, 247, 250)'}}}>
              Differenz
            </TableCell>
            
            {allBillingStatements.map((billingStatement)=>
            <TableCell style={{...this.tableBodyCellStyling, ...{textAlign: 'right'}}}>
                {accounting.formatMoney(billingStatement.prepayments - billingStatement.totalAllocations, '€', 2, '.', ',', '%v %s')}
            </TableCell>)}
            <TableCell style={{...this.tableBodyCellStyling, ...{textAlign: 'right', borderLeft: '3px solid #DADAE6'}}}>
                {accounting.formatMoney(( 
                  allBillingStatements.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.prepayments ? a.prepayments : null), 0))
                  -
                  (sumOfHeatingTotal + sumOfNonHeatingTotal), 
                  '€', 
                  2, 
                  '.', 
                  ',', 
                  '%v %s'
                )}
            </TableCell>
            <TableCell style={{...this.tableBodyCellStyling, ...{textAlign: 'right'}}}>
                {accounting.formatMoney(
                  allBillingStatements.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.prepayments ? a.prepayments : null), 0)
                  -
                  (sumOfTenantHeatingTotal + sumOfTenantNonHeatingTotal), 
                  '€', 
                  2, 
                  '.', 
                  ',', 
                  '%v %s'
                )}
            </TableCell>
            <TableCell style={{...this.tableBodyCellStyling, ...{textAlign: 'right'}}}>
                {accounting.formatMoney((
                  allBillingStatements.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.prepayments ? a.prepayments : null), 0))
                  -
                  (sumOfReceiptAssignmentHeatingTotal + sumOfReceiptAssignmentNonHeatingTotal), 
                  '€', 
                  2, 
                  '.', 
                  ',', 
                  '%v %s'
                )}
            </TableCell>
        </TableRow>
    </TableBody>
    );
  }
  
  render(){
    const {messagePreview} = this.props;
      return (
      <TableContainer className='billingstatement-control-sheet-table-container'>
        <Table style={{borderBottom: '1px solid #DADAE6', borderCollapse: messagePreview && 'collapse', width: messagePreview && '100%'}}>
            {this.getTableHeader()}
            {this.getTableBody()}
        </Table>
      </TableContainer>
      );
          
  }
}

const mapStateToProps = (state) => {
  return {
    allReducer: state,
    currentDataId: state.UtilityBillingReducer.currentBillingId,
    tenantBills: state.UtilityBillingReducer.utilityBillingData,
    utilityBillingData: state.UtilityBillingReducer.utilityBillingData,
    heatingFilter: state.UtilityBillingReducer.HeatingAllocationFilter,
  };
};

export default connect(mapStateToProps)(ControlSheetTable);

