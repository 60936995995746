import { Box, Card, Group, Stack, Text } from '@mantine/core';
import { Link } from 'react-router-dom';

const SingleDataCard = ({ heading, moreLink, children }) => {
  return (
    <Card shadow="xs" sx={(theme) => ({ "& a": { color: theme.colors.brand[4] } })}>
      <Stack>
        <Group position="apart">
          <Text component="h4" sx={{ margin: 0, fontSize: 18 }}>{heading}</Text>
          <Link to={moreLink}>Alle &rsaquo;</Link>
        </Group>
        <Box>
          {children}
        </Box>
      </Stack>
    </Card>
  )
}

export default SingleDataCard;