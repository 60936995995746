import React from 'react';
import { HeadingContainer } from '../../../../index';
import Autoload from '../../../../Autoload';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default].general;

export default function SignupCards({ heading, subheading, steps, children, className }) {
  return (
    <div className={`${className}`}>
      <div className="signup-heading-wrapper">
        <HeadingContainer
          heading={heading}
          rightContent={
            <p>
              {lang.text_steps}
              {''} {steps} {lang.text_of} 6
            </p>
          }
        />
        <p className="subheading">{subheading}</p>
      </div>
      <div className="signup-card-content">{children}</div>
    </div>
  );
}
