import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setTenantBills } from '../../../../redux/action/UtilityBilling/UtilityBillingActions';
import { formatNumber } from '../../../../helpers/stringsManipulations';
import { EmptyStateContainer } from '../../../../neoverv';
import Autoload from '../../../../neoverv/Autoload';
import tap from '../../../../assets/images/module/general/TapIconBlue.svg';


var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];

export class CompositionOfHeatingCostsTable extends Component {

  tableHeadCellStyling = {
    color: '#727293',
    padding: '0 9px 0 9px',
    fontWeight: '500',
    whiteSpace: 'nowrap',
    margin: '0px',
    fontSize: '14px',
    lineHeight: '20px',
    border: '1px solid #DADAE6',
    textTransform: 'capitalize',
    height: '48px',
  }
  tableBodyCellStyling = {
    color: '#0e0e1d',
    padding: '5px 9px 5px 9px',
    fontWeight: '400',
    whiteSpace: 'nowrap',
    fontSize: '14px',
    lineHeight: '20px',
    border: '1px solid #DADAE6',
    height: '48px',
    minWidth: '90px',
    // borderBottom: 'none',
    // borderRight: 'none'
  }

  constructor(props){
    super(props);
    if(props.messagePreview){
      this.tableBodyCellStyling.fontSize = '7pt';
      this.tableBodyCellStyling.height = '0px'
      this.tableBodyCellStyling.padding = '1mm'
      this.tableBodyCellStyling.lineHeight = '11pt'

      this.tableHeadCellStyling.fontSize = '7pt';
      this.tableHeadCellStyling.height = '0px'
      this.tableHeadCellStyling.padding = '1mm'
      this.tableHeadCellStyling.lineHeight = '11pt'
      //this.tableBodyCellStyling.minWidth = '40px';
    } 
  }

  getTableLabels = () => {
    return (
      <TableRow>
        <TableCell style={{...this.tableHeadCellStyling}}>{lang.utility.text_cost_type}</TableCell>
        <TableCell style={{...this.tableHeadCellStyling, ...{textAlign: 'right'}}}>{lang.utility.text_total_cost}</TableCell>
      </TableRow>
    );
  };
  calculateTotal=()=>{
    let total=0;
    //console.log('calculateTotal: ',this.props.selectedTenantData.tenantRows[i].totalCost);
    this.props.heatingCosts.filter((heatingCost)=> !this.props.utilityData?.data?.isCO2TaxActivated ? heatingCost.heatingCostTypeName !== 'CO2-Steuer Erstattung Vermieteranteil' : heatingCost)
    .map((row)=> {
        total = total + row.heatingCost;
    })
    console.log('calculateTotal: ', total);
    return formatNumber(total, true);;
  }
    render(){
      const {heatingCosts, isTablet, print=false, messagePreview, utilityData} = this.props;
      console.log('heatingCosts: ', heatingCosts);
        return (
        heatingCosts && heatingCosts.length > 0 ?
        <TableContainer component={Paper} style={{background: 'transparent', width: messagePreview ? '60%' : !isTablet && '50%'}}>
        <Table style={{borderCollapse: 'collapse'}}>
          <TableHead style={{border: '1px solid #DADAE6', borderBottom: 'none'}}>
            {this.getTableLabels()}
          </TableHead>
          <TableBody style={{background: 'white', border: '1px solid #DADAE6'}}>
            {
            heatingCosts.filter((heatingCost)=> !utilityData?.data?.isCO2TaxActivated ? heatingCost.heatingCostTypeName !== 'CO2-Steuer Erstattung Vermieteranteil' : heatingCost)
            .map((item, index)=>
            <>
            <TableRow>
                <TableCell style={this.tableBodyCellStyling}>
                    {item.heatingCostType}
                </TableCell>
                <TableCell style={{...this.tableBodyCellStyling, ...{textAlign: 'right', borderRight: '1px solid #DADAE6'}}}>
                    {formatNumber(item.heatingCost, true)}
                </TableCell>
            </TableRow>
            </>)}
            <TableRow>
                <TableCell style={{...this.tableBodyCellStyling, ...{fontWeight: 'bold', borderBottom: '1px solid #DADAE6'}}}>
                    {'Gesamte Heizungskosten'}
                </TableCell>
                <TableCell style={{...this.tableBodyCellStyling, ...{fontWeight: 'bold', textAlign: 'right', borderRight: '1px solid #DADAE6', borderBottom: '1px solid #DADAE6'}}}>
                    {this.calculateTotal()}
                </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      :
      !print ?
      <div style={{background: 'white', padding: '40px', width: !isTablet && '50%'}}>
        <EmptyStateContainer 
        styleMainContainer={{marginTop: 0}} 
        heading={'Es liegen keine Heizkostendetails vor.'} 
        subheading={'Geben Sie im Heizkostenrechner Kostenpositionen an, die hier angezeigt werden sollen.'} 
        icon={tap} 
        showBtn={true}  
        btnText={'Heizkostenrechner'}
        btnHandler={()=> {
          this.props.history.push(`/utility-billing/utility?currentBillingId=${this.props.currentDataId}&ub-step=heating-cost&openHeatingForm=true`);
          this.props.goBackTwice();
        }}
        />
      </div> 
      : 
      null);
    }
}

const mapStateToProps = (state) => {
  return {
    allReducer: state,
    currentDataId: state.UtilityBillingReducer.currentBillingId,
    tenantBills: state.UtilityBillingReducer.utilityBillingData,
    utilityBillingData: state.UtilityBillingReducer.utilityBillingData,
    heatingFilter: state.UtilityBillingReducer.HeatingAllocationFilter,
  };
};

const mapDispatchToProps = {
  setTenantBills,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompositionOfHeatingCostsTable);

