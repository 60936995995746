import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import {
  ColorButton,
  UtilityBillsTableRow,
  MobileUtilityCostAllocation,
  EmptyStateContainer,
  HeadingContainer,
  Divider,
  PageHeader,
  SwitchContainer,
} from '../../../../neoverv';
import PrintIcon from '../../../../assets/images/module/general/PrintIcon.svg';
import downArrow from '../../../../assets/images/module/general/SelectArrowDown.svg';
import uparrow from '../../../../assets/images/module/general/SelectArrowUp.svg';
import tap from '../../../../assets/images/module/general/TapIconDark.svg';
import { setTenantBills, setUtilityBillingData } from '../../../../redux/action/UtilityBilling/UtilityBillingActions';
import Autoload from '../../../../neoverv/Autoload';
import CrossIcon from '../../../../assets/images/module/general/CrossIcon.svg';
import LockIcon from '../../../../assets/images/module/general/LockIconRed.svg';
import LockIconBlue from '../../../../assets/images/module/general/LockIconBlue.svg';
import PrintIconBlue from '../../../../assets/images/module/general/PrintIconBlue.svg';
import LockOpenIcon from '../../../../assets/images/module/general/LockOpenIcon.svg';
import LockOpenIconBlue from '../../../../assets/images/module/general/LockOpenIconBlue.svg';
import LockOpenIconRed from '../../../../assets/images/module/general/LockOpenIconRed.svg';
import GoldenLockIcon from '../../../../assets/images/module/general/GoldenLockIcon.svg';
import { OverlayTrigger } from 'react-bootstrap';
import {Tooltip as TooltipRb} from 'react-bootstrap';
import AlertModal from '../../../../neoverv/components/Module/General/CustomModal/AlertModal';
import {
  Table, 
  TableBody, 
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@material-ui/core';
import dayjs from 'dayjs';
import moment from 'moment';
import Logo from '../../../../assets/images/module/general/Logo.svg';
import html2canvas from 'html2canvas';
import jsPdf from 'jspdf';
import { Group, Popover, Skeleton, Stack, Text } from '@mantine/core';
import {
  Tooltip, 
  IconButton, 
  Popper, 
  FormControl, 
  Select, 
  InputLabel, 
  MenuItem,
  Drawer
} from '@material-ui/core';
import { formatNumber } from '../../../../helpers/stringsManipulations';
import UtilityBillTable from './UtilityBillTable';
import CompositionOfHeatingCostsTable from './CompositionOfHeatingCostsTable';
import CostSplitWarmWaterAndHeatingTable from './CostSplitWarmWaterAndHeatingTable';
import SplitBaseConsumptionCosts from './SplitBaseConsumptionCosts';
import MeterDataHandler from '../../../../neoverv/helpers/DataHandler/Meter';
import { NumericFormat } from 'react-number-format';
import HeadingContainerNew from '../../../../neoverv/components/Module/General/HeadingContainerNew';
import breadcrumbsContent from '../../../../assets/breadcrumbs.json';
import HelpLogo from '../../../../assets/images/module/general/book.svg';
import TourIcon from '../../../../assets/images/module/general/TourIcon.svg';
import MeterReadingsTable from './MeterReadingsTable';
import UtilityBillingDataHandler from '../../../../neoverv/helpers/DataHandler/UtilityBillings';
import NewMessageInnerContent from '../Messaging/NewMessageInnerContent';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
const { yearDifferenceMoment, checkProperty, loader } = Autoload.MainHelper;
const MOBILE_SIZE = 766;
const TABLET_SIZE = 1400;
//let allBills = [];
let interval = null;
const ref = React.createRef();

export class UtilityBillsTable extends Component {
  static propTypes = {
    prop: PropTypes,
  };

  state = {
    utilityData: {
        date: '',
        recordStatus: 'last',
        isSecured: false
      },
    skeletonLoading: false
  };

  constructor(props){
    super(props);
  }

  componentDidMount() {
    // if(this.props.utilityBillingData.length === 0){
    //     this.props.goBack();
    //     this.props.history.push(`/utility-billing/utility?currentBillingId=${this.props.currentDataId}&ub-step=billing-statements`);
    // }else{
        this.componentDidMountStuff();
    // }
  }

  componentDidUpdate(prevProps){
    if(prevProps.utilityBillingData.length === 0 && this.props.utilityBillingData.length > 0){
      this.componentDidMountStuff();
    }
  }

  componentDidMountStuff=()=>{
    console.log('numbers: ', this.props.history.location.state.numberBillingStatementTableRows, this.props.history.location.state.numberMeterReadingsTableRows);
    let data = this.props.utilityBillingData.find((ub) => ub.id == this.props.currentDataId);
    if (data !== undefined) {
      this.setState(
        {
          utilityData: {
            date: data && data.date ? data.date : '',
            recordStatus: data && data.recordStatus ? data.recordStatus : 'last',
            isSecured: data && data.isSecured ? data.isSecured : false,
            data: data
          }
        }
      );
    }
  }

  render() {
    const { utilityData, open, freezeAlertHeading, freezeAlertMessage, skeletonLoading } = this.state;
    const { propertySelected } = this.props;
    
    return (
      <div className="view-utility-billing">
        <HeadingContainerNew
          heading={lang.utility.text_utility_billing}
          subheading={<>
            <span id='utility-billing-period-dates'>{utilityData.date}</span>
            {utilityData.isSecured && 
            <OverlayTrigger placement="bottom" delay={{ show: 450, hide: 1200 }} overlay={this.renderTooltip}>
                <img src={LockIcon} width='16px' style={{marginBottom: 4, marginLeft: 4}}/>
            </OverlayTrigger>}
          </>}
          className="profile-heading tenant-head utility-billing-head"
          headingIcon={CrossIcon}
          //headingIconClassName={'utility-close-icon'}
          headingIconId={'utility-message-draft'}
          iconHandler={
            () => {
              this.props.setUtilityBillingData([]);
              this.props.history.push('/utility-billing');
            }
            // this.goBackIcon()
          }
          subheadingClassName="utility-subheading"
          subheadingbadge={
            <span className={utilityData.recordStatus === 'last' ? 'last-period-badge' : 'no-recent-period-bagde'}>
              {utilityData.recordStatus === 'last' ? lang.utility.text_last_billing_period : ''}
            </span>
          }
          rightContent={
            <div className="btn-container utility-bility-button">
              <ColorButton
                id={'utility-message-draft-2'}
                onClick={() => {
                  // this.goBack();
                  this.props.goBack();
                  this.props.history.push(`/utility-billing/utility?currentBillingId=${this.props.currentDataId}&ub-step=billing-statements`);
                }}
                text={lang.utility.text_go_back}
                className={`gray-btn`}
              />
            </div>
          }
          breadcrumbs={<div style={{display: 'flex'}}>
          {breadcrumbsContent.map((item)=> item.area.includes('ub-step=sending-billing-statements') && 
          item.breadcrumbs.map((breadcrumb)=>
          breadcrumb.type === 'tour' ? breadcrumb.condition === 'rental_unit_created' && breadcrumb.view === 'desktop' &&
          <div style={{display: 'flex', width: 'auto', alignItems: 'center', cursor: 'pointer', marginRight: '22px'}} onClick={()=> window.Intercom('startTour', breadcrumb.id)}>
            <Skeleton visible={skeletonLoading}>
              <img src={TourIcon} style={{marginTop: '-3px'}}/>
              <span style={{color: '#5555FF', marginLeft: '5px', fontSize: '14px'}}>{breadcrumb.label}</span>
            </Skeleton>
          </div>
          :
          breadcrumb.view === 'desktop' &&
          breadcrumb.condition === propertySelected.propertyType &&
          <div style={{display: 'flex', width: 'auto', alignItems: 'center', cursor: 'pointer', marginRight: '22px'}} onClick={()=> window.Intercom('showArticle', breadcrumb.id)}>
            <Skeleton visible={skeletonLoading}>
              <img src={HelpLogo} style={{marginTop: '-3px'}}/>
              <span style={{color: '#5555FF', marginLeft: '5px', fontSize: '14px'}}>{breadcrumb.label}</span>
            </Skeleton>
          </div>
          ))}
        </div>}
        />

        <Divider className="utilitiy--divider" />
          <Stack className='cost-allocation-section utility-bills-section' spacing={0}>
            <Group>
                <div className="tooltips ub-final-statements-sending-headline">
                    <h5 className="utility-cost-allocation">{`${propertySelected.propertyType === 'REGULAR' ? '4' : '3'}. Abrechnungen versenden`}</h5>
                </div>
            </Group>
            <Group>
                <p className="utility-cost-allocation-subheading">{'Erstellen Sie ein Anschreiben und versenden Sie die Abrechnungen an Ihre Mieter.'}</p>
            </Group>
            <div style={{marginTop: '30px', width: '100%'}}>
                <NewMessageInnerContent 
                placeholders={{tenant: true, billingPeriod: true, balance: true, prepayments: true, rentalUnit: true, property: true, billingStatementTotalCosts: true, billingStatementAdvancePayments: true, billingStatementBalance: true}} 
                showUtilityBillingMessages={true} 
                history={this.props.history}
                numberBillingStatementTableRows={this.props.history?.location?.state?.numberBillingStatementTableRows} 
                numberMeterReadingsTableRows={this.props.history?.location?.state?.numberMeterReadingsTableRows}
                billingPeriod={utilityData.date}
                goBack={()=> {
                    this.props.goBack();
                    this.props.history.push(`/utility-billing/utility?currentBillingId=${this.props.currentDataId}&ub-step=billing-statements`);
                }}/>
            </div>
          </Stack>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    allReducer: state,
    currentDataId: state.UtilityBillingReducer.currentBillingId,
    tenantBills: state.UtilityBillingReducer.utilityBillingData,
    utilityBillingData: state.UtilityBillingReducer.utilityBillingData,
    heatingFilter: state.UtilityBillingReducer.HeatingAllocationFilter,
    propertySelected: state.generalReducer.property,
    currentSubscription: state.generalReducer.currentSubscription,
    accountData: state.generalReducer.accountData
  };
};

const mapDispatchToProps = {
  setTenantBills,
  setUtilityBillingData
};

export default connect(mapStateToProps, mapDispatchToProps)(UtilityBillsTable);
