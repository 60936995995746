import React from 'react';
import { Card, CardBody, CardTitle, CardSubtitle } from 'reactstrap';
import { Avatar } from '../../../../index';

export default function CustomImageCard({
  sideContent,
  cardSize,
  heading,
  subheading,
  image,
  topRightItem,
  children,
  headingClass,
  subheadingClass,
  className,
  isTablet,
  isMobile,
  propertyCard,
  sideBar,
  cardBodyClassName=null
}) {
  const size = cardSize ? cardSize : 'md';
  const maxHeadingLength = isTablet ? 40 : isMobile ? 35 : sideBar ? 23 : 100;
  return (
    <Card className={`custom-card card-size-${size} ${className}`} style={{width: propertyCard && isMobile && 'auto', boxShadow: 'none'}}>
      <CardBody className={`custom-card-body body-size-${size} ${cardBodyClassName}`}>
        <div className="d-flex flex-row" style={{ flex: 1 }}>
          <Avatar image={image} size={size} />
          <div className={`custom-card-content content-${size}`}>
            <div className="custom-card-header">
              <div style={{ flex: 1 }}>
                <CardTitle
                  className={`custom-card-title ${sideContent ? 'sidebar-dropdown-cardtitle' : ''} ${headingClass ? headingClass : ''} mb-0`}
                  style={{fontSize: propertyCard && isMobile && '14px', width: sideContent ? isTablet ? '142px' : '160px' : 'auto', whiteSpace: 'nowrap'}}
                >
                  {heading}
                  {/* {heading && heading.length > maxHeadingLength ? heading.substring(0, maxHeadingLength).trimEnd() + '…' : heading} */}
                </CardTitle>
              </div>
            </div>
            <CardSubtitle className={`custom-card-subtitle ${subheadingClass ? subheadingClass : ''} `}>
              {subheading}
            </CardSubtitle>

            {children}
          </div>
          {topRightItem}
        </div>
        {sideContent}
      </CardBody>
    </Card>
  );
}
