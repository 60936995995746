import React from 'react';
import { Button } from 'reactstrap';

export default function ColorButton(props) {
  const { text, textStyle, buttonStyle, leftImage, rightImage, className, onClick, name, type, form, disabled = false,loaderImage = {img:null, status:false,style:null }, showLoader=false, id, leftMaterialIcon=null } = props;
  return (
    <Button
      id={id}
      disabled={disabled}
      form={form}
      name={name}
      onClick={onClick ? (ev, id) => showLoader === false && onClick(ev, id) : () => {}}
      className={`color-btn ${className} ${showLoader && 'buttonload'}`}
      {...props.buttonConfig}
      type={type}
      style={buttonStyle}
    >
      <div className="button-img-wrapper">
        {leftImage && (
          <img className="btn-img left" src={leftImage.img} alt="left icon" style={{ ...leftImage.style }} />
        )}
        {leftMaterialIcon}
        {showLoader ? 
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '21px'}}>
          <i class="fa fa-circle-o-notch fa-spin"></i>
        </div>
        :
        <p className={`btn-text`} style={textStyle}>{text}</p>}
        {rightImage && (
          <img className="btn-img right" src={rightImage.img} alt="left icon" style={{ ...rightImage.style }} />
        )}
        {loaderImage.status && (
          <img className="btn-img right" id="c-btn-loader" src={loaderImage.img} alt="left icon" style={{ ...loaderImage.style }} />
        )}
      </div>
    </Button>
  );
}
