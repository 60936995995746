import React, { Component } from 'react';
import { DropFile, ValueText } from '../../../../neoverv';
import TrashIcon from '../../../../assets/images/module/general/TrashIcon.svg';
import { setAddEditFormData, setAddEditFormValidation } from '../../../../redux/action/Tenant/TenantActions';
import { setAddTenantDocument, setTenantFiles, setRefreshTenantDocumentsData } from '../../../../redux/action/General/generalActions';
import pdf from '../../../../assets/images/module/general/test.pdf';
import { connect } from 'react-redux';
import Autoload from '../../../../neoverv/Autoload';
import { Group, Skeleton, Stack } from '@mantine/core';
import BuyPremiumModal from '../../../../neoverv/components/Module/General/CustomModal/BuyPremiumModal';
import { formatNumber } from '../../../../helpers/stringsManipulations';
import { showNotification } from '@mantine/notifications';
import RedCross from '../../../../assets/images/module/general/RedCross.svg';
import DocumentsList from '../Documents/DocumentsList';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
const { checkProperty } = Autoload.MainHelper;

let files = [];
export class TenantDocuments extends Component {
  state = {
    selectedFileErr: { state: false, msg: 'No file selected' },
    fileArray: [],
    skeletonLoading: false,
    memoryStatus: null,
    memoryStatusExceededPackageWise: false,
    usedSpaceInMB: null,
    openPaidModal: false,
    error: []
  };

  componentDidMount() {
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'none';
    this.setState({skeletonLoading: true});
    //this.getDocsApi();
    this.getDocumentsStatusByAccountId();
  }

  componentDidUpdate(){
    console.log('refreshTenantDocumentsData_Tenants', this.props.refreshTenantDocumentsData);
    // if(this.props.refreshTenantDocumentsData){
    //   this.setState({skeletonLoading: true});
    //   this.getDocsApi();
    //   this.getDocumentsStatusByAccountId();
    //   this.props.setRefreshTenantDocumentsData(null);
    // }
  }

  getDocumentsStatusByAccountId=()=>{
    const {propertySelected} = this.props;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.document_api_base_url + 'documents/status?accountId=' + propertySelected.accountId,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleGetDocumentsStatusByAccountId',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  handleGetDocumentsStatusByAccountId(response){
    if(response.status && response.statuscode === 200){
      let usedSpaceInMB = (response.result.usedSpaceInBytes/1000000);//this.convertFileSize(response.result.usedSpace);
      let memoryStatusExceededPackageWise = this.props.currentSubscription.subscriptionKey.split('_')[0] === 'STARTER' && usedSpaceInMB >= 100
      this.setState({memoryStatus: response.result, usedSpaceInMB, memoryStatusExceededPackageWise});
      window.Intercom("update", {
        api_base: "https://api-iam.intercom.io",
        app_id: "vvrs5bx3",
        //'Documents file size': response.result.usedSpace
        'document file size': (Math.round(response.result.usedSpaceInBytes / (1024*1024) * 100) / 100)
      });
    }
  }

  convertFileSize=(sizeString)=>{
    if(sizeString){
      if(sizeString.includes('KB')){
        return parseFloat(sizeString) / 1000;
      }else if(sizeString.includes('GB')){
        return parseFloat(sizeString) * 1000;
      }else if(sizeString.includes('TB')){
        return parseFloat(sizeString) * 1000000;
      }else if(sizeString.includes('B') && (!sizeString.includes('M') || !sizeString.includes('G') || !sizeString.includes('T') || !sizeString.includes('K'))){
        return parseFloat(sizeString) / 1000000;
      }else {
        return parseFloat(sizeString);
      }
    }else{
      return 0;
    }
  }
  getDocsApi = () => {
    files = [];
    // Autoload.HttpRequestAxios.HttpRequest(
    //   Autoload.Setting.property_api_base_url +
    //     'tenantDocuments?offset=0&fetch=100&tenantId=' +
    //     checkProperty(this.props.formData, 'id'),
    //   'GET',
    //   null,
    //   null,
    //   false,
    //   true,
    //   false,
    //   this,
    //   'handle_get_docs',
    //   null,
    //   false,
    //   '',
    //   {
    //     // 'Content-Type': 'application/json',
    //     Authorization: Autoload.Utils.getAuthToken(),
    //   }
    // );
  };

  handle_get_docs = (response) => {
    // console.log(response,'handle get docs tenant')
    if (response.status === false && response.message !== 'Not Found') {
      // Autoload.Redirection.redirection_error();
      Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang.newKeys.text_Error_message + response.message);
    }
    if(response.status && response.statuscode === 200){
      if (response.result && response.result.length > 0) {
        let formattedArr = response.result.map((file, idx) => {
          return {
            path: checkProperty(file, 'fileName', '-'), //name
            id: checkProperty(file, 'id'),
            tenantId: checkProperty(file, 'tenantId'),
            fileLocation: checkProperty(file, 'fileLocation'),
          };
        });
        this.setState({ fileArray: formattedArr });
        this.ShowLoader();
      } else {
        this.setState({ fileArray: [] });
        this.ShowLoader();
      }
    }else{
      this.ShowLoader();
    }

    // let files=[];
    // if(response.result.length>0){
    //     files=response.result.map((file,idx)=>(
    //         {
    //           createdOn: checkProperty(file,'createdOn'),
    //           fileLocation: checkProperty(file,'fileLocation'),
    //           path: file.fileLocation ? file.fileLocation.substring(file.fileLocation.lastIndexOf('/')+1):'',
    //           id: checkProperty(file,'id'),
    //           tenant: checkProperty(file,'tenant'),
    //           tenantId: checkProperty(file,'tenantId'),
    //           updatedOn: checkProperty(file,'updatedOn'),
    //         }
    //     ));
    // }
    // this.setState({fileArray: files });
  };
  // handle_get_docs_content=(response)=>{
  //  // console.log('handle_get_docs_content tenant docs' , response)
  //   if (response.status == false) {
  //     Autoload.Redirection.redirection_error();
  //   }
  //   files.push({
  //     path: response.result ? response.result:'',
  //     id: checkProperty(response.other.file,'id'),
  //     tenantId: checkProperty(response.other.file,'tenantId'),
  //   })

  //   // let sorted=files.sort(function(a, b) {
  //   //   console.log('sorting', a, b)
  //   //   return a.path.toString().localeCompare(b.path);
  //   // });

  //   this.setState({fileArray: files })
  // }

  addDocApi = (file) => {

    let fileData =  [{file: file, tenantId: checkProperty(this.props.formData, 'id')}];
    this.props.setTenantFiles(fileData);
    this.props.setAddTenantDocument(true);
    var elem = document.getElementById("uploadTenantDocs");
    if (typeof elem.onclick == "function") {
        elem.onclick.apply(elem);
    }

    // let fileData = new FormData();
    // fileData.append('tenantId', checkProperty(this.props.formData, 'id'));
    // fileData.append('fileName', file);

    // Autoload.HttpRequestAxios.HttpRequest(
    //   Autoload.Setting.property_api_base_url + 'TenantDocuments',
    //   'POST',
    //   fileData,
    //   null,
    //   false,
    //   true,
    //   false,
    //   this,
    //   'handleAddDocResponse',
    //   null,
    //   false,
    //   '',
    //   {
    //     'Content-Type': 'application/json',
    //     Authorization: Autoload.Utils.getAuthToken(),
    //   }
    // );
  };
  handleAddDocResponse = (response) => {
    //console.log('handleAddDocResponse tenant add doc', response)
    if (response.status === false && response.message !== 'Not Found') {
      // Autoload.Redirection.redirection_error();
      Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang.newKeys.text_Error_message + response.message);
    } else {
      // let file={
      //   createdOn: response.result.createdOn,
      //   fileLocation: response.result.fileLocation,
      //   path: response.result.fileName,
      //   id: response.result.id,
      //   tenant: response.result.tenant,
      //   tenantId: response.result.tenantId,
      //   updatedOn: response.result.updatedOn,
      // }
      //   this.setState({ fileArray: [...this.state.fileArray, file ] });
      //this.getDocsApi();
    }
  };

  handleFileChange = (acceptedFile, rejectedFile) => {
    console.log('acceptedFile: ', acceptedFile, rejectedFile);
    // if (acceptedFile.length > 0) {
    //   if(acceptedFile.length > 1){
    //     acceptedFile.map((file)=> this.addDocApi(file));
    //   }else{
    //     this.addDocApi(acceptedFile[0]);
    //   }
    //   // this.setState({ fileArray: [...this.state.fileArray, acceptedFile[0] ] });
    // }
    if (acceptedFile.length > 0) {
        acceptedFile.map((file)=> this.addDocApi(file));
    }
    if (rejectedFile.length > 0) {
      console.log('rejectedFile: ', rejectedFile);
      rejectedFile.map((file, index)=>
      file.errors.forEach((err) => {
        if (err.code === 'file-too-large') {
          this.setState({error: [...this.state.error, {fileName: file.file.name, message: 'Datei zu groß'}]}, ()=> 
          {
            if(rejectedFile.length === (index+1)){
              showNotification({
                message: 
                <Stack spacing={2}>
                  <Group>Es ist ein Fehler aufgetreten. Das Dokument ist zu groß. Maximal erlaubte Dokumentgröße ist 100 MB.</Group>
                  {this.state.error.map((err)=> 
                    <Group noWrap spacing={2}>
                      <span style={{
                        // maxWidth: '290px', 
                        // overflowX: 'hidden', 
                        // textOverflow: 'ellipsis', 
                        // whiteSpace: 'nowrap'
                        }}>
                          {`Dateiname: ${err.fileName}`}
                      </span>
                      {/* <span>{'->'}</span>
                      <span style={{whiteSpace: 'nowrap', color: '#db1738'}}>{`${err.message}`}</span> */}
                    </Group>)}
                </Stack>,
                icon: <img src={RedCross} alt='red-cross'/>,
                id: 'fileSelectionError',
                autoClose: false
              });
              this.setState({error: []});
            }
          })
        }
        if (err.code === 'file-invalid-type') {
          this.setState({error: [...this.state.error, {fileName: file.file.name, message: 'Dateityp ungültig'}]}, ()=> 
          {
            if(rejectedFile.length === (index+1)){
              showNotification({
                message: 
                <Stack spacing={2}>
                  {this.state.error.map((err)=> <Group noWrap spacing={2}><span style={{maxWidth: '290px', overflowX: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>{`Dateiname: ${err.fileName}`}</span><span>{'->'}</span><span style={{whiteSpace: 'nowrap', color: '#db1738'}}>{`${err.message}`}</span></Group>)}
                </Stack>,
                icon: <img src={RedCross} alt='red-cross'/>,
                id: 'fileSelectionError',
                autoClose: false
              });
              this.setState({error: []});
            }
          });
        }
      }));
    } else {
      this.setState({ selectedFileErr: { state: false } });
    }
  };

  removeFile = (selected) => {
    // console.log('removed', selected)
    //delete doc api
    // Autoload.HttpRequestAxios.HttpRequest(
    //   Autoload.Setting.property_api_base_url + 'TenantDocuments/' + selected.id,
    //   'DELETE',
    //   null,
    //   null,
    //   false,
    //   true,
    //   false,
    //   this,
    //   'handleDeleteDoc',
    //   null,
    //   false,
    //   '',
    //   {
    //     Authorization: Autoload.Utils.getAuthToken(),
    //   }
    // );
  };

  handleDeleteDoc = (response) => {
    //console.log('delete doc handleDeleteDoc', response)
    if (response.status === false && response.message !== 'Not Found') {
      // Autoload.Redirection.redirection_error();
      Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang.newKeys.text_Error_message + response.message);
    }

    //this.getDocsApi();
  };

  ShowLoader = () => {
    // var loader = document.getElementById('hello-housing-loader');
    // loader.style.display = 'none';
    // var emptyDiv = document.getElementById('empty-tenant-doc-page');
    // emptyDiv.style.display = 'none';
    // var dataDiv = document.getElementById('tenant-doc-page');
    // dataDiv.style.display = 'block';
    this.setState({skeletonLoading: false});
  };

  render() {
    const {openPaidModal, usedSpaceInMB, memoryStatusExceededPackageWise} = this.state;
    const {currentSubscription} = this.props;
    console.log('this.props.formData: ', this.props.formData);
    console.log('memoryStatusExceededPackageWise: ', memoryStatusExceededPackageWise);
    return (
      <>
        <div id="empty-tenant-doc-page"></div>
        <div className="tenant-doc-section" 
        id="tenant-doc-page" style={{ display: 'block' }}>
          {/* <p className="section-heading"> {lang.tenant.text_documents} </p>
          {this.state.skeletonLoading ?
          <Skeleton visible={this.state.skeletonLoading} style={{width: '352px', height: '100px', marginTop: '22px'}}/>
          :
          <DropFile
            downloadable
            accept={'audio/*,video/*,image/*,application/*,application/pdf,text/*,.csv,.zip,.docx,.doc,.xls,.xlm,.dbf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pptx,.ppt'}
            customProps={{ multiple: true }}
            handleDrop={(acceptedFile, rejectedFile)=> memoryStatusExceededPackageWise ? this.setState({openPaidModal: true}) : this.handleFileChange(acceptedFile, rejectedFile)}
            file={this.state.fileArray}
            removeFile={this.removeFile}
            multiSelect={true}
            maxSize={100000000}
            hideSecondText={true}
          />}
          {this.state.selectedFileErr.state && (
            <ValueText
              className="error"
              text={this.state.selectedFileErr.msg}
              // rightImage={{
              //   img: TrashIcon,
              //   style: { width: '16px', height: '16px' },
              //   handler: () => this.setState({ selectedFileErr: { state: false, msg: lang.general.text_no_file_selected } }),
              // }}
            />
          )} */}
          <DocumentsList tenantManagement={true} showTenantDocuments={true} tenantId={this.props.formData.id} tenantData={this.props.formData} history={this.props.history}/>
          {openPaidModal &&
            <BuyPremiumModal
            heading={'Zusätzlichen Speicherplatz freischalten'}
            open={openPaidModal}
            text={<span style={{fontSize: '15px', color: 'rgb(19, 19, 92)', fontWeight: '400', textAlign: 'center', width: '350px'}}>Sie haben {usedSpaceInMB ? formatNumber(usedSpaceInMB, false) : '0,00'} MB von 100,00 MB verfügbarem Speicherplatz im Starter Paket genutzt. Schalten Sie hellohousing <span onClick={()=> this.props.history.push('/profile/yourSubscription', {newSubscription: true})} style={{color: 'rgb(85, 85, 255)', textDecoration: 'underline', cursor: 'pointer'}}>{currentSubscription?.subscriptionKey.split('_')[0] === 'STARTER' ? 'Premium' : 'Professional'}</span> frei, um weiteren Speicherplatz zu nutzen.</span>}
            onClick={()=> this.props.history.push('/profile/yourSubscription', {newSubscription: true})}
            onCancel={()=> this.setState({openPaidModal: false})}
            />}
        </div>
        <button style={{display: 'none'}} id='upload'/>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    propertySelected: state.generalReducer.property,
    currentRecord: state.tenantDataReducer.currentTenant,
    formData: state.tenantDataReducer.addEditFormData,
    currentSubscription: state.generalReducer.currentSubscription,
    tenantFiles: state.generalReducer.tenantFiles,
    refreshTenantDocumentsData: state.generalReducer.refreshTenantDocumentsData
  };
};
const actions = {
  setAddEditFormData,
  setAddEditFormValidation,
  setAddTenantDocument, 
  setTenantFiles, 
  setRefreshTenantDocumentsData
};

export default connect(mapStateToProps, actions)(TenantDocuments);
