import { Group, Stack, Text } from "@mantine/core";
import Icon from "./Icon";

const LabeledData = ({ label, icon, value, textStyle }) => {
  return (
    <Stack spacing={6}>
      <Text size="sm" color="dimmed" weight={400}>{label}</Text>
      <Group>
        <Icon name={icon} />
        <Text style={textStyle}>{value}</Text>
      </Group>
    </Stack>
  )
}

export default LabeledData;