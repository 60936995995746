import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import * as Feather from 'react-feather';
import NavBadge from './NavBadge';
import HelpModal from '../../CustomModal/HelpModal';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import ValueText from '../../Text/ValueText';
import userIcon from '../../../../../../assets/images/module/general/UserAccount.svg';
import logoutIcon from '../../../../../../assets/images/module/general/logoutAccount.svg';
import Autoload from '../../../../../../neoverv/Autoload';
import { Route } from 'react-router-dom';
import TenantSingleIcon from '../../../../../../assets/images/module/general/TenantLite.svg';
// import TenantLite from '../../../../../assets/images/module/general/TenantLite.svg';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];

const NavSingleItem = ({ item, resetData, ...props }) => {
  const [openHelpModal, setopenHelpModal] = useState(false);
  const [dropdownOpen, setdropdownOpen] = useState(false);

  const toggleHelpModal = () => {
    setopenHelpModal(!openHelpModal);
  };

  const toggledropdownOpen = () => {
    setdropdownOpen(!dropdownOpen);
  };

  //   changePage=(route)=> {
  //   props.history.push(route);
  // }
  const logout = () => {
    handleLogout();
  };
  // const handleLogout = () => {
  //   //reset data and go to login

  //   // localStorage.clear();
  //   resetData();
  //   Autoload.Utils.clear_session_data();

  //   // setTimeout(() => {
  //   //   resetData();
  //   // }, 2000);
  //   // setTimeout(() => {
  //   //   Autoload.Utils.clear_session_data();
  //   //   console.log('props', props);
  //   // }, 4000);

  //   //
  //   window.location.href = '/login';
  //   // props.history.push('/login');
  // };

  const handleLogout = () => {
    Autoload.Utils.clear_session_data();
    window.Intercom('shutdown');
    window.location.href = "/login";
  }
  const profilePage = () => {
    props.history.push('/profile');
  };

  const Icon = item.icon && Feather[item.icon] ? Feather[item.icon] : null;
  if (item.external) {
    const rel = item.target && item.target === '_blank' ? 'noopener noreferrer' : null;

    return (
      <li className="nav-item " style={{padding: '0 10px'}}>
        <a href={item.url} target={item.target} rel={rel} style={{ display: 'flex', alignItems: 'center' }}>
          {item.icon && Icon ? (
            <Icon className="side-nav-icon" />
          ) : (
            <img className="side-nav-icon " src={item.image} alt={item.name} width="15.33px" height="15.34px" />
          )}
          <span className="nav-item-label">{item.name}</span>
          {item.badge && <NavBadge color={item.badge.variant} text={item.badge.text} />}
        </a>
      </li>
    );
  } else if (item.account) {
    // Force relative URLs to start with a slash
    const url = item.url.charAt(0) === '/' ? item.url : `/${item.url}`;
    const profilePicture = props.profilePicture ? props.profilePicture : TenantSingleIcon; //item.image;
    return (

      <li className="nav-item " style={{padding: '0 10px'}}>
        <NavLink to={url} activeClassName="active" style={{ display: 'flex', alignItems: 'center' }}>
          {item.icon && Icon ? (
            <Icon className="side-nav-icon" />
          ) : (
            <img className="side-nav-icon" src={profilePicture} alt={item.name} width="15.33px" height="15.34px" />
          )}
          <span className="nav-item-label">{item.name}</span>
          {item.badge && <NavBadge color={item.badge.variant} text={item.badge.text} />}
        </NavLink>
      </li>

      // <li className="nav-item " style={{display:'flex', justifyContent: 'flex-end', alignItems: 'flex-end'}}>
      //   <ButtonDropdown
      //     className=""
      //     isOpen={dropdownOpen}
      //     toggle={() => toggledropdownOpen()}
      //   >
      //     <DropdownToggle className="logout-profile-btn">
      //       <div style={{ display: 'flex', flexDirection:'column', alignItems: 'center' }}>
      //         {item.icon && Icon ? (
      //           <Icon className="side-nav-icon" />
      //         ) : (
      //           // <img alt={item.name} src={{uri: "https://cdn.pixabay.com/photo/2018/04/18/18/56/user-3331257__340.png"}} className="side-nav-icon"/>
      //           <img
      //             className="side-nav-icon "
      //             src={profilePicture}
      //             alt={item.name}
      //             width="15.33px"
      //             height="15.34px"
      //             style={{ borderRadius: '50%' }}
      //           />
      //         )}
      //         <span className="nav-item-label">{item.name}</span>
      //         {item.badge && <NavBadge color={item.badge.variant} text={item.badge.text} />}
      //       </div>
      //     </DropdownToggle>
      //     <DropdownMenu className="record-dropdown-menu edit-delete-dropdown logout-profile-dropdown">
      //       <DropdownItem
      //         className="logout-profile-dropdown"
      //         onClick={() => {
      //           profilePage();
      //         }}
      //       >
      //         <ValueText text={lang.newKeys.text_profile} leftImage={{ img: userIcon }} />
      //       </DropdownItem>

      //       <DropdownItem onClick={() => logout()} className="logout-profile-dropdown">
      //         <ValueText text={lang.general.text_log_out} leftImage={{ img: logoutIcon }} />
      //       </DropdownItem>
      //     </DropdownMenu>
      //   </ButtonDropdown>
      //   {/* <a onClick={() => alert('this work is for Khadija ')} style={{ display: 'flex', alignItems: 'center' }}>
      //     {item.icon && Icon ? (
      //       <Icon className="side-nav-icon" />
      //     ) : (
      //       <img className="side-nav-icon " src={item.image} alt={item.name} width="15.33px" height="15.34px" />
      //     )}
      //     <span className="nav-item-label">{item.name}</span>
      //     {item.badge && <NavBadge color={item.badge.variant} text={item.badge.text} />}
      //   </a> */}
      // </li>
    );
  } else if (item.modal) {
    return (
      <li className="nav-item " style={{padding: '0 10px'}}>
        <a
          onClick={
            () => //<a href='https://wissen.hellohousing.de/' target='_blank'/>
            // window.open("https://wissen.hellohousing.de/", "_blank")
            window.Intercom('show')
            //setopenHelpModal(!openHelpModal)
            // alert(
            //   'The react development of this project is done by Zeeshan and UI done by Shahood'
            // ) /* alert("this work is for rija ")*/
          }
          style={{ display: 'flex', alignItems: 'center' }}
        >
          {item.icon && Icon ? (
            <Icon className="side-nav-icon" />
          ) : (
            <img className="side-nav-icon " src={item.image} alt={item.name} width="15.33px" height="15.34px" />
          )}
          <span className="nav-item-label">{item.name}</span>
          {item.badge && <NavBadge color={item.badge.variant} text={item.badge.text} />}
          <HelpModal
            // open={true}

            className="add_proprty_modal"
            edit={false}
            open={openHelpModal}
            toggle={() => {
              toggleHelpModal();
            }}
          />
        </a>
      </li>
    );
  } else {
    // Force relative URLs to start with a slash
    const url = item.url.charAt(0) === '/' ? item.url : `/${item.url}`;
    
    return (
      <li className="nav-item " style={{padding: '0 10px'}} onClick={()=> item.name === 'Abmelden' && handleLogout()}>
        <NavLink to={url} activeClassName="active" style={{ display: 'flex', alignItems: 'center'}}>
          {item.icon && Icon ? (
            <Icon className="side-nav-icon" />
          ) : (
            <img className="side-nav-icon" src={item.image} alt={item.name} width="15.33px" height="15.34px" />
          )}
          <span className="nav-item-label" style={{color: item.name === 'Abmelden' && '#727293'}}>{item.name}</span>
          {item.badge && <NavBadge color={item.badge.variant} text={item.badge.text} />}
        </NavLink>
      </li>
    );
  }
};

export default NavSingleItem;
