import { Group, NumberInput, Text, TextInput, Button } from '@mantine/core';
import { 
  TableCell,
  TableRow as Tr,
  Table,
  TableBody
} from '@material-ui/core';
import { formatNumber, formatNumberByLocale, formatUnit } from '../../../helpers/stringsManipulations';
import { useEffect, useState } from 'react';
import Icon from '../../../components/Icon';
import { DatePicker } from '@mantine/dates';
import 'dayjs/locale/de';
import { CustomModal } from '../../../neoverv';
import Autoload from '../../../neoverv/Autoload';
import { NumericFormat, numericFormatter } from 'react-number-format';
import accounting from 'accounting';


const tableMobileBodyCellStyling = {
  color: 'rgb(17, 17, 71)',
  padding: '4px 16px',
  fontWeight: '400',
  whiteSpace: 'wrap',
  fontSize: '14px',
  lineHeight: '20px',
  border: 'none',
  textTransform: 'capitalize',
  fontFamily: 'Inter, sans-serif',
  textAlign: 'right',
  height: '40px',
  display: 'flex',
  marginTop: '4px',
  alignItems: 'center',
  width: '50vw',
  justifyContent: 'flex-end'
}

const tableHeadCellStyling = {
  color: 'rgb(73, 80, 87)',
  padding: '4px 16px',
  fontWeight: '500',
  whiteSpace: 'nowrap',
  fontSize: '14px',
  lineHeight: '20px',
  border: 'none',
  textTransform: 'capitalize',
  fontFamily: 'Inter, sans-serif',
  display: 'flex',
  height: '40px',
  marginTop: '4px',
  alignItems: 'center'
}

const tableMobileRow = {display: 'flex', justifyContent: 'space-between'}

const TableRow = ({ 
  subIndex, 
  rowReading, 
  occasionDate, 
  end, 
  consumption,
  editableIds,
  isMobile,
  onConsumptionChange,
  onLastReadingEdit, 
  onCurrentReadingEdit, 
  onDateChange, 
  onNotesEdit,
  search,
  startValue,
  currentValue,
  handleDataSaving,
  disableMobileUpdate,
  onBlur,
  print= false
 }) => {
  const [lastValue, setLastValue] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [reading, setReading] = useState(rowReading);
  const [lastReadingInFocus, setLastReadingInFocus] = useState(false);
  const [currentReadingInFocus, setCurrentReadingInFocus] = useState(false);
  // useEffect(() => {
  //   onConsumptionChange(consumption, subIndex);
  // }, [consumption]);

  console.log('values: ', startValue, currentValue);
  const tableBodyCellStyling = print ?
  {
    color: '#0e0e1d',
    padding: '1mm',
    fontWeight: '500',
    whiteSpace: 'nowrap',
    margin: '0px',
    fontSize: '7pt',
    lineHeight: '20px',
    border: '1px solid #DADAE6',
    textTransform: 'capitalize',
    height: '0px',
    minWidth: '40px'
  }
  : 
  {
    color: 'rgb(17, 17, 71)',
    padding: print ? '0px 9px' :'6px 16px',
    fontWeight: '400',
    whiteSpace: 'wrap',
    fontSize: '14px',
    lineHeight: '20px',
    border: '1px solid #DADAE6',
    textTransform: 'capitalize',
    fontFamily: 'Inter, sans-serif',
    height: print && '48px',
    wordWrap: 'break-word',     /* Ensures long words wrap */
    wordBreak: 'break-word',    /* Ensures long words break to fit */
    overflowWrap: 'break-word', /* Ensures long words wrap within the cell */
    hyphens: 'auto'
  }

  const onBlurForStartValue=(e)=>{
    let newStartValue = {
      id: startValue?.id,
      value: e.target.value.length === 0 ? null : accounting.unformat(e.target.value, ',')
    };//Autoload.MainHelper.ConvertCurrencDe_BackToNumber(numericFormatter(e.target.value.replaceAll('.', '').replace(',', '.'), {thousandSeparator: ',', decimalSeparator: '.'}));
    console.log('onBlurStartValue: ', newStartValue, e.target.value.length);
    if(currentValue === '' || startValue?.value === null || currentValue === null || startValue?.value === ''){
      onLastReadingEdit(newStartValue, subIndex, true, reading.meter.id);
      onBlur(false);  
    } else {
      onLastReadingEdit(newStartValue, subIndex, true, reading.meter.id);
      onBlur(currentValue < startValue?.value);  
    }
    setLastReadingInFocus(false);
  }

  const onBlurForCurrentReading=(e)=> {
    let newCurrentValue = e.target.value.length === 0 ? null : accounting.unformat(e.target.value, ',');//Autoload.MainHelper.ConvertCurrencDe_BackToNumber(numericFormatter(e.target.value.replaceAll('.', '').replace(',', '.'), {thousandSeparator: ',', decimalSeparator: '.'}));
    console.log('onBlurCurrentValue: ', newCurrentValue, e.target.value, accounting.unformat(e.target.value));
    if(currentValue === '' || startValue?.value === null || currentValue === null || startValue?.value === ''){
      onCurrentReadingEdit(newCurrentValue, subIndex, true, reading.meter.id);
      onBlur(false);
    } else {
      onCurrentReadingEdit(newCurrentValue, subIndex, true, reading.meter.id);
      console.log('currentValue_234: ', currentValue);
      onBlur(end ? false : currentValue < startValue?.value);
    }
    setCurrentReadingInFocus(false);
  }
  console.log('reading.meter:', reading.meter)

  console.log('consumption: ', consumption);
  useEffect(() => {
    setReading(rowReading);
  }, [rowReading])
  return (
    isMobile ?
    <>
      <Tr style={{height: '80px'}} onClick={() => setOpenModal(!openModal)}>
        <TableCell style={{
          maxWidth: '25vw', 
          textTransform: 'none',
          fontFamily: 'Inter, sans-serif',
          border: 'none',
          padding: '2px 16px',
          borderTopLeftRadius: '3px',
          borderBottomLeftRadius: '3px'
          }}><Text 
          style={{
            textOverflow: 'ellipsis', 
            overflow: 'hidden',
            color: '#111147' 
          }}>{reading.meter.meterNo}</Text></TableCell>
        <TableCell style={{
          maxWidth: '32vw',
          textTransform: 'none',
          lineHeight: '30px',
          padding: '2px 16px',
          fontFamily: 'Inter, sans-serif',
          border: 'none',
          }}><Text 
          style={{
            textOverflow: 'ellipsis', 
            overflow: 'hidden', 
            color: '#111147'
          }}>{reading.meter.allocationKey.allocationKeyName}</Text>
        </TableCell>
        
        {/* Ablesewert / meter reading */}
        <TableCell style={{
          border: 'none',
          //backgroundColor: reading.currentValue < reading.startValue?.value && 'rgba(232, 25, 60, .1)',
          padding: '2px 16px',
          borderTopRightRadius: '3px',
          borderBottomRightRadius: '3px',
          }}>
          <NumberInput
            variant="unstyled"
            styles={{ input: { textAlign: 'right', color: currentValue < startValue?.value ? '#D41636' : '#111147', } }}
            // parser={(value) => value.replace(/\$\s?|(,*)/g, '')} 
            precision={2}
            decimalSeparator=","
            min={0}
            value={currentValue}
            hideControls
            placeholder='0,00'
            onChange={(value) => onCurrentReadingEdit(value, subIndex, end, reading.meter.id)}
          />
        </TableCell>
      </Tr>
      <CustomModal
          open={openModal}
          toggle={() => setOpenModal(!openModal)}
          heading="Zählerablesung"
          className="mobile-meter-detail-modal"
          style={{margin: window.innerWidth < 500 && 5}}
        >
          <Table>
              <TableBody>
                <Tr style={tableMobileRow}>
                  <TableCell style={{...tableHeadCellStyling, ...{padding: '6px 16px 6px 0px'}}}>Nummer</TableCell>
                  <TableCell style={{...tableMobileBodyCellStyling, ...{textTransform: 'none', padding: '6px 0px 6px 16px'}}}><Text>{reading.meter.meterNo}</Text></TableCell>
                </Tr>
                <Tr style={tableMobileRow}>
                  <TableCell style={{...tableHeadCellStyling, ...{padding: '6px 16px 6px 0px'}}}>Art</TableCell>
                  <TableCell style={{...tableMobileBodyCellStyling, ...{textTransform: 'none', padding: '6px 0px 6px 16px'}}}><Text>{reading.meter.allocationKey.allocationKeyName}</Text></TableCell>
                </Tr>
                <Tr style={tableMobileRow}>
                  <TableCell style={{...tableHeadCellStyling, ...{padding: '6px 16px 6px 0px'}}}>Standort</TableCell>
                  <TableCell style={{...tableMobileBodyCellStyling, ...{padding: '6px 0px 6px 16px'}}}><Text>{reading.meter.location}</Text></TableCell>
                </Tr>
                {/* <Tr style={tableMobileRow}>
                  <TableCell style={{...tableHeadCellStyling, ...{}}}>Ablesedatum</TableCell>
                  <TableCell style={{...tableMobileBodyCellStyling, ...{padding: '2px', display: 'flex', justifyContent: 'flex-end'}}} className="reduceSidePaddings">
                    
                        <DatePicker 
                          locale="de"
                          value={new Date(occasionDate)}
                          icon={<Icon name="calendar" />}
                          variant="unstyled"
                          inputFormat="DD.MM.YYYY"
                          allowFreeInput={true}
                          clearable={false}
                          onChange={(value) => onDateChange(value, subIndex)}
                          zIndex={10000}
                          styles={{
                            root: {
                              width: '70%',
                            },
                            input: {
                              textAlign: 'end',
                              paddingRight: '16px',
                            }
                          }}
                          shouldCloseOnSelect={false}
                        />
                    
                  </TableCell>
                </Tr> */}
                
                  <Tr style={tableMobileRow}>
                    
                  <TableCell style={{...tableHeadCellStyling, ...{padding: '6px 16px 6px 0px'}}}>Startwert</TableCell>

                    <TableCell 
                      style={{...tableMobileBodyCellStyling, ...{padding: '6px 5px 6px 16px', backgroundColor: end ? (editableIds.length ? (editableIds.length && editableIds.find((id)=> reading.id === id) ? "rgba(242, 242, 252, 1)" : "rgba(242, 242, 252, 1)") : "rgba(242, 242, 252, 1)") : "rgba(242, 242, 252, 1)"}}}>
                      {end ?
                      '-'
                      :
                      <NumberInput
                        variant="unstyled"
                        styles={{ input: { textAlign: 'right', color: startValue?.value === null && lastValue === '' && '#adb5bd' } }}
                        precision={2}
                        decimalSeparator=","
                        min={0}
                        value={(startValue?.value || startValue?.value === 0) ? startValue?.value : undefined}
                        placeholder='0,00'
                        hideControls
                        //disabled={end ? (editableIds.length ? (editableIds.length && !editableIds.find((id)=> reading.id === id)) : false) : false}
                        onBlur={onBlurForStartValue}
                        onChange={(value) => (value || value === 0) ? Autoload.MainHelper.ConverterCurrencyDe_DisplayOnly(value.toString() + '', false) !== Autoload.MainHelper.ConverterCurrencyDe_DisplayOnly(startValue?.value + '', false) ? onLastReadingEdit(value, subIndex, false, reading.meter.id) 
                        : 
                        startValue?.value !== 0 && Autoload.MainHelper.ConverterCurrencyDe_DisplayOnly(value.toString() + '', false) === '0,00' ? onLastReadingEdit(value, subIndex, false, reading.meter.id) : null
                        : (startValue?.value !== null && startValue?.value !== undefined) && onLastReadingEdit(null, subIndex, false, reading.meter.id)}
                      />}
                    </TableCell>
                  </Tr>
                  <Tr style={tableMobileRow}>
                    <TableCell style={{...tableHeadCellStyling, ...{padding: '6px 16px 6px 0px'}}}>Ablesewert</TableCell>
                    <TableCell style={{...tableMobileBodyCellStyling, ...{padding: '6px 5px 6px 16px', backgroundColor: currentValue !== null && currentValue < startValue?.value ? 'rgba(232, 25, 60, .1)' : 'rgba(242, 242, 252, 1)'}}}>
                      <NumberInput
                        variant="unstyled"
                        className='current-readings-value'
                        styles={{ input: { textAlign: 'right', color: currentValue !== null && (currentValue < startValue?.value ? '#D41636' : '#111147'), } }}
                        precision={2}
                        decimalSeparator=","
                        min={0}
                        value={(currentValue || currentValue === 0) ? currentValue : undefined}
                        hideControls
                        placeholder='0,00'
                        onChange={(value) => (value || value === 0) ? Autoload.MainHelper.ConverterCurrencyDe_DisplayOnly(value.toString() + '', false) !== Autoload.MainHelper.ConverterCurrencyDe_DisplayOnly(currentValue + '', false) ? onCurrentReadingEdit(value, subIndex, false, reading.meter.id)
                        :
                        currentValue !== 0 && Autoload.MainHelper.ConverterCurrencyDe_DisplayOnly(value.toString() + '', false) === '0,00' && Autoload.MainHelper.ConverterCurrencyDe_DisplayOnly(currentValue + '', false) === '0,00' && onCurrentReadingEdit(value, subIndex, false, reading.meter.id)
                        : (currentValue !== null && currentValue !== undefined) && onCurrentReadingEdit(null, subIndex, false, reading.meter.id)}
                        // onBlur={(e)=> e.target.value === '' || startValue?.value === null ? onBlur(false) : onBlur(e.target.value < startValue?.value)}
                        onBlur={onBlurForCurrentReading}
                      />
                    </TableCell>
                  </Tr>
                  <Tr style={tableMobileRow}>
                    <TableCell style={{...tableHeadCellStyling, ...{padding: '6px 16px 6px 0px'}}}>Verbrauch</TableCell>
                    <TableCell style={{...tableMobileBodyCellStyling, ...{
                      //backgroundColor: consumption < 0 ? 'rgba(232, 25, 60, .1)' : 'transparent',
                      padding: '6px 0px 6px 16px',
                      color: consumption < 0 ? '#D41636' : '#111147',
                      fontWeight: 'bold',
                      textAlign: 'right',
                      textTransform: 'none'
                    }}}><Text>{end ? '-' : consumption !== null && formatNumberByLocale(consumption) +' '+ formatUnit(reading.meter.allocationKey.units)}</Text></TableCell>
                  </Tr>
                  <Tr style={tableMobileRow}>
                    <TableCell style={{...tableHeadCellStyling, ...{width: "auto", padding: '6px 16px 6px 0px'}}}>Notiz</TableCell>
                  </Tr>
                  <Tr>
                    <TableCell style={{textTransform: 'none', height: 'auto', justifyContent: 'space-around', border:'none', padding: '16px 0px'}}>
                      <TextInput placeholder="Hinzufügen" style={{border: '1px solid #DADAE6', borderRadius: '3px', padding: '5px', width: '100%'}} variant="unstyled" value={reading.notes} onChange={(event) => event.target.value !== reading.notes && onNotesEdit(event.target.value, subIndex)} onBlur={()=> currentValue === '' || startValue?.value === '' ? onBlur(false) : onBlur(currentValue < startValue?.value)} />
                    </TableCell>
                  </Tr>
                </TableBody>
              </Table>
              <Group style={{display: isMobile && 'flex', justifyContent: isMobile && 'space-between', width: isMobile && '100%', marginTop: '10px'}}>
                <Button variant="white" onClick={() => setOpenModal(false)}>Abbrechen</Button>
                <Button variant="filled" disabled={disableMobileUpdate} onClick={handleDataSaving}>Speichern</Button>
              </Group>
        </CustomModal>
    </>
    :
    <Tr>
      <TableCell style={{...tableBodyCellStyling, ...{textTransform: 'none', borderRight: print && 'none'}}}><Text>{reading.meter.meterNo}</Text></TableCell>
      <TableCell style={{...tableBodyCellStyling, ...{textTransform: 'none', borderRight: print && 'none'}}}><Text>{reading.meter.meterName}</Text></TableCell>
      <TableCell style={{...tableBodyCellStyling, ...{whiteSpace: 'normal', textTransform: 'none', borderRight: print && 'none'}}}><Text>{reading.meter.allocationKey.allocationKeyName}</Text></TableCell>
      <TableCell style={{...tableBodyCellStyling, ...{borderRight: print && 'none'}}}><Text>{reading.meter.location}</Text></TableCell>
      {/*<TableCell style={{...tableBodyCellStyling, ...{padding: '2px'}}} className="reduceSidePaddings">
        <DatePicker 
          locale="de"
          //value={new Date(occasionDate)}
          value={new Date(reading.meterReadingDate)}
          icon={<Icon name="calendar" />}
          variant="unstyled"
          inputFormat="DD.MM.YYYY"
          allowFreeInput={true}
          clearable={false}
          onChange={(value) => onDateChange(value, subIndex, reading.meter.id)}
        />
        </TableCell>*/}

      {/* Editable number fields */}
      {/* Startwert / Last value */}
      {/* TODO: Numbers format needed: 1.234.567.89 */}
      {!end &&
        <TableCell 
        style={{...tableBodyCellStyling, ...{borderBottom: print && '1px solid #DADAE6', borderRight: print && 'none', backgroundColor: print ? 'white' : (end && editableIds.length) ? (editableIds.length && editableIds.find((id)=> reading.id === id) ? "rgba(242, 242, 252, 1)" : "rgba(242, 242, 252, 1)") : end ? 'white' : "rgba(242, 242, 252, 1)"}}}>
        {print ?
        <Text style={{textAlign: 'right'}}>{(startValue?.value || startValue?.value === 0) && !end ? formatNumber(startValue?.value) : '-'}</Text>
        :
        <>
        {end ?
        <Text style={{textAlign: 'right'}}>{'-'}</Text>
        :
        <NumericFormat 
        className='form-control form-control-meter-readings'
        style={{textAlign: 'right', height: '100%', paddingRight: 0}}
        value={(startValue?.value || startValue?.value === 0) ? startValue?.value : ''}
        thousandSeparator="." 
        decimalSeparator=","
        onValueChange={(values, sourceInfo) => (values.floatValue || values.floatValue === 0) ? Autoload.MainHelper.ConverterCurrencyDe_DisplayOnly(values.floatValue.toString() + '', false) !== Autoload.MainHelper.ConverterCurrencyDe_DisplayOnly(startValue?.value + '', false) ? onLastReadingEdit(values.floatValue, subIndex, false, reading.meter.id) 
          : 
          startValue?.value !== 0 && Autoload.MainHelper.ConverterCurrencyDe_DisplayOnly(values.floatValue.toString() + '', false) === '0,00' ? onLastReadingEdit(values.floatValue, subIndex, false, reading.meter.id) : null
          : (startValue?.value !== null && startValue?.value !== undefined) && onLastReadingEdit(null, subIndex, false, reading.meter.id)
        }
        onFocus={()=> setLastReadingInFocus(true)}
        onBlur={onBlurForStartValue}
        //onKeyPress={this.handleEnterKeyPress}
        placeholder='0,00'
        suffix=''
        decimalScale={lastReadingInFocus ? 5 : 2}
        fixedDecimalScale={lastReadingInFocus ? false : true}
        allowNegative={false}
        />}
        </>
        }
      </TableCell>}

      {/* Ablesewert / meter reading */}
      <TableCell style={{...tableBodyCellStyling, ...{ 
        borderBottom: print && '1px solid #DADAE6',
        borderRight: print && 'none', backgroundColor: print ? 'white' : currentValue !== null && currentValue < startValue?.value && !end ? 'rgba(232, 25, 60, .1)' : 'rgba(242, 242, 252, 1)'
        }}}>
        {print ?
        <Text style={{textAlign: 'right'}}>{(currentValue || currentValue === 0) ? formatNumber(currentValue) : '-'}</Text>
        :
        // <NumberInput
        //   variant="unstyled"
        //   className='current-readings-value'
        //   styles={{ input: { textAlign: 'right', color: currentValue !== null && (currentValue < startValue?.value ? '#D41636' : '#111147') } }}
        //   // parser={(value) => value.replace(/\$\s?|(,*)/g, '')} 
        //   precision={2}
        //   decimalSeparator=","
        //   min={0}
        //   value={(currentValue || currentValue === 0) ? currentValue : undefined}
        //   hideControls
        //   placeholder='0,00'
        //   onChange={(value) => (value || value === 0) ? Autoload.MainHelper.ConverterCurrencyDe_DisplayOnly(value.toString() + '', false) !== Autoload.MainHelper.ConverterCurrencyDe_DisplayOnly(currentValue + '', false) ? onCurrentReadingEdit(value, subIndex, false, reading.meter.id)
        //   :
        //   currentValue !== 0 && Autoload.MainHelper.ConverterCurrencyDe_DisplayOnly(value.toString() + '', false) === '0,00' && Autoload.MainHelper.ConverterCurrencyDe_DisplayOnly(currentValue + '', false) === '0,00' && onCurrentReadingEdit(value, subIndex, false, reading.meter.id)
        //   : (currentValue !== null && currentValue !== undefined) && onCurrentReadingEdit(null, subIndex, false, reading.meter.id)
        //   }
        //   //onBlur={(e)=> e.target.value === '' || startValue?.value === null ? onBlur(false) : onBlur(e.target.value < startValue?.value)}
        //   onBlur={(e)=> {
        //     if(e.target.value === '' || startValue?.value === null){
        //       //setTimeout(() => {  
        //       //}, 1000);
        //       onCurrentReadingEdit(parseFloat(e.target.value.replace(',','.')), subIndex, true, reading.meter.id);
        //       onBlur(false);
        //     } else {
        //       // const value = e.target.value;
        //       // setTimeout(() => {  
        //       // }, 1000);
        //       onCurrentReadingEdit(parseFloat(e.target.value.replace(',','.')), subIndex, true, reading.meter.id);
        //       onBlur(e.target.value < startValue?.value);
        //     }
        //   }}
        // />
        <NumericFormat 
        className='form-control form-control-meter-readings'
        style={{textAlign: 'right', height: '100%', paddingRight: 0}}
        value={(currentValue || currentValue === 0) ? currentValue : ''}
        thousandSeparator="." 
        decimalSeparator=","
        onValueChange={(values, sourceInfo) => (values.floatValue || values.floatValue === 0) ? Autoload.MainHelper.ConverterCurrencyDe_DisplayOnly(values.floatValue.toString() + '', false) !== Autoload.MainHelper.ConverterCurrencyDe_DisplayOnly(currentValue + '', false) ? onCurrentReadingEdit(values.floatValue, subIndex, false, reading.meter.id)
          :
          currentValue !== 0 && Autoload.MainHelper.ConverterCurrencyDe_DisplayOnly(values.floatValue.toString() + '', false) === '0,00' && Autoload.MainHelper.ConverterCurrencyDe_DisplayOnly(currentValue + '', false) === '0,00' && onCurrentReadingEdit(values.floatValue, subIndex, false, reading.meter.id)
          : (currentValue !== null && currentValue !== undefined) && onCurrentReadingEdit(null, subIndex, false, reading.meter.id)
        }
        onFocus={()=> setCurrentReadingInFocus(true)}
        onBlur={onBlurForCurrentReading}
        //onKeyPress={this.handleEnterKeyPress}
        placeholder='0,00'
        suffix=''
        decimalScale={currentReadingInFocus ? 5 : 2}
        fixedDecimalScale={currentReadingInFocus ? false : true}
        allowNegative={false}
        />
        }
      </TableCell>

      {!end &&
      <TableCell style={{...tableBodyCellStyling, ...{
        borderRight: print && 'none',
        backgroundColor: consumption < 0 ? 'rgba(232, 25, 60, .1)' : 'transparent',
        color: consumption < 0 ? '#D41636' : '#111147',
        textAlign: 'right'
      }}}><Text>{end ? '-' : isNaN(consumption) ? null : consumption !== null ? formatNumber(consumption) : print && '-'}</Text></TableCell>}
      <TableCell style={{...tableBodyCellStyling, ...{textTransform: 'none', borderRight: print && 'none'}}}><Text>{formatUnit(reading.meter.allocationKey.units)}</Text></TableCell>
      <TableCell colSpan={end && 3} style={tableBodyCellStyling}>
        {print ?
        <Text style={{textTransform: 'none'}}>{reading.notes}</Text>
        :
        <TextInput 
        placeholder="Hinzufügen" 
        variant="unstyled" 
        value={reading.notes} 
        onChange={(event) => event.target.value !== reading.notes && onNotesEdit(event.target.value, subIndex, reading.meter.id)} 
        onBlur={()=> currentValue === '' || startValue?.value === '' ? onBlur(false) : onBlur(currentValue < startValue?.value)}
        />}</TableCell>
    </Tr>
  )
}

export default TableRow