import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import Autoload from '../../../../../neoverv/Autoload';
var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
class Error500 extends Component {
  render() {
    return (
      <div className="error500">
        <Row>
          <Col xs={12}>
            <h1>{lang.general.text_error_msg}</h1>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Error500;
