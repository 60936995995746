import React, { useState, useEffect } from 'react';
import { RecordOptions, DeleteModal, RentModal } from '../../../../index';
import Autoload from '../../../../Autoload';
import TransparentButton from '../Buttons/TransparentButton';
import { Form } from 'react-bootstrap';
import moment from 'moment';
import localization from 'moment/locale/de';
var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];

function RentList({
  moveout,
  parent,
  updateRents,
  sendRentData,
  dataToDelete,
  data,
  labels,
  className,
  labelClassName,
  disabled
}) {
  const [rentModalOpen, setRentModalOpen] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [showPreviousTenants, setShowPreviousTenants] = useState(false);
  const [lastRent, setLastRent] = useState(false);
  const [firstRent, setFirstRent] = useState(false);
  const toggleDeleteModal = () => {
    setDeleteOpen(!deleteOpen);
  };

  const toggleRentModal = () => {
    setRentModalOpen(!rentModalOpen);
    setTimeout(() => {
      Autoload.MainHelper.cust_fb_element('cc_i_r_nr', 500);
      Autoload.MainHelper.cust_fb_element('cc_i_r_ar', 500);
    }, 500);
  };

  const deleteRent = (id) => {
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'block';
    //call api and pass parent for handler
    console.log('delete api', id, parent);
    let btnelement = document.getElementById('rent-delete-modal-footer');
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'Rents/' + id,
      'DELETE',
      null,
      btnelement.children[1],
      false,
      true,
      false,
      parent,
      'handleRentDeleteApi',
      { cancelbtn: btnelement.children[0] },
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };

  const getRecord = (id, lastRent, firstRent) => {
    const record = data.find((rcd) => rcd.id === id);
    setCurrentRecord(record);
    setLastRent(lastRent);
    setFirstRent(firstRent);
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'none';
  };

  const getTableLabels = () => {
    return labels.map((label, index) => {
      return (
        <td key={index} className={labelClassName[index]}>
          {label === '' ? '' : label}
        </td>
      );
    });
  };

  const filterData = () => {
    let filtered;
    if (showPreviousTenants) {
      filtered = data;
    } else {
      filtered = data.filter((tenant) => tenant.current == true);
    }
    return filtered;
  };

  const getTableData = () => {
    return filterData().map((row, rowIndex) => {
      // console.log(moveout.value, row.start, 'moveout in rent list');
      moment().locale("de", localization).format('LLL');
      return (
        <tr key={rowIndex} className="table-data" style={{cursor: 'pointer'}} onClick={()=>{!disabled && getRecord(row.id, rowIndex === 0, filterData().length <= 1 && data.length === 1); !disabled && toggleRentModal();}}>
          <td className="rent-time-frame">
            {row.start !== '' ? moment(row.start, 'DD.MM.YYYY').format('MMM YYYY') : '-'}
            {row.end == '' || row.end == null || row.end == 'Invalid date'
              ? moment(moveout.value).format('DD.MM.YYYY') == '' ||
                moment(moveout.value).format('DD.MM.YYYY') == 'Invalid date'
                ? ' - ... '
                : ` - ...` //${moment(moveout.value).format('DD.MM.YYYY')} -> removed this for ticket 1141
              : ` - ${moment(row.end, 'DD.MM.YYYY').format('MMM YYYY')}`}
          </td>
          <td className="rent-net" translate="no" lang="en">
            {/* {row.net} */}
            {Autoload.MainHelper.ConverterCurrencyDe_DisplayOnly(row.net + '', false)}
            {/* <Form.Control
              type="text"
              className="cc_currancy_val"
              value={Autoload.MainHelper.ConverterCurrencyDe(row.net + '', false)}             
              readOnly={true}
              key={"r-rn-"+rowIndex}
            /> */}
            {/* <input type="text"   id="cc_rent-net" name="cc_rent-net"/> */}
          </td>
          <td className="rent-advance" translate="no" lang="en">
            {/* {row.advance !== '' ? row.advance : '-'} */}
            {Autoload.MainHelper.ConverterCurrencyDe_DisplayOnly(row.advance + '', false)}
          </td>
          <td className="total-rent" translate="no" lang="en">
            {Autoload.MainHelper.ConverterCurrencyDe_DisplayOnly((row.advance+row.net)  + '', false)}
          </td>
          <td className="empty">
            {
              <RecordOptions
                toggleDeleteModal={() => toggleDeleteModal()}
                toggleEditModal={() => toggleRentModal()}
                getRecord={()=> getRecord(row.id, rowIndex === 0, filterData().length <= 1 && data.length === 1)}
                showDeleteButton={filterData().length <= 1 && data.length === 1 ? false : rowIndex === 0}//filterData().length <= 1 && data.length === 1 ? false : true}
                disabled={disabled}
              />
            }
          </td>
        </tr>
      );
    });
  };
  return (
    data.length > 0 && (
      <>
        <table className={`table borderless ${className} mb-0`}>
          <tbody>
            <tr className="table-heading" style={{padding: '11px 26px'}}>{getTableLabels()}</tr>
            {getTableData()}
          </tbody>

          <DeleteModal
            record={currentRecord}
            className="delete-rent-modal"
            open={deleteOpen}
            toggle={() => toggleDeleteModal()}
            handleDelete={(id) => {
              deleteRent(id);
            }}
            // showInfo={['net','timeframe']}
            // showInfo={[
            //   `${Autoload.MainHelper.ConverterCurrencyDe(currentRecord.net + '', true)}`,
            //   `${currentRecord.start} ${
            //     currentRecord.end && currentRecord.end !== 'Invalid date' ? `- ${currentRecord.end}` : ''
            //   }`,
            // ]}
            // start={currentRecord.start}
            // end={currentRecord.end}
            type={lang.tenant.text_rent}
            modalId="rent-delete-modal-footer"
            showDeleteText={false}
            textShowModal={'Mieteintrag löschen'}
            showtext={false}
            typeText={'Sind Sie sicher, dass Sie diesen Mieteintrag löschen wollen?'}
          />

          {/* edit rent modal */}

          <RentModal
            updateRents={updateRents}
            close={() => setRentModalOpen(false)}
            record={currentRecord}
            sendRentData={sendRentData}
            edit={true}
            className="rent-modal"
            open={rentModalOpen}
            AllRents={data}
            lastRent={lastRent}
            firstRent={firstRent}
            moveout={moveout}
            // toggle={toggleRentModal}
          />
        </table>

        {/* previous btn */}
        {data.length > 1 && (
          <TransparentButton
            onClick={() => setShowPreviousTenants(!showPreviousTenants)}
            text={showPreviousTenants ? lang.tenant.text_show_less : lang.tenant.text_show_previous_rents}
            className="tc-dark-purple mb-0 p-0"
          />
        )}
      </>
    )
  );
}
RentList.defaultProps = {
  parentId: null,
  data: [],
  labels: [],
  dataClassName: [],
  labelClassName: [],
  dataIcons: [],
};

export default RentList;
