import React, { Component } from 'react';
import { Table, Row, Col } from 'reactstrap';
import { ColorButton, SearchContainer, EmptyStateContainer, SortAlphabetically } from '../../../../neoverv';
import CostTypeRow from '../../../../neoverv/components/Module/General/CostTypeRow/CostTypeRow.js';
import PageHeader from '../../../elements/module/general/PageHeader';
import Autoload from '../../../../neoverv/Autoload';
import AddCostTypeModal from '../../../../neoverv/components/Module/General/CustomModal/AddCostTypeModal.js';
import { connect } from 'react-redux';
import GlobalServices from '../../../../neoverv/helpers/services/GlobalServices';
import GeneralDataHandler from '../../../../neoverv/helpers/DataHandler/General';
import emptyPropertyHome from '.././../../../assets/images/module/general/PropertyIcon.svg';
import { Grid, Group, Skeleton } from '@mantine/core';
var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
const MOBILE_SIZE = 500;

class CostType extends Component {
  state = {
    AddCostTypeModal: false,
    searchValue: '',
    sortingAlphabetically: true,
    ascendingOrder: true,
    checkFilter: false,
    filterData: [],
    cost_type_data: [],
    isMobile: window.innerWidth <= MOBILE_SIZE,
    skeletonLoading: false
  };

  componentDidMount() {
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'none';
    this.setState({skeletonLoading: true});
    this.bind_list_cost_type_data();
  }

  bind_list_cost_type_data = () => {
    GlobalServices._getCostTypeByProperty(this, 'handle_get_list_data', this.props.propertySelected);
  };

  handle_get_list_data(response) {
    console.log(response, 'data cost type');

    if (response.status === false && response.message !== 'Not Found') {
      // Autoload.Redirection.redirection_error();
      // Autoload.DataHandlerGeneral.GeneralErrorMessage(true, 'Error :  ' + response.message);
      Autoload.DataHandlerGeneral.GeneralErrorMessage(
        true,
        lang.newKeys.text_error_not_found
        // + response.message
      );
    }
    const showCostDataResponse = GeneralDataHandler.getCostTypes(response.result);

    let sortedarray = [];
    let field = 'costTypeName';
    sortedarray = showCostDataResponse.sort((a, b) =>
      (a[field] || '').toString().localeCompare((b[field] || '').toString())
    );
    console.log(sortedarray, 'sortedarray');
    this.setState({
      showCostData: sortedarray,
      cost_type_data: response.result,
    });

    // this.setState({ showCostData });
    this.ShowLoader();
  }

  filterListBySearch = (e, properties) => {
    let filteredArray = [];
    filteredArray = this.state.showCostData.filter((item) => 
      item.costTypeName.toLowerCase().includes(e.target.value.toLowerCase()) ||
      item.allocationKeyName.toLowerCase().includes(e.target.value.toLowerCase())
    );
    this.setState({
      searchValue: e.target.value,
      filterData: filteredArray,
    });
  };

  sortAlphabetically = () => {
    // A to Z
    let filteredArray = [];
    let field = 'costTypeName';
    filteredArray = this.state.showCostData.sort((a, b) =>
      (a[field] || '').toString().localeCompare((b[field] || '').toString())
    );
    let sortFilterArray = this.state.filterData.sort((a, b) =>
    (a[field] || '').toString().localeCompare((b[field] || '').toString())
    );
    this.setState({
      showCostData: filteredArray,
      filterData: sortFilterArray
    });
  };

  sortReverseAlphabetically = () => {
    // Z to A
    let filteredArray = [];
    let field = 'costTypeName';
    filteredArray = this.state.showCostData.sort((a, b) =>
      (b[field] || '').toString().localeCompare((a[field] || '').toString())
    );
    let sortFilterArray = this.state.filterData.sort((a, b) =>
    (a[field] || '').toString().localeCompare((b[field] || '').toString())
    );
    this.setState({
      showCostData: filteredArray,
      filterData: sortFilterArray
    });
  };

  toggleAddCostTypeModal = () => {
    this.setState({ AddCostTypeModal: !this.state.AddCostTypeModal }, () => {
      console.log(this.state.AddCostTypeModal, 'this.state.AddCostTypeModal open state');
    });
  };

  ShowLoader = () => {
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'none';
    var emptyDiv = document.getElementById('empty-cost-type-page');
    emptyDiv.style.display = 'none';
    var dataDiv = document.getElementById('respond-cost-type-page');
    dataDiv.style.display = 'block';
    this.setState({skeletonLoading: false});
  };

  renderAddCostTypeButton=()=>{
    const {skeletonLoading} = this.state;
    return(
      <Skeleton visible={skeletonLoading}>
        <ColorButton
          onClick={() => {
            this.toggleAddCostTypeModal();
          }}
          text={lang.property.text_cost_addcosttype}
          className="purple-btn"
        />
      </Skeleton>
    )
  }

  renderSearchBar=()=>{
    const {skeletonLoading, searchValue} = this.state;
    return(
      <Skeleton visible={skeletonLoading}>
        <SearchContainer
          placeholder={`${lang.general.text_search}...`}
          value={searchValue}
          disabled={false}
          onChange={(e) => this.filterListBySearch(e, ['costTypeName', 'allocationKeyName'])}
        />
      </Skeleton>
    )
  }

  render() {
    const {skeletonLoading, showCostData} = this.state;
    const {isMobile} = this.props;
    console.log('showCostData: ', showCostData);
    console.log('this.props: ', this.props);
    return (
      <>
        <div id="empty-cost-type-page"></div>
        <div className="cost-page" id="respond-cost-type-page" style={{ display: 'block', marginTop: isMobile && '30px' }}>
          {/* <img src={emptyPropertyHome} /> */}
          {/* page header */}
          <PageHeader
            heading={!isMobile && <Skeleton visible={skeletonLoading}>{lang.property.text_cost}</Skeleton>}
            headingClassName={'tooltips property-cost-type-heading'}
            rightContent={!isMobile && this.renderAddCostTypeButton()}
            searchElement={
              isMobile ?
              <Grid>
                <Grid.Col span={8}>
                  {this.renderSearchBar()}
                </Grid.Col>
                <Grid.Col span={4}>
                  {this.renderAddCostTypeButton()}
                </Grid.Col>                
              </Grid>
              :
              this.renderSearchBar()
            }
            sortElement={
              <Skeleton visible={skeletonLoading}>
                <SortAlphabetically
                  sortingParam="alphabet"
                  handleAscending={()=> this.setState({sortingAlphabetically: true})}
                  handleDescending={()=> this.setState({sortingAlphabetically: false})}
                />
              </Skeleton>
            }
          />

          {/* /////////////////////////// web view  ///////////////////////// */}
          {this.state.showCostData ?
          <Table responsive bordered={false} className="table-responsive-cost">
          <thead>
            <tr>
              <th className="table-heading-cost name-cost">{lang.property.costType_tablehead_name}</th>
              <th className="table-heading-cost allocation-key-cost">
                {lang.property.costType_tablehead_allocationkeys}
              </th>
              <th style={{display: 'flex', justifyContent: 'center'}} className="table-heading-cost active-cost">{lang.newKeys.text_system_cost_type}</th>
              <th className="table-heading-cost last-text dot-cost">{lang.property.Text}</th>
            </tr>
          </thead>

          {this.state.searchValue === '' ? (
            this.state.showCostData &&
            this.state.showCostData.sort((a, b) =>
            this.state.sortingAlphabetically ?
            (a.costTypeName || '').toString().localeCompare((b.costTypeName || '').toString())
            : (b.costTypeName || '').toString().localeCompare((a.costTypeName || '').toString())
          ).map((data, index) => {
              return (
                <tbody style={{borderTop: 'none'}}>
                  <CostTypeRow
                    key={`1-${index}`}
                    showCostData={data}
                    showCostWholeData={showCostData}
                    parent={this}
                    costTypeData={this.state.cost_type_data}
                  />
                </tbody>
              );
            })
          ) : this.state.filterData.length > 0 ? (
            this.state.filterData.sort((a, b) =>
            this.state.sortingAlphabetically ?
            (a.costTypeName || '').toString().localeCompare((b.costTypeName || '').toString())
            : (b.costTypeName || '').toString().localeCompare((a.costTypeName || '').toString())
          ).map((data, index) => {
              return (
                <tbody >
                  <CostTypeRow
                    key={`2-${index}`}
                    showCostData={data}
                    showCostWholeData={showCostData}
                    parent={this}
                    costTypeData={this.state.cost_type_data}
                  />
                </tbody>
              );
            })
          ) : (
            <tbody>
              <tr>
                <td>
                  <EmptyStateContainer
                    heading="Keine Übereinstimmung gefunden."
                    // subheading="You must add property first to manage utility billing."
                    icon={emptyPropertyHome}
                    // showbtn={false}
                    btnHandler={() => {
                      this.setState({ AddCostTypeModal: !this.state.AddCostTypeModal });
                    }}
                    // buttonDisable={true}
                    btnText={'Kostenart hinzufügen'} //"Add cost type"
                  />
                </td>
                {/* <div
                  style={{ backgroundColor: '#F7F7FA', textAlign: 'center' }}
                  className="property-types-emptystates"
                > */}

                {/* </div> */}
              </tr>
            </tbody>
          )}
          </Table>
          :
          <>
          <Skeleton visible={skeletonLoading} style={{height: '23px', marginBottom: '10px'}}>
            <div>
            </div>
          </Skeleton>
          {[...Array(10)].map((e, i) => 
            <Skeleton visible={skeletonLoading} style={{height: '75px', marginBottom: '10px'}}>
              <div>
              </div>
            </Skeleton>
          )}
          </>
          }

          {/* /////////////////////////// mobile view ///////////////////////// */}
          {this.state.AddCostTypeModal && (
            <AddCostTypeModal
              record={null}
              showCostData={showCostData}
              parent={this}
              costTypeData={this.state.showCostData}
              className="add_cost_type_modal"
              edit={false}
              open={this.state.AddCostTypeModal}
              toggle={this.toggleAddCostTypeModal}
              callBackFunction={() => this.bind_list_cost_type_data()}
            />
          )}
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    propertySelected: state.generalReducer.property,
  };
};
export default connect(mapStateToProps)(CostType);
