import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setTenantBills } from '../../../../redux/action/UtilityBilling/UtilityBillingActions';
import { formatNumber } from '../../../../helpers/stringsManipulations';
import { EmptyStateContainer } from '../../../../neoverv';
import Autoload from '../../../../neoverv/Autoload';
import tap from '../../../../assets/images/module/general/TapIconDark.svg';
import { Group, Stack } from '@mantine/core';
import 'chartjs-plugin-style';
import { Chart as ChartJS, LinearScale, RadialLinearScale, BarElement, BarController, CategoryScale, Decimation, Filler, Legend, Title, Tooltip } from "chart.js";
import { Bar, Chart } from "react-chartjs-2";
import moment from 'moment';
import GaugeChart from 'react-gauge-chart';
import accounting from 'accounting';


var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];

export class BenchmarkingAverageUserBuilding extends Component {

  tableHeadCellStyling = {
    color: '#727293',
    padding: '0 9px 0 9px',
    fontWeight: '500',
    whiteSpace: 'nowrap',
    margin: '0px',
    fontSize: '14px',
    lineHeight: '20px',
    border: '1px solid #DADAE6',
    textTransform: 'capitalize',
    height: '48px',
  }
  tableBodyCellStyling = {
    color: '#0e0e1d',
    padding: '5px 9px 5px 9px',
    fontWeight: '400',
    whiteSpace: 'nowrap',
    fontSize: '14px',
    lineHeight: '20px',
    border: '1px solid #DADAE6',
    height: '48px',
    minWidth: '90px',
    // borderBottom: 'none',
    // borderRight: 'none'
  }

  state ={
    basicCostHeating: 0,
    consumptionCostHeating: 0,
    basicCostWarmWater: 0,
    consumptionCostWarmWater: 0,
    basicCostHeatingObject1: null,
    basicCostHeatingObject2: null,
    basicCostWarmWaterObject1: null,
    basicCostWarmWaterObject2: null,
    chartDataConsumption: null,
    chartDataCost: null,
    chartOptions: null
  }

  constructor(props){
    super(props);
    ChartJS.register(LinearScale, RadialLinearScale, BarElement, BarController, CategoryScale, Decimation, Filler, Legend, Title, Tooltip);
  }
  
  componentDidMount(){
   
  }

  getBackgroundColor=(value)=>{
    if(value === 'A+'){
        return '#059266';
    }else if(value === 'A'){
      return '#059266cc'
    }else if(value === 'B'){
        return '#2CAF09';
    }else if(value === 'C'){
      return '#bf941abd';
    }else if(value === 'D'){
        return '#BF941A';
    }else if(value === 'E'){
      return '#ff9500ad';
    }else if(value === 'F'){
        return '#FF9500'
    }else if(value === 'G'){
        return '#FC8309'
    }else if(value === 'H'){
        return '#E8193C'
    }else{
      return '#727293'
    }
  }

  renderHouseIndicator=(marginLeft, increaseLineHeight)=>{
    const {utilityData, messagePreview} = this.props;
    return(
      <Stack className='house-comparison-scale-col-value-indicator' spacing={2}>
        <Group spacing={0} position='center' align={'baseline'} noWrap style={{marginLeft, zIndex: '999999'}}>
          <span style={{backgroundColor: `rgb(256, 256, 256, ${messagePreview ? '1' : '0.75'})`, borderRadius: '4px', zIndex: '999999', padding: '0px 4px'}}><span className='consumption-card-value-scale'>{utilityData?.data?.householdEnergyEfficiency ? accounting.formatNumber(utilityData?.data?.householdEnergyEfficiency, 1, '.', ',') : '-'}</span><span className='consumption-card-unit-scale'>{'kWh'}</span></span>
        </Group>
        <Group  position='center'>
          <div style={{border: '0.1px solid rgb(0, 0, 0, 0.45)', borderRadius: '50%'}}><div className='assessment-card-box-three-heading-circle' style={{backgroundColor: '#babbfe'}}/></div>
        </Group>
        <Group position='center'>
          <div className='house-comparison-scale-col-value-indicator-line' style={{height: increaseLineHeight}}/>
        </Group>
      </Stack>
    )
  }

  renderTenantIndicator=(marginLeft, increaseLineHeight)=>{
    const {selectedTenantData, messagePreview} = this.props;
    return(
      <Stack className='house-comparison-scale-col-value-indicator' spacing={2}>
        <Group spacing={0} position='center' align={'baseline'} noWrap style={{marginLeft}}>
          <span style={{backgroundColor: `rgb(256, 256, 256, ${messagePreview ? '1' : '0.75'})`, borderRadius: '4px', zIndex: '999999', padding: '0px 4px'}}><span className='consumption-card-value-scale'>{selectedTenantData?.tenantEnergyEfficiency ? accounting.formatNumber(selectedTenantData?.tenantEnergyEfficiency, 1, '.', ',') : '-'}</span><span className='consumption-card-unit-scale'>{'kWh'}</span></span>
        </Group>
        <Group position='center'>
          <div style={{border: '0.1px solid rgb(0, 0, 0, 0.45)', borderRadius: '50%'}}><div className='assessment-card-box-two-heading-circle'/></div>
        </Group>
        <Group position='center'>
          <div className='house-comparison-scale-col-value-indicator-line' style={{height: increaseLineHeight}}/>
        </Group>
      </Stack>
    )
  }

  renderIndicatorsLogic=(option1, option2=false)=>{
    const {utilityData, selectedTenantData} = this.props;
    if((utilityData?.data?.householdEnergyEfficiencyLevel === option1 || utilityData?.data?.householdEnergyEfficiencyLevel === option2) && (selectedTenantData?.tenantEnergyEfficiencyLevel === option1 || selectedTenantData?.tenantEnergyEfficiencyLevel === option2)){
      if(utilityData?.data?.householdEnergyEfficiency > selectedTenantData?.tenantEnergyEfficiency){
        return(
          <Group noWrap align={'flex-end'} position={'center'} style={{width: '11.1%'}}>
            {this.renderTenantIndicator('-40px')}
            {this.renderHouseIndicator(null, '100px')}
          </Group>
        );
      }else{
        return(
          <Group noWrap align={'flex-end'} position={'center'} style={{width: '11.1%'}}>
            {this.renderHouseIndicator('-40px')}
            {this.renderTenantIndicator(null, '100px')}
          </Group>
        );
      }
    }else if((utilityData?.data?.householdEnergyEfficiencyLevel === option1 || utilityData?.data?.householdEnergyEfficiencyLevel === option2)){
      return(this.renderHouseIndicator(null, utilityData?.data?.householdEnergyEfficiency > selectedTenantData?.tenantEnergyEfficiency ? '100px' : '40px'));
    }else if((selectedTenantData?.tenantEnergyEfficiencyLevel === option1 || selectedTenantData?.tenantEnergyEfficiencyLevel === option2)){
      return(this.renderTenantIndicator(null, selectedTenantData?.tenantEnergyEfficiency > utilityData?.data?.householdEnergyEfficiency ? '100px' : '40px'));
    }else{
      return(<div className='house-comparison-scale-col-value-indicator'/>);
    }
  }

  renderHouseComparisonScale=()=>{
    return (
      <Stack className='house-comparison-scale' spacing={10} style={{width: '100%'}}>
        <Stack spacing={2}>
          <Group className='house-comparison-scale-row' noWrap align={'flex-end'} spacing={0}>
            {this.renderIndicatorsLogic('A+')}
            {this.renderIndicatorsLogic('A')}
            {this.renderIndicatorsLogic('B')}
            {this.renderIndicatorsLogic('C')}
            {this.renderIndicatorsLogic('D')}
            {this.renderIndicatorsLogic('E')}
            {this.renderIndicatorsLogic('F')}
            {this.renderIndicatorsLogic('G')}
            {this.renderIndicatorsLogic('H')}
          </Group>
          <Group className='house-comparison-scale-row' noWrap spacing={0}>
            <div className='house-comparison-scale-col-1'/>
            <div className='house-comparison-scale-col-2'/>
            <div className='house-comparison-scale-col-3'/>
            <div className='house-comparison-scale-col-4'/>
            <div className='house-comparison-scale-col-5'/>
            <div className='house-comparison-scale-col-6'/>
            <div className='house-comparison-scale-col-7'/>
            <div className='house-comparison-scale-col-8'/>
            <div className='house-comparison-scale-col-9'/>
          </Group>
        </Stack>
        <Group className='house-comparison-scale-row' position='apart'>
          <Stack align={'center'} spacing={'2px'}>
              <Group>
                  <span style={{fontSize: '12px', fontWeight: 'bold', color: '#727293'}}>A+</span>
              </Group>
              <Group>
                  <span style={{fontSize: '8px', fontWeight: 'normal', color: '#727293'}}>(unter 30 kWh/m<sup>2</sup>)</span>
              </Group>
          </Stack>
          <Stack align={'center'} spacing={'2px'}>
              <Group>
                  <span style={{fontSize: '12px', fontWeight: 'bold', color: '#727293'}}>H</span>
              </Group>
              <Group>
                  <span style={{fontSize: '8px', fontWeight: 'normal', color: '#727293'}}>(über 250 kWh/m<sup>2</sup>)</span>
              </Group>
          </Stack>
        </Group>
      </Stack>
    )
  }

  render(){
    const {selectedTenantData, isTablet, messagePreview, propertySelected, utilityData} = this.props;
    const {chartDataConsumption, chartDataCost, chartOptions} = this.state;
    //const {basicCostHeating, basicCostWarmWater, consumptionCostHeating, consumptionCostWarmWater, basicCostHeatingObject1, basicCostHeatingObject2, basicCostWarmWaterObject1, basicCostWarmWaterObject2} = this.state;
    console.log('utilityData7283: ', utilityData, selectedTenantData);
      return (
        <Group id='capture' noWrap grow position='apart' style={{width: isTablet ? '100%' : '80%', 
        background: 'white', padding: '40px 20px'//, border: '1px solid rgb(218, 218, 230)'
        }}>
          <Group className='assessment-card-box-one' position='apart' align={'flex-end'} spacing={70} noWrap>
            {/* <span style={{fontSize: '16px', fontWeight: '700'}}>{'Energieverbrauch im Hausvergleich'}</span> */}
            <Group style={{width: '100%'}}>
              {this.renderHouseComparisonScale()}
            </Group>
            <Group>
                <Stack style={{width: '100%'}}>
                  <Stack className='assessment-card-box-three'>
                        <Group noWrap spacing={'5px'}><div style={{border: '0.1px solid rgb(0, 0, 0, 0.45)', borderRadius: '50%'}}><div className='assessment-card-box-two-heading-circle'/></div><span className='assessment-card-box-three-heading-text'>Ihr Verbrauch (je m<sup>2</sup>)</span></Group>
                        <Group noWrap spacing={'xl'}>
                            {<Group noWrap spacing={0}>
                                <Group noWrap position='center' style={{height: '27px', width: '40px', backgroundColor: this.getBackgroundColor(selectedTenantData?.tenantEnergyEfficiencyLevel)}}>
                                    <span className='assessment-card-icon-text'>{selectedTenantData?.tenantEnergyEfficiencyLevel ? selectedTenantData?.tenantEnergyEfficiencyLevel : '-'}</span>
                                </Group>
                                <Group noWrap position='center' className='arrow-right' style={{height: '27px', width: '5px', borderLeft: `5px solid ${this.getBackgroundColor(selectedTenantData?.tenantEnergyEfficiencyLevel)}`}}/>
                            </Group>}
                            <div>
                                <span className='consumption-card-value' style={{whiteSpace: 'nowrap'}}>{selectedTenantData?.tenantEnergyEfficiency ? accounting.formatNumber(selectedTenantData?.tenantEnergyEfficiency, 1, '.', ',') : '-'}</span><span className='consumption-card-unit'>{'kWh'}</span>
                            </div>
                        </Group>
                        <Group noWrap>
                            <span className='assessment-card-box-three-end-text' style={{whiteSpace: 'nowrap'}}>{selectedTenantData?.tenantEnergyEfficiency ? `Ihr Verbrauch liegt im ${selectedTenantData?.tenantEnergyEfficiencyLevel}-Bereich und damit ${utilityData?.data?.householdEnergyEfficiency === selectedTenantData?.tenantEnergyEfficiency ? '' : utilityData?.data?.householdEnergyEfficiency < selectedTenantData?.tenantEnergyEfficiency ? 'über' : 'unter'} ${utilityData?.data?.householdEnergyEfficiency === selectedTenantData?.tenantEnergyEfficiency ? 'im' : 'dem'} Haus-Durchschnitt.` : 'Es liegen noch keine Informationen zu Ihrem Verbrauchslevel vor.'}</span>
                        </Group>
                    </Stack>
                    <Stack className='assessment-card-box-two'>
                        <Group noWrap spacing={'5px'}><div style={{border: '0.1px solid rgb(0, 0, 0, 0.45)', borderRadius: '50%'}}><div className='assessment-card-box-three-heading-circle' style={{backgroundColor: '#babbfe'}}/></div><span className='assessment-card-box-two-heading-text'>Haus-Durchschnitt (je m<sup>2</sup>)</span></Group>
                        <Group noWrap spacing={'5px'}>
                            <Group spacing={'xl'}>
                                {<Group spacing={0} noWrap>
                                    <Group position='center' noWrap style={{height: '27px', width: '40px', backgroundColor: this.getBackgroundColor(utilityData?.data?.householdEnergyEfficiencyLevel)}}>
                                        <span className='assessment-card-icon-text'>{utilityData?.data?.householdEnergyEfficiencyLevel ? utilityData?.data?.householdEnergyEfficiencyLevel : '-'}</span>
                                    </Group>
                                    <Group position='center' className='arrow-right' noWrap style={{height: '27px', width: '5px', borderLeft: `5px solid ${this.getBackgroundColor(utilityData?.data?.householdEnergyEfficiencyLevel)}`}}/>
                                </Group>}
                                <div>
                                    <span className='consumption-card-value' style={{whiteSpace: 'nowrap'}}>{utilityData?.data?.householdEnergyEfficiency ? accounting.formatNumber(utilityData?.data?.householdEnergyEfficiency, 1, '.', ',') : '-'}</span><span className='consumption-card-unit'>{'kWh'}</span>
                                </div>
                            </Group>
                        </Group>
                        <Group noWrap>
                            <span className='assessment-card-box-two-end-text' style={{whiteSpace: 'nowrap'}}>{utilityData?.data?.householdEnergyEfficiency ? 'Der Durchschnitt beinhaltet alle Mietenden in Ihrem Haus.' : 'Es liegen keine Informationen zum Haus-Durchschnitt vor.'}</span>
                        </Group>
                    </Stack>
                </Stack>
            </Group>
          </Group>
          
        </Group>
      );
          
  }
}

const mapStateToProps = (state) => {
  return {
    allReducer: state,
    currentDataId: state.UtilityBillingReducer.currentBillingId,
    tenantBills: state.UtilityBillingReducer.utilityBillingData,
    utilityBillingData: state.UtilityBillingReducer.utilityBillingData,
    heatingFilter: state.UtilityBillingReducer.HeatingAllocationFilter,
    propertySelected: state.generalReducer.property,
  };
};

const mapDispatchToProps = {
  setTenantBills,
};

export default connect(mapStateToProps, mapDispatchToProps)(BenchmarkingAverageUserBuilding);

