import React, { useState, useRef, useEffect } from 'react';
import { usePdf } from '@mikecousins/react-pdf';
import { Button } from 'reactstrap';
import { ChevronRight, ChevronLeft, ZoomIn, ZoomOut} from '@material-ui/icons';
import ExpandIcon from '../../../../../assets/images/module/general/ExpandIcon.svg';
import { Group, Stack } from '@mantine/core';
import Autoload from '../../../../Autoload';

const MOBILE_SIZE = 992;
const SMALLER_MOBILE_SIZE = 500;
var { loader } = Autoload.MainHelper;

const PostviewMessageTenantPortal = ({selectedRecipient, senderEmail, senderProfile, propertySelected, accountData, rentalUnits, messageData, isMobile}) => {
  const [rentalUnit, setRentalUnit] = useState();

  const getDocumentsById=(id)=>{
    loader(true);
    if(id){
      fetch(Autoload.Setting.document_api_base_url + 'documents/' + id + '/download',
        {
          method: 'GET',
          headers: {
            'Authorization': Autoload.Utils.getAuthToken()
          }
        })
        .then(response => {
          response.blob()
          .then(data => {
            //download progress
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            let url = window.URL.createObjectURL(data);
            a.href = url;
            a.download = `${propertySelected.name}-${rentalUnit?.name}-${selectedRecipient ? selectedRecipient.name.replace(/,/g, '_') : 'Mieter'}.pdf`;
            a.click();
            window.URL.revokeObjectURL(url);
            loader(false);
          })
        })
    }
  }

  useEffect(()=> {
    let rentalUnit = rentalUnits.find((ru)=> ru.tenantsData.find((tenant)=> tenant.id === selectedRecipient.tenantId) !== undefined);
    setRentalUnit(rentalUnit);
  })

  return (
    <div style={{width: isMobile ? '90mm' : '210mm', //height: '297mm', 
    background: 'white', //boxShadow: '0px 1px 2px #000', 
    padding: isMobile ? '7mm' : '23mm'}}>
      <Group spacing={2}>
            <span style={{fontSize: isMobile ? '12px' : '16px', fontWeight: 600, fontFamily: 'Arial', textAlign: 'justify', color: '#000'}}>Betreff:</span>
            <div className={'message-card-title'} style={{fontSize: isMobile ? '12px' : '16px', fontWeight: 600, fontFamily: 'Arial', textAlign: 'justify', color: '#000'}} dangerouslySetInnerHTML={{__html: selectedRecipient.subject}}/>  
        </Group>
      <div style={{marginTop: isMobile ? '5mm' : '20mm', fontSize: isMobile ? '10px' : '12px', fontFamily: 'Arial', textAlign: 'justify'}} dangerouslySetInnerHTML={{__html: selectedRecipient.body}}/>
      <Stack spacing={10}>
        <Stack spacing={0}>
            <span style={{fontFamily: 'Arial', fontSize: isMobile ? '8pt' : '9pt'}}>{accountData.addressCompany}</span>
            <span style={{fontFamily: 'Arial', fontSize: isMobile ? '8pt' : '9pt'}}>{accountData.addressStreet}</span>
            <span style={{fontFamily: 'Arial', fontSize: isMobile ? '8pt' : '9pt'}}>{`${accountData.addressZipCode} ${accountData.addressCity}`}</span>
            {accountData.companyPhoneNumber && <span style={{fontFamily: 'Arial', fontSize: isMobile ? '7pt' : '8pt'}}>{`Tel: ${accountData.companyPhoneNumber}`}</span>}
            {accountData.companyEmail && <span style={{fontFamily: 'Arial', fontSize: isMobile ? '7pt' : '8pt'}}>{`E-Mail: ${accountData.companyEmail}`}</span>}
        </Stack>
      </Stack>
      <Stack style={{fontSize: '12px', fontWeight: 400, marginTop: '20px'}} spacing={0}>
        {messageData?.utilityBillingId &&
        <Group spacing={2} style={{fontSize: isMobile ? '10px' : '12px'}}><span style={{fontWeight: 'bold'}}>Anhang:</span><span style={{cursor: 'pointer', marginLeft: '1px', color: '#5555FF'}} onClick={()=> getDocumentsById(selectedRecipient.documentId)}>{`${propertySelected.name}-${rentalUnit?.name}-${selectedRecipient ? selectedRecipient.name.replace(/,/g, '_') : 'Mieter'}.pdf`}</span></Group>}
      </Stack>
    </div>
  );
};

export default PostviewMessageTenantPortal;