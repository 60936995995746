import React, { Component } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import Receipt from '../../../../../assets/images/module/general/ReceiptDummy.png';
import { Row, Col, Form, Tooltip, OverlayTrigger } from 'react-bootstrap';
import CustomModal from '.';
import { showNotification } from '@mantine/notifications';
import ColorButton from '../Buttons/ColorButton';
import CopyIcon from '../../../SVGs/copyIcon';
import GreenTick from '../../../../../assets/images/module/general/GreenTick.svg';
import { Checkbox, Grid, Group, Stack, TextInput } from '@mantine/core';
import EmptyStateContainer from '../EmptyStateContainer/EmptyStateContainer';
import TenantIconBlue from '../../../../../assets/images/module/general/TenantIconBlue.svg';
import { NumericFormat } from 'react-number-format';

const MOBILE_SIZE = 992;
const TABLET_SIZE = 1200;

export class BankConnectionExistModal extends Component {
  state = {
    isMobile: window.innerWidth <= MOBILE_SIZE,
    isTablet: window.innerWidth <= TABLET_SIZE
  };
  
  constructor(props) {
    super(props);
  }
  
  render() {
    const { open, toggle, className, openAddBankModal, bankConnectionData } = this.props;
    
    return (
      <CustomModal
        heading={'Konto ersetzen'}
        headingClassName={'update-received-rent-modal-heading'}
        open={open}
        toggle={() => {
          toggle();
        }}
        className={`${className}`}
        style={{maxWidth: '450px'}}
      >
        <Stack>
            <Stack>
                <Group>
                    <span style={{fontFamily: 'Inter, sans-serif', fontWeight: '400'}}>Sind Sie sicher, dass Sie das bestehende Bankkonto (IBAN: {bankConnectionData?.iban ? bankConnectionData?.iban : '-'}) ersetzen wollen? Alle Konto- und Zahlungsinformationen gehen unwiderruflich verloren.</span>
                </Group>
            </Stack>
            <Group position='apart'>
                <div>
                    <ColorButton
                    text={'Abbrechen'}
                    className='gray-btn'
                    onClick={()=> toggle()}
                    />
                </div>
                <div>
                    <ColorButton
                    text={'Konto ersetzen'}
                    className='red-btn'
                    onClick={()=> openAddBankModal()}
                    />
                </div>
            </Group>
        </Stack>
      </CustomModal>
    );
  }
}

export default BankConnectionExistModal;
