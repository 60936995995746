import React, { Component } from 'react';
import { Modal, Button, Group, Stack } from '@mantine/core';
import CheckIconBlue from '../../../../../assets/images/module/general/DarkBlueCheckIcon.svg';
import RightArrowPartner from '../../../../../assets/images/module/general/RightArrowPartner.svg';
import MantineCloseButton from '../../../../../assets/images/module/general/MantineCloseButton.svg';
import TenEuroLogo from '../../../../../assets/images/module/general/TenEuroLogo.svg';
import UtilityBillingAssignedIcon from '../../../../components/SVGs/utilityBillingAssigned';
import ColorButton from '../Buttons/ColorButton';
import CustomModal from './index';

class PartnerDetailModal extends Component {

    render(){
        const {opened, toggle, partnerData, isMobile} = this.props
        console.log('partnerData.description: ', partnerData.description);
        return (
            <CustomModal
            open={opened}
            toggle={toggle}
            // styles={{
            //     modal: {
            //         width: '740px'
            //     },
            //     header: {
            //         marginBottom: '0px'
            //     }
            // }}
            // withCloseButton={false}
            styleHeader={{display: 'none'}}
            style={{minWidth: isMobile ? 'auto' : '850px'}}
            >
                <Stack>
                    <Group position='right' style={{cursor: 'pointer'}} onClick={()=> toggle()}>
                        <img src={MantineCloseButton} width={'20px'}/>
                    </Group>
                    <Group 
                    style={{cursor: 'pointer'}}
                    onClick={()=> {
                        window.open(partnerData['partner-link'], '_blank');
                        window.Intercom('trackEvent', 'partner-link-clicked', {partnerName: partnerData.heading, partnerLink: partnerData['partner-link']});
                    }}>
                        <img src={partnerData.banner} style={{width: isMobile ? 'auto' : '800px', height: isMobile ? 'auto' : '300px', objectFit: 'cover'}}/>
                    </Group>
                    <Group spacing={10}>
                        <img src={partnerData.icon} height={'30px'} />
                        <span style={{fontSize: '26px', fontWeight: '500', fontFamily: 'Inter, sans-serif', color: '#13135C'}}>{partnerData.heading}</span>
                    </Group>
                    <Group className='partner-list-row-tags'>
                        {partnerData.tags.map((tag)=> <Group className='partner-list-row-tag-badge' style={{fontSize: '12px'}}>{tag}</Group>)}
                    </Group>
                    <div className='partner-list-row-description' dangerouslySetInnerHTML={{__html: partnerData.description}}/>
                    {/* <Group>
                        {partnerData.detailPoint.map((dp)=>
                            <Group spacing={8}>
                                {UtilityBillingAssignedIcon('#5555FF', 10, 10)}
                                <span className='partner-list-row-description'>{dp}</span>
                            </Group>
                        )}
                    </Group> */}
                    <Group position='apart' align='flex-end'>
                        <Group spacing={0}>
                            <Stack spacing={5} style={{boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)', padding: '7px 10px', borderRadius: '4px'}}>
                                <span style={{fontWeight: '400', color: '#13135C', whiteSpace: 'nowrap'}}>Vorteilsangebot für hellohousing Kunden:</span>
                                <Group 
                                spacing={5} 
                                align='center' 
                                style={{cursor: 'pointer'}} 
                                onClick={()=> {
                                    window.open(partnerData['partner-link'], '_blank');
                                    window.Intercom('trackEvent', 'partner-link-clicked', {partnerName: partnerData.heading, partnerLink: partnerData['partner-link']});
                                }}>
                                    <div style={{fontWeight: '400', color: '#5555FF'}} dangerouslySetInnerHTML={{__html: partnerData.cta}}/>
                                    <img src={RightArrowPartner} style={{marginTop: '4px', width: '12px'}}/>
                                </Group>
                            </Stack>
                        </Group>
                        <Group spacing={0}>
                            <div>
                                <ColorButton
                                text={'Zum Anbieter'}
                                className='purple-btn'
                                onClick={()=> {
                                    window.open(partnerData['partner-link'], '_blank');
                                    window.Intercom('trackEvent', 'partner-link-clicked', {partnerName: partnerData.heading, partnerLink: partnerData['partner-link']});
                                }}
                                />
                            </div>
                        </Group>
                    </Group>
                    <Stack spacing={2} style={{marginTop: '40px'}}>
                        {partnerData.footnotes.map((footNote)=> <span className='payment-template-options' style={{textAlign: 'center', fontSize: '10px', color: '#828282', fontWeight: '300'}}>{footNote}</span>)}
                    </Stack>
                </Stack>
            </CustomModal>
        );
    }
}

export default PartnerDetailModal;