import * as ActionTypes from '../../types/meter';
import templateBuilder from '@babel/core';
const initialState =[]

export const getMasterDataReducer = (state = initialState, action) => {
 
  switch (action.type) {
    case ActionTypes.GET_MASTER_DATA:
      return action.payload
    case ActionTypes.DELETE_MASTER_DATA:

      let inner2;
      let final = [...state.map(item => {

        //keys inside object like propertyMeterData
        let inner = [...Object.keys(item).map((key, i) => {

          inner2 = { ...inner2, ...{ [key]: item[key].filter(data => data.id != action.payload) } }

        })]
        // console.log(inner2)
        return inner2

      })
      ]
      return final
    case ActionTypes.EDIT_MASTER_DATA:
      console.log(action.payload)
      let inner;
      let removed;
      let obj;
      let updated = []
      let result = state.map(item => {
        console.log(action.payload.rentalUnitId, action.payload.currentMeter)
        if (item[action.payload.rentalUnitId] != action.payload.currentMeter) {

          removed = item[action.payload.rentalUnitId].filter(tem => {
            if (tem.id == action.payload.id) {
              obj = action.payload;
            }
            return tem.id != action.payload.id;
          })

        }
        else{
          obj = action.payload;
        }
        obj = { ...obj, rentalUnitId: action.payload.currentMeter }
        item[action.payload.rentalUnitId] = removed;
        item[action.payload.currentMeter] = [...item[action.payload.currentMeter], obj]

        return item
      })
























      // let result = state.map(item => {
      //   //rentalUnitId is rental unit like eg links or property meters
      //   //Based on rental units I have seelcted key in above array of objects and filter the array
      //   inner = item[action.payload.rentalUnitId].map((obj, i) => {
      //      //matching ids of meters with the edited payload
      //     if (obj.id == action.payload.id) {
      //             // if rental units are changed
      //       if (obj.rentalUnitId != action.payload.currentMeter) {

      //         obj = action.payload
      //         // removing meter(obj) from old rental units
      //         // updatedOldArr=item[action.payload.rentalUnitId].filter(arr=>{
      //         //   arr.id!=action.payload.id
      //         // })
      //         item[action.payload.rentalUnitId].splice(i, 1)
      //         console.log("removed",item[action.payload.rentalUnitId])
      //         // adding removed obj in new rental unit
      //         console.log("iterable",item[action.payload.currentMeter])
      //         item[action.payload.currentMeter] = [...item[action.payload.currentMeter], obj]
      //         //change the rentalunitId key in meter
      //         obj = { ...obj, rentalUnitId: action.payload.currentMeter }
      //         updatedOldArr.push(obj) }
      //       else {
      //         obj = action.payload

      //       }
      //     }
      //        console.log("obj",obj)

      //     return obj
      //   })
      //   // replacing rental units with new object which is inner
      //   return { ...item, [action.payload.rentalUnitId]: inner }
      // })


      return result


    default:
      return state
  }
  return state
}