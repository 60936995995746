import * as ActionTypes from '../../types/tenant.js';
const initialState = {
  rentalUnits:[],
  filteredList:[],
  currentTenant:{},
  addEditFormValid:true,
  addEditFormData:{},
  loadingTenantsData: false
  // showPreviousTenants:false,
}

export const tenantDataReducer = (state = initialState, action) => {
  let 
  unit,
  updatedUnit,
  updatedtenants,
  filteredTenants,
  updatedUnits;
  switch (action.type) {
    
    case ActionTypes.GET_RENTAL_TENANTS:
      return {...state, rentalUnits:[...action.payload ] , filteredList:[...action.payload] }

    case ActionTypes.SET_FILTERED_DATA:
      return {...state, filteredList:[...action.payload] }

    case ActionTypes.DELETE_TENANT:
      //find unit to which tenant belongs
      unit= state.rentalUnits.find(unit=>unit.id==action.payload.unitId)
      //remove that tenant from that unit
      filteredTenants= unit.tenantsData.filter(tenant=>tenant.id!=action.payload.tenantid)
      //update tenants of that unit after deletion
      updatedUnit={...unit, tenantsData: filteredTenants}
      //replace old unit record with updated unit record
      updatedUnits= state.rentalUnits.map(unit=>{
        if(unit.id==action.payload.unitId){
          return updatedUnit
        }else{
          return unit
        }
      })
      return {...state, rentalUnits: [...updatedUnits], filteredList: [...updatedUnits] }

    case ActionTypes.DELETE_RENTAL_UNIT:
      //remove unit
      updatedUnits= state.rentalUnits.filter(unit=>unit.id != action.payload)
      // console.log('reducer', updatedUnits)
      return {...state, rentalUnits: [...updatedUnits], filteredList: [...updatedUnits] }

    case ActionTypes.EDIT_TENANT:
      //find unit to which tenant belongs
      unit= state.rentalUnits.find(unit=>unit.id==action.payload.unitId)
      //replace data in that unit's tenant
      updatedtenants= unit.tenantsData.map(tenant=>{
        if(tenant.id==action.payload.tenantData.id){
          return action.payload.tenantData
        }else{
          return tenant
        }
      })
      //merge updated tenanats in its unit data
      updatedUnit={...unit, tenantsData: updatedtenants }
      //replace old unit record with updated unit record
      updatedUnits= state.rentalUnits.map(unit=>{
        if(unit.id==action.payload.unitId){
          return updatedUnit
        }else{
          return unit
        }
      })
      return {...state, rentalUnits: [...updatedUnits], filteredList: [...updatedUnits]}

    case ActionTypes.ADD_TENANT:
      //find unit to which tenant belongs
      unit= state.rentalUnits.find(unit=>unit.id==action.payload.unitId)
      //add new data in that unit's tenant
      const id=unit.tenantsData.length;
      let tenants= [ ...unit.tenantsData,{
        id: id,
        ...action.payload.tenantData
      }]
      //merge updated tenanats in its unit data
      updatedUnit={...unit, tenantsData: tenants }
      //replace old unit record with updated unit record
      updatedUnits= state.rentalUnits.map(unit=>{
        if(unit.id==action.payload.unitId){
          return updatedUnit
        }else{
          return unit
        }
      })
      return {...state, rentalUnits: [...updatedUnits], filteredList: [...updatedUnits]}

    case ActionTypes.ADD_RENTAL_UNIT:
      return {
        ...state, 
        rentalUnits: [...state.rentalUnits, {id: state.rentalUnits.length ,...action.payload}], 
        filteredList: [...state.filteredList, {id: state.rentalUnits.length ,...action.payload}],
      }

    case ActionTypes.EDIT_RENTAL_UNIT:
      //find unit 
      unit= state.rentalUnits.find(unit=>unit.id==action.payload.id)
      //replace data in that unit
      updatedUnit= { ...unit, ...action.payload }
      //replace old unit record with updated unit record
      updatedUnits= state.rentalUnits.map(unit=>{
        if(unit.id==action.payload.id){
          return updatedUnit
        }else{
          return unit
        }
      })
      return {...state, rentalUnits: [...updatedUnits], filteredList: [...updatedUnits]}

    case ActionTypes.SET_CURRENT_TENANT:
      return {...state, currentTenant: action.payload.current, addEditFormData:action.payload.formData }

    case ActionTypes.SET_ADD_EDIT_VALIDATION:
      return {...state, addEditFormValid: action.payload}

    case ActionTypes.SET_ADD_EDIT_DATA:
      return {...state, addEditFormData: action.payload }

    case ActionTypes.LOADING_TENANTS_DATA:
      return {...state, loadingTenantsData: action.payload }

    default:
      return state
  }
}