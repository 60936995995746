import React, { Component } from 'react';
import Autoload from '../../../../neoverv/Autoload';
import { connect } from 'react-redux';
import { showNotification } from '@mantine/notifications';
import RightArrowIcon from '../../../../assets/images/module/general/RightArrowIcon.svg';
import GrayTick from '../../../../assets/images/module/general/GrayTick.svg';
import SelectArrowDown from '../../../../assets/images/module/general/SelectArrowDown.svg';
import GreenTick from '../../../../assets/images/module/general/GreenTick.svg';
import RedCross from '../../../../assets/images/module/general/RedCross.svg';
import UtilityBillingAssignedIcon from '../../../../neoverv/components/SVGs/utilityBillingAssigned';
import SelectArrowUp from '../../../../assets/images/module/general/SelectArrowUp.svg';
import Papa from 'papaparse';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { Stack, Group, Select, Skeleton, PasswordInput } from '@mantine/core';
import { Col, Form, Row } from 'react-bootstrap';
import { ColorButton } from '../../../../neoverv';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
const pStyle= {
    fontFamily: 'Inter, sans-serif',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '24px',
    color: 'rgb(14, 14, 29)'
}
const tableStyle= {
    border: 'none',
    padding: '5px 0px',
    textAlign: 'center'
}
const tableHeaderStyle= {
    // fontWeight: '500', 
    // lineHeight: '16px',
    // fontFamily: 'Inter, sans-serif',
    // whiteSpace: 'nowrap',
    // width: '200px',
    // maxWidth: '200px',
    // textAlign: 'left'
    whiteSpace: 'nowrap',
    border: 'none',
    paddingBottom: '0px',
    fontSize: '12px',
    fontWeight: '400',
    color:'#727293',
    width: '80px',
    width: '200px',
    maxWidth: '200px',
    textAlign: 'left'
}
export class MeterDataMapping extends Component {
  constructor(props) {
    super(props);
    this.state = {
        parsedData: [],
        tableColumnNames: [],
        tableRowValues: [
            {
                name: 'Zählernummer',
                checked: false,
                value: ''
            },
            {
                name: 'Ablesezeitpunkt',
                checked: false,
                value: ''
            },
            {
                name: 'Zählerwert',
                checked: false,
                value: ''
            }
        ],
        selectedColumns: [],
        expandModal: false,
        hover:false,
        mappingId: '',
        skeletonLoading: false,
        activeMeterReadingMapping: null,
        mappingPassword: null
    };
  }

  componentDidMount = () => {
    const {meterMappings} =  this.props;
    console.log('meterMappings: ', meterMappings);
    let findMapping = meterMappings?.find((meterMapping)=> meterMapping.isActive);
    if(findMapping !== undefined){
        this.setMeterMappingValues(findMapping);    
    }
  };

  componentDidUpdate= (prevProps) => {
    const {meterMappings} =  this.props;
    if(prevProps.meterMappings !== meterMappings){
        console.log('componentDidUpdate: ', meterMappings);
        let findMapping = meterMappings?.find((meterMapping)=> meterMapping.isActive);
        if(findMapping !== undefined){
            this.setMeterMappingValues(findMapping);    
        }
    }
  }

  setMeterMappingValues = (meterMappings) =>{
    console.log('setMeterMappingValues: ', meterMappings);
    if(meterMappings.mappingType === 'CSV'){
        let {tableRowValues, tableColumnNames} = this.state;
        if(meterMappings.meterIdMapping && meterMappings.meterIdMapping !== ""){
            tableColumnNames.find((item)=> meterMappings.meterIdMapping === item) ? console.log('meterIdMapping exist') : tableColumnNames.push(meterMappings.meterIdMapping);
        } 
        if(meterMappings.dateTimeMapping && meterMappings.dateTimeMapping !== ""){
            tableColumnNames.find((item)=> meterMappings.dateTimeMapping === item) ? console.log('dateTimeMapping exist') : tableColumnNames.push(meterMappings.dateTimeMapping);  
        }
        if(meterMappings.consumptionMapping && meterMappings.consumptionMapping !== ""){
            tableColumnNames.find((item)=> meterMappings.consumptionMapping === item) ? console.log('consumptionMapping exist') : tableColumnNames.push(meterMappings.consumptionMapping);
        } 
        
        if(meterMappings.meterIdMapping && meterMappings.meterIdMapping !== ''){
            tableRowValues[0].value = meterMappings.meterIdMapping;
            tableRowValues[0].checked = true;
        }
        if(meterMappings.dateTimeMapping && meterMappings.dateTimeMapping !== ''){
            tableRowValues[1].value = meterMappings.dateTimeMapping;
            tableRowValues[1].checked = true;
        }
        if(meterMappings.consumptionMapping && meterMappings.consumptionMapping !== ''){
            tableRowValues[2].value = meterMappings.consumptionMapping;
            tableRowValues[2].checked = true;
        }
        this.setState({tableRowValues, tableColumnNames, mappingId: meterMappings.id, activeMeterReadingMapping: meterMappings.id}); 
    }else{
        this.setState({mappingPassword: meterMappings.filePassword, mappingId: meterMappings.id, activeMeterReadingMapping: meterMappings.id}); 
    }
  }

  getMeterReadingMappings = () => {
    Autoload.HttpRequestAxios.HttpRequest(
        Autoload.Setting.meter_api_base_url + 'meterReadingMappings?propertyId='+ this.props.propertySelected.id,
        'GET',
        null,
        null,
        false,
        true,
        true,
        this,
        '_handleGetMeterReadingMappings',
        null,
        false,
        '',
        { 'Content-Type': 'application/json', Authorization: Autoload.Utils.getAuthToken() }
      );
  }

  _handleGetMeterReadingMappings(response) {
    console.log('_handleGetMeterReadingMappings: ', response);
    if(response.status){
        let tableRowValues = this.state.tableRowValues;
        if(response.result[0].meterIdMapping && response.result[0].meterIdMapping !== ''){
            tableRowValues[0].value = response.result[0].meterIdMapping;
            tableRowValues[0].checked = true;
        }
        if(response.result[0].dateTimeMapping && response.result[0].dateTimeMapping !== ''){
            tableRowValues[1].value = response.result[0].dateTimeMapping;
            tableRowValues[1].checked = true;
        }
        if(response.result[0].consumptionMapping && response.result[0].consumptionMapping !== ''){
            tableRowValues[2].value = response.result[0].consumptionMapping;
            tableRowValues[2].checked = true;
        }
        this.setState({tableRowValues, mappingId: response.result[0].id}); 
    }
  }

  updateMeterReadingMappings = () => {
    this.setState({skeletonLoading: true});
    const {tableRowValues, mappingId, activeMeterReadingMapping, mappingPassword} = this.state; 
    const {meterMappings} = this.props;
    let individualMapping = meterMappings.find((meterMapping)=> meterMapping.systemMeterReadingMappingId === '816bf7a2-e184-4847-8d6b-aa42c02a3756');
    let qundisMapping = meterMappings.find((meterMapping)=> meterMapping.systemMeterReadingMappingId === 'f62000b8-3761-4a90-8efe-6a68fe5a10a8');


    if(individualMapping.id === activeMeterReadingMapping){
        individualMapping.meterIdMapping = tableRowValues[0].value ? tableRowValues[0].value : '';
        individualMapping.dateTimeMapping = tableRowValues[1].value ? tableRowValues[1].value : '';
        individualMapping.consumptionMapping = tableRowValues[2].value ? tableRowValues[2].value : '';
    }

    if(qundisMapping?.id === activeMeterReadingMapping){
        qundisMapping.filePassword = mappingPassword;
    }
    
    
    
    // let payload = 
    // individualMapping !== undefined ? {
    //     id: activeMeterReadingMapping,
    //     type: "CSV",
    //     category: "INDIVIDUAL",
    //     meterIdMapping: tableRowValues[0].value ? tableRowValues[0].value : '',
    //     dataTimeMapping: tableRowValues[1].value ? tableRowValues[1].value : '',
    //     consumptionMapping: tableRowValues[2].value ? tableRowValues[2].value : ''
    // }
    // : {
    //     id: activeMeterReadingMapping,
    //     type: "CSV",
    //     category: "INDIVIDUAL",
    //     meterIdMapping: tableRowValues[0].value ? tableRowValues[0].value : '',
    //     dataTimeMapping: tableRowValues[1].value ? tableRowValues[1].value : '',
    //     consumptionMapping: tableRowValues[2].value ? tableRowValues[2].value : ''
    // }
    console.log('payload: ', individualMapping);
    Autoload.HttpRequestAxios.HttpRequest(
        Autoload.Setting.meter_api_base_url + 'meterReadingMappings/'+ (individualMapping.id === activeMeterReadingMapping ? individualMapping.id : qundisMapping.id === activeMeterReadingMapping ? qundisMapping.id : null),
        'PUT',
        individualMapping.id === activeMeterReadingMapping ? individualMapping : qundisMapping.id === activeMeterReadingMapping ? qundisMapping : null,
        null,
        false,
        true,
        true,
        this,
        '_handleUpdateMeterReadingMappings',
        null,
        false,
        '',
        { 'Content-Type': 'application/json', Authorization: Autoload.Utils.getAuthToken() }
    );
  }

  _handleUpdateMeterReadingMappings(response) {
    console.log('_handleUpdateMeterReadingMappings: ', response);
    if(response.status){
        showNotification({
            message: 'Die Änderungen wurden übernommen.',
            icon: <img src={GreenTick} alt='green-tick'/>,
            id: 'automaticMappingSave'
        });
    }
    this.props.updateMappings();
    this.setState({skeletonLoading: false});
  }
  
  changeHandler = (event) => {
    console.log('onChange:', event.target.files[0]);
    Papa.parse(event.target.files[0], {
        header: true,
        skipEmptyLines: true,
        complete: (results)=> {
            showNotification({
                message: 'Datei erfolgreich hochgeladen.',
                icon: <img src={GreenTick} alt='green-tick'/>,
            });
            console.log('Papa.parse:', results.data)
            const rowsArray = [];
            const valuesArray = [];

            // Iterating data to get column name and their values
            results.data.map((d) => {
            rowsArray.push(Object.keys(d));
            valuesArray.push(Object.values(d));
            });

            let tableColumnNames = [];
            rowsArray[0].map((row)=> tableColumnNames.find((item)=> row === item) ? console.log('already exist') : tableColumnNames.push(row));
            this.setState({
                parsedData: results.data,
                tableColumnNames
                //tableRowValues: valuesArray
            })
        },
        error: (e)=>{
            showNotification({
                message: e.message,
                icon: <img src={RedCross} alt='green-tick'/>,
            });
        }
      });
  };

onSelect = (name, checked=true, value)=> {
    let tableRowValues = this.state.tableRowValues;
    tableRowValues.map((row)=> {
        if(row.name === name){
            row.checked = checked;
            row.value = value;
        }
    });
    this.setState({tableRowValues}, ()=> this.updateMeterReadingMappings()); 
}

handleMouseEnter=()=>{
    this.setState({hover: true});
  }
  handleMouseLeave=()=>{
    this.setState({hover: false});
  }

  changeActiveMapping=(id)=>{
    const {meterMappings} = this.props;
    let selectedMeterMapping = meterMappings.find((mapping)=> mapping.id === id);

    if(selectedMeterMapping !== undefined){
        this.setState({skeletonLoading: true});
        selectedMeterMapping.isActive= true;

        Autoload.HttpRequestAxios.HttpRequest(
            Autoload.Setting.meter_api_base_url + 'meterReadingMappings/'+ selectedMeterMapping.id,
            'PUT',
            selectedMeterMapping,
            null,
            false,
            true,
            true,
            this,
            '_handleUpdateMeterReadingMappings',
            null,
            false,
            '',
            { 'Content-Type': 'application/json', Authorization: Autoload.Utils.getAuthToken() }
        );
    }
  }

  renderDropdown=(row)=>{
    const {tableColumnNames} = this.state;
    const {isMobile} = this.props;
    return (
        <Select
        placeholder={tableColumnNames.length === 0 ? 'Eingeben' : "Wählen"}
        searchable
        clearable
        creatable
        label={isMobile && row.name}
        data={tableColumnNames}
        defaultValue={row.value}
        value={row.value}
        style={{height: !isMobile && '36px', width: isMobile && '100%'}}
        rightSection={!row.checked &&  <img src={tableColumnNames.length !== 0 && SelectArrowDown} size={14} style={{cursor: 'pointer'}} />}
        onChange={(value)=> this.onSelect(row.name, value && value.length !== 0 ? true : false, value)}
        styles={{
            fontFamily: 'Inter, sans-serif',
            input:{
                background: 'white',//'rgba(242, 242, 252, 1)',
                fontSize: '14px',
                cursor: 'pointer'
            },
            rightSection: { pointerEvents: 'none' },
            label: {
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '12px',
                lineHeight: '20px',
                color: '#727293'
            }
        }}
        classNames={{
            label: 'form-label'
        }}
        getCreateLabel={(query) => `+ Erstellen ${query}`}
        onCreate={(query) => {
            //const item = { value: query, label: query };
            this.state.tableColumnNames.length === 0 ?
            this.setState({
                tableColumnNames: [query]
            })
            :
            this.setState({
                tableColumnNames: [...this.state.tableColumnNames, query]
            })
            return query;
        }}
        />
    )
  }
  
  render() {
    const {isMobile, isTablet, meterMappings} = this.props;
    const email = 'receipt-1f298e4c-685f-4373-9359-37b70958c3cc@upload.hellohousing.de';
    const {tableColumnNames, tableRowValues, expandModal, skeletonLoading, activeMeterReadingMapping, mappingPassword} = this.state;
    return (
        <div 
        style={{
          width: '100%', //isTablet ? '100%' : '540px',
          height: '100%', //isTablet ? '100%' : '400px',
          background: 'white',
          boxShadow: '0 1px 3px rgba(0,0,0,.08)', 
          borderRadius: '4px'
          }}>
        <Skeleton visible={skeletonLoading}>
            <div
            className='tooltips meter-data-mappings'
            style={{
                padding: '12.1px 24px',
                borderBottom: '1px solid #dadae6',
                borderTopLeftRadius: '4px',
                borderTopRightRadius: '4px',
                background: 'white'
            }}>
                <h5 
                style={{
                    fontWeight: '500',
                    fontSize: '16px',
                    lineHeight: '24px',
                    color: '#0e0e1d',
                    marginBottom: '0px'
                    }}>Datenbereitstellung einrichten</h5>
            </div>
            <Stack style={{padding: '12.1px 24px 30px',}}>
                <Stack>
                    <span style={pStyle}>Bitte geben Sie an, in welchem Datenformat die Daten an hellohousing gesendet werden.</span>
                    <Group>
                        {meterMappings && meterMappings.length > 0 &&
                        <Select
                        data={meterMappings}
                        value={meterMappings.find((meterMapping)=> meterMapping.isActive)?.id}
                        onChange={(value)=> this.changeActiveMapping(value)}
                        placeholder={'Bitte auswählen'}
                        />}
                    </Group>
                    
                </Stack>
                {meterMappings?.find((meterMapping)=> meterMapping.id === activeMeterReadingMapping && meterMapping.systemMeterReadingMappingId === '816bf7a2-e184-4847-8d6b-aa42c02a3756') && 
                <Stack spacing={0}>
                    <div>
                    <span style={pStyle}>Bitte geben Sie an, in welchen Spalten Ihrer CSV-Zählerdatendatei hellohousing die notwendigen Informationen findet.</span>
                        <span style={pStyle}> Sie können eine </span>
                    <label
                                for='file'
                                className='import-csv'
                                style={{
                                    border: 'none',
                                    background: 'none',
                                    boxShadow: 'none',
                                    color: this.state.hover ?  'rgb(14, 14, 29)' : '#5555FF',
                                    fontSize: '14px',
                                    fontFamily: 'Inter, sans-serif',
                                    fontWeight: '400',
                                    lineHeight: '24px',
                                    marginBottom: 0,
                                    paddingRight: 0,
                                    cursor: 'pointer',
                                    marginRight: '3px'
                                }}
                                // onMouseEnter={this.handleMouseEnter}
                                // onMouseLeave={this.handleMouseLeave}
                                >Beispiel-Datei bereitstellen<span style={pStyle}>,</span></label>
                                <span style={pStyle}>um die Spaltenbezeichnungen auszulesen.</span>
                                <input
                                id='file'
                                type="file"
                                name="file"
                                accept=".csv"
                                onChange={this.changeHandler}
                                style={{ visibility: "hidden"}}
                                title={'Import'}
                                />
                    </div>
                    <Table style={{marginTop: '10px'}}>
                        <TableBody>
                            {!isMobile &&
                            <TableRow>
                                <TableCell style={{...tableStyle, ...tableHeaderStyle}}>
                                    <span>Benötigte Information</span>
                                </TableCell>
                                <TableCell style={{...tableStyle, ...{width: '60px'}}}>
                                    
                                </TableCell>
                                <TableCell style={{...tableStyle, ...tableHeaderStyle,}}>
                                    <span>Spaltenbezeichnung CSV</span>
                                </TableCell>
                            </TableRow>}

                            {tableRowValues.map((row)=>
                            isMobile ?
                            <TableRow>
                                <TableCell style={{padding: '5px 0px'}}>
                                    <Group noWrap align={'center'} style={{width: '100%'}}>
                                        {this.renderDropdown(row)}
                                        <div style={{marginTop: '20px'}}>
                                            {!row.checked ? UtilityBillingAssignedIcon('#9A9AB5') : UtilityBillingAssignedIcon('#5555FF')}
                                        </div>
                                    </Group>
                                </TableCell>
                            </TableRow>
                            :
                            <TableRow>
                            <TableCell style={{...tableStyle, ...{
                                //paddingRight: '4px'
                                }}}>
                                <Form.Group>
                                {/* <Form.Label>{lang.property.generalinfo_propertyname}</Form.Label> */}
                                <Form.Control
                                id="meter-number"
                                name="meter-number"
                                type="text"
                                placeholder={row.name}
                                value={row.name}
                                style={{height: '36px', fontFamily: 'Inter, sans-serif', fontSize: '14px',}}
                                disabled
                                
                                //value={this.state.propertyName}
                                //isInvalid={this.state.propertyNameErr}
                                //        onKeyDown={this._handleKeyDown}
                                //   onChange={(e) => {
                                //     this.onInputChange('propertyName', e.target.value, true);
                                //   }}
                                />
                                {/* <Form.Control.Feedback className="err" type="invalid">
                                {lang.property.generalinfo_propertynameError}
                                </Form.Control.Feedback> */}
                                </Form.Group>
                            </TableCell>
                            <TableCell style={{...tableStyle, ...{width: isMobile ? '14%' : '10%', padding: '0 5px'}}}>
                                <div style={{marginTop: '-14px', width: 'auto'}}>
                                    <img src={RightArrowIcon} width={isMobile ? 28 : 33} height={isMobile ? 28.5 : 33.5} />
                                </div>
                            </TableCell>
                            <TableCell style={{...tableStyle, ...{
                                //paddingRight: '4px'
                                }}}>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <Form.Group style={{width: '100%'}}>
                                    {/* <Form.Label>{lang.property.generalinfo_propertyname}</Form.Label> */}
                                    {//tableColumnNames.length === 0 //? 
                                    // <Form.Control
                                    // id="meter-number"
                                    // name="meter-number"
                                    // type="text"
                                    // placeholder={row.name}
                                    // style={{height: '36px', background: 'rgba(242, 242, 252, 1)'}}
                                    // onChange={(e)=> this.onSelect(row.name, e.target.value.length !== 0 ? true : false)}
                                    // onBlur={(e)=> e.target.value.length !== 0 &&
                                    //     showNotification({
                                    //     title: 'Automatisches Speichern!',
                                    //     color: 'green',
                                        
                                    // })}
                                    // />
                                    //:
                                    //&&
                                    this.renderDropdown(row)}
                                    {/* <Form.Control.Feedback className="err" type="invalid">
                                    {lang.property.generalinfo_propertynameError}
                                    </Form.Control.Feedback> */}
                                    </Form.Group>
                                    <div style={{marginTop: '-14px', marginLeft: '8px'}}>
                                    {!row.checked ? UtilityBillingAssignedIcon('#9A9AB5') : UtilityBillingAssignedIcon('#5555FF')}
                                    </div>
                                </div>
                            </TableCell>
                        </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </Stack>}
                {/* : */}
                {meterMappings?.find((meterMapping)=> meterMapping.id === activeMeterReadingMapping && meterMapping.systemMeterReadingMappingId === 'f62000b8-3761-4a90-8efe-6a68fe5a10a8') &&
                <Stack>
                    <span style={pStyle}>Bitte geben Sie das Passwort zum Entschlüsseln der Übertragungsdatei an:</span>
                    <Group spacing={10}>
                        <PasswordInput
                        placeholder='Passwort'
                        value={mappingPassword}
                        onChange={(event) => this.setState({mappingPassword: event.currentTarget.value})}
                        onBlur={()=> this.updateMeterReadingMappings()}
                        style={{minWidth: '179px'}}
                        />
                        <div>
                            {mappingPassword ? UtilityBillingAssignedIcon('#5555FF') : UtilityBillingAssignedIcon('#9A9AB5')}
                        </div>
                    </Group>
                </Stack>}
                
                        {/* <div>
                            <ColorButton
                            className={"purple-btn"}
                            text={'Speichern'}
                            buttonStyle={{
                                margin:0,
                                width: '111px'
                            }}
                            />
                        </div> */}
                
            </Stack>
            {/* <MeterDataMappingExpandModal
            open={expandModal}
            toggle={()=> this.setState({expandModal: !expandModal})}
            style={{maxWidth: '1000px'}}
            styleBody={{height: '700px'}}
            tableColumnNames={tableColumnNames}
            tableRowValues={tableRowValues}
            /> */}
        </Skeleton>
        </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    propertySelected: state.generalReducer.property,
  };
};
export default connect(mapStateToProps)(MeterDataMapping);
