import React, { Component } from 'react';
import {
  ColorButton,
  AddCostTypeModal,
  ZuweisungModal,
  MultiselectDropdown,
  ReceiptDetailModal,
} from '../../../../index';
import { Multiselect } from 'multiselect-react-dropdown';
import { connect } from 'react-redux';
import { Form, OverlayTrigger, Tooltip, Popover } from 'react-bootstrap';
import { deleteCostPosition, editCostPosition } from '../../../../../redux/action/UtilityBilling/UtilityBillingActions';
import TrashIcon from '../../../../../assets/images/module/general/TrashIcon.svg';
import RedTick from '../../../../../assets/images/module/general/RedTick.svg';
import YellowTick from '../../../../../assets/images/module/general/YellowTick.svg';
import GreenTick from '../../../../../assets/images/module/general/GreenTick.svg';
import GrayTick from '../../../../../assets/images/module/general/GrayTick.svg';
import EditForm from '../../../../../assets/images/module/general/EditForm.svg';
import Autoload from '../../../../Autoload';
import { config } from '../../../../config/Setting';
import APIClient from '../../../../config/APIClient';
import moment from 'moment';
import MainHelper from '../../../../helpers/MainHelper';
import { TableCell, TableRow } from '@material-ui/core';
import { formatNumber } from '../../../../../helpers/stringsManipulations';
import FixedAllocationKeyModal from '../CustomModal/FixedAllocationKeyModal';
import AddAllocationKeyModal from '../CustomModal/AddAllocationKeyModal';
import GeneralDataHandler from '../../../../helpers/DataHandler/General';
import GlobalServices from '../../../../helpers/services/GlobalServices';
import RecordOptions from '../../General/RecordOptions/index.js';
import EditVatAndLabourAmount from '../../../Module/General/CustomModal/EditVatAndLabourAmount';
import { Group, Skeleton, Tooltip as MantineTooltip } from '@mantine/core';
import CostPositionValuesModal from '../CustomModal/CostPositionValuesModal';
import dayjs from 'dayjs';
import ReceiptAssigmentRemoveConfirmationModal from '../CustomModal/ReceiptAssigmentRemoveConfirmationModal';
import { NumericFormat } from 'react-number-format';
import { SortableHandle } from 'react-sortable-hoc';
import IconButton from '@material-ui/core/IconButton';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import ReceiptAssigmentRemoveConfirmationForCpAmountModal from '../CustomModal/ReceiptAssigmentRemoveConfirmationForCpAmountModal';


const DragHandle = SortableHandle(() => (
  <IconButton className='custom-icon-button'>
    <DragIndicatorIcon style={{fontSize: '18px'}} />
  </IconButton>
));


var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
const { checkProperty, emptyFieldValidation, redirection, invalidSize, loader } = Autoload.MainHelper;
const tableCellStyle = {
  fontFamily: 'Inter',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '20px',
  letterSpacing: '0',
  color: '#0e0e1d',
  paddingTop: '8px !important',
  paddingBottom: '8px !important'
}

class UtilityCostAllocationRow extends Component {
  initialState = {
    udpatedReciept: '',
    dataValues: {
      costType: [],
      amount: '',
      allocationKey: [],
      meterData: '',
      receipt: [],
      selectedReceipt: [],
      orderNo: 1,
      vatAmount: null,
      laborCost: null
    },
    amountTouch: false,
    amountErr: false,
    amountMsg: '',
    addReceiptOpen: false,
    addCostTypeModal: false,
    AddZuweisungModal: false,
    costTypeOptions: [],
    modalData: [],
    receipts: [],
    selectedValues: [],
    fixedAllocationKeyModal: false,
    selectedAllocationKeyType: '',
    selectedAllocationId: '',
    selectedAllocationUnits: '',
    selectedAllocationKeyName: '',
    addAllocationKeyModal: false,
    newAllocationKey: false,
    openEditVatAndLabourAmount: false,
    openCostPositionValuesModal: false,
    removedItems: null,
    openRemoveReceiptAssignmentModal: false,
    amountInFocus: false,
    previousAmount: null,
    newAmount: null,
    skeletonLoading: false,
    openRemoveReceiptAssignmentForCpAmountModal: false
  };

  costTypeId;
  costPositionId;
  billingPeriodId;

  constructor(props) {
    super(props);
    this.state = this.initialState;    
  }
  componentDidMount() {
    const { data, billingPeriodId } = this.props;
    this.costPositionId = data.id;
    this.costTypeId = data.costTypeId;
    this.billingPeriodId = billingPeriodId;
    this.updateState(data);
    this.setState({ udpatedReciept: this.props.receiptsArr, receipts: this.props.receiptsArr.filter((receipt)=> receipt.selectedCostTypes[0]?.id === this.costTypeId)
      .map((receipt)=> {
        return {...receipt, receiptDate: moment(receipt.receiptDate).format('DD.MM.YYYY')}
    })});
    console.log('this.props.receiptsArr: ', this.props.receiptsArr);
    //this.bind_list_receipt_data(this);
    //this.getAllByBillingPeriodCostType(billingPeriodId, this.costTypeId);

    this.props.index === (this.props.length -1) && this.props.skeletonLoadingFalse();
  }
  //update data to everytime redux changes
  componentDidUpdate(prevProps) {
    //console.log('this.props.receiptsArr: ', this.props.receiptsArr);
    // if (this.props.data !== prevProps.data) {
    //   this.updateState(this.props.data);
    //   //this.getAllByBillingPeriodCostType(this.billingPeriodId, this.costTypeId);
    // }
    // if (this.props !== prevProps && this.props.editReceiptOpen === false && this.props.updateRecieptsInChild) {
    //   //this.getAllByBillingPeriodCostType(this.billingPeriodId, this.costTypeId);
    //   this.props.updateGetAllByBillingPeriodCostType();
    // }

    const { data, receiptsArr } = this.props;
    if (data.amount !== prevProps.data.amount || data.costTypeId !== prevProps.data.costTypeId || data.allocationKeyId !== prevProps.data.allocationKeyId || data.vatAmount !== prevProps.data.vatAmount || data.laborCost !== prevProps.data.laborCost) {
      this.updateState(data);
    }
    console.log('receiptsUpdated: ', JSON.stringify(receiptsArr) !== JSON.stringify(prevProps.receiptsArr));
    if(JSON.stringify(receiptsArr) !== JSON.stringify(prevProps.receiptsArr)){
      console.log('receiptsUpdated');
      this.setState({udpatedReciept: this.props.receiptsArr, receipts: this.props.receiptsArr.filter((receipt)=> receipt.selectedCostTypes[0]?.id === this.costTypeId)
        .map((receipt)=> {
          return {...receipt, receiptDate: moment(receipt.receiptDate).format('DD.MM.YYYY')}
      })});
    }
  }

  updateState = (data) => {
    console.log('data to be set in updatestate', data, this.state.dataValues);
    this.costPositionId = data.id;
    this.costTypeId = data.costTypeId;
    this.setState(
      {
        dataValues: {
          ...this.state.dataValues,
          costTypeId: checkProperty(data, 'costTypeId'),
          costTypeName: checkProperty(data, 'costTypeName'),
          costType: this.getObject(checkProperty(data, 'costTypeId'), this.props.costTypesArr),
          amount: checkProperty(data, 'amount')
            ? data.amount ? data.amount : 0//Autoload.MainHelper.ConverterCurrencyDe_DisplayOnly(data.amount + '', true)
            : '',
          allocationKeyId: checkProperty(data, 'allocationKeyId'),
          allocationKeyName: checkProperty(data, 'allocationKeyName'),
          allocationKey: this.getObject(checkProperty(data, 'allocationKeyId'), this.props.allocationsArr),
          meterData: checkProperty(data, 'meterData'),
          receipt: checkProperty(data, 'receipt', []),
          selectedReceipt: checkProperty(data, 'selectedReceipt', []),
          orderNo: checkProperty(data, 'orderNo'),
          vatAmount: data.vatAmount,
          laborCost: data.laborCost
        },
      },
      () => console.log('data to be set in updatestate setState', this.state.dataValues)
    );
    this.props.propertySelected.propertyType === 'REGULAR' && this.getSelectedReceipts();
  };
  getObject = (id, arr) => {
    // console.log('complete arr', arr, 'filtered', arr.filter(ct=>ct.id==id))
    return arr.filter((ct) => ct.id === id);
  };

  toggleAddCostTypeModal = () => {
    this.setState({ addCostTypeModal: !this.state.addCostTypeModal });
  };

  openEditFixedAllocationKeyModal = (allocationKey) => {
    this.setState({ fixedAllocationKeyModal: true, selectedAllocationId: allocationKey.id, selectedAllocationKeyType: allocationKey.allocationKeyType, selectedAllocationUnits: allocationKey.units, selectedAllocationKeyName: allocationKey.allocationKeyName });
  };

  toggleZuweisungModal = () => {
    this.setState({openCostPositionValuesModal: true});
    //call get data api
    // Autoload.HttpRequestAxios.HttpRequest(
    //   // Autoload.Setting.swagger_mocked_api_baseUrl +
    //   Autoload.Setting.ub_api_base_url +
    //   'costAllocations/forCostPositions?utilityBillingId=' +
    //   checkProperty(this.props.data, 'utilityBillingId') +
    //   '&costPositionId=' +
    //   checkProperty(this.props.data, 'id'),
    //   'GET',
    //   null,
    //   null,
    //   false,
    //   true,
    //   false,
    //   this,
    //   '_handleGetModalData',
    //   null,
    //   false,
    //   '',
    //   {
    //     Authorization: Autoload.Utils.getAuthToken(),
    //   }
    // );
  };
  _handleGetModalData = (response) => {
    console.log('data modal api', response);
    if (response.status === false && response.message !== 'Not Found') {
      // Autoload.Redirection.redirection_error();
      Autoload.DataHandlerGeneral.GeneralErrorMessage(
        true,
        lang.newKeys.text_error_not_found
        // + response.message
      );
    }
    if (response.result.length > 0) {
      let formatted = response.result.map((data) => ({
        id: checkProperty(data, 'id'),
        tenant: checkProperty(data, 'tenantName'),
        rentalUnit: checkProperty(data, 'rentalUnitName'),
        amount: Autoload.MainHelper.ConverterCurrencyDe_DisplayOnly(checkProperty(data, 'tenantCostShare') + '', true),
        //extra data in api
        utilityBillingId: checkProperty(data, 'utilityBillingId'),
        costPositionId: checkProperty(data, 'costPositionId'),
        allocationTypeName: checkProperty(data, 'allocationTypeName'),
        billingStatementId: checkProperty(data, 'billingStatementId'),
        costTypeName: checkProperty(data, 'costTypeName'),
        rentalUnitId: checkProperty(data, 'rentalUnitId'),
        tenantOccupation: checkProperty(data, 'tenantOccupation', {}),
        tenantConsumption: checkProperty(data, 'tenantConsumption'),
        totalConsumption: checkProperty(data, 'totalConsumption'),
        consumptionUnits: checkProperty(data, 'consumptionUnits'),
        totalCostShare: checkProperty(data, 'totalCostShare'),
        // tenantCostShare: checkProperty(data, 'tenantCostShare'),
      }));
      this.setState({ modalData: formatted, AddZuweisungModal: true });
    } else {
      this.setState({ modalData: [], AddZuweisungModal: false });
    }
  };

  closeDataModal = () => {
    this.setState({ AddZuweisungModal: false });
  };

  updateStore = () => {
    const {
      costType,
      costTypeId,
      costTypeName,
      amount,
      allocationKey,
      allocationKeyId,
      allocationKeyName,
      meterData,
      receipt,
      selectedReceipt,
      vatAmount,
      laborCost
    } = this.state.dataValues;
    // console.log('update store', this.state.dataValues)
    // let newAmount= amount? Autoload.MainHelper.ConverterLocaleNumber(amount, "de") : ''
    console.log('updateStore: ', amount);
    let data = {
      id: this.props.data.id,
      costType: costType,
      costTypeId: costTypeId,
      costTypeName: costTypeName,
      amount: amount,
      allocationKey: allocationKey,
      allocationKeyId: allocationKeyId,
      allocationKeyName: allocationKeyName,
      meterData,
      receipt,
      selectedReceipt,
      vatAmount,
      laborCost
    };
    //save data to redux
    // console.log('updateStore in redux', data)
    this.props.editCostPosition(data);
  };

  updateRow = (noLoader, runUpdateReceiptAssignmentsByCostPositionId=false) => {
    //loader(true);
    noLoader === false && this.props.skeletonLoadingTrue();
    const { costTypeId, costTypeName, amount, allocationKeyId, allocationKeyName, meterData, costType, orderNo, vatAmount, laborCost } =
      this.state.dataValues;
    if (this.props.data.id === 0) {
      //post request
      let payload = {
        costPositionId: null,
        utilityBillingId: checkProperty(this.props.data, 'utilityBillingId'),
        amount: amount
          ? amount
          : 0,
        costTypeId: costTypeId,
        costTypeName: costTypeName,
        allocationKeyId: allocationKeyId,
        allocationKeyName: allocationKeyName,
        consumptionDataAvailable: meterData,
        costTypeIdentifier: Array.isArray(costType) ? costType[0].costTypeIdentifier : costType.costTypeIdentifier,
        allcationKeyUnits: 'mWh',
        vatAmount: vatAmount,
        laborCost: laborCost
      };
      console.log('call post api', payload);
      Autoload.HttpRequestAxios.HttpRequest(
        Autoload.Setting.ub_api_base_url + 'costPositions',
        'POST',
        payload,
        null,
        false,
        true,
        false,
        this,
        '_handleSaving',
        null,
        false,
        '',
        {}
      );
    } else {
      //put request
      this.putCostPosition(false, runUpdateReceiptAssignmentsByCostPositionId);
    }
  };

  putCostPosition=(runUpdateReceipt=false, runUpdateReceiptAssignmentsByCostPositionId=false)=>{
    const { costTypeId, costTypeName, amount, allocationKeyId, allocationKeyName, meterData, costType, orderNo, vatAmount, laborCost } = this.state.dataValues;
    let payload = {
      id: checkProperty(this.props.data, 'id'),
      utilityBillingId: checkProperty(this.props.data, 'utilityBillingId'),
      amount: amount
        ? amount
        : 0,
      costTypeId: costTypeId,
      costTypeName: costTypeName,
      allocationKeyId: allocationKeyId,
      allocationKeyName: allocationKeyName,
      consumptionDataAvailable: meterData,
      costTypeIdentifier: Array.isArray(costType) ? costType[0].costTypeIdentifier : costType.costTypeIdentifier,
      allcationKeyUnits: 'mWh',
      orderNo,
      vatAmount: vatAmount,
      laborCost: laborCost
    };

    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.ub_api_base_url + 'costPositions/' + checkProperty(this.props.data, 'id'),
      'PUT',
      payload,
      null,
      false,
      true,
      false,
      this,
      '_handleSaving',
      {runUpdateReceipt, runUpdateReceiptAssignmentsByCostPositionId},
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  updateConsumptionsByCostPositionId = (costPositionIds, getTenantsConsumption=false) => {
    this.setState({skeletonLoading: true});
    //loader(true);
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.ub_api_base_url + 'updateConsumptions/' + this.props.currentDataId+'?costPositionIds='+costPositionIds,
      'PUT',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleUpdateConsumptionsByCostPositionIdResponse',
      {getTenantsConsumption},
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };

  handleUpdateConsumptionsByCostPositionIdResponse = (response) => {
    !response.status && console.log('Update Consumptions Failed');
    this.setState({skeletonLoading: false});
    if(response.other.getTenantsConsumption){
      this.getTenantsConsumption(this.state.selectedAllocationId, this.props.data.id, false);
    }
    this.props.getCostPositionById();
  };
  _handleSaving = (response) => {
    if (response.status === false && response.message !== 'Not Found') {
      // Autoload.Redirection.redirection_error();
      Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang.newKeys.text_error_saving_cost_position);
    }
    //this.props.updateCp();
    this.props.updateCostPositionsAfterPut(response.result);
    this.updateConsumptionsByCostPositionId(response.result.id);
    this.props.updateTable();
    //loader(false);
    this.props.skeletonLoadingFalse();

    if(response.other.runUpdateReceipt){
      this.updateReceipt();
    }
    if(response.other.runUpdateReceiptAssignmentsByCostPositionId){
      //this.updateReceiptAssignmentsByCostPositionId()
      this.props.updateReceiptAssignments();
    }
  };
  toggleAddReceiptModal = (cp) => {
    if (cp !== undefined) {
      this.setState({ currentCp: cp });
    }
    this.setState({ addReceiptOpen: !this.state.addReceiptOpen });
  };
  onCreateReceiptSelect=(id)=>{
    this.props.updateReceipts();
    this.getAllByBillingPeriodCostType(this.billingPeriodId, this.costTypeId, false, id);
  }
  onReceiptSelect = (selectedList, selectedItem, onCostSelect= false) => {
    
    if(selectedItem !== undefined){
      console.log('selectedItem: ', selectedItem);
      //loader(true);
      //this.props.skeletonLoadingTrue();
      this.setState({skeletonLoading: true});
      this.updateReceipt(selectedItem, true);
      // let addedReceipt = {
      //   receiptId: selectedItem.id,
      //   receiptName: selectedItem.name,
      //   proRataDays: selectedItem.proRataDays,
      //   costPositionId: this.props.data.id,
      //   id: null
      // };
      // !onCostSelect && this.sumAmount(selectedItem);

      // Autoload.HttpRequestAxios.HttpRequest(
      //   Autoload.Setting.ub_api_base_url + 'receiptAssignments',
      //   'POST',
      //   addedReceipt,
      //   null,
      //   false,
      //   true,
      //   false,
      //   this,
      //   '_handlePostReceipt',
      //   null,
      //   false,
      //   '',
      //   {
      //     Authorization: Autoload.Utils.getAuthToken(),
      //   }
      // );
    }
    // this.setState({dataValues:{...this.state.dataValues, selectedReceipt: selectedList }},()=>this.updateStore())
  };
  _handlePostReceipt = (response) => {
    if (response.status === false && response.message !== 'Not Found') {
      Autoload.Redirection.redirection_error();
      // Autoload.DataHandlerGeneral.GeneralErrorMessage(true, 'Error Saving receipt assignment :  ' + response.message);
    }
    this.getSelectedReceipts();
    this.props.skeletonLoadingFalse();
  };

  renderStatusTooltip = (message) => (
    <Popover id="button-tooltip" style={{background: '#E3E3FC', border: 'none', boxShadow: '0 1px 2px 0 rgba(14, 14, 29, .1)', }}>
      <Popover.Content>
        <Group position='center' style={{width: '100%', fontFamily: 'Inter, sans-serif', fontSize: '14px'}}>
          {message}
        </Group>
      </Popover.Content>
    </Popover>
  );

  getTenantsConsumption = (allocationKeyId, costPositionId, meterApi=true) => {
    //get tenantsConsumption with costPositionId
    Autoload.HttpRequestAxios.HttpRequest(
      `${Autoload.Setting[meterApi ? 'meter_api_base_url' : 'ub_api_base_url']}${meterApi ? 'tenantsConsumptions' : 'tenantsConsumption'}?billingPeriodId=${this.billingPeriodId}&allocationKeyId=${allocationKeyId}${(meterApi === false && costPositionId) ? `&costPositionId=${costPositionId}` : ''}`,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      '_handleGetTenantsConsumption',
      {costPositionId},
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  _handleGetTenantsConsumption(response){
    if(response.status === true && response.statuscode === 200){
      this.props.updateMeterData(response.result.availability, response.other.costPositionId);
    }
  }

  getSelectedReceipts = () => {
    //get reciepts with costPositionId
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.ub_api_base_url + 'receiptAssignments?costPositionId='+ this.costPositionId,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      '_handleGetSelectedReceipt',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  _handleGetSelectedReceipt = (response) => {
    if (response.status === false && response.message !== 'Not Found') {
      Autoload.Redirection.redirection_error();
      // Autoload.DataHandlerGeneral.GeneralErrorMessage(true, 'Error Saving receipt assignment :  ' + response.message);
    }
    let dataValues = this.state.dataValues;
    dataValues.selectedReceipt = response.result;
    this.setState({
      dataValues: dataValues 
    }, ()=> //loader(false)
    {
      this.props.skeletonLoadingFalse();
      this.setState({skeletonLoading: false});
    });
  }

  bind_list_receipt_data = () => {
    let propertyid = this.props.propertySelected && this.props.propertySelected.id;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.rm_api_base_url + 'property/' + propertyid,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handle_get_list_data',
      null,
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };

  getAllByBillingPeriodCostType = async (billingPeriodId, costTypeId, onCostSelect= false, onCreateReceiptSelectId= null) => {
    //loader(true);
    //this.props.skeletonLoadingTrue();
    await APIClient({
      method: 'GET',
      url: config.receipt + "getAllBy?billingPeriodId=" + billingPeriodId + "&costTypeId=" + costTypeId,
      headers: {
        "Content-Type": "application/octet-stream",
        "Authorization": Autoload.Utils.getAuthToken(),
      }
    })
      .then((response) => {
        response.data.result.map(async (receipt)=> {
          let rec = receipt;
          rec.receiptDate = moment(receipt.receiptDate).format('DD.MM.YYYY');
          // GET BillingPeriodsSplit Start
          await APIClient({
            method: 'GET',
            url: Autoload.Setting.rm_api_basic_base_url + 'BillingPeriodsSplit/GetAllBy?receiptId=' + receipt.id,
            headers: {
              "Content-Type": "application/json",
              "Authorization": Autoload.Utils.getAuthToken(),
            }
          })
            .then((response) => {
              rec.costSplit = response.data.result.find((splitReceipt)=> (splitReceipt.billingPeriodId === this.billingPeriodId)).proRataCosts
            })
            .catch((error) => {
              console.log("_getAllBillingPeriodSplit: ", error);
            })
            // GET BillingPeriodsSplit End
          return rec;
        })
        this.setState({
          receipts: response.data.result
        }, ()=> {
          onCostSelect ? this.bulkReceiptSelect() : this.props.skeletonLoadingFalse();//loader(false);
          setTimeout(() => {
            onCostSelect && this.sumAmount(null, onCostSelect);
            onCreateReceiptSelectId && this.onReceiptSelect('', this.state.receipts.filter((receipt)=> receipt.utilityBillingRelevant).find((receipt)=> receipt.id === onCreateReceiptSelectId));
            // onCreateReceiptSelectId && this.props.skeletonLoadingFalse();
          }, 1500);
          
        })
      })
      .catch((error) => {
        console.log("getAllByBillingPeriodCostType: ", error);
      });
  }

  bulkReceiptSelect= ()=>{
    let receipts = [];      
    this.state.receipts.forEach((i) => {
      console.log('i: ',i);
      let alreadySelected = Array.isArray(this.state.dataValues.selectedReceipt) && this.state.dataValues.selectedReceipt.find((z) => z.id === i.id);
      if(!alreadySelected && i.utilityBillingRelevant){
        receipts.push(i)
      };
    });
    receipts.forEach((i, index) => this.onReceiptSelect(receipts, i, true));
    this.props.skeletonLoadingFalse();
  }

  sumAmount(selectedItem= null, onCostSelect=false) {
    let {dataValues} = this.state;
    let selectedValues = this.state.receipts.filter((receipt)=> receipt.utilityBillingRelevant);
    let initialAmount = dataValues.amount && !onCostSelect ? dataValues.amount : 0;
    let amount = selectedItem ? (selectedItem.costSplit + initialAmount) : selectedValues.reduce(
      (total, currentValue) => total + currentValue.costSplit,
      initialAmount);
    dataValues.amount = amount > 0 ? amount : 0;
    this.setState({dataValues: dataValues}, ()=> {
      this.updateStore();
      this.updateRow();
    })
  }

  removeAmount(selectedItem) {
    let {dataValues} = this.state;
    let selectedValue = this.state.receipts.filter((receipt)=> receipt.utilityBillingRelevant).find((receipt) =>  selectedItem.id === receipt.id)
    let initialAmount = dataValues.amount ? dataValues.amount : 0;
    let amount = initialAmount > 0 ? (initialAmount - selectedValue.costSplit) : 0;
    dataValues.amount = amount > 0 ? amount : 0;
    this.setState({dataValues: dataValues}, ()=> {
      this.updateStore();
      this.updateRow();
    })
  }

  handle_get_list_data(response) {
    if (this.state.currentCp) {
      //this.onReceiptSelect({ id: response.result[0]?.id, name: response.result[0]?.name }, this.state.currentCp);
    }
    // if (!response.status === true && !response.statuscode === 200) {
    //   // Autoload.Redirection.redirection_error();
    //   Autoload.DataHandlerGeneral.GeneralErrorMessage(true, 'Error :  ' + response.message);
    // }
  }


  onReceiptRemove = (selectedList, removedItem) => {
    //loader(true);
    //this.props.skeletonLoadingTrue();
    console.log('onReceiptRemove_123');
    let receipt = this.state.dataValues.selectedReceipt.find((record) => record.receiptId === removedItem.id);

    this.setState({openRemoveReceiptAssignmentModal: true, removedItems: [removedItem]});

    // Autoload.HttpRequestAxios.HttpRequest(
    //   Autoload.Setting.ub_api_base_url + 'receiptAssignments/' + receipt.id,
    //   'DELETE',
    //   null,
    //   null,
    //   false,
    //   true,
    //   false,
    //   this,
    //   '_handleDeleteReceipt',
    //   null,
    //   false,
    //   '',
    //   {
    //     Authorization: Autoload.Utils.getAuthToken(),
    //   }
    // );
  };

  updateReceipt=(selectedItem=null, utilityBillingRelevant=null)=>{
    //this.props.skeletonLoadingTrue();
    this.setState({skeletonLoading: true});
    const {removedItems, newAmount} = this.state;
    let data=null;
    if(selectedItem){
      data={
        ...selectedItem,
        utilityBillingRelevant: utilityBillingRelevant ? utilityBillingRelevant : !selectedItem.utilityBillingRelevant,
        receiptDate: `${dayjs(selectedItem.receiptDate, 'DD.MM.YYYY').format('YYYY-MM-DD')}T00:00:00`
      }
      this.putReceipts(data);
    }else{
      removedItems.map((item)=> {
        data = {
          ...item,
          utilityBillingRelevant: false,
          receiptDate: `${dayjs(item.receiptDate, 'DD.MM.YYYY').format('YYYY-MM-DD')}T00:00:00`
        }
        this.putReceipts(data);
      })
    }
  }

  putReceipts=(receipt)=>{
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.api_base_url + config.receipts + receipt.id,
      'PUT',
      receipt,
      null,
      false,
      true,
      false,
      this,
      'handleUpdateReceipt',
      {receiptId: receipt.id},
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  handleUpdateReceipt(response){
    const {newAmount, dataValues} = this.state;
    if (response.status === false && response.message !== 'Not Found') {
      // Autoload.Redirection.redirection_error();
      Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang.newKeys.text_error_remove_receipt);
    }
    //this.removeAmount(this.state.removedItems.find((item)=> item.id === response.other.receiptId));
    // if(newAmount){
    //   this.updateReceiptAssignmentsByCostPositionId();
    // }else{
    //   this.props.updateReceiptAssignments();
    //   this.setState({skeletonLoading: false});
    // }
    this.props.updateReceipts();
    this.updateReceiptAssignmentsByCostPositionId();
    
    this.setState({removedItems: null, openRemoveReceiptAssignmentModal: false, openRemoveReceiptAssignmentForCpAmountModal: false});
    // this.getSelectedReceipts();
    // this.props.skeletonLoadingFalse();
  }

  updateReceiptAssignmentsByCostPositionId = () => {
    if(this.props.propertySelected && this.props.propertySelected.propertyType === 'REGULAR'){
      Autoload.HttpRequestAxios.HttpRequest(
        Autoload.Setting.ub_api_base_url + 'updateReceiptAssignmentsAndCostPositions?utilityBillingId=' + this.props.currentDataId+ '&costPositionId='+this.props.data.id,
        'PUT',
        null,
        null,
        false,
        true,
        false,
        this,
        'handleUpdateReceiptAssignmentsByCostPositionIdResponse',
        null,
        false,
        '',
        {
          Authorization: Autoload.Utils.getAuthToken(),
        }
      );
    }else{
      this.props.updateReceipts();
    }
  };

  handleUpdateReceiptAssignmentsByCostPositionIdResponse(response){
    const {newAmount} = this.state;
    this.props.updateReceipts();
    if(newAmount){
      let {dataValues} = this.state;
      dataValues.vatAmount = null;
      dataValues.laborCost = null;
      this.setState({dataValues}, ()=> this.onAmountBlur('amount', newAmount, true));
    }else{
      this.props.getCostPositionById();
    }

    setTimeout(() => {
      this.getSelectedReceipts();
    }, 1000);
  }

  _handleDeleteReceipt(response) {
    if (response.status === false && response.message !== 'Not Found') {
      // Autoload.Redirection.redirection_error();
      Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang.newKeys.text_error_remove_receipt);
    }
    this.getSelectedReceipts();
    this.props.skeletonLoadingFalse();
  }

  renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      There is no meter data available. <br></br>
      <a href="/meter">Add meter data</a> to proceed.
    </Tooltip>
  );
  statusView = (meterData, showStatus=false) => {
    if (meterData === 'zuweisung') {
      // console.log('button meter')
      return (
        <ColorButton
          text="Zuweisung"
          className="gray-btn mannual-btn"
          onClick={() => {
            this.toggleZuweisungModal();
          }}
        />
      );
    } else {
      // console.log('text meter')
      //  text_not_required:"nicht benötigt",
      // text_available:"verfügbar",
      // text_not_available:"nicht verfügbar"

      let icon;
      let status = meterData;
      let color;
      switch (meterData) {
        case '3 - complete':
          icon = GreenTick;
          status = 'verfügbar';
          color='#059266';
          break;
        case '1 - none available':
          icon = RedTick;
          status = 'nicht verfügbar';
          color='#E8193C'
          break;
        case '2 - some available':
          icon = YellowTick;
          status = 'teilweise verfügbar';
          color='#D97706';
          break;
        case '0 - not required':
          icon = GrayTick;
          status = 'nicht benötigt';
          color='#727293'
          break;
        default:
          status = '-';
          break;
      }
      if (status === 'Not available') {
        return (
          <OverlayTrigger
            trigger="click"
            placement="top"
            // delay={{ show: 450, hide:700}}
            //     overlay={<Popover  id="button-Popover ">
            //     Time frame which the bill covers.
            //   </Popover >}
            overlay={this.renderTooltip()}
          >
            <div style={{ cursor: 'pointer' }} className={status.replace(/\s/g, '')}>
              {icon && 
              <OverlayTrigger
              trigger={'hover'}
              placement="top"
              overlay={this.renderStatusTooltip(status)}
              >
                <img src={icon} alt="status" />
              </OverlayTrigger>}
              {showStatus && <span style={{ marginLeft: '5px', width: '200px !important' }}>{status}</span>}
            </div>
          </OverlayTrigger>
        );
      } else {
        return (
          <div className={status.replace(/\s/g, '')} style={{display: 'flex'}}>
            {icon && 
            <OverlayTrigger
            trigger={'hover'}
            placement="top"
            overlay={this.renderStatusTooltip(status)}
            >
              <img src={icon} alt="status" />
            </OverlayTrigger>}
            {showStatus && <span style={{ color: color, marginLeft: '5px', width: '200px !important', display: 'block', //whiteSpace: this.props.isTablet ? 'normal' : 'nowrap' 
          }}>{status}</span>}
          </div>
        );
      }
    }
  };
  validateAmount = () => {
    let invalidErr, emptyErr;
    emptyErr = emptyFieldValidation(this.state.dataValues.amount);
    invalidErr = invalidSize(this.state.dataValues.amount);
    if (emptyErr) {
      this.setState({ amountMsg: 'Betrag eingeben', amountErr: true });
      // console.log('dont save data')
    } else if (invalidErr) {
      this.setState({ amountMsg: lang.receipt.text_invalid_amount, amountErr: true });
      // console.log('dont save data')
    } else {
      this.setState({ amountMsg: '', amountErr: false });
      // console.log('save data')
      // this.updateStore()
    }
  };
  onAmountChange = (field, value) => {
    console.log('onAmountChange: ', value);
    this.setState({ dataValues: { ...this.state.dataValues, [field]: value, newAmount: null, previousAmount: null } }, () => this.validateAmount());
  };
  onAmountBlur = (field, value, noLoader=false) => {
    console.log('onAmountBlur: ', value, this.state.amountErr);
    this.setState({ dataValues: { ...this.state.dataValues, [field]: value } }, () => {
      this.setState({newAmount: null, previousAmount: null}, ()=> {
        if (!this.state.amountErr) {
          this.updateStore();
          this.updateRow(noLoader);
        }
      });
    });
  };

  formattingEU = (nStr) => {
    nStr += '';
    let x = nStr.split(',');
    let x1 = x[0];
    let x2 = x.length > 1 ? ',' + x[1] : '';
    let rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, '$1' + '.' + '$2');
    }
    return x1 + x2;
  };
  onCostSelect = (selectedList, selectedItem) => {
    this.setState({skeletonLoading: true});
    let allocation =
      selectedItem.allocationKey &&
      this.props.allocationsArr.find((alloc) => alloc.id === selectedItem.allocationKey.id);
      this.state.receipts.filter((receipt) => this.state.dataValues.selectedReceipt.find((selected) => (selected.costPositionId === this.costPositionId && selected.receiptId === receipt.id)) !== undefined && receipt.utilityBillingRelevant)

    this.setState(
      {
        dataValues: {
          ...this.state.dataValues,
          costTypeId: selectedItem.id,
          costTypeName: selectedItem.costTypeName,
          costType: selectedItem,
          allocationKeyId: allocation && allocation.id,
          allocationKeyName: allocation && allocation.allocationKeyName,
          allocationKey: allocation ? [{ ...allocation }] : [],
          amount: null,
          vatAmount: null,
          laborCost: null
        },
      },
      () => {
        //this.updateStore();
        this.updateRow(true, true);
        //this.getAllByBillingPeriodCostType(this.billingPeriodId, selectedItem.id, true);
        //this.updateReceiptAssignmentsByCostPositionId();
      }
    );
  };
  onAllocationSelect = (selectedList, selectedItem) => {
    console.log('allloc change', this.state.dataValues);
    // if(this.props.propertySelected.propertyType === 'CONDOMINIUM' && selectedItem?.allocationKeyName === 'Personenanzahl' && !this.props.propertySelected.totalOccupantsCount){
    //   console.log('onAllocationSelect: if');
    //   console.log('_handleHeatingCostPositionResponse: ', selectedItem.allocationKeyName, this.props.propertySelected);
    //   this.props.opentotalOccupantsCountNullAlert();
    //   this.updateRow();
    // }else
    // {
      console.log('onAllocationSelect: else');
      this.setState(
        {
          dataValues: {
            ...this.state.dataValues,
            allocationKeyId: selectedItem.id,
            allocationKeyName: selectedItem.allocationKeyName,
            allocationKey: selectedList,
          },
        },
        () => {
          console.log('allloc change setState', this.state.dataValues);
          this.updateStore();
          this.updateRow();
        }
      );
    //}
  };
  deleteCostPosition = (id) => {
    //loader(true);
    this.props.skeletonLoadingTrue();
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.ub_api_base_url + 'costPositions/' + id,
      'DELETE',
      null,
      null,
      false,
      true,
      false,
      this,
      'handle_delete_cp',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };
  handle_delete_cp = (response) => {
    if (response.status === false && response.message !== 'Not Found') {
      // Autoload.Redirection.redirection_error();
      Autoload.DataHandlerGeneral.GeneralErrorMessage(
        true,
        lang.newKeys.text_error_not_found
        // + response.message
      );
    }
    //update data
    this.props.updateCp();
    //loader(false);
    setTimeout(() => {
      this.props.skeletonLoadingFalse();  
    }, 1000);
  };

  toggleViewDetailsModal = (recordId) => {
    this.props.toggleViewDetailsModal(recordId)
  }

  _handle_allocation_keys_response(response) {
    if (response.status === false && response.message !== 'Not Found') {
      Autoload.DataHandlerGeneral.GeneralErrorMessage(true, 'Error :  ' + response.message);
    }
    const keys = GeneralDataHandler.getAllocationKeys(response.result);

    this.onAllocationSelect([keys.find((allocationKey)=> allocationKey.id === this.state.selectedAllocationId)], keys.find((allocationKey)=> allocationKey.id === this.state.selectedAllocationId));
  }

  handleEnterKeyPress = (e) => {
    if (e.charCode == 13) {
      e.preventDefault();
      this.handleSubmit(e);
    }
  };

  render() {
    const { utilityData, billingPeriodId, propertySelected, newCostPositionData } = this.props;
    const { id, utilityBillingId, costTypeId } = this.props.data;
    const {isMobile, isTablet} = this.props;
    const { addCostTypeModal, fixedAllocationKeyModal, selectedAllocationId, selectedAllocationKeyType, selectedAllocationUnits, selectedAllocationKeyName, addAllocationKeyModal, newAllocationKey, openEditVatAndLabourAmount, openCostPositionValuesModal, openRemoveReceiptAssignmentModal, amountInFocus, previousAmount, newAmount, dataValues, skeletonLoading, openRemoveReceiptAssignmentForCpAmountModal } = this.state;
    const { costType, amount, allocationKey, receipt, selectedReceipt, zuweisung, meterData, vatAmount, laborCost } = this.state.dataValues;
    console.log('utilityData: ', utilityData);

    console.log('render.amount: ', amount);
    console.log('newAmount; ', newAmount);
    console.log('newCostPositionData: ', newCostPositionData);
    return (
      <>
        {skeletonLoading ?
        <TableRow>
          <TableCell style={{padding: 0}} colSpan={7}>
            <Skeleton visible={true} style={{height: '74px'}}/>
          </TableCell>
        </TableRow>
        
        :
        <TableRow //className="table-data utility-cost-row"
        className='utility-table-row'
          style={{
            background: 'white',
          }}
        key={id}
        >
          <TableCell className='drag-handler-table-cell'>
            <DragHandle />
          </TableCell>
          <TableCell //className="ub-cost-type"
          className='ub-other-cost-distribution-table-body-cell-cost-type'
            style={{borderBottom: 'none', maxWidth: '190px', paddingLeft: '5px'}}
            width={'15%'}
          >
            <Multiselect
              className="ub_multiselect_id"
              style={{
                chips: { 
                  borderRadius: '0px', 
                  color: '#111147', 
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: 'auto',
                  paddingLeft: '5px',
                  marginTop: '4px',
                  marginRight: '10px',
                  fontSize: '14px'
                },
                searchBox: {
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  height: '36px',
                  border: '1px solid #d5dce6',
                  paddingRight: '10px',
                  paddingLeft: 0,
                  background: utilityData.isSecured && '#f7f7fa',
                },
                multiselectContainer: { 
                  margin: 0,
                  padding: 0
                },
                inputField: { marginRight: '0px', width: '100%', paddingLeft: '5px', paddingRight: '5px', cursor: 'pointer' },
                option: {
                  fontSize: '14px',
                  fontWeight: '400',
                  lineHeight: '20px',
                  color: '#0e0e1d',
                  padding: '10px',
                },
                

              }}
              options={this.props.costTypesArr.sort((a,b)=> a.costTypeName.localeCompare(b.costTypeName))} // Options to display in the
              displayValue="costTypeName"
              selectedValues={this.props.costTypesArr.filter((o) => o.id === this.props.data.costTypeId)} // Preselected value to persist in dropdown
              placeholder={!utilityData.isSecured && "Wählen"}
              closeIcon="cancel"
              avoidHighlightFirstOption={true}
              onSelect={this.onCostSelect} // Function will trigger on select event
              onRemove={this.onCostRemove} // Function will trigger on remove event
              singleSelect={true}
              disable={utilityData.isSecured || (this.props.costTypesArr.length > 0 ? false : true)}
              closeOnSelect={false}
              selectedValueDecorator={(id)=> {
                console.log('selectedValueDecorator: ', id);
                let costType = this.props.costTypesArr.find((o) => o.id === this.props.data.costTypeId);
                return <MantineTooltip label={costType?.costTypeName}>{costType?.costTypeName}</MantineTooltip>
              }}
              // hidePlaceholder={true}
              emptyRecordMsg={'nicht gefunden'}
              renderCustomButton={
                <ColorButton
                  className="gray-btn utitlity-add-cost-button"
                  text={lang.property.text_cost_addcosttype}
                  onClick={() => this.toggleAddCostTypeModal()}
                />
              }
              // style={{
              //   chips: { background: '#F2F2FC', borderRadius: '4px', color: '#111147' },
              //   multiselectContainer: { marginRight: '0px', width: '192px' },
              //   inputField: { marginRight: '0px', width: '100%' },
              // }}
            />
          </TableCell>
          <TableCell //className="ub-amount"
            style={{
              borderBottom: 'none',
              minWidth: propertySelected.propertyType === 'REGULAR' && '190px',
              maxWidth: propertySelected.propertyType === 'REGULAR' && '190px',
              marginRight: 0
            }}
            width={propertySelected.propertyType !== 'REGULAR' && '5%'}
          >
            {/* <div
            style={{ ...tableCellStyle, ...{
              outline: 'none',
              background: '#fff',
              border: '1px solid #d5dce6',
              borderRadius: '4px',
              padding: '6px 12.8px',
              height: '36px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              textAlign: 'right',
              color: amount ? '#0e0e1d' : '#b1b1b1',
            }}}
            >{amount ? amount : '0,00 €'}</div> */}
            <Group noWrap spacing={10}>
              {console.log('this.props.data: ', this.props.data)}
              <div style={{width: '100px'}} onClick={()=> {
              let allocationKey = this.props.allocationsArr.find((o) => o.id === this.props.data.allocationKeyId);
              console.log('allocationKey: ', allocationKey);
              if(allocationKey && allocationKey.allocationKeyName === 'Individuelle Zuweisung' && allocationKey.systemAllocationKey){
                this.setState({openCostPositionValuesModal: true})
              }
            }}>
                {/* <Form.Control
                  type="text"
                  isInvalid={this.state.amountErr}
                  placeholder={this.props.data.costTypeName !== '' && "0,00€"}
                  value={amount}
                  onChange={(e) => {
                    let allocationKey = this.props.allocationsArr.find((o) => o.id === this.props.data.allocationKeyId);
                    if(allocationKey && allocationKey.allocationKeyName === 'Individuelle Zuweisung' && allocationKey.systemAllocationKey){
                      e.preventDefault();
                    } else {
                      console.log('onChange: ', e.target.value);
                      this.onAmountChange('amount', e.target.value)
                    }
                  }}
                  onBlur={(e) => {
                    let allocationKey = this.props.allocationsArr.find((o) => o.id === this.props.data.allocationKeyId);
                    if(allocationKey && allocationKey.allocationKeyName === 'Individuelle Zuweisung' && allocationKey.systemAllocationKey){
                      e.preventDefault();
                    } else {
                      console.log('onBlur.amount: ', amount);
                      this.onAmountBlur('amount', Autoload.MainHelper.ConverterCurrencyDe(amount + '', true, 'de'));
                    }
                  }}
                  style={{height: '36px', textAlign: 'right', paddingTop: '7px', paddingLeft: '10px', paddingRight: '5px'}}
                  disabled={utilityData.isSecured || this.props.data.costTypeName === ''}
                /> */}
                {/* Start of new implementation */}
                <NumericFormat 
                className='form-control'
                value={amount}
                thousandSeparator="." 
                decimalSeparator=","
                onValueChange={(values, sourceInfo) => {
                  let allocationKey = this.props.allocationsArr.find((o) => o.id === this.props.data.allocationKeyId);
                  if(allocationKey && allocationKey.allocationKeyName === 'Individuelle Zuweisung' && allocationKey.systemAllocationKey){
                    // e.preventDefault();
                  } else {
                    console.log('onChange: ', values.floatValue);
                    this.onAmountChange('amount', values.floatValue)
                  }
                }}
                // onBlur={(values)=> {
                //   this.onInputChange('customVatType', values.floatValue);
                // }}
                suffix={' €'}
                onKeyPress={this.handleEnterKeyPress}
                placeholder={this.props.data.costTypeName !== '' && "0,00 €"}
                onBlur={(e) => {
                  let allocationKey = this.props.allocationsArr.find((o) => o.id === this.props.data.allocationKeyId);
                  if(allocationKey && allocationKey.allocationKeyName === 'Individuelle Zuweisung' && allocationKey.systemAllocationKey){
                    e.preventDefault();
                  } else {
                    console.log('onBlur.amount: ', amount);
                    if(this.state.receipts.filter((receipt) => selectedReceipt.find((selected) => (selected.costPositionId === id && selected.receiptId === receipt.id))).length > 0){
                      this.setState({
                        newAmount: amount, 
                        openRemoveReceiptAssignmentForCpAmountModal: true, 
                        removedItems: this.state.receipts.filter((receipt) => selectedReceipt.find((selected) => (selected.costPositionId === id && selected.receiptId === receipt.id)))
                      });
                    }else{
                      this.onAmountBlur('amount', amount, true);
                    }
                  }
                  this.setState({amountInFocus: false});
                }}
                onFocus={()=> this.setState({amountInFocus: true, previousAmount: amount})}
                decimalScale={amountInFocus === false ? 2 : 5}
                fixedDecimalScale={amountInFocus ? false : true}
                allowNegative={true}
                style={{height: '36px', textAlign: 'right', paddingTop: '7px', paddingLeft: '10px', paddingRight: '5px'}}
                disabled={utilityData.isSecured || this.props.data.costTypeName === ''}
                />
                {/* End of new implementation */}
              </div>
              <div className={vatAmount ? 'sub-text-ub-costposition-amount' : 'sub-text-ub-costposition-amount-disabled'} onClick={()=> this.setState({openEditVatAndLabourAmount: true})}>
                Mwst
              </div>
              {/* <div className='sub-text-ub-costposition-amount'>/</div> */}
              <div className={laborCost ? 'sub-text-ub-costposition-amount' : 'sub-text-ub-costposition-amount-disabled'} onClick={()=> this.setState({openEditVatAndLabourAmount: true})}>
                Lohn
              </div>
            </Group>
            <Form.Control.Feedback className="err" type="invalid" style={{display: this.state.amountErr ? 'block' : 'none'}}>
              {' '}
              {this.state.amountMsg}{' '}
            </Form.Control.Feedback>
          </TableCell>
          <TableCell //className="ub-allocation-key"
          className='ub-other-cost-distribution-table-body-cell-allocation-key'
            style={{borderBottom: 'none', maxWidth: '190px'}}
            width={propertySelected.propertyType !== 'REGULAR' ? '25%' : '15%'}
            //onClick={()=> window.scrollTo(0, document.querySelector(".MuiTableContainer-root").scrollHeight)}
          >
            <Multiselect
              className="ub_multiselect_id"
              style={{
                chips: { 
                  borderRadius: '0px', 
                  color: '#111147', 
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: 'auto',
                  paddingLeft: '5px',
                  marginTop: '4px',
                  marginRight: '10px',
                  fontSize: '14px'
                },
                searchBox: {
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  height: '36px',
                  border: '1px solid #d5dce6',
                  paddingRight: '10px',
                  paddingLeft: 0,
                  background: this.props.data.costTypeName === '' && '#f7f7fa',
                  opacity: 1
                },
                multiselectContainer: { 
                  margin: 0,
                  padding: 0
              },
                inputField: { marginRight: '0px', width: '100%', paddingLeft: '5px', paddingRight: '5px' },
                option: {
                  fontSize: '14px',
                  fontWeight: '400',
                  lineHeight: '20px',
                  color: '#0e0e1d',
                  padding: '10px'
                }
              }}
              // style={{
              //   chips: { background: '#F2F2FC', borderRadius: '4px', color: '#111147' },
              //   multiselectContainer: { marginRight: '0px', width: '192px' },
              //   inputField: { marginRight: '0px', width: '100%' },
              // }}
              options={this.props.allocationsArr.filter((o) => o.type === 'NON_HEATING' || o.type === 'NON_HEATING_INDIVIDUAL').sort((a,b)=>a.allocationKeyName.toLowerCase().localeCompare(b.allocationKeyName.toLowerCase()))} // Options to display in the
              selectedValues={this.props.allocationsArr.filter(
                (o) => o.id === this.props.data.allocationKeyId
              )} // Preselected value to persist in dropdown
              displayValue="id"
              selectedValueDecorator={(id)=> {
                let allocationKey = this.props.allocationsArr.find((o) => o.id === id);
                return <MantineTooltip 
                label={`${allocationKey.allocationKeyName} ${allocationKey.units && allocationKey.units !== '-' ? `(${allocationKey.units})` : ''}`}>
                  {`${allocationKey.allocationKeyName} ${allocationKey.units && allocationKey.units !== '-' ? `(${allocationKey.units})` : ''}`}
                </MantineTooltip>
              }}
              optionValueDecorator={(id)=> {
                let allocationKey = this.props.allocationsArr.find((o) => o.id === id);
                return `${allocationKey.allocationKeyName} ${allocationKey.units && allocationKey.units !== '-' ? `(${allocationKey.units})` : ''}`
              }}
              singleSelect={true}
              onSelect={this.onAllocationSelect} // Function will trigger on select event
              placeholder={this.props.data.costTypeName !== '' && "Wählen"}
              closeIcon="cancel"
              avoidHighlightFirstOption={true}
              isMobileView={isMobile}
              disable={utilityData.isSecured || (this.props.allocationsArr.length > 0 ? false : true) || this.props.data.costTypeName === ''}
              closeOnSelect={false}
              renderCustomButton={
                <ColorButton
                  className="gray-btn utitlity-add-cost-button"
                  text={lang.property.allocationKeys_text_add_allocationkeys}
                  onClick={() => this.setState({addAllocationKeyModal: true})}
                />
              }
            />
            {/* <MultiselectDropdown
              className="ub_multiselect_allocation_key"
              // style={{
              //   chips: { background: '#F2F2FC', borderRadius: '4px', color: '#111147' },
              //   multiselectContainer: { marginRight: '0px', width: '192px' },
              //   inputField: { marginRight: '0px', width: '100%' },
              // }}
              // ref={this.multiselectRef}
              options={this.props.allocationsArr} // Options to display in the
              selectedValues={allocationKey} // Preselected value to persist in dropdown
              displayValue="allocationKeyName"
              // singleSelect={true}
              onSelect={this.onAllocationSelect} // Function will trigger on select event
              placeholder="Select"
              // closeIcon="cancel"
              // avoidHighlightFirstOption={true}
              // isMobileView={isMobile}
              disable={this.props.allocationsArr.length > 0 ? false : true}
              // multiSelect={false}
              // closeOnSelect={false}
            /> */}
          </TableCell>
          {console.log('this.state.dataValues: ', this.state.dataValues)}
          <TableCell //className="ub-meter-data"
          style={{borderBottom: 'none'}}
          width={'20%'}
          >{this.state.dataValues.allocationKey.length > 0 && this.state.dataValues.allocationKey[0]?.type === 'NON_HEATING_INDIVIDUAL' ? 
            <Group spacing={10} noWrap>
              <div>
                <ColorButton
                  className="gray-btn"
                  text={lang.utility.text_set_allocation_button}
                  onClick={() => this.toggleZuweisungModal()}
                  disabled={utilityData.isSecured}
                  buttonStyle={{maxWidth: '85px', minWidth: '85px'}}
                />
              </div>
              {this.statusView(this.props.data.meterData, true)}
            </Group>
             :
              (this.state.dataValues && this.state.dataValues.allocationKey[0] && this.state.dataValues.allocationKey[0].allocationKeyName !== 'Wohnfläche' && this.state.dataValues.allocationKey[0].allocationKeyName !== 'Anzahl Wohnungen' && this.state.dataValues.allocationKey[0].allocationKeyName !== 'Personenanzahl' && (this.state.dataValues.allocationKey[0].allocationKeyType === 'FIXED_TENANT' || this.state.dataValues.allocationKey[0].allocationKeyType === 'FIXED_RENTAL_UNIT')) ?  
              //   <ColorButton
              //   className="gray-btn"
              //   text={'Verteilerwerte'}
              //   onClick={() => this.openEditFixedAllocationKeyModal(this.state.dataValues?.allocationKey[0])}
              //   disabled={utilityData.isSecured}
              // />
              <div style={{cursor: 'pointer'}} //onClick={() => this.openEditFixedAllocationKeyModal(this.state.dataValues?.allocationKey[0])}
              >
                <Group spacing={10} noWrap>
                  <div>
                    <ColorButton
                    className="gray-btn"
                    text={'Prüfen'}//propertySelected && propertySelected.propertyType === 'REGULAR' ? 'Prüfen' : (this.props.data.allocationKeyName === 'Wohnfläche' || this.props.data.allocationKeyName === 'Anzahl Wohnungen') ? 'Prüfen' : 'Zuweisen'}
                    onClick={() => this.setState({openCostPositionValuesModal: true})}
                    buttonStyle={{minWidth: '85px'}}
                    />
                  </div>
                  {this.statusView(this.props.data.meterData, true)}
                  {/* <OverlayTrigger
                    trigger={'hover'}
                    placement="top"
                    overlay={this.renderStatusTooltip('Verteilerwerte anpassen')}
                  >
                    <img src={EditForm} style={{marginBottom: '2px'}}/>
                  </OverlayTrigger> */}
                </Group>
              </div>
              :
              (this.state.dataValues && this.state.dataValues.allocationKey[0] && this.state.dataValues.allocationKey[0].allocationKeyType === 'USAGE_BASED') ?  
              <div style={{cursor: 'pointer'}} //onClick={() => this.props.history.push(`/meter/reading/annual/${billingPeriodId}/c-r-0`)}
              >
                <Group spacing={10} noWrap>
                  <div>
                    <ColorButton
                    className="gray-btn"
                    text={'Prüfen'}//propertySelected && propertySelected.propertyType === 'REGULAR' ? 'Prüfen' : (this.props.data.allocationKeyName === 'Wohnfläche' || this.props.data.allocationKeyName === 'Anzahl Wohnungen') ? 'Prüfen' : 'Zuweisen'}
                    onClick={() => this.setState({openCostPositionValuesModal: true})}
                    buttonStyle={{minWidth: '85px'}}
                    />
                  </div>
                  {this.statusView(this.props.data.meterData, true)}
                  {/* <OverlayTrigger
                    trigger={'hover'}
                    placement="top"
                    overlay={this.renderStatusTooltip('Verbrauchswerte anpassen')}
                  >
                    <img src={EditForm} style={{marginBottom: '2px'}}/>
                  </OverlayTrigger> */}
                </Group>
              </div>
              :
              <Group spacing={10} noWrap>
              {this.state.dataValues && this.state.dataValues.allocationKey[0] && (this.state.dataValues.allocationKey[0].allocationKeyName === 'Wohnfläche' || this.state.dataValues.allocationKey[0].allocationKeyName === 'Anzahl Wohnungen' || this.state.dataValues.allocationKey[0].allocationKeyName === 'Personenanzahl') &&
              <div>
                <ColorButton
                className="gray-btn"
                text={'Prüfen'}//propertySelected && propertySelected.propertyType === 'REGULAR' ? 'Prüfen' : (this.props.data.allocationKeyName === 'Wohnfläche' || this.props.data.allocationKeyName === 'Anzahl Wohnungen') ? 'Prüfen' : 'Zuweisen'}
                onClick={() => this.setState({openCostPositionValuesModal: true})}
                buttonStyle={{minWidth: '85px'}}
                />
              </div>}
              {this.statusView(this.props.data.meterData, true)}
              </Group>}
          </TableCell>
          {propertySelected.propertyType === 'REGULAR' ?
          <TableCell //className="ub-receipt"
          style={{borderBottom: 'none', maxWidth: isTablet ? '185px' : '260px'}}>
            <MultiselectDropdown
              className="cost-allocation-receipts"
              tableView={true}
              newTable={true}
              displayValue2="receiptDate"
              options={this.state.receipts.filter((receipt)=> receipt.relevantBillingPeriods.find((bpId)=> bpId === billingPeriodId) //&& receipt.utilityBillingRelevant
              )}
              selectedValues={this.state.receipts.filter((receipt) => selectedReceipt.find((selected) => (selected.costPositionId === id && selected.receiptId === receipt.id)))} // Preselected value to persist in dropdown
              onSelect={this.onReceiptSelect} // Function will trigger on select event
              onRemove={this.onReceiptRemove} // Function will trigger on remove event
              displayValue="name"
              placeholder={utilityData.isSecured ? '' : this.props.data.costTypeName !== '' && "Wählen"}
              viewDetails={true}
              multiSelect
              disable={utilityData.isSecured || this.props.data.costTypeName === ''}
              toggleViewDetailsModal={this.toggleViewDetailsModal}
              endListContent={
                <ColorButton
                  className="gray-btn utitlity-add-cost-button"
                  text={lang.receipt.text_add_receipt}
                  onClick={() => this.toggleAddReceiptModal(this.props.data)}
                />
              }
            />
            {/* add receipt modal */}
            {this.state.addReceiptOpen && (
              <ReceiptDetailModal
                history={this.props.history}
                record={null}
                parent={this}
                className="add-edit-receipt-modal"
                onCreateReceiptSelect={(id)=> this.onCreateReceiptSelect(id)}
                edit={false}
                open={this.state.addReceiptOpen}
                toggle={this.toggleAddReceiptModal}
                utilityData={this.props.utilityData}
                cp={this.props.data}
                timeFrame={this.props.timeFrame}
                billingRelevant
                selectedCostType={[{id: this.costTypeId}]}
              />
            )}
            {console.log('this.state.receipts: ', this.state.receipts)}
            {/* <Multiselect
              className="ub_multiselect_id"
              style={{
                chips: { background: '#F2F2FC', borderRadius: '4px', color: '#111147' },
                multiselectContainer: { marginRight: '0px', width: '296px' },
                inputField: { marginRight: '0px', width: '83px', overflowX: 'ellipses' },
              }}
              tableView={true}
              secondColumnKey="date"
              tableHeaders={[{ heading: 'receipt' }, { heading: 'date' }]}
              // ref={this.multiselectRef}
              options={this.props.receiptsArr} // Options to display in the dropdown
              selectedValues={selectedReceipt} // Preselected value to persist in dropdown
              onSelect={this.onReceiptSelect} // Function will trigger on select event
              onRemove={this.onReceiptRemove} // Function will trigger on remove event
              displayValue="receiptName"
              placeholder="Select"
              closeIcon="cancel"
              avoidHighlightFirstOption={true}
              showCheckbox={true}
              // disable={this.state.multiselectEnable}
              closeOnSelect={false}
              hidePlaceholder={true}
              renderCustomButton={
                <ColorButton
                  className="gray-btn utitlity-add-cost-button"
                  text="Add receipt"
                  onClick={() => redirection('/receipt')}
                />
              }
            /> */}
          </TableCell>
          :
          <TableCell/>}
          {/* <TableCell className='receipt-german-three-dots' style={{textAlign: 'right'}}>
            <RecordOptions
            showEditButton={true}
            editButtonText={'Mwst. & Lohnkosten'}
            showDeleteButton={true}
            toggleEditModal={() => this.setState({openEditVatAndLabourAmount: true})}
            toggleDeleteModal={()=> {!utilityData.isSecured && this.deleteCostPosition(id);}}
            menuStyle={{width: '190px'}}
            style={{marginRight: '0px', marginLeft: '10px'}}
            />
          </TableCell> */}
          <TableCell
            style={{
              borderBottom: 'none', 
              minWidth: isTablet ? '35px' : '40px',
              paddingLeft: '5px',
            }}
            className="empty">
            <button onClick={() => {!utilityData.isSecured && this.deleteCostPosition(id);}} type="button" class="close delete-cost-position" aria-label="Close"><span aria-hidden="true">×</span></button>
          </TableCell>
          {addCostTypeModal && (
            <AddCostTypeModal
              record={null}
              className="add_cost_type_modal"
              edit={false}
              showCostData={this.props.costTypesArr}
              open={addCostTypeModal}
              toggle={this.toggleAddCostTypeModal}
              callBackFunction={this.props.costTypesBackFunction}
            />
          )}
        </TableRow>}
          <ZuweisungModal
            costPositionId={id}
            billingId={utilityBillingId}
            record={this.state.modalData}
            text="Zuweisung"
            className="Zuweisung-modal"
            // edit={zuweisung.length > 0 ? true : false}
            saveAmount={(amount)=> this.onAmountBlur('amount', amount)}
            open={this.state.AddZuweisungModal}
            toggle={this.closeDataModal}
          />
          {openEditVatAndLabourAmount &&
          <EditVatAndLabourAmount
          vatAmount={vatAmount}
          laborCost={laborCost}
          isSecured={utilityData.isSecured}
          open={openEditVatAndLabourAmount}
          toggle={()=> this.setState({openEditVatAndLabourAmount: false})}
          saveVatLaborValues={(vatAmount, laborCost)=> this.setState({dataValues: {...this.state.dataValues, vatAmount, laborCost}}, ()=> {
            this.updateStore();
            this.updateRow();
            this.setState({openEditVatAndLabourAmount: false});
          })}
          />}
          {fixedAllocationKeyModal &&
          <FixedAllocationKeyModal
          open={fixedAllocationKeyModal}
          className={'individual-allocation-key-modal'}
          type={selectedAllocationKeyType}
          allocationKeyId={selectedAllocationId}
          toggle={(onUpdate= false)=> {
            onUpdate && newAllocationKey && GlobalServices._getAllocationKeysByProperty(this, '_handle_allocation_keys_response', this.props.propertySelected);
            onUpdate && this.updateConsumptionsByCostPositionId(id, true) 
            setTimeout(() => {
              this.setState({fixedAllocationKeyModal: false, selectedAllocationKeyType: '', selectedAllocationUnits: '', selectedAllocationKeyName: '', newAllocationKey: false});
            }, onUpdate && newAllocationKey ? 5000 : 0);
          }}
          unit={selectedAllocationUnits}
          history={this.props.history}
          name={selectedAllocationKeyName}
          />}
          {addAllocationKeyModal && (
            <AddAllocationKeyModal
              record={null}
              parent={this}
              className="add_allocation_key_modal"
              edit={false}
              open={addAllocationKeyModal}
              allocationData={this.props.allocationsArr}
              toggle={()=> this.setState({addAllocationKeyModal: false})}
              callBackFunction={() => this.props.fetchAllocationKeysByProperty()}
              selectedType={(type, id, units, name)=> (type === 'FIXED_RENTAL_UNIT' || type === 'FIXED_TENANT') && this.setState({selectedAllocationKeyType: type, fixedAllocationKeyModal: true, selectedAllocationId: id, selectedAllocationUnits: units, selectedAllocationKeyName: name, newAllocationKey: true})}
            />
          )}
          {openCostPositionValuesModal && 
          <CostPositionValuesModal
          open={openCostPositionValuesModal}
          cosumptionType={this.state.dataValues?.allocationKey[0]?.allocationKeyType}
          consumptionStatus={this.statusView(this.props.data.meterData, true)}
          allocationKey={this.props.allocationsArr.filter((o) => o.id === this.props.data.allocationKeyId)[0]}
          allocationKeyId={this.props.data.allocationKeyId}
          costType={this.props.costTypesArr.filter((o) => o.id === this.props.data.costTypeId)[0].costTypeName}
          costPositionId={this.props.data.id}
          costPositionData={this.props.data}
          utilityBillingId={this.props.data.utilityBillingId}
          currentDataId={this.props.currentDataId}
          toggle={()=> this.setState({openCostPositionValuesModal: false})}
          openFixedAllocationKeyModal={()=> this.openEditFixedAllocationKeyModal(this.state.dataValues?.allocationKey[0])}
          redirectToConsumptionEditValues={()=> this.props.history.push(`/meter/reading/annual/${billingPeriodId}/c-r-0`)}
          saveAmount={(amount)=> this.onAmountBlur('amount', amount)}
          redirectToTenantScreen={()=> this.props.history.push(`/user`)}
          redirectToPropertyScreen={()=> this.props.history.push(`/property`)}
          updateStoreAndRow={()=> {this.updateStore(); this.updateRow();}}
          getTenantsConsumption={()=> this.getTenantsConsumption(this.props.data.allocationKeyId, this.props.data.id, false)}
          />}

          {openRemoveReceiptAssignmentModal &&
          <ReceiptAssigmentRemoveConfirmationModal
          open={openRemoveReceiptAssignmentModal}
          newAmount={newAmount}
          toggle={()=> this.setState({openRemoveReceiptAssignmentModal: false}, ()=> {
            if(previousAmount){
              this.onAmountChange('amount', previousAmount);
            }
          })}
          removeReceipt={()=> this.updateReceipt()}
          />}

          {openRemoveReceiptAssignmentForCpAmountModal &&
          <ReceiptAssigmentRemoveConfirmationForCpAmountModal
          open={openRemoveReceiptAssignmentForCpAmountModal}
          newAmount={newAmount}
          toggle={()=> this.setState({openRemoveReceiptAssignmentForCpAmountModal: false}, ()=> {
            if(previousAmount){
              this.onAmountChange('amount', previousAmount);
            }
          })}
          removeReceipt={()=> this.updateReceipt()}
          />}
      </>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    // costTypes:state.costTypeReducer,
    // allocationData:state.allocationKeyReducer,
    propertySelected: state.generalReducer.property,
    costTypesArr: ownProps.costTypesArr ? ownProps.costTypesArr : [],
    allocationsArr: ownProps.allocationsArr ? ownProps.allocationsArr : [],
    receiptsArr: ownProps.receiptsArr ? ownProps.receiptsArr : [],
  };
};
const actions = {
  deleteCostPosition,
  editCostPosition,
};
export default connect(mapStateToProps, actions)(UtilityCostAllocationRow);
