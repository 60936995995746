import { Group, Stack } from '@mantine/core';
import React from 'react';
import GoldenLockIcon from '../../../../../assets/images/module/general/GoldenLockIcon.svg';

export default function CardSelection({hover, hoverName, handleMouseEnter, handleMouseLeave, onClick, blueIcon, icon, selectedType, type, disabled, title, subText, typeError, isMobile, iconStyle, paidFeature, width='220px', className=null}) {
  return (
    <Group position='center'
    align={'flex-start'}
    className={className ? className : isMobile ? 'meter-type-button-mobile' : 'meter-type-button'}
    onMouseEnter={()=> handleMouseEnter(hoverName)}
    onMouseLeave={()=> handleMouseLeave(hoverName)}
    onClick={!disabled && onClick}
    style={{background: disabled ? '#f7f7fa' : selectedType === type && '#E3E3FC', borderColor: typeError && !hover && selectedType !== type && '#dc3545', border: hover && '1px #5555FF solid', width: isMobile ? '156px' : width}}>
        <Stack spacing={'5px'}>
            <Group position={'apart'} noWrap align={'flex-start'}>
                <Group spacing={7}>
                    <span style={{fontWeight: '600', fontFamily: 'Inter, sans-sarif', fontSize: '16px', color: selectedType === type ? '#5555FF' : hover ? '#5555FF' : '#252563', wordBreak: isMobile && 'break-all'}}>{title}</span>
                    {paidFeature && <img src={GoldenLockIcon}/>}
                </Group>
                {(icon && blueIcon) && <img style={{...{marginTop: '3px'}, ...iconStyle}} src={selectedType === type ? blueIcon : hover ? blueIcon : icon} width='16px' height={'16px'} />}
            </Group>
            {subText && <span style={{textAlign: '', fontWeight: '400', color: selectedType === type ? '#252563' : '#252563'}}>{subText}</span>}
        </Stack>
    </Group>
  );
}
