import React, { useState, useRef, useEffect } from 'react';
import { usePdf } from '@mikecousins/react-pdf';
import { Button } from 'reactstrap';
import { ChevronRight, ChevronLeft, ZoomIn, ZoomOut, Add, Remove} from '@material-ui/icons';
import ExpandIcon from '../../../../../assets/images/module/general/ExpandIcon.svg';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { pdfjs } from 'react-pdf';
import { Stack } from '@mantine/core';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const MOBILE_SIZE = 992;
const SMALLER_MOBILE_SIZE = 500;
// const PdfViewer = ({file, preview=false, billingRelevantSwitch=false, costAllocation=false, onExpand, addFile, removePage}) => {
//   const [page, setPage] = useState(1);
//   const [isSmallerMobile, setIsSmallerMobile] = useState(window.innerWidth <= SMALLER_MOBILE_SIZE);
//   const [isMobile, setIsMobile] = useState(window.innerWidth <= MOBILE_SIZE);
//   const [scale, setScale] = useState(isSmallerMobile ? (preview ? 0.45 : 0.55) : isMobile ? 1.0 : preview ? 1.0 : 1.4);
//   const canvasRef = useRef(null);

//   const { pdfDocument, pdfPage } = usePdf({
//     file: file,
//     page,
//     canvasRef,
//     scale
//   });

//   const [totalNumberOfPages, setTotalNumberOfPages] = useState(pdfDocument?.numPages);

//   useEffect(()=>{
//     if(pdfDocument?.numPages > totalNumberOfPages){
//       setTimeout(() => {
//         setPage(totalNumberOfPages + 1);  
//       }, 500);
//     }

//     if(pdfDocument !== undefined){
//       setTotalNumberOfPages(pdfDocument?.numPages);
//     }
//   }, [pdfDocument?.numPages]);

//   console.log('page: ', page, pdfDocument?.numPages);

//   return (
//     <div style={{width: '100%', textAlign:'center'}}>
//       {!preview &&
//       <div style={{textAlign: 'left'}}>  
//       {!pdfDocument && <span>Loading...</span>}
//       {Boolean(pdfDocument && pdfDocument.numPages) && (
        
//         <nav>
//             <Button size={'sm'} disabled={page === 1} onClick={() => setPage(page - 1)} children={<ChevronLeft/>}/>
//             {' '}
//             <Button size={'sm'} disabled={page === pdfDocument.numPages} onClick={() => setPage(page + 1)} children={<ChevronRight/>}/>
//             {' '}
//             {!isSmallerMobile && (
//             <>
//               <Button size={'sm'} disabled={scale === (isMobile ? 1.3 : 1.6)} onClick={() => setScale(v => v + 0.1)} children={<ZoomIn/>}/>
//               <span> </span>
//               <Button size={'sm'} disabled={preview && scale === 0.5} onClick={() => setScale(v => v - 0.1)} children={<ZoomOut/>}/>
//             </>)}
//         </nav>
//       )}
//       </div>}
//       <div style={{marginTop: !preview ? 20 : 10, alignSelf:'center'}}>
//         <canvas ref={canvasRef}/>
//       </div>
//       {console.log('costAllocation: ', window.location.href.includes('/receipt'))}
//       {preview &&
//       <div style={{textAlign: 'left', position: preview && 'absolute', //bottom: preview && !isMobile && 27, 
//       top: isMobile ? 367 : 667, left: preview && 4}}>  
//       {!pdfDocument && <span>Laden...</span>}
//       {Boolean(pdfDocument && pdfDocument.numPages) && (
//         <nav>
//             <Button size={'sm'} disabled={page === 1} onClick={() => setPage(page - 1)} children={<ChevronLeft/>}/>
//             {' '}
//             <Button size={'sm'} disabled={page === pdfDocument.numPages} onClick={() => setPage(page + 1)} children={<ChevronRight/>}/>
//             {' '}
//             {(
//             <>
//               <Button size={'sm'} disabled={isMobile ? scale >= 1.3 : scale >= 1.6} onClick={() => setScale(v => v + (isMobile ? 0.05 : 0.1))} children={<ZoomIn/>}/>
//               <span> </span>
//               <Button size={'sm'} disabled={preview && isMobile ? scale <= 0.45 : scale === 1.0} onClick={() => setScale(v => v - (isMobile ? 0.05 : 0.1))} children={<ZoomOut/>}/>
//             </>)}
//             <>
//               {' '}
//               <Button size={'sm'} onClick={() => onExpand()} children={<img src={ExpandIcon} style={{width: '15px'}}/>}/>
//               {' '}
//               <Button style={{display: 'none'}} id={'add-file'} size={'sm'} onClick={() => addFile()} children={<Add/>}/>
//               {' '}
//               <Button 
//               id={'remove-page'}
//               style={{display: 'none'}}
//               size={'sm'} 
//               onClick={() => {
//                 removePage(page);
//                 if(page > 1){
//                   setPage(page - 1);
//                 }
//               }} 
//               children={<Remove/>}/>
//             </>
//         </nav>
//       )}
//       </div>}
//     </div>
//   );
// };

const PdfViewer = ({file, preview=false, billingRelevantSwitch=false, costAllocation=false, onExpand, addFile, removePage}) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [isSmallerMobile, setIsSmallerMobile] = useState(window.innerWidth <= SMALLER_MOBILE_SIZE);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= MOBILE_SIZE);

  const canvasRef = useRef(null);
  const [imgScale, setImgScale] = useState(isSmallerMobile ? (preview ? 0.45 : 0.55) : isMobile ? 1.0 : preview ? 1.0 : 1.4);

  const { pdfDocument } = usePdf({
    file,
    canvasRef
  });

  console.log('PdfViewer: ', pdfDocument, file);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <Stack>
      {!preview &&
      <div style={{textAlign: 'left'}}>  
      {!pdfDocument && <span>Loading...</span>}
      {Boolean(pdfDocument && numPages) && (
        
        <nav>
            <Button size={'sm'} disabled={pageNumber === 1} onClick={() => setPageNumber(pageNumber - 1)} children={<ChevronLeft/>}/>
            {' '}
            <Button size={'sm'} disabled={pageNumber === numPages} onClick={() => setPageNumber(pageNumber + 1)} children={<ChevronRight/>}/>
            {' '}
            {!isSmallerMobile && (
            <>
              <Button size={'sm'} disabled={imgScale === (isMobile ? 1.3 : 1.6)} onClick={() => setImgScale(v => v + 0.1)} children={<ZoomIn/>}/>
              <span> </span>
              <Button size={'sm'} disabled={preview && imgScale === 0.5} onClick={() => setImgScale(v => v - 0.1)} children={<ZoomOut/>}/>
            </>)}
        </nav>
      )}
      </div>}
      <div style={{marginTop: !preview ? 20 : 10, alignSelf:'center', marginBottom: !preview ? 20 : 10}}>
        <Document file={file} loading={null} onLoadSuccess={onDocumentLoadSuccess} onLoadError={(e)=> console.log('pdfLoadError: ', e)}>
          {<Page loading={null} pageNumber={pageNumber} scale={imgScale} />}
        </Document>
      </div>
      {console.log('costAllocation: ', window.location.href.includes('/receipt'))}
      {preview &&
      <div style={{textAlign: 'left', position: preview && 'absolute', //bottom: preview && !isMobile && 27, 
      top: isMobile ? 367 : 667, left: preview && 4}}>  
      {!pdfDocument && <span>Laden...</span>}
      {Boolean(pdfDocument && numPages) && (
        <nav>
            <Button size={'sm'} disabled={pageNumber === 1} onClick={() => setPageNumber(pageNumber - 1)} children={<ChevronLeft/>}/>
            {' '}
            <Button size={'sm'} disabled={pageNumber === numPages} onClick={() => setPageNumber(pageNumber + 1)} children={<ChevronRight/>}/>
            {' '}
            {(
            <>
              {/* <Button size={'sm'} disabled={isMobile ? scale >= 1.3 : scale >= 1.6} onClick={() => setScale(v => v + (isMobile ? 0.05 : 0.1))} children={<ZoomIn/>}/> */}
              <Button size={'sm'} disabled={imgScale === 3} onClick={() => setImgScale(imgScale + 0.1)} children={<ZoomIn/>}/>
              <span> </span>
              {/* <Button size={'sm'} disabled={preview && isMobile ? scale <= 0.45 : scale === 1.0} onClick={() => setScale(v => v - (isMobile ? 0.05 : 0.1))} children={<ZoomOut/>}/> */}
              <Button size={'sm'} disabled={imgScale === 0.5} onClick={() => setImgScale(imgScale - 0.1)} children={<ZoomOut/>}/>
            </>)}
            <>
              {' '}
              <Button size={'sm'} onClick={() => onExpand()} children={<img src={ExpandIcon} style={{width: '15px'}}/>}/>
              {' '}
              <Button style={{display: 'none'}} id={'add-file'} size={'sm'} onClick={() => addFile()} children={<Add/>}/>
              {' '}
              <Button 
              id={'remove-page'}
              style={{display: 'none'}}
              size={'sm'} 
              onClick={() => {
                removePage(pageNumber);
                if(pageNumber > 1){
                  setPageNumber(pageNumber - 1);
                }
              }} 
              children={<Remove/>}/>
            </>
        </nav>
      )}
      </div>}
    </Stack>
  );
};

export default PdfViewer;