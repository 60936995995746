import Autoload from '../../Autoload';
import moment from 'moment';

const { checkProperty } = Autoload.MainHelper;

class ReceiptDataHandler {
  static getReceipts = (receipts) => {
    const checkedReceipts = receipts.map((receipt) => {
      const date =
        Autoload.MainHelper.checkProperty(receipt, 'receiptDate') === ''
          ? Autoload.MainHelper.checkProperty(receipt, 'date')
          : Autoload.MainHelper.checkProperty(receipt, 'receiptDate');
      const cost =
        Autoload.MainHelper.checkProperty(receipt, 'costs') === ''
          ? Autoload.MainHelper.checkProperty(receipt, 'cost')
          : Autoload.MainHelper.checkProperty(receipt, 'costs');
      return {
        id: Autoload.MainHelper.checkProperty(receipt, 'id'),
        propertyId: Autoload.MainHelper.checkProperty(receipt, 'propertyId'),
        name: Autoload.MainHelper.checkProperty(receipt, 'name'),
        date: checkProperty(receipt, 'date') === '' ? '-' : moment(date).format('DD.MM.YYYY'),
        time_frame:
          moment(Autoload.MainHelper.checkProperty(receipt, 'receiptPeriodStart')).format('DD.MM.YYYY') +
          ' - ' +
          moment(Autoload.MainHelper.checkProperty(receipt, 'receiptPeriodEnd')).format('DD.MM.YYYY'),
        receiptDate: Autoload.MainHelper.checkProperty(receipt, 'receiptDate'),
        receiptPeriodStart: Autoload.MainHelper.checkProperty(receipt, 'receiptPeriodStart'),
        receiptPeriodEnd: Autoload.MainHelper.checkProperty(receipt, 'receiptPeriodEnd'),
        utilityBillingRelevant: Autoload.MainHelper.checkProperty(receipt, 'utilityBillingRelevant'),
        utilityBillingAllocated: Autoload.MainHelper.checkProperty(receipt, 'utilityBillingAllocated'),
        relevantBillingPeriods: Autoload.MainHelper.checkProperty(receipt, 'relevantBillingPeriods'),
        isValidated: Autoload.MainHelper.checkProperty(receipt, 'isValidated'),
        // costs: Autoload.MainHelper.ConverterCurrencyDe(cost + ''),
        costs: cost,
        SelectedCostTypes: Autoload.MainHelper.checkProperty(receipt, 'selectedCostTypes')
          ? Autoload.MainHelper.checkProperty(receipt, 'selectedCostTypes').map((costType) => {
              return {
                id: Autoload.MainHelper.checkProperty(costType, 'id'),
                costTypeName: Autoload.MainHelper.checkProperty(costType, 'costTypeName'),
                propertyId: Autoload.MainHelper.checkProperty(costType, 'propertyId'),
                systemCostType: Autoload.MainHelper.checkProperty(costType, 'systemCostType'),
                useInReceiptMgmt: Autoload.MainHelper.checkProperty(costType, 'useInReceiptMgmt'),
                heatingCostType: Autoload.MainHelper.checkProperty(costType, 'heatingCostType'),
                allocationKey: {
                  allocationKeyId: Autoload.MainHelper.checkProperty(costType.allocationKey, 'allocationKeyId'),
                  allocationKeyName: Autoload.MainHelper.checkProperty(costType.allocationKey, 'allocationKeyName'),
                  propertyId: Autoload.MainHelper.checkProperty(costType.allocationKey, 'propertyId'),
                  units: Autoload.MainHelper.checkProperty(costType.allocationKey, 'units'),
                  metered: Autoload.MainHelper.checkProperty(costType.allocationKey, 'metered'),
                  systemAllocationKey: Autoload.MainHelper.checkProperty(costType.allocationKey, 'systemAllocationKey'),
                },
              };
            })
          : [],
        //@desc extra key for cost name
        cost_type: Autoload.MainHelper.checkProperty(receipt, 'selectedCostTypes')
          ? Autoload.MainHelper.checkProperty(receipt, 'selectedCostTypes').map((costType) =>
              Autoload.MainHelper.checkProperty(costType, 'costTypeName')
            )
          : [],
        vatPercent: Autoload.MainHelper.checkProperty(receipt, 'vatPercent'),
        vatAmount: Autoload.MainHelper.checkProperty(receipt, 'vatAmount'),
        netAmount: Autoload.MainHelper.checkProperty(receipt, 'netAmount'),
        laborCost: Autoload.MainHelper.checkProperty(receipt, 'laborCost'),
        taxCostType: Autoload.MainHelper.checkProperty(receipt, 'taxCostType'),
        fileId: Autoload.MainHelper.checkProperty(receipt, 'fileId'),
        fileName: Autoload.MainHelper.checkProperty(receipt, 'fileName'),
        receiptNumber: receipt.receiptNumber,
        notes: receipt.notes
      };
    });
    return checkedReceipts;
  };
}

export default ReceiptDataHandler;
