import React, { Component } from 'react';
import {
  ColorButton,
  SearchContainer,
  SortContainer,
  Divider,
  RentalUnitModal,
  AddPropertyModal,
  DeleteModal,
  EmptyStateContainer,
  AddTenantModal,
  SortAlphabetically,
} from '../../../../neoverv';
import { TenantRecordRow, PageHeader, PageHeaderNew } from '../../../elements/index';
import {
  getRentalUnitTenants,
  setCurrentTenant,
  updateFilteredList,
  DeleteUnit,
} from '../../../../redux/action/Tenant/TenantActions';
import {
  setRefreshTenantDocumentsData
} from '../../../../redux/action/General/generalActions';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import UnitsIcon from '../../../../assets/images/module/general/UnitsIcon.svg';
import BlueUnitsIcon from '../../../../assets/images/module/general/BlueEmptyTenant.svg';
import HelpButton from '../../../../assets/images/module/general/HelpButton.svg';
import TourButton from '../../../../assets/images/module/general/TourButton.svg';
import HelpLogo from '../../../../assets/images/module/general/book.svg';
import TourIcon from '../../../../assets/images/module/general/TourIcon.svg';
import PdfIcon from '../../../../assets/images/module/general/PdfNewIcon.svg';
import XlsxIcon from '../../../../assets/images/module/general/XlsxIcon.svg';
import breadcrumbsContent from '../../../../../src/assets/breadcrumbs.json';
import moment from 'moment';
import Autoload from '../../../../neoverv/Autoload';
import { Group, Popover, Skeleton, Stack } from '@mantine/core';
import { Button, ButtonGroup } from 'react-bootstrap';
import BuyPremiumModal from '../../../../neoverv/components/Module/General/CustomModal/BuyPremiumModal';
import dayjs from 'dayjs';
import { IconButton, Tooltip } from '@material-ui/core';
import { Add, CreateNewFolder, NoteAdd } from '@material-ui/icons';
import DownloadIcon from '../../../../neoverv/components/SVGs/downloadIcon';
import { CSVLink } from 'react-csv';
import { browserName } from 'react-device-detect';
import { formatNumber } from '../../../../helpers/stringsManipulations';
import { downloadExcel } from 'react-export-table-to-excel';
import * as XLSX from 'xlsx';
import RentalIncomeExportRangeModal from '../../../../neoverv/components/Module/General/CustomModal/RentalIncomeExportRangeModal';
import TenantAdministration from '../User/TenantAdministration';
// import {
//   getRentaUnitsApi,
//   getTenantsApi,
// } from '../../../../neoverv/helpers/services/tenantServices';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
const {
  filterBySearchBarRentalTenants,
  deleteRecord,
  checkProperty,
  mergeArrayByProperty,
  nestedAlphabeticSort,
  filterTenantsAndUnits,
  filterByDateUtility,
} = Autoload.MainHelper;

const MOBILE_SIZE = 992;

let rentalUnits = [];
let rentalListData = [];

class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: window.innerWidth <= MOBILE_SIZE
    };

    this.csvLinkEl = React.createRef();
  }

  componentDidMount(){
    if(this.props.rentalUnits.length === 0){
      this.props.getRentalUnitTenants([]);
      if(this.props.propertySelected && this.props.propertySelected.id !== undefined){
        this.bind_rental_unit_list_data();
      }
    }
  }

  bind_rental_unit_list_data = () => {
    let propertyid = this.props.propertySelected && this.props.propertySelected.id;
    // console.log('property id', propertyid,  Autoload.Setting.rental_unit_api_base_url+"?propertyId="+propertyid)
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'rentalUnits?offset=0&fetch=100&propertyId=' + propertyid,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handle_get_list_rental_unit',
      null,
      false,
      '',
      {
        // 'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };

  handle_get_list_rental_unit = (response) => {
    if (response.result === null || response.result && response.result.length === 0) {
      //this.ShowLoader();
    }

    console.log(response, 'add tenant response');
    this.props.getRentalUnitTenants([]);
    if (response.status === false) {
      
    }else{
      rentalUnits = response.result.map((ru) => ({
        id: checkProperty(ru, 'id'),
        name: checkProperty(ru, 'name'),
        size: checkProperty(ru, 'space'),
        propertyId: checkProperty(ru, 'propertyId'),
        tenantsData: [],
      }));
      response.result.map((ru) => {
        Autoload.HttpRequestAxios.HttpRequest(
          Autoload.Setting.property_api_base_url + 'tenants?offset=0&fetch=100&rentalUnitId=' + ru.id,
          'GET',
          null,
          null,
          false,
          true,
          false,
          this,
          'handle_get_list_rental_tenants',
          null,
          false,
          '',
          {
            //'Content-Type': 'application/json',
            Authorization: Autoload.Utils.getAuthToken(),
          }
        );
      }); 
    }
  };
  ChangeDateFormat = (dateString) => {
    const dateArray = dateString.split('.');
    return `${dateArray[1]}.${dateArray[0]}.${dateArray[2]}`;
  };
  handle_get_list_rental_tenants = (response) => {
    rentalListData = this.props.rentalUnits;

    if (response.result.length > 0) {
      //sort for move out
      let dashMoveOut = response.result.filter((item) => item.out === '');
      let filledMovedout = response.result.filter((item) => item.out !== '');
      filledMovedout.map((item) => dashMoveOut.push(item));
      //let current = false;
      let tenantsData = [...dashMoveOut];
      // first vacant false check
      let isFirstVacantFound = false;
      //first vancant false = index
      let firstOriginalDataIndex;

      tenantsData = response.result.map((tenant, idx) => {
        console.log(tenant, idx, 'tenant current idx');
        // isVacant value
        let isVacant = tenant.vacant;
        let current = false;
        if (
          (dayjs(tenant.moveInDate).isBefore(dayjs()) || dayjs(tenant.moveInDate).isSame(dayjs())) //logic for moveInDate
          && 
          (tenant.moveOutDate === null || dayjs(tenant.moveOutDate).isAfter(dayjs()) || dayjs(tenant.moveOutDate).isSame(dayjs())) //logic for moveOutDate
        ) {
          firstOriginalDataIndex = idx;
          current = true;
          isFirstVacantFound = true;
        }else if(tenant.vacant && tenant.moveInDate === null && tenant.moveOutDate === null && response.result.length === 1){
          current = true;
        }

        return {
          id: checkProperty(tenant, 'id'),
          name: checkProperty(tenant, 'fullName'),
          email: checkProperty(tenant, 'email'),
          phone: checkProperty(tenant, 'phone'),
          in: checkProperty(tenant, 'moveInDate') 
            ? moment(checkProperty(tenant, 'moveInDate')).format('DD.MM.YYYY')
            : '',
          out: checkProperty(tenant, 'moveOutDate')
            ? moment(checkProperty(tenant, 'moveOutDate')).format('DD.MM.YYYY')
            : '',
          type: checkProperty(tenant, 'contractType'),
          note: checkProperty(tenant, 'notes'),
          current: current,
          createdOn: checkProperty(tenant, 'createdOn'),
          vacant: checkProperty(tenant, 'vacant'),
          rentalUnitId: checkProperty(tenant, 'rentalUnitId'),
          updatedOn: checkProperty(tenant, 'updatedOn'),
        };
      });
      console.log(tenantsData, '======tenantsData');
      if (tenantsData.length === 1 && tenantsData[0].vacant == true) {
        //tenantsData[0].current = true;
      } else if (firstOriginalDataIndex !== 0 && firstOriginalDataIndex !== undefined) {
        let moveoutDate = tenantsData[firstOriginalDataIndex].out;
        let dateCompare = moment(this.ChangeDateFormat(moveoutDate)).isSameOrAfter(moment(new Date()));
        console.log(moveoutDate, dateCompare, tenantsData[firstOriginalDataIndex], '====abc======');
        if (dateCompare === false) {
          //tenantsData[firstOriginalDataIndex - 1].current = true;
          tenantsData[firstOriginalDataIndex].current = false;
        } else {
          //tenantsData[firstOriginalDataIndex - 1].current = true;
        }
      }

      //action
      //row ko array
      let field = 'name';
      let rentalunitsorted = rentalUnits.sort((a, b) =>
        (a[field] || '').toString().localeCompare((b[field] || '').toString())
      );
      rentalUnits = rentalunitsorted.map((ru) => {
        if (ru.id == tenantsData[0].rentalUnitId) {
          return {
            ...ru,
            tenantsData: [...tenantsData],
          };
        } else {
          return ru;
        }
      });

      console.log('tenant of rental unit', tenantsData, rentalUnits);
    }

    //pass data received to redux
    //this.ShowLoader();
    this.props.getRentalUnitTenants(rentalUnits);
  };

  render() {
    const { addUnitModal, editUnitModal, currentUnit, deleteUnitModal, skeletonLoading, showArticleCondition, isMobile, buyPremiumModalOpen, exportTenantsTablePopoverOpen, exportRentalIncomePopoverOpen, openRentalIncomeExportRangeModal, selectedMeansOfExport } = this.state;
    const { rentalUnits, filteredList, propertySelected, currentSubscription } = this.props;
    let pathname = this.props.location.pathname;

    return (
      <>
        <div id="empty-main-tenant-page"></div>
        <div className="main-tenant-page" id="main-tenant-page" style={{ display: 'block' }}>
          {/* page header */}
          {!isMobile &&
          <PageHeaderNew
            heading={'Mieterportal'}
            headingClassName={"main-tenant-administration-heading"}
            secondRowContent={
              <div style={{display: 'flex'}}
              >
                {console.log('window.location.href: ', this.props.history.location.pathname === '/tenantPortal')}
                {!skeletonLoading && breadcrumbsContent.map((item)=> (this.props.history.location.pathname === '/tenantPortal' ? item.area === '/user/administration' : item.area === '/user') && 
                item.breadcrumbs.filter((breadcrumb)=> propertySelected.propertyType !== 'REGULAR' ? breadcrumb.label !== 'Mieteinheit anlegen' : breadcrumb).map((breadcrumb)=> 
                  breadcrumb.type === 'tour' ? breadcrumb.condition === showArticleCondition && breadcrumb.view === (isMobile ? 'mobile' : 'desktop') &&
                  <div style={{display: 'flex', width: 'auto', alignItems: 'center', cursor: 'pointer', marginRight: '22px'}} onClick={()=> window.Intercom('startTour', breadcrumb.id)}>
                    <img src={TourIcon} />
                    <span style={{color: '#5555FF', marginLeft: '5px', fontSize: '14px'}}>{breadcrumb.label}</span>
                  </div>
                  :
                  breadcrumb.view === (isMobile ? 'mobile' : 'desktop') &&
                  <div style={{display: 'flex', width: 'auto', alignItems: 'center', cursor: 'pointer', marginRight: '22px'}} onClick={()=> window.Intercom('showArticle', breadcrumb.id)}>
                    <img src={HelpLogo} />
                    <span style={{color: '#5555FF', marginLeft: '5px', fontSize: '14px'}}>{breadcrumb.label}</span>
                  </div>
                  )
                )}
              </div>
            }
          />}
          <>
            <Route path={this.props.match.url} exact //component={TenantAdministration} 
            render={(props)=> <TenantAdministration {...props} isMobile={isMobile}/>}
            />
          </>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    rentalUnits: state.tenantDataReducer.rentalUnits,
    filteredList: state.tenantDataReducer.filteredList,
    properties: state.generalReducer.allProperties,
    propertySelected: state.generalReducer.property,
    subscription: state.generalReducer.subscription,
    currentSubscription: state.generalReducer.currentSubscription,
    refreshTenantDocumentsData: state.generalReducer.refreshTenantDocumentsData
  };
};
const actions = {
  getRentalUnitTenants,
  setCurrentTenant,
  updateFilteredList,
  DeleteUnit,
  setRefreshTenantDocumentsData
};
export default connect(mapStateToProps, actions)(List);
