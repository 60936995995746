import Autoload from '../../Autoload';
import moment from 'moment';

const { checkProperty } = Autoload.MainHelper;

class PropertyDataHandler {
  static getProperties = (properties) => {
    const newProperties = properties.map((tem) => {
      return {
        id: checkProperty(tem, 'id'),
        propertyName: checkProperty(tem, 'name'),
        propertyAddress: checkProperty(checkProperty(tem, 'propertyAdress'), 'streetno'),
        propertyCity: checkProperty(checkProperty(tem, 'propertyAdress'), 'city'),
        propertyZipCode: checkProperty(checkProperty(tem, 'propertyAdress'), 'zipCode'),
        propertyNoOfUnits: checkProperty(tem, 'numberOfRentalUnits'),
        propertyAreaOfUnits: checkProperty(tem, 'totalRentingSpace'),
        cutOffDate: `${checkProperty(checkProperty(tem, 'cutOffDate'), 'day', '31')} ${moment()
          .month(parseInt(checkProperty(checkProperty(tem, 'cutOffDate'), 'month', '12')) - 1)
          .format('MMMM')}`,
        heatingType: checkProperty(tem, 'heatingType'),
        heatingTemp: checkProperty(tem, 'heatingTemperature'),
        consumptionValue: checkProperty(tem, 'consumptionShare'),
        energyFuelType: checkProperty(tem, 'energyFuelType'),
      };
    });
    return newProperties;
  };

  static getPropertiesData = (properties) => {
    const newProperties = properties.map((tem) => {
      return {
        id: Autoload.MainHelper.checkProperty(tem, 'id'),
        propertyName: Autoload.MainHelper.checkProperty(tem, 'name'),
        propertyAddress: Autoload.MainHelper.checkProperty(
          Autoload.MainHelper.checkProperty(tem, 'propertyAdress'),
          'streetno'
        ),
        propertyCity: Autoload.MainHelper.checkProperty(
          Autoload.MainHelper.checkProperty(tem, 'propertyAdress'),
          'city'
        ),
        propertyZipCode: Autoload.MainHelper.checkProperty(
          Autoload.MainHelper.checkProperty(tem, 'propertyAdress'),
          'zipCode'
        ),
        propertyNoOfUnits: Autoload.MainHelper.checkProperty(tem, 'numberOfRentalUnits'),
        propertyAreaOfUnits: Autoload.MainHelper.checkProperty(tem, 'totalRentingSpace'),
        cutOffDate: `${Autoload.MainHelper.checkProperty(
          Autoload.MainHelper.checkProperty(tem, 'cutOffDate'),
          'day',
          '31'
        )} ${moment()
          .month(
            parseInt(
              Autoload.MainHelper.checkProperty(Autoload.MainHelper.checkProperty(tem, 'cutOffDate'), 'month', '12')
            ) - 1
          )
          .format('MMMM')}`,
        heatingType: Autoload.MainHelper.checkProperty(tem, 'heatingType'),
        heatingTemp: Autoload.MainHelper.checkProperty(tem, 'heatingTemperature'),
        consumptionValue: Autoload.MainHelper.checkProperty(tem, 'consumptionShare'),
        energyFuelType: Autoload.MainHelper.checkProperty(tem, 'energyFuelType'),
      };
    });
    return newProperties;
  };
}

export default PropertyDataHandler;
