import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setTenantBills } from '../../../../redux/action/UtilityBilling/UtilityBillingActions';
import { formatNumber } from '../../../../helpers/stringsManipulations';
import { EmptyStateContainer } from '../../../../neoverv';
import Autoload from '../../../../neoverv/Autoload';
import tap from '../../../../assets/images/module/general/TapIconDark.svg';
import { Group, Stack } from '@mantine/core';
import accounting from 'accounting';


var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];

export class CalculationOfCO2TaxTable extends Component {

  tableHeadCellStyling = {
    color: '#727293',
    padding: '0 9px 0 9px',
    fontWeight: '500',
    whiteSpace: 'nowrap',
    margin: '0px',
    fontSize: '14px',
    lineHeight: '20px',
    border: '1px solid #DADAE6',
    textTransform: 'capitalize',
    height: '48px',
  }
  tableBodyCellStyling = {
    color: '#0e0e1d',
    padding: '5px 9px 5px 9px',
    fontWeight: '400',
    whiteSpace: 'nowrap',
    fontSize: '14px',
    lineHeight: '20px',
    border: '1px solid #DADAE6',
    height: '48px',
    minWidth: '90px',
    // borderBottom: 'none',
    // borderRight: 'none'
  }

  state ={
    basicCostHeating: 0,
    consumptionCostHeating: 0,
    basicCostWarmWater: 0,
    consumptionCostWarmWater: 0,
    basicCostHeatingObject1: null,
    basicCostHeatingObject2: null,
    basicCostWarmWaterObject1: null,
    basicCostWarmWaterObject2: null
  }

  constructor(props){
    super(props);
    if(props.messagePreview){
      this.tableBodyCellStyling.fontSize = '7pt';
      this.tableBodyCellStyling.height = '0px'
      this.tableBodyCellStyling.padding = '1mm'
      this.tableBodyCellStyling.lineHeight = '11pt'

      this.tableHeadCellStyling.fontSize = '7pt';
      this.tableHeadCellStyling.height = '0px'
      this.tableHeadCellStyling.padding = '1mm'
      this.tableHeadCellStyling.lineHeight = '11pt'
    }
  }

  componentDidMount(){
    if(this.props.messagePreview){
      this.tableBodyCellStyling.fontSize = '7pt';
      this.tableBodyCellStyling.height = '0px'
      this.tableBodyCellStyling.padding = '1mm'
      this.tableBodyCellStyling.lineHeight = '11pt'

      this.tableHeadCellStyling.fontSize = '7pt';
      this.tableHeadCellStyling.height = '0px'
      this.tableHeadCellStyling.padding = '1mm'
      this.tableHeadCellStyling.lineHeight = '11pt'
    }
  }

  componentDidUpdate(prevProps){
    
  }

  getTableLabels = () => {
    return (
      <TableRow>
        <TableCell style={{...this.tableHeadCellStyling}}>{'Kennzahl'}</TableCell>
        <TableCell style={{...this.tableHeadCellStyling, ...{textAlign: 'right'}}}>{'Gesamt'}</TableCell>
        <TableCell style={{...this.tableHeadCellStyling, ...{textAlign: 'right'}}}>{'Anteil'}</TableCell>
      </TableRow>
    );
  };
  calculateTotal=()=>{
    let total=0;
    this.props.heatingCosts.map((row)=> {
        total = total + row.heatingCost;
    })
    console.log('calculateTotal: ', total);
    return formatNumber(total, true);;
  }
    render(){
      const {selectedTenantData, isTablet, messagePreview, propertySelected, utilityData} = this.props;
      const {basicCostHeating, basicCostWarmWater, consumptionCostHeating, consumptionCostWarmWater, basicCostHeatingObject1, basicCostHeatingObject2, basicCostWarmWaterObject1, basicCostWarmWaterObject2} = this.state;
      console.log('basicCostHeatingObject1: ', basicCostHeatingObject2);
      console.log('utilityData_122: ', utilityData, selectedTenantData);

        return (
        <TableContainer component={Paper} style={{background: 'transparent', width: messagePreview ? '60%' : !isTablet && '50%'}}>
        <Table style={{borderCollapse: 'collapse'}}>
          <TableHead style={{border: '1px solid #DADAE6', borderBottom: 'none'}}>
            {this.getTableLabels()}
          </TableHead>
          <TableBody style={{background: 'white', border: '1px solid #DADAE6'}}>
            <TableRow>
              <TableCell style={{...this.tableBodyCellStyling}}>
                <span style={{lineHeight: 1.43}}>CO<span style={{verticalAlign: 'sub', fontSize: messagePreview ? '5pt' : '10px'}}>2</span>-Steuer (€)</span>
              </TableCell>
              <TableCell style={{...this.tableBodyCellStyling, ...{textAlign: 'right'}}}>
                <span>{utilityData?.data?.cO2TaxTotal ? accounting.formatNumber(utilityData?.data?.cO2TaxTotal, 2, '.', ',') : '-'}</span>
              </TableCell>
              <TableCell style={{...this.tableBodyCellStyling, ...{textAlign: 'right', maxWidth: '122px'}}}>-</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{...this.tableBodyCellStyling}}>
                <span style={{lineHeight: 1.43}}>CO<span style={{verticalAlign: 'sub', fontSize: messagePreview ? '5pt' : '10px'}}>2</span>-Emissionen (kg)</span>
              </TableCell>
              <TableCell style={{...this.tableBodyCellStyling, ...{textAlign: 'right'}}}>
                <span>{utilityData?.data?.totalCO2Emissions ? accounting.formatNumber(utilityData?.data?.totalCO2Emissions, 2, '.', ',') : '-'}</span>
              </TableCell>
              <TableCell style={{...this.tableBodyCellStyling, ...{textAlign: 'right', maxWidth: '122px'}}}>-</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{...this.tableBodyCellStyling}}>
                <span>Berücksichtige Wohnfläche (m<span style={{verticalAlign: 'super', fontSize: messagePreview ? '5pt' : '10px'}}>2</span>)</span>
              </TableCell>
              <TableCell style={{...this.tableBodyCellStyling, ...{textAlign: 'right'}}}>
                <span>{propertySelected?.heatedFloorSpace ? accounting.formatNumber(propertySelected?.heatedFloorSpace, 2, '.', ',') : '-'}</span>
              </TableCell>
              <TableCell style={{...this.tableBodyCellStyling, ...{textAlign: 'right', maxWidth: '122px'}}}>-</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{...this.tableBodyCellStyling}}>
                <span style={{lineHeight: 1.43}}>CO<span style={{verticalAlign: 'sub', fontSize: messagePreview ? '5pt' : '10px'}}>2</span>-Ausstoß der Immobilie (kg/m<span style={{verticalAlign: 'super', fontSize: messagePreview ? '5pt' : '10px'}}>2</span>)</span>
              </TableCell>
              <TableCell style={{...this.tableBodyCellStyling, ...{textAlign: 'right'}}}>
                <span>{utilityData?.data?.cO2EmissionkgM2 ? accounting.formatNumber(utilityData?.data?.cO2EmissionkgM2, 2, '.', ',') : '-'}</span>
              </TableCell>
              <TableCell style={{...this.tableBodyCellStyling, ...{textAlign: 'right', maxWidth: '122px'}}}>-</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{...this.tableBodyCellStyling}}>
                Anteil Mieter (€)
              </TableCell>
              <TableCell style={{...this.tableBodyCellStyling, ...{textAlign: 'right'}}}>
                <span>{utilityData?.data?.cO2TaxTenantShare ? accounting.formatNumber(((utilityData?.data?.cO2TaxTenantShare/100) * utilityData?.data?.cO2TaxTotal), 2, '.', ',') : '-'}</span>
              </TableCell>
              <TableCell style={{...this.tableBodyCellStyling, ...{textAlign: 'right', maxWidth: '122px'}}}>
                <span>{utilityData?.data?.cO2TaxTenantShare ? `${utilityData?.data?.cO2TaxTenantShare}%` : '-'}</span>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{...this.tableBodyCellStyling, ...{fontWeight: 'bold'}}}>
                Anteil Vermieter (Erstattungsbetrag) (€)
              </TableCell>
              <TableCell style={{...this.tableBodyCellStyling, ...{textAlign: 'right', fontWeight: 'bold', maxWidth: '122px'}}}>
                <span>{utilityData?.data?.cO2TaxLandlordShare ? accounting.formatNumber(((utilityData?.data?.cO2TaxLandlordShare/100) * utilityData?.data?.cO2TaxTotal), 2, '.', ',') : '-'}</span>
              </TableCell>
              <TableCell style={{...this.tableBodyCellStyling, ...{textAlign: 'right', fontWeight: 'bold', maxWidth: '122px'}}}>
                <span>{utilityData?.data?.cO2TaxLandlordShare ? `${utilityData?.data?.cO2TaxLandlordShare}%` : '-'}</span>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>);
            
    }
}

const mapStateToProps = (state) => {
  return {
    allReducer: state,
    currentDataId: state.UtilityBillingReducer.currentBillingId,
    tenantBills: state.UtilityBillingReducer.utilityBillingData,
    utilityBillingData: state.UtilityBillingReducer.utilityBillingData,
    heatingFilter: state.UtilityBillingReducer.HeatingAllocationFilter,
    propertySelected: state.generalReducer.property,
  };
};

const mapDispatchToProps = {
  setTenantBills,
};

export default connect(mapStateToProps, mapDispatchToProps)(CalculationOfCO2TaxTable);

