import { Group } from '@mantine/core';
import React from 'react';

export default function ValueText({ leftImage, text, rightImage, className, textHandler=null, textStyle, notificationDot=false, cardValueClassName=null }) {
  return (
    <div className={`value-text-container ${className}`}>
      <div className="text-img-wrapper">
        <div className="text-img-wrapper">
          {leftImage && (
            <img src={leftImage.img} onClick={()=> leftImage.onClick && leftImage.onClick()} className="btn-img left" alt="no left img" style={{ ...leftImage.style }} />
          )}
          <div 
          style={{
            display: 'flex', 
            alignItems: 'center',
          }}
          >
          <p
            className={`card-value ${cardValueClassName}`}
            onClick={() => textHandler && textHandler()}
            value={text}
            style={textStyle}
          >
            {text}
          </p>
          {notificationDot && <div style={{background: '#5555FF', width: '6px', height: '6px', borderRadius: '10px', marginRight: '5px', marginLeft: '5px'}}></div>}
          </div>
        </div>
        {rightImage && (
          <img
            src={rightImage.img}
            onClick={() => (rightImage.handler ? rightImage.handler() : () => {})}
            className="btn-img right"
            alt="no right img"
            style={{ ...rightImage.style }}
          />
        )}
      </div>
    </div>
  );
}
