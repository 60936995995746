import React from 'react';
import { InfoField, ColorButton } from '../../../../index';
import { Row, Col } from 'reactstrap';
import EmailIcon from '../../../../../assets/images/module/general/EmailIcon.svg';
import phoneIcon from '../../../../../assets/images/module/general/PhoneIcon.svg';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import Autoload from '../../../../Autoload';
const MOBILE_SIZE = 992;
var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default].general;
export default function HelpModal({ className, open, toggle }) {
  return (
    // <Modal  open={open} id="myModal" toggle={toggle} className={`${className}`} isOpen={open} toggle={()=>toggle()} className={`custom-modal ${className}`}centered>
    <Modal
      id="aaaa"
      isOpen={open}
      toggle={() => toggle()}
      className={`custom-modal ${className} ${window.innerWidth <= MOBILE_SIZE ? 'centered' : 'help-modal-center'}`}
      centered
    >
      <ModalHeader toggle={() => toggle()}>{lang.text_help}</ModalHeader>
      <ModalBody className="help-modal-conent ">
        <div>
          <Row>
            <Col xs={6}>
              <InfoField
                labelText={lang.text_contact_email}
                text={lang.text_housing_mail}
                labelClassName="text-xs"
                leftTextImage={{ img: EmailIcon, style: { width: '16px', height: '10.6px' } }}
              />
            </Col>
            <Col xs={6}>
              <InfoField
                labelText={lang.text_contact_phone}
                text={lang.phone_no}
                labelClassName="text-xs"
                leftTextImage={{ img: phoneIcon, style: { width: '15.33px', height: '15.31px' } }}
              />
            </Col>
          </Row>
        </div>
        <div className="help-modal-footer">
          <ColorButton text={lang.text_go_back_home} onClick={() => toggle()} className="gray-btn help-goBack-btn" />{' '}
        </div>
      </ModalBody>
    </Modal>
  );
}
